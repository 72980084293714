import React, { useState, useEffect } from 'react'
import {
    Col, Row, Nav, NavItem, NavLink, TabContent, TabPane,
    Table,
} from "reactstrap";

import classnames from "classnames";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";



const BreakDownView = () => {

    const currentCostingProfile = useSelector(
        ({ currentCosting }) => currentCosting.currentCostingProfile
    );
    const [profile] = useState(currentCostingProfile);
    const [wavesData, setWavesData] = useState(currentCostingProfile.WaveSpecs)
    const [activeTabFC, setActiveTabFC] = useState();
    const [activeTabSM, setActiveTabSM] = useState();
    const [waveBreakDown, setWaveBreakDown] = useState({})
    const [currentWaveSpec, setCurrentWaveSpec] = useState({});
    const [showManualCostEntry, setShowManualCostEntry] = useState(false);
    const [showSheetsCosts, setShowSheetsCosts] = useState(false);
    const [showCostMethod, setShowCostMethod] = useState(false);
    const dispatch = useDispatch();
    const [modifiedMeths, setModifiedMeths] = useState([])
    const [activeWave, setActiveWave] = useState("1");
    const [toggle, setToggle] = useState({
        costingOption: false
    });
    const codeLabels = useSelector(({ codeLabels }) => codeLabels);
    const app = useSelector(({ app }) => app);
    useEffect(() => {


        let countryData = _.head(profile.CountrySpecs);

        let mData = countryData.MethodologySpecs?.filter(ms => !ms.NotApplicable);
        let methodologyID = _.head(mData).id

        setActiveTabFC(countryData.id);
        setActiveTabSM(methodologyID);
        onMethodologyChange(methodologyID);

    }, [currentCostingProfile])

    const [timeEntryDetails, setTimeEntryDetails] = useState({});
    const countrySpecs = useSelector(({ countrySpecs }) => countrySpecs);

    useEffect(() => {
        if (countrySpecs && countrySpecs.length && _.head(countrySpecs).MethodologySpecs?.length && !Object.keys(timeEntryDetails).length) {
            let timeEntryDetails = {};
            countrySpecs?.map((cs) => {
                timeEntryDetails[cs.CountryCode] = {};
                cs.MethodologySpecs?.map((ms) => {
                    timeEntryDetails[cs.CountryCode][ms.Code] = {};
                    ms.CostingOptions.filter(selectedCO => selectedCO.CoSelectedForSummary)?.map((co => {
                        timeEntryDetails[cs.CountryCode][ms.Code][co.id] = co.TimingsData
                    }))
                });
            });
            setTimeEntryDetails(timeEntryDetails);
        }
    }, [countrySpecs]);

    const [costingOptionCostSet, setCostingOptionCostSet] = useState({})
    useEffect(() => {

        let _costingOptionCostSet = {}

        if (!Object.keys(costingOptionCostSet).length) {
            currentCostingProfile.CountrySpecs.map(cs => {
                cs.MethodologySpecs.filter(ms => !ms.NotApplicable).map(ms => {
                    if (!_costingOptionCostSet[ms.Code]) {
                        _costingOptionCostSet[ms.Code] = {}
                        ms.TimingsSchema.map(cssch => {
                            Object.keys(cssch.properties).map(_prop => {
                                _costingOptionCostSet[ms.Code][_prop] = cssch.properties[_prop]
                            })
                        })
                    }
                })
            })
        }
        setCostingOptionCostSet(_costingOptionCostSet)
    }, [currentCostingProfile]);
    const currencies = useSelector(({ currentCosting }) =>
        currentCosting.currentCostingProfile.ProfileSetting &&
            currentCosting.currentCostingProfile.ProfileSetting.CurrenciesData
            ? currentCosting.currentCostingProfile.ProfileSetting.CurrenciesData
            : []
    );
    const [currentCurrency, setCurrentCurrency] = useState({});
    useEffect(() => {
        if (profile && profile.CostInputCurrency) {
            let values = profile.CostInputCurrency.split("-");
            let currencyUnit = _.last(values);
            let countryCode = _.head(values);
            if (currencies) {
                setCurrentCurrency(
                    _.head(
                        currencies.filter(
                            (cur) =>
                                cur.Code == countryCode && cur.CurrencyUnit == currencyUnit
                        )
                    )
                );
            }
        }
    }, [profile, currencies]);
    const getCurrentCurrency = (actualvalue, currencyvalue, onlyCurrency) => {
        if (
            currencies &&
            currencies.length &&
            currentCurrency &&
            currentCurrency.ConversionRateToLocal
        ) {
            if (actualvalue) {
                let finalVal = _.round(
                    actualvalue * currentCurrency.ConversionRateToLocal,
                    2
                );
                return `${finalVal} ${currentCurrency.CurrencyUnit}`;
            } else return `0 ${currentCurrency.CurrencyUnit}`;
        }
    };
    const toggleFC = (tab) => {
        if (activeTabFC !== tab) {
            setActiveTabFC(tab);
            let cData = profile.CountrySpecs.filter(x => x.id === tab);
            let methData = _.head(cData);

            let mData = methData.MethodologySpecs?.filter(ms => !ms.NotApplicable);
            let methodologyID = _.head(mData).id
            setActiveTabSM(methodologyID)
            onMethodologyChange(methodologyID)
        }
    };

    const toggleSM = (tab) => {
        if (activeTabSM !== tab) {
            setActiveTabSM(tab);
            onMethodologyChange(tab)
        }
    };

    const getLabelForCode = (key) => {
        let costLabel = key;
        codeLabels['CostLabelsOptions'].forEach(codeLabel => {
            if (codeLabel.Code == key) {
                costLabel = codeLabel.Label;
            }

        });
        return costLabel;
    }

    const onMethodologyChange = (methID) => {
        let _waveBreakdownMain = wavesData?.filter(x => x.MethodologySpecId === methID)
        let _waveBreakdown = _.groupBy(_waveBreakdownMain, m => m.WaveNumber)
        setWaveBreakDown(_waveBreakdown);
        setModifiedMeths((_waveBreakdownMain))
        let data = _.head(wavesData.filter(cws => cws.MethodologySpecId == methID))
        setCurrentWaveSpec(data);
    }
    return (
        <Row>
            <Col md={12} className={'countryTabs tab-vertical'}>

                <div className={"tab mt-5"}>
                    <Nav tabs>
                        {profile.CountrySpecs &&
                            profile.CountrySpecs.map((fc) => (
                                <NavItem key={fc.value}>
                                    <NavLink className={classnames({ active: activeTabFC === fc.id })}
                                        onClick={() => {
                                            toggleFC(fc.id);
                                        }}
                                    >
                                        <b>{fc.CountryName}</b>
                                    </NavLink>
                                </NavItem>
                            ))}
                    </Nav>
                    <TabContent activeTab={activeTabFC}>
                        {profile.CountrySpecs &&
                            profile.CountrySpecs.map((fc, indxFC) => (
                                activeTabFC === fc.id &&
                                <TabPane tabId={fc.id} key={fc.value}>
                                    <Row>
                                        <div className='col-md-12'>
                                            <h3 >Breakdown by Costing Option</h3>
                                        </div>
                                    </Row>
                                    <Row>
                                        <Col md={12} className={"methodologyTabs"}>
                                            <Nav tabs>
                                                {fc.MethodologySpecs &&
                                                    fc.MethodologySpecs.filter(x => !x.NotApplicable && x.CostingOptions.some(s => s.CoSelectedForSummary)).map((sm, indxSM) =>
                                                    (
                                                        <NavItem key={sm.value}>
                                                            <NavLink className={classnames({ active: activeTabSM === sm.id })}
                                                                onClick={() => {
                                                                    toggleSM(sm.id);
                                                                }}
                                                            >
                                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                    <div>
                                                                        <b>{sm.Label}</b>
                                                                    </div>

                                                                </div>
                                                            </NavLink>
                                                        </NavItem>
                                                    ))}
                                            </Nav>

                                            <TabContent activeTab={activeTabSM} style={{ padding: "0.25" }}>
                                                <Row>
                                                    <Col md={12} className={"methodologyTabs"}>
                                                        <Table responsive hover striped bordered={true} size="sm">
                                                            <thead>
                                                                <tr>
                                                                    {fc.MethodologySpecs &&
                                                                        fc.MethodologySpecs.filter(x => x.id === activeTabSM && !x.NotApplicable && x.CostingOptions.some(s => s.CoSelectedForSummary)).map((sm, indxSM) =>
                                                                        (
                                                                            <>
                                                                                <Table responsive hover striped bordered={true} size="sm">
                                                                                    <thead>
                                                                                        <th>Component</th>
                                                                                        {sm.CostingOptions.filter(selectedCO => selectedCO.CoSelectedForSummary).map((co) => (
                                                                                            <th>
                                                                                                {co.Name}
                                                                                            </th>
                                                                                        ))}
                                                                                    </thead>


                                                                                    <tbody>
                                                                                        {Object.keys(costingOptionCostSet[sm.Code]).map(field => {
                                                                                            return costingOptionCostSet[sm.Code][field].title ? <tr>
                                                                                                <td>{costingOptionCostSet[sm.Code][field].title}</td>
                                                                                                {sm.CostingOptions?.filter(selectedCO => selectedCO.CoSelectedForSummary)?.map((co) => (
                                                                                                    <td>
                                                                                                        {/* {co.TimingsData[field] ? co.TimingsData[field] + " Days" : "0 Days"} */}
                                                                                                        <div class="input-group">
                                                                                                            <input
                                                                                                                placeholder={sm.NotApplicable ? "Not Applicable" : null}
                                                                                                                className="form-control"
                                                                                                                type="number"
                                                                                                                onChange={(e) => {
                                                                                                                    if (!timeEntryDetails[fc.CountryCode]) {
                                                                                                                        timeEntryDetails[fc.CountryCode] = {}
                                                                                                                    }
                                                                                                                    if (!timeEntryDetails[fc.CountryCode][sm.Code]) {
                                                                                                                        timeEntryDetails[fc.CountryCode][sm.Code] = {}
                                                                                                                    }
                                                                                                                    if (!timeEntryDetails[fc.CountryCode][sm.Code][co.id]) {
                                                                                                                        timeEntryDetails[fc.CountryCode][sm.Code][co.id] = co.TimingsData ? co.TimingsData : {}
                                                                                                                    }
                                                                                                                    timeEntryDetails[fc.CountryCode][sm.Code][co.id] = { ...timeEntryDetails[fc.CountryCode][sm.Code][co.id] }
                                                                                                                    timeEntryDetails[fc.CountryCode][sm.Code][co.id][field] = e.target.value
                                                                                                                    setTimeEntryDetails({ ...timeEntryDetails })
                                                                                                                }}
                                                                                                                value={timeEntryDetails[fc.CountryCode][sm.Code][co.id] ? timeEntryDetails[fc.CountryCode][sm.Code][co.id][field] : 0}
                                                                                                                id={co.id}
                                                                                                                min={0}
                                                                                                                step="any"
                                                                                                                disabled={
                                                                                                                    sm.NotApplicable ||
                                                                                                                    profile.CostingType == "SHEETS" ||
                                                                                                                    (profile.CostingType == "VENDOR" && profile.VendorBiddingSubmethodologies && _.includes(profile.VendorBiddingSubmethodologies.split(","), sm.Code))
                                                                                                                }
                                                                                                            />
                                                                                                            <div class="input-group-append">
                                                                                                                <span class="input-group-text text-sm">Days</span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>))
                                                                                                }

                                                                                            </tr> : null
                                                                                        })}

                                                                                    </tbody>

                                                                                </Table>
                                                                            </>))}
                                                                </tr>
                                                            </thead>

                                                        </Table>


                                                    </Col>
                                                </Row>
                                            </TabContent>
                                        </Col>
                                    </Row>
                                </TabPane>
                            ))}
                    </TabContent>
                </div>
            </Col>
        </Row >
    )
}

export default BreakDownView
