import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import * as currentProjectActions from "../../redux/actions/currentProjectActions";
import * as currentCostingActions from "../../redux/actions/currentCostingActions";
import * as currentWaveSpecActions from "../../redux/actions/currentWaveSpecActions";
import * as appActions from "../../redux/actions/appActions";

import { msdSalesForceAction } from "../../redux/actions/msdSalesforce/msdSalesforce";
import axios from "../../axios-interceptor";
import { toastr } from "react-redux-toastr";
import DatePicker from "react-datepicker";

// import logo from "../../assets/img/avatars/modal-logo.png";
import "react-datepicker/dist/react-datepicker.css";

import Layout from "../../layouts/Project";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

import {
  Button,
  CustomInput,
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Collapse,
  CardHeader,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
} from "reactstrap";
import {
  setCurrentWaveSpec,
  setWaveSpecs,
} from "../../redux/actions/waveSpecsActions";
import Selector from "../../components/Selector/Selector_Updated";
import { getLabel } from "../../utils/codeLabels";
import FormFeedback from "reactstrap/lib/FormFeedback";
import Spinner from "../../components/Spinner";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import ProjectDeliveryDates from "./ProjectDeliveryDates copy";
import RecordsBadge from "../../components/RecordsBadge";
import {
  localPageLoadEnd,
  localPageLoadStart,
  recordLoadStart,
  recordLoadEnd,
} from "../../redux/actions/appActions";

import logo from "../../assets/img/avatars/modal-logo.png";
import moment from "moment";
const ScheduleDatesEWN = () => {
  const user = useSelector(({ user }) => user.userRecord);
  const project = useSelector(
    ({ currentProject }) => currentProject.newProject
  );
  const costingProfiles = useSelector(
    ({ costings }) => costings.costingProfiles
  );
  const currentCostingProfile = useSelector(
    ({ currentCosting }) => currentCosting.currentCostingProfile
  );

  const [editableCostingProfile, setEditableCosting] = useState(
    currentCostingProfile
  );

  const [selectedMethodologies, setSelectedMethodologies] = useState([]);

  const [count, setCount] = useState(0);
  const [count1, setCount1] = useState(0);
  const waveSpecs = useSelector(({ waveSpecs }) => waveSpecs);
  const currentWaveSpec = useSelector(({ currentWaveSpec }) => currentWaveSpec);
  const msdSalesforce = useSelector(
    ({ msdSalesForceReducer }) => msdSalesForceReducer
  );

  const codeLabels = useSelector(({ codeLabels }) => codeLabels);
  const app = useSelector(({ app }) => app);

  const [isSaveModal, setSaveModal] = useState(false);
  const [scheduleDate, setScheduleDates] = useState({});

  const [waveEditModal, setWaveEditModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [editableWaveName, setEditableWavename] = useState();
  const [fieldingCountries, setFieldingCountries] = useState([]);
  const [updatedFieldingCountries, setUpdatedFieldingCountries] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [allowedBusinessUnits, setAllowedBusinessUnits] = useState(["CI"]);
  const [externalRecipients, setExternalRecipients] = useState([]);
  const [internalRecipients, setInternalRecipients] = useState([]);
  const [updatedExternalRecipients, setUpdatedExternalRecipients] = useState(
    []
  );
  const [updatedInternalRecipients, setUpdatedInternalRecipients] = useState(
    []
  );
  const [showDetailedRecipients, setShowDetailedRecipients] = useState(false);
  const [ewnPopupSections, setEwnPopupSections] = useState({});
  const [currentBusinessUnit, setCurrentBusinessUnit] = useState({});
  const [updatedCurrentBusinessUnit, setUpdateCurrentBusinessUnit] = useState(
    {}
  );
  const [allFields] = useState([
    "DateWaveCommissioned",
    "NotesPM",
    "DateFinalQuestionnaire",
    "NotesFinalQuestionnaire",
    "DateFieldStart",
    "DateFieldEnd",
    "NotesFieldwork",
    "DateDataProcessing",
    "DateAnalysisPlan",
    "DateInterimData",
    "DateInterimTable",
    "DateFinalData",
    "NotesDataProcessing",
    "DateDashboards",
    "NotesDashboards",
    "DateCharts",
    "DateChartingtemplate",
    "DateFinalTableCharting",
    "NotesCharts",
    "DateTranslations",
    "NotesTranslations",
    "ScheduleTranslationsWordCount",
    "ScheduleTranslationType",
    "ScheduleOriginalFileFormat",
    "ScheduleFinalMaterialsDue",
    "DateVerbatimCoding",
    "NotesVerbatimCoding",
    "DateFinalReport",
    "DateFinalPresentation",
    "NotesFinalReport",
    "NotesDataScience",
    "NotesOther",
    "DateFinalQuestionnaireNA",
    "DateFieldworkNA",
    "DateDataProcessingNA",
    "DateDashboardsNA",
    "DateChartsNA",
    "DateTranslationsNA",
    "DateVerbatimCodingNA",
    "DateFinalReportNA",
    "DeliverableTypes",
  ]);
  const localPageload = useSelector(({ app }) => app.localPageload);
  const recordloading = useSelector(({ app }) => app.recordloading);
  const [ewnfields, setEwnfields] = useState({
    //need to cater for sections both here in BU as well as vertical - e.g. section title (Internal EWN Recepients /Ext EWN Recepients)
    InternalEwnRecipients: {
      title: "NielsenIQ Internal Teams EWN Recipients",
      properties: {
        EwnInternalOpsPM: {
          label: "Primary Internal Operations Contact",

          isMultiselect: true,
        },
        EwnInternalCharts: {
          label: "Charting",
          DisablingVal: "DateChartsNA",
          resource: "chartingResource",
          isMultiselect: true,
        },
        EwnInternalDashboards: {
          label: "Dashboarding",
          DisablingVal: "DateDashboardsNA",
          resource: "dashboardingResource",
          isMultiselect: true,
        },
        EwnInternalDataProcessing: {
          label: "Data Processing",
          DisablingVal: "DateDataProcessingNA",
          resource: "dataProcessingResource",
          isMultiselect: true,
        },
        EwnInternalDataScience: {
          label: "Data Science",
          NotesField: "NotesDataScience",
          isMultiselect: true,
        },
        EwnInternalFieldwork: {
          label: "Fieldwork",
          DisablingVal: "DateFieldworkNA",
          isMultiselect: true,
        },

        EwnInternalProgramming: {
          label: "Programming",
          DisablingVal: "DateFinalQuestionnaireNA",
          resource: "surveyProgrammingResource",
          isMultiselect: true,
        },
        EwnInternalTranslations: {
          label: "Translations",
          DisablingVal: "DateTranslationsNA",
          resource: "translationsResource",
          isMultiselect: true,
        },
        EwnInternalVerbatimCoding: {
          label: "Verbatim Coding",
          DisablingVal: "DateVerbatimCodingNA",
          resource: "verbatimCodingResource",
          isMultiselect: true,
        },
        EwnInternalFinance: {
          label: "Finance",
          isMultiselect: true,
        },
      },
    },
    ExternalEwnRecipients: {
      title: "External EWN Recipients",
      properties: {
        EwnExternalOpsPM: {
          label: "Primary External Operations Contact",
          isMultiselect: true,
        },
        EwnExternalCharts: {
          label: "Charting",
          DisablingVal: "DateChartsNA",
          resource: "chartingResource",
          isMultiselect: true,
        },
        EwnExternalDashboards: {
          label: "Dashboarding",
          DisablingVal: "DateDashboardsNA",
          resource: "dashboardingResource",
          isMultiselect: true,
        },
        EwnExternalDataProcessing: {
          label: "Data Processing",
          DisablingVal: "DateDataProcessingNA",
          resource: "dataProcessingResource",
          isMultiselect: true,
        },
        EwnExternalDataScience: {
          label: "Data Science",
          NotesField: "NotesDataScience",
          isMultiselect: true,
        },
        EwnExternalFieldwork: {
          label: "Fieldwork",
          DisablingVal: "DateFieldworkNA",
          isMultiselect: true,
        },

        EwnExternalProgramming: {
          label: "Programming",
          DisablingVal: "DateFinalQuestionnaireNA",
          resource: "surveyProgrammingResource",
          isMultiselect: true,
        },
        EwnExternalTranslations: {
          label: "Translations",
          DisablingVal: "DateTranslationsNA",
          resource: "translationsResource",
          isMultiselect: true,
        },
        EwnExternalVerbatimCoding: {
          label: "Verbatim Coding",
          DisablingVal: "DateVerbatimCodingNA",
          resource: "verbatimCodingResource",
          isMultiselect: true,
        },
      },
    },
  });

  const dispatch = useDispatch();

  const [toggle, setToggle] = useState({
    overall: true,
    inst: true,
    other: true,
  });
  const [Fields, updateFields] = useState({
    "Commissioning Information": {
      id: "CommissioningInformation",
      DateFields: [
        {
          id: "DateWaveCommissioned",
          label: "Date Commissioned",
          isRequired: true,
        },
      ],
      NotesField: {
        id: "NotesPM",
        label: "Notes for Project Manager",
      },
    },
    "Survey Programming": {
      id: "SurveyProgramming",
      DisablingVal: "DateFinalQuestionnaireNA",
      RequiredValue: "surveyProgrammingRequired",
      DateFields: [
        {
          id: "DateFinalQuestionnaire",
          label: "Date Final Questionnaire Available",
          isRequired: true,
        },
      ],
      NotesField: {
        id: "NotesFinalQuestionnaire",
        label: "Notes for Programming team",
      },
    },
    "Field Work": {
      id: "Field Work",
      DisablingVal: "DateFieldworkNA",
      DateFields: [
        {
          id: "DateFieldStart",
          label: "Planned FW End Date",
          isRequired: false,
        },
        {
          id: "DateFieldEnd",
          label: "Planned FW End Date",
          isRequired: false,
        },
      ],
      NotesField: {
        id: "NotesFieldwork",
        label: "Notes for Field Work",
      },
    },
    "Data Processing": {
      id: "Data Processing",
      DisablingVal: "DateDataProcessingNA",
      RequiredValue: "dataProcessingRequired",
      PriorFields: [
        {
          id: "DeliverableTypes",
          label: "Deliverable Types",
          isRequired: true,
          // Options: alldeliverableTypes
        },
      ],
      DateFields: [
        {
          id: "DateAnalysisPlan",
          label: "Date Analysis Plan",
          isRequired: true,
        },
        { id: "DateInterimData", label: "Date Interim Data" },
        { id: "DateInterimTable", label: "Date Interim Table" },
        {
          id: "DateFinalData",
          label: "Date Final Data",
          isRequired: true,
        },
        {
          id: "DateDataProcessing",
          label: "Date Final Table to CS",
          isRequired: true,
        },
      ],
      NotesField: {
        id: "NotesDataProcessing",
        label: "Notes for Data Processing Team",
      },
    },
    Dashboarding: {
      id: "Dashboarding",
      DisablingVal: "DateDashboardsNA",
      RequiredValue: "dashboarding",
      DateFields: [
        {
          id: "DateDashboards",
          label: "Date Dashboard Due",
          isRequired: true,
        },
      ],
      NotesField: {
        id: "NotesDashboards",
        label: "Notes for Dashboarding Team",
      },
    },
    Charting: {
      id: "Charting",
      DisablingVal: "DateChartsNA",
      RequiredValue: "chartingRequired",
      DateFields: [
        {
          id: "DateFinalTableCharting",
          label: "Date Final Table to Charting Team",
          isRequired: true,
        },
        {
          id: "DateChartingtemplate",
          label: "Date Charting template",
          isRequired: true,
        },
        {
          id: "DateCharts",
          label: "Date Charts Due",
          isRequired: true,
        },
      ],
      NotesField: {
        id: "NotesCharts",
        label: "Notes for Charting Team",
      },
    },
    Translations: {
      id: "Translations",
      DisablingVal: "DateTranslationsNA",
      DateFields: [
        {
          id: "DateTranslations",
          label: "Date Translations of Questionnaire Due",
        },
      ],
      NotesField: {
        id: "NotesTranslations",
        label: "Notes for Translations Team",
      },
      AdditionalFields: [
        {
          id: "ScheduleTranslationsWordCount",
          label: "Word Count",
          type: "number",
        },
        {
          id: "ScheduleTranslationType",
          label: "Translation type",
          type: "dropdown",
          options: [
            { label: "Select Translation Type", value: "-" },
            {
              label: "From English into local language",
              value: "From English into local language",
            },
            {
              label: "From local language into English",
              value: "From local language into English",
            },
            { label: "Local Language Review", value: "Local Language Review" },
            {
              label: "Other(please specify in Comments below)",
              value: "Other (please specify in Comments below)",
            },
          ],
        },
        {
          id: "ScheduleOriginalFileFormat",
          label: "Original File Format",
          type: "dropdown",
          options: [
            { label: "Select Original File Format", value: "-" },
            {
              label: "Document (or .doc, etc.)",
              value: "Document (or .doc, etc.)",
            },
            {
              label: "Spreadsheet (or .xls, etc.)",
              value: "Spreadsheet (or .xls, etc.)",
            },
            {
              label: "Presentation (or .ppt, etc.)",
              value: "Presentation (or .ppt, etc.)",
            },
            {
              label: "Other (please specify in Comments below)",
              value: "Other (please specify in Comments below)",
            },
          ],
        },
        {
          id: "ScheduleFinalMaterialsDue",
          label: "Final materials due for translation on",
          isRequired: true,
          type: "date",
        },
      ],
    },
    "Verbatim Coding": {
      id: "VerbatimCoding",
      DisablingVal: "DateVerbatimCodingNA",
      DateFields: [
        {
          id: "DateVerbatimCoding",
          label: "Date Verbatim Coding Due",
        },
      ],
      NotesField: {
        id: "NotesVerbatimCoding",
        label: "Notes for Verbatim Coding Team",
      },
    },
    "Final Report": {
      id: "FinalReport",
      DisablingVal: "DateFinalReportNA",
      DateFields: [
        {
          id: "DateFinalReport",
          label: "Date Final Report Due to the Client",
          isRequired: true,
        },
        {
          id: "DateFinalPresentation",
          label: "Date Final Presentation",
          isRequired: true,
        },
      ],
      NotesField: {
        id: "NotesFinalReport",
        label: "Any Notes on Final Reporting",
      },
    },
  });
  const collapseAll = () => {
    let Toggle = { ...toggle };
    Object.keys(Fields).map((field) => {
      Toggle[Fields[field].id] = !toggle.overall;
    });
    Object.keys(Toggle).map((t) => {
      Toggle[t] = !toggle.overall;
    });
    setToggle({ ...Toggle, overall: !toggle.overall });
  };
  const history = useHistory();
  const [methBreakdown, setmethBreakdown] = useState({});
  const [waveBreakDown, setWaveBreakDown] = useState({});
  const [currentWaveSet, setCurrentWaveSet] = useState([]);
  const [alldeliverableTypes, setDeliverableType] = useState([]);
  const [canEditSchedule, setEditSchedule] = useState(false);
  let { profileId } = useParams();
  const [calledProfile, setCalledProfile] = useState(false);

  useEffect(() => {
    if (user.IsOpsProjectManager && !user.AllActionsAccess)
      setEditSchedule(false);
    else setEditSchedule(true);
  }, [project, user]);
  useEffect(() => {
    let meths = _.cloneDeep(selectedMethodologies);
    // debugger
    if (currentCostingProfile.id)
      currentCostingProfile?.CountrySpecs.map((cs) =>
        cs?.MethodologySpecs.map((ms) => {
          if (
            !ms.NotApplicable &&
            !meths.filter((sm) => sm.Code == ms.Code).length
          ) {
            meths.push({ Code: ms.Code, Label: ms.Label, id: ms.id });
          }
        })
      );

    console.log(meths);
    setSelectedMethodologies(meths);
    setEditableCosting(currentCostingProfile);
    if (
      !alldeliverableTypes.length &&
      currentCostingProfile.WaveSpecs &&
      currentCostingProfile.WaveSpecs.length
    ) {
      // let _firstwave = _.head(currentCostingProfile.WaveSpecs)
      // let _deliverableTypes = [];
      // if (_firstwave.OpsResourcesSchema?.properties?.deliverableTypes) {
      //   _deliverableTypes = _firstwave.OpsResourcesSchema.properties.deliverableTypes.enum?.map(e => e.replace(",", ""))
      // } else {
      let _deliverableTypes = [
        "CE+OE Tables",
        "CE Tables",
        "Interim Table",
        "Top Line/KPI",
        "Excel Tables",
        "Quanvert",
        "Q",
        "SPSS",
        "Resolve",
        "JMP (for P&G studies)",
        "Raw data (ASCII/Excel)",
        "One table per sheet",
        "All tables in one sheet",
        "Branded Proprietory Products",
        "WB (with Kruskal, Netted WB, Category WB, BEI score in tables)",
        "eQ",
        "CE / RSI",
        "FACTOR",
        "Regression (Linear, Shapley Value etc.)",
        "Correlation",
        "EPIC Calc",
        "Correspondence / GAP analysis/ Brand Map",
        "Kruskal Derived Importance Score",
        "Penalty Analysis (with Lifter Score for Pepsi Client only)",
        "Balance Detector",
        "Jaccard",
        "Normalization / Pearson Residual Index",
        "PSM",
        "Rank Order Analysis (Only for Africa)",
        "Decision Tree (Summary Table - Only for Africa)",
        "Incidence Calculation",
        "Cross Brand Affinity",
        "BEI / BEM",
        "WB Foresight",
        "Brand Builder",
        "Segmentation",
        "BPCM",
        "Conjoint Analysis",
        "Pair test",
        "Product testing suite",
        "Eye Tracking",
        "Path Tracking",
        "English",
        "Other",
        "Abs+%+Sig",
        "%+Sig",
        "% Only",
        "Abs Only",
        "Abs+Sig",
        "Abs+%",
        "Weighting",
        "Sig testing",
        "Brand Leverage",
      ];
      // }
      setDeliverableType(_deliverableTypes);
    }

    let OopId =
      currentCostingProfile?.Project?.ContractDetails[0]?.OpportunityNumber;

    if (OopId) {
      dispatch(msdSalesForceAction(OopId));
    }
  }, [currentCostingProfile]);
  useEffect(() => {
    if (profileId && !calledProfile) {
      setCalledProfile(true);
      dispatch(currentCostingActions.getCosting(profileId, null, true));
      axios.get(`/deliveries/getSchedule/${profileId}`).then((data) => {
        console.log(data.data);
        let tempDAtes = {};
        if (data.data.data.length) {
          let dates = data.data.data.map((d, i) => {
            tempDAtes[d.SubmethodologyLabel] = {
              ...tempDAtes[d.SubmethodologyLabel],
              QueDate: new Date(d.QuestionnaireDate),
              FWStart: new Date(d.FWStartDate),
              FWEnd: new Date(d.FWEndDate),
            };
          });
          console.log(tempDAtes);
          setScheduleDates(tempDAtes);
        }
      });
    } else if (!profileId) {
      //console.log("boots to dashboard");
      history.replace("/");
    }
  }, []);

  useEffect(() => {
    let OopIdValue =
      currentCostingProfile?.Project?.ContractDetails[0]?.OpportunityNumber;

    if (OopIdValue) {
      let dateFieldStart = _.head(
        msdSalesforce?.msdData?.StartofDelivery.split("T")
      );

      let dateFinalReport = _.head(
        msdSalesforce?.msdData?.EndofDelivery.split("T")
      );

      let dateUpdatedValue = {
        ...currentWaveSpec,
        DateFieldStart: currentWaveSpec.DateFieldStart ?? dateFieldStart,
        DateFinalReport: currentWaveSpec.DateFinalReport ?? dateFinalReport,
      };

      dispatch(
        currentWaveSpecActions.updateCurrentWaveSpec({ ...dateUpdatedValue })
      );
    }
  }, [msdSalesforce]);

  const onChangeHandlerQueDates = (e, id, sm) => {
    // if (e < new Date()) {
    //   let tempDates = _.cloneDeep(scheduleDate);
    //   tempDates[sm] = { ...tempDates[sm], [id]: null };
    //   setScheduleDates(tempDates);

    //   toastr.info(
    //     "Questionnare Date Should be Greater than Commissioning Date"
    //   );
    // } else {
      if (
        scheduleDate[sm]?.FWStart &&
        scheduleDate[sm].FWStart.getTime() <=e.getTime()
      ) {
        toastr.info(
          " QuestionnaireDate should be less Than FW Start Date "
        );
      } else {
        let tempDates = _.cloneDeep(scheduleDate);
        tempDates[sm] = { ...tempDates[sm], [id]: e };

        setScheduleDates(tempDates);
      }
    // }
  };

  const onChangeHandlerFWStart = (e, id, sm) => {
    let tempDates = _.cloneDeep(scheduleDate);
    if (tempDates[sm].QueDate) {
      if (e.getTime() <= tempDates[sm].QueDate.getTime()) {
        tempDates[sm] = { ...tempDates[sm], [id]: null };
        setScheduleDates(tempDates);
        toastr.info("FW Start Date should be Greater Than QuestionnaireDate");
      } else {
        if (
          scheduleDate[sm]?.FWEnd &&
          scheduleDate[sm].FWEnd.getTime() <= e.getTime()
        ) {
          toastr.info("FW Start Date should be less Than FW End Date");
        } else {
          tempDates[sm] = { ...tempDates[sm], [id]: e };
          setScheduleDates(tempDates);
        }
      }
    } else {
      tempDates[sm] = { ...tempDates[sm], [id]: null };
      setScheduleDates(tempDates);
      toastr.error("Please Select Questionnaire Date");
    }
  };

  const onChangeHandlerFwEnd = (e, id, sm) => {
    let tempDates = _.cloneDeep(scheduleDate);
    if (tempDates[sm].FWStart) {
      if (e > tempDates[sm].FWStart) {
        tempDates[sm] = { ...tempDates[sm], [id]: e };
        setScheduleDates(tempDates);
      } else {
        tempDates[sm] = { ...tempDates[sm], [id]: null };
        setScheduleDates(tempDates);
        toastr.info("FW End Date should be Greater Than FW Start Date");
      }
    } else {
      tempDates[sm] = { ...tempDates[sm], [id]: null };
      setScheduleDates(tempDates);
      toastr.error("Please Select FW Start Date");
    }
  };

  const getWorkingDays = (date) => {
    let commCountry = codeLabels.FieldingCountriesOptions.find(
      (fc) => fc.Code == project.CommissioningCountry
    );
    let days = 4;
    let weekendFirstDay = commCountry.WeekStartDay;
    let weekendSecondDay = commCountry.WeekEndDay;
    date = moment(date); // use a clone
    if (date.isoWeekday() == weekendFirstDay) date = date.subtract(1, "days");
    if (date.isoWeekday() == weekendSecondDay) date = date.subtract(2, "days");

    while (days > 0) {
      // if (date.isoWeekday() === weekendFirstDay)
      //     date = date.subtract(3, 'days');
      // else if (date.isoWeekday() === weekendSecondDay)
      //     date = date.subtract(2, 'days');

      date = date.add(1, "days");
      // decrease "days" only if it's a weekday.
      //Dates enum
      // MONDAY: 1,
      // TUESDAY: 2,
      // WEDNESDAY: 3,
      // THURSDAY: 4,
      // FRIDAY: 5,
      // SATURDAY: 6,
      // SUNDAY: 7
      if (
        date.isoWeekday() !== weekendFirstDay &&
        date.isoWeekday() !== weekendSecondDay
      ) {
        days -= 1;
      }
    }
    return date.format("YYYY-MM-DD");
  };

  const msdDateValue = () => {
    let OopIdValue =
      currentCostingProfile?.Project?.ContractDetails[0]?.OpportunityNumber;

    if (OopIdValue) {
      let dateFieldStart = _.head(
        msdSalesforce?.msdData?.StartofDelivery.split("T")
      );

      let dateFinalReport = _.head(
        msdSalesforce?.msdData?.EndofDelivery.split("T")
      );

      return {
        DateFieldStart: dateFieldStart,
        DateFinalReport: dateFinalReport,
      };
    } else {
      return {
        DateFieldStart: null,
        DateFinalReport: null,
      };
    }
  };

  useEffect(() => {
    if (
      currentCostingProfile &&
      currentCostingProfile.WaveSpecs &&
      currentCostingProfile.WaveSpecs.length &&
      !Object.keys(waveBreakDown).length
    ) {
      // let methBreakdown = _.groupBy(currentCostingProfile.WaveSpecs, m => m.MethodologySpecId)
      let availableMeths = _.uniq(
        currentCostingProfile.WaveSpecs.map((ws) => ws.MethodologySpecId)
      );
      let methBreakdown = {};
      availableMeths.map((am) => {
        currentCostingProfile.CountrySpecs?.map((cs) => {
          cs.MethodologySpecs?.filter((mt) => !mt.NotApplicable).map((ms) => {
            if (ms.id == am) methBreakdown[am] = ms.Label;
          });
        });
      });
      setmethBreakdown(methBreakdown);
      currentCostingProfile.WaveSpecs.map((ws) => {
        ws.MethodologyLabel = methBreakdown[ws.MethodologySpecId];
      });
      let _waveBreakdown = _.groupBy(
        currentCostingProfile.WaveSpecs,
        (m) => m.WaveNumber
      );
      Object.keys(_waveBreakdown).map((w) => {
        _waveBreakdown[w] = _.uniqBy(
          _waveBreakdown[w],
          (t) => t.MethodologyLabel
        );
      });

      //console.log(_waveBreakdown, "_waveBreakdown")
      setWaveBreakDown(_waveBreakdown);
      let _currentwaveset = _.head(Object.values(_waveBreakdown));
      setCurrentWaveSet(_currentwaveset);

      dispatch(
        currentWaveSpecActions.selectWaveSpec({ ..._.head(_currentwaveset) })
      );
    }
  }, [currentCostingProfile]);

  const selectorHandler = (item) => {
    editableCostingProfile.WaveSpecs.map((ws) => {
      ws.MethodologyLabel = methBreakdown[ws.MethodologySpecId];
    });
    let _waveBreakdown = _.groupBy(
      editableCostingProfile.WaveSpecs,
      (m) => m.WaveNumber
    );
    Object.keys(_waveBreakdown).map((w) => {
      _waveBreakdown[w] = _.uniqBy(
        _waveBreakdown[w],
        (t) => t.MethodologyLabel
      );
    });
    // do nothing if clicked item is current item
    if (item === _.head(currentWaveSet)?.WaveNumber) return;
    else {
      setCurrentWaveSet(_.cloneDeep(_waveBreakdown[item]));
    }
    if (_waveBreakdown[item] && _waveBreakdown[item].length) {
      let wavesBreakDown = _.head(_waveBreakdown[item]);
      const msdPullDates = msdDateValue();

      if (msdPullDates.DateFieldStart || msdPullDates.DateFinalReport) {
        wavesBreakDown = {
          ...wavesBreakDown,
          DateFinalReport:
            wavesBreakDown.DateFinalReport ?? msdPullDates.DateFinalReport,
          DateFieldStart:
            wavesBreakDown.DateFieldStart ?? msdPullDates.DateFieldStart,
        };
      }

      dispatch(
        currentWaveSpecActions.selectWaveSpec(
          _.cloneDeep({ ...wavesBreakDown })
        )
      );
    }
  };
  const handleMethodlogyChange = (currentwaveId) => {
    let wavesBreakDown = _.head(
      currentWaveSet.filter((cws) => cws.id == currentwaveId)
    );
    const msdPullDates = msdDateValue();

    if (msdPullDates.DateFieldStart || msdPullDates.DateFinalReport) {
      wavesBreakDown = {
        ...wavesBreakDown,
        DateFinalReport:
          wavesBreakDown.DateFinalReport ?? msdPullDates.DateFinalReport,
        DateFieldStart:
          wavesBreakDown.DateFieldStart ?? msdPullDates.DateFieldStart,
      };
    }
    dispatch(currentWaveSpecActions.selectWaveSpec(wavesBreakDown));
  };

  const onChangeHandler = (eve, useCurrentSpec, isApi) => {
    let currentwavespec = {};
    if (useCurrentSpec) {
      currentwavespec = {
        ...useCurrentSpec,
        [eve.target.id]: eve.target.value,
      };
    } else {
      currentwavespec = {
        ...currentWaveSpec,
        [eve.target.id]: eve.target.value,
      };
    }

    //clearing finance notes when unchecked IncludeFinanceInComms
    if (eve.target.id == "IncludeFinanceInComms" && !eve.target.value)
      currentwavespec = { ...currentwavespec, NotesFinance: null };

    let wavespecs = [...waveSpecs];
    wavespecs = wavespecs.map((ws) => {
      if (ws.id === currentwavespec.id) {
        ws = currentwavespec;
      }
      ws.MethodologyLabel = methBreakdown[ws.MethodologySpecId];

      return { ...ws };
    });
    dispatch(setWaveSpecs([...wavespecs]));
    dispatch(
      currentWaveSpecActions.updateCurrentWaveSpec({ ...currentwavespec })
      //   update(currentWaveSpec, {
      //     $set: { ...currentwavespec },
      //   })
      // )
    );

    let _waveBreakdown = _.groupBy(wavespecs, (m) => m.WaveNumber);
    Object.keys(_waveBreakdown).map((w) => {
      _waveBreakdown[w] = _.uniqBy(
        _waveBreakdown[w],
        (t) => t.MethodologyLabel
      );
    });
    setCurrentWaveSet(_waveBreakdown[currentWaveSpec.WaveNumber]);

    let editablecostingprofile = { ...editableCostingProfile };
    editablecostingprofile.WaveSpecs = editablecostingprofile.WaveSpecs.map(
      (ws) => {
        if (ws.id == currentwavespec.id) {
          return { ...currentwavespec };
        } else {
          return { ...ws };
        }
      }
    );
    setEditableCosting(editablecostingprofile);
    if (isApi) {
      dispatch(
        currentCostingActions.saveCostingProfile(
          { ...editablecostingprofile },
          () => setWaveEditModal(false)
        )
      );
      dispatch(
        currentCostingActions.updateCostingProfiles(costingProfiles, {
          ...editablecostingprofile,
        })
      );
    }

    // dispatch(updateCurrentWaveSpec({...currentwavespec}))
  };

  const validateDatesNew = async () => {
    // await promiseTest().then
    // (res=>{toastr.info(res)})

    if (
      selectedMethodologies.every(
        (sm) =>
          scheduleDate[sm.Label] &&
          scheduleDate[sm.Label]?.QueDate &&
          scheduleDate[sm.Label]?.FWStart &&
          scheduleDate[sm.Label]?.FWEnd
      )
    ) {
      let data = Object.keys(scheduleDate).map((sd) => {
        return {
          QuestionnaireDate: scheduleDate[sd].QueDate,
          FWStartDate: scheduleDate[sd].FWStart,
          FWEndDate: scheduleDate[sd].FWEnd,
          CostingProfileId: currentCostingProfile.id,
          WaveNumber: "1",
          WaveSpecId: currentWaveSpec.id,
          SubmethodologyLabel: sd,
        };
      });
      console.log(data);
      let isError = false;
      await axios
        .post("deliveries/shcedule", data)
        .then((res) => {
          toastr.success(res.data.message);
        })
        .catch((err) => {
          isError = true;
          toastr.error(err.data.Message);
          return;
        });

      if (isError) {
        return;
      }

      let allFieldingCountries =
        currentCostingProfile.FieldingCountries?.split(",");
      let requiredCountryData = currentCostingProfile.CountrySpecs?.filter(
        (cs) => _.includes(allFieldingCountries, cs.CountryCode)
      );
      // setFieldingCountries(requiredCountryData)

      axios
        .post(
          `/utils/getbusinessdetails/${currentCostingProfile.Project.BusinessUnitId}`
        )
        .then((res) => {
          const currentBU = res.data.data;
          setCurrentBusinessUnit(currentBU);
          setUpdateCurrentBusinessUnit({ ...currentBU });
        })
        .catch((err) => {
          toastr.error(
            "Something went wrong",
            "Gettings Email recipients failed"
          );
        });
      axios
        .post(`/utils/getprimarymcp`, {
          CountryCodes: requiredCountryData.map((fc) => fc.CountryCode).join(),
          BusinessUnit: currentCostingProfile.Project.BusinessUnit,
        })
        .then((res) => {
          let fieldingcountries = [...requiredCountryData];
          fieldingcountries = requiredCountryData.map((fc) => {
            let reqCount = _.head(
              res.data.data?.filter((d) => d.Code == fc.CountryCode)
            );
            if (reqCount) {
              fc.PrimaryContactMCP = _.head(
                reqCount.BusinessUnits
              )?.PrimaryContactMCP;
            }
            return { ...fc };
          });
          setFieldingCountries(fieldingcountries);
          let updateFieldingCOuntries = [
            ...fieldingcountries.map((fc) => {
              return { ...fc };
            }),
          ];
          setUpdatedFieldingCountries(updateFieldingCOuntries);
          dispatch(
            currentWaveSpecActions.updateCurrentWaveSpec({
              ...currentWaveSpec,
              EWNFieldingCountries: updateFieldingCOuntries
                .map((fc) => fc.CountryCode)
                .join(),
            })
          );
        })
        .catch((err) => {
          toastr.error(
            "Something went wrong",
            "Getting Primary Contact MCP failed"
          );
        });
      // }
      // if (!allUsers.length) {
      axios
        .get("/users/internal/all")
        .then((response) => {
          setAllUsers(response.data?.users);
        })
        .catch((error) => {
          toastr.error("Users retrieving Failed", error.data.message);
        });
      // }
      /// Saving Waves =
      let costing = _.cloneDeep(editableCostingProfile);
      let methodologyWaveBreakdown = _.groupBy(
        costing.WaveSpecs,
        (m) => m.MethodologySpecId
      );
      costing.CountrySpecs = costing.CountrySpecs?.map((cs) => {
        cs.MethodologySpecs = cs.MethodologySpecs?.map((ms) => {
          ms.WaveSpecs = methodologyWaveBreakdown[ms.id];
          return { ...ms };
        });
        return { ...cs };
      });

      dispatch(appActions.recordLoadStart());
      dispatch(appActions.localPageLoadStart());

      await axios
        .put("/waves/savewaves", { ...costing })
        .then(async (res) => {
          for (let meth of Object.keys(scheduleDate)) {
            setCount((prev) => prev + 1);
            console.log(
              currentWaveSet.find((sb) => sb.MethodologyLabel == meth)?.id
            );
            let currentWave = currentWaveSet.find(
              (sb) => sb.MethodologyLabel == meth
            );
            handleMethodlogyChange(currentWave.id);

            await axios
              .post(`/deliveries/${currentWave.id}/deliveryspec`)
              .then(async (response) => {
                if (!currentWave.WaveFolderId) {
                  toastr.info(
                    "Please wait while the wave folder and project box are being created..."
                  );

                  await axios
                    .post(
                      `/utils/folders/waves/${currentWave.id}/CurrentWave`,
                      { currentWaveSpec: { ...currentWave, WaveNumber: 1 } }
                    )
                    .then((res) => {
                      let id = currentWave.id;
                      let _currentwaveset = _.cloneDeep(currentWaveSet);
                      _currentwaveset = _currentwaveset.map((wave) => {
                        if (wave.id === id) {
                          return {
                            ...wave,
                            WaveFolderId: res.data.WaveFolderId,
                            ProjectBoxId: res.data.ProjectBoxId,
                          };
                        } else {
                          return wave;
                        }
                      });

                      setCurrentWaveSet(_currentwaveset);
                      dispatch(
                        currentWaveSpecActions.updateCurrentWaveSpec({
                          ...currentWave,
                          WaveFolderId: res.data.WaveFolderId,
                          ProjectBoxId: res.data.ProjectBoxId,
                        })
                      );
                      if (count == Object.keys(scheduleDate).length) {
                        setSaveModal(true);
                      }
                      toastr.success(`${res.data.message} ${meth}`);
                      let _wavespecs = waveSpecs.map((ws) => {
                        if (ws.id == currentWave.id) {
                          ws = {
                            ...currentWave,
                            WaveFolderId: res.data.WaveFolderId,
                            ProjectBoxId: res.data.ProjectBoxId,
                          };
                        }
                        return { ...ws };
                      });
                      updatespecs(_wavespecs);
                      setSaveModal(true);
                    })
                    .catch((err) => {
                      toastr.error("Wave Folder Creation Failed ", meth);
                    });
                } else {
                  setSaveModal(true);
                }
                if (response.status == 201)
                  toastr.success("Save Success", res.data.message);
              })
              .catch((error) => {
                dispatch(appActions.recordLoadEnd());
                dispatch(appActions.localPageLoadEnd());
                toastr.error("Delivery Save Failed", error.data.error);
                // //console.log(error, "error");
              });
          }
        })
        .catch((err) => {
          // //console.log("error", err);
          toastr.error("Waves Save Failed", err?.data.error);
          dispatch(appActions.recordLoadEnd());
          dispatch(appActions.localPageLoadEnd());
        });

      dispatch(appActions.recordLoadEnd());
      dispatch(appActions.localPageLoadEnd());
    } else {
      toastr.error("Please Fill All Dates");
    }
  };
  const validateDates = () => {
    let isInvalid = false;
    let fields = { ...Fields };
    Object.keys(fields).map((field) => {
      if (
        (fields[field].DisablingVal &&
          !currentWaveSpec[fields[field].DisablingVal]) ||
        !fields[field].DisablingVal
      ) {
        if (currentWaveSpec.OpsResourcesData) {
          if (fields[field].RequiredValue) {
            let currentOpsvalue =
              currentWaveSpec.OpsResourcesData[fields[field].RequiredValue];
            if (
              (currentOpsvalue &&
                typeof currentOpsvalue == "string" &&
                currentOpsvalue.toLowerCase() == "yes") ||
              typeof currentOpsvalue == "boolean"
            ) {
              fields[field].DateFields = fields[field].DateFields.map((df) => {
                if (df.isRequired && !currentWaveSpec[df.id]) {
                  df.isInvalid = true;
                  isInvalid = true;
                  fields[field].isInvalid = true;
                } else df.isInvalid = false;
                return { ...df };
              });
              if (fields[field].PriorFields)
                fields[field].PriorFields = fields[field].PriorFields.map(
                  (df) => {
                    if (df.isRequired && !currentWaveSpec[df.id]) {
                      df.isInvalid = true;
                      isInvalid = true;
                      fields[field].isInvalid = true;
                    } else df.isInvalid = false;
                    return { ...df };
                  }
                );
            }
          } else {
            fields[field].DateFields = fields[field].DateFields.map((df) => {
              if (df.isRequired && !currentWaveSpec[df.id]) {
                df.isInvalid = true;
                isInvalid = true;
                fields[field].isInvalid = true;
              } else df.isInvalid = false;

              return { ...df };
            });
          }
        }
      }
      fields[field] = { ...fields[field] };
    });
    updateFields({ ...fields });
    if (!isInvalid) {
      Object.keys(fields).map((field) => {
        fields[field].isInvalid = false;
      });
      updateFields({ ...fields });

      // if (!fieldingCountries.length) {
      let allFieldingCountries =
        currentCostingProfile.FieldingCountries?.split(",");
      let requiredCountryData = currentCostingProfile.CountrySpecs?.filter(
        (cs) => _.includes(allFieldingCountries, cs.CountryCode)
      );
      // setFieldingCountries(requiredCountryData)

      axios
        .post(
          `/utils/getbusinessdetails/${currentCostingProfile.Project.BusinessUnitId}`
        )
        .then((res) => {
          const currentBU = res.data.data;
          setCurrentBusinessUnit(currentBU);
          setUpdateCurrentBusinessUnit({ ...currentBU });
        })
        .catch((err) => {
          toastr.error(
            "Something went wrong",
            "Gettings Email recipients failed"
          );
        });
      axios
        .post(`/utils/getprimarymcp`, {
          CountryCodes: requiredCountryData.map((fc) => fc.CountryCode).join(),
          BusinessUnit: currentCostingProfile.Project.BusinessUnit,
        })
        .then((res) => {
          let fieldingcountries = [...requiredCountryData];
          fieldingcountries = requiredCountryData.map((fc) => {
            let reqCount = _.head(
              res.data.data?.filter((d) => d.Code == fc.CountryCode)
            );
            if (reqCount) {
              fc.PrimaryContactMCP = _.head(
                reqCount.BusinessUnits
              )?.PrimaryContactMCP;
            }
            return { ...fc };
          });
          setFieldingCountries(fieldingcountries);
          let updateFieldingCOuntries = [
            ...fieldingcountries.map((fc) => {
              return { ...fc };
            }),
          ];
          setUpdatedFieldingCountries(updateFieldingCOuntries);
          dispatch(
            currentWaveSpecActions.updateCurrentWaveSpec({
              ...currentWaveSpec,
              EWNFieldingCountries: updateFieldingCOuntries
                .map((fc) => fc.CountryCode)
                .join(),
            })
          );
        })
        .catch((err) => {
          toastr.error(
            "Something went wrong",
            "Getting Primary Contact MCP failed"
          );
        });
      // }
      // if (!allUsers.length) {
      axios
        .get("/users/internal/all")
        .then((response) => {
          setAllUsers(response.data?.users);
        })
        .catch((error) => {
          toastr.error("Users retrieving Failed", error.data.message);
        });
      // }
      // We have to do this Operation for All the Methodologies
      dispatch(
        currentCostingActions.saveSchedule(editableCostingProfile, () => {
          if (!currentWaveSpec.WaveFolderId) {
            toastr.info(
              "Please wait while the wave folder and project box are being created..."
            );

            axios
              .post(`/utils/folders/waves/${currentWaveSpec.id}/CurrentWave`, {
                currentWaveSpec,
              })
              .then((res) => {
                dispatch(
                  currentWaveSpecActions.updateCurrentWaveSpec({
                    ...currentWaveSpec,
                    WaveFolderId: res.data.WaveFolderId,
                    ProjectBoxId: res.data.ProjectBoxId,
                  })
                );
                setSaveModal(true);
                toastr.success(res.data.message);
                let _wavespecs = waveSpecs.map((ws) => {
                  if (ws.id == currentWaveSpec.id) {
                    ws = {
                      ...currentWaveSpec,
                      WaveFolderId: res.data.WaveFolderId,
                      ProjectBoxId: res.data.ProjectBoxId,
                    };
                  }
                  return { ...ws };
                });
                updatespecs(_wavespecs);
              })
              .catch((err) => {
                toastr.error("Wave Folder Creation Failed");
              });
          } else {
            setSaveModal(true);
          }
        })
      );

      //copy to other waves
      let _wavebreak = _.groupBy(waveSpecs, (w) => w.WaveNumber);
      editableCostingProfile.WaveSpecs = editableCostingProfile.WaveSpecs.map(
        (ws) => {
          let requiredSet = _wavebreak[ws.WaveNumber];
          let requiredWave = _.head(
            requiredSet.filter(
              (rws) =>
                rws.MethodologyLabel == ws.MethodologyLabel && ws.id != rws.id
            )
          );
          if (requiredWave?.DateWaveCommissioned) {
            allFields.map((af) => {
              ws[af] = requiredWave[af];
            });
          }
          return { ...ws };
        }
      );

      setErrorMessage("");
    } else {
      setErrorMessage("Please review your form inputs and try again.");
    }
  };
  const updateWaveSpecs = () => {
    dispatch(localPageLoadStart());
    axios
      .put("/waves/updatecurrentwave", currentWaveSpec)
      .then((res) => {
        if (currentWaveSpec.IncludeFinanceInComms) {
          axios
            .post("/utils/mail/" + currentWaveSpec.id + "/finance/schedule")
            .then((res) => {
              //console.log(res);
            })
            .catch((err) => {
              //console.log(err);
            });
        }
        axios
          .post("/utils/mail/" + currentWaveSpec.id + "/ewn/all", {
            params: {
              currentBusinessUnit,
              showDetailedRecipients,
              fieldingCountries,
            },
          })
          .then((res) => {
            setShowDetailedRecipients(false);
            setEwnPopupSections({});
            //console.log(res);
            toastr.success("Mail Sending Success", res.data.message);
          })
          .catch((err) => {
            //console.log(err);
            toastr.error("Mail Sending Failed", err?.data?.message);
          });
        setSaveModal(false);
        dispatch(localPageLoadEnd());
      })
      .catch((err) => {
        dispatch(localPageLoadEnd());
      });
  };

  const updateWaveSpecsNew = async () => {
    dispatch(appActions.localPageLoadStart());
    dispatch(appActions.recordLoadStart());
    // let userTimeZoneOffset =  0;
    // userTimeZoneOffset = new Date().getTimezoneOffset();
    // console.log("offset", userTimeZoneOffset);
    for (let meth of Object.keys(scheduleDate)) {
      setCount1((prev) => prev + 1);
      let currentWave = currentWaveSet.find(
        (cw) => cw.MethodologyLabel == meth
      );
      await axios
        .put("/waves/updatecurrentwave", {
          ...currentWave,
          DateWaveCommissioned: new Date(),
          DateFieldStart: scheduleDate[meth]?.FWStart,
          DateFieldEnd: scheduleDate[meth]?.FWEnd,
          DateFinalQuestionnaire: scheduleDate[meth]?.QueDate,
        })
        .then((res) => {
          if (currentWave.IncludeFinanceInComms) {
            axios
              .post("/utils/mail/" + currentWave.id + "/finance/schedule")
              .then((res) => {
                //console.log(res);
              })
              .catch((err) => {
                //console.log(err);
              });
          }
          axios
            .post("/utils/mail/" + currentWave.id + "/ewn/all", {
              params: {
                currentBusinessUnit,
                showDetailedRecipients,
                fieldingCountries,
              },
            })
            .then((res) => {
              setShowDetailedRecipients(false);
              setEwnPopupSections({});
              //console.log(res);
              toastr.success(
                "Mail Sending Success for",
                meth,
                " ",
                res.data.message
              );
              // setConfirmCommission(true)
              if (count1 == Object.keys(scheduleDate).length) {
                setSaveModal(false);
                dispatch(appActions.localPageLoadEnd());
                dispatch(appActions.recordLoadEnd());
              }
            })
            .catch((err) => {
              //console.log(err);
              toastr.error("Mail Sending Failed", err?.data?.message);
            });
        })
        .catch((err) => {
          dispatch(localPageLoadEnd());
        });
    }
    dispatch(
      currentCostingActions.commissionCosting(() => {
        console.log("callback called");
        let waveSpecId = currentCostingProfile.WaveSpecs
          ? _.head(currentCostingProfile.WaveSpecs)?.id
          : 0;
        if (waveSpecId && currentCostingProfile?.Project?.BusinessUnit == "CI")
          axios
            .post(`/utils/mail/${waveSpecId}/rvn/all`)
            .then((res) => {
              dispatch({
                type: currentProjectActions.UPDATE_NEW_PROJECT,
                newProject: { ...project, ProjectStatus: "3" },
              });
              // debugger
              dispatch(appActions.localPageLoadEnd());
              dispatch(appActions.recordLoadEnd());
              toastr.success(
                "Mail Sent Successfully",
                "Mail Sent to Revenue Controller"
              );
              // setCommissionOpen(pre => !pre)
            })
            .catch((error) => {
              toastr.error(
                "Mail Sending failed",
                "Mail Sending to Revenue Controller failed"
              );
            });

        // setIsOpen({ ModalPostCommission: isOpen.ModalCommissioning });
        // })

        history.push("/summary/" + currentCostingProfile.id, () => {});
        window.location.reload();
      })
    );
  };
  const updatespecs = (_wavespecs) => {
    dispatch(setWaveSpecs(_wavespecs));
    let editablecostingprofile = { ...editableCostingProfile };
    editablecostingprofile.WaveSpecs = _wavespecs;
    setEditableCosting(editablecostingprofile);
  };
  const canRenderValue = (ewnfields, ewnrecp, prop) => {
    let canRender = true;
    if (
      ewnfields[ewnrecp].properties[prop].DisablingVal &&
      currentWaveSpec[ewnfields[ewnrecp].properties[prop].DisablingVal]
    )
      canRender = false;
    if (
      ewnfields[ewnrecp].properties[prop].NotesField &&
      !currentWaveSpec[ewnfields[ewnrecp].properties[prop].NotesField]
    )
      canRender = false;

    if (ewnrecp == "ExternalEwnRecipients") {
      if (
        !currentWaveSpec.OpsResourcesData ||
        (currentWaveSpec.OpsResourcesData &&
          currentWaveSpec.OpsResourcesData[
            ewnfields[ewnrecp].properties[prop].resource
          ] != "External")
      ) {
        canRender = false;
      }
    }
    if (!updatedCurrentBusinessUnit[prop]) canRender = false;

    return canRender;
  };
  const fieldingCheckUpdate = (e, fc) => {
    let EWNFieldingCountries = currentWaveSpec.EWNFieldingCountries
      ? currentWaveSpec.EWNFieldingCountries.split(",")
      : [];
    if (e.target.checked) {
      if (!_.includes(EWNFieldingCountries, fc)) {
        EWNFieldingCountries.push(fc);
      }
    } else {
      EWNFieldingCountries = EWNFieldingCountries.filter((f) => f != fc);
    }
    dispatch(
      currentWaveSpecActions.updateCurrentWaveSpec({
        ...currentWaveSpec,
        EWNFieldingCountries: EWNFieldingCountries.join(),
      })
    );
    let _wavespecs = waveSpecs.map((ws) => {
      if (ws.id == currentWaveSpec.id) {
        ws = {
          ...currentWaveSpec,
          EWNFieldingCountries: EWNFieldingCountries.join(),
        };
      }
      return { ...ws };
    });
    updatespecs(_wavespecs);
  };
  const updateAdditionalEwnRecp = (additionalRecipients) => {
    dispatch(
      currentWaveSpecActions.updateCurrentWaveSpec({
        ...currentWaveSpec,
        EWNAdditionalRecipients: additionalRecipients,
      })
    );
    let _wavespecs = waveSpecs.map((ws) => {
      if (ws.id == currentWaveSpec.id) {
        ws = {
          ...currentWaveSpec,
          EWNAdditionalRecipients: additionalRecipients,
        };
      }
      return { ...ws };
    });
    updatespecs(_wavespecs);
  };
  const updateAdditionalEwnRecpNew = (additionalRecipients) => {
    dispatch(
      currentWaveSpecActions.updateCurrentWaveSpec({
        ...currentWaveSpec,
        EWNAdditionalRecipients: additionalRecipients,
      })
    );
    let _wavespecs = waveSpecs.map((ws) => {
      {
        ws = { ...ws, EWNAdditionalRecipients: additionalRecipients };
      }
      return { ...ws };
    });

    let _wavespecs1 = currentWaveSet.map((ws) => {
      {
        ws = { ...ws, EWNAdditionalRecipients: additionalRecipients };
      }
      return { ...ws };
    });
    setCurrentWaveSet(_wavespecs1);
    updatespecs(_wavespecs);
  };

  return (
    <Layout
      profileStatusToDisplay={getLabel(
        "CostingStatusOptions",
        currentCostingProfile.ProfileStatus
      )}
      projectStatusToDisplay={getLabel(
        "ProjectStatusOptions",
        currentCostingProfile.Project?.ProjectStatus
      )}
    >
      {localPageload || recordloading ? <div id="pageCoverSpin"></div> : null}
      <>
        <Container>
          <ProjectDeliveryDates />
        </Container>

        {selectedMethodologies.length && (
          <Container>
            <Card className="ml-2 mr-2 mb-2">
              <CardHeader>
                <Row>
                  <Col xs="9">
                    <CardTitle className="text-uppercase">
                      Sub Methodology Level Schedule
                    </CardTitle>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <table className="table table-stripped table-bordered">
                  <tr>
                    <th>Sub Methodologies</th>
                    <th>
                      Final Questionnaire Delivery Date
                    </th>
                    <th>
                      
                        Planned FW Start Date
                    
                   
                    </th>
                    <th>
                      Planned FW End Date
                    </th>
                  </tr>
                  {selectedMethodologies.map((sm) => (
                    <tr>
                      <td>
                        <Label>
                          {sm.Label}
                        </Label>
                      </td>
                      <td>
                        {" "}
                        <DatePicker
                          // disabled={
                          //     df.DisablingVal||disableforCS
                          // }
                          // locale="en-GB"
                          className="form-control"
                          selected={
                            scheduleDate && scheduleDate[sm.Label]?.QueDate
                              ? scheduleDate[sm.Label]?.QueDate
                              : ""
                          }
                          onChange={(e) =>
                            onChangeHandlerQueDates(e, "QueDate", sm.Label)
                          }
                          dateFormat="dd-MM-yyyy"
                          placeholderText="dd-mm-yyyy"
                        />
                      </td>
                      <td>
                        <DatePicker
                          // disabled={
                          //     df.DisablingVal||disableforCS
                          // }
                          // locale="en-GB"
                          className="form-control"
                          selected={
                            scheduleDate && scheduleDate[sm.Label]?.FWStart
                              ? scheduleDate[sm.Label]?.FWStart
                              : ""
                          }
                          onChange={(e) =>
                            onChangeHandlerFWStart(e, "FWStart", sm.Label)
                          }
                          dateFormat="dd-MM-yyyy"
                          placeholderText="dd-mm-yyyy"
                        />
                      </td>
                      <td>
                        <DatePicker
                          // disabled={
                          //     df.DisablingVal||disableforCS
                          // }
                          // locale="en-GB"
                          className="form-control"
                          selected={
                            scheduleDate && scheduleDate[sm.Label]?.FWEnd
                              ? scheduleDate[sm.Label]?.FWEnd
                              : ""
                          }
                          onChange={(e) =>
                            onChangeHandlerFwEnd(e, "FWEnd", sm.Label)
                          }
                          dateFormat="dd-MM-yyyy"
                          placeholderText="dd-mm-yyyy"
                        />
                      </td>
                    </tr>
                  ))}
                </table>
              </CardBody>
            </Card>
          </Container>
        )}
      </>

      {errorMessage ? (
        <Container className="text-center mt-4">
          <h5 className="error">
            <strong>{errorMessage}</strong>
          </h5>
        </Container>
      ) : null}
      <Container className="d-flex mt-4 mr-2 justify-content-end">
        {currentCostingProfile?.ProfileStatus != "6" ? (
          <Button
            disabled={app.recordloading}
            className="float-right mr-2"
            color="primary"
            onClick={() => {
              validateDatesNew();
              // setSaveModal(true);
            }}
          >
            Go To EWN
          </Button>
        ) : (
          <Button
            disabled={app.recordloading}
            className="float-right"
            color="primary"
            onClick={() => {
              validateDates();
              // setSaveModal(true);
            }}
          >
            Save
          </Button>
        )}
      </Container>
      <Container className="d-flex justify-content-center">
        <RecordsBadge />
      </Container>
      <Modal
        isOpen={isSaveModal}
        toggle={() => setSaveModal(false)}
        size="lg"
        centered={true}
      >
        <ModalHeader toggle={() => setSaveModal(false)}>
          {/* <img src={logo} className="modallogo" /> */}
        </ModalHeader>

        <div>
          <span className="modaltitle modalEWN">
            Early Warning Notification
          </span>
        </div>
        <ModalBody>
          <p>Your changed have saved successfully.</p>
          <p>
            <strong>Would you like to send out an updated EWN?</strong>
          </p>
          <p>
            An email notification will be sent out to all relevant operations
            teams if the dates are provided for the team.
          </p>
          <p>
            <em>
              Please Note: You are also required to include finance in the
              notifications{" "}
              <strong>
                if there are any changes in fieldwork or reports due dates.
              </strong>{" "}
              A seperate notification will be sent to the Finance Team contacts.
            </em>
          </p>
          {showDetailedRecipients ? (
            <>
              {fieldingCountries.length &&
              currentCostingProfile.IsMultiCountry &&
              _.includes(
                allowedBusinessUnits,
                currentCostingProfile.Project.BusinessUnit
              ) ? (
                <>
                  {" "}
                  <Card>
                    <CardHeader
                      onClick={() =>
                        setEwnPopupSections({
                          ...ewnPopupSections,
                          mcpfielding: !ewnPopupSections.mcpfielding,
                        })
                      }
                    >
                      <Row>
                        <Col xs="11">
                          <CardTitle className="text-uppercase">
                            MCP Fielding Countries Recipients
                          </CardTitle>
                        </Col>
                        <Col xs="1">
                          <FontAwesomeIcon
                            icon={
                              !ewnPopupSections["mcpfielding"]
                                ? faChevronDown
                                : faChevronUp
                            }
                            fixedWidth
                            className="mb-0 mr-3 medium"
                          />
                        </Col>
                      </Row>
                    </CardHeader>
                    <Collapse isOpen={ewnPopupSections["mcpfielding"]}>
                      <CardBody>
                        <table className="table">
                          <thead>
                            <tr>
                              <th></th>
                              <th>Fielding Country</th>
                              <th>Primary Contact</th>
                            </tr>
                          </thead>
                          {fieldingCountries.map((fc) => {
                            let currentOptions = _.head(
                              updatedFieldingCountries.filter(
                                (ufc) => fc.CountryCode == ufc.CountryCode
                              )
                            )?.PrimaryContactMCP;
                            return (
                              <tr>
                                <td>
                                  <input
                                    type="checkbox"
                                    defaultChecked={_.includes(
                                      currentWaveSpec.EWNFieldingCountries?.split(
                                        ","
                                      ),
                                      fc.CountryCode
                                    )}
                                    onChange={(e) => {
                                      fieldingCheckUpdate(e, fc.CountryCode);
                                    }}
                                    disabled={!canEditSchedule}
                                  />
                                </td>
                                <td>{fc.CountryName}</td>
                                <td>
                                  <Select
                                    isMulti
                                    options={currentOptions
                                      ?.split(",")
                                      ?.map((pcm) => {
                                        return { value: pcm, label: pcm };
                                      })}
                                    isDisabled={!canEditSchedule}
                                    defaultValue={fc.PrimaryContactMCP?.split(
                                      ","
                                    )?.map((pcm) => {
                                      return { value: pcm, label: pcm };
                                    })}
                                    onChange={(val) => {
                                      let fieldingcountries = [
                                        ...fieldingCountries,
                                      ];
                                      fieldingcountries = fieldingcountries.map(
                                        (fieldcount) => {
                                          if (
                                            fieldcount.CountryCode ==
                                            fc.CountryCode
                                          )
                                            fieldcount.PrimaryContactMCP = val
                                              ? val.join()
                                              : "";

                                          return { ...fieldcount };
                                        }
                                      );
                                      setFieldingCountries(fieldingcountries);
                                    }}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </table>
                      </CardBody>
                    </Collapse>
                  </Card>
                </>
              ) : null}
              {Object.keys(ewnfields).map((ewnrecp) => {
                return (
                  <Card>
                    <CardHeader
                      onClick={() =>
                        setEwnPopupSections({
                          ...ewnPopupSections,
                          [ewnrecp]: !ewnPopupSections[ewnrecp],
                        })
                      }
                    >
                      <Row>
                        <Col xs="11">
                          <CardTitle className="text-uppercase">
                            {ewnfields[ewnrecp].title}
                          </CardTitle>
                        </Col>
                        <Col xs="1">
                          <FontAwesomeIcon
                            icon={
                              !ewnPopupSections[ewnrecp]
                                ? faChevronDown
                                : faChevronUp
                            }
                            fixedWidth
                            className="mb-0 mr-3 medium"
                          />
                        </Col>
                      </Row>
                    </CardHeader>
                    <Collapse isOpen={ewnPopupSections[ewnrecp]}>
                      <CardBody>
                        <Row>
                          <table className="table">
                            <tbody>
                              {Object.keys(ewnfields[ewnrecp].properties).map(
                                (prop) => {
                                  return canRenderValue(
                                    ewnfields,
                                    ewnrecp,
                                    prop
                                  ) ? (
                                    <tr>
                                      <td>
                                        {
                                          ewnfields[ewnrecp].properties[prop]
                                            .label
                                        }
                                      </td>
                                      <td>
                                        {updatedCurrentBusinessUnit[prop]
                                          ? updatedCurrentBusinessUnit[prop]
                                              .split(",")
                                              .map((ucb, index) => {
                                                return (
                                                  <>
                                                    <input
                                                      type="checkbox"
                                                      className="mr-2"
                                                      disabled={
                                                        !canEditSchedule
                                                      }
                                                      id={`check_${ewnrecp}${ewnfields[ewnrecp].properties[prop].label}${index}`}
                                                      defaultChecked={_.includes(
                                                        currentBusinessUnit[
                                                          prop
                                                        ]?.split(","),
                                                        ucb
                                                      )}
                                                      onChange={(e) => {
                                                        let businessunit = {
                                                          ...currentBusinessUnit,
                                                        };
                                                        if (e.target.checked)
                                                          businessunit[prop] =
                                                            businessunit[prop]
                                                              ? [
                                                                  businessunit[
                                                                    prop
                                                                  ],
                                                                  ucb,
                                                                ].join()
                                                              : ucb;
                                                        else
                                                          businessunit[prop] =
                                                            businessunit[prop]
                                                              ? businessunit[
                                                                  prop
                                                                ]
                                                                  .split(",")
                                                                  .filter(
                                                                    (p) =>
                                                                      p != ucb
                                                                  )
                                                                  .join()
                                                              : "";

                                                        setCurrentBusinessUnit(
                                                          businessunit
                                                        );
                                                      }}
                                                    />
                                                    <label
                                                      className="pointer"
                                                      for={`check_${ewnrecp}${ewnfields[ewnrecp].properties[prop].label}${index}`}
                                                    >
                                                      {ucb}
                                                    </label>
                                                    <br></br>
                                                  </>
                                                );
                                              })
                                          : "-"}
                                      </td>
                                    </tr>
                                  ) : null;
                                }
                              )}
                            </tbody>
                          </table>
                        </Row>
                      </CardBody>
                    </Collapse>
                  </Card>
                );
              })}

              <Card>
                <CardHeader
                  onClick={() =>
                    setEwnPopupSections({
                      ...ewnPopupSections,
                      additionalRecipients:
                        !ewnPopupSections.additionalRecipients,
                    })
                  }
                >
                  <Row>
                    <Col xs="11">
                      <CardTitle className="text-uppercase">
                        Enter Additional EWN Recipients
                      </CardTitle>
                    </Col>
                    <Col xs="1">
                      <FontAwesomeIcon
                        icon={
                          !ewnPopupSections["additionalRecipients"]
                            ? faChevronDown
                            : faChevronUp
                        }
                        fixedWidth
                        className="mb-0 mr-3 medium"
                      />
                    </Col>
                  </Row>
                </CardHeader>
                <Collapse isOpen={ewnPopupSections["additionalRecipients"]}>
                  <CardBody>
                    <CreatableSelect
                      isMulti
                      defaultValue={currentWaveSpec.EWNAdditionalRecipients?.split(
                        ","
                      ).map((val) => {
                        return { label: val, value: val };
                      })}
                      onChange={(val) => {
                        updateAdditionalEwnRecpNew(
                          val ? val.map((v) => v.value).join() : null
                        );
                      }}
                      options={allUsers.map((val) => {
                        return {
                          value: val.Email,
                          label: val.Email,
                        };
                      })}
                    />
                  </CardBody>
                </Collapse>
              </Card>
            </>
          ) : null}

          {currentWaveSpec.IncludeFinanceInComms ? (
            <Input
              type="textarea"
              id="NotesFinance"
              disabled={!canEditSchedule}
              //className="form-control"
              onChange={(eve) => onChangeHandler(eve)}
              value={
                currentWaveSpec.NotesFinance ? currentWaveSpec.NotesFinance : ""
              }
              rows="3"
              placeholder="Please provide any relevant notes for Finance Team here..."
            />
          ) : null}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <div className="d-flex">
            <CustomInput
              type="checkbox"
              id="IncludeProjectTeamInComms"
              label="Include Other Client Service Contacts"
              defaultChecked={currentWaveSpec.IncludeProjectTeamInComms}
              onChange={(eve) =>
                onChangeHandler({
                  target: {
                    ...eve.target,
                    value: eve.target.checked,
                    id: "IncludeProjectTeamInComms",
                  },
                })
              }
              className="mr-2"
            />
            <CustomInput
              type="checkbox"
              id="IncludeFinance"
              label="Include Finance"
              defaultChecked={currentWaveSpec.IncludeFinanceInComms}
              onChange={(eve) =>
                onChangeHandler({
                  target: {
                    ...eve.target,
                    value: eve.target.checked,
                    id: "IncludeFinanceInComms",
                  },
                })
              }
            />
          </div>
          <div className="d-flex">
            <Button
              onClick={() => setShowDetailedRecipients(!showDetailedRecipients)}
            >
              {showDetailedRecipients ? "Hide Recipients" : "Edit Recipients"}
            </Button>
            <Button
              className="ml-2"
              color="secondary"
              disabled={app.recordloading}
              onClick={() => setSaveModal(false)}
            >
              Cancel
            </Button>
            {currentCostingProfile?.ProfileStatus == "6" ? (
              <Button
                className="ml-2"
                color="primary"
                disabled={app.recordloading}
                onClick={() => updateWaveSpecs()}
              >
                Send EWN
              </Button>
            ) : (
              <Button
                className="ml-2"
                color="primary"
                disabled={app.recordloading}
                onClick={() => updateWaveSpecsNew()}
              >
                Send EWN and Commission
              </Button>
            )}
          </div>
        </ModalFooter>
      </Modal>
      {/* <CommissioningConfirmModal confirmCommission={confirmCommission} setConfirmCommission={setConfirmCommission} /> */}

      <Modal
        isOpen={waveEditModal}
        toggle={() => setWaveEditModal(!waveEditModal)}
        centered
      >
        <ModalHeader toggle={() => setWaveEditModal(!waveEditModal)}>
          Edit Current Wave Name
        </ModalHeader>
        <ModalBody>
          <Input
            defaultValue={
              currentWaveSpec.WaveName ? currentWaveSpec.WaveName : ""
            }
            disabled={!canEditSchedule}
            id="WaveName"
            onChange={(e) => setEditableWavename(e.target.value)}
            placeholder="Enter Wave Name"
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            disabled={app.recordloading}
            onClick={(e) => {
              onChangeHandler(
                { target: { id: "WaveName", value: editableWaveName } },
                null,
                true
              );
            }}
          >
            Update
          </Button>
          <Button
            color="secondary"
            disabled={app.recordloading}
            onClick={() => setWaveEditModal(false)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </Layout>
  );
};

export default ScheduleDatesEWN;
