import React, {useState} from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { getLabel, getSingleOptions } from "../utils/codeLabels";
import { toggleSidebar } from "../redux/actions/sidebarActions";
import * as userActions from "../redux/actions/userActions";
import { useHistory } from "react-router-dom";
import ApprovalDelegate from "./ApprovalDelegate";
import {
  Row,
  Col,
  Collapse,
  Navbar,
  Nav,
  UncontrolledDropdown,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ListGroup,
  ListGroupItem,
  Form,
  InputGroup,
  Input,
  Button,
} from "reactstrap";

import {
  AlertCircle,
  Bell,
  BellOff,
  Home,
  MessageCircle,
  Settings,
  User,
  UserPlus,
} from "react-feather";

import avatar1 from "../assets/img/avatars/nielsen-logo.png";
import SelectWrapper from "./SelectWrapper/SelectWrapper";
import { setNewNotification } from "../redux/actions/notificationActions";
import { Badge as NewBadge, IconButton } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";

const NavbarDropdown = ({
  children,
  count,
  showBadge,
  header,
  footer,
  icon: Icon,
}) => (
  <UncontrolledDropdown nav inNavbar className="mr-2">
    <DropdownToggle nav className="nav-icon dropdown-toggle">
      <div className="position-relative">
        <Icon className="align-middle" size={18} />
        {showBadge ? <span className="indicator">{count}</span> : null}
      </div>
    </DropdownToggle>
    <DropdownMenu right className="dropdown-menu-lg py-0">
      <div className="dropdown-menu-header position-relative">
        {count} {header}
      </div>
      <ListGroup>{children}</ListGroup>
      <DropdownItem header className="dropdown-menu-footer">
        <span className="text-muted">{footer}</span>
      </DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);

const NavbarDropdownItem = ({ icon, title, description, time, spacing }) => (
  <ListGroupItem>
    <Row noGutters className="align-items-center">
      <Col xs={2}>{icon}</Col>
      <Col xs={10} className={spacing ? "pl-2" : null}>
        <div className="text-dark">{title}</div>
        <div className="text-muted small mt-1">{description}</div>
        <div className="text-muted small mt-1">{time}</div>
      </Col>
    </Row>
  </ListGroupItem>
);

const ProjectSearchBy = () => {
  return (
    <UncontrolledDropdown nav inNavbar className="mr-2">
      <DropdownToggle nav caret className="nav-flag">
        {BellOff}
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem>
          <span className="align-middle">English</span>
        </DropdownItem>
        <DropdownItem>
          <span className="align-middle">Spanish</span>
        </DropdownItem>
        <DropdownItem>
          <span className="align-middle">German</span>
        </DropdownItem>
        <DropdownItem>
          <span className="align-middle">Dutch</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
const searchBy = [
  { Label: "Email", Code: "email" },
  { Label: "First Name", Code: "firstName" },
  { Label: "Last Name", Code: "lastName" },
  { Label: "Comments", Code: "comments" },
];

const NavbarComponent = (props) => {
  const userRecord = JSON.parse(localStorage.getItem("userRecord"));
  const [isApprovalModalOpen, handleApprovalModal] = useState(false);
  const history = useHistory();
  const handleLogOut = () => {
    props.onLogOut();
    history.push("/auth/login");
  };

  const dispatch = useDispatch();
  const hasNewNotification = useSelector((state) => state.notificationReducer.NewNotification);
  const message = useSelector((state) => state.notificationReducer.Message);

  const handleClick = () => {
    // localStorage.removeItem("isAlertClosed");
    // props.setIsVisible(true);
    dispatch(setNewNotification(true));
  };
  
  return (
    <>
      {isApprovalModalOpen && <ApprovalDelegate isApprovalModal={isApprovalModalOpen} setApprovalModal = {handleApprovalModal}/>}
      <Navbar color="black" light expand fixed="top" sticky="top">
        <span
          className="sidebar-toggle d-flex mr-2"
          onClick={() => {
            props.onToggleSideBar();
          }}
        >
          <i className="hamburger align-self-center" />
        </span>
        <span className="h5-projectname" >
          {/* Will remove inline styles */}
          USER MANAGEMENT
        </span>

        <Collapse navbar>
          <Nav className="ml-auto" navbar>
            <InputGroup>
              {/* <UncontrolledButtonDropdown addonType="prepend">
                <DropdownToggle caret>Project ID</DropdownToggle>
                <DropdownMenu>
                  <DropdownItem>Project Name</DropdownItem>
                  <DropdownItem>Another Action</DropdownItem>
                  <DropdownItem>Something else here</DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem>Seperated Link</DropdownItem>
                  {getSingleOptions(props.codeLabels.TrackingFrequencyOptions)}
                </DropdownMenu>
              </UncontrolledButtonDropdown> */}
              <SelectWrapper
                type="select"
                id="exampleCustomSelect"
                className="width-150"
                name="customSelect"
                defaultValue={searchBy[0].Code}
                onChange={(e) => props.onSearchBarTypeChange(e.target.value)}
              >
                {searchBy.map((opt, index) => {
                  return (
                    <option key={index} value={opt.Code}>
                      {opt.Label}
                    </option>
                  );
                })}
                {/* <option value="">Project ID</option>
                {getSingleOptions(props.codeLabels.SearchBy)} */}
              </SelectWrapper>
              <Input
                type="text"
                placeholder="Search users..."
                aria-label="Search"
                className="mr-sm-2"
                value={props.searchCharactors}
                onChange={(e) => props.onSearchBarValueChange(e.target.value)}
                onKeyUp={() => props.handleSearch()}
              />
            </InputGroup>
          </Nav>
        </Collapse>

        {/* <Form inline>
          <Input
            type="text"
            placeholder="Search projects..."
            aria-label="Search"
            className="form-control-no-border mr-sm-2"
          />
        </Form> */}
        {/* <UncontrolledButtonDropdown>
          <DropdownToggle>Advanced Search</DropdownToggle>
          <DropdownMenu>
            <DropdownItem>Project Name</DropdownItem>
            <DropdownItem>Another Action</DropdownItem>
            <DropdownItem>Something else here</DropdownItem>
            <DropdownItem divider />
            <DropdownItem>Seperated Link</DropdownItem>
          </DropdownMenu>
        </UncontrolledButtonDropdown> */}
        <Button  className="header-but"
          onClick={() => {
            props.handleOpen(true);
          }}
        >
          Advanced Search
        </Button>
        {props.clear && (
          <Button  className="header-but"
            style={{ margin: "10px" }}
            onClick={() => {
              props.handleClear();
            }}
          >
            Clear Filter
          </Button>
        )}
        <Collapse navbar>
          <Nav className="ml-auto" navbar>
            {/* <UncontrolledDropdown nav inNavbar className="mr-2">
              <DropdownToggle nav caret className="nav-flag">
                <img src={usFlag} alt="English" />
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem>
                  <span className="align-middle">English</span>
                </DropdownItem>
                <DropdownItem>
                  <span className="align-middle">Spanish</span>
                </DropdownItem>
                <DropdownItem>
                  <span className="align-middle">German</span>
                </DropdownItem>
                <DropdownItem>
                  <span className="align-middle">Dutch</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown> */}
            {/* <ProjectSearchBy /> */}
            {/* <NavbarDropdown
              header="New Messages"
              footer="Show all messages"
              icon={MessageCircle}
              count={messages.length}
              showBadge
            >
              {messages.map((item, key) => {
                return (
                  <NavbarDropdownItem
                    key={key}
                    icon={
                      <img
                        className="avatar img-fluid rounded-circle"
                        src={item.avatar}
                        alt={item.name}
                      />
                    }
                    title={item.name}
                    description={item.description}
                    time={item.time}
                    spacing
                  />
                );
              })}
            </NavbarDropdown>

            <NavbarDropdown
              header="New Notifications"
              footer="Show all notifications"
              icon={BellOff}
              count={notifications.length}
            >
              {notifications.map((item, key) => {
                let icon = <Bell size={18} className="text-warning" />;

                if (item.type === "important") {
                  icon = <AlertCircle size={18} className="text-danger" />;
                }

                if (item.type === "login") {
                  icon = <Home size={18} className="text-primary" />;
                }

                if (item.type === "request") {
                  icon = <UserPlus size={18} className="text-success" />;
                }

                return (
                  <NavbarDropdownItem
                    key={key}
                    icon={icon}
                    title={item.title}
                    description={item.description}
                    time={item.time}
                  />
                );
              })}
            </NavbarDropdown> */}

            <IconButton color="inherit" onClick={handleClick}>
              <NewBadge
                color="secondary"
                variant={hasNewNotification || message ? "dot" : "standard"}
                invisible={!message}
              >
                <FontAwesomeIcon
                  className="ic-hover pointer"
                  icon={faBell}
                  color="white"
                  title="Alert"
                  size="lg"
                />
              </NewBadge>
            </IconButton>

            <UncontrolledDropdown nav inNavbar>
              {/* <span className="d-inline-block d-sm-none">
                <DropdownToggle nav caret>
                  <Settings size={18} className="align-middle" />
                </DropdownToggle>
              </span> */}
              <span className="d-none d-sm-inline-block">
                <DropdownToggle nav caret>
                  {/* <img
                    src={avatar1}
                    className="avatar img-fluid rounded-circle mr-1"
                    alt="User Profile Picture"
                  /> */}
                  <span className="text-dark">
                    {props.userRecord.FirstName} {props.userRecord.LastName}
                  </span>
                </DropdownToggle>
                <a style={{marginLeft :"10px"}}
                  className="select-link text-white support"
                  onClick={() => window.open(process.env.REACT_APP_SUPPORT_FORM)}
                >
                  Need Support? 
                </a>
              </span>
              <DropdownMenu right>
                {userRecord.IsCostingApprover && <><DropdownItem 
                onClick={e => handleApprovalModal(!isApprovalModalOpen)}>
                  User Profile
                </DropdownItem>

                <DropdownItem divider /> </>}

                <DropdownItem
                  onClick={(e) => {
                    handleLogOut();
                  }}
                >
                  Sign out
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
    </>
  );
};

const mapStateToProps = (state) => ({
  app: state.app,
  userRecord: state.user.userRecord,
  codeLabels: state.codeLabels,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onLogOut: () => {
      dispatch(userActions.logout());
    },
    onToggleSideBar: () => {
      dispatch(toggleSidebar());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavbarComponent);
