import {
    faChevronRight,
    faChevronDown,
    faChevronUp,
    faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import update from 'immutability-helper';
import classnames from "classnames";
import './common.css';
import _ from "lodash";
import React, { useRef, useEffect, useState } from "react";
import jspreadsheet from "jspreadsheet-ce";
import "jspreadsheet-ce/dist/jspreadsheet.css";
import Spreadsheet from "x-data-spreadsheet";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './spreadsheet.css';
import {
    Col, Row, Card,
    CustomInput,
    CardBody,
    CardTitle,
    CardHeader, FormFeedback, FormGroup, Nav, NavItem, NavLink, TabContent, TabPane
    , Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip, UncontrolledCollapse,
    Table,
    Container,
    Input,
} from "reactstrap";

import './common.css';
import Select from "react-select";
import {
    AvForm,
    AvField,
    AvRadioGroup,
    AvInput,
    AvRadio
} from "availity-reactstrap-validation";

const DataSheet =({spreadSheetOption,finalProcessedData})=>{

    var xs = useRef();
    

    useEffect(() =>
    {
        
        //ReactDOM.unmountComponentAtNode(xs);
        //xs.splice();
        if(!document.getElementsByClassName("x-spreadsheet").length)
        {
        xs = new Spreadsheet("#x-spreadsheet-demo", {
            showToolbar: true,
            showGrid: true,
            showBottomBar: true,
         
          })
            .loadData(spreadSheetOption).change((data)=>
            {
              //saveDraft(data)
            //  //console.log('>>>', data);
              //console.log('>>>', xs.getData());
            
            })
            xs.on('cell-edited', (text, ri, ci) => {
              //console.log('text:', text, ', ri: ', ri, ', ci:', ci);
              
              
            });
  
        }   
    }, [spreadSheetOption]);   

   
    return(
        
        <>
       
       <div ref={xs} />
        </>)
}
export default DataSheet;