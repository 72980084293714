import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import TextField from "@material-ui/core/TextField";
// import Layout from "../../layouts/RequestsBoardLayout";
import Layout from "../../layouts/Project"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.module.css";
import axios from "../../axios-interceptor";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextareaAutosize,
} from "@material-ui/core";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Navbar from "../../components/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faGripVertical,
  faPen,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { format } from "date-fns";
import logo from "../../assets/img/avatars/nielsen-logo.png";
import { recordLoadEnd, recordLoadStart } from "../../redux/actions/appActions";
import { toastr } from "react-redux-toastr";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
  button: {
    marginTop: theme.spacing(2),
  },
  datepicker: {
    marginTop: theme.spacing(2),
  },
  textarea: {
    width: "100%",
    padding: "10px",
    fontSize: "16px",
    borderColor: "rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    "&:focus": {
      borderColor: "#3f51b5",
      outline: "none",
    },
  },
}));

const initialFormState = {
  ReleaseDate: null,
  Type: "",
  Function: "",
  Component: "",
  Details: "",
  Link: "",
};

const ManageFeeds = () => {
  const [componentList, setComponentList] = useState([]);
  const codeLabels = useSelector(({ codeLabels }) => codeLabels);
  const recordloading = useSelector(({ app }) => app.recordloading);
  const classes = useStyles();
  const [errors, setErrors] = useState({});
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [hideModal, setHideModal] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [hideIndex, setHideIndex] = useState(null);
  const [formData, setFormData] = useState(initialFormState);
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();

  let typeList = [
    { label: "New Features", value: "New Features" },
    { label: "Enhancements", value: "Enhancements" },
    { label: "Bug Fixes", value: "Bug Fixes" },
  ];

  let functList = [
    { label: "ALL", value: "ALL" },
    { label: "CS", value: "CS" },
    { label: "OPS", value: "OPS" },
  ];

  useEffect(() => {
    async function fetchData() {
      dispatch(recordLoadStart());
      await axios
        .get("/Notifications/default")
        .then((res) => {
          res.data.Data.Notifications.sort(
            (a, b) => a.IsShownOrder - b.IsShownOrder
          );
          setRows(res.data.Data.Notifications);
          dispatch(recordLoadEnd());
        })
        .catch((error) => {
          console.error("There was an error updating the row!", error);
          dispatch(recordLoadEnd());
        });
    }
    fetchData();
    getNotifications();
  }, []);

  const getNotifications = () => {
    let b = [];
    codeLabels.ComponentsOptions.map((c) =>
      b.push({ label: c.Label, value: c.Label })
    );
    setComponentList(b);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      ReleaseDate: date,
    });
  };

  const validate = () => {
    let tempErrors = {};
    if (!formData.ReleaseDate || formData.ReleaseDate === "")
      tempErrors.ReleaseDate = "Release Date is required";
    if (!formData.Type || formData.Type === "") tempErrors.Type = "Type is required";
    if (!formData.Function || formData.Function === "") tempErrors.Function = "Function is required";
    if (!formData.Component || formData.Component === "") tempErrors.Component = "Component is required";
    if (!formData.Details || formData.Details === "") tempErrors.Details = "Detail is required";
    if (formData.Link && !/^https?:\/\/[^\s$.?#].[^\s]*$/.test(formData.Link)) {
      tempErrors.Link = "Invalid URL";
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    // e.preventDefault();
    if (validate()) {
      if (editIndex === null) {
        formData.IsShownOrder = 0;
      }
      dispatch(recordLoadStart());
      await axios
        .put("/Notifications/Create", [formData], {
          headers: { "auth-token": localStorage.getItem("auth-token") },
        })
        .then(async (res) => {
          if (editIndex !== null) {
            const updatedRows = rows.map((row, index) =>
              index === editIndex ? formData : row
            );
            setRows(updatedRows);
            setEditIndex(null);
            toastr.success("Data updated successfully");
          } else {
            try {
              await incrementIsShownOrder(res);              
            } catch(error) {
              console.error("Something went wrong ", error);
            }
          }
          dispatch(recordLoadEnd());
        })
        .catch((error) => {
          console.error("There was an error updating the row!", error);
          toastr.error("Failed");
          dispatch(recordLoadEnd());
        });
      setFormData(initialFormState);
      setErrors({});
      setEditModal(false);
    }
  };

  const incrementIsShownOrder = async(res) => {
    try {
      const updatedRows = rows.map((row, index) => {
        row.IsShownOrder += 1;
        return row;
      });
      dispatch(recordLoadStart());
      await axios
        .put("/Notifications/Create", updatedRows, {
          headers: { "auth-token": localStorage.getItem("auth-token") },
        })
        .then ((response)=> {
          console.log(response, "response")
          const {Components, ...rest} = res?.data?.Data[0];
          const transformedData = {Component: Components, ...rest};
          toastr.success("Data added successfully");
          setRows([transformedData, ...updatedRows]);
          dispatch(recordLoadEnd());
        })
        .catch((error) => {
          console.error("There was an error updating the row!", error);
          toastr.error("Failed");
          dispatch(recordLoadEnd());
        });

    } catch(error) {
      console.error("Failed to increment order:", error);
    }

  };

  const handleDelete = (index) => {
    setDeleteIndex(index);
    setDeleteModal(!deleteModal);
  };

  const handleHide = (index) => {
    setHideIndex(index);
    setHideModal(!hideModal);
  };
  const handleEdit = (index) => {
    setErrors({});
    setFormData(rows[index]);
    setEditModal(!editModal);
    setEditIndex(index);
  };

  const confirmDelete = async () => {
    dispatch(recordLoadStart());
    await axios
      .delete(`/Notifications/${rows[deleteIndex].id}`)
      .then(async () => {
        const updatedRows = rows.filter(
          (_, rowIndex) => rowIndex !== deleteIndex
        ).map(
          (row, idx) => {
          row.IsShownOrder = idx;
          return row;
        });

        await axios
        .put("/Notifications/Create", updatedRows, {
          headers: { "auth-token": localStorage.getItem("auth-token") },
        })
        .then((res) => {
          setRows(updatedRows);
          setDeleteModal(false);
          toastr.success("Data deleted successfully");
          dispatch(recordLoadEnd());
        })
        .catch((error) => {
          console.error("There was an error updating the row!", error);
          toastr.error("Failed");
          dispatch(recordLoadEnd());
        });                
      })
      .catch((error) => {
        console.error("There was an error deleting the row!", error);
        toastr.error("Failed");
        dispatch(recordLoadEnd());
      });
  };

  const confirmHide = async () => {
    if (rows[hideIndex]?.IsHide) {
      rows[hideIndex].IsHide = false;
    } else {
      rows[hideIndex].IsHide = true;
    }

    dispatch(recordLoadStart());

    await axios
      .put("/Notifications/Create", [rows[hideIndex]], {
        headers: { "auth-token": localStorage.getItem("auth-token") },
      })
      .then((res) => {
        // setRows([...rows]);
        toastr.success("Data shown/hidden successfully");
        dispatch(recordLoadEnd());
        setHideModal(false);
      })
      .catch((error) => {
        console.error("There was an error updating the row!", error);
        toastr.error("Failed");
        dispatch(recordLoadEnd());
      });
  };

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    const reorderedRows = Array.from(rows);
    const [removed] = reorderedRows.splice(result.source.index, 1);
    reorderedRows.splice(result.destination.index, 0, removed);
    reorderedRows.map((row, index) => (row.IsShownOrder = index));
    dispatch(recordLoadStart());
    await axios
      .put("/Notifications/Create", reorderedRows, {
        headers: { "auth-token": localStorage.getItem("auth-token") },
      })
      .then((res) => {
        setRows(reorderedRows);
        toastr.success("Data reordered successfully");
        dispatch(recordLoadEnd());
      })
      .catch((error) => {
        console.error("There was an error updating the row!", error);
        toastr.error("Failed");
        dispatch(recordLoadEnd());
      });
  };

  return (
    <Layout  CardHeader="Manage Feeds for the Notification Board"  onlyHeader={true} >
      {recordloading ? <div id="pageCoverSpin"></div> : null}
      <Card>
        <CardHeader
          style={{
            position: "sticky",
            // top: "20%",
            // zIndex: "9999",
            // marginTop: "10px",
            marginBottom:"86px"
          }}
        >
          <div
            column-gap="80px"
            style={{
              marginLeft: "70%",
              marginBottom: "30px",
            }}
          ></div>
          <div className="col-md-14 col-md-offset-2">
            <form noValidate autoComplete="off">
              <Row>
                <Col>
                  <div className={classes.datepicker}>
                    <DatePicker
                      // locale="en-GB"
                      label="Release Date"
                      popperClassName="fixed-top"
                      className="form-control"
                      selected={
                        formData.ReleaseDate
                          ? new Date(formData?.ReleaseDate)
                          : null
                      }
                      onChange={handleDateChange}
                      dateFormat="dd-MMM-yyyy"
                      placeholderText="Select Date"
                      customInput={
                        <TextField
                          error={!!errors.ReleaseDate}
                          helperText={errors.ReleaseDate}
                        />
                      }
                    />
                  </div>
                </Col>
                <Col>
                  <FormControl
                    className={classes.formControl}
                    error={!!errors.Type}
                  >
                    <InputLabel>Select Type</InputLabel>
                    <Select
                      name="Type"
                      className=""
                      //   options={
                      //     Array.isArray(type) && type.length > 0 ? [] : TypeList
                      //   }
                      placeholder="Select Type"
                      value={formData.Type}
                      onChange={handleChange}
                    >
                      {typeList.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{errors.Type}</FormHelperText>
                  </FormControl>
                </Col>
                <Col>
                  <FormControl
                    className={classes.formControl}
                    error={!!errors.Function}
                  >
                    <InputLabel>Select Function</InputLabel>
                    <Select
                      name="Function"
                      className=""
                      //   options={
                      //     Array.isArray(funct) && funct.length > 0
                      //       ? []
                      //       : FunctList
                      //   }
                      placeholder="Select Function"
                      value={formData.Function}
                      onChange={handleChange}
                    >
                      {functList.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{errors.Function}</FormHelperText>
                  </FormControl>
                </Col>
                <Col>
                  <FormControl
                    className={classes.formControl}
                    error={!!errors.Component}
                  >
                    <InputLabel>Select Component</InputLabel>
                    <Select
                      name="Component"
                      className=""
                      //   options={
                      //     Array.isArray(type) && type.length > 0
                      //       ? []
                      //       : componentList
                      //   }
                      placeholder="Select Component"
                      value={formData.Component}
                      onChange={handleChange}
                    >
                      {componentList.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{errors.Component}</FormHelperText>
                  </FormControl>
                </Col>
                <Col>
                  <FormControl
                    className={classes.formControl}
                    error={!!errors.Details}
                  >
                    <TextareaAutosize
                      // type="text"
                      placeholder="Enter Detail"
                      label="Details"
                      name="Details"
                      onChange={handleChange}
                      value={formData.Details}
                      className={classes.textarea}
                      // error={!!errors.Details}
                      // helperText={errors.Details}
                      // fullWidth
                      // multiline
                      minRows={6}
                    />
                    <FormHelperText>{errors.Details}</FormHelperText>
                  </FormControl>
                </Col>
                <Col>
                  <TextField
                    type="text"
                    name="Link"
                    label="Enter Link"
                    placeholder="Enter Link"
                    onChange={handleChange}
                    value={formData.Link}
                    error={!!errors.Link}
                    helperText={errors.Link}
                    fullWidth
                  />
                </Col>
                <Col>
                  <Button
                    // type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={handleSubmit}
                  >
                    Add
                  </Button>
                </Col>
              </Row>
            </form>
          </div>
        </CardHeader>
        <CardBody
          style={
            {
              // position: "sticky",
              // top: "20%",
              // zIndex: "9999",
              // marginTop: "120px",
            }
          }
        >
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="table">
              {(provided) => (
                <TableContainer component={Paper} style={{maxHeight: "325px", overflow: "auto"}}>
                  <Table
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className={classes.table}
                    stickyHeader
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Release Date</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Function</TableCell>
                        <TableCell>Component</TableCell>
                        <TableCell>Details</TableCell>
                        <TableCell>Link</TableCell>
                        <TableCell>Actions</TableCell>
                        <TableCell>Reorder</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row, index) => (
                        <Draggable
                          key={index}
                          draggableId={`${index}`}
                          index={index}
                        >
                          {(provided) => (
                            <TableRow
                              key={index}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >                             
                              <TableCell>
                                {row.ReleaseDate
                                  ? format(
                                      new Date(row.ReleaseDate),
                                      "dd-MMM-yyyy"
                                    )
                                  : ""}
                              </TableCell>
                              <TableCell>{row.Type}</TableCell>
                              <TableCell>{row.Function}</TableCell>
                              <TableCell>{row.Component}</TableCell>
                              <TableCell>{<div dangerouslySetInnerHTML={{__html:row.Details}}/>}</TableCell>
                              <TableCell>
                                <a
                                  href={row.Link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {row.Link}
                                </a>
                              </TableCell>
                              <TableCell style={{width: '180px' }}>
                                <FontAwesomeIcon
                                  icon={faPen}
                                  className="ic-hover pointer"
                                  fixedWidth
                                  title="Edit Notification"
                                  style={{ marginRight: "20px" }}
                                  onClick={() => handleEdit(index)}
                                  size="lg"
                                />
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  className="ic-hover pointer"
                                  fixedWidth
                                  title="Delete Notification"
                                  style={{ marginRight: "20px" }}
                                  onClick={() => handleDelete(index)}
                                  size="lg"
                                />
                                <FontAwesomeIcon
                                  icon={row?.IsHide ? faEyeSlash : faEye}
                                  className="ic-hover pointer"
                                  fixedWidth
                                  title={
                                    row?.IsHide
                                      ? "Show Notification"
                                      : "Hide Notification"
                                  }
                                  style={{ marginRight: "20px" }}
                                  onClick={() => handleHide(index)}
                                  size="lg"
                                />
                              </TableCell>
                              <TableCell>
                                <FontAwesomeIcon
                                  icon={faGripVertical}
                                  // size="lg"
                                />
                              </TableCell>
                            </TableRow>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Droppable>
          </DragDropContext>
        </CardBody>
      </Card>
      <Modal
        isOpen={editModal}
        toggle={() => setEditModal(false)}
        className="modal-lg"
        centered={true}
      >
        <ModalHeader toggle={() => {
          setEditModal(false);
          setFormData(initialFormState);
          }}
        >
          {/* <img src={logo} className="modallogo" /> */}
        </ModalHeader>
        <div>
          <span className="modaltitle modaltitleDisplay">Update Feeds</span>
        </div>

        <ModalBody style={{ "margin-left": "10px" }}>
          {/* {console.log(rows)} */}
          {/* {rows.length && ( */}
          <form noValidate autoComplete="off">
            <Row>
              <Col>
                <div className={classes.datepicker}>
                  <DatePicker
                    // locale="en-GB"
                    label="Release Date"
                    popperClassName="fixed-top"
                    className="form-control"
                    selected={new Date(formData?.ReleaseDate)}
                    onChange={handleDateChange}
                    dateFormat="dd-MMM-yyyy"
                    placeholderText="Select Date"
                    customInput={
                      <TextField
                        error={!!errors.ReleaseDate}
                        helperText={errors.ReleaseDate}
                      />
                    }
                  />
                </div>
              </Col>
              <Col>
                <FormControl
                  className={classes.formControl}
                  error={!!errors.Type}
                >
                  <InputLabel>Select Type</InputLabel>
                  <Select
                    name="Type"
                    className=""
                    //   options={
                    //     Array.isArray(type) && type.length > 0 ? [] : TypeList
                    //   }
                    placeholder="Select Type"
                    value={formData?.Type}
                    onChange={handleChange}
                  >
                    {typeList.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{errors.Type}</FormHelperText>
                </FormControl>
              </Col>
              <Col>
                <FormControl
                  className={classes.formControl}
                  error={!!errors.Function}
                >
                  <InputLabel>Select Function</InputLabel>
                  <Select
                    name="Function"
                    className=""
                    //   options={
                    //     Array.isArray(funct) && funct.length > 0
                    //       ? []
                    //       : FunctList
                    //   }
                    placeholder="Select Function"
                    value={formData?.Function}
                    onChange={handleChange}
                  >
                    {functList.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{errors.Function}</FormHelperText>
                </FormControl>
              </Col>
              <Col>
                <FormControl
                  className={classes.formControl}
                  error={!!errors.Component}
                >
                  <InputLabel>Select Component</InputLabel>
                  <Select
                    name="Component"
                    className=""
                    //   options={
                    //     Array.isArray(type) && type.length > 0
                    //       ? []
                    //       : componentList
                    //   }
                    placeholder="Select Component"
                    value={formData?.Component}
                    onChange={handleChange}
                  >
                    {componentList.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{errors.Component}</FormHelperText>
                </FormControl>
              </Col>
              <Col>
                <FormControl
                  className={classes.formControl}
                  error={!!errors.Details}
                >
                  <TextareaAutosize
                    // type="text"
                    placeholder="Enter Details"
                    label="Details"
                    name="Details"
                    onChange={handleChange}
                    value={formData?.Details}
                    className={classes.textarea}
                    // error={!!errors.Details}
                    // helperText={errors.Details}
                    // fullWidth
                    // multiline
                    minRows={6}
                  />
                  <FormHelperText>{errors.Details}</FormHelperText>
                </FormControl>
              </Col>
              <Col>
                <TextField
                  type="text"
                  name="Link"
                  label="Link"
                  placeholder="Enter Link"
                  onChange={handleChange}
                  value={formData?.Link}
                  error={!!errors.Link}
                  helperText={errors.Link}
                  fullWidth
                />
              </Col>
            </Row>
          </form>
          {/* )} */}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              handleSubmit();
            }}
          >
            Save
          </Button>
          <Button
            onClick={() => {
              setEditModal(false);
              setFormData(initialFormState);
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={deleteModal}
        toggle={() => setDeleteModal(!deleteModal)}
        className="modal-sm"
        centered={true}
      >
        <ModalHeader>
          {/* toggle={() => setDeleteModal(false)} */}
          {/* <img src={logo} className="modallogo" /> */}
        </ModalHeader>
        <ModalBody>
          <p>
            <strong>Are you sure, you want to delete this row?</strong>
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              confirmDelete();
            }}
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              setDeleteModal(false);
            }}
          >
            No
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={hideModal}
        toggle={() => setHideModal(!hideModal)}
        className="modal-sm"
        centered={true}
      >
        <ModalHeader> 
          {/* //toggle={() => setHideModal(false)} */}
          {/* <img src={logo} className="modallogo" /> */}
        </ModalHeader>
        <ModalBody>
          <p>
            <strong>
              Are you sure, you want to 
              {rows[hideIndex]?.IsHide
                ? " show "
                : " hide "}
                this row in the notification board?
            </strong>
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              confirmHide();
            }}
          >
            {/* {rows[hideIndex]?.IsHide ? "Show" : "Hide"} */}
            Yes
          </Button>
          <Button
            onClick={() => {
              setHideModal(false);
            }}
          >
            No
          </Button>
        </ModalFooter>
      </Modal>
      {/* <Button color="primary" onClick={() => setOpenFeeds(false)}>
        Save
    </Button>
    <Button color="secondary" onClick={() => setOpenFeeds(false)}>
        Cancel
    </Button> */}
    </Layout>
  );
};

export default ManageFeeds;