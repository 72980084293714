import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../layouts/Project";
import { getLabel } from "../../utils/codeLabels";
import {
  faChevronRight,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";

import {
  Button,
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Collapse,
  CardTitle,
  CardHeader,
  Label,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input, Nav, NavItem, NavLink, TabContent, TabPane
} from "reactstrap";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReviewSchedule from "./ReviewSchedule";
import ReviewCosting from "./ReviewCosting";
import ReviewOperations from "./ReviewOperations";
import ReviewVendor from "./ReviewVendor";
import {
  getCosting,
  saveCostingProfileNew,
} from "../../redux/actions/currentCostingActions";
import {
  selectWaveSpec,
  updateCurrentWaveSpec,
} from "../../redux/actions/currentWaveSpecActions";
import ReviewPerformance from "./ReviewPerformance";
import ReviewSpecifications from "./ReviewSpecifications";
import Spinner from "../../components/Spinner";
import { setWaveSpecs } from "../../redux/actions/waveSpecsActions";
import { useHistory } from "react-router";
import ProjectDeliveryDates from "../projectSchedule/ProjectDeliveryDates";
import { useParams } from "react-router-dom";
import * as currentCostingActions from "../../redux/actions/currentCostingActions";
import ReviewOperationsNew from "./ReviewoperationsNew";
import RecordsBadge from "../../components/RecordsBadge";

const ProjectReviewUpdated = () => {
  let { profileId } = useParams();

  const app = useSelector(({ app }) => app);
  const currentCostingProfile = useSelector(
    ({ currentCosting }) => currentCosting.currentCostingProfile
  );
  const codeLabels = useSelector(({ codeLabels }) => codeLabels);

  const [calledProfile, setCalledProfile] = useState(false)
  const currentProject = useSelector(({ currentProject }) => currentProject.newProject);
  const project = useSelector(
    ({ currentProject }) => currentProject.newProject
  );
  const countrySpecs = useSelector(({ countrySpecs }) => countrySpecs);
  const currentWaveSpec = useSelector(({ currentWaveSpec }) => currentWaveSpec);
  const waveSpecs = useSelector(({ waveSpecs }) => waveSpecs);
  const userRecord = useSelector(({ user }) => user.userRecord);

  const [currentProfile, setCurrentProfile] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const [waveEditModal, setWaveEditModal] = useState(false);
  const [editableWaveName, setEditableWavename] = useState("");
  const [collapseStatus, setCollapseStatus] = useState(true);
  const [activeTabFC, setActiveTabFC] = useState(0);
  const [activeTabSM, setActiveTabSM] = useState(0);
  let pacificData = _.head(codeLabels.PacificCountriesList).Label;
  const [pacificMarkets] = useState(pacificData?.split(','))
  const [toggle, setToggle] = useState({
    specifications: false,
    operations: false,
    costing: false,
    schedule: false,
    performance: false,
    vendor: false,
  });
  useEffect(() => {
    if (currentCostingProfile && currentCostingProfile.id && !currentCostingProfile.Project)
      history.replace("/");
  });
  const updateSelectedWaveName = () => {
    let wavespecs = [...currentCostingProfile.WaveSpecs];
    let profileToSave = { ...currentCostingProfile };
    wavespecs = wavespecs.map((ws) => {
      if (ws.id == currentWaveSpec.id) {
        ws.WaveName = editableWaveName;
        dispatch(
          updateCurrentWaveSpec({
            ...currentWaveSpec,
            WaveName: editableWaveName,
          })
        );
      }
      return { ...ws };
    });
    dispatch(setWaveSpecs(wavespecs));
    // dispatch(
    //   saveCostingProfileNew(
    //     { ...profileToSave, WaveSpecs: wavespecs },
    //     () => setWaveEditModal(false),
    //     true
    //   )
    // );
  };
  const collapseAll = () => {
    let _toggle = { ...toggle };
    Object.keys(_toggle).map((t) => {
      _toggle[t] = collapseStatus;
    });
    setToggle(_toggle);
    setCollapseStatus(!collapseStatus);
  };
  useEffect(() => {
    if (userRecord && !userRecord.IsOpsProjectManager
      && (currentProject.ProposalOwnerEmail?.value?.toLowerCase() !== userRecord.Email?.toLowerCase())) { history.replace("/"); }
  }, [currentProject]);


  useEffect(() => {
    if (
      currentCostingProfile &&
      currentCostingProfile.CountrySpecs &&
      currentCostingProfile.CountrySpecs.length
    ){
      setActiveTabFC(
        currentCostingProfile.CountrySpecs.findIndex((cs) =>
          cs.MethodologySpecs.some((ms) => !ms.NotApplicable)
        )
      );
    setActiveTabSM(
      currentCostingProfile.CountrySpecs[
        activeTabFC
      ].MethodologySpecs?.findIndex((ms) => !ms.NotApplicable)
    );}
  }, []);
  // }, [currentCostingProfile]);

  useEffect(() => {
    if (profileId && !calledProfile) {
      setCalledProfile(true);
      dispatch(currentCostingActions.getCosting(profileId, null, true));
    } else if (!profileId) {
      console.log("boots to dashboard");
      history.replace("/");
    }
  }, []);
  const toggleFC = (tab) => {
    if (activeTabFC !== tab) {
      setActiveTabFC(tab);
      setActiveTabSM(currentCostingProfile.CountrySpecs[tab].MethodologySpecs.map((m, mIndex) => ({
        methodology: m,
        index: mIndex,
      }))?.filter(x => !x.methodology.NotApplicable)[0]?.index);
    }
  };

  const toggleSM = (tab) => {
    if (activeTabSM !== tab) {
      setActiveTabSM(tab);
      //  onMethodologyChange(tab)
    }
  };

  const saveUpdatedProfile = () => {
    console.log(currentCostingProfile, "profile");
    dispatch(currentCostingActions.saveProfileReview(currentCostingProfile));

  }
  function disableForCsUser() {
    let isCSOnly = userRecord.IsClientService ? true : false
    if (((userRecord.ProjectReviewAccess) || (_.includes(project.ProjectManagerEmail?.map(pm => pm?.value?.toLowerCase()), userRecord.Email?.toLowerCase()))))
      isCSOnly = false
    // return (!((userRecord.ProjectReviewAccess) || (_.includes(project.ProjectManagerEmail?.map(pm => pm?.value?.toLowerCase()), userRecord.Email?.toLowerCase())))
    //   ? true : userRecord.IsClientService)
    return isCSOnly
  }
  return (currentCostingProfile && currentCostingProfile.ProfileSetting)
    ?
    <Layout
      hideActions={true}
      profileStatusToDisplay={getLabel(
        "CostingStatusOptions",
        currentCostingProfile.ProfileStatus
      )}
      projectStatusToDisplay={getLabel(
        "ProjectStatusOptions",
        currentCostingProfile.Project?.ProjectStatus
      )}
      avoidEdit={true}
    >
      <Container>
        <Card>
          <CardHeader>
            <Row>
              <Col lg="10" md="10">
                <h4>Project Review</h4>
              </Col>
              <Col lg="2" md="2">
                <a className="select-link" onClick={collapseAll}>
                  {collapseStatus ? "Expand All" : "Collapse All"}
                </a>
              </Col>
            </Row>
          </CardHeader>
        </Card>
        <Card className="ml-2 mr-2 mb-2">
          <CardBody>
            <ProjectDeliveryDates
              disableforCS={disableForCsUser()}
            />
          </CardBody>

        </Card>

        {codeLabels["FieldingCountriesOptions"].find(
          (fco) =>
            (fco.Code == currentCostingProfile.Project.CommissioningCountry)
        ).IsProjectReview == true && (<Row>
          <Col md={12} className={'countryTabs tab-vertical'}>

            <div className={"tab mt-4"}>
              <Nav tabs>
                {currentCostingProfile.CountrySpecs &&
                  currentCostingProfile.CountrySpecs.map((fc, indxFC) => (
                    fc.MethodologySpecs.some(ms => !ms.NotApplicable) && <NavItem key={indxFC}>
                      <NavLink className={classnames({ active: activeTabFC === indxFC })}
                        onClick={() => {
                          toggleFC(indxFC);
                        }}
                      >
                        <b>{fc.label}</b>
                      </NavLink>
                    </NavItem>
                  ))}
              </Nav>
              <TabContent activeTab={activeTabFC}>
                {currentCostingProfile.CountrySpecs &&
                  currentCostingProfile.CountrySpecs.map((fc, indxFC) => (
                    fc.MethodologySpecs.some(ms => !ms.NotApplicable) && activeTabFC === indxFC &&
                    <TabPane tabId={indxFC} key={fc.value}>
                      <Row>
                        {/*JSON.stringify(userRecord)*/}
                        <Col md={12} className={"methodologyTabs"}>
                          <Nav tabs>
                            {fc.MethodologySpecs &&
                              fc.MethodologySpecs.map((sm, indxSM) =>
                                !sm.NotApplicable &&
                                (
                                  <NavItem key={indxSM}>
                                    <NavLink className={classnames({ active: activeTabSM === indxSM })}
                                      onClick={() => {
                                        toggleSM(indxSM);
                                      }}
                                    >
                                      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <div>
                                          <b>{sm.label}</b>
                                        </div>
                                      </div>
                                    </NavLink>
                                  </NavItem>
                                ))}
                          </Nav>
                          <TabContent activeTab={activeTabSM} style={{ padding: "0.25" }}>
                            {fc.MethodologySpecs &&
                              fc.MethodologySpecs?.map((sm, indxSM) => (
                                !sm.NotApplicable && activeTabSM === indxSM &&
                                <TabPane tabId={indxSM} key={sm.value}>
                                  <Card className="ml-2 mr-2 mb-2">
                                    <CardHeader
                                      onClick={(e) =>
                                        setToggle({ ...toggle, specifications: !toggle.specifications })
                                      }
                                    >
                                      <Row>
                                        <Col xs="11">
                                          <CardTitle className="mb-0 text-capitalize">
                                            Methodologies & Fieldwork
                                          </CardTitle>
                                        </Col>
                                        <Col xs="1">
                                          <FontAwesomeIcon
                                            className="align-middle mr-2"
                                            icon={!toggle.specifications ? faChevronRight : faChevronDown}
                                            fixedWidth
                                          />
                                        </Col>
                                      </Row>
                                    </CardHeader>
                                    <Collapse isOpen={toggle.specifications}>
                                      <CardBody>
                                        <ReviewSpecifications
                                          profile={currentCostingProfile}
                                          indxFC={indxFC}
                                          methodology={sm}
                                          indxSM={indxSM}
                                          disableforCS={disableForCsUser()}
                                        />
                                      </CardBody>
                                    </Collapse>
                                  </Card>

                                  <Card className="ml-2 mr-2 mb-2">
                                    <CardHeader
                                      onClick={(e) =>
                                        setToggle({ ...toggle, operations: !toggle.operations })
                                      }
                                    >
                                      <Row>
                                        <Col xs="11">
                                          <CardTitle className="mb-0 text-capitalize">
                                            Operations Resources
                                          </CardTitle>
                                        </Col>
                                        <Col xs="1">
                                          <FontAwesomeIcon
                                            className="align-middle mr-2"
                                            icon={!toggle.operations ? faChevronRight : faChevronDown}
                                            fixedWidth
                                          />
                                        </Col>
                                      </Row>
                                    </CardHeader>
                                    <Collapse isOpen={toggle.operations}>
                                      <CardBody>
                                        <ReviewOperationsNew
                                          profile={currentCostingProfile}
                                          indxFC={indxFC}
                                          methodology={sm}
                                          indxSM={indxSM}
                                          disableforCS={disableForCsUser()}
                                        />
                                      </CardBody>
                                    </Collapse>
                                  </Card>

                                  <Card className="ml-2 mr-2 mb-2">
                                    <CardHeader
                                      onClick={(e) => setToggle({ ...toggle, costing: !toggle.costing })}
                                    >
                                      <Row>
                                        <Col xs="11">
                                          <CardTitle className="mb-0 text-capitalize">Costing</CardTitle>
                                        </Col>
                                        <Col xs="1">
                                          <FontAwesomeIcon
                                            className="align-middle mr-2"
                                            icon={!toggle.costing ? faChevronRight : faChevronDown}
                                            fixedWidth
                                          />
                                        </Col>
                                      </Row>
                                    </CardHeader>
                                    <Collapse isOpen={toggle.costing}>
                                      <CardBody>
                                        <ReviewCosting
                                          profile={currentCostingProfile}
                                          indxFC={indxFC}
                                          methodology={sm}
                                          indxSM={indxSM}
                                          pacificMarkets={pacificMarkets}
                                          disableforCS={disableForCsUser()}
                                        />
                                      </CardBody>
                                    </Collapse>
                                  </Card>



                                  <Card className="ml-2 mr-2 mb-2">
                                    <CardHeader
                                      onClick={(e) =>
                                        setToggle({ ...toggle, schedule: !toggle.schedule })
                                      }
                                    >
                                      <Row>
                                        <Col xs="11">
                                          <CardTitle className="mb-0 text-capitalize">Schedule</CardTitle>
                                        </Col>
                                        <Col xs="1">
                                          <FontAwesomeIcon
                                            className="align-middle mr-2"
                                            icon={!toggle.schedule ? faChevronRight : faChevronDown}
                                            fixedWidth
                                          />
                                        </Col>
                                      </Row>
                                    </CardHeader>
                                    <Collapse isOpen={toggle.schedule}>
                                      <CardBody>
                                        <ReviewSchedule
                                          profile={currentCostingProfile}
                                          indxFC={indxFC}
                                          methodology={sm}
                                          indxSM={indxSM}
                                          disableforCS={disableForCsUser()}
                                        />
                                      </CardBody>
                                    </Collapse>
                                  </Card>

                                  {userRecord.IsOpsProjectManager ? (
                                    <Card className="ml-2 mr-2 mb-2">
                                      <CardHeader
                                        onClick={(e) =>
                                          setToggle({ ...toggle, performance: !toggle.performance })
                                        }
                                      >
                                        <Row>
                                          <Col xs="11">
                                            <CardTitle className="mb-0 text-capitalize">
                                              Performance
                                            </CardTitle>
                                          </Col>
                                          <Col xs="1">
                                            <FontAwesomeIcon
                                              className="align-middle mr-2"
                                              icon={!toggle.performance ? faChevronRight : faChevronDown}
                                              fixedWidth
                                            />
                                          </Col>
                                        </Row>
                                      </CardHeader>
                                      <Collapse isOpen={toggle.performance}>
                                        <CardBody>
                                          <ReviewPerformance
                                            profile={currentCostingProfile}
                                            indxFC={indxFC}
                                            methodology={sm}
                                            indxSM={indxSM}
                                            disableforCS={disableForCsUser()}
                                          />
                                        </CardBody>
                                      </Collapse>
                                    </Card>
                                  ) : null}
                                  {userRecord.IsOpsProjectManager ? (
                                    <Card className="ml-2 mr-2 mb-2">
                                      <CardHeader
                                        onClick={(e) => setToggle({ ...toggle, vendor: !toggle.vendor })}
                                      >
                                        <Row>
                                          <Col xs="11">
                                            <CardTitle className="mb-0 text-capitalize">
                                              Vendor Evaluation
                                            </CardTitle>
                                          </Col>
                                          <Col xs="1">
                                            <FontAwesomeIcon
                                              className="align-middle mr-2"
                                              icon={!toggle.vendor ? faChevronRight : faChevronDown}
                                              fixedWidth
                                            />
                                          </Col>
                                        </Row>
                                      </CardHeader>
                                      <Collapse isOpen={toggle.vendor}>
                                        <CardBody>
                                          <ReviewVendor
                                            profile={currentCostingProfile}
                                            indxFC={indxFC}
                                            methodology={sm}
                                            indxSM={indxSM}
                                            disableforCS={disableForCsUser()}
                                          />
                                        </CardBody>
                                      </Collapse>
                                    </Card>
                                  ) : null}

                                </TabPane>
                              ))}
                          </TabContent>
                        </Col>
                      </Row>
                    </TabPane>
                  ))}
              </TabContent>
            </div>
          </Col>
        </Row>)}
        {codeLabels["FieldingCountriesOptions"].find(
          (fco) =>
            (fco.Code == currentCostingProfile.Project.CommissioningCountry)
        ).IsProjectReview == true && !disableForCsUser() && (
            <Row className="justify-content-end mt-2 ml-0 mr-0">
              <Button
                color="primary"
                onClick={() => {
                  saveUpdatedProfile();
                }}
              >
                Save {app.recordloading ?
                  <Spinner size="small" color="#495057" />
                  : null}
              </Button></Row>
          )}{" "}
      </Container>
      <Modal
        isOpen={waveEditModal}
        toggle={() => setWaveEditModal(!waveEditModal)}
        centered
      >
        <ModalHeader toggle={() => setWaveEditModal(!waveEditModal)}>
          Edit Wave Current Wave Name
        </ModalHeader>
        <ModalBody>
          <Input
            defaultValue={
              currentWaveSpec.WaveName ? currentWaveSpec.WaveName : ""
            }
            id="WaveName"
            onChange={(e) => setEditableWavename(e.target.value)}
            placeholder="Enter Wave Name"
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            disabled={app.recordloading}
            onClick={updateSelectedWaveName}
          >
            Update
            {app.recordloading ? (
              <Spinner size="small" color="#495057" />
            ) : null}
          </Button>
          <Button
            color="secondary"
            disabled={app.recordloading}
            onClick={() => setWaveEditModal(false)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Container className="d-flex justify-content-center">
        <RecordsBadge />
      </Container>
    </Layout> : null

};

export default ProjectReviewUpdated;
