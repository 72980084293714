import axios from "../../axios-interceptor";
import * as costingsActions from "./costingsActions";
import * as currentCostingActions from "./currentCostingActions";
import * as appActions from "./appActions";
import { setProjects } from "./projectsActions";
import { toastr } from "react-redux-toastr";
import _ from "lodash";
import moment from "moment";
import * as projectActions from "../../redux/actions/currentProjectActions";
//import * as projectDetails from "../../ProjectDetails";
// import { projectDetails } from "./pages/proposalNew/ProjectDetails";
import {  useDispatch } from "react-redux";
import store from "../store/index";

export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const UPDATE_NEW_PROJECT = "UPDATE_NEW_PROJECT";
export const CLEAR_NEW_PROJECT = "CLEAR_NEW_PROJECT";
export const SET_CURRENT_LOADING_CONTACT = "SET_CURRENT_LOADING_CONTACT";
export const SET_FORM_ERROR = "SET_FORM_ERROR";
export const SET_OPPORTUNITY_VALUE="SET_OPPORTUNITY_VALUE";
export const SET_PRODUCT_VALUE="SET_PRODUCT_VALUE";
export const SET_METHODOLOGY_VALUE="SET_METHODOLOGY_VALUE";
export const SET_NEW_TAB_COUNTRY="SET_NEW_TAB_COUNTRY";
export const SET_NEW_TAB_METH="SET_NEW_TAB_METH";
//era_start
export const PROJECT_CREATED = "PROJECT_CREATED";
export const SET_OPPORTUNITY_DATA="SET_OPPORTUNITY_DATA";
export const SET_SETINDEXCO_VALUE="SET_SETINDEXCO_VALUE";
export const SET_SETINDEXCODEL_VALUE="SET_SETINDEXCODEL_VALUE";
export const SET_OPPORTUNITYLINEITEMS="SET_OPPORTUNITYLINEITEMS"
export const SET_PRODUCTTABLE="SET_PRODUCTTABLE"
export const SET_DELEGATEEMAIL="SET_DELEGATEEMAIL"
export const SET_MARKETDROPDOWN_VALUE="SET_MARKETDROPDOWN_VALUE"
//era_end

export const initProject = () => {

	//console.log("init project called");
	return (dispatch, getState) => {
		dispatch(appActions.pageLoadStart());
		dispatch(
			appActions.setCostingStatus({
				showManualCostEntry: false,
				showSheetsCosts: false,
			})
		);
		let p1 = axios
			.get("/users/cs/all")
			.then((res) => {
				//console.log("init project finished");
				//console.log(res);
				dispatch({
					type: UPDATE_PROJECT,
					contacts: {
						primaryCSContacts: res.data.users,
					},
				});
			})
			.catch((err) => {
				dispatch(appActions.pageLoadEnd());
			});
		let p2 = axios
			.get("/users/internal/all")
			.then((res) => {
				//console.log(res);
				dispatch({
					type: UPDATE_PROJECT,
					contacts: {
						otherInternalContacts: res.data.users,
					},
				});
			})
			.catch((err) => {
				dispatch(appActions.pageLoadEnd());
			});
		let p3 = axios
			.get("/users/costingspocs/all")
			.then((res) => {
				//console.log(res);
				dispatch({
					type: UPDATE_PROJECT,
					contacts: {
						costingSPOCs: res.data.users,
					},
				});
			})
			.catch((err) => {
				dispatch(appActions.pageLoadEnd());
			});
		Promise.all([p1, p2, p3]).then(() => {
			//console.log("end");
			dispatch(appActions.pageLoadEnd());
		});
	};
};
export const setOpportunityValue = (value) => {

    return (dispatch) => {

        dispatch({ type: SET_OPPORTUNITY_VALUE, value})

    }

}






export const setProductValue = (value) => {

    return (dispatch) => {

        dispatch({ type: SET_PRODUCT_VALUE, value})

    }

}
export const setMethodologyValue = (value1, value2) => {

    return (dispatch) => {

        dispatch({ type: SET_METHODOLOGY_VALUE, value1, value2});

    }

}
export const setTableData=(payload)=>{
	return (dispatch)=>{
		dispatch({type:SET_PRODUCTTABLE,payload})
	}
}

export const setMarketDropdown=(value)=>{
	return (dispatch)=>{
		dispatch({type:SET_MARKETDROPDOWN_VALUE,value})
	}
}

export const setDelegateEmails=(payload)=>{
	return (dispatch)=>{
		dispatch({type:SET_DELEGATEEMAIL,payload})
	}
}
export const setOppourtnityLineItems=(payload)=>{
	return (dispatch)=>{
		dispatch({type:SET_OPPORTUNITYLINEITEMS,payload})
	}
}
export const setindexCOforCopy = (value) => {

    return (dispatch) => {

        dispatch({ type: SET_SETINDEXCO_VALUE, value})

    }

}
export const setindexCOfordelete = (value) => {

    return (dispatch) => {

        dispatch({ type: SET_SETINDEXCODEL_VALUE, value})

    }

}
export const getOnlyProject = (projectId, callback) => {
	return (dispatch, getState) => {

		axios
			.get("/projects/" + projectId)
			.then((res) => {
				let project = res.data.project;
				dispatch({ type: UPDATE_NEW_PROJECT, newProject: { ...project } });
				callback()
			}).catch((err) => {
				toastr.error("Something went wrong", "Failed to fetch project detaisl")
			});
	}
}
export const getProject = (projectId, callback, avoidImmediateLoader) => {


	return (dispatch, getState) => {
		//commit
		dispatch(appActions.pageLoadStart());
		dispatch(appActions.localPageLoadStart());
		dispatch(appActions.recordLoadStart());
		axios
			.get("/projects/" + projectId)
			.then((res) => {
				let project = res.data.project;
				project.Edit = true;
				if (!project.BusinessUnitId && project.IsImportedProject) {
					project.BusinessUnit = null;
					project.VerticalId = null; //null anyway, just for safety
					project.IndustryVertical = null; //null anyway, just for safety
				}
				//comment as we need to show all the projects, at any case only one profile will be editable
				// res.data.project.CostingProfiles = [_.last(res.data.project.CostingProfiles)];

				let WaveSpecIds = [].concat(...project.CostingProfiles.map((c) => [].concat(...c.WaveSpecs.map((w) => w.id))));
				let CostingOptionIds = [].concat(...project.CostingProfiles.map((c) => [].concat(...c.CountrySpecs.map((cs) => [].concat(...cs.MethodologySpecs.map((ms) => [].concat(...ms.CostingOptions.map((co) => co.id))))))));
				//console.log('CostingOptionIds', CostingOptionIds);
				let fetchWaveSpecs = WaveSpecIds.length > 0 ? axios.post("/projects/FetchWavesByIds", { WaveIds: WaveSpecIds }) : [];
				let fetchCostingOptions = CostingOptionIds.length > 0 ? axios.post("/projects/FetchCostingOptionsByIds", { CostingOptionsIds: CostingOptionIds }) : [];
				dispatch(appActions.localPageLoadStart())
				dispatch(appActions.recordLoadStart())
				Promise.all([fetchWaveSpecs, fetchCostingOptions]).then((sss) => {
					let waveDetailObjects = (sss && sss[0] && sss[0].data && sss[0].data.waves) || [];
					let costingOptionDetailObjects = (sss && sss[1] && sss[1].data && sss[1].data.costingOptions) || [];
					project.CostingProfiles.forEach((cp, cpi) => {
						cp.WaveSpecs.forEach((ws, wsi) => {
							project.CostingProfiles[cpi].WaveSpecs[wsi] = { ...ws, ...waveDetailObjects[waveDetailObjects.findIndex((wdo) => wdo.id === ws.id)] };
							//console.log('ws', ws);
						})
						cp.CountrySpecs.forEach((cos, cosi) => {
							cos.MethodologySpecs.forEach((ms, msi) => {
								ms.CostingOptions.forEach((co, coi) => {
									project.CostingProfiles[cpi].CountrySpecs[cosi].MethodologySpecs[msi].CostingOptions[coi] = { ...co, ...costingOptionDetailObjects[costingOptionDetailObjects.findIndex((codo) => codo.id === co.id)] };
								})
							})
						})
					})
					//console.log('project', project);
					// let projectCloned = _.cloneDeep(project);
					dispatch({ type: UPDATE_NEW_PROJECT, newProject: { ...project } });
					const profile = getState().currentCosting.currentCostingProfile;

					//set deafult parent profile -first one
					// if (!profile.id)
					// dispatch(currentCostingActions.getCostingProfile(_.last(res.data.project?.CostingProfiles?.filter(cp => cp.ProfileStatus != 99)).id))

					
					
					let costingProfiles = res.data.project?.CostingProfiles?.map(cp => {
						if (profile.id && cp?.id == profile.id)
							cp = { ...profile }
						return { ...cp }
					})
					 dispatch({
						type: currentCostingActions.UPDATE_NEW_COSTING,
						currentCostingProfile: _.last(res.data.project?.CostingProfiles?.filter(cp => cp.ProfileStatus != 99)) 
						// currencies: getState().costings.currencies,
								 })

					dispatch(
						costingsActions.setCurrentCostingProfiles(
							costingProfiles
						)
					);
					if (callback)
						callback();
					if (!avoidImmediateLoader) {
						dispatch(appActions.recordLoadEnd());
						dispatch(appActions.localPageLoadEnd())
						dispatch(appActions.pageLoadEnd());
					}
				}).catch((err) => {
					dispatch(appActions.recordLoadEnd());
					dispatch(appActions.localPageLoadEnd())
					dispatch(appActions.pageLoadEnd());
				});
			})
			.catch((err) => {
				dispatch(appActions.pageLoadEnd());
				dispatch(appActions.localPageLoadEnd());
				dispatch(appActions.recordLoadEnd());
			});
		// dispatch(selectProject(id));
	};
};
export const getIndividualProject = (projectId, pid, callback) => {
	return (dispatch, getState) => {
		dispatch(appActions.recordLoadStart());
		let existingProjects = [...getState().projects.items];
		axios
			.get(`/costingprofiles/${pid}/commissioned`)
			.then((res) => {
				// axios
				//   .get("/costingprofiles/" + reqpro.id)
				//   .then((profile) => {
				existingProjects = existingProjects.map((it) => {
					if (it.id == pid) {
						// let reqpro = _.head(it.CostingProfiles.filter(cp => cp.ProfileStatus == "6"))
						it.CostingProfiles = it.CostingProfiles.map((cp) => {
							if (cp.ProfileStatus == "6") {
								return { ...res.data.costingProfile };
							}
							return { ...cp };
						});
						// currentProject.CostingProfiles = currentProject.CostingProfiles.map(cp => {
						//   if (cp.id == reqpro.id) {
						//     cp = profile.data.costingProfile
						//   }
						//   return { ...cp }
						// })
						return { ...it, isCostingOverviewLoaded: true };
					} else
						return {
							...it,
							isCostingOverviewLoaded: it.isCostingOverviewLoaded,
						};
				});
				dispatch(setProjects({ items: [...existingProjects] }));

				if (callback) callback();
				dispatch(appActions.recordLoadEnd());
				// }).catch((err) => {
				//   dispatch(appActions.recordLoadEnd());
				// });
				// dispatch({ type: UPDATE_NEW_PROJECT, newProject: res.data.project });
			})
			.catch((err) => {
				dispatch(appActions.recordLoadEnd());
          		toastr.error("Details fetch failed", "WARNING: loading of some commissioned profile specs failed due to long process time (E.g. Total waves, Net Revenue, Out of Pocket etc.)");
			});
		// dispatch(selectProject(id));
	};
};


export const setOpportunityData = (value) => {




    return (dispatch) => {




        dispatch({ type: SET_OPPORTUNITY_DATA, value})




    }




}
export const setOverviewStatus = (row) => {
	return (dispatch, getState) => {
		let existingProjects = [...getState().projects.items];
		existingProjects = existingProjects.map((ep) => {
			if (ep.id == row.id) {
				ep.isCostingOverviewLoaded = true;
			}
			return { ...ep };
		});
		dispatch(setProjects({ items: [...existingProjects] }));
	};
};
export const setCurrentProject = (project) => {
	return (dispatch, getState) => {
		dispatch({ type: UPDATE_NEW_PROJECT, newProject: project });
	};
};

export const setFormErrorStatus = (errors) => {

	return (dispatch) => {
		dispatch({ type: SET_FORM_ERROR, errors })
	}
}
//era_start
export const setCurrentProjectCreatedFlag = () => {
	return (dispatch, getState) => {
		dispatch({ type: PROJECT_CREATED });
	};
};
//era_end

export const clearCurrentProject = () => {
	return (dispatch) => {
		dispatch({ type: CLEAR_NEW_PROJECT });
		dispatch({ type: currentCostingActions.CLEAR_NEW_COSTING });
		dispatch({ type: costingsActions.CLEAR_ALL_COSTINGS });
	};
};

export const createProject = (newProject, callback) => {
	return (dispatch, getState) => {
		dispatch(appActions.pageLoadStart());
		newProject = {
			...newProject,
			ContractDetails: [
				...newProject.ContractDetails.map((obj) => {
					delete obj.id;
					return obj;
				}),
			],
		};

		axios
			.post("/projects", newProject, {
				headers: { "auth-token": localStorage.getItem("auth-token") },
			})
			.then((res) => {
				let _project = res.data.Project
				if (callback)
					callback(res.data.Project)
					let other_contats = _project?.OtherProjectTeamContacts.length?
					_project?.OtherProjectTeamContacts.map(contact=>({"email":contact.value}))
					:_project?.OtherProjectTeamContacts
				axios
					.post("/utils/folders/" + res.data.Project.id,other_contats)
					.then((res) => {
						//console.log(res);
						dispatch({
							type: UPDATE_NEW_PROJECT,
							newProject: {
								CostingsFolderId: res.data.CostingsFolderId,
								ProjectResourcesFolderId: res.data.ProjectResourcesFolderId,
							},
						});
						toastr.success("One Drive folders are now ready for use");
					})
					.catch(() => {
						toastr.error("One Drive folder creation failed");
					});
				dispatch(setCurrentProject(res.data.Project));
				//era_start
				dispatch(setCurrentProjectCreatedFlag());
				//era_end
				//dispatch(appActions.pageLoadEnd());
			})
			.catch((err) => {
				dispatch(appActions.pageLoadEnd());
			});
	};
};

export const saveProject = (currentProject, callback, isInternalLoad) => {
	return (dispatch, getState) => {
		if (isInternalLoad) dispatch(appActions.recordLoadStart());
		else dispatch(appActions.pageLoadStart());

		currentProject = _.cloneDeep(currentProject)
		// //console.log("/projects/" + currentProject.ProjectId);
		// //console.log(getState());
		currentProject.CostingProfiles = _.cloneDeep(getState().costings.costingProfiles);
		// currentProject.CostingProfiles.forEach((profile) => {
		// 	delete profile.Methodology;
		// 	delete profile.SubMethodology;
		// 	delete profile.FieldingCountries;
		// 	delete profile.StudyType;
		// });
		let existingProjects = getState().projects?.items?.map((it) => {
			if (it && it.id == currentProject.id) {
				return { ...currentProject };
			} else if (it) return { ...it };
		});
		let _costingProfiles = currentProject.CostingProfiles.map(cp => {
			delete cp.project
			return _.cloneDeep(cp)
		})
		try {
			// console.log("after delete  main");
			// console.log(currentProject);
			
			axios.put("/projects/" + currentProject.ProjectId, { ...currentProject, CostingProfiles: _costingProfiles,  }, {
				headers: { "auth-token": localStorage.getItem("auth-token") },
			})
				.then(() => {
					if (callback) callback();
					//update projects table without api call
					dispatch(setProjects({ items: [...existingProjects] }));

					//avoiding whole page loader
					if (isInternalLoad) dispatch(appActions.recordLoadEnd());
					else dispatch(appActions.pageLoadEnd());
				})
				.catch((err) => {
					if (callback) callback();
					console.error(err);
					if (isInternalLoad) dispatch(appActions.recordLoadEnd());
					else dispatch(appActions.pageLoadEnd());
				});
		} catch (error) {
			console.log(error)
		}
	};
};

const handleJSONData = (data) => {
    let parsedData;
    
    if(typeof data === 'string') {
      try {
        parsedData = JSON.parse(data);
      } catch( err ) {
        console.error("Error parsing json", err);
      }
    } else if(typeof data === 'object') {
      parsedData = data;
    } else{
      console.error("Invalid data type", typeof data);
    }

    if(typeof parsedData === 'string') {
      try {
        parsedData = JSON.parse(parsedData);
      } catch( err ) {
        console.error("Error parsing json", err);
      }
    }
    return parsedData;
  };

//   export const refresh = (oppline) => {
// 	return (dispatch,getState) => {
		
// 			console.log("test refresh",oppline);
// 		let costingProfile = getState().currentCosting.currentCostingProfile;
// 		console.log("cost",costingProfile);
					
// 			};
// 	};

// const updateCostingProfile = (_costingProfile) => {
// 	return(dispatch)=>{
// 		dispatch({
// 			type: "UPDATE_NEW_COSTING",
// 			currentCostingProfile: _costingProfile,
// 		  });
// 		  dispatch({
// 			  type: "UPDATE_PROJECT",
// 			  FieldingCountries: _costingProfile.FieldingCountries,
// 			});
// 	}
//     // setProfileDetailsChange(true)
    
//     // updateProject({
//     //   FieldingCountries: _costingProfile.FieldingCountries,
//     // });
//   };

export const refresh = (oppline,history) => {
	return (dispatch,getState)=>{
	let costingProfile = store.getState().currentCosting.currentCostingProfile;
	while (typeof costingProfile.OPPDatas === "string") {
        costingProfile.OPPDatas = JSON.parse(costingProfile.OPPDatas);
      }
	  while (typeof costingProfile.ProductTableData === "string") {
        costingProfile.ProductTableData = JSON.parse(costingProfile.ProductTableData);
      }
	let newProjectData = getState().currentProject.newProject;
    let lineItems;
	let opportunityData=costingProfile.OPPDatas
          
	lineItems = oppline.filter(
	  (lineId) => !opportunityData.some((line) => line.OpportunityLineItemID === lineId.OpportunityLineItemID)
	);


  

if (lineItems?.length > 0){
	newProjectData.ContractDetails[0].opportunityLineItemDetails.map((data)=>{
		oppline.map((item)=>{
			if(data.OpportunityLineItemID !== item.OpportunityLineItemID){
				newProjectData.ContractDetails[0].isUpdated = true
				newProjectData.ContractDetails[0].opportunityLineItemDetails.push(item)
			}
		})
		
	})

  lineItems.map((item)=> opportunityData.push(item))
// // // console.log("contractDetails",opportunityData);




let temparr= costingProfile.ProductTableData

lineItems.map((item)=> temparr.push(item))
setTableData(temparr)
setOpportunityData(opportunityData)
// // dispatch(projectActions.setTableData(temparr))
// // dispatch(projectActions.setOpportunityData(opportunityData))



let product= opportunityData.map(m => {
  return m.MaterialID
})
let prodRemoveDupl = [...new Set(product)]
let productCode = prodRemoveDupl.map(m => {
  return ({ label: m, value: m, isFixed: false })
})
costingProfile.ProductType = productCode;
costingProfile.StudyType = productCode;
}
else{
	newProjectData.ContractDetails[0].opportunityLineItemDetails = 
 newProjectData.ContractDetails[0].opportunityLineItemDetails
}

if(lineItems.length){
	let temp = newProjectData.ContractDetails[0].opportunityLineItemDetails
let temp1 = []
temp.map((cd)=>{
	oppline.map((ol)=>{
		if(ol.OpportunityLineItemID !== cd.OpportunityLineItemID){
			temp1.push(ol)
		}
	})
})

newProjectData.ContractDetails[0].opportunityLineItemDetails = temp1


}
// // // console.log('tableData111',tableData)
// // // console.log('project.ContractDetails[0].o',project.ContractDetails[0].opportunityLineItemDetails)
 
 let tempAr = costingProfile.ProductTableData

// // // Updating table data with new msd response
const tableDataRef = tempAr.map(temp => {
const matchOppLineItem = oppline.filter(opp => opp.OpportunityLineItemID === temp.OpportunityLineItemID);

if (matchOppLineItem.length > 0) {
  temp.WBSNumber = matchOppLineItem[0].WBSNumber;
  temp.MaterialID = matchOppLineItem[0].MaterialID;
  temp.ProductDescription = matchOppLineItem[0].ProductDescription;
  temp.StartDate = matchOppLineItem[0].StartDate;
  temp.EndDate = matchOppLineItem[0].EndDate;
  temp.CharacteristicValues.Z_FIELDINGMETHOD[0] = matchOppLineItem[0].CharacteristicValues.Z_FIELDINGMETHOD[0];
}

return temp;
});
// Updating OpportunityData with new msd response
const updatedOppData = opportunityData.map(temp => {
const matchOppLineItem = oppline.filter(opp => opp.OpportunityLineItemID === temp.OpportunityLineItemID);

if (matchOppLineItem.length > 0) {
  temp.WBSNumber = matchOppLineItem[0].WBSNumber;
  temp.MaterialID = matchOppLineItem[0].MaterialID;
  temp.ProductDescription = matchOppLineItem[0].ProductDescription;
  temp.StartDate = matchOppLineItem[0].StartDate;
  temp.EndDate = matchOppLineItem[0].EndDate;
}

return temp;
});

// setting red colour object to identify deleted line items at MSD
	let updateTempAr = tableDataRef.map((row) => ({
	...row,
	isRed : !oppline.some(line => line.OpportunityLineItemID === row.OpportunityLineItemID),
	}));
	let updatedOpportunityData = updatedOppData.map((row) => ({
	...row,
	isRed : !oppline.some(line => line.OpportunityLineItemID === row.OpportunityLineItemID),
	})); 
	setOpportunityData(updatedOpportunityData)
	// dispatch(projectActions.setOpportunityData(updatedOpportunityData))
	setTableData(updateTempAr)
	///
	while (typeof costingProfile.ProductTableData === "string") {
		costingProfile.ProductTableData = JSON.parse(costingProfile.ProductTableData);
	  }
	var showText1 = updateTempAr.some(item => item?.isRed);
	var showText2 = costingProfile.ProductTableData.some(item => item?.isRed);
	costingProfile.ProductTableData = updateTempAr;
	costingProfile.OPPDatas = updatedOpportunityData;
	newProjectData.CostingProfiles[0] = costingProfile

	axios.put(`/projects/${getState().currentProject.newProject.ProjectId}`,newProjectData).then((result)=>{
		axios.get(`/projects/${getState().currentProject.newProject.ProjectId}`).then((res)=>{
			dispatch(saveProject(res.data.project,null,null))
			getState().currentProject.newProject.ContractDetails[0].opportunityLineItemDetails= 
			res.data.project.ContractDetails[0].opportunityLineItemDetails
		})
	})
	let profile = costingProfile
	axios.put(`/costingprofiles/${getState().currentCosting.currentCostingProfile.id}`,profile)
	.then((res)=>{
		getState().currentCosting.currentCostingProfile = profile;
		
	})
	dispatch({
		type: "UPDATE_NEW_COSTING",
		currentCostingProfile: profile,
	  });
	  if (showText1 && !showText2) {
		toastr.info("Please check the product line item information from Project Detail Page, make sure the methodology assoication and cost option selection are updated with the newly added or deleted line item")
		}
	// var showText = updateTempAr.some(item => item?.isRed);
	// if (showText) {
	// toastr.info("Please check the product line item information from Project Detail Page, make sure the methodology assoication and cost option selection are updated with the newly added or deleted line item")
	// }
	// if (lineItems?.length === 0) {
	// toastr.info("No new line items added");
	// }
	
	}
}
const handleCommCountryChange = (SalesOrgName,POCRevenueCountry)=>
	{
  
		
	setCurrentProject({
	  SalesOrgName:SalesOrgName,
	  POCRevenueCountry:POCRevenueCountry,
	});
  };
export const updateWbsLevel = (currentProject, WBSDatas, callback, isInternalLoad) => {
	return (dispatch, getState) => {
		if (isInternalLoad) dispatch(appActions.recordLoadStart());
		else dispatch(appActions.pageLoadStart());

		let projectStatus = currentProject.ProjectStatus;
		// const firstValue = WBSDatas[0].status;
		let lengthOfWBS = [];
		let lengthOfClosed = [];
		let lengthOfCancel = [];
		let lengthOfFieldwork = [];	
		let newLengthOfFieldWork = 0;
		let newLengthOfClosed = 0;
		let newLengthOfCancel = 0;
		let pid = currentProject.id;

		let allWbsData = currentProject.CostingProfiles.filter((cp) => ["6"].indexOf(cp.ProfileStatus) !== -1)
		.map((wd) => {
			return handleJSONData(wd?.ProductTableData)?.length;
		});
		let allWbsClosed = currentProject.CostingProfiles.filter((cp) => ["6"].indexOf(cp.ProfileStatus) !== -1)
		.map((fcp) => {
			return handleJSONData(fcp?.ProductTableData)?.filter((ptd) => {
				return ptd.IsClosed;
			});
		});
		let allWbsClosedNew = currentProject.CostingProfiles.filter((cp) => ["6"].indexOf(cp.ProfileStatus) !== -1)
		.map((fcp) => {
			return handleJSONData(fcp?.ProductTableData)?.filter((ptd) => {
				return (!ptd.IsClosed&&ptd.IsCommercial&&ptd.IsCommercialAriba&&ptd.IsOperational&&ptd.IsOperationalAriba);
			});
		});
		let allWbsFieldwork = currentProject.CostingProfiles.filter((cp) => ["6"].indexOf(cp.ProfileStatus) !== -1)
		.map((fcp) => {
			return handleJSONData(fcp?.ProductTableData)?.filter((ptd) => {
				return ptd.IsFieldwork && !ptd.IsClosed;
			});
		});

		let allWbsCancelled = currentProject.CostingProfiles.filter((cp) => ["6"].indexOf(cp.ProfileStatus) !== -1)
        .map((fcp) => {
            return handleJSONData(fcp?.ProductTableData)?.filter((ptd) => {
                return ptd.IsCancel && (!ptd.IsFieldwork ||  !ptd.IsClosed);
            });
        });

		if(allWbsData.length && allWbsData[0] !== '') {
			lengthOfWBS = allWbsData[0];
		}
		if(allWbsClosed.length && allWbsClosed[0] !== '') {
			lengthOfClosed = allWbsClosed[0].length;
		}
		if(allWbsFieldwork.length && allWbsFieldwork[0] !== '') {
			lengthOfFieldwork = allWbsFieldwork[0].length;
		}
		if( allWbsCancelled.length &&  allWbsCancelled[0] !== '') {
            lengthOfCancel = allWbsCancelled[0].length;
        }
		if(allWbsClosedNew && allWbsClosedNew.length && allWbsClosedNew[0] !== '') {
			newLengthOfClosed = allWbsClosedNew[0].length;
		}

		WBSDatas.map((WBSData) => {
			if(WBSData.status == "Fieldwork") {
				newLengthOfFieldWork += 1;
			} else if(WBSData.status == "Cancel"){
				newLengthOfCancel +=1;
			} 
		});
			
		let existingProjects = getState().projects?.items?.map((it) => {
			if (it && it.id == currentProject.id) {
				// for(let i = 0; i < WBSDatas.length; i++){
				// 	if(WBSDatas[i].status !== firstValue) {
				// 		projectStatus = it.ProjectStatus;
				// 	} else if(WBSDatas[i].status == firstValue && firstValue == "Fieldwork" && lengthOfWBS == lengthOfFieldwork) {
				// 		projectStatus = "98";
				// 	} else if(WBSDatas[i].status == firstValue && firstValue == "Closed" && lengthOfWBS == lengthOfClosed) {
				// 		projectStatus = "4";
				// 	} else if (WBSDatas[i].status == firstValue && firstValue == "Cancelled" & false) {
				// 		projectStatus = "2";
				// 	}
				// 	// currentProject.CostingProfiles[0].ProductTableData = currentProject.CostingProfiles.filter((cp) => ["6"].indexOf(cp.ProfileStatus) !== -1).map((cp) => {
				// 	// 	return handleJSONData(cp.ProductTableData).map((ptd) => {
				// 	// 		if(ptd.WBSNumber === WBSDatas[i].WBSNUMBER)
				// 	// 			return {...ptd, IsClosed: WBSDatas[i].status == "Closed" ? true : false, IsFieldwork: WBSDatas[i].status == "Fieldwork" ? true : false};							
				// 	// 		else
				// 	// 			return {...ptd}
				// 	// 	})[0];
				// 	// });
				// }
				if(lengthOfWBS == lengthOfClosed+newLengthOfClosed) {
					projectStatus = "4";
				} else if(lengthOfWBS == lengthOfFieldwork+newLengthOfFieldWork) {
					projectStatus = "98";
				} else if(lengthOfWBS == lengthOfCancel+newLengthOfCancel) {
                    projectStatus = "2";
                }				
				return { ...currentProject, ProjectStatus:  projectStatus};
			} else if (it) return { ...it };
		});

		let payload = {};

		payload = {
			projectid: currentProject.id,
			Updatestatus: WBSDatas	
		};

		axios.post("/projects/updatewbs", payload, {
			headers: { "auth-token": localStorage.getItem("auth-token") },
		}).then((response) => {
			if (callback) callback();
			axios
			.get(`/costingprofiles/${pid}/commissioned`)
			.then((res) => {
				// axios
				//   .get("/costingprofiles/" + reqpro.id)
				//   .then((profile) => {
				existingProjects = existingProjects.map((it) => {
					if (it.id == pid) {
						// let reqpro = _.head(it.CostingProfiles.filter(cp => cp.ProfileStatus == "6"))
						it.CostingProfiles = it.CostingProfiles.map((cp) => {
							if (cp.ProfileStatus == "6") {
								return { ...res.data.costingProfile };
							}
							return { ...cp };
						});
						// currentProject.CostingProfiles = currentProject.CostingProfiles.map(cp => {
						//   if (cp.id == reqpro.id) {
						//     cp = profile.data.costingProfile
						//   }
						//   return { ...cp }
						// })
						return { ...it, isCostingOverviewLoaded: true };
					} else
						return {
							...it,
							isCostingOverviewLoaded: it.isCostingOverviewLoaded,
						};
				});
				dispatch(setProjects({ items: [...existingProjects] }));

				if (callback) callback();
				if (isInternalLoad) dispatch(appActions.recordLoadEnd());
				else dispatch(appActions.pageLoadEnd());
				// }).catch((err) => {
				//   dispatch(appActions.recordLoadEnd());
				// });
				// dispatch({ type: UPDATE_NEW_PROJECT, newProject: res.data.project });
			})
			.catch((err) => {
				dispatch(appActions.recordLoadEnd());
          		toastr.error("Details fetch failed", "WARNING: loading of some commissioned profile specs failed due to long process time (E.g. Total waves, Net Revenue, Out of Pocket etc.)");
			});
		}).catch((err) => {
			if (callback) callback();
			// dispatch(setProjects({ items: [...existingProjects] }));
			console.error(err);
			if (isInternalLoad) dispatch(appActions.recordLoadEnd());
			else dispatch(appActions.pageLoadEnd());
		});
	};
};

export const saveIndividualProject = (
	currentProject,
	callback,
	isInternalLoad,MSDUpdated
) => {
	return (dispatch, getState) => {
	
		dispatch(appActions.recordLoadStart());
	


		axios
			.put("/projects/" + currentProject.ProjectId, {...currentProject,LastMSDUpdate:MSDUpdated? new Date():currentProject.LastMSDUpdate}, {
				headers: { "auth-token": localStorage.getItem("auth-token") },
			})
			.then((res) => {
				if (callback) callback();
				dispatch(appActions.recordLoadEnd());
			})
			.catch((err) => {
				if (callback) callback();
				console.error(err);
				if (isInternalLoad) dispatch(appActions.recordLoadEnd());
				else dispatch(appActions.pageLoadEnd());
			});
	};
};

export const syncContactDetails = (
	projectId,
	contact,
	updateProfile,
	callback
) => {
	return (dispatch, getState) => {
		dispatch(appActions.recordLoadStart());
		dispatch({
			type: SET_CURRENT_LOADING_CONTACT,
			contact,
		});
		axios
			.get(`/salesforce/${contact.OpportunityNumber}`)
			.then((response) => {
				let currentCostingProfile = {
					...getState().currentCosting.currentCostingProfile,
				};
				let currentProject = { ...currentCostingProfile.Project };
				let existingProjects = getState().projects.items.map((it) => {
					if (it.id == projectId) {
						it.ContractDetails = it.ContractDetails.map((cd) => {
							if (cd.id == contact.id)
								cd = {
									...cd,
									...response.data.opportunity,
									updateAt: moment().format("YYYY-MM-DD hh:mm:ss"),
									isUpdated: true
								};
							return { ...cd };
						});
						dispatch(
							saveIndividualProject(
								it,
								() =>
									dispatch({
										type: SET_CURRENT_LOADING_CONTACT,
										contact: {},
									}),
								true,true
							)
						);
						return { ...it };
					} else return { ...it };
				});
				dispatch(setProjects({ items: [...existingProjects] }));

				if (
					currentProject &&
					currentProject.ContractDetails &&
					currentProject.ContractDetails.length
				) {
					if (currentProject.ContractDetails)
						currentProject.ContractDetails = currentProject.ContractDetails.map(
							(cd) => {
								if (cd.id == contact.id)
									cd = { ...cd, ...response.data.opportunity };
								return { ...cd };
							}
						);
					dispatch(
						saveIndividualProject(
							{ ...currentProject },
							updateProfile
								? () => {
									axios
										.get("/costingprofiles/" + currentCostingProfile.id)
										.then((res) => {
											let reqProjectClients =
												res.data.costingProfile.Project?.ContractDetails;
											let sfClients = reqProjectClients.filter(
												(rpc) => rpc.isSF
											);
											if (
												sfClients.filter(
													(sc) =>
														sc.Probability >=
														res.data.costingProfile.ProfileSetting
															.MinimumSFProbability
												).length &&
												callback
											) {
												callback();
											} else {
												toastr.error(
													"Commissioning Not Allowed",
													`Please ensure Microsoft Dynamics opportunity is update-to-date with  ${res.data.costingProfile.ProfileSetting.MinimumSFProbability}% probability`
												);
											}
											dispatch(appActions.recordLoadEnd());
											dispatch({
												type: currentCostingActions.SELECT_COSTING,
												profile: res.data.costingProfile,
											});
										})
										.catch((error) => {
											dispatch(appActions.recordLoadEnd());
											toastr.error(
												"Failed updating Profile",
												error.data.message
											);
										});
								}
								: callback,
							true
						)
					);
				}
			})
			.catch((error) => {
				dispatch(appActions.recordLoadEnd());
				toastr.error("Microsoft Dynamics details sync failed", error?.data?.message);
			});
	};
};

export const msdRefresh = (
	projectId,
	codeLabels,
	contact,
	callback,
	summary,
	history
) => {
	return (dispatch, getState) => {
		dispatch(appActions.recordLoadStart());
		dispatch({
			type: SET_CURRENT_LOADING_CONTACT,
			contact,
		});
		let res;
		axios
			.get(`/salesforce/${contact.OpportunityNumber}`)
			.then((response) => {
				//sample response for testing
				// response.data.opportunity.opportunityLineItemDetails =
				//   res = response.data.opportunity.opportunityLineItemDetails;
				if(getState().projects.items.length && !summary){			
				let existingProjects = getState().projects.items.map((it) => {
					if (it.id == projectId) {
						let project = getState().currentProject.newProject
					let filtOp = response.data.opportunity.opportunityLineItemDetails.filter((item) => {
						if (item.MaterialID?.slice(0,16).toLowerCase().replace(' ','') === "expenserecharge") {
							item.CharacteristicValues = {Z_FIELDINGMETHOD:["EXPENSE RECHARGE"]};
							return true;
						  } else if (
						(item.MaterialID?.slice(0,4).toLowerCase() === "cmi-" || item.MaterialID?.slice(0,3).toLowerCase() === "cmi" || item.MaterialID?.slice(0,3).toLowerCase() === "ci-" || item.MaterialID?.slice(0,4).toLowerCase().replace(' ','') === "tbc") && (item.CharacteristicValues !== null)) {
						  return true;
						} else {
						  return false;
						}
					})
						response.data.opportunity.opportunityLineItemDetails = filtOp;
						res = response.data.opportunity.opportunityLineItemDetails;
	
						it.ContractDetails = it.ContractDetails.map((cd) => {
							if(cd.AccountName !== response.data.opportunity.accountDetails.AccountName){
								cd.AccountName = response.data.opportunity.accountDetails.AccountName
							}
							if(cd.Industry!== response.data.opportunity.accountDetails.Industry){
								cd.Industry = response.data.opportunity.accountDetails.Industry

							}
							if (project?.SalesOrgName !== null) {
								let SalesOrgcodes= response?.data?.opportunity?.SalesOrgcode
            					let SalesOrgcodeFilterArray=codeLabels.SalesOrgcodes.filter((code)=>code.SalesOrgcode	== SalesOrgcodes)
								if( !SalesOrgcodeFilterArray.length) {
									SalesOrgcodeFilterArray = [{PocRevenueCountry: "N/A"}]
								}
								handleCommCountryChange(response?.data?.opportunity?.SalesOrgName, SalesOrgcodeFilterArray[0].PocRevenueCountry)
								it.SalesOrgName = response?.data?.opportunity?.SalesOrgName;
								it.POCRevenueCountry = SalesOrgcodeFilterArray[0].PocRevenueCountry;
								// project
								project.SalesOrgName = response?.data?.opportunity?.SalesOrgName;
								project.POCRevenueCountry = SalesOrgcodeFilterArray[0].PocRevenueCountry;
							}
							// handleCommCountryChange
							if (cd.id == contact.id)
								cd = {
									...cd,
									...response.data.opportunity,
									updateAt: moment().format("YYYY-MM-DD hh:mm:ss"),
									isUpdated: true
								};
							return { ...cd };
						});
						it.refreshMsd = true
						dispatch(
							saveIndividualProject(
								it,
								()=>{toastr.success("Microsoft Dynamics details sync success");},
								true,true
							)
						);
						dispatch(setOppourtnityLineItems(response.data.opportunity.opportunityLineItemDetails));
						callback(response.data.opportunity.opportunityLineItemDetails);
						return { ...it };
					} else return { ...it };
				});}
				else
				{
					let project = getState().currentProject.newProject
					let filtOp = response.data.opportunity.opportunityLineItemDetails.filter((item) => {
						if (item.MaterialID?.slice(0,16).toLowerCase().replace(' ','') === "expenserecharge") {
							item.CharacteristicValues = {Z_FIELDINGMETHOD:["EXPENSE RECHARGE"]};
							return true;
						  } else if (
						(item.MaterialID?.slice(0,4).toLowerCase() === "cmi-" || item.MaterialID?.slice(0,3).toLowerCase() === "cmi" || item.MaterialID?.slice(0,3).toLowerCase() === "ci-" || item.MaterialID?.slice(0,4).toLowerCase().replace(' ','') === "tbc") && (item.CharacteristicValues !== null)) {
						  return true;
						} else {
						  return false;
						}
			});
			//console.log("filtOp", filtOp);
			response.data.opportunity.opportunityLineItemDetails = filtOp;
			res = response.data.opportunity.opportunityLineItemDetails;
			if (project?.SalesOrgName !== null) {
				let SalesOrgcodes= response?.data?.opportunity?.SalesOrgcode
				let SalesOrgcodeFilterArray=codeLabels.SalesOrgcodes.filter((code)=>code.SalesOrgcode	== SalesOrgcodes)
				if( !SalesOrgcodeFilterArray.length) {
					SalesOrgcodeFilterArray = [{PocRevenueCountry: "N/A"}]
				}
				handleCommCountryChange(response?.data?.opportunity?.SalesOrgName, SalesOrgcodeFilterArray[0].PocRevenueCountry)
				project.SalesOrgName = response?.data?.opportunity?.SalesOrgName;
				project.POCRevenueCountry = SalesOrgcodeFilterArray[0].PocRevenueCountry;
			}
					
					project.ContractDetails = project.ContractDetails.map((cd) => {
						if (cd.id == contact.id)
							cd = {
								...cd,
								...response.data.opportunity,
								opportunityLineItemDetails:response.data.opportunity.opportunityLineItemDetails.map(ol=>{

								return({...ol,id:cd.opportunityLineItemDetails.find(_ol=>_ol.OpportunityLineItemID == ol.OpportunityLineItemID).id})
								})
								,
								updateAt: moment().format("YYYY-MM-DD hh:mm:ss"),
								isUpdated: true
							};
						return { ...cd };
					});
					dispatch(refresh(res,history));
					project.refreshMsd = true
					dispatch(
						saveIndividualProject(
							project,
							()=>{callback(); toastr.success("Microsoft Dynamics details sync success");},
							true,true
						)
					);
				}
			})
			.catch((error) => {
				dispatch(appActions.recordLoadEnd());
				console.log("api response",res);
				
			
				
				if (!summary) {
					toastr.error("Microsoft Dynamics details sync failed", error?.data?.message);
				} else {
					dispatch(refresh(res,history));
					// toastr.error("Line items changed. Please perform MSD refresh", error?.data?.message);
					setTimeout(()=>{
						history.push("/proposal-new");
					},5000)
					
				}
				
			});
	};
};

export const setNewCountryValue = (value) => {
    return (dispatch) => {
        dispatch({ type: SET_NEW_TAB_COUNTRY, value})
    }
}

export const setNewMethValue = (value) => {
    return (dispatch) => {
        dispatch({ type: SET_NEW_TAB_METH, value})
    }
}
		
