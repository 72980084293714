// Sidebar

export const SAVING = "SAVING";
export const RESENDING = "RESENDING";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";


export const SEARCH_PROPOSALS_REQUEST = "SEARCH_PROPOSALS_REQUEST";
export const SEARCH_PROPOSALS_SUCCESS = "SEARCH_PROPOSALS_SUCCESS";
export const SAVE_VENDORBIDDING_REQUEST = "SAVE_VENDORBIDDING_REQUEST";


export const FETCH_PROPOSALS_REQUEST = "FETCH_PROPOSALS_REQUEST";
export const FETCH_PROPOSALS_SUCCESS = "FETCH_PROPOSALS_SUCCESS";
export const FETCH_PROPOSALS_FAILURE = "FETCH_PROPOSALS_FAILURE"; 


export const FETCH_PROPOSAL_DETAILS_REQUEST = "FETCH_PROPOSAL_DETAILS_REQUEST"; 
export const FETCH_PROPOSAL_DETAILS_SUCCESS = "FETCH_PROPOSAL_DETAILS_SUCCESS"; 
export const FETCH_PROPOSAL_DETAILS_FAILURE = "FETCH_PROPOSAL_DETAILS_FAILURE"; 


export const FETCH_PROPOSAL_WO_DETAILS_REQUEST = "FETCH_PROPOSAL_WO_DETAILS_REQUEST";
export const FETCH_PROPOSAL_WO_DETAILS_SUCCESS = "FETCH_PROPOSAL_WO_DETAILS_SUCCESS";
export const FETCH_PROPOSAL_WO_DETAILS_FAILURE = "FETCH_PROPOSAL_WO_DETAILS_FAILURE"; 



export const SAVE_PROPOSAL_REQUEST = "SAVE_PROPOSAL_REQUEST";
export const SAVE_PROPOSAL_REQUEST_DATA = "SAVE_PROPOSAL_REQUEST_DATA";
export const SAVE_PROPOSAL_SELECTEDVENDOR = "SAVE_PROPOSAL_SELECTEDVENDOR";
export const SAVE_PROFILE_REQUEST = "SAVE_PROFILE_REQUEST";
export const SAVE_VENDOR_REQUEST = "SAVE_VENDOR_REQUEST";
export const SAVE_PROPOSAL_SUCCESS = "SAVE_PROPOSAL_SUCCESS";
export const SAVE_PROFILE_SUCCESS = "SAVE_PROFILE_SUCCESS";
export const SAVE_PROPOSAL_FAILURE = "SAVE_PROPOSAL_FAILURE"; 
export const SAVE_PROFILE_FAILURE = "SAVE_PROFILE_FAILURE"; 


export const CLEAR_PROPOSAL_STATE = "CLEAR_PROPOSAL_STATE";
export const API_PROPOSAL_REDIRECT_TO_EDIT = 'API_PROPOSAL_REDIRECT_TO_EDIT';

export const ACKNOWLEDGE_VENDOR_BIDDING = "ACKNOWLEDGE_VENDOR_BIDDING"; 
export const ACKNOWLEDGE_VENDOR_BIDDING_SUCCESS = "ACKNOWLEDGE_VENDOR_BIDDING_SUCCESS"; 


export const FETCH_PROPOSAL_VENDOR_DETAILS_REQUEST = "FETCH_PROPOSAL_VENDOR_DETAILS_REQUEST";
export const FETCH_PROPOSAL_VENDOR_DETAILS_SUCCESS = "FETCH_PROPOSAL_VENDOR_DETAILS_SUCCESS";
export const FETCH_PROPOSAL_VENDOR_DETAILS_FAILURE = "FETCH_PROPOSAL_VENDOR_DETAILS_FAILURE"; 

export const FETCH_VENDOR_BIDDING_SUMMARY_REQUEST = "FETCH_VENDOR_BIDDING_SUMMARY_REQUEST";
export const FETCH_VENDOR_BIDDING_SUMMARY_SUCCESS = "FETCH_VENDOR_BIDDING_SUMMARY_SUCCESS";
export const FETCH_VENDOR_BIDDING_SUMMARY_FAILURE = "FETCH_VENDOR_BIDDING_SUMMARY_FAILURE"; 

export const FETCH_CHAT_REQUEST = "FETCH_CHAT_REQUEST";
export const FETCH_CHAT_SUCCESS = "FETCH_CHAT_SUCCESS";
export const FETCH_CHAT_FAILURE = "FETCH_CHAT_FAILURE"; 

export const SAVE_CHAT_REQUEST = "SAVE_CHAT_REQUEST";
export const SAVE_CHAT_SUCCESS = "SAVE_CHAT_SUCCESS";
export const SAVE_CHAT_FAILURE = "SAVE_CHAT_FAILURE"; 

export const SET_LOADING = "SET_LOADING";
export const SET_PROPOSALS = "SET_PROPOSALS";
export const APPEND_PROPOSALS = "APPEND_PROPOSALS";
export const FETCH_MORE_PROPOSALS = "FETCH_MORE_PROPOSALS";
export const SET_HAS_MORE_PROPOSALS = "SET_HAS_MORE_PROPOSALS";


export const SAVE_APPROVED_BIDDING_VENDORS_REQUEST = "SAVE_APPROVED_BIDDING_VENDORS_REQUEST";
export const SAVE_APPROVED_BIDDING_VENDORS_SUCCESS = "SAVE_APPROVED_BIDDING_VENDORS_SUCCESS";
export const SAVE_APPROVED_BIDDING_VENDORS_FAILURE = "SAVE_APPROVED_BIDDING_VENDORS_FAILURE"; 


export const SEND_EARLY_WARNING_NOTE = "SEND_EARLY_WARNING_NOTE";
export const SEND_EARLY_WARNING_NOTE_REQUEST = "SEND_EARLY_WARNING_NOTE_REQUEST";
export const SEND_EARLY_WARNING_NOTE_SUCCESS = "SEND_EARLY_WARNING_NOTE_SUCCESS";
export const SEND_EARLY_WARNING_NOTE_FAILURE = "SEND_EARLY_WARNING_NOTE_FAILURE";

export const DATA_CHANGED = "DATA_CHANGED";
export const OTHER_COST_CHANGED = "OTHER_COST_CHANGED";
export const SAVE_ADDITIONAL_COST = "SAVE_ADDITIONAL_COST";
export const UPDATE_ADDITIONAL_COST_TO_DATA = "UPDATE_ADDITIONAL_COST_TO_DATA";

