import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  CardTitle,
  ModalHeader,
  Row,
  FormGroup,
  Input,
  FormFeedback,
} from "reactstrap";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import Spinner from "../../../components/Spinner";
import { imgStyle } from "../DynamicRFQ/ModalRFQ";
import logo from "../../../assets/img/avatars/modal-logo.png";

import {
  createRecepientContact,
  updateRecContact,
  deleteReciepientContact,
} from "../../../redux/actions/marketDefaultsActions";
import { Dropdown } from "bootstrap";

const ReceipentContacts = (props) => {
  const dispatch = useDispatch();

  const app = useSelector(({ app }) => app);
  const [addMail, setAddMail] = useState();
  const [approverMail, setApproverMail] = useState();
  const [addContactModal, setAddContactModal] = useState(false);
  const [currentSavingContact, setCurrentSavingContact] = useState({});
  const [deletableContact, setDeletableContact] = useState({});
  const [deleteContactModal, setDeleteContactModal] = useState(false);
  const [recipientdata, setRecipientsdata] = useState([]);

  const [addMailError, setAddMailError] = useState();
  useEffect(() => {
    let recipient = [];
    // props.approvalSetting.ApproverContacts.map((approver)=>{
    // 	return approver.RecipientContacts.map((rec)=>{
    // 			return recipient.push(rec)
    // 	})
    // })
    props.approvalSetting.RecipientContacts.map((rec) => {
      return recipient.push(rec);
    });
    setRecipientsdata(recipient);
  }, [props.approvalSetting.ApproverContacts]);
  const addContact = () => {
    // if(props.approvalSetting.ApproverContacts.length<2){
    // 	setApproverMail(props.approvalSetting.ApproverContacts[0].id)

    // }
    if (recipientdata.filter((ac) => ac.EmailAddress == addMail).length) {
      setAddMailError("Email already exists to current approver");
    } else {
      setAddMailError(null);
      dispatch(
        createRecepientContact(
          approverMail,
          { EmailAddress: addMail },
          () => {
            setAddContactModal(false);
            setAddMail(null);
          },
          props.currentCountry.id
        )
      );
    }
  };
  const saveRecContacts = (row) => {
    props.setLocalPageload(true);
    let finalAppSet = _.head(recipientdata.filter((as) => as.id == row.id));
    if (finalAppSet !== undefined) {
      dispatch(
        updateRecContact(
          finalAppSet.id,
          finalAppSet,
          () => {
            setCurrentSavingContact(null);
            props.setLocalPageload(false);
          },
          props.currentCountry.id
        )
      );
    } else {
      dispatch(
        updateRecContact(
          row.id,
          row,
          () => {
            setCurrentSavingContact(null);
            props.setLocalPageload(false);
          },
          props.currentCountry.id
        )
      );
    }
  };

  const columns = [
    { dataField: "id", text: "#", editable: false },
    // {
    // 	dataField: "ApprovalSettingId",
    // 	text: "Approval Setting Id",
    // 	editable: false,
    // 	validator: (newValue, row, column) => {
    // 		return validator(newValue, row, column);
    // 	},
    // },
    {
      dataField: "EmailAddress",
      text: "Email Address",
      editable: true,
      validator: (newValue, row, column) => {
        return validator(newValue, row, column);
      },
    },
    {
      dataField: "IsMandatoryRecipient",
      text: "Mandatory Recipient",
      editable: true,
      // editor: {
      //     type: Type.CHECKBOX
      // },
      editorRenderer: (
        editorProps,
        value,
        row,
        column,
        rowIndex,
        columnIndex
      ) => (
        <input
          {...editorProps}
          onChange={(e) => {
            e.persist();
            editorProps.onUpdate(e.target.checked);
            let initialPropsNew = [...recipientdata];
            initialPropsNew = initialPropsNew.map((app) => {
              if (app.id == row.id) {
                app.IsMandatoryApprover = e.target.checked;
              }
              return { ...app };
            });
            setRecipientsdata(recipientdata);
          }}
          type="checkbox"
          defaultChecked={value}
        />
      ),
    },
    { dataField: "CreatedBy", text: "Created By", editable: false },
    { dataField: "updatedAt", text: "Updated At", editable: false },
    {
      text: "Actions",
      editable: false,
      formatter: (cell, row) => {
        return (
          <div className="d-flex">
            <Button
              color="success"
              size="sm"
              onClick={() => {
                saveRecContacts(row);
                setCurrentSavingContact(row);
              }}
            >
              Save{" "}
              {/* {app.recordloading && currentSavingContact.id == row.id ? (
								<Spinner size="small" color="#495057" />
							) : null} */}
            </Button>
            <Button
              color="danger"
              size="sm"
              className="ml-2"
              onClick={() => {
                setDeletableContact(row);
                setDeleteContactModal(true);
              }}
            >
              Delete
            </Button>
          </div>
        );
      },
    },
  ];
  const validator = (newValue, row, column) => {
    //console.log(newValue, column);
    if (!newValue) {
      return {
        valid: false,
        message: "Value is required",
      };
    }
    return true;
  };
  return (
    <div>
      <Card>
        <CardHeader className="d-flex justify-content-between">
          <CardTitle>
            #{props.approvalSetting.id} - Approval email notification CC
            recipient contacts ({props.approvalSetting.Label})
          </CardTitle>
          <Button
            className="btn-primary float-right"
            color="primary"
            onClick={() => {
              setAddContactModal(true);
              setApproverMail(props.approvalSetting.id);
            }}
          >
            Add Contact
          </Button>
        </CardHeader>
        <CardBody>
          <Row>
            {recipientdata && (
              <BootstrapTable
                className="pb-0 mb-0"
                hover
                bootstrap4
                striped
                data={recipientdata}
                columns={columns}
                keyField="id"
                // onTableChange=
                // remote={{ cellEdit: true }}
                cellEdit={cellEditFactory({
                  mode: "click",
                  blurToSave: true,
                  // afterSaveCall: (oldValue, newValue, row, column) => {

                  //     //console.log(row, column);
                  // }
                })}
              />
            )}
          </Row>
        </CardBody>
      </Card>

      <Modal
        isOpen={addContactModal}
        toggle={() => {
          setAddContactModal(false);
          setAddMail(null);
          setAddMailError(null);
        }}
      >
        <ModalHeader
          toggle={() => {
            setAddContactModal(false);
            setAddMail(null);
            setAddMailError(null);
          }}
        >
          {/* <img src={logo} className="modallogo" /> */}
        </ModalHeader>
        <span className="modaltitle p-0">Add Recipient Contact</span>
        <ModalBody>
          <Row className="mb-2 ml-1">
            <strong>
              Commissioning Country: <i>{props.currentCountry.Label}</i>
            </strong>
          </Row>
          <Row className="mb-2 ml-1">
            <strong>
              Business Unit: <i>{props.businessUnit.Label}</i>
            </strong>
          </Row>
          <Row className="mb-2 ml-1">
            <strong>
              Vertical: <i>{props.vertical.Label}</i>
            </strong>
          </Row>
          <Row className="mb-2 ml-1">
            <strong>
              Approval: <i>{props.approvalSetting.Label}</i>
            </strong>
          </Row>
          {/* <Row className="mb-2 ml-1">
            <>
              <strong>
                Please select the approver for which the recepient should be
                added:
              </strong>
              <FormGroup>
                <Input
                  type="select"
                  id="approvercontact"
                  name="approvercontact"
                  onChange={(e) => setApproverMail(e.target.value)}
                >
                  {props.approvalSetting.ApproverContacts.length > 1 ? (
                    <option value="">Please select an option</option>
                  ) : null}
                  {props.approvalSetting.ApproverContacts.map((approver) => {
                    return (
                      <option value={approver.id}>
                        {approver.EmailAddress}
                      </option>
                    );
                  })}
                </Input>
                <FormFeedback>
                  Please select the approver for which the recepient should be
                  added
                </FormFeedback>
              </FormGroup>
            </>
          </Row> */}
          <form
            onSubmit={(e) => {
              e.preventDefault();
              addContact();
            }}
          >
            <Row className="mb-2 ml-1">
              <Col>
                <strong>Email Address</strong>
              </Col>
              <Col>
                <input
                  className="form-control"
                  type="email"
                  required
                  onChange={(e) => setAddMail(e.target.value)}
                />
              </Col>
            </Row>

            {addMailError ? (
              <Row className="ml-2">
                <p className="error">{addMailError}</p>
              </Row>
            ) : null}
            <Row className=" ml-0 mt-2 justify-content-start">
              <Button color="primary" type="submit" className="mr-2">
                Add
                {app.recordloading ? (
                  <Spinner size="small" color="#495057" />
                ) : null}
              </Button>
              <Button
                color="secondary"
                onClick={() => {
                  setAddContactModal(false);
                  setAddMail(null);
                  setAddMailError(null);
                }}
              >
                Cancel
              </Button>
            </Row>
          </form>
          {/* <CreatableSelect
                    onChange={val => {
                        let approver = val ? _.head((getApproverContactOptions(allApprovers)).filter(ap => ap.EmailAddress == val.value)) : {}
                        setApproverContactToAdd(approver)
                    }}
                    options={getApproverContactOptions(allApprovers).map(contact => {
                        return { value: contact.EmailAddress, label: contact.EmailAddress }
                    })}
                /> */}
        </ModalBody>
        {/* <ModalFooter>
                <Button
                    className="btn-warning btn-sm"
                    onClick={() => setAddContactModal(!addContactModal)}>Cancel</Button>
                <Button
                    className="btn-success btn-sm"
                    onClick={() => dispatch(createApprover(props.vertical.id, approverContactToAdd, () => setAddContactModal(!addContactModal)))}>Add</Button>
            </ModalFooter> */}
      </Modal>
      <Modal
        size="sm"
        isOpen={deleteContactModal}
        toggle={() => setDeleteContactModal(!deleteContactModal)}
      >
        <ModalHeader toggle={() => setDeleteContactModal(!deleteContactModal)}>
          Delete Recipient Contact
        </ModalHeader>
        <ModalBody>
          <strong>
            This change is irreversible. Are you sure want to delete{" "}
            <i>{deletableContact?.EmailAddress}</i>
          </strong>
        </ModalBody>
        <ModalFooter>
          <Row className="justify-content-end mt-2 mr-4">
            <Button
              size="sm"
              color="secondary"
              onClick={() => {
                setDeleteContactModal(false);
                setDeletableContact(null);
              }}
            >
              Cancel
            </Button>
            <Button
              size="sm"
              color="primary"
              className="ml-2"
              onClick={() => {
                props.setLocalPageload(true);
                dispatch(
                  deleteReciepientContact(
                    deletableContact,
                    () => {
                      setDeleteContactModal(false);
                      setDeletableContact(null);
                      props.setLocalPageload(false);
                    },
                    props.currentCountry.id
                  )
                );
              }}
            >
              Confirm
              {app.recordloading ? (
                <Spinner size="small" color="#495057" />
              ) : null}
            </Button>
          </Row>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default ReceipentContacts;
