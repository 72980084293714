import * as appActions from "../../redux/actions/appActions";
import axios from "../../axios-interceptor";
import { toastr } from "react-redux-toastr";
import React, { useState, useEffect, Suspense } from "react";
import ManualCostEntry from "../costingProfile/ManualCostEntry";
import {
    AvForm,
    AvField,
    AvRadioGroup,
    AvInput,
    AvRadio,
    AvSelectField
} from "availity-reactstrap-validation";
import {
    Col, Row, Card,
    CardBody,
    CardTitle,
    CardHeader, FormFeedback, FormGroup, Nav, NavItem, NavLink, TabContent, TabPane
    , Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip, UncontrolledCollapse,
    Table,
    Container
} from "reactstrap";
import { useSelector } from "react-redux";
import {
    CustomInput,
    Input,
    Label,
    Button,
} from "reactstrap";
import { useDispatch } from "react-redux";
import { getMultiOptions } from "../../utils/codeLabels";
import classnames from "classnames";
import './common.css';
import CostingOption from "./CostingOptionFinal";
import TestPageRFQ from "./TestPageRFQ";
import TestPageOpResource from "./TestOpResource";
import RFQForm from "./TestPageRFQ";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faCopy, faPlus, faPlusCircle, faTimesCircle, faFileUpload, faSpinner } from "@fortawesome/free-solid-svg-icons";
//import CostingOption from "./CostingOption";
import _ from "lodash";
import Select from "react-select";
import update from 'immutability-helper';
import * as sideBarActions from "../../redux/actions/sidebarActions";
import Settings from "../../components/Settings";
import TestResponsibilities from "./TestResponsibilities";
import CostSpreadsheet from "./CostSpreadsheet";
import * as mapperFunctions from "../../utils/rfqMapper";
import * as currentCostingActions from "../../redux/actions/currentCostingActions";
import RecordsSpinner from "../../components/RecordsSpinner";
import CommercialHours from "../costingProfile/CommercialHours";


const CostInput = ({ profile, updateProject }) => {
    const dispatch = useDispatch();
    const [spreadSheetData, setSpreadSheetData] = useState(null);
    const [showSpreadSheet, setShowSpreadSheet] = useState(false);
    const [activeTabSD, setActiveTabSD] = useState(0);
    const [tempData, setTempData] = useState(null);


    function toggleSD(tab) {
        if (activeTabSD !== tab) {
            setActiveTabSD(tab);
        }
    };
    function saveCosting()
    {
        let data={CostingData: JSON.stringify(spreadSheetData),CostingProfileId:profile.id};
         
        dispatch(appActions.recordLoadStart());
        axios
        .post("/costingdata/save",data,{
            headers: { "auth-token": localStorage.getItem("auth-token") },
        })
        .then((res) => {
            toastr.success("Success", "Data Saved Successfully");
        })
        .catch((err) => {
            toastr.error("Save Failed", err?.data?.error);
            dispatch(appActions.recordLoadEnd());
        }); 
    } 

    return (
        <React.Fragment>
    Jairam        
    {showSpreadSheet && spreadSheetData &&
    <>
    
        <Col md={12} className={'countryTabs'}>                                        
            <div className={"tab"}>
                <Nav tabs>
                    {spreadSheetData &&
                        spreadSheetData.map((sd, indxSD) => (
                            <NavItem key={sd.value}>
                                <NavLink className={classnames({ active: activeTabSD === indxSD })}
                                    onClick={() => {
                                        toggleSD(indxSD);
                                    }}
                                >
                                    <b>{sd.label}</b>
                                </NavLink>
                            </NavItem>
                        ))}
                </Nav>
                <TabContent activeTab={activeTabSD}>                                 
                    {spreadSheetData &&
                        spreadSheetData.map((sd, indxSD) => (
                            activeTabSD === indxSD &&
                            <TabPane tabId={indxSD} key={sd.value}>
                                <Row>
                                    <Col md={12} className={"methodologyTabs"}>
                                        <CostSpreadsheet optionsInput={sd.options} spreadSheetData={spreadSheetData} setSpreadSheetData={setSpreadSheetData} tempData={tempData} setTempData={setTempData} indxSD={indxSD} />
                                    </Col>
                                </Row>
                            </TabPane>
                        ))}
                </TabContent>
            </div>
        </Col>
        <Col md={12}>
            <Button color="primary" onClick={() => {
                setShowSpreadSheet(false);
            }}>
                Back
           </Button>

           <Button style={{ marginLeft: "10px" }} color="primary" onClick={() =>
            {
                saveCosting();
            }}>
                Save Costing
           </Button>
        </Col>
    </>
}
</React.Fragment>
    );
};
export default CostInput;
