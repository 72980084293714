import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { customFilter, multiSelectFilter, selectFilter, textFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import Select from "react-select";
import _ from "lodash";
import classnames from "classnames";
import update from "immutability-helper";
import * as currentProjectActions from "../../../redux/actions/currentProjectActions";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  faFilter
} from "@fortawesome/free-solid-svg-icons";
import { toastr } from "react-redux-toastr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
  Table,
  Media
} from "reactstrap";

import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledCollapse,
} from "reactstrap";
import {
  faChevronDown,
  faChevronUp,
  faSpinner,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import cellEditFactory from "react-bootstrap-table2-editor";
import AdditionalCostPopUp from './AdditionalCostPopUp';
import { ReOpenRequest } from '../../../utils/GoogleAnalyticsEvents';
import logo from "../../../assets/img/avatars/modal-logo.png";
import { imgStyle } from '../../marketDefault/DynamicRFQ/ModalRFQ';

function InternalCostView({ project, vendorSelection, setVendorSelection }) {
  const [activeTabCo, setActiveTabCo] = useState(0);
  const [activeTabFC, setActiveTabFC] = useState(0);
  const [activeTabSM, setActiveTabSM] = useState(0);
  const [InternalCost, setInternalCost] = useState(false);
  const [viewonlymode, setViewOnlyMode] = useState(false);
  let [activeCoId, setactiveCoId] = useState()
  const [modelRowIndex, setModelSelectedRow] = useState([]);
  var [staticModelData, setstaticModelData] = useState([]);
  const vendorAndList = useSelector(({ fetchVendorAndList }) => fetchVendorAndList.vendorAndList);
  const codeLabels = useSelector(({ codeLabels }) => codeLabels);
  var [Tabdata, setTabdata] = useState([]);
  const [currentCurrencySelection, setCurrentCurrencySelection] = useState({});
  const dispatch = useDispatch();
  //forPop-Up
  const [internalAdditionalCost, setInternalAdditionalCost] = useState([])
  const [finalCosting, setfinalCosting] = useState(0);
  const [Costing, setCosting] = useState(0);
  //table
  const [internalCostData, setInternalCostData] = useState([]);
  var imgStyle = {
    width: "150px",
  };
  var ModalFooterStyle = {
    display: "flex",
    justifyContent: "flex-Start",
  };
  useEffect(() => {
    setList();
    setViewOnlyMode(((["2", "3", "4", "5", "6"].indexOf(_.last(project?.CostingProfiles).ProfileStatus) != -1) ||
      (project.CostingProfiles[project?.CostingProfiles?.length - 1]?.VenodrBidDeadLineStatus == "close") ||
      (project?.ProjectStatus == 7)) ? true : false)
  }, [])

  useEffect(() => {
    if (
      project?.CostingProfiles[
        project?.CostingProfiles?.length - 1
      ]?.CountrySpecs.map(
        (cs) =>
          cs?.MethodologySpecs.filter((y, index) => y.CostingType == 3 && !y.NotApplicable).length
      ).reduce((r, e) => r + e) > 0
    ) {
      //Finding First Country for Vendor Bidding
      var indexfc = project.CostingProfiles[
        project?.CostingProfiles?.length - 1
      ].CountrySpecs.filter(
        (cs) =>
          cs.MethodologySpecs.filter((sm) => sm.CostingType == 3 && !sm.NotApplicable).length > 0
      )[0].id;
      setActiveTabFC(
        project.CostingProfiles[
          project?.CostingProfiles?.length - 1
        ].CountrySpecs.findIndex((m) => m.id == indexfc)
      );

      //Finding First Methodology for Vendor Bidding
      var indexsm = project.CostingProfiles[
        project?.CostingProfiles?.length - 1
      ].CountrySpecs.filter((m) => m.id == indexfc)[0].MethodologySpecs.filter(
        (y, index) => y.CostingType == 3
      )[0]?.id;
      setActiveTabSM(
        project.CostingProfiles[
          project?.CostingProfiles?.length - 1
        ].CountrySpecs.filter(
          (m) => m.id == indexfc
        )[0].MethodologySpecs.findIndex((m) => m.id == indexsm)
      );
      setactiveCoId(
        project.CostingProfiles[
          project?.CostingProfiles?.length - 1
        ].CountrySpecs.filter(
          (m) => m.id == indexfc
        )[0].MethodologySpecs.filter((m) => m.id == indexsm)[0]
          ?.CostingOptions.filter(x => x.Selected)[0].id
      );

      setActiveTabCo(0);
    }
  }, []);

  useEffect(() => {
    let currentMethodology = project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
    let tempVS = vendorSelection.map(tempVendor => {
      if (tempVendor.Costingtype != "Total Internal Cost") {
        tempVendor.ConstantCostFromVendor = isNaN(tempVendor?.ConstantCostFromVendor) ? 0 : tempVendor.ConstantCostFromVendor
        tempVendor.TotalCostusd = isNaN(tempVendor?.ConstantCostFromVendor) ? 0 : tempVendor.ConstantCostFromVendor
        tempVendor.TotalCost = isNaN(tempVendor.CostFromVendor) ? 0 : tempVendor.CostFromVendor
        tempVendor.FinalCPIusd = isNaN(_.round((tempVendor.Costingtype == "Field Cost" ? tempVendor?.AchivableSamples ? (tempVendor.ConstantCostFromVendor / tempVendor.AchivableSamples) : 0 : (tempVendor.ConstantCostFromVendor / tempVendor.SampleSize)), 2)) ? 0 :
          _.round((tempVendor.Costingtype == "Field Cost" ? tempVendor?.AchivableSamples ? (tempVendor.ConstantCostFromVendor / tempVendor.AchivableSamples) : 0 : (tempVendor.ConstantCostFromVendor / tempVendor.SampleSize)), 2)
        tempVendor.FinalCPI = parseFloat(tempVendor.CPI)
      }
      return tempVendor
    })
    setCurrentCurrencySelection(defaultvalueforcurrency())
    setInternalPopUp(activeTabFC, activeTabSM, activeTabCo);
    setInternalCostTable(tempVS)
    setInternalAdditionalCost(vendorSelection.filter(vs => vs.Type == "Internal Cost" && vs.CostingOptionID == currentMethodology.CostingOptions.filter(x => x.Selected)[activeTabCo].id))
  }, [project, activeTabCo, activeTabSM, activeTabFC])

  useEffect(() => {
    let tempVendorSelection = vendorSelection.map(vs => {
      if (currentCurrencySelection?.label == "USD") {
        vs.ConstantCostFromVendor = isNaN(vs.ConstantCostFromVendor) ? 0 : vs.ConstantCostFromVendor
        vs.CostFromVendor = isNaN(vs.ConstantCostFromVendor) ? 0 :typeof  vs.ConstantCostFromVendor == "string"?parseFloat(vs.ConstantCostFromVendor).toFixed(2): vs.ConstantCostFromVendor.toFixed(2)
        vs.CPI = isNaN(_.round((vs.Costingtype == "Field Cost" ? vs?.AchivableSamples ? (vs.CostFromVendor / vs.AchivableSamples) : 0 : (vs.CostFromVendor / vs.SampleSize)), 2)) ? 0 :
          _.round((vs.Costingtype == "Field Cost" ? vs?.AchivableSamples ? (vs.CostFromVendor / vs.AchivableSamples) : 0 : (vs.CostFromVendor / vs.SampleSize)), 2)
        vs.TotalCost = isNaN(vs.TotalCostusd) ? 0 :typeof  vs.TotalCostusd == "string"?parseFloat(vs.TotalCostusd).toFixed(2): vs.TotalCostusd.toFixed(2)
        vs.FinalCPI = isNaN(vs.FinalCPIusd) ? 0 : vs.FinalCPIusd
      }
      else {
        vs.CostFromVendor = isNaN(vs.ConstantCostFromVendor) ? 0 : (vs.ConstantCostFromVendor)
        vs.CostFromVendor = isNaN(_.round(vs.ConstantCostFromVendor * currentCurrencySelection?.ConversionRateToLocal, 2)) ? 0 : _.round(vs.ConstantCostFromVendor * currentCurrencySelection?.ConversionRateToLocal, 2)
        vs.CPI = isNaN(_.round((vs.Costingtype == "Field Cost" ? vs?.AchivableSamples ? (vs.CostFromVendor / vs.AchivableSamples) : 0 : (vs.CostFromVendor / vs.SampleSize)), 2)) ? 0 :
          _.round((vs.Costingtype == "Field Cost" ? vs?.AchivableSamples ? (vs.CostFromVendor / vs.AchivableSamples) : 0 : (vs.CostFromVendor / vs.SampleSize)), 2)
        vs.TotalCost = isNaN(_.round(vs.TotalCostusd * currentCurrencySelection?.ConversionRateToLocal, 2)) ? 0 : _.round(vs.TotalCostusd * currentCurrencySelection?.ConversionRateToLocal, 2)
        vs.FinalCPI = isNaN(_.round(vs.FinalCPIusd * currentCurrencySelection?.ConversionRateToLocal, 2)) ? 0 : _.round(vs.FinalCPIusd * currentCurrencySelection?.ConversionRateToLocal, 2)
      }
      return vs
    })
    setInternalCostTable(tempVendorSelection)
    // for popup
    let addittionalPorUpCost = internalAdditionalCost.map(ac => {
      if (currentCurrencySelection.label == "USD") {
        ac.AdditionalCost = ac.AdditionalCostUSD
      }
      else {
        ac.AdditionalCost = _.round(ac.AdditionalCostUSD * currentCurrencySelection?.ConversionRateToLocal, 2)
      }
      return ac
    })

    setInternalAdditionalCost(addittionalPorUpCost)
  }, [currentCurrencySelection])
  function setList() {
    if (vendorAndList && vendorAndList.length > 0) {
      Tabdata = vendorAndList.filter(obj => obj.Group == 'fieldexecutionandsudtable')[0].List.filter(v => v.Name == "Tabdata")[0].Values;
      setTabdata(Tabdata);
      staticModelData = vendorAndList.filter(obj => obj.Group == 'fieldexecutionandsudtable')[0].List.filter(v => v.Name == "staticModelData")[0].Values;
      setstaticModelData(staticModelData);
      return true;
    }
  }
  function setInternalPopUp(activeTabFC, activeTabSM, activeTabCo) {
    let currentMethodology = project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
    let tempInternalCost = vendorSelection.filter(sv => sv.CostingOptionID == currentMethodology.CostingOptions.filter(x => x.Selected)[activeTabCo].id
      && (sv.Type == "Internal Cost" || sv.Type == " Total Internal Cost"))

    // if(tempInternalCost.length){
    setInternalAdditionalCost(tempInternalCost)
    // }
  }
  function setInternalCostTable(dataset) {
    let internalCostTableData = dataset.filter(
      (vs) =>
        (vs.IsSelected == true ||
          vs.IsSUD == true) &&
        vs.Country == _.last(project.CostingProfiles).CountrySpecs[activeTabFC]
          .CountryName &&
        vs.MethodologyID ==
        _.last(project.CostingProfiles).CountrySpecs[activeTabFC]
          .MethodologySpecs[activeTabSM]
          .id &&
        vs.CostingOptionID ==
        _.last(project.CostingProfiles).CountrySpecs[activeTabFC]
          .MethodologySpecs[activeTabSM]
          .CostingOptions.filter(x => x.Selected)[activeTabCo]
          .id
    )
    var CostData = []
    var sortedinternalCostTableData = _.sortBy(internalCostTableData, "Costingtype")
    if (sortedinternalCostTableData?.filter(x => x.Costingtype == "Field Cost" || x.Costingtype == "Total Internal Cost")?.length > 0) {
      sortedinternalCostTableData?.filter(x => x.Costingtype == "Field Cost")?.forEach(y => {
        CostData.push(y);
      })
      sortedinternalCostTableData?.filter(x => x.Costingtype != "Field Cost" && x.Costingtype != "Total Internal Cost")?.forEach(y => {
        CostData.push(y);
      })
      sortedinternalCostTableData?.filter(x => x.Costingtype == "Total Internal Cost")?.forEach(y => {
        CostData.push(y);
      })
    }
    else {
      CostData = sortedinternalCostTableData
    }
    setInternalCostData(CostData)
  }

  function defaultvalueforcurrency() {
    var currdata = [];
    var curr =
      _.last(project?.CostingProfiles) &&
        _.last(project?.CostingProfiles)?.CountrySpecs?.length > 0
        ? _.last(project?.CostingProfiles)?.ProfileSetting?.CurrenciesData?.find(
          (c) =>
            c.Code ==
            _.last(project?.CostingProfiles)?.CountrySpecs[activeTabFC]?.CountryCode
        )
        : "";
    if (curr != "") {
      curr.AllCurrencyUnit = curr?.AllCurrencyUnit.replace(
        /(\r\n|\n|\r)/gm,
        ""
      );
      var allUnits = curr?.AllCurrencyUnit
        ? curr?.AllCurrencyUnit.split(",")
        : [];
      if (allUnits.length > 0) {
        allUnits.forEach((all, i) => {
          currdata.push({
            id: i + 1,
            label: all,
            value: i + 1,
            code: curr.Code,
            ConversionRateToLocal: _.last(project?.CostingProfiles)?.ProfileSetting.CurrenciesData.filter(
              (curr) => curr.CurrencyUnit == all
            )[0].ConversionRateToLocal,
          });
        });
      }
    }

    var defaultvalue = currdata.find((a) => a.label == curr.CurrencyUnit);

    if (!_.last(project?.CostingProfiles)?.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]) {
      return defaultvalue
    }
    else if (!_.last(project?.CostingProfiles)?.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]?.Currency) {
      return defaultvalue
    }
    else {
      return _.last(project?.CostingProfiles)?.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].BiddingDetail?.Currency
    }
  }


  function currencyDropdown() {
    var currdata = [];
    var curr = _.last(project.CostingProfiles) &&
      _.last(project.CostingProfiles)?.CountrySpecs.length > 0
      ? _.last(project.CostingProfiles)?.ProfileSetting.CurrenciesData.find(
        (c) =>
          c.Code == _.last(project.CostingProfiles)?.CountrySpecs[
            activeTabFC
          ].CountryCode
      )
      : "";
    var commcountrycurr =
      _.last(project.CostingProfiles) &&
        _.last(project.CostingProfiles)?.CountrySpecs.length > 0
        ? _.last(project.CostingProfiles)?.CountrySpecs[
          activeTabFC
        ].CountryCode != project.CommissioningCountry
          ? _.last(project.CostingProfiles)?.ProfileSetting.CurrenciesData.find(
            (c) => c.Code == project.CommissioningCountry
          )
          : ""
        : "";

    //project.CommissioningCountry;
    if (curr != "" && curr != undefined) {
      curr.AllCurrencyUnit = codeLabels.FieldingCountriesOptions.find(
        (fc) => fc.Code == curr.Code
      ).AllCurrencyUnit;
      //  curr.AllCurrencyUnit.replace(/(\r\n|\n|\r)/gm, "");
      var allUnits = curr.AllCurrencyUnit
        ? curr.AllCurrencyUnit.split(",")
        : [];
      if (allUnits.length > 0) {
        allUnits.forEach((all, i) => {
          currdata.push({
            id: i + 1,
            label: all,
            value: i + 1,
            code: curr.Code,
            ConversionRateToLocal: _.last(project.CostingProfiles)?.ProfileSetting.CurrenciesData.filter(
              (curr) => curr.CurrencyUnit == all
            )[0].ConversionRateToLocal,
          });
        });
      }
    }
    if (commcountrycurr != "" && commcountrycurr != undefined) {
      var currlength = currdata.length;
      commcountrycurr.AllCurrencyUnit =
        codeLabels.FieldingCountriesOptions.find(
          (fc) => fc.Code == commcountrycurr.Code
        ).AllCurrencyUnit;
      //  curr.AllCurrencyUnit.replace(/(\r\n|\n|\r)/gm, "");
      var allUnits = commcountrycurr.AllCurrencyUnit
        ? commcountrycurr.AllCurrencyUnit.split(",")
        : [];
      if (allUnits.length > 0) {
        allUnits.forEach((all, i) => {
          if (!currdata.find((a) => a.label == all)) {
            currdata.push({
              id: currlength + i + 1,
              label: all,
              value: currlength + i + 1,
              code: commcountrycurr.Code,
              ConversionRateToLocal: _.last(project.CostingProfiles)?.ProfileSetting.CurrenciesData.filter(
                (curr) => curr.CurrencyUnit == all
              )[0].ConversionRateToLocal,
            });
          }
        });
      }
    }
    return currdata;
  }
  const tableColumnsInternalCost = [
    {
      dataField: "Costingtype",
      text: "Costing Type",
      editable: false,
      footer: 'Total Cost',
      footerStyle: {
        color: "blue",
      }
    },
    // {
    //   dataField: "SampleSize",
    //   text: project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions.filter(x=> x.Selected)[0].CostingOptionSchema.find(a => a.id == "sampleSizeMain") ? "Sample Size Main " : "Number Of Groups",
    //   editable: false,
    //   footer: ''
    // },
    // {
    //   dataField: "LOI",
    //   text: "LOI",
    //   sort: true,
    //   editable: false,
    //   footer: ''
    // },
    {
      dataField: "Vendor",
      text: "Vendor Name",
      sort: true,
      editable: false,
      footer: ''
    },
    {
      dataField: "CostFromVendor",
      text: "Total Vendor Cost",
      editable: false,
      footer: columnData => costforvendor(columnData),
      //footerAlign: (column, colIndex) => 'right',
      footerStyle: {
        color: "blue",
        textAlign: "left"
      }
    },
    {
      dataField: "CPI",
      text: "CPI Vendor",
      formatter: CPIFormatter,
      sort: true,
      editable: false,
      //footerAlign: (column, colIndex) => 'right',
      footer: columnData => cpi(columnData),
      footerStyle: {
        color: "blue",
        textAlign: "left"
      }
    },
    {
      dataField: "VendorID",
      text: "Vendor ID",
      sort: true,
      editable: false,
      hidden: true,
    },
    {
      dataField: "key",
      text: "key",
      sort: true,
      editable: false,
      hidden: true,
    },
    {
      dataField: "MethodologyIndex",
      text: "M Index",
      sort: true,
      editable: false,
      hidden: true,
    },
    {
      dataField: "MethodologyID",
      text: "MethodologyID",
      sort: true,
      editable: false,
      hidden: true,
    },
    {
      dataField: "ProfileID",
      text: "ProfileID",
      sort: true,
      editable: false,
      hidden: true,
    },
    {
      dataField: "CountryIndex",
      text: "C Index",
      sort: true,
      editable: false,
      hidden: true,
    },
    {
      dataField: "TotalCost",
      text: "Total Cost",
      sort: true,
      editable: false,
      footer: columnData => costforfinalvendor(columnData),
      //footerAlign: (column, colIndex) => 'right',
      footerStyle: {
        color: "blue",
        textAlign: "left"
      }
    },
    {
      dataField: "FinalCPI",
      text: "Final CPI",
      editable: false,
      footer: columnData => finalcpi(columnData),
      //footerAlign: (column, colIndex) => 'right',
      footerStyle: {
        color: "blue",
        textAlign: "left"
      }
    },
  ];
  function CPIFormatter(cell, row) {
    return (
      <span>
        <strong style={{ color: row["Color"] }}>{cell}</strong>
      </span>
    );
  }
  function costforvendor(columnData) {
    var data = columnData.reduce((acc, item) => parseFloat(acc) + parseFloat(item == undefined ? 0 : item), 0).toFixed(2);
    setCosting(data)
    return data
  }
  function costforfinalvendor(columnData) {
    var data = columnData.reduce((acc, item) => parseFloat(acc) + parseFloat(item == undefined ? 0 : item), 0).toFixed(2);
    setfinalCosting(data)
    return data
  }
  function finalcpi(columnData) {
    // let originalCoSample = project?.CostingProfiles[project?.CostingProfiles?.length - 1]?.CountrySpecs[activeTabFC]?.MethodologySpecs[activeTabSM]?.CostingOptions.filter(x => x.Selected)[activeTabCo]?.CostingOptionData[
    //   _.head(project?.CostingProfiles[project?.CostingProfiles?.length - 1]?.CountrySpecs[activeTabFC]?.MethodologySpecs[activeTabSM]?.CostingOptions.filter(x => x.Selected)[0]?.CostingOptionSchema?.filter(a => a.id?.includes("numberOfGroup")))?.id ||
    //   _.head(project?.CostingProfiles[project?.CostingProfiles?.length - 1]?.CountrySpecs[activeTabFC]?.MethodologySpecs[activeTabSM]?.CostingOptions.filter(x => x.Selected)[0]?.CostingOptionSchema.filter(a => a.id?.includes("sampleSize")))?.id] == undefined ? "0" :
    //   project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions.filter(x => x.Selected)[activeTabCo].CostingOptionData[
    //   _.head(project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions.filter(x => x.Selected)[0].CostingOptionSchema.filter(a => a.id?.includes("numberOfGroup")))?.id ||
    //   _.head(project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions.filter(x => x.Selected)[0].CostingOptionSchema.filter(a => a.id?.includes("sampleSize")))?.id]
    // let result = (finalCosting == 0 || originalCoSample == '0') ? 0 : ((parseFloat(finalCosting) == undefined ? 0 : parseFloat(finalCosting)) / parseInt(originalCoSample)).toFixed(2);
    // var resultfiltered = isNaN(result) ? 0 : result;
    // return resultfiltered;
    var data = columnData.reduce((acc, item) => parseFloat(acc) + parseFloat(item == undefined ? 0 : item), 0).toFixed(2);
    setCosting(data)
    return data
  }
  function cpi(columnData) {
    // let originalCoSample = project?.CostingProfiles[project?.CostingProfiles?.length - 1]?.CountrySpecs[activeTabFC]?.MethodologySpecs[activeTabSM]?.CostingOptions.filter(x => x.Selected)[activeTabCo]?.CostingOptionData[
    //   _.head(project?.CostingProfiles[project?.CostingProfiles?.length - 1]?.CountrySpecs[activeTabFC]?.MethodologySpecs[activeTabSM]?.CostingOptions.filter(x => x.Selected)[0]?.CostingOptionSchema?.filter(a => a.id?.includes("numberOfGroup")))?.id ||
    //   _.head(project?.CostingProfiles[project?.CostingProfiles?.length - 1]?.CountrySpecs[activeTabFC]?.MethodologySpecs[activeTabSM]?.CostingOptions.filter(x => x.Selected)[0]?.CostingOptionSchema.filter(a => a.id?.includes("sampleSize")))?.id] == undefined ? "0" :
    //   project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions.filter(x => x.Selected)[activeTabCo].CostingOptionData[
    //   _.head(project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions.filter(x => x.Selected)[0].CostingOptionSchema.filter(a => a.id?.includes("numberOfGroup")))?.id ||
    //   _.head(project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions.filter(x => x.Selected)[0].CostingOptionSchema.filter(a => a.id?.includes("sampleSize")))?.id]

    // let result = (Costing == 0 || originalCoSample == '0') ? 0 : ((parseFloat(Costing) == undefined ? 0 : parseFloat(Costing)) / parseInt(originalCoSample)).toFixed(2);
    // var resultfiltered = isNaN(result) ? 0 : result;
    // return resultfiltered;
    var data = columnData.reduce((acc, item) => parseFloat(acc) + parseFloat(item == undefined ? 0 : item), 0).toFixed(2);
    setCosting(data)
    return data
  }
  function toggle(tab) {
    setActiveTabCo(0)
    if (activeTabFC !== tab) {
      setActiveTabFC(tab);
      var indexsm = project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[
        tab
      ].MethodologySpecs.filter((y, index) => y.CostingType == 3 && !y.NotApplicable)[0]?.id;
      setActiveTabSM(
        project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[tab].MethodologySpecs.findIndex(
          (m) => m.id == indexsm
        )
      );
      setactiveCoId(project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[tab].MethodologySpecs.filter(
        (m) => m.id == indexsm
      )[0]?.CostingOptions.filter(x => x.Selected)[0].id)
    }
  }
  function toggleSM(tab) {
    setActiveTabCo(0)
    if (activeTabSM !== tab) {
      setActiveTabSM(tab);
      setactiveCoId(project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[tab].CostingOptions.filter(x => x.Selected)[0].id)
      setModelSelectedRow([])
      setInternalPopUp(activeTabFC, tab, 0)
    }
  }
  function togglecostingOption(tab) {
    setModelSelectedRow([])
    if (activeTabCo !== tab) {
      setActiveTabCo(tab);
    }
    setInternalPopUp(activeTabFC, activeTabSM, tab)
  }

  const rowStyle = (row, rowIndex) => {
    if (row.Country == "Total Cost(B2B-FTF||Option1)") {
      return { backgroundColor: "#ebebeb" };
    }
  };

  function SetinternalCostValue(e, row) {
    var currency = currentCurrencySelection == undefined ? defaultvalueforcurrency() : currentCurrencySelection

    let tempInternalCost = internalAdditionalCost
    let tempDisplayName = tempInternalCost.find(ic => ic.keyID == row.key) ? tempInternalCost.find(ic => ic.keyID == row.key).displayName : "";
    let tempInternalCostPerCO = {}
    tempInternalCostPerCO.CostingOptionID = _.last(project.CostingProfiles).CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions.filter(x => x.Selected)[activeTabCo].id
    tempInternalCostPerCO.Costingtype = row.Costingtype
    tempInternalCostPerCO.OnlineMethodology = row.OnlineMethodology
    //todo:chk 
    tempInternalCostPerCO.PsudoType = row.Type
    tempInternalCostPerCO.Type = "Internal Cost"
    tempInternalCostPerCO.IsSUD = true
    tempInternalCostPerCO.keyID = row.key
    tempInternalCostPerCO.displayName = tempDisplayName
    tempInternalCostPerCO.AdditionalCost = isNaN(parseFloat(e.target.value).toFixed(2)) ? 0 : parseFloat(e.target.value).toFixed(2)

    tempInternalCostPerCO.AdditionalCostUSD = (currentCurrencySelection.label == "USD") ?
      isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value)
      : (isNaN(parseFloat(e.target.value)) ? 0 : _.round(parseFloat(e.target.value) / currentCurrencySelection?.ConversionRateToLocal, 2))
    //todo
    tempInternalCostPerCO.TotalCost = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value)
    tempInternalCostPerCO.CostForTable = e.target.value
    tempInternalCostPerCO.SelectedCurrency = currency
    tempInternalCostPerCO.VendorSchema = {
      VendorDataUSD: tempInternalCostPerCO.AdditionalCostUSD,
      key: row.key,
      name: row.Costingtype,
      type: "Internal Cost",
      group: "Internal Cost"
    }

    tempInternalCost = tempInternalCost.filter(ic => ic.keyID != row.key)
    tempInternalCost.push(tempInternalCostPerCO)
    setInternalAdditionalCost(tempInternalCost)
    return tempInternalCost
  }

  function saveInternalCost() {
    let currentMethodology = project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
    // let tempInternalCost=currentMethodology.BiddingDetail?.SelectedVendor?.filter(sv=>sv.CostingOptionID!=currentMethodology.CostingOptions.filter(x=> x.Selected)[activeTabCo].id)

    let cloneInternalCost = internalAdditionalCost
    cloneInternalCost = cloneInternalCost.filter(clone => clone.Type != "Total Internal Cost")
    let tempVendor = {}
    tempVendor.CostingOptionID = currentMethodology.CostingOptions.filter(x => x.Selected)[activeTabCo].id
    tempVendor.IsSelected = false
    tempVendor.Type = "Total Internal Cost"
    tempVendor.Costingtype = "Total Internal Cost"
    tempVendor.CostFromVendor = 0;
    tempVendor.ConstantCostFromVendor = 0
    tempVendor.keyID = "SumInternal"
    // tempVendor.CountryIndex=cIndex
    tempVendor.Country = project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].CountryName
    tempVendor.ProfileID = project.CostingProfiles[project?.CostingProfiles?.length - 1].id
    tempVendor.SampleSize = currentMethodology.CostingOptions.filter(x => x.Selected)[activeTabCo].CostingOptionData.sampleSizeMain || currentMethodology.CostingOptions.filter(x => x.Selected)[activeTabCo].CostingOptionData.numberOfGroup
    tempVendor.AchivableSamples = currentMethodology.CostingOptions.filter(x => x.Selected)[activeTabCo].CostingOptionData.sampleSizeMain || currentMethodology.CostingOptions.filter(x => x.Selected)[activeTabCo].CostingOptionData.numberOfGroup
    tempVendor.MethodologyID = currentMethodology.id
    tempVendor.IsSUD = true
    tempVendor.Methodology = currentMethodology.Label
    //to do cost 
    tempVendor.TotalCostusd = isNaN(internalAdditionalCost?.filter(a => a.AdditionalCostUSD != "")?.map(a => a.AdditionalCostUSD)?.reduce((a, b) => parseFloat(a) + parseFloat(b) + 0)) ? 0
      : internalAdditionalCost?.filter(a => a.AdditionalCostUSD != "")?.map(a => a.AdditionalCostUSD)?.reduce((a, b) => parseFloat(a) + parseFloat(b) + 0)
    tempVendor.TotalCost = isNaN(internalAdditionalCost?.filter(a => a.AdditionalCost != "")?.map(a => a.AdditionalCost)?.reduce((a, b) => parseFloat(a) + parseFloat(b) + 0)) ? 0
      : internalAdditionalCost?.filter(a => a.AdditionalCost != "")?.map(a => a.AdditionalCost)?.reduce((a, b) => parseFloat(a) + parseFloat(b) + 0)
    tempVendor.TotalInternalCost = isNaN(internalAdditionalCost?.filter(a => a.AdditionalCost != "")?.map(a => a.AdditionalCost)?.reduce((a, b) => parseFloat(a) + parseFloat(b) + 0)) ? 0
      : internalAdditionalCost?.filter(a => a.AdditionalCost != "")?.map(a => a.AdditionalCost)?.reduce((a, b) => parseFloat(a) + parseFloat(b) + 0)
    tempVendor.CostFromVendor = tempVendor.TotalInternalCost
    tempVendor.ConstantCostFromVendor = tempVendor.TotalCostusd
    tempVendor.FinalCPI = _.round(tempVendor.TotalInternalCost / tempVendor.SampleSize, 2)
    tempVendor.CPIusd = _.round(tempVendor.ConstantCostFromVendor / tempVendor.SampleSize, 2)
    tempVendor.CPI = _.round(tempVendor.TotalInternalCost / tempVendor.SampleSize, 2)
    tempVendor.VendorSchema = internalAdditionalCost.map(a => a.VendorSchema)
    cloneInternalCost.push(tempVendor)
    let tempVendorSelectionInActiveCO = vendorSelection.filter(sv => sv.CostingOptionID != currentMethodology.CostingOptions.filter(x => x.Selected)[activeTabCo].id)
    let tempVendorSelection = vendorSelection.filter(sv => sv.CostingOptionID == currentMethodology.CostingOptions.filter(x => x.Selected)[activeTabCo].id)
      .filter(sv => sv.Type != "Internal Cost").filter(sv => sv.Type != "Total Internal Cost")
    let childVendorSelection = [...tempVendorSelectionInActiveCO, ...tempVendorSelection, ...cloneInternalCost]
    setVendorSelection(childVendorSelection)
    var internalCostTableData = ([...tempVendorSelection, ...cloneInternalCost])
    var CostData = []
    var sortedinternalCostTableData = _.sortBy(internalCostTableData, "Costingtype")
    if (sortedinternalCostTableData?.filter(x => x.Costingtype == "Field Cost" || x.Costingtype == "Total Internal Cost")?.length > 0) {
      sortedinternalCostTableData?.filter(x => x.Costingtype == "Field Cost")?.forEach(y => {
        CostData.push(y);
      })
      sortedinternalCostTableData?.filter(x => x.Costingtype != "Field Cost" && x.Costingtype != "Total Internal Cost")?.forEach(y => {
        CostData.push(y);
      })
      sortedinternalCostTableData?.filter(x => x.Costingtype == "Total Internal Cost")?.forEach(y => {
        CostData.push(y);
      })
    }
    else {
      CostData = sortedinternalCostTableData
    }
    setInternalCostData(CostData);
    setInternalCost(!InternalCost);
    toastr.success("Internal Cost Submitted")
  }

  function updateName(name, row) {
    let tempInternalCost = _.cloneDeep(internalAdditionalCost)
    let tempInternalCostPerCO = tempInternalCost.find(ic => ic.keyID == row.key) ? tempInternalCost.find(ic => ic.keyID == row.key) : {}
    if (Object.entries(tempInternalCostPerCO).length) {
      tempInternalCostPerCO = tempInternalCost.find(ic => ic.keyID == row.key)
      tempInternalCostPerCO.displayName = name
    }
    else {
      // let tempInternalCostPerCO = {}
      tempInternalCostPerCO.CostingOptionID = _.last(project.CostingProfiles).CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions.filter(x => x.Selected)[activeTabCo].id
      tempInternalCostPerCO.Costingtype = row.Costingtype
      tempInternalCostPerCO.OnlineMethodology = row.OnlineMethodology
      tempInternalCostPerCO.PsudoType = row.Type
      tempInternalCostPerCO.Type = "Internal Cost"
      tempInternalCostPerCO.IsSUD = true
      tempInternalCostPerCO.keyID = row.key
      tempInternalCostPerCO.displayName = name
      tempInternalCostPerCO.AdditionalCost = ""
      tempInternalCostPerCO.AdditionalCostUSD = ""
      tempInternalCostPerCO.TotalCost = ""
      tempInternalCostPerCO.CostForTable = ""
      // tempInternalCostPerCO.SelectedCurrency = currency
      tempInternalCostPerCO.VendorSchema = {
        VendorDataUSD: tempInternalCostPerCO.AdditionalCostUSD,
        key: row.key,
        name: row.Costingtype,
        type: "Internal Cost",
        group: "Internal Cost"
      }
    }
    tempInternalCost = tempInternalCost.filter(ic => ic.keyID != row.key)
    tempInternalCost.push(tempInternalCostPerCO)
    setInternalAdditionalCost(tempInternalCost)
  }
  function updateproject(newProposal) {
    project.CostingProfiles[project?.CostingProfiles?.length - 1] = newProposal;
    dispatch({
      type: currentProjectActions.UPDATE_NEW_PROJECT,
      newProject: project,
    });
  }
  return (
    <>

      {
        <>
          <Nav tabs>
            {project.CostingProfiles.length > 0 &&
              project.CostingProfiles[
                project?.CostingProfiles?.length - 1
              ].CountrySpecs &&
              project.CostingProfiles[
                project?.CostingProfiles?.length - 1
              ].CountrySpecs.map(
                (fc, indxFC) =>
                  fc.MethodologySpecs.filter(
                    (sm) => sm.CostingType == 3 && !sm.NotApplicable
                  ).length > 0 && (
                    <NavItem key={fc.value}>
                      <NavLink
                        className={classnames({
                          active: activeTabFC === indxFC,
                        })}
                        onClick={() => {
                          toggle(indxFC);
                        }}
                      >
                        <b>{fc.CountryName}</b>
                      </NavLink>
                    </NavItem>
                  )
              )}
          </Nav>
          <TabContent activeTab={activeTabFC}>
            <Col md={3} style={{ marginLeft: "75%" }}>
              <FormGroup className="ml-5">
                <div
                  style={{
                    backgroundColor: "#00f000",
                  }}
                >
                  <Label
                    style={{
                      fontWeight: "bolder",
                      color: "black",
                      marginLeft: "5px",
                      marginTop: "5px",
                    }}
                  >
                    Available Currency
                  </Label>
                  <Select
                    name="CurrencySelection"
                    classNamePrefix="react-select"
                    options={currencyDropdown()}
                    onChange={(e) => { setCurrentCurrencySelection(e) }}
                    value={currentCurrencySelection ? currentCurrencySelection : defaultvalueforcurrency()}
                    color="green"
                    isDisabled={viewonlymode}

                    style={{ Color: "green" }} />
                </div>
              </FormGroup>
            </Col>
            {project.CostingProfiles[
              project?.CostingProfiles?.length - 1
            ].CountrySpecs &&
              project.CostingProfiles[
                project?.CostingProfiles?.length - 1
              ].CountrySpecs.map(
                (fc, indxFC) =>
                  activeTabFC === indxFC && (
                    <TabPane tabId={indxFC} key={fc.value}>
                      <Card>
                        <Row>
                          <Col md={12}>
                            <Nav tabs>
                              {project.CostingProfiles.length > 0 &&
                                project.CostingProfiles[
                                  project?.CostingProfiles?.length - 1
                                ].CountrySpecs &&
                                project.CostingProfiles[
                                  project?.CostingProfiles?.length - 1
                                ].CountrySpecs[
                                  activeTabFC
                                ].MethodologySpecs.map(
                                  (sm, indxSM) =>
                                    !sm.NotApplicable &&
                                    sm.CostingType == 3 && (
                                      <NavItem key={sm.value}>
                                        <NavLink
                                          className={classnames({
                                            active:
                                              activeTabSM === indxSM,
                                          })}
                                          onClick={() => {
                                            toggleSM(indxSM);
                                            project.CostingProfiles[
                                              project?.CostingProfiles
                                                ?.length - 1
                                            ].CountrySpecs[
                                              activeTabFC
                                            ].MethodologySpecs[
                                              activeTabSM
                                            ].copySelectedVendor = _.cloneDeep(
                                              project.CostingProfiles[
                                                project?.CostingProfiles
                                                  ?.length - 1
                                              ].CountrySpecs[
                                                activeTabFC
                                              ].MethodologySpecs[
                                                activeTabSM
                                              ].SelectedVendor
                                            );
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent:
                                                "space-between",
                                              alignItems: "center",
                                              top: "-3rem",
                                            }}
                                          >
                                            <div>
                                              <b>{sm.Label}</b>
                                            </div>
                                          </div>
                                        </NavLink>
                                      </NavItem>
                                    )
                                )}
                            </Nav>
                            <TabContent
                              activeTab={activeTabSM}
                              style={{ padding: "0.25" }}
                            >
                              <Col md={12} >
                                <Row className={"costingOptionTabsRow"}>
                                  <Col md={12} className={"costingOptionTabsRow"}>
                                    <Nav tabs>
                                      {project.CostingProfiles.length > 0 &&
                                        project.CostingProfiles[
                                          project?.CostingProfiles?.length - 1
                                        ].CountrySpecs &&
                                        project.CostingProfiles[
                                          project?.CostingProfiles?.length - 1
                                        ].CountrySpecs[
                                          activeTabFC
                                        ].MethodologySpecs[
                                          activeTabSM
                                        ].CostingOptions.filter(x => x.Selected).map(
                                          (Co, indexCo) => (
                                            <NavItem key={Co.Name}>
                                              {Co.Selected && (
                                                <NavLink
                                                  className={classnames({
                                                    active:
                                                      activeTabCo === indexCo,
                                                  })}
                                                  onClick={() => {
                                                    togglecostingOption(
                                                      indexCo
                                                    );
                                                    setactiveCoId(Co.id);
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent:
                                                        "space-between",
                                                      alignItems: "center",
                                                      top: "-3rem",
                                                    }}
                                                  >
                                                    <div>
                                                      <b>{Co.Name}</b>
                                                    </div>
                                                  </div>
                                                </NavLink>
                                              )}
                                            </NavItem>
                                          )
                                        )}
                                    </Nav>
                                    <TabContent
                                      activeTab={activeTabSM}
                                      style={{ padding: "0.25" }}
                                    >
                                      {_.last(project.CostingProfiles)
                                        .CountrySpecs[activeTabFC]
                                        ?.MethodologySpecs[
                                        activeTabSM
                                      ].CostingOptions.filter(x => x.Selected)[activeTabCo]
                                        .CostingOptionData
                                        .lengthOfInterview &&
                                        <Row>
                                          <Col lg-1 md-1 xs-12>
                                            <Label className="h5">
                                              Length Of Interview:{" "}
                                              {_.last(project.CostingProfiles)
                                                .CountrySpecs[activeTabFC]
                                                ?.MethodologySpecs[
                                                activeTabSM
                                              ].CostingOptions.filter(x => x.Selected)[activeTabCo]
                                                .CostingOptionData
                                                .lengthOfInterview
                                              }
                                            </Label>
                                          </Col>
                                        </Row>}
                                      {_.last(project.CostingProfiles).CountrySpecs[activeTabFC]
                                        ?.MethodologySpecs[activeTabSM].CostingOptions.filter(x => x.Selected)[activeTabCo]
                                        .CostingOptionData
                                        .sampleSizeMain
                                        && <Row>
                                          <Col lg-1 md-1 xs-12>
                                            <Label className="h5">
                                              Sample Size:{" "}
                                              {
                                                _.last(project.CostingProfiles).CountrySpecs[activeTabFC]
                                                  ?.MethodologySpecs[activeTabSM].CostingOptions.filter(x => x.Selected)[activeTabCo]
                                                  .CostingOptionData
                                                  .sampleSizeMain
                                              }
                                            </Label>
                                          </Col>
                                        </Row>}
                                      {_.last(project.CostingProfiles).CountrySpecs[activeTabFC]
                                        ?.MethodologySpecs[activeTabSM].CostingOptions.filter(x => x.Selected)[activeTabCo]
                                        .CostingOptionData
                                        .numberOfGroup
                                        && <Row>
                                          <Col lg-1 md-1 xs-12>
                                            <Label className="h5">
                                              Number Of Groups:{" "}
                                              {
                                                _.last(project.CostingProfiles).CountrySpecs[activeTabFC]
                                                  ?.MethodologySpecs[activeTabSM].CostingOptions.filter(x => x.Selected)[activeTabCo]
                                                  .CostingOptionData
                                                  .numberOfGroup
                                              }
                                            </Label>
                                          </Col>
                                        </Row>}
                                      <ToolkitProvider
                                        keyField="key"
                                        data={internalCostData.filter(sv => sv.Type != "Internal Cost")}
                                        columns={tableColumnsInternalCost}
                                      >
                                        {(props) => (
                                          <CardBody className="bg-white">
                                            <BootstrapTable
                                              {...props.baseProps}
                                              bootstrap4
                                              rowStyle={rowStyle}
                                              wrapperClasses="table-responsive"
                                              bordered={true}
                                              filter={filterFactory()}
                                              filterPosition="top"
                                              cellEdit={cellEditFactory({
                                                mode: "click",
                                                blurToSave: true,
                                              })}
                                            />
                                            <button
                                              className="btn btn-primary"
                                              //  disabled={project.CostingProfiles[project?.CostingProfiles?.length - 1].
                                              // CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]?.SelectedVendor?.filter(co => co.id == activeCoId).length == 0 || viewonlymode}
                                              disabled={viewonlymode}
                                              onClick={() => {
                                                setInternalCost(
                                                  !InternalCost
                                                );
                                                setInternalPopUp(
                                                  activeTabFC,
                                                  activeTabSM,
                                                  activeTabCo
                                                );
                                              }}
                                            >
                                              Add Internal Cost
                                            </button>
                                          </CardBody>
                                        )}
                                      </ToolkitProvider>
                                    </TabContent>
                                  </Col>
                                </Row>
                              </Col>
                            </TabContent>

                          </Col>
                        </Row>
                      </Card>
                    </TabPane>
                  )
              )}
          </TabContent>
        </>

      }


      {
        // <AdditionalCostPopUp
        //   InternalCost={InternalCost}
        //   setInternalCost={setInternalCost}
        //   project={project}
        //   internalAdditionalCost={internalAdditionalCost}
        //   activeTabFC={activeTabFC}
        //   activeTabSM={activeTabSM}
        //   SetinternalCostValue={SetinternalCostValue}
        //   CopyInternalCost={CopyInternalCost}
        //   saveInternalCost={saveInternalCost}
        //   staticModelData={staticModelData}
        //   updateName={updateName}
        //   internalOtherSpecify={internalOtherSpecify}
        //   activeInternalDropCurrency={activeInternalDropCurrency}
        //   activeCoId={activeCoId}
        // ></AdditionalCostPopUp>
        <Modal
          isOpen={InternalCost}
          toggle={() => setInternalCost(!InternalCost)}
          centered
          size="lg"
          className={"modelSelectVendors"}
        >
          <ModalHeader
            toggle={() => {
              setInternalCost(!InternalCost);
            }}
          >
            {/* <img src={logo} className="modallogo" /> */}
          </ModalHeader>
          <span className='modaltitle p-0'>Select Internal Cost</span>
          <ModalBody className="m-3">
            {/* {JSON.stringify(internalAdditionalCost)} */}
            <div style={{ maxHeight: "380px", overflow: "auto" }}>
              <Table className="table" style={{ position: "relative" }}>
                <tr className="table bg-accent text-black">
                  <th>
                    Costing Type
                    {/* {project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[
                    activeTabFC
                  ].MethodologySpecs[activeTabSM].CostingOptions.filter(x=> x.Selected).length > 1 &&
                    <FontAwesomeIcon
                      title="Copy data"
                      className="align-middle"
                      icon={faCopy}
                      style={{
                        cursor: "pointer",
                        marginLeft: "10px",
                      }}
                      onClick={() => { saveInternalCost(); CopyInternalCost() }}
                    />} */}
                  </th>
                  <th>Internal Cost</th>
                </tr>
                {(project.CostingProfiles[project?.CostingProfiles?.length - 1]
                  .CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
                  .Label == "Online Self Completion Survey"
                  ? staticModelData.filter(
                    (data) => data.OnlineMethodology == true
                  )
                  : staticModelData
                ).map((d) => {
                  return (
                    <tr>
                      <td>
                        {d.Costingtype.includes(
                          "Other Costs - PLEASE SPECIFY"
                        ) ? (
                          <>
                            <label>Other Cost </label>{" "}
                            <input
                              type="text"
                              name={d.key}
                              placeholder="Please Specify..."
                              value={internalAdditionalCost.filter(
                                (f) =>
                                  (f.Type == "Internal Cost" ||
                                    f.Type == "Total Internal Cost") &&
                                  f.keyID == d.key
                              ).length > 0
                                ? internalAdditionalCost.find(
                                  (ic) => ic.keyID == d.key
                                )?.displayName
                                : ""}
                              onChange={(e) => {
                                updateName(e.target.value, d);
                              }}
                            />
                          </>
                        ) : (
                          d.Costingtype
                        )}{" "}
                      </td>
                      <td>
                        <InputGroup>
                          <Input
                            type="number"
                            min={0}
                            value={
                              internalAdditionalCost.filter(
                                (f) =>
                                  (f.Type == "Internal Cost" ||
                                    f.Type == "Total Internal Cost") &&
                                  f.keyID == d.key
                              ).length > 0
                                ? parseFloat(
                                  internalAdditionalCost.find(
                                    (ic) => ic.keyID == d.key
                                  )?.AdditionalCost
                                )
                                : ""
                            }
                            onChange={(e) => {
                              e.target.value < 0
                                ? toastr.error("Please Enter Valid Number")
                                : SetinternalCostValue(e, d);
                            }}
                          />
                          <InputGroupAddon addonType="prepend">
                            {currentCurrencySelection ? currentCurrencySelection.label : ""}
                          </InputGroupAddon>
                        </InputGroup>
                      </td>
                    </tr>
                  );
                })}
              </Table>
            </div>
          </ModalBody>
          <ModalFooter style={ModalFooterStyle}>
            <Button
              color="primary"
              onClick={() => {
                internalAdditionalCost.length > 0 &&
                  internalAdditionalCost?.filter(x => x.TotalCost)?.map(x => x.TotalCost).length > 0 &&
                  internalAdditionalCost?.filter(x => x.TotalCost)?.map(x => x.TotalCost)?.reduce((result, sm) => result + sm) ?
                  saveInternalCost() : toastr.error("Please Add Internal Cost");
              }}
              disabled={viewonlymode}
            >
              Submit
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                setInternalCost(!InternalCost);
                project.CostingProfiles[
                  project?.CostingProfiles?.length - 1
                ].CountrySpecs[activeTabFC].MethodologySpecs[
                  activeTabSM
                ].SelectedVendor = _.cloneDeep(
                  project.CostingProfiles[project?.CostingProfiles?.length - 1]
                    .CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
                    .copySelectedVendor
                );
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      }
    </>
  );


}


export default InternalCostView;