import React from "react";
import { Container, Row, Col } from "reactstrap";

const Footer = () => (
  <footer className="footer">
    <Container fluid>
      <Row className="text-muted">
        <Col xs="6" className="text-left">
          <ul className="list-inline">
            <li className="list-inline-item">
              <span className="text-muted">
              <a className="select-link text-white pointer" onClick={() => window.open(process.env.REACT_APP_FEATURE_REQUEST_FORM)}>Request New Feature or Enhancements</a>
              </span>
            </li>
            <li className="list-inline-item">
              {/* <span className="text-muted" href="#">
                Help Center
              </span> */}
            </li>
            {/* <li className="list-inline-item">
              <span className="text-muted" href="#">
                Privacy
              </span>
            </li> */}
            {/* <li className="list-inline-item">
              <span className="text-muted" href="#">
                Terms of Service
              </span>
            </li> */}
          </ul>
        </Col>
        <Col xs="6" className="text-right">
          <p className="mb-0 footer-text">
            &copy; {new Date().getFullYear()} -{" "}
            {/* <span href="/" className="text-muted footer-text"> */}
            <span href="/" className="footer-text">
              CINTRAL
            </span>
          </p>
        </Col>
      </Row>
    </Container>
  </footer>
);

export default Footer;
