import React, { useEffect, useState } from "react";
import { toastr } from "react-redux-toastr";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "@alexcambose/react-infinite-scroll";
import axios from "../../../axios-interceptor";
import { Badge } from "reactstrap";
import Navbar from "../../../components/Navbar";
import { useHistory } from "react-router-dom";
import { getLabel } from "../../../utils/codeLabels";
import * as projectsActions from "../../../redux/actions/projectsActions";
import * as currentCostingActions from "../../../redux/actions/currentCostingActions";
import { saveProject } from "../../../redux/actions/currentProjectActions";
import ExpandableRows from "./ExpandableRows";
import DashboardLayout from "../../../layouts/Dashboard";
import RecordsSpinner from "../../../components/RecordsSpinner";
import RecordsBadge from "../../../components/RecordsBadge";
import "../../dashboard/Projects/Dashboard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../../assets/img/avatars/modal-logo.png"; 

import {
  faCheck,
  faLock,
  faExclamationTriangle,
  faCheckCircle,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import Spinner from "../../../components/Spinner";
import * as currentProjectActions from "../../../redux/actions/currentProjectActions";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table,
  Input,
  Label,
  CardTitle,
} from "reactstrap";
import SelectWrapper from "../../../components/SelectWrapper/SelectWrapper";

const Vdashboard = () => {
  const baseurl = process.env.REACT_APP_API_BASE_URL;
  const [infiniteLoad, setInfiniteLoad] = useState(false);
  const [isOpen, handleOpen] = useState(false);
  const [selectedCountries, setCountries] = useState([]);
  const [selectedVerticals, setVerticals] = useState([]);
  const [selectedBusinessUnits, setBusinessUnits] = useState([]);
  const [selectedStatus, setStatus] = useState([]);
  const [currentSelectedProject, setCurrentSelectedProject] = useState({});
  const app = useSelector(({ app }) => app);
  const projects = useSelector(({ projects }) => projects.items);
  const totalItems = useSelector(({ projects }) => projects.totalItems);
  const hasMore = useSelector(({ projects }) => projects.hasMore);
  const countries =
    useSelector(({ codeLabels }) => codeLabels.UserScopeOptions) || [];
  const verticals =
    useSelector(({ codeLabels }) => codeLabels.VerticalOptions) || [];
  const businessUnits =
    useSelector(({ codeLabels }) => codeLabels.BusinessUnitOptions) || [];
  const projectStatus =
    useSelector(({ codeLabels }) => codeLabels.ProjectStatusOptions) || [];
  const searchCharactors =
    useSelector(({ navbar }) => navbar.SearchCharactors) || "";
  const searchBy = useSelector(({ navbar }) => navbar.SearchBy) || "";
  const [clear, isClear] = useState(false);
  const [editStatusModal, setEditStatusModal] = useState(false);
  const [projectList, selectProjects] = useState("myProject");
  const [selectedProjectStatus, setProjectStatus] = useState(null);

  const isAuthenticated = useSelector(
    ({ user }) =>
      user.authToken !== null &&
      user.authToken !== "undefined" &&
      user.authToken !== ""
  );

  const dispatch = useDispatch();
  const [isWelcome, setWelcome] = useState(false);

  useEffect(() => {
    dispatch({ type: "SEARCH_TYPE", SearchingType: "ID" });
    dispatch({ type: currentCostingActions.CLEAR_NEW_COSTING });
    dispatch({ type: currentProjectActions.CLEAR_NEW_PROJECT });
  }, []);

  const handleCookies = () => {
    setCookies();
    setWelcome(false);
  };

  const fetchMoreData = () => {
    let jsonBody = getJson();
    if (searchCharactors.length > 2)
      jsonBody = {
        ...jsonBody,
        [getSearchBy()]: searchCharactors,
      };
    if (projects.length >= totalItems) {
      dispatch(projectsActions.setProjects({ hasMore: false }));
      return;
    }
    setInfiniteLoad(true);
    axios
      .post(
        baseurl + "projects/filter?limit=20&offset=" + projects.length,
        jsonBody
      )
      .then((res) => {
        dispatch(projectsActions.appendProjects(res.data.items));
        setInfiniteLoad(false);
      })
      .catch((err) => {
        toastr.error("Loading Failed", err.data.message);
        setInfiniteLoad(false);
      });
  };
  const hist = useHistory();
  var imgStyle = {
		width: "150px",
	  };
  const isGreenLightProject = (projectCode, ContractDetails) => {
    let ProjectStatus = getLabel("ProjectStatusOptions", projectCode);
    if (
      ProjectStatus.toUpperCase() === "PROPOSAL" ||
      ProjectStatus.toUpperCase() === "NEGOTIATING"
    ) {
      var probabilityCheck = ContractDetails.filter(
        (obj) => obj.Probability == 100
      );
      if (probabilityCheck.length) return true;
    }
    return false;
  };

  useEffect(() => {
    dispatch(projectsActions.getProjects({ myProject: true ,vendor:true}));
    dispatch({ type: "SEARCH_CHARACTORS", Charactors: "" });
    dispatch(currentProjectActions.clearCurrentProject());
  }, []);

  useEffect(() => {
    dispatch(projectsActions.setProjects({ hasMore: true }));
  }, [projects]);

  useEffect(() => {
    hist.replace("/vendordashboard");
  }, [isAuthenticated]);

  const getPriceLabel = (priceValue) => {
    return  priceValue ===  1 
      ?  
       {title: "", color: "success" }
      : 
        priceValue ===  2 
      ? 
      {title: "Pre-approved", color : "secondary" }

      : 
        priceValue ===  3 
      ?  
      {title: "Project locked, create a new project ", color : "danger" }

      : 
        {title: "Project locked and Copied", color : "danger" }


  }


  const data = {
    tableColumns: [
      {
        dataField: "ProjectId",
        text: "PROJECT ID",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "10%" };
        },
        formatter: (cell, row) => {
          return (
            <span>
              {cell}{" "}
              {row.ContractDetails && row.ContractDetails.length > 0 ? (
                isGreenLightProject(row.ProjectStatus, row.ContractDetails) ? (
                  <FontAwesomeIcon
                    className="success pointer"
                    icon={faCheckCircle}
                    title="Project ready for commissiong"
                  />
                ) : null
              ) : null}
            </span>
          );
        },
      },
      {
        dataField: "ProjectName",
        text: "PROJECT NAME",
        sort: true,
        formatter: (cell, row) => {
          return (
            <span>
              {cell}{" "}
              {row.IsImportedProject ? (
                <FontAwesomeIcon
                  className="warning pointer"
                  icon={faExclamationTriangle}
                  title="Project Migrated from v1. Some features may not be available."
                />
              ) : null}
            </span>
          );
        },
      },
      {
        dataField: "ProjectStatus",
        text: "STATUS",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "10%" };
        },
        formatter: (cell, row) => {
          const label = getLabel("ProjectStatusOptions", cell);
          const priceValue = row.IsSyndicated && row.ParentProjectId ? null :
          row?.PriceChange ? getPriceLabel(row.PriceChange) : null


          return (
            <div>
              <Badge
                color="secondary"
                className="pointer mr-2"
                onClick={() => {
                  setProjectStatus(row?.ProjectStatus)
                  setCurrentSelectedProject(row);
                  setEditStatusModal(true);
                }}
              >
                {label}
              </Badge>
              {row.ContractDetails && row.ContractDetails.length > 0 ? (
                isGreenLightProject(row.ProjectStatus, row.ContractDetails) ? (
                  <Badge color="red" className="pointer">
                    Project ready for commissiong
                  </Badge>
                ) : null
              ) : null}
              {
                (row.CommissioningCountry === "AU" || row.CommissioningCountry === "NZ") 
                || priceValue === null
                ? 
                  null 
                : 
                  <Badge color={priceValue.color} className="pointer">
                    {priceValue.title}
                  </Badge>
              }
            </div>
          );
        },
      },
      {
        dataField: "ProposalOwnerEmail",
        text: "PROPOSAL OWNER",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "20%" };
        },
        formatter: (cell) => {
          if (cell.value) {
            return cell.value
              .toLowerCase()
              .split("@")[0]
              .split(".")
              .map((word) => {
                return word.replace(word[0], word[0].toUpperCase());
              })
              .join(" ");
          } else {
            return "";
          }
        },
      },
      {
        dataField: "CommissioningCountry",
        text: "COUNTRY",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "15%" };
        },
        formatter: (cell) => {
          return getLabel("FieldingCountriesOptions", cell);
        },
      },
      {
        dataField: "createdAt",
        text: "CREATED DATE",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "10%" };
        },
        formatter: (cell) => {
          return cell.split("T")[0];
        },
      },
    ],
    tableData: projects,
  };

  const onSearchBarValueChange = (chr) => {
    dispatch({ type: "SEARCH_CHARACTORS", Charactors: chr });
  };
  const onSearchBarTypeChange = (typ) => {
    dispatch({ type: "SEARCH_TYPE", SearchingType: typ });
    dispatch({ type: "SEARCH_CHARACTORS", Charactors: "" });
  };
  const onModalChange = () => {
    handleOpen(!isOpen);
  };
  const hanldeCountries = (code) => {
    const arrayList = [...selectedCountries];
    if (!selectedCountries.includes(code)) arrayList.push(code);
    else {
      let i = arrayList.indexOf(code);
      arrayList.splice(i, 1);
    }
    setCountries([...arrayList]);
  };
  const hanldeVerticals = (code) => {
    const arrayList = [...selectedVerticals];
    if (!selectedVerticals.includes(code)) arrayList.push(code);
    else {
      let i = arrayList.indexOf(code);
      arrayList.splice(i, 1);
    }
    setVerticals([...arrayList]);
  };
  const hanldeBusinessUnits = (code) => {
    const arrayList = [...selectedBusinessUnits];
    if (!selectedBusinessUnits.includes(code)) arrayList.push(code);
    else {
      let i = arrayList.indexOf(code);
      arrayList.splice(i, 1);
    }
    setBusinessUnits([...arrayList]);
  };
  const hanldeProjectStatus = (code) => {
    const arrayList = [...selectedStatus];
    if (!selectedStatus.includes(code)) arrayList.push(code);
    else {
      let i = arrayList.indexOf(code);
      arrayList.splice(i, 1);
    }
    setStatus([...arrayList]);
  };

  const getJson = () => {
    return {
      verticals: selectedVerticals.length > 0 ? selectedVerticals : null,
      businessUnits:
        selectedBusinessUnits.length > 0 ? selectedBusinessUnits : null,
      countries: selectedCountries.length > 0 ? selectedCountries : null,
      vendor:true,
      projectStatus: selectedStatus.length > 0 ? selectedStatus : null,
      [projectList]: true,
    };
};
  const handleAdvancedFilter = (e) => {
    handleOpen(false);
    isClear(true);
    dispatch(projectsActions.getProjects(getJson()));
  };
  const getSearchBy = () => {
    let searchType = searchBy;
    switch (searchType) {
      case "ID":
        searchType = "projectId";
        break;
      case "NAME":
        searchType = "projectName";
        break;
      case "OP":
        searchType = "opNumber";
        break;
      case "PO":
        searchType = "proposalOwner";
        break;
      case "AN":
        searchType = "accountName";
        break;
      case "PM":
        searchType = "projectmanager";
        break;
      default:
        searchType = "projectId";
        break;
    }
    return searchType;
  };
  const [timelimit, setTimeLimit] = useState(0);
  const handleSearch = () => {
    if (timelimit) clearTimeout(timelimit);
    if (searchCharactors.length >= 3 || searchCharactors.length === 0) {
      setTimeLimit(
        setTimeout(() => {
          let jsonBody = {
            [getSearchBy()]: searchCharactors,
            vendor:true
          };
          dispatch(projectsActions.getProjects(jsonBody));
          isClear(true);
        }, 500)
      );
    }
  };
  const handleClear = () => {
    isClear(false);
    setVerticals([]);
    setStatus([]);
    setCountries([]);
    setBusinessUnits([]);
    dispatch({ type: "SEARCH_CHARACTORS", Charactors: "" });
    dispatch(projectsActions.getProjects({ myProject: true,vendor:true }));
    selectProjects("myProject");
  };
  const setCookies = () => {
    var now = new Date();
    now.setTime(now.getTime() + 1 * 3600 * 1000 * 24 * 7);
    let expirationDate = new Date(
      new Date().getTime() + 1 * 3600 * 1000 * 24 * 7
    );
    let newValue = {
      value: false,
      expirationDate: expirationDate.toISOString(),
    };
    localStorage.setItem("appMsg", JSON.stringify(newValue));
  };
  const handleRadio = (value) => {
    selectProjects(value);
  };
  const radioList = [
    { name: "My Projects", id: "myProject" },
    { name: "Team Projects", id: "teamProject" },
    { name: "All Projects", id: "allProjects" },
  ];

  const cancelOpen = () => {
    handleOpen();
    if (!clear) {
      setCountries([]);
      setVerticals([]);
      setBusinessUnits([]);
      setStatus([]);
    }
  };

  let length = Math.max(
    countries.length,
    verticals.length,
    projectStatus.length + 1,
    businessUnits.length
  );
  return (
    <>
    <div className="vendor_dashboard">
      <DashboardLayout
        navbar={
          <Navbar
            headerTitle="VENDOR DASHBOARD"
            onSearchBarValueChange={onSearchBarValueChange}
            onSearchBarTypeChange={onSearchBarTypeChange}
            handleOpen={onModalChange}
            handleSearch={handleSearch}
            handleClear={handleClear}
            searchCharactors={searchCharactors}
            clear={clear}
          />
        }
      >
        <React.Fragment>
          <h2>
            Showing {projects.length} of {totalItems} Projects
          </h2>
          {projects ? (
            <>
              <InfiniteScroll
                loadMore={fetchMoreData}
                hasMore={hasMore}
                isLoading={infiniteLoad}
                loading={<RecordsSpinner />}
                noMore={<RecordsBadge back_top={true} recordTypeLabel="projects" />}
                initialLoad={false}
              >
                <ExpandableRows {...data}></ExpandableRows>
              </InfiniteScroll>
            </>
          ) : null}
        </React.Fragment>
      </DashboardLayout>
    </div>
      <Modal isOpen={isOpen} toggle={() => handleOpen()} size="lg" centered={true}>
      <ModalHeader toggle={() => cancelOpen()}>
      {/* <img src={logo}  className="modallogo"/> */}
          
          </ModalHeader>
          <div>
            <span className="modaltitleadvancesearch">
            Refine Search
            </span>
          </div>
        <ModalBody id="scrollModalBody">
          <div className="col-md-12 projectType">
            {radioList.map((value, key) => (
              <Label className="col-md-2" check>
                <Input
                  type="radio"
                  checked={projectList === value.id}
                  onChange={() => {
                    handleRadio(value.id);
                  }}
                />
                {value.name}
              </Label>
            ))}
          </div>

          <Table borderless id="advancedSearch">
            <thead>
              <tr>
                <th>PROJECT STATUS</th>
                <th>COMMISSIONING COUNTRY</th>
                <th>VERTICALS</th>
                <th>BUSINESS UNITS</th>
              </tr>
            </thead>
            <tbody>
              {[...Array(length).keys()].map((index) => (
                <tr>
                  {projectStatus.map(
                    (status, key) =>
                      key === index &&
                      projectStatus.length > index && (
                        <td
                          onClick={() => hanldeProjectStatus(status.Code)}
                          className={
                            selectedStatus.includes(status.Code)
                              ? "selected-column"
                              : selectedStatus.includes(0)
                              ? "unselectable"
                              : "table-column"
                          }
                        >
                          {selectedStatus.includes(status.Code) && (
                            <span style={{ marginRight: "1%" }}>
                              <FontAwesomeIcon icon={faCheck} fixedWidth />
                            </span>
                          )}
                          {status.Label}
                        </td>
                      )
                  )}
                  {index == projectStatus.length && (
                    <td
                      onClick={() => hanldeProjectStatus(0)}
                      className={
                        selectedStatus.includes(0)
                          ? "selected-column"
                          : selectedStatus.length > 0 &&
                            !selectedStatus.includes(0)
                          ? "unselectable"
                          : "table-column"
                      }
                    >
                      {selectedStatus.includes(0) && (
                        <span style={{ marginRight: "1%" }}>
                          <FontAwesomeIcon icon={faCheck} fixedWidth />
                        </span>
                      )}
                      Ready for Commission{" "}
                      <FontAwesomeIcon
                        className="info"
                        icon={faInfoCircle}
                        title="Project ready for commissiong"
                      />
                    </td>
                  )}
                  {index > projectStatus.length && <td></td>}
                  {countries
                    .sort((a, b) => a.Label.localeCompare(b.Label))
                    .map(
                      (country, key) =>
                        key === index &&
                        countries.length > index && (
                          <td
                            onClick={() => hanldeCountries(country.Code)}
                            className={
                              selectedCountries.includes(country.Code)
                                ? "selected-column"
                                : "table-column"
                            }
                          >
                            {selectedCountries.includes(country.Code) && (
                              <span style={{ marginRight: "1%" }}>
                                <FontAwesomeIcon icon={faCheck} fixedWidth />
                              </span>
                            )}
                            {country.Label}
                          </td>
                        )
                    )}
                  {index >= countries.length && <td></td>}
                  {verticals.map(
                    (vertical, key) =>
                      key === index &&
                      verticals.length > index && (
                        <td
                          onClick={() => hanldeVerticals(vertical.Code)}
                          className={
                            selectedVerticals.includes(vertical.Code)
                              ? "selected-column"
                              : "table-column"
                          }
                        >
                          {selectedVerticals.includes(vertical.Code) && (
                            <span style={{ marginRight: "1%" }}>
                              <FontAwesomeIcon icon={faCheck} fixedWidth />
                            </span>
                          )}
                          {vertical.Label}
                        </td>
                      )
                  )}
                  {index >= verticals.length && <td></td>}
                  {businessUnits.map(
                    (bu, key) =>
                      key === index &&
                      businessUnits.length > index && (
                        <td
                          onClick={() => hanldeBusinessUnits(bu.Code)}
                          className={
                            selectedBusinessUnits.includes(bu.Code)
                              ? "selected-column"
                              : "table-column"
                          }
                        >
                          {selectedBusinessUnits.includes(bu.Code) && (
                            <span style={{ marginRight: "1%" }}>
                              <FontAwesomeIcon icon={faCheck} fixedWidth />
                            </span>
                          )}
                          {bu.Label}
                        </td>
                      )
                  )}
                  {index >= businessUnits.length && <td></td>}
                </tr>
              ))}
            </tbody>
          </Table>
          <div>
            <b>
              Note: <i>Ready for Commission: </i>
            </b>{" "}
            Displays the projects with status Proposal/Negotiation and
            Probability is 100.
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={(e) => {
              handleAdvancedFilter(e);
            }}
          >
            Filter
          </Button>{" "}
          <Button color="secondary" onClick={() => cancelOpen()}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={isWelcome} toggle={() => setWelcome(!isWelcome)} size="lg">
        <ModalHeader toggle={() => setWelcome(!isWelcome)}>
          <CardTitle>Welcome to CI Central v3.0</CardTitle>
        </ModalHeader>
        <ModalBody>
          <p>
            <strong className="text-uppercase">
              Important note for our users
            </strong>
          </p>
          <p>
            After last few months of tireless development efforts, we are
            excited to finally launch the next-generation CI workflow platfrom
            to our global CI markets.
          </p>
          <p>
            CI Central v1 was built with a collabrative effort from a very small
            team of internal developers by leveraging Appmaker - Google Gsuite's
            low-code appplication development environment for building custom
            business apps.
          </p>
          <p>
            Last year, when the pandemic had just started to disrupt the global
            order, we were met with another setback of our own. Google announced
            the Appmaker shut down within just over 3 years since it's launch on
            19 January, 2021.
          </p>
          <p>
            Having to migrate CI Central meant that everthing in v2 had to be
            built from the very ground up. Although this provided us an
            excellent opportunity to address the v1 limitations, it also meant
            having to work within a very tight, hard deadline.
          </p>
          <p>
            Ideally, we would have liked to involve users from all markets in
            extensive training sessions and UAT pre-launch. Unfortunately, to
            prevent any disruptions across our 40+ CI markets already on the
            platform we had no option but to launch v2.0 before 19 Jan, 2021.
          </p>
          <p>
            In the coming weeks, your local market administrators and the global
            team will be reaching out to understand the customizations required
            for your markets. For various countries who have not yet recieved
            the training, you can expect these sessions to be scheduled in next
            few days.
          </p>
          <p>
            Along with some performance optimizations, we also aim to roll out
            various suggested feature improvements in weekly minor releases.
            Meanwhile, if you encounter any issues or bugs, please reach out to
            your local contact or the global team.
          </p>
          <p>
            <strong>
              <em>
                For existing projects migrated from v1, please remember to first
                select the project's Business Unit and Industry Vertical and
                saving before creating new costing profiles.
              </em>
            </strong>
          </p>
          <p>
            <strong>We thank you for your support!</strong>
          </p>
          <p>
            <strong>Team CI Central</strong>
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={(e) => {
              handleCookies();
            }}
          >
            Do not show this message again
          </Button>{" "}
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={editStatusModal}
        toggle={() => setEditStatusModal(!editStatusModal)}
        size="sm"
        centerd={true}
      >
        <ModalHeader toggle={() => setEditStatusModal(!editStatusModal)}>
        {/* <img src={logo}  className="modallogo"/> */}

        </ModalHeader>
        <div>
          <span className="modaltitle modaltitleprojectstatus">
          Change Project Status
          </span>
        </div>
        <ModalBody>
          <p>
            <strong>{currentSelectedProject.ProjectName}</strong>
            <br />
            <strong>{currentSelectedProject.ProjectId}</strong>
          </p>
          <p>
            <em>Project status change guidelines here.</em>
          </p>
          <p>Change Project Status To:</p>
          <SelectWrapper
            onChange={(e) =>
              setCurrentSelectedProject({
                ...currentSelectedProject,
                ProjectStatus: e.target.value,
              })
            }
            defaultValue={currentSelectedProject.ProjectStatus}
          >
                 {selectedProjectStatus == 7 ? (
                          projectStatus
                          .filter((ps) => ["1"].indexOf(ps.Code) !== -1)
                          .map((ps) => (
                              <option value={ps.Code}>{ps.Label}</option>
                          ))
                      ): (
                        projectStatus
                            .filter((ps) => ["4", "5", "98"].indexOf(ps.Code) !== -1)
                            .map((ps) => (
                                <option value={ps.Code}>{ps.Label}</option>
                            ))
                      )}
            {/* {projectStatus
              .filter((ps) => ["4", "5", "98"].indexOf(ps.Code) !== -1)
              .map((ps) => (
                <option value={ps.Code}>{ps.Label}</option>
              ))} */}
          </SelectWrapper>
          <Label className="mb-2 mt-2">Project Status Notes:</Label>
          <Input
            type="textarea"
            id="NotesFinance"
            onChange={(e) =>
              setCurrentSelectedProject({
                ...currentSelectedProject,
                NotesProjectStatus: e.target.value,
              })
            }
            value={
              currentSelectedProject.NotesProjectStatus
                ? currentSelectedProject.NotesProjectStatus
                : ""
            }
            rows="3"
            placeholder="Please provide any relevant notes any project status related notes here..."
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            disabled={app.recordLoading}
            onClick={() => {
              dispatch(
                saveProject(
                  currentSelectedProject,
                  () => setEditStatusModal(!editStatusModal),
                  true
                )
              );
            }}
          >
            Update
            {app.recordloading ? (
              <Spinner size="small" color="#495057" />
            ) : null}
          </Button>
          <Button
            color="secondary"
            disabled={app.recordLoading}
            onClick={() => setEditStatusModal(!editStatusModal)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Vdashboard;
