import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Card,
    CardHeader,
    Col,
    Container,
    CustomInput,
    Row,
    Table, Input,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import update from "immutability-helper";
import _ from "lodash";
import Selector from "../../components/Selector/Selector_ProjectReview";
import {
    setCurrentWaveSpec,
    setWaveSpecs,
} from "../../redux/actions/waveSpecsActions";
import { selectWaveSpec, updateCurrentWaveSpec } from "../../redux/actions/currentWaveSpecActions";
import { saveCostingProfile, updateProfile } from "../../redux/actions/currentCostingActions";
import Spinner from "../../components/Spinner";
import { getLabel } from "../../utils/codeLabels";
import Select from "react-select";
import { setCountrySpecs } from "../../redux/actions/countrySpecsActions";
import Project from "../../layouts/Project";

const ReviewCosting = ({ profile, indxFC, methodology, indxSM,pacificMarkets,disableforCS }) => {
    const dispatch = useDispatch();
    const currentProject = useSelector(
        ({ currentProject }) => currentProject.newProject
    );

    const [selectedItemWave, setSelectedItemWave] = useState("1");
    const [waveEditModal, setWaveEditModal] = useState(false);
    const [editableWaveName, setEditableWavename] = useState();

    const currencies = useSelector(({ currentCosting }) =>
        profile.ProfileSetting &&
            profile.ProfileSetting.CurrenciesData
            ? profile.ProfileSetting.CurrenciesData
            : []
    );
    const countrySpecs = useSelector(({ countrySpecs }) => countrySpecs);
    const waveSpecs = useSelector(({ waveSpecs }) => waveSpecs);
    // const currentWaveSpec = useSelector(({ currentWaveSpec }) => currentWaveSpec);
    const app = useSelector(({ app }) => app);
    const [showManualCostEntry, setShowManualCostEntry] = useState(false);
    const [showSheetsCosts, setShowSheetsCosts] = useState(false);
    const [showCostMethod, setShowCostMethod] = useState(false);
    // const [currentWaveSpec, setEditableWaveSpec] = useState({
    //     ...currentWaveSpec,
    // });
    const [countryDetails, setCountryDetails] = useState({});
    const [currentCurrency, setCurrentCurrency] = useState();
    const [costsDataActualStatus, setCostsActualFieldsStatus] = useState({});
    const userRecord = useSelector(({ user }) => user.userRecord);
    const [waveBreakDown, setWaveBreakDown] = useState({})
    const [currentWaveSpec, setcurrentWaveSpec] = useState([])
    const [modifiedMeths, setModifiedMeths] = useState([])
    const [wavesSpecsData, setwavesSpecsData] = useState(profile.WaveSpecs);
    const [activeTabFC, setActiveTabFC] = useState();
    const [activeTabSM, setActiveTabSM] = useState();

    useEffect(() => {
        if (profile &&
            wavesSpecsData
            && wavesSpecsData.length
            && !Object.keys(waveBreakDown).length) {

            let data = checkCurrentWaveSpec(profile, selectedItemWave);
            setcurrentWaveSpec(data);
        }
        else
        {
            let rData = (profile.CountrySpecs[indxFC]);
            let checData = (rData?.MethodologySpecs[indxSM]);        
            let _waveBreakdownMain = profile.WaveSpecs.filter(x => x.MethodologySpecId === checData.id);
            setModifiedMeths(_waveBreakdownMain);
        }
    }, [profile])
    useEffect(() => {
        if (
            currencies &&
            !currentCurrency &&
            profile.CostInputCurrency
        ) {
            let values = profile.CostInputCurrency.split("-");
            let currencyUnit = _.last(values);
            let countryCode = _.head(values);
            let finalCurrency = _.head(
                currencies.filter(
                    (cur) => cur.Code == countryCode && cur.CurrencyUnit == currencyUnit
                )
            );
            setCurrentCurrency(finalCurrency);
        }
    }, [profile]);

    useEffect(() => {
        if (countrySpecs && countrySpecs.length && !Object.keys(countryDetails).length) {
            let countrydetails = {};
            countrySpecs.map((cs) => {
                countrydetails[cs.CountryCode] = {};
                cs.MethodologySpecs.map((ms) => {
                    countrydetails[cs.CountryCode][ms.Code] = ms.CostsData;
                });
            });
            setCountryDetails(countrydetails);

        }
    }, [countrySpecs]);

    const checkCurrentWaveSpec = (profileVal, selectedItemWave) => {
        let rData = (profileVal.CountrySpecs[indxFC]);
        let checData = (rData?.MethodologySpecs[indxSM]);
        setActiveTabFC(rData.id);
        setActiveTabSM(checData.id);
        let _waveBreakdownMain = profileVal.WaveSpecs.filter(x => x.MethodologySpecId === checData.id);
        let _waveBreakdown = _.groupBy(_waveBreakdownMain, m => m.WaveNumber);
        setModifiedMeths(_waveBreakdownMain);

        Object.keys(_waveBreakdown).map(w => {
            _waveBreakdown[w] = _.uniqBy(_waveBreakdown[w], t => t.MethodologyLabel);
        });
        setwavesSpecsData(profileVal.WaveSpecs);
        setWaveBreakDown(_waveBreakdown);
        let _currentwaveset = _.head(_waveBreakdown[selectedItemWave]);
        return _currentwaveset;
    }
    const [reviewCostFields, setReviewCostFields] = useState({
        OpsPM: {
            //rfq
            Internal: "CostIntOpsPM",
            External: "",
            Label: "Project Management (Ops Only)",
        },
        SurveyProgramming: {
            decisiveField: "surveyProgrammingResource",
            Internal: "CostIntOpsSurveyProgramming",
            External: "CostExtOpsSurveyProgramming",
            Label: "Survey Programming",
        },
        DataProcessing: {
            decisiveField: "dataProcessingResource",
            Internal: "CostIntOpsDataProcessing",
            External: "CostExtOpsDataProcessing",
            Label: "Data Processing",
        },
        Charting: {
            decisiveField: "chartingResource",
            Internal: "CostIntOpsCharting",
            External: "CostExtOpsCharting",
            Label: "Charting",
        },
        Coding: {
            decisiveField: "verbatimCodingResource",
            Internal: "CostIntOpsVerbatimCoding",
            External: "CostExtOpsVerbatimCoding",
            Label: "Verbatim Coding",
        },
        DataEntry: {
            decisiveField: "dataEntryResource",
            Internal: "CostIntOpsDataEntry",
            External: "CostExtOpsDataEntry",
            Label: "Data Entry",
        },
        OnlineSample: {
            //rfq
            Internal: "",
            External: "CostExtOpsOnlineSample",
            Label: "Online Sample",
        },
        AdditionalOperationsSupport: {
            //bool
            decisiveField: "additionalOperationsSupport",
            Internal: "CostIntOpsAdditionalOperationsSupport",
            External: "",
            Label: "Additional Operations Support (PM Rates)",
            isBool: true,
        },
        OtherDataPreparation: {
            //bool
            decisiveField: "otherDataPreparationAssistance",
            Internal: "CostIntOpsOtherDataPreparation",
            External: "",
            Label: "Other Data Preparation",
            isBool: true,
        },
        DataScience: {
            //internal-operations//bool
            decisiveField: "dataScienceRequired",
            Internal: "CostIntOpsDataScience",
            External: "",
            Label: "Data Science (Ops Only)",
            isBool: true,
        },
        TextAnalytics: {
            decisiveField: "textAnalytics", //bool
            Internal: "",
            External: "CostExtOpsTextAnalytics",
            Label: "Text Analytics",
            isBool: true,
        },
        Hosting: {
            //rfq
            Internal: "",
            External: "CostExtOpsHosting",
            Label: "Online Hosting",
        },
    });

    const getDecisiveFieldValue = (orf) => {
        if (reviewCostFields[orf].decisiveField && !reviewCostFields[orf].isBool) {
            return currentWaveSpec.OpsResourcesData
                ? currentWaveSpec.OpsResourcesData[reviewCostFields[orf].decisiveField]
                : null;
        } else if (
            reviewCostFields[orf].decisiveField &&
            reviewCostFields[orf].isBool
        ) {
            if (
                currentWaveSpec.OpsResourcesData &&
                currentWaveSpec.OpsResourcesData[reviewCostFields[orf].decisiveField]
            ) {
                if (reviewCostFields[orf].Internal) return "Internal";
                else return "External";
            } else return null;
        } else {
            if (reviewCostFields[orf].Internal) return "Internal";
            else return "External";
        }
    };
    const getCurrentCurrency = (actualvalue) => {
        if (
            currencies &&
            currentCurrency &&
            currentCurrency.ConversionRateToLocal
        ) {
            if (actualvalue) {
                let finalVal = _.round(
                    actualvalue * currentCurrency.ConversionRateToLocal,
                    2
                );
                return `${finalVal} ${currentCurrency.CurrencyUnit}`;
            } else return `0 ${currentCurrency.CurrencyUnit}`;
        }
    };
    const getCurrentCurrencyValue = (actualvalue) => {
        if (
            currencies &&
            currentCurrency &&
            currentCurrency.ConversionRateToLocal
        ) {
            if (actualvalue) {
                let finalVal = _.round(
                    actualvalue * currentCurrency.ConversionRateToLocal,
                    2
                );
                return finalVal;
            }
            else return ""
        }
    };
    const getFinalProperties = (properties) => {
        let finalProperties = [];
        if (profile.IsMultiCountry) {
            // if (countrySpecs.length > 1) {
            Object.keys(properties).map((prop) => {
                if (properties[prop].isMultiCountry == true || properties[prop].isMultiCountry == undefined) {
                    finalProperties.push(prop);
                }
            });
        } else {
            Object.keys(properties).map((prop) => {
                if (properties[prop].isMultiCountry == false || properties[prop].isMultiCountry == undefined) {
                    finalProperties.push(prop);
                }
            });
        }

        return finalProperties;
    };
    const getDefaultCurrValue = (value) => {
        if (currencies) {
            let currentCurrencyConversion = !profile.CostInputCurrency || profile.CostInputCurrency == "US-USD" ? 1 : _.head(currencies.filter((c) => c.CurrencyUnit == _.last(profile.CostInputCurrency.split("-")))).ConversionRateToLocal;
            return _.round(value * currentCurrencyConversion, 2);
        } else {
            return null;
        }
    };
    const canGetValue = (prop) => {
        let status = false
        if (countryDetails) {
            Object.keys(countryDetails).map(CountryCode => {
                if (countryDetails[CountryCode])
                    Object.keys(countryDetails[CountryCode]).map(Code => {
                        if (countryDetails[CountryCode][Code]
                            && countryDetails[CountryCode][Code][prop])
                            status = true
                    })
            })
        }
        return status
    }
    const onNotesHandler = (eve) => {
        let waveSpecsToSave = [...profile.WaveSpecs];
        setwavesSpecsData(profile.WaveSpecs)
        let profileToSave = { ...profile };
        let currentwavespec = {}
        currentwavespec = checkCurrentWaveSpec(profileToSave, selectedItemWave);
        setcurrentWaveSpec(currentwavespec)
        let editablewavespec = { ...currentwavespec };
        if (!editablewavespec.NotesActual)
            editablewavespec.NotesActual = {};
        if (!editablewavespec.NotesActual["CostingNotes"])
            editablewavespec.NotesActual["CostingNotes"] = {}
        editablewavespec.NotesActual["CostingNotes"] = { ...editablewavespec.NotesActual["CostingNotes"] }
        editablewavespec.NotesActual["CostingNotes"][eve.target.id] = eve.target.value;
        waveSpecsToSave = waveSpecsToSave.map((wss) => {
            if (wss.id == editablewavespec.id)
                wss.NotesActual = { ...editablewavespec.NotesActual }
            return wss;
        });
        setcurrentWaveSpec({
            ...editablewavespec,
            NotesActual: { ...editablewavespec.NotesActual }
        });
        // dispatch(setWaveSpecs(waveSpecsToSave));
        // dispatch(saveCostingProfile({ ...profileToSave, WaveSpecs: waveSpecsToSave }));
        setwavesSpecsData(waveSpecsToSave);

        const profileTemp = update(profileToSave, {
            WaveSpecs: { $set: waveSpecsToSave }
        });
        dispatch(updateProfile(profileTemp));
    };

    const onCurrencyChange = (eve) => {
        let waveSpecsToSave = [...profile.WaveSpecs];
        let profileToSave = { ...profile };
        let currentwavespec = checkCurrentWaveSpec(profileToSave, selectedItemWave);
        setcurrentWaveSpec(currentwavespec);
        let editablewavespec = { ...currentwavespec, ActualCostData: { ...currentwavespec.ActualCostData } }
        editablewavespec[eve.target.id] = eve.target.value / currentCurrency.ConversionRateToLocal;
        // setcurrentWaveSpec({ ...editablewavespec });
        let data = {
            ...currentwavespec, ActualCostData: currentwavespec.ActualCostData ?
                {
                    ...currentwavespec.ActualCostData,
                    [eve.target.id]:
                        eve.target.value /
                        currentCurrency.ConversionRateToLocal
                } : {
                    [eve.target.id]:
                        eve.target.value /
                        currentCurrency.ConversionRateToLocal
                }

        }

        waveSpecsToSave = waveSpecsToSave.map((wss) => {
            if (wss.id == data.id)
                wss.ActualCostData = { ...data.ActualCostData }
            return wss;
        });
        setwavesSpecsData(waveSpecsToSave);
        setcurrentWaveSpec(data);

        const profileTemp = update(profileToSave, {
            WaveSpecs: { $set: waveSpecsToSave }
        });
        dispatch(updateProfile(profileTemp));

    }
    const onOtherNotesHandler = (eve) => {
        let waveSpecsToSave = [...profile.WaveSpecs];
        setwavesSpecsData(profile.WaveSpecs)
        let profileToSave = { ...profile };
        let editablewavespec = { ...currentWaveSpec };
        if (!editablewavespec.NotesActual)
            editablewavespec.NotesActual = {};
        if (!editablewavespec.NotesActual["CostingNotes"])
            editablewavespec.NotesActual["CostingNotes"] = {}
        editablewavespec.NotesActual["CostingNotes"] = { ...editablewavespec.NotesActual["CostingNotes"] }
        editablewavespec.NotesActual["CostingNotes"]["OtherNotes"] = eve.target.value;
        waveSpecsToSave = waveSpecsToSave.map((wss) => {
            if (editablewavespec.id == wss.id) {
                wss = { ...editablewavespec }
            }
            if (!wss.NotesActual)
                wss.NotesActual = {}
            if (!wss.NotesActual["CostingNotes"])
                wss.NotesActual["CostingNotes"] = {}

            wss.NotesActual = { ...wss.NotesActual, CostingNotes: { ...wss.NotesActual.CostingNotes, OtherNotes: eve.target.value } }
            return wss;
        });

        setcurrentWaveSpec({
            ...editablewavespec,
            NotesActual: { ...editablewavespec.NotesActual }
        });

        dispatch(setWaveSpecs(waveSpecsToSave));
        setwavesSpecsData(waveSpecsToSave);
        const profileTemp = update(profileToSave, {
            WaveSpecs: { $set: waveSpecsToSave }
        });
        dispatch(updateProfile(profileTemp));
    }

    const submitCurrentWaveSpec = () => {
        // let waveSpecsToSave = [...wavesSpecsData];
        // let profileToSave = { ...profile };
        // waveSpecsToSave = waveSpecsToSave.map((wss) => {
        //     if (wss.id == currentWaveSpec.id) {
        //         wss = { ...currentWaveSpec };
        //     }
        //     return wss;
        // });
        // dispatch(updateCurrentWaveSpec({ ...currentWaveSpec }));
        // dispatch(setWaveSpecs(waveSpecsToSave));
        // dispatch(saveCostingProfile({ ...profileToSave, WaveSpecs: waveSpecsToSave }));
        console.log(profile, "Costing Save")
    };

    const selectorHandler = (item) => {
        setSelectedItemWave(item.WaveNumber);
        let _waveBreakdownMain = profile.WaveSpecs.filter(x => x.MethodologySpecId === activeTabSM)
        let _waveBreakdown = _.groupBy(_waveBreakdownMain, m => m.WaveNumber)
        setModifiedMeths((_waveBreakdownMain))
        Object.keys(_waveBreakdown).map(w => {
            _waveBreakdown[w] = _.uniqBy(_waveBreakdown[w], t => t.MethodologyLabel)
        })
        // do nothing if clicked item is current item
        if (item === (currentWaveSpec)?.MethodologySpecId) return;
        else {
            let data = _.head(_waveBreakdown[item.WaveNumber])
            setcurrentWaveSpec(data);
        }
    };

    const renderSelector = () => { 
        return (
            <Col lg="2" md="2" sm="12" xs="12">
                <Selector
                    heading={"Waves"}
                    records={modifiedMeths}
                    clicked={selectorHandler}
                    interPolField={["WaveNumber", "WaveName"]}
                    displayField={
                        <>
                            <FontAwesomeIcon
                                size="xs"
                                icon={faPen}
                                className={userRecord.IsOpsProjectManager ? "pointer" : "no-actions"}
                                onClick={() => {
                                    if (userRecord.IsOpsProjectManager)
                                        setWaveEditModal(!waveEditModal)
                                }}
                            />
                        </>
                    }
                    selected={currentWaveSpec}
                />
            </Col>
        );
    };
    const summaryCurrencyChange = (value) => {
        let editableprofile = {
            ...profile,
            CountrySpecs: [...profile.CountrySpecs],
            CostInputCurrency: value.value,
            currencies,
        };
        let reqValue = value.value;
        let values = reqValue.split("-");
        let currencyUnit = _.last(values);
        let countryCode = _.head(values);
        let finalCoversionUnit = "";
        if (currencies && currencies.length) {
            let finalCurr = _.head(
                currencies.filter(
                    (cur) => cur.Code == countryCode && cur.CurrencyUnit == currencyUnit
                )
            );
            setCurrentCurrency({ ...finalCurr });
            finalCoversionUnit = finalCurr.ConversionRateToLocal;
        }

        dispatch(updateProfile({ ...editableprofile }));
    };
    const getCurrentCurrencyUnit = (currencyInput) => {
        if (!currencyInput) currencyInput = "US-USD";
        let values = currencyInput.split("-");
        let currencyUnit = _.last(values);
        let countryCode = _.head(values);
        let finalCurrency = null;
        if (currencies && currencies.length) {
            finalCurrency = _.head(
                currencies.filter(
                    (cur) => cur.Code == countryCode && cur.CurrencyUnit == currencyUnit
                )
            );
            finalCurrency = {
                value: `${finalCurrency.Code}-${finalCurrency.CurrencyUnit}`,
                label: `${finalCurrency.Label} (1USD = ${finalCurrency.ConversionRateToLocal}${finalCurrency.CurrencyUnit})`,
            };
        }
        return finalCurrency;
    };
    const updateProfileCostsActual = (currentMeth, currentCountry) => {
        let editableProfile = { ...profile }
        editableProfile.CountrySpecs = editableProfile.CountrySpecs.map(cs => {
            cs.MethodologySpecs = cs.MethodologySpecs.map(ms => {
                if (cs.CountryCode == currentCountry.CountryCode
                    && ms.Code == currentMeth.Code) {
                    ms = { ...currentMeth }
                }
                return { ...ms }
            })
            return { ...cs }
        })
        dispatch(setCountrySpecs(editableProfile.CountrySpecs))
        dispatch(updateProfile(editableProfile))
    }
    const onChangeHandler = (eve, useCurrentSpec, isApi) => {
        let currentwavespec = {};
        currentwavespec = checkCurrentWaveSpec(profile, selectedItemWave);
        setcurrentWaveSpec(currentwavespec);
        currentwavespec = {
            ...currentwavespec,
            [eve.target.id]: eve.target.value,
        };

        let wavespecs = [...profile.WaveSpecs];
        wavespecs = wavespecs.map((ws) => {
            if (ws.id == currentWaveSpec.id) {
                ws = currentwavespec;
            }
            return { ...ws };
        });

        setcurrentWaveSpec(currentwavespec);
        let mappedData = modifiedMeths.map(x => {
            if (x.id === currentwavespec.id) {
                x = { ...currentwavespec }
            }
            return x;
        })
        setModifiedMeths(mappedData);
        setwavesSpecsData(wavespecs)

        const profileTemp = update(profile, {
            WaveSpecs: { $set: wavespecs }
        });
        dispatch(updateProfile(profileTemp));
        if (isApi) {
            setWaveEditModal(false);
        }
    };
    return (

        <> <Row className="justify-content-end">
            <Col sm="6" xs="12" lg="4" md="4" >
                <Select
                    placeholder="Select a Currency..."
                    options={currencies?.map((c) => {
                        return {
                            value: `${c.Code}-${c.CurrencyUnit}`,
                            label: `${c.Label} (1USD = ${c.ConversionRateToLocal}${c.CurrencyUnit})`,
                        };
                    })}
                    value={
                        profile.CostInputCurrency
                            ? getCurrentCurrencyUnit(
                                profile.CostInputCurrency
                            )
                            : null
                    }
                    onChange={summaryCurrencyChange}
                /></Col>
        </Row>
            <Row>
                <Container fluid>
                    <Card className="p-4">
                    {  (_.includes(pacificMarkets, currentProject.CommissioningCountry) && currentProject.CostingTemplateVersion != "v2.0") && 
                   <>  <Row>
                            {renderSelector()}
                        <Col>
                                <Row className="m-0">
                                    <h5>
                                        Auto-calculated costs{profile.IsTracker ? " (per wave)" : ""}
                                    </h5>
                                </Row>
                                <Table className="review-costing-main-table">
                                    {/* <Row className="h4 mb-5"> */}
                                    <thead><tr>
                                        <th>Costing Component</th>
                                        <th>Notes</th>
                                        <th>Commissioned Value</th>
                                        <th>Actual Value</th></tr>
                                    </thead>
                                    {/* </Row> */}
                                    <tbody>
                                        {Object.keys(reviewCostFields).map((orf, index) => {
                                            let decisiveField = getDecisiveFieldValue(orf)
                                            return decisiveField ? (
                                                <tr key={index}>
                                                    <td>
                                                        {reviewCostFields[orf].Label}
                                                        {currentWaveSpec.OpsResourcesData ? (
                                                            <span className="decisive-pop-value">
                                                                {decisiveField}
                                                            </span>
                                                        ) : null}
                                                    </td>

                                                    <td>
                                                        <textarea
                                                            disabled={!userRecord.IsOpsProjectManager||disableforCS}
                                                            readOnly={!userRecord.IsOpsProjectManager}
                                                            className="review-costing-notes form-control"
                                                            value={
                                                                currentWaveSpec.NotesActual &&
                                                                    currentWaveSpec.NotesActual.CostingNotes &&
                                                                    currentWaveSpec.NotesActual.CostingNotes[orf]
                                                                    ? currentWaveSpec.NotesActual.CostingNotes[orf]
                                                                    : ""
                                                            }
                                                            id={orf}
                                                            onChange={onNotesHandler}
                                                        ></textarea>
                                                    </td>
                                                    <td>
                                                        <span>
                                                            {getCurrentCurrency(
                                                                currentWaveSpec[
                                                                reviewCostFields[orf][decisiveField]
                                                                ]
                                                            )}
                                                        </span>
                                                    </td>
                                                    <td>

                                                        <div class="input-group">
                                                            <input
                                                                disabled={!userRecord.IsOpsProjectManager||disableforCS}
                                                                readOnly={!userRecord.IsOpsProjectManager}
                                                                className="form-control"
                                                                type="number"
                                                                min={0}
                                                                id={
                                                                    `${reviewCostFields[orf][decisiveField]}Actual`
                                                                }
                                                                step="any"
                                                                value={currentWaveSpec.ActualCostData
                                                                    && currentWaveSpec.ActualCostData[
                                                                    `${reviewCostFields[orf][decisiveField]}Actual`
                                                                    ] ?
                                                                    getCurrentCurrencyValue(
                                                                        currentWaveSpec.ActualCostData[
                                                                        `${reviewCostFields[orf][decisiveField]}Actual`
                                                                        ]
                                                                    ) : ""}
                                                                // onChange={(eve) => {
                                                                //     setCurrentWaveSpec({
                                                                //         ...currentWaveSpec, ActualCostData: currentWaveSpec.ActualCostData ?
                                                                //             {
                                                                //                 ...currentWaveSpec.ActualCostData,
                                                                //                 [`${reviewCostFields[orf][decisiveField]}Actual`]:
                                                                //                     eve.target.value /
                                                                //                     currentCurrency.ConversionRateToLocal
                                                                //             } : {
                                                                //                 [`${reviewCostFields[orf][decisiveField]}Actual`]:
                                                                //                     eve.target.value /
                                                                //                     currentCurrency.ConversionRateToLocal
                                                                //             }

                                                                //     })
                                                                // }}
                                                                onChange={onCurrencyChange}
                                                            />
                                                            <div className="input-group-append">
                                                                <span className="input-group-text text-sm">
                                                                    {currentCurrency?.CurrencyUnit}
                                                                </span>
                                                            </div>
                                                        </div>

                                                    </td>
                                                </tr>
                                            ) : null;
                                        })}
                                    </tbody>
                                </Table>

                                <hr className="border"></hr>

                            </Col>
                        </Row>
                        <hr></hr>
                        </>}
                        {methodology.CostsData !== null
                            ?
                            <>
                                <Row className="m-0">
                                    <h5>
                                        All other costs{profile.IsTracker ? " (all waves combined)" : ""}
                                    </h5>
                                </Row>
                                <Row className={`mt-4${countrySpecs.length > 1 ? " costbreakdown-in-review" : ""}`}>
                                    <div className="wrapper">
                                        <Table inline hover bordered={true} size="sm">
                                            <thead className="border"><tr><th className="h4">Breakdown by Methodology</th>
                                                <> <th className="text-center">
                                                    <span className="d-flex align-middle text-nowrap">
                                                        {getLabel("FieldingCountriesOptions", profile.CountrySpecs[indxFC].CountryCode)}
                                                    </span><br></br>
                                                    Commissioned Value
                                                </th>
                                                    <th className="text-center">
                                                        <span className="d-flex align-middle text-nowrap">
                                                            {getLabel("FieldingCountriesOptions", profile.CountrySpecs[indxFC].CountryCode)}
                                                        </span><br></br>
                                                        Actual Value
                                                    </th></></tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    countrySpecs && countrySpecs.length ? (

                                                        <>
                                                            <tr>
                                                                <td className="main-meth-label text-uppercase">
                                                                    <h5>{methodology.Label}</h5>
                                                                </td>
                                                                {countrySpecs.map((cs) => (
                                                                    <><td></td>
                                                                        <td></td></>
                                                                ))}
                                                                {/* only for border styles purpose, todo: optimize */}
                                                            </tr>

                                                            {methodology.CostsSchema.map((costsch) => {
                                                                let finalProps = getFinalProperties(costsch.properties);

                                                                return finalProps.length ? (
                                                                    <>
                                                                        {methodology.CostsData && finalProps.filter(fp => methodology.CostsData[fp]).length ? <tr className="mt-4 h5">
                                                                            <td>
                                                                                <strong>{costsch.title}</strong>
                                                                                {/* only for border styles purpose, todo: optimize */}
                                                                            </td>
                                                                            {countrySpecs.map((cs) => (
                                                                                <><td></td>
                                                                                    <td></td></>
                                                                            ))}
                                                                        </tr> : null}
                                                                        {finalProps.map((prop) => {
                                                                            return (costsch.properties[prop].title && canGetValue(prop)) ? (
                                                                                <tr>
                                                                                    <td className="sub-meth-label">{costsch.properties[prop].title}</td>
                                                                                    <><td>
                                                                                        <span>{
                                                                                            countryDetails[profile.CountrySpecs[indxFC].CountryCode] && countryDetails[profile.CountrySpecs[indxFC].CountryCode][methodology.Code] && countryDetails[profile.CountrySpecs[indxFC].CountryCode][methodology.Code][prop]
                                                                                                ? getDefaultCurrValue(countryDetails[profile.CountrySpecs[indxFC].CountryCode][methodology.Code][prop], profile.CountrySpecs[indxFC])
                                                                                                : "0.00"
                                                                                        } {profile.CostInputCurrency ? _.last(profile.CostInputCurrency.split("-")) : "USD"}</span>
                                                                                    </td>
                                                                                        <td>
                                                                                            <div class="input-group">
                                                                                                <input
                                                                                                    disabled={!userRecord.IsOpsProjectManager||disableforCS}
                                                                                                    readOnly={!userRecord.IsOpsProjectManager}
                                                                                                    className="form-control"
                                                                                                    type="number"
                                                                                                    min={0}
                                                                                                    id={
                                                                                                        `${prop}Actual`
                                                                                                    }
                                                                                                    step="any"
                                                                                                    defaultValue={methodology.CostsDataActual
                                                                                                        && methodology.CostsDataActual[prop] ?
                                                                                                        getCurrentCurrencyValue(
                                                                                                            methodology.CostsDataActual[prop]
                                                                                                        ) : null}
                                                                                                    onChange={(eve) => {
                                                                                                        updateProfileCostsActual({
                                                                                                            ...methodology, CostsDataActual: methodology.CostsDataActual ?
                                                                                                                {
                                                                                                                    ...methodology.CostsDataActual,
                                                                                                                    [prop]:
                                                                                                                        eve.target.value /
                                                                                                                        currentCurrency.ConversionRateToLocal
                                                                                                                } : {
                                                                                                                    [prop]:
                                                                                                                        eve.target.value /
                                                                                                                        currentCurrency.ConversionRateToLocal
                                                                                                                }

                                                                                                        }, profile.CountrySpecs[indxFC]);
                                                                                                    }}
                                                                                                />
                                                                                                <div class="input-group-append">
                                                                                                    <span class="input-group-text text-sm">
                                                                                                        {currentCurrency?.CurrencyUnit}
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>

                                                                                        </td></>

                                                                                </tr>
                                                                            ) : null;
                                                                        })}
                                                                    </>
                                                                ) : null;
                                                            })}
                                                        </>

                                                    ) : (
                                                        <></>
                                                    )
                                                    // })
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                </Row>
                                <hr></hr>
                                <Col>
                                    <Row>
                                        <strong>Other costing related notes</strong>
                                    </Row>
                                    <Row>
                                        <textarea
                                            id="OtherNotes"
                                            value={
                                                currentWaveSpec.NotesActual &&
                                                    currentWaveSpec.NotesActual.CostingNotes &&
                                                    currentWaveSpec.NotesActual.CostingNotes["OtherNotes"]
                                                    ? currentWaveSpec.NotesActual.CostingNotes["OtherNotes"]
                                                    : ""
                                            }
                                            onChange={onOtherNotesHandler}
                                            className="form-control other-notes-textarea"
                                            disabled={disableforCS}
                                        ></textarea>
                                    </Row>
                                </Col>
                            </> : ''
                        }
                    </Card>
                </Container>
            </Row>
            <Modal
                isOpen={waveEditModal}
                toggle={() => setWaveEditModal(!waveEditModal)}
                centered
            >
                <ModalHeader toggle={() => setWaveEditModal(!waveEditModal)}>
                    Edit Current Wave Name
                </ModalHeader>
                <ModalBody>
                    <Input
                        defaultValue={
                            currentWaveSpec.WaveName ? currentWaveSpec.WaveName : ""
                        }
                        id="WaveName"
                        onChange={(e) => setEditableWavename(e.target.value)}
                        placeholder="Enter Wave Name"
                    />
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        disabled={app.recordloading}
                        onClick={(e) => {
                            onChangeHandler(
                                { target: { id: "WaveName", value: editableWaveName } },
                                null,
                                true
                            );
                        }}
                    >
                        Update
                    </Button>
                    <Button
                        color="secondary"
                        disabled={app.recordloading}
                        onClick={() => setWaveEditModal(false)}
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </>);
};
export default ReviewCosting;
