import * as types from "../actions/marketDefaultsActions";

const initialState = {
  users:[]
};

export default function marketDefaultsReducer(state = initialState, actions) {
  switch (actions.type) {
    case types.LOAD_ITEMS:
      return {
        ...state,
        items: actions.items
      }
      case types.SET_USERS:
        return {
          ...state,
          users:[...state.users,actions.UsersData],
        };  
    default:
      return state;
  }
}