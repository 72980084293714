import { takeEvery, call, put } from "redux-saga/effects";
import * as types from "../actions/FetchVendorAndList/ActionTypes";
import axios from '../../axios-interceptor';
import { SET_PAGELOAD } from "../actions/appActions";

function* fetchVendorAndList(action) {
    try {
        if(action.payload.pageload){yield put({ type: SET_PAGELOAD, pageloaded: false })}
       
        let url = "FetchVendorAndLists/list"
        let filterationvalues = action.payload
        const ListData = yield axios.post(url, filterationvalues);

        let tempList = [];
        ListData.data.items.forEach(x => {
            let tempObj = { Group: x.Group, List: [] };
            x.Data.forEach(y => {
                let temp = { ...y };
                temp.label = x.Group == "Vendors" ? temp.VendorName : temp.Label;
                temp.value = temp.id;

                if (x.Group == 'fieldexecutionandsudtable') {
                    // y.List=y.List.map(val=>{return {[val.name]:JSON.parse( val.values)}})
                    let name = y.name;
                    let values = y.values;
                    temp = {};
                    temp.Name = name;
                    temp.Values = eval(values)
                }
                tempObj.List.push(temp);
            });
            tempList.push(tempObj);
        });
        yield put({ type: types.FETCH_VENDOR_AND_LIST_SUCCESS, payload: tempList });
      
        yield put({ type: SET_PAGELOAD, pageloaded: true });
    }
    catch (error) {
        const errorMsg = error.message;
        yield put({ type: types.FETCH_VENDOR_AND_LIST_FAILURE, payload: errorMsg });
    }
}

export default function* waitForLoadVendorAndList() {
    yield takeEvery(types.FETCH_VENDOR_AND_LIST_REQUEST, fetchVendorAndList);
}

