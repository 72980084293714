import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import update from "immutability-helper";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import * as currentProjectActions from "../../redux/actions/currentProjectActions";
import * as costingsActions from "../../redux/actions/costingsActions";
import * as currentCostingActions from "../../redux/actions/currentCostingActions";
import * as currentWaveSpecActions from "../../redux/actions/currentWaveSpecActions";
import axios from "../../axios-interceptor";
import { toastr } from "react-redux-toastr";
import moment from "moment";
import Select from "react-select";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faQuestionCircle,
  faPen,
} from "@fortawesome/free-solid-svg-icons";

import {
  Button,
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Collapse,
  CardHeader,
  CardTitle,
  CardText,
  Badge,
  Table,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { setWaveSpecs } from "../../redux/actions/waveSpecsActions";
import Selector from "../../components/Selector/Selector_ProjectReview";
import { getLabel } from "../../utils/codeLabels";
import FormFeedback from "reactstrap/lib/FormFeedback";
import Spinner from "../../components/Spinner";
import SelectWrapper from "../../components/SelectWrapper/SelectWrapper";

const ReviewOperationsNew = ({ profile, indxFC, methodology, indxSM,disableforCS }) => {
  const user = useSelector(({ user }) => user.userRecord);
  const project = useSelector(
    ({ currentProject }) => currentProject.newProject
  );
  const costingProfiles = useSelector(
    ({ costings }) => costings.costingProfiles
  );
  // const currentCostingProfile = useSelector(
  //   ({ currentCosting }) => currentCosting.currentCostingProfile
  // );

  const [editableCostingProfile, setEditableCosting] = useState(
    profile
  );
  const userRecord = useSelector(({ user }) => user.userRecord);

  const waveSpecs = useSelector(({ waveSpecs }) => waveSpecs);
  const codeLabels = useSelector(({ codeLabels }) => codeLabels);
  const app = useSelector(({ app }) => app);
  const [waveEditModal, setWaveEditModal] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [editableWaveName, setEditableWavename] = useState();
  const dispatch = useDispatch();
  const [selectedItemWave, setSelectedItemWave] = useState("1");

  const [opsActualsData, setOpsActualsData] = useState({});
  const [waveBreakDown, setWaveBreakDown] = useState([])
  const [currentWaveSpec, setcurrentWaveSpec] = useState([])
  const [modifiedMeths, setModifiedMeths] = useState([])
  const [wavesSpecsData, setwavesSpecsData] = useState(profile.WaveSpecs)
  const [activeTabSM, setActiveTabSM] = useState();
  const [activeTabFC, setActiveTabFC] = useState();

  const [toggle, setToggle] = useState({
    overall: true,
    inst: true,
    other: true,
  });
  useEffect(() => {
    if (profile &&
      wavesSpecsData
      && wavesSpecsData.length
      && !Object.keys(waveBreakDown).length) {

      let data = checkCurrentWaveSpec(profile, selectedItemWave);
      setcurrentWaveSpec(data);

    }
    else {
      let rData = (profile.CountrySpecs[indxFC]);
      let checData = (rData?.MethodologySpecs[indxSM]);
      let _waveBreakdownMain = profile.WaveSpecs.filter(x => x.MethodologySpecId === checData.id);
      setModifiedMeths(_waveBreakdownMain);
    }

  }, [profile])
  useEffect(() => {
    setEditableCosting(profile);
  }, [profile]);

  const checkCurrentWaveSpec = (profileVal, selectedItemWave) => {
    let rData = (profileVal.CountrySpecs[indxFC]);
    let checData = (rData?.MethodologySpecs[indxSM]);
    setActiveTabFC(rData.id);
    setActiveTabSM(checData.id);
    let _waveBreakdownMain = profileVal.WaveSpecs.filter(x => x.MethodologySpecId === checData.id);
    let _waveBreakdown = _.groupBy(_waveBreakdownMain, m => m.WaveNumber);
    setModifiedMeths(_waveBreakdownMain);

    Object.keys(_waveBreakdown).map(w => {
      _waveBreakdown[w] = _.uniqBy(_waveBreakdown[w], t => t.MethodologyLabel);
    });
    setwavesSpecsData(profileVal.WaveSpecs);
    setWaveBreakDown(_waveBreakdown);
    let _currentwaveset = _.head(_waveBreakdown[selectedItemWave]);
    return _currentwaveset;
  }
  const selectorHandler = (item) => {
    setSelectedItemWave(item.WaveNumber);
    let _waveBreakdownMain = profile.WaveSpecs.filter(x => x.MethodologySpecId === activeTabSM)
    let _waveBreakdown = _.groupBy(_waveBreakdownMain, m => m.WaveNumber)
    setModifiedMeths((_waveBreakdownMain))
    Object.keys(_waveBreakdown).map(w => {
      _waveBreakdown[w] = _.uniqBy(_waveBreakdown[w], t => t.MethodologyLabel)
    })
    // do nothing if clicked item is current item
    if (item === (currentWaveSpec)?.MethodologySpecId) return;
    else {
      let data = _.head(_waveBreakdown[item.WaveNumber])
      setcurrentWaveSpec(data);
    }
  };
  const getProperty = (schema, prop) => {
    if (schema.properties[prop]) return schema.properties[prop];
    else {
      let reqVal = "";
      if (schema.dependencies) {
        Object.keys(schema.dependencies).map((dep) => {
          schema.dependencies[dep].oneOf.map((oo) => {
            if (oo.properties[prop] && oo.properties[prop].title) {
              reqVal = oo.properties[prop];
            }
          });
        });
      }
      return reqVal;
    }
  };

  const getOpsValue = (value, prop) => {
    if (value || value == false) {
      if (prop.toLowerCase().indexOf("complexity") != -1) {
        if (prop == "surveyProgrammingComplexity") {
          return _.head(
            codeLabels.QuestionnaireComplexityOptions.filter(
              (frq) => frq.Code == value
            )
          )?.Label;
        }
        if (prop == "dataProcessingComplexity") {
          return _.head(
            codeLabels.DataProcessingComplexityOptions.filter(
              (frq) => frq.Code == value
            )
          )?.Label;
        }
        if (prop == "chartingComplexity") {
          return _.head(
            codeLabels.ChartingComplexityOptions.filter(
              (frq) => frq.Code == value
            )
          )?.Label;
        }
      } else {
        if (Array.isArray(value)) {
          return value.join();
        } else if (typeof value == "boolean") {
          return value ? "Yes" : "No";
        } else return value;
      }
    } else {
      return "-";
    }
  };

  const renderSelector = () => {
    if (!waveSpecs || (waveSpecs && waveSpecs.length === 1)) return null;
    return (
      <Col lg="2" md="2" sm="12" xs="12">
        <Selector
          heading={"Waves"}
          records={modifiedMeths}
          clicked={selectorHandler}
          interPolField={["WaveNumber", "WaveName"]}
          displayField={
            <>
              <FontAwesomeIcon
                title="Edit Wave Name"
                size="xs"
                icon={faPen}
                className={
                  userRecord.IsOpsProjectManager ? "pointer" : "no-actions"
                }
                onClick={() => {
                  if (userRecord.IsOpsProjectManager)
                    setWaveEditModal(!waveEditModal);
                }}
              />
            </>
          }
          selected={currentWaveSpec}
        //   labelGroup={"FieldingCountriesOptions"}
        />
      </Col>
    );
  };
  const scheduleNotesHandler = (eve) => {
    let notesActual = {};
    // let waveSpecsToSave = [...waveSpecs];

    if (currentWaveSpec.NotesActual)
      notesActual = { ...currentWaveSpec.NotesActual };

    if (!notesActual.OpsResourcesNotes) notesActual.OpsResourcesNotes = {};

    notesActual.OpsResourcesNotes[eve.target.id] = eve.target.value;

    onChangeHandler({
      target: {
        id: "NotesActual",
        value: notesActual,
      },
    });
  };
  const onChangeHandler = (eve, useCurrentSpec, isApi) => {
    let currentwavespec = {};
    currentwavespec = checkCurrentWaveSpec(profile, selectedItemWave);
    setcurrentWaveSpec(currentwavespec);
    if (useCurrentSpec) {
      currentwavespec = {
        ...useCurrentSpec,
        [eve.target.id]: eve.target.value,
      };
    } else {
      currentwavespec = {
        ...currentwavespec,
        [eve.target.id]: eve.target.value,
      };
    }
    if (currentwavespec.TotalIntOpsPMActualHours) {
      if (typeof (currentwavespec.TotalIntOpsPMActualHours) === 'string') {
        currentwavespec.TotalIntOpsPMActualHours = parseInt(currentwavespec.TotalIntOpsPMActualHours);
      }
    }
    let wavespecs = [...profile.WaveSpecs];
    wavespecs = wavespecs.map((ws) => {
      if (ws.id == currentWaveSpec.id) {
        ws = currentwavespec;
      }
      return { ...ws };
    });
    dispatch(setWaveSpecs([...wavespecs]));
    dispatch(
      currentWaveSpecActions.updateCurrentWaveSpec({ ...currentwavespec })
    );
    setcurrentWaveSpec(currentwavespec);
    let mappedData = modifiedMeths.map(x => {
      if (x.id === currentwavespec.id) {
        x = { ...currentwavespec }
      }
      return x;
    })
    setModifiedMeths(mappedData);
    setwavesSpecsData(wavespecs)
    let editablecostingprofile = { ...editableCostingProfile };
    editablecostingprofile.WaveSpecs = editablecostingprofile.WaveSpecs.map(
      (ws) => {
        if (ws.id == currentwavespec.id) {
          return { ...currentwavespec };
        } else {
          return { ...ws };
        }
      }
    );
    setEditableCosting(editablecostingprofile);
    const profileTemp = update(editableCostingProfile, {
      WaveSpecs: { $set: wavespecs }
    });
    dispatch(currentCostingActions.updateProfile(profileTemp));
    if (isApi) {
      setWaveEditModal(false);
    }
  };

  const saveActualDates = () => {
    dispatch(
      currentCostingActions.saveProjectReviewSchedule(editableCostingProfile)
    );
  };

  // boolean value conversion
  const getBooleanConversion = (value) => {
    return (value === true) ? 'Yes' : 'No'
  }

  const capitalizeFirstLetterBoolean = (string) => {
    const str = string.toLowerCase();
    if (str == "yes" || str == "no") {
      return str.charAt(0).toUpperCase() + str.slice(1);
    } else {
      return string;
    }
  }

  // for select and multi of OPS
  const onOptionSelectChange = (select, ors) => {
    actualResourceHandler(select, ors);
  }


  // Ops resource handler
  const actualResourceHandler = (eve, ors = null) => {
    let OpsResourcesActualData = {};

    if (currentWaveSpec.OpsResourcesActualData)
      OpsResourcesActualData = { ...currentWaveSpec.OpsResourcesActualData };

    if (!OpsResourcesActualData) OpsResourcesActualData = {};

    if (ors) {
      OpsResourcesActualData[ors] = eve;
    } else {
      OpsResourcesActualData[eve.target.id] = capitalizeFirstLetterBoolean(eve.target.value);
      console.log(OpsResourcesActualData[eve.target.id]);
    }

    onChangeHandler({
      target: {
        id: "OpsResourcesActualData",
        value: OpsResourcesActualData,
      },
    });
  };
  
  return (
    <>
    { methodology?.OpResourcesVariables?.length ? <>
        <Container className="review-schedule">
          <Card
            className="ml-2 mr-2 mb-0 p-0"
            style={{ background: "none", boxShadow: "none" }}
          >
            <Row>
              {renderSelector()}
              <Col>
                <Card className="mb-2">
                  <CardHeader className="border-bottom border-light">
                    <Row>
                      <Col lg="3" md="3" sm="12" xs="12">
                        <strong>Wave Resource</strong>
                      </Col>
                      <Col lg="3" md="3" sm="12" xs="12">
                        <strong>Notes</strong>
                      </Col>
                      <Col lg="3" md="3" sm="12" xs="12">
                        <strong>Planned Resource</strong>
                      </Col>
                      <Col lg="3" md="3" sm="12" xs="12">
                        <strong>Actual Resource</strong>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row className="mb-2 border-bottom border-light">
                      <Col lg="3" md="3" sm="12" xs="12">
                        <h4>Internal Operations Project Management</h4>
                      </Col>
                    </Row>
                    <Row className="mb-2 border-bottom border-light">
                      <Col lg="3" md="3" sm="12" xs="12">
                        Operations Project Management Hours
                      </Col>
                      <Col lg="3" md="3" sm="12" xs="12">
                        <textarea id="TotalIntOpsPMHours"
                          disabled={!userRecord.IsOpsProjectManager||disableforCS}
                          readOnly={!userRecord.IsOpsProjectManager}
                          value={
                            currentWaveSpec?.NotesActual &&
                              currentWaveSpec?.NotesActual?.OpsResourcesNotes &&
                              currentWaveSpec?.NotesActual?.OpsResourcesNotes[
                              "TotalIntOpsPMHours"
                              ]
                              ? currentWaveSpec?.NotesActual?.OpsResourcesNotes[
                              "TotalIntOpsPMHours"
                              ]
                              : ""
                          }
                          onChange={scheduleNotesHandler}
                          className="form-control">
                        </textarea>
                      </Col>
                      <Col lg="3" md="3" sm="12" xs="12">
                        {currentWaveSpec?.TotalIntOpsPMHours}
                      </Col>
                      <Col lg="3" md="3" sm="12" xs="12">
                        <input
                          className="form-control"
                          disabled={!userRecord.IsOpsProjectManager||disableforCS}
                          readOnly={!userRecord.IsOpsProjectManager}
                          id="TotalIntOpsPMActualHours"
                          onChange={onChangeHandler}
                          value={currentWaveSpec["TotalIntOpsPMActualHours"]
                            ? currentWaveSpec["TotalIntOpsPMActualHours"] : ''}
                          type="number"
                          min={0}
                          step="any"
                        />
                      </Col>
                    </Row>
                    {currentWaveSpec?.OpsResourcesSchema?.order.map((ors) => {
                      if (
                        currentWaveSpec?.OpsResourcesData?.hasOwnProperty(ors)
                      ) {
                        let property = getProperty(
                          currentWaveSpec.OpsResourcesSchema,
                          ors
                        );

                        return (
                          <>
                            {property?.isNewSection ? (
                              <Row className="mb-2 border-bottom border-light">
                                <Col lg="3" md="3" sm="12" xs="12">
                                  <h4>{property?.sectionTitle}</h4>
                                </Col>
                              </Row>
                            ) : null}
                            <Row className="mb-2 border-bottom border-light">
                              <Col lg="3" md="3" sm="12" xs="12">
                                {property?.title}
                              </Col>
                              <Col lg="3" md="3" sm="12" xs="12">
                                <textarea id={ors}
                                  disabled={!userRecord.IsOpsProjectManager||disableforCS}
                                  readOnly={!userRecord.IsOpsProjectManager}
                                  value={currentWaveSpec?.NotesActual
                                    && currentWaveSpec?.NotesActual?.OpsResourcesNotes
                                    && currentWaveSpec?.NotesActual?.OpsResourcesNotes[ors]
                                    ? currentWaveSpec?.NotesActual?.OpsResourcesNotes[ors]
                                    : ""}
                                  onChange={scheduleNotesHandler}
                                  className="form-control">
                                </textarea>
                              </Col>
                              <Col lg="3" md="3" sm="12" xs="12">
                                {property?.hasOwnProperty("optionsLabel") &&
                                  !property?.isMulti
                                  ? getLabel(
                                    property?.optionsLabel,
                                    currentWaveSpec?.OpsResourcesData[ors]
                                  )
                                  : typeof (currentWaveSpec?.OpsResourcesData[ors]) === "string"
                                    ? currentWaveSpec?.OpsResourcesData[ors] :
                                    Array.isArray(currentWaveSpec?.OpsResourcesData[ors])
                                      ? currentWaveSpec?.OpsResourcesData[ors].length ?
                                        currentWaveSpec?.OpsResourcesData[ors].map(v => v.label).join(",") : ''
                                      : currentWaveSpec?.OpsResourcesData[ors]
                                }
                                {property?.type === "boolean" && currentWaveSpec?.OpsResourcesData[ors]
                                  ? getBooleanConversion(
                                    currentWaveSpec?.OpsResourcesData[ors]
                                  )
                                  : ''}
                              </Col>
                              <Col lg="3" md="3" sm="12" xs="12">
                                {property?.type == "string" &&
                                  property?.enum &&
                                  property?.enum.length ? (
                                  <SelectWrapper
                                    disabled={!userRecord.IsOpsProjectManager||disableforCS}
                                    readOnly={!userRecord.IsOpsProjectManager}
                                    id={ors}
                                    value={currentWaveSpec?.OpsResourcesActualData
                                      && currentWaveSpec?.OpsResourcesActualData[ors]
                                      ? currentWaveSpec?.OpsResourcesActualData[ors]
                                      : ""}
                                    onChange={actualResourceHandler}
                                  >
                                    <option value="NA">Select Option</option>
                                    {property?.enum?.map((propopt, index) => (
                                      <option key={index} value={propopt}>{propopt}</option>
                                    ))}
                                  </SelectWrapper>
                                ) : property?.type === "array" &&
                                  property?.items?.enum?.length ? (
                                  <Select
                                    className="custom-select-box"
                                    isMulti={property?.isMulti}
                                    options={property?.items?.enum?.map(
                                      (opt) => {
                                        return {
                                          value: opt,
                                          label: opt,
                                        };
                                      }
                                    )}
                                    value={currentWaveSpec?.OpsResourcesActualData
                                      && currentWaveSpec?.OpsResourcesActualData[ors]
                                      ? currentWaveSpec?.OpsResourcesActualData[ors]
                                      : ""}
                                    onChange={(select) =>
                                      onOptionSelectChange(select, ors)
                                    }
                                  />
                                ) : property?.optionsLabel ? (
                                  <Select
                                    className="custom-select-box"
                                    isMulti={property?.isMulti}
                                    options={codeLabels[
                                      property?.optionsLabel
                                    ]?.map((opt) => {
                                      return {
                                        value: opt?.Code,
                                        label: opt?.Label,
                                      };
                                    })}
                                    value={currentWaveSpec?.OpsResourcesActualData
                                      && currentWaveSpec?.OpsResourcesActualData[ors]
                                      ? currentWaveSpec?.OpsResourcesActualData[ors]
                                      : ""}
                                    onChange={(select) =>
                                      onOptionSelectChange(select, ors)
                                    }
                                  />
                                ) : (
                                  <input
                                    className="form-control"
                                    disabled={!userRecord.IsOpsProjectManager||disableforCS}
                                    readOnly={!userRecord.IsOpsProjectManager}
                                    id={ors}
                                    value={currentWaveSpec?.OpsResourcesActualData
                                      && currentWaveSpec?.OpsResourcesActualData[ors]
                                      ? currentWaveSpec?.OpsResourcesActualData[ors]
                                      : ""}
                                    onChange={actualResourceHandler}
                                  />
                                )}
                              </Col>
                            </Row>
                          </>
                        );
                      }
                    })}
                  </CardBody>
                </Card>
                <hr></hr>
                <div className="mb-0">
                  <CardBody>
                    <Row>
                      <Col>
                        <Label>Other schedule related notes</Label>

                        <Input
                          id="OpsNotesOtherActual"
                          disabled={!userRecord?.IsOpsProjectManager ||disableforCS}
                          readOnly={!userRecord?.IsOpsProjectManager}
                          type="textarea"
                          className="mb-2"
                          onChange={scheduleNotesHandler}
                          value={
                            currentWaveSpec?.NotesActual &&
                              currentWaveSpec?.NotesActual?.OpsResourcesNotes &&
                              currentWaveSpec?.NotesActual?.OpsResourcesNotes[
                              "OpsNotesOtherActual"
                              ]
                              ? currentWaveSpec?.NotesActual?.OpsResourcesNotes[
                              "OpsNotesOtherActual"
                              ]
                              : ""
                          }
                          placeholder="Any other notes or topics not covered elsewhere..."
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </div>
              </Col>
            </Row>
          </Card>
        </Container>
      </>:"No Data Found"}
      <Modal
        isOpen={waveEditModal}
        toggle={() => setWaveEditModal(!waveEditModal)}
        centered
      >
        <ModalHeader toggle={() => setWaveEditModal(!waveEditModal)}>
          Edit Current Wave Name
        </ModalHeader>
        <ModalBody>
          <Input
            defaultValue={
              currentWaveSpec.WaveName ? currentWaveSpec.WaveName : ""
            }
            id="WaveName"
            onChange={(e) => setEditableWavename(e.target.value)}
            placeholder="Enter Wave Name"
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            disabled={app.recordloading}
            onClick={(e) => {
              onChangeHandler(
                { target: { id: "WaveName", value: editableWaveName } },
                null,
                true
              );
            }}
          >
            Update
          </Button>
          <Button
            color="secondary"
            disabled={app.recordloading}
            onClick={() => setWaveEditModal(false)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ReviewOperationsNew;
