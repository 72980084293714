import { takeEvery, put } from "redux-saga/effects";
import * as types from "../actions/proposal/ActionTypes";
import axios from '../../axios-interceptor';
import moment from 'moment';
import { SET_PAGELOAD } from "../actions/appActions";
import { VENDOR_SET_PAGELOAD } from "../actions/appActions";
import _ from "lodash"
import { toastr } from "react-redux-toastr";
import * as currentProjectActions from "../actions/currentProjectActions";
import * as  currentCostingActions from "../actions/currentCostingActions";
import { SET_TOTAL_FILES, SET_UPLOADED_FILES, SET_UPLOAD_STATUS, SET_UPLOAD_SUMMARY, SET_FILES } from "../actions/vendor/ActionTypes";
import { useSelector } from "react-redux";


function* fetchProposals(action) {
    try {

        let filter = action.filters || {};
        if (action.tempSearchBy && action.tempSearchVal)
            filter[action.tempSearchBy] = action.tempSearchVal;
        let url = "proposals/list?limit=20" + (action.offsetss ? "&offset=" + action.offsetss : "");
        const res = yield axios.post(url, filter);
        res.data.items.forEach(x => {
            delete x["CostingProfiles"];
        });
        let newList = [];
        res.data.items.forEach(x => {
            newList.push({
                id: x.id, ProjectId: x.ProjectId, ProjectName: x.ProjectName, BusinessUnit: x.BusinessUnit, IndustryVertical: x.IndustryVertical,
                CommissioningCountry: x.CommissioningCountry, CommissioningOffice: x.CommissioningOffice,
                CreationDate: (x.createdAt ? moment(x.createdAt).format('YYYY-MM-DD') : '')
                , ProposalOwner: x.ProposalOwnerEmail["label"]
                , Status: x.Status
            });
        });


        yield put({
            type: types.SEARCH_PROPOSALS_SUCCESS
        });

        if (!action.offsetss)
            yield put({
                type: types.SET_PROPOSALS,
                payload: { items: newList, totalItems: res.data.totalItems }
            });
        else
            yield put({
                type: types.APPEND_PROPOSALS,
                payload: { items: newList, totalItems: res.data.totalItems }
            });
    }
    catch (error) {
        ;
        const errorMsg = error.message;
        yield put({
            type: types.FETCH_PROPOSALS_FAILURE,
            payload: errorMsg
        });
    }
}

function* fetchChats(action) {
    try {
        var url = "chats/list?proposalId=" + action.proposalId;
        if (action.vendorId)
            url += "&vendorId=" + action.vendorId
        const res = yield axios.get("chats/list?vendorId=" + action.vendorId + "&proposalId=" + action.proposalId);

        let newList = [];
        res.data.items.forEach(x => {
            newList.push({
                ...x,
                CreationDate: (x.createdAt ? moment(x.createdAt).format('DD MMM YYYY') : '')
            });
        });
        yield put({
            type: types.FETCH_CHAT_SUCCESS,
            payload: newList
        });
    }
    catch (error) {
        const errorMsg = error.message;
        yield put({
            type: types.FETCH_CHAT_FAILURE,
            payload: errorMsg
        });
    }
}

function* saveVendorCost(action) {

    try {
        yield put({ type: SET_PAGELOAD, pageloaded: false });

        yield put({ type: types.SAVING });

        let proposal = action.payload;


        let url = "bidding/save";
        const res = yield axios.post(url, proposal);
        yield put({ type: types.SAVE_PROPOSAL_SUCCESS, payload: proposal });
        yield put({ type: SET_PAGELOAD, pageloaded: true });

    }
    catch (error) {
        const errorMsg = error.message;
        yield put({
            type: types.SAVE_PROPOSAL_FAILURE,
            payload: errorMsg
        });
    }
}
function* saveVendorBidding(action) {

    try {
        yield put({ type: VENDOR_SET_PAGELOAD, vendorPageloaded: false });
        let proposal = action.payload;
        let url = "vendors/project/bidding/status";
        let urforget = "vendors/project/bidding";
        const res = yield axios.post(url, proposal.data);
        if (res.status === 200) {
            toastr.success("Acknowledgement Saved")
            const project = yield axios.get(urforget, {
                params: {
                    ProjectId: proposal.data.projectId,
                    CostingProfileId: proposal.data.CostingProfileId,
                    VendorId: proposal.data.VendorId
                },
            })
            project.data.data["VendorUpload"] = true
            yield put({ type: currentProjectActions.UPDATE_NEW_PROJECT, newProject: project.data.data });
        }
        else {
            toastr.error("Something went wrong!")
        }
        yield put({ type: VENDOR_SET_PAGELOAD, vendorPageloaded: true });
    }
    catch (error) {
        const errorMsg = error.message;
        yield put({
            type: types.SAVE_PROPOSAL_FAILURE,
            payload: errorMsg
        });
    }
}


function* saveProfile(action) {

    try {
        yield put({ type: SET_PAGELOAD, pageloaded: false });

        yield put({ type: types.SAVING });

        let proposal = action.payload;


        let url = "bidding/saveprofile";
        const res = yield axios.post(url, proposal)
        yield put({ type: types.SAVE_PROFILE_SUCCESS, payload: proposal });
        yield put({ type: SET_PAGELOAD, pageloaded: true });

    }
    catch (error) {
        const errorMsg = error.message;
        yield put({
            type: types.SAVE_PROFILE_FAILURE,
            payload: errorMsg
        });
    }
}
function* saveProposal(action) {

    try {
        yield put({ type: SET_PAGELOAD, pageloaded: false });
        // yield put({ type: types.SAVING });
        let proposal = action.payload.formdata;
        let projectId = action.payload.projectId;
        let url = "bidding/save";
        const res = yield axios.post(url, proposal)
        yield put({ type: SET_PAGELOAD, pageloaded: true })
        const project = yield axios.get("projects/bidding/" + projectId)
        project.data.project["VendorLoaded"] = true
        yield put({ type: currentProjectActions.UPDATE_NEW_PROJECT, newProject: project.data.project });
        let _res = action.payload.files
        if (_res.length && res) { // TO check if we have fils to upload and have recieved response from save vendor
            // if(_res.length )

            yield put({ type: SET_UPLOAD_STATUS, payload: true })
            let totalNumberofFiles = _.reduce(_res.map(r => r.files ? r.files.length : 0), (sum, n) => sum + n, 0)
            yield put({ type: SET_TOTAL_FILES, payload: totalNumberofFiles })

            for (let ven of _res) {
                let formdata = new FormData()
                ven.files?.forEach(a => {

                    formdata.set(a.name, a)
                })
                delete ven.files
                formdata.append("data", JSON.stringify(ven))
                let response = yield axios.post("/vendors/files/upload", formdata)
                if (response) {
                    yield put({ type: SET_UPLOADED_FILES, payload: response.data.data.uploadedFile })
                    yield put({ type: SET_UPLOAD_SUMMARY, payload: { files: response.data.data.uploadedFile, vendorName: response.data.data.vendorName } })
                }
            }

            yield put({ type: SET_UPLOAD_STATUS, payload: false })
            yield put({ type: SET_FILES, files: [] })
            yield put({ type: SET_TOTAL_FILES, payload: 0 })
            yield put({ type: SET_UPLOADED_FILES, payload: 0 })
            yield put({ type: SET_UPLOAD_SUMMARY, payload: null })
        }
        yield put(
            currentCostingActions.getCosting(project.data.project.CostingProfiles[project.data.project.CostingProfiles.length - 1].id)
        );
        yield put({ type: types.SAVE_PROPOSAL_SUCCESS, payload: proposal });
    }
    catch (error) {
        const errorMsg = error.message;
        yield put({
            type: types.SAVE_PROPOSAL_FAILURE,
            payload: errorMsg
        });
    }
}
function* saveProposalData(action) {

    try {
        let proposal = action.payload.data;
        proposal.type == "Vendor" ?
            yield put({ type: VENDOR_SET_PAGELOAD, vendorPageloaded: false }) :
            yield put({ type: SET_PAGELOAD, pageloaded: proposal.closeToastr })
        let params;
        proposal.type == "Vendor" ? params = {
            ProjectId: proposal.Project.ProjectId,
            CostingProfileId: proposal.id,
            VendorId: proposal.VendorId
        } : params = {
            ProjectId: proposal.Project.ProjectId,
            CostingProfileId: proposal.id,
        }
        let url = "vendors/project/bidding"
        let vendorUrl = "vendors/project/bidding?VendorId=" + proposal?.VendorId

        const res = yield axios.post( proposal.type == "Vendor"?vendorUrl:url, proposal)
        if (res.status === 200) {

            let _res = action?.payload?.files ? action?.payload?.files : []
            if(!proposal.closeToastr)
           {
            proposal.Error?toastr.error(proposal.Message):toastr.success(proposal.Message)
        }
       if(proposal.Error){toastr.error(proposal.Message)}
            if (_res.length && res) {
                proposal.type == "Vendor" ?
                    yield put({ type: VENDOR_SET_PAGELOAD, vendorPageloaded: true }) :
                    yield put({ type: SET_PAGELOAD, pageloaded: true })
                yield put({ type: SET_UPLOAD_STATUS, payload: true })
                let totalNumberofFiles = _.reduce(_res.map(r => r.files ? r.files.length : 0), (sum, n) => sum + n, 0)
                yield put({ type: SET_TOTAL_FILES, payload: totalNumberofFiles })
                for (let ven of _res) {
                    let formdata = new FormData()
                    ven.files?.forEach(a => {

                        formdata.set(a.name, a)
                    })
                    delete ven.files
                    formdata.append("data", JSON.stringify(ven))
                    let response = yield axios.post("/vendors/files/upload", formdata)
                    if (response) {
                        yield put({ type: SET_UPLOADED_FILES, payload: response.data.data.uploadedFile })
                        yield put({ type: SET_UPLOAD_SUMMARY, payload: { files: response.data.data.uploadedFile, vendorName: response.data.data.vendorName } })
                    }
                }
                yield put({ type: SET_UPLOAD_STATUS, payload: false })
                yield put({ type: SET_FILES, files: [] })
                yield put({ type: SET_TOTAL_FILES, payload: 0 })
                yield put({ type: SET_UPLOADED_FILES, payload: 0 })
                yield put({ type: SET_UPLOAD_SUMMARY, payload: null })
            }
            proposal.type == "Vendor" ?
                yield put({ type: VENDOR_SET_PAGELOAD, vendorPageloaded: false }) :
                yield put({ type: SET_PAGELOAD, pageloaded: proposal.closeToastr  })

            const project = yield axios.get(url, {
                params: params
            })
            project.data.data["VendorUpload"] = true
            project.data.data["ProfileChange"] = true
            yield put({ type: currentProjectActions.UPDATE_NEW_PROJECT, newProject: project.data.data });
            proposal.type == "Vendor" ?
                yield put({ type: VENDOR_SET_PAGELOAD, vendorPageloaded: true }) :
                yield put({ type: SET_PAGELOAD, pageloaded: true })
        }
        else {
            toastr.error("Something went wrong!")
            proposal.type == "Vendor" ?
                yield put({ type: VENDOR_SET_PAGELOAD, vendorPageloaded: true }) :
                yield put({ type: SET_PAGELOAD, pageloaded: true })
        }


    }
    catch (error) {
        const errorMsg = error.message;
        yield put({
            type: types.SAVE_PROPOSAL_FAILURE,
            payload: errorMsg
        });
    }
}


function* selectedVendor(action) {

    try {
        if (action.payload.pageload == true) {
            yield put({ type: SET_PAGELOAD, pageloaded: false });
        }
        yield put({ type: types.SAVING });

        let proposal = action.payload.Proposal;


        let url = "bidding/selectedVendor";
        const res = yield axios.post(url, proposal)
        yield put({ type: types.SAVE_PROPOSAL_SUCCESS, payload: proposal });
        yield put({ type: SET_PAGELOAD, pageloaded: true });

    }
    catch (error) {
        yield put({ type: SET_PAGELOAD, pageloaded: true });
        toastr.error("Save Failed")
        const errorMsg = error.message;
        yield put({
            type: types.SAVE_PROPOSAL_FAILURE,
            payload: errorMsg
        });
    }
}


function* saveChat(action) {
    try {
        let chatData = action.payload;
        let url = "chats/save";
        const res = yield axios.post(url, chatData);
        yield put({ type: types.SAVE_CHAT_SUCCESS, payload: chatData });
    }
    catch (error) {
        const errorMsg = error.message;
        yield put({
            type: types.SAVE_CHAT_FAILURE,
            payload: errorMsg
        });
    }
}

function* fetchProposalDetails(action) {
    try {
        const qcValModeList = yield axios.get("qcvalidationmodes/list");
        const vendors = yield axios.post("vendors/list");

        let url = "proposals/details?Id=" + action.payload.id;
        if (action.payload.vid)
            url += "&VendorId=" + parseInt(action.payload.vid);
        if (action.payload.pid)
            url += "&ProfileId=" + parseInt(action.payload.pid);
        const res = yield axios.get(url);
        let proposal = res.data.Proposal;

        ;
        delete proposal["ContractDetails"];

        proposal.CostingProfiles.forEach(cp => {
            delete cp["ProfileSetting"];
            //delete cp["WaveSpecs"];
            cp.CountrySpecs.forEach(cs => {
                cs.CountryId = cs.CountryCode;
                cs.MethodologySpecs.forEach(m => {
                    delete m["RFQSchema"];
                    delete m["CostsSchema"];
                    delete m["CostsData"];
                    delete m["CalculationSchema"];
                    delete m["CountryCostBreakdown"];
                });
            });
        });
        let sumSample = 0;
        proposal.CostingProfiles.forEach((cp, cpIndex) => {
            cp.CountrySpecs.forEach((cs, csIndex) => {
                cs.CountryId = cs.CountryCode;
                cs.MethodologySpecs.forEach(m => {
                    if (m.BiddingData) {
                        m.QCRequirments = m.BiddingData.QCRequirments || '';
                        if (m.BiddingData.QCValidationModeId) {
                            let label = qcValModeList.data.items.filter(z => z.id == m.BiddingData.QCValidationModeId)[0]['Label'];
                            m['QCValidationMode'] = { value: m.BiddingData.QCValidationModeId, label };
                        }
                        m.Deadline = m.BiddingData.Deadline ? moment(m.BiddingData.Deadline).format('YYYY-MM-DD') : '';
                        m.OtherProjectSpecification = m.BiddingData.OtherProjectSpecification || '';
                        m.Vendors = [];
                        m.BiddingData.Vendors.forEach(z => {
                            m.Vendors.push({ label: z["VendorName"], value: z["id"] });
                        });
                        if (m.BiddingData) {
                            m["Edit"] = true;
                            m.BiddingData.Vendors.forEach((z, i) => {
                                m.Vendors[i].id = z.BiddingVendors.id || '';
                                m.Vendors[i].VendorBiddingId = z.VendorBidding ? z.VendorBidding.id : '';

                                if (m.RFQData.sampleSizeMain) {
                                    m.Vendors[i].TargetMain = z.VendorBidding ? z.VendorBidding.TargetMain : '';
                                    m.Vendors[i].ActualMain = z.VendorBidding ? z.VendorBidding.ActualMain : '';
                                    m.Vendors[i].CPIMain = z.VendorBidding ? z.VendorBidding.CPIMain : '';
                                    m.Vendors[i].CPIMainFinal = z.VendorBidding ? z.VendorBidding.CPIMainFinal : '';
                                    m.Vendors[i].FieldWorkDaysCPIMain = z.VendorBidding ? z.VendorBidding.FieldWorkDaysCPIMain : '';
                                    m.Vendors[i].TotalCPIMain = z.VendorBidding ? z.VendorBidding.TotalCPIMain : '';
                                    m.Vendors[i].SelectedCPIMain = z.VendorBidding ? z.VendorBidding.SelectedCPIMain : '';
                                    m.Vendors[i].IsResentMain = z.VendorBidding ? z.VendorBidding.IsResentMain : '';
                                }

                                if (m.RFQData.sampleSizeBooster) {
                                    m.Vendors[i].TargetBooster = z.VendorBidding ? z.VendorBidding.TargetBooster : '';
                                    m.Vendors[i].ActualBooster = z.VendorBidding ? z.VendorBidding.ActualBooster : '';
                                    m.Vendors[i].CPIBooster = z.VendorBidding ? z.VendorBidding.CPIBooster : '';
                                    m.Vendors[i].CPIBoosterFinal = z.VendorBidding ? z.VendorBidding.CPIBoosterFinal : '';
                                    m.Vendors[i].FieldWorkDaysCPIBooster = z.VendorBidding ? z.VendorBidding.FieldWorkDaysCPIBooster : '';
                                    m.Vendors[i].TotalCPIBooster = z.VendorBidding ? z.VendorBidding.TotalCPIBooster : '';
                                    m.Vendors[i].SelectedCPIBooster = z.VendorBidding ? z.VendorBidding.SelectedCPIBooster : '';
                                    m.Vendors[i].IsResentBooster = z.VendorBidding ? z.VendorBidding.IsResentBooster : '';
                                }

                                if (m.RFQData.sampleSizeOther) {
                                    m.Vendors[i].TargetOther = z.VendorBidding ? z.VendorBidding.TargetOther : '';
                                    m.Vendors[i].ActualOther = z.VendorBidding ? z.VendorBidding.ActualOther : '';
                                    m.Vendors[i].CPIOther = z.VendorBidding ? z.VendorBidding.CPIOther : '';
                                    m.Vendors[i].CPIOtherFinal = z.VendorBidding ? z.VendorBidding.CPIOtherFinal : '';
                                    m.Vendors[i].FieldWorkDaysCPIOther = z.VendorBidding ? z.VendorBidding.FieldWorkDaysCPIOther : '';
                                    m.Vendors[i].TotalCPIOther = z.VendorBidding ? z.VendorBidding.TotalCPIOther : '';
                                    m.Vendors[i].SelectedCPIOther = z.VendorBidding ? z.VendorBidding.SelectedCPIOther : '';
                                    m.Vendors[i].IsResentOther = z.VendorBidding ? z.VendorBidding.IsResentOther : '';
                                }

                                m.Vendors[i].OtherAllowance = z.VendorBidding ? z.VendorBidding.OtherAllowance : '';

                                m.Vendors[i].TotalGrand = z.VendorBidding ? z.VendorBidding.TotalGrand : '';

                                m.Vendors[i].Comments = z.VendorBidding ? z.VendorBidding.Comments : '';
                            });
                            m.SelfBidding = m.BiddingData.SelfBidding;
                        }

                    }
                    if (cpIndex == 0 && csIndex == 0 && (!m.Vendors || m.Vendors.length == 0)) {
                        m.Vendors = [];
                        vendors.data.items.forEach((x, index) => {
                            if (index <= 1)
                                m.Vendors.push({ label: x.VendorName, value: x.id.toString() });
                        });

                    }
                });
            })
        });
        proposal.SampleSize = sumSample;
        let bData = proposal?.CostingProfiles[proposal?.CostingProfiles?.length - 1]?.CountrySpecs[0]?.MethodologySpecs[0]["BiddingData"];
        if (bData && bData.Vendors.length > 0 && bData.Vendors[0]["BiddingVendors"])
            proposal.Acknowledged = bData.Vendors[0]["BiddingVendors"]["Acknowledged"];

        yield put({ type: types.FETCH_PROPOSAL_DETAILS_SUCCESS, payload: { proposal: proposal } });

    }
    catch (error) {
        ;
        const errorMsg = error.message;
        yield put({
            type: types.FETCH_PROPOSAL_DETAILS_FAILURE,
            payload: errorMsg
        });
    }
}

function* fetchProposalVendorDetails(action) {
    try {
        const qcValModeList = yield axios.get("qcvalidationmodes/list");
        let url = "proposals/detailscountrywise?Id=" + action.payload.id;

        if (action.payload.vid)
            url += "&VendorId=" + parseInt(action.payload.vid);

        const res = yield axios.get(url);
        let proposal = res.data.Proposal;

        delete proposal["ContractDetails"];
        proposal.CostingProfiles.forEach(cp => {
            delete cp["ProfileSetting"];
            delete cp["WaveSpecs"];
            cp.CountrySpecs.forEach(cs => {
                cs.CountryId = cs.CountryCode;
                cs.MethodologySpecs.forEach(m => {
                    delete m["RFQSchema"];
                    delete m["CostsSchema"];
                    delete m["CostsData"];
                    delete m["CalculationSchema"];
                    delete m["CountryCostBreakdown"];
                });
            });
        });

        let sumSample = 0;
        proposal.CostingProfiles.forEach(cp => {
            cp.CountrySpecs.forEach(cs => {
                cs.CountryId = cs.CountryCode;
                cs.MethodologySpecs.forEach(m => {
                    if (m.BiddingData) {
                        m.QCRequirments = m.BiddingData.QCRequirments || '';
                        if (m.BiddingData.QCValidationModeId) {
                            let label = qcValModeList.data.items.filter(z => z.id == m.BiddingData.QCValidationModeId)[0]['Label'];
                            m['QCValidationMode'] = { value: m.BiddingData.QCValidationModeId, label };
                        }
                        m.Deadline = m.BiddingData.Deadline ? moment(m.BiddingData.Deadline).format('YYYY-MM-DD') : '';
                        m.OtherProjectSpecification = m.BiddingData.OtherProjectSpecification || '';


                        m.Vendors = [];
                        m.BiddingData.Vendors.forEach(z => {
                            m.Vendors.push({ label: z["VendorName"], value: z["id"] });
                        });

                        let tempCount = m.BiddingData.Vendors.filter(k => k.VendorBidding).length;
                        m["BidCounts"] = tempCount;

                        if (m.BiddingData) {
                            m["Edit"] = true;
                            m.BiddingData.Vendors.forEach((z, i) => {
                                m.Vendors[i].id = z.BiddingVendors.id || '';

                                m.Vendors[i].VendorBiddingId = z.VendorBidding ? z.VendorBidding.id : '';
                                m.Vendors[i].CPIMain = z.VendorBidding ? z.VendorBidding.CPIMain : '';
                                m.Vendors[i].FieldWorkDaysCPIMain = z.VendorBidding ? z.VendorBidding.FieldWorkDaysCPIMain : '';
                                m.Vendors[i].TotalCPIMain = z.VendorBidding ? z.VendorBidding.TotalCPIMain : '';
                                m.Vendors[i].SelectedCPIMain = z.VendorBidding ? z.VendorBidding.SelectedCPIMain : '';

                                m.Vendors[i].CPIBooster = z.VendorBidding ? z.VendorBidding.CPIBooster : '';
                                m.Vendors[i].FieldWorkDaysCPIBooster = z.VendorBidding ? z.VendorBidding.FieldWorkDaysCPIBooster : '';
                                m.Vendors[i].TotalCPIBooster = z.VendorBidding ? z.VendorBidding.TotalCPIBooster : '';
                                m.Vendors[i].SelectedCPIBooster = z.VendorBidding ? z.VendorBidding.SelectedCPIBooster : '';

                                m.Vendors[i].CPIOther = z.VendorBidding ? z.VendorBidding.CPIOther : '';
                                m.Vendors[i].FieldWorkDaysCPIOther = z.VendorBidding ? z.VendorBidding.FieldWorkDaysCPIOther : '';
                                m.Vendors[i].TotalCPIOther = z.VendorBidding ? z.VendorBidding.TotalCPIOther : '';
                                m.Vendors[i].SelectedCPIOther = z.VendorBidding ? z.VendorBidding.SelectedCPIOther : '';

                                m.Vendors[i].OtherAllowance = z.VendorBidding ? z.VendorBidding.OtherAllowance : '';
                                m.Vendors[i].TotalGrand = z.VendorBidding ? z.VendorBidding.TotalGrand : '';

                                m.Vendors[i].Comments = z.VendorBidding ? z.VendorBidding.Comments : '';
                            });
                            m.SelfBidding = m.BiddingData.SelfBidding;
                        }

                    }
                });
            })
        });
        proposal.SampleSize = sumSample;

        let bData = proposal?.CostingProfiles[proposal?.CostingProfiles.length - 1]?.CountrySpecs[0]?.MethodologySpecs[0]["BiddingData"];

        if (bData && bData.Vendors.length > 0 && bData.Vendors[0]["BiddingVendors"])
            proposal.Acknowledged = bData.Vendors[0]["BiddingVendors"]["Acknowledged"];

        yield put({ type: types.FETCH_PROPOSAL_VENDOR_DETAILS_SUCCESS, payload: { proposal: proposal, editMode: true } });
        yield put({ type: types.API_PROPOSAL_REDIRECT_TO_EDIT, link: '/pages/proposal-vendor-bidding' });
    }
    catch (error) {
        const errorMsg = error.message;
        yield put({
            type: types.FETCH_PROPOSAL_DETAILS_FAILURE,
            payload: errorMsg
        });
    }
}

function* fetchProposalbiddingummary(action) {
    try {
        let url = "proposals/detailscountrywise?Id=" + action.payload.id;
        const res = yield axios.get(url);
        let proposal = res.data.Proposal;
        proposal["Data"] = [];
        proposal["ListCountries"] = [];
        let profileIndex = 0;
        for (var x of proposal.Countries) {
            let objCountry = { Name: x.Name, Code: x.Code, Methodologies: [] };
            let index = 0;
            x["Data"].forEach((y, rowIndex) => {
                let pName = y["Profile"]["ProfileName"];
                let mName = y["Methodology"]["Label"];
                let mCode = y["Methodology"]["Code"];
                let RFQData = y["Methodology"]["RFQData"];
                objCountry.Methodologies.push({ Name: mName, Code: mCode });
                y.Vendors = y.Vendors.filter(xx => xx.VendorBidding).sort(function (a, b) {
                    return a.VendorBidding.TotalGrand - b.VendorBidding.TotalGrand;
                });
                let count = y.Vendors.length;
                for (var tempCount = 0; tempCount < count; tempCount++) {
                    if (tempCount == 0)
                        y.Vendors[tempCount]["Color"] = "limegreen";
                    else if (tempCount == count - 1)
                        y.Vendors[tempCount]["Color"] = "#AD4400";
                    else
                        y.Vendors[tempCount]["Color"] = "#FFBF00";//amber
                }
                y.Vendors.forEach((z, vendorIndex) => {
                    let VendorBidding = z.VendorBidding;
                    if (VendorBidding.TotalGrand && VendorBidding.TotalGrand) {
                        let totalCostOther = VendorBidding.TotalGrand - (VendorBidding.TotalCPIMain + VendorBidding.TotalCPIBooster + VendorBidding.TotalCPIOther);
                        let totalSampleSize = (VendorBidding.ActualMain || 0) + (VendorBidding.ActualBooster || 0) + (VendorBidding.ActualOther || 0);
                        ;
                        let totalRFQSampleSize = (RFQData["sampleSizeMain"] || 0) + (RFQData["sampleSizeBooster"] || 0) + (RFQData["sampleSizeOther"] || 0);

                        let costPerSampleOther = totalCostOther / totalSampleSize;
                        let obj = {
                            RowIndex: profileIndex,
                            Color: z.Color, Index: index, ProfileName: pName, MethodologyName: mName
                            , MethodologyCode: mCode, LOI: RFQData.lengthOfInterview
                            , VendorName: z.VendorName, id: VendorBidding.id, VendorId: z.id
                            , Country: x.Name
                            , RFQSampleSize: totalRFQSampleSize
                        };

                        if (VendorBidding.TotalCPIMain && VendorBidding.CPIMain && RFQData.sampleSizeMain) {
                            let totalMain = VendorBidding.ActualMain ? parseInt(VendorBidding.TotalCPIMain + (VendorBidding.ActualMain * costPerSampleOther)) : null;
                            let tempObj = JSON.parse(JSON.stringify(obj));
                            fillData("Main", tempObj, VendorBidding, RFQData);
                            tempObj["Total"] = totalMain;
                            proposal["Data"].push(tempObj);

                        }

                        if (VendorBidding.TotalCPIBooster && VendorBidding.CPIBooster && RFQData.sampleSizeBooster) {
                            let totalBooster = VendorBidding.ActualBooster ? parseInt(VendorBidding.TotalCPIBooster + (VendorBidding.ActualBooster * costPerSampleOther)) : null;
                            let tempObj = JSON.parse(JSON.stringify(obj));
                            fillData("Booster", tempObj, VendorBidding, RFQData);
                            tempObj["Total"] = totalBooster;
                            proposal["Data"].push(tempObj);
                        }

                        if (VendorBidding.TotalCPIOther && VendorBidding.CPIOther && RFQData.sampleSizeOther) {
                            let totalOther = VendorBidding.ActualOther ? parseInt(VendorBidding.TotalCPIOther + (VendorBidding.ActualOther * costPerSampleOther)) : null;
                            let tempObj = JSON.parse(JSON.stringify(obj));
                            fillData("Other", tempObj, VendorBidding, RFQData);
                            tempObj["Total"] = totalOther;
                            proposal["Data"].push(tempObj);
                        }
                    }
                });
            });
            proposal["ListCountries"].push(objCountry);
            profileIndex += 1;
        }
        proposal["Data"].forEach((xx, index_xx) => {
            xx["RecordIndex"] = index_xx;
        });

        for (var country of proposal["ListCountries"]) {
            for (var methodology of country.Methodologies) {
                methodology["OtherCosts"] = [];
                methodology["SubTotals"] = {};
                let tempHeader = "";
                url = "proposalothercosts/list?ProposalId=" + proposal.ProjectId + "&CountryCode=" + country.Code;
                let res = yield axios.get(url);
                if (res.data.OnlyFields) {
                    res.data.items.forEach((item, index) => {
                        if (item["ProposalOtherCostField"].Header) {
                            tempHeader = item["ProposalOtherCostField"].Header;
                        }
                        methodology["OtherCosts"].push({
                            dataField: item["ProposalOtherCostField"].DataField
                            , text: item["ProposalOtherCostField"].DataLabel
                            , header: item["ProposalOtherCostField"].Header
                            , parent: tempHeader
                            , id: item["ProposalOtherCostField"].id
                        });
                    });
                }
                else {
                    ;
                    let items = res.data.items.filter(item => item.CountryCode == country.Code && item.MethodologyCode == methodology.Code);
                    items = items.reverse();
                    let methodologyTotal = 0;
                    let tempTotalSub = 0;
                    items.forEach((item, index) => {

                        if (item["ProposalOtherCostField"].Header || index == items.length - 1) {
                            if (tempHeader) {
                                methodology["SubTotals"][tempHeader] = tempTotalSub;
                                tempHeader = item["ProposalOtherCostField"].Header;
                                tempTotalSub = 0;
                            }
                            else {
                                tempHeader = item["ProposalOtherCostField"].Header;
                            }
                        }
                        if (item.DataValue) {
                            methodologyTotal += item.DataValue;
                            tempTotalSub += item.DataValue;
                        }
                        methodology["OtherCosts"].push({
                            dataField: item["ProposalOtherCostField"].DataField
                            , text: item["ProposalOtherCostField"].DataLabel
                            , header: item["ProposalOtherCostField"].Header
                            , parent: tempHeader
                            , id: item["ProposalOtherCostField"].id
                            , rid: item.id
                            , [item["ProposalOtherCostField"].DataField]: item.DataValue
                        });
                    });
                    methodology["TotalCost"] = methodologyTotal;
                }
            }
        }

        yield put({ type: types.FETCH_VENDOR_BIDDING_SUMMARY_SUCCESS, payload: { proposal: proposal } });
    }
    catch (error) {
        ;
        const errorMsg = error.message;
        yield put({
            type: types.FETCH_PROPOSAL_DETAILS_FAILURE,
            payload: errorMsg
        });
    }
    function fillData(name, tempObj, VendorBidding, RFQData) {

        tempObj["SampleType"] = name;
        tempObj["SampleSize"] = RFQData["sampleSize" + name];
        tempObj["TargetSampleSize"] = VendorBidding["Target" + name];
        tempObj["FinalSampleSize"] = VendorBidding["Actual" + name];
        tempObj["TotalCPI"] = VendorBidding["TotalCPI" + name];
        tempObj["TotalGrand"] = VendorBidding.TotalGrand;
        tempObj["CPI"] = VendorBidding["CPI" + name];
        tempObj["CPIFinal"] = VendorBidding["CPI" + name + "Final"];
        tempObj["FWD"] = VendorBidding["FieldWorkDaysCPI" + name];
        tempObj["Selected"] = VendorBidding["ApprovedCPI" + name] || VendorBidding["IsResent" + name];
        tempObj["TotalFinal"] = VendorBidding["TotalCPI" + name + "Final"];
        return tempObj;
    }
}

function* fetchProposalWorkOrderDetails(action) {
    try {
        let url = "proposals/detailscountrywise?Id=" + action.payload.id + "&Approved=true";
        const res = yield axios.get(url);
        let proposal = res.data.Proposal;
        proposal.Countries.forEach(country => {
            country.Data.forEach(d => {
                let methodology = d.Methodology;
                let arrQuota = ["age", "gender", "brandCategory", "socialClass", "race", "regionCity"];
                let requiredQuota = null;
                arrQuota.forEach(x => {
                    if (methodology["RFQData"][x])
                        requiredQuota += x + ":" + methodology["RFQData"][x] + ",";
                });
                methodology.requiredQuota = requiredQuota;
                d.MData = [];
                d.Vendors.forEach((mVendor, index) => {
                    let tData = {};
                    tData.Name = mVendor.VendorName;
                    tData.id = index;
                    tData.SampleSizeMain = mVendor.VendorBidding.ApprovedCPIMain ? methodology.RFQData.sampleSizeMain : "NA";
                    tData.SampleSizeBooster = mVendor.VendorBidding.ApprovedCPIBooster ? methodology.RFQData.sampleSizeBooster : "NA";
                    tData.SampleSizeOther = mVendor.VendorBidding.ApprovedCPIOther ? methodology.RFQData.sampleSizeOther : "NA";
                    tData.FieldWorkDaysMain = mVendor.VendorBidding.ApprovedCPIMain ? mVendor.VendorBidding.FieldWorkDaysCPIMain : "NA";
                    tData.FieldWorkDaysBooster = mVendor.VendorBidding.ApprovedCPIBooster ? mVendor.VendorBidding.FieldWorkDaysCPIBooster : "NA";
                    tData.FieldWorkDaysOther = mVendor.VendorBidding.ApprovedCPIOther ? mVendor.VendorBidding.FieldWorkDaysCPIOther : "NA";
                    d.MData.push(tData);
                });
            });
        });
        yield put({ type: types.FETCH_PROPOSAL_DETAILS_SUCCESS, payload: { proposal: proposal } });

    }
    catch (error) {
        const errorMsg = error.message;
        yield put({
            type: types.FETCH_PROPOSAL_DETAILS_FAILURE,
            payload: errorMsg
        });
    }
}

function* acknowledgeVendorBidding(action) {
    try {

        let data = {
            "Acknowledged": true,
            "ProposalId": action.payload.ProjectId,
            "VendorId": action.payload.VendorId,
            "Ids": action.payload.Ids
        };

        let url = "biddingvendors/update";
        yield axios.post(url, data);
        yield put({ type: types.ACKNOWLEDGE_VENDOR_BIDDING_SUCCESS });
    }
    catch (error) {
        const errorMsg = error.message;
        yield put({
            type: types.FETCH_PROPOSALS_FAILURE,
            payload: errorMsg
        });
    }
}

function* saveApprovedBiddingVendors(action) {
    ;
    try {
        if (!action.payload.resend)
            yield put({ type: types.SAVING });
        else
            yield put({ type: types.RESENDING });
        let data = action.payload.data;
        let url = "vendorbidding/update";
        const res = yield axios.post(url, data);
        url = "proposals/updatecostingprofiles?Id=" + action.payload.projectId;
        const res2 = yield axios.get(url, data);
        yield put({ type: types.SAVE_APPROVED_BIDDING_VENDORS_SUCCESS, saveOnly: action.payload.saveOnly });
    }
    catch (error) {
        const errorMsg = error.message;
        yield put({
            type: types.SAVE_APPROVED_BIDDING_VENDORS_FAILURE,
            payload: errorMsg
        });
    }
}

function* sendEarlyWarningNote(action) {

    try {
        yield put({ type: types.SEND_EARLY_WARNING_NOTE_REQUEST });
        let url = "proposals/SendEarlyWarningNote?ProposalId=" + action.payload.id;
        yield axios.get(url);
        yield put({ type: types.SEND_EARLY_WARNING_NOTE_SUCCESS });
    }
    catch (error) {
        const errorMsg = error.message;
        yield put({
            type: types.SEND_EARLY_WARNING_NOTE_FAILURE,
            payload: errorMsg
        });
    }
}

function* saveAdditionalCost(action) {
    try {
        let proposal = action.proposal;
        let data = [];
        for (var country of proposal.ListCountries) {
            country.Methodologies.forEach(methodology => {
                methodology.OtherCosts.forEach(otherCost => {
                    data.push({
                        "ProposalId": proposal.ProjectId,
                        "CountryCode": country.Code,
                        "MethodologyCode": methodology.Code,
                        "ProposalOtherCostFieldId": otherCost.id,
                        "DataValue": otherCost[otherCost.dataField]
                    });
                });

            });
        }
        let url = "proposalothercosts/save";
        yield axios.post(url, data);
        yield put({ type: types.UPDATE_ADDITIONAL_COST_TO_DATA, indexC: action.indexC });
    }
    catch (error) {
        yield put({ type: types.UPDATE_ADDITIONAL_COST_TO_DATA, indexC: action.indexC });
        //const errorMsg = error.message;
        //yield put({
        //    type: types.SEND_EARLY_WARNING_NOTE_FAILURE,
        //    payload: errorMsg
        //});
    }
}

export function* waitForFetchChats() {
    yield takeEvery(types.FETCH_CHAT_REQUEST, fetchChats);
}

export function* waitForLoadProposals() {
    yield takeEvery(types.FETCH_PROPOSALS_REQUEST, fetchProposals);
}

export function* waitForProposalDetails() {

    yield takeEvery(types.FETCH_PROPOSAL_DETAILS_REQUEST, fetchProposalDetails);
}

export function* waitForProposalVendorDetails() {
    yield takeEvery(types.FETCH_PROPOSAL_VENDOR_DETAILS_REQUEST, fetchProposalVendorDetails);
}

export function* waitForVendorbiddingummary() {
    yield takeEvery(types.FETCH_VENDOR_BIDDING_SUMMARY_REQUEST, fetchProposalbiddingummary);
}

export function* waitForProposalWODetails() {
    yield takeEvery(types.FETCH_PROPOSAL_WO_DETAILS_REQUEST, fetchProposalWorkOrderDetails);
}

export function* waitForSaveProposal() {
    yield takeEvery(types.SAVE_PROPOSAL_REQUEST, saveProposal);
}
export function* waitForSaveProposalData() {
    yield takeEvery(types.SAVE_PROPOSAL_REQUEST_DATA, saveProposalData);
}


export function* waitForSaveSelectedVendor() {
    yield takeEvery(types.SAVE_PROPOSAL_SELECTEDVENDOR, selectedVendor);
}
export function* waitForSaveProfile() {
    yield takeEvery(types.SAVE_PROFILE_REQUEST, saveProfile);
}
export function* waitForVendorCost() {
    yield takeEvery(types.SAVE_VENDOR_REQUEST, saveVendorCost);
}

export function* waitForSaveChat() {
    yield takeEvery(types.SAVE_CHAT_REQUEST, saveChat);
}
export function* waitForSaveVendorBidding() {
    yield takeEvery(types.SAVE_VENDORBIDDING_REQUEST, saveVendorBidding);
}

export function* waitForAcknowledgeVendorBidding() {
    yield takeEvery(types.ACKNOWLEDGE_VENDOR_BIDDING, acknowledgeVendorBidding);
}

export function* waitForSaveApprovedBiddingVendors() {
    yield takeEvery(types.SAVE_APPROVED_BIDDING_VENDORS_REQUEST, saveApprovedBiddingVendors);
}

export function* waitForSendEarlyWarningNote() {
    yield takeEvery(types.SEND_EARLY_WARNING_NOTE, sendEarlyWarningNote);
}


export function* waitForSaveAdditionalCost() {
    yield takeEvery(types.SAVE_ADDITIONAL_COST, saveAdditionalCost);
}