import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Input, Col, Container, Row, Table, Label, Tooltip, TabContent, TabPane, Nav, NavItem, NavLink, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Select from "react-select";
import _ from "lodash";
import { toastr } from "react-redux-toastr";
import classnames from "classnames";
import { useHistory, useParams } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import axios from "../../axios-interceptor";
import { getCosting, saveCostingProfile, updateProfile } from "../../redux/actions/currentCostingActions";
import { addCostingOptionsLink, addSidebarLinks } from "../../redux/actions/sidebarActions";
import Layout from "../../layouts/Project";
import { getLabel } from "../../utils/codeLabels";
import SelectWrapper from "../../components/SelectWrapper/SelectWrapper";

const CommercialHours = (props) => {
  const dispatch = useDispatch();
  let { profileId } = useParams();
  const recordloading = useSelector(
    ({ app }) => app.recordloading
  );
  const history = useHistory();
  const codeLabels = useSelector(({ codeLabels }) => codeLabels);
  const [commercialTooltips, setCommercialTooltips] = useState({});
  const [schema, setSchema] = useState({});
  const [subMethodologies, setSubMethodologies] = useState([]);
  const [finalObjectRef, setFinalObjectRef] = useState({});
  const [clientServiceCards, setClientServiceCards] = useState();
  const [defaultprofileproject, setdefaultprofileproject] = useState({});

  const [clientRatesCalled, setClientRatesCalled] = useState(false);
  const [currentCurrency, setCurrentCurrency] = useState();
  const localPageload = useSelector(({ app }) => app.localPageload);

  const currentProject = useSelector(
    ({ currentProject }) => currentProject.newProject
  );

  const [activeTabFC, setActiveTabFC] = useState(0);
  const [activeTabSM, setActiveTabSM] = useState(0);

  const [currentCountrySpec, setcurrentCountrySpec] = useState({})
  const [currentSubmeth, setCurrentSubMeth] = useState({})
  const [currentCostingOption, setCurrentCOstingOption] = useState({})
  const [currentCostingProfileTemp, setCurrentCostingProfileTemp] = useState({})

  const currencies = useSelector(({ currentCosting }) =>
    currentCosting.currentCostingProfile.ProfileSetting &&
      currentCosting.currentCostingProfile.ProfileSetting.CurrenciesData
      ? currentCosting.currentCostingProfile.ProfileSetting.CurrenciesData
      : []
  );

  const currentCostingProfile = useSelector(
    ({ currentCosting }) => currentCosting.currentCostingProfile
  );
  // const schema = currentCostingProfile.ProfileSetting.CommercialHoursSchema;

  const [copyWindow, openCopyWindow] = useState(false);
  //Commented due to infinate API call when clicked on activity breakdown
  // const [calledProfile, setCalledProfile] = useState(false);
  // useEffect(() => {
  //   if (profileId && !calledProfile) {
  //     setCalledProfile(true);
  //     dispatch(getCosting(profileId, null, true));
  //   } else if (!profileId) {
  //     //console.log("boots to dashboard");
  //     history.replace("/");
  //   }
  // }, [calledProfile, dispatch, history, profileId]);
  useEffect(() => {
    if (
      (!currencies || !currencies.length) &&
      currentCostingProfile &&
      currentCostingProfile.ProfileSetting &&
      currentCostingProfile.ProfileSetting.CurrenciesData
    ) {
    }
    if (currentCostingOption && !Object.keys(currentCostingOption).length
      && currentCostingProfile.CountrySpecs?.length
      && _.head(currentCostingProfile.CountrySpecs)?.MethodologySpecs?.length
    ) {

      let country = _.head(currentCostingProfile?.CountrySpecs)
      setcurrentCountrySpec({ ...country })
      let meth = _.head(country?.MethodologySpecs)
      setCurrentSubMeth({ ...meth })
      let _costingoption = _.head(meth?.CostingOptions?.filter(co => co.Selected))
      if (_costingoption)
        setCurrentCOstingOption(_costingoption)
    }
  }, [currencies, currentCostingOption, currentCostingProfile]);
  useEffect(() => {
    if (
      schema &&
      !Object.keys(schema).length &&
      currentCostingProfile &&
      currentCostingProfile.ProfileSetting
    )
      setSchema(currentCostingProfile.ProfileSetting.CommercialHoursSchema);
    let subMethodologies = []
    codeLabels.MethodologyOptions.forEach((m) => {
      m.SubMethodologies.forEach((sm) => {
        subMethodologies.push(sm)
      })
    })
    setSubMethodologies(subMethodologies);
    // //console.log('codelabels', codeLabels.MethodologyOptions);
  }, [codeLabels.MethodologyOptions, currentCostingProfile, schema]);

  useEffect(() => {
    if (
      currencies &&
      !currentCurrency &&
      currentCostingProfile.CostInputCurrency
    ) {
      let values = currentCostingProfile.CostInputCurrency.split("-");
      let currencyUnit = _.last(values);
      let countryCode = _.head(values);
      let finalCurrency = _.head(
        currencies.filter(
          (cur) => cur.Code == countryCode && cur.CurrencyUnit == currencyUnit
        )
      );
      setCurrentCurrency(finalCurrency);
    }
  }, [currentCostingProfile, currencies, currentCurrency]);
  useEffect(() => {
    if (schema) {
      setFinalObjectRef({
        "Executive Director": "ExecutiveDirector",
        Director: "Director",
        "Associate Director": "AssociateDirector",
        "Senior Manager": "SeniorManager",
        Manager: "Manager",
        "Senior Executive": "SeniorExecutive",
        Executive: "Executive",
        "Data Science": "DatascienceInternalComm",
      });
    }
  }, [schema]);

  useEffect(() => {
    if (
      (!clientServiceCards || (currentCostingProfile.ProfileSetting
        && !currentCostingProfile.ProfileSetting.CSRateCardUsed)) &&
      ((currentProject &&
        currentProject.BusinessUnitId)
        || (currentCostingProfile?.Project?.BusinessUnitId)) &&
      !clientRatesCalled &&
      currencies.length
    ) {
      setClientRatesCalled(true);
      let businessUnitId = currentProject && currentProject.BusinessUnitId
        ? currentProject.BusinessUnitId : currentCostingProfile?.Project?.BusinessUnitId
      if (businessUnitId) {
        axios
          .get(
            `marketsettings/${businessUnitId}/clientservicerates/all`
          )
          .then((response) => {
            setClientServiceCards(response.data.CSRatecards);
            let defaultCard = _.head(
              response.data.CSRatecards.filter((cs) => cs.IsDefault)
            );

            if (defaultCard && defaultCard.id && currentCostingProfile && currentCostingProfile.ProfileSetting && !currentCostingProfile.ProfileSetting.CSRateCardUsed) {
              let currentprofile = {
                ...currentCostingProfile,
                ProfileSetting: { ...currentCostingProfile.ProfileSetting },
              };
              currentprofile.ProfileSetting = {
                ...currentprofile.ProfileSetting,
                CSRateCardUsed: { ...defaultCard },
              };
              setdefaultprofileproject(_.cloneDeep(currentprofile))
              dispatch(updateProfile(_.cloneDeep(currentprofile)));
            } else {
              let _profile = { ...currentCostingProfile }
              dispatch(updateProfile(_profile))
            }
            setClientRatesCalled(false);
          })
          .catch((error) => {
            toastr.error("Error while retrieving rates", error.data.error);
          });
      }
    }
  }, [currentCostingProfile, currencies, clientServiceCards, currentProject, clientRatesCalled]);

  const bands = schema?.bands;

  const onChangeHandler = (band, field, value) => {

    let profile = _.cloneDeep(currentCostingProfile)
    if (!profile.ProfileChanged) {
      profile.ProfileChanged = true
      dispatch(updateProfile(profile))
    }

    let newCommercialHoursData = {
      ...currentCostingOption.CommercialHours
    };
    newCommercialHoursData[band] = newCommercialHoursData[band] || {};
    newCommercialHoursData[band][field] = Number(value) || 0;

    bands?.forEach((band) => {
      newCommercialHoursData[band] = newCommercialHoursData[band] || {};
      let total = 0;
      Object.keys(newCommercialHoursData[band]).forEach((key) => {
        if (key !== "Total") {
          total += Number(newCommercialHoursData[band][key]);
        }
      });
      newCommercialHoursData[band]["Total"] = total;
    });
    let _currentCostingOption = { ...currentCostingOption, CommercialHours: newCommercialHoursData }
    setCurrentCOstingOption(_currentCostingOption)


  };
  const commercialFields = {
    "Associate Director": "CostIntCommAssociateDirector",
    "Data Science": "CostIntCommDataScience",
    Director: "CostIntCommDirector",
    "Executive Director": "CostIntCommExecDirector",
    Executive: "CostIntCommExecutive",
    Manager: "CostIntCommManager",
    "Senior Executive": "CostIntCommSeniorExecutive",
    "Senior Manager": "CostIntCommSeniorManager",
  };

  const rateCardReferences = {
    "Executive Director": "ExecutiveDirector",
    Director: "Director",
    "Associate Director": "AssociateDirector",
    "Senior Manager": "SeniorManager",
    Manager: "Manager",
    "Senior Executive": "SeniorExecutive",
    Executive: "Executive",
    "Data Science": "DatascienceInternalComm",
  };
  const CommFieldOnBlur = () => {
    let CSRateCardUsed = {};
    if (
      currentCostingProfile.ProfileSetting &&
      currentCostingProfile.ProfileSetting.CSRateCardUsed
    ) {
      CSRateCardUsed = currentCostingProfile.ProfileSetting.CSRateCardUsed;
    }
    updateprofile()
    let _currentCostingOption = { ...currentCostingOption }
    Object.keys(commercialFields).map((cf) => {
      _currentCostingOption[commercialFields[cf]] = _currentCostingOption.CommercialHours
        && _currentCostingOption.CommercialHours[cf]
        ? _currentCostingOption.CommercialHours[cf]["Total"] * CSRateCardUsed[rateCardReferences[cf]] : 0
    });
    setCurrentCOstingOption(_currentCostingOption)
    // currentCostingProfile.WaveSpecs = [...newWaveSpecs]
    // Object.keys(commercialFields).map((cf) => {
    //   wavespecs = wavespecs.map((ws) => {
    //     ws[commercialFields[cf]] =
    //       ws.CommercialHoursData && ws.CommercialHoursData[cf]
    //         ? ws.CommercialHoursData[cf]["Total"] *
    //         CSRateCardUsed[rateCardReferences[cf]]
    //         : 0;
    //     return { ...ws };
    //   });
    // });
    // let currentwavespec = _.head(
    //   wavespecs.filter((ws) => ws.id == currentWaveSpec.id)
    // );

    // dispatch(waveSpecsActions.setWaveSpecs(wavespecs));

    // dispatch(currentWaveSpecActions.updateCurrentWaveSpec(currentwavespec));
  };
  const CSRateChanged = (eve) => {
    let currentprofile = {
      ...currentCostingProfile,
      ProfileSetting: { ...currentCostingProfile.ProfileSetting },
    };
    let finalRateCard = {}
    if (eve.target.value) {
      finalRateCard = _.head(
        clientServiceCards.filter((csc) => csc.id == eve.target.value)
      );
      currentprofile.ProfileSetting = {
        ...currentprofile.ProfileSetting,
        CSRateCardUsed: { ...finalRateCard },
      };
    } else {
      currentprofile.ProfileSetting = {
        ...currentprofile.ProfileSetting,
        CSRateCardUsed: null,
      };
    }
    currentprofile.CountrySpecs = currentprofile.CountrySpecs.map(cs => {
      cs.MethodologySpecs = cs.MethodologySpecs?.map(ms => {
        ms.CostingOptions = ms.CostingOptions.map(co => {
          if (co.CommercialHours) {
            Object.keys(commercialFields).map((cf) => {
              co[commercialFields[cf]] = co.CommercialHours
                && co.CommercialHours[cf]
                ? co.CommercialHours[cf]["Total"] * ((finalRateCard && finalRateCard[rateCardReferences[cf]]) ? finalRateCard[rateCardReferences[cf]] : 0) : 0
            });
          }
          return { ...co }
        })
        return { ...ms }
      })
      return { ...cs }
    })
    setdefaultprofileproject(currentprofile)
    dispatch(updateProfile(currentprofile))
    // dispatch(updateProfile(currentprofile));
  };
  const CopyCountryChange = (eve) => {
    let currentprofile = {
      ...currentCostingProfileTemp
    };
    if (eve.target.value) {
      currentprofile.SelectedCountry = eve.target.value;
    } else {
      currentprofile.SelectedCountry = 0;
    }
    currentprofile.SelectedMethodology = 0;
    currentprofile.SelectedCostingOption = 0;
    setCurrentCostingProfileTemp(currentprofile);
  };
  const CopyMethodologyChange = (eve) => {
    let currentprofile = {
      ...currentCostingProfileTemp
    };
    if (eve.target.value) {
      currentprofile.SelectedMethodology = eve.target.value;
    } else {
      currentprofile.SelectedMethodology = 0;
      currentprofile.SelectedCostingOption = 0;
    }
    setCurrentCostingProfileTemp(currentprofile);
  };
  const CopyCostingOptionChange = (eve) => {
    // //console.log('eve', eve)
    let currentprofile = {
      ...currentCostingProfileTemp
    };
    if (eve) {
      currentprofile.SelectedCostingOption = eve;
    } else {
      currentprofile.SelectedCostingOption = null;
    }
    setCurrentCostingProfileTemp(currentprofile);
  };
  const getCurrentCurrency = (actualvalue, showUnit = true) => {
    if (
      currencies &&
      currentCurrency &&
      currentCurrency.ConversionRateToLocal
    ) {
      if (actualvalue) {
        let finalVal = _.round(
          actualvalue * currentCurrency.ConversionRateToLocal,
          2
        );
        return `${finalVal}${showUnit ? ' ' + currentCurrency.CurrencyUnit : ''}`;
      }
      else return `0${showUnit ? ' ' + currentCurrency.CurrencyUnit : ''}`;
    }
  };
  function populateSideBarLinks() {
    dispatch(addCostingOptionsLink("#costing_options_card_" + activeTabFC + "_" + activeTabSM));
    let links = [];
    // variables?.forEach((x, index) => {
    //     if (x.isNewSection)
    //         links.push({ label: x.sectionTitle, value: '#header_' + index });
    // })
    dispatch(addSidebarLinks(links));
  }
  const updateprofile = (isApi) => {
    let _profile = { ...currentCostingProfile }

    _profile.CountrySpecs = _profile.CountrySpecs.map(cs => {
      cs.MethodologySpecs = cs.MethodologySpecs?.map(ms => {
        ms.CostingOptions = ms.CostingOptions.map(co => {
          if (co.id == currentCostingOption?.id) {
            let CostTotalInternalCommercial = 0
            co.CommercialHours = currentCostingOption?.CommercialHours
            Object.keys(commercialFields).map((cf) => {
              if (currentCostingOption && Object.keys(currentCostingOption).length > 0) {
                co[commercialFields[cf]] = currentCostingOption[commercialFields[cf]]

              }
              CostTotalInternalCommercial = CostTotalInternalCommercial + co[commercialFields[cf]]
            })
            return { ...co, CostTotalInternalCommercial }
          } else
            return { ...co }
        })
        return { ...ms }
      })
      return { ...cs }
    })
    dispatch(updateProfile(_profile))
    if (isApi) {
      _profile.ProfileChanged = false

      Object.keys(commercialFields).map(cf => {
        _profile[commercialFields[cf]] = 0
      })
      _profile.CountrySpecs = _profile.CountrySpecs.map(cs => {
        Object.keys(commercialFields).map(cf => {
          cs[commercialFields[cf]] = 0
        })
        cs.MethodologySpecs = cs.MethodologySpecs.map(ms => {
          ms.CostingOptions = ms.CostingOptions?.map(co => {
            co.CostTotalInternalCommercial = 0
            Object.keys(commercialFields).map(cf => {
              cs[commercialFields[cf]] = (cs[commercialFields[cf]] ? cs[commercialFields[cf]] : 0) + (co[commercialFields[cf]] ? co[commercialFields[cf]] : 0)
              co.CostTotalInternalCommercial = co.CostTotalInternalCommercial + (co[commercialFields[cf]] ? co[commercialFields[cf]] : 0)
            })
            return { ...co }
          })
          return { ...ms }
        })
        _profile.CostTotalInternalCommercial = 0
        Object.keys(commercialFields).map(cf => {
          _profile[commercialFields[cf]] = (_profile[commercialFields[cf]] ? _profile[commercialFields[cf]] : 0) + cs[commercialFields[cf]]

          _profile.CostTotalInternalCommercial = _profile[commercialFields[cf]] + _profile.CostTotalInternalCommercial
        })
        return { ...cs }
      })
      setTimeout(() => {
        dispatch(saveCostingProfile(_profile))
      });
    }
  }

  const toggle = (tab) => {
    if (activeTabFC !== tab) {
      setActiveTabFC(tab);
      setActiveTabSM(0);
      updateprofile()
      let country = currentCostingProfile?.CountrySpecs[tab]
      setcurrentCountrySpec({ ...country })
      let meth = _.head(country?.MethodologySpecs)
      setCurrentSubMeth({ ...meth })
      let _costingoption = _.head(meth?.CostingOptions?.filter(co => co.Selected))

      setCurrentCOstingOption(_costingoption)
    }
    populateSideBarLinks();
  };

  const toggleSM = (tab) => {
    if (activeTabSM !== tab) {
      setActiveTabSM(tab);
      let meth = currentCostingProfile.CountrySpecs.find(cs => cs.CountryCode == currentCountrySpec.CountryCode).MethodologySpecs[tab];
      setCurrentSubMeth({ ...meth })
      let _costingoption = _.head(meth?.CostingOptions?.filter(co => co.Selected))
      // //console.log('toggleSM_costingoption', _costingoption)
      updateprofile()
      setCurrentCOstingOption(_costingoption)


    }
    populateSideBarLinks();
  };
  const updateCurrentCostingOption = (eve) => {
    if (eve.target.value) {
      updateprofile()
      let _currentSubmeth = { ...currentSubmeth }
      _currentSubmeth.CostingOptions = _currentSubmeth.CostingOptions.map(co => {
        if (co.id == currentCostingOption?.id) {
          co = { ...currentCostingOption }
        }
        return { ...co }
      })
      setCurrentSubMeth(_currentSubmeth)
      let _currentCostingOption = _.head(currentSubmeth?.CostingOptions?.filter(co => co.id == eve.target.value))
      // //console.log('updateCurrentCostingOption', _currentCostingOption)
      setCurrentCOstingOption({ ..._currentCostingOption })
    }
  }
  const openCopyWindowModal = () => {
    setCurrentCostingProfileTemp(_.cloneDeep(currentCostingProfile))
  }

  const checkValidCopyOptions = () => {
    let isInValid = true;
    if (currentCostingProfileTemp.SelectedCountry && currentCostingProfileTemp.SelectedCountry != 0) {
      if (currentCostingProfileTemp.SelectedCountry === 'all') {
        isInValid = false
      }
      else {
        if (currentCostingProfileTemp.SelectedMethodology && currentCostingProfileTemp.SelectedMethodology != 0) {
          if (currentCostingProfileTemp.SelectedMethodology === 'all') {
            isInValid = false
          }
          else {
            if (currentCostingProfileTemp.SelectedCostingOption && currentCostingProfileTemp.SelectedCostingOption.length > 0) {
              isInValid = false
            }
          }
        }
      }
    }
    return isInValid;
  }

  const recalculateValues = (option, code) => {

    // checkPropertyAvailable(field, schema.properties, currentSubmeth.Code)

    let newCommercialHoursData = {};
    let CSRateCardUsed = {};
    if (
      currentCostingProfile.ProfileSetting &&
      currentCostingProfile.ProfileSetting.CSRateCardUsed
    ) {
      CSRateCardUsed = currentCostingProfile.ProfileSetting.CSRateCardUsed;
    }
    // //console.log('option.CommercialHours', option.CommercialHours)

    bands?.forEach((band) => {
      newCommercialHoursData[band] = {};
      Object.keys(schema.properties).forEach((propertyField) => {
        newCommercialHoursData[band][propertyField] = 0;
        if (checkPropertyAvailable(propertyField, schema.properties, code))
          newCommercialHoursData[band][propertyField] = option.CommercialHours && option.CommercialHours[band] && option.CommercialHours[band][propertyField] ? option.CommercialHours[band][propertyField] : 0
        else
          newCommercialHoursData[band][propertyField] = 0
      })
      newCommercialHoursData[band] = newCommercialHoursData[band] || {};
      let total = 0;
      Object.keys(newCommercialHoursData[band]).forEach((key) => {
        if (key !== "Total") {
          total += Number(newCommercialHoursData[band][key]);
        }
      });
      newCommercialHoursData[band]["Total"] = total;
    });
    option.CommercialHours = newCommercialHoursData
    Object.keys(commercialFields).map((cf) => {
      option[commercialFields[cf]] = option.CommercialHours
        && option.CommercialHours[cf]
        ? option.CommercialHours[cf]["Total"] * CSRateCardUsed[rateCardReferences[cf]] : 0
    })
    return option;

  }

  const doCopy = () => {
    if (currentCostingProfileTemp.SelectedCountry && currentCostingProfileTemp.SelectedCountry != 0) {
      if (currentCostingProfileTemp.SelectedCountry === 'all') {
        let _profile = { ...currentCostingProfile }
        _profile.CountrySpecs = _profile.CountrySpecs.map(cs => {
          cs.MethodologySpecs = cs.MethodologySpecs?.map(ms => {
            ms.CostingOptions = ms.CostingOptions.map(co => {
              if (co.Selected && co.id != currentCostingOption?.id) {
                let t = _.cloneDeep(currentCostingOption);
                let rt = recalculateValues(t, ms.Code);
                co.CommercialHours = { ...rt.CommercialHours }
                // //console.log('copy', cs.CountryName, ms.Label, co.Name, t.CommercialHours, co.CommercialHours);
                Object.keys(commercialFields).map((cf) => {
                  co[commercialFields[cf]] = rt[commercialFields[cf]]
                })
              }
              return { ...co }
            })
            return { ...ms }
          })
          return { ...cs }
        })
        // //console.log('_profile', _profile);
        dispatch(updateProfile(_profile));
        let country = _profile?.CountrySpecs[activeTabFC]
        setcurrentCountrySpec({ ...country })
        let meth = _profile?.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM];
        setCurrentSubMeth({ ...meth })
      }
      else {
        if (currentCostingProfileTemp.SelectedMethodology && currentCostingProfileTemp.SelectedMethodology != 0) {
          if (currentCostingProfileTemp.SelectedMethodology === 'all') {
            let _profile = { ...currentCostingProfile }
            _profile.CountrySpecs = _profile.CountrySpecs.map(cs => {
              if (cs.id == currentCostingProfileTemp.SelectedCountry) {
                cs.MethodologySpecs = cs.MethodologySpecs?.map(ms => {
                  ms.CostingOptions = ms.CostingOptions.map(co => {
                    if (co.Selected && co.id != currentCostingOption?.id) {
                      let t = _.cloneDeep(currentCostingOption);
                      let rt = recalculateValues(t, ms.Code);
                      co.CommercialHours = { ...rt.CommercialHours }
                      // //console.log('copy', cs.CountryName, ms.Label, co.Name, t.CommercialHours, co.CommercialHours);
                      Object.keys(commercialFields).map((cf) => {
                        co[commercialFields[cf]] = rt[commercialFields[cf]]
                      })
                    }
                    return { ...co }
                  })
                  return { ...ms }
                })
              }
              return { ...cs }
            })
            // //console.log('_profile', _profile);
            dispatch(updateProfile(_profile));
            let country = _profile?.CountrySpecs[activeTabFC]
            setcurrentCountrySpec({ ...country })
            let meth = _profile?.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM];
            setCurrentSubMeth({ ...meth })
          }
          else {
            if (currentCostingProfileTemp.SelectedCostingOption && currentCostingProfileTemp.SelectedCostingOption.length > 0) {
              // if (currentCostingProfileTemp.SelectedCostingOption === 'all') {
              //   let _profile = { ...currentCostingProfile }
              //   _profile.CountrySpecs = _profile.CountrySpecs.map(cs => {
              //     if (cs.id == currentCostingProfileTemp.SelectedCountry) {
              //       cs.MethodologySpecs = cs.MethodologySpecs?.map(ms => {
              //         if (ms.id == currentCostingProfileTemp.SelectedMethodology) {
              //           ms.CostingOptions = ms.CostingOptions.map(co => {
              //             if (co.Selected && co.id != currentCostingOption.id) {
              //               let t = _.cloneDeep(currentCostingOption);
              //               co.CommercialHours = { ...t.CommercialHours }
              //               // //console.log('copy', cs.CountryName, ms.Label, co.Name, t.CommercialHours, co.CommercialHours);
              //               Object.keys(commercialFields).map((cf) => {
              //                 co[commercialFields[cf]] = t[commercialFields[cf]]
              //               })
              //             }
              //             return { ...co }
              //           })
              //         }
              //         return { ...ms }
              //       })
              //     }
              //     return { ...cs }
              //   })
              //   // //console.log('_profile', _profile);
              //   dispatch(updateProfile(_profile));
              //   let country = _profile?.CountrySpecs[activeTabFC]
              //   setcurrentCountrySpec({ ...country })
              // }
              // else {
              let _profile = { ...currentCostingProfile }
              _profile.CountrySpecs = _profile.CountrySpecs.map(cs => {
                if (cs.id == currentCostingProfileTemp.SelectedCountry) {
                  cs.MethodologySpecs = cs.MethodologySpecs?.map(ms => {
                    if (ms.id == currentCostingProfileTemp.SelectedMethodology) {
                      ms.CostingOptions = ms.CostingOptions.map(co => {
                        if (co.Selected && co.id != currentCostingOption?.id) {
                          let coIds = currentCostingProfileTemp.SelectedCostingOption.map((so) => so.value);
                          // //console.log('coIds', coIds)
                          if (coIds.includes(co.id)) {
                            let t = _.cloneDeep(currentCostingOption);
                            let rt = recalculateValues(t, ms.Code);
                            co.CommercialHours = { ...rt.CommercialHours }
                            // //console.log('copy', cs.CountryName, ms.Label, co.Name, t.CommercialHours, co.CommercialHours);
                            Object.keys(commercialFields).map((cf) => {
                              co[commercialFields[cf]] = rt[commercialFields[cf]]
                            })
                          }
                        }
                        return { ...co }
                      })
                    }
                    return { ...ms }
                  })
                }
                return { ...cs }
              })
              // //console.log('_profile', _profile);
              dispatch(updateProfile(_profile));
              let country = _profile?.CountrySpecs[activeTabFC]
              setcurrentCountrySpec({ ...country })
              let meth = _profile?.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM];
              setCurrentSubMeth({ ...meth })
              // }
            }
          }
        }
      }
    }

  }

  const checkPropertyAvailable = (currentField = '', properties = [], Code) => {
    let subMDetails = subMethodologies.find((s) => s.Code === Code)
    // //console.log('asd', currentField, properties, Code, subMDetails.ResearchType)
    if (subMDetails && subMDetails.ResearchType) {
      if (properties[currentField]) {
        let ks = Object.keys(properties[currentField]);
        if (ks.includes('isQuant') && subMDetails.ResearchType === "Quantitative") {
          return properties[currentField]['isQuant']
        }
        if (ks.includes('isQuali') && subMDetails.ResearchType === "Qualitative") {
          return properties[currentField]['isQuali']
        }
        return true
      }
      else {
        return true
      }
    }
    return true
  }
  // //console.log('currentCostingProfile', currentCostingProfile)
  return (
    <>
      {localPageload || recordloading? (
        <div id="pageCoverSpin"></div>
      ) : null}
      {!props.editDisabled ?
        <Layout
          costMethod={currentCostingProfile.CostingType}
          profileStatusToDisplay={getLabel(
            "CostingStatusOptions",
            currentCostingProfile.ProfileStatus
          )}
          projectStatusToDisplay={getLabel(
            "ProjectStatusOptions",
            currentCostingProfile.Project?.ProjectStatus
          )}
          avoidEdit={true}
        >
          {/* <Row className="justify-content-between m-3"><h3 className="mt-3 ml-1 mb-0">{currentCostingProfile.ProfileName} </h3>
        <Button color="primary" className="btn-sm view-summary-btn" onClick={() => history.push(`/summary/${currentCostingProfile.id}`)}>
          View Cost Summary
        </Button>
      </Row> */}
          <Container fluid>
            <Row>
              <Col md={12} className={'countryTabs tab-vertical'}>
                {!props.editDisabled &&
                  <Row className="justify-content-end">
                    <Col className="mb-2 col-8">
                      <Label className="h5">Rate card in use:</Label>
                      <SelectWrapper
                        onChange={(eve) => CSRateChanged(eve)}
                        selectedValue={currentCostingProfile.ProfileName}
                        value={
                          currentCostingProfile.ProfileSetting &&
                            currentCostingProfile.ProfileSetting.CSRateCardUsed
                            ? currentCostingProfile.ProfileSetting.CSRateCardUsed.id
                            : null
                        }
                      >

                        {clientServiceCards?.map((csc) => (

                          <option value={csc.id}>{csc.ProfileName}</option>
                        ))}
                      </SelectWrapper>

                    </Col>
                  </Row>
                }
                {props.editDisabled &&
                  <Row>
                    <Col lg-1 md-1 xs-12>
                    </Col>
                    <Col lg-7 md-7 xs-12>
                      <Label className="h5">Ratecard applied:</Label>
                      <input
                        value={
                          currentCostingProfile.ProfileSetting?.CSRateCardUsed
                            ?.ProfileName
                        }
                        className="form-control"
                        disabled
                      />
                    </Col>
                  </Row>
                }
                <div className={"tab"}>
                  <Nav tabs>
                    {currentCostingProfile.CountrySpecs &&
                      currentCostingProfile.CountrySpecs.map((fc, indxFC) => (
                        <NavItem key={fc.value}>
                          <NavLink className={classnames({ active: activeTabFC === indxFC })}
                            onClick={() => {
                              toggle(indxFC);
                            }}
                          >
                            <b>{fc.CountryName}</b>
                          </NavLink>
                        </NavItem>
                      ))}
                  </Nav>
                  <TabContent activeTab={activeTabFC}>
                    {currentCostingProfile.CountrySpecs &&
                      currentCostingProfile.CountrySpecs.map((fc, indxFC) => (
                        activeTabFC === indxFC &&
                        <TabPane tabId={indxFC} key={fc.value}>
                          <Row>

                            <Col md={12} className={"methodologyTabs"}>
                              <Nav tabs>
                                {fc.MethodologySpecs &&
                                  fc.MethodologySpecs.map((sm, indxSM) => (
                                    !sm.NotApplicable &&
                                    <NavItem key={sm.value}>
                                      <NavLink className={classnames({ active: activeTabSM === indxSM })}
                                        onClick={() => {
                                          toggleSM(indxSM);
                                        }}
                                      >
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                          <div>
                                            {sm.NotApplicable}
                                            <b>{sm.Label}</b>
                                          </div>

                                        </div>
                                      </NavLink>
                                    </NavItem>
                                  ))}
                              </Nav>
                              <TabContent activeTab={activeTabSM} style={{ padding: "0.25" }}>
                                <Col md={12} className={"methodologyTabs"}>
                                  {/* <div className="ml-auto mb-2">
                                <Row className="justify-content-end">
                                  <Col className="mb-2 col-8">
                                    <Label className="h5">Select Costing Option</Label>
                                    <select className="form-control"
                                      onChange={eve => updateCurrentCostingOption(eve)}
                                      defaultValue={currentCostingOption?.id}
                                      disabled={!currentCostingOption || !currentCostingOption.id}>
                                      {currentSubmeth.CostingOptions?.map(co => <option value={co.id}>{co.Name}</option>)}
                                    </select>
                                  </Col>
                                </Row>
                              </div> */}
                                  <Nav tabs>
                                    {currentSubmeth &&
                                      currentSubmeth.CostingOptions?.filter(co => co.Selected)?.map((co) => (

                                        <NavItem>
                                          <NavLink className={classnames({ active: co.id === currentCostingOption?.id })}
                                            onClick={(eve) => {
                                              updateCurrentCostingOption({ ...eve, target: { ...eve.target, value: co.id } })
                                            }}
                                          >
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                              <div>

                                                <b>{co.Name}</b>
                                              </div>

                                            </div>
                                          </NavLink>
                                        </NavItem>
                                      ))}
                                  </Nav>
                                  {!props.editDisabled &&
                                    <Row className="">
                                      <Col className="col-12 mb-2 text-right">
                                        {
                                          (currentCostingProfile.CountrySpecs?.length > 1 ||
                                            currentCountrySpec.MethodologySpecs?.length > 1 ||
                                            currentSubmeth.CostingOptions?.length > 1)
                                          && <button
                                            className="btn btn-secondary mt-2 mr-2"
                                            onClick={() => {
                                              openCopyWindow(!copyWindow);
                                              openCopyWindowModal();
                                            }}
                                          >
                                            Copy
                                          </button>}

                                      </Col>
                                    </Row>
                                  }
                                  <div className="d-flex">
                                    <Table responsive hover striped bordered size="sm">
                                      <thead>
                                        <tr>
                                          <th>Hourly Chargeout Rate</th>
                                          {bands && currencies && currentCurrency
                                            ? bands.map((band) => (
                                              <td>

                                                {defaultprofileproject && defaultprofileproject.ProfileSetting &&
                                                  defaultprofileproject.ProfileSetting.CSRateCardUsed &&
                                                  defaultprofileproject.ProfileSetting.CSRateCardUsed
                                                  ? getCurrentCurrency(
                                                    defaultprofileproject.ProfileSetting
                                                      .CSRateCardUsed[finalObjectRef[band]]
                                                  )
                                                  : clientServiceCards && clientServiceCards.length ?
                                                    getCurrentCurrency(_.head(clientServiceCards)[finalObjectRef[band]]) :
                                                    getCurrentCurrency()}


                                              </td>
                                            ))
                                            : null}
                                        </tr>
                                        <tr>
                                          <th>Stages</th>
                                          {bands?.map((band) => (
                                            <th>{band}</th>
                                          ))}
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {schema && schema.properties
                                          ? Object.keys(schema.properties).map((field) => {

                                            return checkPropertyAvailable(field, schema.properties, currentSubmeth.Code) ? (
                                              <tr>
                                                <td>
                                                  <span id={field}>
                                                    {schema.properties[field].title}
                                                  </span>
                                                </td>
                                                <Tooltip
                                                  placement="top"
                                                  isOpen={
                                                    commercialTooltips[field] &&
                                                    schema.properties[field].description
                                                  }
                                                  target={field}
                                                  toggle={() =>
                                                    setCommercialTooltips({
                                                      ...commercialTooltips,
                                                      [field]: !commercialTooltips[field],
                                                    })
                                                  }
                                                >
                                                  {schema.properties[field].description}
                                                </Tooltip>
                                                {bands?.map((band) => {
                                                  return (
                                                    <td>
                                                      <Input
                                                        id={`${band}-${field}`}
                                                        type="number"
                                                        value={
                                                          currentCostingOption?.CommercialHours &&
                                                            currentCostingOption?.CommercialHours[band]
                                                            ? currentCostingOption?.CommercialHours[
                                                            band
                                                            ][field] || ""
                                                            : ""
                                                        }
                                                        onChange={(e) =>
                                                          onChangeHandler(
                                                            band,
                                                            field,
                                                            e.target.value
                                                          )
                                                        }
                                                        disabled={!currentCostingOption || !currentCostingOption?.id || props.editDisabled}
                                                        onBlur={CommFieldOnBlur}
                                                        min={0}
                                                      />
                                                    </td>
                                                  );
                                                })}
                                              </tr>
                                            ) : null;
                                          })
                                          : null}
                                      </tbody>
                                      <tfoot style={{ borderTop: "2px solid #dee2e6" }}>
                                        <tr>
                                          <th>Band Totals (Hours)</th>
                                          {bands?.map((band) => {
                                            return (
                                              <td>
                                                {currentCostingOption?.CommercialHours &&
                                                  currentCostingOption?.CommercialHours[band]
                                                  ? currentCostingOption?.CommercialHours[band][
                                                  "Total"
                                                  ] ?? 0
                                                  : 0}
                                              </td>
                                            );
                                          })}
                                        </tr>
                                        <tr>
                                          <th>Band Totals (Values)</th>
                                          {bands?.map((band) => {
                                            return (
                                              <td>
                                                {currentCostingOption?.CommercialHours &&
                                                  currentCostingOption?.CommercialHours[band]
                                                  ? getCurrentCurrency(currentCostingOption?.CommercialHours[band][
                                                    "Total"
                                                  ] * currentCostingProfile?.ProfileSetting?.CSRateCardUsed?.[rateCardReferences[band]] ?? 0, false)
                                                  : 0}
                                              </td>
                                            );
                                          })}
                                        </tr>
                                        <tr>
                                          <th>Total Wave Hours</th>
                                          <td>
                                            {currentCostingOption?.CommercialHours
                                              ? _.sum(
                                                Object.keys(
                                                  currentCostingOption?.CommercialHours
                                                ).map((band) => {
                                                  return currentCostingOption?.CommercialHours[band]
                                                    .Total;
                                                })
                                              )
                                              : 0}
                                          </td>
                                          <td>
                                            {currentCostingOption?.CommercialHours
                                              ? getCurrentCurrency(_.sum(
                                                Object.keys(
                                                  currentCostingOption?.CommercialHours
                                                ).map((band) => {
                                                  return currentCostingOption?.CommercialHours[band]
                                                    .Total * currentCostingProfile?.ProfileSetting?.CSRateCardUsed?.[rateCardReferences[band]];
                                                })
                                              ), false)
                                              : 0}
                                          </td>
                                        </tr>
                                      </tfoot>
                                    </Table>
                                  </div>
                                </Col>
                              </TabContent>
                            </Col>
                          </Row>
                        </TabPane>
                      ))}
                  </TabContent>
                  {!props.editDisabled &&
                    <Row className="mt-2 mb-2 mr-2 justify-content-end">
                      <Button color="secondary" onClick={() => {
                        updateprofile(true)
                      }}>Save
                      </Button>
                    </Row>
                  }
                  <Modal
                    toggle={() => openCopyWindow(!copyWindow)}
                    isOpen={copyWindow}
                  >
                    <ModalHeader toggle={() => openCopyWindow(!copyWindow)}>
                      Select Option to Copy
                    </ModalHeader>
                    <ModalBody>
                      <Row className="justify-content-end">
                        <Col className="mb-2 col-12">
                          <Label className="h5">Country:</Label>
                          <SelectWrapper
                            onChange={(eve) => CopyCountryChange(eve)}
                            value={
                              currentCostingProfileTemp.SelectedCountry ? currentCostingProfileTemp.SelectedCountry : 0
                            }
                          >
                            <option value={0}>Select Country</option>
                            <option value={'all'}>All</option>
                            {currentCostingProfileTemp?.CountrySpecs?.map((cp) => (
                              <option value={cp.id}>{cp.CountryName}</option>
                            ))}
                          </SelectWrapper>
                        </Col>
                      </Row>
                      {(currentCostingProfileTemp.SelectedCountry && currentCostingProfileTemp.SelectedCountry != 'all' && currentCostingProfileTemp.SelectedCountry != 0) ?
                        <Row className="justify-content-end">
                          <Col className="mb-2 col-12">
                            <Label className="h5">Methodology:</Label>
                            <SelectWrapper
                              onChange={(eve) => CopyMethodologyChange(eve)}
                              value={
                                currentCostingProfileTemp.SelectedMethodology ? currentCostingProfileTemp.SelectedMethodology : 0
                              }
                            >
                              <option value={0}>Select Methodology</option>
                              <option value={'all'}>All</option>
                              {currentCostingProfileTemp?.CountrySpecs?.filter((cs) => cs.id == currentCostingProfileTemp.SelectedCountry)[0]?.MethodologySpecs?.map((ms) => (
                                <option value={ms.id}>{ms.Label}</option>
                              ))}
                            </SelectWrapper>
                          </Col>
                        </Row> : null
                      }
                      {(currentCostingProfileTemp.SelectedMethodology && currentCostingProfileTemp.SelectedMethodology !== 'all' && currentCostingProfileTemp.SelectedMethodology != 0) ?
                        <Row className="justify-content-end">
                          <Col className="mb-2 col-12">
                            <Label className="h5">Costing Options:</Label>
                            <Select
                              className="react-select-container"
                              classNamePrefix="react-select"
                              options={currentCostingProfileTemp?.CountrySpecs?.filter((cs) => cs.id == currentCostingProfileTemp.SelectedCountry)[0]?.MethodologySpecs?.filter((cs) => cs.id == currentCostingProfileTemp.SelectedMethodology)[0]?.CostingOptions.filter((co) => co.Selected === true && co.id !== currentCostingOption?.id).map((item) => {
                                return { value: item.id, label: item.Name };
                              })}
                              isMulti
                              isSearchable
                              value={currentCostingProfileTemp.SelectedCostingOption ? currentCostingProfileTemp.SelectedCostingOption : null}
                              onChange={(e) => CopyCostingOptionChange(e)}
                            />
                          </Col>
                        </Row> : null
                      }
                    </ModalBody>
                    <ModalFooter style={{ display: 'block' }}>
                      <Row>
                        <Col className="mb-2 col-8 pl-0">
                          <span className='text-danger float-left'>Note: Copy option will override previous values. </span>
                        </Col>
                        <Col className="mb-2 col-4 pr-0" style={{ textAlign: 'right' }}>
                        <Button
                            color="primary"
                            size="sm"
                            className="mr-2"
                            disabled={checkValidCopyOptions()}
                            onClick={() => {
                              openCopyWindow(!copyWindow);
                              doCopy();
                            }}
                          >
                            Copy
                          </Button>
                          <Button
                            color="secondary"
                            size="sm"
                            onClick={() => {
                              openCopyWindow(!copyWindow);
                            }}
                          >
                            Close
                          </Button>
                        </Col>
                      </Row>
                    </ModalFooter>
                  </Modal>
                </div>
              </Col>

              {/* {renderSelector()} */}

            </Row>
          </Container>
        </Layout> : <Container fluid>
          <Row>
            <Col md={12} className={'countryTabs tab-vertical'}>
              {!props.editDisabled &&
                <Row className="justify-content-end">
                  <Col className="mb-2 col-8">
                    <Label className="h5">Rate card in use:</Label>
                    <select
                      className="form-control"
                      onChange={(eve) => CSRateChanged(eve)}
                      selectedValue={currentCostingProfile.ProfileName}
                      value={
                        currentCostingProfile.ProfileSetting &&
                          currentCostingProfile.ProfileSetting.CSRateCardUsed
                          ? currentCostingProfile.ProfileSetting.CSRateCardUsed.id
                          : null
                      }
                    >

                      {clientServiceCards?.map((csc) => (

                        <option value={csc.id}>{csc.ProfileName}</option>
                      ))}
                    </select>

                  </Col>
                </Row>
              }
              {props.editDisabled &&
                <Row>
                  <Col lg-1 md-1 xs-12>
                  </Col>
                  <Col lg-7 md-7 xs-12>
                    <Label className="h5">Ratecard applied:</Label>
                    <input
                      value={
                        currentCostingProfile.ProfileSetting?.CSRateCardUsed
                          ?.ProfileName
                      }
                      className="form-control"
                      disabled
                    />
                  </Col>
                </Row>
              }
              <div className={"tab"}>
                <Nav tabs>
                  {currentCostingProfile.CountrySpecs &&
                    currentCostingProfile.CountrySpecs.map((fc, indxFC) => (
                      <NavItem key={fc.value}>
                        <NavLink className={classnames({ active: activeTabFC === indxFC })}
                          onClick={() => {
                            toggle(indxFC);
                          }}
                        >
                          <b>{fc.CountryName}</b>
                        </NavLink>
                      </NavItem>
                    ))}
                </Nav>
                <TabContent activeTab={activeTabFC}>
                  {currentCostingProfile.CountrySpecs &&
                    currentCostingProfile.CountrySpecs.map((fc, indxFC) => (
                      activeTabFC === indxFC &&
                      <TabPane tabId={indxFC} key={fc.value}>
                        <Row>

                          <Col md={12} className={"methodologyTabs"}>
                            <Nav tabs>
                              {fc.MethodologySpecs &&
                                fc.MethodologySpecs.map((sm, indxSM) => (
                                  !sm.NotApplicable &&
                                  <NavItem key={sm.value}>
                                    <NavLink className={classnames({ active: activeTabSM === indxSM })}
                                      onClick={() => {
                                        toggleSM(indxSM);
                                      }}
                                    >
                                      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <div>
                                          {sm.NotApplicable}
                                          <b>{sm.Label}</b>
                                        </div>

                                      </div>
                                    </NavLink>
                                  </NavItem>
                                ))}
                            </Nav>
                            <TabContent activeTab={activeTabSM} style={{ padding: "0.25" }}>
                              <Col md={12} className={"methodologyTabs"}>
                                {/* <div className="ml-auto mb-2">
                                <Row className="justify-content-end">
                                  <Col className="mb-2 col-8">
                                    <Label className="h5">Select Costing Option</Label>
                                    <select className="form-control"
                                      onChange={eve => updateCurrentCostingOption(eve)}
                                      defaultValue={currentCostingOption?.id}
                                      disabled={!currentCostingOption || !currentCostingOption.id}>
                                      {currentSubmeth.CostingOptions?.map(co => <option value={co.id}>{co.Name}</option>)}
                                    </select>
                                  </Col>
                                </Row>
                              </div> */}
                                <Nav tabs>
                                  {currentSubmeth &&
                                    currentSubmeth.CostingOptions?.filter(co => co.Selected)?.map((co) => (

                                      <NavItem>
                                        <NavLink className={classnames({ active: co.id === currentCostingOption?.id })}
                                          onClick={(eve) => {
                                            updateCurrentCostingOption({ ...eve, target: { ...eve.target, value: co.id } })
                                          }}
                                        >
                                          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <div>

                                              <b>{co.Name}</b>
                                            </div>

                                          </div>
                                        </NavLink>
                                      </NavItem>
                                    ))}
                                </Nav>
                                {!props.editDisabled &&
                                  <Row className="">
                                    <Col className="col-12 mb-2 text-right">
                                      <button
                                        className="btn btn-secondary mt-2 mr-2"
                                        onClick={() => {
                                          openCopyWindow(!copyWindow);
                                          openCopyWindowModal();
                                        }}
                                      >
                                        Copy
                                      </button>
                                    </Col>
                                  </Row>
                                }
                                <div className="d-flex">
                                  <Table responsive hover striped bordered size="sm">
                                    <thead>
                                      <tr>
                                        <th>Hourly Chargeout Rate</th>
                                        {bands && currencies && currentCurrency
                                          ? bands.map((band) => (
                                            <td>

                                              {defaultprofileproject && defaultprofileproject.ProfileSetting &&
                                                defaultprofileproject.ProfileSetting.CSRateCardUsed &&
                                                defaultprofileproject.ProfileSetting.CSRateCardUsed
                                                ? getCurrentCurrency(
                                                  defaultprofileproject.ProfileSetting
                                                    .CSRateCardUsed[finalObjectRef[band]]
                                                )
                                                : clientServiceCards && clientServiceCards.length ?
                                                  getCurrentCurrency(_.head(clientServiceCards)[finalObjectRef[band]]) :
                                                  getCurrentCurrency()}


                                            </td>
                                          ))
                                          : null}
                                      </tr>
                                      <tr>
                                        <th>Stages</th>
                                        {bands?.map((band) => (
                                          <th>{band}</th>
                                        ))}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {schema && schema.properties
                                        ? Object.keys(schema.properties).map((field) => {

                                          return checkPropertyAvailable(field, schema.properties, currentSubmeth.Code) ? (
                                            <tr>
                                              <td>
                                                <span id={field}>
                                                  {schema.properties[field].title}
                                                </span>
                                              </td>
                                              <Tooltip
                                                placement="top"
                                                isOpen={
                                                  commercialTooltips[field] &&
                                                  schema.properties[field].description
                                                }
                                                target={field}
                                                toggle={() =>
                                                  setCommercialTooltips({
                                                    ...commercialTooltips,
                                                    [field]: !commercialTooltips[field],
                                                  })
                                                }
                                              >
                                                {schema.properties[field].description}
                                              </Tooltip>
                                              {bands?.map((band) => {
                                                return (
                                                  <td>
                                                    <Input
                                                      id={`${band}-${field}`}
                                                      type="number"
                                                      value={
                                                        currentCostingOption?.CommercialHours &&
                                                          currentCostingOption?.CommercialHours[band]
                                                          ? currentCostingOption?.CommercialHours[
                                                          band
                                                          ][field] || ""
                                                          : ""
                                                      }
                                                      onChange={(e) =>
                                                        onChangeHandler(
                                                          band,
                                                          field,
                                                          e.target.value
                                                        )
                                                      }
                                                      disabled={!currentCostingOption || !currentCostingOption?.id || props.editDisabled}
                                                      onBlur={CommFieldOnBlur}
                                                      min={0}
                                                    />
                                                  </td>
                                                );
                                              })}
                                            </tr>
                                          ) : null;
                                        })
                                        : null}
                                    </tbody>
                                    <tfoot style={{ borderTop: "2px solid #dee2e6" }}>
                                      <tr>
                                        <th>Band Totals (Hours)</th>
                                        {bands?.map((band) => {
                                          return (
                                            <td>
                                              {currentCostingOption?.CommercialHours &&
                                                currentCostingOption?.CommercialHours[band]
                                                ? currentCostingOption?.CommercialHours[band][
                                                "Total"
                                                ] ?? 0
                                                : 0}
                                            </td>
                                          );
                                        })}
                                      </tr>
                                      <tr>
                                        <th>Band Totals (Values)</th>
                                        {bands?.map((band) => {
                                          return (
                                            <td>
                                              {currentCostingOption?.CommercialHours &&
                                                currentCostingOption?.CommercialHours[band]
                                                ? getCurrentCurrency(currentCostingOption?.CommercialHours[band][
                                                  "Total"
                                                ] * currentCostingProfile?.ProfileSetting?.CSRateCardUsed?.[rateCardReferences[band]] ?? 0, false)
                                                : 0}
                                            </td>
                                          );
                                        })}
                                      </tr>
                                      <tr>
                                        <th>Total Wave Hours</th>
                                        <td>
                                          {currentCostingOption?.CommercialHours
                                            ? _.sum(
                                              Object.keys(
                                                currentCostingOption?.CommercialHours
                                              ).map((band) => {
                                                return currentCostingOption?.CommercialHours[band]
                                                  .Total;
                                              })
                                            )
                                            : 0}
                                        </td>
                                        <td>
                                          {currentCostingOption?.CommercialHours
                                            ? getCurrentCurrency(_.sum(
                                              Object.keys(
                                                currentCostingOption?.CommercialHours
                                              ).map((band) => {
                                                return currentCostingOption?.CommercialHours[band]
                                                  .Total * currentCostingProfile?.ProfileSetting?.CSRateCardUsed?.[rateCardReferences[band]];
                                              })
                                            ), false)
                                            : 0}
                                        </td>
                                      </tr>
                                    </tfoot>
                                  </Table>
                                </div>
                              </Col>
                            </TabContent>
                          </Col>
                        </Row>
                      </TabPane>
                    ))}
                </TabContent>
                {!props.editDisabled &&
                  <Row className="mt-2 mb-2 mr-2 justify-content-end">
                    <Button color="secondary" onClick={() => {
                      updateprofile(true)
                    }}>Save
                      {recordloading && <FontAwesomeIcon class="saving-btn-sec" icon={faSpinner} fixedWidth />}
                    </Button>
                  </Row>
                }
                <Modal
                  toggle={() => openCopyWindow(!copyWindow)}
                  isOpen={copyWindow}
                >
                  <ModalHeader toggle={() => openCopyWindow(!copyWindow)}>
                    Select Option to Copy
                  </ModalHeader>
                  <ModalBody>
                    <Row className="justify-content-end">
                      <Col className="mb-2 col-12">
                        <Label className="h5">Country:</Label>
                        <SelectWrapper
                          onChange={(eve) => CopyCountryChange(eve)}
                          value={
                            currentCostingProfileTemp.SelectedCountry ? currentCostingProfileTemp.SelectedCountry : 0
                          }
                        >
                          <option value={0}>Select Country</option>
                          <option value={'all'}>All</option>
                          {currentCostingProfileTemp?.CountrySpecs?.map((cp) => (
                            <option value={cp.id}>{cp.CountryName}</option>
                          ))}
                        </SelectWrapper>
                      </Col>
                    </Row>
                    {(currentCostingProfileTemp.SelectedCountry && currentCostingProfileTemp.SelectedCountry != 'all' && currentCostingProfileTemp.SelectedCountry != 0) ?
                      <Row className="justify-content-end">
                        <Col className="mb-2 col-12">
                          <Label className="h5">Methodology:</Label>
                          <SelectWrapper
                            onChange={(eve) => CopyMethodologyChange(eve)}
                            value={
                              currentCostingProfileTemp.SelectedMethodology ? currentCostingProfileTemp.SelectedMethodology : 0
                            }
                          >
                            <option value={0}>Select Methodology</option>
                            <option value={'all'}>All</option>
                            {currentCostingProfileTemp?.CountrySpecs?.filter((cs) => cs.id == currentCostingProfileTemp.SelectedCountry)[0]?.MethodologySpecs?.map((ms) => (
                              <option value={ms.id}>{ms.Label}</option>
                            ))}
                          </SelectWrapper>
                        </Col>
                      </Row> : null
                    }
                    {(currentCostingProfileTemp.SelectedMethodology && currentCostingProfileTemp.SelectedMethodology !== 'all' && currentCostingProfileTemp.SelectedMethodology != 0) ?
                      <Row className="justify-content-end">
                        <Col className="mb-2 col-12">
                          <Label className="h5">Costing Options:</Label>
                          <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            options={currentCostingProfileTemp?.CountrySpecs?.filter((cs) => cs.id == currentCostingProfileTemp.SelectedCountry)[0]?.MethodologySpecs?.filter((cs) => cs.id == currentCostingProfileTemp.SelectedMethodology)[0]?.CostingOptions.filter((co) => co.Selected === true && co.id !== currentCostingOption?.id).map((item) => {
                              return { value: item.id, label: item.Name };
                            })}
                            isMulti
                            isSearchable
                            value={currentCostingProfileTemp.SelectedCostingOption ? currentCostingProfileTemp.SelectedCostingOption : null}
                            onChange={(e) => CopyCostingOptionChange(e)}
                          />
                        </Col>
                      </Row> : null
                    }
                  </ModalBody>
                  <ModalFooter style={{ display: 'block' }}>
                    <Row>
                      <Col className="mb-2 col-8 pl-0">
                        <span className='text-danger float-left'>Note: Copy option will override previous values. </span>
                      </Col>
                      <Col className="mb-2 col-4 pr-0" style={{ textAlign: 'right' }}>
                        <Button
                          color="secondary"
                          size="sm"
                          onClick={() => {
                            openCopyWindow(!copyWindow);
                          }}
                        >
                          Close
                        </Button>
                        <Button
                          color="primary"
                          size="sm"
                          className="ml-2"
                          disabled={checkValidCopyOptions()}
                          onClick={() => {
                            openCopyWindow(!copyWindow);
                            doCopy();
                          }}
                        >
                          Copy
                        </Button>
                      </Col>
                    </Row>
                  </ModalFooter>
                </Modal>
              </div>
            </Col>

            {/* {renderSelector()} */}

          </Row>
        </Container>
      }</>
  );
};

export default React.memo(CommercialHours);
