import React from "react";

function Save() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          fill="#000"
          d="M15.5 2H11v4.5h4.5V2zm3.75 0h-1.5v6.75a.75.75 0 01-.75.75H7.25a.75.75 0 01-.75-.75V2H4.25A2.25 2.25 0 002 4.25v16.5A2.25 2.25 0 004.25 23h16.5A2.25 2.25 0 0023 20.75v-15L19.25 2zm-.75 19.5h-12v-6.75a.771.771 0 01.773-.75H17.75a.75.75 0 01.75.75v6.75z"
        ></path>
      </g>
    </svg>
  );
}

export default Save;