import React, { useState, useEffect } from "react";
import {
    Label,
    Input,
    Button,
    Row,
    Col,
    Card,
    CardBody,
    Container,
    Collapse,
    CardHeader,
    CardTitle,
    CardText,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Badge,
    Table,
    InputGroup,
    InputGroupAddon,
    NavLink,
    NavItem,
    Nav,
    TabContent,
    TabPane,
} from "reactstrap";
import classnames from "classnames";

import _ from "lodash";
import { getLabel } from "../../utils/codeLabels";
const ProfileLevelmethodologiesBreakDown = ({ profile }) => {
    const [allRFQSchema, setAllRFQSchema] = useState({});
    const [rfqData, setRfqData] = useState({});
    const [activeTabFC, setActiveTabFC] = useState();
    const [activeTabSM, setActiveTabSM] = useState();
    useEffect(() => {
        if (profile && profile.CountrySpecs) {
        let countryData = _.head(profile.CountrySpecs);
        let mData = countryData.MethodologySpecs?.filter(ms => !ms.NotApplicable);
        let methodologyID = _.head(mData).id
        setActiveTabFC(countryData.id);
        setActiveTabSM(methodologyID);
        }
    }, [profile])

    useEffect(() => {
        if (profile && profile.CountrySpecs) {
            let finalData = {};
            let _allRFQSchema = {}
            profile.CountrySpecs.map((cs) => {
                cs.MethodologySpecs.filter((ms) => !ms.NotApplicable).map((ms) => {
                    if (!_allRFQSchema[ms.Code])
                        _allRFQSchema[ms.Code] = ms.RFQSchema
                    if (!finalData[cs.CountryCode]) {
                        finalData[cs.CountryCode] = {};
                    }
                    if (!finalData[cs.CountryCode][ms.Code]) {
                        finalData[cs.CountryCode][ms.Code] = {};
                    }
                    finalData[cs.CountryCode][ms.Code] = ms.RFQData;
                });
            });
            setAllRFQSchema(_allRFQSchema)
            setRfqData(finalData);
        }
    }, [profile]);
    const getProperty = (schema, prop) => {
        if (schema && schema.properties && schema.properties[prop]) return schema.properties[prop];
        else {
            let reqVal = "";
            if (schema?.dependencies) {
                Object.keys(schema.dependencies).map((dep) => {
                    schema.dependencies[dep].oneOf.map((oo) => {
                        if (oo.properties[prop] && oo.properties[prop].title) {
                            reqVal = oo.properties[prop];
                        }
                    });
                });
            }
            return reqVal;
        }
    };

    const toggleFC = (tab) => {
        if (activeTabFC !== tab) {
            setActiveTabFC(tab);
            let cData = profile.CountrySpecs.filter(x => x.id === tab);
            let methData = _.head(cData);
            let mData = methData.MethodologySpecs?.filter(ms => !ms.NotApplicable);
            let methodologyID = _.head(mData).id
            setActiveTabSM(methodologyID)
            // onMethodologyChange(methodologyID)
        }
    };

    const toggleSM = (tab) => {
        if (activeTabSM !== tab) {
            setActiveTabSM(tab);
            // onMethodologyChange(tab)
        }
    };

    return (
        <>
            <Row>
                <Col md={12} className={'countryTabs tab-vertical'}>

                    <div className={"tab mt-5"}>
                        <Nav tabs>
                            {profile.CountrySpecs &&
                                profile.CountrySpecs.map((fc) => (
                                    <NavItem key={fc.value}>
                                        <NavLink className={classnames({ active: activeTabFC === fc.id })}
                                            onClick={() => {
                                                toggleFC(fc.id);
                                            }}
                                        >
                                            <b>{fc.CountryName}</b>
                                        </NavLink>
                                    </NavItem>
                                ))}
                        </Nav>
                        <TabContent activeTab={activeTabFC}>
                            {profile.CountrySpecs &&
                                profile.CountrySpecs.map((fc, indxFC) => (
                                    activeTabFC === fc.id &&
                                    <TabPane tabId={fc.id} key={fc.value}>
                                        <Row>
                                            <Col md={12} className={"methodologyTabs"}>
                                                <Nav tabs>
                                                    {fc.MethodologySpecs &&
                                                        fc.MethodologySpecs.filter(x => !x.NotApplicable).map((sm, indxSM) =>
                                                        (
                                                            <NavItem key={sm.value}>
                                                                <NavLink className={classnames({ active: activeTabSM === sm.id })}
                                                                    onClick={() => {
                                                                        toggleSM(sm.id);
                                                                    }}
                                                                >
                                                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                        <div>
                                                                            <b>{sm.Label}</b>
                                                                        </div>

                                                                    </div>
                                                                </NavLink>
                                                            </NavItem>
                                                        ))}
                                                </Nav>

                                                <TabContent activeTab={activeTabSM} style={{ padding: "0.25" }}>
                                                    {fc.MethodologySpecs &&
                                                        fc.MethodologySpecs.map((ms, indxSM) => {
                                                            return <TabPane tabId={ms.id} key={ms.value}>
                                                                <CardHeader >
                                                                    <Row>
                                                                        <Col xs="10" sm="11" md="11">
                                                                            <CardTitle className="mb-0">
                                                                                Methodologies and Fieldwork Breakdown
                                                                            </CardTitle>
                                                                        </Col>
                                                                    </Row>
                                                                </CardHeader>
                                                                <Table responsive hover striped bordered={true} size="sm">
                                                                    <tbody>

                                                                        <>
                                                                            <tr className="meth-head-country">
                                                                                <th>
                                                                                    <h5 class="text-uppercase mb-0">
                                                                                        {ms.Label}
                                                                                    </h5>
                                                                                </th>
                                                                                <th>
                                                                                    {getLabel(
                                                                                        "FieldingCountriesOptions",
                                                                                        fc.CountryCode
                                                                                    )}
                                                                                </th>
                                                                            </tr>
                                                                            {(allRFQSchema[ms.Code] && Object.keys(allRFQSchema[ms.Code]).length) ? (
                                                                                Object.keys(allRFQSchema[ms.Code].properties)?.map((rfq) => {
                                                                                    let property = getProperty(ms.RFQSchema, rfq);
                                                                                    return property ? (
                                                                                        <>
                                                                                            {property.isNewSection ? (
                                                                                                <tr>
                                                                                                    <th>{property.sectionTitle}</th>

                                                                                                </tr>
                                                                                            ) : null}
                                                                                            <tr>
                                                                                                <td>{property.title}</td>
                                                                                                <td>
                                                                                                    {rfqData[fc.CountryCode] &&
                                                                                                        rfqData[fc.CountryCode][
                                                                                                        ms.Code
                                                                                                        ]
                                                                                                        ? Array.isArray(
                                                                                                            rfqData[fc.CountryCode][
                                                                                                            ms.Code
                                                                                                            ][rfq]
                                                                                                        )
                                                                                                            ? rfqData[fc.CountryCode][
                                                                                                                ms.Code
                                                                                                            ][rfq].length ? rfqData[fc.CountryCode][
                                                                                                                ms.Code
                                                                                                            ][rfq].map(v => v.label).join() : "-"
                                                                                                            : typeof rfqData[
                                                                                                                fc.CountryCode
                                                                                                            ][ms.Code][rfq] == "boolean"
                                                                                                                ? rfqData[fc.CountryCode][
                                                                                                                    ms.Code
                                                                                                                ][rfq]
                                                                                                                    ? "Yes"
                                                                                                                    : "No"
                                                                                                                : rfqData[fc.CountryCode][
                                                                                                                    ms.Code
                                                                                                                ][rfq]
                                                                                                                    ? rfqData[fc.CountryCode][
                                                                                                                    ms.Code
                                                                                                                    ][rfq]
                                                                                                                    : "-"
                                                                                                        : "-"}
                                                                                                </td>
                                                                                            </tr>
                                                                                        </>
                                                                                    ) : null;
                                                                                })
                                                                            ) : (
                                                                                <tr>
                                                                                    <td>{ms.Label}</td>
                                                                                    <td>
                                                                                        No data is available for this methodology.
                                                                                    </td>
                                                                                </tr>
                                                                            )}
                                                                        </>

                                                                    </tbody>
                                                                </Table>

                                                            </TabPane>
                                                        })}
                                                </TabContent>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                ))}
                        </TabContent>
                    </div>
                </Col>
            </Row>

        </>
    )
}

export default ProfileLevelmethodologiesBreakDown
