import axios from "../../axios-interceptor";
export const SET_REPORT_STATUSES = "SET_REPORT_STATUSES";

export const getReportStatuses = () => {
  return (dispatch, getState) => {
    axios
      .get("/reports/statuses")
      .then((res) => {
        dispatch({
          type: SET_REPORT_STATUSES,
          ReportStatuses: res.data && res.data.statuses ? res.data.statuses : [],
        });
      })
      .catch((err) => {
      });
  };
};
