import React, { useState, useEffect } from 'react'
import {
    Button,
    Row,
    Col,
    Card,
    CardBody,
    Container,
    CardTitle,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    Table
} from "reactstrap";
import Spinner from "../../components/Spinner";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { setCurrentProject } from '../../redux/actions/currentProjectActions';
import axios from '../../axios-interceptor';
import { toastr } from 'react-redux-toastr';
import { recordLoadEnd, recordLoadStart } from '../../redux/actions/appActions';
import SFProjectInfoSection from './SFProjectInfoSection';
import moment from "moment";
import DatePicker from "react-datepicker";

import logo from "../../assets/img/avatars/modal-logo.png";
import "react-datepicker/dist/react-datepicker.css";
var imgStyle = {
    width: "150px",
  };

const ProjectDeliveryDates = ({disableforCS}) => {

    const [isOpen, handleOpen] = useState(false)
    const [auditData, setAuditData] = useState([])
    const [count, setCount] = useState(0);
    const [isSFProject, setIsSFProject] = useState(false)
    const [DateFieldWorkStartPlanned, setDateFieldWorkStartPlanned] = useState(null)

    const onModalChange = () => {
        let id = `${project.id}`;
        axios.post(`/projects/FetchAuditLogsByProjectId`, { projectId: id })
        .then((res) => {
            setAuditData(res.data.logs);
            setCount(res.data.logs.length);
        })
        .catch((err) => {
            //console.log('Error ' +err);
        });
        handleOpen(!isOpen);
    };

    const cancelOpen = () => {
        handleOpen();
    }

    const dispatch = useDispatch()
    const app = useSelector(({ app }) => app);

    const currentCostingProfile = useSelector(
        ({ currentCosting }) => currentCosting.currentCostingProfile
    );
    const project = useSelector(
        ({ currentProject }) => currentProject.newProject
    );

    const [errorMessage, setErrorMessage] = useState("");
    const codeLabels = useSelector(({ codeLabels }) => codeLabels);

    const [toggle, setToggle] = useState({
        overall: true,
        inst: true,
        other: true,
    });
    const [plannedDates, setPlannedDates] = useState({})
    const [estimatedDays, setEstimatedDays] = useState(0)
    const [finalProjectDates, setFinalProjectDates] = useState({

    })

    useEffect(() => {
        if (!Object.keys(plannedDates).length && project?.ContractDetails?.length
            && project.ContractDetails.filter(cd => cd.isSF).length && currentCostingProfile?.ProfileSetting) {
            let reqContracts = project.ContractDetails.filter(cd => cd.isSF)
                .filter(proj => proj.Probability >= currentCostingProfile?.ProfileSetting?.MinimumSFProbability)
            let finalContract = _.head(reqContracts)
            if (finalContract) {
                let _planneddates = {
                    DateEndOfDeliveryPlanned: moment(finalContract.EndofDelivery).format("YYYY-MM-DD"),
                    // DateFieldWorkStartPlanned:  moment(finalContract.StartofDelivery).format("YYYY-MM-DD")
                }
                setDateFieldWorkStartPlanned(moment(finalContract.StartofDelivery).format("YYYY-MM-DD"))
                setPlannedDates({ ..._planneddates })
                dispatch(setCurrentProject({ ...project, ..._planneddates }))
            }
        }
        if (!Object.keys(finalProjectDates).length
            && projectDatesParams["Project Delivery Dates"].DateFields.filter(df => project[df.id]).length) {

            var _finalProjectDates = { ...finalProjectDates }
            projectDatesParams["Project Delivery Dates"].DateFields?.map(df => {
                _finalProjectDates = { ..._finalProjectDates, [df.id]: project[df.id] }
            })
            setFinalProjectDates(_finalProjectDates)
        }
        if (!estimatedDays && project.CommissioningCountry) {
            let commCountry = codeLabels?.FieldingCountriesOptions.find(fc => fc.Code == project.CommissioningCountry)
            let days = commCountry?.FWDays;
            setEstimatedDays(days)
            let _projectDatesParams = { ...projectDatesParams }
            _projectDatesParams["Project Delivery Dates"] = { ..._projectDatesParams["Project Delivery Dates"] }
            _projectDatesParams["Project Delivery Dates"]["DateFields"] = [..._projectDatesParams["Project Delivery Dates"]["DateFields"]]
            setProjectDatesParams({ ...projectDatesParams, "Project Delivery Dates": { ...projectDatesParams["Project Delivery Dates"] } })
        }
        if (project?.ContractDetails?.length && project.ContractDetails.filter(cd => cd.isSF).length) {
            setIsSFProject(true);
        }
    }, [currentCostingProfile, project])
    const [projectDatesParams, setProjectDatesParams] = useState({
        "Project Delivery Dates": {
            id: "Project Delivery Dates",
            DateFields: [
                {
                    id: "DateFieldWorkStartPlanned",
                    label: "Fieldwork Start Date (MSD) ",
                    sfProp: "StartofDelivery",
                    DisablingVal: true,



                }, 
                , {
                    id: "CommissionedDate",
                    label: "Commissioning Date in CINTRAL",
                    sfProp: "CommissionedDate",
                    DisablingVal: true,
                },
                {

                    id: "DateEndOfDeliveryPlanned",
                    label: "Client Delivery Date (EOD) (MSD)",
                    sfProp: "EndofDelivery",
                    DisablingVal: true,
                },
                // {
                //     id: "DateFieldWorkStartActual",
                //     label: "Actual Fieldwork Start",
                //     isRequired: true,
                //     DisablingVal: false,

                // },
                // {
                //     id: "DateFieldWorkEndActual",
                //     label: "Actual Fieldwork End",
                //     isRequired: true,
                //     DisablingVal: false,

                // }
                // ,
                // {
                //     id: "DateFieldWorkEndPlanned",
                //     label: "Planned Fieldwork End",
                //     isRequired: true,
                //     DisablingVal: false,

                // },
                // {
                //     id: "DateEndOfDeliveryActual",
                //     label: "Estimated Delivery Date",
                //     isRequired: true,
                //     DisablingVal: true,
                //     info: `This Delivery Date has been calculated with respect to your Fieldwork End Date, i.e., estimatedDays Working Days from your Fieldwork End Date`//couldnt replace through state, so, workaround
                // },


            ],

        },
    })



    const getWorkingDays = (date) => {
        let commCountry = codeLabels.FieldingCountriesOptions.find(fc => fc.Code == project.CommissioningCountry)
        let days = commCountry.FWDays;
        let weekendFirstDay = commCountry.WeekStartDay;
        let weekendSecondDay = commCountry.WeekEndDay;
        date = moment(date); // use a clone
        if (date.isoWeekday() == weekendFirstDay)
            date = date.subtract(1, 'days');
        if (date.isoWeekday() == weekendSecondDay)
            date = date.subtract(2, 'days');

        while (days > 0) {
            // if (date.isoWeekday() === weekendFirstDay)
            //     date = date.subtract(3, 'days');
            // else if (date.isoWeekday() === weekendSecondDay)
            //     date = date.subtract(2, 'days');

            date = date.add(1, 'days');
            // decrease "days" only if it's a weekday.
            //Dates enum
            // MONDAY: 1,
            // TUESDAY: 2,
            // WEDNESDAY: 3,
            // THURSDAY: 4,
            // FRIDAY: 5,
            // SATURDAY: 6,
            // SUNDAY: 7
            if (date.isoWeekday() !== weekendFirstDay && date.isoWeekday() !== weekendSecondDay) {
                days -= 1;
            }

        }
        return date.format("YYYY-MM-DD");
    }

    const onChangeHandler = (eve, id) => {
        var _finalProjectDates = { ...finalProjectDates, [id]: eve ? moment(eve).format("YYYY-MM-DD") : null }
        setFinalProjectDates(_finalProjectDates)
        dispatch(setCurrentProject({ ...project, ..._finalProjectDates }))
        if (id === "DateFieldWorkEndActual") {

            let _modifiedFinalProjects = { ..._finalProjectDates, DateEndOfDeliveryActual: eve ? getWorkingDays(eve) : null }
            setFinalProjectDates(_modifiedFinalProjects)
            dispatch(setCurrentProject({ ...project, ..._modifiedFinalProjects }))
        }
        else if (id === "DateFieldWorkEndPlanned" && !finalProjectDates["DateFieldWorkEndActual"]) {
            let _modifiedFinalProjects = { ..._finalProjectDates, DateEndOfDeliveryActual: eve ? getWorkingDays(eve) : null }
            setFinalProjectDates(_modifiedFinalProjects)
            dispatch(setCurrentProject({ ...project, ..._modifiedFinalProjects }))
        }

    }
    const saveProjectDatesFinal = () => {
        dispatch(recordLoadStart())
        let id = `${project.id}`;
        axios.put(`projects/${project.ProjectId}/UpdateProjectDates`, { Dates: { ...finalProjectDates, ...plannedDates }, id: id })
            .then(res => {
                dispatch(setCurrentProject({ ...project, ...finalProjectDates }))
                dispatch(recordLoadEnd())
                if (res.data.email)
                    toastr.success("Saved the Dates", "Project level dates saved successfully and Email Sent Successfully to Respective RevenueController Users and Proposal owner")
                else
                    toastr.success("Saved the Dates", "Project level dates saved successfully")    
            }).catch(error => {
                dispatch(recordLoadEnd())
                toastr.error("Couldn't Save Dates", "Something went wrong while saving dates")
            })
    }
    const saveProjectDates = () => {
        if (finalProjectDates["DateFieldWorkEndActual"] && finalProjectDates["DateFieldWorkStartActual"]) {
            if (finalProjectDates["DateFieldWorkEndActual"] >= finalProjectDates["DateFieldWorkStartActual"]) {
                saveProjectDatesFinal()
            } else {
                toastr.error("Cannot Save Dates", "Fieldwork End Date Shouldn't be before Fieldwork Start Date")
            }
        } else {
            saveProjectDatesFinal()
        }

    };

    return (
        <>
            <div className="ml-2 mr-2 mb-2">


                <div className="mb-0">
                {/* {isSFProject ? 
                    <Card>
                        <CardTitle className="text-uppercase m-4">
                            Project Details
                        </CardTitle>
                        <Row className="m-0">
                            <Col>
                                <SFProjectInfoSection
                                    profile={currentCostingProfile}
                                    project={project}
                                />
                            </Col>
                        </Row>
                    </Card> : null } */}
                    <Row>
                        <Col>
                            {Object.keys(projectDatesParams).map((field) => {
                                return (
                                    <Card className="mb-2">
                                        <CardBody>
                                            <Row>
                                                <CardTitle className="text-uppercase col-6">
                                                Project Level Schedule &nbsp;&nbsp;&nbsp;&nbsp;
                                                {/* <a id="viewHistory" className="text-capitalize text-link" onClick={onModalChange}>View Audit Logs</a> */}
                                                </CardTitle>
                                                {/* <div className="col-3">
                                                    <a onClick={onModalChange}>View Audit Logs</a></div> */}
             
             
                                            </Row>
                                            {/* <Row  className="mb-2">
                                            <Col
                                            md = "4"
                                            className="mb-4 fw-bold"

                                            >
                                                <Label className="fw-bolder"> Schedule Milestones (Mandatory inputs)</Label>
                                            </Col>
                                            <Col  className="mb-4 fw-bolder"><Label className="fw-bold">Planned Dates</Label></Col>
                                           </Row> */}
                                                {projectDatesParams[field].DateFields.map((df, index) => {
                                                    if(df.id == "CommissionedDate"){
                                                        return (
                                                            <>
                                                         <Row className="mb-2">
    
                                                                <Col 
                                                                // ...lg="6" md="12" sm="12" xs="12"
                                                                 md = "4"
                                                                 className="review-schedule-dates d-flex mb-4">
                                                                    {/* couldnt render through info, making it as different state object */}
                                                                    <Label className = "mr-4">{df.label}{df.info ? <FontAwesomeIcon icon={faInfoCircle} className="pointer ml-2  info" title={(df.info).replace("estimatedDays", estimatedDays)} /> : null}</Label>
                                                                    {/* <input
                                                                        disabled={
                                                                            df.DisablingVal
                                                                        }
                                                                        className="form-control mb-2"
                                                                        id={df.id}
                                                                        onChange={(eve) => onChangeHandler(eve)}
                                                                        placeholder="DD/MM/YYYY"
    
                                                                        value={
                                                                            project[df.id]
                                                                                ?
                                                                                moment(_.head(
                                                                                    project[df.id].split("T"))).format("DD/MM/YYYY")
                                                                                : plannedDates[df.id]
                                                                                    ? moment(_.head(
                                                                                        plannedDates[df.id].split("T"))).format("DD/MM/YYYY") : null
                                                                        }
                                                                        type="date"
                                                                    /> */}
                                                                    </Col>
                                                                    <Col>
                                                                    <DatePicker
                                                                        disabled={
                                                                            df.DisablingVal||disableforCS
                                                                        }
                                                                        locale="en-GB"
                                                                        className="form-control"
                                                                        selected={
                                                                            project[df.id] ?new Date(project[df.id]) :
                                                                            df.id == "DateFieldWorkStartPlanned"? new Date(DateFieldWorkStartPlanned): df.id == "CommissionedDate" &&currentCostingProfile == 6 && currentCostingProfile[df.id]? new Date(currentCostingProfile[df.id].split("T")[0]):
                                                                            new Date()
                                                                            // ?
                                                                            // _.head(
                                                                            //     project[df.id])
                                                                            // :
                                                                            // plannedDates[df.id]
                                                                            //     ? _.head(
                                                                            //         plannedDates[df.id]) : null
                                                                        }
    
                                                                        onChange={(e) => onChangeHandler(e, df.id)}
                                                                        dateFormat="dd-MM-yyyy"
                                                                        placeholderText="dd-mm-yyyy"
                                                                    />
                                                                    {df.isInvalid
                                                                        &&
                                                                        !project[df.id]
                                                                        ? (
                                                                            <p className="error small">
                                                                                Please provide a date or select not
                                                                                applicable.
                                                                            </p>
                                                                        ) : null}
                                                                </Col>
                                                                </Row>
                                                                {/* {index % 2 == 0 ? <br /> : null} */}
    
                                                            </>
                                                        );
                                                    }
                                                   
                                                })}
                                       
                                            {errorMessage ? (
                                                <Container className="text-center mt-4">
                                                    <h5 className="error">
                                                        <strong>{errorMessage}</strong>
                                                    </h5>
                                                </Container>
                                            ) : null}
                                            {/* <div dangerouslySetInnerHTML={{ __html: _.head(codeLabels.ActualDatesGuidelines).Label }}> */}

                                            {/* </div> */}
                                            {/* {!disableforCS && <Button
                                                className="mr-2 justify-content-end float-right"
                                                color="primary"
                                                onClick={() => {
                                                    saveProjectDates();
                                                }}
                                            >
                                                Save {app.recordloading ?
                                                    <Spinner size="small" color="#495057" />
                                                    : null}

                                            </Button>} */}

                                        </CardBody>
                                    </Card>
                                );
                            })}
                        </Col>
                    </Row>
                </div>
                {/* </Collapse> */}

            </div>

            <Modal size="lg" isOpen={isOpen} toggle={() => handleOpen()} centered={true}>
            <ModalHeader toggle={() => cancelOpen()}>
                {/* <img src={logo}  className="modallogo"/> */}
			</ModalHeader>
            <div>
                        <span className="modaltitle modaltitleaudit">
                        Project Delivery Dates History 
                        </span>
                    </div>

            <ModalBody id="scrollModalBody">
            <Table id="advancedSearch">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Modified Date</th>
                                <th>Field Name</th>
                                <th>Modified User</th>
                                <th>Old Value</th>
                                <th>New Value</th>
                            </tr>
                        </thead>
                        <tbody>
                        { auditData.map((obj, index) => (
                                <tr>
                                    <td>{count - index}</td>
                                    <td>{moment(obj.UpdatedDate).format('DD/MM/YYYY h:mm a')}</td>
                                    <td>{obj.FieldName}</td>
                                    <td className="userName">{obj.UpdatedBy}</td>
                                    <td>{obj.OldValue}</td>
                                    {
                                        obj.OldValue !== obj.NewValue ? 
                                        <td className="newValue">{obj.NewValue}</td> : 
                                        <td>{obj.NewValue}</td>
                                    }
                                    
                                </tr>
                            ))}
                        </tbody>
                            </Table>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => cancelOpen()}> Ok </Button>
            </ModalFooter>
        </Modal>                   
        </>
    )
}

export default ProjectDeliveryDates


