import * as types from "../actions/timeCostActions";

const initialState = {
    selectedRole:'',
    inputleveldata:[],
    productData:[]
  };

  const timeCostReducer=(state=initialState,action)=>{
    switch(action.type){
        case types.SAVE_SELECTED_ROLE:
            return{
                ...state,
                selectedRole:action.payload
            };
        case types.SAVE_INPUT_LEVEL:
            return{
                ...state,
                inputleveldata:action.payload
            }
        default:
            return state;
    }
  }

  export default timeCostReducer;