import _ from 'lodash'

/**
 * isCommissioningCountryIsFildingCountry() checked wether current filding country is commissioning country or not.
 * @param  {[object]} project Required project object.
 * @param  {[object]} profile Required profile object.
 * @param  {[number]} indxFC Required CountrySpecs index.
 * @return {[boolean]}      Return boolean value.
 */
export const isCommissioningCountryIsFildingCountry = (project, profile, indxFC) => {
    if (project.CommissioningCountry == profile.CountrySpecs[indxFC].CountryCode) {
        let fieldingCountries = Array.isArray(profile.FieldingCountries) ? profile.FieldingCountries.map(fc => fc.value) : profile.FieldingCountries?.split(",")
        if (_.includes(fieldingCountries, project.CommissioningCountry)) {
            return true
        } else {
            return false
        }
    }
    return true
}