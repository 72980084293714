import React, { useState, useEffect } from "react";
import Spinner from "../../components/Spinner";
import AzureAuthUrl from '../../AuthEndpointSetup/Azure';
import AzureToken, { storageValue } from '../../AuthEndpointSetup/AzureToken';
import AzureUser from '../../AuthEndpointSetup/AzureUser';

import { encryptOrDecryptString} from  '../../utils/azureConstant';

import {
	Button,
	Card,
	CardText,
	CardImg,
	CardBody,
	CardTitle,
	Row,
	Col,
} from "reactstrap";

import banner from "../../assets/img/avatars/nielsen-logo-header-3x1.png";
import image from "../../assets/img/avatars/niq.png";

import * as userActions from "../../redux/actions/userActions";
import { connect } from "react-redux";

import AuthLayout from "../../layouts/Auth";
import useAnalyticsEventTracker from '../../utils/useAnalyticsEventTracker';
import * as EventName from '../../utils/GoogleAnalyticsEvents';

const SignIn = (props) => {
	const gaEventTracker = useAnalyticsEventTracker('SignIn');

	const [reInt, setInt] = useState(false)
	const [isLoad, setLoad] = useState(true)
	const [signIn, setSign] = useState(false)
	const [progress, setprogress] = useState(false)

	const [aError, setErr] = useState('')
	const [attempt, setAttempt] = useState(false)

	let provider = "Microsoft"
	console.log("provider", provider)

	let authToken = sessionStorage.getItem("auth-token")

	let flag = 	authToken 
			? 
				authToken
			:
				false

	useEffect(() => {
		setSign(false)
		azureInitCall()
	}, [])

	useEffect(() => {
		if(reInt) {
			getAuthUrl(provider)
		}
	}, [reInt])

	useEffect(() => {
		if(!props.error?.includes("SUCCESS") && props.error) {
			setprogress(false)
		}
	}, [props.error])


	useEffect(() => {
		if(props.isAuthenticated) {
			// setLoad(true)
			gaEventTracker(EventName.LOGIN);
			window.location.href = "/"
		}
	}, [props.isAuthenticated])

	const azureInitCall = () => {

		if(!sessionStorage.getItem('xyzData') 
		|| 
		!JSON.parse(sessionStorage.getItem('xyzData'))?.logVal
		) {
			setAttempt(true)
			getAuthUrl(provider)
		}

		let locationVal = window.location.href.substring(window.location.href.indexOf("?")+1).split("#")
        let code_url = /((\?|\&)code\=)[^\&]+/.exec("?".concat(locationVal[0]))

		const AzureTokenData =  JSON.parse(sessionStorage.getItem('xyzData'))?.xyzT

        if (code_url != null) {
            let code = decodeURIComponent(String(code_url[0]).replace(/(\?|\&)?code\=/, ''));
            if (AzureTokenData == null) {
                getAccessTokenFromCode(provider, code);
            } else {
				if (!flag) 
					getUserDetails(encryptOrDecryptString(AzureTokenData, 0), provider);
				else 
				setLoad(false)

            }
        } else if(AzureTokenData) {
			if (!flag) 
			getUserDetails(encryptOrDecryptString(AzureTokenData, 0), provider);
			else
				setLoad(false)
		} else {
			setLoad(false)
			setSign(true)
		}
	}
	

	const updateAuthDetails = (AzureTokenData, provider) => {
		console.log('updateAuthDetails updateAuthDetails updateAuthDetails');
		if(AzureTokenData) {
			storageValue("xyzT", encryptOrDecryptString(AzureTokenData, 1))
			getUserDetails(AzureTokenData, provider);
			setprogress(true)
			setLoad(false)
		} else {
			setErr("Sorry, unable to complete authentication. Please try again")
			setLoad(false)
			setSign(true)
		}
    }

    const getAccessTokenFromCode = (provider, code) => {
		console.log('getAccessTokenFromCode');
        switch (provider) {
            case 'Microsoft':
                AzureToken(code).then(AzureTokenData => {
					updateAuthDetails(AzureTokenData, provider)
				})
					.catch(err =>  {
						setLoad(false)
						setErr("Sorry, unable to complete authentication. Please try again")
						setSign(true)
					}
					);
                break;;
            default:
                break;
        }
    }

    const getAuthUrl = (provider) => {
		console.log('getAuthUrl');
		storageValue("logVal", encryptOrDecryptString("true", 1))
		
        switch (provider) {
            case 'Microsoft':
                window.location.href = AzureAuthUrl;
                break;
            default:
                break;
        }
    }

	const getUserDetails = (AzureTokenData, provider) => {
        switch (provider) {
            case 'Microsoft':
                AzureUser(AzureTokenData).then(userDetails => updateUserDetails(userDetails)).catch(err => console.log("dataissue",err));
                break;
            default:
                break;
        }
    }
    const updateUserDetails = (userDetails) => {
		if(userDetails?.error) {
			sessionStorage.clear()
			setInt(true)
			setLoad(false)
			setAttempt(true)
			setprogress(false)
		} else {

			storageValue("userObj", userDetails)
			props.onAuth(userDetails);
			setLoad(false)
		}
    }


	let errorMessage = null;
	if (props.error) {
		errorMessage = props.error;
	}

	let statusCode = null;
	if (props.statusCode) {
		statusCode = props.statusCode;
	}


	let userDetails = sessionStorage.getItem('xyzData') 
		? 
			JSON.parse(sessionStorage.getItem('xyzData'))
		:
			null


	let  disabledtxt =  statusCode === 	401  ? true : false



	const loginpage = () => {
		return (
			<AuthLayout>
				<Card>
					<CardImg
						variant="top"
						src={image}
						className="mx-auto img-responsive"
						style = {{
							backgroundColor: "#000",
							width:"70%",marginTop:"5%"
							}}
					/>
					<CardBody>
						{userDetails?.name &&
							<div className="m-sm-4">
								<h1 className="h3 text-center" style=
									{{
										color: "#2D6DF6"
									}}
								>
									Hi {userDetails?.name} 
								</h1>
								<p className="text-center">
									{userDetails?.email}
								</p>
							</div>
						}
						{!signIn &&
							<CardTitle className="text-center  mt-4">
								<h1 className="h3" >Welcome to NielsenIQ CINTRAL</h1>
							</CardTitle>
						}

						<div className={(signIn && !attempt)  ?  "" : "m-sm-4"}>
							<div>

								{((
									(
									errorMessage && 
									!(errorMessage.includes("blocked") && statusCode === 555)
									)
									|| 
									aError
								) && statusCode!==500 && !progress && !disabledtxt) ?
										<p className={aError  ? "text-center text-danger" : "text-center"}>
											{aError ? 
													aError
												: 

												statusCode === 	404 
												?
												
													`You have not registered with the 
													CINTRAL for access with 
													your email ID. 
													Please click "Request Account" 
													for the registration.​`

												:

												statusCode === 	409 
												?
													"Your request for account is in review state, admin will let you know once completed."
												:

												statusCode === 	555  
												?
													errorMessage 
												:
													""
											}

										</p>
									:

									(statusCode === 401 || statusCode === 500 || statusCode === 555)
									?
										<p className="text-center">
											{statusCode === 401 
											? 
												 errorMessage 
											: 
												statusCode === 500 
											?
												"Failed to complete sign-in. Please retry, if problem persists"
											:
												errorMessage
											}{" "}
											 please contact 
												{<a
													className="select-link ml-1 support"
													onClick={
														() => 
													window.open
													(process.env.REACT_APP_SUPPORT_FORM)
													}
												>here.
												</a>}

										</p>
									:
									
									(signIn && !attempt)  ?

										<p 	style={{fontSize:"20px"}} className="text-center signinTxt">
												Please sign in to continue.
										</p>
									:
										(!sessionStorage.getItem('xyzData') 
										|| 
										!JSON.parse(sessionStorage.getItem('xyzData'))?.logVal
										|| attempt
										)  ? 

											<p className="text-center">
												 Initiating SSO authentication...
											</p>
									: 
											 	
										progress ? 
											<p className="text-center">
												CINTRAL sign-in is in progress, please wait.. 
											</p>
										:
											""
								}

							</div>
							{console.log(process.env.REACT_APP_REDIRECT_URI.split(".")[process.env.REACT_APP_REDIRECT_URI.split(".").length-1].includes( "cn"))}
							{process.env.REACT_APP_REDIRECT_URI.split(".")[process.env.REACT_APP_REDIRECT_URI.split(".").length-1].includes( "cn") ?
							<span>粤ICP备2021056281号</span>
						:null
						}
							
							{(((errorMessage)  || signIn) && !attempt && !progress && !disabledtxt && statusCode !== 409 && statusCode !== 555 ) &&								
								<Button
									style={{ float: "right",background : "#060A45",color :"white", hover:"red"}}
									color="black"
									size="lg"
									onClick={
											errorMessage  && statusCode === 404 
										?

											()=>  {props.history.push("/auth/register"); gaEventTracker(EventName.Register)}
										:
											() => {
												setLoad(true);
												setInt(true)
										}
									}
								>
									{errorMessage  && statusCode === 404
										?
											"Request Account"
										:
											"Sign In with NielsenIQ"
									}
								</Button>
							}
							
						</div>
					</CardBody>
				</Card>
			</AuthLayout>
		);
	};

	if ( isLoad ) {
		return (
			<AuthLayout>
				<Spinner parentStyles={{paddingTop: 0}} />
			</AuthLayout>
		);
	} else {
		return loginpage();
	}
};

const mapStateToProps = (state) => {
	return {
		isLogIn: state.user.isLogIn,
		loading: state.user.loading,
		error: state.user.error,
		statusCode: state.user.statusCode,
		isAuthenticated: state.user.authToken !== null,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		onAuth: (userData) =>
			dispatch(userActions.auth(userData)),
		toggleLogIn: () => dispatch({ type: userActions.TOGGLE_LOGIN }),
		updateAuth: (field, value) =>
			dispatch({ type: userActions.UPDATE_AUTH, field: field, value: value }),
		onRegister: (registerData) => {
			dispatch(userActions.register(registerData));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
