export const SAVE_SELECTED_ROLE = "SAVE_SELECTED_ROLE";
export const SAVE_INPUT_LEVEL="SAVE_INPUT_LEVEL"




export function handleRoleSelection(selectedrole) {
    return {
      type: SAVE_SELECTED_ROLE,
      payload:selectedrole
    };
  }

  export function saveinputlevel(selectedrole) {
    return {
      type: SAVE_INPUT_LEVEL,
      payload:selectedrole
    };
  }