import * as rateCardsActions from "./rateCardsActions";
import axios from "../../axios-interceptor";
import * as userActions from "./userActions";
import { toastr } from "react-redux-toastr";

export const SET_CODELABELS = "SET_CODELABELS";
export const SET_SEARCHBY = "SET_SEARCHBY";
export const UPDATE_CODELABELS = "UPDATE_CODELABELS";

let errorMessageShown = false;

export const resetErrorFlag = () => {
  errorMessageShown = false;
};

export const getCodeLabels = () => {
  return (dispatch, getState) => {
    axios
      .get("/init")
      .then((res) => {
        dispatch({
          type: SET_CODELABELS,
          InitLabels: res.data.initialState,
        });
        dispatch(rateCardsActions.setRateCards(res.data.rateCards));
        const token = localStorage.getItem("auth-token");
        const userRecord = JSON.parse(localStorage.getItem("userRecord"));
        dispatch(userActions.authSuccess(token, userRecord));
      })
      .catch((err) => {
        dispatch(userActions.authFail());
      });
  };
};

export const updateCodeLabels = (codeLabels) => {
  return (dispatch, getState) => {
    dispatch({
      type: SET_CODELABELS,
      InitLabels: codeLabels,
    });
  }
}

export const getCodeLabelResponse = () => {
  return (dispatch, getState) => {
    axios
      .get("/init/code-labels")
      .then((res) => {
        dispatch({
          type: UPDATE_CODELABELS,
          InitLabels: res.data.initialState,
        });
      })
      .catch((err) => {
        handleErrorResponse(err, dispatch);         
      });
  };
};

export const getDashBoardResponse = () => {
  return (dispatch, getState) => {
    axios
      .get("/init/dashboard")
      .then((res) => {
        dispatch({
          type: UPDATE_CODELABELS,
          InitLabels: res.data.initialState,
        });
        dispatch(userActions.setInitDataStatus(false));
        const token = localStorage.getItem("auth-token");
        const userRecord = JSON.parse(localStorage.getItem("userRecord"));
        dispatch(userActions.authSuccess(token, userRecord));        
      })
      .catch((err) => {
        if(err.status !== 401) { 
          dispatch(userActions.setInitDataStatus(true));
          const token = localStorage.getItem("auth-token");
          const userRecord = JSON.parse(localStorage.getItem("userRecord"));
          dispatch(userActions.authSuccess(token, userRecord));          
        }
        handleErrorResponse(err, dispatch); 
      });
  };
};

export const getCountriesResponse = () => {
  return (dispatch, getState) => {
    axios
      .get("/countries")
      .then((res) => {
        dispatch({
          type: UPDATE_CODELABELS,
          InitLabels: res.data.initialState,
        });
      })
      .catch((err) => {
        handleErrorResponse(err, dispatch);        
      });
  };
};

export const getRateCardsResponse = () => {
  return (dispatch, getState) => {
    axios
      .get("/rate-cards")
      .then((res) => {
        dispatch(rateCardsActions.setRateCards(res.data.rateCards));
      })
      .catch((err) => {
        handleErrorResponse(err, dispatch);         
      });
  };  
};

const handleErrorResponse = (err, dispatch) => {
  if(!errorMessageShown) {
    toastr.error(err.data.message);
    errorMessageShown = true;
  }        
  if(err.status === 401) {        
    dispatch(userActions.authFail());
  } 
};
