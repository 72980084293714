import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader,Row,Col } from "reactstrap";
import logo from "../../assets/img/imagesVM/logo.png"; 


const EwnNotesModal = ({ isOpen, NotesFields,currentEwn }) => {
    const [isModalOpen, setModalOpen] = useState(false);
    useEffect(() => {
        setModalOpen(isOpen);
    }, [isOpen]);
    var imgStyle = {
        width: "150px",
      };
    return (
        <React.Fragment>
            {
                (isModalOpen) ?
                <Modal
                isOpen={isOpen}
                className="modal-sm"
                centered={true}>
                <ModalHeader>
                {/* <img src={logo}  className="modallogo"/> */}
               </ModalHeader>
                <Row>
                        <Col>
                            <span className="modaltitle">EWN Notes</span>
                        </Col>
                    </Row>
                <ModalBody>
                    <div>
                        {NotesFields.map((nf) => (
                            <div>
                                <label>{nf.label}</label>
                                <br></br>
                                <textarea
                                    id={nf.value}
                                    className="form-control"
                                    defaultValue={currentEwn[nf.value]}
                                    disabled={true}
                                ></textarea>
                            </div>
                        ))}
                    </div>
                </ModalBody>
            </Modal>
                    : ''
            }
        </React.Fragment>
    )
}
export default EwnNotesModal;