import React, { useState, useEffect } from "react"
//import axios from 'axios';
import { useHistory, useLocation } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { useDispatch } from 'react-redux';
// import { pageLoadStart, pageLoadEnd } from "../../../redux/actions/appActions";
// import VendorBiddingview from "./VendorBiddingview"
// import axios from "../../../axios-interceptor";
// import { SIDEBAR_VISIBILITY_SHOW } from "../../../redux/constants";
import VendorBiddingviewUpdated from "./VendorBiddingviewUpdated";
const jwt = require("jsonwebtoken");
const REACT_APP_JWT_SECRET = process.env.REACT_APP_JWT_SECRET ? process.env.REACT_APP_JWT_SECRET : process.env.REACT_APP_JWT_SECRET_DEFAULT

const VendorBiddingViewBase = (props) => {
    const search = useLocation().search;
    const history = useHistory();
    const query = new URLSearchParams(search).get("q");

    const dispatch = useDispatch();
    const [projectId, setProjectId] = useState(null);
    const [show, setShow] = useState(false);
    const [vendorId, setVendorId] = useState(null);
    const [VendorName, setVendorName] = useState(null);
    const [costingProfileId, setCostingProfileId] = useState(null);

    useEffect(() => {
        jwt.verify(query.toString(), REACT_APP_JWT_SECRET, (error, data) => {
            if (error) {
                toastr.error("Invalid token or token got expired !!!");
                history.replace({ pathname: 'unauthorised', isActive: true });
            } else {
                setProjectId(data.data.projectId);
                setVendorId(data.data.vendorId);
                setVendorName(data.data.vendorName);
                setCostingProfileId(data.data.costingProfileId);
                setVendorName(data.data.vendorName);
                localStorage.setItem('auth-token', query.toString());
                setShow(true);
            }
        });
    }, [query]);

    return (
        show &&
        <VendorBiddingviewUpdated vendorId={vendorId} projectId={projectId} Name={VendorName} costingProfileId={costingProfileId}  token={query}></VendorBiddingviewUpdated>
    )
}

export default VendorBiddingViewBase;