import React from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
} from "reactstrap";
import classnames from "classnames";

const InfoModal = ({
  infoModal,
  infoModalToggle,
  activityMapping,
  currentActiveTab,
  toggleTabPane
}) => {
  return (
    <Modal isOpen={infoModal} toggle={infoModalToggle} size="lg">
      <ModalHeader toggle={infoModalToggle}>Activities Details</ModalHeader>
      <ModalBody>
        {activityMapping.length === 2 ? (
          <div>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: currentActiveTab === "1",
                  })}
                  onClick={() => {
                    toggleTabPane("1");
                  }}
                  style={{ fontWeight: "800", padding: "15px" }}
                >
                  Commercial
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: currentActiveTab === "2",
                  })}
                  onClick={() => {
                    toggleTabPane("2");
                  }}
                  style={{ fontWeight: "800", padding: "15px" }}
                >
                  Operations
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={currentActiveTab}>
              <TabPane tabId="1">
                <Table
                  responsive
                  hover
                  striped
                  bordered
                  style={{ margin: "25px 0" }}
                >
                  <thead>
                    <tr>
                      <td style={{ fontWeight: "800" }}>Old Activity name</td>
                      <td style={{ fontWeight: "800" }}>New Activity name</td>
                    </tr>
                  </thead>
                  <tbody>
                    {activityMapping[0].map((i) => {
                      return (
                        <tr>
                          <td>{i.old_activity_name}</td>
                          <td>{i.new_activity_name}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </TabPane>
              <TabPane tabId="2">
                <Table
                  responsive
                  hover
                  striped
                  bordered
                  style={{ margin: "25px 0" }}
                >
                  <thead>
                    <tr>
                      <td style={{ fontWeight: "800" }}>Old Activity name</td>
                      <td style={{ fontWeight: "800" }}>New Activity name</td>
                    </tr>
                  </thead>
                  <tbody>
                    {activityMapping[1].map((i) => {
                      return (
                        <tr>
                          <td>{i.old_activity_name}</td>
                          <td>{i.new_activity_name}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </TabPane>
            </TabContent>
          </div>
        ) : null}
      </ModalBody>
    </Modal>
  );
};

export default InfoModal;