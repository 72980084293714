import React, { useEffect, useState } from "react";
import {
  pageLoadEnd,
  pageLoadStart,
  recordLoadEnd,
  recordLoadStart,
} from "../../../redux/actions/appActions";
// import axios from "../../src/axios-interceptor";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import logo from "./../../../assets/img/avatars/modal-logo.png";

import { toastr } from "react-redux-toastr";
import classnames from "classnames";
import Spinner from "../../../components/Spinner";
import _, { cloneDeep } from "lodash";
import update from "immutability-helper";
import {
  Input,
  FormGroup,
  Modal,
  Nav,
  NavItem,
  NavLink,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  ModalFooter,
  Button,
  Label,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import Table from "reactstrap/lib/Table";
import { cancel } from "redux-saga/effects";
import { setMainConfig } from "react-doc-viewer/build/state/actions";
import axios from "../../../axios-interceptor";
import * as currentProjectActions from "../../../redux/actions/currentProjectActions";
// import { NavLink } from "react-router-dom";
import {
  faCalendarAlt,
  faCopy,
  faRefresh,
  faChevronCircleRight,
  faTimesCircle,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { proposalListRedirectToEdit } from "../../../redux/actions/proposal/Actions";
import { parse } from "postcss";
import * as proposalActions from "../../../redux/actions/proposal/Actions";
import { data } from "jquery";
import Project from "../../../layouts/Project";

function VbmCopyToallMarket({ MarketCopy, setMarketCopy, vendorId }) {
  const dispatch = useDispatch();

  const userRecord = useSelector(({ user }) => user.userRecord);

  const currentCostingProfile = useSelector(
    ({ currentCosting }) => currentCosting.currentCostingProfile
  );
  const projects = useSelector(({ projects }) => projects.items);
  let vendorreducer = useSelector(({ vendorReducer }) => vendorReducer);
  const app = useSelector(({ app }) => app);
  const cancelPopUp = () => {
    setMarketCopy(!MarketCopy);
    setOnSubmit(false)
  };
  const splitdata = useSelector(
    ({ vendorReducer }) => vendorReducer.SplitData
  )
  var project = useSelector(({ currentProject }) => currentProject.newProject);
  const codeLabels = useSelector(({ codeLabels }) => codeLabels);
  const [allMarketCopying, setAllMarketCopying] = useState({});
  const [onSubmit, setOnSubmit] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([
    { value: 1, label: "RFQ" },
    { value: 2, label: "SUD" },
    { value: 3, label: "Bid Details" },
    { value: 4, label: "Vendors" },
  ]);

  useEffect(() => { }, []);

  function ListForCountries() {
    var datavalue = _.last(project?.CostingProfiles)?.CountrySpecs?.filter(
      (c) =>
        allMarketCopying.SelectedFromCountries?.label !=
        c.CountryName
    ).map(
      (fc) =>
        fc.MethodologySpecs.filter(
          (sm) => sm.CostingType == 3 && !sm.NotApplicable && sm?.InvitedVendors?.filter(
            (v) => (v.VendorId == vendorId && !(v.IsDeclined))
          ).length > 0
        ).length > 0 && {
          label: fc.CountryName,
          value: fc.id,
        }
    )
    return datavalue
  }
  function ListForMethodlogies() {
    let datavalue = []
    _.last(project?.CostingProfiles)?.CountrySpecs?.filter(
      (c) =>
        allMarketCopying.SelectedFromCountries?.label ==
        c.CountryName
    ).forEach(
      (fc) =>
        fc.MethodologySpecs.filter(
          (sm) => {
            if (sm.CostingType == 3 && !sm.NotApplicable && sm?.InvitedVendors?.filter(
              (v) => (v.VendorId == vendorId && !(v.IsDeclined))
            ).length > 0) {
              datavalue.push({
                label: sm.Label,
                value: sm.id,
              })
            }
          }
        )
    )
    return datavalue
  }
  function ListForMethodlogiesList(val) {
    let datavalue = []
    _.last(project?.CostingProfiles)?.CountrySpecs?.filter(
      (c) =>
        val.label ==
        c.CountryName
    ).forEach(
      (fc) =>
        fc.MethodologySpecs.filter(
          (sm) => {
            if (sm.CostingType == 3 && !sm.NotApplicable && sm?.InvitedVendors?.filter(
              (v) => v.VendorId == vendorId
            ).length > 0) {
              datavalue.push({
                label: sm.Label,
                value: sm.id,
              })
            }
          }
        )
    )
    return datavalue
  }
  function returnTargetSample(coheader, vendor) {
    let list;
    let val = splitdata.filter(a => a.id)?.filter(a => a.id == vendor.VendorCosts.filter(v => v.CostOptionId == coheader)[0]?.RoundId)
    if (val.length > 0) {
      if (vendor.VendorCosts.filter(v => v.CostOptionId == coheader)[0]?.SampleDistributionDetailsId) {
        list = val[0]?.SampleDistributionMasters?.filter(a => a.CostingOptionId == coheader)[0]?.SampleDistributionDetails?.map(a => {
          return {
            label: a.DistributionName,
            value: a.id,
            DistributionSize: a.DistributionSize
          }
        }).filter(l => l.value == vendor.VendorCosts.filter(v => v.CostOptionId == coheader)[0]?.SampleDistributionDetailsId)[0]?.DistributionSize
      }
      else {
        list = val[0]?.SampleDistributionMasters?.filter(a => a.CostingOptionId == coheader)[0].TargetSamples
      }
    }
    return list
  }
  function copy() {
    var data = _.cloneDeep(_.last(project.CostingProfiles));
    var dataToCopy = _.cloneDeep(_.last(project.CostingProfiles).CountrySpecs.filter(a => a.id == allMarketCopying?.SelectedFromCountries?.value)[0]);
    data?.CountrySpecs.forEach(a => {
      if (allMarketCopying?.SelectedToCountries?.map(a => a.value)?.includes(a.id)) {
        a.MethodologySpecs.forEach(sm => {
          if (allMarketCopying?.SelectedFromMethodologies?.map(a => a.label)?.includes(sm.Label) && sm.CostingType == 3 && sm?.InvitedVendors?.filter(a => a.VendorId == vendorId)?.length > 0) {
            sm.InvitedVendors.forEach(a => {
              if (a.VendorId == vendorId) {
                if (!(a.IsDeclined)) {
                  let vendordata = dataToCopy?.MethodologySpecs?.find(a => a.Label == sm.Label)?.InvitedVendors?.find(v => v.VendorId == vendorId)
                  vendordata?.SelectedRows?.filter(d => d.OtherCost)?.forEach(c => {
                    a.SelectedRows.forEach(vs => {
                      if (vs.id == c.id) {
                        vs["OtherCost"] = c.OtherCost
                      }
                    })
                  })
                  vendordata.VendorCosts.forEach((m, i) => {
                    if ((a?.VendorCosts?.length - 1) >= i) {
                      let data = []
                      m.CostingsData.forEach(c => {
                        if (a.SelectedRows.filter(a => a.id == c.id)[0]?.selected)
                          data.push(c)
                      })
                      a.VendorCosts[i].CostingsData = data;

                      if (m.AchivableSamples <= (returnTargetSample(a.VendorCosts[i].CostOptionId, a)))
                        a.VendorCosts[i].AchivableSamples = m.AchivableSamples
                    }
                  })
                }
              }
            })
          }
        }
        )
      }
    })

    project.CostingProfiles[project.CostingProfiles.length - 1] = data
    dispatch({
      type: currentProjectActions.UPDATE_NEW_PROJECT,
      newProject: project,
    });

    toastr.success("Data copied successfully")
    cancelPopUp()
  }

  var imgStyle = {
    width: "150px",
  };
  var roundclosedstyle = {
    color: "#b94e48",
    marginBottom: "5px",
  };
  var ModalFooterStyle = {
    display: "flex",
    justifyContent: "flex-Start",
  };

  return (
    <>

      <Modal
        isOpen={MarketCopy}
        backdrop="static"
        toggle={cancelPopUp}
        centered={true}
        size="lg"
      >
        <ModalHeader toggle={cancelPopUp}>
          {/* <img src={logo} className="modallogo" /> */}
          <h1> </h1>
          <h3>Copy Accross Markets</h3>
        </ModalHeader>

        <ModalBody>
          {allMarketCopying.SelectedFromCountries && <h6>{`Copying ${allMarketCopying.SelectedFromCountries.label} Methodology data to other applicable methodologies.`}</h6>}
          <Row>
            <Col>
              <FormGroup>
                <Label>Select Source Country</Label>
                <sup>(</sup>
                <sup className="requiredField">*</sup>
                <sup>)</sup>

                <Select
                  name={"selectCountriesFrom"}
                  // value={project?.CostingProfiles[project.CostingProfiles.length - 1].CountrySpecs}
                  className="react-select-container"
                  classNamePrefix="react-select"
                  menuPortalTarget={document.querySelector("modal-body")}
                  menuPosition={"fixed"}
                  options={ListForCountries("from")}
                  // isMulti
                  onChange={(val) => {
                    setAllMarketCopying((prevState) => ({
                      ...prevState,
                      SelectedFromCountries: val,
                      SelectedToCountries: allMarketCopying.SelectedToCountries?.length > 0
                        ? prevState.SelectedToCountries.filter(
                          (a) => a.label != val.label
                        )
                        : [],
                      SelectedFromMethodologies: ListForMethodlogiesList(val),
                    }));
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          {allMarketCopying.SelectedFromCountries && (
            <Row>
              <Col>
                <FormGroup>
                  <Label>Select Destination Countries</Label>
                  <sup>(</sup>
                  <sup className="requiredField">*</sup>
                  <sup>)</sup>
                  <Select
                    name={"selectCountriesTo"}
                    value={
                      allMarketCopying.SelectedToCountries?.length > 0
                        ? allMarketCopying.SelectedToCountries
                        : []
                    }
                    className="react-select-container"
                    classNamePrefix="react-select"
                    menuPortalTarget={document.querySelector("modal-body")}
                    menuPosition={"fixed"}
                    options={ListForCountries("to")}
                    isMulti
                    onChange={(val) => {
                      setAllMarketCopying((prevState) => ({
                        ...prevState,
                        SelectedToCountries: val,
                      }));
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
          )}
          {allMarketCopying.SelectedFromCountries &&
            allMarketCopying.SelectedToCountries?.length > 0 && (
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Select Methodologies</Label>
                    <sup>(</sup>
                    <sup className="requiredField">*</sup>
                    <sup>)</sup>
                    <Select
                      name={"selectMethodologies"}
                      // value={project?.CostingProfiles[project.CostingProfiles.length - 1].CountrySpecs}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      menuPortalTarget={document.querySelector("modal-body")}
                      menuPosition={"fixed"}
                      options={ListForMethodlogies()}
                      isMulti
                      value={allMarketCopying.SelectedFromMethodologies}
                      onChange={(val) => {
                        setAllMarketCopying((prevState) => ({
                          ...prevState,
                          SelectedFromMethodologies: val,
                        }));
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            )}
          <Row>
            <Col>
              <Label>
                {" "}
                <strong>Note:</strong>
                1. Copying the information may change the
                data in selected markets.<br />
                2.This feature will only copy cost and achievable sample size.
              </Label>
            </Col>
          </Row>

        </ModalBody>
        <ModalFooter style={ModalFooterStyle}>
          {_.last(project.CostingProfiles)?.VenodrBidDeadLineStatus !=
            "close" && (
              <Button
                color="primary"
                onClick={() => {
                  (!allMarketCopying.SelectedFromCountries ||
                    !allMarketCopying.SelectedToCountries?.length ||
                    !allMarketCopying.SelectedFromMethodologies?.length)
                    ? toastr.error(
                      "Please complete selections in Mandatory fields!"
                    )
                    : setOnSubmit(true)
                }
                }
                disabled={app.recordloading}
              >
                Submit
                {app.recordloading && <Spinner size="small" color="#495057" />}
              </Button>
            )}
          <Button
            color="secondary"
            onClick={cancelPopUp}
            disabled={app.recordloading}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={onSubmit}
        backdrop="static"
        toggle={cancelPopUp}
        centered={true}
        size="sm"
      >
        <ModalHeader toggle={cancelPopUp}>
          {/* <img src={logo} className="modallogo" /> */}
          <h1> </h1>
          <h3>Confirm Copying Data</h3>
        </ModalHeader>
        <ModalBody style={{ minHeight: "60px" }}>
          <Label>{`You are about to copy the data to other methodologies.
               This action is irreversible.Are you sure want to proceed with copying data?`}</Label>
        </ModalBody>
        <ModalFooter style={ModalFooterStyle}>
          {_.last(project.CostingProfiles)?.VenodrBidDeadLineStatus !=
            "close" && (
              <Button
                color="primary"
                onClick={() => copy()}
                disabled={app.recordloading}
              >
                Yes
                {app.recordloading && (
                  <Spinner size="small" color="#495057" />
                )}
              </Button>
            )}
          <Button
            color="secondary"
            onClick={cancelPopUp}
            disabled={app.recordloading}
          >
            No
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default VbmCopyToallMarket;
