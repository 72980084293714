
export const MSD_SALES_FORCE = "MSD_SALES_FORCE";
export const MSD_SALES_FORCE_DONE = "MSD_SALES_FORCE_DONE";

const initialState = {  
    pageloaded: false, 
    msdErrVal: false
}

export default function msdSalesForceReducer(state = initialState, action)
{
    switch (action.type)
    {
        case MSD_SALES_FORCE:
            return {
                ...state,
                pageloaded : true
            }

        case MSD_SALES_FORCE_DONE:
            {      
                if (action.errVal) {
                    return {
                        ...state,
                        pageloaded: false,
                        msdErrVal: true
                    }

                }         
                return {
                    ...state,
                    msdData: action.payload.opportunity,
                }
            }

        default: return state
    }

}