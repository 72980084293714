import * as types from "../actions/codeLabelActions";
import update from "immutability-helper";

const initialState = {

  SearchBy: [],

  SearchProjectsBy: [
    { Code: "ID", Label: "Project ID" },
    { Code: "NAME", Label: "Project Name" },
    { Code: "PM", Label: "Project Manager" },
    { Code: "PO", Label: "Project Owner" },
    { Code: "OP", Label: "OP Number" },
  ],

  SearchUsersBy: [
    { Code: "EMAIL", Label: "Email ID" },
    { Code: "ID", Label: "User ID" },
    { Code: "COUNTRY", Label: "Country" },
    { Code: "BU", Label: "Business Unit" }
  ],

  // Note: have a flat static Label for all Labels
  // StaticLabels: [
  //   { Label: "New Zealand", Code: "NZ" },
  //   { Label: "Australia", Code: "AU" },
  //   { Label: "Sydney", Code: "SYD" },
  //   { Label: "Melbourne", Code: "MEL" },
  //   { Label: "Auckland", Code: "AKL" },
  //   { Label: "Wellington", Code: "WGT" },
  //   { Label: "Home Scan or Retail", Code: "HSOR" },
  //   { Label: "Consumer Insights", Code: "CI" },
  //   { Label: "Fast Moving Consumer Goods (FMCG)", Code: "IV1" },
  //   { Label: "Telecom, Technology, Auto, Finance (TTA&F)", Code: "IV2" },
  //   { Label: "Global E-tailers (REV)", Code: "IV3" },
  //   { Label: "Public Non-Profit and Expanded Verticals (PNP&EV)", Code: "IV4" },
  //   { Label: "Global Strategic Alliances and New Verticals", Code: "IV5" },
  // ],
  // UserScopeOptions: [],
  // MethodologyOptions: [],
  // ProjectStatus: [
  //   { Code: "1", Label: "Proposal" },
  //   { Code: "6", Label: "Negotiating" },
  //   { Code: "2", Label: "Cancelled" },
  //   { Code: "3", Label: "Commissioned" },
  //   { Code: "30", Label: "Commissioned (Recost)" },
  //   { Code: "4", Label: "Completed" },
  //   { Code: "5", Label: "Archived" },
  // ],
  // SearchBy: [
  //   { Code: "ID", Label: "Project ID" },
  //   { Code: "NAME", Label: "Project Name" },
  //   { Code: "PM", Label: "Project Manager" },
  //   { Code: "PO", Label: "Proposal Owner" },
  //   { Code: "OP", Label: "OP Number" },
  // ],
  // CountryScopeOptions: [
  //   {
  //     Label: "Australia",
  //     Code: "AU",
  //     businessUnits: [
  //       {
  //         Label: "Consumer Insights",
  //         Code: "CI",
  //         industryVertical: [
  //           {
  //             Label: "Fast Moving",
  //             Code: "FMCG",
  //           },
  //         ],
  //       },
  //       {
  //         Label: "Home Scan or Retail",
  //         Code: "HSOR",
  //         industryVertical: [
  //           {
  //             Label: "Global E-tailers (REV)",
  //             Code: "REV",
  //           },
  //         ],
  //       },
  //     ],
  //     commissioningOffices: [
  //       {
  //         Label: "Sydney",
  //         Code: "SYD",
  //       },
  //       {
  //         Label: "Melbourne",
  //         Code: "MEL",
  //       },
  //     ],
  //   },
  //   {
  //     Label: "New Zealand",
  //     Code: "NZ",
  //     businessUnits: [
  //       {
  //         Label: "Consumer Insights",
  //         Code: "CI",
  //         industryVertical: [
  //           {
  //             Label: "Fast Moving",
  //             Code: "FMCG",
  //           },
  //         ],
  //       },
  //     ],
  //     commissioningOffices: [
  //       {
  //         Label: "Auckland",
  //         Code: "AKL",
  //       },
  //       {
  //         Label: "Wellington",
  //         Code: "WGT",
  //       },
  //     ],
  //   },
  // ],
  // TrackingFrequencyOptions: [
  //   { Code: "1", Label: "Ongoing" },
  //   { Code: "2", Label: "Weekly" },
  //   { Code: "3", Label: "Fortnightly" },
  //   { Code: "4", Label: "Monthly" },
  //   { Code: "5", Label: "Quarterly" },
  //   { Code: "6", Label: "Bi-annually" },
  //   { Code: "7", Label: "Annually" },
  //   { Code: "8", Label: "Pre and Post (Nielsen Sports)" },
  // ],
  // MethodologyOptions: [
  //   { Code: "FM000040", Label: "ONLINE QUANTITATIVE" },
  //   { Code: "FM000011", Label: "ONLINE QUALITATIVE" },
  //   { Code: "FM000036", Label: "ONLINE CLT SELF ADMINISTRATED" },
  //   { Code: "FM000001", Label: "OFFLINE CENTRAL LOCATION" },
  //   { Code: "FM000002", Label: "OFFLINE - DOOR TO DOOR / OTHERS" },
  //   { Code: "FM000004", Label: "OFFLINE - QUALITATIVE FOCUS GROUPS" },
  //   { Code: "FM000005", Label: "OFFLINE - QUALITATIVE IN DEPTH INTERVIEWS" },
  //   { Code: "FM000010", Label: "OFFLINE - TELEPHONE" },
  //   { Code: "FM000009", Label: "OFFLINE - QUALITATIVE OTHERS" },
  //   { Code: "FM000006", Label: "CLIENT DATABASE - MAIL" },
  //   { Code: "FM000019", Label: "SEQUENTIAL MIXED METHODOLOGY" },
  //   { Code: "FM000007", Label: "MYSTERY SHOPPER" },
  //   { Code: "FM000041", Label: "EYE TRACKING" },
  //   { Code: "FM000043", Label: "VIRTUAL STORE" },
  //   { Code: "FM000047", Label: "SMARTSTORE" },
  //   { Code: "FM000048", Label: "PATH TRACKING" },
  //   { Code: "FM000012", Label: "NO FIELDWORK - REPORTING ONLY" },
  //   { Code: "FM000008", Label: "OTHER" },
  // ],
  // FieldingCountriesOptions: [
  //   { Code: "AF", Label: "Afghanistan" },
  //   { Code: "AL", Label: "Albania" },
  //   { Code: "DZ", Label: "Algeria" },
  //   { Code: "AD", Label: "Andorra" },
  //   { Code: "AO", Label: "Angola" },
  //   { Code: "AG", Label: "Antigua and Barbuda" },
  //   { Code: "AR", Label: "Argentina" },
  //   { Code: "AM", Label: "Armenia" },
  //   { Code: "AU", Label: "Australia" },
  //   { Code: "AT", Label: "Austria" },
  //   { Code: "AZ", Label: "Azerbaijan" },
  //   { Code: "BS", Label: "The Bahamas" },
  //   { Code: "BH", Label: "Bahrain" },
  //   { Code: "BD", Label: "Bangladesh" },
  //   { Code: "BB", Label: "Barbados" },
  //   { Code: "BY", Label: "Belarus" },
  //   { Code: "BE", Label: "Belgium" },
  //   { Code: "BZ", Label: "Belize" },
  //   { Code: "BJ", Label: "Benin" },
  //   { Code: "BT", Label: "Bhutan" },
  //   { Code: "BO", Label: "Bolivia" },
  //   { Code: "BA", Label: "Bosnia" },
  //   { Code: "BW", Label: "Botswana" },
  //   { Code: "BR", Label: "Brazil" },
  //   { Code: "BN", Label: "Brunei" },
  //   { Code: "BG", Label: "Bulgaria" },
  //   { Code: "BF", Label: "Burkina Faso" },
  //   { Code: "BI", Label: "Burundi" },
  //   { Code: "KH", Label: "Cambodia" },
  //   { Code: "CM", Label: "Cameroon" },
  //   { Code: "CA", Label: "Canada" },
  //   { Code: "CV", Label: "Cape Verde" },
  //   { Code: "CF", Label: "Central African Republic" },
  //   { Code: "TD", Label: "Chad" },
  //   { Code: "CL", Label: "Chile" },
  //   { Code: "CN", Label: "China" },
  //   { Code: "CO", Label: "Colombia" },
  //   { Code: "KM", Label: "Comoros" },
  //   { Code: "CD", Label: "Democratic Republic of the Congo" },
  //   { Code: "CG", Label: "Republic of the Congo" },
  //   { Code: "CR", Label: "Costa Rica" },
  //   { Code: "HR", Label: "Croatia" },
  //   { Code: "CU", Label: "Cuba" },
  //   { Code: "CY", Label: "Cyprus" },
  //   { Code: "CZ", Label: "Czech Republic" },
  //   { Code: "DK", Label: "Denmark" },
  //   { Code: "DJ", Label: "Djibouti" },
  //   { Code: "DO", Label: "Dominican Republic" },
  //   { Code: "TL", Label: "East Timor" },
  //   { Code: "EC", Label: "Ecuador" },
  //   { Code: "EG", Label: "Egypt" },
  //   { Code: "SV", Label: "El Salvador" },
  //   { Code: "GQ", Label: "Equatorial Guinea" },
  //   { Code: "ER", Label: "Eritrea" },
  //   { Code: "EE", Label: "Estonia" },
  //   { Code: "ET", Label: "Ethiopia" },
  //   { Code: "FJ", Label: "Fiji" },
  //   { Code: "FI", Label: "Finland" },
  //   { Code: "FR", Label: "France" },
  //   { Code: "GA", Label: "Gabon" },
  //   { Code: "GM", Label: "Gambia" },
  //   { Code: "GE", Label: "Georgia" },
  //   { Code: "DE", Label: "Germany" },
  //   { Code: "GH", Label: "Ghana" },
  //   { Code: "GR", Label: "Greece" },
  //   { Code: "GD", Label: "Grenada" },
  //   { Code: "GT", Label: "Guatemala" },
  //   { Code: "GN", Label: "Guinea" },
  //   { Code: "GW", Label: "Guinea-Bissau" },
  //   { Code: "GY", Label: "Guyana" },
  //   { Code: "HT", Label: "Haiti" },
  //   { Code: "HN", Label: "Honduras" },
  //   { Code: "HK", Label: "Hong Kong" },
  //   { Code: "HU", Label: "Hungary" },
  //   { Code: "IS", Label: "Iceland" },
  //   { Code: "IN", Label: "India" },
  //   { Code: "ID", Label: "Indonesia" },
  //   { Code: "IR", Label: "Iran" },
  //   { Code: "IQ", Label: "Iraq" },
  //   { Code: "IE", Label: "Ireland" },
  //   { Code: "IL", Label: "Israel" },
  //   { Code: "IT", Label: "Italy" },
  //   { Code: "CI", Label: "Ivory Coast" },
  //   { Code: "JM", Label: "Jamaica" },
  //   { Code: "JP", Label: "Japan" },
  //   { Code: "JO", Label: "Jordan" },
  //   { Code: "KZ", Label: "Kazakhstan" },
  //   { Code: "KE", Label: "Kenya" },
  //   { Code: "KI", Label: "Kiribati" },
  //   { Code: "KP", Label: "North Korea" },
  //   { Code: "KR", Label: "South Korea" },
  //   { Code: "XK", Label: "Kosovo" },
  //   { Code: "KW", Label: "Kuwait" },
  //   { Code: "KG", Label: "Kyrgyzstan" },
  //   { Code: "LA", Label: "Laos" },
  //   { Code: "LV", Label: "Latvia" },
  //   { Code: "LB", Label: "Lebanon" },
  //   { Code: "LS", Label: "Lesotho" },
  //   { Code: "LR", Label: "Liberia" },
  //   { Code: "LY", Label: "Libya" },
  //   { Code: "LI", Label: "Liechtenstein" },
  //   { Code: "LT", Label: "Lithuania" },
  //   { Code: "LU", Label: "Luxembourg" },
  //   { Code: "MK", Label: "Macedonia" },
  //   { Code: "MG", Label: "Madagascar" },
  //   { Code: "MW", Label: "Malawi" },
  //   { Code: "MY", Label: "Malaysia" },
  //   { Code: "MV", Label: "Maldives" },
  //   { Code: "ML", Label: "Mali" },
  //   { Code: "MT", Label: "Malta" },
  //   { Code: "MH", Label: "Marshall Islands" },
  //   { Code: "MR", Label: "Mauritania" },
  //   { Code: "MU", Label: "Mauritius" },
  //   { Code: "MX", Label: "Mexico" },
  //   { Code: "MD", Label: "Moldova" },
  //   { Code: "MC", Label: "Monaco" },
  //   { Code: "MN", Label: "Mongolia" },
  //   { Code: "ME", Label: "Montenegro" },
  //   { Code: "MA", Label: "Morocco" },
  //   { Code: "MZ", Label: "Mozambique" },
  //   { Code: "MM", Label: "Myanmar" },
  //   { Code: "NA", Label: "Namibia" },
  //   { Code: "NR", Label: "Nauru" },
  //   { Code: "NP", Label: "Nepal" },
  //   { Code: "NL", Label: "Netherlands" },
  //   { Code: "NZ", Label: "New Zealand" },
  //   { Code: "NI", Label: "Nicaragua" },
  //   { Code: "NE", Label: "Niger" },
  //   { Code: "NG", Label: "Nigeria" },
  //   { Code: "NO", Label: "Norway" },
  //   { Code: "OM", Label: "Oman" },
  //   { Code: "PK", Label: "Pakistan" },
  //   { Code: "PW", Label: "Palau" },
  //   { Code: "PS", Label: "Palestine" },
  //   { Code: "PA", Label: "Panama" },
  //   { Code: "PG", Label: "Papua New Guinea" },
  //   { Code: "PY", Label: "Paraguay" },
  //   { Code: "PE", Label: "Peru" },
  //   { Code: "PH", Label: "Philippines" },
  //   { Code: "PL", Label: "Poland" },
  //   { Code: "PT", Label: "Portugal" },
  //   { Code: "QA", Label: "Qatar" },
  //   { Code: "RO", Label: "Romania" },
  //   { Code: "RU", Label: "Russia" },
  //   { Code: "RW", Label: "Rwanda" },
  //   { Code: "KN", Label: "Saint Kitts and Nevis" },
  //   { Code: "LC", Label: "Saint Lucia" },
  //   { Code: "VC", Label: "Saint Vincent and the Grenadines" },
  //   { Code: "WS", Label: "Samoa" },
  //   { Code: "SM", Label: "San Marino" },
  //   { Code: "ST", Label: "São Tomé and Príncipe" },
  //   { Code: "SA", Label: "Saudi Arabia" },
  //   { Code: "SN", Label: "Senegal" },
  //   { Code: "RS", Label: "Serbia" },
  //   { Code: "SC", Label: "Seychelles" },
  //   { Code: "SL", Label: "Sierra Leone" },
  //   { Code: "SG", Label: "Singapore" },
  //   { Code: "SK", Label: "Slovakia" },
  //   { Code: "SI", Label: "Slovenia" },
  //   { Code: "SB", Label: "Solomon Islands" },
  //   { Code: "SO", Label: "Somalia" },
  //   { Code: "ZA", Label: "South Africa" },
  //   { Code: "SS", Label: "South Sudan" },
  //   { Code: "ES", Label: "Spain" },
  //   { Code: "LK", Label: "Sri Lanka" },
  //   { Code: "SD", Label: "Sudan" },
  //   { Code: "SR", Label: "Suriname" },
  //   { Code: "SZ", Label: "Swaziland" },
  //   { Code: "SE", Label: "Sweden" },
  //   { Code: "CH", Label: "Switzerland" },
  //   { Code: "SY", Label: "Syria" },
  //   { Code: "TW", Label: "Taiwan" },
  //   { Code: "TJ", Label: "Tajikistan" },
  //   { Code: "TZ", Label: "Tanzania" },
  //   { Code: "TH", Label: "Thailand" },
  //   { Code: "TG", Label: "Togo" },
  //   { Code: "TO", Label: "Tonga" },
  //   { Code: "TT", Label: "Trinidad and Tobago" },
  //   { Code: "TN", Label: "Tunisia" },
  //   { Code: "TR", Label: "Turkey" },
  //   { Code: "TM", Label: "Turkmenistan" },
  //   { Code: "TV", Label: "Tuvalu" },
  //   { Code: "UG", Label: "Uganda" },
  //   { Code: "UA", Label: "Ukraine" },
  //   { Code: "AE", Label: "United Arab Emirates" },
  //   { Code: "GB", Label: "United Kingdom" },
  //   { Code: "US", Label: "United States" },
  //   { Code: "UY", Label: "Uruguay" },
  //   { Code: "UZ", Label: "Uzbekistan" },
  //   { Code: "VU", Label: "Vanuatu" },
  //   { Code: "VE", Label: "Venezuela" },
  //   { Code: "VN", Label: "Vietnam" },
  //   { Code: "YE", Label: "Yemen" },
  //   { Code: "ZM", Label: "Zambia" },
  //   { Code: "ZW", Label: "Zimbabwe" },
  //   { Code: "Portfolio", Label: "Portfolio" },
  // ],
  // ProjectStatus: {
  //   1: "Proposal",
  //   6: "Negotiating",
  //   2: "Cancelled",
  //   3: "Commissioned",
  //   30: "Commissioned (Recost)",
  //   4: "Completed",
  //   5: "Archived",
  // },
  // SearchBy: {
  //   ID: "Project ID",
  //   NAME: "Project Name",
  //   PM: "Project Manager",
  //   PO: "Proposal Owner",
  //   OP: "OP Number",
  // },
  // IndustryGroupOptions: [
  //   { Code: "Academy", Label: "Academy" },
  //   { Code: "Advertiser/Brand", Label: "Advertiser/Brand" },
  //   { Code: "Agency", Label: "Agency" },
  //   { Code: "Automotive", Label: "Automotive" },
  //   { Code: "Biotechnology", Label: "Biotechnology" },
  //   { Code: "Broker/Sales Agency", Label: "Broker/Sales Agency" },
  //   {
  //     Code: "Construction/Home Builder/Home Improvement",
  //     Label: "Construction/Home Builder/Home Improvement",
  //   },
  //   { Code: "Consultants", Label: "Consultants" },
  //   { Code: "Digital Publisher/Platform", Label: "Digital Publisher/Platform" },
  //   { Code: "Education", Label: "Education" },
  //   {
  //     Code: "Entertainment (incl. gambling)",
  //     Label: "Entertainment (incl. gambling)",
  //   },
  //   { Code: "Financial Services", Label: "Financial Services" },
  //   { Code: "Government / Non-profit", Label: "Government / Non-profit" },
  //   { Code: "Insurance", Label: "Insurance" },
  //   {
  //     Code: "Manufacturer/Distributor : Alcoholic beverages",
  //     Label: "Manufacturer/Distributor : Alcoholic beverages",
  //   },
  //   {
  //     Code: "Manufacturer/Distributor : FMCG",
  //     Label: "Manufacturer/Distributor : FMCG",
  //   },
  //   {
  //     Code: "Manufacturer/Distributor : Fresh produce + prepared fresh foods",
  //     Label: "Manufacturer/Distributor : Fresh produce + prepared fresh foods",
  //   },
  //   {
  //     Code: "Manufacturer/Distributor : Non-alcoholic beverages",
  //     Label: "Manufacturer/Distributor : Non-alcoholic beverages",
  //   },
  //   {
  //     Code: "Manufacturer/Distributor : Pharma + Healthcare",
  //     Label: "Manufacturer/Distributor : Pharma + Healthcare",
  //   },
  //   {
  //     Code: "Manufacturer/Distributor : Technology",
  //     Label: "Manufacturer/Distributor : Technology",
  //   },
  //   {
  //     Code: "Manufacturer/Distributor : Tobacco",
  //     Label: "Manufacturer/Distributor : Tobacco",
  //   },
  //   {
  //     Code: "Manufacturer/Distributor : Cannabis",
  //     Label: "Manufacturer/Distributor : Cannabis",
  //   },
  //   {
  //     Code: "Manufacturer/Distributor : Pet",
  //     Label: "Manufacturer/Distributor : Pet",
  //   },
  //   { Code: "Media Owner / Publisher", Label: "Media Owner / Publisher" },
  //   { Code: "Military ", Label: "Military " },
  //   { Code: "Online technology platform", Label: "Online technology platform" },
  //   { Code: "Real Estate", Label: "Real Estate" },
  //   { Code: "Restaurant", Label: "Restaurant" },
  //   { Code: "Retailer : FMCG", Label: "Retailer : FMCG" },
  //   { Code: "Retailer : Other", Label: "Retailer : Other" },
  //   { Code: "Sports", Label: "Sports" },
  //   {
  //     Code: "Telecom + Internet service providers",
  //     Label: "Telecom + Internet service providers",
  //   },
  //   { Code: "Travel & Tourism", Label: "Travel & Tourism" },
  //   { Code: "Utility providers", Label: "Utility providers" },
  //   { Code: "Other", Label: "Other" },
  // ],
};

export default function CodeLabelReducer(state = initialState, actions) {
  switch (actions.type) {
    case types.SET_CODELABELS: {
      return {
        ...state,
        ...actions.InitLabels,
      };
    }
    case types.UPDATE_CODELABELS: {
      return update(state, { $merge: actions.InitLabels });
    }
    case types.SET_SEARCHBY: {
      return {
        ...state,
        SearchBy: actions.SearchTypes
      }
    }
    default:
      return state;
  }
}
