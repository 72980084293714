import React, { useState, useEffect } from "react";
import axios from "../../../axios-interceptor";
import * as currentProjectActions from "../../../redux/actions/currentProjectActions";
import _ from "lodash";
import { Key, MinusCircle, PlusCircle } from "react-feather";
import Select from "react-select";
import classnames from "classnames";
import * as currentCostingActions from "../../../redux/actions/currentCostingActions";
import { connect, useDispatch, useSelector } from "react-redux";
import update from "immutability-helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ComparisonView from "./ComparisonView";
import InternalCostView from "./InternalCostSummaryTable";
import FinalCost from "./FinalCost";
import Spinner from "../../../components/Spinner";
import {
  faChevronDown,
  faChevronUp,
  faSpinner,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledCollapse,
} from "reactstrap";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
  Table,
  Media,
} from "reactstrap";
import * as proposalActions from "../../../redux/actions/proposal/Actions";
import * as sheetCall from "../../../utils/msSheetAPiCall";
import * as appActions from "../../../redux/actions/appActions";
import { toastr } from "react-redux-toastr";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
  textFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import cellEditFactory from "react-bootstrap-table2-editor";
import { setCurrentProject } from "../../../redux/actions/currentProjectActions";

const mapStateToProps = (state, ownProps) => {
  return {
    proposal: state.proposal.proposal,
    loading: state.proposal.Loading,
    error: state.proposal.error,
    msg: state.proposal.msg,
    redux_masters: state.redux_master.masters,
    cc_masters: state.redux_master.ccMasters,

    saving: state.proposal.saving,
    resending: state.proposal.resending,
    additionalCostSaved: state.proposal.additionalCostSaved,
  };
};

function ProposalBiddingSelectVendorsUpdated(props) {
  const baseurl = process.env.REACT_APP_API_BASE_URL;
  const [proposal, setProposalData] = useState({});
  const [activeTabCountryPopup, setActiveTabCountryPopup] = useState(0);
  const [activeTabMethodologyPopup, setActiveTabMethodologyPopup] = useState(0);
  const [modelState, setModelState] = useState(false);
  const [modelRowIndex, setModelSelectedRow] = useState([]);
  const [InternalCost, setInternalCost] = useState(false);
  const [modelStateSummary, setModelStateSummary] = useState(false);
  const [option, setoption] = useState();
  const [country, setcountry] = useState();
  const [Costing, setCosting] = useState(0);
  const [finalCosting, setfinalCosting] = useState(0);
  const dispatch = useDispatch();
  const codeLabels = useSelector(({ codeLabels }) => codeLabels);

  const [countrymodal, setcountrymodal] = useState(false);
  const [activeTabV, setActiveTabV] = useState(0);
  const [activeTabCo, setActiveTabCo] = useState(0);
  const [activeTabFC, setActiveTabFC] = useState(0);
  const [activeTabSM, setActiveTabSM] = useState(0);
  const [activeDropCurrency, setactiveDropCurrency] = useState();
  const [activeInternalDropCurrency, setactiveInternalDropCurrency] =
    useState();
  let [activeTabSummary, setactiveTabSummary] = useState(0);
  let [internalCost, setinternalCost] = useState([]);
  let [activeCoId, setactiveCoId] = useState();
  let [internalOtherSpecify, setinternalOtherSpecify] = useState({});
  const [vendorSelectionSaved, setVendorSelectionSaved] = useState(false);
  const [ShowTabs, setShowTabs] = useState(false);
  const [currentCoSampleSize, setCurrentCoSampleSize] = useState(0);
  const [initialSelectedField, setInitialSelectedField] = useState(false);
  const [costingProfileIndex, setCostingProfileIndex] = useState(0);
  const [vendorSelection, setVendorSelection] = useState([]);
  const [currentCurrencySelection, setCurrentCurrencySelection] = useState({});
  const [viewonlymode, setViewOnlyMode] = useState(false);
  const [disableselection, setDisableSelection] = useState(false);


  const currentprofile = useSelector(
    ({ currentCosting }) => currentCosting.currentCostingProfile
  );
  var [Tabdata, setTabdata] = useState([]);
  var [staticModelData, setstaticModelData] = useState([]);

  var totalKeyArray = [
    "Field Cost",
    "Survey Programing & Platform",
    "Dashboard",
    "Coding & Data Entry",
    "Data Processing",
    "Charting",
    "Translation",
    "Dashboarding",
    "Transciption",
    "Moderation",
    "Rental",
    "Internal Cost",
  ];
  var roundclosedstyle = {
    color: "#b94e48",
    marginBottom: "5px"
  };
  var keyarrayQuant = [
    "Field Cost",
    "Survey Programing & Platform",
    "Dashboard",
    "Coding & Data Entry",
    "Data Processing",
    "Charting",
    "Translation",
    "Dashboarding",
  ];
  var keyarrayQualOnline = [
    "Field Cost",
    "Transciption",
    "Translation",
    "Moderation",
    "Rental",
  ];
  var optionforlocalsupport = [
    { label: "Yes", id: 1, value: "Vendor FW with local support" },
    { label: "No", id: 0, value: "Vendor FW (direct outsourcing)" },
  ];
  var a = 0;
  var i = 0;

  const vendorAndList = useSelector(
    ({ fetchVendorAndList }) => fetchVendorAndList.vendorAndList
  );
  const app = useSelector(({ app }) => app);
  const splitdata = useSelector(({ vendorReducer }) => vendorReducer.SplitData);

  function setList() {
    if (vendorAndList && vendorAndList.length > 0) {
      Tabdata = vendorAndList
        .filter((obj) => obj.Group == "fieldexecutionandsudtable")[0]
        .List.filter((v) => v.Name == "Tabdata")[0].Values;
      setTabdata(Tabdata);
      staticModelData = vendorAndList
        .filter((obj) => obj.Group == "fieldexecutionandsudtable")[0]
        .List.filter((v) => v.Name == "staticModelData")[0].Values;
      setstaticModelData(staticModelData);
      return true;
    }
  }
  useEffect(() => {
    setList();
    setCurrentCurrencySelection(defaultvalueforcurrency);
  }, [activeTabSM, activeTabFC, project]);
  //for Currency Conversion
  useEffect(() => {
    let tempVendorSelection = vendorSelection.map((vs) => {
      if (currentCurrencySelection?.label == "USD") {
        vs.ConstantCostFromVendor = isNaN(vs.ConstantCostFromVendor) ? 0 : vs.ConstantCostFromVendor;
        vs.CostFromVendor = vs.ConstantCostFromVendor;
        vs.CPI = _.round(
          vs.Costingtype == "Field Cost" ? vs?.AchivableSamples ?
            (vs.ConstantCostFromVendor / vs.AchivableSamples) : 0 :
            (vs.ConstantCostFromVendor / vs.SampleSize), 2);
      } else {
        vs.ConstantCostFromVendor = isNaN(vs.ConstantCostFromVendor) ? 0 : vs.ConstantCostFromVendor;
        vs.CostFromVendor = _.round(
          vs.ConstantCostFromVendor *
          currentCurrencySelection?.ConversionRateToLocal, 2);
        vs.CPI = _.round(
          vs.Costingtype == "Field Cost" ? vs?.AchivableSamples ?
            ((vs.ConstantCostFromVendor * currentCurrencySelection?.ConversionRateToLocal) / vs.AchivableSamples) : 0 :
            ((vs.ConstantCostFromVendor * currentCurrencySelection?.ConversionRateToLocal) / vs.SampleSize), 2);

      }
      return vs;
    });
    setVendorSelection(tempVendorSelection);
  }, [currentCurrencySelection]);

  function updateproject(newProposal) {
    project.CostingProfiles[project?.CostingProfiles?.length - 1] = newProposal;
    dispatch({
      type: currentProjectActions.UPDATE_NEW_PROJECT,
      newProject: project,
    });
  }
  const updateProject = (profileTemp) => {
    const tempProfiles = project.CostingProfiles.map((cp) => {
      if (cp.id == profileTemp.id) return { ..._.cloneDeep(profileTemp) };
      return { ..._.cloneDeep(cp) };
    });
    dispatch(setCurrentProject({ CostingProfiles: tempProfiles }));
  };

  function defaultvalueforcurrency() {
    var currdata = [];
    var curr =
      project?.CostingProfiles[costingProfileIndex] &&
        project?.CostingProfiles[costingProfileIndex]?.CountrySpecs?.length > 0
        ? project?.CostingProfiles[
          costingProfileIndex
        ]?.ProfileSetting?.CurrenciesData?.find(
          (c) =>
            c.Code ==
            project?.CostingProfiles[costingProfileIndex]?.CountrySpecs[activeTabFC]?.CountryCode
        )
        : "";
    if (curr != "") {
      curr.AllCurrencyUnit = curr?.AllCurrencyUnit.replace(
        /(\r\n|\n|\r)/gm,
        ""
      );
      var allUnits = curr?.AllCurrencyUnit
        ? curr?.AllCurrencyUnit.split(",")
        : [];
      if (allUnits.length > 0) {
        allUnits.forEach((all, i) => {
          currdata.push({
            id: i + 1,
            label: all,
            value: i + 1,
            code: curr.Code,
            ConversionRateToLocal: project.CostingProfiles[
              costingProfileIndex
            ]?.ProfileSetting.CurrenciesData.filter(
              (curr) => curr.CurrencyUnit == all
            )[0].ConversionRateToLocal,
          });
        });
      }
    }

    var defaultvalue = currdata.find((a) => a.label == curr.CurrencyUnit);

    if (!project?.CostingProfiles[costingProfileIndex]?.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]) {
      return defaultvalue
    }
    else if (!project?.CostingProfiles[costingProfileIndex]?.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]?.Currency) {
      return defaultvalue
    }
    else {
      return project?.CostingProfiles[costingProfileIndex]?.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].BiddingDetail?.Currency
    }
  }

  function currencyDropdown() {
    var currdata = [];
    var curr =
      _.last(project.CostingProfiles) &&
        _.last(project.CostingProfiles)?.CountrySpecs.length > 0
        ? _.last(project.CostingProfiles)?.ProfileSetting.CurrenciesData.find(
          (c) =>
            c.Code ==
            _.last(project.CostingProfiles)?.CountrySpecs[activeTabFC]
              .CountryCode
        )
        : "";
    var commcountrycurr =
      _.last(project.CostingProfiles) &&
        _.last(project.CostingProfiles)?.CountrySpecs.length > 0
        ? _.last(project.CostingProfiles)?.CountrySpecs[activeTabFC]
          .CountryCode != project.CommissioningCountry
          ? _.last(project.CostingProfiles)?.ProfileSetting.CurrenciesData.find(
            (c) => c.Code == project.CommissioningCountry
          )
          : ""
        : "";

    //project.CommissioningCountry;
    if (curr != "" && curr != undefined) {
      curr.AllCurrencyUnit = codeLabels.FieldingCountriesOptions.find(
        (fc) => fc.Code == curr.Code
      ).AllCurrencyUnit;
      //  curr.AllCurrencyUnit.replace(/(\r\n|\n|\r)/gm, "");
      var allUnits = curr.AllCurrencyUnit
        ? curr.AllCurrencyUnit.split(",")
        : [];
      if (allUnits.length > 0) {
        allUnits.forEach((all, i) => {
          currdata.push({
            id: i + 1,
            label: all,
            value: i + 1,
            code: curr.Code,
            ConversionRateToLocal: _.last(
              project.CostingProfiles
            )?.ProfileSetting.CurrenciesData.filter(
              (curr) => curr.CurrencyUnit == all
            )[0].ConversionRateToLocal,
          });
        });
      }
    }
    if (commcountrycurr != "" && commcountrycurr != undefined) {
      var currlength = currdata.length;
      commcountrycurr.AllCurrencyUnit =
        codeLabels.FieldingCountriesOptions.find(
          (fc) => fc.Code == commcountrycurr.Code
        ).AllCurrencyUnit;
      //  curr.AllCurrencyUnit.replace(/(\r\n|\n|\r)/gm, "");
      var allUnits = commcountrycurr.AllCurrencyUnit
        ? commcountrycurr.AllCurrencyUnit.split(",")
        : [];
      if (allUnits.length > 0) {
        allUnits.forEach((all, i) => {
          if (!currdata.find((a) => a.label == all)) {
            currdata.push({
              id: currlength + i + 1,
              label: all,
              value: currlength + i + 1,
              code: commcountrycurr.Code,
              ConversionRateToLocal: _.last(
                project.CostingProfiles
              )?.ProfileSetting.CurrenciesData.filter(
                (curr) => curr.CurrencyUnit == all
              )[0].ConversionRateToLocal,
            });
          }
        });
      }
    }
    return currdata;
  }
  function localoperationsupport(selectedLocal) {
    let currentProfile = _.cloneDeep(_.last(project.CostingProfiles));
    currentProfile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].VendorType = selectedLocal
    updateproject(currentProfile);
    // project.CostingProfiles[project.CostingProfiles.length - 1] = currentProfile;
  }

  function ApplicableForCostSheet(meth) {
    var result = meth.CostingOptions?.filter(x => x.Selected).map(co => {

      var totalMethSampleSize = co.CostingOptionData.sampleSizeMain || co.CostingOptionData.numberOfGroup
      var totalAchievableSample = _.last(splitdata?.filter(a => (a.MethodologySpecId == meth.id) && a.RoundClosedOn)?.
        flatMap(x => x.SampleDistributionMasters).filter(x => x.CostingOptionId == co.id))?.AchivableSamples?.
        flatMap(z => z.AchivableSamples)?.reduce((a, b) => parseInt(a) + parseInt(b), 0)
      if (totalAchievableSample == totalMethSampleSize)
        return true
      else
        return false
    })

    if (result.every(x => x == true))
      return true
    else
      return false
  }
  function copyVendorType() {
    var type =
      _.last(project.CostingProfiles)
        .CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].VendorType;
    var project1 = _.cloneDeep(
      _.last(project.CostingProfiles)
    );
    project1.CountrySpecs[activeTabFC].MethodologySpecs.forEach(
      (a) => {
        if (
          a.CostingType == 3 &&
          !a.NotApplicable
        ) { (a.VendorType = type) }
      });
    updateproject(project1);
    toastr.success("Copied Successfully");
  }

  function ComparisonViewData() {
    var ComparisonData = [];
    project.CostingProfiles[
      project?.CostingProfiles?.length - 1
    ].CountrySpecs.map((cs) => {
      cs.MethodologySpecs.map((co) => {
        if (
          co.CostingType == 3 &&
          !co.NotApplicable
        ) {
          if (co?.BiddingDetail?.SelectedVendor && co?.BiddingDetail?.SelectedVendor.length > 0) {
            co?.BiddingDetail?.SelectedVendor.map((sv) => {
              ComparisonData = [...ComparisonData, ...sv.selected];
            });
          }
        }
      });
    });

    var temparray = [];
    totalKeyArray.forEach((key) => {
      let temp = ComparisonData.filter((cd) => cd.Costingtype == key);
      if (temp.length > 0 && key != "Internal Cost") {
        project.CostingProfiles[
          project?.CostingProfiles?.length - 1
        ].CountrySpecs.map((cs) => {
          cs.MethodologySpecs.map((co) => {
            if (
              co.CostingType == 3 &&
              !co.NotApplicable
            ) {
              co.CostingOptions?.filter(x => x.Selected).map((sv) => {
                temp
                  .filter(
                    (t) =>
                      t.CostingOption == sv.Name &&
                      t.Methodology == co.Label &&
                      t.Country == cs.CountryName
                  )
                  .forEach((val) => {
                    temparray.push(val);
                  });
              });
            }
          });
        });
      }
      if (key == "Internal Cost") {
        let tempobj = {};
        let tempinput = ComparisonData.filter((cd) => cd.Type == "SumInternal");
        project.CostingProfiles[
          project?.CostingProfiles?.length - 1
        ].CountrySpecs.map((cs) => {
          cs.MethodologySpecs.map((co) => {
            if (
              co.CostingType == 3 &&
              !co.NotApplicable
            ) {
              co.CostingOptions?.filter(x => x.Selected).map((sv) => {
                if (
                  tempinput.filter(
                    (t) =>
                      t.CostingOption == sv.Name &&
                      t.Methodology == co.Label &&
                      t.Country == cs.CountryName
                  ).length > 0
                ) {
                  tempobj = {};
                  tempobj.Country = cs.CountryName;
                  tempobj.Methodology = co.Label;
                  tempobj.CostingOption = sv.Name;
                  tempobj.LOI = sv.CostingOptionData.lengthOfInterview;
                  tempobj.SampleSize = sv.CostingOptionData.sampleSizeMain
                    ? sv.CostingOptionData.sampleSizeMain
                    : sv.CostingOptionData.numberOfGroup;
                  var tempobjTotalCost =
                    tempinput.filter(
                      (t) =>
                        t.CostingOption == sv.Name &&
                        t.Methodology == co.Label &&
                        t.Country == cs.CountryName
                    ).length > 0
                      ? tempinput.filter(
                        (t) =>
                          t.CostingOption == sv.Name &&
                          t.Methodology == co.Label &&
                          t.Country == cs.CountryName
                      )[0].TotalCost
                      : 0;

                  tempobj.TotalCost = isNaN(tempobjTotalCost)
                    ? 0
                    : tempobjTotalCost;

                  var tempobjAdditionalCost =
                    tempinput.filter(
                      (t) =>
                        t.CostingOption == sv.Name &&
                        t.Methodology == co.Label &&
                        t.Country == cs.CountryName
                    ).length > 0
                      ? tempinput
                        .filter(
                          (t) =>
                            t.CostingOption == sv.Name &&
                            t.Methodology == co.Label &&
                            t.Country == cs.CountryName
                        )
                        .map((a) => a.AdditionalCostUSD)
                        .reduce((a, b) => parseInt(a) + parseInt(b))
                      : 0;

                  tempobj.AdditionalCost = isNaN(tempobjAdditionalCost)
                    ? 0
                    : tempobjAdditionalCost;

                  var tempobjTotalInternalCost =
                    tempinput.filter(
                      (t) =>
                        t.CostingOption == sv.Name &&
                        t.Methodology == co.Label &&
                        t.Country == cs.CountryName
                    ).length > 0
                      ? tempinput.filter(
                        (t) =>
                          t.CostingOption == sv.Name &&
                          t.Methodology == co.Label &&
                          t.Country == cs.CountryName
                      )[0]?.TotalCostusd
                      : 0;

                  tempobj.TotalInternalCost = isNaN(tempobjTotalInternalCost)
                    ? 0
                    : tempobjTotalInternalCost;

                  tempobj.Costingtype = "Total Internal Cost";
                  temparray.push(tempobj);
                }
              });
            }
          });
        });
      }
    });

    temparray = temparray.map((t, index) => {
      return { ...t, index: index };
    });
    return _.sortBy(
      temparray,
      "Costingtype",
      "CostingOption",
      "Methodology",
      "Country"
    );
  }
  function FinalTabRender() {
    var flag = true;
    project.CostingProfiles[
      project?.CostingProfiles?.length - 1
    ].CountrySpecs.map((cs) => {
      cs.MethodologySpecs.map((ms) => {
        if (
          ms.CostingType == 3 &&
          !ms.NotApplicable
        ) {
          if (
            !ms?.BiddingDetail?.SelectedVendor ||
            ms?.BiddingDetail?.SelectedVendor == null ||
            ms?.BiddingDetail?.SelectedVendor == undefined
          ) {
            flag = false;
          }
        }
      });
    });
    return flag;
  }

  async function createSheet() {
    if (!currentprofile.CostingsSheetId) {
      toastr.info("Costing Sheet is being created...");
      let currentProfile = { ...currentprofile, Project: project }
      dispatch(appActions.pageLoadStart());
      axios
        .post("/utils/sheets/" + currentprofile.id + "/costing")
        .then((res) => {
          axios
            .get("vendors/project/bidding", {
              params: {
                ProjectId: project.ProjectId,
                CostingProfileId: _.last(project.CostingProfiles).id,
              },
            })
            .then((result) => {
              dispatch(
                currentCostingActions.updateProfile({
                  ..._.last(result.data.data.CostingProfiles),
                  CostingsSheetId: res.data.CostingsSheetId,
                  ConfirmCosting: true,
                })
              );
              updateProject({
                ..._.last(result.data.data.CostingProfiles),
                CostingsSheetId: res.data.CostingsSheetId,
                ConfirmCosting: true,
              });
            })


          // dispatch(
          //   currentCostingActions.saveCostingProfile(
          //     {
          //       ...currentProfile,
          //       CostingsSheetId: res.data.CostingsSheetId,
          //     },
          //     null,
          //     true
          //   )
          // );

          // toastr.success("Bidding has been Closed");
          toastr.success("Sheet created Successfully", res.data.message);
          // window.open(`https://docs.google.com/spreadsheets/d/${res.data.CostingsSheetId}`, "_blank");

          // if (res.data.CostingsSheetId && project.CostingTemplateVersion != "v2.0") {
          if (
            res.data.CostingsSheetId &&
            project.CostingTemplateVersion != "v2.0"
          ) {
            toastr.info(
              "Sheet is being prepared",
              "Sheet Preparation might take sometime to setup your project configuration",
              { timeOut: 20000 }
            );
            setTimeout(() => {
              toastr.info(
                "It is about to complete",
                "Please do not refresh or close the page, sheet preparation is inprogress",
                { timeOut: 80000 }
              );
            }, 20000);
            setTimeout(() => {
              toastr.info("Opening the sheet", null);
              dispatch(sheetCall.msSheetAPiCall(res.data.CostingsSheetId, true, true, () => {
                dispatch(appActions.pageLoadEnd())
              }
              ));
              // dispatch(appActions.pageLoadEnd());
            }, 100000);
          } else if (
            res.data.CostingsSheetId &&
            project.CostingTemplateVersion == "v2.0"
          ) {
            dispatch(sheetCall.msSheetAPiCall(res.data.CostingsSheetId, true, true, () => {
              dispatch(appActions.pageLoadEnd())
            }
            ));
          }
        })
        .catch((err) => {
          dispatch(appActions.pageLoadEnd());
          // setSyncAbility(false)
          toastr.error("Creating Sheet Failed", err?.data?.message);
        });
    }
  }

  async function openSheet() {
    dispatch(appActions.recordLoadStart());

    // window.open(`https://docs.google.com/spreadsheets/d/${currentprofile.CostingsSheetId}`, "_blank");
    //todo: handle in open cost sheet inside then block, avoid sending dispatch
    dispatch(sheetCall.msSheetAPiCall(currentprofile.CostingsSheetId, true, true, () => {
      dispatch(appActions.recordLoadEnd());
    }));
  }

  function SaveSelectedVendor() {
    var profileCopy = _.cloneDeep(
      project.CostingProfiles[project?.CostingProfiles?.length - 1]
    );
    var profileSave = true;

    profileCopy.CountrySpecs.forEach((cs) => {
      cs.MethodologySpecs.forEach((ms, i) => {
        if (
          ms.CostingType == 3 &&
          ms.SelectedVendor &&
          !ms.NotApplicable &&
          ms.SelectedVendor.length > 0
        ) {
          ms.CostingOptions?.filter(x => x.Selected).forEach((co, coi) => {
            var vName = "";
            var coSelectedData = {};
            var obj = [];
            if (
              ms.SelectedVendor[coi]?.selected?.filter(
                (sv) => sv.Costingtype == "Field Cost"
              ).length > 0 &&
              ms.SplitSample == 1
            ) {
              var selectedFieldSampleSize = ms.SelectedVendor[coi].selected
                .filter((sv) => sv.Costingtype == "Field Cost")
                .filter(
                  (x) =>
                    x.VendorID ==
                    ms.Vendors.filter((y) => y.id == x.VendorID)[0].id &&
                    x.VendorCO.length ==
                    ms.Vendors.filter((y) => y.id == x.VendorID)[0]
                      .CostingData.length
                ).length
                ? ms.SelectedVendor[coi].selected
                  .filter((sv) => sv.Costingtype == "Field Cost")
                  .filter(
                    (x) =>
                      x.VendorID ==
                      ms.Vendors.filter((y) => y.id == x.VendorID)[0].id &&
                      x.VendorCO.length ==
                      ms.Vendors.filter((y) => y.id == x.VendorID)[0]
                        .CostingData.length
                  )
                  .map((sv) => sv.SampleSize)
                  .reduce((a, b) => a + b)
                : 0;
              if (
                selectedFieldSampleSize !=
                parseInt(
                  co.CostingOptionData.sampleSizeMain ||
                  co.CostingOptionData.sampleSize ||
                  co.CostingOptionData.numberOfGroup
                )
              ) {
                toastr.error(
                  `Field Selection is missing for ${cs.CountryName} in ${ms.label} for ${co.Name} `
                );
                profileSave = false;
              }
            }

            var stubsActual = ms.SelectedVendor.filter((x) => x.id == co.id)[0]
              ?.TotalCount;

            if (stubsActual)
              stubsActual =
                ms.SplitSample == 1
                  ? ms.SelectedVendor.filter((x) => x.id == co.id)[0]
                    ?.TotalCount +
                  parseInt(
                    ms?.SplitSampledata?.NumberOfSplits?.find(
                      (a) => a.coid == co.id
                    )?.size
                  ) -
                  1
                  : ms.SelectedVendor.filter((x) => x.id == co.id)[0]
                    ?.TotalCount;

            var stubsPresent = ms.SelectedVendor.filter((x) => x.id == co.id)
              .map((a) => a.selected)
              .flat()
              .filter(
                (stub) =>
                  stub.Type != "Internal Cost" &&
                  stub.Costingtype != "Total Internal Cost"
              ).length;
            if (stubsActual > stubsPresent) {
              toastr.error(
                `Please Complete Vendor Selection for ${cs.CountryName} in ${ms.Label} for ${co.Name} `
              );
              profileSave = false;
            }

            if (ms.VendorType == null) {
              profileSave = false;
              toastr.error(
                `Please Select FW Local Operation Support for ${cs.CountryName} in ${ms.Label}`
              );
            }
            _.head(
              ms.SelectedVendor?.filter((sv) => sv.id == co.id)
            )?.selected.forEach((s) => {
              //converting back to local currency while saving

              if (s.Type == "Internal Cost") {
                if (ms.label == "Online Self Completion Survey") {
                  coSelectedData["BufferSampleCost"] =
                    parseFloat(
                      isNaN(s.AdditionalCostUSD)
                        ? 0
                        : s.AdditionalCostUSD.toFixed(2)
                    ) +
                    (coSelectedData["BufferSampleCost"] != undefined
                      ? parseFloat(coSelectedData["BufferSampleCost"])
                      : 0);
                } else {
                  obj.push({ name: s.Costingtype, group: s.Type, key: s.key });
                  coSelectedData[s.key] =
                    s?.AdditionalCostUSD != ""
                      ? s.AdditionalCostUSD.toFixed(2)
                      : 0;
                  coSelectedData["BufferSampleCost"] =
                    s.AdditionalCostUSD.toFixed(2);
                }
              } else {
                if (s.vendorSchema != undefined) {
                  obj.flat(s.vendorSchema);
                }
                if (s.Costingtype != "Total Internal Cost") {
                  vName =
                    (vName ? vName : "") + s.Vendor + `(${s.Costingtype}),`;
                }
                if (s.subFields && s.subFields.length > 0) {
                  if (s.Costingtype == "Field Cost" && ms.SplitSample == 1) {
                    s.subFields.forEach((sub) => {
                      if (sub.Name.includes("Other Cost")) {
                        coSelectedData[sub.key] =
                          (isNaN(
                            parseInt(
                              (
                                sub.cost / ms.Currency.ConversionRateToLocal
                              ).toFixed(2)
                            )
                          )
                            ? 0
                            : parseInt(
                              (
                                sub.cost / ms.Currency.ConversionRateToLocal
                              ).toFixed(2)
                            )) +
                          (coSelectedData[sub.key] != undefined
                            ? parseInt(coSelectedData[sub.key])
                            : 0);
                        coSelectedData[sub.key + "_Label"] =
                          (sub.pleaseSpecify != "" &&
                            sub.pleaseSpecify != undefined
                            ? sub.pleaseSpecify
                            : "") +
                          (coSelectedData[sub.key + "_Label"] != undefined
                            ? coSelectedData[sub.key + "_Label"]
                            : "");
                      } else {
                        coSelectedData[sub.key] =
                          sub.type == "TimingSection"
                            ? (isNaN(parseInt(sub.cost))
                              ? 0
                              : parseInt(sub.cost)) +
                            (coSelectedData[sub.key] != undefined
                              ? parseInt(coSelectedData[sub.key])
                              : 0)
                            : (isNaN(
                              parseInt(
                                (
                                  sub.cost / ms.Currency.ConversionRateToLocal
                                ).toFixed(2)
                              )
                            )
                              ? 0
                              : parseInt(
                                (
                                  sub.cost /
                                  ms.Currency.ConversionRateToLocal
                                ).toFixed(2)
                              )) +
                            (coSelectedData[sub.key] != undefined
                              ? parseInt(coSelectedData[sub.key])
                              : 0);
                      }
                    });
                  } else {
                    s.subFields.forEach((sub) => {
                      if (sub.key.includes("otherSUD")) {
                        coSelectedData["OtherSUDCost1"] =
                          parseFloat(
                            isNaN(
                              (
                                sub.cost / ms.Currency.ConversionRateToLocal
                              ).toFixed(2)
                            )
                              ? 0
                              : (
                                sub.cost / ms.Currency.ConversionRateToLocal
                              ).toFixed(2)
                          ) +
                          (coSelectedData["OtherSUDCost1"] != undefined
                            ? parseFloat(coSelectedData["OtherSUDCost1"])
                            : 0);
                        coSelectedData["OtherSUDCost1_Label"] =
                          (sub.pleaseSpecify != "" &&
                            sub.pleaseSpecify != undefined
                            ? sub.pleaseSpecify
                            : ""
                              ? sub.pleaseSpecify
                              : "") +
                          " " +
                          (coSelectedData["OtherSUDCost1_Label"] != undefined
                            ? coSelectedData["OtherSUDCost1_Label"]
                            : "");
                      } else {
                        if (sub.Name.includes("Other Cost")) {
                          coSelectedData[sub.key] = (
                            sub.cost / ms.Currency.ConversionRateToLocal
                          ).toFixed(2);
                          coSelectedData[sub.key + "_Label"] =
                            sub.pleaseSpecify != "" &&
                              sub.pleaseSpecify != undefined
                              ? sub.pleaseSpecify
                              : "";
                        } else {
                          coSelectedData[sub.key] =
                            sub.type == "TimingSection"
                              ? sub.cost
                              : (
                                sub.cost / ms.Currency.ConversionRateToLocal
                              ).toFixed(2);
                        }
                      }
                    });
                  }
                }
              }
            });
            co.vendorSchema = obj;
            co.SelectedVendor = coSelectedData;
            co.selectedVendorName = vName;
          });
        } else if (ms.CostingType == 3 && ms.NotApplicable) {
        } else {
          if (ms.CostingType == 3) {
            ms.SelectedVendor = null;
            profileSave = false;
            toastr.error(
              `Please Select Vendors for ${cs.CountryName} in ${ms.Label} `
            );
          } else {
            profileSave = true;
          }
        }
      });
    });

    updateproject(profileCopy);
    dispatch(proposalActions.saveSelectedVendor(project, true));
    setVendorSelectionSaved(true);
    toastr.success("Vendors Selected Saved");
  }
  function saveAndNext(tab, operation) {
    let tempVendorSelection;
    let error = 0;

    let currentProfile = _.cloneDeep(project.CostingProfiles[project.CostingProfiles.length - 1]);
    currentProfile.CountrySpecs?.forEach((country, cIndex) => {
      country.MethodologySpecs?.forEach((methodology, mIndex) => {
        if (
          methodology.CostingType == 3 &&
          !methodology.NotApplicable
        ) {
          if (!methodology.VendorType) {
            error = error + 1
            toastr.error(
              `Please Complete Local Operation Support ${country.CountryName} in ${methodology.Label} `
            );
          }
          if (methodology.BiddingDetail) {
            methodology.BiddingDetail.SelectedVendor = [];
            if (methodology.CostingOptions?.filter(x => x.Selected)) {
              methodology.CostingOptions?.filter(x => x.Selected)?.forEach((co, coIndex) => {
                let invitedVendorCost = methodology.InvitedVendors?.filter(a => !a.IsDeclined)?.map((invited) => invited.VendorCosts);
                let mainStubs = invitedVendorCost
                  .flat()
                  .filter((vc) => vc.CostOptionId == co.id)
                  .map((c) => c.CostingsData)
                  .flat()
                  .filter(
                    (f) =>
                      f.group != "TimingSection" && f.key != "TimingSection" && f.soid != 14
                  )
                  .map((m) => m.group);
                let selectedVendor = vendorSelection.filter(vs => vs.CostingOptionID == co.id && vs.IsSUD)
                let allVendor = _.uniq(mainStubs).map(
                  (val, i) => {
                    return {
                      key: i,
                      Costingtype: val,
                    };
                  }
                ).filter(vs => vs.Costingtype != "Field Cost")
                if (selectedVendor.length < allVendor.length) {
                  error += 1;
                  toastr.error(
                    `Please Complete Vendor Selection for ${country.CountryName} in ${methodology.Label} for ${co.Name} `
                  );
                }
                else {
                  tempVendorSelection = {};
                  tempVendorSelection.name = co.Name;
                  tempVendorSelection.CostOptionId = co.id;
                  tempVendorSelection.selected = vendorSelection
                    .filter((v) => v.CostingOptionID == co.id)
                    .filter((vs) => vs.IsSelected == true || vs.IsSUD == true);
                  methodology.BiddingDetail.SelectedVendor.push(tempVendorSelection);
                }
              });
            }
          }
        }
      });
    });

    if (vendorSelection.length == 0) {
      toastr.error("Atleast one vendor needs to be selected")
    }
    else
      if (error == 0) {
        project.CostingProfiles[project.CostingProfiles.length - 1] = currentProfile;
        dispatch({
          type: "UPDATE_NEW_PROJECT",
          newProject: project,
        });
        if (operation == "Save&Next") {
          setactiveTabSummary(tab + 1);
          saveSelectedVendors(false);
        }
        if (operation == "SaveDraft") {
          saveSelectedVendors(false);
        }
        setShowTabs(true);
        if (operation == "Summary") {
          saveSelectedVendors(true);
        }
      }
  }
  function returnTotalForSubmit(methodology, costingOption) {
    let total = {};
    let currentMethodology = methodology?.BiddingDetail;

    let selectedVendor = currentMethodology?.SelectedVendor?.find(
      (sv) => (sv.id || sv.CostOptionId) == costingOption.id
    )
      ? currentMethodology.SelectedVendor?.find(
        (sv) => (sv.id || sv.CostOptionId) == costingOption.id
      ).selected
      : [];
    let fieldVendors = selectedVendor
      .filter((f) => f.Costingtype == "Field Cost")
      .map((m) => m.VendorSchema)
      .flat();
    let sudVendors = selectedVendor
      .filter((f) => f.Costingtype != "Field Cost" && f.Costingtype != 'Total Internal Cost' && f.Type != 'Internal Cost')
      .map((m) => m.VendorSchema)
      .flat();
    let InternalCost = selectedVendor
      .filter((f) => f.Costingtype == 'Total Internal Cost')?.map((m) => m.VendorSchema)?.flat();

    if (fieldVendors.length > 0) {
      fieldVendors.forEach((sub) => {
        if (sub.selected) {
          if (sub.name.includes("Other Cost")) {
            total[sub.key] =
              (isNaN(sub.VendorDataUSD) ? 0 : parseFloat(sub.VendorDataUSD)) +
              (total[sub.key] != undefined ? parseFloat(total[sub.key]) : 0);
            total[sub.key + "_Label"] =
              (sub.pleaseSpecify != "" && sub.pleaseSpecify != undefined
                ? sub.pleaseSpecify
                : "") +
              (total[sub.key + "_Label"] != undefined
                ? total[sub.key + "_Label"]
                : "");
          } else {
            total[sub.key] =
              sub.type == "TimingSection"
                ? (isNaN(sub.VendorDataUSD) ? 0 : parseFloat(sub.VendorDataUSD)) +
                (total[sub.key] != undefined ? parseFloat(total[sub.key]) : 0)
                : (isNaN(sub.VendorDataUSD) ? 0 : parseFloat(sub.VendorDataUSD)) +
                (total[sub.key] != undefined ? parseFloat(total[sub.key]) : 0);
          }
        }
      });
    }
    if (InternalCost.length > 0) {
      InternalCost.forEach((sub) => {
        if (methodology.Label == "Online Self Completion Survey") {
          total["BufferSampleCost"] =
            parseFloat(isNaN(sub.VendorDataUSD) ? 0 : sub.VendorDataUSD) +
            (total["BufferSampleCost"] != undefined
              ? parseFloat(total["BufferSampleCost"])
              : 0);
        }
        else {
          total[sub.key] = parseFloat(isNaN(sub.VendorDataUSD) ? 0 : sub.VendorDataUSD);
        }

      })
    }

    if (sudVendors.length > 0) {

      sudVendors.forEach((sub) => {
        if (sub.selected) {
          if (sub.key.includes("otherSUD")) {
            total["OtherSUDCost1"] =
              parseFloat(isNaN(sub.VendorDataUSD) ? 0 : sub.VendorDataUSD) +
              (total["OtherSUDCost1"] != undefined
                ? parseFloat(total["OtherSUDCost1"])
                : 0);
            total["OtherSUDCost1_Label"] =
              (sub.pleaseSpecify != "" && sub.pleaseSpecify != undefined
                ? sub.pleaseSpecify
                : ""
                  ? sub.pleaseSpecify
                  : "") +
              " " +
              (total["OtherSUDCost1_Label"] != undefined
                ? total["OtherSUDCost1_Label"]
                : "");
          } else {
            if (sub.name.includes("Other Cost")) {
              total[sub.key] = parseFloat(isNaN(sub.VendorDataUSD) ? 0 : sub.VendorDataUSD);
              total[sub.key + "_Label"] =
                sub.pleaseSpecify != "" && sub.pleaseSpecify != undefined
                  ? sub.pleaseSpecify
                  : "";
            } else {
              // total[sub.key] = sub.type == "TimingSection" ? sub.VendorDataUSD : sub.VendorDataUSD
              total[sub.key] = parseFloat(isNaN(sub.VendorDataUSD) ? 0 : sub.VendorDataUSD);
            }
          }
        }
      });
    }
    return total;
  }
  function saveSelectedVendors(IsSubmit) {
    var error = 0;
    var rounderror = 0;
    let vendor = false;
    let deadLine = false;
    let tempData = {};
    let currentProfile = _.last(project?.CostingProfiles);
    let selectedVendors = vendorSelection.filter(
      (vs) => vs.IsSelected == true || vs.IsSUD == true
    );
    tempData.id = currentProfile.id;
    tempData.IsSubmit = IsSubmit;
    if (!currentProfile.VendorBidDeadLine) {
      deadLine = true;
    }
    currentProfile.CountrySpecs.forEach(c => {
      c.MethodologySpecs.forEach(m => {
        if (
          m.CostingType == 3 &&
          !m.NotApplicable
        ) {
          if (m.InvitedVendors.filter(a => !a.IsDeleted)?.length != 0) {
            vendor = true;
          }
          if (splitdata?.filter(a => (a.MethodologySpecId == m.id)).every(x => x.RoundClosedOn)) {
            rounderror = rounderror + 1
          }
          m.CostingOptions?.filter(x => x.Selected).forEach(co => {
            if (vendorSelection.filter(a => a.MethodologyID == m.id && a.Costingtype == "Field Cost" && a.IsSelected && a.CostingOptionID == co.id).length > 0) {
              let selectedsize = vendorSelection.filter(a => a.MethodologyID == m.id && a.Costingtype == "Field Cost" && a.IsSelected && a.CostingOptionID == co.id).map(a => a.AchivableSamples).reduce((a, b) => a + b + 0, 0)
              if (!(selectedsize == parseInt(co.CostingOptionData.sampleSizeMain || co.CostingOptionData.sampleSize || co.CostingOptionData.numberOfGroup))) {
                error = error + 1;
              }
            }
          }
          )
        }
      })
    })

    tempData.MethodologySpecs = currentProfile.CountrySpecs.map(
      (country, cIndex) =>
        country.MethodologySpecs.filter(
          meth => meth.CostingType == 3 && !meth.NotApplicable).map(
            (ms, mIndex) => {
              return {
                id: ms.id,
                VendorType: ms.VendorType,
                SelectedVendor: ApplicableForCostSheet(ms) ? ms.CostingOptions?.filter(x => x.Selected).map((co, coIndex) => {
                  return {
                    Name: co.Name,
                    id: co.id,
                    selected: vendorSelection.filter(
                      (vs) =>
                        (vs.IsSelected == true || vs.IsSUD == true) &&
                        vs.CostingOptionID == co.id
                    ),
                  };
                }) : [],
                CostingOptions: ApplicableForCostSheet(ms) ? ms.CostingOptions?.filter(x => x.Selected).map((co, coIndex) => {
                  return {
                    Name: co.Name,
                    id: co.id,
                    SelectedVendorName: _.uniq(selectedVendors?.filter(x => x.MethodologyID == ms.id)
                      ?.map((vs) => {
                        let res = vs.Vendor ? (vs.Vendor + " " + vs.Costingtype) : "";
                        return res;
                      }))
                      .toString(),
                    SelectedVendorCost: returnTotalForSubmit(ms, co),
                  };
                }) : [],
              };
            })
    ).flat();

    if (!vendor) {
      toastr.error("Please select atleast one vendor for atleast one methodology")
    }
    else
      if (!rounderror) {
        toastr.error("Please close all the rounds for atleast one methodology")
      }
      else {
        if (!IsSubmit) {
          axios.post("vendors/project/summary", tempData)
            .then((res) => {
              toastr.success("Bidding Saved")
            })
            .catch((error) => {
              console.log("Error Occured while submitting the selection of Vendors");
            });
        }
        else
          if (deadLine) {
            toastr.error("Please Select the Deadline before Submit")
          }
          else {
            dispatch(appActions.recordLoadStart());
            axios.post("vendors/project/summary", tempData)
              .then((res) => {
                toastr.success("Bidding Saved")
                if (IsSubmit) {
                  createSheet();
                }
                dispatch(appActions.recordLoadEnd());
              })
              .catch((error) => {
                console.log("Error Occured while submitting the selection of Vendors");
                dispatch(appActions.recordLoadEnd());
              });
          }
      }
  }

  const rowStyleVendorSelection = (row, rowIndex) => {
    {
      return { backgroundColor: "#ebebeb" };
    }
  };
  function toggleSM(tab) {
    setActiveTabCo(0);
    if (activeTabSM !== tab) {
      setActiveTabSM(tab);
      setactiveCoId(
        project.CostingProfiles[project?.CostingProfiles?.length - 1]
          .CountrySpecs[activeTabFC].MethodologySpecs[tab].CostingOptions?.filter(x => x.Selected)[0].id
      );
      setModelSelectedRow([]);
    }
  }

  function toggleSummary(tab) {
    setActiveTabCo(0);
    if (activeTabSummary !== tab) {
      setactiveTabSummary(tab);
    }
  }

  function toggle(tab) {
    setActiveTabCo(0);
    if (activeTabFC !== tab) {
      setActiveTabFC(tab);
      var indexsm = project.CostingProfiles[
        project?.CostingProfiles?.length - 1
      ].CountrySpecs[tab].MethodologySpecs.filter(
        (y, index) => y.CostingType == 3 && !y.NotApplicable
      )[0]?.id;
      setActiveTabSM(
        project.CostingProfiles[
          project?.CostingProfiles?.length - 1
        ].CountrySpecs[tab].MethodologySpecs.findIndex((m) => m.id == indexsm)
      );
      setactiveCoId(
        project.CostingProfiles[
          project?.CostingProfiles?.length - 1
        ].CountrySpecs[tab].MethodologySpecs.filter((m) => m.id == indexsm)[0]
          ?.CostingOptions?.filter(x => x.Selected)[0].id
      );
    }
  }
  function Country(v) {
    var selectedcountry = [];
    selectedcountry.push(v);
    setcountry(selectedcountry);
  }

  const styleModelBody = {
    minHeight: "400px",
    overflow: "hidden !important",
  };
  useEffect(() => {
    setCostingProfileIndex(project?.CostingProfiles?.length - 1);
    setViewOnlyMode((["2", "3", "4", "5", "6"].indexOf(_.last(project?.CostingProfiles).ProfileStatus) != -1) ||
      (project.CostingProfiles[project?.CostingProfiles?.length - 1]?.VenodrBidDeadLineStatus == "close") ||
      (project?.ProjectStatus == 7)
      ? true : false)
    setDisableSelection((project?.ProjectStatus == 7)
      || (["2", "3", "4", "5", "6"].indexOf(_.last(project?.CostingProfiles).ProfileStatus) != -1)
      ? true : false)
  }, []);

  useEffect(() => {
    if (
      project?.CostingProfiles[
        project?.CostingProfiles?.length - 1
      ]?.CountrySpecs.map(
        (cs) =>
          cs?.MethodologySpecs.filter((y, index) => y.CostingType == 3 && !y.NotApplicable).length
      ).reduce((r, e) => r + e) > 0
    ) {
      //Finding First Country for Vendor Bidding
      var indexfc = project.CostingProfiles[
        project?.CostingProfiles?.length - 1
      ].CountrySpecs.filter(
        (cs) =>
          cs.MethodologySpecs.filter((sm) => sm.CostingType == 3 && !sm.NotApplicable).length > 0
      )[0].id;
      setActiveTabFC(
        project.CostingProfiles[
          project?.CostingProfiles?.length - 1
        ].CountrySpecs.findIndex((m) => m.id == indexfc)
      );

      //Finding First Methodology for Vendor Bidding
      var indexsm = project.CostingProfiles[
        project?.CostingProfiles?.length - 1
      ].CountrySpecs.filter((m) => m.id == indexfc)[0].MethodologySpecs.filter(
        (y, index) => y.CostingType == 3
      )[0]?.id;
      setActiveTabSM(
        project.CostingProfiles[
          project?.CostingProfiles?.length - 1
        ].CountrySpecs.filter(
          (m) => m.id == indexfc
        )[0].MethodologySpecs.findIndex((m) => m.id == indexsm)
      );
      setactiveCoId(
        project.CostingProfiles[
          project?.CostingProfiles?.length - 1
        ].CountrySpecs.filter(
          (m) => m.id == indexfc
        )[0].MethodologySpecs.filter((m) => m.id == indexsm)[0]
          ?.CostingOptions?.filter(x => x.Selected)[0].id
      );

      setActiveTabCo(0);
    }
  }, []);
  useEffect(() => {
    ComparisonViewData();
    FinalTabRender();
  }, []);
  useEffect(() => {
    var action = false;
    project.CostingProfiles[
      project?.CostingProfiles?.length - 1
    ].CountrySpecs.forEach((a) =>
      a.MethodologySpecs.forEach((b) => {
        if (b.CostingType == 3 && !b.NotApplicable) {
          b?.BiddingDetail?.SelectedVendor?.map((select) => {
            if (select.selected.length > 0) {
              action = true;
            }
          });
        }
      })
    );
    // if (_.last(project.CostingProfiles).CostingsSheetId) {
    //   setShowTabs(true)
    // }
    if (_.last(project.CostingProfiles).ConfirmCosting) {
      setShowTabs(true)

      var tabs = []
      if (_.last(project.CostingProfiles)
        .CountrySpecs &&
        _.last(
          project.CostingProfiles
        ).CountrySpecs.map(
          (cs) =>
            cs?.MethodologySpecs?.filter((y, index) => y.CostingType == 3 && !y.NotApplicable).length > 0
        )?.filter(x => x).length > 1) {
        tabs = Tabdata;

      } else {
        tabs = Tabdata.filter((a) => a.value != "v3");
      }
      setactiveTabSummary(tabs.length ? tabs.length - 1 : 0)
    }
    if (action == true) {
      setShowTabs(true);
    }
  }, [project]);

  useEffect(() => {
    let bidVendors = [];
    let currentProfile =
      project.CostingProfiles[project?.CostingProfiles?.length - 1];
    // let currentMethodology=currentProfile?.CountrySpecs[activeTabFC]?.MethodologySpecs[activeTabSM]

    let key = 0;
    currentProfile.CountrySpecs.map((country, cIndex) => {
      country.MethodologySpecs.map((currentMethodology, mIndex) => {
        if (currentMethodology.CostingType == 3 && !currentMethodology.NotApplicable) {
          currentMethodology.CostingOptions?.filter(x => x.Selected).map((co, coIndex) => {
            // let currentCO=currentMethodology.CostingOptions?.filter(x=> x.Selected)[0]
            if (currentMethodology) {
              if (currentMethodology.BiddingDetail) {
                currentMethodology.InvitedVendors?.filter(a => !a.IsDeclined)?.map((iv, i) => {
                  if (iv.VendorCosts.some((vc) => vc.CostOptionId == co.id)) {
                    let invitedVendorCost = iv.VendorCosts.find(
                      (vc) => vc.CostOptionId == co.id
                    );
                    // if(iv.ServiceType==2 && )
                    if ((invitedVendorCost.IsSelected == true && iv.ServiceType == 0) || iv.ServiceType == 1 || iv.ServiceType == 2) {
                      let vendorCostData = invitedVendorCost.CostingsData.filter(
                        (cd) => cd.soid != 14
                      );
                      let groups = _.uniq(vendorCostData.map((cd) => cd.group));
                      //for full service group filter
                      if (iv.ServiceType == 2 && !invitedVendorCost.IsSelected) {
                        groups = groups.filter(grp => grp != "Field Cost")
                      }
                      groups.map((group, index) => {
                        let tempVendor = {};
                        tempVendor.CostingOptionID = invitedVendorCost.CostOptionId;
                        tempVendor.IsSelected = group == "Field Cost" ? invitedVendorCost.IsSelected : false;
                        tempVendor.Costingtype = group;

                        tempVendor.TargetSamples = splitdata?.find((sd) => sd.MethodologySpecId == currentMethodology.id && sd.id == invitedVendorCost.RoundId)?.SampleDistributionMasters?.map((master) => {
                          if (master.CostingOptionId == co.id) {
                            return master.SampleDistributionDetails.find(
                              (detail) => detail.id == invitedVendorCost.SampleDistributionDetailsId)
                              ? master.SampleDistributionDetails.find(
                                (detail) => detail.id == invitedVendorCost.SampleDistributionDetailsId)?.DistributionSize
                              : master.TargetSamples;
                          }
                        });
                        tempVendor.SampleSize = co.CostingOptionData.sampleSizeMain ? co.CostingOptionData.sampleSizeMain : (co.CostingOptionData.numberOfGroup ? co.CostingOptionData.numberOfGroup : 0);
                        tempVendor.AchivableSamples = group == "Field Cost" ?
                          invitedVendorCost.AchivableSamples : tempVendor.SampleSize;
                        tempVendor.Vendor = iv.label;
                        tempVendor.InvitedVendorID = iv.VendorId;
                        //todo:temp usd
                        // console.log(vendorCostData);
                        // console.log(group);

                        tempVendor.CostFromVendor = vendorCostData
                          ?.filter((cost) => (cost.group == group && cost.type != "TimingSection" && cost.selected))
                          ?.map((cost) => cost?.VendorDataUSD).filter(x => x).length > 0 ?
                          vendorCostData
                            .filter((cost) => (cost.group == group && cost.type != "TimingSection" && cost.selected))
                            .map((cost) => { if (cost.VendorDataUSD) { return cost.VendorDataUSD } else { return 0 } })
                            .reduce((a, b) => parseFloat(a) + parseFloat(b) + 0) : 0;
                        tempVendor.ConstantCostFromVendor = vendorCostData
                          ?.filter((cost) => (cost.group == group && cost.type != "TimingSection" && cost.selected))
                          ?.map((cost) => cost?.VendorDataUSD).filter(x => x).length > 0 ?
                          vendorCostData
                            .filter((cost) => (cost.group == group && cost.type != "TimingSection" && cost.selected))
                            .map((cost) => { if (cost.VendorDataUSD) { return cost.VendorDataUSD } else { return 0 } })
                            .reduce((a, b) => parseFloat(a) + parseFloat(b) + 0) : 0;
                        // tempVendor.CostFromVendor =
                        // tempVendor.CostFromVendor/(activeDropCurrency?.ConversionRateToLocal != 0
                        // ? activeDropCurrency?.ConversionRateToLocal
                        // : 1)
                        tempVendor.key = key;
                        tempVendor.CostFromVendor = tempVendor.CostFromVendor /
                          (_.last(project.CostingProfiles).CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
                            .SelectedCurrency)?.ConversionRateToLocal
                        tempVendor.CPI = _.round(
                          (tempVendor.Costingtype == "Field Cost"
                            ? tempVendor?.AchivableSamples && tempVendor?.AchivableSamples != 0 ?
                              (tempVendor.CostFromVendor / tempVendor.AchivableSamples) : 0
                            : (tempVendor.CostFromVendor / tempVendor.SampleSize)), 2);
                        tempVendor.VendorID = iv.VendorId;
                        tempVendor.CityCoverage = co.CostingOptionData
                          ?.citiesCoverage
                          ? co.CostingOptionData.citiesCoverage
                            .map((city) => city.value)
                            .toString()
                          : "";
                        tempVendor.LOI = co.CostingOptionData.lengthOfInterview;
                        tempVendor.CountryIndex = cIndex;
                        tempVendor.Country = country.CountryName;
                        tempVendor.ProfileID = currentProfile.id;
                        tempVendor.MethodologyID = iv.MethodologySpecId;
                        tempVendor.IsSUD = iv.IsSUD?.includes(group) ? true : ((iv.label.includes("TCS") || iv.label.includes("Internal")) ? true : false);
                        tempVendor.Methodology = currentMethodology.Label;
                        tempVendor.CostingOption = co.Name;
                        //to do cost
                        // tempVendor.TotalCostusd=0
                        // tempVendor.FinalCPIusd=0
                        // tempVendor.TotalInternalCost=0
                        tempVendor.CPIusd = _.round(
                          (group == "Field Cost"
                            ? tempVendor.AchivableSamples && tempVendor.AchivableSamples != 0 ?
                              (tempVendor.ConstantCostFromVendor / tempVendor.AchivableSamples) : 0
                            : (tempVendor.ConstantCostFromVendor / tempVendor.SampleSize)), 2);
                        tempVendor.VendorSchema = vendorCostData.filter(
                          (cost) => cost.group == group
                        );

                        bidVendors.push(tempVendor);
                        key += 1;
                      });
                    }
                  }
                });
                //INFO:selected vendors if exist already
                if (currentMethodology.BiddingDetail.SelectedVendor) {
                  setShowTabs(true);
                  let currentSelected =
                    currentMethodology.BiddingDetail.SelectedVendor.find(
                      (sv) => (sv.id = co.id)
                    );
                  bidVendors = bidVendors.map((bid) => {
                    currentSelected?.selected?.map((cs) => {
                      if (
                        bid.VendorID == cs.VendorID &&
                        bid.Costingtype == cs.Costingtype
                      ) {
                        bid = {
                          ...bid,
                          IsSUD: cs.IsSUD,
                          IsSelected: cs.IsSelected,
                        };
                      } else {
                        bid = { ...bid };
                      }
                    });
                    return bid;
                  });
                  // INFO:If it has Internal Cost
                  if (
                    currentSelected?.selected.filter(
                      (cs) => cs.Type == "Total Internal Cost" || cs.Type == "Internal Cost"
                    ).length
                  ) {
                    currentSelected?.selected.filter(
                      (cs) => cs.Type == "Total Internal Cost" || cs.Type == "Internal Cost"
                    ).forEach(x => {
                      bidVendors.push(x);
                    })
                  }
                }
                setVendorSelection(bidVendors.flat());
              }
            }
          });
        }
      });
    });
  }, [project]);

  const project = useSelector(
    ({ currentProject }) => currentProject.newProject
  );

  function Savevendor() {
    var c = props.saveselectedrows;
    if (
      project.CostingProfiles[project?.CostingProfiles?.length - 1]
        .CostingDatum != null &&
      project.CostingProfiles[project?.CostingProfiles?.length - 1].CostingDatum
        .CostingData.length > 0
    ) {
      var clone =
        project.CostingProfiles[project?.CostingProfiles?.length - 1]
          .CostingDatum.CostingData;
      props.saveselectedrows.forEach(
        (Y) =>
        (Object.entries(
          Object.entries(
            clone
              .filter((x) => x.Code == Y.Code)
              .map((s) =>
                Object.entries(s.rows).filter(
                  ([fkey, fvalue]) => fvalue.id == 41
                )
              )
          )[0][1][0][1].cells
        ).filter((d) => d[1].coid == Y.CostingOptionID)[0][1]["value"] =
          Y.CostFromVendor)
      );
      props.saveselectedrows.forEach(
        (Y) =>
        (Object.entries(
          Object.entries(
            clone
              .filter((x) => x.Code == Y.Code)
              .map((s) =>
                Object.entries(s.rows).filter(
                  ([fkey, fvalue]) => fvalue.id == 41
                )
              )
          )[0][1][0][1].cells
        ).filter((d) => d[1].coid == Y.CostingOptionID)[0][1]["text"] =
          Y.CostFromVendor.toString())
      );

      const profileTemp = update(
        project.CostingProfiles[project?.CostingProfiles?.length - 1],
        {
          CostingDatum: {
            CostingData: {
              $set: JSON.stringify(clone),
            },
          },
        }
      );

      axios
        .put(
          baseurl +
          "costingprofiles/updateCostingInput/" +
          project.CostingProfiles[project?.CostingProfiles?.length - 1].id,
          profileTemp,
          {
            headers: { "auth-token": localStorage.getItem("auth-token") },
          }
        )
        .then((res) => {
          dispatch(
            currentCostingActions.getCosting(
              project.CostingProfiles[project?.CostingProfiles?.length - 1].id
            )
          );
        });
    }

    dispatch(proposalActions.saveProfile(props.saveselectedrows));
    toastr.success("Vendors Saved");
  }
  const tableColumnsSummary = [
    {
      dataField: "Country",
      text: "Market",
      sort: true,
      formatter: profileMethodologyFormatter,
      formatExtraData: {
        dataField: "Country",
        color: false,
      },
      filter: selectFilter({
        options: getProfileSelectOptions,
      }),
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "120px", padding: "2px", textAlign: "center" };
      },
    },
    {
      dataField: "MethodologyName",
      text: "Methodology",
      sort: true,
      filter: textFilter(),
      filter: selectFilter({
        options: getProfileSelectOptions,
      }),
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "120px", padding: "2px", textAlign: "center" };
      },
    },
    {
      dataField: "SampleType",
      text: "Sample Type",
      sort: true,
      filter: selectFilter({
        options: getProfileSelectOptions,
      }),
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "120px", padding: "2px", textAlign: "center" };
      },
    },
    {
      dataField: "VendorName",
      text: "Vendor",
      sort: true,
      filter: selectFilter({
        options: getProfileSelectOptions,
      }),
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "120px", padding: "2px", textAlign: "center" };
      },
    },
    {
      dataField: "LOI",
      text: "LOI",
      sort: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "60px", padding: "2px", textAlign: "center" };
      },
    },
    {
      dataField: "FWD",
      text: "FWD",
      sort: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "60px", padding: "2px", textAlign: "center" };
      },
    },
    {
      dataField: "FinalSampleSize",
      text: _.last(project.CostingProfiles).CountrySpecs[activeTabFC].MethodologySpecs[
        activeTabSM
      ].CostingOptions?.filter(x => x.Selected)[0].CostingOptionSchema.find(
        (a) => a.id == "sampleSizeMain"
      )
        ? "Sample Size Main "
        : "Number Of Groups",
      sort: true,
      formatter: AchievableSampleSizeFormatter,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "120px", padding: "2px", textAlign: "center" };
      },
    },
    {
      dataField: "CPI",
      text: "CPI Vendor",
      sort: true,
      formatter: CPIFormatter,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "60px", padding: "2px", textAlign: "center" };
      },
    },
    {
      dataField: "Total",
      text: "Vendor Total",
      sort: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "80px", padding: "2px", textAlign: "center" };
      },
    },
    {
      dataField: "CPIFinal",
      text: "CPI With Other Cost",
      sort: true,
      formatter: CPIFormatter,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "80px", padding: "2px", textAlign: "center" };
      },
    },
    {
      dataField: "FieldCosts",
      text: "Field Costs",
      sort: true,
      editable: false,
      hidden: isHidden("FieldCosts"),
      subTotals: true,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "80px", padding: "2px", textAlign: "center" };
      },
    },
    {
      dataField: "SetUpDeliveryCosts",
      text: "Set-up & Delivery Costs",
      sort: true,
      editable: false,
      hidden: isHidden("SetUpDeliveryCosts"),
      subTotals: true,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "80px", padding: "2px", textAlign: "center" };
      },
    },
    {
      dataField: "TranslationCosts",
      text: "Translation Costs",
      sort: true,
      editable: false,
      hidden: isHidden("TranslationCosts"),
      subTotals: true,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "80px", padding: "2px", textAlign: "center" };
      },
    },
    {
      dataField: "Other Costs",
      text: "OtherCosts",
      sort: true,
      editable: false,
      hidden: isHidden("OtherCosts"),
      subTotals: true,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "80px", padding: "2px", textAlign: "center" };
      },
    },
    {
      dataField: "CSOOPCosts",
      text: "CS OOP",
      sort: true,
      editable: false,
      hidden: isHidden("CSOOPCosts"),
      subTotals: true,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "80px", padding: "2px", textAlign: "center" };
      },
    },
    {
      dataField: "CSTimeCosts",
      text: "CS Time Costs",
      sort: true,
      editable: false,
      hidden: isHidden("CSTimeCosts"),
      subTotals: true,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "80px", padding: "2px", textAlign: "center" };
      },
    },
    {
      dataField: "VATOtherTax",
      text: "VAT/Other Tax Adjustment",
      sort: true,
      editable: false,
      hidden: isHidden("VATOtherTax"),
      subTotals: true,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "80px", padding: "2px", textAlign: "center" };
      },
    },
    {
      dataField: "TotalFinal",
      text: "Total With Other Costs",
      sort: true,
      editable: false,
      hidden: isHidden("TotalFinal"),
      subTotals: true,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "80px", padding: "2px", textAlign: "center" };
      },
    },
  ];

  const tableColumnsVendorSelection = [

    {
      dataField: "Costingtype",
      text: "Costing Type",
      editable: false,
    },
  ];

  const tableColumnsSUD = [
    // {
    //   dataField: "Costingtype",
    //   text: "Costing Type",
    //   editable: false,
    //   field: true,
    //   sud: true
    // },
    {
      dataField: "Vendor",
      text: "Vendor Name",
      sort: true,
      editable: false,
      field: true,
      sud: true,
    },
    {
      dataField: "TargetSamples",
      text: "Target Samples",
      sort: true,
      editable: false,
      field: true,
      sud: false,
    },
    {
      dataField: "AchivableSamples",
      text: "Achievable Samples",
      sort: true,
      editable: false,
      field: true,
      sud: false,
    },
    {
      dataField: "CostFromVendor",
      text: "Cost",
      editable: false,
      field: true,
      sud: true,
    },
    {
      dataField: "SampleSize",
      text: project.CostingProfiles[
        project?.CostingProfiles?.length - 1
      ].CountrySpecs[activeTabFC].MethodologySpecs[
        activeTabSM
      ].CostingOptions?.filter(x => x.Selected)[0].CostingOptionSchema.find(
        (a) => a.id == "sampleSizeMain"
      )
        ? "Sample Size Main"
        : "Number Of Groups",
      editable: false,
      field: false,
      sud: false,
    },

    {
      dataField: "VendorID",
      text: "Vendor ID",
      sort: true,
      editable: false,
      hidden: true,
      field: true,
      sud: true,
    },
    {
      dataField: "key",
      text: "key",
      sort: true,
      editable: false,
      hidden: true,
      field: true,
      sud: true,
    },
    {
      dataField: "CostingOptionID",
      text: "CostingOptionID",
      sort: true,
      editable: false,
      hidden: true,
      field: true,
      sud: true,
    },
    {
      dataField: "cid",
      text: "cid",
      sort: true,
      editable: false,
      hidden: true,
      field: true,
      sud: true,
    },
    {
      dataField: "CostingOptionindex",
      text: "CostingOptionindex",
      sort: true,
      editable: false,
      hidden: true,
      field: true,
      sud: true,
    },
    {
      dataField: "MethodologyIndex",
      text: "M Index",
      sort: true,
      editable: false,
      hidden: true,
      field: true,
      sud: true,
    },
    {
      dataField: "MethodologyID",
      text: "MethodologyID",
      sort: true,
      editable: false,
      hidden: true,
      field: true,
      sud: true,
    },
    {
      dataField: "ProfileID",
      text: "ProfileID",
      sort: true,
      editable: false,
      hidden: true,
      field: true,
      sud: true,
    },
    {
      dataField: "CountryIndex",
      text: "C Index",
      sort: true,
      editable: false,
      hidden: true,
      field: true,
      sud: true,
    },
    // {
    //   dataField: "LOI",
    //   text: "LOI",
    //   sort: true,
    //   editable: false,
    //   field: true,
    //   sud: true
    // },

    {
      dataField: "CPI",
      text: "CPI",
      formatter: CPIFormatter,
      sort: true,
      editable: false,
      field: true,
      sud: true,
    },
  ];
  const tableColumns = [
    {
      dataField: "Costingtype",
      text: "Costing Type",
      editable: false,
      field: true,
      sud: true,
    },
    {
      dataField: "ActualSampleSize",
      text: project.CostingProfiles[
        project?.CostingProfiles?.length - 1
      ].CountrySpecs[activeTabFC].MethodologySpecs[
        activeTabSM
      ].CostingOptions?.filter(x => x.Selected)[0].CostingOptionSchema.find(
        (a) => a.id == "sampleSizeMain"
      )
        ? "Sample Size Main "
        : "Number Of Groups",
      editable: false,
      field: true,
      sud: false,
    },

    {
      dataField: "SampleSize",
      text:
        project.CostingProfiles[project?.CostingProfiles?.length - 1]
          .CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
          .SplitSample != 1
          ? project.CostingProfiles[
            project?.CostingProfiles?.length - 1
          ].CountrySpecs[activeTabFC].MethodologySpecs[
            activeTabSM
          ].CostingOptions?.filter(x => x.Selected)[0].CostingOptionSchema.find(
            (a) => a.id == "sampleSizeMain"
          )
            ? "Sample Size Main "
            : "Number Of Groups"
          : project.CostingProfiles[
            project?.CostingProfiles?.length - 1
          ].CountrySpecs[activeTabFC].MethodologySpecs[
            activeTabSM
          ].CostingOptions?.filter(x => x.Selected)[0].CostingOptionSchema.find(
            (a) => a.id == "sampleSizeMain"
          )
            ? "Sample Size Main Split"
            : "Number Of Groups Split",
      editable: false,
      field: true,
      sud: true,
    },
    {
      dataField: "Booster",
      text: "Booster",
      editable: false,
      field: true,
      sud: false,
    },
    {
      dataField: "CityCoverage",
      text: "City Coverage",
      editable: false,
      field: true,
      sud: false,
    },

    {
      dataField: "Vendor",
      text: "Vendor",
      sort: true,
      editable: false,
      field: true,
      sud: true,
    },
    {
      dataField: "VendorID",
      text: "Vendor ID",
      sort: true,
      editable: false,
      hidden: true,
      field: true,
      sud: true,
    },
    {
      dataField: "key",
      text: "key",
      sort: true,
      editable: false,
      hidden: true,
      field: true,
      sud: true,
    },
    {
      dataField: "CostingOptionID",
      text: "CostingOptionID",
      sort: true,
      editable: false,
      hidden: true,
      field: true,
      sud: true,
    },
    {
      dataField: "cid",
      text: "cid",
      sort: true,
      editable: false,
      hidden: true,
      field: true,
      sud: true,
    },
    {
      dataField: "CostingOptionindex",
      text: "CostingOptionindex",
      sort: true,
      editable: false,
      hidden: true,
      field: true,
      sud: true,
    },
    {
      dataField: "MethodologyIndex",
      text: "M Index",
      sort: true,
      editable: false,
      hidden: true,
      field: true,
      sud: true,
    },
    {
      dataField: "MethodologyID",
      text: "MethodologyID",
      sort: true,
      editable: false,
      hidden: true,
      field: true,
      sud: true,
    },
    {
      dataField: "ProfileID",
      text: "ProfileID",
      sort: true,
      editable: false,
      hidden: true,
      field: true,
      sud: true,
    },
    {
      dataField: "CountryIndex",
      text: "C Index",
      sort: true,
      editable: false,
      hidden: true,
      field: true,
      sud: true,
    },
    {
      dataField: "LOI",
      text: "LOI",
      sort: true,
      editable: false,
      field: true,
      sud: true,
    },
    {
      dataField: "CostFromVendor",
      text: "Cost From Vendor",
      editable: false,
      field: true,
      sud: true,
    },
    {
      dataField: "CPI",
      text: "CPI Vendor",
      formatter: CPIFormatter,
      sort: true,
      editable: false,
      field: true,
      sud: true,
    },
  ];

  function vendorSelectionParent() {
    let activeCoId =
      project.CostingProfiles[project?.CostingProfiles?.length - 1]
        .CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions?.filter(x => x.Selected)[
        activeTabCo
      ].id;
    let invitedVendorCost = project.CostingProfiles[
      project?.CostingProfiles?.length - 1
    ].CountrySpecs[activeTabFC].MethodologySpecs[
      activeTabSM
    ].InvitedVendors?.filter(a => !a.IsDeclined)?.map((invited) => invited.VendorCosts);

    let mainStubs = invitedVendorCost
      .flat()
      .filter((vc) => vc.CostOptionId == activeCoId)
      .map((c) => c.CostingsData)
      .flat()
      .filter(
        (f) =>
          f.group != "TimingSection" && f.key != "TimingSection" && f.soid != 14
      )
      .map((m) => m.group);
    var uniquemainstubs = _.uniq(mainStubs)?.sort()
    var mstubs = [];
    if (uniquemainstubs?.includes("Field Cost")) {
      mstubs.push("Field Cost");
      uniquemainstubs.filter(x => x != "Field Cost" && x).forEach(y => {
        mstubs.push(y);
      })
    }
    else {
      mstubs = uniquemainstubs
    }

    return mstubs;
  }

  function copyonmethlevel() {
    if (country.length == 0 && option == undefined) {
      toastr.error("Please select the values ");
    } else {
      var project1 = _.cloneDeep(
        project.CostingProfiles[project?.CostingProfiles?.length - 1]
          .CountrySpecs
      );
      var additionalcost = project.CostingProfiles[
        project?.CostingProfiles?.length - 1
      ].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].Vendors[
        activeTabV
      ].CostingData.filter((tc) => tc.soid == 14 && tc.coid == option.id)[0]
        .AdditionalCost;
      var finalcost = project.CostingProfiles[
        project?.CostingProfiles?.length - 1
      ].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].Vendors[
        activeTabV
      ].CostingData.filter((tc) => tc.soid == 14 && tc.coid == option.id)[0]
        .FinalCost;

      var Costloop = ["AdditionalCost", "FinalCost"];
      Costloop.forEach((cost) =>
        country[0].forEach((c) =>
          project1.forEach((co) => {
            if (co.CountryName == c.value) {
              if (co.MethodologySpecs[activeTabSM].Vendors.length > 0) {
                co.MethodologySpecs[activeTabSM].Vendors.forEach((v) => {
                  if (cost == "AdditionalCost") {
                    v.CostingData.filter(
                      (tc) => tc.soid == 14
                    )[0].AdditionalCost = additionalcost;
                  } else if (cost == "FinalCost") {
                    v.CostingData.filter((tc) => tc.soid == 14)[0].FinalCost =
                      finalcost;
                  }
                });
              }
            }
          })
        )
      );
      const newProposal = update(
        project.CostingProfiles[project?.CostingProfiles?.length - 1],
        {
          CountrySpecs: { $set: project1 },
        }
      );
      updateproject(newProposal);
      toastr.success("Copied Successfully");
      setcountrymodal(false);
    }
  }

  function togglecostingOption(tab) {
    setModelSelectedRow([]);
    if (activeTabCo !== tab) {
      setActiveTabCo(tab);
    }
  }

  function profileMethodologyFormatter(cell, row, rowIndex, formatExtraData) {
    let colors = [
      "#8064A2",
      "#0099C6",
      "#B7BF12",
      "#3B3EAC",
      "#DD4477",
      "#22AA99",
      "#6633CC",
      "#252d47",
      "#8B0707",
      "#329262",
      "#007681",
      "#3B3EAC",
      "#3e95cd",
      "#8e5ea2",
      "#3cba9f",
      "#c45850",
      "#C0504D",
      "#71B2C9",
      "#888B8D",
      "#316395",
      "#994499",
      "#E67300",
      "#5574A6",
    ];
    let selectedColor =
      formatExtraData && formatExtraData["color"]
        ? colors[row.RowIndex]
        : "none";
    return (
      <span>
        <strong style={{ color: selectedColor }}>
          {formatExtraData && formatExtraData["dataField"] == "Country"
            ? getLabel(cell)
            : cell}
        </strong>
      </span>
    );
  }

  function CPIFormatter(cell, row) {
    return (
      <span>
        <strong style={{ color: row["Color"] }}>{cell}</strong>
      </span>
    );
  }

  function AchievableSampleSizeFormatter(cell, row) {
    return (
      <Input
        type="number"
        id={"Achievable_" + row.RecordIndex}
        name="AchievableSampleSize"
        value={row["FinalSampleSize"]}
        disabled
      />
    );
  }

  function getProfileSelectOptions(cell) {
    let values = [];
    if (ComparisonViewData().length > 0)
      values = Array.from(
        new Set(ComparisonViewData().map((x) => x[cell.dataField]))
      );
    const selectOptions = {};
    values.forEach((x, i) => {
      selectOptions[x] = cell.dataField == "Country" ? getLabel(x) : x;
    });
    return selectOptions;
  }

  const GetMethodWiseAdditionalCost = (method, indexM) => {
    return method["OtherCosts"].map((x, indexOC) => {
      return (
        <div key={"row1_" + indexOC}>
          {" "}
          {x.header && (
            <Row form>
              <Col md={6} style={{ padding: "0.3rem" }}>
                <h6 style={{ color: "black" }}>{x.header}</h6>
              </Col>
              <Col md={6} style={{ padding: "0.3rem" }}>
                <h5 style={{ color: "blue" }}>
                  {" "}
                  Sub Total {method["SubTotals"][x.header]}
                </h5>
              </Col>
            </Row>
          )}
          <Row form>
            <Col md={6} style={{ padding: "0.3rem" }}>
              {x.text}
            </Col>
            <Col md={4} style={{ padding: "0.3rem" }}>
              <Input
                type="number"
                name={x.dataField}
                value={method["OtherCosts"][indexOC][x.dataField]}
                onChange={(e) =>
                  dispatch(
                    proposalActions.otherCostChange(
                      activeTabCountryPopup,
                      indexM,
                      indexOC,
                      e
                    )
                  )
                }
              />
            </Col>
            <Col md={1} style={{ padding: "0.3rem" }}>
              USD
            </Col>
          </Row>
        </div>
      );
    });
  };

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div>
        <button
          className="btn btn-secondary mt-2"
          onClick={() => setModelState(!modelState)}
        >
          Manage Other Costs
        </button>
        {/*
                <button className="btn btn-secondary mt-2" onClick={handleClick}>
                    Export
               </button>
               */}
      </div>
    );
  };

  function getLabel(cell) {
    if (!props.cc_masters["FieldingCountriesOptions"]) return cell;
    let label = props.cc_masters["FieldingCountriesOptions"].filter(
      (x) => x.Label == cell
    )[0]["Label"];
    return label;
  }

  function isHidden(field) {
    if (
      proposal["Data"] &&
      proposal["Data"].length > 0 &&
      field in proposal["Data"][0]
    )
      return false;
    else return true;
  }
  const expandRow = {
    clickToExpand: true,
    onExpand: (row, isExpand, rowIndex, e) => {
      if (row.Costingtype == "Field Cost") {
        setSampleSizeState(
          project.CostingProfiles[project?.CostingProfiles?.length - 1]
            .CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
            .CostingOptions?.filter(x => x.Selected)[activeTabCo].id
        );
      }
      if (!isExpand) {
        setModelSelectedRow(modelRowIndex.filter((val) => val != rowIndex));
      } else {
        setModelSelectedRow([...modelRowIndex, rowIndex]);
      }
    },
    renderer: (row, rowIndex) =>
      row ? (
        <Col md={12}>
          {row && (
            <Card>
              <ToolkitProvider
                keyField="key"
                data={vendorSelection.filter(
                  (vs) =>
                    vs.Costingtype == row.Costingtype &&
                    vs.Country ==
                    project.CostingProfiles[
                      project?.CostingProfiles?.length - 1
                    ].CountrySpecs[activeTabFC].CountryName &&
                    vs.MethodologyID ==
                    project.CostingProfiles[
                      project?.CostingProfiles?.length - 1
                    ].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
                      .id &&
                    //todo
                    vs.CostingOptionID ==
                    project.CostingProfiles[
                      project?.CostingProfiles?.length - 1
                    ].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
                      .CostingOptions?.filter(x => x.Selected)[activeTabCo].id
                )}
                columns={
                  row.Costingtype != "Field Cost"
                    ? tableColumnsSUD.filter((a) => a.sud == true)
                    : tableColumnsSUD.filter((a) => a.field == true)
                }
              >
                {(props) => (
                  <CardBody>
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4
                      wrapperClasses="table-responsive"
                      bordered={true}
                      selectRow={
                        row.Costingtype != "Field Cost"
                          ? selectRow
                          : selectFieldCostRow
                      }
                    />
                  </CardBody>
                )}
              </ToolkitProvider>
            </Card>
          )}
        </Col>
      ) : (
        ""
      ),
    onExpandAll: (isExpandAll, results, e, rows) => {
      if (!isExpandAll) {
        setModelSelectedRow([]);
      } else {
        setModelSelectedRow(results?.filter((a) => a)?.map((a) => a?.key));
        setSampleSizeState(
          project.CostingProfiles[project?.CostingProfiles?.length - 1]
            .CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
            .CostingOptions?.filter(x => x.Selected)[activeTabCo]?.id
        );
      }
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expanded: modelRowIndex,
    expandHeaderColumnRenderer: ({ isAnyExpands }) =>
      isAnyExpands ? (
        <MinusCircle width={16} height={16} />
      ) : (
        <PlusCircle width={16} height={16} />
      ),
    expandColumnRenderer: ({ expanded }) =>
      expanded ? (
        <MinusCircle width={16} height={16} />
      ) : (
        <PlusCircle width={16} height={16} />
      ),
    className: "expanding-foo",
  };

  const selectFieldCostRow = {
    mode: "checkbox",
    hideSelectAll: true,
    nonSelectable: vendorSelection
      .filter((vs) => vs.Costingtype == "Field Cost")
      .map((fc) => fc.key),
    selected: vendorSelection
      .filter((vs) => vs.Costingtype == "Field Cost")
      .map((fc) => fc.key),
    style: { backgroundColor: "#9CF89C" },
  };

  const selectRow = {
    mode: "radio",
    selected: vendorSelection
      .filter(
        (vs) =>
          vs.IsSUD == true &&
          vs.CostingOptionID ==
          _.last(project.CostingProfiles).CountrySpecs[activeTabFC]
            .MethodologySpecs[activeTabSM].CostingOptions?.filter(x => x.Selected)[activeTabCo].id
      )
      .map((a) => a.key),
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        let temprow = vendorSelection.map((vendor) => {
          if (
            vendor.VendorID == row.VendorID &&
            vendor.Costingtype == row.Costingtype &&
            vendor.CostingOptionID ==
            project.CostingProfiles[project?.CostingProfiles?.length - 1]
              .CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
              .CostingOptions?.filter(x => x.Selected)[activeTabCo].id
          ) {
            vendor.IsSUD = true;
          }
          if (
            vendor.VendorID != row.VendorID &&
            vendor.Costingtype == row.Costingtype &&
            vendor.CostingOptionID ==
            project.CostingProfiles[project?.CostingProfiles?.length - 1]
              .CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
              .CostingOptions?.filter(x => x.Selected)[activeTabCo].id
          ) {
            vendor.IsSUD = false;
          }
          return vendor;
        });
        setVendorSelection(temprow);
      } else {
        let temprow = vendorSelection.map((vendor) => {
          if (
            vendor.VendorID == row.VendorID &&
            vendor.Costingtype == row.Costingtype
          ) {
            vendor.IsSUD = false;
          }
          return vendor;
        });
        setVendorSelection(temprow);
      }
    },
  };

  function setSampleSizeState(rowCostingOptionID) {
    var Index = project.CostingProfiles[project?.CostingProfiles?.length - 1]
      .CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]["SelectedVendor"]
      ? project.CostingProfiles[
        project?.CostingProfiles?.length - 1
      ].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM][
        "SelectedVendor"
      ].findIndex((co) => co.id == rowCostingOptionID)
      : -1;
    var originalCoSample =
      project.CostingProfiles[project?.CostingProfiles?.length - 1]
        .CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions?.filter(x => x.Selected)[
        activeTabCo
      ].CostingOptionData[
        _.head(
          project.CostingProfiles[
            project?.CostingProfiles?.length - 1
          ].CountrySpecs[activeTabFC].MethodologySpecs[
            activeTabSM
          ].CostingOptions?.filter(x => x.Selected)[0].CostingOptionSchema.filter((a) =>
            a.id?.includes("numberOfGroup")
          )
        )?.id ||
        _.head(
          project.CostingProfiles[
            project?.CostingProfiles?.length - 1
          ].CountrySpecs[activeTabFC].MethodologySpecs[
            activeTabSM
          ].CostingOptions?.filter(x => x.Selected)[0].CostingOptionSchema.filter((a) =>
            a.id?.includes("sampleSize")
          )
        )?.id
      ] == undefined
        ? "0"
        : project.CostingProfiles[project?.CostingProfiles?.length - 1]
          .CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
          .CostingOptions?.filter(x => x.Selected)[activeTabCo].CostingOptionData[
        _.head(
          project.CostingProfiles[
            project?.CostingProfiles?.length - 1
          ].CountrySpecs[activeTabFC].MethodologySpecs[
            activeTabSM
          ].CostingOptions?.filter(x => x.Selected)[0].CostingOptionSchema.filter((a) =>
            a.id?.includes("numberOfGroup")
          )
        )?.id ||
        _.head(
          project.CostingProfiles[
            project?.CostingProfiles?.length - 1
          ].CountrySpecs[activeTabFC].MethodologySpecs[
            activeTabSM
          ].CostingOptions?.filter(x => x.Selected)[0].CostingOptionSchema.filter((a) =>
            a.id?.includes("sampleSize")
          )
        )?.id
        ];
    if (Index != -1) {
      var existingSS = project.CostingProfiles[
        project?.CostingProfiles?.length - 1
      ].CountrySpecs[activeTabFC].MethodologySpecs[
        activeTabSM
      ].SelectedVendor?.filter((co) => co.id == activeCoId).length
        ? project.CostingProfiles[
          project?.CostingProfiles?.length - 1
        ].CountrySpecs[activeTabFC].MethodologySpecs[
          activeTabSM
        ].SelectedVendor?.filter(
          (co) => co.id == activeCoId
        )[0].selected.some((s) => s.Costingtype == "Field Cost")
          ? project.CostingProfiles[
            project?.CostingProfiles?.length - 1
          ].CountrySpecs[activeTabFC].MethodologySpecs[
            activeTabSM
          ]?.SelectedVendor[Index].selected?.filter(
            (fc) => fc.Costingtype == "Field Cost"
          ).length > 0
            ? project.CostingProfiles[
              project?.CostingProfiles?.length - 1
            ].CountrySpecs[activeTabFC].MethodologySpecs[
              activeTabSM
            ].SelectedVendor[Index].selected
              ?.filter((fc) => fc.Costingtype == "Field Cost")
              ?.map((m) => m.SampleSize)
              ?.reduce((a, b) => a + b)
            : 0
          : 0
        : 0;

      // setInitialSelectedField(false)
      setCurrentCoSampleSize(parseInt(originalCoSample) - existingSS);
    } else {
      setCurrentCoSampleSize(parseInt(originalCoSample));
    }
  }

  function saveInternalCost() {
    var res = [];
    var SumInternal = {};
    res = project.CostingProfiles[
      project?.CostingProfiles?.length - 1
    ].CountrySpecs[activeTabFC].MethodologySpecs[
      activeTabSM
    ].SelectedVendor.filter((co) => co.id == activeCoId)[0].selected.filter(
      (el) => {
        return !internalCost.find((element) => {
          return element.key === el.key;
        });
      }
    );
    project.CostingProfiles[project.CostingProfiles.length - 1].CountrySpecs[
      activeTabFC
    ].MethodologySpecs[activeTabSM].SelectedVendor.filter(
      (co) => co.id == activeCoId
    )[0].selected = _.union(res, internalCost, SumInternal);
    var selectedmeth =
      project.CostingProfiles[project.CostingProfiles.length - 1].CountrySpecs[
        activeTabFC
      ].MethodologySpecs[activeTabSM];

    var currentcurrencyconv =
      project.CostingProfiles[project.CostingProfiles.length - 1].ProfileSetting
        .CurrenciesData &&
        project.CostingProfiles[project.CostingProfiles.length - 1].CountrySpecs[
          activeTabFC
        ].MethodologySpecs[activeTabSM].Currency &&
        project.CostingProfiles[project.CostingProfiles.length - 1].CountrySpecs[
          activeTabFC
        ].MethodologySpecs[activeTabSM].Currency.code
        ? project.CostingProfiles[
          project.CostingProfiles.length - 1
        ].ProfileSetting.CurrenciesData.filter(
          (curr) =>
            curr.CurrencyUnit ==
            project.CostingProfiles[project.CostingProfiles.length - 1]
              .CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
              .Currency.label
        ).length > 0 &&
        (project.CostingProfiles[
          project.CostingProfiles.length - 1
        ].ProfileSetting.CurrenciesData.filter(
          (curr) =>
            curr.CurrencyUnit ==
            project.CostingProfiles[project.CostingProfiles.length - 1]
              .CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
              .Currency.label
        )[0].ConversionRateToLocal == 0
          ? 1
          : project.CostingProfiles[
            project.CostingProfiles.length - 1
          ].ProfileSetting.CurrenciesData.filter(
            (curr) =>
              curr.CurrencyUnit ==
              project.CostingProfiles[project.CostingProfiles.length - 1]
                .CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
                .Currency.label
          )[0].ConversionRateToLocal)
        : 1;

    if (activeDropCurrency)
      currentcurrencyconv =
        activeDropCurrency.ConversionRateToLocal != 0
          ? activeDropCurrency.ConversionRateToLocal
          : 1;

    var currency;
    var CurrencyConvRateToLocal;
    setinternalCost([]);
    setInternalCost(!InternalCost);
    setinternalOtherSpecify({});
    SumInternal.key = "SumInternal";
    SumInternal.Type = "SumInternal";
    SumInternal.Costingtype = "Total Internal Cost";
    SumInternal.ConstantCostFromVendor = 0;
    SumInternal.CostFromVendor = 0;
    SumInternal.CPI = 0;
    SumInternal.CostingOption = selectedmeth.CostingOptions?.filter(x => x.Selected).find(
      (co) => co.id == activeCoId
    ).Name;
    SumInternal.Methodology = selectedmeth.Label;
    SumInternal.Country =
      project.CostingProfiles[
        project?.CostingProfiles?.length - 1
      ].CountrySpecs[activeTabFC].CountryName;

    var SumInternalTotalCost =
      selectedmeth.SelectedVendor &&
        selectedmeth.SelectedVendor.filter((co) => co.id == activeCoId).length >
        0 &&
        selectedmeth.SelectedVendor.filter(
          (co) => co.id == activeCoId
        )[0].selected.filter((el) => el.Type == "Internal Cost").length > 0
        ? selectedmeth.SelectedVendor.filter((co) => co.id == activeCoId)[0]
          .selected.filter((el) => el.Type == "Internal Cost")
          ?.map((row) => row.TotalCost)
          ?.reduce(
            (cost, sum) =>
              cost + (isNaN(parseFloat(sum)) ? 0 : parseFloat(sum)) + 0,
            0
          )
        : 0;

    SumInternal.TotalCost = isNaN(SumInternalTotalCost)
      ? 0
      : parseFloat(SumInternalTotalCost).toFixed(2);

    var SumInternalTotalCostusd =
      selectedmeth.SelectedVendor &&
        selectedmeth.SelectedVendor.filter((co) => co.id == activeCoId).length >
        0 &&
        selectedmeth.SelectedVendor.filter(
          (co) => co.id == activeCoId
        )[0].selected.filter((el) => el.Type == "Internal Cost").length > 0
        ? selectedmeth.SelectedVendor.filter((co) => co.id == activeCoId)[0]
          .selected.filter((el) => el.Type == "Internal Cost")
          ?.map((row) => row.TotalCost)
          ?.map((x) => (x / currentcurrencyconv).toFixed(2))
          ?.reduce(
            (cost, sum) =>
              cost + (isNaN(parseFloat(sum)) ? 0 : parseFloat(sum)) + 0,
            0
          )
        : 0;
    // selectedmeth.SelectedVendor.filter(co => co.id == activeCoId)[0].selected.filter(el => el.Type == "Internal Cost")?.map(row => row.TotalCost)?.map(x => (x / currentcurrencyconv).toFixed(2))?.reduce((cost, sum) => cost + (isNaN(parseFloat(sum)) ? 0 : Math.round(parseFloat(sum)))+0,0) : 0;

    //var SumInternalTotalCostusd = SumInternal.TotalCost /currentcurrencyconv;

    //(activeInternalDropCurrency == undefined ? project.CostingProfiles[project.CostingProfiles.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].Currency.ConversionRateToLocal : activeInternalDropCurrency.ConversionRateToLocal)

    SumInternal.TotalCostusd = isNaN(SumInternalTotalCostusd)
      ? 0
      : parseFloat(SumInternalTotalCostusd).toFixed(2);

    // var SumInternalTotalCost = selectedmeth.SelectedVendor &&
    //   selectedmeth.SelectedVendor.filter(co => co.id == activeCoId).length > 0 &&
    //   selectedmeth.SelectedVendor.filter(co => co.id == activeCoId)[0].selected.filter(el => el.Type == "Internal Cost" && el.SelectedCurrency?.label != "USD").length > 0 ?
    //   selectedmeth.SelectedVendor.filter(co => co.id == activeCoId)[0].selected.filter(el => el.Type == "Internal Cost" && el.SelectedCurrency?.label != "USD")?.map(row => row.TotalCost)?.reduce((cost, sum) => cost + (isNaN(parseInt(sum)) ? 0 : parseInt(sum))) : 0;

    // var SumInternalTotalCostUSD = selectedmeth.SelectedVendor &&
    //   selectedmeth.SelectedVendor.filter(co => co.id == activeCoId).length > 0 &&
    //   selectedmeth.SelectedVendor.filter(co => co.id == activeCoId)[0].selected.filter(el => el.Type == "Internal Cost" && el.SelectedCurrency?.label == "USD").length > 0 ?
    //   selectedmeth.SelectedVendor.filter(co => co.id == activeCoId)[0].selected.filter(el => el.Type == "Internal Cost" && el.SelectedCurrency?.label == "USD")?.map(row => row.TotalCost)?.reduce((cost, sum) => cost + (isNaN(parseInt(sum)) ? 0 : parseInt(sum))) : 0;

    // var SumInternalTotalCostfiltered = isNaN(SumInternalTotalCost) ? 0 : SumInternalTotalCost;

    // var SumInternalTotalCostUSDfiltered = isNaN(SumInternalTotalCostUSD) ? 0 : SumInternalTotalCostUSD;

    // if (currencyDropdown().length > 1) {
    //   currency = currencyDropdown().filter(f => f?.label != "USD")[0];
    //   CurrencyConvRateToLocal = isNaN(currency.ConversionRateToLocal) ? 0 : currency.ConversionRateToLocal;
    // }
    // else {
    //   currency = currencyDropdown()[0];
    //   CurrencyConvRateToLocal = isNaN(currency.ConversionRateToLocal) ? 0 : currency.ConversionRateToLocal;

    // }

    // SumInternal.TotalCost = parseInt(SumInternalTotalCostfiltered) + parseInt(SumInternalTotalCostUSDfiltered * CurrencyConvRateToLocal)

    // SumInternal.TotalCostusd = parseInt(SumInternalTotalCostUSDfiltered) + parseInt(SumInternalTotalCostfiltered / CurrencyConvRateToLocal)

    //remove field of TotalCost and again insert
    var SumInternalFinalCPI = (
      SumInternal.TotalCost /
      parseInt(
        selectedmeth.CostingOptions?.filter(x => x.Selected).filter((co) => co.id == activeCoId)[0].CostingOptionData.sampleSizeMain ||
        selectedmeth.CostingOptions?.filter(x => x.Selected).filter((co) => co.id == activeCoId)[0].CostingOptionData.numberOfGroup
      )
    ).toFixed(2);

    SumInternal.FinalCPI = isNaN(SumInternalFinalCPI) ? 0 : SumInternalFinalCPI;
    project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[
      activeTabFC
    ].MethodologySpecs[activeTabSM].SelectedVendor.filter(
      (co) => co.id == activeCoId
    )[0].selected = [
        ...project.CostingProfiles[
          project?.CostingProfiles?.length - 1
        ].CountrySpecs[activeTabFC].MethodologySpecs[
          activeTabSM
        ].SelectedVendor.filter((co) => co.id == activeCoId)[0].selected.filter(
          (row) => row.Type != "SumInternal"
        ),
        SumInternal,
      ];

    project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[
      activeTabFC
    ].MethodologySpecs[activeTabSM].copySelectedVendor = _.cloneDeep(
      project.CostingProfiles[project?.CostingProfiles?.length - 1]
        .CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor
    );
  }

  function ConditionalSummaryTabs(savedVendorSelection) {
    var tabs = [];

    if (ShowTabs == true
      // || project?.IsProposalSubmit
    ) {
      if (_.last(project.CostingProfiles)
        .CountrySpecs &&
        _.last(
          project.CostingProfiles
        ).CountrySpecs.map(
          (cs) =>
            cs?.MethodologySpecs?.filter((y, index) => y.CostingType == 3 && !y.NotApplicable).length > 0
        )?.filter(x => x).length > 1) {
        tabs = Tabdata;

      } else {
        tabs = Tabdata.filter((a) => a.value != "v3");
      }
    } else {
      tabs = Tabdata.filter((a) => a.value == "v1");
    }
    return tabs;
  }

  function SetinternalCostValue(e, row) {
    var res =
      project.CostingProfiles[project?.CostingProfiles?.length - 1]
        .CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor;
    var currency =
      activeInternalDropCurrency == undefined
        ? project.CostingProfiles[project?.CostingProfiles?.length - 1]
          .CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].Currency
        : activeInternalDropCurrency;

    if (!currency) currency = currencyDropdown()[0];

    if (
      res
        .filter((co) => co.id == activeCoId)[0]
        .selected.filter((d) => d.key == row.key).length > 0
    ) {
      res
        .filter((co) => co.id == activeCoId)[0]
        .selected.filter((d) => d.key == row.key)[0].AdditionalCost = isNaN(
          parseInt(e.target.value)
        )
          ? 0
          : parseInt(e.target.value);
      res
        .filter((co) => co.id == activeCoId)[0]
        .selected.filter((d) => d.key == row.key)[0].AdditionalCostUSD =
        currency.label == "USD"
          ? isNaN(parseInt(e.target.value))
            ? 0
            : parseInt(e.target.value)
          : isNaN(parseFloat(e.target.value) / currency.ConversionRateToLocal)
            ? 0
            : parseFloat(e.target.value) / currency.ConversionRateToLocal;
      res
        .filter((co) => co.id == activeCoId)[0]
        .selected.filter((d) => d.key == row.key)[0].TotalCost = isNaN(
          parseInt(e.target.value)
        )
          ? 0
          : parseInt(e.target.value);
      res
        .filter((co) => co.id == activeCoId)[0]
        .selected.filter((d) => d.key == row.key)[0].CostForTable =
        e.target.value;
      res
        .filter((co) => co.id == activeCoId)[0]
        .selected.filter((d) => d.key == row.key)[0].SelectedCurrency =
        currency;
    } else {
      row.AdditionalCost = isNaN(parseInt(e.target.value))
        ? 0
        : parseInt(e.target.value);
      row.AdditionalCostUSD =
        currency?.label == "USD"
          ? isNaN(parseInt(e.target.value))
            ? 0
            : parseInt(e.target.value)
          : parseFloat(e.target.value) / currency.ConversionRateToLocal;
      row.TotalCost = isNaN(parseInt(e.target.value))
        ? 0
        : parseInt(e.target.value);
      row.CostForTable = e.target.value;
      row.Country =
        project.CostingProfiles[
          project?.CostingProfiles?.length - 1
        ].CountrySpecs[activeTabFC].CountryName;
      row.Methodology =
        project.CostingProfiles[
          project?.CostingProfiles?.length - 1
        ].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].Label;
      row.CostingOption =
        project.CostingProfiles[
          project?.CostingProfiles?.length - 1
        ].CountrySpecs[activeTabFC].MethodologySpecs[
          activeTabSM
        ].CostingOptions?.filter(x => x.Selected)[activeTabCo].Name;
      res.filter((co) => co.id == activeCoId)[0].selected.push(row);
    }
    const newProposal = update(
      project.CostingProfiles[project?.CostingProfiles?.length - 1],
      {
        CountrySpecs: {
          [activeTabFC]: {
            MethodologySpecs: {
              [activeTabSM]: {
                SelectedVendor: { $set: res },
              },
            },
          },
        },
      }
    );
    updateproject(newProposal);
  }
  function updateName(e, row) {
    var res = _.cloneDeep(
      project.CostingProfiles[project?.CostingProfiles?.length - 1]
        .CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor
    );
    var country =
      project.CostingProfiles[project?.CostingProfiles?.length - 1]
        .CountrySpecs[activeTabFC];
    if (
      res
        .filter((co) => co.id == activeCoId)[0]
        .selected.filter((d) => d.key == row.key).length > 0
    ) {
      res
        .filter((co) => co.id == activeCoId)[0]
        .selected.filter((v) => v.key == row.key)[0].pleaseSpecify = e;
    } else {
      row.AdditionalCost = "";
      row.AdditionalCostUSD = "";
      row.TotalCost = "";
      row.CostForTable = "";
      row.Country = country.CountryName;
      row.Methodology = country.MethodologySpecs[activeTabSM].Label;
      row.CostingOption =
        country.MethodologySpecs[activeTabSM].CostingOptions?.filter(x => x.Selected)[activeTabCo].Name;
      row.pleaseSpecify = e;
      res.filter((co) => co.id == activeCoId)[0].selected.push(row);
    }
    const newProposal = update(
      project.CostingProfiles[project?.CostingProfiles?.length - 1],
      {
        CountrySpecs: {
          [activeTabFC]: {
            MethodologySpecs: {
              [activeTabSM]: {
                SelectedVendor: { $set: res },
              },
            },
          },
        },
      }
    );
    updateproject(newProposal);
  }

  function CopyInternalCost() {
    if (
      project.CostingProfiles[
        project?.CostingProfiles?.length - 1
      ].CountrySpecs[activeTabFC].MethodologySpecs[
        activeTabSM
      ].SelectedVendor.filter((co) => co.id != activeCoId).length > 0
    ) {
      var temp = project.CostingProfiles[
        project?.CostingProfiles?.length - 1
      ].CountrySpecs[activeTabFC].MethodologySpecs[
        activeTabSM
      ].SelectedVendor.filter((co) => co.id == activeCoId)[0].selected.filter(
        (x) => x.Type == "Internal Cost" || x.Type == "SumInternal"
      );
      project.CostingProfiles[
        project?.CostingProfiles?.length - 1
      ].CountrySpecs[activeTabFC].MethodologySpecs[
        activeTabSM
      ].SelectedVendor.filter((co) => co.id != activeCoId).map((sv) => {
        if (sv.selected && sv.selected.length > 0) {
          temp.map((copydata) => {
            if (
              sv.selected.filter((c) => c.Costingtype == copydata.Costingtype)
                .length == 0
            ) {
              if (copydata.Costingtype != "Total Internal Cost") {
                var data = {};
                data.AdditionalCost = copydata.AdditionalCost;
                data.AdditionalCostUSD = copydata.AdditionalCostUSD;
                data.TotalCost = copydata.TotalCost;
                data.CostForTable = copydata.CostForTable;
                data.Country =
                  project.CostingProfiles[
                    project?.CostingProfiles?.length - 1
                  ].CountrySpecs[activeTabFC].CountryName;
                data.Methodology =
                  project.CostingProfiles[
                    project?.CostingProfiles?.length - 1
                  ].CountrySpecs[activeTabFC].MethodologySpecs[
                    activeTabSM
                  ].Label;
                data.Costingtype = copydata.Costingtype;
                data.Type = "Internal Cost";
                data.CostingOption = sv.name;
                data.key = copydata.key;

                sv.selected.push(data);
              } else {
                var data = {};
                data.TotalCostusd = copydata.TotalCostusd;
                data.FinalCPI = copydata.FinalCPI;
                data.CostFromVendor = copydata.CostFromVendor;
                data.ConstantCostFromVendor = copydata.ConstantCostFromVendor;
                data.CPI = copydata.CPI;
                data.TotalCost = copydata.TotalCost;
                data.Country =
                  project.CostingProfiles[
                    project?.CostingProfiles?.length - 1
                  ].CountrySpecs[activeTabFC].CountryName;
                data.Methodology =
                  project.CostingProfiles[
                    project?.CostingProfiles?.length - 1
                  ].CountrySpecs[activeTabFC].MethodologySpecs[
                    activeTabSM
                  ].Label;
                data.Costingtype = copydata.Costingtype;
                data.Type = "SumInternal";
                data.CostingOption = sv.name;
                data.key = copydata.key;
                sv.selected.push(data);
              }
            } else {
              if (copydata.Costingtype != "Total Internal Cost") {
                sv.selected.filter(
                  (c) => c.Costingtype == copydata.Costingtype
                )[0].AdditionalCost = copydata.AdditionalCost;
                sv.selected.filter(
                  (c) => c.Costingtype == copydata.Costingtype
                )[0].AdditionalCostUSD = copydata.AdditionalCostUSD;
                sv.selected.filter(
                  (c) => c.Costingtype == copydata.Costingtype
                )[0].TotalCost = copydata.TotalCost;
                sv.selected.filter(
                  (c) => c.Costingtype == copydata.Costingtype
                )[0].CostForTable = copydata.CostForTable;
              } else {
                sv.selected.filter(
                  (c) => c.Costingtype == copydata.Costingtype
                )[0].TotalCostusd = copydata.TotalCostusd;
                sv.selected.filter(
                  (c) => c.Costingtype == copydata.Costingtype
                )[0].FinalCPI = copydata.FinalCPI;
                sv.selected.filter(
                  (c) => c.Costingtype == copydata.Costingtype
                )[0].CostFromVendor = copydata.CostFromVendor;
                sv.selected.filter(
                  (c) => c.Costingtype == copydata.Costingtype
                )[0].ConstantCostFromVendor = copydata.ConstantCostFromVendor;
                sv.selected.filter(
                  (c) => c.Costingtype == copydata.Costingtype
                )[0].CPI = copydata.CPI;
                sv.selected.filter(
                  (c) => c.Costingtype == copydata.Costingtype
                )[0].TotalCost = copydata.TotalCost;
              }
            }
          });
        }
      });
    }
    toastr.success("Success", "Internal Cost Copied");
  }

  return (
    <>
      <Container fluid className="p-0">
        {
          <>
            <Row>
              <Col lg="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h5" className="my-2">
                      <Row>
                        <Col md={11}>
                          <Label>Project Details</Label>
                        </Col>
                        <Col md={1}>
                          <FontAwesomeIcon
                            id="collapseProjectDetails"
                            icon={faChevronDown}
                            href="#collapseProjectDetails"
                          />
                        </Col>
                      </Row>
                    </CardTitle>
                  </CardHeader>
                  <UncontrolledCollapse
                    toggler="#collapseProjectDetails"
                    defaultOpen={true}
                  >
                    <CardBody>
                      <Form>
                        <Row form>
                          <Col md={4}>
                            <FormGroup>
                              <Label>Project ID</Label>
                              <Input
                                type="text"
                                name="id"
                                value={props.project.ProjectId}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col md={4}>
                            <FormGroup>
                              <Label>Single/Multiple Country</Label>
                              <Input
                                type="text"
                                name="IsSingleCountry"
                                value={
                                  project.CostingProfiles &&
                                    project.CostingProfiles.length > 0 &&
                                    project.CostingProfiles[
                                      project?.CostingProfiles?.length - 1
                                    ].IsMultiCountry == false
                                    ? "Single Country"
                                    : "Multi Country"
                                }
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col md={4}>
                            <FormGroup>
                              <Label>Commissioning Country</Label>
                              <input
                                type="text"
                                className="form-control w-100"
                                style={{ height: "2rem" }}
                                name="CommissioningCountry"
                                // value={project.CommissioningCountry && project.CommissioningCountry != "" &&
                                //   project.CostingProfiles && project.CostingProfiles.length > 0 && project.CostingProfiles[project?.CostingProfiles?.length - 1].ProfileSetting.CurrenciesData &&
                                //   project.CostingProfiles[project?.CostingProfiles?.length - 1].ProfileSetting.CurrenciesData.length > 0 ? project.CostingProfiles[project?.CostingProfiles?.length - 1].ProfileSetting.CurrenciesData.filter(comm => comm.Code == project.CommissioningCountry)[0].Label : ""}
                                value={
                                  project.CommissioningCountry &&
                                    project.CommissioningCountry != "" &&
                                    project.CostingProfiles &&
                                    project.CostingProfiles.length > 0 &&
                                    project.CostingProfiles[costingProfileIndex]
                                      ?.ProfileSetting?.CurrenciesData &&
                                    project.CostingProfiles[costingProfileIndex]
                                      ?.ProfileSetting?.CurrenciesData.length > 0
                                    ? project.CostingProfiles[
                                      costingProfileIndex
                                    ]?.ProfileSetting?.CurrenciesData.filter(
                                      (comm) =>
                                        comm.Code ==
                                        project.CommissioningCountry
                                    )[0].Label
                                    : ""
                                }
                                disabled
                              />
                            </FormGroup>
                          </Col>

                          <Col md={4}>
                            <FormGroup>
                              <Label>Background</Label>
                              <Input
                                type="textarea"
                                name="Background"
                                value={props.project.ProjectBackground || ""}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col md={4}>
                            <FormGroup>
                              <Label>Objectives</Label>
                              <Input
                                type="textarea"
                                name="Objectives"
                                value={props.project.ResearchObjectives || ""}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Form>
                    </CardBody>
                  </UncontrolledCollapse>
                </Card>
              </Col>
            </Row>
            {
              <div className={"tabsummary tabContentCostSummaryView"}>
                <Row>
                  <Col md={12} className={"methodologySummaryTabs"}>
                    <Nav tabs>
                      {project.CostingProfiles.length > 0 &&
                        ConditionalSummaryTabs(vendorSelectionSaved).map(
                          (sm, indx) => (
                            <NavItem key={sm.value}>
                              <NavLink
                                className={classnames({
                                  active: activeTabSummary === indx,
                                })}
                                onClick={() => {
                                  toggleSummary(indx);
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <div>
                                    <b>{sm.label}</b>
                                  </div>
                                </div>
                              </NavLink>
                            </NavItem>
                          )
                        )}
                    </Nav>
                  </Col>
                </Row>
                <TabContent activeTab={activeTabSummary}>
                  {activeTabSummary == 0 && (
                    <>
                      <Nav tabs>
                        {project.CostingProfiles.length > 0 &&
                          project.CostingProfiles[
                            project?.CostingProfiles?.length - 1
                          ].CountrySpecs &&
                          project.CostingProfiles[
                            project?.CostingProfiles?.length - 1
                          ].CountrySpecs.map(
                            (fc, indxFC) =>
                              fc.MethodologySpecs.filter(
                                (sm) => sm.CostingType == 3 && !sm.NotApplicable
                              ).length > 0 && (
                                <NavItem key={fc.value}>
                                  <NavLink
                                    className={classnames({
                                      active: activeTabFC === indxFC,
                                    })}
                                    onClick={() => {
                                      toggle(indxFC);
                                    }}
                                  >
                                    <b>{fc.CountryName}</b>
                                  </NavLink>
                                </NavItem>
                              )
                          )}
                      </Nav>
                      <TabContent activeTab={activeTabFC}>

                        <Col md={3} style={{ marginLeft: "75%" }}>
                          <FormGroup className="ml-5">
                            <div
                              style={{
                                backgroundColor: "#00F000",
                              }}
                            >
                              <Label
                                style={{
                                  fontWeight: "bolder",
                                  color: "black",
                                  marginLeft: "5px",
                                  marginTop: "5px",
                                }}
                              >
                                Available Currency
                              </Label>
                              <Select
                                name="CurrencySelection"
                                classNamePrefix="react-select"
                                options={currencyDropdown()}
                                isDisabled={viewonlymode}
                                onChange={(e) => { setCurrentCurrencySelection(e) }}
                                value={currentCurrencySelection ? currentCurrencySelection : defaultvalueforcurrency}
                                color="green"
                                style={{ Color: "green" }} />
                            </div>
                          </FormGroup>
                        </Col>

                        {project.CostingProfiles[
                          project?.CostingProfiles?.length - 1
                        ].CountrySpecs &&
                          project.CostingProfiles[
                            project?.CostingProfiles?.length - 1
                          ].CountrySpecs.map(
                            (fc, indxFC) =>
                              activeTabFC === indxFC && (
                                <TabPane tabId={indxFC} key={fc.value}>
                                  <Card>
                                    <Row>
                                      <Col md={12}>
                                        <Nav tabs>
                                          {project.CostingProfiles.length > 0 &&
                                            project.CostingProfiles[
                                              project?.CostingProfiles?.length -
                                              1
                                            ].CountrySpecs &&
                                            project.CostingProfiles[
                                              project?.CostingProfiles?.length -
                                              1
                                            ].CountrySpecs[
                                              activeTabFC
                                            ].MethodologySpecs.map(
                                              (sm, indxSM) =>
                                                !sm.NotApplicable &&
                                                sm.CostingType == 3 && (
                                                  <NavItem key={sm.value}>
                                                    <NavLink
                                                      className={classnames({
                                                        active:
                                                          activeTabSM ===
                                                          indxSM,
                                                      })}
                                                      onClick={() => {
                                                        toggleSM(indxSM);
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          justifyContent:
                                                            "space-between",
                                                          alignItems: "center",
                                                          top: "-3rem",
                                                        }}
                                                      >
                                                        <div>
                                                          <b>{sm.Label}</b>
                                                        </div>
                                                      </div>
                                                    </NavLink>
                                                  </NavItem>
                                                )
                                            )}
                                        </Nav>
                                        <TabContent
                                          activeTab={activeTabSM}
                                        // style={{ padding: "0.25" }}
                                        >
                                          <Col md={12}>
                                            <Row>
                                              <Col md={4}>
                                                <FormGroup>
                                                  <Label
                                                    style={{
                                                      fontWeight: "bolder",
                                                      color: "#464241",
                                                      margin: "0px 5px",
                                                    }}
                                                  >
                                                    Local Operation Support
                                                    Required For FW (*)
                                                    {(!viewonlymode) && project.CostingProfiles[
                                                      project?.CostingProfiles
                                                        ?.length - 1
                                                    ].CountrySpecs[
                                                      activeTabFC
                                                    ].MethodologySpecs
                                                      // .filter(
                                                      //   (a) => a.CostingType == 3)
                                                      .length > 1 && (
                                                        <FontAwesomeIcon
                                                          title="Copy data"
                                                          className="align-middle"
                                                          icon={faCopy}
                                                          style={{
                                                            cursor: "pointer",
                                                            margin: "0px 5px",
                                                          }}
                                                          onClick={() => {
                                                            copyVendorType();
                                                          }}
                                                        />
                                                      )}
                                                  </Label>
                                                  <div style={{ marginLeft: ".3rem", width: "75%" }}>
                                                    <Select
                                                      name="LocalOperationSupportRequiredForFW"
                                                      classNamePrefix="react-select"
                                                      options={
                                                        optionforlocalsupport
                                                      }
                                                      onChange={(e) =>
                                                        localoperationsupport(e)
                                                      }
                                                      value={
                                                        _.last(project.CostingProfiles).CountrySpecs[
                                                          activeTabFC
                                                        ].MethodologySpecs[
                                                          activeTabSM
                                                        ].VendorType
                                                          ? _.last(project.CostingProfiles).CountrySpecs[
                                                            activeTabFC
                                                          ].MethodologySpecs[
                                                            activeTabSM
                                                          ].VendorType
                                                          : ""
                                                      }
                                                      isDisabled={viewonlymode}
                                                    // value={{label: 'No', id: 0, value: 'Vendor FW (direct outsourcing)'}}
                                                    />
                                                  </div>
                                                </FormGroup>
                                              </Col>
                                            </Row>
                                            <Row
                                              className={"costingOptionTabsRow"}
                                            >
                                              <Col
                                                md={12}
                                                className={"costingOptionTabs"}
                                              >
                                                <Nav tabs>
                                                  {project.CostingProfiles
                                                    .length > 0 &&
                                                    project.CostingProfiles[
                                                      project?.CostingProfiles
                                                        ?.length - 1
                                                    ].CountrySpecs &&
                                                    project.CostingProfiles[
                                                      project?.CostingProfiles
                                                        ?.length - 1
                                                    ].CountrySpecs[
                                                      activeTabFC
                                                    ].MethodologySpecs[
                                                      activeTabSM
                                                    ].CostingOptions?.filter(x => x.Selected).map(
                                                      (Co, indexCo) => (
                                                        <NavItem key={Co.Name}>
                                                          {Co.Selected && (
                                                            <NavLink
                                                              className={classnames(
                                                                {
                                                                  active:
                                                                    activeTabCo ===
                                                                    indexCo,
                                                                }
                                                              )}
                                                              onClick={() => {
                                                                togglecostingOption(
                                                                  indexCo
                                                                );
                                                                setactiveCoId(
                                                                  Co.id
                                                                );
                                                              }}
                                                            >
                                                              <div
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  justifyContent:
                                                                    "space-between",
                                                                  alignItems:
                                                                    "center",
                                                                  top: "-3rem",
                                                                }}
                                                              >
                                                                <div>
                                                                  <b>
                                                                    {Co.Name}
                                                                  </b>
                                                                </div>
                                                              </div>
                                                            </NavLink>
                                                          )}
                                                        </NavItem>
                                                      )
                                                    )}
                                                </Nav>

                                                <TabContent
                                                  activeTab={activeTabSM}
                                                >
                                                  {_.last(project.CostingProfiles)
                                                    .CountrySpecs[activeTabFC]
                                                    ?.MethodologySpecs[
                                                    activeTabSM
                                                  ].CostingOptions?.filter(x => x.Selected)[activeTabCo]
                                                    .CostingOptionData
                                                    .lengthOfInterview &&
                                                    <Row>
                                                      <Col lg-1 md-1 xs-12>
                                                        <Label className="h5">
                                                          Length Of Interview:{" "}
                                                          {_.last(project.CostingProfiles)
                                                            .CountrySpecs[activeTabFC]
                                                            ?.MethodologySpecs[
                                                            activeTabSM
                                                          ].CostingOptions?.filter(x => x.Selected)[activeTabCo]
                                                            .CostingOptionData
                                                            .lengthOfInterview
                                                          }
                                                        </Label>
                                                      </Col>
                                                    </Row>}
                                                  {_.last(project.CostingProfiles).CountrySpecs[activeTabFC]
                                                    ?.MethodologySpecs[activeTabSM].CostingOptions?.filter(x => x.Selected)[activeTabCo]
                                                    .CostingOptionData
                                                    .sampleSizeMain
                                                    && <Row>
                                                      <Col lg-1 md-1 xs-12>
                                                        <Label className="h5">
                                                          Sample Size:{" "}
                                                          {
                                                            _.last(project.CostingProfiles).CountrySpecs[activeTabFC]
                                                              ?.MethodologySpecs[activeTabSM].CostingOptions?.filter(x => x.Selected)[activeTabCo]
                                                              .CostingOptionData
                                                              .sampleSizeMain
                                                          }
                                                        </Label>
                                                      </Col>
                                                    </Row>}
                                                  {_.last(project.CostingProfiles).CountrySpecs[activeTabFC]
                                                    ?.MethodologySpecs[activeTabSM].CostingOptions?.filter(x => x.Selected)[activeTabCo]
                                                    .CostingOptionData
                                                    .numberOfGroup
                                                    && <Row>
                                                      <Col lg-1 md-1 xs-12>
                                                        <Label className="h5">
                                                          Number Of Groups:{" "}
                                                          {
                                                            _.last(project.CostingProfiles).CountrySpecs[activeTabFC]
                                                              ?.MethodologySpecs[activeTabSM].CostingOptions?.filter(x => x.Selected)[activeTabCo]
                                                              .CostingOptionData
                                                              .numberOfGroup
                                                          }
                                                        </Label>
                                                      </Col>
                                                    </Row>}
                                                  <div
                                                    style={{
                                                      maxHeight: "380px",
                                                      overflow: "auto",
                                                    }}
                                                  >
                                                    <ToolkitProvider
                                                      keyField="key"
                                                      data={vendorSelectionParent().map(
                                                        (val, i) => {
                                                          return {
                                                            key: i,
                                                            Costingtype: val,
                                                          };
                                                        }
                                                      )}
                                                      columns={
                                                        tableColumnsVendorSelection
                                                      }
                                                    >
                                                      {(props) => (
                                                        <CardBody
                                                          className={
                                                            "table-White-Auto"
                                                          }
                                                        >
                                                          <BootstrapTable
                                                            {...props.baseProps}
                                                            bootstrap4
                                                            wrapperClasses="table-responsive"
                                                            bordered={true}
                                                            rowStyle={
                                                              rowStyleVendorSelection
                                                            }
                                                            expandRow={
                                                              expandRow
                                                            }
                                                          />
                                                        </CardBody>
                                                      )}
                                                    </ToolkitProvider>
                                                  </div>
                                                </TabContent>
                                              </Col>
                                            </Row>
                                          </Col>
                                        </TabContent>
                                      </Col>
                                    </Row>
                                  </Card>
                                </TabPane>
                              )
                          )}
                      </TabContent>
                    </>
                  )}

                  {activeTabSummary == 1 && (
                    // && ComparisonViewData().length> 0
                    <>
                      <InternalCostView
                        project={project}
                        vendorSelection={vendorSelection.filter((vs) => vs.IsSelected == true || vs.IsSUD == true)}
                        setVendorSelection={setVendorSelection}
                      ></InternalCostView>
                    </>
                  )}

                  {activeTabSummary == 2 &&
                    // ComparisonViewData().length > 0 &&
                    project.CostingProfiles &&
                    project.CostingProfiles.length > 0 &&
                    project.CostingProfiles[
                      project.CostingProfiles?.length - 1
                    ]?.CountrySpecs.map(
                      (cs) =>
                        cs?.MethodologySpecs.filter((y, index) => y.CostingType == 3 && !y.NotApplicable).length > 0
                    ).filter(x => x).length > 1 && (
                      <ComparisonView
                        ComparisonViewData={vendorSelection.filter(
                          (vs) => vs.IsSelected == true || vs.IsSUD == true
                        )}
                        ViewOnlyMode={disableselection}
                      ></ComparisonView>
                    )}

                  {((!(
                    project?.CostingProfiles[
                      project?.CostingProfiles?.length - 1
                    ]?.CountrySpecs.map(
                      (cs) =>
                        cs?.MethodologySpecs?.filter((y, index) => y.CostingType == 3 && !y.NotApplicable).length > 0
                    )?.filter(x => x).length > 1) &&
                    activeTabSummary == 2) ||
                    ((project?.CostingProfiles[
                      project?.CostingProfiles?.length - 1
                    ]?.CountrySpecs.map(
                      (cs) =>
                        cs?.MethodologySpecs?.filter((y, index) => y.CostingType == 3 && !y.NotApplicable).length > 0
                    )?.filter(x => x).length > 1) &&
                      activeTabSummary == 3)) && (
                      <FinalCost
                        FinalCostData={vendorSelection.filter(
                          (vs) => vs.IsSelected == true || vs.IsSUD == true
                        )}
                        project={project}
                        ViewOnlyMode={disableselection}
                      ></FinalCost>
                    )}
                </TabContent>
              </div>
            }
          </>
        }
      </Container>
      {
        <Modal
          isOpen={countrymodal}
          toggle={() => setcountrymodal(!countrymodal)}
          centered
          size="xm"
          className={"modelSelectVendors"}
        >
          <ModalHeader
            toggle={() => {
              setcountrymodal(!countrymodal);
            }}
          >
            <span>Copying Costing Options To Methodologies & Markets</span>
          </ModalHeader>
          <ModalBody className="m-3" style={styleModelBody}>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Select Costing Options</Label>
                  <Select
                    name={"copyCostingOptions?.filter(x=> x.Selected)"}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={project.CostingProfiles[
                      project?.CostingProfiles?.length - 1
                    ].CountrySpecs[activeTabFC].MethodologySpecs[
                      activeTabSM
                    ].CostingOptions?.filter(x => x.Selected).map((a) => {
                      return { value: a.Name, label: a.Name, id: a.id };
                    })}
                    onChange={(val) => {
                      setoption(val);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Select Country</Label>
                  <Select
                    name={"copyCostingOptions?.filter(x=> x.Selected)"}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={project.CostingProfiles[
                      project?.CostingProfiles?.length - 1
                    ].CountrySpecs.map((a) => {
                      return { value: a.CountryName, label: a.CountryName };
                    })}
                    isMulti
                    onChange={(val) => {
                      Country(val);
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="success" onClick={() => copyonmethlevel()}>
              Save
            </Button>
            <Button
              color="danger"
              onClick={() => {
                setcountrymodal(!countrymodal);
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      }
      {
        <Modal
          isOpen={InternalCost}
          toggle={() => setInternalCost(!InternalCost)}
          centered
          size="lg"
          className={"modelSelectVendors"}
        >
          <ModalHeader
            toggle={() => {
              setInternalCost(!InternalCost);
              project.CostingProfiles[
                project?.CostingProfiles?.length - 1
              ].CountrySpecs[activeTabFC].MethodologySpecs[
                activeTabSM
              ].SelectedVendor = _.cloneDeep(
                project.CostingProfiles[project?.CostingProfiles?.length - 1]
                  .CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
                  .copySelectedVendor
              );
            }}
          >
            <span>Select Internal Cost</span>
          </ModalHeader>
          <ModalBody className="m-3">
            <div style={{ maxHeight: "380px", overflow: "auto" }}>
              <Table className="table" style={{ position: "relative" }}>
                <tr className="table bg-primary text-white">
                  <th>
                    Costing Type
                    {project.CostingProfiles[
                      project?.CostingProfiles?.length - 1
                    ].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
                      .CostingOptions?.filter(x => x.Selected).length > 1 && (
                        <FontAwesomeIcon
                          title="Copy data"
                          className="align-middle"
                          icon={faCopy}
                          style={{
                            cursor: "pointer",
                            marginLeft: "10px",
                          }}
                          onClick={() => {
                            saveInternalCost();
                            CopyInternalCost();
                          }}
                        />
                      )}
                  </th>
                  <th>Internal Cost</th>
                </tr>
                {(project.CostingProfiles[project?.CostingProfiles?.length - 1]
                  .CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
                  .Label == "Online Self Completion Survey"
                  ? staticModelData.filter(
                    (data) => data.OnlineMethodology == true
                  )
                  : staticModelData
                ).map((d) => {
                  return (
                    <tr>
                      <td>
                        {d.Costingtype.includes(
                          "Other Costs - PLEASE SPECIFY"
                        ) ? (
                          <>
                            <label>Other Cost </label>
                            <input
                              type="text"
                              name={d.key}
                              placeholder="Please Specify..."
                              value={
                                internalOtherSpecify &&
                                  internalOtherSpecify.hasOwnProperty(d.key)
                                  ? internalOtherSpecify[d.key]
                                  : project.CostingProfiles[
                                    project?.CostingProfiles?.length - 1
                                  ].CountrySpecs[activeTabFC]
                                    .MethodologySpecs[activeTabSM]
                                    .SelectedVendor &&
                                    project.CostingProfiles[
                                      project?.CostingProfiles?.length - 1
                                    ].CountrySpecs[activeTabFC]
                                      .MethodologySpecs[activeTabSM]
                                      .SelectedVendor.length > 0 &&
                                    project.CostingProfiles[
                                      project?.CostingProfiles?.length - 1
                                    ].CountrySpecs[
                                      activeTabFC
                                    ].MethodologySpecs[
                                      activeTabSM
                                    ].SelectedVendor.filter(
                                      (co) => co.id == activeCoId
                                    ).length > 0 &&
                                    project.CostingProfiles[
                                      project?.CostingProfiles?.length - 1
                                    ].CountrySpecs[
                                      activeTabFC
                                    ].MethodologySpecs[
                                      activeTabSM
                                    ].SelectedVendor.filter(
                                      (co) => co.id == activeCoId
                                    )[0].selected &&
                                    project.CostingProfiles[
                                      project?.CostingProfiles?.length - 1
                                    ].CountrySpecs[
                                      activeTabFC
                                    ].MethodologySpecs[
                                      activeTabSM
                                    ].SelectedVendor.filter(
                                      (co) => co.id == activeCoId
                                    )[0].selected.length > 0 &&
                                    project.CostingProfiles[
                                      project?.CostingProfiles?.length - 1
                                    ].CountrySpecs[
                                      activeTabFC
                                    ].MethodologySpecs[
                                      activeTabSM
                                    ].SelectedVendor.filter(
                                      (co) => co.id == activeCoId
                                    )[0].selected.filter((v) => v.key == d.key)
                                      .length > 0
                                    ? project.CostingProfiles[
                                      project?.CostingProfiles?.length - 1
                                    ].CountrySpecs[
                                      activeTabFC
                                    ].MethodologySpecs[
                                      activeTabSM
                                    ].SelectedVendor.filter(
                                      (co) => co.id == activeCoId
                                    )[0].selected.filter(
                                      (v) => v.key == d.key
                                    )[0].pleaseSpecify
                                    : ""
                              }
                              onChange={(e) => {
                                updateName(e.target.value, d);
                              }}
                            />
                          </>
                        ) : (
                          d.Costingtype
                        )}{" "}
                      </td>
                      <td>
                        <InputGroup>
                          <input
                            type="number"
                            min={0}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            pattern="[0-9]"
                            value={
                              parseInt(
                                project.CostingProfiles[
                                  project?.CostingProfiles?.length - 1
                                ].CountrySpecs[activeTabFC]?.MethodologySpecs[
                                  activeTabSM
                                ]?.SelectedVendor?.filter(
                                  (co) => co.id == activeCoId
                                )[0]?.selected.filter((v) => v.key == d.key)[0]
                                  ?.CostForTable == undefined
                                  ? ""
                                  : activeInternalDropCurrency == undefined
                                    ? project.CostingProfiles[
                                      project?.CostingProfiles?.length - 1
                                    ].CountrySpecs[
                                      activeTabFC
                                    ]?.MethodologySpecs[
                                      activeTabSM
                                    ]?.SelectedVendor?.filter(
                                      (co) => co.id == activeCoId
                                    )[0]?.selected.filter(
                                      (v) => v.key == d.key
                                    )[0]?.CostForTable
                                    : (
                                      project.CostingProfiles[
                                        project?.CostingProfiles?.length - 1
                                      ].CountrySpecs[
                                        activeTabFC
                                      ]?.MethodologySpecs[
                                        activeTabSM
                                      ]?.SelectedVendor?.filter(
                                        (co) => co.id == activeCoId
                                      )[0]?.selected.filter(
                                        (v) => v.key == d.key
                                      )[0]?.AdditionalCostUSD *
                                      (activeInternalDropCurrency == undefined
                                        ? 1
                                        : activeInternalDropCurrency.ConversionRateToLocal)
                                    ).toFixed(2)
                              ) || ""
                            }
                            onChange={(e) => {
                              e.target.value < 0
                                ? toastr.error("Please Enter Valid Number")
                                : SetinternalCostValue(e, d);
                            }}
                          />
                          <InputGroupAddon addonType="prepend">
                            {activeInternalDropCurrency == null ||
                              activeInternalDropCurrency == undefined ||
                              !activeInternalDropCurrency
                              ? project.CostingProfiles[
                                project?.CostingProfiles?.length - 1
                              ].CountrySpecs[activeTabFC].MethodologySpecs[
                                activeTabSM
                              ].Currency?.label
                              : activeInternalDropCurrency.label}
                          </InputGroupAddon>
                        </InputGroup>
                      </td>
                    </tr>
                  );
                })}
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              onClick={() => {
                saveInternalCost();
              }}
              disabled={viewonlymode}
            >
              Submit
            </Button>
            <Button
              color="danger"
              onClick={() => {
                setInternalCost(!InternalCost);
                project.CostingProfiles[
                  project?.CostingProfiles?.length - 1
                ].CountrySpecs[activeTabFC].MethodologySpecs[
                  activeTabSM
                ].SelectedVendor = _.cloneDeep(
                  project.CostingProfiles[project?.CostingProfiles?.length - 1]
                    .CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
                    .copySelectedVendor
                );
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      }
      {
        <Modal
          isOpen={modelState}
          toggle={() => setModelState(!modelState)}
          centered
          size="xl"
          className={"modelSelectVendors"}
        >
          <ModalHeader
            toggle={() => {
              setModelState(!modelState);
            }}
          >
            <span>Fill Other Costs</span>
          </ModalHeader>
          <ModalBody className="m-3" style={styleModelBody}>
            {proposal.id && (
              <div className={"tab"}>
                <Nav tabs>
                  {proposal["ListCountries"].map((country, indxC) => (
                    <NavItem key={country.Code}>
                      <NavLink
                        className={classnames({
                          active: activeTabCountryPopup === indxC,
                        })}
                        onClick={() => {
                          setActiveTabCountryPopup(indxC);
                        }}
                      >
                        <b>{country.Name}</b>
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
                <TabContent
                  activeTab={activeTabCountryPopup}
                  className={"modelCostSummary"}
                >
                  {proposal["ListCountries"].map(
                    (country, indxC) =>
                      activeTabCountryPopup === indxC && (
                        <TabPane tabId={indxC} key={country.Code}>
                          <div className={"tab"}>
                            <Nav tabs>
                              {country["Methodologies"].map(
                                (methodology, indxM) => (
                                  <NavItem key={methodology.Code}>
                                    <NavLink
                                      className={classnames({
                                        active:
                                          activeTabMethodologyPopup === indxM,
                                      })}
                                      onClick={() => {
                                        setActiveTabMethodologyPopup(indxM);
                                      }}
                                    >
                                      <b>{methodology.Name}</b>
                                    </NavLink>
                                  </NavItem>
                                )
                              )}
                            </Nav>
                            <TabContent activeTab={activeTabMethodologyPopup}>
                              {country["Methodologies"].map(
                                (methodology, indxM) =>
                                  activeTabMethodologyPopup === indxM && (
                                    <TabPane
                                      tabId={indxM}
                                      key={methodology.Code}
                                    >
                                      <h4
                                        style={{
                                          position: "absolute",
                                          top: "100px",
                                          right: "80px",
                                          color: "blue",
                                        }}
                                      >
                                        Grand Total {methodology.TotalCost}
                                      </h4>
                                      {GetMethodWiseAdditionalCost(
                                        methodology,
                                        indxM
                                      )}
                                    </TabPane>
                                  )
                              )}
                            </TabContent>
                          </div>
                        </TabPane>
                      )
                  )}
                </TabContent>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              onClick={() => {
                setModelState(!modelState);
                dispatch(
                  proposalActions.saveAdditionalCostBiddingSummary(
                    activeTabCountryPopup,
                    proposal
                  )
                );
              }}
              disabled={viewonlymode}
            >
              Save
            </Button>
            <Button
              color="danger"
              onClick={() => {
                setModelState(!modelState);
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      }
      {modelStateSummary && (
        <Modal
          isOpen={modelStateSummary}
          toggle={() => setModelStateSummary(!modelStateSummary)}
          centered
          size="xl"
          className={"modelSelectVendors"}
        >
          <ModalHeader
            toggle={() => {
              setModelStateSummary(!modelStateSummary);
            }}
          >
            <span>Selected Vendors Cost Summary</span>
            <div
              style={{
                position: "absolute",
                top: "5px",
                right: "50px",
                color: "blue",
              }}
            >
              <MyExportCSV {...props.csvProps} />
            </div>
          </ModalHeader>
          <ModalBody className="m-3" style={styleModelBody}>
            <div className={"tab tabContentCostSummary selectedVendors"}>
              {proposal["Data"] && (
                <Card>
                  <ToolkitProvider
                    keyField="RecordIndex"
                    data={proposal["Data"].filter((x) => x.Selected)}
                    columns={tableColumnsSummary}
                    exportCSV={{
                      onlyExportFiltered: true,
                      onlyExportSelection: false,
                      exportAll: true,
                    }}
                  >
                    {(props) => (
                      <>
                        <CardBody>
                          <BootstrapTable
                            {...props.baseProps}
                            bootstrap4
                            wrapperClasses="table-responsive"
                            bordered={true}
                            filter={filterFactory()}
                            filterPosition="top"
                          />
                        </CardBody>
                      </>
                    )}
                  </ToolkitProvider>
                </Card>
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="success"
              disabled={viewonlymode}>
              Submit</Button>
            <Button
              color="danger"
              onClick={() => {
                setModelStateSummary(!modelStateSummary);
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {props.oncostview && project.data && (
        <Modal
          isOpen={props.oncostview}
          centered
          size="xl"
          className={"modelSelectVendors"}
        >
          <ModalHeader>Final summary of selected vendor cost</ModalHeader>
          <ModalBody>
            {project.data && (
              <div className={"tab tabContentCostSummary"}>
                <Col md={12}>
                  {
                    <Card>
                      <ToolkitProvider
                        keyField="VendorID"
                        data={props.saveselectedrows}
                        columns={tableColumns}
                      >
                        {(props) => (
                          <CardBody>
                            <BootstrapTable
                              {...props.baseProps}
                              bootstrap4
                              wrapperClasses="table-responsive"
                              bordered={true}
                              filter={filterFactory()}
                              filterPosition="top"
                              cellEdit={cellEditFactory({
                                mode: "click",
                                blurToSave: true,
                              })}
                            />
                          </CardBody>
                        )}
                      </ToolkitProvider>
                    </Card>
                  }
                </Col>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              onClick={() => props.setoncostview(!props.oncostview)}
            >
              Close
            </Button>
            <Button color="success" disabled={viewonlymode} onClick={() => Savevendor()}>
              Submit
            </Button>
          </ModalFooter>
        </Modal>
      )}

      {
        !(activeTabSummary == 2 &&
          // ComparisonViewData().length > 0 &&
          project.CostingProfiles &&
          project.CostingProfiles.length > 0 &&
          project.CostingProfiles[
            project.CostingProfiles?.length - 1
          ]?.CountrySpecs.map(
            (cs) =>
              cs?.MethodologySpecs.filter((y, index) => y.CostingType == 3 && !y.NotApplicable).length > 0
          ).filter(x => x).length > 1) &&
        <Col md={12}>
          <label style={roundclosedstyle}><b>
            Note : For a Methodology cost will be saved only when
            <br></br>
            1. All the rounds are suppose to be closed
            <br></br>
            2. Sum of achievable samples of selected vendors are suppose to be equal to sum of sample size main or number of groups of all costing options
          </b></label>
        </Col>
      }
      {activeTabSummary ==
        ((project?.CostingProfiles[
          project?.CostingProfiles?.length - 1
        ]?.CountrySpecs.map(
          (cs) =>
            cs?.MethodologySpecs?.filter((y, index) => y.CostingType == 3 && !y.NotApplicable).length > 0
        )?.filter(x => x).length > 1)
          ? 3
          : 2) && (
          <Button
            color="primary"
            className="Button-Primary"
            style={{ marginTop: "10px" }}
            onClick={() => {
              saveAndNext(activeTabSummary, "Summary");
            }}
            disabled={app.recordloading || viewonlymode}
          >
            Confirm Costing
          </Button>
        )}
      {activeTabSummary == 2 &&
        (project?.CostingProfiles[
          project?.CostingProfiles?.length - 1
        ]?.CountrySpecs.map(
          (cs) =>
            cs?.MethodologySpecs?.filter((y, index) => y.CostingType == 3 && !y.NotApplicable).length > 0
        )?.filter(x => x).length > 1)
        && (
          <Button
            color="primary"
            className="Button-Primary"
            style={{ marginTop: "10px" }}
            onClick={(e) => {
              setShowTabs(true);
              setactiveTabSummary(activeTabSummary + 1);
            }}
          >
            Next
          </Button>
        )}
      {activeTabSummary ==
        ((project?.CostingProfiles[
          project?.CostingProfiles?.length - 1
        ]?.CountrySpecs.map(
          (cs) =>
            cs?.MethodologySpecs?.filter((y, index) => y.CostingType == 3 && !y.NotApplicable).length > 0
        )?.filter(x => x).length > 1) ? 3 : 2) &&
        currentprofile.CostingsSheetId && (
          <Button
            color="primary"
            style={{ marginTop: "10px" }}
            className="Button-Primary"
            onClick={() => openSheet()}
            disabled={app.recordloading || disableselection}
          >
            Open Cost Sheet
            {app.recordloading && <Spinner size="small" color="#495057" />}
          </Button>
        )}
      {(activeTabSummary == 0 || activeTabSummary == 1) && (
        <Button
          color="primary"
          className="btn btn-primary mr-2"
          style={{ marginTop: "10px" }}
          disabled={viewonlymode}
          onClick={() => {
            saveAndNext(activeTabSummary, "Save&Next");
          }}
        >
          Save And Next
        </Button>
      )}
      {(activeTabSummary == 0 || activeTabSummary == 1) && (
        <Button
          color="primary"
          className="btn btn-primary mr-2"
          style={{ marginTop: "10px" }}
          disabled={viewonlymode}
          onClick={() => {
            saveAndNext(activeTabSummary, "SaveDraft");
          }}
        >
          Save As Draft
        </Button>
      )}{" "}
    </>
  );
}
export default connect(
  mapStateToProps,
  null
)(ProposalBiddingSelectVendorsUpdated);