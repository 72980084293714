import * as types from "../actions/FetchVendorAndList/ActionTypes";

const initialState = {   
    vendorAndList: [],    
    error: '',
    msg: '',
}

export default function fetchVendorAndList(state = initialState, action)
{
    switch (action.type)
    {
        case types.FETCH_VENDOR_AND_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }

        case types.FETCH_VENDOR_AND_LIST_SUCCESS:
            {                
                return {
                    ...state,
                    loading: false,
                    vendorAndList: action.payload,
                    error: ''
                }
            }
            case types.FETCH_VENDOR_AND_LIST_FAILURE:
                return {
                    loading: false,
                    vendorAndList: [],
                    error: action.payload
                } 
    
       

        default: return state
    }

}