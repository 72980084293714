import axios from "../../../axios-interceptor";
import { toastr } from "react-redux-toastr";
export const FILTER_CRITERIA = "FILTER_CRITERIA";
export const FILTER_CRITERIA_DONE = "FILTER_CRITERIA_DONE";


export const filterCriteria = (data) => {

  let filterId =  null
  const userRecordData  =  JSON.parse(localStorage.getItem("userRecord"))
  //user ID
  let userId = JSON.parse(localStorage.getItem("userRecord")).id


  let defaultCriteria = userRecordData?.UserDefaultCriteria?.length > 0 
    ? 
    userRecordData.UserDefaultCriteria.find(e => e.Source == data.Source) 
    : null

  if(defaultCriteria) {
    filterId = defaultCriteria.id
  }

  let apiPayload = {
         CriteriaData : data.CriteriaData , 
          UserId : userId,
          Source: data.Source,
      }

  if(filterId) {
    apiPayload.id= filterId
  }


  return (dispatch) => {
    dispatch({  type: FILTER_CRITERIA, pageloaded: false });
    axios
      .post("/filterCriteria/user/default", apiPayload)
      .then((res) => {

        const dataJson = res.data.data;

        if(!defaultCriteria) {

          userRecordData.UserDefaultCriteria.push(dataJson)

          localStorage.setItem("userRecord", JSON.stringify(userRecordData));

        }else{

          let criteriaIndex = userRecordData.UserDefaultCriteria.findIndex(e => e.Source == data.Source)

          userRecordData.UserDefaultCriteria[criteriaIndex] = {...apiPayload}

          localStorage.setItem("userRecord", JSON.stringify(userRecordData));

        }

        dispatch({  type: FILTER_CRITERIA_DONE, pageloaded: res.data, err: false });
        if(!data?.isClear) {
          toastr.success("Successfully filtered", res.data.message);
        }
      })
      .catch((err) => {
        dispatch({ type: FILTER_CRITERIA_DONE, payload: null, err: true });
        toastr.error("Filtered Failed", err.data?.message);
      });
  };
};


  

