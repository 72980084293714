import React, { useEffect, useState, useRef } from "react";
import { toastr } from "react-redux-toastr";
import update from "immutability-helper";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "@alexcambose/react-infinite-scroll";
import axios from "../../axios-interceptor";
import Navbar from "../../components/Navbar";
import DashboardLayout from "../../layouts/Dashboard";
import RecordsSpinner from "../../components/RecordsSpinner";
import RecordsBadge from "../../components/RecordsBadge";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { getLabel } from "../../utils/codeLabels";
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table,
  Input,
  Label,
  CardHeader,
  CardTitle,
  CardBody,
  Container,
  Card,
  Row,
  Col,
} from "reactstrap";
import _ from "lodash";
import { pageLoadEnd, pageLoadStart } from "../../redux/actions/appActions";
import filterFactory, {
  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";
import moment from "moment";
import { csvHeaders, finalColumns, reportColumns } from "./constants";
import logo from "../../assets/img/avatars/modal-logo.png";
import useAnalyticsEventTracker from '../../utils/useAnalyticsEventTracker';
import * as EventName from '../../utils/GoogleAnalyticsEvents';
import '../oopReport/oop.css';

var imgStyle = {
  width: "150px",
};

const DeliveryReportNew = () => {
  const gaEventTracker = useAnalyticsEventTracker(EventName.DeliveryReport);
  const dispatch = useDispatch();

  // infinite load state
  const [infiniteLoad, setInfiniteLoad] = useState(false);
  const [record, setRecord] = useState({
    records: [],
    hasMore: true,
    totalItems: null,
  });

  const reportStatuses =
    useSelector(({ reportStatuses }) => reportStatuses) || [];
  // select columns to display states
  const [selectColumns, openSelectColumns] = useState(false);
  const [allColumns] = useState(reportColumns);
  const [selectedDisplayColumns, setSelectedDisplayColumns] = useState(
    allColumns
  );
  const [selectedTempDisplayColumns, setSelectedTempDisplayColumns] = useState(
    allColumns
  );
  const [resetDisplayCols, setResetDisplayCols] = useState([]);

  const [reportItems, setReportItems] = useState([]);
  const [reportRemoteFilters, setReportRemoteFilters] = useState({});
  const csvInstance = useRef(null);

  // initial api call - load 30
  useEffect(() => {
    dispatch(pageLoadStart());
  }, []);

  const loadInitial = async () => {
    axios
      .post("/reports/tcs/deliveryspecs-new/all?limit=30", { filters: reportRemoteFilters })
      .then((res) => {
        dispatch(pageLoadEnd());
        //console.log(res);
        setRecord({
          ...record,
          records: res.data.items,
          totalItems: res.data.totalItems,
          hasMore: true
        }); // change records to whatever array is named
      })
      .catch((err) => {
        // toastr.error("Loading Failed", err.data.message);
      });
  }

  // load all
  const loadAllFinances = async () => {
    try {
      dispatch(pageLoadStart());
      var res = await axios.post(`/reports/tcs/deliveryspecs-new/all`, {
        responseType: 'Array',
        headers: {
          'Content-Type': 'text/csv'
        }
      });
      if (res) {
        const fileType ='csv';
        const ws = XLSX.utils.json_to_sheet(res.data.items);
        const wb = {Sheets:{data:ws},SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb,{bookType : "csv", type:"array"});
        const dataexport = new Blob([excelBuffer],{type : fileType});
        // FileSaver.saveAs(dataexport, "sheet"+".xlsx")
        console.log(JSON.stringify(res.data, null, 2));
        const url = window.URL.createObjectURL(dataexport);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `TCSReport-UTC.csv`); //or any other extension
        document.body.appendChild(link);
        link.click();
      }
      dispatch(pageLoadEnd());
    }
    catch (err) {
      dispatch(pageLoadEnd());
      toastr.error("Loading Failed", err?.data?.message);
    }
  };
  useEffect(() => {
    //console.log("record has changed", record);
  }, [record]);

  // infinite load fetch more
  const fetchMoreData = () => {
    //console.log("fetch more called");
    //console.log(record);
    if (record.records.length >= record.totalItems) {
      //console.log("set hasMore false");
      setRecord({ ...record, hasMore: false });
      return;
    }
    setInfiniteLoad(true);
    axios
      .post(
        "/reports/tcs/deliveryspecs-new/all?limit=30&offset=" +
        record.records.length,
        { filters: reportRemoteFilters }
      )
      .then((res) => {
        //console.log(res);
        //console.log(
        //   "new array",
        //   update(record.records, { $push: res.data.items })
        // );
        setRecord({
          ...record,
          records: update(record.records, { $push: res.data.items }),
          totalItems: res.data.totalItems,
        });
        setInfiniteLoad(false);
      })
      .catch((err) => {
        //console.log(err);
        // toastr.error("Loading Failed", err.data.message);
        setInfiniteLoad(false);
      });
  };

  // camelCase to Title Case regex
  // str.replace(/((?<!^)[A-Z](?![A-Z]))(?=\S)/g, ' $1').replace(/^./, s => s.toUpperCase() );

  // const getOnline = () =>{
  //   const fields= [];
  //   return fields.map((field) => {
  //     return {
  //       dataField: field,
  //       text: field,
  //       sort: true,
  //       //filter: textFilter(),
  //       formatter: (cell, row) => {
  //         const methodologies = row.WaveSpec?.CostingProfile?.CountrySpecs?.filter(country=>{

  //         })
  //         if ()
  //         return <span>{row[field] || ""}</span>;
  //       },
  //     };
  //   });
  // }


  const [tableColumns, setTableColumns] = useState(finalColumns);

  // select columns to show
  useEffect(() => {
    if (selectedDisplayColumns) {
      dispatch(pageLoadStart());
      let columns = finalColumns
        .filter((col) => _.includes(selectedDisplayColumns, col.text))
        .map((col) => {
          return { ...col };
        });
      setTableColumns([]);
      setTimeout(() => {
        setTableColumns([...columns]);
        dispatch(pageLoadEnd());
      });
    }
  }, [selectedDisplayColumns]);

  const data = {
    tableColumns: [...tableColumns],
    tableData: record.records,
  };

  const ExportCSV = (props) => {
    const handleClick = () => {
      gaEventTracker(EventName.Export);
      props.onExport();
    };
    return (
      <div>
        <button className="btn btn-secondary mt-2" onClick={handleClick}>
          Export
        </button>
      </div>
    );
  };

  const tcsReportStatus = reportStatuses && reportStatuses.find((s) => s.ReportName === 'TCS REPORT') ? reportStatuses.find((s) => s.ReportName === 'TCS REPORT') : null
  const getReportStatus = (tcsReportStatus) => {
    if (!tcsReportStatus)
      return 'Report not generated.';
    switch (tcsReportStatus.Status) {
      case 'STARTED':
      case 'REPORT_GENERATED':
        return 'The report data is available for view now, for Export... it is still in progress.'
        break;
      case 'ERROR':
        return 'Report generation failed.'
        break;
      default:
        return ''
        break;
    }
  }

  const handleTableChange = (type, { filters }) => {
    //console.log('filters', filters display)
    if(Object.keys(filters).length !== 0){
      for (const [key, value] of Object.entries(filters)) { 
        value.filterVal = value.filterVal.trim()
      }     
    
    }

    setReportRemoteFilters(filters)
    return true
  }

  useEffect(() => {
    //console.log('reportRemoteFilters', reportRemoteFilters)
    loadInitial();
  }, [reportRemoteFilters])

  return (
    <div className="delivery_report">
      <DashboardLayout
        navbar={<Navbar headerTitle="DELIVERY REPORT" show={false} header_nav={true} content_report={true}/>}
      >
        <CSVLink style={{ display: 'none' }}
          filename={`TCSReport-${(tcsReportStatus && tcsReportStatus.ReportEndTime && tcsReportStatus.Status === 'CSV_UPLOADED') ? moment.utc(tcsReportStatus.ReportEndTime).format('l LT') : ''}-UTC.csv`}
          data={reportItems} headers={csvHeaders} separator={","} enclosingCharacter={`"`}
          ref={csvInstance}>
          Download me
        </CSVLink>
        <>
          {/* {record.records ? ( */}
          <div style={{position:'relative'}}>
          <InfiniteScroll
            loadMore={fetchMoreData}
            hasMore={record.hasMore}
            isLoading={infiniteLoad}
            loading={<RecordsSpinner />}
            noMore={<RecordsBadge back_top={true} recordTypeLabel="records" />}
            initialLoad={false}
          >
            <ToolkitProvider
              keyField="id"
              data={data.tableData}
              columns={[...data.tableColumns]}
              exportCSV={{ onlyExportFiltered: true, exportAll: false, fileName: `TCSReport-${(tcsReportStatus && tcsReportStatus.ReportEndTime && tcsReportStatus.Status === 'CSV_UPLOADED') ? moment.utc(tcsReportStatus.ReportEndTime).format('l LT') : ''}-UTC.csv` }}
            >
              {(props) => (
                // <Container fluid className="p-0">
                <Card>
                  <CardHeader style={{position:'fixed',width:'100%',zIndex:'999',top:'92px',borderBottom:'1px solid lightgray'}}>
                  <div className="row inner" style={{display:'flex',flexWrap:'nowrap', marginTop: "60px"}}>
                  <div className="col-lg-3 col-md-4 p-0">
                  
                      <h3>
                        Showing {record.records?.length || 0} of{" "}
                        {record.totalItems || 0} Waves
                      </h3>
                 
                  {tcsReportStatus && tcsReportStatus.ReportEndTime && <span className='small'>Last refresh on : {moment.utc(tcsReportStatus.ReportEndTime).format('l LT')} UTC</span>}
                  </div>
                  <div className="col-lg-3 col-md-4 text-right">
                    {tcsReportStatus && tcsReportStatus.ReportEndTime && 
                      <div className='text-right'>
                        
                        <button
                          className="btn btn-secondary mt-2 ml-2"
                          onClick={() => {gaEventTracker(EventName.Exportalldata);loadAllFinances()}}
                        >
                          Export all data
                        </button>
                      </div>
                    }
                  </div>
                  <div className="col-lg-6 col-md-4">
                    {tcsReportStatus && tcsReportStatus.ReportEndTime && (tcsReportStatus.Status === 'CSV_UPLOADED' || tcsReportStatus.Status === 'REPORT_GENERATED') &&

                      <div className="row">
                      <div className="col-lg-2 col-md-4">
                        <ExportCSV {...props.csvProps} />
                      </div>
                      <div className="col-lg-8 col-md-8 p-0">
                        <button
                            className="btn btn-secondary mt-2 mr-2"
                            onClick={() => {
                              openSelectColumns(!selectColumns);
                              setResetDisplayCols(selectedTempDisplayColumns);
                            }}
                          >
                            Select Columns to display
                          </button>
                        </div>
                      </div>
                    }
                </div>
                    </div>
                  </CardHeader>
                  <CardBody className="p-4" style={{marginTop:tcsReportStatus && tcsReportStatus.ReportEndTime && tcsReportStatus.Status === 'CSV_UPLOADED'?'0.5rem':'9rem',position:'relative'}}>
                  <p className={`text-danger text-center`}style={{position:'fixed',left:'45%'}}>{getReportStatus(tcsReportStatus)}</p>
                  {tcsReportStatus && tcsReportStatus.ReportEndTime && (tcsReportStatus.Status === 'CSV_UPLOADED' || tcsReportStatus.Status === 'REPORT_GENERATED') ? <>
                      {data.tableColumns && data.tableColumns.length ? (                        
                        <div className="fixTableHead">
                        <BootstrapTable
                          id="mapping_table"
                          style={{marginTop:'9rem !important'}}
                          classes="sticky-top-table delivery-report"
                          remote={{ filter: true }}
                          {...props.baseProps}
                          bootstrap4
                          bordered={true}
                          striped
                          hover
                          condensed
                          onTableChange={handleTableChange}
                          filter={filterFactory()}
                          noDataIndication={<b>No data available</b>}
                        />
                        </div>
                        
                      ) : null}
                    </> : null
                    }
                  </CardBody>
                </Card>
                //</Container>
              )}
            </ToolkitProvider>
          </InfiniteScroll>
          </div>
        </>
        {/* ) : null} */}
        <Modal
          toggle={() => openSelectColumns(!selectColumns)}
          isOpen={selectColumns}
          centered={true}
        >
          <ModalHeader toggle={() => openSelectColumns(!selectColumns)}>
          {/* <img src={logo}  className="modallogo"/> */}
          </ModalHeader>
          <div>
                        <span className="modaltitle modaltitleDisplay">
                        Select Columns to display
                        </span>
                    </div>

          <ModalBody>
            <Row className="justify-content-end">
              <a
                className="select-link"
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedTempDisplayColumns([...allColumns]);
                }}
              >
                Select all
              </a>
              <a
                className="ml-2 mr-4 select-link"
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedTempDisplayColumns([]);
                }}
              >
                Deselect all
              </a>
            </Row>
            {allColumns.map((ac, index) => (
              <Col lg="6" className="d-inline-flex">
                <span>
                  <input
                    id={`column_${index}`}
                    type="checkbox"
                    checked={_.includes(selectedTempDisplayColumns, ac)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedTempDisplayColumns([
                          ...selectedTempDisplayColumns,
                          ac,
                        ]);
                      } else {
                        setSelectedTempDisplayColumns([
                          ...selectedTempDisplayColumns.filter(
                            (sdc) => sdc != ac
                          ),
                        ]);
                      }
                    }}
                  />
                  <label className="ml-2 pointer" for={`column_${index}`}>
                    {ac}
                  </label>
                </span>
              </Col>
            ))}
          </ModalBody>
          <ModalFooter>
            <Row>
              <Button
                color="primary"
                size="sm"
                onClick={() => {
                  setSelectedDisplayColumns([...selectedTempDisplayColumns]);
                  openSelectColumns(!selectColumns);
                  // setSelectedTempDisplayColumns([])
                }}
                >
                Confirm
              </Button>
              <Button
                className="ml-2"
                color="secondary"
                size="sm"
                onClick={() => {
                  openSelectColumns(!selectColumns);
                  setSelectedTempDisplayColumns([...resetDisplayCols]);
                  setResetDisplayCols([]);
                }}
              >
                Close
              </Button>
            </Row>
          </ModalFooter>
        </Modal>
      </DashboardLayout>
    </div>
  );
};

export default DeliveryReportNew;
