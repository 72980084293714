import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
  customFilter,
  multiSelectFilter,
  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import CardBody from "reactstrap/lib/CardBody";
import Select from "react-select";
import _ from "lodash";

const OopAdjustmentLog = (props) => {
  const [profileOpsData, setProfileOpsData] = useState(props.HistoryLogData);
  const [country, setCountry] = useState([]);
  const [methodology, setMethodology] = useState([]);
  function priceFormatter(cell) {
    return `${_.round(cell, 2)}%`;
  }
  function dateFormatter(cell) {
    return _.first(cell.split("T"));
  }
  const tableColumns = [
    //  OldOpsOOPTOtal, NewOpsOOPTOtal, OldPriceToClient, NewPriceToClient, UpdatedDate, UpdatedBy
    {
      dataField: "WBSNumber",
      text: "WBS Number ",
    },
    {
      dataField: "OldOpsOOP",
      text: "Old Ops External ",
    },
    {
      dataField: "NewOpsOOP",
      text: "New Ops External ",
    },
    {
      dataField: "OldCSOOP",
      text: "Old CS External ",
    },
    {
      dataField: "NewCSOOP",
      text: "New CS External ",
    },
    {
      dataField: "OldTotalExternalCosts",
      text: "Old Total External Costs",
    },
    {
      dataField: "NewTotalExternalCosts",
      text: "New Total External Costs",
    },
    //internal
    {
      dataField: "OldOpsInternal",
      text: "Old Ops Internal",
    },
    {
      dataField: "NewOpsInternal",
      text: "New Ops Internal",
    },
    {
      dataField: "OldCsInternal",
      text: "Old CS Internal",
    },
    {
      dataField: "NewCsInternal",
      text: "New CS Internal",
    },
    {
      dataField: "OldTotalInternalCosts",
      text: "Old Total Internal Costs",
    },
    {
      dataField: "NewTotalInternalCosts",
      text: "New Total Internal Costs",
    },

    //till here
    {
      dataField: "PricetoClient",
      text: "Price to Client",
    },
    {
      dataField: "OldOopPercent",
      text: "Old OOP Percentage",
      formatter: priceFormatter,
    },
    {
      dataField: "NewOopPercent",
      text: "New OOP Percentage",
      formatter: priceFormatter,
    },
    //mg %
    {
      dataField: "OldMargin",
      text: "Old Margin Percentage",
      formatter: priceFormatter,
    },
    {
      dataField: "NewMargin",
      text: "New Margin Percentage",
      formatter: priceFormatter,
    },
    //mg%
    {
      dataField: "UpdatedDate",
      text: "Updated Date",
      formatter: dateFormatter,
    },
    {
      dataField: "UpdatedBy",
      text: "Updated By",
    },
    ,
    {
      dataField: "AdjustmentReason",
      text: "Adjustment Reason",
    },
  ];

  return (
    <>
      <ToolkitProvider
        keyField="index"
        data={profileOpsData}
        columns={tableColumns}
      >
        {(props) => (
          <CardBody>
            <div style = {{height: '400px', overflow: 'auto', display: 'flex'}}>
              
            <BootstrapTable
              {...props.baseProps}
              bootstrap4
              wrapperClasses="table-responsive"
              bordered={true}
              filter={filterFactory()}
              filterPosition="top"
              striped
              hover
              condensed
            />
            
            </div>
          </CardBody>
        )}
      </ToolkitProvider>
    </>
  );
};

export default OopAdjustmentLog;
