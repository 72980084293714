import _ from "lodash";
import * as currentCostingActions from "../actions/currentCostingActions";

const initialState = {
    currentCostingProfile: {
        ProfileName: "",
        ProfileNumber: 1,
        ProfileStatus: "1",
        IsTracker: false,
        NumberOfWaves: 1,
        TrackingFrequency: "",
        IsMultiCountry: false,
        FieldingCountries: [],
        StudyType: [],
        // Methodology: [],
        // SubMethodology: [],
        ResearchType: "",
        FieldType: "",
        VerticalId: null,
        EditClicked: false,
        ProfileLoaded: false
        ,ProfileChanged:false
    },
};

const removeOppState = {
    currentCostingProfile: {
        ProfileName: "",
        ProfileNumber: 1,
        ProfileStatus: "1",
        IsTracker: false,
        NumberOfWaves: 1,
        TrackingFrequency: "",
        IsMultiCountry: false,
        StudyType: [],
        Methodology: [],
        SubMethodology: [],
        ResearchType: "",
        FieldType: "",
        //VerticalId: null,
        EditClicked: false,
        ProfileLoaded: false
        ,ProfileChanged:false
    },
};

export default function currentCostingReducer(state = initialState, actions) {
    switch (actions.type) {
        case currentCostingActions.SELECT_COSTING:
            //console.log("COSTING SELECTED", actions.profile);
            let tempProfile = actions.profile;
            tempProfile.ProfileLoaded =true;
            return {
                currentCostingProfile: _.cloneDeep(tempProfile)
            };
        case currentCostingActions.UPDATE_NEW_COSTING:
            return {
                currentCostingProfile: {
                    ...state.currentCostingProfile,
                    ..._.cloneDeep(actions.currentCostingProfile),
                },
            };
        case currentCostingActions.CLEAR_NEW_COSTING:
            return {
                ...state,
                currentCostingProfile: { ...initialState.currentCostingProfile },
            };
        case currentCostingActions.CLEAR_OPP:
            return {
                ...state,
                currentCostingProfile: { 
                    ...state.currentCostingProfile,
                    ...removeOppState.currentCostingProfile },
            };
        case currentCostingActions.SET_CURRENCIES:
            return {
                ...state,
                currencies: actions.payload,
                currentCostingProfile: {
                    ...state.currentCostingProfile
                    , ProfileSetting: { ...state.currentCostingProfile.ProfileSetting, CurrenciesData: actions.payload }
                }
            };
            
        default:
            return state;
    }
}
