import * as types from "../actions/masters/ActionTypes";

const initialState = {   
    masters: [],    
    error: '',
    msg: '',
    ccMasters: []
}

export default function mastersReducer(state = initialState, action)
{
    switch (action.type)
    {
        case types.FETCH_ALL_MASTERS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case types.FETCH_ALL_MASTERS_REQUEST_SUCCESS:
            {                
                return {
                    ...state,
                    loading: false,
                    masters: action.payload,
                    error: ''
                }
            }

        case types.FETCH_CC_MASTERS_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                ccMasters: action.payload,
                error: ''
            }

        case types.FETCH_ALL_MASTERS_FAILURE:
            return {
                loading: false,
                masters: [],
                error: action.payload
            } 

        default: return state
    }

}