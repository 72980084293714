import * as types from "../actions/reportStatusActions";

const initialState = [];

export default function ReportStatusReducer(state = initialState, actions) {
  switch (actions.type) {
    case types.SET_REPORT_STATUSES: {
      return [...actions.ReportStatuses];
    }
    default:
      return state;
  }
}
