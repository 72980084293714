import React, { useState, useEffect, Suspense } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import * as timeCostActions from "../redux/actions/timeCostActions"
import {Button,ModalFooter,ModalBody,Modal,ModalHeader,Table,Alert} from 'reactstrap'
import * as currentCostingActions from "../redux/actions/currentCostingActions";
import axios from "../axios-interceptor";
import { useHistory, useLocation } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import _, { update } from "lodash";


const TimeCostModal=({isOpen,isClose})=>{
    const history = useHistory();
    const timecostView=useSelector(({timeCost})=>timeCost.selectedRole)
const profile = useSelector(  ({ currentCosting }) => currentCosting.currentCostingProfile
);
 
    const [newtd,setnewtd]=useState([])
    const [selectedCO,setselectedCO]=useState([])
    const [tableData,setTableData]=useState([])
    const [wbslevel,setwbslevel]=useState([])
    const [alert,setalert]=useState(false)
    const [showText,setshowText]=useState(false)
    const dispatch = useDispatch();
    const levelInput=timecostView==="commercial"?profile.CSInput:profile.OpsInput

    const currentCostingProfile = useSelector(
        ({ currentCosting }) => currentCosting.currentCostingProfile
      );

      useEffect(()=>{
        if(isOpen){
          while(typeof currentCostingProfile.OPPDatas === "string"){
            currentCostingProfile.OPPDatas = JSON.parse(currentCostingProfile.OPPDatas)
          }
          if(currentCostingProfile.OPPDatas){
            let showText = currentCostingProfile.OPPDatas.some(item => item?.isRed);
            setshowText(showText)
          }
          
            collectCO()
            const unique=new Set();
            let Opportunitytable=currentCostingProfile.ProductTypeData.filter((obj)=>{
                if(!unique.has(obj.OpportunityLineItemID)){
                    unique.add(obj.OpportunityLineItemID)
                    return true
                }
                return false;

            }
        )
        let methodologyspectable=currentCostingProfile.CountrySpecs.map((country)=>{
            return country.MethodologySpecs.map((ms)=>{
                return ms.CostingOptions.map((co)=>{
                   return {
                   Costingoptionlabel:co.label||co.Name,
                   costingoptionid:co.id,
                   OpportunityLineItemID:co.OpportunityLineItemID,
                 }
               })
             })

        })
        let temp=Opportunitytable.map((data,index)=>{
            let costing=methodologyspectable.flat().flat().filter((id)=>{
                return (id.OpportunityLineItemID==data.OpportunityLineItemID)
            }).map((data)=>{
                return data.costingoptionid
            })
            return {
              
              ...data,
              WBSLevel:levelInput!==null&&levelInput[data.OpportunityLineItemID]?levelInput[data.OpportunityLineItemID]==="wbslevel":timecostView==="commercial",
              OptionLevel:levelInput!==null&&levelInput[data.OpportunityLineItemID]?levelInput[data.OpportunityLineItemID]==="optionlevel":timecostView==="operations",
              CostingOptions:costing
            }
        })
        setnewtd(temp)
        }
     
        

      },[isOpen])
    
    const collectCO=()=>{
      let methodologyspectable1=currentCostingProfile.CountrySpecs.map((country)=>{
        return (country.MethodologySpecs.map((ms)=>{
            return ms.CostingOptions.map((co)=>{
               return {
              methodology:ms.Label,
               Costingoptionlabel:co.label||co.Name,
               costingoptionid:co.id,
               OpportunityLineItemID:co.OpportunityLineItemID.toString(),
               Country:country.CountryName
             }
           })
         })).flat()
      })
        let costing=methodologyspectable1.map((cty)=>{
          let oopId=new Set()
          return cty.map((data)=>{
              if(!oopId.has(data.OpportunityLineItemID)){
                  oopId.add(data.OpportunityLineItemID)
                  return data.costingoptionid
              }
             
            })
           })
          let cos=costing.flat().flat().filter((data)=>{
            return data!=undefined
          })
          console.log(cos,newtd,"wbslevel")

          setwbslevel(cos)  
    }

 const updateCS=()=>{
  setalert(false)
  setshowText(false)
    let selected=[]
    console.log(wbslevel,"collectCO")
    let inputObj={}
    let inputdata=newtd.map((data)=>{
        inputObj[data.OpportunityLineItemID]=(data.WBSLevel?"wbslevel":"optionlevel")
        return{
           inputObj
        }
    })

    newtd.map((data)=>{
        if(data.WBSLevel){
             selected.push(wbslevel)

        }
        else{
             selected.push(data.CostingOptions)
        }
    })
    setselectedCO(selected)

    let tempProfile=_.cloneDeep(currentCostingProfile)
    tempProfile?.CountrySpecs?.map((cs) => {
      return cs?.MethodologySpecs.map((ms) => {
         ms.CostingOptions.forEach((co, indexCO) => {

          if(selected.flat().includes(co.id)){
            console.log(co.id,'updatecommercialsu1')
          if(timecostView==="commercial"){
          
            
                return co["CostingOptionData"]={...co["CostingOptionData"],
                  "Commericalsupport" : "Yes"
                  }
                }
                else if(timecostView==="operations"){
                  return co["CostingOptionData"]={
                    ...co["CostingOptionData"],
                    
                    "operationpm" : "Yes",
                    "operationalpm" : "Yes"}
                  }
                  
            }
            else{
                console.log(co.id,'updatecommercialsu1')
                if(timecostView==="commercial"){
                
                  
                      return co["CostingOptionData"]={...co["CostingOptionData"],
                        "Commericalsupport" : "No"
                        }
                      }
                      else if(timecostView==="operations"){
                        return co["CostingOptionData"]={
                          ...co["CostingOptionData"],
                          "operationpm" : "No",
                          "operationalpm" : "Yes"}
                        }

            }
          })
              
              
        });

      })
     
    
     if(timecostView==="commercial"){
       tempProfile={
        ...tempProfile,
        CSInput:inputObj,
        isRFQ:false
      }
     }
     else if(timecostView==="operations"){
       tempProfile={
        ...tempProfile,
        OpsInput:inputObj,
        isRFQ:false
      }
     }

      dispatch(currentCostingActions.saveCostingProfileDraft(tempProfile,()=>{
        if(timecostView==="commercial") history.push("/internalCostCommercial");
        else if(timecostView==="operations") history.push("/internalCostOperations");
      }));
    console.log(tempProfile,'currentCostingActions')
   }
      
  const updateWBS=(index,checked)=>{
    let temp= _.cloneDeep(newtd)
    if(checked){
        if(temp[index].OptionLevel){
            setalert(true)
        }
        temp[index].WBSLevel=checked
        temp[index].OptionLevel=!checked
    }
    else{
        temp[index].WBSLevel=checked
        setalert(false)
    }
   
    setnewtd(temp)


  }
  const updateOption=(index,checked)=>{
    let temp= _.cloneDeep(newtd)
    if(checked){
        temp[index].WBSLevel=!checked
        temp[index].OptionLevel=checked
        setalert(false)

    }
    else{
        temp[index].OptionLevel=checked
        setalert(false)

    }
    setnewtd(temp)
console.log(newtd,"update")

  }
      return(
        <Modal
        isOpen={isOpen}
        size="lg"
        toggle={()=>{isClose()}}
        centered={true}
        >
            <ModalHeader>{timecostView==="commercial"?'Commercial':'Operations'} Time cost Level of Input</ModalHeader>
            <ModalBody>           
                <Table bordered>
                    <thead>
                        <tr>
                            <th>WBS</th>
                            <th>Product Description</th>
                            <th>WBS level</th>
                            <th>Option Level</th>
                        </tr>
                    </thead>
                    <tbody>
                        {newtd.map((product,index)=>{
                            return(
                                <tr>
                                    <td>{product.WBSNumber}</td>
                                    <td>{product.Productname}</td>
                                    <td><input type="checkbox" checked={product.WBSLevel} onClick={(e)=>{updateWBS(index,e.target.checked)}}/></td>
                                    <td><input type="checkbox" checked={product.OptionLevel} onClick={(e)=>{updateOption(index,e.target.checked)}}/></td>
                                </tr>
                            )
                        })}
                      
                    </tbody>
                </Table>

                {alert?<h4 style={{color:"red"}}>The hours entered will be overwritten by Product Level hours input</h4>:null}
                {showText?<h4 style={{color:"red"}}>Line Items are removed from MSD.The hours entered for the respective costing options might be lost</h4>:null}

            </ModalBody>
            <ModalFooter>
                <Button onClick={()=>{
                if(currentCostingProfile.CountrySpecs[0].MethodologySpecs[0].CostingOptions[0].id !== undefined){  
                  updateCS()
                    isClose()
                }
                else{
                    toastr.error("Please save RFQ details to proceed")
                }
                }}>Save</Button>
                <Button onClick={()=>{setalert(false)
                  isClose()}}>Cancel</Button>
          </ModalFooter>
        </Modal>
    )

}

export default TimeCostModal ;