import React, { useEffect, useState } from "react";
// import {
//   pageLoadEnd,
//   pageLoadStart,
//   recordLoadEnd,
//   recordLoadStart,
// } from "../../../../../../src/redux/actions/appActions";
// import axios from "../../src/axios-interceptor";
import logo from "./../../../assets/img/avatars/modal-logo.png";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { toastr } from "react-redux-toastr";
import update from "immutability-helper";
import classnames from "classnames";
import CreatableSelect from "react-select/creatable";
import * as currentProjectActions from "../../../redux/actions/currentProjectActions";
import * as proposalActions from "../../../redux/actions/proposal/Actions";
import moment from "moment-timezone";
import ct from "countries-and-timezones";

import {
  SET_TOTAL_FILES,
  SET_UPLOADED_FILES,
  SET_UPLOAD_STATUS,
  SET_VENDOR_FLAG,
  SET_BIDDING_SPLIT_DATA
} from "../../../redux/actions/vendor/ActionTypes";
// import Spinner from "../../../../../components/Spinner";
import _, { cloneDeep } from "lodash";
import {
  Input,
  Modal,
  Nav,
  NavItem,
  NavLink,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  ModalFooter,
  Button,
  Label,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  Container,
  Progress,
} from "reactstrap";
import Table from "reactstrap/lib/Table";
import { cancel } from "redux-saga/effects";
import { setMainConfig } from "react-doc-viewer/build/state/actions";
// import Axios from "../../../../../../src/axios-interceptor";
// import { NavLink } from "react-router-dom";
import {
  faCalendarAlt,
  faCopy,
  faRefresh,
  faChevronCircleRight,
  faTimesCircle,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { imgStyle } from "../../marketDefault/DynamicRFQ/ModalRFQ";
// import { proposalListRedirectToEdit } from "../../../../../redux/actions/proposal/Actions";

function VendorsEmailManagement({
  EmailManagement,
  setEmailManagement,
  // project,
  splitdata,
  cCProfile,
  isSummary
}) {
  const [invitedVendors, setInvitedVendors] = useState([]);
  const dispatch = useDispatch();
  const vendorAndList = useSelector(
    ({ fetchVendorAndList }) => fetchVendorAndList.vendorAndList
  );
  const userRecord = useSelector(({ user }) => user.userRecord);

  const currentCostingProfile = useSelector(
    ({ currentCosting }) => currentCosting.currentCostingProfile
  );
  var imgStyle = {
    width: "150px",
  };
  var ModalFooterStyle = {
    display: "flex",
    justifyContent: "flex-Start",
  };
  let vendorreducer = useSelector(({ vendorReducer }) => vendorReducer);
  const projects = useSelector(({ projects }) => projects.items);
  const project = useSelector(
    ({ currentProject }) => currentProject.newProject
  );
  const app = useSelector(({ app }) => app);
  const cancelPopUp = () => {
    setEmailManagement(!EmailManagement);
  };
  useEffect(() => {
    let tempVendors = [];
    // let  = [];
    let vendors = vendorAndList
      .filter((x) => x.Group == "Vendors")[0]
    ["List"].map((x) => ({
      label: x.label,
      value: x.value,
      VendorId: x.id,
      Methodology: x.SubMethodologies,
      VendorType: x.VendorTypes,
      Countries: x.countries
        ?.filter((c) => c.Blacklisted == 0),
    }))
    _.last(project.CostingProfiles)?.CountrySpecs.map((cs) => {
      cs.MethodologySpecs.map((ms) => {
        ms.InvitedVendors?.filter(deletedVendor => deletedVendor.IsDeleted == false && deletedVendor.SelectedRows.length > 0)?.filter(deletedVendor => !deletedVendor.label.includes("TCS"))
          ?.filter(deletedVendor => !deletedVendor.label.includes("Internal")).map((iv) => {
            // if(tempVendors.map(a=>a.VendorName).includes(iv.label)){
            //   let existingVendor=tempVendors.filter(v=>v.VendorName==iv.label)[0]
            // existingVendor.VendorEmail=existingVendor.VendorEmail.concat(JSON.parse(vendors.filter(a=>a.VendorId==iv.VendorId)[0].Countries.filter(b=>b.Country.Code==cs.CountryCode)[0].AdditionalContactEmail))
            // existingVendor.VendorEmail=existingVendor.VendorEmail.reduce((unique, o) => {
            //   if (!unique.some(obj => obj.value === o.value)) {
            //     unique.push(o);
            //   }
            //   return unique;
            // }, [])
            // }
            // else{
            let temp = {};
            temp.VendorName = iv.label;
            temp.VendorEmail = iv.AdditionalContacts
              ? iv.AdditionalContacts
              : JSON.parse(vendors.filter(a => a.VendorId == iv.VendorId)[0].Countries.filter(b => ((b.Country.Code == cs.CountryCode) || (b.Country.Code == project.CommissioningCountry)))[0].AdditionalContactEmail);
            tempVendors.push(temp);
            // }
          })
      });
    });
    tempVendors = tempVendors.reduce((unique, o) => {
      if (!unique.some(obj => obj.VendorName === o.VendorName)) {
        unique.push(o);
      }
      return unique;
    }, []);
    //console.log(tempVendors)
    setInvitedVendors(tempVendors);
  }, [project]);
  const customStylesCPN = {
    control: (base, state) => ({
      ...base,
      boxShadow: "none!important",
      border: "",
      //"solid 1px #AD4400"
      // You can also use state.isFocused to conditionally style based on the focus state
    }),
  };
  function onemailchange(e, row) {
    let error = false;
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    e?.length > 0 &&
      e.forEach((a) => {
        if (regex.test(a.label) === false) {
          error = true;
        }
      });
    if (error) {
      toastr.error("Please Enter Valid Email");
    } else {
      let tempVendors = _.cloneDeep(invitedVendors);
      tempVendors.forEach((vendors) => {
        if (vendors.VendorName == row.VendorName) {
          vendors.VendorEmail = e ? e : [];
        }
      });
      setInvitedVendors(tempVendors);
    }
  }
  function Savebiddingrequest(tempProject) {
    let vendor = false;
    let error = 0;
    if (_.last(tempProject.CostingProfiles).VendorBidDeadLine) {
      const tempcostdata = _.cloneDeep(
        _.last(tempProject.CostingProfiles).CountrySpecs
      );

      tempcostdata.forEach((s, i) =>
        s.MethodologySpecs.forEach((m, im) => {
          if (m.CostingType == 3) {
            if (m.InvitedVendors.filter(a => !a.IsDeleted)?.length == 0) {
              vendor = true;
            }
            else {
              m.InvitedVendors?.filter(a => !a.IsDeleted && a.label != "TCS" && a.label != "Internal")?.forEach((v, iv) => {
                if (v.ServiceType != 1) {
                  if ((v.VendorCosts.length > 0) && !(v.VendorCosts?.filter(x => x.RoundId).length > 0)) {
                    error = error + 1;
                  }
                }
                v.VendorCosts.forEach(a => a.IsEditable = true)

              });
            }
          }
        })
      );
      const newProposal = update(tempProject.CostingProfiles[tempProject.CostingProfiles.length - 1], {
        CountrySpecs: { $set: tempcostdata },
      });
      tempProject.CostingProfiles[tempProject.CostingProfiles.length - 1] = newProposal;
      dispatch({
        type: currentProjectActions.UPDATE_NEW_PROJECT,
        newProject: tempProject,
      });
      if (error > 0) {
        toastr.error("Please select sample split for all the vendors")
      }
      else {
        var data = {};
        const country_tz = ct.getCountry(tempProject.CommissioningCountry);
        const timezone = ct.getTimezone(country_tz.timezones[0]);
        data["VendorOPSMails"] = tempProject.CostingProfiles[tempProject.CostingProfiles.length - 1].VendorOPSMails ?
          tempProject.CostingProfiles[tempProject.CostingProfiles.length - 1].VendorOPSMails : userRecord.Email
        data["id"] = tempProject.CostingProfiles[tempProject.CostingProfiles.length - 1].id;
        data["CountryName"] = tempProject?.CommissioningCountry;
        data["IsSubmit"] = true;
        data["Message"] = "Bidding Submitted";
        data["type"] = "OPS"
        data["Error"] = false
        data["Project"] = tempProject
        data["VendorBidDeadLine"] = _.last(tempProject.CostingProfiles).VendorBidDeadLine
        data["VenodrBidDeadLineStatus"] = _.last(tempProject.CostingProfiles).VenodrBidDeadLineStatus
        data["VendorBidDeadLineUTC"] = `${moment(_.last(tempProject.CostingProfiles).VendorBidDeadLine).tz(timezone.name).format('lll')} (${moment.tz(timezone.name).zoneAbbr()})`;
        data["CountrySpecs"] = tempProject.CostingProfiles[tempProject.CostingProfiles.length - 1].CountrySpecs
        dispatch(
          proposalActions.saveData(
            data,
            false,
            false,
            vendorreducer.biddingFiles
          )
        );
      }
      cancelPopUp();
    }
    else { toastr.error("Please Select the Deadline !") }
  }
  const onSubmit = () => {
    if (invitedVendors.every(iv => iv.VendorEmail.length > 0)) {
      let tempProject = _.cloneDeep(project);
      _.last(tempProject.CostingProfiles)?.CountrySpecs.forEach((cs) => {
        cs.MethodologySpecs.forEach((ms) => {
          if (
            ms.InvitedVendors.map((iv) => iv.label).some((item) =>
              invitedVendors.map((iv) => iv.VendorName).includes(item)
            )
          ) {
            invitedVendors.map((currentVendors) => {
              ms.InvitedVendors.forEach((invited) => {
                if (invited.label == currentVendors.VendorName) {
                  invited.AdditionalContacts = currentVendors.VendorEmail;
                }
              });
            });
          }
        });
      });
      // dispatch({
      //   type: currentProjectActions.UPDATE_NEW_PROJECT,
      //   newProject: tempProject,
      // });
      Savebiddingrequest(tempProject);
    }
    else {
      toastr.error("Additional Contacts is compulsory for all Vendors!")
    }
  };
  // }
  return (
    <>
      <Modal
        isOpen={EmailManagement}
        backdrop="static"
        toggle={cancelPopUp}
        centered={true}
        size="lg"
      >
        <ModalHeader toggle={cancelPopUp}>
          {/* <img src={logo} className="modallogo" /> */}
        </ModalHeader>
        <h3>Selected Vendor Contacts Email</h3>
        <ModalBody style={{ maxHeight: "420px", padding: "0px", overflow: "auto" }}>
          <Table>
            <tbody>
              <tr>
                <td>
                  <strong>Selected Vendors</strong>
                </td>
                <td>
                  <strong>Additional Contacts<sup>(</sup><sup className="requiredField">*</sup><sup>)</sup></strong>
                </td>
              </tr>
              {invitedVendors.map((iv) => {
                return (
                  <>
                    <tr>
                      <td>
                        <label>{iv.VendorName}</label>
                      </td>
                      <td>
                        <CreatableSelect
                          styles={customStylesCPN}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name={iv.VendorName}
                          isMulti
                          value={iv.VendorEmail}
                          label={iv}
                          onChange={(e) => {
                            onemailchange(e, iv);
                            // VendorUpdateStyle(e, v.name)
                            // VendorErrorMsg(e, v.name)
                          }}
                        />
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={onSubmit}
            disabled={app.recordloading}
          >
            Submit
            {/* {app.recordloading && <Spinner size="small" color="#495057" />} */}
          </Button>
          <Button
            color="secondary"
            onClick={cancelPopUp}
            disabled={app.recordloading}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>


      {!isSummary && (
        <>
          {" "}
          <Container fluid className="p-0">
            {
              <>
                <Row>
                  <Modal
                    size="xs"
                    isOpen={vendorreducer.uploadStatus}
                    toggle={() => {
                      dispatch({ type: SET_UPLOAD_STATUS, payload: false });
                      dispatch({ type: SET_TOTAL_FILES, payload: 0 });
                      dispatch({ type: SET_UPLOADED_FILES, payload: 0 });
                    }}
                  >
                    {/* {vendorreducer.totalFiles!=vendorreducer.uploadedFiles  &&<Loader></Loader>} */}
                    <ModalBody>
                      <div className="text-center">
                        {vendorreducer.uploadedFiles} of{" "}
                        {vendorreducer.totalFiles} files are uploaded.
                      </div>
                      <hr></hr>
                      <Progress
                        max={vendorreducer.totalFiles}
                        value={vendorreducer.uploadedFiles}
                      />
                      {vendorreducer.uploadSummary.length ? (
                        vendorreducer.uploadSummary.map((vendor) => (
                          <b style={{ color: "green" }}>
                            <hr></hr>
                            {vendor.files} files are uploaded for{" "}
                            {vendor.vendorName
                              ? vendor.vendorName
                              : "Bidding"}
                            <br />
                          </b>
                        ))
                      ) : (
                        <b>
                          <hr></hr>Uploading Files . . .
                        </b>
                      )}
                    </ModalBody>
                  </Modal>
                </Row>

              </>
            }
          </Container>
        </>
      )}
    </>
  );
}

export default VendorsEmailManagement;
