import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import Select from 'react-select'

function SelectWrapper(props) {
    const inputRef = useRef()
    const selectRef = useRef()
    const isRequired = props.required || props.validate?.required?.value;
    const [touched, setTouched] = useState(false);
    const errorStyles = {
        control: (styles) => ({ ...styles, borderColor: "#AD4400" })
    }
    const mapOptions = e => {
        return {
            label: e?.props.children,
            value: e?.props.value || e?.props.children,
            ...e?.props
        }
    }
    const isEmpty = v => (v === null || v === undefined || v === "")
    const getOptions = () => {
        return props.children
            ? _.flatten(props.children).filter(i => !isEmpty(i)).map(mapOptions)
            : []
    }

    const getValue = val => {
        let v = getOptions().find(i => i.value === val)
        return {
            label: v?.label,
            ...v
        }
    }

    const mapTarget = e => {
        return {
            ...e,
            selectedOptions: [e],
            id: props.id
        }
    }


    const isInValid = () => {
        if(inputRef.current) {
            return props.invalid 
                // || (isRequired && touched)
                || (inputRef.current?.form?.className?.includes("av-submitted") && !inputRef.current?.validity?.valid)
        }
        return props.invalid
    }

    const handleChange = e => {
        props.onChange({ target: mapTarget(e) })
    }

    return (
        <>
            {props.label && <label for={props.name}>{props.label}</label>}
            <Select
                {...props}
                ref={selectRef}
                className={isInValid() ? "react-select-container is-invalid " + props.className : "react-select-container " + props.className}
                isDisabled={props.disabled}
                isClearable={props.clearable}
                styles={props.styles || (isInValid() && errorStyles)}
                value={
                    props.value 
                        ? getValue(props.value) 
                        : props.value === "" ? "" : undefined
                }
                isSearchable={props.isSearchable || true}
                options={getOptions()}
                isOptionDisabled={(option) => option.isdisabled}
                onChange={handleChange}
                onBlur={() => setTouched(true)}
                placeholder={props.placeholder || "Select"}
                {...props.defaultValue && {defaultValue: getValue(props.defaultValue)}}
            />
            {!props.disabled && <input
                ref={inputRef}
                tabIndex={-1}
                required={isRequired}
                autoComplete="off"
                onFocus={() => selectRef.current.focus()}
                value={!isEmpty(props.value) ? props.value : ""}
                style={{
                    opacity: 0,
                    width: '100%',
                    height: 0,
                    position: 'absolute'
                }}
            />}
            {
                isInValid()
                && <span className='error-msg'>{props.errorMessage}</span>
            }
        </>
    )
}

export default React.memo(SelectWrapper)