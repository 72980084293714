import { all } from 'redux-saga/effects';

import {
waitForLoadProposals, waitForProposalDetails,
waitForProposalVendorDetails, waitForSaveProposal,waitForSaveProposalData,
waitForVendorCost,
waitForAcknowledgeVendorBidding,
waitForFetchChats, waitForSaveChat,
waitForSaveApprovedBiddingVendors,
waitForProposalWODetails,
waitForSendEarlyWarningNote,
waitForSaveSelectedVendor,
waitForSaveProfile,
waitForSaveAdditionalCost,
waitForSaveVendorBidding
} from './proposal-sagas';
import waitForLoadAllMasters from './masters-sagas'
import waitForLoadVendorAndList    from './FetchVendorAndList_sagas'
import {
    waitForLoadVendors,
    waitForLoadMoreVendors,
    waitForVendorDetails,
    waitForSaveVendors
} from './vendor-sagas'


export default function* rootSaga() {
    yield all([
        waitForLoadAllMasters(),
        waitForSaveAdditionalCost(),
        waitForSaveProfile(),
        waitForLoadProposals(),
        waitForProposalDetails(),
        waitForProposalVendorDetails(),
        waitForSaveProposal(),
        waitForSaveProposalData(),
        waitForVendorCost(),
        waitForAcknowledgeVendorBidding(),
        waitForFetchChats, waitForSaveChat(),
        waitForSaveApprovedBiddingVendors(),
        waitForProposalWODetails(),
        waitForSendEarlyWarningNote(),
        waitForSaveVendors(),
        waitForLoadVendors(),
        waitForLoadMoreVendors(),
        waitForVendorDetails(),
        waitForSaveSelectedVendor(),
        waitForLoadVendorAndList(),
        waitForSaveVendorBidding()
    ])
}