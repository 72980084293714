import React, { useState, useEffect } from "react"
import _ from "lodash";
import {
    Table, Row, Col
} from "reactstrap";
import './css/common.css';
import { toastr } from "react-redux-toastr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faMoneyBill
} from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import * as currentProjectActions from "../../../redux/actions/currentProjectActions";
import vendorReducer from "../../../redux/reducers/vendorReducer";
import { data } from "jquery";

function VendorCostingAndTimeline(props) {
    const dispatch = useDispatch();
    const [initailstate, setInitailstate] = useState([]);
    const [cIndex, setCindex] = useState();
    const [ViewOnlyMode, setViewOnlyMode] = useState(false);
    const [vendor, setVendor] = useState(0);
    var project = useSelector(({ currentProject }) => currentProject.newProject);
    var index;
    const splitdata = useSelector(
        ({ vendorReducer }) => vendorReducer.SplitData
    )
    useEffect(() => {
        setVendor(props.vendor)
        setCindex(project.CostingProfiles.length - 1)
        setViewOnlyMode(
            ((project.CostingProfiles[project.CostingProfiles.length - 1].VenodrBidDeadLineStatus == "close")
                || (project?.ProjectStatus == 7)
                || (["2", "3", "4", "5", "6"].indexOf(_.last(project?.CostingProfiles).ProfileStatus) != -1))
                ? true : false)
    }, []);
    const current1 = new Date();
    let d = current1.getDate();
    let m = current1.getMonth() + 1;
    let y = current1.getFullYear();
    let current = d + "-" + m + "-" + y;
    function OtherCost(e, data) {
        if (!isNaN(parseInt(e.target.value[0]))) { toastr.error("Field Should not start with a Number") }

        var vendor = _.cloneDeep(props.vendor);
        if (vendor.SelectedRows.filter(a => a.id == data.id).length > 0) {

            vendor.SelectedRows.forEach(a => { if (a.id == data.id) { a["OtherCost"] = !isNaN(parseInt(e.target.value[0])) ? "" : e.target.value } })
        }
        index = props.meth.InvitedVendors.findIndex(v => v.VendorId === props.vendor.VendorId)
        project.CostingProfiles[cIndex].CountrySpecs[props.indexCountry].MethodologySpecs[props.indexMethodology].InvitedVendors[index] = vendor
        dispatch({
            type: currentProjectActions.UPDATE_NEW_PROJECT,
            newProject: project,
        });

    }
    function changesplit(e, coheader, type) {
        var Vendor
        Vendor = _.cloneDeep(props.vendor)
        switch (type) {
            case ("distribution"):
                Vendor.VendorCosts.filter(a => a.CostOptionId == coheader.id)[0].SampleDistributionDetailsId = e.id
                break;
            case ("Achievable Samples"):
                let distval = returnTargetSample(coheader, "targetsample")
                if (e.target.value == "" || parseInt(e.target.value) < 0) {
                    Vendor.VendorCosts.filter(a => a.CostOptionId == coheader.id)[0].AchivableSamples = 0
                }
                else {
                    if (parseInt(e.target.value) > parseInt(distval || coheader.CostingOptionData.numberOfGroup || coheader.CostingOptionData.sampleSizeMain)
                        || parseInt(e.target.value < 0)) {
                        toastr.error("Please enter valid value")
                    }
                    else {
                        Vendor.VendorCosts.filter(a => a.CostOptionId == coheader.id)[0].AchivableSamples = parseInt(e.target.value)
                        e.target.value = parseInt(e.target.value).toLocaleString()

                    }
                }
                break;
            case ("Comments"):
                Vendor.VendorCosts.filter(a => a.CostOptionId == coheader.id)[0].Comments = e.target.value
                break;
            default:
                break;

        }

        index = props.meth.InvitedVendors.findIndex(v => v.VendorId === props.vendor.VendorId)
        project.CostingProfiles[cIndex].CountrySpecs[props.indexCountry].MethodologySpecs[props.indexMethodology].InvitedVendors[index] = Vendor
        dispatch({
            type: currentProjectActions.UPDATE_NEW_PROJECT,
            newProject: project,
        });
    }
    function ChangeValue(name, e, coheader, cdata) {
        e.target.value = "0";
        switch (name) {
            case "History": {
                addHistory(e, coheader, cdata)
            }
            case "Cost": {
                AddValues(e, coheader, cdata)
            }
        }
    }
    function addHistory(e, coheader, cdata) {
        var Vendor;
        Vendor = _.cloneDeep(props.vendor)
        var calculatedvaluetoUSD = (props?.meth?.InvitedVendors[0]?.Currency?.label == "USD" || cdata.type == "TimingSection") ? e.target.value :
            ((e.target.value == "0" || isNaN(e.target.value)) ? 0 : (e.target.value / props?.meth?.InvitedVendors[0]?.Currency?.ConversionRateToLocal).toFixed(4))
        var history = [];
        let oldVal = vendor?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.CostingsData.filter(a => a.id == cdata.id)[0]?.VendorDataUSD ? vendor?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.CostingsData.filter(a => a.id == cdata.id)[0]?.VendorDataUSD : 0
        if (!(oldVal == calculatedvaluetoUSD)) {
            if (!Vendor.CostHistory) {
                Vendor.CostHistory = []
                history.push({ "oldval": oldVal, "latestvalue": calculatedvaluetoUSD, "doneby": Vendor.label, "CostingOptionsName": coheader.Name, "date": current, "OrignalValue": e.target.value, "Currencylabel": props?.meth?.InvitedVendors[0]?.Currency?.label })
                Vendor.CostHistory.push({ "id": cdata.id, "history": history })
            }
            else {
                if (Vendor.CostHistory.filter(a => a.id == cdata.id).length > 0) {
                    Vendor.CostHistory.filter(a => a.id == cdata.id)[0].history.push({ "oldval": oldVal, "date": current, "latestvalue": calculatedvaluetoUSD, "doneby": Vendor.label, "CostingOptionsName": coheader.Name, "OrignalValue": e.target.value, "Currencylabel": props?.meth?.InvitedVendors[0]?.Currency?.label })
                }
                else {
                    history.push({ "oldval": oldVal, "date": current, "latestvalue": calculatedvaluetoUSD, "doneby": Vendor.label, "CostingOptionsName": coheader.Name, "OrignalValue": e.target.value, "Currencylabel": props?.meth?.InvitedVendors[0]?.Currency?.label })
                    Vendor.CostHistory.push({ "id": cdata.id, "history": history })
                }
            }
            index = props.meth.InvitedVendors.findIndex(v => v.VendorId === props.vendor.VendorId)
            project.CostingProfiles[cIndex].CountrySpecs[props.indexCountry].MethodologySpecs[props.indexMethodology].InvitedVendors[index] = Vendor
            dispatch({
                type: currentProjectActions.UPDATE_NEW_PROJECT,
                newProject: project,
            });
            setVendor(Vendor)
        }
    }
    function AddValues(e, coheader, cdata) {
        var Vendor;
        Vendor = _.cloneDeep(props.vendor)
        var calculatedvaluetoUSD = (props?.meth?.InvitedVendors[0]?.Currency?.label == "USD" || cdata.type == "TimingSection") ? e.target.value :
            ((e.target.value == "0" || isNaN(e.target.value)) ? 0 : (e.target.value / props?.meth?.InvitedVendors[0]?.Currency?.ConversionRateToLocal).toFixed(4))
        var oldval;
        if (Vendor.VendorCosts.filter(a => a.CostOptionId == coheader.id)[0]?.CostingsData.filter(a => a.id == cdata.id).length > 0) {
            oldval = Vendor.VendorCosts.filter(a => a.CostOptionId == coheader.id)[0]?.CostingsData.filter(a => a.id == cdata.id)[0].VendorDataUSD;


            Vendor.VendorCosts.filter(a => a.CostOptionId == coheader.id)[0].CostingsData.filter(a => a.id == cdata.id)[0].VendorDataUSD = (calculatedvaluetoUSD == "" || isNaN(calculatedvaluetoUSD)) ? "" : calculatedvaluetoUSD
            Vendor.VendorCosts.filter(a => a.CostOptionId == coheader.id)[0].CostingsData.filter(a => a.id == cdata.id)[0].OrignalValue = e.target.value;
            Vendor.VendorCosts.filter(a => a.CostOptionId == coheader.id)[0].CostingsData.filter(a => a.id == cdata.id)[0].Currencylabel = props?.meth?.InvitedVendors[0]?.Currency?.label
        }
        else {
            oldval = 0;
            Vendor.VendorCosts.filter(a => a.CostOptionId == coheader.id)[0]?.CostingsData.push({ "VendorDataUSD": ((calculatedvaluetoUSD == "" || isNaN(calculatedvaluetoUSD)) ? "" : calculatedvaluetoUSD), "key": cdata.key, "name": cdata.name, "id": cdata.id, "type": cdata.type, "group": cdata.group, "selected": true, "OrignalValue": e.target.value, "Currencylabel": props?.meth?.InvitedVendors[0]?.Currency?.label })
        }

        index = props.meth.InvitedVendors.findIndex(v => v.VendorId === props.vendor.VendorId)
        project.CostingProfiles[cIndex].CountrySpecs[props.indexCountry].MethodologySpecs[props.indexMethodology].InvitedVendors[index] = Vendor
        dispatch({
            type: currentProjectActions.UPDATE_NEW_PROJECT,
            newProject: project,
        });

    }

    function returnTargetSample(coheader, type) {
        let list;
        let val = splitdata.filter(a => a.id)?.filter(a => a.id == props.vendor.VendorCosts.filter(v => v.CostOptionId == coheader.id)[0]?.RoundId)
        if (val.length > 0) {
            if (props.vendor.VendorCosts.filter(v => v.CostOptionId == coheader.id)[0]?.SampleDistributionDetailsId) {
                list = val[0]?.SampleDistributionMasters?.filter(a => a.CostingOptionId == coheader.id)[0]?.SampleDistributionDetails?.map(a => {
                    return {
                        label: a.DistributionName,
                        value: a.id,
                        DistributionSize: a.DistributionSize
                    }
                }).filter(l => l.value == props.vendor.VendorCosts.filter(v => v.CostOptionId == coheader.id)[0]?.SampleDistributionDetailsId)[0]?.DistributionSize
            }
            else {
                list = val[0]?.SampleDistributionMasters?.filter(a => a.CostingOptionId == coheader.id)[0].TargetSamples
            }
        }
        return list
    }
    function returnDistribution(coheader, type) {
        var val = splitdata?.filter(a => a.MethodologySpecId == props.meth.id).filter(a => {
            if (a.SampleDistributionMasters.filter(a => a.CostingOptionId == coheader.id)[0]?.SampleDistributionDetails?.map(s => s.id).includes(props.vendor.VendorCosts.filter(v => v.CostOptionId == coheader.id)[0]?.SampleDistributionDetailsId)) { return (a); }
        })
        var list
        switch (type) {
            case ("options"):
                if (props.vendor.VendorCosts.filter(v => v.CostOptionId == coheader.id)[0]?.SampleDistributionDetailsId) {

                    if (val.length > 0) {
                        list = val[0].SampleDistributionMasters?.filter(a => a.CostingOptionId == coheader.id)[0]?.SampleDistributionDetails?.map(a => {
                            return {
                                label: a.DistributionName,
                                id: a.id

                            }
                        })
                    }
                }
                else {
                    list = _.last(splitdata?.filter(a => a.MethodologySpecId == props.meth.id))?.SampleDistributionMasters.filter(a => a.CostingOptionId == coheader.id)[0]?.SampleDistributionDetails?.map(a => {
                        return {
                            label: a.DistributionName,
                            id: a.id
                        }
                    })
                }
                break;
            case ("distribution"):
                {
                    if (val.length > 0) {
                        list = val[0].SampleDistributionMasters?.filter(a => a.CostingOptionId == coheader.id)[0]?.SampleDistributionDetails?.map(a => {
                            return {
                                label: a.DistributionName,
                                id: a.id
                            }
                        }).filter(l => l.value == props.vendor.VendorCosts.filter(v => v.CostOptionId == coheader.id)[0]?.SampleDistributionDetailsId)
                    }
                }
                break;
            case ("round"):
                {
                    if (val.length > 0) {
                        list = val[0].RoundName
                    }
                    else
                        list = _.last(splitdata?.filter(a => a.MethodologySpecId == props.meth.id))?.RoundName
                }
                break;
            case ("cities"):
                {
                    if (val.length > 0) {
                        list = val[0].SampleDistributionMasters?.filter(a => a.CostingOptionId == coheader.id)[0]?.SampleDistributionDetails?.map(a => {
                            return {
                                label: a.DistributionName,
                                value: a.id,
                                CoverageDetails: a.CoverageDetails
                            }
                        }).filter(l => l.value == props.vendor.VendorCosts.filter(v => v.CostOptionId == coheader.id)[0]?.SampleDistributionDetailsId)[0]?.CoverageDetails?.map(a => a.label)?.toString();
                    }
                }
                break;
            case ("targetsample"):
                {
                    if (val.length > 0) {
                        list = val[0].SampleDistributionMasters?.filter(a => a.CostingOptionId == coheader.id)[0]?.SampleDistributionDetails?.map(a => {
                            return {
                                label: a.DistributionName,
                                value: a.id,
                                DistributionSize: a.DistributionSize
                            }
                        }).filter(l => l.value == props.vendor.VendorCosts.filter(v => v.CostOptionId == coheader.id)[0]?.SampleDistributionDetailsId)[0]?.DistributionSize
                    }
                }
                break;
            default:
                break;
        }
        return list
    }
    function returnvalue(coheader, cdata) {

        var value = (props.vendor?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.CostingsData?.filter(a => a.id == cdata.id).length > 0 ?
            props.vendor?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.CostingsData?.filter(a => a.id == cdata.id)[0].VendorDataUSD : "")
        let OV = (props.vendor?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.CostingsData?.filter(a => a.id == cdata.id).length > 0 ?
            props.vendor?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.CostingsData?.filter(a => a.id == cdata.id)[0]?.OrignalValue : "")
        let OVLabel = (props.vendor?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.CostingsData?.filter(a => a.id == cdata.id).length > 0 ?
            props.vendor?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.CostingsData?.filter(a => a.id == cdata.id)[0]?.Currencylabel : "")
        var tosend;
        if (value == "") { tosend = "" }
        else {
            if (OVLabel == props?.meth?.InvitedVendors[0]?.Currency?.label) {
                if (!OV?.includes("."))
                    tosend = Math.round(value * props.meth?.InvitedVendors[0]?.Currency.ConversionRateToLocal)
                else {
                    if (OV?.lastIndexOf(".") == OV?.length - 1) {
                        tosend = Math.round(value * props.meth?.InvitedVendors[0]?.Currency.ConversionRateToLocal) + "."
                    }
                    else {
                        let fixeddata = (OV?.length - 1) - OV?.lastIndexOf(".")
                        tosend = (value * props.meth?.InvitedVendors[0]?.Currency.ConversionRateToLocal).toFixed(fixeddata > 2 ? 2 : fixeddata)
                    }
                }
            }
            else
                tosend = (value * props.meth?.InvitedVendors[0]?.Currency.ConversionRateToLocal).toFixed(2)
        }

        return (tosend)
    }
    function returnTotal(coheader) {
        var total
        total = props.vendor?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.CostingsData?.filter(a => a.type != "TimingSection" && a.selected)?.map((c) => ((isNaN(c.VendorDataUSD) || c.VendorDataUSD == '') ? "0" : c.VendorDataUSD))
            .reduce((a, b) => parseFloat(a) + parseFloat(b) + 0, 0)


        total = props?.meth?.InvitedVendors[0]?.Currency?.label == "USD" ? total : (total * props?.meth?.InvitedVendors[0]?.Currency?.ConversionRateToLocal)
        return (total.toFixed(2))
    }
    function returnTotalCPI(coheader) {
        var total
        var totalCPI
        total = props.vendor?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.CostingsData?.filter(a => a.type != "TimingSection" && a.selected)?.map((c) => ((isNaN(c.VendorDataUSD) || c.VendorDataUSD == '') ? "0" : c.VendorDataUSD))
            .reduce((a, b) => parseFloat(a) + parseFloat(b) + 0, 0)
        total = props?.meth?.InvitedVendors[0]?.Currency?.label == "USD" ? total : (total * props?.meth?.InvitedVendors[0]?.Currency?.ConversionRateToLocal).toFixed(2)
        if (props.vendor?.ServiceType != 1) {
            if (props.vendor?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.AchivableSamples != "" && props.vendor?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.AchivableSamples)
                totalCPI = total / parseInt(props.vendor?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.AchivableSamples)
            else
                totalCPI = 0
        }
        else
            totalCPI = total / (coheader.CostingOptionData?.sampleSizeMain || coheader.CostingOptionData?.numberOfGroup)
        return (totalCPI.toFixed(2))
    }
    return (
        <>
            {<Row>
                <Col className="mb-2 col-8 pl-0">
                    <span className='text-danger float-left margin-left'>
                        <h5>
                            Note: After cost submit costing section will be disabled.
                            <br />
                            Please contact Nielsen for further enabling of costing section. </h5></span>
                </Col>
            </Row>}
            <Table
                className="table table-stripped"
                size="sm"
                bordered
                style={{
                    backgroundColor:
                        "white",
                    color:
                        "black",
                    maxWidth: "100%",
                    width: "auto",
                    marginTop: "20px"
                }}
            >
                <tr>
                    <th
                        style={{
                            // display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            minWidth: "250px",
                        }}
                        colSpan={2}
                    >
                    </th>
                    {props.meth.CostingOptions.filter(a => props.meth.InvitedVendors[0].VendorCosts.filter(x => x.CostOptionId == a.id && x.IsInvited).length > 0).map(
                        (coheader) => (
                            <th
                                style={{
                                    maxWidth: "300px",
                                    backgroundColor: "#00f000",
                                    color: "black",
                                }}
                            >
                                {coheader.Name}

                            </th>
                        )
                    )}
                </tr>
                {props.mappingState?.filter(a => a.header && a.type != "TimingSection" && a.selected).map(tab => {
                    return (
                        <>
                            <tr>
                                <th
                                    style={{
                                        backgroundColor: "#00f000",
                                        borderStyle: "groove",
                                        borderRight: "10px",
                                        borderBottom: "10px",
                                    }}
                                    colSpan={
                                        props.meth.CostingOptions.filter(a => props.meth.InvitedVendors[0].VendorCosts.filter(x => x.CostOptionId == a.id && x.IsInvited).length > 0)?.length + 3
                                    }
                                >
                                    {tab.header}

                                </th>
                            </tr>
                            {tab.header == "Field Cost" &&
                                <>
                                    <tr>
                                        <td style={{ position: "relative" }} colSpan={2}>
                                            Target Sample
                                        </td>
                                        {props.meth.CostingOptions.filter(a => props.meth.InvitedVendors[0].VendorCosts.filter(x => x.CostOptionId == a.id && x.IsInvited).length > 0).map(
                                            (coheader) => (
                                                <td>
                                                    <input
                                                        style={{
                                                            marginLeft: "10px",
                                                            marginRight: "10px",
                                                        }}
                                                        type="number"
                                                        id={coheader.id}
                                                        name={coheader.Name}

                                                        title="Target Samples"
                                                        value={returnTargetSample(coheader, "targetsample")}
                                                        disabled={true}
                                                    />
                                                </td>))}
                                    </tr>
                                    <tr>
                                        <td style={{ position: "relative" }} colSpan={2}>
                                            Achievable Samples
                                        </td>
                                        {props.meth.CostingOptions.filter(a => props.meth.InvitedVendors[0].VendorCosts.filter(x => x.CostOptionId == a.id && x.IsInvited).length > 0).map(
                                            (coheader) => (
                                                <td>
                                                    <input
                                                        style={{
                                                            marginLeft: "10px",
                                                            marginRight: "10px",
                                                        }}
                                                        type="number"
                                                        id={coheader.id}
                                                        name={coheader.Name}
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        pattern="[0-9]"
                                                        disabled={ViewOnlyMode || props.vendor.VendorCosts.filter(v => v.CostOptionId == coheader.id)[0]?.IsSelected ||
                                                            props.vendor.VendorCosts.filter(v => v.CostOptionId == coheader.id)[0]?.IsVendorSubmit}
                                                        onChange={(e) => { changesplit(e, coheader, "Achievable Samples") }}
                                                        value={props.vendor.VendorCosts.filter(v => v.CostOptionId == coheader.id)[0]?.AchivableSamples}
                                                        title="Achievable Samples"

                                                    />
                                                </td>))}
                                    </tr>
                                    <tr>
                                        <td style={{ position: "relative" }} colSpan={2}>
                                            CityCoverage
                                        </td>
                                        {props.meth.CostingOptions.filter(a => props.meth.InvitedVendors[0].VendorCosts.filter(x => x.CostOptionId == a.id && x.IsInvited).length > 0).map(
                                            (coheader) => (
                                                <td>
                                                    <label>{props.vendor.VendorCosts.filter(v => v.CostOptionId == coheader.id)[0]?.SampleDistributionDetailsId ? returnDistribution(coheader, "cities") :
                                                        coheader.CostingOptionData["citiesCoverage"]?.map(a => a.label)?.toString()}</label>
                                                </td>))}
                                    </tr>
                                    <tr>
                                        <td style={{ position: "relative" }} colSpan={2}>
                                            Comments (By Costing Option)
                                        </td>
                                        {props.meth.CostingOptions.filter(a => props.meth.InvitedVendors[0].VendorCosts.filter(x => x.CostOptionId == a.id && x.IsInvited).length > 0).map(
                                            (coheader) => (
                                                <td>
                                                    <textarea
                                                        className="form-control w-100"
                                                        type="text"
                                                        onChange={(e) => { changesplit(e, coheader, "Comments") }}
                                                        value={props.vendor.VendorCosts.filter(v => v.CostOptionId == coheader.id)[0]?.Comments
                                                        }
                                                        disabled={ViewOnlyMode || props.vendor.VendorCosts.filter(v => v.CostOptionId == coheader.id)[0]?.IsVendorSubmit}
                                                    />
                                                </td>))}
                                    </tr>
                                </>


                            }

                            {/* timing section  */}
                            {props?.mappingState?.filter(a => (a.type == "TimingSection" && a.group == tab.header && a.selected) || a.header == 'TIMINGS ( # of WORKING DAYS)')?.length > 0 &&
                                <tr>
                                    <th
                                        style={{
                                            backgroundColor: "#dcdcdc",
                                            borderStyle: "groove",
                                            borderRight: "10px",
                                            borderBottom: "10px",
                                        }}
                                        colSpan={2}
                                    >
                                        TIMINGS ( # of WORKING DAYS)
                                    </th>

                                    {props.meth.CostingOptions.filter(a => props.meth.InvitedVendors[0].VendorCosts.filter(x => x.CostOptionId == a.id && x.IsInvited).length > 0)?.map(a => (
                                        <th
                                            style={{
                                                textAlign: "center",
                                                backgroundColor: "#dcdcdc",
                                                borderStyle: "groove",
                                                borderRight: "10px",
                                                borderBottom: "10px",
                                            }}>
                                            Days
                                        </th>))
                                    }
                                </tr>}
                            {props?.mappingState?.filter(a => (a.type == "TimingSection" && a.group == tab.header && a.selected))?.map(cdata => {
                                return (
                                    <tr>
                                        <td style={{ position: "relative" }} colSpan={cdata.name.includes("Other Cost") ? 1 : 2} >
                                            {
                                                cdata.name
                                            }
                                        </td>
                                        {cdata.name.includes("Other Cost") && <td>
                                            <input
                                                style={{
                                                    marginLeft: "10px",
                                                    marginRight: "10px",
                                                }}
                                                type="text"
                                                name={"othercost" + cdata.id + props.meth.id}
                                                title="Select Option"
                                                onChange={(e) => { OtherCost(e, cdata) }}
                                                value={props.vendor?.SelectedRows?.filter(a => a.id == cdata.id)[0]?.OtherCost ? props.vendor?.SelectedRows?.filter(a => a.id == cdata.id)[0]?.OtherCost : ""}
                                                disabled={ViewOnlyMode || props.vendor.VendorCosts.every(v => v?.IsVendorSubmit == true)}
                                            />
                                        </td>}

                                        {props.meth.CostingOptions.filter(a => props.meth.InvitedVendors[0].VendorCosts.filter(x => x.CostOptionId == a.id && x.IsInvited).length > 0).map(
                                            (coheader) => (
                                                <td>

                                                    <input
                                                        style={{
                                                            marginLeft: "10px",
                                                            marginRight: "10px",
                                                        }}
                                                        type="text"
                                                        id={coheader.id}
                                                        name={coheader.Name}
                                                        title="Select Option"
                                                        pattern="[0-9]"
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        onBlur={(e) => {
                                                            e.target.value.includes(".") ? ChangeValue("History", e, coheader, cdata)
                                                                : addHistory(e, coheader, cdata)
                                                        }}
                                                        onChange={(e) => {
                                                            e.target.value.includes(".") ? ChangeValue("Cost", e, coheader, cdata)
                                                                : AddValues(e, coheader, cdata)
                                                        }}
                                                        value={
                                                            (props.vendor?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.CostingsData?.
                                                                filter(a => a.id == cdata.id).length > 0 ? props.vendor?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.CostingsData?.
                                                                    filter(a => a.id == cdata.id)[0].VendorDataUSD : "")
                                                        }
                                                        disabled={ViewOnlyMode || props.vendor.VendorCosts.filter(v => v.CostOptionId == coheader.id)[0]?.IsSelected || props.vendor.VendorCosts.filter(v => v.CostOptionId == coheader.id)[0]?.IsVendorSubmit}

                                                    />
                                                </td>
                                            ))}
                                    </tr>)
                            })}
                            {/* Costing  */}
                            <tr>
                                <th
                                    style={{
                                        backgroundColor: "#dcdcdc",
                                        borderStyle: "groove",
                                        borderRight: "10px",
                                        borderBottom: "10px",
                                    }}
                                    colSpan={
                                        2
                                    }
                                >
                                    Costing Section
                                </th>
                                {props.meth.CostingOptions.filter(a => props.meth.InvitedVendors[0].VendorCosts.filter(x => x.CostOptionId == a.id && x.IsInvited).length > 0)?.map(a => (
                                    <th
                                        style={{
                                            textAlign: "center",
                                            backgroundColor: "#dcdcdc",
                                            borderStyle: "groove",
                                            borderRight: "10px",
                                            borderBottom: "10px",
                                        }}>
                                        <FontAwesomeIcon
                                            title="VendorCurrency"
                                            className="align-middle"
                                            icon={faMoneyBill}
                                            style={{
                                                cursor: "pointer",
                                                marginLeft: "10px",
                                            }}
                                        />

                                        {props.meth?.InvitedVendors[0]?.Currency == null ? "USD" : props.meth?.InvitedVendors[0]?.Currency?.label}
                                    </th>))
                                }
                            </tr>
                            {props.mappingState?.filter(a => (a.group == tab.header) && a.selected && a.id != 14 && a.type != "TimingSection" && !a.header).map(cdata => {
                                return (
                                    <tr>
                                        <td style={{ position: "relative" }} colSpan={cdata.name.includes("Other Cost") ? 1 : 2} >
                                            {
                                                cdata.name
                                            }
                                        </td>
                                        {cdata.name.includes("Other Cost") && <td>
                                            <input
                                                style={{
                                                    marginLeft: "10px",
                                                    marginRight: "10px",
                                                }}
                                                type="text"
                                                name={"othercost" + cdata.id + props.meth.id}
                                                onChange={(e) => { OtherCost(e, cdata) }}
                                                value={props.vendor?.SelectedRows?.filter(a => a.id == cdata.id)[0]?.OtherCost ? props.vendor?.SelectedRows?.filter(a => a.id == cdata.id)[0]?.OtherCost : ""}
                                                title="Select Option"
                                                disabled={ViewOnlyMode || props.vendor.VendorCosts.every(v => v?.IsVendorSubmit == true)}
                                            />
                                        </td>}

                                        {props.meth.CostingOptions.filter(a => props.meth.InvitedVendors[0].VendorCosts.filter(x => x.CostOptionId == a.id && x.IsInvited).length > 0).map(
                                            (coheader) => (
                                                <td>

                                                    <input
                                                        style={{
                                                            marginLeft: "10px",
                                                            marginRight: "10px",
                                                        }}
                                                        type="text"
                                                        id={coheader.id}
                                                        name={coheader.Name}
                                                        title="Select Option"
                                                        onBlur={(e) => { addHistory(e, coheader, cdata) }}
                                                        onChange={(e) => { AddValues(e, coheader, cdata) }}
                                                        value={
                                                            returnvalue(coheader, cdata)
                                                        }
                                                        disabled={ViewOnlyMode || props.vendor.VendorCosts.filter(v => v.CostOptionId == coheader.id)[0]?.IsSelected || props.vendor.VendorCosts.filter(v => v.CostOptionId == coheader.id)[0]?.IsVendorSubmit}

                                                    />
                                                </td>

                                            ))}
                                    </tr>)
                            }
                            )}

                        </>)
                })
                }

                <>
                    <tr>
                        <td style={{ position: "relative" }} colSpan={2} >
                            Total Outsourced Vendor Data collection with QC (External)
                        </td>
                        {props.meth.CostingOptions.filter(a => props.meth.InvitedVendors[0].VendorCosts.filter(x => x.CostOptionId == a.id && x.IsInvited).length > 0).map(
                            (coheader) => (
                                <td>

                                    <input
                                        style={{
                                            marginLeft: "10px",
                                            marginRight: "10px",
                                        }}
                                        type="number"
                                        id={coheader.id}
                                        name={coheader.Name}
                                        title="Select Option"
                                        disabled
                                        value={returnTotal(coheader)}

                                    />
                                </td>
                            ))}
                    </tr>
                    <tr>
                        <td style={{ position: "relative" }} colSpan={2} >
                            Total Outsourced Vendor Data collection with QC (External) CPI
                        </td>
                        {props.meth.CostingOptions.filter(a => props.meth.InvitedVendors[0].VendorCosts.filter(x => x.CostOptionId == a.id && x.IsInvited).length > 0).map(
                            (coheader) => (
                                <td>

                                    <input
                                        style={{
                                            marginLeft: "10px",
                                            marginRight: "10px",
                                        }}
                                        type="number"
                                        id={coheader.id}
                                        name={coheader.Name}
                                        title="Select Option"
                                        disabled
                                        value={returnTotalCPI(coheader)}


                                    />
                                </td>
                            ))}
                    </tr>
                </>
            </Table>
        </>
    )
}
export default VendorCostingAndTimeline