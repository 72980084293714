import React, { useState, useEffect } from "react";
import _ from "lodash";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import { MinusCircle, PlusCircle } from "react-feather";
import { useParams } from "react-router-dom";
import classnames from "classnames";
import PdfGenerate from "./PDFGenerateUpdated";
import { connect, useDispatch, useSelector } from "react-redux";
import * as currentProjectActions from "../../../redux/actions/currentProjectActions";
import * as costingsActions from "../../../redux/actions/costingsActions";
import * as currentCostingActions from "../../../redux/actions/currentCostingActions";
import update from "immutability-helper";
import VbmCopyToallMarket from "./VendorCopyAllMarket";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faTimesCircle,
  faCopy,
  faSpinner,
  faChevronRight,
  faLevelUpAlt,
  faSave,
  faDownload,
  faBan,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledTooltip,
  UncontrolledCollapse,
} from "reactstrap";
import { Prompt } from "react-router";
import CostingOption from "../../proposalNew/CostingOptionFinal";
import BiddingDetails from "./viewDetailsUpdated";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
  Table,
  Collapse,
  CustomInput,
  Media,
} from "reactstrap";
import "./css/common.css";
//import Loader from "../../../../../../fnd/src/theme/components/Loader";
import * as masterActions from "../../../redux/actions/masters/Actions";
import * as proposalActions from "../../../redux/actions/proposal/Actions";
import logo from "./../../../assets/img/avatars/modal-logo.png"; // with import
import { setCurrentDelivery } from "../../../redux/actions/deliveryActions";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import Project from "../../../layouts/Project";
import { pageLoadStart, pageLoadEnd } from "../../../redux/actions/appActions";
import * as appActions from "../../../redux/actions/appActions";
import Loader from "../../../components/Loader";
import {
  SET_TOTAL_FILES,
  SET_UPLOADED_FILES,
  SET_UPLOAD_STATUS,
  SET_VENDOR_FLAG,
  SET_BIDDING_SPLIT_DATA
} from "../../../redux/actions/vendor/ActionTypes";

function VendorBiddingviewUpdated(props) {
  const baseurl = process.env.REACT_APP_API_BASE_URL;
  const projectid = useParams();
  const dispatch = useDispatch();
  const [proposal, setProposalData] = useState({});
  const [DeclineReason, setDeclineReason] = useState("  ");
  const [DeclineEmail, setDeclineEmail] = useState("");
  const [DeclineName, setDeclineName] = useState("");
  const [vendor, setvendor] = useState();
  const [activeTabCostingProfile, setActiveTabCostingProfile] = useState(0);
  const [activeTabCountry, setActiveTabCountry] = useState(0);
  const [activeTabMethodology, setActiveTabMethodology] = useState(0);
  const [modelState, setModelState] = useState(false);
  const [chatData, setChatData] = useState(props.chatData);
  const [methactiveTabSM, setmethActiveTabSM] = useState(0);
  const [isAcknowledged, setisAcknowledged] = useState(false);
  const [ViewOnlyMode, setViewOnlyMode] = useState(false);
  const [fetchData, setfetchData] = useState(false);
  const [render, setRender] = useState(null);
  const [isfirst, setisFirst] = useState(false);
  const [submit, setsubmit] = useState(false);
  const [DisableView, setDisableView] = useState(false);
  const [vendorname, setName] = useState();
  const [vendorAndList, setVendorAndList] = useState(null);
  const [countrytab, setcountrytab] = useState(0);
  const [modelState1, setModelState1] = useState(false);
  const [modelState2, setModelState2] = useState(false);
  var [unExpandableRowssud, setunExpandableRowssud] = useState([]);
  const [MarketCopy, setMarketCopy] = useState(false);
  var [unExpandableRowsrfq, setunExpandableRowsrfq] = useState([]);
  var [DeclineTableValues, setDeclineTableValues] = useState([]);
  const [costingProfileIndex, setCostingProfileIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  useState([]);
  const pageloaded = useSelector(({ app }) => app.vendorPageloaded);


  const declineTableColumns = [
    {
      dataField: "Country",
      text: "Market",
      editable: false,
    },
    {
      dataField: "Methodology",
      text: "Methodology",
      editable: false,
    },
  ];
  var DeclineDataValues = [];
  function SelectDecline(e, row) {
    var project1 = _.cloneDeep(project)
    project1?.CostingProfiles[costingProfileIndex]?.CountrySpecs?.forEach(a => {
      if (a.id == row.CountryID) {
        a?.MethodologySpecs?.forEach(m => {
          if (m.id == row.MethodologyID) {
            m?.InvitedVendors?.forEach(v => {
              if (v.VendorId == props.vendorId && !v.Selected) {
                if (project.DeclineVendorData.filter(f => f.MethodologyID == row.MethodologyID && f.CountryID == row.CountryID).length > 0) {
                  project.DeclineVendorData.filter(f => f.MethodologyID == row.MethodologyID && f.CountryID == row.CountryID)[0].Selected = e.target.checked
                }
              }
            }
            )
          }
        }
        )
      }
    }
    )
  }

  async function validateForms() {
    let obj = {
      "Name": DeclineName,
      "Email": DeclineEmail,
      "Reason": DeclineReason
    }
    var project1 = _.cloneDeep(project)
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if ((DeclineName == "" || !DeclineName) || (DeclineReason == "" || !DeclineReason) ||
      (DeclineEmail == "" || !DeclineEmail)) { toastr.error("Please Enter the Data in Mandatory Fields") }
    else
      if (!DeclineEmail.match(validRegex))
        toastr.error("Invalid Email Address")
      else {

        project1?.CostingProfiles[costingProfileIndex]?.CountrySpecs?.forEach(a => {
          {
            a?.MethodologySpecs?.forEach(m => {
              {
                m?.InvitedVendors?.forEach(v => {
                  if (v.VendorId == props.vendorId && project1.DeclineVendorData?.filter(x => x.MethodologyID == m.id && x.CountryID == a.id).length > 0) {
                    v.IsDeclined = project1.DeclineVendorData?.filter(x => x.MethodologyID == m.id && x.CountryID == a.id)[0]?.Selected
                  }
                }
                )
              }
            }
            )
          }
        })

        project1?.CostingProfiles[costingProfileIndex]?.CountrySpecs?.forEach(a => {
          {
            a?.MethodologySpecs?.forEach(m => {
              {
                m?.InvitedVendors?.forEach(v => {
                  if (v.VendorId == props.vendorId && v.IsDeclined) {
                    v.DeclineInfo = obj
                  }
                }
                )
              }
            }
            )
          }
        })
        let id = [];
        project1?.CostingProfiles[costingProfileIndex]?.CountrySpecs?.forEach(a => {
          a?.MethodologySpecs?.forEach(m => {
            if (m?.InvitedVendors?.filter(v => v.VendorId == props.vendorId)[0]?.IsDeclined) { id.push(m.id) }
          })
        })

        if (id.length) {
          project1["MailPdfString"] = "";
          const pdfstrupdate = await getTemppdf(props).then(
            (x) => (project1["MailPdfString"] = x)
          );

          DeclineDataValues = [];
          let data = {
            "id": project1.CostingProfiles[project1.CostingProfiles.length - 1].id,
            "CountryName": project1?.CommissioningCountry,
            "IsSubmit": false,
            "type": "Vendor",
            "status": "DECLINE",
            "Message": "Proposal has been declined",
            "Project": project1,
            "VendorBidDeadLine": _.last(project1.CostingProfiles).VendorBidDeadLine,
            "VenodrBidDeadLineStatus": _.last(project1.CostingProfiles).VenodrBidDeadLineStatus,
            "VendorBidDeadLineUTC": _.last(project1.CostingProfiles).VendorBidDeadLineUTC,
            "CountrySpecs": project1.CostingProfiles[project1.CostingProfiles.length - 1].CountrySpecs,
            "VendorId": props.vendorId,
            "Error": true
          }
          data["closeToastr"] = true;
          dispatch(
            proposalActions.saveData(
              data,
              false,
              false,
              null
            )
          )
          setIsOpen(!isOpen)
        }
        else {
          toastr.error("Please Select Market & Methodology")
        }
      }
    if (project.CostingProfiles.length > 0) {
      var flag = 0;
      var tableValues = [];
      var i = 0;

      _.last(project.CostingProfiles)?.CountrySpecs?.filter(
        (cs) =>
          cs.MethodologySpecs?.filter((sm) => sm.CostingType == 3).length > 0
      ).forEach((cs, ci) => {
        cs.MethodologySpecs.forEach((ms, mi) => {
          var tableValuesObj = {};
          i += 1;

          if (ms.InvitedVendors?.some((v) => v.VendorId == props.vendorId) && ms.CostingType == 3) {
            tableValuesObj.key = i;
            tableValuesObj.Country = cs.CountryName;
            tableValuesObj.Methodology = ms.Label;
            tableValuesObj.MethodologyID = ms.id;
            tableValuesObj.CountryID = cs.id;
            tableValuesObj.Selected = ms.InvitedVendors?.filter((v) => v.VendorId == props.vendorId)[0]?.IsDeclined;
            tableValuesObj.Disable = ms.InvitedVendors?.filter((v) => v.VendorId == props.vendorId)[0]?.VendorCosts?.filter(x => x.IsSelected).length > 0 ? true : false;
            tableValues.push(tableValuesObj);
          }
        });
      })

      tableValues?.map((sd) => {
        _.last(project.CostingProfiles).CountrySpecs?.filter(
          (cs) =>
            cs.MethodologySpecs?.filter((sm) => sm.CostingType == 3).length > 0
        )?.map((cs, ci) => {
          cs.MethodologySpecs?.map((ms, mi) => {
            if (cs.id == sd.CountryID && ms.id == sd.MethodologyID) {
              if (ms.InvitedVendors?.filter((v) => v.VendorId == props.vendorId).length > 0)
                if (ms.InvitedVendors?.filter((v) => v.VendorId == props.vendorId)[0]?.IsDeclined)
                  flag++;
            }
          });
        });
      });
      setDeclineTableValues(tableValues)
      if (flag == tableValues.length && flag != 0) {
        setsubmit(true)
      }
      else {
        setsubmit(false)
      }
    }

  }
  function unExpandablesud(rows) {
    var temp = [];
    rows
      ?.filter((row) => !row.dependent)
      .forEach((r, i) => {
        if (!rows.some((s) => s.dependentOn == r.label)) {
          temp.push(i);
        }
      });
    setunExpandableRowssud(temp);
  }
  function declindeValues() {
    var tableValues = [];
    project.CostingProfiles[
      project.CostingProfiles.length - 1
    ]?.CountrySpecs.forEach((cs, ci) => {
      cs.MethodologySpecs.forEach((ms, mi) => {
        if (ms?.InvitedVendors?.filter(a => a.VendorId == props.vendorId)?.length > 0) {
          var tableValuesObj = {};
          tableValuesObj.key = ms.id;
          tableValuesObj.Country = cs.CountryName;
          tableValuesObj.Methodology = ms.Label;
          tableValuesObj.CountryID = cs.id;
          tableValues.push(tableValuesObj);
        }
      }
      )
    })
    return tableValues;
  }
  async function SubmitData() {

    project["MailPdfString"] = "";
    const pdfstrupdate = await getTemppdf(props).then(
      (x) => (project["MailPdfString"] = x)
    );
    project.CostingProfiles[project.CostingProfiles.length - 1].CountrySpecs.forEach
      (c => c.MethodologySpecs.forEach(m => m.InvitedVendors.forEach(iv => {

        if (iv.VendorId == props.vendorId) {
          iv.IsCostSubmitted = true
          iv.CostSubmittedCount = iv.CostSubmittedCount + 1
          iv.VendorCosts.forEach(vc => vc.IsVendorSubmit = true)
        }
      })))
    dispatch({
      type: currentProjectActions.UPDATE_NEW_PROJECT,
      newProject: project,
    });
    sendData("submit", true)
  }
  function VendorSubmitCheck() {
    let vs = []
    _.last(project.CostingProfiles)?.CountrySpecs?.filter(
      (cs) =>
        cs.MethodologySpecs?.filter((sm) => sm.CostingType == 3).length > 0
    ).forEach((cs, ci) => {
      cs.MethodologySpecs.forEach((ms, mi) => {
        let tempVC = ms.InvitedVendors?.filter((v) => v.VendorId == props.vendorId)[0]?.VendorCosts
        vs.push(tempVC.every(e => e.IsVendorSubmit == true))
      })
    })
    return vs.every(e => e == true) ? true : false
  }
  function sendData(type, val) {
    let data = {
      "id": project.CostingProfiles[project.CostingProfiles.length - 1].id,
      "CountryName": project?.CommissioningCountry,
      "IsSubmit": val,
      "type": "Vendor",
      "Project": project,
      "Message": val ? "Bidding Submitted" : "Bidding Saved",
      "VendorBidDeadLine": _.last(project.CostingProfiles).VendorBidDeadLine,
      "VenodrBidDeadLineStatus": _.last(project.CostingProfiles).VenodrBidDeadLineStatus,
      "VendorBidDeadLineUTC": val ? _.last(project.CostingProfiles).VendorBidDeadLineUTC : '',
      "CountrySpecs": project.CostingProfiles[project.CostingProfiles.length - 1].CountrySpecs,
      "VendorId": props.vendorId,
      "Error": false
    }
    dispatch(
      proposalActions.saveData(
        data,
        false,
        false,
        null
      )
    );
  }
  function saveDraft() {
    sendData("saveasdraft", false)
  }
  function DeclineValues(val) {
    DeclineDataValues = []
    let i = 0;
    project?.CostingProfiles[costingProfileIndex]?.CountrySpecs?.forEach(a => {
      {
        a?.MethodologySpecs?.forEach(m => {
          {
            m?.InvitedVendors?.forEach(v => {
              if (v.VendorId == props.vendorId && v.IsDeclined) {
                setDeclineName(v?.DeclineInfo?.Name)
                setDeclineEmail(v?.DeclineInfo?.Email)
                setDeclineReason(v?.DeclineInfo?.Reason)
                i = i + 1
              }
            }
            )
          }
        }
        )
      }
    }
    )
    var tableValues = [];
    i = 0;
    _.last(project.CostingProfiles)?.CountrySpecs?.filter(
      (cs) =>
        cs.MethodologySpecs?.filter((sm) => sm.CostingType == 3).length > 0
    ).forEach((cs, ci) => {
      cs.MethodologySpecs.forEach((ms, mi) => {
        var tableValuesObj = {};
        i += 1;
        if (ms.InvitedVendors?.some((v) => v.VendorId == props.vendorId) && ms.CostingType == 3) {
          tableValuesObj.key = i;
          tableValuesObj.Country = cs.CountryName;
          tableValuesObj.Methodology = ms.Label;
          tableValuesObj.MethodologyID = ms.id;
          tableValuesObj.CountryID = cs.id;
          tableValuesObj.Selected = ms.InvitedVendors?.filter((v) => v.VendorId == props.vendorId)[0]?.IsDeclined;
          tableValuesObj.Disable = ms.InvitedVendors?.filter((v) => v.VendorId == props.vendorId)[0]?.VendorCosts?.filter(x => x.IsSelected).length > 0 ? true : false;
          tableValues.push(tableValuesObj);
        }
      });
    })

    project.DeclineVendorData = _.cloneDeep(tableValues)
    dispatch({
      type: currentProjectActions.UPDATE_NEW_PROJECT,
      newProject: project,
    });
    setIsOpen(!val)
  }
  function unExpandablerfq(rows) {
    var temp = [];
    rows
      ?.filter((row) => !row.dependent)
      .forEach((r, i) => {
        if (!rows.some((s) => s.dependentOn == r.label)) {
          temp.push(r.key);
        }
      });
    setunExpandableRowsrfq(temp);
  }
  const [costingType, setCostingType] = useState(false);
  function checkCostingType(data) {
    let count = 0;
    _.last(data.CostingProfiles).CountrySpecs.forEach(co => co.MethodologySpecs.forEach(ms => {
      if (ms.CostingType == 3 && ms.InvitedVendors.filter(iv => iv.VendorId == props.vendorId).length > 0) {
        count = +1;
      }
    })


    )
    if (count == 0) {
      setCostingType(true);
    }
  }



  useEffect(() => {
    dispatch(appActions.pageLoadStart());
    axios.defaults.headers.common["auth-token"] = props.token;
    axios
      .get(baseurl + "vendors/project/bidding", {
        params: {
          ProjectId: props.projectId,
          CostingProfileId: props.costingProfileId,
          VendorId: props.vendorId
        }
      }).then((res) => {
        setIndexes(res.data.data, true);
        checkCostingType(res.data.data);
        dispatch({
          type: currentProjectActions.UPDATE_NEW_PROJECT,
          newProject: res.data.data,
        });
        setDeclineVendorsData(res.data.data);
        setfetchData(true)
        //loaddata(res.data.data)
        dispatch(appActions.pageLoadEnd());
      });
  }, []);
  function loaddata(project) {
    axios
      .post(baseurl + "sampleSizeDistributionRounds/details", {
        MethodologySpecId: project.CostingProfiles[
          project.CostingProfiles.length - 1
        ]?.CountrySpecs.map((cs) =>
          cs.MethodologySpecs?.filter(a => a.CostingType == 3)?.map((ms) => ms.id)
        ).flat(),
      })
      .then((res) => {
        dispatch({ type: SET_BIDDING_SPLIT_DATA, data: res.data.data })
        setfetchData(false)
      })
      .catch((error) => {
        toastr.error("Data Retrival Failed!");
      });
  }
  useEffect(() => {
    if (fetchData) {
      axios
        .post(baseurl + "sampleSizeDistributionRounds/details", {
          MethodologySpecId: project.CostingProfiles[
            project.CostingProfiles.length - 1
          ]?.CountrySpecs.map((cs) =>
            cs.MethodologySpecs?.filter(a => a.CostingType == 3)?.map((ms) => ms.id)
          ).flat(),
        })
        .then((res) => {
          //console.log(res.data.data)
          dispatch({ type: SET_BIDDING_SPLIT_DATA, data: res.data.data })
          setfetchData(false)
        })
        .catch((error) => {
          toastr.error("Data Retrival Failed!");
        });

    }

  }, [fetchData]);
  useEffect(() => {
    let i = 0;
    if (project.id != "" && project.id != null) {
      project?.CostingProfiles[costingProfileIndex]?.CountrySpecs?.forEach(a => {
        {
          a?.MethodologySpecs?.forEach(m => {
            {
              m?.InvitedVendors?.forEach(v => {
                if (v.VendorId == props.vendorId && v.IsDeclined) {
                  setDeclineName(v?.DeclineInfo?.Name)
                  setDeclineEmail(v?.DeclineInfo?.Email)
                  setDeclineReason(v?.DeclineInfo?.Reason)
                  i = i + 1
                }
              }
              )
            }
          }
          )
        }
      }
      )
    }
  }, [project]);

  const project = useSelector(
    ({ currentProject }) => currentProject.newProject
  );

  Date.prototype.addDays = function (days) {
    let date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  function SetDate(current1) {
    var d = current1.getDate();
    var day = d < 10 ? "0" + d : d;
    var m = current1.getMonth() + 1;
    var month = m < 10 ? "0" + m : m;
    var y = current1.getFullYear();
    var h = current1.getHours();
    var hour = h < 10 ? "0" + h : h;
    var minu = current1.getMinutes();
    var minute = minu < 10 ? "0" + minu : minu;
    var GetDate = y + "-" + month + "-" + day + "T" + hour + ":" + minute;
    return GetDate;
  }

  useEffect(() => {
    let tempIsAck = 0
    if (project.ProjectId != "" &&
      project.CostingProfiles[project.CostingProfiles.length - 1]) {

      if (_.last(project.CostingProfiles)?.VenodrBidDeadLineStatus == "close" ||
        _.last(project.CostingProfiles).ProfileStatus == 6
      ) {
        setRender(false);
      }
      else {
        var deadlinedate = new Date(_.last(project.CostingProfiles)?.VendorBidDeadLine);
        var Cdate = new Date();
        var current1 = deadlinedate.addDays(5);
        SetDate(current1);
        var d = current1.getDate();
        var day = d < 10 ? "0" + d : d;
        var m = current1.getMonth() + 1;
        var month = m < 10 ? "0" + m : m;
        var y = current1.getFullYear();
        var h = current1.getHours();
        var hour = h < 10 ? "0" + h : h;
        var minu = current1.getMinutes();
        var minute = minu < 10 ? "0" + minu : minu;
        var extended =
          y + "-" + month + "-" + day + "T" + hour + ":" + minute;
        var currentdate = SetDate(Cdate);

        if (extended < currentdate) {
          setRender(false);
        }
        else {
          setRender(true);
        }
      }

      setIndexes(project, false);

      project.CostingProfiles[
        project.CostingProfiles.length - 1
      ].CountrySpecs?.map((fc, indxFC) =>
        fc.MethodologySpecs?.map(
          (cd) =>
            cd.InvitedVendors?.filter((v) => {
              if (v.VendorId == props.vendorId && !v.IsAcknowledged) { tempIsAck += 1 }

            })
        )
      );

      if ((["2", "3", "4", "5"].indexOf(_.last(project?.CostingProfiles).ProfileStatus) != -1)
        || (project?.ProjectStatus == 7)) {
        setViewOnlyMode(true);
      }
    }
    if (tempIsAck > 0) {
      setisAcknowledged(false);
    }
    else {
      setisAcknowledged(true);
    }
  }, [project]);

  const CostingProfiles = useSelector(
    ({ costings }) => costings.CostingProfiles
  );
  const rowStyleRFQ = (row, rowIndex) => {
    const style = {};
    if (

      project.CostingProfiles[project.CostingProfiles.length - 1]
        .CountrySpecs[countrytab].MethodologySpecs[activeTabSM]
        ?.BiddingDetail?.SelectedRFQ
        ?.some((v) => v.dependentOn == row.label && row.value != "No")
    ) {
      style.backgroundColor = "#9CF89C";
    } else {
      style.backgroundColor = "#eeeeee";
    }

    if (
      props.sm &&
      props.sm.Variables &&
      props.sm.Variables?.filter((v) => v.dependentOn).some((v) =>
        v.dependentOn.fields.some((f) => f.fieldName == row.label)
      )
    ) {
      //   style.fontWeight = 'bold';
      // style.color = 'white';
    }

    return style;
  };

  const rowStyleSUD = (row, rowIndex) => {
    const style = {};
    if (
      project.CostingProfiles[project.CostingProfiles.length - 1]
        .CountrySpecs[countrytab].MethodologySpecs[activeTabSM]
        ?.BiddingDetail?.SelectedSUD
        ?.some((v) => v.dependentOn == row.label && row.value != "No")
    ) {
      style.backgroundColor = "#9CF89C";
    } else {
      style.backgroundColor = "#eeeeee";
    }

    if (
      props.sm &&
      props.sm.Variables &&
      props.sm.Variables?.filter((v) => v.dependentOn).some((v) =>
        v.dependentOn.fields.some((f) => f.fieldName == row.label)
      )
    ) {
    }

    return style;
  };
  var orignalindex;
  function returnValue() {
    let id = [];
    project?.CostingProfiles[costingProfileIndex]?.CountrySpecs?.forEach(a => {
      a?.MethodologySpecs?.forEach(m => {
        if (m?.InvitedVendors?.filter(v => v.VendorId == props.vendorId)[0]?.IsDeclined) { id.push(m.id) }
      })
    })
    return id
  }
  function acknowledged() {
    let data = {
      "CostingProfileId": _.last(project.CostingProfiles).id,
      "VendorId": props.vendorId,
      "status": "ACK",
      "type": "Vendor",
      "projectId": project.ProjectId,
      "VendorBidDeadLineUTC": _.last(project.CostingProfiles).VendorBidDeadLineUTC
    }
    setisAcknowledged(true)
    dispatch(proposalActions.saveVendor(data));
  }
  const selectRow = {
    mode: "checkbox",
    selected: returnValue(),
    hideSelectAll: true,
    onSelect: (row, isSelect, rowIndex, e) => {
      project?.CostingProfiles[costingProfileIndex]?.CountrySpecs?.forEach(a => {
        if (a.id == row.CountryID) {
          a?.MethodologySpecs?.forEach(m => {
            if (m.id == row.key) {
              m?.InvitedVendors?.forEach(v => {
                if (v.VendorId == props.vendorId) {
                  if (DeclineDataValues?.filter(df => df.methid == row.key).length > 0) {
                    DeclineDataValues.filter(df => df.methid == row.key)[0].value = isSelect;
                  }
                  else {
                    DeclineDataValues.push({ methid: row.key, countryid: row.CountryID, value: isSelect })
                  }
                }
              }
              )
            }
          }
          )
        }
      }
      )
    },
  };
  function formatLink(cell, row) {
    return (
      <>
        <textarea
          id={row.key}
          defaultValue={
            row?.dependentOn
              ? project.CostingProfiles[project.CostingProfiles.length - 1]
                .CountrySpecs[countrytab].MethodologySpecs[activeTabSM]
                ?.BiddingDetail?.SelectedRFQ?.find(
                  (f) =>
                    f.label == row.label && f?.dependentOn == row?.dependentOn
                )?.Comments != undefined
                ? project.CostingProfiles[project.CostingProfiles.length - 1]
                  .CountrySpecs[countrytab].MethodologySpecs[activeTabSM]
                  ?.BiddingDetail?.SelectedRFQ?.find(
                    (f) =>
                      f.label == row.label && f?.dependentOn == row?.dependentOn
                  )?.Comments == ""
                  ? ""
                  : project.CostingProfiles[project.CostingProfiles.length - 1]
                    .CountrySpecs[countrytab].MethodologySpecs[activeTabSM]
                    ?.BiddingDetail?.SelectedRFQ
                    .find(
                      (f) =>
                        f.label == row.label &&
                        f?.dependentOn == row?.dependentOn
                    )
                    ?.Comments.find((cm) => cm.vendorid == props.vendorId)
                    ?.comment
                : ""
              : project.CostingProfiles[project.CostingProfiles.length - 1]
                .CountrySpecs[countrytab].MethodologySpecs[activeTabSM]
                ?.BiddingDetail?.SelectedRFQ
                ?.find((f) => f.label == row.label)?.Comments != undefined
                ? project.CostingProfiles[project.CostingProfiles.length - 1]
                  .CountrySpecs[countrytab].MethodologySpecs[activeTabSM]
                  ?.BiddingDetail?.SelectedRFQ
                  ?.find((f) => f.label == row.label)?.Comments == ""
                  ? ""
                  : project.CostingProfiles[project.CostingProfiles.length - 1]
                    .CountrySpecs[countrytab].MethodologySpecs[activeTabSM]
                    ?.BiddingDetail?.SelectedRFQ.find((f) => f.label == row.label)
                    ?.Comments.find((cm) => cm.vendorid == props.vendorId)
                    ?.comment
                : ""
          }
          title="View Comments"
          className="align-middle"
          cols={50}
          rows={2}
          disabled={ViewOnlyMode || DisableView}
          style={{ fontSize: "1rem", marginLeft: "1rem" }}
          onBlur={(e) => {
            setComments(e, "rfq", row?.dependentOn);
          }}
        />
      </>
    );
  }
  function DeclineInfodata(e, type) {
    switch (type) {
      case ("Name"):
        setDeclineName(e.target.value)
        break
      case ("Email"):
        setDeclineEmail(e.target.value)
        break
      case ("Reason"):
        setDeclineReason(e.target.value)
        break
      default:
        break
    }
    // setDeclineInfo(DeclineInfo)
  }
  function formatLinkSUD(cell, row) {
    return (
      <>
        <textarea
          id={row.key}
          defaultValue={
            row?.dependentOn
              ?
              project.CostingProfiles[project.CostingProfiles.length - 1]
                .CountrySpecs[countrytab].MethodologySpecs[activeTabSM]
                .BiddingDetail?.SelectedSUD?.find(
                  (f) => f.key == row.key && f?.dependentOn == row?.dependentOn
                )?.Comments != undefined
                ?
                project.CostingProfiles[project.CostingProfiles.length - 1]
                  .CountrySpecs[countrytab].MethodologySpecs[activeTabSM]
                  .BiddingDetail?.SelectedSUD?.find(
                    (f) =>
                      f.label == row.label && f?.dependentOn == row?.dependentOn
                  )?.Comments == ""
                  ? ""
                  :
                  project.CostingProfiles[project.CostingProfiles.length - 1]
                    .CountrySpecs[countrytab].MethodologySpecs[activeTabSM]
                    .BiddingDetail?.SelectedSUD?.find(
                      (f) =>
                        f.label == row.label &&
                        f?.dependentOn == row?.dependentOn
                    )
                    ?.Comments.find((cm) => cm.vendorid == props.vendorId)
                    ?.comment
                : ""
              :
              project.CostingProfiles[project.CostingProfiles.length - 1]
                .CountrySpecs[countrytab].MethodologySpecs[activeTabSM]
                .BiddingDetail?.SelectedSUD?.find((f) => f.label == row.label)?.Comments != undefined
                ?
                project.CostingProfiles[project.CostingProfiles.length - 1]
                  .CountrySpecs[countrytab].MethodologySpecs[activeTabSM]
                  .BiddingDetail?.SelectedSUD?.find((f) => f.label == row.label)?.Comments == ""
                  ? ""
                  :
                  project.CostingProfiles[project.CostingProfiles.length - 1]
                    .CountrySpecs[countrytab].MethodologySpecs[activeTabSM]
                    .BiddingDetail?.SelectedSUD?.find((f) => f.label == row.label)
                    ?.Comments.find((cm) => cm.vendorid == props.vendorId)
                    ?.comment
                : ""
          }
          title="View Comments"
          className="align-middle"
          cols={50}
          rows={2}
          disabled={ViewOnlyMode || DisableView}
          style={{ fontSize: "1rem", marginLeft: "1rem" }}
          onBlur={(e) => {
            setComments(e, "sud", row?.dependentOn);
          }}
        />
      </>
    );
  }
  function addvendor(e, header, statement) {
    const tempcostdata = _.cloneDeep(
      props.sm.Vendors[methactiveTabSM].CostingData
    );
    if (
      tempcostdata?.filter(
        (tc) => tc.soid == statement.id && tc.coid == header.id
      ).length > 0
    ) {
      tempcostdata.filter(
        (tc) => tc.soid == statement.id && tc.coid == header.id
      )[0].VendorData = e.target.value;
    } else {
      tempcostdata.push({
        coid: header.id,
        soid: statement.id,
        VendorData: e.target.value,
      });
    }
    const newProposal = update(
      project.CostingProfiles[project.CostingProfiles.length - 1],
      {
        CountrySpecs: {
          [props.indexCountry]: {
            MethodologySpecs: {
              [props.indexMethodology]: {
                Vendors: {
                  [methactiveTabSM]: {
                    CostingData: { $set: tempcostdata },
                  },
                },
              },
            },
          },
        },
      }
    );

    project.CostingProfiles[project.CostingProfiles.length - 1] = newProposal;

    dispatch({
      type: currentProjectActions.UPDATE_NEW_PROJECT,
      newProject: project,
    });
  }
  function onbehalfofvendor(e) {
    const newProposal = update(
      project.CostingProfiles[project.CostingProfiles.length - 1],
      {
        CountrySpecs: {
          [props.indexCountry]: {
            MethodologySpecs: {
              [props.indexMethodology]: {
                Vendors: {
                  [methactiveTabSM]: {
                    $merge: { SelfBidding: e.target.checked },
                  },
                },
              },
            },
          },
        },
      }
    );

    project.CostingProfiles[project.CostingProfiles.length - 1] = newProposal;

    dispatch({
      type: currentProjectActions.UPDATE_NEW_PROJECT,
      newProject: project,
    });
  }

  useEffect(() => {
    if (vendorAndList?.length > 0) {
      // var Name=  props.redux_masters?.filter(v=>v.value==props.vendorId)[0].Name;
      var Name = vendorAndList
        ?.filter((x) => x.Group == "Vendors")[0]
        ?.List?.filter((v) => v.id == props.vendorId)[0]?.VendorName;
      const nm = vendorAndList
        ?.filter((x) => x.Group == "Vendors")[0]
        ?.List?.filter((v) => v.id == props.vendorId)[0]?.VendorName;
      setName(Name);
    }
    //     setvendor(redux_masters?.filter(x => x.Group == "Vendors")[0]["List"]?.map(x => ({ label: x.label, value: x.value, CostingData: [] })));
    //    if( vendor?.filter(v=>v.value== props.vendorId)?.map((vendor, indxMV)=>vendor.CostingData && vendor.CostingData?.map(cd => cd.VendorData).length>0 ))
    //    {setsubmit(true)}

    // }
  }, [vendorAndList]);

  useEffect(() => {
    if (props.proposal && !props.proposal.id) {
      // dispatch(proposalActions.fetchProposalDetailsById(props.projectId, props.vendorId));
    } else {
      setProposalData(props.proposal);
    }
  }, [props.proposal]);

  useEffect(() => {
    if (props.msg) alert.show(props.msg);
  }, [props.msg]);
  function toggleCostingProfile(tab) {
    if (activeTabCostingProfile !== tab) {
      setActiveTabCostingProfile(tab);
      setActiveTabCountry(0);
      setActiveTabMethodology(0);
    }
  }
  function toggleCountry(tab) {
    if (activeTabCountry !== tab) {
      setActiveTabCountry(tab);
      setActiveTabMethodology(0);
    }
  }
  function toggleMethodology(tab) {
    if (activeTabMethodology !== tab) {
      setActiveTabMethodology(tab);
    }
  }
  function onMethodologyInfoChange(
    indexCostingProfile,
    indexCountry,
    indexMethodology,
    e
  ) {
    const newProposal = update(proposal, {
      CostingProfiles: {
        [indexCostingProfile]: {
          CountrySpecs: {
            [indexCountry]: {
              MethodologySpecs: {
                [indexMethodology]: {
                  $merge: { [e.target.name]: e.target.value },
                },
              },
            },
          },
        },
      },
    });
    setProposalData(newProposal);
  }
  function onMethodologySelectChange(
    name,
    indexCostingProfile,
    indexCountry,
    indexMethodology,
    val
  ) {
    const newProposal = update(proposal, {
      CostingProfiles: {
        [indexCostingProfile]: {
          CountrySpecs: {
            [indexCountry]: {
              MethodologySpecs: {
                [indexMethodology]: {
                  $merge: { [name]: JSON.parse(JSON.stringify(val)) },
                },
              },
            },
          },
        },
      },
    });
    setProposalData(newProposal);
  }

  var setIndexes = (project, value) => {
    if (project.CostingProfiles.length > 0) {
      if (value) {

        var indexfc = _.last(project.CostingProfiles).CountrySpecs?.filter(
          (cs) =>
            cs.MethodologySpecs?.filter((sm) => sm.CostingType == 3).length > 0
        )?.filter(
          (a) =>
            a.MethodologySpecs?.filter(
              (d) =>
                d.CostingType == 3 &&
                d.InvitedVendors?.filter((v) => v.VendorId == props.vendorId).length > 0
            ).length > 0
        )[0]?.id;

        var indexsm = _.last(project.CostingProfiles).CountrySpecs?.filter((cs) => cs.id == indexfc)[0]
          ?.MethodologySpecs?.filter((d) => d.CostingType == 3)
          ?.filter(
            (a) => a.InvitedVendors?.filter((v) => v.VendorId == props.vendorId)[0]
          )[0]?.id;

        setActiveTabFC(_.last(project.CostingProfiles).CountrySpecs?.filter(
          (cs) =>
            cs.MethodologySpecs
        ).findIndex((m) => m.id == indexfc));
        setcountrytab(
          _.last(project.CostingProfiles).CountrySpecs.findIndex((m) => m.id == indexfc)
        );
        setActiveTabSM(
          _.last(project.CostingProfiles).CountrySpecs?.filter(
            (m) => m.id == indexfc
          )[0]?.MethodologySpecs.findIndex((m) => m.id == indexsm)
        );
        setActiveTabMethodology(indexsm);
      }
      var flag = 0;
      var tableValues = [];
      var i = 0;

      _.last(project.CostingProfiles)?.CountrySpecs?.filter(
        (cs) =>
          cs.MethodologySpecs?.filter((sm) => sm.CostingType == 3).length > 0
      ).forEach((cs, ci) => {
        cs.MethodologySpecs.forEach((ms, mi) => {
          var tableValuesObj = {};
          i += 1;

          if (ms.InvitedVendors?.some((v) => v.VendorId == props.vendorId) && ms.CostingType == 3) {
            tableValuesObj.key = i;
            tableValuesObj.Country = cs.CountryName;
            tableValuesObj.Methodology = ms.Label;
            tableValuesObj.MethodologyID = ms.id;
            tableValuesObj.CountryID = cs.id;
            tableValuesObj.Selected = ms.InvitedVendors?.filter((v) => v.VendorId == props.vendorId)[0]?.IsDeclined;
            tableValuesObj.Disable = ms.InvitedVendors?.filter((v) => v.VendorId == props.vendorId)[0]?.VendorCosts?.filter(x => x.IsSelected).length > 0 ? true : false;
            tableValues.push(tableValuesObj);
          }
        });
      })

      tableValues?.map((sd) => {
        _.last(project.CostingProfiles).CountrySpecs?.filter(
          (cs) =>
            cs.MethodologySpecs?.filter((sm) => sm.CostingType == 3).length > 0
        )?.map((cs, ci) => {
          cs.MethodologySpecs?.map((ms, mi) => {
            if (cs.id == sd.CountryID && ms.id == sd.MethodologyID) {
              if (ms.InvitedVendors?.filter((v) => v.VendorId == props.vendorId).length > 0)
                if (ms.InvitedVendors?.filter((v) => v.VendorId == props.vendorId)[0]?.IsDeclined)
                  flag++;
            }
          });
        });
      });
      if (flag == tableValues.length && flag != 0) {
        setsubmit(true)
      }
      else {
        setsubmit(false)
      }
      setDeclineTableValues(tableValues)
      //console.log(tableValues)
    }
  };
  var setDeclineVendorsData = (project) => {
    if (project.CostingProfiles.length > 0) {
      var tableValues = [];
      var i = 0;
      _.last(project.CostingProfiles)?.CountrySpecs?.filter(
        (cs) =>
          cs.MethodologySpecs?.filter((sm) => sm.CostingType == 3).length > 0
      ).forEach((cs, ci) => {
        cs.MethodologySpecs.forEach((ms, mi) => {
          var tableValuesObj = {};
          i += 1;
          if (ms.InvitedVendors?.some((v) => v.VendorId == props.vendorId) && ms.CostingType == 3) {
            tableValuesObj.key = i;
            tableValuesObj.Country = cs.CountryName;
            tableValuesObj.Methodology = ms.Label;
            tableValuesObj.MethodologyID = ms.id;
            tableValuesObj.CountryID = cs.id;
            tableValuesObj.Selected = ms.InvitedVendors?.filter((v) => v.VendorId == props.vendorId)[0]?.IsDeclined;
            tableValuesObj.Disable = ms.InvitedVendors?.filter((v) => v.VendorId == props.vendorId)[0]?.VendorCosts?.filter(x => x.IsSelected).length > 0 ? true : false;
            tableValues.push(tableValuesObj);
          }
        });
      })

      project.DeclineVendorData = _.cloneDeep(tableValues)
      dispatch({
        type: currentProjectActions.UPDATE_NEW_PROJECT,
        newProject: project,
      });
    }
  };

  function setComments(e, inp, a) {
    var i;

    if (inp == "rfq") {
      const SelectedRFQ1 = _.cloneDeep(_.last(project.CostingProfiles)?.CountrySpecs[countrytab].MethodologySpecs[activeTabSM]
        .BiddingDetail?.SelectedRFQ)
      if (a)
        //    i = SelectedRFQ1?.filter(sr => sr?.dependentOn).findIndex(sr => sr.key == e.target.id && sr?.dependentOn == a)
        i = SelectedRFQ1.findIndex(
          (sr) => sr.key == e.target.id && sr?.dependentOn == a
        );
      else i = SelectedRFQ1.findIndex((sr) => sr.key == e.target.id);
      var comment = SelectedRFQ1[i]?.Comments;
      if (comment == "" || comment == undefined) {
        comment = [];
      }
      if (
        comment.length > 0 &&
        comment?.filter((c) => parseInt(c.vendorid) == props.vendorId).length >
        0
      ) {
        comment[
          comment.findIndex((c) => c.vendorid == props.vendorId)
        ].comment = e.target.value;
      } else {
        comment.push({
          comment: e.target.value,
          vendorid: props.vendorId,
          vendorName: props.Name,
        });
      }

      SelectedRFQ1[i].Comments = comment;
      var project1 = _.cloneDeep(
        project.CostingProfiles[project.CostingProfiles.length - 1]
      );

      project1.CountrySpecs[countrytab].MethodologySpecs[
        activeTabSM
      ].BiddingDetail.SelectedRFQ = SelectedRFQ1
      project.CostingProfiles[project.CostingProfiles.length - 1] = project1;
      dispatch({
        type: currentProjectActions.UPDATE_NEW_PROJECT,
        newProject: project,
      });
    } else if (inp == "sud") {
      const SelectedSUD1 = _.cloneDeep(_.last(project.CostingProfiles)?.CountrySpecs[countrytab].MethodologySpecs[activeTabSM]
        .BiddingDetail?.SelectedSUD);
      if (a)
        //    i = SelectedSUD1?.filter(sr => sr?.dependentOn).findIndex(sr => sr.key == e.target.id && sr?.dependentOn == a)
        i = SelectedSUD1.findIndex(
          (sr) => sr.key == e.target.id && sr?.dependentOn == a
        );
      else i = SelectedSUD1.findIndex((sr) => sr.key == e.target.id);
      var comment = SelectedSUD1[i]?.Comments;
      if (comment == "" || comment == undefined) {
        comment = [];
      }
      if (
        comment?.length > 0 &&
        comment?.filter((c) => parseInt(c.vendorid) == props.vendorId)?.length >
        0
      ) {
        comment[
          comment.findIndex((c) => c.vendorid == props.vendorId)
        ].comment = e.target.value;
      } else {
        comment.push({
          comment: e.target.value,
          vendorid: props.vendorId,
          vendorName: props.Name,
        });
      }
      SelectedSUD1[i].Comments = comment;

      var project1 = _.cloneDeep(
        project.CostingProfiles[project.CostingProfiles.length - 1]
      );
      project1.CountrySpecs[countrytab].MethodologySpecs[
        activeTabSM
      ].BiddingDetail.SelectedSUD = SelectedSUD1;
      project.CostingProfiles[project.CostingProfiles.length - 1] = project1;
      dispatch({
        type: currentProjectActions.UPDATE_NEW_PROJECT,
        newProject: project,
      });
    }
  }
  const [activeTabFC, setActiveTabFC] = useState(0);
  const [activeTabSM, setActiveTabSM] = useState(0);
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);

  const child = React.createRef();

  var varcheck = "carrr";

  function newsave(newprops) {
    child.current.PrintDocument1(props);
  }
  async function getTemppdf(newprops) {
    var pdfstr = "";
    const ab = await child.current.Textpdf(props).then((ax) => {
      pdfstr = ax;
    });
    return pdfstr;
  }
  const tableColumns2 = [
    {
      dataField: "label",
      text: "Task",
      // sort: true,
      editable: false,
    },
    {
      dataField: "value",
      text: "Details",
      // sort: true,
      editable: false,
    },
    {
      dataField: "Comments",
      text: "Comments",
      formatter: formatLink,
      editable: false,
    },
  ];

  const tableColumnsSUD = [
    {
      dataField: "label",
      text: "Task",
      // sort: true,
      editable: false,
    },
    {
      dataField: "value",
      text: "Details",
      // sort: true,
      editable: false,
    },
    {
      dataField: "Comments",
      text: "Comments",
      formatter: formatLinkSUD,
      editable: false,
    },
  ];

  const expandRow = {
    renderer: (row) =>
      row && (
        <Col md={12}>
          <Card>
            <ToolkitProvider
              keyField="key"
              data={
                project.CostingProfiles[project.CostingProfiles.length - 1]
                  .CountrySpecs[countrytab].MethodologySpecs[activeTabSM]
                  ?.BiddingDetail?.SelectedRFQ
                  ?.filter((v) => v.dependentOn == row.label && row.value != "No")}
              columns={tableColumns2}
            >
              {(props) => (
                <CardBody>
                  <BootstrapTable
                    {...props.baseProps}
                    bootstrap4
                    wrapperClasses="table-responsive"
                    bordered={true}
                    filterPosition="top"
                  />
                </CardBody>
              )}
            </ToolkitProvider>
          </Card>
        </Col>
      ),
    showExpandColumn: true,
    expandByColumnOnly: true,
    nonExpandable: unExpandableRowsrfq,
    //   expanded: [modelRowInde],
    expandHeaderColumnRenderer: ({ isAnyExpands }) =>
      isAnyExpands ? (
        <MinusCircle width={16} height={16} />
      ) : (
        <PlusCircle width={16} height={16} />
      ),
    expandColumnRenderer: ({ expanded, a, b }) =>
      expanded ? (
        <MinusCircle width={16} height={16} />
      ) : (
        <PlusCircle width={16} height={16} />
      ),
    className: "expanding-foo",
  };
  const expandRowSUD = {
    renderer: (row) =>
      row && row.value != "No" && project.CostingProfiles[project.CostingProfiles.length - 1]
        .CountrySpecs[countrytab].MethodologySpecs[activeTabSM]
        ?.BiddingDetail?.SelectedSUD
        ?.filter((v) => v.dependent && v.dependentOn == row.label && row.value != "No").length > 0 ? (
        <Col md={12}>
          <Card>
            <ToolkitProvider
              keyField="key"
              data={project.CostingProfiles[project.CostingProfiles.length - 1]
                .CountrySpecs[countrytab].MethodologySpecs[activeTabSM]
                ?.BiddingDetail?.SelectedSUD
                ?.filter((v) => v.dependent && v.dependentOn == row.label && row.value != "No")}
              columns={tableColumnsSUD}
            >
              {(props) => (
                project.CostingProfiles[project.CostingProfiles.length - 1]
                  .CountrySpecs[countrytab].MethodologySpecs[activeTabSM]
                  ?.BiddingDetail?.SelectedSUD
                  ?.filter((v) => v.dependent && v.dependentOn == row.label && row.value != "No").length > 0 &&
                <CardBody>
                  <BootstrapTable
                    {...props.baseProps}
                    bootstrap4
                    wrapperClasses="table-responsive"
                    bordered={true}
                    filterPosition="top"
                  />
                </CardBody>
              )}
            </ToolkitProvider>
          </Card>
        </Col>
      ) : (<div style={{ textAlign: "center" }}>
        No Dependents Fields
      </div>
      ),
    showExpandColumn: true,
    expandByColumnOnly: true,
    nonExpandable: unExpandableRowssud,
    //   expanded: [modelRowInde],
    expandHeaderColumnRenderer: ({ isAnyExpands }) =>
      isAnyExpands ? (
        <MinusCircle width={16} height={16} />
      ) : (
        <PlusCircle width={16} height={16} />
      ),
    expandColumnRenderer: ({ expanded, a, b }) =>
      expanded ? (
        <MinusCircle width={16} height={16} />
      ) : (
        <PlusCircle width={16} height={16} />
      ),
    className: "expanding-foo",
  };

  var tab1;
  function toggle(tab, name) {
    if (activeTabFC !== tab) {
      setActiveTabFC(tab);

      project.CostingProfiles[
        project.CostingProfiles.length - 1
      ].CountrySpecs.forEach((o, ocindex) => {
        if (o.CountryName == name) {
          setcountrytab(ocindex);
          tab1 = ocindex;
        }
      });

      var indexsm = project.CostingProfiles[
        project.CostingProfiles.length - 1
      ].CountrySpecs[tab1].MethodologySpecs?.filter(
        (d) => d.CostingType == 3
      )[0].id;

      setActiveTabSM(
        project.CostingProfiles[
          project.CostingProfiles.length - 1
        ].CountrySpecs[tab1].MethodologySpecs.findIndex((m) => m.id == indexsm)
      );
    }
  }
  const updateProject = (newProject) =>
    dispatch({
      type: currentProjectActions.UPDATE_NEW_PROJECT,
      newProject: newProject,
    });
  function onBlur() { }
  var styles = {
    display: "inline",
  };
  var stylesDiv = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };
  var imgStyle = {
    width: "150px",
    padding: "10px"
  };
  var ModalHeaderStyle = {
    paddingBottom: "0px"
  };
  var ModalFooterStyle = {
    display: "flex",
    justifyContent: "flex-Start",
    marginLeft: "10px"
  };
  var HeadingStyle = {
    marginLeft: "10px"
  };
  var FontStyle = {
    fontSize: "14px",
  };
  const rowStyle = (row, rowIndex) => {
    return { padding: "0.5rem" };
  };

  function toggleSM(tab) {
    if (activeTabSM !== tab) {
      setActiveTabSM(tab);
      setModelState1(false);
      setModelState2(false);
    }
  }
  return (
    <>
      {render == false && (
        <>
          <Container fluid className="p-0">
            <div
              style={{
                position: "relative",
                alignItems: "center",
                justifyContent: "center",
                height: "300px",
                width: "700px",
                marginLeft: "25%",
                marginTop: "10%",
                border: "1px solde black",
                boxShadow: "4px 2px 4px grey",
                borderRadius: "2px",
              }}
              className="container"
            >
              <h1
                style={{
                  textAlign: "center",
                  color: "#AD4400",
                  backgroundColor: "rgb(217,222,240)",
                }}
              >
                LINK EXPIRED
              </h1>
              <h2 style={{ textAlign: "center" }}>
                Please Contact Nielsen to Activate your Link
              </h2>
            </div>
          </Container>
        </>
      )}

      {project && render && pageloaded && (
        <>
          {
            <Container fluid className="p-0">
              {
                <>
                  <Row>
                    <Col lg="12">
                      <Card>
                        <CardHeader>
                          <CardTitle tag="h5" className="my-2">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                              }}
                            >
                              <div>
                                {/* <img src={logo} className="modallogo" /> */}
                              </div>
                              <Form
                                inline
                                style={{
                                  alignItems: "flex-start",
                                  marginLeft: "20px",
                                }}
                              >
                                <h2 className="mt-2">
                                  {"Proposal Bidding"}
                                </h2>
                                <ul style={{ listStyleType: "none" }}>
                                  <li>
                                    <h5
                                      style={{
                                        marginBottom: "0px",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      <span> Project Name: </span>
                                      {project.ProjectName}
                                    </h5>
                                  </li>
                                  <li>
                                    <h5
                                      style={{
                                        marginBottom: "0px",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      <span> Project ID: </span>
                                      {project.ProjectId}
                                    </h5>
                                  </li>
                                  <li>
                                    <h5
                                      style={{
                                        marginBottom: "0px",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      <span> Vendor Name: </span>
                                      {props?.Name}
                                    </h5>
                                  </li>
                                </ul>
                              </Form>
                              {!isAcknowledged && (
                                <Col>
                                  <Button
                                    onClick={() => acknowledged()}
                                    style={{
                                      position: "relative",
                                      float: "right",
                                    }}
                                    disabled={ViewOnlyMode}
                                    color="primary"
                                  >
                                    Acknowledge
                                  </Button>
                                </Col>
                              )}
                            </div>
                          </CardTitle>
                        </CardHeader>
                      </Card>
                    </Col>
                  </Row>
                  {isAcknowledged && !costingType && (
                    <>
                      <Row>
                        <Col lg="12">
                          <Card>
                            <CardHeader>
                              <CardTitle tag="h5" className="my-2">
                                <Row>
                                  <Col md={11}>
                                    <Label>Project Details</Label>
                                  </Col>
                                  <Col md={1}>
                                    <FontAwesomeIcon
                                      id="collapseProjectDetails"
                                      icon={faChevronDown}
                                      href="#collapseProjectDetails"
                                    />
                                  </Col>
                                </Row>
                              </CardTitle>
                            </CardHeader>
                            <UncontrolledCollapse
                              toggler="#collapseProjectDetails"
                              defaultOpen={true}
                            >
                              <CardBody>
                                <Form>
                                  <Row form>
                                    <Col md={3}>
                                      <FormGroup>
                                        <Label>Project ID</Label>
                                        <Input
                                          type="text"
                                          name="id"
                                          value={project.ProjectId}
                                          disabled
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                      <FormGroup>
                                        <Label>Single/Multiple Country</Label>
                                        <Input
                                          type="text"
                                          name="IsSingleCountry"
                                          value={
                                            project.CostingProfiles &&
                                              project.CostingProfiles.length >
                                              0 &&
                                              project?.CostingProfiles[costingProfileIndex]
                                                ?.IsMultiCountry == false
                                              ? "Single Country"
                                              : "Multi Country"
                                          }
                                          disabled
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                      <FormGroup>
                                        <Label>Commissioning Country</Label>
                                        <input
                                          type="text"
                                          className="form-control w-100"
                                          style={{ height: "2rem" }}
                                          name="CommissioningCountry"
                                          value={
                                            _.last(project?.CostingProfiles)
                                              ?.ProfileSetting?.CurrenciesData?.filter(
                                                (comm) =>
                                                  comm.Code ==
                                                  project?.CommissioningCountry
                                              )[0]?.Label
                                          }
                                          disabled
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                      <FormGroup>
                                        <Label style={{ color: "#AD4400" }}>
                                          Deadline For Submission{""}
                                        </Label>
                                        <br />
                                        {_.last(project.CostingProfiles).VendorBidDeadLineUTC}
                                        {/* <Input
                                          type="datetime-local"
                                          name="VendorBidDeadLine"
                                          defaultValue={
                                            _.last(project?.CostingProfiles)?.VendorBidDeadLine
                                          }
                                          disabled
                                        //    onChange={(e) => adddeadline(e)}
                                        /> */}
                                      </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                      <FormGroup>
                                        <Label>Background</Label>
                                        <Input
                                          type="textarea"
                                          name="Background"
                                          value={
                                            project.ProjectBackground || ""
                                          }
                                          disabled
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                      <FormGroup>
                                        <Label>Objectives</Label>
                                        <Input
                                          type="textarea"
                                          name="Objectives"
                                          value={
                                            project.ResearchObjectives || ""
                                          }
                                          disabled
                                        />
                                      </FormGroup>
                                    </Col>
                                    {(
                                      <Col md={3}>
                                        <FormGroup>
                                          <Label>
                                            <b>Fielding Countries</b>
                                          </Label>
                                          <Input
                                            type="textarea"
                                            name="Objectives"
                                            value={
                                              project.CostingProfiles.length > 0 && _.last(project.CostingProfiles).CountrySpecs &&
                                              [...new Set(_.last(project.CostingProfiles).CountrySpecs?.map((cdata, index) => cdata?.MethodologySpecs?.map((mdata, msindex) => mdata?.CostingType == 3 && cdata)).flatMap(fm => fm?.flatMap(mf => mf?.CountryName)))]?.toLocaleString()}
                                            disabled
                                          />
                                        </FormGroup>
                                      </Col>
                                    )}
                                  </Row>
                                </Form>
                              </CardBody>
                            </UncontrolledCollapse>
                          </Card>
                        </Col>
                      </Row>

                      <Card>
                        <CardHeader></CardHeader>
                        <CardBody>
                          <Row>
                            <Col md={12} className={"countryTabs tab-horizontal"}>
                              <div className={"tab"}>
                                <Nav tabs>
                                  {_.last(project?.CostingProfiles)?.CountrySpecs?.filter(
                                    (cs) =>
                                      cs.MethodologySpecs
                                  )?.map(
                                    (fc, indxFC) =>
                                      fc.MethodologySpecs?.filter(
                                        (a) =>
                                          a.CostingType == 3 &&
                                          a.InvitedVendors?.filter(
                                            (v) => v.VendorId == props.vendorId
                                          ).length > 0
                                      ).length > 0 &&
                                      fc.MethodologySpecs?.filter(
                                        (sm) => sm.CostingType == 3
                                      ) && (
                                        <NavItem key={fc.value}>
                                          <NavLink
                                            className={classnames({
                                              active: activeTabFC === indxFC,
                                            })}
                                            onClick={() => {
                                              toggle(indxFC, fc.CountryName);
                                            }}
                                          >
                                            <b>{fc.CountryName}</b>
                                          </NavLink>
                                        </NavItem>
                                      )
                                  )}
                                </Nav>

                                <TabContent activeTab={activeTabFC}>
                                  <Row>
                                    <Col md={12} className={"methodologyTabs"}>
                                      <Nav tabs>
                                        {_.last(project?.CostingProfiles)?.CountrySpecs[
                                          countrytab
                                        ]?.MethodologySpecs?.map(
                                          (sm, indxSM) =>
                                            !sm.NotApplicable &&
                                            sm.CostingType == 3 &&
                                            sm.InvitedVendors?.filter(
                                              (v) => v.VendorId == props.vendorId
                                            ).length > 0 && (
                                              <NavItem key={sm.value}>
                                                <NavLink
                                                  className={classnames({
                                                    active:
                                                      activeTabSM === indxSM,
                                                  })}
                                                  onClick={() => {
                                                    toggleSM(indxSM);
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent:
                                                        "space-between",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    <div>
                                                      {sm.NotApplicable}
                                                      <b>{sm.Label}</b>
                                                    </div>
                                                  </div>
                                                </NavLink>
                                              </NavItem>
                                            )
                                        )}
                                      </Nav>

                                      <TabContent activeTab={activeTabSM}
                                        style={{ padding: "0.25" }}
                                      >
                                        {_.last(project?.CostingProfiles)?.CountrySpecs[
                                          countrytab
                                        ]?.MethodologySpecs[
                                          activeTabSM
                                        ]?.InvitedVendors?.find(
                                          (v) => v.VendorId == props.vendorId
                                        )?.IsDeclined && (
                                            <>
                                              <Container fluid className="p-0">
                                                <div
                                                  style={{
                                                    position: "relative",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    height: "300px",
                                                    width: "700px",
                                                    marginLeft: "25%",
                                                    marginTop: "10%",
                                                    border: "1px solde black",
                                                    boxShadow: "4px 2px 4px grey",
                                                    borderRadius: "2px",
                                                  }}
                                                  className="container"
                                                >
                                                  <h1
                                                    style={{
                                                      textAlign: "center",
                                                      color: "#AD4400",
                                                      backgroundColor:
                                                        "rgb(217,222,240)",
                                                    }}
                                                  >
                                                    Proposal Declined
                                                  </h1>
                                                  <h2
                                                    style={{
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    Proposal has been Decline for
                                                    this Country and Methodology
                                                  </h2>
                                                </div>
                                              </Container>
                                            </>
                                          )}
                                        {project.CostingProfiles.length > 0 &&
                                          _.last(project.CostingProfiles)
                                            .CountrySpecs &&
                                          _.last(
                                            project.CostingProfiles
                                          ).CountrySpecs?.filter(
                                            (cs) =>
                                              cs?.MethodologySpecs?.filter((sm) => sm.CostingType == 3).length > 0
                                          )[
                                            activeTabFC
                                          ]?.MethodologySpecs.map(
                                            (sm, indxSM) =>
                                              !sm.NotApplicable &&
                                              activeTabSM === indxSM &&
                                              (!_.last(project?.CostingProfiles)?.CountrySpecs[
                                                countrytab
                                              ]?.MethodologySpecs[
                                                activeTabSM
                                              ]?.InvitedVendors?.find(
                                                (v) => v.VendorId == props.vendorId
                                              )?.IsDeclined) && (
                                                <>
                                                  <TabPane
                                                    tabId={indxSM}
                                                    key={sm.value}
                                                  >
                                                    {sm.CostingOptions &&
                                                      sm.CostingType == 3 && (
                                                        <Card
                                                          style={{
                                                            overflow:
                                                              "scroll",
                                                          }}
                                                        >
                                                          <CardBody>
                                                            <Table
                                                              className="table table-stripped"
                                                              size="sm"
                                                              bordered
                                                              style={{
                                                                backgroundColor:
                                                                  "white",
                                                                maxWidth:
                                                                  "100%",
                                                                width: "auto",
                                                                overflow:
                                                                  "scroll",
                                                              }}
                                                            >
                                                              <tr>
                                                                <th
                                                                  style={{
                                                                    display:
                                                                      "flex",
                                                                    justifyContent:
                                                                      "space-between",
                                                                    alignItems:
                                                                      "center",
                                                                    minWidth:
                                                                      "250px",
                                                                    maxWidth:
                                                                      "250px",
                                                                  }}
                                                                >
                                                                  Costing
                                                                  Parameter
                                                                </th>
                                                                {sm.CostingOptions?.filter(a => sm.InvitedVendors[0].VendorCosts.filter(x => x.CostOptionId == a.id && x.IsInvited).length > 0)?.map(
                                                                  // (header) => header.Selected==true &&(
                                                                  (header) =>
                                                                  (
                                                                    <th>
                                                                      {
                                                                        header.Name
                                                                      }
                                                                    </th>
                                                                  )
                                                                )}
                                                              </tr>
                                                              {sm?.CostingOptions &&
                                                                sm
                                                                  ?.CostingOptions[0]
                                                                  ?.CostingOptionSchema &&
                                                                sm?.CostingOptions[0]?.CostingOptionSchema?.map(
                                                                  (b) =>
                                                                    b ? (
                                                                      <tr>
                                                                        <td>
                                                                          {
                                                                            b.label
                                                                          }
                                                                        </td>
                                                                        {sm.CostingOptions?.filter(a => sm.InvitedVendors[0]
                                                                          .VendorCosts.filter(x => x.CostOptionId == a.id && x.IsInvited).length > 0)?.map(
                                                                            //  ( header) => header.Selected==true &&(
                                                                            (
                                                                              header
                                                                            ) =>
                                                                            (
                                                                              <td>
                                                                                {Array.isArray(
                                                                                  header
                                                                                    .CostingOptionData[
                                                                                  b
                                                                                    .id
                                                                                  ]
                                                                                )
                                                                                  ? header?.CostingOptionData[
                                                                                    b
                                                                                      .id
                                                                                  ]
                                                                                    .map(
                                                                                      (
                                                                                        cd
                                                                                      ) =>
                                                                                        cd.label
                                                                                    )
                                                                                    .toString()
                                                                                  : typeof header
                                                                                    ?.CostingOptionData[
                                                                                    b
                                                                                      .id
                                                                                  ] ==
                                                                                    "object"
                                                                                    ? header
                                                                                      .CostingOptionData[
                                                                                      b
                                                                                        .id
                                                                                    ]
                                                                                      .value
                                                                                    : header
                                                                                      ?.CostingOptionData[
                                                                                    b
                                                                                      .id
                                                                                    ]}
                                                                              </td>
                                                                            )
                                                                          )}
                                                                      </tr>
                                                                    ) : (
                                                                      ""
                                                                    )
                                                                )}
                                                            </Table>
                                                          </CardBody>
                                                        </Card>
                                                      )}
                                                    {sm?.BiddingDetail?.SelectedRFQ && (
                                                      <Card>
                                                        <CardHeader
                                                          onClick={() => {
                                                            setModelState1(!modelState1);
                                                            unExpandablerfq(
                                                              sm.BiddingDetail.SelectedRFQ
                                                            );
                                                          }}
                                                        >
                                                          <Row>
                                                            <Col xs="11">
                                                              <CardTitle className="mb-0">
                                                                Additional Information
                                                                From RFQ
                                                              </CardTitle>
                                                            </Col>
                                                            <Col xs="1">
                                                              <FontAwesomeIcon
                                                                className="align-middle mr-2"
                                                                icon={
                                                                  !modelState1
                                                                    ? faChevronRight
                                                                    : faChevronDown
                                                                }
                                                                fixedWidth
                                                              />
                                                            </Col>
                                                          </Row>
                                                        </CardHeader>
                                                        <Collapse isOpen={modelState1}>
                                                          <CardBody>
                                                            {
                                                              <div
                                                                className={
                                                                  "tab tabContentCostSummary"
                                                                }
                                                              >
                                                                <Col md={11}>
                                                                  {
                                                                    <Card>
                                                                      <ToolkitProvider
                                                                        keyField="key"
                                                                        data={sm.BiddingDetail.SelectedRFQ?.filter(
                                                                          (v) =>
                                                                            !v.dependent
                                                                        )}
                                                                        columns={
                                                                          tableColumns2
                                                                        }
                                                                      >
                                                                        {(props) => (
                                                                          <CardBody className="details-flex">
                                                                            {sm.BiddingDetail.SelectedRFQ?.filter(
                                                                              (v) =>
                                                                                !v.dependent
                                                                            ).length > 0 ?
                                                                              <BootstrapTable
                                                                                {...props.baseProps}
                                                                                bootstrap4
                                                                                wrapperClasses="table-responsive"
                                                                                bordered={
                                                                                  true
                                                                                }
                                                                                //      filter={filterFactory()}
                                                                                filterPosition="top"
                                                                                expandRow={
                                                                                  expandRow
                                                                                }
                                                                                rowStyle={
                                                                                  rowStyleRFQ
                                                                                }
                                                                              />
                                                                              : <span className="nodataavailability">No RFQ details provided</span>}
                                                                          </CardBody>
                                                                        )}
                                                                      </ToolkitProvider>
                                                                    </Card>
                                                                  }
                                                                </Col>
                                                              </div>
                                                            }
                                                          </CardBody>
                                                        </Collapse>
                                                      </Card>
                                                    )}
                                                    {sm?.BiddingDetail?.SelectedSUD && (
                                                      <Card>
                                                        <CardHeader
                                                          onClick={() => {
                                                            setModelState2(!modelState2);
                                                            unExpandablesud(
                                                              sm.BiddingDetail.SelectedSUD
                                                            );
                                                          }}
                                                        >
                                                          <Row>
                                                            <Col xs="11">
                                                              <CardTitle className="mb-0">
                                                                Additional Information
                                                                From SUD
                                                              </CardTitle>
                                                            </Col>
                                                            <Col xs="1">
                                                              <FontAwesomeIcon
                                                                className="align-middle mr-2"
                                                                icon={
                                                                  !modelState2
                                                                    ? faChevronRight
                                                                    : faChevronDown
                                                                }
                                                                fixedWidth
                                                              />
                                                            </Col>
                                                          </Row>
                                                        </CardHeader>
                                                        <Collapse isOpen={modelState2}>
                                                          <CardBody>
                                                            {
                                                              <div
                                                                className={
                                                                  "tab tabContentCostSummary"
                                                                }
                                                              >
                                                                <Col md={11}>
                                                                  {
                                                                    <Card>
                                                                      <ToolkitProvider
                                                                        keyField="key"
                                                                        data={
                                                                          sm?.BiddingDetail?.SelectedSUD
                                                                            ?.filter(
                                                                              (v) =>
                                                                                !v.dependent
                                                                            ).length > 0 ?
                                                                            sm?.BiddingDetail?.SelectedSUD
                                                                              ?.filter(
                                                                                (v) =>
                                                                                  !v.dependent
                                                                              )?.filter(x => x) : []}
                                                                        columns={
                                                                          tableColumnsSUD
                                                                        }
                                                                      //   expandRow={ expandRow }
                                                                      //exportCSV={{ onlyExportFiltered: true, onlyExportSelection: false, exportAll: true }}
                                                                      >
                                                                        {(props) => (
                                                                          <CardBody className="details-flex">
                                                                            {sm?.BiddingDetail?.SelectedSUD?.filter(
                                                                              (v) =>
                                                                                !v.dependent
                                                                            ).length > 0 ?
                                                                              <BootstrapTable
                                                                                {...props.baseProps}
                                                                                bootstrap4
                                                                                wrapperClasses="table-responsive"
                                                                                bordered={
                                                                                  true
                                                                                }
                                                                                filterPosition="top"
                                                                                expandRow={
                                                                                  expandRowSUD
                                                                                }
                                                                                rowStyle={
                                                                                  rowStyleSUD
                                                                                }
                                                                              />
                                                                              : <span className="nodataavailability">No SUD details provided</span>}
                                                                          </CardBody>
                                                                        )}
                                                                      </ToolkitProvider>
                                                                    </Card>
                                                                  }
                                                                </Col>
                                                              </div>
                                                            }
                                                          </CardBody>
                                                        </Collapse>
                                                      </Card>
                                                    )}

                                                    {
                                                      <BiddingDetails
                                                        projectnew={project}
                                                        vendorid={props.vendorId}
                                                        ack={true}
                                                        CountryCode={
                                                          _.last(project.CostingProfiles).CountrySpecs[countrytab]
                                                            .CountryCode
                                                        }
                                                        Vendor={sm.InvitedVendors.find(a => a.VendorId == props.vendorId)}
                                                        indexCountry={activeTabFC}
                                                        sm={sm}
                                                        indexMethodology={activeTabSM}
                                                        VendorId={props.vendorId}
                                                      ></BiddingDetails>
                                                    }
                                                  </TabPane>
                                                </>
                                              )
                                          )}



                                      </TabContent>
                                    </Col>
                                  </Row>
                                </TabContent>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>

                      {
                        <Col className="justify-content-end">

                          {_.last(project?.CostingProfiles)?.CountrySpecs?.filter(
                            (cs) =>
                              cs.MethodologySpecs
                          )?.map(
                            (fc, indxFC) =>
                              fc.MethodologySpecs?.filter(
                                (a) =>
                                (a.CostingType == 3 &&
                                  a.InvitedVendors?.filter(
                                    (v) => (v.VendorId == props.vendorId && !(v.IsDeclined))
                                  )?.length > 0)
                              )?.length > 0)?.filter(a => a)?.length > 1 &&
                            <Button
                              className="vendor-copyAll vendor-update-btn"
                              color="primary"
                              onClick={() => setMarketCopy(true)}
                              // onClick={Savebiddingrequest}
                              // disabled={onsubmit}
                              disabled={ViewOnlyMode || submit || DisableView
                                || VendorSubmitCheck()} >
                              <FontAwesomeIcon
                                class="icon-vendorbinding"
                                icon={faCopy}
                                fixedWidth
                              />
                              <span className="copyText">
                                Copy to all Markets
                              </span>
                              {props.saving && (
                                <FontAwesomeIcon
                                  class="saving-btn"
                                  icon={faCopy}
                                  fixedWidth
                                />
                              )}
                            </Button>}
                          <Button
                            color="primary"
                            className="Submit-vendor vendor-update-btn"
                            disabled={ViewOnlyMode || submit || DisableView
                              || VendorSubmitCheck()}
                            onClick={() => { SubmitData() }
                            }
                          >
                            <FontAwesomeIcon
                              class="icon-vendorbinding"
                              icon={faCheck}
                              fixedWidth
                            />
                            Submit
                            {props.saving && (
                              <FontAwesomeIcon
                                class="saving-btn"
                                icon={faSpinner}
                                fixedWidth
                              />
                            )}
                          </Button>
                          <Button
                            className="copy-to-all-market vendor-update-btn"

                            color="primary"
                            disabled={ViewOnlyMode || submit || DisableView
                              || VendorSubmitCheck()}
                            onClick={() => saveDraft()}
                          >
                            <FontAwesomeIcon
                              class="icon-vendorbinding"
                              icon={faSave}
                              fixedWidth
                            />Save As Draft
                          </Button>

                          <div style={{ display: "none" }}>
                            <PdfGenerate
                              props={props}
                              ref={child}
                              varcheck={varcheck}
                            />
                          </div>
                          <Button
                            color="primary"
                            disabled={ViewOnlyMode}
                            className="savedraft vendor-update-btn"
                            onClick={() => {
                              newsave(project);
                            }}
                          >
                            <FontAwesomeIcon
                              class="icon-vendorbinding"
                              icon={faDownload}
                              fixedWidth
                            />
                            Download PDF
                          </Button>

                          <Button
                            color="danger"
                            className={"validateandsave vendor-update-btn"}
                            style={{ backgroundColor: "#DC3545" }}
                            disabled={ViewOnlyMode || submit || DisableView
                            }
                            onClick={() => { DeclineValues(isOpen) }}
                          >
                            <FontAwesomeIcon
                              class="icon-vendorbinding"
                              icon={faBan}
                              fixedWidth
                            />
                            Decline Proposal
                          </Button>
                          {/* {JSON.stringify(project.CostingProfiles[project.CostingProfiles.length-1].CountrySpecs[activeTabFC])} */}

                          <Modal
                            isOpen={isOpen}
                            toggle={() => { setIsOpen(!isOpen) }}
                            centered={true}
                            size="lg"
                          >
                            <ModalHeader toggle={() => DeclineValues(isOpen)} style={ModalHeaderStyle}>
                              {/* <img src={logo} className="modallogo" /> */}
                              <h1>{" "}</h1>
                              <h3 style={HeadingStyle}>Decline Proposal</h3>
                            </ModalHeader>
                            <ModalBody style={{ maxHeight: "450px", padding: "0px", overflow: "auto" }}>
                              <Container>
                                <Row>
                                  <Col md={6} >
                                    <FormGroup>
                                      <Label>Name<sup>(</sup><sup className="requiredField">*</sup><sup>)</sup></Label>
                                      <input
                                        type="text"
                                        className={"form-control w-100"}
                                        name="DeclineVendorName"
                                        onChange={(e) => DeclineInfodata(e, "Name")}
                                        value={DeclineName}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={6} >
                                    <FormGroup>
                                      <Label>Email Address<sup>(</sup><sup className="requiredField">*</sup><sup>)</sup></Label>
                                      <input
                                        type="email"
                                        className={"form-control w-100"}
                                        name="DeclineVendorEmail"
                                        onChange={(e) => DeclineInfodata(e, "Email")}
                                        value={DeclineEmail}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={12} >
                                    <FormGroup>
                                      <Label>Reason for Proposal Decline<sup>(</sup><sup className="requiredField">*</sup><sup>)</sup></Label>
                                      <textarea
                                        title="Reason for Proposal decline"
                                        className={"form-control w-100"}
                                        cols={100}
                                        rows={2}
                                        value={DeclineReason}
                                        onChange={(e) => DeclineInfodata(e, "Reason")}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={12}>

                                    <Label>
                                      Select Market & Methodology<sup>(</sup><sup className="requiredField">*</sup><sup>)</sup>
                                    </Label>

                                    {/* <ToolkitProvider
                                      keyField="key"
                                      data={declindeValues()}
                                      columns={declineTableColumns}
                                    >
                                      {(props) => (
                                        <CardBody className={"decline-table-valid"}>
                                          <BootstrapTable
                                            {...props.baseProps}
                                            bootstrap4
                                            rowStyle={rowStyle}
                                            wrapperClasses="table-responsive"
                                            bordered={true}
                                            selectRow={selectRow}
                                          />
                                        </CardBody>
                                      )}
                                    </ToolkitProvider> */}

                                    <Table
                                      className="table table-stripped"
                                      bordered
                                      style={{
                                        backgroundColor:
                                          "white",
                                        color:
                                          "black",
                                      }}
                                      id="DeclineVendorTable"
                                    >
                                      <tr style={{
                                        backgroundColor:
                                          "#dcdcdc",
                                        color:
                                          "black",
                                      }}>
                                        <td width={"6%"}></td>
                                        <td width={"47%"}><b>Market</b></td>
                                        <td width={"47%"}><b>Methodology</b></td>
                                      </tr>
                                      {DeclineTableValues?.map(x => x && <tr>
                                        <td width={"4%"}>
                                          {<Input
                                            style={{ marginLeft: "7px" }}
                                            type="checkbox"
                                            name={x.key}
                                            value={project?.DeclineVendorData ? project?.DeclineVendorData?.filter(f => f.MethodologyID == x.MethodologyID && f.CountryID == x.CountryID)[0]?.Selected : false}
                                            defaultChecked={project?.DeclineVendorData ? project?.DeclineVendorData?.filter(f => f.MethodologyID == x.MethodologyID && f.CountryID == x.CountryID)[0]?.Selected : false}
                                            onChange={(e) => { SelectDecline(e, x) }}
                                            disabled={x.Disable}
                                          />}
                                        </td>
                                        <td width={"48%"}>{x.Country}</td>
                                        <td width={"48%"}>{x.Methodology}</td>
                                      </tr>)}
                                    </Table>
                                    <div style={{ color: "black", fontWeight: "bold" }}>
                                      Note: Vendor been selected for the costing, so that methodology selection been disabled.
                                    </div>

                                  </Col>
                                </Row>
                              </Container>
                            </ModalBody>
                            <ModalFooter style={ModalFooterStyle}>
                              <Button
                                color="primary"
                                pa
                                onClick={() => validateForms()}
                              >
                                Submit
                              </Button>
                              <Button
                                color="secondary"
                                onClick={() => DeclineValues(isOpen)}
                              >
                                Cancel
                              </Button>
                            </ModalFooter>
                          </Modal>
                        </Col>
                      }
                    </>
                  )
                  }
                  {costingType && (<>
                    <h1
                      style={{
                        textAlign: "center",
                        color: "red"
                      }}>
                      <b>THIS PROJECT HAVE BEEN MOVED TO INTERNAL COSTING</b></h1>
                  </>)


                  }
                </>
              }
            </Container>
          }
          {MarketCopy && (<VbmCopyToallMarket
            MarketCopy={MarketCopy}
            setMarketCopy={setMarketCopy}
            vendorId={props.vendorId}
          />)}
        </>
      )}
      {/* {console.log(pageloaded)} */}
      {(!pageloaded) && <Loader></Loader>}
    </>
  );
}
export default VendorBiddingviewUpdated;