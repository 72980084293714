import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import logo from '../../../../../assets/img/avatars/modal-logo.png';
import { imgStyle } from '../../../../marketDefault/DynamicRFQ/ModalRFQ';

const WarningModal = ({ isOpen, message, popupHandler }) => {
    const [isModalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        setModalOpen(isOpen);
    }, [isOpen]);

    return (
        <React.Fragment>    
            {
                (isModalOpen) ?
                    <Modal isOpen={isModalOpen} toggle={() => popupHandler('close', !isModalOpen)}
                        centered className={"modelSelectVendors"}>
                        <ModalHeader toggle={() => popupHandler('close', !isModalOpen)}>
                            {/* <img src={logo} style={imgStyle} className="modallogo" /> */}
                            <h1>{" "}</h1>
                            <h3>Warning</h3>
                        </ModalHeader>
                        <ModalBody className="m-3">
                            {message}
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={() => popupHandler('ok', false)}>
                                Yes
                            </Button>
                            <Button color="secondary" onClick={() => popupHandler('close', false)}>
                                No
                            </Button>
                        </ModalFooter>
                    </Modal>
                    : ''
            }
        </React.Fragment>
    )
}
export default WarningModal;