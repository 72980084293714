import React, { useState, useEffect } from "react";
import { useHistory, Link, useParams } from "react-router-dom";
import update from "immutability-helper";
import { useDispatch, useSelector } from "react-redux";
import _, { filter } from "lodash";
import * as currentProjectActions from "../../redux/actions/currentProjectActions";
import * as costingsActions from "../../redux/actions/costingsActions";
import * as currentCostingActions from "../../redux/actions/currentCostingActions";
import * as currentWaveSpecActions from "../../redux/actions/currentWaveSpecActions";
import { msdSalesForceAction } from "../../redux/actions/msdSalesforce/msdSalesforce";
import axios from "../../axios-interceptor";
import { toastr } from "react-redux-toastr";

import Layout from "../../layouts/Project";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faQuestionCircle,
  faPen,
} from "@fortawesome/free-solid-svg-icons";

import {
  Button,
  CustomInput,
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Collapse,
  CardHeader,
  CardTitle,
  CardText,
  Tooltip,
  Modal,
  Badge,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
} from "reactstrap";
import { setCurrentWaveSpec, setWaveSpecs } from "../../redux/actions/waveSpecsActions";
import Selector from "../../components/Selector/Selector_Updated";
import { getLabel } from "../../utils/codeLabels";
import FormFeedback from "reactstrap/lib/FormFeedback";
import Spinner from "../../components/Spinner";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import ProjectDeliveryDates from "./ProjectDeliveryDates";
import * as  sheetCall from "../../utils/msSheetAPiCall";
import RecordsBadge from "../../components/RecordsBadge";
import { localPageLoadEnd, localPageLoadStart } from "../../redux/actions/appActions";

import logo from "../../assets/img/avatars/modal-logo.png";
import SelectWrapper from "../../components/SelectWrapper/SelectWrapper";
const ProjectSchedule = () => {
  const user = useSelector(({ user }) => user.userRecord);
  const project = useSelector(
    ({ currentProject }) => currentProject.newProject
  );
  const costingProfiles = useSelector(
    ({ costings }) => costings.costingProfiles
  );
  const currentCostingProfile = useSelector(
    ({ currentCosting }) => currentCosting.currentCostingProfile
  );

  const [editableCostingProfile, setEditableCosting] = useState(
    currentCostingProfile
  );

  const [acceptEditableCostingProfile, setAcceptEditableCosting] = useState(
    currentCostingProfile
  );

  const waveSpecs = useSelector(({ waveSpecs }) => waveSpecs);
  const currentWaveSpec = useSelector(({ currentWaveSpec }) => currentWaveSpec);
  const msdSalesforce = useSelector(({ msdSalesForceReducer }) => msdSalesForceReducer);

  const codeLabels = useSelector(({ codeLabels }) => codeLabels);
  const app = useSelector(({ app }) => app);

  const[acceptDates, setAcceptDates] = useState({})
  const [isSaveModal, setSaveModal] = useState(false);
  const [ewnCautionOpen, setEwnCaution] = useState(false);
  const [waveEditModal, setWaveEditModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [editableWaveName, setEditableWavename] = useState();
  const [fieldingCountries, setFieldingCountries] = useState([])
  const [updatedFieldingCountries, setUpdatedFieldingCountries] = useState([])
  const [allUsers, setAllUsers] = useState([])
  const [allowedBusinessUnits, setAllowedBusinessUnits] = useState(["CI"])
  const [externalRecipients, setExternalRecipients] = useState([])
  const [internalRecipients, setInternalRecipients] = useState([])
  const [updatedExternalRecipients, setUpdatedExternalRecipients] = useState([])
  const [updatedInternalRecipients, setUpdatedInternalRecipients] = useState([])
  const [showDetailedRecipients, setShowDetailedRecipients] = useState(false)
  const [ewnPopupSections, setEwnPopupSections] = useState({

  })
  const [currentBusinessUnit, setCurrentBusinessUnit] = useState({})
  const [updatedCurrentBusinessUnit, setUpdateCurrentBusinessUnit] = useState({})
  const [allFields] = useState(["DateWaveCommissioned", "NotesPM", "DateFinalQuestionnaire", "NotesFinalQuestionnaire", "DateFieldStart", "DateFieldEnd", "NotesFieldwork", "DateDataProcessing", "DateAnalysisPlan", "DateInterimData", "DateInterimTable", "DateFinalData", "NotesDataProcessing", "DateDashboards", "NotesDashboards", "DateCharts", "DateChartingtemplate", "DateFinalTableCharting", "NotesCharts", "DateTranslations", "NotesTranslations", "ScheduleTranslationsWordCount", "ScheduleTranslationType", "ScheduleOriginalFileFormat", "ScheduleFinalMaterialsDue", "DateVerbatimCoding", "NotesVerbatimCoding", "DateFinalReport", "DateFinalPresentation", "NotesFinalReport", "NotesDataScience", "NotesOther", "DateFinalQuestionnaireNA", "DateFieldworkNA", "DateDataProcessingNA", "DateDashboardsNA", "DateChartsNA", "DateTranslationsNA", "DateVerbatimCodingNA", "DateFinalReportNA", "DeliverableTypes"])
  const alldatefields = ["DateWaveCommissioned", "DateFinalQuestionnaire", "DateFieldStart", "DateFieldEnd",

    "DateAnalysisPlan", "DateInterimData", "DateInterimTable", "DateFinalData", "DateDataProcessing", "DateDashboards",

    "DateFinalTableCharting", "DateChartingtemplate", "DateCharts", "DateTranslations", "ScheduleFinalMaterialsDue",

    "DateVerbatimCoding", "DateFinalReport", "DateFinalPresentation"]
  const [enableAccept, setEnableAccept] = useState(false);
  const [getprojectmanagerlist, setGetPorjectManagerList] = useState([]);
  const [currproj, setCurrProj] = useState([]);
  const [enableUpdate, setEnableUpdate] = useState(false);
  const [updateDisplay, setUpdateDisplay] = useState(false)
  const [inputValue, setInputValue] = useState("");
  const [newDates, setNewDates] = useState();
  const [oldDates, setOldDates] = useState({});
  const [dateFlag, setDateFlag] = useState(false);
  const localPageload = useSelector(({ app }) => app.localPageload);
  const recordloading = useSelector(({ app }) => app.recordloading);
  const [ewnfields, setEwnfields] = useState({
    //need to cater for sections both here in BU as well as vertical - e.g. section title (Internal EWN Recepients /Ext EWN Recepients)
    InternalEwnRecipients: {
      title: "NielsenIQ Internal Teams EWN Recipients",
      properties: {
        EwnInternalOpsPM: {
          label: "Primary Internal Operations Contact",

          isMultiselect: true,
        },
        EwnInternalCharts: {
          label: "Charting",
          DisablingVal: "DateChartsNA",
          resource: "chartingResource",
          isMultiselect: true,
        },
        EwnInternalDashboards: {
          label: "Dashboarding",
          DisablingVal: "DateDashboardsNA",
          resource: "dashboardingResource",
          isMultiselect: true,
        },
        EwnInternalDataProcessing: {
          label: "Data Processing",
          DisablingVal: "DateDataProcessingNA",
          resource: "dataProcessingResource",
          isMultiselect: true,
        },
        EwnInternalDataScience: {
          label: "Data Science",
          NotesField: "NotesDataScience",
          isMultiselect: true,
        },
        EwnInternalFieldwork: {
          label: "Fieldwork",
          DisablingVal: "DateFieldworkNA",
          isMultiselect: true,
        },

        EwnInternalProgramming: {
          label: "Programming",
          DisablingVal: "DateFinalQuestionnaireNA",
          resource: "surveyProgrammingResource",
          isMultiselect: true,
        },
        EwnInternalTranslations: {
          label: "Translations",
          DisablingVal: "DateTranslationsNA",
          resource: "translationsResource",
          isMultiselect: true,
        },
        EwnInternalVerbatimCoding: {
          label: "Verbatim Coding",
          DisablingVal: "DateVerbatimCodingNA",
          resource: "verbatimCodingResource",
          isMultiselect: true,
        },
        EwnInternalFinance: {
          label: "Finance",
          isMultiselect: true,
        }
      },
    },
    ExternalEwnRecipients: {
      title: "External EWN Recipients",
      properties: {
        EwnExternalOpsPM: {
          label: "Primary External Operations Contact",
          isMultiselect: true,
        },
        EwnExternalCharts: {
          label: "Charting",
          DisablingVal: "DateChartsNA",
          resource: "chartingResource",
          isMultiselect: true,
        },
        EwnExternalDashboards: {
          label: "Dashboarding",
          DisablingVal: "DateDashboardsNA",
          resource: "dashboardingResource",
          isMultiselect: true,
        },
        EwnExternalDataProcessing: {
          label: "Data Processing",
          DisablingVal: "DateDataProcessingNA",
          resource: "dataProcessingResource",
          isMultiselect: true,
        },
        EwnExternalDataScience: {
          label: "Data Science",
          NotesField: "NotesDataScience",
          isMultiselect: true,
        },
        EwnExternalFieldwork: {
          label: "Fieldwork",
          DisablingVal: "DateFieldworkNA",
          isMultiselect: true,
        },

        EwnExternalProgramming: {
          label: "Programming",
          DisablingVal: "DateFinalQuestionnaireNA",
          resource: "surveyProgrammingResource",
          isMultiselect: true,
        },
        EwnExternalTranslations: {
          label: "Translations",
          DisablingVal: "DateTranslationsNA",
          resource: "translationsResource",
          isMultiselect: true,
        },
        EwnExternalVerbatimCoding: {
          label: "Verbatim Coding",
          DisablingVal: "DateVerbatimCodingNA",
          resource: "verbatimCodingResource",
          isMultiselect: true,
        },
      },
    },
  });
  const [customizedWaves, setCustomizedWaves] = useState({})

  const dispatch = useDispatch();

  const [toggle, setToggle] = useState({
    overall: true,
    inst: true,
    other: true,
  });
  const [Fields, updateFields] = useState({
    "Commissioning Information": {
      id: "CommissioningInformation",
      DateFields: [
        {
          id: "DateWaveCommissioned",
          label: "Date Commissioned",
          isRequired: true
        },
      ],
      NotesField: {
        id: "NotesPM",
        label: "Notes for Project Manager",
      },
    },
    "Survey Programming": {
      id: "SurveyProgramming",
      DisablingVal: "DateFinalQuestionnaireNA",
      RequiredValue: "surveyProgrammingRequired",
      DateFields: [
        {
          id: "DateFinalQuestionnaire",
          label: "Date Final Questionnaire Available",
          isRequired: true
        },
      ],
      NotesField: {
        id: "NotesFinalQuestionnaire",
        label: "Notes for Programming team",
      },
    },
    "Field Work": {
      id: "Field Work",
      DisablingVal: "DateFieldworkNA",
      DateFields: [
        {
          id: "DateFieldStart",
          label: "Planned FW Start Date",
          isRequired: false
        },
        {
          id: "DateFieldEnd",
          label: "Planned FW End Date",
          isRequired: false
        },
      ],
      NotesField: {
        id: "NotesFieldwork",
        label: "Notes for Field Work",
      },
    },
    "Data Processing": {
      id: "Data Processing",
      DisablingVal: "DateDataProcessingNA",
      RequiredValue: "dataProcessingRequired",
      PriorFields: [
        {
          id: "DeliverableTypes",
          label: "Deliverable Types",
          isRequired: true,
          // Options: alldeliverableTypes
        }
      ],
      DateFields: [
        {
          id: "DateAnalysisPlan",
          label: "Date Analysis Plan",
          isRequired: true
        },
        { id: "DateInterimData", label: "Date Interim Data" },
        { id: "DateInterimTable", label: "Date Interim Table" },
        {
          id: "DateFinalData", label: "Date Final Data",
          isRequired: true
        },
        {
          id: "DateDataProcessing",
          label: "Date Final Table to CS",
          isRequired: true
        }
      ],
      NotesField: {
        id: "NotesDataProcessing",
        label: "Notes for Data Processing Team",
      }
    },
    Dashboarding: {
      id: "Dashboarding",
      DisablingVal: "DateDashboardsNA",
      RequiredValue: "dashboarding",
      DateFields: [
        {
          id: "DateDashboards",
          label: "Date Dashboard Due",
          isRequired: true
        },
      ],
      NotesField: {
        id: "NotesDashboards",
        label: "Notes for Dashboarding Team",
      },
    },
    Charting: {
      id: "Charting",
      DisablingVal: "DateChartsNA",
      RequiredValue: "chartingRequired",
      DateFields: [
        {
          id: "DateFinalTableCharting", label: "Date Final Table to Charting Team",
          isRequired: true
        },
        {
          id: "DateChartingtemplate", label: "Date Charting template",
          isRequired: true
        },
        {
          id: "DateCharts",
          label: "Date Charts Due",
          isRequired: true
        }
      ],
      NotesField: {
        id: "NotesCharts",
        label: "Notes for Charting Team",
      },
    },
    Translations: {
      id: "Translations",
      DisablingVal: "DateTranslationsNA",
      DateFields: [
        {
          id: "DateTranslations",
          label: "Date Translations of Questionnaire Due",
        },
      ],
      NotesField: {
        id: "NotesTranslations",
        label: "Notes for Translations Team",
      },
      AdditionalFields: [
        {
          id: "ScheduleTranslationsWordCount",
          label: "Word Count",
          type: "number"
        },
        {
          id: "ScheduleTranslationType",
          label: "Translation type",
          type: "dropdown",
          options: [
            { label: "Select Translation Type", value: "-" },
            { label: "From English into local language", value: "From English into local language" },
            { label: "From local language into English", value: "From local language into English" },
            { label: "Local Language Review", value: "Local Language Review" },
            { label: "Other(please specify in Comments below)", value: "Other (please specify in Comments below)" }
          ]
        },
        {
          id: "ScheduleOriginalFileFormat",
          label: "Original File Format",
          type: "dropdown",
          options: [
            { label: "Select Original File Format", value: "-" },
            { label: "Document (or .doc, etc.)", value: "Document (or .doc, etc.)" },
            { label: "Spreadsheet (or .xls, etc.)", value: "Spreadsheet (or .xls, etc.)" },
            { label: "Presentation (or .ppt, etc.)", value: "Presentation (or .ppt, etc.)" },
            { label: "Other (please specify in Comments below)", value: "Other (please specify in Comments below)" }
          ]
        },
        {
          id: "ScheduleFinalMaterialsDue",
          label: "Final materials due for translation on",
          isRequired: true,
          type: "date"
        }
      ]
    },
    "Verbatim Coding": {
      id: "VerbatimCoding",
      DisablingVal: "DateVerbatimCodingNA",
      DateFields: [
        {
          id: "DateVerbatimCoding",
          label: "Date Verbatim Coding Due",
        },
      ],
      NotesField: {
        id: "NotesVerbatimCoding",
        label: "Notes for Verbatim Coding Team",
      },
    },
    "Final Report": {
      id: "FinalReport",
      DisablingVal: "DateFinalReportNA",
      DateFields: [
        {
          id: "DateFinalReport",
          label: "Date Final Report Due to the Client",
          isRequired: true
        },
        {
          id: "DateFinalPresentation", label: "Date Final Presentation",
          isRequired: true
        }
      ],
      NotesField: {
        id: "NotesFinalReport",
        label: "Any Notes on Final Reporting",
      },
    },
  });
  const collapseAll = () => {
    let Toggle = { ...toggle };
    Object.keys(Fields).map((field) => {
      Toggle[Fields[field].id] = !toggle.overall;
    });
    Object.keys(Toggle).map((t) => {
      Toggle[t] = !toggle.overall;
    });
    setToggle({ ...Toggle, overall: !toggle.overall });
  };
  const history = useHistory();
  const [methBreakdown, setmethBreakdown] = useState({})
  const [waveBreakDown, setWaveBreakDown] = useState({})
  const [currentWaveSet, setCurrentWaveSet] = useState([])
  const [alldeliverableTypes, setDeliverableType] = useState([])
  const [canEditSchedule, setEditSchedule] = useState(false)
  let { profileId } = useParams();
  const [calledProfile, setCalledProfile] = useState(false)
  var imgStyle = {
    width: "150px",
  };
  useEffect(() => {
    if (user.IsOpsProjectManager && !user.AllActionsAccess)
      setEditSchedule(false)
    else
      setEditSchedule(true)

  }, [project, user])
  useEffect(() => {
    setEditableCosting(currentCostingProfile);
    if (!alldeliverableTypes.length
      && currentCostingProfile.WaveSpecs
      && currentCostingProfile.WaveSpecs.length) {
      // let _firstwave = _.head(currentCostingProfile.WaveSpecs)
      // let _deliverableTypes = [];
      // if (_firstwave.OpsResourcesSchema?.properties?.deliverableTypes) {
      //   _deliverableTypes = _firstwave.OpsResourcesSchema.properties.deliverableTypes.enum?.map(e => e.replace(",", ""))
      // } else {
      let _deliverableTypes = ["CE+OE Tables"
        , "CE Tables"
        , "Interim Table"
        , "Top Line/KPI"
        , "Excel Tables"
        , "Quanvert"
        , "Q"
        , "SPSS"
        , "Resolve"
        , "JMP (for P&G studies)"
        , "Raw data (ASCII/Excel)"
        , "One table per sheet"
        , "All tables in one sheet"
        , "Branded Proprietory Products"
        , "WB (with Kruskal, Netted WB, Category WB, BEI score in tables)"
        , "eQ"
        , "CE / RSI"
        , "FACTOR"
        , "Regression (Linear, Shapley Value etc.)"
        , "Correlation"
        , "EPIC Calc"
        , "Correspondence / GAP analysis/ Brand Map"
        , "Kruskal Derived Importance Score"
        , "Penalty Analysis (with Lifter Score for Pepsi Client only)"
        , "Balance Detector"
        , "Jaccard"
        , "Normalization / Pearson Residual Index"
        , "PSM"
        , "Rank Order Analysis (Only for Africa)"
        , "Decision Tree (Summary Table - Only for Africa)"
        , "Incidence Calculation"
        , "Cross Brand Affinity"
        , "BEI / BEM"
        , "WB Foresight"
        , "Brand Builder"
        , "Segmentation"
        , "BPCM"
        , "Conjoint Analysis"
        , "Pair test"
        , "Product testing suite"
        , "Eye Tracking"
        , "Path Tracking"
        , "English"
        , "Other"
        , "Abs+%+Sig"
        , "%+Sig"
        , "% Only"
        , "Abs Only"
        , "Abs+Sig"
        , "Abs+%"
        , "Weighting"
        , "Sig testing"
        , "Brand Leverage"]
      // }
      setDeliverableType(_deliverableTypes)
    }

    let OopId = currentCostingProfile?.Project?.ContractDetails[0]?.OpportunityNumber

    if (OopId) {
      dispatch(msdSalesForceAction(OopId))
    }

  }, [currentCostingProfile]);
  useEffect(() => {
    if (profileId && !calledProfile) {
      setCalledProfile(true);
      dispatch(currentCostingActions.getCosting(profileId, null, true));
    } else if (!profileId) {
      //console.log("boots to dashboard");
      history.replace("/");
    }
  }, []);

  useEffect(() => {
    let OopIdValue = currentCostingProfile?.Project?.ContractDetails[0]?.OpportunityNumber

    if (OopIdValue) {

      let dateFieldStart =
        _.head(msdSalesforce?.msdData
          ?.StartofDelivery.split("T"))

      let dateFinalReport =
        _.head(msdSalesforce?.msdData
          ?.EndofDelivery.split("T"))

      let dateUpdatedValue =
      {
        ...currentWaveSpec,
        DateFieldStart: currentWaveSpec.DateFieldStart ?? dateFieldStart,
        DateFinalReport: currentWaveSpec.DateFinalReport ?? dateFinalReport
      };

      dispatch(
        currentWaveSpecActions.updateCurrentWaveSpec({ ...dateUpdatedValue })

      );

    }

  }, [msdSalesforce]);


  const msdDateValue = () => {
    let OopIdValue = currentCostingProfile?.Project?.ContractDetails[0]?.OpportunityNumber

    if (OopIdValue) {

      let dateFieldStart =
        _.head(msdSalesforce?.msdData
          ?.StartofDelivery.split("T"))

      let dateFinalReport =
        _.head(msdSalesforce?.msdData
          ?.EndofDelivery.split("T"))

      return {
        DateFieldStart: dateFieldStart,
        DateFinalReport: dateFinalReport
      }
    } else {
      return {
        DateFieldStart: null,
        DateFinalReport: null
      }
    }
  }



  useEffect(() => {
    if (currentCostingProfile &&
      currentCostingProfile.WaveSpecs
      && currentCostingProfile.WaveSpecs.length
      && !Object.keys(waveBreakDown).length) {
      // let methBreakdown = _.groupBy(currentCostingProfile.WaveSpecs, m => m.MethodologySpecId)
      let availableMeths = _.uniq(currentCostingProfile.WaveSpecs.map(ws => ws.MethodologySpecId))
      let methBreakdown = {}
      availableMeths.map(am => {
        currentCostingProfile.CountrySpecs
          ?.map(cs => {
            cs.MethodologySpecs?.filter(mt => !mt.NotApplicable).map(ms => {
              if (ms.id == am)
                methBreakdown[am] = ms.Label
            })
          })
      })
      setmethBreakdown(methBreakdown)
      currentCostingProfile.WaveSpecs.map(ws => {
        ws.MethodologyLabel = methBreakdown[ws.MethodologySpecId]
      })
      let _waveBreakdown = _.groupBy(currentCostingProfile.WaveSpecs, m => m.WaveNumber)
      Object.keys(_waveBreakdown).map(w => {
        _waveBreakdown[w] = _.uniqBy(_waveBreakdown[w], t => t.MethodologyLabel)
      })

      //console.log(_waveBreakdown, "_waveBreakdown")
      setWaveBreakDown(_waveBreakdown)
      let _currentwaveset = _.head(Object.values(_waveBreakdown))
      setCurrentWaveSet(_currentwaveset)

      dispatch(currentWaveSpecActions.selectWaveSpec({ ..._.head(_currentwaveset) }));

    }
  }, [currentCostingProfile])

  useEffect(() => {

    axios

      .get("/users/opspms/all")

      .then((res) => {

        setGetPorjectManagerList(res.data.users);

      })

      .catch((err) => {

        // dispatch(appActions.pageLoadEnd());

      });

  }, []);

  const selectorHandler = (item) => {
    setCurrProj([])
    setEnableUpdate(false)
    setDateFlag(false)
    editableCostingProfile.WaveSpecs.map(ws => {
      ws.MethodologyLabel = methBreakdown[ws.MethodologySpecId]
    })
    let _waveBreakdown = _.groupBy(editableCostingProfile.WaveSpecs, m => m.WaveNumber)
    Object.keys(_waveBreakdown).map(w => {
      _waveBreakdown[w] = _.uniqBy(_waveBreakdown[w], t => t.MethodologyLabel)
    })
    // do nothing if clicked item is current item
    if (item === _.head(currentWaveSet)?.WaveNumber) return;
    else {
      setCurrentWaveSet(_.cloneDeep(_waveBreakdown[item]))
    }
    if (_waveBreakdown[item] && _waveBreakdown[item].length) {
      let wavesBreakDown = _.head(_waveBreakdown[item])
      const msdPullDates = msdDateValue()

      if ((msdPullDates.DateFieldStart) || ((msdPullDates.DateFinalReport))) {
        wavesBreakDown = {
          ...wavesBreakDown,
          DateFinalReport: wavesBreakDown.DateFinalReport ?? msdPullDates.DateFinalReport,
          DateFieldStart: wavesBreakDown.DateFieldStart ?? msdPullDates.DateFieldStart
        }
      }

      dispatch(currentWaveSpecActions.selectWaveSpec(_.cloneDeep({ ...wavesBreakDown })));
    }
  };
  const handleMethodlogyChange = (currentwaveId) => {
    setCurrProj([])
    setEnableUpdate(false)
    setDateFlag(false)
    let wavesBreakDown = _.head(currentWaveSet.filter(cws => cws.id == currentwaveId))
    const msdPullDates = msdDateValue()

    if ((msdPullDates.DateFieldStart) || ((msdPullDates.DateFinalReport))) {
      wavesBreakDown = {
        ...wavesBreakDown,
        DateFinalReport: wavesBreakDown.DateFinalReport ?? msdPullDates.DateFinalReport,
        DateFieldStart: wavesBreakDown.DateFieldStart ?? msdPullDates.DateFieldStart
      }
    }
    dispatch(currentWaveSpecActions.selectWaveSpec(wavesBreakDown));
  }
  const renderSelector = () => {
    if (!waveSpecs || (waveSpecs && waveSpecs.length === 1)) return null;
    return (
      <Col lg="2" md="2" sm="12" xs="12">
        <Selector
          heading={"Waves"}
          records={waveBreakDown}
          // applyAll={applyToAllWaves}
          // applyAllText={"Apply to All Waves"}
          clicked={selectorHandler}
          selected={_.head(currentWaveSet)?.WaveNumber}
        />
      </Col>
    );
  };

  const onChangeHandler = (eve, useCurrentSpec, isApi) => {
    if (alldatefields.includes(eve.target.id)) {
      setEnableUpdate(true)
    }
    let currentwavespec = {};
    if (useCurrentSpec) {
      currentwavespec = {
        ...useCurrentSpec,
        [eve.target.id]: eve.target.value,
      };
      setNewDates(currentwavespec)
    } else {
      currentwavespec = {
        ...currentWaveSpec,
        [eve.target.id]: eve.target.value,
      };
      setNewDates(currentwavespec)
    }

    //clearing finance notes when unchecked IncludeFinanceInComms
    if (eve.target.id == "IncludeFinanceInComms" && !eve.target.value)
      currentwavespec = { ...currentwavespec, NotesFinance: null };

    let wavespecs = [...waveSpecs];
    wavespecs = wavespecs.map((ws) => {
      if (ws.id === currentwavespec.id) {

        ws = currentwavespec;

      }
      ws.MethodologyLabel = methBreakdown[ws.MethodologySpecId];

      return { ...ws };
    });
    dispatch(setWaveSpecs([...wavespecs]));
    if (dateFlag == false) {
      let prevDate;
      prevDate = currentWaveSpec;
      setOldDates(prevDate)
      setDateFlag(true)
    }
    dispatch(
      currentWaveSpecActions.updateCurrentWaveSpec({ ...currentwavespec })
      //   update(currentWaveSpec, {
      //     $set: { ...currentwavespec },
      //   })
      // )
    );

    let _waveBreakdown = _.groupBy(wavespecs, m => m.WaveNumber)
    Object.keys(_waveBreakdown).map(w => {
      _waveBreakdown[w] = _.uniqBy(_waveBreakdown[w], t => t.MethodologyLabel)
    })
    setCurrentWaveSet(_waveBreakdown[currentWaveSpec.WaveNumber])


    let editablecostingprofile = { ...editableCostingProfile };
    editablecostingprofile.WaveSpecs = editablecostingprofile.WaveSpecs.map(
      (ws) => {
        if (ws.id == currentwavespec.id) {
          return { ...currentwavespec };
        } else {
          return { ...ws };
        }
      }
    );
    setEditableCosting(editablecostingprofile);
    if (isApi) {
      dispatch(
        currentCostingActions.saveCostingProfile(
          { ...editablecostingprofile },
          () => setWaveEditModal(false)
        )
      );
      dispatch(
        currentCostingActions.updateCostingProfiles(costingProfiles, {
          ...editablecostingprofile,
        })
      );
    }

    // dispatch(updateCurrentWaveSpec({...currentwavespec}))
  };

  const onTogglingChecks = (eve) => {
    if (eve.target.value) {
      let currentwavespec = { ...currentWaveSpec };
      let targetId = eve.target.id;
      let requiredField = _.head(
        Object.keys(Fields).filter(
          (field) => Fields[field].DisablingVal == targetId
        )
      );

      let fields = { ...Fields };
      fields[requiredField].isInvalid = false;
      updateFields({ ...fields });

      Fields[requiredField].DateFields.map((df) => {
        df.isInvalid = false
        currentwavespec[df.id] = null;
      });
      currentwavespec[Fields[requiredField].NotesField.id] = null;
      onChangeHandler(
        { target: { id: eve.target.id, value: eve.target.value } },
        currentwavespec
      );
    } else {
      onChangeHandler({
        target: { id: eve.target.id, value: eve.target.value },
      });
    }
  };
  const validateDates = () => {
    let isInvalid = false;
    let fields = { ...Fields };
    Object.keys(fields).map((field) => {
      if (
        (fields[field].DisablingVal &&
          !currentWaveSpec[fields[field].DisablingVal]) ||
        !fields[field].DisablingVal
      ) {

        if (currentWaveSpec.OpsResourcesData) {
          if (fields[field].RequiredValue) {
            let currentOpsvalue = currentWaveSpec.OpsResourcesData[fields[field].RequiredValue]
            if (currentOpsvalue && (typeof (currentOpsvalue) == "string" && currentOpsvalue.toLowerCase() == "yes")
              || (typeof (currentOpsvalue) == "boolean")) {
              fields[field].DateFields = fields[field].DateFields.map((df) => {
                if (df.isRequired && !currentWaveSpec[df.id]) {
                  df.isInvalid = true;
                  isInvalid = true;
                  fields[field].isInvalid = true
                } else df.isInvalid = false;
                return { ...df }
              });
              if (fields[field].PriorFields)
                fields[field].PriorFields = fields[field].PriorFields.map((df) => {
                  if (df.isRequired && !currentWaveSpec[df.id]) {
                    df.isInvalid = true;
                    isInvalid = true;
                    fields[field].isInvalid = true
                  } else df.isInvalid = false;
                  return { ...df }
                });
            }
          } else {
            fields[field].DateFields = fields[field].DateFields.map((df) => {
              if (df.isRequired && !currentWaveSpec[df.id]) {
                df.isInvalid = true;
                isInvalid = true;
                fields[field].isInvalid = true
              } else df.isInvalid = false;

              return { ...df }
            });
          }
        }
        // if (fields[field].AdditionalFields)
        //   fields[field].AdditionalFields.map((df) => {
        //     if ((currentWaveSpec[df.id] == null || currentWaveSpec[df.id] == "") && df.isRequired) {
        //       df.isInvalid = true;
        //       isInvalid = true;
        //     } else df.isInvalid = false;
        //   });
      }
      fields[field] = { ...fields[field] }
    });
    updateFields({ ...fields });
    if (!isInvalid) {
      Object.keys(fields).map((field) => {
        fields[field].isInvalid = false
      })
      updateFields({ ...fields });

      // if (!fieldingCountries.length) {
      let allFieldingCountries = currentCostingProfile.FieldingCountries?.split(",")
      let requiredCountryData = currentCostingProfile.CountrySpecs?.filter(cs => _.includes(allFieldingCountries, cs.CountryCode))
      // setFieldingCountries(requiredCountryData)

      axios.post(`/utils/getbusinessdetails/${currentCostingProfile.Project.BusinessUnitId}`)
        .then(res => {
          const currentBU = res.data.data
          setCurrentBusinessUnit(currentBU)
          setUpdateCurrentBusinessUnit({ ...currentBU })

        }).catch(err => {
          toastr.error("Something went wrong", "Gettings Email recipients failed")

        })
      axios.post(`/utils/getprimarymcp`, {

        CountryCodes: requiredCountryData.map(fc => fc.CountryCode).join(),
        BusinessUnit: currentCostingProfile.Project.BusinessUnit

      }).then(res => {
        let fieldingcountries = [...requiredCountryData]
        fieldingcountries = requiredCountryData.map(fc => {
          let reqCount = _.head(res.data.data?.filter(d => d.Code == fc.CountryCode))
          if (reqCount) {
            fc.PrimaryContactMCP = _.head(reqCount.BusinessUnits)?.PrimaryContactMCP
          }
          return { ...fc }
        })
        setFieldingCountries(fieldingcountries)
        let updateFieldingCOuntries = [...fieldingcountries.map(fc => { return { ...fc } })]
        setUpdatedFieldingCountries(updateFieldingCOuntries)
        dispatch(
          currentWaveSpecActions.updateCurrentWaveSpec({ ...currentWaveSpec, EWNFieldingCountries: updateFieldingCOuntries.map(fc => fc.CountryCode).join() })
        );
      }).catch(err => {
        toastr.error("Something went wrong", "Getting Primary Contact MCP failed")
      })
      // }
      // if (!allUsers.length) {
      axios
        .get("/users/internal/all")
        .then((response) => {
          setAllUsers(response.data?.users)
        })
        .catch((error) => {
          toastr.error("Users retrieving Failed", error.data.message);
        });
      // }
      dispatch(
        currentCostingActions.saveSchedule(editableCostingProfile, () => {
          if (!currentWaveSpec.WaveFolderId) {
            toastr.info(
              "Please wait while the wave folder and project box are being created..."
            );

            axios
              .post(`/utils/folders/waves/${currentWaveSpec.id}/CurrentWave`,
                { currentWaveSpec })
              .then((res) => {
                dispatch(
                  currentWaveSpecActions.updateCurrentWaveSpec({
                    ...currentWaveSpec,
                    WaveFolderId: res.data.WaveFolderId,
                    ProjectBoxId: res.data.ProjectBoxId,
                  })
                );
                setSaveModal(true)
                toastr.success(res.data.message);
                let _wavespecs = waveSpecs.map(ws => {
                  if (ws.id == currentWaveSpec.id) {
                    ws = {
                      ...currentWaveSpec, WaveFolderId: res.data.WaveFolderId,
                      ProjectBoxId: res.data.ProjectBoxId
                    }
                  }
                  return { ...ws }
                })
                updatespecs(_wavespecs)

              })
              .catch((err) => {
                toastr.error("Wave Folder Creation Failed");
              });
          }
          else {
            setSaveModal(true)
          }
        }));


      //copy to other waves
      let _wavebreak = _.groupBy(waveSpecs, w => w.WaveNumber)
      editableCostingProfile.WaveSpecs = editableCostingProfile.WaveSpecs.map(ws => {
        let requiredSet = _wavebreak[ws.WaveNumber]
        let requiredWave = _.head(requiredSet.filter(rws => rws.MethodologyLabel == ws.MethodologyLabel && ws.id != rws.id))
        if (requiredWave?.DateWaveCommissioned) {
          allFields.map(af => {
            ws[af] = requiredWave[af]
          })
        }
        return { ...ws }
      })

      // editableCostingProfile.WaveSpecs = editableCostingProfile.WaveSpecs.map(wspec => {
      //   let requiredWaveSet = waveBreakDown[wspec.WaveNumber]
      //   let requiredWaveToCopy = _.head(requiredWaveSet.filter(rws =>
      //     rws.MethodologyLabel == methBreakdown[wspec.MethodologySpecId]
      //     && rws.id != wspec.id))
      //     // editableCostingProfile.WaveSpecs.filter(w=>w.WaveNumber==3&&w.MethodologyLabel=="B2B-FTF")

      //   if (requiredWaveToCopy) {
      //     allFields.map(af => {
      //       wspec[af] = requiredWaveToCopy[af]
      //     })
      //   }
      //   return { ...wspec }
      // })


      setErrorMessage("");
    } else {
      setErrorMessage("Please review your form inputs and try again.");
    }
  };
  const updateWaveSpecs = () => {
    dispatch(localPageLoadStart());
    axios.put("/waves/updatecurrentwave", currentWaveSpec)
      .then(res => {
        if (currentWaveSpec.IncludeFinanceInComms) {
          axios
            .post("/utils/mail/" + currentWaveSpec.id + "/finance/schedule")
            .then((res) => {
              //console.log(res);
            })
            .catch((err) => {
              //console.log(err);
            });
        }
        axios
          .post("/utils/mail/" + currentWaveSpec.id + "/ewn/all", {
            params: {
              currentBusinessUnit,
              showDetailedRecipients,
              fieldingCountries
            }
          })
          .then((res) => {
            setShowDetailedRecipients(false)
            setEwnPopupSections({})
            //console.log(res);
            toastr.success("Mail Sending Success", res.data.message);
          })
          .catch((err) => {
            //console.log(err);
            toastr.error("Mail Sending Failed", err?.data?.message);
          });
        setSaveModal(false);
        dispatch(localPageLoadEnd());
      })
      .catch(err => {
        dispatch(localPageLoadEnd())
      })

  };
  const updatespecs = (_wavespecs) => {

    dispatch(setWaveSpecs(_wavespecs))
    let editablecostingprofile = { ...editableCostingProfile };
    editablecostingprofile.WaveSpecs = _wavespecs
    setEditableCosting(editablecostingprofile);
  }
  const canRenderValue = (ewnfields, ewnrecp, prop) => {
    let canRender = true
    if ((ewnfields[ewnrecp].properties[prop].DisablingVal
      && currentWaveSpec[ewnfields[ewnrecp].properties[prop].DisablingVal]))
      canRender = false
    if ((ewnfields[ewnrecp].properties[prop].NotesField
      && !currentWaveSpec[ewnfields[ewnrecp].properties[prop].NotesField]))
      canRender = false

    if (ewnrecp == "ExternalEwnRecipients") {
      if (!currentWaveSpec.OpsResourcesData || (currentWaveSpec.OpsResourcesData && currentWaveSpec.OpsResourcesData[ewnfields[ewnrecp].properties[prop].resource] != "External")) {
        canRender = false
      }
    }
    if (!updatedCurrentBusinessUnit[prop])
      canRender = false

    return canRender
  }
  const fieldingCheckUpdate = (e, fc) => {
    let EWNFieldingCountries = currentWaveSpec.EWNFieldingCountries
      ? currentWaveSpec.EWNFieldingCountries.split(",") : []
    if (e.target.checked) {
      if (!_.includes(EWNFieldingCountries, fc)) {
        EWNFieldingCountries.push(fc)
      }
    } else {
      EWNFieldingCountries = EWNFieldingCountries.filter(f => f != fc)
    }
    dispatch(currentWaveSpecActions.updateCurrentWaveSpec({ ...currentWaveSpec, EWNFieldingCountries: EWNFieldingCountries.join() }))
    let _wavespecs = waveSpecs.map(ws => {
      if (ws.id == currentWaveSpec.id) {
        ws = { ...currentWaveSpec, EWNFieldingCountries: EWNFieldingCountries.join() }
      }
      return { ...ws }
    })
    updatespecs(_wavespecs)
  }
  const updateAdditionalEwnRecp = (additionalRecipients) => {

    dispatch(currentWaveSpecActions.updateCurrentWaveSpec({ ...currentWaveSpec, EWNAdditionalRecipients: additionalRecipients }))
    let _wavespecs = waveSpecs.map(ws => {
      if (ws.id == currentWaveSpec.id) {
        ws = { ...currentWaveSpec, EWNAdditionalRecipients: additionalRecipients }
      }
      return { ...ws }
    })
    updatespecs(_wavespecs)
  }
  const copyToAllMethodologyWaves = () => {
    let _wavespecs = waveSpecs.map(ws => {
      if (ws.id != currentWaveSpec.id && ws.WaveNumber == currentWaveSpec.WaveNumber) {
        allFields.map(af => {
          ws[af] = currentWaveSpec[af]
        })
      }
      return { ...ws }
    })
    updatespecs(_wavespecs)
    toastr.success("Successfully copied to all methodologies")
  }
  const saveProjectManager = (acceptStatus, updateStatus, scheduleFlag) => {
    let filteredOldDates;
    let filteredNewDates;
    let projectManager;
    setEnableUpdate(false)
    projectManager = currproj.map((i) => {
      return i.value
    })
    if (oldDates) {
      filteredOldDates = Object.keys(oldDates)
        .filter(key => (alldatefields.includes(key)))
        .reduce((obj, key) => {
          obj[key] = oldDates[key];
          return obj;
        }, {});
    }
    if (newDates) {
      filteredNewDates = Object.keys(newDates)
        .filter(key => (alldatefields.includes(key)))
        .reduce((obj, key) => {
          obj[key] = newDates[key];
          return obj;
        }, {});
    }

    //Accept Email
   let acceptFilterDates;
   acceptFilterDates=  Object.keys(acceptDates)
   .filter(key => (alldatefields.includes(key)))
       .reduce((obj, key) => {
        obj[key] = acceptDates[key];
         return obj;
      }, {});
     console.log("acceptedFilter", acceptFilterDates)
  
    axios.put(`/projects/${currentCostingProfile.ProjectId}/UpdateProjectParams`, {
      ProjectManagerEmail: project.ProjectManagerEmail
    })
      .then((res) => {
        //dispatch(recordLoadEnd());
        //todo: sendemail
        //use SendProjectAssignEmail
        axios.post(
          `/utils/mail/${currentCostingProfile.ProjectId}/SendProjectAssignEmail`,
          {
            ProjectManagerEmail: currproj,
            acceptStatus: acceptStatus,
            updateStatus: updateStatus,
            historyData: '',
            currentWaveSpec: currentWaveSpec ? currentWaveSpec : '',
            filteredNewDates: filteredNewDates ? filteredNewDates : '',
            filteredOldDates: filteredOldDates ? filteredOldDates : '',
            scheduleFlag: scheduleFlag ? scheduleFlag : '',
            acceptFilterDates : acceptFilterDates ? acceptFilterDates : ''
          },
        )
          .then((res) => {
            toastr.success(
              "Mail Sent Successfully",
              "Mail Sent to Selected Project Managers"
            );
          })
          .catch((error) => {
            toastr.error(
              "Mail Sending failed",
              "Mail Sending to Selected Project Managers failed"
            );
          });
        setEnableAccept(false);
        toastr.success(
          "Successfully Updated",
          "Assigning Project Manager Successful"
        );
      })
      .catch((error) => {
        //setAssignProjectManager(false);
        //dispatch(recordLoadEnd());
        toastr.error(
          "Something went wrong",
          "Assigning Project Manager Failed"
        );
      });

    let setOPSProjectManager = [...waveSpecs];
    setOPSProjectManager = setOPSProjectManager.map((ws) => {
      if (ws.id === currentWaveSpec.id) {
        ws = { ...ws, OPSProjectManager: projectManager && projectManager.length ? projectManager.toString() : '' }
      }
      return { ...ws };
    });
    dispatch(setWaveSpecs([...setOPSProjectManager]));

    let editableCostingProfileWavesUpdated = { ...editableCostingProfile };
    editableCostingProfileWavesUpdated = { ...editableCostingProfile, WaveSpecs: setOPSProjectManager }

    //if (acceptStatus && updateStatus && scheduleFlag) {
    dispatch(
      currentCostingActions.saveSchedule(editableCostingProfileWavesUpdated))
    // }
  };

 // Accept Email
  useEffect(() => {
     setAcceptDates(currentWaveSpec)   
   }, [currentWaveSpec?.MethodologyLabel, currentWaveSpec?.WaveNumber])
   console.log("acceptDates", acceptDates)

  return (
    <Layout
      profileStatusToDisplay={getLabel(
        "CostingStatusOptions",
        currentCostingProfile.ProfileStatus
      )}
      projectStatusToDisplay={getLabel(
        "ProjectStatusOptions",
        currentCostingProfile.Project?.ProjectStatus
      )}
    >
      {localPageload || recordloading ? (
        <div id="pageCoverSpin"></div>
      ) : null}
      <>
        <Container>
          <Card>
            <CardHeader>
              <Row>
                <Col xs="9">
                  <CardTitle className="text-uppercase">
                    Operations Schedule
                  </CardTitle>
                </Col>
                <Col xs="3">
                  <Link
                    className=" p-1 medium float-right mr-2"
                    onClick={(e) => {
                      collapseAll();
                      // setToggle({
                      //   ...toggle,
                      //   overall: !toggle.overall,
                      //   other: !toggle.overall ? true : false,
                      //   inst: !toggle.overall ? true : false,
                      // })
                    }}
                  >
                    <Label className="mb-0 mr-1 small">
                      {!toggle.overall ? "Expand All" : "Collapse All"}
                    </Label>
                    <FontAwesomeIcon
                      icon={!toggle.overall ? faChevronDown : faChevronUp}
                      fixedWidth
                      className="mb-0 mr-3 medium"
                    />
                  </Link>
                </Col>
              </Row>
            </CardHeader>
          </Card>
        </Container>

        <Container>
          <Card
            className="ml-2 mr-2 mb-0 p-0 projectschedule"
          >

            {/* <ProjectDeliveryDates/> */}
          </Card>
        </Container>

        <Container>
          <Card className="ml-2 mr-2 mb-2">
            <CardHeader
              onClick={(e) => setToggle({ ...toggle, inst: !toggle.inst })}
            >
              <Row className="mb-0 d-flex justify-content-between">
                <Col xs="11" className="align-self-center">
                  <Row className="mb-0 d-flex justify-content-start">
                    <Col className="align-self-center">
                      <CardTitle className="mb-0">
                        Wave #{currentWaveSpec.WaveNumber}{" "}
                        {currentWaveSpec.WaveName}
                      </CardTitle>
                    </Col>
                    <Col className="align-self-center">
                      {/* <Badge title="Wave Status"> */}
                      {/* Awaiting Schedule */}
                      {/* {
                          getLabel(
                            "WaveStatusOptions",
                            currentWaveSpec.WaveStatus
                          )
                          //Todo: setup WaveStatusOptions on Backend, temporarily hardcoding status to "Awaiting Schedule" which will be default.
                        } */}
                      {/* </Badge> */}
                    </Col>
                    <Col className="align-self-center">
                      <Button
                        color="secondary"
                        className="mr-2"
                        onClick={(e) => {
                          e.stopPropagation();

                          if (currentWaveSpec.WaveFolderId) {
                            // window.open(
                            //   "https://drive.google.com/drive/folders/" +
                            //   currentWaveSpec.WaveFolderId
                            // );
                            dispatch(sheetCall.msSheetAPiCall(currentWaveSpec.WaveFolderId, false));

                          } else {
                            e.target.disabled = true;
                            e.persist();
                            toastr.info(
                              "Please wait while the wave folder is being created..."
                            );
                            axios
                              .post(`/utils/folders/waves/${currentWaveSpec.id}/CurrentWave`,
                                { currentWaveSpec })
                              .then((res) => {
                                e.target.disabled = false;
                                dispatch(
                                  currentWaveSpecActions.updateCurrentWaveSpec({
                                    ...currentWaveSpec,
                                    WaveFolderId: res.data.WaveFolderId,
                                    ProjectBoxId: res.data.ProjectBoxId,
                                  })
                                );
                                toastr.success(res.data.message);
                                // window.open(
                                //   "https://drive.google.com/drive/folders/" +
                                //   res.data.WaveFolderId
                                // );
                                dispatch(sheetCall.msSheetAPiCall(res.data.WaveFolderId, false));

                              })
                              .catch((err) => {
                                e.target.disabled = false;

                                toastr.error("Wave Folder Creation Failed");
                              });
                          }
                        }}
                      >
                        Upload Files
                      </Button>
                      {currentWaveSpec.ProjectBoxId ? (
                        <Button
                          color="secondary"
                          onClick={(e) => {
                            e.stopPropagation();
                            // window.open(
                            //   "https://docs.google.com/spreadsheets/d/" +
                            //   currentWaveSpec.ProjectBoxId
                            // );
                            dispatch(sheetCall.msSheetAPiCall(currentWaveSpec.ProjectBoxId, false));

                          }}
                        >
                          Open ProjectBox
                        </Button>
                      ) : null}
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs="1"
                  className="d-flex align-self-center justify-content-center"
                >
                  <FontAwesomeIcon
                    className="align-middle mr-2"
                    icon={!toggle.inst ? faChevronRight : faChevronDown}
                    fixedWidth
                  />
                </Col>
              </Row>
            </CardHeader>
            <Collapse isOpen={toggle.inst}>
              <CardBody>
                <Row>
                  <Col>
                    <CardText>
                      These input boxes are
                      <mark>not for the PPM or briefing notes</mark>, please
                      enter here just the info the Ops team will need prior to
                      the PPM, especially to schedule and book vendors or to
                      recover a questionnaire from archive, any watchouts, any
                      requests for assigning or booking vendors, etc.
                    </CardText>
                    <CardText>
                      The PPM notes must be entered in the Project Box Project
                      notes tab, which will be available as soon as you click on
                      "Save" below.
                    </CardText>
                    <CardText>
                      Please ensure you click on the Save button at the bottom
                      of the page to save this information
                    </CardText>
                  </Col>
                </Row>
              </CardBody>
            </Collapse>
          </Card>
        </Container>
        <Container>
          <Card
            className="ml-2 mr-2 mb-0 p-0 projectschedule"
          // style={{ background: "none", boxShadow: "none" }}
          >
            <Row>
              {renderSelector()}
              <Col>
                <Row className="m-0 mb-1 justify-content-end">
                  <Col lg="3" md="3" className="p-3">
                    #{currentWaveSpec.WaveNumber} {currentWaveSpec.WaveName} <FontAwesomeIcon
                      title="Edit Wave Name"
                      size="xs"
                      icon={faPen}
                      className="pointer"
                      onClick={() => setWaveEditModal(!waveEditModal)}
                    />
                  </Col>

                  <Col lg="3" md="3" className="p-3">

                    <Button color='primary'

                      style={{

                        margin: "3px"

                      }}

                      onClick={() => setEnableAccept(true)}

                    >

                      Accept

                    </Button>

                    <Button color='primary'

                      disabled={!enableUpdate}

                      onClick={() => {

                        //fetch();

                        setUpdateDisplay(true);

                      }}

                    >

                      Update

                    </Button>

                  </Col>

                  <Col lg="6" md="6">
                    <strong>Select Methodology:</strong>
                    <SelectWrapper
                      disabled={!canEditSchedule}
                      value={currentWaveSpec.id}
                      onChange={(e) => handleMethodlogyChange(e.target.value)}>
                      {currentWaveSet?.map((cws, i) => <option value={cws.id}>{cws.MethodologyLabel}</option>)}
                    </SelectWrapper>
                  </Col>
                </Row>
                <Row className="m-0 mb-3 justify-content-end">
                  {currentWaveSet.length > 1 &&
                    <Button onClick={copyToAllMethodologyWaves}>
                      Copy to all methodologies
                    </Button>}
                </Row>
                <Row>
                  <Col>

                    <strong>Assigned Project Manager:</strong>

                    <Input

                      style={{

                        marginBottom: '20px'

                      }}

                      type="text"
                      value={waveSpecs ? (
                        waveSpecs.map((ws) =>
                          ws.id === currentWaveSpec.id ?
                            ws.OPSProjectManager ? ws.OPSProjectManager : '' : ''
                        ).filter((value) => {
                          return value !== ""
                        })
                      ) : ''} />

                  </Col>
                </Row>
                {Object.keys(Fields).map((field) => {
                  return (
                    <Card className="mb-2">
                      <CardHeader
                        className="mb-0"
                        onClick={(e) =>
                          setToggle({
                            ...toggle,
                            [Fields[field].id]: !toggle[Fields[field].id],
                          })
                        }
                      >
                        <Row className="mb-0">
                          <Col xs="11">
                            <CardTitle className="mb-0">
                              {field}
                              {(Fields[field].isInvalid) ? (
                                <CardText className="error small">
                                  Please review your form inputs.
                                </CardText>
                              ) : null}
                            </CardTitle>
                          </Col>
                          <Col xs="1">
                            <FontAwesomeIcon
                              className="align-middle mr-2"
                              icon={
                                !toggle[Fields[field].id]
                                  ? faChevronRight
                                  : faChevronDown
                              }
                              fixedWidth
                            />
                          </Col>
                        </Row>
                      </CardHeader>
                      <Collapse isOpen={toggle[Fields[field].id]}>
                        <CardBody>
                          <Row className="mb-2">
                            {Fields[field].PriorFields?.map((df) => {
                              return (
                                <>
                                  <Col lg="4" md="4" sm="12" xs="12">
                                    <Label>{df.label}</Label>
                                    <CreatableSelect
                                      className={`mb-2${df.isInvalid &&
                                        !currentWaveSpec[df.id] ? " is-invalid" : ""}`}
                                      isMulti
                                      options={alldeliverableTypes?.map(dt => { //todo:make it generic when gets time
                                        return { value: dt, label: dt }
                                      })}
                                      value={(currentWaveSpec[df.id] && currentWaveSpec[df.id].length) ? currentWaveSpec[df.id].split(",").map(dt => {
                                        return { value: dt, label: dt }
                                      }) : []}
                                      onChange={(eve) => {
                                        onChangeHandler({ target: { id: df.id, value: eve?.map(e => e.value)?.join() } })
                                      }}
                                      isDisabled={
                                        currentWaveSpec[
                                        Fields[field].DisablingVal
                                        ] || !canEditSchedule
                                      }
                                    />

                                    {df.isInvalid &&
                                      !currentWaveSpec[df.id] ? (
                                      <p className="error small">
                                        Please provide a date or select not
                                        applicable.
                                      </p>
                                    ) : null}
                                  </Col>
                                </>
                              );
                            })}
                            {Fields[field].DateFields.map((df) => {

                              return (
                                <>
                                  <Col lg="4" md="4" sm="12" xs="12">
                                    <Label>{df.label}</Label>
                                    <Input
                                      invalid={
                                        df.isInvalid &&
                                        !currentWaveSpec[df.id]
                                      }
                                      className="mb-2"
                                      id={df.id}
                                      onChange={(eve) => onChangeHandler(eve)}
                                      disabled={
                                        currentWaveSpec[
                                        Fields[field].DisablingVal
                                        ] || !canEditSchedule
                                      }
                                      value={
                                        currentWaveSpec[df.id]
                                          ? _.head(
                                            currentWaveSpec[df.id].split("T")
                                          )
                                          : ""
                                      }
                                      type="date"
                                    />
                                    {df.isInvalid &&
                                      !currentWaveSpec[df.id] ? (
                                      <p className="error small">
                                        Please provide a date or select not
                                        applicable.
                                      </p>
                                    ) : null}
                                  </Col>
                                </>
                              );
                            })}

                            {Fields[field].AdditionalFields?.map(afField => {
                              return afField.type == "dropdown" ? <Col lg="4" md="4" sm="12" xs="12">
                                <Label>{afField.label}</Label>
                                <SelectWrapper
                                  defaultValue={
                                    currentWaveSpec[afField.id]
                                  }
                                  disabled={
                                    currentWaveSpec[
                                    Fields[field].DisablingVal
                                    ] || !canEditSchedule
                                  }
                                  invalid={
                                    afField.isInvalid &&
                                    !currentWaveSpec[afField.id]
                                  } onChange={(eve) => onChangeHandler(eve)} id={afField.id}>
                                  {afField.options.map(opt => <option value={opt.value}>{opt.label}</option>)}
                                </SelectWrapper>
                                {afField.isInvalid &&
                                  !currentWaveSpec[afField.id] ? (
                                  <FormFeedback>
                                    Please provide a date or select not
                                    applicable.
                                  </FormFeedback>
                                ) : null}
                              </Col> : <Col lg="4" md="4" sm="12" xs="12">
                                <Label>{afField.label}</Label>
                                <Input className="form-control"
                                  disabled={
                                    currentWaveSpec[
                                    Fields[field].DisablingVal
                                    ] || !canEditSchedule
                                  }
                                  value={
                                    currentWaveSpec[afField.id]
                                      ? afField.type == "date" ? _.head(
                                        currentWaveSpec[afField.id].split("T")
                                      ) : currentWaveSpec[afField.id] ? currentWaveSpec[afField.id] : null
                                      : ""
                                  }
                                  invalid={
                                    afField.isInvalid &&
                                    (currentWaveSpec[afField.id] == null
                                      || currentWaveSpec[afField.id] == "")
                                  } type={afField.type} onChange={(eve) => onChangeHandler(eve)} id={afField.id} />
                                {afField.isInvalid &&
                                  (currentWaveSpec[afField.id] == null
                                    || currentWaveSpec[afField.id] == "") ? (
                                  <FormFeedback>
                                    Please provide a date or select not
                                    applicable.
                                  </FormFeedback>
                                ) : null}
                              </Col>
                            })}

                          </Row>
                          <Row className="mb-2">
                            <Col lg="4" md="4" sm="12" xs="12">
                              {Fields[field].DisablingVal ? (
                                <>
                                  <Label className="ml-4">
                                    <Input
                                      type="checkbox"
                                      onChange={(eve) =>
                                        onTogglingChecks({
                                          target: {
                                            ...eve.target,
                                            value: eve.target.checked,
                                            id: Fields[field].DisablingVal,
                                          },
                                        })
                                      }
                                      checked={
                                        currentWaveSpec[
                                          Fields[field].DisablingVal
                                        ] != null
                                          ? currentWaveSpec[
                                          Fields[field].DisablingVal
                                          ]
                                          : ""
                                      }
                                      disabled={!canEditSchedule}
                                      id={Fields[field].DisablingVal}
                                    />
                                    Not Required/TBC
                                  </Label>
                                </>
                              ) : null}
                            </Col>
                          </Row>

                          {!currentWaveSpec[Fields[field].DisablingVal] ? (
                            <Row className="mb-2">
                              <Col>
                                <label>{Fields[field].NotesField.label}</label>
                                {/* <textarea
                              rows="10"
                              className="form-control"
                              id={Fields[field].NotesField.id}
                              disabled={
                                currentWaveSpec[Fields[field].DisablingVal]
                              }
                              onChange={(eve) => onChangeHandler(eve)}
                              value={
                                currentWaveSpec[Fields[field].NotesField.id]
                                  ? currentWaveSpec[Fields[field].NotesField.id]
                                  : ""
                              }
                              placeholder="Please provide any relevant instructions here..."
                            ></textarea> */}
                                <Input
                                  id={Fields[field].NotesField.id}
                                  type="textarea"
                                  disabled={
                                    currentWaveSpec[Fields[field].DisablingVal] || !canEditSchedule
                                  }
                                  onChange={(eve) => onChangeHandler(eve)}
                                  value={
                                    currentWaveSpec[Fields[field].NotesField.id]
                                      ? currentWaveSpec[
                                      Fields[field].NotesField.id
                                      ]
                                      : ""
                                  }
                                  placeholder="Please provide any relevant information here..."
                                />
                              </Col>
                            </Row>
                          ) : null}
                        </CardBody>
                      </Collapse>
                    </Card>
                  );
                })}
                <Card className="mb-0">
                  <CardHeader
                    onClick={(e) =>
                      setToggle({ ...toggle, other: !toggle.other })
                    }
                  >
                    <Row>
                      <Col xs="11">
                        <CardTitle className="mb-0">
                          Other Requirements
                        </CardTitle>
                      </Col>
                      <Col xs="1">
                        <FontAwesomeIcon
                          className="align-middle mr-2"
                          icon={!toggle.other ? faChevronRight : faChevronDown}
                          fixedWidth
                        />
                      </Col>
                    </Row>
                  </CardHeader>
                  <Collapse isOpen={toggle.other}>
                    <CardBody>
                      <Row className="mb-2">
                        <Col>
                          <Label>Notes for Data/Measurement Science</Label>

                          <Input
                            className="mb-2"
                            type="textarea"
                            id="NotesDataScience"
                            onChange={(eve) => onChangeHandler(eve)}
                            value={
                              currentWaveSpec.NotesDataScience
                                ? currentWaveSpec.NotesDataScience
                                : ""
                            }
                            disabled={!canEditSchedule}
                            placeholder="Please provide any data/measurement science related notes here..."
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Label>Other Notes</Label>
                          {/* <textarea
                        className="form-control mb-2"
                        onChange={(eve) => onChangeHandler(eve)}
                        id="NotesOther"
                        value={
                          currentWaveSpec.NotesOther
                            ? currentWaveSpec.NotesOther
                            : ""
                        }
                        placeholder="Any other notes or topics not covered elsewhere..."
                      ></textarea> */}
                          <Input
                            id="NotesOther"
                            type="textarea"
                            className="mb-2"
                            onChange={(eve) => onChangeHandler(eve)}
                            value={
                              currentWaveSpec.NotesOther
                                ? currentWaveSpec.NotesOther
                                : ""
                            }
                            disabled={!canEditSchedule}
                            placeholder="Any other notes or topics not covered elsewhere..."
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Collapse>
                </Card>
              </Col>
            </Row>
          </Card>
        </Container>
      </>

      {errorMessage ? (
        <Container className="text-center mt-4">
          <h5 className="error">
            <strong>{errorMessage}</strong>
          </h5>
        </Container>
      ) : null}
      <Container className="d-flex mt-4 mr-2 justify-content-end">
        <Button
          disabled={app.recordloading}
          className="float-right"
          color="primary"
          onClick={() => {
            validateDates();
            // setSaveModal(true);
          }}
        >
          Save
        </Button>
      </Container>
      <Container className="d-flex justify-content-center">
        <RecordsBadge />
      </Container>
      <Modal isOpen={isSaveModal} toggle={() => setSaveModal(false)} size="lg" centered={true}>
        <ModalHeader toggle={() => setSaveModal(false)}>
          {/* <img src={logo} className="modallogo" /> */}
        </ModalHeader>

        <div>
          <span className="modaltitle modalEWN">
            Early Warning Notification
          </span>
        </div>
        <ModalBody>
          <p>Your changed have saved successfully.</p>
          <p>
            <strong>Would you like to send out an updated EWN?</strong>
          </p>
          <p>
            An email notification will be sent out to all relevant operations
            teams if the dates are provided for the team.
          </p>
          <p>
            <em>
              Please Note: You are also required to include finance in the
              notifications{" "}
              <strong>
                if there are any changes in fieldwork or reports due dates.
              </strong>{" "}
              A seperate notification will be sent to the Finance Team contacts.
            </em>
          </p>
          {showDetailedRecipients ? <>
            {(fieldingCountries.length
              && currentCostingProfile.IsMultiCountry
              && _.includes(allowedBusinessUnits, currentCostingProfile.Project.BusinessUnit)) ?
              <> <Card>
                <CardHeader onClick={() => setEwnPopupSections({
                  ...ewnPopupSections
                  , mcpfielding: !ewnPopupSections.mcpfielding
                })}>
                  <Row>
                    <Col xs="11">
                      <CardTitle className="text-uppercase">
                        MCP Fielding Countries Recipients
                      </CardTitle>
                    </Col>
                    <Col xs="1">
                      <FontAwesomeIcon
                        icon={!ewnPopupSections["mcpfielding"] ? faChevronDown : faChevronUp}
                        fixedWidth
                        className="mb-0 mr-3 medium"
                      />
                    </Col>
                  </Row>
                </CardHeader>
                <Collapse isOpen={ewnPopupSections["mcpfielding"]}>
                  <CardBody>
                    <table className="table">
                      <thead><tr>
                        <th>
                        </th>
                        <th>Fielding Country
                        </th>
                        <th>Primary Contact
                        </th>
                      </tr></thead>
                      {fieldingCountries.map(fc => {
                        let currentOptions = _.head(updatedFieldingCountries.filter(ufc => fc.CountryCode == ufc.CountryCode))?.PrimaryContactMCP
                        return <tr>
                          <td><input type="checkbox"
                            defaultChecked={_.includes(currentWaveSpec.EWNFieldingCountries?.split(","), fc.CountryCode)}
                            onChange={(e) => {
                              fieldingCheckUpdate(e, fc.CountryCode)
                            }}
                            disabled={!canEditSchedule}
                          /></td>
                          <td>{fc.CountryName}</td>
                          <td>
                            <Select
                              isMulti
                              options={currentOptions?.split(",")?.map(pcm => {
                                return { value: pcm, label: pcm }
                              })}
                              isDisabled={!canEditSchedule}
                              defaultValue={fc.PrimaryContactMCP?.split(",")?.map(pcm => {
                                return { value: pcm, label: pcm }
                              })}
                              onChange={val => {
                                let fieldingcountries = [...fieldingCountries]
                                fieldingcountries = fieldingcountries.map(fieldcount => {
                                  if (fieldcount.CountryCode == fc.CountryCode)
                                    fieldcount.PrimaryContactMCP = val ? val.join() : ""

                                  return { ...fieldcount }
                                })
                                setFieldingCountries(fieldingcountries)
                              }}
                            />
                          </td>
                        </tr>
                      })}
                    </table>
                  </CardBody>
                </Collapse>
              </Card>
              </>
              : null}
            {Object.keys(ewnfields).map(ewnrecp => {
              return <Card>
                <CardHeader onClick={() => setEwnPopupSections({
                  ...ewnPopupSections
                  , [ewnrecp]: !ewnPopupSections[ewnrecp]
                })}>
                  <Row>
                    <Col xs="11">
                      <CardTitle className="text-uppercase">
                        {ewnfields[ewnrecp].title}
                      </CardTitle>
                    </Col>
                    <Col xs="1">
                      <FontAwesomeIcon
                        icon={!ewnPopupSections[ewnrecp] ? faChevronDown : faChevronUp}
                        fixedWidth
                        className="mb-0 mr-3 medium"
                      />
                    </Col>
                  </Row>
                </CardHeader>
                <Collapse isOpen={ewnPopupSections[ewnrecp]}>
                  <CardBody>
                    <Row>
                      <table className="table">
                        <tbody>

                          {Object.keys(ewnfields[ewnrecp].properties).map(prop => {
                            return canRenderValue(ewnfields, ewnrecp, prop)
                              ? <tr>
                                <td>{ewnfields[ewnrecp].properties[prop].label}</td>
                                <td>{updatedCurrentBusinessUnit[prop] ? updatedCurrentBusinessUnit[prop].split(",").map((ucb, index) => {
                                  return <><input type="checkbox" className="mr-2"
                                    disabled={!canEditSchedule}
                                    id={`check_${ewnrecp}${ewnfields[ewnrecp].properties[prop].label}${index}`} defaultChecked={_.includes(currentBusinessUnit[prop]?.split(","), ucb)} onChange={(e) => {
                                      let businessunit = { ...currentBusinessUnit }
                                      if (e.target.checked)
                                        businessunit[prop] = businessunit[prop] ? [businessunit[prop], ucb].join() : ucb
                                      else
                                        businessunit[prop] = businessunit[prop] ? businessunit[prop].split(",").filter(p => p != ucb).join() : ""

                                      setCurrentBusinessUnit(businessunit)
                                    }} /><label className="pointer" for={`check_${ewnrecp}${ewnfields[ewnrecp].properties[prop].label}${index}`}>{ucb}</label><br></br></>
                                }) : "-"}</td>
                                {/* {updatedCurrentBusinessUnit[prop] ? <Select
                              isMulti
                              options={updatedCurrentBusinessUnit[prop]?.split(",")?.map(pcm => {
                                return { value: pcm, label: pcm }
                              })}
                              defaultValue={currentBusinessUnit[prop]?.split(",")?.map(pcm => {
                                return { value: pcm, label: pcm }
                              })}
                              onChange={val => {
                                let businessunit = { ...currentBusinessUnit }
                                businessunit[prop] = val && val.length ? val.map(v => v.value).join() : ""
                                setCurrentBusinessUnit(businessunit)
                              }}
                            /> : "-"} */}
                              </tr> : null
                          }
                          )}
                        </tbody>
                      </table>
                    </Row>
                  </CardBody>
                </Collapse>
              </Card>
            })}

            <Card>
              <CardHeader onClick={() => setEwnPopupSections({
                ...ewnPopupSections
                , additionalRecipients: !ewnPopupSections.additionalRecipients
              })}>
                <Row>
                  <Col xs="11">
                    <CardTitle className="text-uppercase">
                      Enter Additional EWN Recipients
                    </CardTitle>
                  </Col>
                  <Col xs="1">
                    <FontAwesomeIcon
                      icon={!ewnPopupSections["additionalRecipients"] ? faChevronDown : faChevronUp}
                      fixedWidth
                      className="mb-0 mr-3 medium"
                    />
                  </Col>
                </Row>
              </CardHeader>
              <Collapse isOpen={ewnPopupSections["additionalRecipients"]}>
                <CardBody>
                  <CreatableSelect

                    isMulti
                    defaultValue={currentWaveSpec.EWNAdditionalRecipients?.split(",").map(
                      (val) => {
                        return { label: val, value: val };
                      }
                    )}
                    onChange={(val) => {
                      updateAdditionalEwnRecp(val
                        ? val.map((v) => v.value).join()
                        : null
                      );
                    }}
                    options={allUsers
                      .map((val) => {
                        return {
                          value: val.Email,
                          label: val.Email,
                        }
                      })}
                  />
                </CardBody>
              </Collapse>
            </Card>
          </> : null}


          {currentWaveSpec.IncludeFinanceInComms ? (
            <Input
              type="textarea"
              id="NotesFinance"
              disabled={!canEditSchedule}
              //className="form-control"
              onChange={(eve) => onChangeHandler(eve)}
              value={
                currentWaveSpec.NotesFinance ? currentWaveSpec.NotesFinance : ""
              }
              rows="3"
              placeholder="Please provide any relevant notes for Finance Team here..."
            />
          ) : null}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <div className="d-flex">
            <CustomInput
              type="checkbox"
              id="IncludeProjectTeamInComms"
              label="Include Other Client Service Contacts"
              defaultChecked={currentWaveSpec.IncludeProjectTeamInComms}
              onChange={(eve) =>
                onChangeHandler({
                  target: {
                    ...eve.target,
                    value: eve.target.checked,
                    id: "IncludeProjectTeamInComms",
                  },
                })
              }
              className="mr-2"
            />
            <CustomInput
              type="checkbox"
              id="IncludeFinance"
              label="Include Finance"
              defaultChecked={currentWaveSpec.IncludeFinanceInComms}
              onChange={(eve) =>
                onChangeHandler({
                  target: {
                    ...eve.target,
                    value: eve.target.checked,
                    id: "IncludeFinanceInComms",
                  },
                })
              }
            />

            {/* <Tooltip
              placement="bottom"
              isOpen={ewnCautionOpen}
              hideArrow={true}
              target="ewnCaution"
              toggle={() => setEwnCaution(!ewnCautionOpen)}
            >
              <p>
                Please leave the setting unchecked if you{" "}
                <strong>DO NOT</strong> want other Client Service contacts to be
                sent a copy of this EWN.
              </p>
              <p>
                Please Note: All relevant Operations teams will always be sent
                an EWN.
              </p>
            </Tooltip> */}
          </div>
          <div className="d-flex">
            <Button onClick={() => setShowDetailedRecipients(!showDetailedRecipients)}>
              {showDetailedRecipients ? "Hide Recipients" : "Edit Recipients"}
            </Button>
            <Button
              className="ml-2"
              color="secondary"
              disabled={app.recordloading}
              onClick={() => setSaveModal(false)}
            >
              Cancel
            </Button>
            <Button
              className="ml-2"
              color="primary"
              disabled={app.recordloading}
              onClick={() => updateWaveSpecs()}
            >
              Send EWN
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={waveEditModal}
        toggle={() => setWaveEditModal(!waveEditModal)}
        centered
      >
        <ModalHeader toggle={() => setWaveEditModal(!waveEditModal)}>
          Edit Current Wave Name
        </ModalHeader>
        <ModalBody>
          <Input
            defaultValue={
              currentWaveSpec.WaveName ? currentWaveSpec.WaveName : ""
            }
            disabled={!canEditSchedule}
            id="WaveName"
            onChange={(e) => setEditableWavename(e.target.value)}
            placeholder="Enter Wave Name"
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            disabled={app.recordloading}
            onClick={(e) => {
              onChangeHandler(
                { target: { id: "WaveName", value: editableWaveName } },
                null,
                true
              );
            }}
          >
            Update
          </Button>
          <Button
            color="secondary"
            disabled={app.recordloading}
            onClick={() => setWaveEditModal(false)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal

        isOpen={enableAccept}

        toggle={() => setEnableAccept(false)}

        className="modal-md"

        centerd={true}

      >

        <ModalHeader

          toggle={() => setEnableAccept(!enableAccept)}

        >

          {/* <img src={logo} className="modallogo" /> */}



        </ModalHeader>

        <ModalBody>



          <Row>

            <Col>

              <div>

                <span className="modaltitle modaltitlemanager">

                  Select Project Manager

                </span>

              </div>

            </Col>

          </Row>

          <Select

            className="react-select-container mb-3 lg-12 md-12"

            classNamePrefix="react-select"

            options={getprojectmanagerlist?.map((item) => {

              return { value: item.Email, label: item.Email };

            })}

            isSearchable

            value={

              currproj?.length

                ? currproj

                : []

            }

            onChange={(e) => {



              setCurrProj(e);
            }}

            isMulti

          />
        </ModalBody>

        <ModalFooter>

          <Row className="justify-content-end">

            <Button

              color="primary"
              disabled={

                currproj?.length == 0 ||

                currproj == null

              }

              onClick={() => {
                saveProjectManager(true, false, true);
                setEnableAccept(false); setCurrProj([])
              }}



            >

              Save

              {/* {app.recordloading ? (

<Spinner size="small" color="#495057" />

) : null} */}

            </Button>

            <Button

              className="ml-2"

              color="secondary"

              onClick={() => { setEnableAccept(false); setCurrProj([]) }}

            >

              Cancel

            </Button>

          </Row>

        </ModalFooter>


      </Modal>

      <Modal

        isOpen={updateDisplay}

        toggle={() => setUpdateDisplay(false)}

        className="modal-md"

        centered={true}

      >

        <ModalHeader>

          {/* <img src={logo} className="modallogo" /> */}

        </ModalHeader>

        <ModalBody>

          <Label className={inputValue ? "" : "text-danger"}>

            Reason for Update:<sup>*</sup>

          </Label>

          <Input type='text' placeholder="" required

            onChange={(e) => {

              setInputValue(e.target.value)

            }}

          />

          <Row>

            <Col>

              <div>

                <span>

                  Select Project Manager

                </span>

              </div>

            </Col>

          </Row>

          <Select

            className="react-select-container mb-3 lg-12 md-12"

            classNamePrefix="react-select"

            options={getprojectmanagerlist?.map((item) => {

              return { value: item.Email, label: item.Email };

            })}

            isSearchable

            value={

              currproj?.length

                ? currproj

                : []

            }

            onChange={(e) => {

              setCurrProj(e);

            }}

            isMulti

          />

          <ModalFooter>

            <Row className="justify-content-end">

              <Button

                color="primary"

                onClick={() => { saveProjectManager(false, true, true); setUpdateDisplay(false); setCurrProj([]) }}

                disabled={

                  currproj?.length == 0 ||

                  currproj == null || inputValue == ""

                }

              >

                Save

                {/* {app.recordloading ? (

                    <Spinner size="small" color="#495057" />

                  ) : null} */}

              </Button>

              <Button

                className="ml-2"

                color="secondary"

                onClick={() => {

                  setUpdateDisplay(false);

                }}

              >

                Cancel

              </Button>

            </Row>

          </ModalFooter>

        </ModalBody>

      </Modal>
    </Layout>
  );
};

export default ProjectSchedule;
