import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardHeader, Button, Row, Col, Table, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import _ from "lodash";
import Spinner from "../../components/Spinner";
import * as countryActions from "../../redux/actions/countrySpecsActions";
import * as currentCostingActions from "../../redux/actions/currentCostingActions";
import { getLabel } from "../../utils/codeLabels";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard, faPaste, faPlus, faTimes, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { setCurrentCostingProfiles } from "../../redux/actions/costingsActions";
import Select from "react-select";
import { pageLoadEnd, pageLoadStart, setCostingStatus, recordLoadEnd, recordLoadStart, SET_RECORDLOAD, } from "../../redux/actions/appActions";
import { toastr } from "react-redux-toastr";
import axios from "../../axios-interceptor";
import TimeEntry from "./TimeEntry";
import Layout from "../../layouts/Project";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { setCurrentProject } from "../../redux/actions/currentProjectActions";

import * as  sheetCall from "../../utils/msSheetAPiCall";
import CostingDataView from "../summary/CostingDataView";
import { saveButtonEnableInputCost } from "../../redux/actions/appActions";
import logo from '../../assets/img/avatars/modal-logo.png'

const ManualCostEntry = ({ setShowManualCostEntry }) => {
	const dispatch = useDispatch();
	let { profileId } = useParams();
	const history = useHistory();
	const countrySpecs = useSelector(({ countrySpecs }) => countrySpecs);
	const costingProfiles = useSelector(({ costings }) => costings.costingProfiles);
	const currentCosting = useSelector(({ currentCosting }) => currentCosting.currentCostingProfile);
	const app = useSelector(({ app }) => app);
	const costingStatus = useSelector(({ app }) => app.costingStatus);
	const project = useSelector(
		({ currentProject }) => currentProject.newProject
	);
	const [countryDetails, setCountryDetails] = useState({});
	const [isIndividualCosting, toggleIndividualCosting] = useState(false);
	const [currentCountry, setCurrentCountry] = useState({});
	const [individualCountry, updateIndividualCountry] = useState({});
	const [individualEntryError, setIndividualEntryError] = useState();
	const [currencyConversionRates, setCurrencyConversionRates] = useState({});
	const [countryBreakdownModal, setCountryBreakdownModal] = useState(false);
	const [currentClippedCountry, setCurrentClippedCountry] = useState();
	const [currentClippedRegion, setCurrentClippedRegion] = useState();
	const [calledCurrencies, setCalledCurrencies] = useState(false);
	const [resetCosts, openResetCosts] = useState(false);
	const [timeDataEntry, switchToTime] = useState(false);
	const [isMethodologyApplicable, setIsMethodologyApplicable] = useState(false);
	const [isVendorApplicable, setIsVendorApplicable] = useState(false);
	const [calledProfile, setCalledProfile] = useState(false);
	//const [recordLoad, setRecordLoad] = useState(false);
	const [shopperMethodologies] = useState(['SM000024', 'SM000026', 'SM000029'])
	const currencies = useSelector(({ currentCosting }) =>
		currentCosting.currentCostingProfile.ProfileSetting && currentCosting.currentCostingProfile.ProfileSetting.CurrenciesData ? currentCosting.currentCostingProfile.ProfileSetting.CurrenciesData : []
	);
	const currentprofile = useSelector(({ currentCosting }) => currentCosting.currentCostingProfile);
	const codeLabels = useSelector(({ codeLabels }) => codeLabels);
	const location = useLocation();

	let pacificData = _.head(codeLabels.PacificCountriesList).Label;
	let arr = pacificData.split(',');
	const [pacificMarkets] = useState(arr)
	const [costEntryDetails, setCostEntryDetails] = useState({});

	const getDefaultCurrencySelection = (value) => {
		if (currencies) {
			if (!value) value = "US-USD";
			else if (value && value.value) value = value.value;
			let values = value.split("-");
			let currencyUnit = _.last(values);
			let countryCode = _.head(values);
			let finalValue = _.head(currencies.filter((cur) => cur.Code == countryCode && cur.CurrencyUnit == currencyUnit));
			if (finalValue)
				return {
					value: `${finalValue.Code}-${finalValue.CurrencyUnit}`,
					label: `${finalValue.Label} (1USD = ${finalValue.ConversionRateToLocal}${finalValue.CurrencyUnit})`,
				};
			else return "";
		} else return "";
	};

	useEffect(() => {
		if (currencyConversionRates)
			setCurrencyConversionRates(currencies?.map(cu => {
				currencyConversionRates[cu.CurrencyUnit] = cu.ConversionRateToLocal
			}))

	}, [currencies])

	const getCoSelected = () => {
		let timeButtonEnable = true;
		if (project.CostingTemplateVersion == "v2.0") {
			countrySpecs.map((cs) => {
				cs.MethodologySpecs.map((sm) => {
					if (sm.CostingOptions) {
						sm.CostingOptions.forEach(co => {
							if (co.TimingsData) timeButtonEnable = false;
							if (co.CoSelectedForSummary) timeButtonEnable = false;
						});
					}
				})
			})
		} else {
			timeButtonEnable = false;
		}
		return timeButtonEnable;
	}
	const onCurrencyChange = (value, country) => {
		let countryspecs = [...countrySpecs];
		countryspecs.map((cs) => {
			if (cs.CountryCode == country.CountryCode) {
				let incomingCurrency = value;

				cs.MethodologySpecs.map((meth) => {
					if (!meth.CostsData) meth.CostsData = {};
					if (countryDetails[cs.CountryCode] && countryDetails[cs.CountryCode][meth.Code]) {
						Object.keys(countryDetails[cs.CountryCode][meth.Code]).map((mc) => {
							// countryDetails[cs.CountryCode][meth.Code][mc] = countryDetails[cs.CountryCode][meth.Code][mc]
							meth.CostsData[mc] = countryDetails[cs.CountryCode][meth.Code][mc];
						});
					}
					// else if (meth.CostsData) {
					meth.CostsData = { ...meth.CostsData };
					Object.keys(meth.CostsData)?.map((cd) => {
						if (countryDetails[cs.CountryCode] && countryDetails[cs.CountryCode][meth.Code]) {
							countryDetails[cs.CountryCode][meth.Code][cd] = meth.CostsData[cd];
						}
					});
					// }
				});

				if (incomingCurrency) cs.CostInputCurrency = incomingCurrency;
				else cs.CostInputCurrency = "US-USD";
				cs.MethodologySpecs = [...cs.MethodologySpecs];
				cs = { ...cs };
			}
		});

		dispatch(countryActions.setCountrySpecs([...countryspecs]));
	};
	useEffect(() => {
		if (countrySpecs && countrySpecs.length && _.head(countrySpecs).MethodologySpecs?.length && !Object.keys(costEntryDetails).length) {
			let costEntryDetails = {};
			countrySpecs.map((cs) => {
				costEntryDetails[cs.CountryCode] = {};
				cs.MethodologySpecs.map((ms) => {
					costEntryDetails[cs.CountryCode][ms.Code] = {};
					ms.CostingOptions.map((co => {
						costEntryDetails[cs.CountryCode][ms.Code][co.id] = co.CostsData;
					}))
				});
			});
			setCostEntryDetails(costEntryDetails);
		}
	}, [countrySpecs]);
	useEffect(() => {
		if (countrySpecs && countrySpecs.length && _.head(countrySpecs).MethodologySpecs?.length) {
			let countrydetails = {};
			countrySpecs.map((cs) => {
				countrydetails[cs.CountryCode] = {};
				cs.MethodologySpecs.map((ms) => {
					countrydetails[cs.CountryCode][ms.Code] = ms.CostsData;
				});
			});
			setCountryDetails(countrydetails);
		}
	}, [currentprofile]);
	useEffect(() => {
		currentprofile.CountrySpecs && currentprofile.CountrySpecs.length > 0 && currentprofile.CountrySpecs.forEach(
			c => c.MethodologySpecs.length > 0 && c.MethodologySpecs.forEach(
				m => {
					if (m.CostingType == 2) {
						setIsMethodologyApplicable(true)
					}
					if (m.CostingType == 3) {
						setIsVendorApplicable(true)
					}
				}))

	}, [currentprofile]);
	useEffect(() => {
		if (profileId && !calledProfile) {
			setCalledProfile(true);
			dispatch(currentCostingActions.getCosting(profileId, null, true));
		} else if (!profileId) {
			//console.log("boots to dashboard");
			history.replace("/");
		}
	}, []);
	useEffect(() => {
		if (!currencies && !calledCurrencies && currentprofile && currentprofile.ProfileSetting && !currentprofile.ProfileSetting.CurrenciesData) {
			setCalledCurrencies(true);
			dispatch(currentCostingActions.setCurrencies(() => setCalledCurrencies(false)));
		}
	});
	const pasteClippedCountry = (country) => {
		let countryspecs = [...countrySpecs];
		let currentclipped = { ...currentClippedCountry };
		// if (currentClippedCountry) {
		countryspecs = countryspecs.map((cs) => {
			if (cs.id == country.id) {
				cs.CostInputCurrency = currentclipped.CostInputCurrency;
				cs.MethodologySpecs = cs.MethodologySpecs?.map((ms) => {
					let currentMeth = _.head(currentclipped.MethodologySpecs.filter((mspec) => mspec.Code == ms.Code));
					if (!currentMeth.CostsData) ms.CostsData = {};
					if(!ms.NotApplicable)
					{if (currentMeth.CostsData) ms.CostsData = { ...currentMeth.CostsData };
					if (countryDetails[currentclipped.CountryCode] && countryDetails[currentclipped.CountryCode][ms.Code])
						ms.CostsData = {
							...currentMeth.CostsData,
							...countryDetails[currentclipped.CountryCode][ms.Code],
						};

					if (!countryDetails[cs.CountryCode]) countryDetails[cs.CountryCode] = {};
					countryDetails[cs.CountryCode][ms.Code] = { ...ms.CostsData };
					}
					return { ...ms };
				});
			}
			return { ...cs };
		});
		dispatch(countryActions.setCountrySpecs(countryspecs));
		// }
		setCurrentClippedCountry(null);
	};
	const pasteClippedRegion = (index) => {
		let indiviDet = { ...individualCountry };
		let requiredCodes = currentCountry.MethodologySpecs.map((ms) => ms.Code);
		Object.keys(indiviDet).map((idet) => {
			if (idet == currentCountry.CountryCode) {
				indiviDet[idet] = indiviDet[idet].map((ir, ind) => {
					if (ind == index) {
						ir = { ...currentClippedRegion };
						requiredCodes.map((rc) => {
							if (ir[rc]) {
								ir[rc] = { ...ir[rc], properties: { ...ir[rc].properties } };
							}
						});
					}
					return { ...ir };
				});
			}
		});
		setCurrentClippedRegion(null);
		updateIndividualCountry(indiviDet);
	};
	// useEffect(() => {
	//   if (currencyConversionRates)
	//     setCurrencyConversionRates(currencies?.map(cu => {
	//       currencyConversionRates[cu.CurrencyUnit] = cu.ConversionRateToLocal
	//     }))

	// }, [currencies])
	const validInputValue = (evnt) => {
		let charcode = evnt.charCode;

		//character codes to avoid other characters apart numbers and .
		if (charcode > 31 && (charcode < 48 || charcode > 57) && charcode != 46) {
			evnt.preventDefault();
		}
		// else if (charcode == 46) {
		//   if (evnt.target.value.indexOf(".") != -1) evnt.preventDefault();
		// }
	};

	const calcFormula = (countryspecs) => {
		// let costCalFormulas = _.head(_.head(countryspecs).MethodologySpecs).CalculationSchema;
		// let finalCostFields = [];

		let costingprofiles = costingProfiles;
		countryspecs.map((cs) => {
			// let currentCurrencyConversion = (!cs.CostInputCurrency || cs.CostInputCurrency == "USD")
			//   ? 1 : _.head(currencies.filter(c => c.CurrencyUnit == cs.CostInputCurrency)).ConversionRateToLocal

			cs.MethodologySpecs.map((ms) => {
				let costCalFormulas = ms.CalculationSchema;
				Object.keys(costCalFormulas).map((ccf) => {
					cs[ccf] = 0;
					Object.keys(costCalFormulas[ccf]).map((obj) => {
						cs[obj] = 0;
						cs.MethodologySpecs.map((mth) => {
							if (mth.CostsData) {
								cs[obj] =
									cs[obj] +
									_.sum(
										Object.keys(mth.CostsData)
											.filter((cdkey) => _.includes(costCalFormulas[ccf][obj], cdkey))
											.map((k) => {
												return mth.CostsData[k];
											})
									);
							}
						});
						cs[ccf] = cs[ccf] + cs[obj];
					});
				});

				// Object.keys(ms.CalculationSchema).map((csch) => {
				//   if (!_.includes(finalCostFields, csch)) {
				//     finalCostFields.push(csch);
				//   }
				//   Object.keys(ms.CalculationSchema[csch]).map((insch) => {
				//     if (!_.includes(finalCostFields, insch)) {
				//       finalCostFields.push(insch);
				//     }
				//   });
				// });
			});
		});
		// can fetch from currentCostingProfile in store?
		// let currentCostingProfile = _.head(
		//   costingprofiles.filter(
		//     (cp) => cp.id == _.head(countryspecs).CostingProfileId
		//   )
		// );

		// finalCostFields.map((ff) => {
		//   // if (!currentCostingProfile[ff])
		//   currentCostingProfile[ff] = 0;
		//   countrySpecs.map((cs) => {
		//     currentCostingProfile[ff] = currentCostingProfile[ff] + cs[ff];
		//   });
		// });

		let currentCostingProfile = { ...currentprofile };
		// if (currentCostingProfile.PriceToClient)
		// 	currentCostingProfile.OutOfPocketCostPercent = currentCostingProfile.CostTotalExternalOperations / currentCostingProfile.PriceToClient;
		dispatch(setCurrentCostingProfiles([...costingprofiles]));
		dispatch(countryActions.setCountrySpecs([...countryspecs]));
		//console.log(currentCostingProfile, "currentCostingProfile");
		let finalProfile = {
			...currentCostingProfile,
			CountrySpecs: countryspecs,
			WaveSpecs: currentCosting.WaveSpecs,
			CostingType: currentCosting.CostingType,
		};
		return finalProfile;
	};

	const updateCostEntryForm = (eve, methCode, country) => {
		let countryCode = country.CountryCode;
		let currentCurrencyConversion = !country.CostInputCurrency || country.CostInputCurrency == "US-USD" ? 1 : _.head(currencies.filter((c) => c.CurrencyUnit == _.last(country.CostInputCurrency.split("-")))).ConversionRateToLocal;
		// let currentCurrencyConversion = currencyConversionRates[country.CostInputCurrency ? country.CostInputCurrency : "USD"]
		let countryDet = { ...countryDetails };
		if (!countryDet[countryCode]) countryDet[countryCode] = {};
		else countryDet[countryCode] = { ...countryDet[countryCode] };

		if (!countryDet[countryCode][methCode]) countryDet[countryCode][methCode] = {};
		else
			countryDet[countryCode][methCode] = {
				...countryDet[countryCode][methCode],
			};

		countryDet[countryCode][methCode][eve.target.id] = eve.target.value / currentCurrencyConversion;
		setCountryDetails({ ...countryDet }); //todo: recheck needed or not?
	};
	const updateProject = (profileTemp) => {
		const tempProfiles = project.CostingProfiles.map(cp => {
			if (cp.id == profileTemp.id)
				return { ..._.cloneDeep(profileTemp) }
			return { ..._.cloneDeep(cp) }
		});
		dispatch(setCurrentProject({ CostingProfiles: tempProfiles }))
	}
	const submitCostEntryForm = (isSubmit) => {
		let countryspecs = [...countrySpecs];
		if (_.includes(pacificMarkets, project.CommissioningCountry)&& project.CostingTemplateVersion != "v2.0") {
			countryspecs.map((country) => {
				country.MethodologySpecs.map((mth) => {
					if (!_.includes(shopperMethodologies, mth.Code) || (_.includes(pacificMarkets, project.CommissioningCountry)&& project.CostingTemplateVersion != "v2.0")) {
						if (countryDetails[country.CountryCode] && countryDetails[country.CountryCode][mth.Code]) {
							if (!mth.CostsData) mth.CostsData = {};
							mth.CostsData = {
								...mth.CostsData,
								...countryDetails[country.CountryCode][mth.Code],
							};
						}
					}
				});
			});
		}
		else {
			countryspecs.map((country) => {
				country.MethodologySpecs.map((mth) => {
					if (!_.includes(shopperMethodologies, mth.Code)) {
						if (costEntryDetails[country.CountryCode] && costEntryDetails[country.CountryCode][mth.Code]) {
							mth.CostingOptions.map((co => {
								if (!co.CostsData) co.CostsData = {};
								if (!co.SelectedVendorCost) co.SelectedVendorCost = {};
								if (mth.CostingType == 1) {
									co.CostsData = {
										...co.CostsData,
										...costEntryDetails[country.CountryCode][mth.Code][co.id],
									};
								}
								if (mth.CostingType == 3) {
									co.SelectedVendorCost = {
										...co.SelectedVendorCost,
										...costEntryDetails[country.CountryCode][mth.Code][co.id],
									};
								}
							}))

						}
					}
				});
			});
		}
		if (isSubmit) {
			const currentCostingProfile = calcFormula(countryspecs);
			dispatch(
				currentCostingActions.updateProfile({
					...currentCostingProfile,
					ProfileSetting: {
						...currentCostingProfile.ProfileSetting,
						CurrenciesData: currencies,
					},
					currencies,
				})
			);
			updateProject({
				...currentCostingProfile,
				ProfileSetting: {
					...currentCostingProfile.ProfileSetting,
					CurrenciesData: currencies,
				},
				currencies,
			})
			dispatch(
				currentCostingActions.saveCostingProfile({
					...currentCostingProfile,
					ProfileSetting: {
						...currentCostingProfile.ProfileSetting,
						CurrenciesData: currencies,
					},
					currencies,
				})
			);
		} else {
			return countryspecs;
		}
	};

	const calcFormulaOnChange = () => {
		let countryspecs = submitCostEntryForm();
		calcFormula(countryspecs);
	};
	const getDefaultCurrValue = (value, country) => {
		if (currencies) {
			// let currentCurrencyConversion = currencyConversionRates[country.CostInputCurrency ? country.CostInputCurrency : "USD"]
			let currentCurrencyConversion = !country.CostInputCurrency || country.CostInputCurrency == "US-USD" ? 1 : _.head(currencies.filter((c) => c.CurrencyUnit == _.last(country.CostInputCurrency.split("-"))))?.ConversionRateToLocal;
			return _.round(value * currentCurrencyConversion, 2);
		} else {
			return null;
		}
	};
	const openCountryBreakdownCaution = (country) => {
		setCurrentCountryDetails(country);
		if (country.MethodologySpecs.filter((met) => met.CountryCostBreakdown).length) {
			breakdownAccepted();
		} else {
			setCountryBreakdownModal(true);
		}
	};
	const breakdownAccepted = () => {
		submitCostEntryForm();
		toggleIndividualCosting(true);
		setCountryBreakdownModal(false);
	};
	const setCurrentCountryDetails = (country) => {
		country.MethodologySpecs.map((mth) => {
			if (mth.CountryCostBreakdown) {
				mth.CountryCostBreakdown.map((bd) => {
					if (!individualCountry[country.CountryCode]) individualCountry[country.CountryCode] = [];
					let existedBD = _.head(individualCountry[country.CountryCode].filter((cc) => cc.title == bd.title));
					if (!existedBD)
						individualCountry[country.CountryCode].push({
							title: bd.title,
							[mth.Code]: { properties: bd.properties },
						});
					else existedBD[mth.Code] = { properties: bd.properties };
				});
			}
		});
		if (!individualCountry[country.CountryCode]) {
			individualCountry[country.CountryCode] = [
				{
					title: "",
				},
			];
		}
		setCurrentCountry(country);
		// submitCostEntryForm();
		// toggleIndividualCosting(true);
	};

	const getFinalProperties = (properties) => {
		let finalProperties = [];

		//todo uncomment these when SC, MC changes come
		//and please take care of the client chargeable also, thanks
		// if (currentCosting.IsMultiCountry) {
		// 	// if (countrySpecs.length > 1) {
		// 	Object.keys(properties).map((prop) => {
		// 		if (properties[prop].isMultiCountry == true || properties[prop].isMultiCountry == undefined) {
		// 			finalProperties.push(prop);
		// 		}
		// 	});
		// } else {
		// 	Object.keys(properties).map((prop) => {
		// 		if (properties[prop].isMultiCountry == false || properties[prop].isMultiCountry == undefined) {
		// 			finalProperties.push(prop);
		// 		}
		// 	});
		// }
		Object.keys(properties).map((prop) => {
			if ((properties[prop].IsClientChargeable && currentCosting?.ProfileSetting?.ClientChargeableApplicable)
				|| properties[prop].IsClientChargeable == undefined) {
				finalProperties.push(prop);
			}
		});
		return finalProperties;
	};
	const updateIndividualProperty = (eve, index, methCode, country) => {
		let countryCode = country.CountryCode;
		let currentCurrencyConversion = !country.CostInputCurrency || country.CostInputCurrency == "US-USD" ? 1 : _.head(currencies.filter((c) => c.CurrencyUnit == _.last(country.CostInputCurrency.split("-")))).ConversionRateToLocal;

		let indiviDet = { ...individualCountry };
		if (!indiviDet[countryCode][index][methCode]) {
			indiviDet[countryCode][index][methCode] = { properties: {} };
		}
		indiviDet[countryCode][index][methCode].properties[eve.target.id] = eve.target.value / currentCurrencyConversion;
		updateIndividualCountry(indiviDet);
	};
	const updateIndividualCountryTitle = (value, index) => {
		let indiviDet = { ...individualCountry };
		indiviDet[currentCountry.CountryCode][index].title = value;
		updateIndividualCountry(indiviDet);
	};
	const updateDataToCountry = () => {
		let countryspecs = [...countrySpecs];
		let individualcountry = { ...individualCountry };
		let updatableCountry = _.head(countryspecs.filter((cs) => cs.CountryCode == currentCountry.CountryCode));
		if (individualCountry[currentCountry.CountryCode].filter((inc) => !inc.title).length) {
			setIndividualEntryError("Please Provide Column Titles");
		} else {
			setIndividualEntryError("");
			updatableCountry.MethodologySpecs.map((mth) => {
				let reqProp = individualcountry[currentCountry.CountryCode].filter(
					(ic) =>
						Object.keys(ic)
							.filter((key) => key != "title")
							.indexOf(mth.Code.toString()) != -1
				);
				let finalProp = {};
				if (reqProp.length) {
					reqProp.map((prop) => {
						if (prop[mth.Code] && prop[mth.Code].properties) {
							Object.keys(prop[mth.Code].properties).map((intProp) => {
								if (!finalProp[intProp]) finalProp[intProp] = prop[mth.Code].properties[intProp];
								else finalProp[intProp] += prop[mth.Code].properties[intProp];
							});
						}
					});
				}

				individualcountry[currentCountry.CountryCode]?.map((ic) => {
					if (_.includes(Object.keys(ic), mth.Code)) {
						if (!mth.CountryBreakDown) mth.CountryBreakDown = [];
						let existedBD = mth.CountryBreakDown.filter((bd) => bd.title == ic.title);
						if (existedBD.length) {
							existedBD.properties = ic[mth.Code].properties;
						} else {
							mth.CountryBreakDown.push({
								title: ic.title,
								properties: ic[mth.Code].properties,
							});
						}
					}
				});

				individualcountry[currentCountry.CountryCode]?.map((ic) => {
					if (_.includes(Object.keys(ic), mth.Code)) {
						if (!mth.CountryCostBreakdown) mth.CountryCostBreakdown = [];
						let existedBD = mth.CountryCostBreakdown.filter((bd) => bd.title == ic.title);
						if (existedBD.length) {
							existedBD.properties = ic[mth.Code].properties;
						} else {
							mth.CountryCostBreakdown.push({
								title: ic.title,
								properties: ic[mth.Code].properties,
							});
						}
					}
				});

				mth.CountryCostBreakdown = mth.CountryCostBreakdown?.filter((bd) =>
					_.includes(
						individualcountry[currentCountry.CountryCode].map((ic) => ic.title),
						bd.title
					)
				);

				if (!countryDetails[currentCountry.CountryCode]) countryDetails[currentCountry.CountryCode] = {};
				if (!countryDetails[currentCountry.CountryCode][mth.Code]) countryDetails[currentCountry.CountryCode][mth.Code] = {};

				countryDetails[currentCountry.CountryCode][mth.Code] = Object.keys(finalProp).length ? finalProp : {};
				mth.CostsData = Object.keys(finalProp).length ? finalProp : null;
			});
			// }
			// })
			dispatch(countryActions.setCountrySpecs([...countryspecs]));
			calcFormula(countryspecs);
			toggleIndividualCosting(false);
		}
		// countryspecs.map(cs => {
		// if (cs.CountryCode == currentCountry.CountryCode) {
	};
	const addColumnForIndividual = () => {
		let indiviDet = { ...individualCountry };
		indiviDet[currentCountry.CountryCode].push({ title: "" });
		updateIndividualCountry(indiviDet);
	};
	const cancelIndividualCosting = () => {
		//clears the added inputs when cancel
		// let indiviDet = { ...individualCountry }
		// indiviDet[currentCountry.CountryCode] = [{
		//   title: ""
		// }]
		// updateIndividualCountry(indiviDet)
		toggleIndividualCosting(false);
	};
	const removeFromIndividual = (index) => {
		let indiviDet = { ...individualCountry };
		// let deletedValue = { ...indiviDet[currentCountry.CountryCode][index] }
		let finalArr = [];
		indiviDet[currentCountry.CountryCode].map((id, ind) => {
			if (ind != index) finalArr.push(id);
		});
		indiviDet[currentCountry.CountryCode] = finalArr;
		updateIndividualCountry(indiviDet);

		// let countryspecs = countrySpecs

		// countryspecs.map(cs => {
		//   if (cs.CountryCode == currentCountry.CountryCode) {
		//     cs.MethodologySpecs.map(meth => {
		//       meth.CountryCostBreakdown = meth.CountryCostBreakdown.filter(bd => bd.title != deletedValue.title)
		//     })
		//   }
		// })

		// dispatch(countryActions.setCountrySpecs([...countryspecs]));
	};
	function refreshCostingProfile(costingProfileID) {
		dispatch(
			currentCostingActions.getCosting(costingProfileID)
		);
	}

	async function openCreateSheet() {
		if (!currentprofile.CostingsSheetId) {
			toastr.info("Costing Sheet is being created...");

			dispatch(pageLoadStart());
			axios
				.post("/utils/sheets/" + currentprofile.id + "/costing")
				.then((res) => {
					dispatch(
						currentCostingActions.updateProfile({
							...currentprofile,
							CostingsSheetId: res.data.CostingsSheetId,
						})
					);
					updateProject({
						...currentprofile,
						CostingsSheetId: res.data.CostingsSheetId,
					})

					dispatch(
						currentCostingActions.saveCostingProfile(
							{
								...currentprofile,
								CostingsSheetId: res.data.CostingsSheetId,
							},
							null,
							true
						)
					);
					toastr.success("Sheet created Successfully", res.data.message);
					// window.open(`https://docs.google.com/spreadsheets/d/${res.data.CostingsSheetId}`, "_blank");

					// if (res.data.CostingsSheetId && project.CostingTemplateVersion != "v2.0") {
					if (res.data.CostingsSheetId && project.CostingTemplateVersion != "v2.0") {
						toastr.info("Sheet is being prepared", "Sheet Preparation might take sometime to setup your project configuration", { timeOut: 20000 });
						setTimeout(() => {
							toastr.info("It is about to complete", "Please do not refresh or close the page, sheet preparation is inprogress", { timeOut: 80000 });
						}, 20000);
						setTimeout(() => {
							toastr.info("Opening the sheet", null);
							dispatch(sheetCall.msSheetAPiCall(res.data.CostingsSheetId, true, true, () => dispatch(pageLoadEnd())));
							// dispatch(pageLoadEnd());

						}, 100000);

					}
					else if (res.data.CostingsSheetId && project.CostingTemplateVersion == "v2.0") {
						dispatch(sheetCall.msSheetAPiCall(res.data.CostingsSheetId, true, true, () => dispatch(pageLoadEnd())));

					}

				})
				.catch((err) => {
					dispatch(pageLoadEnd());
					// setSyncAbility(false)
					toastr.error("Creating Sheet Failed", err?.data.message);
				});
		}
		else {

			// window.open(`https://docs.google.com/spreadsheets/d/${currentprofile.CostingsSheetId}`, "_blank");
			//todo: handle in open cost sheet inside then block, avoid sending dispatch
			dispatch(sheetCall.msSheetAPiCall(currentprofile.CostingsSheetId, true, true, () => dispatch(recordLoadEnd())))

		}
	}
	async function openSheet() {
		dispatch(recordLoadStart());
		if (!project.CostingsFolderId && !project.ProjectResourcesFolderId) {
			axios
				.post("/utils/folders/" + project.id)
				.then((res) => {
					dispatch(setCurrentProject({
						CostingsFolderId: res.data.CostingsFolderId,
						ProjectResourcesFolderId: res.data.ProjectResourcesFolderId,
					}))
					openCreateSheet()
					toastr.success("One Drive folders are now ready for use");
				})
				.catch(() => {
					toastr.error("One Drive folder creation failed");
				})
		}
		else {
			openCreateSheet()
		}
	};

	const CostingTypeCheckInCostsDataView = (val) => {
		dispatch(saveButtonEnableInputCost(val));
	}
	return <>
		<Layout
			costMethod={currentCosting.CostingType}
			profileStatusToDisplay={getLabel(
				"CostingStatusOptions",
				currentCosting.ProfileStatus
			)}
			projectStatusToDisplay={getLabel(
				"ProjectStatusOptions",
				currentCosting.Project?.ProjectStatus
			)}
			avoidEdit={true}
		>
			{/* {_.includes(pacificMarkets, project.CommissioningCountry) ? <Row className="justify-content-between m-3"><h3 className="mt-3 ml-1 mb-0">{currentCosting.ProfileName} </h3>
				<Button color="primary" className="btn-sm view-summary-btn" onClick={() => history.push(`/summary/${currentCosting.id}`)}>
					View Cost Summary
				</Button>
			</Row> : null} */}
			{!isIndividualCosting ? (
				!timeDataEntry ? <Card className="rounded ext-costing">
					<CardHeader>
						<Row className="justify-content-between p-3">
							<h3>Cost Details</h3>
							<div>

								<Button className="mr-2" color="secondary"
									onClick={() => switchToTime(true)}
									disabled={getCoSelected()}>
									View Timings
								</Button>
								{(
									 project.CostingTemplateVersion == "v2.0" ||!_.includes(pacificMarkets, project.CommissioningCountry) 
								)
									&& <Button
										onClick={() => {
											let clone = _.cloneDeep(currentCosting)
											clone["updateCost"] = true
											dispatch({
												type: "UPDATE_NEW_COSTING",
												currentCostingProfile: clone,
											});

										}

										}
									>
										Update Costing Type
									</Button>}
							</div>
						</Row>
						<Row>
							{/* <Col>{currentprofile.CostingType != "SHEETS" ? <h5>Please input cost for all waves combined if your project is a tracker.</h5> : null}</Col> */}
							<Col>
								<h5 className="float-right error">{location.state == "SHEETS" ? "Manual cost entry is restricted for the Methodology whose costing type is MS-Sheets" : ""}</h5>
							</Col>
						</Row>
						{currentprofile.isOldSheet ? (
							<Row className="m-0 mb-2 justify-content-end error">
								<strong>Please note: This profile is using an older version of the cost sheet. If this profile has multiple submethodologies, all costs will be combined under one submethodology.</strong>{" "}
							</Row>
						) : null}
						{
							//todo:why is location.state
							(project.CostingTemplateVersion == "v2.0" || (location.state == "SHEETS")) ?
								(
									<>
										<Row className="m-0 justify-content-end">


											{currentprofile.CostingsSheetId && <Button color="primary" onClick={() =>
												refreshCostingProfile(currentprofile.id)} style={{ marginRight: "1rem" }}>
												Refresh Costs

											</Button>}
											<Button color="primary" onClick={() => {
												if(project.PriceChange == 2 && !currentprofile.CostingsSheetId){
													toastr.error("Open Cost Sheet Failed", "Cannot create a new cost sheet for preapproved projects.");
												}else{
												openSheet()
												}
												}} 
												disabled={app.recordloading} >
												Open Cost Sheet
												{app.recordloading && <Spinner size="small" color="#495057" />}
											</Button>



											{project.CostingTemplateVersion != "v2.0" ?
												(<Button color="primary" className="ml-2" disabled={!currentprofile.CostingsSheetId || currentprofile.ProfileStatus > 5} onClick={() => dispatch(currentCostingActions.syncSheetData(currentprofile, pacificMarkets))}>
													Sync Costs from sheet
												</Button>
												) : null}

										</Row>
										<Row className="m-0 justify-content-end">
											<Col>
												<h5 className="float-right error">{location.state == "SHEETS" ? "Please ensure pop-up window setting is set to enabled in the browser. If disabled the costing sheet will not open." : ""}</h5>
											</Col>										</Row>
									</>
								) : null}
						<Row className="m-0 justify-content-end">
							{(project.CostingTemplateVersion != "v2.0" && currentprofile.CostingType == "SHEETS") ?
								(<Button color="primary" onClick={() => openSheet()} disabled={app.recordloading} >
									Open Cost Sheet
									{app.recordloading && <Spinner size="small" color="#495057" />}
								</Button>) : null}
							{(project.CostingTemplateVersion != "v2.0" && currentprofile.CostingType == "SHEETS") ?
								(<Button color="primary" className="ml-2" disabled={!currentprofile.CostingsSheetId || currentprofile.ProfileStatus > 5} onClick={() => dispatch(currentCostingActions.syncSheetData(currentprofile, pacificMarkets, () => refreshCostingProfile(currentprofile.id)))}>
									Sync Costs
								</Button>) : null}
						</Row>

					</CardHeader>
					<div className="d-flex wrapper">
						{project.CostingTemplateVersion == "v2.0"  ?
							<>
								<CostingDataView
									isEnabled={CostingTypeCheckInCostsDataView}>
								</CostingDataView>
							</> :
							<Table inline hover bordered={true} size="sm">
								<thead className="border">
									<th className="h4">Breakdown by Methodology</th>
									{countrySpecs.map((country) => (
										<th className="text-center">
											<span className="d-flex align-middle text-nowrap">
												{getLabel("FieldingCountriesOptions", country.CountryCode)}
												{currentprofile.CostingType !== "SHEETS" ? (
													<FontAwesomeIcon title="Add Breakdown for Country" className="ml-2 pointer small add-indivi-icon" icon={faPlus} fixedWidth onClick={() => openCountryBreakdownCaution(country)} />
												) : null}
											</span>
											<div class="input-group mt-2">
												<div class="input-group-prepend">
													<span class="input-group-text text-sm">Currency</span>
												</div>
												<Select
												className="react-select-container currency-entry custom-select-box"
												classNamePrefix="react-select"
													//className="currency-entry custom-select-box"
													options={currencies?.map((c) => {
														return {
															value: `${c.Code}-${c.CurrencyUnit}`,
															label: `${c.Label} (1USD = ${c.ConversionRateToLocal}${c.CurrencyUnit})`,
														};
													})}
													value={getDefaultCurrencySelection(country.CostInputCurrency)}
													onChange={(select) => onCurrencyChange(select.value, country)}
												/>
											</div>
											{currentCosting.IsMultiCountry && currentprofile.CostingType !== "SHEETS" ? (
												<div className="d-flex justify-content-between mt-2">
													<FontAwesomeIcon title="Copy this country costs" icon={faClipboard} onClick={() => setCurrentClippedCountry(country)} className="ml-5 pointer" color="#04ace7" />
													<FontAwesomeIcon title="Paste copied costs to this country" icon={faPaste} onClick={() => pasteClippedCountry(country)} className={`mr-5 ${!currentClippedCountry ? "no-actions" : "pointer "}`} color="#04ace7" />
												</div>
											) : null}
										</th>
									))}
								</thead>
								<tbody>
									{
										// countrySpecs.map((country) => {
										//avoid hardcoding index
										//replace with lodash when installed
										countrySpecs && countrySpecs.length ? (
											_.head(countrySpecs)?.MethodologySpecs?.filter(ms => (!_.includes(shopperMethodologies, ms.Code) || (_.includes(pacificMarkets, project.CommissioningCountry)  && project.CostingTemplateVersion != "v2.0"))).map((ms) => {
												return (
													<>
														<tr>
															<td className="main-meth-label text-uppercase">
																<h5>{ms.Label}</h5>
															</td>
															{countrySpecs?.map((cs) => (
																<td></td>
															))}
															{/* only for border styles purpose, todo: optimize */}
														</tr>

														{ms.CostsSchema?.map((costsch) => {
															let finalProps = getFinalProperties(costsch.properties);
															return finalProps.length ? (
																<>
																	<tr className="mt-4 h5">
																		<td>
																			<strong>{costsch.title}</strong>
																			{/* only for border styles purpose, todo: optimize */}
																		</td>
																		{countrySpecs.map((cs) => (
																			<td></td>
																		))}
																	</tr>
																	{finalProps.map((prop) => {
																		return costsch.properties[prop].title ? (
																			<tr>
																				<td className="sub-meth-label">{costsch.properties[prop].title}</td>
																				{countrySpecs.map((cs) => {
																					let currentMeth = _.head(cs.MethodologySpecs.filter((mt) => mt.Code == ms.Code));
																					return currentMeth ? (
																						<td>
																							<div class="input-group">
																								<input
																									placeholder={currentMeth.NotApplicable ? "Not Applicable" : null}
																									className="form-control"
																									type="number"
																									id={prop}
																									value={
																										countryDetails[cs.CountryCode] && countryDetails[cs.CountryCode][currentMeth.Code] && countryDetails[cs.CountryCode][currentMeth.Code][prop]
																											? getDefaultCurrValue(countryDetails[cs.CountryCode][currentMeth.Code][prop], cs)
																											: ""
																									} //for prepopulation
																									onKeyPress={(e) => {
																										validInputValue(e);
																									}}
																									onBlur={calcFormulaOnChange}
																									min={0}
																									step="any"
																									onChange={(eve) => updateCostEntryForm(eve, currentMeth.Code, cs)}
																									disabled={
																										currentMeth.NotApplicable ||
																										currentprofile.CostingType == "SHEETS" ||
																										(currentprofile.CostingType == "VENDOR" && currentprofile.VendorBiddingSubmethodologies && _.includes(currentprofile.VendorBiddingSubmethodologies.split(","), currentMeth.Code))
																									}
																								/>{" "}
																								<div class="input-group-append">
																									<span class="input-group-text text-sm">{cs.CostInputCurrency ? _.last(cs.CostInputCurrency.split("-")) : "USD"}</span>
																								</div>
																							</div>
																						</td>
																					) : null
																				})}
																			</tr>
																		) : null;
																	})}
																</>
															) : null;
														})}
													</>
												);
											})
										) : (
											<></>
										)
										// })
									}
								</tbody>
							</Table>
						}
					</div>
					<div className="ml-auto">
						<button className="btn btn-secondary mt-4 mr-2"
							// onClick={() => dispatch(setCostingStatus({ ...costingStatus, showManualCostEntry: false }))}>
							onClick={() => history.goBack()}>
							Back
						</button >
						<button
							className="btn btn-primary mt-4"
							disabled={app.recordloading || currentCosting.ProfileStatus > 5 || currentprofile.CostingType == "SHEETS"}
							onClick={(e) => {
								if (app.recordloading || currentCosting.ProfileStatus > 5 || currentprofile.CostingType == "SHEETS") {
									e.preventDefault();
								} else {
									submitCostEntryForm(true);
								}
							}}
						>
							Save
							{app.recordloading ? <Spinner size="small" color="#495057" /> : null}
						</button>
					</div >
					{/*TODO: Modal also shows after closing breakdown page. Please review. :) */}
					< Modal isOpen={countryBreakdownModal} toggle={() => setCountryBreakdownModal(false)} size="sm" >
						<ModalHeader toggle={() => setCountryBreakdownModal(false)}>
							<img src={logo} className="modallogo" />
						</ModalHeader>
						<span className="modaltitle p-0">Warning!</span>
						<ModalBody>
							<p>You are about to edit cost breakdown by country.</p>
							<p>
								By doing this you might lose any costs already added for <strong>{getLabel("FieldingCountriesOptions", currentCountry.CountryCode)}</strong> without using breakdowns.
							</p>
							<p>If you are editing breakdowns already provided, you can safely ignore this warning.</p>
							<h5>Are you sure you want to continue?</h5>
						</ModalBody>
						<ModalFooter>
							<div className="d-flex justify-content-between">
								<Button className="form-control mr-2" color="primary" disabled={app.recordloaded} onClick={() => breakdownAccepted()}>
									Confirm
								</Button>
								<Button className="form-control" color="secondary" disabled={app.recordloaded} onClick={() => setCountryBreakdownModal(false)}>
									Cancel
								</Button>
							</div>
						</ModalFooter>
					</Modal >
					<Modal isOpen={resetCosts} toggle={() => openResetCosts(!resetCosts)}>
						<ModalHeader toggle={() => openResetCosts(!resetCosts)}>Allow Costing</ModalHeader>
						<ModalBody>This change is irreversible, you might lose costs data. Are you sure want to reset costing?</ModalBody>
						<ModalFooter>
							<Row>
								<Button
									color="secondary"
									onClick={() => {
										openResetCosts(!resetCosts);
									}}
								>
									Cancel
								</Button>
								<Button
									color="primary"
									className="ml-2"
									onClick={() => {
										let _currentprofile = { ...currentprofile };
										const additionalLabels = {
											CostTotalExternalOperations: {
												CostExtOpsMCPSubContract: "MCP/Group Company Sub-Contracting",
												CostExtOpsOtherTaxVAT: ["MCPTaxCost"
													, "MCPOtherTaxAdjustment"]
											},
										};
										_currentprofile.CountrySpecs.map((cs) => {
											cs.MethodologySpecs.map((ms) => {
												ms.CostsData = null;
												ms.TimingsData = null;
												Object.keys(ms.CalculationSchema).map((csch) => {
													cs[csch] = 0;
													Object.keys(ms.CalculationSchema[csch]).map((insch) => {
														cs[insch] = 0;
													});
													if (additionalLabels && additionalLabels[csch]) {
														Object.keys(additionalLabels[csch]).map((lab) => {
															cs[lab] = 0;
														});
													}
												});
											});
										});
										dispatch(
											currentCostingActions.saveCostingProfile({ ..._currentprofile, CostingType: null }, () => {
												dispatch(
													currentCostingActions.getCosting(currentprofile.id, () => {
														openResetCosts(!resetCosts);
														dispatch(
															setCostingStatus({
																...costingStatus,
																showManualCostEntry: false,
															})
														);
													})
												);
											})
										);
									}}
								>
									Confirm{app.recordloading ? <Spinner size="small" color="#495057" /> : null}
								</Button>
							</Row>
						</ModalFooter>
					</Modal>
				</Card > : <TimeEntry history = {history} isEnabled={CostingTypeCheckInCostsDataView} switchToTime={switchToTime} />
			) : (
				<Card className="rounded ext-costing">
					<CardHeader>
						<Row>
							<Col>
								<Row>
									<Col>
										<h4>Add cost breakdown for {getLabel("FieldingCountriesOptions", currentCountry.CountryCode)}</h4>
									</Col>
								</Row>
								<Row>
									<Col>
										<h5>Columns names are required (City/Region/Vendor etc.)</h5>
									</Col>
								</Row>
							</Col>
							<Col>
								<Row>
									<Col>
										<Row>
											<Col>
												<h5 className="float-right error">Reminder: Input amounts should be in currency indicated per cell.</h5>
											</Col>
										</Row>
									</Col>
								</Row>
								<Row>
									<Col>
										<Button className="float-right" color="primary" onClick={addColumnForIndividual}>
											Add New Column +
										</Button>
									</Col>
								</Row>
							</Col>
						</Row>
					</CardHeader>
					<CardBody>
						<div className="wrapper">
							<Table inline hover bordered={true}>
								<thead>
									<th className="h4">Breakdown by Methodology</th>
									{individualCountry[currentCountry.CountryCode].map((countDet, index) => (
										<th key={`header_${index}`}>
											<span className="d-flex">
												<input value={countDet.title}
													className="form-control"
													placeholder="City/Region/Vendor..."
													onChange={(eve) => updateIndividualCountryTitle(eve.target.value, index)} />
												{individualCountry[currentCountry.CountryCode].length > 1 ? <FontAwesomeIcon className="align-middle mr-2 error pointer" icon={faTimes} fixedWidth onClick={() => removeFromIndividual(index)} /> : null}
											</span>
											{individualCountry[currentCountry.CountryCode].length > 1 && currentprofile.CostingType !== "SHEETS" ? (
												<div className="d-flex justify-content-between mt-2">
													<FontAwesomeIcon icon={faClipboard} title="Copy" onClick={() => setCurrentClippedRegion(countDet)} className="ml-5 pointer" color="#04ace7" />
													<FontAwesomeIcon icon={faPaste} title="Paste" onClick={() => pasteClippedRegion(index)} className={`mr-5 ${!currentClippedRegion ? "no-actions" : "pointer "}`} color="#04ace7" />
												</div>
											) : null}
										</th>
									))}
								</thead>
								<tbody>
									{currentCountry.MethodologySpecs.map((ms, ind) => {
										return (
											<>
												<tr>
													<td className="main-meth-label text-uppercase">
														<h4>{ms.Label}</h4>
													</td>
													{individualCountry[currentCountry.CountryCode].map((cs) => (
														<td></td>
													))}
													{/* only for border styles purpose, todo: optimize */}
												</tr>

												{ms.CostsSchema.map((costsch) => {
													let finalProps = getFinalProperties(costsch.properties);
													return finalProps.length ? (
														<>
															<tr className="mt-4">
																<td>
																	<strong>{costsch.title}</strong>
																	{/* only for border styles purpose, todo: optimize */}
																</td>
																{individualCountry[currentCountry.CountryCode].map((cs) => (
																	<td></td>
																))}
															</tr>
															{finalProps.map((prop) => {
																return (
																	costsch.properties[prop].title ? <tr>
																		<td className="sub-meth-label">{costsch.properties[prop].title}</td>
																		{individualCountry[currentCountry.CountryCode].map((indivi, indIndex) => {
																			return (
																				<td>
																					<div class="input-group">
																						<input
																							className="form-control"
																							type="number"
																							value={
																								individualCountry[currentCountry.CountryCode] && individualCountry[currentCountry.CountryCode].length
																									? individualCountry[currentCountry.CountryCode][indIndex][ms.Code]
																										? getDefaultCurrValue(individualCountry[currentCountry.CountryCode][indIndex][ms.Code].properties[prop], currentCountry)
																										: null
																									: null
																							}
																							onKeyPress={(e) => {
																								validInputValue(e);
																							}}
																							min={0}
																							step="any"
																							onChange={(eve) => {
																								updateIndividualProperty(eve, indIndex, ms.Code, currentCountry);
																							}}
																							id={prop}
																							disabled={location.state == "SHEETS" || ms.CostingType == 2 || ms.CostingType == 3}
																						/>{" "}
																						<div class="input-group-append">
																							<span class="input-group-text text-sm">{currentCountry.CostInputCurrency ? _.last(currentCountry.CostInputCurrency.split("-")) : "USD"}</span>
																						</div>
																					</div>
																				</td>
																			);
																		})}
																	</tr> : null
																);
															})}
														</>
													) : null;
												})}
											</>
										);
									})}
								</tbody>
							</Table>
						</div>
						<div className="ml-auto mt-2">
							<h4 className="error float-right mb-0">{individualEntryError}</h4>
							<br></br>
							<button className="btn btn-primary mt-4 float-right" onClick={() => updateDataToCountry()}>
								Add to {getLabel("FieldingCountriesOptions", currentCountry.CountryCode)}
							</button>

							<button className="btn btn-secondary mr-2 mt-4 float-right" onClick={() => cancelIndividualCosting()}>
								Cancel
							</button>
						</div>
					</CardBody>
				</Card>
			)}
		</Layout >
	</>

};

export default ManualCostEntry;
