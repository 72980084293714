import React, { useState, useEffect } from "react";
import {
    Table, Button,Row,Col
} from "reactstrap";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as currentCostingActions from "../../redux/actions/currentCostingActions";

const ProfitabilityOverview = ({ profile }) => {

    const history = useHistory()
    const dispatch = useDispatch()
    const [currentCurrency, setCurrentCurrency] = useState({});
    const project = useSelector(
        ({ currentProject }) => currentProject.newProject
    );
    const currencies = useSelector(({ currentCosting }) =>
        currentCosting.currentCostingProfile.ProfileSetting &&
            currentCosting.currentCostingProfile.ProfileSetting.CurrenciesData
            ? currentCosting.currentCostingProfile.ProfileSetting.CurrenciesData
            : []
    );

    const getCurrentCurrency = (actualvalue) => {
        if (
            currencies &&
            currencies.length &&
            currentCurrency &&
            currentCurrency.ConversionRateToLocal
        ) {
            if (actualvalue) {
                let finalVal = _.round(
                    actualvalue * currentCurrency.ConversionRateToLocal,
                    2
                );
                return `${finalVal} ${currentCurrency.CurrencyUnit}`;
            } else return `0 ${currentCurrency.CurrencyUnit}`;
        }
    };
    useEffect(() => {
        if (profile && profile.CostInputCurrency) {
            let values = profile.CostInputCurrency.split("-");
            let currencyUnit = _.last(values);
            let countryCode = _.head(values);
            if (currencies) {
                setCurrentCurrency(
                    _.head(
                        currencies.filter(
                            (cur) =>
                                cur.Code == countryCode && cur.CurrencyUnit == currencyUnit
                        )
                    )
                );
            }
        }
    }, [profile, currencies]);
    const alternativeLabels = {
        CostTotalInternalCommercial: {
            UsesOopOverrideIntCommCost: "CostIntCommMultiplier",
            // Value: "CostTotalExternalOperations"
        },
        CostTotalInternalOperations: {
            UsesOopOverrideIntOpsCost: "CostIntOpsMultiplier",
            // Value: "CostTotalExternalOperations"
        },
    };
    return (
        <>
            <div id="profitabilityBreakdown">
                <Table responsive hover striped bordered={true} size="sm">
                    <thead>
                        <tr>
                            <th></th>
                            {project.CostingProfiles.map(_profile =>

                                <th>
                                    {/* <a style={{ textDecoration: "underline" }}
                                            onClick={() => history.push(`/summary/${_profile.id}`)} >
                                            {_profile.ProfileName}
                                        </a> */}

                                          {/* <Row className="justify-content-between m-2">
                                        {_profile.ProfileName}
                                        <p>
                                            <Button color="primary"
                                                className="btn-sm view-summary-btn"
                                                onClick={() => history.push(`/summary/${_profile.id}`)}
                                            >
                                                View Cost Summary
                                            </Button>
                                        </p>
                                    </Row> */}
                                    <div>
                                        {_profile.ProfileName}
                                        <p>
                                            <Button color="primary"
                                                className="btn-sm view-summary-btn"
                                                onClick={() => 
                                                    {
                                                        dispatch(
                                                            currentCostingActions.getSummaryCosting(_profile, () =>
                                                              history.push(`/summary/${_profile.id}`),
                                                              _profile?.IsOPSOOPAdjusted ? false : true
                                                            )
                                                          );
                                                    }
                                                }
                                            >
                                                View Cost Summary
                                            </Button>
                                        </p>
                                    </div>
                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td xs="11">Total Internal Operations Cost
                                <br></br>
                                <p className="help">{alternativeLabels["CostTotalInternalOperations"] &&
                                    Object.keys(alternativeLabels["CostTotalInternalOperations"]).filter(
                                        (al) => profile.ProfileSetting[al]
                                    ).length ? "Estimated using Multiplier" : null}</p></td>

                            {project.CostingProfiles.map(_profile =>
                                <td xs="1">
                                    {getCurrentCurrency(
                                        _profile.CostTotalInternalOperations
                                    )}
                                </td>
                            )}
                        </tr>
                        <tr>
                            <td xs="11">
                                Total External Operations / OOP / Third Party Cost
                            </td>
                            {project.CostingProfiles.map(_profile =>
                                <td xs="1">
                                    {getCurrentCurrency(
                                        _profile.CostTotalExternalOperations
                                    )}
                                </td>
                            )}
                        </tr>
                        <tr>
                            <td xs="11">Total Internal Commercial Cost<br></br>
                                <p className="help">{alternativeLabels["CostTotalInternalCommercial"] &&
                                    Object.keys(alternativeLabels["CostTotalInternalCommercial"]).filter(
                                        (al) => profile.ProfileSetting[al]
                                    ).length ? "Estimated using Multiplier" : null}</p></td>

                            {project.CostingProfiles.map(_profile =>
                                <td xs="1">
                                    {getCurrentCurrency(
                                        _profile.CostTotalInternalCommercial
                                    )}
                                </td>
                            )}
                        </tr>
                        <tr>
                            <td xs="11">Total External Commercial Cost</td>

                            {project.CostingProfiles.map(_profile =>
                                <td xs="1">
                                    {getCurrentCurrency(
                                        _profile.CostTotalExternalCommercial
                                    )}
                                </td>
                            )}
                        </tr>
                        <tr>
                            <td xs="11">MCP Local Margin cost</td>
                            {project.CostingProfiles.map(_profile =>
                                <td xs="1">
                                    {getCurrentCurrency(_profile.CostTotalMCPMargin)}
                                </td>
                            )}
                        </tr>
                        <tr>
                            <td xs="11">
                                Overheads{" "}
                                {profile.ProfileSetting?.PercentOverhead * 100}%
                            </td>
                            {project.CostingProfiles.map(_profile =>
                                <td xs="1">
                                    {getCurrentCurrency(_profile.Overheads)}
                                </td>
                            )}
                        </tr>
                        <tr>
                            <td xs="11">
                                Markup to get{" "}
                                {profile.ProfileSetting
                                    ? profile.ProfileSetting.UsesOOPMarkUp
                                        ? profile.ProfileSetting
                                            .TargetPercentOOPMarkUp * 100
                                        : profile.ProfileSetting
                                            .TargetPercentContributionMargin * 100
                                    : ""}
                                %{" "}
                                {profile.ProfileSetting?.UsesOOPMarkUp
                                    ? "OOP"
                                    : "Contribution Margin"}
                            </td>
                            {project.CostingProfiles.map(_profile =>
                                <td xs="1">{getCurrentCurrency(_profile.Markup)}</td>
                            )}
                        </tr>
                        {project.CostingProfiles.map(_profile =>
                            <>
                                {_profile.ProfileSetting &&
                                    _profile.ProfileSetting.ShowMarginPercentage ? (
                                    <tr>
                                        <td xs="11">Margin%</td>
                                        <td xs="1">
                                            {_profile.MarginPercent !== null
                                                ? `${_profile.MarginPercent}%`
                                                : "-"}
                                        </td>
                                    </tr>
                                ) : null}
                            </>
                        )}
                    </tbody>
                    <tfoot style={{ borderTop: "2px solid #dee2e6" }}>
                        <th xs="11">Minimum Recommended Price to Client</th>

                        {project.CostingProfiles.map(_profile =>
                            <th xs="1">
                                {getCurrentCurrency(_profile.RecommendedPrice)}
                            </th>
                        )}
                    </tfoot>
                </Table>
            </div>

        </>
    )
}

export default ProfitabilityOverview
