import React, {useState} from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { getLabel, getSingleOptions } from "../utils/codeLabels";
import { toggleSidebar } from "../redux/actions/sidebarActions";
import * as userActions from "../redux/actions/userActions";
import { useHistory } from "react-router-dom";
import { debounce } from "throttle-debounce";
import ApprovalDelegate from "./ApprovalDelegate";
// import "../src/assets/scss/5-vendor/_support.scss";
import {
  Row,
  Col,
  Collapse,
  Navbar,
  Button,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  Input,
} from "reactstrap";
import { User } from "react-feather";

import avatar1 from "../assets/img/avatars/nielsen-logo.png";
import SelectWrapper from "./SelectWrapper/SelectWrapper";
import { setNewNotification } from "../redux/actions/notificationActions";
import { Badge as NewBadge, IconButton } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";

const NavbarComponent = (props) => {
  const userRecord = JSON.parse(localStorage.getItem("userRecord"));
  const [isApprovalModalOpen, handleApprovalModal] = useState(false);
  const history = useHistory();
  const handleLogOut = () => {
    props.onLogOut();
    history.push("/auth/login");
  };
  
  let { searchCharactors } = props;
  const searchBy = [
    { Code: "projectId", Label: "Project ID" },
    { Code: "projectName", Label: "Project Name" },
    { Code: "waveName", Label: "Wave Name" },
    { Code: "programmer", Label: "Programmer" },
    { Code: "projectmanager", Label: "Project Manager" },
    { Code: "proposalOwner", Label: "Project Owner" },
  ];

  const dispatch = useDispatch();
  const hasNewNotification = useSelector((state) => state.notificationReducer.NewNotification);
  const message = useSelector((state) => state.notificationReducer.Message);

  const handleClick = () => {
    // localStorage.removeItem("isAlertClosed");
    // props.setIsVisible(true);
    dispatch(setNewNotification(true));
  };


  return (
    <>
      {isApprovalModalOpen && <ApprovalDelegate isApprovalModal={isApprovalModalOpen} setApprovalModal = {handleApprovalModal}/>}
      <Navbar color="black" light expand fixed="top" sticky="top">
        <span
          className="sidebar-toggle d-flex mr-2"
          onClick={() => {
            props.onToggleSideBar();
          }}
        >
          <i className="hamburger align-self-center" />
        </span>
        <span
          className="h5-projectname"
        >
          {props.headerTitle}
        </span>

        <Collapse navbar>
          <Nav className="ml-auto" navbar>
            <InputGroup>
              <SelectWrapper
                type="select"
                id="exampleCustomSelect"
                name="customSelect"
                className="width-150"
                defaultValue={searchBy[0].Code}
                onChange={(e) => props.onSearchBarTypeChange(e.target.value)}
              >
                {searchBy.map((opt, index) => {
                  return (
                    <option key={index} value={opt.Code}>
                      {opt.Label}
                    </option>
                  );
                })}
              </SelectWrapper>
              <Input
                type="text"
                placeholder="Search projects..."
                aria-label="Search"
                className="mr-sm-2"
                value={searchCharactors}
                onChange={(e) => props.onSearchBarValueChange(e.target.value)}
                onKeyUp={() => props.handleSearch()}
              />
            </InputGroup>
          </Nav>
        </Collapse>
        <Button
          className="header-but"
          onClick={() => {
            props.handleOpen(true);
          }}
        >
          Advanced Search
        </Button>
        {props.clear && (
          <Button
            className="header-but"
            style={{ margin: "10px" }}
            onClick={() => {
              props.handleClear();
            }}
          >
            Clear Filter
          </Button>
        )}
        <Collapse navbar>
          <Nav className="ml-auto" navbar>
            <IconButton color="inherit" onClick={handleClick}>
              <NewBadge
                color="secondary"
                variant={hasNewNotification || message ? "dot" : "standard"}
                invisible={!message}
              >
                <FontAwesomeIcon
                  className="ic-hover pointer"
                  icon={faBell}
                  color="white"
                  title="Alert"
                  size="lg"
                />
              </NewBadge>
            </IconButton>
            <UncontrolledDropdown nav inNavbar>
              <span className="d-none d-sm-inline-block">
                <DropdownToggle nav caret>
                  {/* <img
                    src={avatar1}
                    className="avatar img-fluid rounded-circle mr-1"
                    alt="User Profile Picture"
                  /> */}
                  <span className="text-dark">
                    {props.userRecord.FirstName} {props.userRecord.LastName}
                  </span>
                </DropdownToggle>
                <a style={{marginLeft :"10px"}}
                  className="select-link text-white support"
                  onClick={() => window.open(process.env.REACT_APP_SUPPORT_FORM)}
                >
                  Need Support?
                </a>
              </span>
              <DropdownMenu right>
                { userRecord.IsCostingApprover && <><DropdownItem 
                onClick={e => handleApprovalModal(!isApprovalModalOpen)}>
                  User Profile
                </DropdownItem>
                
                <DropdownItem divider /></> }

                <DropdownItem
                  onClick={(e) => {
                    handleLogOut();
                  }}
                >
                  Sign out
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
    </>
  );
};

const mapStateToProps = (state) => ({
  app: state.app,
  userRecord: state.user.userRecord,
  codeLabels: state.codeLabels,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onLogOut: () => {
      dispatch(userActions.logout());
    },
    onToggleSideBar: () => {
      dispatch(toggleSidebar());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavbarComponent);
