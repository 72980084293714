import { faCheck, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import axios from "../../../axios-interceptor";
import { useEffect } from "react";
import { toastr } from "react-redux-toastr";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  Row,
} from "reactstrap";
import logo from "../../../assets/img/avatars/modal-logo.png";
import SelectWrapper from "../../../components/SelectWrapper/SelectWrapper";


function AdvanceFilterModel({isOpen,handleOpen,cancelOpen,radioList,projectList,handleRadio,length, projectStatus,hanldeProjectStatus,
  selectedStatus,
  hanldeCountries,
  countries,
  selectedCountries,
  verticals,
  hanldeVerticals,
  selectedVerticals,
  businessUnits,
  selectedBusinessUnits,
  hanldeBusinessUnits,
  handleAdvancedFilter,
  crName,
  setCrName,
  selected,
  setSelected,
  setGetCriteria,
  getCriteria,
  splitCriteria,
  handleDropdownSelection,
  handleStartDate,
  handleEndDate,
  startDate,
  endDate,
  setIsSavedCriteria
}) {
  //Save Filter start
  const [showSave, setShowsave] = useState(false);
  const [popDelete, setPopDelete] = useState(false);
  const [criteriaName, setCriteriaName] = useState("");
  const [saveValue, setSaveValue] = useState(null);
  const [date, setDate] = useState(new Date());
  // const [startDate, setStartDate] = useState();
  // const [endDate, setEndDate] = useState();

  // const handleStartDate = (startDate) => {
  //   setStartDate(new Date(startDate));
  //   console.log(startDate)
  
  // };

  // const handleEndDate = (endDate) => {
  //   setEndDate(new Date(endDate));
  //   console.log(endDate)

  // };

  
  
  /**
   * Manage condition for duplicate criteria
   */
  const handleSave = (e) => {   
    setSaveValue(e)
    let isDuplicate = false;
    getCriteria.forEach(e => {
        if(isEqual(e.CriteriaData.CommissioningCountry,selectedCountries) && 
        isEqual(e.CriteriaData.BussinessUnits,selectedBusinessUnits) &&
        isEqual(e.CriteriaData.ProjectStatus,selectedStatus) &&
        isEqual(e.CriteriaData.Verticals,selectedVerticals) && 
        isEqual(e.CriteriaData.startDate,startDate) &&
        isEqual(e.CriteriaData.endDate,endDate) &&
        e.CriteriaData.hasOwnProperty(projectList)) {
            isDuplicate = true;
            return ;
        }
    })

    if(isDuplicate) {
        toastr.error("This creteria is already present");
        setShowsave(false);
        return;
    }else{
        setShowsave(true);
    }
    setCriteriaName("");
  };


  const inputName = (event) => {
    setCriteriaName(event.target.value);
  };

  const userRecord = JSON.parse(localStorage.getItem("userRecord"));
  const confirmDelete = () => {
    deleteCriteria();

    setPopDelete(!popDelete);
    toastr.error(
      `${getCriteria
        .filter((i) => i.id == selected)
        .map((e) => e.CriteriaName)
        .flat()} successfully deleted`
    );
    getFilter();
  };

  const handleSubmit = (a) => {
    setShowsave(!showSave);

    if (!criteriaName.trim()) {
      toastr.error("Criteria name is empty");
      setShowsave(true);
      return;
    }
    if (getCriteria.findIndex((e) => e.CriteriaName === (projectCriteria + " - " + criteriaName)) != -1) {
      toastr.error(`${projectCriteria + " - " + criteriaName} already exists`);
      setShowsave(true);
      return;
    }
    

    const criteriaInfo = {
      Source: "Dashboard",
      CriteriaName: projectCriteria + " - " + criteriaName,
      UserId: userRecord.id,
      CriteriaData: {
        ProjectStatus: selectedStatus?.length > 0 ? selectedStatus : [],
        Verticals: selectedVerticals?.length > 0 ? selectedVerticals : [],
        CommissioningCountry: selectedCountries?.length > 0 ? selectedCountries : [],
        BussinessUnits: selectedBusinessUnits?.length > 0 ? selectedBusinessUnits : [],
        [projectList] : true,
        startDate : startDate,
        endDate : endDate
      },
    };
  
    axios
      .post("/filterCriteria", criteriaInfo, {})
      .then((res) => {
        setSelected(res.data?.data?.id)
        getFilter(res.data?.data?.id);
      })
      .catch((err) => {});
      setIsSavedCriteria(true)
    toastr.success(`${projectCriteria + " - " + criteriaName} successfully saved`);
   
  };

  function isEqual(a, b){
    return a?.sort()?.join() == b?.sort().join();
  }

  //save filter end
  const projectCriteria=radioList?.filter(e=>e.id==projectList)?.map(a=>a.name)

  useEffect(() => {
    axios
      .get(`/filterCriteria/details?UserId=${userRecord.id}&Source=Dashboard`)
      .then((res) => {
        setGetCriteria(res.data.data);
      })
      .catch((err) => {});
  }, []);

  const getFilter = (criteriaId) => {
    axios
      .get(`/filterCriteria/details?UserId=${userRecord.id}&Source=Dashboard`)
      .then((res) => {
        setGetCriteria(res.data.data);
        if(criteriaId) {
          handleAdvancedFilter(saveValue, 'saveCriteria', criteriaId, res.data.data)
        }
      })
      .catch((err) => {});

  };

  const deleteCriteria = () => {
    axios
      .delete(`/filterCriteria?id=${selected}`)
      .then((res) => {
        // console.log(res.data.data, "response delete");
      })
      .catch((err) => {});
  };

  const handleDeleteCr = () => {
    // deleteCriteria()
    // getFilter()
    setPopDelete((e) => !e.popDelete);
  };

  return (
    <>
      <Modal isOpen={isOpen} toggle={() => handleOpen()} size="xl">
        <ModalHeader toggle={() => cancelOpen()}>
          {/* <img src={logo} className="modallogo" /> */}
        </ModalHeader>
        <Row className="m-0 p-2 col-12">
          <span className="modaltitle p-0 col-8 m-0">Refine Search</span>
        </Row>
        <ModalBody id="scrollModalBody">
          <Row className="col-12">
            <div className="col-md-12 projectType">
              {radioList.map((value, key) => (
                <Label key={key} className="col-md-3" check>
                  <Input
                    type="radio"
                    checked={projectList === value.id}
                    onChange={() => {
                      handleRadio(value.id);
                    }}
                  />
                  {value.name}
                </Label>
              ))}
            </div>
            
            {projectList === "myDefaultCriteria" && (
              <>
              
                <div className="col-12">
                  {/* <select
                    className="react-select-container col-12 p-2 m-0"
                    value={selected}
                    onChange={(e) => handleDropdownSelection(e.target.value)}
                  >
                    <option value={"Select"}>Select</option>
                    {getCriteria.map((e) => (
                      <option value={e.id} key={e.CriteriaName}>
                        {e.CriteriaName}
                      </option>
                    ))}
                  </select> */}
                  <SelectWrapper
                    type="select"
                    id="defaultCriteria"
                    name="defaultCriteria"
                    value={selected}
                    placeholder="Select Criteria Name"
                    onChange={(e) => handleDropdownSelection(e.target.value)}
                  >
                    <option value="">Select Criteria Name</option>
                    {getCriteria.map((e) => {
                      return (
                        <option value={e.id} key={e.CriteriaName}>
                        {e.CriteriaName}
                      </option>
                      );
                    })}
                  </SelectWrapper>
                  {/* <div className=" col-1 p-2 m-0 ">
                    {" "}
                    <FontAwesomeIcon
                      icon={faTrash}
                      onClick={handleDeleteCr}
                      disabled={!selected || selected == "Select"}
                    />
                  </div> */}

                  {/* <Button
                      className=" col-2 p-2 m-2 d-none"
                      color="primary"
                      onClick={handleDeleteCr}
                      disabled={!selected || selected == "Select"}
                    >
                      Delete Criteria
                    </Button>{" "} */}
                    </div>
                </>
              
            )}
          </Row>
          <Table borderless id="advancedSearch">
            <thead>
              <tr>
                <th>PROJECT STATUS</th>
                <th>COMMISSIONING COUNTRY</th>
                <th>VERTICALS</th>
                <th>BUSINESS UNITS</th>
                <th>COMMISSIONED DATE</th>
              </tr>
            </thead>
            <tbody>
              {[...Array(length).keys()].map((index) => (
                <tr key={index}>
                  {projectStatus?.map(
                    (status, key) =>
                      key === index &&
                      projectStatus?.length > index && (
                        status.Label !=="Archived"?
                        <td
                          key={key}
                          onClick={() => hanldeProjectStatus(status.Code)}
                          className={
                            selectedStatus?.includes(status.Code)
                              ? "selected-column"
                              : selectedStatus?.includes(0)
                              ? "unselectable"
                              : "table-column"
                          }
                        >
                          {selectedStatus?.includes(status.Code) && (
                            <span className="mr-1">
                              <FontAwesomeIcon icon={faCheck} fixedWidth />
                            </span>
                          )}
                          {status.Label}
                        </td>:<td></td>
                      )
                  )}
                  {index === projectStatus?.length && (
                    <td
                      onClick={() => hanldeProjectStatus(0)}
                      className={
                        selectedStatus?.includes(0)
                          ? "selected-column"
                          : selectedStatus?.length > 0 &&
                            !selectedStatus?.includes(0)
                          ? "unselectable"
                          : "table-column"
                      }
                    >
                      {selectedStatus?.includes(0) && (
                        <span className="mr-1">
                          <FontAwesomeIcon icon={faCheck} fixedWidth />
                        </span>
                      )}
                      Ready for Commission{" "}
                      <FontAwesomeIcon
                        className="info"
                        icon={faInfoCircle}
                        title="Project ready for commissiong"
                      />
                    </td>
                  )}
                  {index > projectStatus?.length && <td></td>}
                  {countries
                    .sort((a, b) => a.Label.localeCompare(b.Label))
                    .map(
                      (country, key) =>
                        key === index &&
                        countries?.length > index && (
                          <td
                            key={key}
                            onClick={() => hanldeCountries(country.Code)}
                            className={
                              selectedCountries?.includes(country.Code)
                                ? "selected-column"
                                : "table-column"
                            }
                          >
                            {selectedCountries?.includes(country.Code) && (
                              <span className="mr-1">
                                <FontAwesomeIcon icon={faCheck} fixedWidth />
                              </span>
                            )}
                            {country.Label}
                          </td>
                        )
                    )}
                  {index >= countries?.length && <td></td>}
                  {verticals.map(
                    (vertical, key) =>
                      key === index &&
                      verticals?.length > index && (
                        <td
                          key={key}
                          onClick={() => hanldeVerticals(vertical.Code)}
                          className={
                            selectedVerticals?.includes(vertical.Code)
                              ? "selected-column"
                              : "table-column"
                          }
                        >
                          {selectedVerticals?.includes(vertical.Code) && (
                            <span className="mr-1">
                              <FontAwesomeIcon icon={faCheck} fixedWidth />
                            </span>
                          )}
                          {vertical.Label}
                        </td>
                      )
                  )}
                  {index >= verticals?.length && <td></td>}
                  {businessUnits.map(
                    (bu, key) =>
                      key === index &&
                      businessUnits?.length > index && (
                        <td
                          key={key}
                          onClick={() => hanldeBusinessUnits(bu.Code)}
                          className={
                            selectedBusinessUnits?.includes(bu.Code)
                              ? "selected-column"
                              : "table-column"
                          }
                        >
                          {selectedBusinessUnits?.includes(bu.Code) && (
                            <span className="mr-1">
                              <FontAwesomeIcon icon={faCheck} fixedWidth />
                            </span>
                          )}
                          {bu.Label}
                        </td>
                      )
                  )}
                  {index >= businessUnits?.length && <td></td>}
                  {index ==0 && <td> From Date <DatePicker
                              // locale="en-GB"
                              className="form-control"
                              selected={startDate}

                              // disabled={disableDatePicker(wb.value)}
                              onChange={(e) =>
                                handleStartDate(e)
                              }
                              dateFormat="dd-MM-yyyy"
                              placeholderText="dd-mm-yyyy"
                            /></td>}
                             {index ==1 && <td> To  Date <br></br> <DatePicker
                              // locale="en-GB"
                              className="form-control"
                              selected={endDate}

                              // disabled={disableDatePicker(wb.value)}
                              onChange={(e) =>
                                handleEndDate(e)
                              }
                              dateFormat="dd-MM-yyyy"
                              placeholderText="dd-mm-yyyy"
                            /></td>}
                </tr>
              ))}
            </tbody>
          </Table>
          <div>
            <b>
              Note: <i>Ready for Commission: </i>
            </b>{" "}
            Displays the projects with status Proposal/Negotiation and
            Probability is 100.
            {/* <CriteriaFilter/> */}
          </div>
        </ModalBody>

        <ModalFooter>
          <Button
            color="primary"
            onClick={(e) => {
              handleAdvancedFilter(e);
            }}
            disabled={
              projectList == "myDefaultCriteria" && !selected
            }
          >
            Filter
          </Button>{" "}
          <Button
            color="primary"
            onClick={(e) => {
              handleSave(e);
            }}
            disabled={
              (selectedCountries?.length === 0 &&
                selectedBusinessUnits?.length === 0 &&
                selectedStatus?.length === 0 &&
                selectedVerticals?.length === 0) || (
                  !selectedCountries &&
                  !selectedBusinessUnits &&
                  !selectedStatus && 
                  !selectedVerticals 
                ) || 
              projectList == "myDefaultCriteria"
            }
          >
            Save and Filter
          </Button>{" "}
          <Button color="secondary" onClick={() => cancelOpen()}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        centered={true}
        isOpen={showSave}
        size="md"
        toggle={() => {
          setShowsave(!showSave);
        }}
      >
        <ModalHeader
          toggle={() => {
            setShowsave(!showSave);
            setCriteriaName("");
          }}
        >
          {/* <img src={logo} className="modallogo" /> */}
        </ModalHeader>

        <ModalBody className="m-0 p-0 col-12">
          <Row className="m-0 p-0 col-12">
            <strong className="m-0 p-0 mb-2 col-12">
              Enter Criteria Name:
            </strong>
           
             <span className="input-group-text col-3 mb-2">{projectCriteria + " - "}</span>
            <input
              className="m-0 p-0 col-9 mb-2"
              type="text"
              value={criteriaName}
              onChange={inputName}
              placeholder="Enter Criteria Name"
              maxLength={30}
              minLength={1}
            />
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit}>
            Save
          </Button>{" "}
          <Button
            color="secondary"
            onClick={() => {
              setShowsave(!showSave);
              setCriteriaName("");
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        centered={true}
        isOpen={popDelete}
        size="md"
        toggle={() => {
          setPopDelete(!popDelete);
        }}
      >
        <ModalHeader
          toggle={() => {
            setPopDelete(!popDelete);
            setCriteriaName("");
          }}
        >
          {/* <img src={logo} className="modallogo" /> */}
        </ModalHeader>

        <ModalBody className="m-0 p-0 col-12">
          <Row className="m-0 p-0 col-12">
            <p>
              Are you sure to delete{" "}
              <strong>
                {" "}
                {getCriteria
                  .filter((i) => i.id == selected)
                  .map((e) => e.CriteriaName)
                  .flat()}
              </strong>
            </p>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={confirmDelete}>
            Confirm
          </Button>{" "}
          <Button
            color="secondary"
            onClick={() => {
              setPopDelete(!popDelete);
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default AdvanceFilterModel;
