import React from "react";
import { HashLink } from "react-router-hash-link";
import { Badge } from "reactstrap";

const RecordsBadge = (props) => {
    return (
      <div className={`d-flex justify-content-center ${props.back_top ? "back-button" : ""}`} >
        <h6>
          <Badge className="bg-black rounded-0" color="info">
            <HashLink color="secondary"
              style={{ color: 'white' }}
              smooth to={'#'}>
              Back to top ↑
            </HashLink>
          </Badge>
        </h6>
      </div>
    );
};

export default RecordsBadge;
