import React from "react";

function EditFill({color,width,height}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          fill={color || "#000"}
          d="M3.15 14.437L0 24l9.564-3.151L23.826 6.586c.59-.59-.366-2.503-2.138-4.274C19.916.54 18.005-.416 17.414.175L3.15 14.437z"
        ></path>
      </g>
    </svg>
  );
}

export default EditFill;