import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as proposalActions from '../../../redux/actions/proposal/Actions';
import * as currentProjectActions from "../../../redux/actions/currentProjectActions";
import { getLabel } from "../../../utils/codeLabels";
import * as appActions from "../../../redux/actions/appActions";
import {
	Card,
	CardBody,
	Container,
	Row,
	Col,
	Label,
} from "reactstrap";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import { MinusCircle, PlusCircle } from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faPen,
	faCalendarAlt,
	faFolder,
	faInfoCircle,
	faThumbsUp,
	faThumbsDown,
	faHourglassHalf,
	faExclamationCircle,
	faCogs
} from "@fortawesome/free-solid-svg-icons";
import { faFolder as faFolderO } from "@fortawesome/free-regular-svg-icons";
import _ from "lodash";
import moment from "moment";
import Spinner from "../../../components/Spinner";
import { toastr } from "react-redux-toastr";
import { getCosting, getSummaryCosting } from "../../../redux/actions/currentCostingActions";
import * as  sheetCall from "../../../utils/msSheetAPiCall";
import EwnNotesModal from "./components/modals/EwnNotesModal";
import { NotesFields } from "./constants/noteConstants";
import EditFill from "../../../assets/icons/Connected-System-Icons/Tools/Edit-fill";
import Folderfill from "../../../assets/icons/Connected-System-Icons/Objects/Folder-fill";
import Folder from "../../../assets/icons/Connected-System-Icons/Objects/Folder";
import Info from "../../../assets/icons/Connected-System-Icons/Navigation/Info";
import SelectWrapper from "../../../components/SelectWrapper/SelectWrapper";
import { useHistory } from "react-router-dom";
const SchedulesData = (props) => {
	const [allWaves, setAllWaves] = useState([]);
	const [current, setcurrent] = useState(_.head(props.wavespecs));
	const changeWave = (id) => {
		setcurrent(_.head(allWaves.filter((aw) => aw.id == id)));
	};
	const [methCountrySet, setMethCountrySet] = useState({})

	useEffect(() => {
		if (!Object.keys(methCountrySet).length && !allWaves.length && props.wavespecs
			&& props.wavespecs.length
			&& props.profile && props.profile.CountrySpecs) {
			let methBreak = {}
			props.profile.CountrySpecs.map(cs => {
				cs.MethodologySpecs?.map(ms => {
					if (!methBreak[ms.id]) {
						methBreak[ms.id] = { country: cs.CountryName, methlabel: ms.Label }
					}
				})
			})
			setMethCountrySet(methBreak)
			let reqWaves = []
			reqWaves = props.wavespecs.map(ws => {
				ws.MethodologyLabel = methBreak[ws.MethodologySpecId]?.methlabel
				ws.CountryName = methBreak[ws.MethodologySpecId]?.country
				return { ...ws }
			})
			setAllWaves(reqWaves)
		}
	}, [])
	const [isEwnNotesModal, setEwnModal] = useState(false);
	const [currentEwn, setCurrentEwn] = useState({});

	return (
		<div>
			<Row>
				<Col>
					<h5>Timeline</h5>
				</Col>
			</Row>
			{current ? (
				<>
					<Row className="mb-2">
						<Col>Wave</Col>
						<Col>
							<SelectWrapper
								defaultValue={current?.id}
								onChange={(e) => changeWave(e.target.value)}
							>
								{allWaves?.map((ws) => (
									<option value={ws.id}>
										#{ws.WaveNumber} {ws.WaveName} {ws.MethodologyLabel ? `- ${ws.MethodologyLabel}` : ""} {props.profile?.IsMultiCountry ? `- ${ws.CountryName}` : ""}
									</option>
								))}
							</SelectWrapper>
						</Col>
					</Row>{" "}
					<Row>
						{current.ProjectBoxId ? (
							<Col>
								<a
									href={`https://docs.google.com/spreadsheets/d/${current.ProjectBoxId}`}
									target="_blank"
									className={(props.PriceChange ==4 ) ?"no-actions":"small ewn-links"}>
									Open Project Box
								</a>
							</Col>
						) : null}
						<Col>
							<a
								className={(props.PriceChange ==4 ) ?"no-actions":"small ewn-link"}
								onClick={() => {
									setEwnModal(true);
									setCurrentEwn(current);
								}}>
								View EWN Notes
							</a>
						</Col>
					</Row>
					<Row className="mb-2">
						<Col>Commissioned Date</Col>
						<Col>
							{current.DateWaveCommissioned
								? moment(current.DateWaveCommissioned).format("YYYY-MM-DD")
								: "-"}
						</Col>
					</Row>
					<Row className="mb-2">
						{dateRender('Final Questionnaire Due', current.DateFinalQuestionnaire, current.DateFinalQuestionnaireNA)}
					</Row>
					<Row className="mb-2">
						{dateRender('Translations Due', current.DateTranslations, current.DateTranslationsNA)}
					</Row>
					<Row className="mb-2">
						{dateRender('Field Start', current.DateFieldStart, current.DateFieldworkNA)}
					</Row>
					<Row className="mb-2">
						{dateRender('Field End', current.DateFieldEnd, current.DateFieldworkNA)}
					</Row>
					<Row className="mb-2">
						{dateRender('Data Processing Due', current.DateDataProcessing, current.DateDataProcessingNA)}
					</Row>
					<Row className="mb-2">
						{dateRender('Charting Due', current.DateCharts, current.DateChartsNA)}
					</Row>
					<Row className="mb-2">
						{dateRender('Dashboard Due', current.DateDashboards, current.DateDashboardsNA)}
					</Row>
					<Row className="mb-2">
						{dateRender('Final Reports Due', current.DateFinalReport, current.DateFinalReportNA)}
					</Row>
					<Row className="mb-2">
						<Col>Programmer Assigned</Col>{" "}
						<Col>
							{current.DeliverySpec && current.DeliverySpec.ProgrammerAssigned
								? current.DeliverySpec.ProgrammerAssigned
								: "-"}
						</Col>
					</Row>
					<Row className="mb-2">
						<Col>Unique Wave Number</Col>{" "}
						<Col>
							{current.DeliverySpec &&
								current.DeliverySpec.ProjectDeliveryNumber
								? current.DeliverySpec.ProjectDeliveryNumber
								: "-"}
						</Col>
					</Row>
					<EwnNotesModal isOpen={isEwnNotesModal} NotesFields={NotesFields} currentEwn={currentEwn} toggle={() => setEwnModal(false)} />
				</>
			) : null}
		</div>
	);
};
const dateRender = (label, date, isNA) => {
	return (
		<>
			<Col>{label}</Col>
			<Col>
				{date
					? moment(date).format("YYYY-MM-DD")
					: isNA
						? "Not Required"
						: "-"}
			</Col>
		</>
	);
}
const rowFields = ["Contribution Margin", "Out of Pocket", "Net Revenue", "Total Project Value", "Total Internal Cost", "Total Third Party Cost"];
const ExpandableRowsTable = ({ tableData, tableColumns }) => {
	const dispatch = useDispatch();
	const history = useHistory()
	const app = useSelector(({ app }) => app);
	const currentContactLoading = useSelector(({ currentProject }) => currentProject.currentContactLoading);
	const getTotalWaves = (finalProfile) => {
		let groupedWaves = _.groupBy(finalProfile.WaveSpecs, w => w.MethodologySpecId)
		let numberOfWaves = _.max(Object.keys(groupedWaves).map(gw => groupedWaves[gw]?.length))
		return numberOfWaves
	}
	const projectDetails = (row) => {
		let finalProfile = null;
		const validStatus = ["3", "9", "4", "5", "98"];
		if (_.includes(validStatus, row.ProjectStatus))
			finalProfile = _.head(
				row.CostingProfiles.filter((cp) => cp.ProfileStatus == "6")
			);
		return (
			<Col lg="3" md="3" sm="12" xs="12" className="border-right">
				<Row className="mb-2">
					<Col>
						<h5>Project Details</h5>
					</Col>
				</Row>
				<Row className="mb-2">
					<Col xs="7">Office</Col>
					<Col>{getLabel("OfficeOptions", row.CommissioningOffice)}</Col>
				</Row>
				<Row className="mb-2">
					<Col xs="7">Business Unit</Col>
					<Col xs="5">{getLabel("BusinessUnitOptions", row.BusinessUnit)}</Col>
				</Row>
				<Row className="mb-2">
					<Col xs="7">Vertical</Col>
					<Col>{getLabel("VerticalOptions", row.IndustryVertical)}</Col>
				</Row>
				<Row className="mb-2">
					<Col xs="7">Syndicated Project</Col>
					<Col>{row.IsSyndicated ? "Yes" : "No"}</Col>
				</Row>
				<Row className="mb-2">
					<Col xs="7">Tracker</Col>
					<Col>{finalProfile ? (finalProfile.IsTracker ? "Yes" : "No") : "-"}</Col>
				</Row>
				<Row className="mb-2">
					<Col xs="7">Total Waves</Col>
					<Col>{finalProfile ? getTotalWaves(finalProfile) : "-"}</Col>
				</Row>
				<Row className="mb-2">
					<Col xs="7">Tracking Frequency</Col>
					<Col>
						{finalProfile && finalProfile.TrackingFrequency
							?
							finalProfile.TrackingFrequency : "-"}
					</Col>
				</Row>
				<Row className="mb-2">
					<Col xs="7">Fielding Countries</Col>
					<Col>
						{finalProfile && finalProfile.FieldingCountries
							? getLabel(
								"FieldingCountriesOptions",
								finalProfile.FieldingCountries) : "-"}
					</Col>
				</Row>
				<Row>
					<Col xs="7">Methodologies</Col>
					<Col xs="5">
						<ul style={{ paddingInlineStart: "0" }}>
							{finalProfile && finalProfile.Methodology
								? finalProfile.Methodology.split(",").map((method, index) => {
									return (
										<li key={index} style={{ listStyle: "none" }}>
											{getLabel("MethodologyOptions", method)}
										</li>
									);
								}) : null}
						</ul>
					</Col>
				</Row>
				<Row>
					<Col xs="7">Sub-Methodologies</Col>
					<Col xs="5">
						<ul style={{ paddingInlineStart: "0" }}>
							{finalProfile && finalProfile.SubMethodology
								? finalProfile.SubMethodology.split(",").map(
									(method, index) => {
										return (
											<li key={index} style={{ listStyle: "none" }}>
												{getLabel("SubMethodologyOptions", method)}
											</li>
										);
									}) : "-"}
						</ul>
					</Col>
				</Row>
				<Row>
					<Col xs="7">Other Project Team Contacts</Col>
					<Col xs="5">
						{row.OtherProjectTeamContacts && row.OtherProjectTeamContacts.length
							? row.OtherProjectTeamContacts.map((opt, index) => (
								<li className="no-list-style" key={index}>
									{opt.value
										.toLowerCase()
										.split("@")[0]
										.split(".")
										.map((word) => {
											return word.replace(word[0], word[0].toUpperCase());
										})
										.join(" ")}
								</li>)) : "Not Available"}
					</Col>
				</Row>
			</Col>
		);
	};
	const salesforceColumn = (row) => {
		const syncClientDetails = (contactDetail) => {
			if (contactDetail.updateAt) {
				let lastUpdated = ((new Date(moment().format("YYYY-MM-DD hh:mm:ss")).getTime() - new Date(contactDetail.updateAt).getTime()) / (1000) / 60)
				if (lastUpdated < 1)
					toastr.info("Already Updated", "Contact details are already updated")
				else dispatch(currentProjectActions.syncContactDetails(row.id, contactDetail));
			} else {
				dispatch(currentProjectActions.syncContactDetails(row.id, contactDetail));
			}
		};
		return (
			<Col lg="3" md="3" sm="12" xs="12" className="border-right">
				<Row>
					<Col>
						<h5>Microsoft Dynamics / Client Details</h5>
					</Col>
				</Row>
				{row.ContractDetails
					? row.ContractDetails.map((cd, index) =>
						(app.recordloading && currentContactLoading?.id == cd.id) ? (
							<Row className="justify-content-center mt-5">
								<Spinner size="small" color="#495057" />
							</Row>
						) : (
							<>
								<Row className="mb-2">
									<Col>
										<Label className="small font-weight-bold mb-0">
											Opportunity Details #{index + 1}
										</Label>
									</Col>
									{cd.isSF ? (
										<Col className="text-right">
											<a
												className={row?.PriceChange ==4  ?"no-actions":"update-client-details small"}
												onClick={() => syncClientDetails(cd)}
											>Refresh MSD Opportunity</a>
										</Col>
									) : null}
								</Row>
								<Row className="mb-2">
									<Col xs="4" className="pr-0">
										Name
									</Col>
									<Col xs="8" className="pl-1">
										{cd.OpportunityName}
									</Col>
								</Row>
								<Row className="mb-2">
									<Col xs="4" className="pr-0">
										Account
									</Col>
									<Col xs="8" className="pl-1">
										{cd.AccountName}
									</Col>
								</Row>
								<Row className="mb-2">
									<Col xs="4" className="pr-0">
										Industry
									</Col>
									<Col xs="8" className="pl-1">
										{cd.Industry}
									</Col>
								</Row>
								<Row className="mb-2">
									<Col xs="4" className="pr-0">
										OP Number
									</Col>
									<Col xs="8" className="pl-1">
										{cd.OpportunityNumber}
									</Col>
								</Row>
								<Row className="mb-2">
									<Col xs="4" className="pr-0">
										Stage
									</Col>
									<Col xs="8" className="pl-1">
										{cd.Stage} ({cd.Probability}
										{"%"})
									</Col>
								</Row>
								<Row className="mb-2">
									<Col xs="4" className="pr-0">
										Amount
									</Col>
									<Col xs="8" className="pl-1">
										{cd.Amount} {cd.AmountCurrency}
									</Col>
								</Row>
								<Row className="mb-2">
									<Col xs="4" className="pr-0">
										Start of Delivery
									</Col>
									<Col xs="8" className="pl-1">
										{cd.StartofDelivery
											? cd.StartofDelivery.split("T")[0]
											: "Not available"}
									</Col>
								</Row>
								<Row className="mb-2">
									<Col xs="4" className="pr-0">
										End of Delivery
									</Col>
									<Col xs="8" className="pl-1">
										{cd.EndofDelivery
											? cd.EndofDelivery.split("T")[0]
											: "Not available"}
									</Col>
								</Row>
								<Row className="mb-2">
									<Col xs="4" className="pr-0">
										Close Date
									</Col>
									<Col xs="8" className="pl-1">
										{cd.CloseDate
											? cd.CloseDate?.split("T")[0]
											: "Not available"}
									</Col>
								</Row>
								<hr
									className="mb-0 mt-0"
									style={{ borderStyle: "dotted" }}></hr>
								{cd.opportunityLineItemDetails
									? cd.opportunityLineItemDetails.map((opl, pi) => (
										<>
											<Row>
												<Col>
													<Label className="small font-weight-bold mb-0">
														Product Details #{pi + 1}
													</Label>
												</Col>
											</Row>
											<Row className="small">
												<Col xs="4" className="pr-0">
													WBS Number
												</Col>
												<Col xs="8" className="pl-1">
													{opl.WBSNumber ? opl.WBSNumber : "Not Available"}
												</Col>
											</Row>
											<Row className="small">
												<Col xs="4" className="pr-0">
													Material ID
												</Col>
												<Col xs="8" className="pl-1">
													{opl.MaterialID
														? opl.MaterialID
														: "Not Available"}
												</Col>
											</Row>
											<Row className="small">
												<Col xs="4" className="pr-0">
													Product Description
												</Col>
												<Col xs="8" className="pl-1">
													{opl.ProductDescription
														? opl.ProductDescription
														: "Not Available"}
												</Col>
											</Row>
											<Row className="small">
												<Col xs="4" className="pr-0">
													Sub Brand
												</Col>
												<Col xs="8" className="pl-1">
													{opl.SubBrand ? opl.SubBrand : "Not Available"}
												</Col>
											</Row>
											<Row className="small">
												<Col xs="4" className="pr-0">
													Practice Area
												</Col>
												<Col xs="8" className="pl-1">
													{opl.PracticeArea
														? opl.PracticeArea
														: "Not Available"}
												</Col>
											</Row>
										</>
									)) : "No Products Found"}
								<hr
									className="mt-0 mb-0"
									style={{ borderStyle: "dashed" }}
								></hr>
							</>)) : "No Contract Details Found"}
			</Col>
		);
	};
	const costingOverview = (row) => {
		let finalProfile = null;
		const validStatus = ["3", "9", "4", "5", "98"];
		if (_.includes(validStatus, row.ProjectStatus))
			finalProfile = _.head(
				row.CostingProfiles.filter((cp) => cp.ProfileStatus == "6")
			);
		let cvalues = finalProfile?.CostInputCurrency?.split("-");
		let currencyUnit = _.last(cvalues);
		let countryCode = _.head(cvalues);
		let conversionUnit = _.head(
			finalProfile?.ProfileSetting?.CurrenciesData?.filter(
				(cur) => cur.Code == countryCode && cur.CurrencyUnit == currencyUnit
			)
		)?.ConversionRateToLocal;
		if (!conversionUnit) conversionUnit = 1;
		if (!currencyUnit) currencyUnit = "USD";
		return (
			<Col lg="3" md="3" sm="12" xs="12" className="border-right">
				{!finalProfile ? (
					<>
						<Row>
							<Col>
								<h5>Costing Overview</h5>
							</Col>
						</Row>
						<Row>
							<Col className="p-3">
								<Row className="justify-content-center text-center">
									<FontAwesomeIcon
										icon={faInfoCircle}
										className="text-muted mb-1"
										size="lg"
									/>
								</Row>
								<Row className="justify-content-center text-center">
									<h5>Final Summary Not Available</h5>
								</Row>
								<Row className="justify-content-center text-center">
									Waiting for the project to be Commissioned
								</Row>
							</Col>
						</Row>
					</>
				) : (
					<>
						<Row>
							<Col>
								<h5>Costing Overview</h5>
							</Col>
							{!finalProfile.IsImportedProfile ? (
								<Col>
									<Link className={row.PriceChange <= 3 ?"small":"no-actions"}
										onClick={() => {
											dispatch(
											  getSummaryCosting(
												finalProfile,
												() => {
												  history.push(`/summary/${finalProfile.id}`);
												},
												finalProfile?.IsOPSOOPAdjusted ? false : true
											  )
											)
										  }
										}>
										View Details
									</Link>
								</Col>
							) : null}
						</Row>
						<Row className="mb-2">
							<Col>Profile #{finalProfile.ProfileNumber}</Col>
							<Col>{finalProfile.ProfileName}</Col>
						</Row>
						<Row className="mb-2">
							<Col>Total Project Value</Col>
							<Col>{`${_.round(
								finalProfile.PriceToClient * conversionUnit,
								2
							)} ${currencyUnit}`}</Col>
						</Row>
						<Row className="mb-2">
							<Col>Total Internal Cost</Col>
							<Col>{`${_.round(
								(finalProfile.TotalInternalCosts
									|| (finalProfile.CostTotalInternalCommercial + finalProfile.CostTotalInternalOperations)) * conversionUnit,
								2
							)} ${currencyUnit}`}</Col>
						</Row>
						<Row className="mb-2">
							<Col>Total Third Party Cost</Col>
							<Col>{`${_.round(
								finalProfile.TotalExternalCosts * conversionUnit,
								2
							)} ${currencyUnit}`}</Col>
						</Row>
						<Row className="mb-2">
							<Col>Net Revenue</Col>
							<Col>{_.round(finalProfile.NetRevenuePercent * 100, 2)}%</Col>
						</Row>
						<Row className="mb-2">
							<Col>Out of Pocket</Col>
							<Col>
								{_.round(finalProfile.OutOfPocketCostPercent * 100, 2)}%
							</Col>
						</Row>
						<Row className="mb-2">
							<Col>Contribution Margin</Col>
							<Col>{finalProfile.MarginPercent ? _.round(finalProfile.MarginPercent * 100, 2) + "%" : "-"}</Col>
						</Row>
						{finalProfile.ApprovalDetails &&
							finalProfile.ApprovalDetails.length &&
							finalProfile.ApprovalDetails
								.filter(ad => ad.Order <= finalProfile.ApprovalLevelNeeded).length ? (
							<>
								<Row>
									<Col>
										<strong>Approvals needed</strong>
									</Col>
								</Row>
								{finalProfile.ApprovalDetails.map((ad) => (
									<>
										<Row className="ml-1 mt-2"><strong>{ad.Label}</strong></Row>
										{ad.ApproverContacts?.map(contact =>
											contact.Order <= finalProfile.ApprovalLevelNeeded ? <Row>
												<Col>
													{contact.Approved ? (
														<FontAwesomeIcon
															icon={faThumbsUp}
															className="text-muted align-middle mr-2"
															title="Appproval Granted"
															size="sm"
														/>
													) :
														contact.Denied ? (

															<FontAwesomeIcon
																icon={faThumbsDown}
																title="Appproval Denied"
																className="text-muted align-middle mr-2"
																size="sm"
															/>
														) : (
															<FontAwesomeIcon
																icon={faHourglassHalf}
																title="Appproval Awaited"
																className="text-muted align-middle mr-2"
																size="sm"
															/>

														)}
													{contact.EmailAddress}
													{contact.IsMandatoryApprover ? (

														<FontAwesomeIcon
															icon={faExclamationCircle}
															title="Mandatory Approver for this level."
															className="text-muted align-middle ml-2"
															size="sm"
														/>
													) : null}
												</Col>
											</Row> : null)}
									</>
								))}
							</>
						) : (
							<div className="mt-2">
								<strong>No Approvals were required for this Costing</strong>
							</div>
						)}
					</>
				)}
			</Col>
		);
	};
	const costingOverviewRowRender = (colTitle, finalProfile, conversionUnit, currencyUnit) => {
		return (<><Col>{colTitle}</Col>
			<Col>
				{costingOverviewRowValue(colTitle, finalProfile, conversionUnit, currencyUnit)}
			</Col></>);
	}
	const costingOverviewRowValue = (colTitle, finalProfile, conversionUnit, currencyUnit) => {
		switch (colTitle) {
			case "Contribution Margin":
				return _.round(finalProfile.ContributionMarginPercent * 100, 2) + ' %';
			case "Out of Pocket":
				return _.round(finalProfile.OutOfPocketCostPercent * 100, 2) + ' %';
			case "Net Revenue":
				return _.round(finalProfile.NetRevenuePercent * 100, 2) + ' %';
			case "Total Project Value":
				return `${_.round(
					finalProfile.PriceToClient * conversionUnit,
					2
				)} ${currencyUnit}`;
			case "Total Internal Cost":
				return `${_.round(
					finalProfile.TotalExternalCosts * conversionUnit,
					2
				)} ${currencyUnit}`;
			case "Total Third Party Cost":
				return `${_.round(
					finalProfile.TotalExternalCosts * conversionUnit,
					2
				)} ${currencyUnit}`;
			default:
		}
	}
	const timeline = (row) => {
		let finalProfile = null;
		const validStatus = ["3", "9", "4", "5", "98"];
		if (_.includes(validStatus, row.ProjectStatus))
			finalProfile = _.head(
				row.CostingProfiles.filter((cp) => cp.ProfileStatus == "6")
			);
		let waveSpecs = finalProfile?.WaveSpecs;
		return (
			<Col lg="2" md="2" sm="12" xs="12" className="border-right">
				{waveSpecs && waveSpecs.length ? (
					<SchedulesData wavespecs={waveSpecs} profile={finalProfile} />
				) : (
					<>
						<Row>
							<Col>
								<h5>Timeline</h5>
							</Col>
						</Row>
						<Col className="p-3">
							<Row className="justify-content-center text-center">
								<FontAwesomeIcon
									icon={faInfoCircle}
									className="text-muted mb-1"
									size="lg" />
							</Row>
							<Row className="justify-content-center text-center">
								<h5>Schedule Not Available</h5>
							</Row>
							<Row className="justify-content-center text-center">
								Waiting for the project to be Commissioned
							</Row>
						</Col>
					</>
				)}
			</Col>
		);
	};
	const actions = (row) => {
		let finalProfile = null;
		const validStatus = ["3", "9", "4", "5", "98"];

		if (_.includes(validStatus, row.ProjectStatus))
			finalProfile = _.head(
				row.CostingProfiles.filter((cp) => cp.ProfileStatus == "6")
			);
		return (
			<Col lg="1" md="1" sm="12" xs="12">
				<Row>
					<Col>
						<h5>Actions</h5>
					</Col>
				</Row>
				<Row>
					<Col xs="1">
						<Link
							to={'/vendordashboard/vendor-bidding/' + row.ProjectId +"/"+_.last(row.CostingProfiles)?.id}
							onClick={() =>
								dispatch(appActions.pageLoadStart())
							}
							className={row?.PriceChange ==4?"no-actions": (row?.PriceChange ==3 && (row.ProjectStatus !== "3" || row.ProjectStatus !== "9")) ?  "no-actions" : ""}
							title="Proposal Vendor Bidding"
						>
							<FontAwesomeIcon icon={faPen} className="ic-hover" fixedWidth title="Proposal Vendor Bidding" />
						</Link>
						<Link
							className={row?.PriceChange ==4?"no-actions": (row?.PriceChange ==3 && (row.ProjectStatus !== "3" || row.ProjectStatus !== "9")) ? "no-actions"  : ""}
							to={'/vendordashboard/vendor-bidding-summary/' + row.ProjectId+"/" +_.last(row.CostingProfiles)?.id+ "/" + 1}
							onClick={() =>dispatch(appActions.pageLoadStart())
							}>
							<FontAwesomeIcon icon={faCogs} className="ic-hover" fixedWidth title="View Vendor Cost Summary" />
						</Link>
						
							<FontAwesomeIcon
								icon={faFolder}
								fixedWidth
								className={row?.PriceChange ==4?"no-actions": (row?.PriceChange ==3 && (row.ProjectStatus !== "3" || row.ProjectStatus !== "9")) ? "no-actions" :"ic-hover" }
								title="Open Project Resources Folder"
								onClick={() => {
									dispatch(sheetCall.msSheetAPiCall(row.ProjectResourcesFolderId, false));
								}}
							/>
						
						<Link
							className={row?.PriceChange ==4?"no-actions": (row?.PriceChange ==3 && (row.ProjectStatus !== "3" || row.ProjectStatus !== "9")) ? "no-actions" : ""}>
							<FontAwesomeIcon
								icon={faFolderO}
								fixedWidth
								className="ic-hover"
								title="Open Costings Folder"
								onClick={() => {
									dispatch(sheetCall.msSheetAPiCall(row.CostingsFolderId, false));
								}}
							/>
						</Link>
						{finalProfile ? <Link
							className={row?.PriceChange ==4?"no-actions": (row?.PriceChange ==3 && (row.ProjectStatus !== "3" || row.ProjectStatus !== "9")) ? "no-actions" : ""}
							to={`/schedule/${finalProfile.id}`}
							title="Project Schedule"
							onClick={(e) => {
								dispatch(getCosting(finalProfile.id))
								dispatch(currentProjectActions.getProject(row.ProjectId));
							}
							}>
							<FontAwesomeIcon
								icon={faCalendarAlt}
								className="ic-hover"
								fixedWidth
							/>
						</Link> : null}
					</Col>
				</Row>
			</Col>
		);
	};
	const expandRow = {
		renderer: (row) =>
			app.recordloading && !row.isCostingOverviewLoaded ? (
				<Row className="justify-content-center details-section-loading">
					<Spinner size="small" color="#495057" />
				</Row>
			) : (
				<div>
					<Row>
						{projectDetails(row)}
						{salesforceColumn(row)}
						{costingOverview(row)}
						{timeline(row)}
						{actions(row)}
					</Row>
				</div>
			),
		showExpandColumn: true,
		expandHeaderColumnRenderer: ({ isAnyExpands }) =>
			isAnyExpands ? (
				<MinusCircle width={16} height={16} />
			) : (
				<PlusCircle width={16} height={16} />
			),
		expandColumnRenderer: ({ expanded }) =>
			expanded ? (
				<MinusCircle width={16} height={16} />
			) : (
				<PlusCircle width={16} height={16} />
			),
		onExpand: (row) => {
			let commProfile = null;
			const validStatus = ["3", "9", "4", "5", "98"];
			if (_.includes(validStatus, row.ProjectStatus))
				commProfile = _.head(
					row.CostingProfiles?.filter((cp) => cp.ProfileStatus == "6")
				);
			if (commProfile && !row.isCostingOverviewLoaded) {
				setTimeout(() => {
					dispatch(
						currentProjectActions.getIndividualProject(row.ProjectId, row.id)
					);
				});
			} else if (!commProfile) {
				setTimeout(() => {
					dispatch(currentProjectActions.setOverviewStatus(row));
				});
			}
		},
	};
	return (
		<Card>
			<CardBody className="p-4">
				<BootstrapTable
					classes="sticky-top-table"
					hover
					striped
					bordered={false}
					responsive
					defaultSorted={[{ dataField: "createdAt", order: "desc" }]}
					bootstrap4
					keyField="id"
					data={tableData}
					columns={tableColumns}
					expandRow={expandRow}
					noDataIndication={<b>No data available</b>}
				/>
			</CardBody>
		</Card>
	);
};
const Tables = (props) => {
	return (
		<Container fluid className="p-0">
			<ExpandableRowsTable {...props} />
		</Container>
	);
};
export default Tables;