import * as types from "./ActionTypes";


export const otherCostChange = (indexC, indexM, indexOC, e) => {
    return {
        type: types.OTHER_COST_CHANGED,
        indexC: indexC
        , indexM: indexM
        , indexOC: indexOC
        , e: e
    };
}

export const dataChange = (rowIndex, name, value) => {
    return {
        type: types.DATA_CHANGED,
        rowIndex,
        name,
        value
    };
}

export const clearMessage = () => {
    return {
        type: types.CLEAR_MESSAGE
    };
}

export const fetchProposalsRequest = () => {
    return {
        type: types.FETCH_PROPOSALS_REQUEST
    };
}

export const searchProposalsRequest = () => {
    return {
        type: types.SEARCH_PROPOSALS_REQUEST
    };
}

export const fetchProposalDetailsById = (id, vendorId = null, profileId = null) => {
    return {
        type: types.FETCH_PROPOSAL_DETAILS_REQUEST,
        payload: { id: id, vid: vendorId, pid: profileId }
    };
}

export const fetchProposalWorkOrderDetailsById = (id) => {
    return {
        type: types.FETCH_PROPOSAL_WO_DETAILS_REQUEST,
        payload: { id: id }
    };
}

export const fetchProposalBiddingSummary = (id) => {
    return {
        type: types.FETCH_VENDOR_BIDDING_SUMMARY_REQUEST,
        payload: { id: id }
    };
}

export const fetchProposalVendorDetails = (id, vendorId = null) => {
    return {
        type: types.FETCH_PROPOSAL_VENDOR_DETAILS_REQUEST,
        payload: { id: id, vid: vendorId }
    };
}

export function proposalListRedirectToEdit(link) {
    return {
        type: types.API_PROPOSAL_REDIRECT_TO_EDIT,
        link
    }
}

export function clearProposalState() {
    return {
        type: types.CLEAR_PROPOSAL_STATE
    }
}

export const saveData = (temp, SaveVendorBidding = null, saveasdraft = null,biddingFiles) => {
    return {
        type: types.SAVE_PROPOSAL_REQUEST_DATA,
        payload: { data:temp, files:biddingFiles}
       
    };

}
export const saveProposal = (temp, SaveVendorBidding = null, saveasdraft = null,biddingFiles) => {
    

    let Proposal = JSON.parse(JSON.stringify(temp));
    let tempObj = { "ProposalId": Proposal.ProjectId, "Methodologies": [], SaveVendorBidding: SaveVendorBidding, saveasdraft: saveasdraft };
    if (Proposal.BiddingId)
        tempObj.id = Proposal.BiddingId;
    tempObj.proposal = temp;
    //  tempObj.other_required_documents_filename=temp.other_required_documents_filename
    let LatestCO = Proposal.CostingProfiles[Proposal.CostingProfiles.length - 1]
    let formdata = new FormData()

    //Proposal.CostingProfiles[Proposal?.CostingProfiles?.length - 1].

    // Proposal.CostingProfiles.forEach(profile => {

    LatestCO.CountrySpecs.forEach(country => {

        country.MethodologySpecs.forEach(methodology => {

            if (methodology.Vendors && methodology.Vendors.length > 0) {

                let objMethodology = {
                    "MethodologyId": methodology.id, "QCValidationModeId": (methodology.QCValidationMode ? methodology.QCValidationMode.value : null),
                    "OtherProjectSpecification": methodology.OtherProjectSpecification,
                    "Vendors": [], "Deadline": methodology.Deadline, "VendorCostid": methodology.VendorCostid, "QCValidationMode": methodology.QCValidationMode, "UploadOtherRequiredDocuments": methodology.UploadOtherRequiredDocuments, "OtherProjectSpecification": methodology.OtherProjectSpecification
                };

                if (methodology.BiddingData && methodology.BiddingData.id)
                    objMethodology["id"] = methodology.BiddingData.id;

                tempObj.Methodologies.push(objMethodology);
                methodology.Vendors.forEach(vendor => {

                    let objVendor = {
                        "VendorId": vendor.value, "SelfBidding": vendor.SelfBidding || false, "VendorBidding": {}
                    };

                    if (vendor.id)
                        objVendor["id"] = vendor.id;
                    if (vendor.VendorBiddingId)
                        objVendor["VendorBidding"]["id"] = methodology.VendorCostid;
                    if (vendor.isAcknowledged)
                        objVendor["isAcknowledged"] = methodology.isAcknowledged;
                    if (vendor.isFirst)
                        objVendor["isFirst"] = methodology.isFirst;
                    if (vendor.mail == 1) {
                        objVendor["SendMail"] = true
                    }
                    else {
                        objVendor["SendMail"] = false
                    }
                    if (vendor.mail == 2) {
                        objVendor["SendMailUpdate"] = true
                    }
                    else
                    {
                    objVendor["SendMailUpdate"] = false
                    }
                    // objVendor["VendorBidding"]["TargetMain"] = vendor.TargetMain || methodology.RFQData.sampleSizeMain;
                    // objVendor["VendorBidding"]["ActualMain"] = vendor.ActualMain;
                    // objVendor["VendorBidding"]["CPIMain"] = vendor.CPIMain;
                    // objVendor["VendorBidding"]["FieldWorkDaysCPIMain"] = vendor.FieldWorkDaysCPIMain;
                    // objVendor["VendorBidding"]["TotalCPIMain"] = vendor.TotalCPIMain;
                    // objVendor["VendorBidding"]["SelectedCPIMain"] = (vendor.SelectedCPIMain != false ? true : vendor.SelectedCPIMain);

                    // objVendor["VendorBidding"]["TargetBooster"] = vendor.TargetBooster || methodology.RFQData.sampleSizeBooster;
                    // objVendor["VendorBidding"]["ActualBooster"] = vendor.ActualBooster;
                    // objVendor["VendorBidding"]["CPIBooster"] = vendor.CPIBooster;
                    // objVendor["VendorBidding"]["FieldWorkDaysCPIBooster"] = vendor.FieldWorkDaysCPIBooster;
                    // objVendor["VendorBidding"]["TotalCPIBooster"] = vendor.TotalCPIBooster;
                    // objVendor["VendorBidding"]["SelectedCPIBooster"] = (vendor.SelectedCPIBooster != false ? true : vendor.SelectedCPIBooster);

                    // objVendor["VendorBidding"]["TargetOther"] = vendor.TargetOther || methodology.RFQData.sampleSizeOther;
                    // objVendor["VendorBidding"]["ActualOther"] = vendor.ActualOther;
                    // objVendor["VendorBidding"]["CPIOther"] = vendor.CPIOther;
                    // objVendor["VendorBidding"]["FieldWorkDaysCPIOther"] = vendor.FieldWorkDaysCPIOther;
                    // objVendor["VendorBidding"]["TotalCPIOther"] = vendor.TotalCPIOther;
                    // objVendor["VendorBidding"]["SelectedCPIOther"] = (vendor.SelectedCPIOther != false ? true : vendor.SelectedCPIOther);

                    // objVendor["VendorBidding"]["OtherAllowance"] = vendor.OtherAllowance;
                    // objVendor["VendorBidding"]["TotalGrand"] = vendor.TotalGrand;
                    // objVendor["VendorBidding"]["FieldWorkDays"] = vendor.FieldWorkDays;
                    // objVendor["VendorBidding"]["Comments"] = vendor.Comments;


                    objVendor["VendorBidding"]["TargetMain"] = 100;
                    objVendor["VendorBidding"]["ActualMain"] = 100;
                    objVendor["VendorBidding"]["CPIMain"] = 100;
                    objVendor["VendorBidding"]["FieldWorkDaysCPIMain"] = 100;
                    objVendor["VendorBidding"]["TotalCPIMain"] = 100;
                    objVendor["VendorBidding"]["SelectedCPIMain"] = 100;

                    objVendor["VendorBidding"]["TargetBooster"] = 100;
                    objVendor["VendorBidding"]["ActualBooster"] = 100;
                    objVendor["VendorBidding"]["CPIBooster"] = 100;
                    objVendor["VendorBidding"]["FieldWorkDaysCPIBooster"] = 100;
                    objVendor["VendorBidding"]["TotalCPIBooster"] = 100;
                    objVendor["VendorBidding"]["SelectedCPIBooster"] = 100;

                    objVendor["VendorBidding"]["TargetOther"] = 100;
                    objVendor["VendorBidding"]["ActualOther"] = 100;
                    objVendor["VendorBidding"]["CPIOther"] = 100;
                    objVendor["VendorBidding"]["FieldWorkDaysCPIOther"] = 100;
                    objVendor["VendorBidding"]["TotalCPIOther"] = 100;
                    objVendor["VendorBidding"]["SelectedCPIOther"] = 100;

                    objVendor["VendorBidding"]["OtherAllowance"] = 100;
                    objVendor["VendorBidding"]["TotalGrand"] = 100;
                    objVendor["VendorBidding"]["FieldWorkDays"] = 100;
                    objVendor["VendorBidding"]["Comments"] = 100;


                    objMethodology.Vendors.push(objVendor);
                });
            }
        });

    });
    //  });

    // Object.keys(tempObj).forEach(d=>{
    //     formdata.append(d,tempObj[d])
    // })
    var val = null

    formdata.append("Proposal", JSON.stringify(tempObj))
    if (temp?.other_required_documents_filename) {
        Object.entries(temp.other_required_documents_filename).forEach(a => {

            formdata.set(a[0], a[1], a[1].name)
        })
    }

    

    return {
        type: types.SAVE_PROPOSAL_REQUEST,
        payload: { formdata: formdata, projectId: Proposal.ProjectId , files:biddingFiles}
       
    };
}

export const saveVendor = (temp, vendorId, status,show) => {    ;
    return {
        type: types.SAVE_VENDORBIDDING_REQUEST,
        payload: { data: temp, id: vendorId, status: status, ProposalId: temp.ProjectId,show:show }
    };
}

export const saveSelectedVendor = (temp, pageload) => {
    ;
    let Proposal = JSON.parse(JSON.stringify(temp));
    return {
        type: types.SAVE_PROPOSAL_SELECTEDVENDOR,
        payload: { Proposal: Proposal, pageload: pageload }
    };
}

export const saveProfile = (temp, SaveVendorBidding = null) => {

    return {
        type: types.SAVE_PROFILE_REQUEST,
        payload: temp
    };
}

export const saveVendorCost = (temp, vendorId, SaveVendorBidding = null) => {
    ;
    let Proposal = JSON.parse(JSON.stringify(temp));
    let tempObj = { "ProposalId": Proposal.ProjectId, "Methodologies": [], SaveVendorBidding: SaveVendorBidding };
    if (Proposal.BiddingId)
        tempObj["id"] = Proposal.BiddingId;
    tempObj.proposal = temp;
    let LatestCO = Proposal.CostingProfiles[Proposal.CostingProfiles.length - 1]
    // Proposal.CostingProfiles.forEach(profile => {

    LatestCO.CountrySpecs.forEach(country => {

        country.MethodologySpecs.forEach(methodology => {

            if (methodology.Vendors && methodology.Vendors.length > 0) {

                let objMethodology = {
                    "MethodologyId": methodology.id, "QCValidationModeId": (methodology.QCValidationMode ? methodology.QCValidationMode.value : null),
                    "OtherProjectSpecification": methodology.OtherProjectSpecification,
                    "Vendors": [], "Deadline": methodology.Deadline, "VendorCostid": methodology.VendorCostid, "QCValidationMode": methodology.QCValidationMode, "UploadOtherRequiredDocuments": methodology.UploadOtherRequiredDocuments, "OtherProjectSpecification": methodology.OtherProjectSpecification
                };

                if (methodology.BiddingData && methodology.BiddingData.id)
                    objMethodology["id"] = methodology.BiddingData.id;

                tempObj.Methodologies.push(objMethodology);
                methodology.Vendors.forEach(vendor => {
                    ;
                    let objVendor = {
                        "VendorId": vendor.value, "SelfBidding": vendor.SelfBidding || false, "VendorBidding": {}
                    };

                    if (vendor.id)
                        objVendor["id"] = vendor.id;
                    if (vendor.VendorBiddingId)
                        objVendor["VendorBidding"]["id"] = methodology.VendorCostid;

                    // objVendor["VendorBidding"]["TargetMain"] = vendor.TargetMain || methodology.RFQData.sampleSizeMain;
                    // objVendor["VendorBidding"]["ActualMain"] = vendor.ActualMain;
                    // objVendor["VendorBidding"]["CPIMain"] = vendor.CPIMain;
                    // objVendor["VendorBidding"]["FieldWorkDaysCPIMain"] = vendor.FieldWorkDaysCPIMain;
                    // objVendor["VendorBidding"]["TotalCPIMain"] = vendor.TotalCPIMain;
                    // objVendor["VendorBidding"]["SelectedCPIMain"] = (vendor.SelectedCPIMain != false ? true : vendor.SelectedCPIMain);

                    // objVendor["VendorBidding"]["TargetBooster"] = vendor.TargetBooster || methodology.RFQData.sampleSizeBooster;
                    // objVendor["VendorBidding"]["ActualBooster"] = vendor.ActualBooster;
                    // objVendor["VendorBidding"]["CPIBooster"] = vendor.CPIBooster;
                    // objVendor["VendorBidding"]["FieldWorkDaysCPIBooster"] = vendor.FieldWorkDaysCPIBooster;
                    // objVendor["VendorBidding"]["TotalCPIBooster"] = vendor.TotalCPIBooster;
                    // objVendor["VendorBidding"]["SelectedCPIBooster"] = (vendor.SelectedCPIBooster != false ? true : vendor.SelectedCPIBooster);

                    // objVendor["VendorBidding"]["TargetOther"] = vendor.TargetOther || methodology.RFQData.sampleSizeOther;
                    // objVendor["VendorBidding"]["ActualOther"] = vendor.ActualOther;
                    // objVendor["VendorBidding"]["CPIOther"] = vendor.CPIOther;
                    // objVendor["VendorBidding"]["FieldWorkDaysCPIOther"] = vendor.FieldWorkDaysCPIOther;
                    // objVendor["VendorBidding"]["TotalCPIOther"] = vendor.TotalCPIOther;
                    // objVendor["VendorBidding"]["SelectedCPIOther"] = (vendor.SelectedCPIOther != false ? true : vendor.SelectedCPIOther);

                    // objVendor["VendorBidding"]["OtherAllowance"] = vendor.OtherAllowance;
                    // objVendor["VendorBidding"]["TotalGrand"] = vendor.TotalGrand;
                    // objVendor["VendorBidding"]["FieldWorkDays"] = vendor.FieldWorkDays;
                    // objVendor["VendorBidding"]["Comments"] = vendor.Comments;


                    objVendor["VendorBidding"]["TargetMain"] = 100;
                    objVendor["VendorBidding"]["ActualMain"] = 100;
                    objVendor["VendorBidding"]["CPIMain"] = 100;
                    objVendor["VendorBidding"]["FieldWorkDaysCPIMain"] = 100;
                    objVendor["VendorBidding"]["TotalCPIMain"] = 100;
                    objVendor["VendorBidding"]["SelectedCPIMain"] = 100;

                    objVendor["VendorBidding"]["TargetBooster"] = 100;
                    objVendor["VendorBidding"]["ActualBooster"] = 100;
                    objVendor["VendorBidding"]["CPIBooster"] = 100;
                    objVendor["VendorBidding"]["FieldWorkDaysCPIBooster"] = 100;
                    objVendor["VendorBidding"]["TotalCPIBooster"] = 100;
                    objVendor["VendorBidding"]["SelectedCPIBooster"] = 100;

                    objVendor["VendorBidding"]["TargetOther"] = 100;
                    objVendor["VendorBidding"]["ActualOther"] = 100;
                    objVendor["VendorBidding"]["CPIOther"] = 100;
                    objVendor["VendorBidding"]["FieldWorkDaysCPIOther"] = 100;
                    objVendor["VendorBidding"]["TotalCPIOther"] = 100;
                    objVendor["VendorBidding"]["SelectedCPIOther"] = 100;

                    objVendor["VendorBidding"]["OtherAllowance"] = 100;
                    objVendor["VendorBidding"]["TotalGrand"] = 100;
                    objVendor["VendorBidding"]["FieldWorkDays"] = 100;
                    objVendor["VendorBidding"]["Comments"] = 100;


                    objMethodology.Vendors.push(objVendor);
                });
            }
        });
    });
    // });

    return {
        type: types.SAVE_VENDOR_REQUEST,

        payload: { tempObj: tempObj, vendorId: vendorId }

    };
}

export const acknowledgeVendorBidding = (proposal, vendorId) => {
    let Ids = [];
    proposal.CostingProfiles.forEach(x => {
        x.CountrySpecs.forEach(y => {
            y.MethodologySpecs.forEach(z => {
                Ids.push(z["BiddingData"].Vendors[0]["BiddingVendors"]["id"]);
            });
        });

    });
    return {
        type: types.ACKNOWLEDGE_VENDOR_BIDDING,
        payload: { ProjectId: proposal.ProjectId, VendorId: vendorId, Ids: Ids }
    };
}

export const fetchChatRequest = (proposalId, vendorId = null) => {
    return {
        type: types.FETCH_CHAT_REQUEST,
        vendorId: vendorId,
        proposalId: proposalId
    };
}

export const fetchChatSuccess = chats => {
    return {
        type: types.FETCH_CHAT_SUCCESS,
        payload: chats
    };
}

export const fetchChatFailure = error => {
    return {
        type: types.FETCH_CHAT_FAILURE,
        payload: error
    };
}

export const saveChatRequest = chatData => {
    return {
        type: types.SAVE_CHAT_REQUEST,
        payload: chatData
    };
}

export const saveChatSuccess = chatData => {
    return {
        type: types.SAVE_CHAT_SUCCESS,
        payload: chatData
    };
}

export const saveChatFailure = error => {
    return {
        type: types.SAVE_CHAT_FAILURE,
        payload: error
    };
}

export const fetchProposals = (offsetss = null, searchBy = null, searchVal = null) => {
    ;
    let tempSearchVal = null;
    let tempSearchBy = null;
    if (searchBy && searchVal) {
        tempSearchBy = searchBy.value;
        if (searchBy.value == "countries")
            tempSearchVal = searchVal.map(y => y.Code);
        else
            tempSearchVal = searchVal;
    }

    return {
        type: types.FETCH_PROPOSALS_REQUEST,
        offsetss,
        tempSearchBy,
        tempSearchVal
    }
};

export const saveApprovedBiddingVendors = (temp, resend = false, saveOnly = false) => {
    let Bidding = JSON.parse(JSON.stringify(temp));
    let DataToSave = [];
    Bidding.Data.forEach(z => {
        let name = "ApprovedCPI" + z.SampleType;
        let obj = {
            "id": z.id,
            "ProposalId": temp.ProjectId,
            ["Target" + z.SampleType]: z.TargetSampleSize,
            ["CPI" + z.SampleType + "Final"]: z.CPIFinal,
            ["TotalCPI" + z.SampleType + "Final"]: z.TotalFinal,
            ["VendorId"]: z["VendorId"]
        };
        if (resend && z.Selected) {
            obj["CPI" + z.SampleType + "Final"] = null;
            obj["TotalCPI" + z.SampleType + "Final"] = null;
            obj["IsResent" + z.SampleType] = resend;
            obj["Actual" + z.SampleType] = null;
            obj["CPI" + z.SampleType] = null;
            obj["TotalGrand"] = (parseInt(z.TotalGrand) - parseInt(z["TotalCPI"]));
            obj["FieldWorkDaysCPI" + z.SampleType] = null;
            obj["TotalCPI" + z.SampleType] = null;
        }
        if (!resend && !saveOnly) {
            obj[name] = z.Selected;
            obj["IsResent" + z.SampleType] = false;
            obj["IsSubmitted"] = true;
            obj["ApprovedCPI" + z.SampleType] = z.Selected;
            obj["FieldCosts"] = z.FieldCosts;
            obj["SetupAndDeliveryCosts"] = z.SetupAndDeliveryCosts;
            obj["TranslationCosts"] = z.TranslationCosts;
            obj["OtherCosts"] = z.OthersCosts;
            obj["CSOOPCosts"] = z.CSOOPCosts;
            obj["CSTimeCosts"] = z.CSTimeCosts;
            obj["VATOtherTax"] = z.VATOtherTax;
            obj["TotalCPI" + z.SampleType + "Final"] = z.TotalFinal;
        }
        DataToSave.push(obj);
    });
    ;
    return {
        type: types.SAVE_APPROVED_BIDDING_VENDORS_REQUEST,
        payload: {
            data: DataToSave, projectId: temp.ProjectId, resend: resend, saveOnly: saveOnly
        }
    };
}

export const saveAdditionalCostBiddingSummary = (indexC, proposal) => {
    return {
        type: types.SAVE_ADDITIONAL_COST,
        indexC: indexC,
        proposal: proposal
    };
}

export const sendEarlyWarningNote = (id) => {
    ;
    return {
        type: types.SEND_EARLY_WARNING_NOTE,
        payload: { id: id }
    };
}