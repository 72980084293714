import React from "react";
import { Col, Container, Row, Table } from "reactstrap";
import _ from "lodash";

const CostingMarketTable = ({
  activities,
  band,
  tableData,
  defaultRates,
  teamDataOptions,
  costingOptionID,
  CurrencyConversion
}) => {
 
  const getTotalHours = (dataHours) => {
    if (dataHours !== undefined && dataHours !== null) {
      let sumOfHours = 0;
      for (let i = 0; i < activities.length; i++) {
        for (let j = 0; j < band.length; j++) {
          if (
            dataHours[i] !== undefined &&
            dataHours[i][j] !== undefined
          )
            sumOfHours += Number(dataHours[i][j]);
        }
      }
      return sumOfHours;
    }
  };

  const getTotalBandHours = (bandIndex, data) => {
    let totalHours = 0;
    let hours = Object.values(data)[0];
    for (let i = 0; i < activities.length; i++) {
      if (hours[i][bandIndex] !== "") totalHours += hours[i][bandIndex];
    }
    return totalHours;
  };

  const getTotalCost = (id, i, data) => {
    let bandRates = [];    
    let totalHours = getTotalBandHours(i, data);
    if (isNaN(totalHours)) {
      return 0;
    }
    const country = teamDataOptions?.filter((csTeam =>csTeam.label === Object.keys(data)[0] ));
    if(country && country?.length > 0){
      bandRates = defaultRates?.filter((rate) => rate.country_id === country[0]?.value);
    }
    let rate = bandRates?.filter((r)=>r.band_id === id);
    let finalRate = 0;
    if(rate && rate?.length > 0){
    finalRate = Number(
    Math.round(totalHours * rate[0].rate * 100) / 100
    ).toFixed(2);
    }
    return finalRate; 
  };

  const totalCost = (data) => {    
    let totalCost = 0;
    band.forEach((b,index)=>{
      let tempCsValue = getTotalCost(b.band_id, index, data);
      if (!isNaN(tempCsValue)) {
        totalCost += Number(tempCsValue);
      }
    })
    return (Math.round(totalCost * 100) / 100).toFixed(2);
  };


  const getHoursBandWise = (bandIndex, data) => {
    let totalHours = 0;
    for (let i = 0; i < activities.length; i++) {
      if (data[i][bandIndex] !== "") totalHours += data[i][bandIndex];
    }
    return totalHours;
  };

  return (
    <Container style={{ marginLeft: "100px" }}>
      <br />
      <Row>
        <Col>
          <div className="">
            <Table
              responsive
              hover
              striped
              bordered
              size="sm"
              style={{
                width: "90%",
                marginLeft: "15px",
                marginTop: "10px",
                backgroundColor: "white",
                tableLayout: "fixed",
                wordWrap: "break-word",
              }}
            >
              <thead>
                <tr className="">
                  <th style={{ width: "150px" }}>Costing Market</th>
                  {band
                    ? band.map((b) => <th key={b.id}>{b.band_name}</th>)
                    : null}
                  <th style={{ width: "100px" }}>Total Hours</th>
                  <th style={{ width: "100px" }}>Total Costs</th>
                </tr>
              </thead>
              <tbody>
                { costingOptionID && tableData[costingOptionID]?.map((data, index) => (
                    <tr key={index}>
                      <td>
                        <label>
                          {teamDataOptions?.some(
                            (obj) => obj.label === Object.keys(data)[0]
                          )
                            ? Object.keys(data)[0]
                            : ""}
                        </label>
                      </td>
                      {band
                        ? band.map((b, bandIndex) => (
                            <td key={b.id}>
                              {getHoursBandWise(bandIndex,Object.values(data)[0])}
                            </td>
                          ))
                        : null}
                      <td>{getTotalHours(Object.values(data)[0])}</td>
                      <td>{(totalCost(data)*CurrencyConversion)?.toFixed(2)}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default CostingMarketTable;