import React, { useEffect, useState } from "react";
import { Label, CustomInput, Row, Col } from "reactstrap";
import Select from "react-select";

const ProjectContacts = ({
  user,
  project,
  updateProject,
  primaryCSContacts,
  otherInternalContacts,
}) => {
  const [filteredOtherInternalContacts, setFilteredOtherInternalContacts] = useState([]);
  useEffect(() => {
    if (!(project?.ProposalOwnerEmail?.value)) {
      project.ProposalOwnerEmail = updateProject({
        ProposalOwnerEmail: {
          value: user.EmailId,
          label: user.EmailId,
        },
      });
    }
    const userBusinessUnits = user.BusinessUnits.split(",");
    if (user && otherInternalContacts && otherInternalContacts.length > 0) {
      let filteredContacts = otherInternalContacts.filter(item => {
        // if (item.BusinessUnits?.indexOf(',') > -1) {
        //   // const contactBusinessUnits = item.BusinessUnits.split(',');
        //   return userBusinessUnits.some(ubu => item.BusinessUnits.split(',').includes(ubu))
        // } else {
        //   return userBusinessUnits.includes(item.BusinessUnits)
        // }
        return (item.BusinessUnits?.indexOf(',') > -1? 
        userBusinessUnits.some(ubu => item.BusinessUnits.split(',').includes(ubu)):
        userBusinessUnits.includes(item.BusinessUnits))
      })
      setFilteredOtherInternalContacts(filteredContacts);
    }
  }, [project, user, otherInternalContacts]);
 
  return (
    <React.Fragment>
      <Row>
        <Col>
          <Row>
            <Col>
              <Label className="h5">Primary Client Service Contact</Label>
            </Col>
          </Row>
          <Row>
            <Col>
            
              <Select
                className="react-select-container mb-3 lg-12 md-12"
                classNamePrefix="react-select"
                options={primaryCSContacts.map((item) => {
                  return { value: item.EmailId, label: item.EmailId };
                })}
                isSearchable
                value={project.ProposalOwnerEmail}
                isDisabled= {project?.ProjectStatus == "7"}
                onChange={(e) => {
                  updateProject({ ProposalOwnerEmail: e });
                }}
                
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <CustomInput
                type="switch"
                id="isSFContactSyncPaused"
                name="customSwitch"
                label="Pause Microsoft Dynamics Contacts Sync"
                className="h5"
                disabled = {project?.ProjectStatus == "7"}
                // defaultChecked={project.IsSFContactSyncPaused}
                checked={project.IsSFContactSyncPaused}
                onChange={(e) => {
                  updateProject({
                    IsSFContactSyncPaused: !project.IsSFContactSyncPaused,
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <CustomInput
                type="switch"
                id="isRestrictedProject"
                name="customSwitch"
                label="Restrict Project Access"
                className="h5"
                disabled = { project?.ProjectStatus == "7"}
                // defaultChecked={project.IsRestrictedProject}
                checked={project.IsRestrictedProject}
                onChange={(e) => {
                  updateProject({
                    IsRestrictedProject: !project.IsRestrictedProject,
                  });
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Label className="h5">Other Project Team Contacts</Label>
          <Select
            className="react-select-container"
            classNamePrefix="react-select"
            options={filteredOtherInternalContacts.map((item) => {
              return { value: item.Email, label: item.Email };
            })}
            isMulti
            isDisabled= {project?.ProjectStatus == "7"}
            isSearchable
            value={project.OtherProjectTeamContacts}
            onChange={(e) => updateProject({ OtherProjectTeamContacts: e ?? []})}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ProjectContacts;
