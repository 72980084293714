import React, { useState, useEffect } from "react";
import { getLabel } from "../../../utils/codeLabels";
import _, { set } from "lodash";
import { toastr } from "react-redux-toastr";
import * as mapperFunctions from "../../../utils/rfqMapper";
import {
    pageLoadEnd,
    pageLoadStart,
    recordLoadEnd,
    recordLoadStart,
    setCostingStatus,
} from "../../../redux/actions/appActions";
import * as appActions from "../../../redux/actions/appActions";
import axios from "../../../axios-interceptor";
import Layout from "../../../layouts/Project";
import { useHistory } from "react-router-dom";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import * as currentProjectActions from "../../../redux/actions/currentProjectActions";
import * as currentCostingActions from "../../../redux/actions/currentCostingActions";
import update from "immutability-helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CostingTypeMapping from "../../../components/CostingTypeMapping";
import {
    faChevronDown,
    faChevronUp,
    faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import {
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    UncontrolledCollapse,
    Progress,
} from "reactstrap";
import { Prompt } from "react-router";
import BiddingDetails from "./details";
import { useParams } from "react-router-dom";
import ProposalBiddingSelectVendors from "./ProposalBiddingSelectVendors";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    Table,
} from "reactstrap";
import "./css/common.css";
import * as proposalActions from "../../../redux/actions/proposal/Actions";
import * as FetchVendorAndListActions from "../../../redux/actions/FetchVendorAndList/Actions";
import RFQTable from "./RFQTable";
import SUDTable from "./SUDTAble";
import { SET_TOTAL_FILES, SET_UPLOADED_FILES, SET_UPLOAD_STATUS, SET_VENDOR_FLAG } from "../../../redux/actions/vendor/ActionTypes";
import Down from "../../../assets/icons/NIQ Icons SVG/Chevron/Down";
import { Link} from "react-router-dom";
import logo from "../../../assets/img/avatars/modal-logo.png";
var imgStyle = {
    width: "150px",
  };
function VendorBiddingForm(props) {
    const codeLabels = useSelector(({ codeLabels }) => codeLabels);
    let pacificData = _.head(codeLabels?.PacificCountriesList)?.Label;
    let arr = pacificData?.split(',');
    const [pacificMarkets] = useState(arr ? arr : [])

    const projectid = useParams();
    const dispatch = useDispatch();
    const [updateCost, setUpdateCost] = useState()
    const [isCostSelection, setCostSelection] = useState(false);
    const [showCostMethod, setShowCostMethod] = useState(false)
    const [vendor, setvendor] = useState();
    const [navigation, setNavigation] = useState(false);
    const [buttonCheck, setButtonCheck] = useState(false);
    const [activeTabMethodology, setActiveTabMethodology] = useState(0);
    const [resetcostmodelState, setresetcostModelState] = useState(false);
    const [saveselectedrows, setsaveselectedrows] = useState([]);
    const [isSummary, setIsSummary] = useState(false);
    const [fromDashboard, setFromDashboard] = useState(false);
    const history = useHistory();
    const [activeTabFC, setActiveTabFC] = useState(0);
    const [activeTabSM, setActiveTabSM] = useState(0);
    const [cCProfile, setCCProfile] = useState(null);
    const [deadline, setDeadline] = useState();
    const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
    const [oncostview, setoncostview] = useState(false);
    const [CurrentDate, setCurrentDate] = useState(0);
    const [render, setRender] = useState(false);
    const [onsubmit, setOnsubmit] = useState(false);
    const [costingProfileIndex, setCostingProfileIndex] = useState(0);

    var CloseBidding;
    const costingStatus = useSelector(({ app }) => app.costingStatus);

    const vendorAndList = useSelector(
        ({ fetchVendorAndList }) => fetchVendorAndList.vendorAndList
    );
    let vendorreducer = useSelector(({ vendorReducer }) => vendorReducer)
    var project = useSelector(({ currentProject }) => currentProject.newProject);
    const currentCostingProfile = useSelector(
        ({ currentCosting }) => currentCosting.currentCostingProfile
    );
    const oppValue = useSelector(

        ({ currentProject }) => currentProject.opportunityValue
    
      );
    useEffect(() => {
        const current1 = new Date();

        setCurrentDate(SetDate(current1));
    }, [])
    const updateCostingProfile = (costingProfile) => {
        dispatch({
            type: "UPDATE_NEW_COSTING",
            currentCostingProfile: costingProfile,
        });
    };
    Date.prototype.addDays = function (days) {
        let date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }

    function SetSubmitDisable(e, status) {
        if (status == "close") {
            setOnsubmit(true);
            // var deadlinedate = new Date(e);
            // var deadlineextended = SetDate(deadlinedate.addDays(5))
            // var datecurrent = new Date()
            // var currentdate = SetDate(datecurrent)
            // if (deadlineextended < currentdate) {
            //     setOnsubmit(true);
            // }
        }
    }

    function extractingVendor(res) {
        dispatch(
            FetchVendorAndListActions.fetchVendorAndList(
                res,
                null, false
            )
        );
    }
    useEffect(() => {
        if (project.id == "" || project.id == null) {
            dispatch(appActions.pageLoadStart());
            axios.get("projects/bidding/" + projectid.projectId).then((res) => {
                setCostingProfileIndex(res.data.project.CostingProfiles.length - 1)
                // dispatch(
                //     FetchVendorAndListActions.fetchVendorAndList(
                //         res.data.project.CostingProfiles[res.data.project.CostingProfiles.length - 1],
                //         null
                //     )
                // );
                if (res.data.project.CostingProfiles[res.data.project.CostingProfiles.length - 1]?.Deadlinestatus == "close") {
                    setRender(true);
                    SetSubmitDisable(res.data.project.CostingProfiles[res.data.project.CostingProfiles.length - 1]?.Deadline, "close")
                }
                else
                    if (res.data.project.CostingProfiles[res.data.project.CostingProfiles.length - 1]?.Deadline) {
                        var Cdate = new Date();
                        var deadlinedate = new Date(res.data.project.CostingProfiles[res.data.project.CostingProfiles.length - 1]?.Deadline);
                        var current1 = deadlinedate.addDays(5)
                        SetDate(current1)

                        var d = current1.getDate();
                        var day = d < 10 ? ("0" + d) : d;
                        var m = current1.getMonth() + 1;
                        var month = m < 10 ? ("0" + m) : m;
                        var y = current1.getFullYear();
                        var h = current1.getHours();
                        var hour = h < 10 ? ("0" + h) : h;
                        var minu = current1.getMinutes();
                        var minute = minu < 10 ? ("0" + minu) : minu;
                        var extended = y + "-" + month + "-" + day + "T" + hour + ":" + minute;
                        var currentdate = SetDate(Cdate)
                        var extendeddate = SetDate(current1)

                        if (extended < currentdate) {
                            if (res.data.project.CostingProfiles[res.data.project.CostingProfiles.length - 1]?.Deadlinestatus != "close") {
                                setRender(true);
                                setOnsubmit(true);
                            }
                        }
                    }


                dispatch({
                    type: currentProjectActions.UPDATE_NEW_PROJECT,
                    newProject: res.data.project,
                });
                dispatch(
                    currentCostingActions.getCosting(
                        res.data.project.CostingProfiles[res.data.project.CostingProfiles.length - 1].id, () => extractingVendor(res.data.project.CostingProfiles[res.data.project.CostingProfiles.length - 1]), null, null, true
                    )
                );

                setCCProfile(res.data.project.CostingProfiles[res.data.project.CostingProfiles.length - 1])
                setDeadline(res?.data?.project?.CostingProfiles[res.data.project.CostingProfiles.length - 1]?.Deadline)
            });


        }
        else {
            setNavigation(true)
            setDeadline(project?.CostingProfiles[project.CostingProfiles.length - 1]?.Deadline)
            if (project?.CostingProfiles[project.CostingProfiles.length - 1]?.Deadlinestatus == "close") {
                setRender(true);
                setOnsubmit(true);
            }
            dispatch(
                FetchVendorAndListActions.fetchVendorAndList(
                    project.CostingProfiles[project.CostingProfiles.length - 1],
                    null, true
                )
            );

        }
        dispatch({ type: SET_VENDOR_FLAG, Vendor: true })
    }, []);

    useEffect(() => {
        if (project.VendorLoaded == true) {
            setCCProfile(project.CostingProfiles[project.CostingProfiles.length - 1])
            setDeadline(project?.CostingProfiles[project.CostingProfiles.length - 1]?.Deadline)
            if (project?.CostingProfiles[project.CostingProfiles.length - 1]?.Deadlinestatus == "close") {
                setRender(true);
                setOnsubmit(true);
            }
            project.VendorLoaded = false
            dispatch({
                type: currentProjectActions.UPDATE_NEW_PROJECT,
                newProject: project,
            });
        }
    }, [project.VendorLoaded]);
    useEffect(() => {
        if (currentCostingProfile.ProfileLoaded == true) {
            if (projectid.summary == 1) {
                setIsSummary(true);
                setFromDashboard(true);
            }
        }
    }, [currentCostingProfile.ProfileLoaded]);
    useEffect(() => {
        if (
            project.CostingProfiles &&
            project.CostingProfiles.length > 0 &&
            project.CostingProfiles[costingProfileIndex].id &&
            project.CostingProfiles[costingProfileIndex].CountrySpecs
        ) {
            let cloneProfile = formatingDataForForms();
            if (!Array.isArray(project.CostingProfiles[costingProfileIndex].Methodology)) {
                updateCostingProfile({
                    ...cloneProfile,
                    StudyType: oppValue === "" ? (_.cloneDeep(
                        mapperFunctions.GetValueLablesFromCSV(
                            codeLabels.StudyTypeOptions,
                            project.CostingProfiles[costingProfileIndex].StudyType
                        )
                    )) : null,
                    Methodology: _.cloneDeep(
                        mapperFunctions.GetValueLablesFromCSV(
                            codeLabels.MethodologyOptions,
                            project.CostingProfiles[costingProfileIndex].Methodology
                        )
                    ),
                    SubMethodology: _.cloneDeep(
                        mapperFunctions.GetValueLablesFromCSV(
                            codeLabels.SubMethodologyOptions,
                            project.CostingProfiles[costingProfileIndex].SubMethodology
                        )
                    ),
                    IsMultiCountry: project.CostingProfiles[costingProfileIndex].IsMultiCountry,
                    FieldingCountries: _.cloneDeep(
                        mapperFunctions.GetValueLablesFromCSV(
                            codeLabels.FieldingCountriesOptions,
                            project.CostingProfiles[costingProfileIndex].FieldingCountries
                        )
                    ),
                });
                var costingprofiles = _.cloneDeep(project.CostingProfiles)

                costingprofiles[costingProfileIndex] = cloneProfile
                updateProject({
                    CostingProfiles: costingprofiles,

                });
                dispatch({
                    type: "UPDATE_NEW_COSTING",
                    currentCostingProfile: cloneProfile,
                });
            }
        }
    }, [currentCostingProfile.ProfileLoaded]);

    useEffect(() => {
        if (vendorAndList.length > 0) {
            setvendor(
                vendorAndList
                    .filter((x) => x.Group == "Vendors")[0]
                ["List"].map((x) => ({
                    label: x.label,
                    value: x.value,
                    id: x.id,
                    Methodology: x.SubMethodologies,
                    VendorType: x.VendorTypes,
                    Countries: x.countries
                        ?.filter((c) => c.Blacklisted == 0)
                        ?.map((d) => d.Country),
                }))
            );
        }
    }, [vendorAndList]);

    useEffect(() => {
        if (
            project &&
            project.CostingProfiles &&
            project.CostingProfiles.length > 0 &&
            project.CostingProfiles[costingProfileIndex]?.CountrySpecs &&
            project.CostingProfiles[costingProfileIndex]?.CountrySpecs?.length > 0 &&
            project.CostingProfiles[costingProfileIndex]?.CountrySpecs.map(
                (cs) =>
                    cs.MethodologySpecs.filter((y, index) => y.CostingType == 3).length
            ).reduce((r, e) => r + e) > 0
        ) {
            //Finding First Country for Vendor Bidding
            var indexfc = project.CostingProfiles[costingProfileIndex].CountrySpecs.filter(
                (cs) =>
                    cs.MethodologySpecs.filter((sm) => sm.CostingType == 3 && !sm.NotApplicable).length > 0
            )[0].id;
            setActiveTabFC(
                project.CostingProfiles[costingProfileIndex].CountrySpecs.findIndex(
                    (m) => m.id == indexfc
                )
            );

            //Finding First Methodology for Vendor Bidding
            var indexsm = project.CostingProfiles[costingProfileIndex].CountrySpecs.filter(
                (m) => m.id == indexfc
            )[0].MethodologySpecs.filter((y, index) => y.CostingType == 3 && !y.NotApplicable)[0]?.id;
            setActiveTabSM(
                project.CostingProfiles[costingProfileIndex].CountrySpecs.filter(
                    (m) => m.id == indexfc
                )[0].MethodologySpecs.findIndex((m) => m.id == indexsm)
            );
            setActiveTabMethodology(indexsm);
        }
    }, [project.CostingProfiles]);

    function SetDate(current1) {
        var d = current1.getDate();
        var day = d < 10 ? ("0" + d) : d;
        var m = current1.getMonth() + 1;
        var month = m < 10 ? ("0" + m) : m;
        var y = current1.getFullYear();
        var h = current1.getHours();
        var hour = h < 10 ? ("0" + h) : h;
        var minu = current1.getMinutes();
        var minute = minu < 10 ? ("0" + minu) : minu;

        var GetDate = y + "-" + month + "-" + day + "T" + hour + ":" + minute;
        return GetDate
    }


    function fetchBack() {
        if (fromDashboard) {

            history.push("/vendordashboard");

        }
        else {
            let condition = false
            const tempcostdata = _.cloneDeep(project.CostingProfiles[costingProfileIndex].CountrySpecs);
            tempcostdata.forEach((s, i) =>
                s.MethodologySpecs.forEach((m, im) => {
                    if (m.CostingType == 3 && m.Vendors?.length > 0) {
                        m.Vendors?.forEach((v, iv) => {
                            if (!v.save) { condition = true }


                        })
                    }
                }))
            if (!condition) {
                setIsSummary(!isSummary);
            }
            else {
                toastr.error("Please save the data before Going to next Page")
            }
        }
    }

    function navigateToDashboard() {
        if (navigation)
            history.push("/costing")
        else
            history.push("/vendordashboard");
    }

    function formatingDataForForms() {
        let typeofquant = codeLabels?.SubMethodologyOptions?.filter(sm => sm.ResearchType == "Quantitative")?.map(c => c.Code)
        let typeofqual = codeLabels?.SubMethodologyOptions?.filter(sm => sm.ResearchType == "Qualitative")?.map(c => c.Code)
        let cloneProfile = _.cloneDeep(currentCostingProfile);
        cloneProfile.CountrySpecs?.forEach((cs, indexCS) => {
            let commCountryCode = project.CommissioningCountry;
            cs["label"] = cs.CountryName;
            cs.MethodologySpecs?.forEach((ms, indexMS) => {
                if (typeofquant.find((tp) => tp == ms.Code)) ms["Quant"] = true;
                else if (typeofqual.find((tp) => tp == ms.Code)) ms["Qual"] = true;

                ms["label"] = ms.Label;
                let tempVariables = mapperFunctions.getSchemaWithDataJSON(
                    ms.RFQSchema,
                    ms.Code,
                    ms.RFQData,
                    true,
                    commCountryCode,
                    pacificMarkets,
                    project.CostingTemplateVersion,
                    currentCostingProfile,
                    oppValue
                );
                if (!ms.RFQData) {
                    tempVariables.forEach((x) => {
                        if (x.dependentOn) x.disabled = true;
                    });
                }

                let tempOpResourceVariables = mapperFunctions.getSchemaWithDataJSON(
                    ms.SetupSchema,
                    ms.Code,
                    ms.SetupData,
                    false,
                    commCountryCode,
                    pacificMarkets,
                    currentCostingProfile,
                    oppValue
                );
                if (!ms.RFQData) {
                    tempOpResourceVariables.forEach((x) => {
                        if (
                            x.dependentOn ||
                            x.label == "Coding Required" ||
                            x.label == "Verbatim Coding Required"
                        ) {
                            x.disabled = true;
                        }
                    });
                }

                let tempCostingOption = [];
                if (ms.CostingOptions?.length == 0)
                    tempCostingOption = [
                        {
                            label: "Default Option",
                            value: "Default Option",
                            isSelected: "true",
                            Variables: _.cloneDeep(tempVariables.filter((x) => x.Default)),
                        },
                    ];
                else {
                    ms.CostingOptions.forEach((co, coi) => {
                        let _tempCostingOption = {};
                        Object.keys(co).map((optionKey) => {
                            _tempCostingOption[optionKey] = co[optionKey];
                        });
                        _tempCostingOption.label = co.Name;
                        _tempCostingOption.value = co.Name;
                        _tempCostingOption.id = co.id;
                        _tempCostingOption.isSelected = co.Selected;
                        _tempCostingOption.Variables = _.cloneDeep(co.CostingOptionSchema);
                        tempCostingOption.push(_tempCostingOption);
                    });
                }
                ms["CostingOptions"] = _.cloneDeep(tempCostingOption);
            });
        });
        cloneProfile["updateCost"] = false;
        return cloneProfile;
    }

    function closebidding(project, data) {
        const newProposal = update(project.CostingProfiles[costingProfileIndex], data);

        project.CostingProfiles[costingProfileIndex] = newProposal;
        dispatch({
            type: currentProjectActions.UPDATE_NEW_PROJECT,
            newProject: project,
        });

        dispatch(
            proposalActions.saveProposal(project, false, true)
        );

        toastr.success("Bidding has been Closed");
    }

    function saveasdraft() {
        const tempcostdata = _.cloneDeep(project.CostingProfiles[costingProfileIndex].CountrySpecs);

        tempcostdata.forEach((s, i) =>
            s.MethodologySpecs.forEach((m, im) => {
                if (m.CostingType == 3 && m.Vendors?.length > 0) {
                    m.Vendors?.forEach((v, iv) => {
                        v.save = true;
                        v.CostingData.forEach((h, ih) => {
                            if (h.soid == 14) {
                                h.enable = true;
                            }
                        });
                    });
                }
            })
        );
        const newProposal = update(project.CostingProfiles[costingProfileIndex], {
            CountrySpecs: { $set: tempcostdata },
        });
        project.CostingProfiles[costingProfileIndex] = newProposal;
        dispatch({
            type: currentProjectActions.UPDATE_NEW_PROJECT,
            newProject: project,
        });

        dispatch(
            proposalActions.saveProposal(project, false, true, vendorreducer.biddingFiles)
        );

        toastr.success("Bidding Details has been Saved");

    }

    function Savebiddingrequest() {
        var condition = false;
        var Count;
        var totallength = project.CostingProfiles[costingProfileIndex].CountrySpecs.map(x => x.MethodologySpecs.length).reduce((a, b) => a + b);
        var totalvendorlength = project.CostingProfiles[costingProfileIndex].CountrySpecs.flatMap(x => x.MethodologySpecs.flatMap(y => y?.Vendors ? y.Vendors.length > 0 ? true : false : false)).filter(ele => ele == true).length;
        var vendorselectedcolength = project.CostingProfiles[costingProfileIndex].CountrySpecs.flatMap(x => x.MethodologySpecs.flatMap(y => y?.Vendors ? y.Vendors.length > 0 ? y.Vendors.flatMap(m => m.selectedCO.flatMap(s => s.selected))
            : false : false)).filter(ele => ele == true).filter(ele => ele == true).length
        project.CostingProfiles[costingProfileIndex].CountrySpecs.filter(
            (cs) => cs.MethodologySpecs.filter((ms) => ms.CostingType == 3).length > 0
        ).filter((cs) =>
            cs.MethodologySpecs.forEach((g) => {
                if (g.CostingType == 3) {
                }
            })
        );
        if (project?.CostingProfiles[costingProfileIndex]?.Deadlinestatus == "close") {
            setOnsubmit(true)
        }
        const tempcostdata = _.cloneDeep(project.CostingProfiles[costingProfileIndex].CountrySpecs);
        project.CostingProfiles[costingProfileIndex].CountrySpecs.forEach((s, i) =>
            s.MethodologySpecs.forEach((m, im) => {
                if (m.CostingType == 3 && m.Vendors?.length > 0) {
                    if (
                        m.Vendors.length > 0 &&
                        m.SplitSample == 1 &&
                        m.CostingType == 3
                    ) {
                        m.CostingOptions.map((Co) => {
                            m?.SplitSampledata?.SplitsData.filter(
                                (split) => split.coid == Co.id
                            )?.map((cosplit) => {
                                Count = m.Vendors.filter(
                                    (ven) =>
                                        ven?.CostingData?.filter(
                                            (s) => s.soid == 14 && s.coid == Co.id
                                        )[0]?.Selected?.value == cosplit.id
                                );
                                if (Count.length == 0) {
                                    condition = true;
                                }
                            });
                        });
                    }
                }
            })
        );
        if ((project.CostingProfiles[costingProfileIndex]?.deadline == undefined && (project.CostingProfiles[costingProfileIndex]?.Deadline == undefined || project.CostingProfiles[costingProfileIndex]?.Deadline == "")) || condition) {
            !condition
                ? toastr.error("Please Select the Deadline")
                : toastr.error("Please Select the distribution for all Vendors");
        }

        else {
            tempcostdata.forEach((s, i) =>
                s.MethodologySpecs.forEach((m, im) => {
                    if (m.CostingType == 3 && m.Vendors?.length > 0) {
                        var sendmail = true;
                        var oldval = cCProfile.CountrySpecs[i].MethodologySpecs[im]
                        if (deadline == project.CostingProfiles[costingProfileIndex]?.Deadline &&
                            _.isEqual(oldval.selectedRFQ, m.selectedRFQ) &&
                            _.isEqual(oldval.selectedSUD, m.selectedSUD) &&
                            oldval.Comments == m.Comments &&
                            oldval.OtherProjectSpecification == m.OtherProjectSpecification &&
                            _.isEqual(oldval.QCValidationMode, m.QCValidationMode) &&
                            _.isEqual(oldval.Currency, m.Currency) &&
                            oldval.FullServiceRequired == m.FullServiceRequired &&
                            oldval.SplitSample == m.SplitSample &&
                            oldval.SplitSample == m.SplitSample &&
                            //oldval.UploadOtherRequiredDocuments == m.UploadOtherRequiredDocuments
                            _.isEqual(oldval.UploadOtherRequiredDocuments, m.UploadOtherRequiredDocuments)

                        ) {
                            sendmail = false;
                        }

                        m.Vendors?.forEach((v, iv) => {
                            v.save = true;
                            v.CostingData.forEach((h, ih) => {
                                if (h.soid == 14) {
                                    tempcostdata[i].MethodologySpecs[im].Vendors[iv].CostingData[
                                        ih
                                    ].enable = true;
                                }
                            });
                            if (!sendmail) {
                                if (_.isEqual(oldval?.Vendors?.find(a => a.id == v.id), v)) {
                                    sendmail = false;
                                }
                                else {
                                    sendmail = true;
                                }
                            }
                            if (v.mail == 0) {
                                v.mail = 1;
                            }
                            else if (sendmail) {
                                v.mail = 2;
                            }
                            else {
                                v.mail = 3
                            }


                        });
                    }
                }
                )
            )
            const newProposal = update(project.CostingProfiles[costingProfileIndex], {
                CountrySpecs: { $set: tempcostdata },
            });
            project.CostingProfiles[costingProfileIndex] = newProposal;
            dispatch({
                type: currentProjectActions.UPDATE_NEW_PROJECT,
                newProject: project,
            });
            dispatch(proposalActions.saveProposal(project, false, false, vendorreducer.biddingFiles));
            toastr.success("Sent Bidding Request");
            // setButtonCheck(true);
        }

    }

    function updateProjectInfo(type, project, data) {
        let newProposal;
        switch (type) {
            case "deadline":
                newProposal = update(project.CostingProfiles[costingProfileIndex], data);
                project.CostingProfiles[costingProfileIndex] = newProposal;
                break;
            case "save-bidding":
                newProposal = update(project.CostingProfiles[costingProfileIndex], {
                    CountrySpecs: data,
                });
                project.CostingProfiles[costingProfileIndex] = newProposal;
                break;
            default:
                break;
        }
        dispatch({
            type: currentProjectActions.UPDATE_NEW_PROJECT,
            newProject: project,
        });
    }

    function restricteddate(e, a) {
        if (a)
            e.target.value = a;
    }

    function adddeadline(e, type) {
        var selecteddate = e.target.value;
        let deadLine;
        if (type === "deadline") {

            if (CurrentDate <= selecteddate || e.target.value.length == 0) {
                deadLine = {
                    Deadline: { $set: e.target.value },

                };
                updateProjectInfo("deadline", project, deadLine);
            }

        } else {

            setOnsubmit(true);
            deadLine = {
                Deadlinestatus: { $set: "close" },
                Deadline: { $set: CurrentDate },
            };
            closebidding(project, deadLine);
        }
    }

    function toggle(tab) {
        if (activeTabFC !== tab) {
            setActiveTabFC(tab);
            var indexsm = project.CostingProfiles[costingProfileIndex]?.CountrySpecs[
                tab
            ].MethodologySpecs.filter((y, index) => y.CostingType == 3 && !y.NotApplicable)[0].id;
            setActiveTabSM(
                project.CostingProfiles[costingProfileIndex].CountrySpecs[tab].MethodologySpecs.findIndex(
                    (m) => m.id == indexsm
                )
            );
        }
    }
    const updateProject = (newProject) =>
        dispatch({
            type: "UPDATE_NEW_PROJECT",
            newProject: newProject,
        });
    // function UpdateCloseBidding() {
    //     (project?.CostingProfiles[costingProfileIndex]?.CountrySpecs?.map(cs => cs?.MethodologySpecs?.map(ms => ms?.Vendors?.map(vs => {
    //         if (vs?.mail > 0)
    //             CloseBidding = true;
    //     }))));
    //     if (CloseBidding)

    // }

    function toggleSM(tab) {
        if (activeTabSM !== tab) {
            setActiveTabSM(tab);
        }
        //populateSideBarLinks(profile.CountrySpecs[activeTabFC]?.MethodologySpecs[activeTabSM]?.Variables);
    }
    return (
        <Layout
            hideProfileName={true}
            projectStatusToDisplay={getLabel(
                "ProjectStatusOptions",
                project?.ProjectStatus
            )}
            avoidEdit={true}
        >
            {currentCostingProfile.ProfileLoaded && (
                <>
                    <Prompt
                        when={shouldBlockNavigation}
                        message="You have unsaved changes, are you sure you want to leave?"
                    />

                    <CostingTypeMapping setUpdateCost={setUpdateCost} setCostSelection={setCostSelection} setShowCostMethod={setShowCostMethod} pacificData={pacificData} showCostMethod={showCostMethod} isCostSelection={isCostSelection} updateCost={updateCost} ></CostingTypeMapping>

                    {!isSummary && (
                        <>
                            {" "}
                            <Container fluid className="p-0">
                                {
                                    <>
                                        <Row>
                                            <Modal
                                                size="xs"
                                                isOpen={vendorreducer.uploadStatus}
                                                toggle={() => {
                                                    dispatch({ type: SET_UPLOAD_STATUS, payload: false })
                                                    dispatch({ type: SET_TOTAL_FILES, payload: 0 })
                                                    dispatch({ type: SET_UPLOADED_FILES, payload: 0 })
                                                }}
                                            >
                                                {/* {vendorreducer.totalFiles!=vendorreducer.uploadedFiles  &&<Loader></Loader>} */}
                                                <ModalBody>
                                                    <div className="text-center">
                                                        {vendorreducer.uploadedFiles} of {vendorreducer.totalFiles} files are uploaded.
                                                    </div>
                                                    <hr></hr>
                                                    <Progress
                                                        max={vendorreducer.totalFiles}
                                                        value={vendorreducer.uploadedFiles}
                                                    />
                                                    {vendorreducer.uploadSummary.length ?
                                                        vendorreducer.uploadSummary.map(vendor =>
                                                            <b style={{ color: "green" }}>

                                                            <hr></hr>
                                                            {vendor.files} files are uploaded for {vendor.vendorName ? vendor.vendorName : "Bidding"}<br />
                                                        </b>)

                                                        :
                                                        <b><hr></hr>Uploading Files . . .</b>}
                                                </ModalBody>

                                            </Modal>
                                            <Modal
                                                size="xs"
                                                isOpen={resetcostmodelState}
                                                toggle={() =>
                                                    setresetcostModelState(!resetcostmodelState)
                                                }
                                                centered={true}
                                            >
                                                <ModalHeader>
                                                {/* <img src={logo}  className="modallogo"/> */}
                                                </ModalHeader>
                                                <div>
                        <span className="modaltitle modaltitleDisplay">
                        Update Costing Option
                        </span>
                    </div>
                                                <ModalBody>
                                                    You may lost the saved date after reseting the Costing
                                                    type. Are You sure you want to reset Costing Type?
                                                </ModalBody>
                                                <ModalFooter>
                                                    <Button
                                                        onClick={() => {
                                                            setresetcostModelState(false);
                                                            dispatch(recordLoadStart());

                                                            var profileTemp = currentCostingProfile;
                                                            var profileTemp1 = {};
                                                            let currentCostingProfile1 = _.cloneDeep(
                                                                project.CostingProfiles[costingProfileIndex]
                                                            );
                                                            currentCostingProfile1.CountrySpecs.forEach(
                                                                (c, i) => {
                                                                    c.MethodologySpecs.forEach((m, mi) => {
                                                                        {
                                                                            currentCostingProfile1.CountrySpecs[
                                                                                i
                                                                            ].MethodologySpecs[mi].CostingType = 0;
                                                                        }
                                                                    });
                                                                }
                                                            );
                                                            dispatch(
                                                                currentCostingActions.saveCostingProfileDraft(
                                                                    currentCostingProfile1
                                                                )
                                                            );
                                                            const project1 = update(project, {
                                                                CostingProfiles: {
                                                                    0: {
                                                                        $set: currentCostingProfile1,
                                                                    },
                                                                },
                                                            });
                                                            axios
                                                                .put(
                                                                    "costingprofiles/" +
                                                                    currentCostingProfile1.id,
                                                                    currentCostingProfile1
                                                                )
                                                                .then((res) => {
                                                                    dispatch(
                                                                        currentCostingActions.updateProfile(
                                                                            currentCostingProfile1
                                                                        )
                                                                    );
                                                                    toastr.success(
                                                                        "Cost Type is Reset",
                                                                        res.data.message
                                                                    );
                                                                    dispatch(
                                                                        currentProjectActions.saveProject({
                                                                            ...project1,
                                                                            CostingType: 0,
                                                                            Mode: "",
                                                                        })
                                                                    );

                                                                    dispatch(
                                                                        setCostingStatus(
                                                                            {
                                                                                ...costingStatus,
                                                                                showSheetsCosts: false,
                                                                                showManualCostEntry: false,
                                                                                showCommercialCosts: false,
                                                                                showCostingInput: false,
                                                                            },
                                                                            history.push("/proposal-new")
                                                                        )
                                                                    );
                                                                });
                                                            dispatch(recordLoadEnd());
                                                        }}
                                                    >
                                                        Update Costing
                                                    </Button>
                                                    <Button
                                                        onClick={() =>
                                                            setresetcostModelState(!resetcostmodelState)
                                                        }
                                                    >
                                                        Cancel
                                                    </Button>
                                                </ModalFooter>
                                            </Modal>
                                            <Col md={10}></Col>
                                            <Col md={2}>
                                                {
                                                    <Button
                                                        onClick={() => {
                                                            //   let clone = _.cloneDeep(currentCostingProfile);
                                                            //   clone["updateCost"] = true;
                                                            //   dispatch({
                                                            //     type: "UPDATE_NEW_COSTING",
                                                            //     currentCostingProfile: clone,
                                                            //   });
                                                            setShowCostMethod(true)
                                                        }}
                                                        style={{ marginBottom: "10px" }}
                                                    >
                                                        Update Costing Option
                                                    </Button>
                                                }
                                            </Col>
                                        </Row>
                                        {
                                            <Row>
                                                <Col lg="12">
                                                    <Card>
                                                        <CardHeader>
                                                            <CardTitle tag="h5" className="my-2">
                                                                <Row>
                                                                    <Col md={11}>
                                                                        <Label>Project Details</Label>
                                                                    </Col>
                                                                    <Col md={1}>
                                                                        <FontAwesomeIcon
                                                                            id="collapseProjectDetails"
                                                                            icon={
                                                                                "#collapseProjectDetails"
                                                                                    ? faChevronDown
                                                                                    : faChevronUp
                                                                            }
                                                                            href="#collapseProjectDetails"
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </CardTitle>
                                                        </CardHeader>
                                                        <UncontrolledCollapse
                                                            toggler="#collapseProjectDetails"
                                                            defaultOpen={true}
                                                        >
                                                            <CardBody>
                                                                {/* {JSON.stringify(project)} */}
                                                                <Form>
                                                                    <Row form>
                                                                        <Col md={3}>
                                                                            <FormGroup>
                                                                                <Label>Project ID</Label>
                                                                                <Input
                                                                                    type="text"
                                                                                    name="id"
                                                                                    value={project.ProjectId}
                                                                                    disabled
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col md={3}>
                                                                            <FormGroup>
                                                                                <Label>Single/Multiple Country</Label>
                                                                                <Input
                                                                                    type="text"
                                                                                    name="IsSingleCountry"
                                                                                    value={
                                                                                        project.CostingProfiles &&
                                                                                            project.CostingProfiles.length >
                                                                                            0 &&
                                                                                            project?.CostingProfiles[costingProfileIndex]
                                                                                                ?.IsMultiCountry == false
                                                                                            ? "Single Country"
                                                                                            : "Multi Country"
                                                                                    }
                                                                                    disabled
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>

                                                                        <Col md={3}>
                                                                            <FormGroup>
                                                                                <Label>Commissioning Country</Label>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control w-100"
                                                                                    style={{ height: "2rem" }}
                                                                                    name="CommissioningCountry"
                                                                                    value={
                                                                                        project.CommissioningCountry &&
                                                                                            project.CommissioningCountry !=
                                                                                            "" &&
                                                                                            project.CostingProfiles &&
                                                                                            project.CostingProfiles.length >
                                                                                            0 &&
                                                                                            project.CostingProfiles[costingProfileIndex]
                                                                                                ?.ProfileSetting?.CurrenciesData &&
                                                                                            project.CostingProfiles[costingProfileIndex]
                                                                                                ?.ProfileSetting?.CurrenciesData
                                                                                                .length > 0
                                                                                            ? project.CostingProfiles[costingProfileIndex]?.ProfileSetting?.CurrenciesData.filter(
                                                                                                (comm) =>
                                                                                                    comm.Code ==
                                                                                                    project.CommissioningCountry
                                                                                            )[0].Label
                                                                                            : ""
                                                                                    }
                                                                                    disabled
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col md={3}>
                                                                            <FormGroup>
                                                                                <Label>
                                                                                    Deadline For Submission (*){" "}
                                                                                </Label>
                                                                                <Input
                                                                                    type="datetime-local"
                                                                                    name="Deadline"
                                                                                    defaultValue={project.CostingProfiles[costingProfileIndex]?.Deadline}
                                                                                    min={CurrentDate}
                                                                                    onChange={(e) => (e.target.value >= CurrentDate) ?
                                                                                        adddeadline(e, "deadline") : restricteddate(e, project.Deadline)
                                                                                    }
                                                                                    disabled={
                                                                                        (project.CostingProfiles[costingProfileIndex]?.Deadlinestatus == "close" || render == true)
                                                                                            ? true
                                                                                            : false
                                                                                    }
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col md={3}>
                                                                            <Label>Background</Label>
                                                                            <FormGroup>
                                                                                <Input
                                                                                    type="textarea"
                                                                                    name="Background"
                                                                                    value={
                                                                                        project.ProjectBackground || ""
                                                                                    }
                                                                                    disabled
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col md={3}>
                                                                            <Label>Objectives</Label>
                                                                            <FormGroup>
                                                                                <Input
                                                                                    type="textarea"
                                                                                    name="Objectives"
                                                                                    value={
                                                                                        project.ResearchObjectives || ""
                                                                                    }
                                                                                    disabled
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col md={2}>
                                                                            {/* {UpdateCloseBidding()} */}
                                                                            <Label></Label>
                                                                            {project.CostingProfiles[costingProfileIndex] &&
                                                                                project.CostingProfiles[costingProfileIndex]
                                                                                    .CountrySpecs[0].MethodologySpecs
                                                                                    .length > 0 &&
                                                                                project.CostingProfiles[costingProfileIndex]
                                                                                    .CountrySpecs[0].MethodologySpecs[0]
                                                                                    .VendorCostingOptionData &&
                                                                                project.CostingProfiles[costingProfileIndex]
                                                                                    .CountrySpecs[0].MethodologySpecs[0]
                                                                                    .VendorCostingOptionData.length > 0 &&
                                                                                (project?.CostingProfiles[costingProfileIndex]?.CountrySpecs?.flatMap(cs => cs?.MethodologySpecs?.flatMap(ms => ms?.Vendors?.flatMap(vs => vs?.mail)))).filter(f => f).length > 0
                                                                                // CloseBidding 
                                                                                && (project.CostingProfiles[costingProfileIndex]?.Deadlinestatus != "close") && !render && (
                                                                                    <button
                                                                                        className="btn bg-danger text-white "
                                                                                        style={{ marginTop: "1.7rem" }}
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            adddeadline(e, "deadlinestatus");
                                                                                        }}
                                                                                    >
                                                                                        Close Bidding
                                                                                    </button>
                                                                                )}
                                                                        </Col>

                                                                    </Row>
                                                                </Form>
                                                            </CardBody>
                                                        </UncontrolledCollapse>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        }
                                        <Row>
                                            <Col lg="12">
                                                {
                                                    <Card>
                                                        <CardHeader>
                                                            <CardTitle tag="h5" className="my-2">
                                                                <Row>
                                                                    <Col md={11}>
                                                                        <Label>Costing Details</Label>
                                                                    </Col>
                                                                    <Col md={1}>
                                                                        {/* <FontAwesomeIcon
                                                                            id="collapseProjectDetails"
                                                                            icon={faChevronDown}
                                                                            href="#collapseProjectDetails"
                                                                        /> */}
                                                                        <Link  href="#collapseProjectDetails">
                                                                        <Down  id="collapseProjectDetails"/>

                                                                        </Link>
                                                                    </Col>
                                                                </Row>
                                                            </CardTitle>
                                                        </CardHeader>
                                                        <UncontrolledCollapse
                                                            toggler="#collapseProjectDetails"
                                                            defaultOpen={true}
                                                        >
                                                            <CardBody>
                                                                <Col
                                                                    md={12}
                                                                    className={"countryTabs tab-vertical"}
                                                                >
                                                                    <div className={"tab"}>
                                                                        <Nav tabs>
                                                                            {project.CostingProfiles.length > 0 &&
                                                                                _.last(project.CostingProfiles)
                                                                                    .CountrySpecs &&
                                                                                _.last(
                                                                                    project.CostingProfiles
                                                                                ).CountrySpecs.map(
                                                                                    (fc, indxFC) =>
                                                                                        fc.MethodologySpecs.filter(
                                                                                            (sm) => sm.CostingType == 3 && !sm.NotApplicable
                                                                                        ).length > 0 && (
                                                                                            <NavItem key={fc.value}>
                                                                                                <NavLink
                                                                                                    className={classnames({
                                                                                                        active:
                                                                                                            activeTabFC === indxFC,
                                                                                                    })}
                                                                                                    onClick={() => {
                                                                                                        toggle(indxFC);
                                                                                                    }}
                                                                                                >
                                                                                                    {<b> {fc.CountryName}</b>}
                                                                                                </NavLink>
                                                                                            </NavItem>
                                                                                        )
                                                                                )}
                                                                        </Nav>
                                                                        <TabContent activeTab={activeTabFC}>
                                                                            <Row>
                                                                                <Col
                                                                                    md={12}
                                                                                    className={"methodologyTabs"}
                                                                                >
                                                                                    <Nav tabs>
                                                                                        {project.CostingProfiles.length >
                                                                                            0 &&
                                                                                            _.last(project.CostingProfiles)
                                                                                                .CountrySpecs &&
                                                                                            _.last(
                                                                                                project.CostingProfiles
                                                                                            ).CountrySpecs[
                                                                                                activeTabFC
                                                                                            ].MethodologySpecs.map(
                                                                                                (sm, indxSM) =>
                                                                                                    !sm.NotApplicable &&
                                                                                                    sm.CostingType == 3 && (
                                                                                                        <NavItem key={sm.value}>
                                                                                                            <NavLink
                                                                                                                className={classnames({
                                                                                                                    active:
                                                                                                                        activeTabSM ===
                                                                                                                        indxSM,
                                                                                                                })}
                                                                                                                onClick={() => {
                                                                                                                    toggleSM(indxSM);
                                                                                                                }}
                                                                                                            >
                                                                                                                <div
                                                                                                                    style={{
                                                                                                                        display: "flex",
                                                                                                                        justifyContent:
                                                                                                                            "space-between",
                                                                                                                        alignItems:
                                                                                                                            "center",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <div>
                                                                                                                        {sm.NotApplicable}
                                                                                                                        <b>{sm.Label}</b>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </NavLink>
                                                                                                        </NavItem>
                                                                                                    )
                                                                                            )}
                                                                                    </Nav>
                                                                                </Col>
                                                                            </Row>

                                                                            <TabContent
                                                                                activeTab={activeTabSM}
                                                                                style={{ padding: "0.25" }}
                                                                            >
                                                                                {project.CostingProfiles.length > 0 &&
                                                                                    _.last(project.CostingProfiles)
                                                                                        .CountrySpecs &&
                                                                                    _.last(
                                                                                        project.CostingProfiles
                                                                                    ).CountrySpecs[
                                                                                        activeTabFC
                                                                                    ].MethodologySpecs.map(
                                                                                        (sm, indxSM) =>
                                                                                            !sm.NotApplicable &&
                                                                                            activeTabSM === indxSM && (
                                                                                                <>
                                                                                                    <TabPane
                                                                                                        tabId={indxSM}
                                                                                                        key={sm.value}
                                                                                                    >
                                                                                                        {sm.CostingOptions &&
                                                                                                            sm.CostingType == 3 && (

                                                                                                                <Card style={{ overflow: "scroll" }}><CardBody>
                                                                                                                    <Table
                                                                                                                        className="table table-stripped"
                                                                                                                        size="sm"
                                                                                                                        bordered
                                                                                                                        style={{
                                                                                                                            backgroundColor:
                                                                                                                                "white",
                                                                                                                            maxWidth: "100%",
                                                                                                                            width: "auto",
                                                                                                                            overflow: "scroll"

                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <tr>
                                                                                                                            <th
                                                                                                                                style={{
                                                                                                                                    display: "flex",
                                                                                                                                    justifyContent:
                                                                                                                                        "space-between",
                                                                                                                                    alignItems:
                                                                                                                                        "center",
                                                                                                                                    minWidth:
                                                                                                                                        "250px",
                                                                                                                                    maxWidth:
                                                                                                                                        "250px",
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                Costing Parameter
                                                                                                                            </th>
                                                                                                                            {sm.CostingOptions.map(
                                                                                                                                // (header) => header.Selected==true &&(
                                                                                                                                (header) => (
                                                                                                                                    header.Selected &&
                                                                                                                                    <th>
                                                                                                                                        {header.Name}
                                                                                                                                    </th>
                                                                                                                                )
                                                                                                                            )}
                                                                                                                        </tr>
                                                                                                                        {sm.CostingOptions &&
                                                                                                                            sm.CostingOptions[0]
                                                                                                                                ?.CostingOptionSchema &&
                                                                                                                            sm.CostingOptions[0].CostingOptionSchema?.map(
                                                                                                                                (b) => (
                                                                                                                                    <tr>
                                                                                                                                        <td>
                                                                                                                                            {b.label}
                                                                                                                                        </td>
                                                                                                                                        {sm.CostingOptions.map(
                                                                                                                                            //  ( header) => header.Selected==true &&(
                                                                                                                                            (header) => (
                                                                                                                                                header.Selected &&
                                                                                                                                                <td>
                                                                                                                                                    {Array.isArray(
                                                                                                                                                        header
                                                                                                                                                            .CostingOptionData[
                                                                                                                                                        b.id
                                                                                                                                                        ]
                                                                                                                                                    )
                                                                                                                                                        ? header.CostingOptionData[
                                                                                                                                                            b
                                                                                                                                                                .id
                                                                                                                                                        ]
                                                                                                                                                            .map(
                                                                                                                                                                (
                                                                                                                                                                    cd
                                                                                                                                                                ) =>
                                                                                                                                                                    cd.label
                                                                                                                                                            )
                                                                                                                                                            .toString()
                                                                                                                                                        : typeof header
                                                                                                                                                            .CostingOptionData[
                                                                                                                                                            b
                                                                                                                                                                .id
                                                                                                                                                        ] ===
                                                                                                                                                            "object"
                                                                                                                                                            ? header
                                                                                                                                                                .CostingOptionData[
                                                                                                                                                                b
                                                                                                                                                                    .id
                                                                                                                                                            ]
                                                                                                                                                                .value
                                                                                                                                                            : header
                                                                                                                                                                .CostingOptionData[
                                                                                                                                                            b
                                                                                                                                                                .id
                                                                                                                                                            ]}
                                                                                                                                                </td>
                                                                                                                                            )
                                                                                                                                        )}
                                                                                                                                    </tr>
                                                                                                                                )
                                                                                                                            )}
                                                                                                                    </Table>
                                                                                                                </CardBody></Card>
                                                                                                            )}

                                                                                                        <RFQTable
                                                                                                            project={project}
                                                                                                            sm={sm}
                                                                                                            indexCountry={activeTabFC}
                                                                                                            indexMethodology={
                                                                                                                activeTabSM
                                                                                                            }
                                                                                                        />
                                                                                                        <SUDTable
                                                                                                            project={project}
                                                                                                            sm={sm}
                                                                                                            indexCountry={activeTabFC}
                                                                                                            indexMethodology={
                                                                                                                activeTabSM
                                                                                                            }
                                                                                                        />
                                                                                                        {
                                                                                                            <BiddingDetails
                                                                                                                Vendor={vendor}
                                                                                                                indexCountry={
                                                                                                                    activeTabFC
                                                                                                                }
                                                                                                                setCCProfile={setCCProfile}
                                                                                                                cCProfile={cCProfile}
                                                                                                                sm={sm}
                                                                                                                indexMethodology={
                                                                                                                    activeTabSM
                                                                                                                }
                                                                                                            ></BiddingDetails>
                                                                                                        }
                                                                                                    </TabPane>
                                                                                                </>
                                                                                            )
                                                                                    )}
                                                                            </TabContent>
                                                                        </TabContent>
                                                                    </div>
                                                                </Col>
                                                            </CardBody>
                                                        </UncontrolledCollapse>
                                                    </Card>
                                                }
                                            </Col>
                                        </Row>
                                    </>
                                }
                            </Container>
                            {
                                <Col className="justify-content-end">
                                    <Button className="Submit-vendor" color="primary" onClick={Savebiddingrequest} disabled={onsubmit}>
                                        Submit
                                        {props.saving && (
                                            <FontAwesomeIcon
                                                class="saving-btn"
                                                icon={faSpinner}
                                                fixedWidth
                                            />
                                        )}
                                    </Button>
                                    <Button className="copy-to-all-market" style={{ marginLeft: "20px" }} color="primary" onClick={() => navigateToDashboard()}>
                                        Back
                                    </Button>
                                    <Button
                                        style={{ marginLeft: "20px" }}
                                        onClick={() => {
                                            saveasdraft()
                                        }}
                                        className="savedraft"
                                        color="primary"
                                    >
                                        Save as Draft
                                    </Button>
                                </Col>
                            }
                        </>
                    )}

                    {isSummary && (
                        <ProposalBiddingSelectVendors
                            fromDashboard={fromDashboard}
                            setoncostview={setoncostview}
                            oncostview={oncostview}
                            setsaveselectedrows={setsaveselectedrows}
                            saveselectedrows={saveselectedrows}
                            id={props.projectId}
                            project={project}
                        ></ProposalBiddingSelectVendors>
                    )}


                    {
                        <Button
                            color="primary"
                            onClick={() => fetchBack()}
                            className={isSummary ? "" : "validateandsave"}
                            style={{ marginLeft: "20px" }}
                            disabled={!(project.CostingProfiles[costingProfileIndex]?.CountrySpecs?.flatMap(x => x.MethodologySpecs?.flatMap(y => y?.Vendors ? y.Vendors.length > 0 ? true : false : false))?.filter(ele => ele == true).length > 0 && project.CostingProfiles[costingProfileIndex]?.Deadline)}
                        >
                            {isSummary ? "Back" : "Summary View"}
                        </Button>
                    }

                    {saveselectedrows.length > 0 && isSummary && (
                        <Button
                            color="primary"
                            onClick={() => setoncostview(!oncostview)}
                            style={{ marginLeft: "20px" }}
                        >
                            Selected Vendor
                        </Button>
                    )}
                </>
            )}
        </Layout>
    );
}
export default VendorBiddingForm;
