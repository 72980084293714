import React, { useEffect, useState } from "react";
import { AppBar, IconButton, Toolbar, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
// import { recordLoadEnd, recordLoadStart } from "../../redux/actions/appActions";
import { useDispatch, useSelector } from "react-redux";
// import Axios from "../../axios-interceptor";
import { setNewNotification } from "../../redux/actions/notificationActions";

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: 1000,
    display: "none",
    marginBottom: "10px",
  },
  appBarNew: {
    bottom: "auto",
    position: "sticky",
    zIndex: 1000,
    backgroundColor: "yellow",
    border: "4px solid red",
    display: "flex",
    marginBottom: "10px",
    top:"91px"
  },
  toolbar: {
    justifyContent: "center",
  },
  announcementText: {
    color: "red",
    fontWeight: "bold",
  },
}));

const AnnouncementBar = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const hasNewNotification = useSelector((state) => state.notificationReducer.NewNotification);
  const message = useSelector((state) => state.notificationReducer.Message);
  // const [isFilterContainerPresent, setIsFilterContainerPresent] = useState(false);

  useEffect(() => {
    // if(document.querySelector('.content > a') || document.querySelector('.content .card .card-header')){
    //   setIsFilterContainerPresent(true);
    // } else {
    //   const timer = setTimeout(() => {
    //     const filterContainer = document.querySelector('.content .filter-container') ;
    //     if(filterContainer) {
    //       setIsFilterContainerPresent(true);
    //     }
    //   }, 3000);
    //   return () => clearTimeout(timer);
    // }
    const mainDiv = document.querySelector('.main header');
    if(message && mainDiv && window.getComputedStyle(mainDiv).display !== 'none') {
      const topBar = document.querySelector('.pagination-dashboard');
      const tableHeader = document.querySelector('.sticky-top-table-next');
      if(topBar && tableHeader) {
        topBar.style.top = '160px';
        tableHeader.classList.add('sticky-top-table-next-alternate');
      }
    }
  }, []);

  // const validateDatePlusHours = (date, validity) => {
  //   const newDate = new Date(date);
  //   newDate.setHours(newDate.getHours() + validity);
  //   const now = new Date();
  //   console.log(newDate, now);
  //   return newDate > now;
  // };

  const onClose = () => {
    // localStorage.setItem("isAlertClosed", true);
    // setIsVisible(false);
    dispatch(setNewNotification(false));
    const mainDiv = document.querySelector('.main');
    if(mainDiv && mainDiv.querySelector('header')) {
      const topBar = document.querySelector('.pagination-dashboard');
      const tableHeader = document.querySelector('.sticky-top-table-next-alternate');
      if(topBar && tableHeader) {
        topBar.style.top = '91px';
        tableHeader.classList.remove('sticky-top-table-next-alternate');       
      }
    }
  };

  return (
    <AppBar
      className={!hasNewNotification || message === '' ? classes.appBar : classes.appBarNew}
    >
      <Toolbar className={classes.toolbar}>
        <Typography variant="h6" className={classes.announcementText}>
          <p dangerouslySetInnerHTML={{__html:message}}>
          </p>
        </Typography>
        <IconButton edge="end" color="red" onClick={onClose} style={{marginLeft:"auto"}}>
          <FontAwesomeIcon icon={faTimes} />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default AnnouncementBar;