import _ from "lodash";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import DatePicker from "react-datepicker";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Label,
  Row,
  Button,
  Modal,
  ModalBody,
} from "reactstrap";
import Axios from "../../axios-interceptor";
import { toastr } from "react-redux-toastr";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { recordLoadEnd, recordLoadStart } from "../../redux/actions/appActions";
import { Link } from "react-router-dom";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
// import logo from "../assets/img/avatars/modal-logo.png";
import logo from "../../assets/img/avatars/modal-logo.png";

function ProjectScheduleAdjustment({
  profile,
  project,
  setScheduleDate,
  isScheduleDates,
}) {
  const [selectedMethodologies, setSelectedMethodologies] = useState([]);
  const [wbsNumbers, setWbsNumbers] = useState([]);
  const [scheduleDate, setScheduleDates] = useState({});
  const [historyData, setHistoryData] = useState([]);
  const [isHistoryOpen, setHistoryOpen] = useState(false);
  const [historyDatatoDisplay, setHistoryDatatoDisplay] = useState([]);

  const codeLabels = useSelector(({ codeLabels }) => codeLabels);
  const recordloading = useSelector(({ app }) => app.recordloading);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(project);
    let meths = _.cloneDeep(selectedMethodologies);
    if (profile?.id && project?.id)
    {
      profile?.CountrySpecs.map(cs=>cs?.MethodologySpecs.map(ms =>{
        if(!ms.NotApplicable && !meths.filter(sm=>sm.Code == ms.Code).length)
        {
          meths.push({Code:ms.Code,Label:ms.Label,id:ms.id})
        }
            }))
            console.log(meths)
            setSelectedMethodologies(meths)
            fetchSchedueDates();
    }
    //   profile?.CountrySpecs.map((cs) =>
    //     cs?.MethodologySpecs.map((ms) => {
    //       let opportunityLineItemDetails = [];
    //       project.ContractDetails.forEach((cd) => {
    //         opportunityLineItemDetails = [
    //           ...opportunityLineItemDetails,
    //           ...cd.opportunityLineItemDetails,
    //         ];
    //       });
    //       let lineItemIds = _.uniq(
    //         ms.CostingOptions.filter((co) => co.CoSelectedForSummary).map(
    //           (co) => co.OpportunityLineItemID
    //         )
    //       );
    //       if (opportunityLineItemDetails.length) {
    //         if (
    //           opportunityLineItemDetails
    //             .filter((ol) =>
    //               _.includes(lineItemIds, ol.OpportunityLineItemID)
    //             )
    //             .every((ol) => ol.WBSNumber)
    //         ) {
    //           let _wbs = lineItemIds.map(
    //             (li) =>
    //               opportunityLineItemDetails.find(
    //                 (ol) => ol.OpportunityLineItemID === li
    //               )?.WBSNumber
    //           );

    //           if (
    //             !ms.NotApplicable &&
    //             !meths.filter((sm) => sm.Code === ms.Code).length
    //           ) {
    //             meths.push({
    //               Code: ms.Code,
    //               Label: ms.Label,
    //               id: ms.id,
    //               wbsnumbers: _wbs,
    //             });
    //           }
    //         } else {
    //           toastr.error("WBS is Missing");
    //         }
    //       } else {
    //         toastr.error("Opportinty Details Missing this Project");
    //       }
    //     })
    //   );

    // console.log(meths);
    // setSelectedMethodologies(meths);
  }, []);
  const fetch = async () => {
    await Axios.get("costingProfiles/gethistoryDateAdjustments/" + profile.id, {
      headers: { "auth-token": localStorage.getItem("auth-token") },
    })
      .then((data) => {
        setHistoryDatatoDisplay(data.data.data);
        console.log(data.data.data);
      })
      .catch((err) => {
        console.log("History Data Loadig Failed");
      });
  };

  useEffect(() => {
    fetch();
  }, []);

  const fetchSchedueDates = async () => {
    await Axios.get(`/deliveries/getSchedule/${profile.id}`).then((data) => {
      console.log(data.data);
      let tempDAtes ={}

      if (data.data.data.length) {
        let dates =  data.data.data.map((d,i)=>{
          
          tempDAtes[d.SubmethodologyLabel] = {...tempDAtes[d.SubmethodologyLabel],"QueDate":new Date(d.QuestionnaireDate),"FWStart":new Date(d.FWStartDate),"FWEnd":new Date(d.FWEndDate)}
        
       })
       console.log(tempDAtes)
       setScheduleDates(tempDAtes) 
      
      } else {
        let meths = _.cloneDeep(selectedMethodologies)

        profile?.CountrySpecs.map(cs=>cs?.MethodologySpecs.map(ms =>{
          if(!ms.NotApplicable && !meths.filter(sm=>sm.Code == ms.Code).length)
          {
            meths.push({Code:ms.Code,Label:ms.Label,id:ms.id})
          }
              }))
          
              console.log(meths)
              setSelectedMethodologies(meths)  // setSelectedWBS(_.head(wbsnumbers).value)
          //   setSelectedProduct(_.head(wbsnumbers).product)
        }
      }
    )}

  const onChangeHandlerQueDates = (e, id, sm) => {
    let tempDates = _.cloneDeep(scheduleDate);
    let _historyData = _.cloneDeep(historyData);
    let rec = _historyData.find(
      (wb) =>  wb.SubmethodologyLabel == sm
    );

    if (rec) {
      _historyData = _historyData.map((ele) => {
        if (
          ele.SubmethodologyLabel == sm &&
          !ele.OldQuestionnaireDate
        ) {
          return {
            ...ele,
            OldQuestionnaireDate: tempDates[sm][id],
          };
        } else return ele;
      });
    } else {
      _historyData.push({
        OldQuestionnaireDate: tempDates[sm][id],
        SubmethodologyLabel: sm,
        OldFWEndDate: tempDates[sm].FWEnd,
        OldFWStartDate: tempDates[sm][id].FWStart,
        CostingProfileId: profile.id,
      });
    }
    setHistoryData((prev) => [...prev, ..._historyData]);

    // if (e < new Date(profile.CommissionedDate)) {
    //   let _sm = tempDates[sm]
    //     ? { ...tempDates[sm], [id]: null }
    //     : { ...tempDates[sm], [id]: null };
    //   tempDates = { ...tempDates, [sm]: _sm };

    //   setScheduleDates(tempDates);

    //   toastr.info(
    //     "Questionnare Date Should be Greater than Commissioning Date"
    //   );
    // } 
    // else
     if (
      tempDates[sm].FWStart &&
      tempDates[sm].FWStart.getTime()<= e.getTime()
    ) {
     
      toastr.info(
        "QuestionnaireDate should be less than FW Start Date"
      );
    } else {
      let tempDates = _.cloneDeep(scheduleDate);
      let temp = {
        SubmethodologyLabel: sm,
        QuestionnaireDate: new Date(e),
        OldQuestionnaireDate: tempDates[sm][id],
        CostingProfileId: profile.id,
      };

      let _historyData = _.cloneDeep(historyData);
      let rec = _historyData.find(
        (wb) => wb.SubmethodologyLabel == sm
      );
      if (rec) {
        _historyData = _historyData.map((ele) => {
          if (
            ele.SubmethodologyLabel == sm &&
            ele.OldQuestionnaireDate
          ) {
            return { ...ele, QuestionnaireDate: e };
          } else if (
            ele.SubmethodologyLabel == sm &&
            !ele.OldQuestionnaireDate
          ) {
            return {
              ...ele,
              QuestionnaireDate: e,
              OldQuestionnaireDate: tempDates[sm][id],
            };
          } else return ele;
        });
      } else {
        _historyData.push(temp);
      }
      console.log(_historyData);

      setHistoryData(_historyData);
      let _sm = tempDates[sm]
        ? { ...tempDates[sm], [id]: e }
        : { ...tempDates[sm], [id]: e };
      tempDates = { ...tempDates, [sm]: _sm };
      // tempDates[sm] = {...tempDates[sm],WBS:selectedWBS}
      console.log(tempDates);
      setScheduleDates(tempDates);
    }
  };

  const onChangeHandlerFWStart = (e, id, sm) => {
    let tempDates = _.cloneDeep(scheduleDate);

    let _historyData = _.cloneDeep(historyData);
    let rec = _historyData.find(
      (wb) =>  wb.SubmethodologyLabel == sm
    );
    if (rec) {
      _historyData = _historyData.map((ele) => {
        if (
          ele.SubmethodologyLabel == sm &&
          !ele.OldFWStartDate
        ) {
          return { ...ele, OldFWStartDate: tempDates[sm][id] };
        } else return ele;
      });
    } else {
      _historyData.push({
        OldFWStartDate: tempDates[sm][id],
        SubmethodologyLabel: sm,
        OldFWEndDate: tempDates[sm].FWEnd,

        CostingProfileId: profile.id,
      });
    }

    setHistoryData(_historyData);

    if (tempDates && tempDates[sm].QueDate) {
      if (e.getTime() <= tempDates[sm].QueDate.getTime()) {
        let _sm = tempDates
          ? { ...tempDates[sm], [id]: null }
          : { ...tempDates[sm], [id]: null };
        tempDates = { ...tempDates, [sm]: _sm };

        setScheduleDates(tempDates);
        toastr.info(
          "FW Start Date should be Greater Than QuestionnaireDate"
        );
      } 
      
      else if (
        tempDates[sm].FWEnd &&
        tempDates[sm].FWEnd.getTime()<= e.getTime()
      ) {
        // let _sm = tempDates[selectedWBS]
        //   ? { ...tempDates[selectedWBS][sm],FWEnd:null}
        //   : { ...tempDates[sm], FWStart: null, };
        // tempDates[selectedWBS] = { ...tempDates[selectedWBS], [sm]: _sm };
  
        // setScheduleDates(tempDates);
  
        toastr.info("FW Start Date should be less than FW Emd Date");
      }
      else {
        let tempDates = _.cloneDeep(scheduleDate);
        let temp = {
          SubmethodologyLabel: sm,
          FWStartDate: new Date(e),
          OldFWStartDate: tempDates[sm][id],
          CostingProfileId: profile.id,
        };
        let _historyData = _.cloneDeep(historyData);
        let rec = _historyData.find(
          (wb) =>  wb.SubmethodologyLabel == sm
        );

        if (rec) {
          _historyData = _historyData.map((ele) => {
            if (
             
              ele.SubmethodologyLabel == sm &&
              ele[`Old${id}Date`]
            ) {
              return { ...ele, FWStartDate: e };
            } else if (
              ele.SubmethodologyLabel == sm &&
              !ele[`Old${id}Date`]
            ) {
              return {
                ...ele,
                FWStartDate: e,
                OldFWStartDate: tempDates[sm][id],
              };
            } else return ele;
          });
        } else {
          _historyData.push(temp);
        }

        setHistoryData(_historyData);
        console.log(_historyData);

        let _sm = tempDates[sm]
          ? { ...tempDates[sm], [id]: e }
          : { ...tempDates[sm], [id]: e };

        tempDates = { ...tempDates, [sm]: _sm };

        setScheduleDates(tempDates);
      }
    } else {
      let _sm = tempDates[sm]
        ? { ...tempDates[sm], [id]: null }
        : { ...tempDates[sm], [id]: null };
      tempDates = { ...tempDates, [sm]: _sm };

      setScheduleDates(tempDates);
      toastr.error("Please Select Questionnaire Date");
    }
    console.log(tempDates);
  };

  const onChangeHandlerFwEnd = (e, id, sm, ) => {
    let tempDates = _.cloneDeep(scheduleDate);

    let _historyData = _.cloneDeep(historyData);
    let rec = _historyData.find(
      (wb) =>  wb.SubmethodologyLabel == sm
    );
    if (rec) {
      _historyData = _historyData.map((ele) => {
        if (
          ele.SubmethodologyLabel == sm &&
          !ele[`Old${id}Date`]
        ) {
          return { ...ele, OldFWEndDate: tempDates[sm][id] };
        } else return ele;
      });
    } else {
      _historyData.push({
        OldFWEndDate: tempDates[sm][id],
        SubmethodologyLabel: sm,
        CostingProfileId: profile.id,
      });
    }
    setHistoryData(_historyData);

    if (tempDates[sm] && tempDates[sm].FWStart) {
      if (e > tempDates[sm].FWStart) {
        let tempDates = _.cloneDeep(scheduleDate);
        let temp = {
          SubmethodologyLabel: sm,
          FWEndDate: new Date(e),
          OldFWEndDate: tempDates[sm][id],
          CostingProfileId: profile.id,
        };

        let _historyData = _.cloneDeep(historyData);
        let rec = _historyData.find(
          (wb) => wb.SubmethodologyLabel == sm
        );
        if (rec) {
          _historyData = _historyData.map((ele) => {
            if (
              ele.SubmethodologyLabel == sm &&
              ele[`Old${id}Date`]
            ) {
              return { ...ele, FWEndDate: e };
            } else if (
              ele.SubmethodologyLabel == sm &&
              !ele[`Old${id}Date`]
            ) {
              return {
                ...ele,
                FWEndDate: e,
                OldFWEndDate: tempDates[sm][id],
              };
            } else return ele;
          });
        } else {
          _historyData.push(temp);
        }

        setHistoryData(_historyData);
        console.log(_historyData);

        let _sm = tempDates[sm]
          ? { ...tempDates[sm], [id]: e }
          : { ...tempDates[sm], [id]: e };
        tempDates= { ...tempDates, [sm]: _sm };

        setScheduleDates(tempDates);
      } else {
        let _sm = tempDates[sm]
          ? { ...tempDates[sm], [id]: null }
          : { ...tempDates[sm], [id]: null };
        tempDates = { ...tempDates, [sm]: _sm };

        setScheduleDates(tempDates);
        toastr.info("FW End Date should be Greater Than FW Start Date");
      }
    } else {
      let _sm = tempDates[sm]
        ? { ...tempDates[sm], [id]: null }
        : { ...tempDates[sm], [id]: null };
      tempDates = { ...tempDates, [sm]: _sm };

      setScheduleDates(tempDates);
      toastr.error("Please Select FW Start Date");
      console.log(tempDates);
    }
  };

  const saveDates = async() => {

    dispatch(recordLoadStart());

    await Axios.post("costingProfiles/historyDateAdjustments", historyData, {
      headers: { "auth-token": localStorage.getItem("auth-token") },
    })
      .then((res) => {
        toastr.success("Dates History Saved");
      })
      .catch((err) => {
        console.log("Dates History Saving Failed", err);
      });

  
if(
  selectedMethodologies.every(sm=>(scheduleDate[sm.Label] &&(scheduleDate[sm.Label]?.QueDate && scheduleDate[sm.Label]?.FWStart && scheduleDate[sm.Label]?.FWEnd))  
  ))
  {
  
  let data=  Object.keys(scheduleDate).map(
    sd=>{return ({
      "QuestionnaireDate":scheduleDate[sd].QueDate,
      "FWStartDate":scheduleDate[sd].FWStart,
      "FWEndDate":scheduleDate[sd].FWEnd,
      "CostingProfileId": profile.id,
      "WaveNumber":"1",
      "SubmethodologyLabel":sd
    })}
  )
  console.log(data)  
  let isError = false
   await Axios.post("deliveries/shcedule",data).then(
    res=>{
      dispatch(recordLoadEnd())
      toastr.success(res.data.message)
    }
   ).catch(err=>{
    isError =true
  toastr.error(err.data.Message)
  dispatch(recordLoadEnd())
  return
  
   })
  
   if(isError)
   {
    return
   }}}


  return (
    <div>
      {selectedMethodologies.length && (
        <Container>
          <Card className="ml-2 mr-2 mb-2">
            <CardHeader>
              <Row>
                <Col xs="9">
                  <CardTitle className="text-uppercase">
                    Sub Methodology Level Schedule
                  </CardTitle>
                </Col>
              </Row>
              <h5>
                <Link
                  className="float-right"
                  onClick={() => {
                    fetch();
                    setHistoryOpen(true);
                  }}
                >
                  View History
                </Link>
              </h5>{" "}
            </CardHeader>
            <CardBody>
              {/* {JSON.stringify(historyDatatoDisplay)} */}

              <table className = "table table-stripped table-bordered">
  <tr>
    <th >Sub Methodologies</th>
    <th >Final Questionnaire Delivery Date in CINTRAL</th>
   <th>
     Planned FW Start Date in CINTRAL
  
    </th>
    <th >Planned FW End Date in CINTRAL</th>

  </tr>
  {selectedMethodologies.map(sm=><tr>
    <td><Label>{sm.Label}</Label></td>
    <td>    <DatePicker
                                                                    // disabled={
                                                                    //     df.DisablingVal||disableforCS
                                                                    // }
                                                                    locale="en-GB"
                                                                    className="form-control"
                                                                    selected={
                                                                      scheduleDate && scheduleDate[sm.Label]?.QueDate?scheduleDate[sm.Label]?.QueDate:""
                                                                    }
                                                                    onChange={(e) => onChangeHandlerQueDates(e, "QueDate",sm.Label)}
                                                                    dateFormat="dd-MM-yyyy"
                                                                    placeholderText="dd-mm-yyyy"
                                                                /></td>
    <td>
    <DatePicker
                                                                    // disabled={
                                                                    //     df.DisablingVal||disableforCS
                                                                    // }
                                                                    locale="en-GB"
                                                                    className="form-control"
                                                                    selected={
                                                                      scheduleDate && scheduleDate[sm.Label]?.FWStart?scheduleDate[sm.Label]?.FWStart:""
                                                                    }
                                                                    onChange={(e) => onChangeHandlerFWStart(e, "FWStart",sm.Label)}
                                                                    dateFormat="dd-MM-yyyy"
                                                                    placeholderText="dd-mm-yyyy"
                                                                />
    </td>
    <td>
    <DatePicker
                                                                    // disabled={
                                                                    //     df.DisablingVal||disableforCS
                                                                    // }
                                                                    locale="en-GB"
                                                                    className="form-control"
                                                                    selected={
                                                                      scheduleDate && scheduleDate[sm.Label]?.FWEnd?scheduleDate[sm.Label]?.FWEnd:""
                                                                    }
                                                                    onChange={(e) => onChangeHandlerFwEnd(e, "FWEnd",sm.Label)}
                                                                    dateFormat="dd-MM-yyyy"
                                                                    placeholderText="dd-mm-yyyy"
                                                                />
    </td>
  </tr>)}
</table>
            </CardBody>
          </Card>
          <Modal
            isOpen={isHistoryOpen}
            toggle={() => setHistoryOpen(false)}
            className="modal-xl"
            centered={true}
          >
            <ModalHeader>
              <img alt="Nielsen Logo" src={logo} className="modallogo" />
              <div>
                <span className="modaltitle modalops">
                  Date Adjustment History
                </span>
              </div>
            </ModalHeader>
            <ModalBody>
              {historyDatatoDisplay.length && (
                <div
                  style={{
                    maxWidth: "inherit",
                    maxHeight: "400px",
                    overflow: "auto",
                    overflowX: "auto",
                  }}
                >
                  <table className="table table-stripped table-bordered">
                    <tr>
                      {/* <th>WBS Number</th>
                      <th>Product</th> */}
                      <th>SubMethodology</th>
                      <th>Old QuestionnaireDate</th>
                      <th>New QuestionnaireDate</th>
                      <th>Old FWStartDate</th>
                      <th>New FWStartDate</th>
                      <th>Old FWEndDate</th>
                      <th>New FWEndDate</th>
                      <th>Updated By</th>
                      <th>Updated At</th>
                    </tr>

                    {historyDatatoDisplay.map((hisData) => (
                      <tr>
                        {/* <td>{hisData.WBSNumber}</td>
                        <td>{hisData.Product ? hisData.Product : ""}</td> */}
                        <td>{hisData.SubmethodologyLabel}</td>
                        <td>
                          {hisData.OldQuestionnaireDate ? (
                            <DatePicker
                              disabled={true}
                              selected={new Date(hisData.OldQuestionnaireDate)}
                            ></DatePicker>
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          {hisData.QuestionnaireDate ? (
                            <DatePicker
                              disabled={true}
                              selected={new Date(hisData.QuestionnaireDate)}
                            ></DatePicker>
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          {hisData.OldFWStartDate ? (
                            <DatePicker
                              disabled={true}
                              selected={new Date(hisData.OldFWStartDate)}
                            ></DatePicker>
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          {hisData.FWStartDate ? (
                            <DatePicker
                              disabled={true}
                              selected={new Date(hisData.FWStartDate)}
                            ></DatePicker>
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          {hisData.OldFWEndDate ? (
                            <DatePicker
                              disabled={true}
                              selected={new Date(hisData.OldFWEndDate)}
                            ></DatePicker>
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          {hisData.FWEndDate ? (
                            <DatePicker
                              disabled={true}
                              selected={new Date(hisData.FWEndDate)}
                            ></DatePicker>
                          ) : (
                            ""
                          )}
                        </td>

                        <td>{hisData.UpdatedBY ? hisData.UpdatedBY : ""}</td>
                        <td>
                          {hisData.UpdatedAT ? (
                            <DatePicker
                              disabled={true}
                              selected={new Date(hisData.UpdatedAT)}
                            ></DatePicker>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    ))}
                  </table>
                </div>
              )}
            </ModalBody>
          </Modal>
          <Button
            onClick={() => {
              setScheduleDate(false);
            }}
            className="float-right"
            color="primary"
          >
            Close
          </Button>

          <Button
            className="float-right mr-2"
            color="primary"
            onClick={() => saveDates()}
          >
            {recordloading ? (
              <FontAwesomeIcon
                class="saving-btn icon-costingprofile"
                icon={faSpinner}
                fixedWidth
              />
            ) : null}
            Save
          </Button>
        </Container>
      )}
    </div>
  );
}

export default ProjectScheduleAdjustment;
