import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
  customFilter,
  multiSelectFilter,
  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import CardBody from "reactstrap/lib/CardBody";
import _ from "lodash";
import logo from "../../assets/img/avatars/modal-logo.png";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";


function ResetApprovalModal({ historyApprovalPopup, sethistoryApprovalPopup, historyData, getHistoryForApproval }) {
  useEffect(() => {
    getHistoryForApproval()
  }, [])
  const closePopup = () => {
    sethistoryApprovalPopup(!historyApprovalPopup)
  }
  function dateFormatter(cell) {
    return _.first(cell.split("T"));
  }
  function ProfileStatusWas(cell) {
    let data = JSON.parse(cell)
    let approverContactsData = data.approverContacts.map((val) => {
      return val
    })
    let detailsContactsData = data.detailsForApproval.map((del) => {
        return del.Label
    })
    let newData = `approvalstatus:${data.approvalStatus[0]},approvalContacts:${approverContactsData},detailsForApproval:${detailsContactsData}`
    return newData
  }
  const tableColumns = [

    // {
    //   dataField: "ProjectId",
    //   text: "Project ID",
    // },
    // {
    //     dataField: "ProfileId",
    //     text: "Profile Id",
    // },
    {
      dataField: "Reason",
      text: "Reason",
    },
    {
      dataField: "ByUser",
      text: "By User",
    },
    {
      dataField: "ProfileStatusWas",
      text: "Profile Status",
      formatter: ProfileStatusWas,
    },
    {
      dataField: "PerformedOn",
      text: "Performed On",
      formatter: dateFormatter,
    },

    {
      dataField: "ApprovalAction",
      text: "Approval Action",

    },

  ];
  return (
    <>
      <Modal
        isOpen={historyApprovalPopup}
        toggle={() => sethistoryApprovalPopup(!historyApprovalPopup)}
        centered={true}
        size="lg"
        backdrop="static"
      >
        <ModalHeader toggle={() => sethistoryApprovalPopup(!historyApprovalPopup)}>

          {/* <img src={logo} className="modallogo" /> */}

        </ModalHeader>
        <div>
          <span className="modaltitle modalops">History for Reset of Approvals</span>
        </div>
        <ModalBody>
          <ToolkitProvider
            keyField="index"
            data={historyData}
            columns={tableColumns}
          >
            {(props) => (
              <CardBody className="bg-white">
                <BootstrapTable
                  {...props.baseProps}
                  bootstrap4
                  wrapperClasses="table-responsive"
                  bordered={true}
                  filter={filterFactory()}
                  filterPosition="top"
                  striped
                  hover
                  condensed
                />
              </CardBody>
            )}
          </ToolkitProvider>
        </ModalBody>
        <ModalFooter>

          <Button
            color="secondary"
            onClick={closePopup}
          >
            Close
          </Button>{" "}

        </ModalFooter>
      </Modal>
    </>
  )
}

export default ResetApprovalModal