import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Link, useHistory } from "react-router-dom";
import * as currentCostingActions from "../../redux/actions/currentCostingActions";
import Layout from "../../layouts/Project";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import classNames from "classnames";

import Spinner from "../../components/Spinner";
import { getLabel } from "../../utils/codeLabels";
import {
    Label,
    Input,
    Button,
    Row,
    Col,
    Card,
    CardBody,
    Container,
    Collapse,
    CardHeader,
    CardTitle,
    CardText,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Badge,
    Table,
    InputGroup,
    InputGroupAddon,
    NavLink,
    NavItem,
    Nav,
    TabContent,
    TabPane,
} from "reactstrap";
import {
    faChevronRight,
    faChevronDown,
    faChevronUp,
    faExclamationCircle,
    faHourglassHalf,
    faThumbsUp,
    faThumbsDown,
} from "@fortawesome/free-solid-svg-icons";
import ProfileCostingOptions from "./ProfileCostingOptionsSummary";
import ProfitabilityOverview from "./ProfitabilityOverview";
import ProfileLevelmethodologies from "./ProfileLevelMethodologiesBreakDown";
import { getProject } from "../../redux/actions/currentProjectActions";

const ProfileSummary = () => {
    const history = useHistory()
    const currentCostingProfile = useSelector(
        ({ currentCosting }) => currentCosting.currentCostingProfile
    );
    const project = useSelector(
        ({ currentProject }) => currentProject.newProject
    );
    const [activeProfileTab, setActiveProfileTab] = useState();

    const [allTabsStatus, setTabStatus] = useState({
        isProfileCompare: true,
        isProfitableOverview: true,
        overall: false,
    });
    const codeLabels = useSelector(({ codeLabels }) => codeLabels);
    const [projectData, setProjectData] = useState(project.CostingProfiles)
    const [profileData, setProfile] = useState({})
    const [masterprofile, setMasterProfile] = useState({})
    const dispatch = useDispatch();
    const [allCostingOptions, setAllCostingOptions] = useState([])
    let pacificData = _.head(codeLabels.PacificCountriesList)?.Label;
    const [pacificMarkets] = useState(pacificData?.split(','));
    useEffect(() => {
        dispatch(getProject(currentCostingProfile.Project.ProjectId));
    }, [])
    useEffect(() => {
        setProjectData(project.CostingProfiles)
        let pData = _.head(projectData);
        console.log(pData);
        setActiveProfileTab(pData.id);
        setProfile(pData);
        setMasterProfile(pData);
        AllCostigOptions(pData);
    }, [])

    const AllCostigOptions = (profile) => {
        if (!allCostingOptions.length && profile.CountrySpecs?.length) {
            let _allCostingOptions = [];
            profile.CountrySpecs.map(cs => {
                if (cs.MethodologySpecs) {
                    cs.MethodologySpecs.map(ms => {

                        ms.CostingOptions?.filter(co => co.Selected).map(t => {
                            t?.CostingOptionSchema?.map(cso => {
                                if (t.CostingOptionData && t.CostingOptionData[cso.id]) {
                                    if (!_allCostingOptions.filter(asc => asc.id == cso.id).length) {
                                        _allCostingOptions.push(cso);
                                    }
                                }
                            });
                        });
                    });
                }
            });
            setAllCostingOptions(_allCostingOptions);
        }
    }
    const onTabchange = (costingProfileId) => {
        console.log(costingProfileId);
        setActiveProfileTab(costingProfileId);
        let filteredData = projectData.filter(x => x.id === costingProfileId)
        console.log(filteredData);
        let data = _.head(filteredData);
        setProfile(data);
        AllCostigOptions(data);
    }
    return (
        <Layout
            profileStatusToDisplay={getLabel(
                "CostingStatusOptions",
                currentCostingProfile.ProfileStatus
            )}
            projectStatusToDisplay={getLabel(
                "ProjectStatusOptions",
                currentCostingProfile.Project?.ProjectStatus
            )}
            hideProfileName={true}
        >
            {(
                <>
                    <Row className="costing-parent-component">
                        <Col>
                            <Nav tabs className="ml-4 mr-4">
                                {project.CostingProfiles.map(_costingProfile => <NavItem>
                                    <NavLink
                                        className={classNames({ active: activeProfileTab === _costingProfile.id })}
                                        onClick={() => { onTabchange(_costingProfile.id) }}
                                    >
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <div>
                                                {_costingProfile.ProfileName}
                                                <Badge
                                                    className="m-0 ml-2 h6"
                                                    bg="dark"
                                                    
                                                >
                                                    {getLabel("CostingStatusOptions"
                                                        , _costingProfile.ProfileStatus)}
                                                </Badge>
                                            </div>
                                        </div>                                        
                                    </NavLink>
                                </NavItem>)}
                            </Nav>
                            <TabContent activeTab={activeProfileTab}>
                                <TabPane tabId={activeProfileTab}>
                                    <Container>
                                        {(_.includes(pacificMarkets, project.CommissioningCountry) && project.CostingTemplateVersion != "v2.0")
                                            ?
                                            <Card className="ml-2 mr-2 mb-2">                                              
                                                <Collapse isOpen={allTabsStatus.isProfitableOverview}>
                                                    <CardBody id="profitableOverview">
                                                        <ProfileLevelmethodologies
                                                            profile={profileData}
                                                        />
                                                    </CardBody>
                                                </Collapse>
                                            </Card>
                                            :
                                            <Card className="ml-2 mr-2 mb-2">
                                                <CardHeader>
                                                    <Row>
                                                        <Col xs="10" sm="11" md="11">
                                                            <CardTitle className="mb-0">
                                                                Costing Options
                                                            </CardTitle>
                                                        </Col>
                                                    </Row>
                                                </CardHeader>
                                                <CardBody id="costingOptionsBreakdown">
                                                    <ProfileCostingOptions
                                                        profile={profileData}
                                                        allCostingOptions={allCostingOptions}
                                                    />
                                                </CardBody>
                                            </Card>
                                        }
                                    </Container>
                                </TabPane>
                            </TabContent>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        {masterprofile && masterprofile.ProfileSetting &&
                            <Col >
                                <Card className="ml-2 mr-2 mb-2">
                                    <CardHeader
                                    >
                                        <Row>
                                            <Col xs="10" sm="11" md="11">
                                                <CardTitle className="mb-0">
                                                    Profitability Overview among Profiles
                                                </CardTitle>
                                            </Col>

                                        </Row>
                                    </CardHeader>
                                    <CardBody id="profitableOverview">
                                        {/* passing masterProfile Data for calculating overheads and markup count % */}
                                        <ProfitabilityOverview
                                            profile={masterprofile}
                                        />

                                    </CardBody>
                                </Card>
                            </Col>
                        }
                    </Row>
                    <Row className="m-0 justify-content-end">
                        <Button
                            color="secondary"
                            className="mr-2 mt-3"
                            onClick={() => history.goBack()}
                        >
                            Back
                        </Button>
                    </Row>
                </>
            )}
        </Layout>
    );
}

export default ProfileSummary;
