import React from "react";

function Folderfill() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          fill="#000"
          d="M21 4.5h-8.55l-3-3H3a3 3 0 00-3 3v18h24v-15a3 3 0 00-3-3z"
        ></path>
      </g>
    </svg>
  );
}

export default Folderfill;