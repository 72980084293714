import React, { useState, useEffect } from "react";
import axios from "../../../axios-interceptor";
import * as currentProjectActions from "../../../redux/actions/currentProjectActions";
import _ from "lodash";
import { Key, MinusCircle, PlusCircle } from "react-feather";
import Select from "react-select";
import classnames from "classnames";
import * as currentCostingActions from "../../../redux/actions/currentCostingActions";
import { connect, useDispatch, useSelector } from "react-redux";
import update from "immutability-helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ComparisonView from "./ComparisonView";
import FinalCost from "./FinalCost";
import Spinner from "../../../components/Spinner";
import {
  faChevronDown,
  faChevronUp,
  faSpinner,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledCollapse,
} from "reactstrap";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
  Table,
  Media
} from "reactstrap";
import * as proposalActions from "../../../redux/actions/proposal/Actions";
import * as  sheetCall from "../../../utils/msSheetAPiCall";
import { pageLoadEnd, pageLoadStart, setCostingStatus, recordLoadEnd, recordLoadStart, SET_RECORDLOAD, } from "../../../redux/actions/appActions";
import { toastr } from "react-redux-toastr";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
  textFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import cellEditFactory from "react-bootstrap-table2-editor";
import { setCurrentProject } from "../../../redux/actions/currentProjectActions";
import Down from "../../../assets/icons/NIQ Icons SVG/Chevron/Down";
import { Link} from "react-router-dom";

const mapStateToProps = (state, ownProps) => {
  return {
    proposal: state.proposal.proposal,
    loading: state.proposal.Loading,
    error: state.proposal.error,
    msg: state.proposal.msg,
    redux_masters: state.redux_master.masters,
    cc_masters: state.redux_master.ccMasters,

    saving: state.proposal.saving,
    resending: state.proposal.resending,
    additionalCostSaved: state.proposal.additionalCostSaved,
  };
};

function ProposalBiddingSelectVendors(props) {
  const baseurl = process.env.REACT_APP_API_BASE_URL
  const [proposal, setProposalData] = useState({});
  const [activeTabCountryPopup, setActiveTabCountryPopup] = useState(0);
  const [activeTabMethodologyPopup, setActiveTabMethodologyPopup] = useState(0);
  const [selectarray, setNonselectarray] = useState([]);
  const [modelState, setModelState] = useState(false);
  const [modelRowIndex, setModelSelectedRow] = useState([]);
  const [InternalCost, setInternalCost] = useState(false);
  const [modelStateSummary, setModelStateSummary] = useState(false);
  const [option, setoption] = useState();
  const [country, setcountry] = useState();
  const [Costing, setCosting] = useState(0);
  const [finalCosting, setfinalCosting] = useState(0);
  const [submit, setsubmit] = useState(false);
  const dispatch = useDispatch();
  const codeLabels = useSelector(({ codeLabels }) => codeLabels);

  const [countrymodal, setcountrymodal] = useState(false);
  const [activeTabV, setActiveTabV] = useState(0);
  const [activeTabCo, setActiveTabCo] = useState(0);
  const [activeTabFC, setActiveTabFC] = useState(0);
  const [activeTabSM, setActiveTabSM] = useState(0);
  const [activeDropCurrency, setactiveDropCurrency] = useState();
  const [activeInternalDropCurrency, setactiveInternalDropCurrency] = useState();
  let [activeTabSummary, setactiveTabSummary] = useState(0);
  let [internalCost, setinternalCost] = useState([])
  let [activeCoId, setactiveCoId] = useState()
  let [internalOtherSpecify, setinternalOtherSpecify] = useState({})
  const [vendorSelectionSaved, setVendorSelectionSaved] = useState(false);
  const [ShowTabs, setShowTabs] = useState(false);
  const [currentCoSampleSize, setCurrentCoSampleSize] = useState(0);
  const [initialSelectedField, setInitialSelectedField] = useState(false);
  const [costingProfileIndex, setCostingProfileIndex] = useState(0);

  const currentprofile = useSelector(({ currentCosting }) => currentCosting.currentCostingProfile);
  var [Tabdata, setTabdata] = useState([]);
  var [staticModelData, setstaticModelData] = useState([]);

  var totalKeyArray = ["Field Cost", "Survey Programing & Platform", "Dashboard", "Coding & Data Entry", "Data Processing", "Charting", "Translation", "Dashboarding", "Transciption", "Moderation", "Rental", "Internal Cost"]
  var keyarrayQuant = ["Field Cost", "Survey Programing & Platform", "Dashboard", "Coding & Data Entry", "Data Processing", "Charting", "Translation", "Dashboarding"]
  var keyarrayQualOnline = ["Field Cost", "Transciption", "Translation", "Moderation", "Rental"]
  var optionforlocalsupport = [{ label: "Yes", id: 1, value: "Vendor FW with local support" }, { label: "No", id: 0, value: "Vendor FW (direct outsourcing)" }]
  var a = 0;
  var i = 0;


  const vendorAndList = useSelector(({ fetchVendorAndList }) => fetchVendorAndList.vendorAndList);

  function setList() {
    if (vendorAndList && vendorAndList.length > 0) {
      Tabdata = vendorAndList.filter(obj => obj.Group == 'fieldexecutionandsudtable')[0].List.filter(v => v.Name == "Tabdata")[0].Values;
      setTabdata(Tabdata);
      staticModelData = vendorAndList.filter(obj => obj.Group == 'fieldexecutionandsudtable')[0].List.filter(v => v.Name == "staticModelData")[0].Values;
      setstaticModelData(staticModelData);
      return true;
    }
  }
  useEffect(() => {
    setList();
  }, [])

  function updateproject(newProposal) {
    project.CostingProfiles[project?.CostingProfiles?.length - 1] = newProposal;
    dispatch({
      type: currentProjectActions.UPDATE_NEW_PROJECT,
      newProject: project,
    });
  }
  const updateProject = (profileTemp) => {
    const tempProfiles = project.CostingProfiles.map(cp => {
      if (cp.id == profileTemp.id)
        return { ..._.cloneDeep(profileTemp) }
      return { ..._.cloneDeep(cp) }
    });
    dispatch(setCurrentProject({ CostingProfiles: tempProfiles }))
  }
  const app = useSelector(({ app }) => app);
  function localoperationsupport(e) {
    const newProposal = update(project.CostingProfiles[project?.CostingProfiles?.length - 1], {
      CountrySpecs: {
        [activeTabFC]: {
          MethodologySpecs: {
            [activeTabSM]: {
              VendorType: { $set: e }
            },
          },
        },
      },
    });
    updateproject(newProposal);
  }
  function copyVendorType() {
    var type = project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].VendorType
    var project1 = _.cloneDeep(project.CostingProfiles[project?.CostingProfiles?.length - 1])
    project1.CountrySpecs[activeTabFC].MethodologySpecs.forEach(a => a.VendorType = type)
    updateproject(project1);
    toastr.success("Copied Successfully")
  }

  function currencyDropdown() {
    var currdata = [];
    var curr = project.CostingProfiles[project?.CostingProfiles?.length - 1] && project.CostingProfiles[project?.CostingProfiles?.length - 1]?.CountrySpecs.length > 0 ? project.CostingProfiles[project?.CostingProfiles?.length - 1]?.ProfileSetting?.CurrenciesData?.find(
      (c) => c.Code == project.CostingProfiles[project?.CostingProfiles?.length - 1]?.CountrySpecs[activeTabFC].CountryCode
    ) : "";
    var commcountrycurr = project.CostingProfiles[project?.CostingProfiles?.length - 1] && project.CostingProfiles[project?.CostingProfiles?.length - 1]?.CountrySpecs.length > 0 ?
      project.CostingProfiles[project?.CostingProfiles?.length - 1]?.CountrySpecs[activeTabFC].CountryCode != project.CommissioningCountry ?
        project.CostingProfiles[project?.CostingProfiles?.length - 1]?.ProfileSetting.CurrenciesData.find(
          (c) => c.Code == project.CommissioningCountry
        ) : "" : "";

    //project.CommissioningCountry;
    if (curr != "" && curr != undefined) {
      curr.AllCurrencyUnit = codeLabels.FieldingCountriesOptions.find(fc => fc.Code == curr.Code).AllCurrencyUnit
      //  curr.AllCurrencyUnit.replace(/(\r\n|\n|\r)/gm, "");
      var allUnits = curr.AllCurrencyUnit ? curr.AllCurrencyUnit.split(",") : [];
      if (allUnits.length > 0) {
        allUnits.forEach((all, i) => {
          currdata.push({
            id: i + 1, label: all, value: i + 1, code: curr.Code, ConversionRateToLocal: project.CostingProfiles[project?.CostingProfiles?.length - 1]?.ProfileSetting.CurrenciesData.
              filter(curr => curr.CurrencyUnit == all)[0].ConversionRateToLocal
          })
        }
        )
      }
    }
    if (commcountrycurr != "" && commcountrycurr != undefined) {
      var currlength = currdata.length
      commcountrycurr.AllCurrencyUnit = codeLabels.FieldingCountriesOptions.find(fc => fc.Code == commcountrycurr.Code).AllCurrencyUnit
      //  curr.AllCurrencyUnit.replace(/(\r\n|\n|\r)/gm, "");
      var allUnits = commcountrycurr.AllCurrencyUnit ? commcountrycurr.AllCurrencyUnit.split(",") : [];
      if (allUnits.length > 0) {
        allUnits.forEach((all, i) => {
          if (!currdata?.find(a => a.label == all)) {
            currdata.push({
              id: currlength + i + 1, label: all, value: currlength + i + 1, code: commcountrycurr.Code, ConversionRateToLocal: project.CostingProfiles[project?.CostingProfiles?.length - 1]?.ProfileSetting.CurrenciesData.
                filter(curr => curr.CurrencyUnit == all)[0].ConversionRateToLocal
            })
          }
        }
        )
      }
    }
    return currdata;
  }
  // function currencyDropdown() {
  //   var currdata = [];
  //   var curr = project.CostingProfiles[project?.CostingProfiles?.length - 1] && project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs.length > 0 ? project.CostingProfiles[project?.CostingProfiles?.length - 1].ProfileSetting.CurrenciesData.find(
  //     (c) => c.Code == project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].CountryCode
  //   ) : "";

  //   if (curr != "") {
  //     curr.AllCurrencyUnit = curr.AllCurrencyUnit.replace(/(\r\n|\n|\r)/gm, "");
  //     var allUnits = curr.AllCurrencyUnit ? curr.AllCurrencyUnit.split(",") : [];
  //     if (allUnits.length > 0) {
  //       allUnits.forEach((all, i) => {
  //         currdata.push({
  //           id: i + 1, label: all, value: i + 1, code: curr.Code, ConversionRateToLocal: project.CostingProfiles[project?.CostingProfiles?.length - 1].ProfileSetting.CurrenciesData.
  //             filter(curr => curr.CurrencyUnit == all)[0].ConversionRateToLocal
  //         })
  //       }
  //       )
  //     }
  //   }
  //   return currdata;
  // }
  function ComparisonViewData() {
    var ComparisonData = []
    project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs.map(cs => {
      cs.MethodologySpecs.map(co => {
        if (co.SelectedVendor && co.SelectedVendor.length > 0) {
          co.SelectedVendor.map((sv) => {
            ComparisonData = [...ComparisonData, ...sv.selected]
          })
        }
      })
    })

    var temparray = []
    totalKeyArray.forEach((key) => {
      let temp = ComparisonData.filter(cd => cd.Costingtype == key)
      if (temp.length > 0 && key != "Internal Cost") {

        project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs.map(cs => {
          cs.MethodologySpecs.map(co => {
            co.CostingOptions.map(sv => {
              temp.filter(t =>
                t.CostingOption == sv.Name && t.Methodology == co.Label && t.Country == cs.CountryName
              ).forEach(val => {
                temparray.push(val)
              })
            })
          })
        })
      }
      if (key == "Internal Cost") {
        let tempobj = {}
        let tempinput = ComparisonData.filter(cd => cd.Type == "SumInternal")
        project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs.map(cs => {
          cs.MethodologySpecs.map(co => {
            co.CostingOptions.map(sv => {
              if (tempinput.filter(t => t.CostingOption == sv.Name && t.Methodology == co.Label && t.Country == cs.CountryName).length > 0) {
                tempobj = {}
                tempobj.Country = cs.CountryName
                tempobj.Methodology = co.Label
                tempobj.CostingOption = sv.Name
                tempobj.LOI = sv.CostingOptionData.lengthOfInterview
                tempobj.SampleSize = sv.CostingOptionData.sampleSizeMain ? sv.CostingOptionData.sampleSizeMain : sv.CostingOptionData.numberOfGroup
                var tempobjTotalCost = tempinput.filter(t =>
                  t.CostingOption == sv.Name && t.Methodology == co.Label && t.Country == cs.CountryName
                ).length > 0 ? tempinput.filter(t =>
                  t.CostingOption == sv.Name && t.Methodology == co.Label && t.Country == cs.CountryName
                )[0].TotalCost : 0

                tempobj.TotalCost = isNaN(tempobjTotalCost) ? 0 : tempobjTotalCost

                var tempobjAdditionalCost = tempinput.filter(t =>
                  t.CostingOption == sv.Name && t.Methodology == co.Label && t.Country == cs.CountryName
                ).length > 0 ? tempinput.filter(t =>
                  t.CostingOption == sv.Name && t.Methodology == co.Label && t.Country == cs.CountryName
                ).map(a => a.AdditionalCostUSD).reduce((a, b) => parseInt(a) + parseInt(b)) : 0

                tempobj.AdditionalCost = isNaN(tempobjAdditionalCost) ? 0 : tempobjAdditionalCost

                var tempobjTotalInternalCost = tempinput.filter(t =>
                  t.CostingOption == sv.Name && t.Methodology == co.Label && t.Country == cs.CountryName
                ).length > 0 ? tempinput.filter(t =>
                  t.CostingOption == sv.Name && t.Methodology == co.Label && t.Country == cs.CountryName
                )[0]?.TotalCostusd : 0

                tempobj.TotalInternalCost = isNaN(tempobjTotalInternalCost) ? 0 : tempobjTotalInternalCost

                tempobj.Costingtype = "Total Internal Cost"
                temparray.push(tempobj)
              }
            })
          })
        })
      }
    })

    temparray = temparray.map((t, index) => { return ({ ...t, index: index }) })
    return (_.sortBy(temparray, "Costingtype", "CostingOption", "Methodology", "Country"));
  }
  function FinalTabRender() {
    var flag = true
    project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs.map(cs => {
      cs.MethodologySpecs.map(ms => {
        if (!ms.SelectedVendor || ms.SelectedVendor == null || ms.SelectedVendor == undefined) {
          flag = false
        }
      })
    })
    return flag
  }
  function FinalCostData() {
    var FinalData = []
    var metaData = ComparisonViewData()
    project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs.map(cs => {
      cs.MethodologySpecs.map(ms => {
        if (ms.CostingType == 3 && ms.SelectedVendor && ms.SelectedVendor.length > 0 && ms.SelectedVendor.map(val => val.selected).length > 0) {
          let tempobj = {}
          tempobj.Vendor = ms.SelectedVendor.map(val => val.selected).flat().filter(v => v.Costingtype == "Field Cost").map(name => name.Vendor).toString()
          tempobj.Country = cs.CountryName
          tempobj.Methodology = ms.Label
          tempobj.SampleSize = isNaN(ms.CostingOptions.map(a => a.CostingOptionData).map(x => x.sampleSizeMain || x.sampleSize || x.numberOfGroup).reduce((a, b) => parseInt(a) + parseInt(b))) ? 0 : ms.CostingOptions.map(a => a.CostingOptionData).map(x => x.sampleSizeMain || x.sampleSize || x.numberOfGroup).reduce((a, b) => parseInt(a) + parseInt(b))
          tempobj.CostFromVendor =
            ms.SelectedVendor.map(val => val.selected).flat().some(s => s.Type == "Internal Cost" && s.key != "SumInternal") ?
              (ms.SelectedVendor.map(val => val.selected).flat().filter(k => k.Type != "Internal Cost" && k.key != "SumInternal").length > 0 ?
                ms.SelectedVendor.map(val => val.selected).flat().filter(k => k.Type != "Internal Cost" && k.key != "SumInternal")
                  .map(val => typeof val.TotalCostusd == "string" ? parseInt(val.TotalCostusd) : val.TotalCostusd).reduce((a, b) => parseInt(a) + parseInt(b)).toFixed(2)
                : 0) : (ms.SelectedVendor.map(val => val.selected).flat().map(val => typeof val.TotalCostusd == "string" ? parseInt(val.TotalCostusd) : val.TotalCostusd).length > 0 ?
                  parseFloat(ms.SelectedVendor.map(val => val.selected).flat().map(val => typeof val.TotalCostusd == "string" ? parseInt(val.TotalCostusd)
                    : val.TotalCostusd).reduce((a, b) => parseFloat(a) + parseInt(b))).toFixed(2) : '0')
          tempobj.AdditionalCost = ms.SelectedVendor.map(a => a.selected).flat().filter(s => s.key == "SumInternal").map(s => s.TotalCostusd).length > 0 ? isNaN(
            ms.SelectedVendor.map(a => a.selected).flat().filter(s => s.key == "SumInternal").map(s => s.TotalCostusd).reduce((a, b) => parseInt(a) + parseInt(b))) ? 0 : ms.SelectedVendor.map(a => a.selected).flat().filter(s => s.key == "SumInternal").map(s => s.TotalCostusd).reduce((a, b) => parseInt(a) + parseInt(b)) : 0
          tempobj.TotalCost = isNaN(parseInt(tempobj.CostFromVendor) + (tempobj.AdditionalCost)) ? 0 : parseInt(tempobj.CostFromVendor) + parseInt(tempobj.AdditionalCost)
          tempobj.CPI = tempobj.SampleSize == 0 ? 0 : (tempobj.CostFromVendor / (tempobj.SampleSize == 0 ? 1 : tempobj.SampleSize)).toFixed(2)
          tempobj.FinalCPI = tempobj.SampleSize == 0 ? 0 : (tempobj.TotalCost / (tempobj.SampleSize == 0 ? 1 : tempobj.SampleSize)).toFixed(2)
          FinalData.push(tempobj)
        }
      })
    })
    return FinalData
  }
  async function createSheet() {
    if (!currentprofile.CostingsSheetId && currentprofile.Deadline) {
      toastr.info("Costing Sheet is being created...");

      dispatch(pageLoadStart());
      axios
        .post("/utils/sheets/" + currentprofile.id + "/costing")
        .then((res) => {
          dispatch(
            currentCostingActions.updateProfile({
              ...project.CostingProfiles[project.CostingProfiles.length - 1],
              CostingsSheetId: res.data.CostingsSheetId,
            })
          );
          updateProject({
            ...project.CostingProfiles[project.CostingProfiles.length - 1],
            CostingsSheetId: res.data.CostingsSheetId,
          })

          dispatch(
            currentCostingActions.saveCostingProfile(
              {
                ...project.CostingProfiles[project.CostingProfiles.length - 1],
                CostingsSheetId: res.data.CostingsSheetId,
              },
              null,
              true
            )
          );
          toastr.success("Sheet created Successfully", res.data.message);
          // window.open(`https://docs.google.com/spreadsheets/d/${res.data.CostingsSheetId}`, "_blank");

          // if (res.data.CostingsSheetId && project.CostingTemplateVersion != "v2.0") {
          if (res.data.CostingsSheetId && project.CostingTemplateVersion != "v2.0") {
            toastr.info("Sheet is being prepared", "Sheet Preparation might take sometime to setup your project configuration", { timeOut: 20000 });
            setTimeout(() => {
              toastr.info("It is about to complete", "Please do not refresh or close the page, sheet preparation is inprogress", { timeOut: 80000 });
            }, 20000);
            setTimeout(() => {
              toastr.info("Opening the sheet", null);
              dispatch(sheetCall.msSheetAPiCall(res.data.CostingsSheetId, true, true, () => dispatch(pageLoadEnd())));
              // dispatch(pageLoadEnd());

            }, 100000);

          }
          else if (res.data.CostingsSheetId && project.CostingTemplateVersion == "v2.0") {
            dispatch(sheetCall.msSheetAPiCall(res.data.CostingsSheetId, true, true, () => dispatch(pageLoadEnd())));

          }

        })
        .catch((err) => {
          dispatch(pageLoadEnd());
          // setSyncAbility(false)
          toastr.error("Creating Sheet Failed", err?.data.message);
        });


    }
  }

  async function openSheet() {
    dispatch(recordLoadStart());

    // window.open(`https://docs.google.com/spreadsheets/d/${currentprofile.CostingsSheetId}`, "_blank");
    //todo: handle in open cost sheet inside then block, avoid sending dispatch
    dispatch(sheetCall.msSheetAPiCall(currentprofile.CostingsSheetId, true, true, () => { dispatch(recordLoadEnd()) }))




  };

  function SaveSelectedVendor() {
    var profileCopy = _.cloneDeep(project.CostingProfiles[project?.CostingProfiles?.length - 1])
    var profileSave = true
    var error = 0;
    profileCopy.CountrySpecs.forEach(cs => {
      cs.MethodologySpecs.forEach((ms, i) => {


        if (ms.CostingType == 3 && ms.SelectedVendor && (!ms.NotApplicable) && ms.SelectedVendor.length > 0) {
          ms.CostingOptions.forEach((co, coi) => {
            if (ms.CostingType == 0 || ms.CostingType == 2) {
              if (ms.SelectedVendor[coi]?.selected?.filter(sv => sv.Costingtype == "Field Cost").length > 0) {
                if (!ms.SelectedVendor[coi]?.selected?.filter(sv => sv.Costingtype == "Field Cost").map(sv => sv.SampleSize).reduce((a, b) => a + b + 0, 0) ==
                  parseInt(co.CostingOptionData.sampleSizeMain || co.CostingOptionData.sampleSize || co.CostingOptionData.numberOfGroup)) {
                    error = error + 1 
                }
              
              }


            }





            var vName = ""
            var coSelectedData = {}
            var obj = [];
            if (ms.SelectedVendor[coi]?.selected?.filter(sv => sv.Costingtype == "Field Cost").length > 0 && ms.SplitSample == 1) {
              var selectedFieldSampleSize = ms.SelectedVendor[coi].selected.filter(sv => sv.Costingtype == "Field Cost").filter(x => x.VendorID == ms.Vendors.filter
                (y => y.id == x.VendorID)[0].id && x.VendorCO.length == ms.Vendors.filter(y => y.id == x.VendorID)[0].CostingData.length).length ?
                ms.SelectedVendor[coi].selected.filter(sv => sv.Costingtype == "Field Cost").filter(x => x.VendorID == ms.Vendors.filter
                  (y => y.id == x.VendorID)[0].id && x.VendorCO.length == ms.Vendors.filter(y => y.id == x.VendorID)[0].CostingData.length).map(sv => sv.SampleSize).reduce((a, b) => a + b) : 0
              if (selectedFieldSampleSize != parseInt(co.CostingOptionData.sampleSizeMain || co.CostingOptionData.sampleSize || co.CostingOptionData.numberOfGroup)) {
                toastr.error(`Field Selection is missing for ${cs.CountryName} in ${ms.label} for ${co.Name} `)
                profileSave = false
              }
            }


            var stubsActual = ms.SelectedVendor.filter(x => x.id == co.id)[0]?.TotalCount

            if (stubsActual)
              stubsActual = ms.SplitSample == 1 ? (ms.SelectedVendor.filter(x => x.id == co.id)[0]?.TotalCount + parseInt(ms?.SplitSampledata?.NumberOfSplits?.find(a => a.coid == co.id)?.size) - 1) : ms.SelectedVendor.filter(x => x.id == co.id)[0]?.TotalCount

            var stubsPresent = ms.SelectedVendor.filter(x => x.id == co.id).map(a => a.selected).flat().filter(stub => stub.Type != "Internal Cost" && stub.Costingtype != "Total Internal Cost").length
            if (stubsActual > stubsPresent) {
              toastr.error(`Please Complete Vendor Selection for ${cs.CountryName} in ${ms.Label} for ${co.Name} `)
              profileSave = false
            }

            if (ms.VendorType == null) {
              profileSave = false
              toastr.error(`Please Select FW Local Operation Support for ${cs.CountryName} in ${ms.Label}`)

            }
            _.head(ms.SelectedVendor?.filter(sv => sv.id == co.id))?.selected.forEach(
              s => {
                //converting back to local currency while saving

                if (s.Type == "Internal Cost") {
                  if (ms.label == "Online Self Completion Survey") {

                    coSelectedData["BufferSampleCost"] = parseFloat(isNaN(s.AdditionalCostUSD) ? 0 : (s.AdditionalCostUSD).toFixed(2)) + (coSelectedData["BufferSampleCost"] != undefined ? parseFloat(coSelectedData["BufferSampleCost"]) : 0)
                  }
                  else {

                    obj.push({ name: s.Costingtype, group: s.Type, key: s.key })
                    coSelectedData[s.key] = s?.AdditionalCostUSD != "" ? s.AdditionalCostUSD.toFixed(2) : 0
                    coSelectedData["BufferSampleCost"] = s.AdditionalCostUSD.toFixed(2)
                  }
                }
                else {
                  if (s.vendorSchema != undefined) { obj.flat(s.vendorSchema) }
                  if (s.Costingtype != "Total Internal Cost") {
                    vName = (vName ? vName : "") + s.Vendor + `(${s.Costingtype}),`
                  }
                  if (s.subFields && s.subFields.length > 0) {
                    if (s.Costingtype == "Field Cost" && ms.SplitSample == 1) {
                      s.subFields.forEach(sub => {
                        if (sub.Name.includes("Other Cost")) {
                          coSelectedData[sub.key] = (isNaN(parseInt((sub.cost / ms.Currency.ConversionRateToLocal).toFixed(2))) ? 0 : parseInt((sub.cost / ms.Currency.ConversionRateToLocal).toFixed(2))) + (coSelectedData[sub.key] != undefined ? parseInt(coSelectedData[sub.key]) : 0)
                          coSelectedData[sub.key + "_Label"] = ((sub.pleaseSpecify != "" && sub.pleaseSpecify != undefined) ? sub.pleaseSpecify : "") + (coSelectedData[sub.key + "_Label"] != undefined ? coSelectedData[sub.key + "_Label"] : "")
                        }
                        else {
                          coSelectedData[sub.key] =
                            sub.type == "TimingSection" ?
                              (isNaN(parseInt((sub.cost))) ? 0 : parseInt((sub.cost))) + (coSelectedData[sub.key] != undefined ? parseInt(coSelectedData[sub.key]) : 0)
                              : (isNaN(parseInt((sub.cost / ms.Currency.ConversionRateToLocal).toFixed(2))) ? 0 : parseInt((sub.cost / ms.Currency.ConversionRateToLocal).toFixed(2))) + (coSelectedData[sub.key] != undefined ? parseInt(coSelectedData[sub.key]) : 0)

                        }

                      })
                    }

                    else {
                      s.subFields.forEach(sub => {
                        if (sub.key.includes("otherSUD")) {
                          coSelectedData["OtherSUDCost1"] = parseFloat(isNaN((sub.cost / ms.Currency.ConversionRateToLocal).toFixed(2)) ? 0 : (sub.cost / ms.Currency.ConversionRateToLocal).toFixed(2)) + (coSelectedData["OtherSUDCost1"] != undefined ? parseFloat(coSelectedData["OtherSUDCost1"]) : 0)
                          coSelectedData["OtherSUDCost1_Label"] = ((sub.pleaseSpecify != "" && sub.pleaseSpecify != undefined) ? sub.pleaseSpecify : "" ? sub.pleaseSpecify : "") + " " + (coSelectedData["OtherSUDCost1_Label"] != undefined ? coSelectedData["OtherSUDCost1_Label"] : "")
                        }
                        else {
                          if (sub.Name.includes("Other Cost")) {
                            coSelectedData[sub.key] = (sub.cost / ms.Currency.ConversionRateToLocal).toFixed(2)
                            coSelectedData[sub.key + "_Label"] = ((sub.pleaseSpecify != "" && sub.pleaseSpecify != undefined) ? sub.pleaseSpecify : "")
                          }
                          else {
                            coSelectedData[sub.key] = sub.type == "TimingSection" ? sub.cost : (sub.cost / ms.Currency.ConversionRateToLocal).toFixed(2)
                          }
                        }
                      })
                    }
                  }

                }
              },

            )
            co.vendorSchema = obj
            co.SelectedVendor = coSelectedData
            co.selectedVendorName = vName
          })
        }
        else if (ms.CostingType == 3 && ms.NotApplicable) {

        }

        else {
          if (ms.CostingType == 3) {
            ms.SelectedVendor = null
            profileSave = false
            toastr.error(`Please Select Vendors for ${cs.CountryName} in ${ms.Label} `)
          }
          else {
            profileSave = true
          }
        }
      })
    })

    updateproject(profileCopy);
    if(error>0)
    {
      toastr.error("Please Complete the sample size")
    }
    else{
    dispatch(proposalActions.saveSelectedVendor(project, true));
    setVendorSelectionSaved(true)
    toastr.success("Vendors Selected Saved");
    }
  }
  function saveandnext(tab) {
    var profileCopy = _.cloneDeep(project.CostingProfiles[project?.CostingProfiles?.length - 1])
    var profileSave = true
    var length = 0
    var totallength = 0
    profileCopy.CountrySpecs.forEach(cs => {
      cs.MethodologySpecs.forEach((ms, i) => {
        //     var  selectedvendor= ms.SelectedVendor.filter(x=> {x.selected}).length;
        if (ms.CostingType == 3 && ms.SelectedVendor && (!ms.NotApplicable) && ms.SelectedVendor.length > 0) {
          ms.SelectedVendor.map(a => (a.selected.forEach(a => {
            if (a.saveAsDraft) {
              profileSave = false
              toastr.error(`${a.Vendor} has not submitted the cost`)
            }
          })))
          totallength = totallength + 1
          if (ms.SelectedVendor?.filter(a => a.selected.length > 0).length == 0) {
            length = length + 1
          }
          ms.CostingOptions.forEach((co, coi) => {
            var vName = ""
            var coSelectedData = {}

            var obj = [];

            if (ms.SelectedVendor[coi]?.selected?.filter(sv => sv.Costingtype == "Field Cost").length > 0 && ms.SplitSample == 1) {
              var selectedFieldSampleSize = ms.SelectedVendor[coi].selected.filter(sv => sv.Costingtype == "Field Cost").filter(x => x.VendorID == ms.Vendors.filter
                (y => y.id == x.VendorID)[0].id && x.VendorCO.length == ms.Vendors.filter(y => y.id == x.VendorID)[0].CostingData.length).length ?
                ms.SelectedVendor[coi].selected.filter(sv => sv.Costingtype == "Field Cost").filter(x => x.VendorID == ms.Vendors.filter
                  (y => y.id == x.VendorID)[0].id && x.VendorCO.length == ms.Vendors.filter(y => y.id == x.VendorID)[0].CostingData.length).map(sv => sv.SampleSize).reduce((a, b) => a + b)
                : 0

              // var selectedFieldSampleSize = ms.SelectedVendor[coi].selected.filter(sv => sv.Costingtype == "Field Cost").length ?
              //   ms.SelectedVendor[coi].selected.filter(sv => sv.Costingtype == "Field Cost").map(sv => sv.SampleSize).reduce((a, b) => a + b) : 0
              if (selectedFieldSampleSize != parseInt(co.CostingOptionData.sampleSizeMain || co.CostingOptionData.sampleSize || co.CostingOptionData.numberOfGroup)) {
                toastr.error(`Field Selection is missing for ${cs.CountryName} in ${ms.label} for ${co.Name} `)
                profileSave = false
              }
            }

            var stubsActual = ms.SelectedVendor.filter(x => x.id == co.id)[0]?.TotalCount

            if (stubsActual)
              stubsActual = ms.SplitSample == 1 ? (ms.SelectedVendor.filter(x => x.id == co.id)[0]?.TotalCount + parseInt(ms?.SplitSampledata?.NumberOfSplits?.find(a => a.coid == co.id)?.size) - 1) : ms.SelectedVendor.filter(x => x.id == co.id)[0]?.TotalCount

            var stubsPresent = ms.SelectedVendor.filter(x => x.id == co.id).map(a => a.selected).flat().filter(stub => stub.Type != "Internal Cost" && stub.Costingtype != "Total Internal Cost").length
            if (stubsActual > stubsPresent) {
              toastr.error(`Please Complete Vendor Selection for ${cs.CountryName} in ${ms.Label} for ${co.Name} `)
              profileSave = false
            }
            if (ms.VendorType == null) {
              profileSave = false
              toastr.error(`Please Select FW Local Operation Support for ${cs.CountryName} in ${ms.Label}`)

            }
          })
        }
        else if (ms.CostingType == 3 && ms.NotApplicable) {

        } else {
          if (ms.CostingType == 3) {
            ms.SelectedVendor = null
            profileSave = false
            toastr.error(`Please Select Vendors for ${cs.CountryName} in ${ms.Label} `)
          }
          else {
            profileSave = true
          }
        }
      })
    })
    if (length == totallength) {
      toastr.error("At least one Vendor cost should be present to move Forward")
    }
    else if (profileSave == true) {
      updateproject(profileCopy);
      dispatch(proposalActions.saveSelectedVendor(project, false));
      setVendorSelectionSaved(true)

      tab == 0 ? toastr.success("Vendors Selected Saved") : toastr.success("Internal Cost Saved")
      setShowTabs(true)
      setactiveTabSummary(tab + 1)

      project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs.forEach(cs => {
        cs.MethodologySpecs.forEach((ms, i) => {
          if (ms.CostingType == 3 && ms.SelectedVendor && (!ms.NotApplicable) && ms.SelectedVendor.length > 0) {
            ms.copySelectedVendor = _.cloneDeep(ms.SelectedVendor);
          }
        })
      })
    }
  }
  function saveasdraft() {
    var profileCopy = _.cloneDeep(project.CostingProfiles[project?.CostingProfiles?.length - 1])
    updateproject(profileCopy);
    dispatch(proposalActions.saveSelectedVendor(project));
    setVendorSelectionSaved(true)
    toastr.success("Vendors Selected Saved");
    let action = false;
    project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs.forEach(a => a.MethodologySpecs.forEach(b => {
      b.SelectedVendor?.map(select => {
        if (select.selected.length > 0) {
          action = true
        }
      }
      )
    }))
    if (action == true) {
      setShowTabs(true)
    }

  }
  const rowStyle = (row, rowIndex) => {
    if (row.Country == "Total Cost(B2B-FTF||Option1)") {
      return { backgroundColor: "#ebebeb" };
    }
  };

  const rowStyleVendorSelection = (row, rowIndex) => {
    {
      return { backgroundColor: "#ebebeb" };
    }
  };
  function toggleSM(tab) {
    setActiveTabCo(0)
    if (activeTabSM !== tab) {
      setActiveTabSM(tab);
      setactiveCoId(project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[tab].CostingOptions[0].id)
      setModelSelectedRow([])
    }
  }
  function InternalCostTableData() {
    var internaltableData = [];
    (project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor
      &&
      project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor.filter(co => co.id == activeCoId).length > 0
      &&
      project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor.filter(co => co.id == activeCoId)[0].selected)
      ?
      internaltableData = project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor.filter(co => co.id == activeCoId)[0].selected.filter(row => row.Type != "Internal Cost") : internaltableData = [];

    var currentcurrencyconv = project.CostingProfiles[project?.CostingProfiles?.length - 1].ProfileSetting.CurrenciesData &&
      project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].Currency &&
      project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].Currency.code ?
      project.CostingProfiles[project?.CostingProfiles?.length - 1].ProfileSetting.CurrenciesData.filter(curr => curr.CurrencyUnit == project.CostingProfiles[project?.CostingProfiles?.length - 1].
        CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].Currency.label).length > 0 &&
      (project.CostingProfiles[project?.CostingProfiles?.length - 1].ProfileSetting.CurrenciesData.filter(curr => curr.CurrencyUnit == project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].Currency.label)[0].ConversionRateToLocal == 0 ? 1 :
        project.CostingProfiles[project?.CostingProfiles?.length - 1].ProfileSetting.CurrenciesData.filter(curr => curr.CurrencyUnit == project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].Currency.label)[0].ConversionRateToLocal) : 1

    var tempCostFromVendor = 0;

    if (!activeInternalDropCurrency || activeInternalDropCurrency == undefined) {
      internaltableData.forEach(data => {
        //   data.CostFromVendor = Math.round((data.ConstantCostFromVendor * project.CostingProfiles[project.CostingProfiles.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].Currency.ConversionRateToLocal), 2)
        tempCostFromVendor = (data.VendorCO.map((c) => c.group == data.Costingtype && c.type != "TimingSection" && c.coid == data.CostingOptionID && (isNaN(c.VendorDataUSD) || c.VendorDataUSD == '' ? "0" : c.VendorDataUSD)).filter(m => m).map(y => y * currentcurrencyconv).reduce((a, b) => Math.round(a) + Math.round(b) + 0, 0))
        data.CostFromVendor = isNaN(tempCostFromVendor) ? 0 : tempCostFromVendor

        data.CPI = (data.SampleSize == 0 || data.CostFromVendor == 0) ? 0 : (data.CostFromVendor / data.SampleSize).toFixed(2)
        data.TotalCost = data.CostFromVendor
        data.FinalCPI = data.SampleSize == 0 ? 0 : (data.CostFromVendor / data.SampleSize).toFixed(2)
        if (data.Type == "SumInternal") {

          data.SampleSize = project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions.find(co => co.id == activeCoId).CostingOptionData.sampleSizeMain != undefined ?
            project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions.find(co => co.id == activeCoId).CostingOptionData.sampleSizeMain :
            (project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions.find(co => co.id == activeCoId).CostingOptionData.sampleSize != undefined ?
              project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions.find(co => co.id == activeCoId).CostingOptionData.sampleSize :
              project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions.find(co => co.id == activeCoId).CostingOptionData.numberOfGroup != undefined ?
                project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions.find(co => co.id == activeCoId).CostingOptionData.numberOfGroup :
                0)

          // data.AdditionalCost = Math.round(data.AdditionalCostUSD * project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].Currency.ConversionRateToLocal).toFixed(2)
          // data.TotalCost = (data.TotalCostusd * project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].Currency.ConversionRateToLocal).toFixed(2)

          data.AdditionalCost = Math.round(data.AdditionalCostUSD * currentcurrencyconv).toFixed(2)
          data.TotalCost = (data.TotalCostusd * currentcurrencyconv).toFixed(2)
          data.FinalCPI = data.SampleSize == 0 ? 0 : (data.TotalCost / (data.SampleSize == 0 ? 1 : data.SampleSize)).toFixed(2)
        }
      })
    }

    if (activeInternalDropCurrency && activeInternalDropCurrency.label != "USD") {
      currentcurrencyconv = activeInternalDropCurrency.ConversionRateToLocal != 0 ? activeInternalDropCurrency.ConversionRateToLocal : 1
      internaltableData.forEach(data => {

        //        data.CostFromVendor = Math.round((data.ConstantCostFromVendor * activeInternalDropCurrency.ConversionRateToLocal), 2)

        tempCostFromVendor = data.VendorCO?.map((c) => c.group == data.Costingtype && c.type != "TimingSection" && c.coid == data.CostingOptionID && (isNaN(c.VendorDataUSD) || c.VendorDataUSD == '' ? "0" : c.VendorDataUSD))?.filter(m => m)?.map(y => y * currentcurrencyconv)?.reduce((a, b) => Math.round(a) + Math.round(b) + 0, 0)
        data.CostFromVendor = isNaN(tempCostFromVendor) ? 0 : tempCostFromVendor


        data.CPI = (data.SampleSize == 0 || data.CostFromVendor == 0) ? 0 : (data.CostFromVendor / (data.SampleSize == 0 ? 1 : data.SampleSize)).toFixed(2)
        data.TotalCost = data.CostFromVendor
        data.FinalCPI = data.SampleSize == 0 ? 0 : (data.CostFromVendor / (data.SampleSize == 0 ? 1 : data.SampleSize)).toFixed(2)
        if (data.Type == "SumInternal") {
          data.SampleSize = project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions.find(co => co.id == activeCoId).CostingOptionData.sampleSizeMain != undefined ?
            project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions.find(co => co.id == activeCoId).CostingOptionData.sampleSizeMain :
            (project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions.find(co => co.id == activeCoId).CostingOptionData.sampleSize != undefined ?
              project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions.find(co => co.id == activeCoId).CostingOptionData.sampleSize :
              project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions.find(co => co.id == activeCoId).CostingOptionData.numberOfGroup != undefined ?
                project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions.find(co => co.id == activeCoId).CostingOptionData.numberOfGroup :
                0)

          // data.AdditionalCost = Math.round(data.AdditionalCostUSD * activeInternalDropCurrency.ConversionRateToLocal).toFixed(2)
          // data.TotalCost = (data.TotalCostusd * activeInternalDropCurrency.ConversionRateToLocal).toFixed(2)

          data.AdditionalCost = Math.round(data.AdditionalCostUSD * currentcurrencyconv).toFixed(2)
          data.TotalCost = (data.TotalCostusd * currentcurrencyconv).toFixed(2)

          data.FinalCPI = data.SampleSize == 0 ? 0 : (data.TotalCost / (data.SampleSize == 0 ? 1 : data.SampleSize)).toFixed(2)
        }
      })

    }
    if (activeInternalDropCurrency && activeInternalDropCurrency.label == "USD") {
      currentcurrencyconv = activeInternalDropCurrency.ConversionRateToLocal != 0 ? activeInternalDropCurrency.ConversionRateToLocal : 1

      internaltableData.forEach(data => {

        //      data.CostFromVendor = isNaN(data.ConstantCostFromVendor) ? 0 : data.ConstantCostFromVendor

        tempCostFromVendor = data.VendorCO?.map((c) => c.group == data.Costingtype && c.type != "TimingSection" && c.coid == data.CostingOptionID && (isNaN(c.VendorDataUSD) || c.VendorDataUSD == '' ? "0" : c.VendorDataUSD))?.filter(m => m)?.map(y => y).reduce((a, b) => Math.round(a) + Math.round(b) + 0, 0)
        data.CostFromVendor = isNaN(tempCostFromVendor) ? 0 : tempCostFromVendor

        data.CPI = isNaN(data.CPIusd) ? 0 : data.CPIusd
        data.FinalCPI = isNaN(data.CPIusd) ? 0 : data.CPIusd
        data.TotalCost = Math.round(data.ConstantCostFromVendor, 2)
        if (data.Type == "SumInternal") {
          data.SampleSize = project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions.find(co => co.id == activeCoId).CostingOptionData.sampleSizeMain != undefined ?
            project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions.find(co => co.id == activeCoId).CostingOptionData.sampleSizeMain :
            (project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions.find(co => co.id == activeCoId).CostingOptionData.sampleSize != undefined ?
              project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions.find(co => co.id == activeCoId).CostingOptionData.sampleSize :
              project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions.find(co => co.id == activeCoId).CostingOptionData.numberOfGroup != undefined ?
                project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions.find(co => co.id == activeCoId).CostingOptionData.numberOfGroup :
                0)

          data.AdditionalCost = data.AdditionalCostUSD
          data.TotalCost = data.TotalCostusd
          data.FinalCPI = data.SampleSize == 0 ? 0 : (data.TotalCost / (data.SampleSize == 0 ? 1 : data.SampleSize)).toFixed(2)
        }
      })
    }
    return internaltableData

  }
  function SummaryTableData(rawdata) {

    var currentcurrencyconv = project.CostingProfiles[project?.CostingProfiles?.length - 1].ProfileSetting.CurrenciesData &&
      project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].Currency &&
      project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].Currency.code ?
      project.CostingProfiles[project?.CostingProfiles?.length - 1].ProfileSetting.CurrenciesData.filter(curr => curr.CurrencyUnit == project.CostingProfiles[project?.CostingProfiles?.length - 1].
        CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].Currency.label).length > 0 &&
      (project.CostingProfiles[project?.CostingProfiles?.length - 1].ProfileSetting.CurrenciesData.filter(curr => curr.CurrencyUnit == project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].Currency.label)[0].ConversionRateToLocal == 0 ? 1 :
        project.CostingProfiles[project?.CostingProfiles?.length - 1].ProfileSetting.CurrenciesData.filter(curr => curr.CurrencyUnit == project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].Currency.label)[0].ConversionRateToLocal) : 1

    var tempCostFromVendor = 0;
    var tempConstCostFromVendor = 0;
    // tempCostFromVendor = data.GroupValues[groupname] == undefined ? 0 : (DATACO.map((c) => c.group == groupname && (isNaN(c.VendorDataUSD) || c.VendorDataUSD == '' ? "0" : c.VendorDataUSD)).filter(m => m).map(y => y * currentcurrencyconv).reduce((a, b) => Math.round(a) + Math.round(b) + 0, 0)).toFixed(4)


    if (activeDropCurrency && activeDropCurrency.label != "USD") {
      currentcurrencyconv = activeDropCurrency.ConversionRateToLocal != 0 ? activeDropCurrency.ConversionRateToLocal : 1

      rawdata.forEach(data => {

        tempCostFromVendor = (data.VendorCO.map((c) => c.group == data.Costingtype && c.coid == data.CostingOptionID && c.type != "TimingSection" && (isNaN(c.VendorDataUSD) || c.VendorDataUSD == '' ? "0" : c.VendorDataUSD)).filter(m => m).map(y => y * currentcurrencyconv).reduce((a, b) => Math.round(a) + Math.round(b) + 0, 0))
        data.CostFromVendor = isNaN(tempCostFromVendor) ? 0 : tempCostFromVendor

        //  data.CostFromVendor = Math.round((data.ConstantCostFromVendor * (activeDropCurrency.ConversionRateToLocal != 0 ? activeDropCurrency.ConversionRateToLocal : 1)))
        data.CPI = (data.CostFromVendor / (data.SampleSize == 0 ? 1 : data.SampleSize)).toFixed(2)
      })

    }
    if (activeDropCurrency && activeDropCurrency.label == "USD") {
      currentcurrencyconv = activeDropCurrency.ConversionRateToLocal != 0 ? activeDropCurrency.ConversionRateToLocal : 1

      rawdata.forEach(data => {
        //   data.CostFromVendor = data.ConstantCostFromVendor

        tempCostFromVendor = (data.VendorCO.map((c) => c.group == data.Costingtype && c.type != "TimingSection" && c.coid == data.CostingOptionID && (isNaN(c.VendorDataUSD) || c.VendorDataUSD == '' ? "0" : c.VendorDataUSD)).filter(m => m).map(y => y).reduce((a, b) => Math.round(a) + Math.round(b) + 0, 0))
        data.CostFromVendor = isNaN(tempCostFromVendor) ? 0 : tempCostFromVendor

        data.CPI = (data.ConstantCostFromVendor / (data.SampleSize == 0 ? 1 : data.SampleSize)).toFixed(2)
      })
    }


    var arr = [];
    var sortaccKey = project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].label == "Online Self Completion Survey" ? keyarrayQuant : (project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].Quant ? keyarrayQuant : keyarrayQualOnline)
    var data = Object.entries(_.groupBy(rawdata, 'Costingtype')).map(y => y[0]).map(r => _.groupBy(rawdata, 'Costingtype')[r]).flat();
    sortaccKey.map(key => {
      var subdata = data.filter(k => k.Costingtype == key)
      arr.push(...subdata)
    }
    )
    var finalsortedarr = Object.entries(_.groupBy(arr, 'CostingOption')).map(val => val[1]).flat()
    return finalsortedarr
  }
  function toggleSummary(tab) {
    setActiveTabCo(0)
    if (activeTabSummary !== tab) {
      setactiveTabSummary(tab);
    }
  }

  function toggle(tab) {
    setActiveTabCo(0)
    if (activeTabFC !== tab) {
      setActiveTabFC(tab);
      var indexsm = project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[
        tab
      ].MethodologySpecs.filter((y, index) => y.CostingType == 3 && !y.NotApplicable)[0]?.id;
      setActiveTabSM(
        project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[tab].MethodologySpecs.findIndex(
          (m) => m.id == indexsm
        )
      );
      setactiveCoId(project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[tab].MethodologySpecs.filter(
        (m) => m.id == indexsm
      )[0]?.CostingOptions[0].id)
    }
  }
  function Country(v) {
    var selectedcountry = [];
    selectedcountry.push(v);
    setcountry(selectedcountry);
  }

  function saveDataNew(i, data, m, project, c, mindex, cindex, v, groupname, flag, DATACO) {
    var dtpush = {}
    var currentcurrencyconv = project?.CostingProfiles[project?.CostingProfiles?.length - 1].ProfileSetting.CurrenciesData &&
      project?.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].Currency &&
      project?.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].Currency.code ?
      project?.CostingProfiles[project?.CostingProfiles?.length - 1].ProfileSetting.CurrenciesData.filter(curr => curr.CurrencyUnit == project?.CostingProfiles[project?.CostingProfiles?.length - 1].
        CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].Currency.label).length > 0 &&
      (project?.CostingProfiles[project?.CostingProfiles?.length - 1].ProfileSetting.CurrenciesData.filter(curr => curr.CurrencyUnit == project?.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].Currency.label)[0].ConversionRateToLocal == 0 ? 1 :
        project?.CostingProfiles[project?.CostingProfiles?.length - 1].ProfileSetting.CurrenciesData.filter(curr => curr.CurrencyUnit == project?.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].Currency.label)[0].ConversionRateToLocal) : 1
    var tempCostFromVendor = 0;
    var tempConstCostFromVendor = 0;
    dtpush.key = i;
    dtpush.uniquekey = m.Label + data.cLabel + groupname;
    if (flag == 1)
      dtpush.SampleSize = data.sampleSize ? parseInt(data.sampleSize) : 0;
    else if (flag == 2 || flag == 3) {
      var tempSampleSize = (groupname == "Field Cost") ? (m.SplitSample == 1 ? activeCoId ?
        parseInt(v.CostingData.filter(x => x.sampleSize && x.coid == activeCoId)[0]?.DistributedSampleSize != undefined ? v.CostingData.filter(x => x.sampleSize && x.coid == activeCoId)[0]?.DistributedSampleSize : 0) :
        parseInt(v.CostingData.filter(x => x.sampleSize && x.coid == v.selectedCO.filter(mx => mx.selected)[activeTabCo].id)[0]?.DistributedSampleSize != undefined ? v.CostingData.filter(x => x.sampleSize && x.coid == v.selectedCO.filter(mx => mx.selected)[activeTabCo].id)[0]?.DistributedSampleSize : 0) :
        (data.sampleSize ? parseInt(data.sampleSize) : 0))
        : data.sampleSize ? parseInt(data.sampleSize) : 0
      dtpush.ActualSampleSize = data.sampleSize ? parseInt(data.sampleSize) : 0

      dtpush.SampleSize = isNaN(tempSampleSize) ? 0 : tempSampleSize

      var tempBooster = (groupname == "Field Cost") ? (m.SplitSample == 1 ? activeCoId ?
        parseInt(v.CostingData.filter(x => x.sampleSize)[activeTabCo]?.DistributedBoosterSampleSize != undefined ? v.CostingData.filter(x => x.sampleSize)[activeTabCo]?.DistributedBoosterSampleSize : 0) :
        parseInt(v.CostingData.filter(x => x.sampleSize && x.coid == v.selectedCO.filter(mx => mx.selected)[activeTabCo].id)[0]?.DistributedBoosterSampleSize != undefined ? v.CostingData.filter(x => x.sampleSize && x.coid == v.selectedCO.filter(mx => mx.selected)[activeTabCo].id)[0]?.DistributedBoosterSampleSize : 0) :
        (data.sampleSizeBooster ? parseInt(data.sampleSizeBooster) : 0))
        : data.sampleSizeBooster ? parseInt(data.sampleSizeBooster) : 0

      dtpush.Booster = isNaN(tempBooster) ? 0 : tempBooster
    }
    dtpush.CityCoverage = (groupname == "Field Cost") ? (m.SplitSample == 1 ?
      (v.CostingData.filter(x => x.sampleSize)[activeTabCo].DistributedCity != undefined ? v.CostingData.filter(x => x.sampleSize)[activeTabCo].DistributedCity.map(dc => dc.label).toString() : "") :
      (m.CostingOptions[activeTabCo].CostingOptionData.citiesCoverage != undefined ? m.CostingOptions[activeTabCo].CostingOptionData.citiesCoverage.map(dc => dc.label).toString() : "")) : "";
    dtpush.ProfileID = project.CostingProfiles[project?.CostingProfiles?.length - 1].id;
    dtpush.cid = c.CountryCode;
    dtpush.Country = c.CountryName;
    dtpush.MethodologyID = m.id;
    dtpush.Code = m.Code;
    dtpush.MethodologyIndex = mindex;
    dtpush.CostingOptionID = data.coid;
    dtpush.CountryIndex = cindex;
    dtpush.Methodology = m.Label;
    dtpush.Vendor = v.label;
    dtpush.VendorID = v.value;
    dtpush.Costingtype = groupname;
    dtpush.CostingOption = data.cLabel;
    dtpush.VendorCO = DATACO
    dtpush.CostingOptionid = data.id;
    dtpush.LOI = data?.lengthOfInterview ? data.lengthOfInterview : data?.loi ? data.loi : "0";
    dtpush.saveAsDraft = v?.saveAsDraft
    dtpush.ConstantCostFromVendor = data.GroupValues[groupname] != undefined || !isNaN(data.GroupValues[groupname]) ?
      (parseInt(data.GroupValues[groupname])) : 0;
    tempConstCostFromVendor = data.GroupValues[groupname] == undefined ? 0 : (DATACO.map((c) => c.group == groupname && c.type != "TimingSection" && data.coid == c.coid && (isNaN(c.VendorDataUSD) || c.VendorDataUSD == '' ? "0" : c.VendorDataUSD)).filter(m => m).map(y => y).reduce((a, b) => Math.round(a) + Math.round(b) + 0, 0)).toFixed(4)
    dtpush.ConstantCostFromVendor = isNaN(tempConstCostFromVendor) ? 0 : tempConstCostFromVendor




    // dtpush.ConstantCostFromVendor = data.GroupValues[groupname] != undefined || !isNaN(data.GroupValues[groupname]) ?
    //  (parseInt(data.GroupValues[groupname]) /
    // (project.CostingProfiles[project?.CostingProfiles?.length - 1].ProfileSetting.CurrenciesData &&
    //   project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].Currency &&
    //   project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].Currency.code ?
    //   project.CostingProfiles[project?.CostingProfiles?.length - 1].ProfileSetting.CurrenciesData.filter(curr => curr.CurrencyUnit == project.CostingProfiles[project?.CostingProfiles?.length - 1].
    //     CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].Currency.label).length > 0 &&
    //   (project.CostingProfiles[project?.CostingProfiles?.length - 1].ProfileSetting.CurrenciesData.filter(curr => curr.CurrencyUnit == project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].Currency.label)[0].ConversionRateToLocal == 0 ? 1 :
    //     project.CostingProfiles[project?.CostingProfiles?.length - 1].ProfileSetting.CurrenciesData.filter(curr => curr.CurrencyUnit == project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].Currency.label)[0].ConversionRateToLocal) : 1
    // )).toFixed(2) : 0;
    tempCostFromVendor = data.GroupValues[groupname] == undefined ? 0 : (DATACO.map((c) => c.group == groupname && c.type != "TimingSection" && data.coid == c.coid && (isNaN(c.VendorDataUSD) || c.VendorDataUSD == '' ? "0" : c.VendorDataUSD)).filter(m => m).map(y => y * currentcurrencyconv)?.reduce((a, b) => Math.round(a) + Math.round(b) + 0, 0)).toFixed(4)
    dtpush.CostFromVendor = isNaN(tempCostFromVendor) ? 0 : tempCostFromVendor




    if (flag == 1) {

      //  dtpush.CostFromVendor = isNaN(data.GroupValues[groupname] == undefined ? 0 : data.GroupValues[groupname]) ? 0 : parseInt(data.GroupValues[groupname] == undefined ? 0 : data.GroupValues[groupname])
      // dtpush.CostFromVendor = data.GroupValues[groupname] != undefined || !isNaN(data.GroupValues[groupname]) ?
      //   (parseInt(data.GroupValues[groupname])) : 0;

      // dtpush.CostFromVendor = isNaN(data.GroupValues[groupname] == undefined ? 0 : data.GroupValues[groupname]) ? 0 : parseInt(data.GroupValues[groupname] == undefined ? 0 : data.GroupValues[groupname] *
      //   currentcurrencyconv).toFixed(4)



      // tempCostFromVendor = data.GroupValues[groupname] == undefined ? 0 : (DATACO.map((c) => c.group == groupname && (isNaN(c.VendorDataUSD) || c.VendorDataUSD == '' ? "0" : c.VendorDataUSD)).filter(m => m).map(y => y * currentcurrencyconv).reduce((a, b) => Math.round(a) + Math.round(b) + 0, 0)).toFixed(4)
      // dtpush.CostFromVendor = isNaN(tempCostFromVendor) ? 0 : tempCostFromVendor

      //  DATACO.map((c) => c.group == groupname && (isNaN(c.VendorDataUSD) || c.VendorDataUSD == '' ? "0" : c.VendorDataUSD)).filter(m => m).map(y => y * currentcurrencyconv).reduce((a, b) => Math.round(a) + Math.round(b) + 0, 0)

      dtpush.CPI = parseInt(data.sampleSize) == 0 ? 0 : (isNaN(dtpush.CostFromVendor / parseInt(data.sampleSize)) ? 0 : (dtpush.CostFromVendor / parseInt(data.sampleSize)).toFixed(2));
      dtpush.CPIusd = parseInt(data.sampleSize) == 0 ? 0 : (isNaN(dtpush.ConstantCostFromVendor / parseInt(data.sampleSize)) ? 0 : (dtpush.ConstantCostFromVendor / parseInt(data.sampleSize)).toFixed(2));
      dtpush.FinalCPI = parseInt(data.sampleSize) == 0 ? 0 : (isNaN(dtpush.CostFromVendor / parseInt(data.sampleSize)) ? 0 : (dtpush.CostFromVendor / parseInt(data.sampleSize)).toFixed(2));
      dtpush.FinalCPIusd = parseInt(data.sampleSize) == 0 ? 0 : (isNaN(dtpush.ConstantCostFromVendor / parseInt(data.sampleSize)) ? 0 : (dtpush.ConstantCostFromVendor / parseInt(data.sampleSize)).toFixed(2));
      dtpush.TotalCost = parseFloat(dtpush.ConstantCostFromVendor);
      dtpush.TotalCostusd = parseFloat(dtpush.ConstantCostFromVendor);
    }
    else if (flag == 2 || flag == 3) {
      //   dtpush.CostFromVendor = data.GroupValues[groupname] != undefined || !isNaN(data.GroupValues[groupname]) ? (parseInt(data.GroupValues[groupname])) : 0

      // dtpush.CostFromVendor = data.GroupValues[groupname] != undefined || !isNaN(data.GroupValues[groupname]) ? (parseInt(data.GroupValues[groupname]) *
      //   currentcurrencyconv).toFixed(4) : 0

      //   dtpush.CostFromVendor = isNaN(data.GroupValues[groupname] == undefined ? 0 : data.GroupValues[groupname]) ? 0 : parseInt(data.GroupValues[groupname] == undefined ? 0 : DATACO.map((c) => c.group == groupname && (isNaN(c.VendorDataUSD) || c.VendorDataUSD == '' ? "0" : c.VendorDataUSD)).filter(m => m).map(y => y * currentcurrencyconv).reduce((a, b) => Math.round(a) + Math.round(b) + 0, 0)).toFixed(4)
      // dtpush.CostFromVendor = isNaN(data.GroupValues[groupname] == undefined ? 0 : data.GroupValues[groupname]) ? 0 : parseInt(data.GroupValues[groupname] == undefined ? 0 : data.GroupValues[groupname] *
      //   currentcurrencyconv).toFixed(4)

      //  tempCostFromVendor = data.GroupValues[groupname] == undefined ? 0 : (DATACO.map((c) => c.group == groupname && (isNaN(c.VendorDataUSD) || c.VendorDataUSD == '' ? "0" : c.VendorDataUSD)).filter(m => m).map(y => y * currentcurrencyconv).reduce((a, b) => Math.round(a) + Math.round(b) + 0, 0)).toFixed(4)
      //  dtpush.CostFromVendor = isNaN(tempCostFromVendor) ? 0 : tempCostFromVendor


      dtpush.CPI = parseInt(dtpush.SampleSize) == 0 ? 0 : (isNaN(dtpush.CostFromVendor / parseInt(dtpush.SampleSize)) ? 0 : (dtpush.CostFromVendor / parseInt(dtpush.SampleSize)).toFixed(2));
      dtpush.CPIusd = parseInt(dtpush.SampleSize) == 0 ? 0 : (isNaN(dtpush.ConstantCostFromVendor / parseInt(dtpush.SampleSize)) ? 0 : (dtpush.ConstantCostFromVendor / parseInt(dtpush.SampleSize)).toFixed(2));
      dtpush.FinalCPI = parseInt(dtpush.SampleSize) == 0 ? 0 : (isNaN(dtpush.CostFromVendor / parseInt(dtpush.SampleSize)) ? 0 : (dtpush.CostFromVendor / parseInt(dtpush.SampleSize)).toFixed(2));
      dtpush.FinalCPIusd = parseInt(dtpush.SampleSize) == 0 ? 0 : (isNaN(dtpush.ConstantCostFromVendor / parseInt(dtpush.SampleSize)) ? 0 : (dtpush.ConstantCostFromVendor / parseInt(dtpush.SampleSize)).toFixed(2));
      dtpush.TotalCost = dtpush.CostFromVendor != undefined ? dtpush.CostFromVendor : 0;
      dtpush.TotalCostusd = dtpush.ConstantCostFromVendor != undefined ? dtpush.ConstantCostFromVendor : 0;
    }
    return dtpush;
  }

  const styleModelBody = {
    minHeight: "400px",
    overflow: "hidden !important",
  };
  useEffect(() => {
    setCostingProfileIndex(project?.CostingProfiles?.length - 1)
  }, [])

  useEffect(() => {
    if (
      project?.CostingProfiles[project?.CostingProfiles?.length - 1]?.CountrySpecs.map(
        (cs) =>
          cs?.MethodologySpecs.filter((y, index) => y.CostingType == 3).length
      ).reduce((r, e) => r + e) > 0
    ) {
      //Finding First Country for Vendor Bidding
      var indexfc = project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs.filter(
        (cs) =>
          cs.MethodologySpecs.filter((sm) => sm.CostingType == 3).length > 0
      )[0].id;
      setActiveTabFC(
        project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs.findIndex(
          (m) => m.id == indexfc
        )
      );

      //Finding First Methodology for Vendor Bidding
      var indexsm = project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs.filter(
        (m) => m.id == indexfc
      )[0].MethodologySpecs.filter((y, index) => y.CostingType == 3)[0]?.id;
      setActiveTabSM(
        project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs.filter(
          (m) => m.id == indexfc
        )[0].MethodologySpecs.findIndex((m) => m.id == indexsm)
      );
      setactiveCoId(project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs.filter(
        (m) => m.id == indexfc
      )[0].MethodologySpecs.filter((m) => m.id == indexsm)[0]?.CostingOptions[0].id)

      setActiveTabCo(0)
    }
  }, []);
  useEffect(() => {
    ComparisonViewData()
    FinalTabRender()
  }, []);
  useEffect(() => {
    var action = false;
    project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs.forEach(a => a.MethodologySpecs.forEach(b => {
      b.SelectedVendor?.map(select => {
        if (select.selected.length > 0) {
          action = true
        }
      }
      )
    }))
    if (action == true) {
      setShowTabs(true)
    }
  }, [project])

  useEffect(() => {
    project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs.forEach((c, cindex) =>
      c.MethodologySpecs.forEach((m, mindex) => {

        if (m.Currency)
          m.SelectedCurrency = m.Currency;
        else
          m.SelectedCurrency = currencyDropdown()[0];
      }))

  }, [])

  useEffect(() => {
    var datanew = [];
    var grouplist = [];
    setactiveDropCurrency(project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedCurrency);
    setactiveInternalDropCurrency(project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedCurrency);
    setsubmit(project.CostingProfiles[project?.CostingProfiles?.length - 1].ProfileStatus == "6" ? true : false)

    project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs.forEach((c, cindex) =>
      c.MethodologySpecs.forEach((m, mindex) => {
        if (m.Vendors) {
          m.Vendors.filter(dv => !dv.decline).forEach(v => {

            if (v.CostingData && v.biddingType == 1 && project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].FullServiceRequired != 1) {
              if (m.Label == project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].Label &&
                c.CountryName == project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].CountryName) {

                v.CostingData.filter(v => v.soid == 14)[activeTabCo] &&
                  v.CostingData.filter(v => v.soid == 14)[activeTabCo].GroupValues &&
                  Object.entries(v.CostingData.filter(v => v.soid == 14)[activeTabCo].GroupValues).forEach(([groupname, value]) => {
                    if (v.selectedCO.filter(a => a.id == (activeCoId == undefined ? project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions[0].id : activeCoId) && a.group == groupname)[0]?.selected) {
                      var data = v.CostingData.filter(e => e.soid == 14)[activeTabCo]
                      var datapush = {};
                      var flag = 1;
                      datanew.push({ ...datapush, ...saveDataNew(i, data, m, project, c, mindex, cindex, v, groupname, flag, v.CostingData) });
                      i++
                    }
                  })
              }
            }
            if (v.CostingData && v.biddingType == 0 && project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].FullServiceRequired != 1) {
              if (
                m.Label == project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].Label &&
                c.CountryName == project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].CountryName) {

                v.CostingData.filter(v => v.soid == 14)[activeTabCo] &&
                  v.CostingData.filter(v => v.soid == 14)[activeTabCo].GroupValues &&
                  Object.entries(v.CostingData.filter(v => v.soid == 14)[activeTabCo].GroupValues).forEach(([groupname, value]) => {
                    if (v.selectedCO.filter(a => a.id == (activeCoId == undefined ? project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions[0].id : activeCoId) && a.group == "Field Cost")[0]?.selected) {
                      var data = v.CostingData.filter(e => e.soid == 14)[activeTabCo]
                      var dtpush = {};
                      var flag = 2;
                      datanew.push({ ...dtpush, ...saveDataNew(i, data, m, project, c, mindex, cindex, v, groupname, flag, v.CostingData) });
                      i++
                    }
                  }
                  )
              }
            }
            if (v.CostingData && v.biddingType == 2) {
              if (
                m.Label == project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].Label &&
                c.CountryName == project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].CountryName) {

                v.CostingData.filter(v => v.soid == 14)[activeTabCo] &&
                  v.CostingData.filter(v => v.soid == 14)[activeTabCo].GroupValues &&
                  Object.entries(v.CostingData.filter(v => v.soid == 14)[activeTabCo].GroupValues).forEach(([groupname, value]) => {
                    if (v.selectedCO.filter(a => a.id == (activeCoId == undefined ? project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions[0].id : activeCoId) && a.group == "Field Cost")[0]?.selected) {
                      var data = v.CostingData.filter(e => e.soid == 14)[activeTabCo]
                      var dtpush = {};
                      var flag = 3;
                      datanew.push({ ...dtpush, ...saveDataNew(i, data, m, project, c, mindex, cindex, v, groupname, flag, v.CostingData) });
                      i++
                    }
                  }
                  )
              }
            }
            setSampleSizeState(project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions[activeTabCo].id)
          })
        }
      }))

    var tcsVendor = datanew.filter(vendor => vendor.Vendor == "TCS")


    var stubs = _.uniq(datanew?.map(a => a.Costingtype)?.filter(s => s.Type != "Internal Cost" && s.Costingtype != "Total Internal Cost"))?.length
    var id = project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions[activeTabCo]
    var selectedTCSVendor = project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
      ?.SelectedVendor
    if (tcsVendor) {
      tcsVendor?.forEach(v => {
        v["subFields"] = []
        if (v.VendorCO?.filter(a => a.coid == v.CostingOptionID && a.group == v.Costingtype)?.length > 0) {
          v.VendorCO?.filter(a => a.coid == v.CostingOptionID && a.group == v.Costingtype).forEach(f =>
            v.subFields.push({
              Name: f.name, id: f.soid, cost: f.VendorData, key: f.key,
              type: f.type
            })
          )
        }
      })

    }
    if (selectedTCSVendor == undefined) {
      let tempdata = {}
      tempdata.id = id.id;
      tempdata.name = id.Name;
      tempdata.TotalCount = stubs;
      tempdata.selected = tcsVendor

      project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]['SelectedVendor'] = [tempdata]

    }
    else {
      var filtercostingid = selectedTCSVendor.filter(co => co.id == id.id)
      if (filtercostingid.length > 0) {
        var filtered = [...filtercostingid[0].selected.filter(x => x.Vendor != "TCS"), tcsVendor]
        var filter = filtered.flat();
        project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor.filter(co => co.id == id.id)[0].selected = filter
        project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor.filter(co => co.id == id.id)[0].TotalCount = stubs
      }
      else {
        let tempdata = {}
        tempdata.id = id.id;
        tempdata.name = id.Name;
        tempdata.TotalCount = stubs;
        tempdata.selected = tcsVendor
        project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]['SelectedVendor'].push(tempdata)
      }
    }
    dispatch({
      type: currentProjectActions.UPDATE_NEW_PROJECT,
      newProject: { ...project, "data": datanew },
    });
  }, [project, activeTabCo, activeTabSM, activeTabFC]);

  const project = useSelector(
    ({ currentProject }) => currentProject.newProject
  );
  useEffect(() => {
    if (project.data && project.data.length > 0)
      setNonselectarray(
        project.data
          .filter(
            (d, di) =>
              props.saveselectedrows.filter(
                (s) =>
                  s.CostingOptionID == d.CostingOptionID &&
                  s.VendorID != d.VendorID
              )[0]
          )
          .map((h) => project.data.indexOf(h))
      );
  }, []);

  function Savevendor() {
    var c = props.saveselectedrows;
    if (
      project.CostingProfiles[project?.CostingProfiles?.length - 1].CostingDatum != null &&
      project.CostingProfiles[project?.CostingProfiles?.length - 1].CostingDatum.CostingData.length > 0
    ) {
      var clone = project.CostingProfiles[project?.CostingProfiles?.length - 1].CostingDatum.CostingData;
      props.saveselectedrows.forEach(
        (Y) =>
        (Object.entries(
          Object.entries(
            clone
              .filter((x) => x.Code == Y.Code)
              .map((s) =>
                Object.entries(s.rows).filter(
                  ([fkey, fvalue]) => fvalue.id == 41
                )
              )
          )[0][1][0][1].cells
        ).filter((d) => d[1].coid == Y.CostingOptionID)[0][1]["value"] =
          Y.CostFromVendor)
      );
      props.saveselectedrows.forEach(
        (Y) =>
        (Object.entries(
          Object.entries(
            clone
              .filter((x) => x.Code == Y.Code)
              .map((s) =>
                Object.entries(s.rows).filter(
                  ([fkey, fvalue]) => fvalue.id == 41
                )
              )
          )[0][1][0][1].cells
        ).filter((d) => d[1].coid == Y.CostingOptionID)[0][1]["text"] =
          Y.CostFromVendor.toString())
      );

      const profileTemp = update(project.CostingProfiles[project?.CostingProfiles?.length - 1], {
        CostingDatum: {
          CostingData: {
            $set: JSON.stringify(clone),
          },
        },
      });

      axios
        .put(
          baseurl + "costingprofiles/updateCostingInput/" +
          project.CostingProfiles[project?.CostingProfiles?.length - 1].id,
          profileTemp,
          {
            headers: { "auth-token": localStorage.getItem("auth-token") },
          }
        )
        .then((res) => {
          dispatch(
            currentCostingActions.getCosting(project.CostingProfiles[project?.CostingProfiles?.length - 1].id)
          );
        });
    }

    dispatch(proposalActions.saveProfile(props.saveselectedrows));
    toastr.success("Vendors Saved");
  }
  const tableColumnsSummary = [
    {
      dataField: "Country",
      text: "Market",
      sort: true,
      formatter: profileMethodologyFormatter,
      formatExtraData: {
        dataField: "Country",
        color: false,
      },
      filter: selectFilter({
        options: getProfileSelectOptions,
      }),
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "120px", padding: "2px", textAlign: "center" };
      },
    },
    {
      dataField: "MethodologyName",
      text: "Methodology",
      sort: true,
      filter: textFilter(),
      filter: selectFilter({
        options: getProfileSelectOptions,
      }),
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "120px", padding: "2px", textAlign: "center" };
      },
    },
    {
      dataField: "SampleType",
      text: "Sample Type",
      sort: true,
      filter: selectFilter({
        options: getProfileSelectOptions,
      }),
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "120px", padding: "2px", textAlign: "center" };
      },
    },
    {
      dataField: "VendorName",
      text: "Vendor",
      sort: true,
      filter: selectFilter({
        options: getProfileSelectOptions,
      }),
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "120px", padding: "2px", textAlign: "center" };
      },
    },
    {
      dataField: "LOI",
      text: "LOI",
      sort: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "60px", padding: "2px", textAlign: "center" };
      },
    },
    {
      dataField: "FWD",
      text: "FWD",
      sort: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "60px", padding: "2px", textAlign: "center" };
      },
    },
    {
      dataField: "FinalSampleSize",
      text: project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions[0].CostingOptionSchema.find(a => a.id == "sampleSizeMain") ? "Sample Size Main " : "Number Of Groups",
      sort: true,
      formatter: AchievableSampleSizeFormatter,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "120px", padding: "2px", textAlign: "center" };
      },
    },
    {
      dataField: "CPI",
      text: "CPI Vendor",
      sort: true,
      formatter: CPIFormatter,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "60px", padding: "2px", textAlign: "center" };
      },
    },
    {
      dataField: "Total",
      text: "Vendor Total",
      sort: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "80px", padding: "2px", textAlign: "center" };
      },
    },
    {
      dataField: "CPIFinal",
      text: "CPI With Other Cost",
      sort: true,
      formatter: CPIFormatter,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "80px", padding: "2px", textAlign: "center" };
      },
    },
    {
      dataField: "FieldCosts",
      text: "Field Costs",
      sort: true,
      editable: false,
      hidden: isHidden("FieldCosts"),
      subTotals: true,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "80px", padding: "2px", textAlign: "center" };
      },
    },
    {
      dataField: "SetUpDeliveryCosts",
      text: "Set-up & Delivery Costs",
      sort: true,
      editable: false,
      hidden: isHidden("SetUpDeliveryCosts"),
      subTotals: true,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "80px", padding: "2px", textAlign: "center" };
      },
    },
    {
      dataField: "TranslationCosts",
      text: "Translation Costs",
      sort: true,
      editable: false,
      hidden: isHidden("TranslationCosts"),
      subTotals: true,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "80px", padding: "2px", textAlign: "center" };
      },
    },
    {
      dataField: "Other Costs",
      text: "OtherCosts",
      sort: true,
      editable: false,
      hidden: isHidden("OtherCosts"),
      subTotals: true,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "80px", padding: "2px", textAlign: "center" };
      },
    },
    {
      dataField: "CSOOPCosts",
      text: "CS OOP",
      sort: true,
      editable: false,
      hidden: isHidden("CSOOPCosts"),
      subTotals: true,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "80px", padding: "2px", textAlign: "center" };
      },
    },
    {
      dataField: "CSTimeCosts",
      text: "CS Time Costs",
      sort: true,
      editable: false,
      hidden: isHidden("CSTimeCosts"),
      subTotals: true,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "80px", padding: "2px", textAlign: "center" };
      },
    },
    {
      dataField: "VATOtherTax",
      text: "VAT/Other Tax Adjustment",
      sort: true,
      editable: false,
      hidden: isHidden("VATOtherTax"),
      subTotals: true,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "80px", padding: "2px", textAlign: "center" };
      },
    },
    {
      dataField: "TotalFinal",
      text: "Total With Other Costs",
      sort: true,
      editable: false,
      hidden: isHidden("TotalFinal"),
      subTotals: true,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "80px", padding: "2px", textAlign: "center" };
      },
    },
  ];

  const tableColumnsVendorSelection = [
    {
      dataField: "#",
      text: "#",
      editable: false,
    },
    {
      dataField: "Costingtype",
      text: "Costing Type",
      editable: false
    }]

  const tableColumnsSUD = [
    {
      dataField: "Costingtype",
      text: "Costing Type",
      editable: false,
      field: true,
      sud: true
    },
    {
      dataField: "SampleSize",
      text: project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions[0].CostingOptionSchema.find(a => a.id == "sampleSizeMain") ? "Sample Size Main" : "Number Of Groups",
      editable: false,
      field: true,
      sud: true
    },
    {
      dataField: "Vendor",
      text: "Vendor",
      sort: true,
      editable: false,
      field: true,
      sud: true
    },
    {
      dataField: "VendorID",
      text: "Vendor ID",
      sort: true,
      editable: false,
      hidden: true,
      field: true,
      sud: true
    },
    {
      dataField: "key",
      text: "key",
      sort: true,
      editable: false,
      hidden: true,
      field: true,
      sud: true
    },
    {
      dataField: "CostingOptionID",
      text: "CostingOptionID",
      sort: true,
      editable: false,
      hidden: true,
      field: true,
      sud: true
    },
    {
      dataField: "cid",
      text: "cid",
      sort: true,
      editable: false,
      hidden: true,
      field: true,
      sud: true
    },
    {
      dataField: "CostingOptionindex",
      text: "CostingOptionindex",
      sort: true,
      editable: false,
      hidden: true,
      field: true,
      sud: true
    },
    {
      dataField: "MethodologyIndex",
      text: "M Index",
      sort: true,
      editable: false,
      hidden: true,
      field: true,
      sud: true
    },
    {
      dataField: "MethodologyID",
      text: "MethodologyID",
      sort: true,
      editable: false,
      hidden: true,
      field: true,
      sud: true
    },
    {
      dataField: "ProfileID",
      text: "ProfileID",
      sort: true,
      editable: false,
      hidden: true,
      field: true,
      sud: true
    },
    {
      dataField: "CountryIndex",
      text: "C Index",
      sort: true,
      editable: false,
      hidden: true,
      field: true,
      sud: true
    },
    {
      dataField: "LOI",
      text: "LOI",
      sort: true,
      editable: false,
      field: true,
      sud: true
    },
    {
      dataField: "CostFromVendor",
      text: "Cost From Vendor",
      editable: false,
      field: true,
      sud: true
    },
    {
      dataField: "CPI",
      text: "CPI Vendor",
      formatter: CPIFormatter,
      sort: true,
      editable: false,
      field: true,
      sud: true
    },

  ];
  const tableColumns = [
    {
      dataField: "Costingtype",
      text: "Costing Type",
      editable: false,
      field: true,
      sud: true
    },
    {
      dataField: "ActualSampleSize",
      text: project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions[0].CostingOptionSchema.find(a => a.id == "sampleSizeMain") ? "Sample Size Main " : "Number Of Groups",
      editable: false,
      field: true,
      sud: false
    },

    {
      dataField: "SampleSize",
      text: project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SplitSample != 1 ? project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions[0].CostingOptionSchema.find(a => a.id == "sampleSizeMain") ? "Sample Size Main " : "Number Of Groups" : project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions[0].CostingOptionSchema.find(a => a.id == "sampleSizeMain") ? "Sample Size Main Split" : "Number Of Groups Split",
      editable: false,
      field: true,
      sud: true
    },
    {
      dataField: "Booster",
      text: "Booster",
      editable: false,
      field: true,
      sud: false
    },
    {
      dataField: "CityCoverage",
      text: "City Coverage",
      editable: false,
      field: true,
      sud: false
    },

    {
      dataField: "Vendor",
      text: "Vendor",
      sort: true,
      editable: false,
      field: true,
      sud: true

    },
    {
      dataField: "VendorID",
      text: "Vendor ID",
      sort: true,
      editable: false,
      hidden: true,
      field: true,
      sud: true
    },
    {
      dataField: "key",
      text: "key",
      sort: true,
      editable: false,
      hidden: true,
      field: true,
      sud: true
    },
    {
      dataField: "CostingOptionID",
      text: "CostingOptionID",
      sort: true,
      editable: false,
      hidden: true,
      field: true,
      sud: true
    },
    {
      dataField: "cid",
      text: "cid",
      sort: true,
      editable: false,
      hidden: true,
      field: true,
      sud: true
    },
    {
      dataField: "CostingOptionindex",
      text: "CostingOptionindex",
      sort: true,
      editable: false,
      hidden: true,
      field: true,
      sud: true
    },
    {
      dataField: "MethodologyIndex",
      text: "M Index",
      sort: true,
      editable: false,
      hidden: true,
      field: true,
      sud: true
    },
    {
      dataField: "MethodologyID",
      text: "MethodologyID",
      sort: true,
      editable: false,
      hidden: true,
      field: true,
      sud: true
    },
    {
      dataField: "ProfileID",
      text: "ProfileID",
      sort: true,
      editable: false,
      hidden: true,
      field: true,
      sud: true
    },
    {
      dataField: "CountryIndex",
      text: "C Index",
      sort: true,
      editable: false,
      hidden: true,
      field: true,
      sud: true
    },
    {
      dataField: "LOI",
      text: "LOI",
      sort: true,
      editable: false,
      field: true,
      sud: true
    },
    {
      dataField: "CostFromVendor",
      text: "Cost From Vendor",
      editable: false,
      field: true,
      sud: true
    },
    {
      dataField: "CPI",
      text: "CPI Vendor",
      formatter: CPIFormatter,
      sort: true,
      editable: false,
      field: true,
      sud: true
    },

  ];
  const tableColumnsInternalCost = [
    {
      dataField: "Costingtype",
      text: "Costing Type",
      editable: false,
      footer: 'Total Cost',
      footerStyle: {
        color: "blue",
      }
    },
    {
      dataField: "SampleSize",
      text: project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions[0].CostingOptionSchema.find(a => a.id == "sampleSizeMain") ? "Sample Size Main " : "Number Of Groups",
      editable: false,
      footer: ''
    },
    {
      dataField: "LOI",
      text: "LOI",
      sort: true,
      editable: false,
      footer: ''
    },
    {
      dataField: "Vendor",
      text: "Vendor Name",
      sort: true,
      editable: false,
      footer: ''
    },
    {
      dataField: "CostFromVendor",
      text: "Total Vendor Cost",
      editable: false,
      footer: columnData => costforvendor(columnData),
      footerAlign: (column, colIndex) => 'right',
      footerStyle: {
        color: "blue",
      }
    },
    {
      dataField: "CPI",
      text: "CPI Vendor",
      formatter: CPIFormatter,
      sort: true,
      editable: false,
      footerAlign: (column, colIndex) => 'right',
      footer: cpi(),
      footerStyle: {
        color: "blue",
      }
    },
    {
      dataField: "VendorID",
      text: "Vendor ID",
      sort: true,
      editable: false,
      hidden: true,
    },
    {
      dataField: "key",
      text: "key",
      sort: true,
      editable: false,
      hidden: true,
    },
    {
      dataField: "MethodologyIndex",
      text: "M Index",
      sort: true,
      editable: false,
      hidden: true,
    },
    {
      dataField: "MethodologyID",
      text: "MethodologyID",
      sort: true,
      editable: false,
      hidden: true,
    },
    {
      dataField: "ProfileID",
      text: "ProfileID",
      sort: true,
      editable: false,
      hidden: true,
    },
    {
      dataField: "CountryIndex",
      text: "C Index",
      sort: true,
      editable: false,
      hidden: true,
    },
    {
      dataField: "FinalCPI",
      text: "Final CPI",
      editable: false,
      footer: finalcpi(),
      footerAlign: (column, colIndex) => 'right',
      footerStyle: {
        color: "blue",
      }
    },
    {
      dataField: "TotalCost",
      text: "Total Cost",
      sort: true,
      editable: false,
      footer: columnData => costforfinalvendor(columnData),
      footerAlign: (column, colIndex) => 'right',
      footerStyle: {
        color: "blue",
      }
    },
  ];

  function copyonmethlevel() {
    if (country.length == 0 && option == undefined) {
      toastr.error("Please select the values ");
    } else {
      var project1 = _.cloneDeep(project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs);
      var additionalcost = project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[
        activeTabFC
      ].MethodologySpecs[activeTabSM].Vendors[activeTabV].CostingData.filter(
        (tc) => tc.soid == 14 && tc.coid == option.id
      )[0].AdditionalCost;
      var finalcost = project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[
        activeTabFC
      ].MethodologySpecs[activeTabSM].Vendors[activeTabV].CostingData.filter(
        (tc) => tc.soid == 14 && tc.coid == option.id
      )[0].FinalCost;

      var Costloop = ["AdditionalCost", "FinalCost"];
      Costloop.forEach((cost) =>
        country[0].forEach((c) =>
          project1.forEach((co) => {
            if (co.CountryName == c.value) {
              if (co.MethodologySpecs[activeTabSM].Vendors.length > 0) {
                co.MethodologySpecs[activeTabSM].Vendors.forEach(
                  (v) => {
                    if (cost == "AdditionalCost") {
                      v.CostingData.filter((tc) => tc.soid == 14)[0].AdditionalCost = additionalcost
                    }
                    else if (cost == "FinalCost") {
                      v.CostingData.filter((tc) => tc.soid == 14)[0].FinalCost = finalcost
                    }
                  });
              }
            }
          })
        ));
      const newProposal = update(project.CostingProfiles[project?.CostingProfiles?.length - 1], {
        CountrySpecs: { $set: project1 },
      });
      updateproject(newProposal);
      toastr.success("Copied Successfully");
      setcountrymodal(false);
    }
  }

  function togglecostingOption(tab) {
    setModelSelectedRow([])
    if (activeTabCo !== tab) {
      setActiveTabCo(tab);
    }
  }

  function profileMethodologyFormatter(cell, row, rowIndex, formatExtraData) {
    let colors = [
      "#8064A2",
      "#0099C6",
      "#B7BF12",
      "#3B3EAC",
      "#DD4477",
      "#22AA99",
      "#6633CC",
      "#252d47",
      "#8B0707",
      "#329262",
      "#007681",
      "#3B3EAC",
      "#3e95cd",
      "#8e5ea2",
      "#3cba9f",
      "#c45850",
      "#C0504D",
      "#71B2C9",
      "#888B8D",
      "#316395",
      "#994499",
      "#E67300",
      "#5574A6",
    ];
    let selectedColor =
      formatExtraData && formatExtraData["color"]
        ? colors[row.RowIndex]
        : "none";
    return (
      <span>
        <strong style={{ color: selectedColor }}>
          {formatExtraData && formatExtraData["dataField"] == "Country"
            ? getLabel(cell)
            : cell}
        </strong>
      </span>
    );
  }

  function CPIFormatter(cell, row) {
    return (
      <span>
        <strong style={{ color: row["Color"] }}>{cell}</strong>
      </span>
    );
  }

  function AchievableSampleSizeFormatter(cell, row) {
    return (
      <Input
        type="number"
        id={"Achievable_" + row.RecordIndex}
        name="AchievableSampleSize"
        value={row["FinalSampleSize"]}
        disabled
      />
    );
  }

  function getProfileSelectOptions(cell) {
    let values = [];
    if (ComparisonViewData().length > 0)
      values = Array.from(
        new Set(ComparisonViewData().map((x) => x[cell.dataField]))
      );
    const selectOptions = {};
    values.forEach((x, i) => {
      selectOptions[x] = cell.dataField == "Country" ? getLabel(x) : x;
    });
    return selectOptions;
  }

  const GetMethodWiseAdditionalCost = (method, indexM) => {
    return method["OtherCosts"].map((x, indexOC) => {
      return (
        <div key={"row1_" + indexOC}>
          {" "}
          {x.header && (
            <Row form>
              <Col md={6} style={{ padding: "0.3rem" }}>
                <h6 style={{ color: "black" }}>{x.header}</h6>
              </Col>
              <Col md={6} style={{ padding: "0.3rem" }}>
                <h5 style={{ color: "blue" }}>
                  {" "}
                  Sub Total {method["SubTotals"][x.header]}
                </h5>
              </Col>
            </Row>
          )}
          <Row form>
            <Col md={6} style={{ padding: "0.3rem" }}>
              {x.text}
            </Col>
            <Col md={4} style={{ padding: "0.3rem" }}>
              <Input
                type="number"
                name={x.dataField}
                value={method["OtherCosts"][indexOC][x.dataField]}
                onChange={(e) =>
                  dispatch(
                    proposalActions.otherCostChange(
                      activeTabCountryPopup,
                      indexM,
                      indexOC,
                      e
                    )
                  )
                }
              />
            </Col>
            <Col md={1} style={{ padding: "0.3rem" }}>
              USD
            </Col>
          </Row>
        </div>
      );
    });
  };

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div>
        <button
          className="btn btn-secondary mt-2"
          onClick={() => setModelState(!modelState)}
        >
          Manage Other Costs
        </button>
        {/*
                <button className="btn btn-secondary mt-2" onClick={handleClick}>
                    Export
               </button>
               */}
      </div>
    );
  };

  function getLabel(cell) {
    if (!props.cc_masters["FieldingCountriesOptions"]) return cell;
    let label = props.cc_masters["FieldingCountriesOptions"].filter(
      (x) => x.Label == cell
    )[0]["Label"];
    return label;
  }

  function isHidden(field) {
    if (
      proposal["Data"] &&
      proposal["Data"].length > 0 &&
      field in proposal["Data"][0]
    )
      return false;
    else return true;
  }
  const expandRow = {
    clickToExpand: true,
    onExpand: (row, isExpand, rowIndex, e) => {
      if (row.Costingtype == "Field Cost") {
        setSampleSizeState(project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions[activeTabCo].id)
      }
      if (!isExpand) {
        setModelSelectedRow(modelRowIndex.filter(val => val != rowIndex))
      }
      else {
        setModelSelectedRow([...modelRowIndex, rowIndex])
      }
    },
    renderer: (row, rowIndex) => (
      row ?
        <Col md={12} >
          {row &&
            <Card>
              <ToolkitProvider
                keyField="key"
                data={SummaryTableData(project.data).filter(e => e.Costingtype == row.Costingtype)}
                columns={(row.Costingtype != "Field Cost" || project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SplitSample != 1) ? tableColumnsSUD.filter(a => a.sud == true) : tableColumns.filter(a => a.field == true)}
              >
                {(row.Costingtype != "Field Cost" || project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SplitSample != 1) ? (props) => (
                  <CardBody className="bg-white">
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4
                      wrapperClasses="table-responsive"
                      bordered={true}
                      selectRow={selectRow}
                    />
                  </CardBody>
                ) : (props) => (
                  <CardBody className="bg-white">
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4
                      wrapperClasses="table-responsive"
                      bordered={true}
                      selectRow={selectFieldCostRow}
                    />
                  </CardBody>
                )
                }
              </ToolkitProvider>
            </Card>
          }
        </Col> : ""
    ),
    onExpandAll: (isExpandAll, results, e, rows) => {
      if (!isExpandAll) {
        setModelSelectedRow([])
      }
      else {
        setModelSelectedRow(results?.filter(a => a)?.map(a => a?.key))
        setSampleSizeState(project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions[activeTabCo]?.id)
      }
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expanded: modelRowIndex,
    expandHeaderColumnRenderer: ({ isAnyExpands }) =>
      isAnyExpands ? (
        <MinusCircle width={16} height={16} />
      ) : (
        <PlusCircle width={16} height={16} />
      ),
    expandColumnRenderer: ({ expanded }) =>
      expanded ? (
        <MinusCircle width={16} height={16} />
      ) : (
        <PlusCircle width={16} height={16} />
      ),
    className: 'expanding-foo',

  };

  function enableDisableFieldVendors() {
    var selectedVendors = (project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor
      &&
      project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor.filter(co => co.id == activeCoId).length > 0
      &&
      project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor.filter(co => co.id == activeCoId)[0].selected) ?
      project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor.filter(co => co.id == activeCoId)[0].
        selected.filter(s => s.Costingtype == "Field Cost").map(b => b.key) : []
    if (initialSelectedField == true) {
      var diff = _.difference(SummaryTableData(project.data).filter(e => e.Costingtype == "Field Cost").map(a => a.key), selectedVendors)
    }
    else {
      var diff = _.difference(SummaryTableData(project.data).filter(e => e.Costingtype == "Field Cost").filter(c => c.SampleSize > currentCoSampleSize).map(a => a.key), selectedVendors)
    }
    return diff
  }
  const selectFieldCostRow = {
    mode: "checkbox",
    hideSelectAll: true,
    // nonSelectable: enableDisableFieldVendors(),

    selected:
      project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor
        &&
        project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor.filter(co => co.id == activeCoId).length > 0
        &&
        project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor.filter(co => co.id == activeCoId)[0].selected
        ?
        project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor.filter(co => co.id == activeCoId)[0].selected.map(a => a.key) : [],


    onSelect: (row, isSelect, rowIndex, e) => {
      var coSelected;
      var coIndex;
      var tempselected = project?.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor
        &&
        project?.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor.filter(co => co.id == activeCoId).length > 0
        &&
        project?.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor.filter(co => co.id == activeCoId)[0].selected
        ?
        project?.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor.filter(co => co.id == activeCoId)[0].selected.map(a => a.key) : []

      if (isSelect == true) {

        row["subFields"] = [];
        var first = _.head(project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].Vendors
          .filter(f => f.value == row.VendorID))


        first?.CostingData.filter(c => c.coid == row.CostingOptionID).filter(d => d.group == row.Costingtype).forEach(f => {
          row["subFields"].push({
            Name: f.name, id: f.soid, cost: f.VendorData, key: f.key,
            pleaseSpecify: (first?.selectedrows.
              find(a => a.id == f.soid)?.otherCost != undefined ? first?.selectedrows.find(a => a.id == f.soid)?.otherCost : ""),
            type: f.type

          })
        });

        row["vendorSchema"] = _.head(project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].Vendors
          .filter(f => f.value == row.VendorID))?.selectedrows.filter(sr => sr.group == row.Costingtype);

        row.CostFromVendor = row.ConstantCostFromVendor

        if (!project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]['SelectedVendor']) { //when selected vendor is not persent
          var index = project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions.findIndex(co => co.id == row.CostingOptionID)
          var tempOptionVal = project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions[index];
          var tempdata = {}
          tempdata.id = tempOptionVal.id;
          tempdata.name = tempOptionVal.Name;
          tempdata.selected = [row]
          project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]['SelectedVendor'] = [tempdata]
        }
        else if (project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]['SelectedVendor']
          && project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]['SelectedVendor'].findIndex(co => co.id == row.CostingOptionID) == -1) {
          //when other costing id not match current costing option id
          var index = project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions.findIndex(co => co.id == row.CostingOptionID)
          var tempOptionVal = project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions[index];
          var tempdata = {}
          tempdata.id = tempOptionVal.id;
          tempdata.name = tempOptionVal.Name;
          tempdata.selected = [row]
          project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]['SelectedVendor'] = [...project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]['SelectedVendor'], tempdata]
        }

        else {
          coIndex = project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]['SelectedVendor'].findIndex(co => co.id == row.CostingOptionID);
          coSelected = project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]['SelectedVendor'][coIndex];

          project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]['SelectedVendor'][coIndex].selected.push(row)

        }  //set selected vendor on methodlogy level




      }

      else
        if (isSelect == false) {

          var Index = project?.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]['SelectedVendor']?.findIndex(co => co.id == row.CostingOptionID);

          if (Index != -1 || Index != undefined) {

            project.CostingProfiles[project.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]['SelectedVendor'][Index].selected =
              project.CostingProfiles[project.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]['SelectedVendor'][Index].selected.filter(f => f.key != row.key);
          }
        }


      setSampleSizeState(row.CostingOptionID)



    },

    style: { backgroundColor: '#CCF1F9' },
  };

  function costforvendor(columnData) {
    var data = columnData.reduce((acc, item) => parseFloat(acc) + parseFloat(item == undefined ? 0 : item), 0).toFixed(2);
    setCosting(data)
    return data
  }
  function costforfinalvendor(columnData) {
    var data = columnData.reduce((acc, item) => parseFloat(acc) + parseFloat(item == undefined ? 0 : item), 0).toFixed(2);
    setfinalCosting(data)
    return data
  }
  function cpi() {
    let originalCoSample = project?.CostingProfiles[project?.CostingProfiles?.length - 1]?.CountrySpecs[activeTabFC]?.MethodologySpecs[activeTabSM]?.CostingOptions[activeTabCo]?.CostingOptionData[
      _.head(project?.CostingProfiles[project?.CostingProfiles?.length - 1]?.CountrySpecs[activeTabFC]?.MethodologySpecs[activeTabSM]?.CostingOptions[0]?.CostingOptionSchema?.filter(a => a.id?.includes("numberOfGroup")))?.id ||
      _.head(project?.CostingProfiles[project?.CostingProfiles?.length - 1]?.CountrySpecs[activeTabFC]?.MethodologySpecs[activeTabSM]?.CostingOptions[0]?.CostingOptionSchema.filter(a => a.id?.includes("sampleSize")))?.id] == undefined ? "0" :
      project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions[activeTabCo].CostingOptionData[
      _.head(project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions[0].CostingOptionSchema.filter(a => a.id?.includes("numberOfGroup")))?.id ||
      _.head(project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions[0].CostingOptionSchema.filter(a => a.id?.includes("sampleSize")))?.id]

    let result = (Costing == 0 || originalCoSample == '0') ? 0 : ((parseFloat(Costing) == undefined ? 0 : parseFloat(Costing)) / parseInt(originalCoSample)).toFixed(2);
    var resultfiltered = isNaN(result) ? 0 : result;
    return resultfiltered;
  }
  function finalcpi() {
    let originalCoSample = project?.CostingProfiles[project?.CostingProfiles?.length - 1]?.CountrySpecs[activeTabFC]?.MethodologySpecs[activeTabSM]?.CostingOptions[activeTabCo]?.CostingOptionData[
      _.head(project?.CostingProfiles[project?.CostingProfiles?.length - 1]?.CountrySpecs[activeTabFC]?.MethodologySpecs[activeTabSM]?.CostingOptions[0]?.CostingOptionSchema?.filter(a => a.id?.includes("numberOfGroup")))?.id ||
      _.head(project?.CostingProfiles[project?.CostingProfiles?.length - 1]?.CountrySpecs[activeTabFC]?.MethodologySpecs[activeTabSM]?.CostingOptions[0]?.CostingOptionSchema.filter(a => a.id?.includes("sampleSize")))?.id] == undefined ? "0" :
      project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions[activeTabCo].CostingOptionData[
      _.head(project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions[0].CostingOptionSchema.filter(a => a.id?.includes("numberOfGroup")))?.id ||
      _.head(project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions[0].CostingOptionSchema.filter(a => a.id?.includes("sampleSize")))?.id]
    let result = (finalCosting == 0 || originalCoSample == '0') ? 0 : ((parseFloat(finalCosting) == undefined ? 0 : parseFloat(finalCosting)) / parseInt(originalCoSample)).toFixed(2);
    var resultfiltered = isNaN(result) ? 0 : result;
    return resultfiltered;
  }

  const selectRow = {
    mode: "radio",
    selected:
      project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor
        &&
        project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor.filter(co => co.id == activeCoId).length > 0
        &&
        project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor.filter(co => co.id == activeCoId)[0].selected
        ?
        project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor.filter(co => co.id == activeCoId)[0].selected.map(a => a.key) : [],
    onSelect: (row, isSelect, rowIndex, e) => {
      var coSelected;
      var coIndex;
      //  if (e.target.value == 'on') {
      row["subFields"] = [];
      var first = _.head(project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].Vendors
        .filter(f => f.value == row.VendorID))
      first?.CostingData.filter(c => c.coid == row.CostingOptionID).filter(d => d.group == row.Costingtype).forEach(f => {
        row["subFields"].push({
          Name: f.name, id: f.soid, cost: f.VendorData, key: f.key, type: f.type,
          pleaseSpecify: (first?.selectedrows.
            find(a => a.id == f.soid)?.otherCost != undefined ? first?.selectedrows.find(a => a.id == f.soid)?.otherCost : "")
        })

      });
      row["vendorSchema"] = _.head(project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].Vendors
        .filter(f => f.value == row.VendorID))?.selectedrows.filter(sr => sr.group == row.Costingtype);
      row.CostFromVendor = row.ConstantCostFromVendor
      if (!project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]['SelectedVendor']) { //when selected vendor is not persent
        var index = project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions.findIndex(co => co.id == row.CostingOptionID)
        var tempOptionVal = project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions[index];
        var tempdata = {}
        tempdata.id = tempOptionVal.id;
        tempdata.name = tempOptionVal.Name;
        tempdata.selected = [row]
        project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]['SelectedVendor'] = [tempdata]
      }
      else if (project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]['SelectedVendor'] && project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]['SelectedVendor'].findIndex(co => co.id == row.CostingOptionID) == -1) { //when other costing id not match current costing option id
        var index = project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions.findIndex(co => co.id == row.CostingOptionID)
        var tempOptionVal = project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions[index];
        var tempdata = {}
        tempdata.id = tempOptionVal.id;
        tempdata.name = tempOptionVal.Name;
        tempdata.selected = [row]
        project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]['SelectedVendor'] = [...project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]['SelectedVendor'], tempdata]
      }
      else {
        coIndex = project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]['SelectedVendor'].findIndex(co => co.id == row.CostingOptionID);
        coSelected = project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]['SelectedVendor'][coIndex];
        if (coSelected.selected.some(q => q.Costingtype == row.Costingtype)) {
          project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]['SelectedVendor'][coIndex].selected = [...coSelected.selected.filter(y => y.Costingtype != row.Costingtype), row]
        }
        else {
          project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]['SelectedVendor'][coIndex].selected.push(row)
        }
      }   //set selected vendor on methodlogy level
      //}
    },
  };

  function setSampleSizeState(rowCostingOptionID) {
    var Index = project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]['SelectedVendor'] ?
      project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]['SelectedVendor'].findIndex(co => co.id == rowCostingOptionID) : -1
    var originalCoSample = project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions[activeTabCo].CostingOptionData[
      _.head(project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions[0].CostingOptionSchema.filter(a => a.id?.includes("numberOfGroup")))?.id ||
      _.head(project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions[0].CostingOptionSchema.filter(a => a.id?.includes("sampleSize")))?.id] == undefined ? "0" :
      project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions[activeTabCo].CostingOptionData[
      _.head(project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions[0].CostingOptionSchema.filter(a => a.id?.includes("numberOfGroup")))?.id ||
      _.head(project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions[0].CostingOptionSchema.filter(a => a.id?.includes("sampleSize")))?.id]
    if (Index != -1) {
      var existingSS = project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor?.filter(co => co.id == activeCoId).length ?

        (project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
          .SelectedVendor?.filter(co => co.id == activeCoId)[0].selected
          .some(s => s.Costingtype == "Field Cost") ? project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]?.SelectedVendor[Index]
            .selected?.filter(fc => fc.Costingtype == "Field Cost").length > 0 ? project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor[Index]
              .selected?.filter(fc => fc.Costingtype == "Field Cost")?.map(m => m.SampleSize)?.reduce((a, b) => a + b) : 0 : 0) : 0

      // setInitialSelectedField(false)                         
      setCurrentCoSampleSize(parseInt(originalCoSample) - existingSS)
    }
    else {
      setCurrentCoSampleSize(parseInt(originalCoSample))
    }
  }

  function saveInternalCost() {
    var res = []
    var SumInternal = {}
    res = project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor.filter(co => co.id == activeCoId)[0].selected.filter(el => {
      return !internalCost.find(element => {
        return element.key === el.key
      })
    })
    project.CostingProfiles[project.CostingProfiles.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor.filter(co => co.id == activeCoId)[0].selected = _.union(res, internalCost, SumInternal)
    var selectedmeth = project.CostingProfiles[project.CostingProfiles.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM];

    var currentcurrencyconv = project.CostingProfiles[project.CostingProfiles.length - 1].ProfileSetting.CurrenciesData &&
      project.CostingProfiles[project.CostingProfiles.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].Currency &&
      project.CostingProfiles[project.CostingProfiles.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].Currency.code ?
      project.CostingProfiles[project.CostingProfiles.length - 1].ProfileSetting.CurrenciesData.filter(curr => curr.CurrencyUnit == project.CostingProfiles[project.CostingProfiles.length - 1].
        CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].Currency.label).length > 0 &&
      (project.CostingProfiles[project.CostingProfiles.length - 1].ProfileSetting.CurrenciesData.filter(curr => curr.CurrencyUnit == project.CostingProfiles[project.CostingProfiles.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].Currency.label)[0].ConversionRateToLocal == 0 ? 1 :
        project.CostingProfiles[project.CostingProfiles.length - 1].ProfileSetting.CurrenciesData.filter(curr => curr.CurrencyUnit == project.CostingProfiles[project.CostingProfiles.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].Currency.label)[0].ConversionRateToLocal) : 1

    if (activeDropCurrency)
      currentcurrencyconv = activeDropCurrency.ConversionRateToLocal != 0 ? activeDropCurrency.ConversionRateToLocal : 1

    var currency;
    var CurrencyConvRateToLocal;
    setinternalCost([])
    setInternalCost(!InternalCost);
    setinternalOtherSpecify({});
    SumInternal.key = "SumInternal"
    SumInternal.Type = "SumInternal"
    SumInternal.Costingtype = "Total Internal Cost"
    SumInternal.ConstantCostFromVendor = 0
    SumInternal.CostFromVendor = 0
    SumInternal.CPI = 0
    SumInternal.CostingOption = selectedmeth.CostingOptions.find(co => co.id == activeCoId).Name
    SumInternal.Methodology = selectedmeth.Label
    SumInternal.Country = project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].CountryName

    var SumInternalTotalCost = selectedmeth.SelectedVendor &&
      selectedmeth.SelectedVendor.filter(co => co.id == activeCoId).length > 0 &&
      selectedmeth.SelectedVendor.filter(co => co.id == activeCoId)[0].selected.filter(el => el.Type == "Internal Cost").length > 0 ?
      selectedmeth.SelectedVendor.filter(co => co.id == activeCoId)[0].selected.filter(el => el.Type == "Internal Cost")?.map(row => row.TotalCost)?.reduce((cost, sum) => cost + (isNaN(parseFloat(sum)) ? 0 : parseFloat(sum)) + 0, 0) : 0;

    SumInternal.TotalCost = isNaN(SumInternalTotalCost) ? 0 : parseFloat(SumInternalTotalCost).toFixed(2);

    var SumInternalTotalCostusd = selectedmeth.SelectedVendor &&
      selectedmeth.SelectedVendor.filter(co => co.id == activeCoId).length > 0 &&
      selectedmeth.SelectedVendor.filter(co => co.id == activeCoId)[0].selected.filter(el => el.Type == "Internal Cost").length > 0 ?
      selectedmeth.SelectedVendor.filter(co => co.id == activeCoId)[0].selected.filter(el => el.Type == "Internal Cost")?.map(row => row.TotalCost)?.map(x => (x / currentcurrencyconv).toFixed(2))?.reduce((cost, sum) => cost + (isNaN(parseFloat(sum)) ? 0 : (parseFloat(sum))) + 0, 0) : 0
    // selectedmeth.SelectedVendor.filter(co => co.id == activeCoId)[0].selected.filter(el => el.Type == "Internal Cost")?.map(row => row.TotalCost)?.map(x => (x / currentcurrencyconv).toFixed(2))?.reduce((cost, sum) => cost + (isNaN(parseFloat(sum)) ? 0 : Math.round(parseFloat(sum)))+0,0) : 0;

    //var SumInternalTotalCostusd = SumInternal.TotalCost /currentcurrencyconv;

    //(activeInternalDropCurrency == undefined ? project.CostingProfiles[project.CostingProfiles.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].Currency.ConversionRateToLocal : activeInternalDropCurrency.ConversionRateToLocal)

    SumInternal.TotalCostusd = isNaN(SumInternalTotalCostusd) ? 0 : parseFloat(SumInternalTotalCostusd).toFixed(2);


    // var SumInternalTotalCost = selectedmeth.SelectedVendor &&
    //   selectedmeth.SelectedVendor.filter(co => co.id == activeCoId).length > 0 &&
    //   selectedmeth.SelectedVendor.filter(co => co.id == activeCoId)[0].selected.filter(el => el.Type == "Internal Cost" && el.SelectedCurrency?.label != "USD").length > 0 ?
    //   selectedmeth.SelectedVendor.filter(co => co.id == activeCoId)[0].selected.filter(el => el.Type == "Internal Cost" && el.SelectedCurrency?.label != "USD")?.map(row => row.TotalCost)?.reduce((cost, sum) => cost + (isNaN(parseInt(sum)) ? 0 : parseInt(sum))) : 0;

    // var SumInternalTotalCostUSD = selectedmeth.SelectedVendor &&
    //   selectedmeth.SelectedVendor.filter(co => co.id == activeCoId).length > 0 &&
    //   selectedmeth.SelectedVendor.filter(co => co.id == activeCoId)[0].selected.filter(el => el.Type == "Internal Cost" && el.SelectedCurrency?.label == "USD").length > 0 ?
    //   selectedmeth.SelectedVendor.filter(co => co.id == activeCoId)[0].selected.filter(el => el.Type == "Internal Cost" && el.SelectedCurrency?.label == "USD")?.map(row => row.TotalCost)?.reduce((cost, sum) => cost + (isNaN(parseInt(sum)) ? 0 : parseInt(sum))) : 0;

    // var SumInternalTotalCostfiltered = isNaN(SumInternalTotalCost) ? 0 : SumInternalTotalCost;

    // var SumInternalTotalCostUSDfiltered = isNaN(SumInternalTotalCostUSD) ? 0 : SumInternalTotalCostUSD;

    // if (currencyDropdown().length > 1) {
    //   currency = currencyDropdown().filter(f => f?.label != "USD")[0];
    //   CurrencyConvRateToLocal = isNaN(currency.ConversionRateToLocal) ? 0 : currency.ConversionRateToLocal;
    // }
    // else {
    //   currency = currencyDropdown()[0];
    //   CurrencyConvRateToLocal = isNaN(currency.ConversionRateToLocal) ? 0 : currency.ConversionRateToLocal;

    // }

    // SumInternal.TotalCost = parseInt(SumInternalTotalCostfiltered) + parseInt(SumInternalTotalCostUSDfiltered * CurrencyConvRateToLocal)

    // SumInternal.TotalCostusd = parseInt(SumInternalTotalCostUSDfiltered) + parseInt(SumInternalTotalCostfiltered / CurrencyConvRateToLocal)

    //remove field of TotalCost and again insert
    var SumInternalFinalCPI = (SumInternal.TotalCost /
      parseInt(selectedmeth.CostingOptions.filter(co => co.id == activeCoId)[0]
        .CostingOptionData.sampleSizeMain)).toFixed(2);

    SumInternal.FinalCPI = isNaN(SumInternalFinalCPI) ? 0 : SumInternalFinalCPI;
    project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor.filter(co => co.id == activeCoId)[0].selected = [
      ...project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor.filter(co => co.id == activeCoId)[0].selected.filter(row => row.Type != "SumInternal"),
      SumInternal]

    project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].copySelectedVendor = _.cloneDeep(project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor);

  }

  function mappingInternalCostingType(row) {

    if (project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor
      &&
      project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor.length > 0
      &&
      project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor.filter(co => co.id == activeCoId).length > 0
      &&
      project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor.filter(co => co.id == activeCoId)[0].selected &&
      project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor.filter(co => co.id == activeCoId)[0].selected.length > 0 &&
      project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor.filter(co => co.id == activeCoId)[0].selected.filter(v => v.key == row.key).length > 0
    ) {
      return project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor.filter(co => co.id == activeCoId)[0].selected
        .filter(v => v.key == row.key)[0].Costingtype
    }
  }

  function setInternalCostCurrency() {
    var tempinternalCost = project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor
      .filter(co => co.id == activeCoId)[0].selected.filter(el => el.Type == "Internal Cost")
    if (tempinternalCost.length > 0) {
      tempinternalCost.map(temp => {
        if (activeInternalDropCurrency && activeInternalDropCurrency.label != "USD") {
          temp.AdditionalCost = (temp.AdditionalCostUSD * activeInternalDropCurrency.ConversionRateToLocal).toFixed(2)
        }
        if (activeInternalDropCurrency && activeInternalDropCurrency.label == "USD") {
          temp.AdditionalCost = temp.AdditionalCostUSD
        }
      })
    }
  }

  function ConditionalSummaryTabs(savedVendorSelection) {
    var tabs = []
    if (ShowTabs == true) {
      if (project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs?.length > 1) {
        tabs = Tabdata
      }
      else {

        tabs = Tabdata.filter(a => a.value != "v3")
      }
    }
    else {
      tabs = Tabdata.filter(a => a.value == "v1")
    }
    return tabs
  }

  function SetinternalCostValue(e, row) {
    var res = project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor
    var currency = activeInternalDropCurrency == undefined ?
      project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].Currency : activeInternalDropCurrency

    if (!currency)
      currency = currencyDropdown()[0];

    if (res.filter(co => co.id == activeCoId)[0].selected.filter(d => d.key == row.key).length > 0) {
      res.filter(co => co.id == activeCoId)[0].selected.filter(d => d.key == row.key)[0].AdditionalCost = isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value)
      res.filter(co => co.id == activeCoId)[0].selected.filter(d => d.key == row.key)[0].AdditionalCostUSD = currency.label == "USD" ? (isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value)) : (isNaN((parseFloat(e.target.value) / currency.ConversionRateToLocal)) ? 0 : (parseFloat(e.target.value) / currency.ConversionRateToLocal))
      res.filter(co => co.id == activeCoId)[0].selected.filter(d => d.key == row.key)[0].TotalCost = isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value)
      res.filter(co => co.id == activeCoId)[0].selected.filter(d => d.key == row.key)[0].CostForTable = e.target.value
      res.filter(co => co.id == activeCoId)[0].selected.filter(d => d.key == row.key)[0].SelectedCurrency = currency

    }
    else {
      row.AdditionalCost = isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value)
      row.AdditionalCostUSD = currency?.label == "USD" ? (isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value)) : (parseFloat(e.target.value) / currency.ConversionRateToLocal)
      row.TotalCost = isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value)
      row.CostForTable = e.target.value
      row.Country = project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].CountryName
      row.Methodology = project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].Label
      row.CostingOption = project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions[activeTabCo].Name
      res.filter(co => co.id == activeCoId)[0].selected.push(row);
    }
    const newProposal = update(project.CostingProfiles[project?.CostingProfiles?.length - 1], {
      CountrySpecs: {
        [activeTabFC]: {
          MethodologySpecs: {
            [activeTabSM]: {
              SelectedVendor: { $set: res },
            },
          },
        },
      },
    });
    updateproject(newProposal);
  }
  function updateName(e, row) {
    var res = _.cloneDeep(project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor)
    var country = project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC]
    if (res.filter(co => co.id == activeCoId)[0].selected.filter(d => d.key == row.key).length > 0) {
      res.filter(co => co.id == activeCoId)[0].selected.filter(v => v.key == row.key)[0].pleaseSpecify = e;
    }
    else {
      row.AdditionalCost = ""
      row.AdditionalCostUSD = ""
      row.TotalCost = ""
      row.CostForTable = ""
      row.Country = country.CountryName
      row.Methodology = country.MethodologySpecs[activeTabSM].Label
      row.CostingOption = country.MethodologySpecs[activeTabSM].CostingOptions[activeTabCo].Name
      row.pleaseSpecify = e
      res.filter(co => co.id == activeCoId)[0].selected.push(row);
    }
    const newProposal = update(project.CostingProfiles[project?.CostingProfiles?.length - 1], {
      CountrySpecs: {
        [activeTabFC]: {
          MethodologySpecs: {
            [activeTabSM]: {
              SelectedVendor: { $set: res },
            },
          },
        },
      },
    });
    updateproject(newProposal);
  }

  function CopyInternalCost() {

    if (project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor.filter(co => co.id != activeCoId).length > 0) {
      var temp = project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor
        .filter(co => co.id == activeCoId)[0].selected.filter(x => x.Type == 'Internal Cost' || x.Type == 'SumInternal')
      project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor.filter(co => co.id != activeCoId).map(sv => {
        if (sv.selected && sv.selected.length > 0) {
          temp.map(copydata => {
            if (sv.selected.filter(c => c.Costingtype == copydata.Costingtype).length == 0) {

              if (copydata.Costingtype != "Total Internal Cost") {
                var data = {};
                data.AdditionalCost = copydata.AdditionalCost
                data.AdditionalCostUSD = copydata.AdditionalCostUSD
                data.TotalCost = copydata.TotalCost
                data.CostForTable = copydata.CostForTable
                data.Country = project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].CountryName
                data.Methodology = project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].Label
                data.Costingtype = copydata.Costingtype
                data.Type = "Internal Cost"
                data.CostingOption = sv.name
                data.key = copydata.key

                sv.selected.push(data)
              }
              else {
                var data = {};
                data.TotalCostusd = copydata.TotalCostusd
                data.FinalCPI = copydata.FinalCPI
                data.CostFromVendor = copydata.CostFromVendor
                data.ConstantCostFromVendor = copydata.ConstantCostFromVendor
                data.CPI = copydata.CPI
                data.TotalCost = copydata.TotalCost
                data.Country = project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].CountryName
                data.Methodology = project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].Label
                data.Costingtype = copydata.Costingtype
                data.Type = "SumInternal"
                data.CostingOption = sv.name
                data.key = copydata.key
                sv.selected.push(data)
              }

            }

            else {
              if (copydata.Costingtype != "Total Internal Cost") {

                sv.selected.filter(c => c.Costingtype == copydata.Costingtype)[0].AdditionalCost = copydata.AdditionalCost
                sv.selected.filter(c => c.Costingtype == copydata.Costingtype)[0].AdditionalCostUSD = copydata.AdditionalCostUSD
                sv.selected.filter(c => c.Costingtype == copydata.Costingtype)[0].TotalCost = copydata.TotalCost
                sv.selected.filter(c => c.Costingtype == copydata.Costingtype)[0].CostForTable = copydata.CostForTable
              }
              else {
                sv.selected.filter(c => c.Costingtype == copydata.Costingtype)[0].TotalCostusd = copydata.TotalCostusd
                sv.selected.filter(c => c.Costingtype == copydata.Costingtype)[0].FinalCPI = copydata.FinalCPI
                sv.selected.filter(c => c.Costingtype == copydata.Costingtype)[0].CostFromVendor = copydata.CostFromVendor
                sv.selected.filter(c => c.Costingtype == copydata.Costingtype)[0].ConstantCostFromVendor = copydata.ConstantCostFromVendor
                sv.selected.filter(c => c.Costingtype == copydata.Costingtype)[0].CPI = copydata.CPI
                sv.selected.filter(c => c.Costingtype == copydata.Costingtype)[0].TotalCost = copydata.TotalCost
              }
            }

          })
        }
      })
    }
    toastr.success("Success", "Internal Cost Copied");
  }
  function SelectAvailableCurrency(e) {
    var project1 = _.cloneDeep(project.CostingProfiles[project?.CostingProfiles?.length - 1])
    project1.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedCurrency = e;
    updateproject(project1);
    setactiveDropCurrency(e)
    setactiveInternalDropCurrency(e)
  }
  return (
    <>
      <Container fluid className="p-0">
        {
          <>
            <Row>
              <Col lg="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h5" className="my-2">
                      <Row>
                        <Col md={11}>
                          <Label>Project Details</Label>
                        </Col>
                        <Col md={1}>
                          {/* <FontAwesomeIcon
                            id="collapseProjectDetails"
                            icon={faChevronDown}
                            href="#collapseProjectDetails"
                          /> */}
                           
                          <Link  href="#collapseProjectDetails">
                          <Down id="collapseProjectDetails"/>
                          </Link>
                        </Col>
                      </Row>
                    </CardTitle>
                  </CardHeader>
                  <UncontrolledCollapse
                    toggler="#collapseProjectDetails"
                    defaultOpen={true}
                  >
                    <CardBody>
                      <Form>
                        <Row form>
                          <Col md={4}>
                            <FormGroup>
                              <Label>Project ID</Label>
                              <Input
                                type="text"
                                name="id"
                                value={props.project.ProjectId}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col md={4}>
                            <FormGroup>
                              <Label>Single/Multiple Country</Label>
                              <Input
                                type="text"
                                name="IsSingleCountry"
                                value={project.CostingProfiles && project.CostingProfiles.length > 0 && project.CostingProfiles[project?.CostingProfiles?.length - 1].IsMultiCountry == false ? 'Single Country' : 'Multi Country'}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col md={4}>
                            <FormGroup>
                              <Label>Commissioning Country</Label>
                              <input
                                type="text"
                                className="form-control w-100"
                                style={{ height: "2rem" }}
                                name="CommissioningCountry"
                                value={project.CommissioningCountry && project.CommissioningCountry != "" &&
                                  project.CostingProfiles && project.CostingProfiles.length > 0 && project.CostingProfiles[project?.CostingProfiles?.length - 1].ProfileSetting.CurrenciesData &&
                                  project.CostingProfiles[project?.CostingProfiles?.length - 1].ProfileSetting.CurrenciesData.length > 0 ? project.CostingProfiles[project?.CostingProfiles?.length - 1].ProfileSetting.CurrenciesData.filter(comm => comm.Code == project.CommissioningCountry)[0].Label : ""}
                                disabled
                              />
                            </FormGroup>
                          </Col>

                          <Col md={4}>
                            <FormGroup>
                              <Label>Background</Label>
                              <Input
                                type="textarea"
                                name="Background"
                                value={props.project.ProjectBackground || ""}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col md={4}>
                            <FormGroup>
                              <Label>Objectives</Label>
                              <Input
                                type="textarea"
                                name="Objectives"
                                value={props.project.ResearchObjectives || ""}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Form>
                    </CardBody>
                  </UncontrolledCollapse>
                </Card>
              </Col>
            </Row>
            {project.data && (
              <div className={"tab tabContentCostSummary"}>
                <Row>
                  <Col md={12} className={"methodologyTabs"}>
                    <Nav tabs>
                      {
                        project.CostingProfiles.length > 0 &&

                        ConditionalSummaryTabs(vendorSelectionSaved).map((sm, indx) => (
                          <NavItem key={sm.value}>
                            <NavLink
                              className={classnames({
                                active: activeTabSummary === indx,
                              })}
                              onClick={() => {
                                toggleSummary(indx);




                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <div>
                                  <b>{sm.label}</b>
                                </div>
                              </div>
                            </NavLink>
                          </NavItem>
                        ))}
                    </Nav>
                  </Col>
                </Row>
                <TabContent activeTab={activeTabSummary}>



                  {activeTabSummary == 0 && <card>
                    <Col md={12}>
                      {
                        <Card>
                          <Col md={12} className={"countryTabs tab-vertical"}>
                            <div className={"tab"}>
                              <Nav tabs>
                                {project.CostingProfiles.length > 0 &&
                                  project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs &&
                                  project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs.map(
                                    (fc, indxFC) =>
                                      fc.MethodologySpecs.filter(
                                        (sm) => sm.CostingType == 3 && !sm.NotApplicable
                                      ).length > 0 && (
                                        <NavItem key={fc.value}>
                                          <NavLink
                                            className={classnames({
                                              active: activeTabFC === indxFC,
                                            })}
                                            onClick={() => {
                                              toggle(indxFC);
                                            }}
                                          >
                                            <b>{fc.CountryName}</b>
                                          </NavLink>
                                        </NavItem>
                                      )
                                  )}
                              </Nav>
                              <TabContent activeTab={activeTabFC}>
                                {project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs &&
                                  project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs.map(
                                    (fc, indxFC) =>
                                      activeTabFC === indxFC && (
                                        <TabPane tabId={indxFC} key={fc.value}>
                                          <Card>
                                            <Row>
                                              <Col
                                                md={12}
                                                className={"methodologyTabs"}
                                              >
                                                <Nav tabs>
                                                  {project.CostingProfiles
                                                    .length > 0 &&
                                                    project.CostingProfiles[project?.CostingProfiles?.length - 1]
                                                      .CountrySpecs &&
                                                    project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[
                                                      activeTabFC
                                                    ].MethodologySpecs.map(
                                                      (sm, indxSM) =>
                                                        !sm.NotApplicable &&
                                                        sm.CostingType == 3 && (
                                                          <NavItem
                                                            key={sm.value}
                                                          >
                                                            <NavLink
                                                              className={classnames(
                                                                {
                                                                  active:
                                                                    activeTabSM ===
                                                                    indxSM,
                                                                }
                                                              )}
                                                              onClick={() => {
                                                                toggleSM(
                                                                  indxSM
                                                                );

                                                              }}
                                                            >
                                                              <div
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  justifyContent:
                                                                    "space-between",
                                                                  alignItems:
                                                                    "center",
                                                                  top: "-3rem",
                                                                }}
                                                              >
                                                                <div>
                                                                  <b>
                                                                    {sm.Label}
                                                                  </b>
                                                                </div>
                                                              </div>
                                                            </NavLink>
                                                          </NavItem>
                                                        )
                                                    )}
                                                </Nav>
                                                <TabContent
                                                  activeTab={activeTabSM}
                                                  style={{ padding: "0.25" }}
                                                >
                                                  <Col
                                                    md={12} >
                                                    <Row>
                                                      <Col md={3} >
                                                        <FormGroup className="ml-5"
                                                        >
                                                          <Label style={{ fontWeight: "bolder", color: "#464241", margin: "0px 5px" }}>
                                                            Local Operation Support Required For FW (*)
                                                            {
                                                              project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs.filter(a => a.CostingType == 3).length > 1 &&
                                                              <FontAwesomeIcon
                                                                title="Copy data"
                                                                className="align-middle"
                                                                icon={faCopy}
                                                                style={{ cursor: "pointer", margin: "0px 5px" }}
                                                                onClick={() => {
                                                                  copyVendorType();
                                                                }}
                                                              />
                                                            }
                                                          </Label>
                                                          <Select
                                                            name="LocalOperationSupportRequiredForFW"
                                                            classNamePrefix="react-select"
                                                            options={optionforlocalsupport}
                                                            onChange={(e) => localoperationsupport(e)}
                                                            value={project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].VendorType != null ?
                                                              (project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].VendorType) : []}
                                                          />
                                                        </FormGroup>
                                                      </Col>
                                                      <Col md={4} />
                                                      <Col md={3} >
                                                        <FormGroup className="ml-5" >
                                                          <div style={{ backgroundColor: "#004e00" }}>
                                                            <Label style={{ fontWeight: "bolder", color: "white", marginLeft: "5px", marginTop: "5px" }}>Available Currency</Label>

                                                            <Select
                                                              name="CurrencySelection"
                                                              classNamePrefix="react-select"
                                                              options={project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs.length > 0 && _.head(project.CostingProfiles[project?.CostingProfiles?.length - 1].ProfileSetting.CurrenciesData) ? currencyDropdown() : []}
                                                              onChange={(e) => { setactiveDropCurrency(e); SelectAvailableCurrency(e) }}

                                                              value={project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs.length > 0 && project.CostingProfiles[project?.CostingProfiles?.length - 1].ProfileSetting.CurrenciesData.length > 0 ?
                                                                (currencyDropdown().length > 1 ?
                                                                  (currencyDropdown().filter(f => f?.label == project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedCurrency?.label).length > 0 ?
                                                                    currencyDropdown().filter(f => f?.label == project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedCurrency?.label) : currencyDropdown()[0]) : currencyDropdown()[0]) : []}
                                                              color="green"
                                                              style={{ Color: "green" }}
                                                            />

                                                          </div>
                                                        </FormGroup>
                                                      </Col>
                                                    </Row>


                                                    <Nav tabs>
                                                      {project.CostingProfiles
                                                        .length > 0 &&
                                                        project.CostingProfiles[project?.CostingProfiles?.length - 1]
                                                          .CountrySpecs &&
                                                        project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
                                                          .CostingOptions.map((Co, indexCo) =>

                                                          (

                                                            <NavItem

                                                              key={Co.Name}
                                                            >

                                                              {Co.Selected &&
                                                                <NavLink
                                                                  className={classnames(
                                                                    {
                                                                      active:
                                                                        activeTabCo === indexCo,
                                                                    }
                                                                  )}
                                                                  onClick={() => {
                                                                    togglecostingOption(indexCo)
                                                                    setactiveCoId(Co.id);
                                                                  }}
                                                                >
                                                                  <div
                                                                    style={{
                                                                      display:
                                                                        "flex",
                                                                      justifyContent:
                                                                        "space-between",
                                                                      alignItems:
                                                                        "center",
                                                                      top: "-3rem",
                                                                    }}
                                                                  >
                                                                    <div>
                                                                      <b>
                                                                        {Co.Name}
                                                                      </b>

                                                                    </div>
                                                                  </div>
                                                                </NavLink>
                                                              }
                                                            </NavItem>
                                                          )
                                                          )}
                                                    </Nav>

                                                    <TabContent
                                                      activeTab={activeTabSM}
                                                      style={{ padding: "0.25" }}
                                                    >
                                                      <ToolkitProvider
                                                        keyField="key"
                                                        data={_.uniq(SummaryTableData(project.data).map(val => val.Costingtype)).map((val, i) => { return { key: i, Costingtype: val } })}
                                                        columns={tableColumnsVendorSelection}
                                                      >
                                                        {(props) => (
                                                          <CardBody className="bg-white" style={{ width: "50rem" }}>
                                                            <BootstrapTable
                                                              {...props.baseProps}
                                                              bootstrap4
                                                              wrapperClasses="table-responsive"
                                                              bordered={true}

                                                              rowStyle={rowStyleVendorSelection}

                                                              expandRow={expandRow}

                                                            />
                                                          </CardBody>
                                                        )}
                                                      </ToolkitProvider>
                                                    </TabContent>
                                                  </Col>
                                                </TabContent>
                                              </Col>
                                            </Row>
                                          </Card>
                                        </TabPane>
                                      )
                                  )}
                              </TabContent>
                            </div>
                          </Col>
                        </Card>
                      }
                    </Col>
                  </card>
                  }
                  {activeTabSummary == 1 && ComparisonViewData().length > 0 &&
                    <Card>
                      <Col md={12}>
                        {
                          <Card>
                            <Col md={12} className={"countryTabs tab-vertical"}>
                              <div className={"tab"}>
                                <Nav tabs>
                                  {project.CostingProfiles.length > 0 &&
                                    project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs &&
                                    project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs.map(
                                      (fc, indxFC) =>
                                        fc.MethodologySpecs.filter(
                                          (sm) => sm.CostingType == 3
                                        ).length > 0 && (
                                          <NavItem key={fc.value}>
                                            <NavLink
                                              className={classnames({
                                                active: activeTabFC === indxFC,
                                              })}
                                              onClick={() => {
                                                toggle(indxFC);
                                              }}
                                            >
                                              <b>{fc.CountryName}</b>
                                            </NavLink>
                                          </NavItem>
                                        )
                                    )}
                                </Nav>
                                <TabContent activeTab={activeTabFC}>
                                  {project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs &&
                                    project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs.map(
                                      (fc, indxFC) =>
                                        activeTabFC === indxFC && (
                                          <TabPane tabId={indxFC} key={fc.value}>
                                            <Card>
                                              <Row>
                                                <Col
                                                  md={12}
                                                  className={"methodologyTabs"}
                                                >
                                                  <Nav tabs>
                                                    {project.CostingProfiles
                                                      .length > 0 &&
                                                      project.CostingProfiles[project?.CostingProfiles?.length - 1]
                                                        .CountrySpecs &&
                                                      project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[
                                                        activeTabFC
                                                      ].MethodologySpecs.map(
                                                        (sm, indxSM) =>
                                                          !sm.NotApplicable &&
                                                          sm.CostingType == 3 && (
                                                            <NavItem
                                                              key={sm.value}
                                                            >
                                                              <NavLink
                                                                className={classnames(
                                                                  {
                                                                    active:
                                                                      activeTabSM ===
                                                                      indxSM,
                                                                  }
                                                                )}
                                                                onClick={() => {
                                                                  toggleSM(
                                                                    indxSM
                                                                  );
                                                                  project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].copySelectedVendor = _.cloneDeep(project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor);
                                                                }}
                                                              >
                                                                <div
                                                                  style={{
                                                                    display:
                                                                      "flex",
                                                                    justifyContent:
                                                                      "space-between",
                                                                    alignItems:
                                                                      "center",
                                                                    top: "-3rem",
                                                                  }}
                                                                >
                                                                  <div>
                                                                    <b>
                                                                      {sm.Label}
                                                                    </b>
                                                                  </div>
                                                                </div>
                                                              </NavLink>
                                                            </NavItem>
                                                          )
                                                      )}
                                                  </Nav>
                                                  <TabContent
                                                    activeTab={activeTabSM}
                                                    style={{ padding: "0.25" }}
                                                  >
                                                    <Col md={3} style={{ marginLeft: "75%" }}>
                                                      <FormGroup className="ml-5" >
                                                        <div style={{ backgroundColor: "#004e00 " }}>
                                                          <Label style={{ fontWeight: "bolder", color: "white", marginLeft: "5px", marginTop: "5px" }}>Available Currency
                                                          </Label>
                                                          <Select
                                                            name="CurrencySelection"
                                                            classNamePrefix="react-select"
                                                            options={project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs.length > 0 && _.head(project.CostingProfiles[project?.CostingProfiles?.length - 1].ProfileSetting.CurrenciesData) ? currencyDropdown() : []}
                                                            onChange={(e) => { setactiveInternalDropCurrency(e); SelectAvailableCurrency(e); setInternalCostCurrency() }}
                                                            value={project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs.length > 0 && project.CostingProfiles[project?.CostingProfiles?.length - 1].ProfileSetting.CurrenciesData.length > 0 ?
                                                              (currencyDropdown().length > 1 ?
                                                                (currencyDropdown().filter(f => f?.label == project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedCurrency?.label).length > 0 ?
                                                                  currencyDropdown().filter(f => f?.label == project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedCurrency?.label) : currencyDropdown()[0]) : currencyDropdown()[0]) : []}

                                                          />
                                                        </div>
                                                      </FormGroup>
                                                    </Col>
                                                    <Nav tabs>
                                                      {project.CostingProfiles
                                                        .length > 0 &&
                                                        project.CostingProfiles[project?.CostingProfiles?.length - 1]
                                                          .CountrySpecs &&
                                                        project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions.map((Co, indexCo) =>
                                                        (
                                                          <NavItem
                                                            key={Co.Name}
                                                          >
                                                            {Co.Selected && <NavLink
                                                              className={classnames(
                                                                {
                                                                  active:
                                                                    activeTabCo === indexCo,
                                                                }
                                                              )}
                                                              onClick={() => {
                                                                togglecostingOption(indexCo)
                                                                setactiveCoId(Co.id);
                                                              }}
                                                            >
                                                              <div
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  justifyContent:
                                                                    "space-between",
                                                                  alignItems:
                                                                    "center",
                                                                  top: "-3rem",
                                                                }}
                                                              >
                                                                <div>
                                                                  <b>
                                                                    {Co.Name}
                                                                  </b>
                                                                </div>
                                                              </div>
                                                            </NavLink>
                                                            }
                                                          </NavItem>
                                                        )
                                                        )}
                                                    </Nav>
                                                    <TabContent
                                                      activeTab={activeTabSM}
                                                      style={{ padding: "0.25" }}
                                                    >
                                                      <ToolkitProvider
                                                        keyField="key"

                                                        data={InternalCostTableData()}
                                                        columns={tableColumnsInternalCost}
                                                      >
                                                        {(props) => (
                                                          <CardBody className="bg-white">
                                                            <BootstrapTable
                                                              {...props.baseProps}
                                                              bootstrap4
                                                              rowStyle={rowStyle}
                                                              wrapperClasses="table-responsive"
                                                              bordered={true}
                                                              filter={filterFactory()}
                                                              filterPosition="top"
                                                              cellEdit={cellEditFactory({
                                                                mode: "click",
                                                                blurToSave: true,
                                                              })}
                                                            />
                                                            <button className="btn btn-primary" disabled={project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]?.SelectedVendor?.filter(co => co.id == activeCoId).length == 0 || submit} onClick={() => {
                                                              setInternalCost(!InternalCost);
                                                            }}>Add Internal Cost</button>
                                                          </CardBody>
                                                        )}

                                                      </ToolkitProvider>
                                                    </TabContent>
                                                  </TabContent>
                                                </Col>
                                              </Row>
                                            </Card>
                                          </TabPane>
                                        )
                                    )}
                                </TabContent>
                              </div>
                            </Col>
                          </Card>
                        }
                      </Col>
                    </Card>
                  }
                  {activeTabSummary == 2 && ComparisonViewData().length > 0 &&
                    project.CostingProfiles && project.CostingProfiles.length > 0 && project.CostingProfiles[project?.CostingProfiles?.length - 1].IsMultiCountry &&
                    <ComparisonView ComparisonViewData={ComparisonViewData()}></ComparisonView>

                  }
                  {((!project.CostingProfiles[project?.CostingProfiles?.length - 1].IsMultiCountry && activeTabSummary == 2) || (project.CostingProfiles[project?.CostingProfiles?.length - 1].IsMultiCountry && activeTabSummary == 3)) &&
                    <FinalCost FinalCostData={FinalCostData()}></FinalCost>
                  }

                </TabContent>
              </div>
            )}
          </>
        }
      </Container>
      {
        <Modal
          isOpen={countrymodal}
          toggle={() => setcountrymodal(!countrymodal)}
          centered
          size="xm"
          className={"modelSelectVendors"}
        >
          <ModalHeader
            toggle={() => {
              setcountrymodal(!countrymodal);
            }}
          >
            <span>Copying Costing Options To Methodologies & Markets</span>
          </ModalHeader>
          <ModalBody className="m-3" style={styleModelBody}>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Select Costing Options</Label>
                  <Select
                    name={"copyCostingOptions"}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[
                      activeTabFC
                    ].MethodologySpecs[activeTabSM].CostingOptions.map((a) => {
                      return { value: a.Name, label: a.Name, id: a.id };
                    })}
                    onChange={(val) => {
                      setoption(val);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Select Country</Label>
                  <Select
                    name={"copyCostingOptions"}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs.map(
                      (a) => {
                        return { value: a.CountryName, label: a.CountryName };
                      }
                    )}
                    isMulti
                    onChange={(val) => {
                      Country(val);
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="success" onClick={() => copyonmethlevel()}>
              Save
            </Button>
            <Button
              color="danger"
              onClick={() => {
                setcountrymodal(!countrymodal);
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      }
      {
        <Modal
          isOpen={InternalCost}
          toggle={() => setInternalCost(!InternalCost)}
          centered
          size="lg"
          className={"modelSelectVendors"}
        >
          <ModalHeader
            toggle={() => {
              setInternalCost(!InternalCost);
              project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor = _.cloneDeep(project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].copySelectedVendor);
            }}
          >
            <span>Select Internal Cost</span>
          </ModalHeader>
          <ModalBody className="m-3"  >
            <div style={{ maxHeight: "380px", overflow: "auto" }}>
              <Table className="table" style={{ position: "relative" }}>
                <tr className="table bg-primary text-white">
                  <th>Costing Type
                    {project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[
                      activeTabFC
                    ].MethodologySpecs[activeTabSM].CostingOptions.length > 1 &&
                      <FontAwesomeIcon
                        title="Copy data"
                        className="align-middle"
                        icon={faCopy}
                        style={{
                          cursor: "pointer",
                          marginLeft: "10px",
                        }}
                        onClick={() => { saveInternalCost(); CopyInternalCost() }}
                      />}
                  </th>
                  <th>Internal Cost</th>
                </tr>{
                  (project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].Label == "Online Self Completion Survey" ? staticModelData.filter(data => data.OnlineMethodology == true)
                    : staticModelData)
                    .map((d) => {
                      return (
                        <tr>
                          <td >
                            {d.Costingtype.includes("Other Costs - PLEASE SPECIFY") ?
                              <>
                                <label>Other Cost </label>
                                <input type="text" name={d.key} placeholder="Please Specify..."
                                  value={
                                    internalOtherSpecify && internalOtherSpecify.hasOwnProperty(d.key) ?
                                      internalOtherSpecify[d.key] :
                                      (
                                        project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor
                                          &&
                                          project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor.length > 0
                                          &&
                                          project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor.filter(co => co.id == activeCoId).length > 0
                                          &&
                                          project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor.filter(co => co.id == activeCoId)[0].selected &&
                                          project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor.filter(co => co.id == activeCoId)[0].selected.length > 0 &&
                                          project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor.filter(co => co.id == activeCoId)[0].selected.filter(v => v.key == d.key).length > 0
                                          ?
                                          project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor.filter(co => co.id == activeCoId)[0].selected.filter(v => v.key == d.key)[0].pleaseSpecify : "")
                                  }
                                  onChange={(e) => {
                                    updateName(e.target.value, d)

                                  }}
                                />
                              </> :
                              d.Costingtype
                            }  </td>
                          <td >
                            <InputGroup >
                              <input type="number"
                                min={0}
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                pattern="[0-9]"

                                value={
                                  parseInt(project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC]?.MethodologySpecs[activeTabSM]?.SelectedVendor?.filter(co => co.id == activeCoId)[0]?.selected.filter(v => v.key == d.key)[0]?.CostForTable == undefined ? "" :
                                    (activeInternalDropCurrency == undefined ? project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC]?.MethodologySpecs[activeTabSM]?.SelectedVendor?.filter(co => co.id == activeCoId)[0]?.selected.filter(v => v.key == d.key)[0]?.CostForTable :
                                      (project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC]?.MethodologySpecs[activeTabSM]?.SelectedVendor?.filter(co => co.id == activeCoId)[0]?.selected.filter(v => v.key == d.key)[0]?.AdditionalCostUSD * (activeInternalDropCurrency == undefined ?
                                        1 : activeInternalDropCurrency.ConversionRateToLocal)).toFixed(2))) || ""
                                }
                                onChange={(e) => {
                                  (e.target.value < 0) ? toastr.error("Please Enter Valid Number")
                                    : SetinternalCostValue(e, d)
                                }} />
                              <InputGroupAddon addonType="prepend">{activeInternalDropCurrency == null || activeInternalDropCurrency == undefined || !activeInternalDropCurrency ?
                                project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].Currency?.label
                                : activeInternalDropCurrency.label}</InputGroupAddon>
                            </InputGroup>
                          </td>
                        </tr>
                      )
                    })
                }
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="success"
              onClick={() => { saveInternalCost() }}>
              Submit
            </Button>
            <Button
              color="danger"
              onClick={() => {
                setInternalCost(!InternalCost);
                project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor = _.cloneDeep(project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].copySelectedVendor);
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      }
      {
        <Modal
          isOpen={modelState}
          toggle={() => setModelState(!modelState)}
          centered
          size="xl"
          className={"modelSelectVendors"}
        >
          <ModalHeader
            toggle={() => {
              setModelState(!modelState);
            }}
          >
            <span>Fill Other Costs</span>
          </ModalHeader>
          <ModalBody className="m-3" style={styleModelBody}>
            {proposal.id && (
              <div className={"tab"}>
                <Nav tabs>
                  {proposal["ListCountries"].map((country, indxC) => (
                    <NavItem key={country.Code}>
                      <NavLink
                        className={classnames({
                          active: activeTabCountryPopup === indxC,
                        })}
                        onClick={() => {
                          setActiveTabCountryPopup(indxC);
                        }}
                      >
                        <b>{country.Name}</b>
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
                <TabContent
                  activeTab={activeTabCountryPopup}
                  className={"modelCostSummary"}
                >
                  {proposal["ListCountries"].map(
                    (country, indxC) =>
                      activeTabCountryPopup === indxC && (
                        <TabPane tabId={indxC} key={country.Code}>
                          <div className={"tab"}>
                            <Nav tabs>
                              {country["Methodologies"].map(
                                (methodology, indxM) => (
                                  <NavItem key={methodology.Code}>
                                    <NavLink
                                      className={classnames({
                                        active:
                                          activeTabMethodologyPopup === indxM,
                                      })}
                                      onClick={() => {
                                        setActiveTabMethodologyPopup(indxM);
                                      }}
                                    >
                                      <b>{methodology.Name}</b>
                                    </NavLink>
                                  </NavItem>
                                )
                              )}
                            </Nav>
                            <TabContent activeTab={activeTabMethodologyPopup}>
                              {country["Methodologies"].map(
                                (methodology, indxM) =>
                                  activeTabMethodologyPopup === indxM && (
                                    <TabPane
                                      tabId={indxM}
                                      key={methodology.Code}
                                    >
                                      <h4
                                        style={{
                                          position: "absolute",
                                          top: "100px",
                                          right: "80px",
                                          color: "blue",
                                        }}
                                      >
                                        Grand Total {methodology.TotalCost}
                                      </h4>
                                      {GetMethodWiseAdditionalCost(
                                        methodology,
                                        indxM
                                      )}
                                    </TabPane>
                                  )
                              )}
                            </TabContent>
                          </div>
                        </TabPane>
                      )
                  )}
                </TabContent>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              onClick={() => {
                setModelState(!modelState);
                dispatch(
                  proposalActions.saveAdditionalCostBiddingSummary(
                    activeTabCountryPopup,
                    proposal
                  )
                );
              }}
            >
              Save
            </Button>
            <Button
              color="danger"
              onClick={() => {
                setModelState(!modelState);
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      }

      {modelStateSummary && (
        <Modal
          isOpen={modelStateSummary}
          toggle={() => setModelStateSummary(!modelStateSummary)}
          centered
          size="xl"
          className={"modelSelectVendors"}
        >
          <ModalHeader
            toggle={() => {
              setModelStateSummary(!modelStateSummary);
            }}
          >
            <span>Selected Vendors Cost Summary</span>
            <div
              style={{
                position: "absolute",
                top: "5px",
                right: "50px",
                color: "blue",
              }}
            >
              <MyExportCSV {...props.csvProps} />
            </div>
          </ModalHeader>
          <ModalBody className="m-3" style={styleModelBody}>
            <div className={"tab tabContentCostSummary selectedVendors"}>
              {proposal["Data"] && (
                <Card>
                  <ToolkitProvider
                    keyField="RecordIndex"
                    data={proposal["Data"].filter((x) => x.Selected)}
                    columns={tableColumnsSummary}
                    exportCSV={{
                      onlyExportFiltered: true,
                      onlyExportSelection: false,
                      exportAll: true,
                    }}
                  >
                    {(props) => (
                      <>
                        <CardBody>
                          <BootstrapTable
                            {...props.baseProps}
                            bootstrap4
                            wrapperClasses="table-responsive"
                            bordered={true}
                            filter={filterFactory()}
                            filterPosition="top"
                          />
                        </CardBody>
                      </>
                    )}
                  </ToolkitProvider>
                </Card>
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="success">
              Submit
            </Button>
            <Button
              color="danger"
              onClick={() => {
                setModelStateSummary(!modelStateSummary);
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {props.oncostview && project.data && (
        <Modal
          isOpen={props.oncostview}
          centered
          size="xl"
          className={"modelSelectVendors"}
        >
          <ModalHeader>Final summary of selected vendor cost</ModalHeader>
          <ModalBody>
            {project.data && (
              <div className={"tab tabContentCostSummary"}>
                <Col md={12}>
                  {
                    <Card>
                      <ToolkitProvider
                        keyField="VendorID"
                        data={props.saveselectedrows}
                        columns={tableColumns}
                      >
                        {(props) => (
                          <CardBody>
                            <BootstrapTable
                              {...props.baseProps}
                              bootstrap4
                              wrapperClasses="table-responsive"
                              bordered={true}
                              filter={filterFactory()}
                              filterPosition="top"
                              cellEdit={cellEditFactory({
                                mode: "click",
                                blurToSave: true,
                              })}
                            />
                          </CardBody>
                        )}
                      </ToolkitProvider>
                    </Card>
                  }
                </Col>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => Savevendor()}>
              Submit
            </Button>
            <Button
              color="secondary"
              onClick={() => props.setoncostview(!props.oncostview)}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )}

      {activeTabSummary == (project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs?.length > 1 ? 3 : 2) &&
        <Button color="primary" className="Button-Primary" onClick={() => {
          SaveSelectedVendor();
          createSheet();
        }} disabled={app.recordloading || submit}>
          Submit Proposal
        </Button>

      }
      {activeTabSummary == 2 && (project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs?.length > 1) &&
        <Button color="primary" className="Button-Primary" onClick={(e) => {
          setShowTabs(true);
          setactiveTabSummary(activeTabSummary + 1);
        }} >
          Next
        </Button>

      }
      {activeTabSummary == (project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs?.length > 1 ? 3 : 2) && currentprofile.CostingsSheetId &&
        <Button color="primary" className="Button-Primary" onClick={() => openSheet()} disabled={app.recordloading}>
          Open Cost Sheet
          {app.recordloading && <Spinner size="small" color="#495057" />}
        </Button>}
      {(activeTabSummary == 0 || activeTabSummary == 1) &&
        <Button color="primary" className="btn btn-primary" disabled={submit} onClick={() => {
          saveandnext(activeTabSummary)
        }}>
          Save And Next
        </Button>
      }
      {
        (activeTabSummary == 0 || activeTabSummary == 1) &&
        <Button color="primary" className="btn btn-primary" style={{ marginLeft: "20px" }} disabled={submit} onClick={() => {
          saveasdraft()
        }}>
          Save As Draft
        </Button>
      }   </>
  );
}
export default connect(mapStateToProps, null)(ProposalBiddingSelectVendors);