import React from "react";

function Trash() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          fill="#000"
          d="M19.5 3H18V1.5A1.5 1.5 0 0016.5 0h-9A1.5 1.5 0 006 1.5V3H4.5A1.5 1.5 0 003 4.5v3h1.5v15A1.5 1.5 0 006 24h12a1.5 1.5 0 001.5-1.5v-15H21v-3A1.5 1.5 0 0019.5 3zm-12-1.5h9V3h-9V1.5zM9 21H6V7.5h3V21zm4.5 0h-3V7.5h3V21zm4.5 0h-3V7.5h3V21z"
        ></path>
      </g>
    </svg>
  );
}

export default Trash;