import { AZURE_ID, AZURE_TENANT_ID, REDIRECT_URI } from './azureKeys';
import queryString from 'query-string';
import { CODE_VERIFIER } from './PCKEConfigs';
import URL from 'url';

const AzureToken = async (code) => {
    let params = {
        client_id: AZURE_ID,
        // client_secret: config.secret,
        code: code,
        grant_type: ['authorization_code'],
        redirect_uri: REDIRECT_URI,
        code_verifier: CODE_VERIFIER,
        state: JSON.stringify({ provider: 'Microsoft' }),
    }

    const post_data = queryString.stringify(params);
    let parsedUrl = URL.parse(`https://login.microsoftonline.com/${AZURE_TENANT_ID}/oauth2/v2.0/token`, true);

    let realHeaders = {};
    realHeaders['Host'] = parsedUrl.host;
    realHeaders["Content-Length"] = post_data.length;
    realHeaders["Content-Type"] = 'application/x-www-form-urlencoded';


    const options = {
        host: parsedUrl.hostname,
        port: parsedUrl.port,
        path: parsedUrl.pathname,
        method: "POST",
        headers: realHeaders
    };

    const payload = Object.assign({
        body: post_data
    }, options);

    let response = await fetch(`https://login.microsoftonline.com/${AZURE_TENANT_ID}/oauth2/v2.0/token`, payload)

    let token_object = await response.json();

    return token_object.access_token;

}

export const AzureRefreshToken = async () => {
    let params = {
        client_id: AZURE_ID,
        grant_type: ['refresh_token'],
        refresh_token: localStorage.getItem('refresh_token')
    }

    const post_data = queryString.stringify(params);
    let parsedUrl = URL.parse(`https://login.microsoftonline.com/${AZURE_TENANT_ID}/oauth2/v2.0/token`, true);

    let realHeaders = {};
    realHeaders['Host'] = parsedUrl.host;
    realHeaders["Content-Length"] = post_data.length;
    realHeaders["Content-Type"] = 'application/x-www-form-urlencoded';

    const options = {
        host: parsedUrl.hostname,
        port: parsedUrl.port,
        path: parsedUrl.pathname,
        method: "POST",
        headers: realHeaders
    };

    const payload = Object.assign({
        body: post_data
    }, options);

    let response = await fetch(`https://login.microsoftonline.com/${AZURE_TENANT_ID}/oauth2/v2.0/token`, payload)

    let token_object = await response.json();

    localStorage.setItem('refresh_token', token_object.refresh_token);

    return token_object;
}

export const azureData = () => {
    
    return sessionStorage.getItem('xyzData')
        ?
        JSON.parse(sessionStorage.getItem('xyzData'))
        :
        null
}

export const storageValue = (key, data) => {

    const xyzData = azureData()

    // key==="userObj" means data is object

    sessionStorage.setItem("xyzData", xyzData
        ?
        key === "userObj" ?
            JSON.stringify({ ...xyzData, ...data })
            :
            JSON.stringify({ ...xyzData, [key]: data })
        :
        key === "userObj" ?
            JSON.stringify(data)
            :
            JSON.stringify({ [key]: data })
    );

}


export default AzureToken;
