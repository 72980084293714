import axios from "../axios-interceptor";

export const  fetchUsers = async() =>{
    let delegateContact = await axios.get("/users/costingapprovers/all")
    return delegateContact;
}

export const  fetchDelegateUsers = async() =>{
    let delegatedUser = await axios.get("/delegate/fetch")
    return delegatedUser;
}
