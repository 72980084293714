import React, { useState, useEffect } from 'react'
import {
	Col, Row, Nav, NavItem, NavLink, TabContent, TabPane,
	Table,
} from "reactstrap";

import classnames from "classnames";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { mainStateReducer } from 'react-doc-viewer/build/state/reducer';



const ViewCostBreakDown = () => {
	const project = useSelector(
		({ currentProject }) => currentProject.newProject
	  );
	const currentCostingProfile = useSelector(
		({ currentCosting }) => currentCosting.currentCostingProfile
	);
	const [wavesData, setWavesData] = useState(currentCostingProfile.WaveSpecs)
	const [activeTabFC, setActiveTabFC] = useState();
	const [activeTabSM, setActiveTabSM] = useState();
	const [waveBreakDown, setWaveBreakDown] = useState({})
	const [currentWaveSpec, setCurrentWaveSpec] = useState({});
	const [showManualCostEntry, setShowManualCostEntry] = useState(false);
	const [showSheetsCosts, setShowSheetsCosts] = useState(false);
	const [showCostMethod, setShowCostMethod] = useState(false);
	const dispatch = useDispatch();
	const [modifiedMeths, setModifiedMeths] = useState([])
	const [activeWave, setActiveWave] = useState("1");
	const [toggle, setToggle] = useState({
		costingOption: false
	});
	const codeLabels = useSelector(({ codeLabels }) => codeLabels);
	const costIntCommLabels = {
		CostIntCommExecDirector: "Executive Director",
		CostIntCommDirector: "Director",
		CostIntCommAssociateDirector: "Associate Director",
		CostIntCommSeniorManager: "Senior Manager",
		CostIntCommManager: "Manager",
		CostIntCommSeniorExecutive: "Senior Executive",
		CostIntCommExecutive: "Executive",
		CostIntCommDataScience: "Data Science",
	};
	const [currentCurrency, setCurrentCurrency] = useState({});
	const currencies = useSelector(({ currentCosting }) =>
		currentCosting.currentCostingProfile.ProfileSetting &&
			currentCosting.currentCostingProfile.ProfileSetting.CurrenciesData
			? currentCosting.currentCostingProfile.ProfileSetting.CurrenciesData
			: []
	);
	useEffect(() => {
		if (currentCostingProfile && currentCostingProfile.CostInputCurrency) {
			let values = currentCostingProfile.CostInputCurrency.split("-");
			let currencyUnit = _.last(values);
			let countryCode = _.head(values);
			if (currencies) {
				setCurrentCurrency(
					_.head(
						currencies.filter(
							(cur) =>
								cur.Code == countryCode && cur.CurrencyUnit == currencyUnit
						)
					)
				);
			}
		}
	}, [currentCostingProfile, currencies]);
	const getCurrentCurrency = (actualvalue, currencyvalue, onlyCurrency) => {
		if (
			currencies &&
			currencies.length &&
			currentCurrency &&
			currentCurrency.ConversionRateToLocal
		) {
			if (actualvalue) {
				let finalVal = _.round(
					actualvalue * currentCurrency.ConversionRateToLocal,
					2
				);
				return `${finalVal} ${currentCurrency.CurrencyUnit}`;
			} else return `0 ${currentCurrency.CurrencyUnit}`;
		}
	};
	const shopperCounter = {
		CostTotalExternalOperations: "shopperCostTotalExternalOperations"
		, CostTotalInternalOperations: "shopperCostTotalInternalOperations"
		, CostTotalInternalCommercial: "shopperCostTotalInternalCommercial"
	}

	const app = useSelector(({ app }) => app);
	useEffect(() => {
		if (currentCostingProfile &&
			wavesData
			&& wavesData.length
			&& !Object.keys(waveBreakDown).length
		) {

			let countryData = _.head(currentCostingProfile.CountrySpecs);

			let mData = countryData.MethodologySpecs?.filter(
				ms => !ms.NotApplicable &&
				(ms.CostingOptions.some(co => co.CoSelectedForSummary==true ||( co.CoSelectedForSummary==null && co.Selected))));
			let methodologyID = _.head(mData)?.id

			setActiveTabFC(countryData.id);
			setActiveTabSM(methodologyID);
			let _waveBreakdownMain = wavesData.filter(x => x.MethodologySpecId === methodologyID)
			let _waveBreakdown = _.groupBy(_waveBreakdownMain, m => m.WaveNumber)
			setModifiedMeths((_waveBreakdownMain))

			Object.keys(_waveBreakdown).map(w => {
				_waveBreakdown[w] = _.uniqBy(_waveBreakdown[w], t => t.MethodologyLabel)
			});
			setWavesData(wavesData);
			setWaveBreakDown(_waveBreakdown)
			let _currentwaveset = _.head(_waveBreakdown[activeWave])
			setCurrentWaveSpec(_currentwaveset);
		}
	}, [currentCostingProfile])
	const alternativeLabels = {
		CostTotalInternalCommercial: {
			UsesOopOverrideIntCommCost: "CostIntCommMultiplier",
			// Value: "CostTotalExternalOperations"
		},
		CostTotalInternalOperations: {
			UsesOopOverrideIntOpsCost: "CostIntOpsMultiplier",
			// Value: "CostTotalExternalOperations"
		},
	};
	const additionalLabels = {
		CostTotalExternalOperations: {
			CostExtOpsMCPSubContract: "MCP/Group Company Sub-Contracting",
			// CostExtOpsOtherTaxVAT: "VAT and Other Tax Adjustment",
		},
	};
	const [includeOnlyInMCP, setIncludeOnlyInMCP] = useState([
		"MCPLocalCountryExtra",
		"CostTotalMCPMargin",
	]);
	// const currencies = useSelector(({ currentCosting }) =>
	// 	currentCosting.currentCostingProfile.ProfileSetting &&
	// 		currentCosting.currentCostingProfile.ProfileSetting.CurrenciesData
	// 		? currentCosting.currentCostingProfile.ProfileSetting.CurrenciesData
	// 		: []
	// );

	const [costFields, setCostFields] = useState({});
	useEffect(() => {
		let finalCostFields = {};
		let countrySpecs = [...currentCostingProfile.CountrySpecs];
		countrySpecs.map((cs)=>{
			cs?.MethodologySpecs.map(ms => {
				finalCostFields[ms.id] = {};
				Object.keys(ms.CalculationSchema).map((csch) => {
					let condition = currentCostingProfile?.Project?.PriceChange == "1" ?!finalCostFields[ms.id][csch] &&  !(csch == "TotalSUDTimeCosts" || csch == "TotalFieldTimeCosts" || csch == "TotalOpsTimeCosts"):
					!finalCostFields[ms.id][csch] 
					if (condition ) {
						if (_.includes(includeOnlyInMCP, csch)) {
							if (currentCostingProfile.IsMultiCountry) finalCostFields[csch] = [];
						} else {
							finalCostFields[ms.id][csch] = [];
						}
					}
					Object.keys(ms.CalculationSchema[csch]).map((insch) => {
						if (
							finalCostFields[ms.id][csch] &&
							!_.includes(finalCostFields[ms.id][csch], insch)
						) {
							finalCostFields[ms.id][csch].push(insch);
						}
					});
					if (additionalLabels && additionalLabels[csch]) {
						Object.keys(additionalLabels[csch]).map((lab) => {
							if (!_.includes(finalCostFields[ms.id][csch], lab)) {
								finalCostFields[ms.id][csch].push(lab);
							}
						});
					}
				});
			});
		});
		setCostFields(finalCostFields);
	},[]);

	const toggleFC = (tab) => {
		if (activeTabFC !== tab) {
			setActiveTabFC(tab);
			let cData = currentCostingProfile.CountrySpecs.filter(x => x.id === tab);
			let methData = _.head(cData);

			let mData = methData.MethodologySpecs?.filter(
				ms => !ms.NotApplicable &&
				(ms.CostingOptions.some(co => co.CoSelectedForSummary==true ||( co.CoSelectedForSummary==null && co.Selected))
				 ));
			let methodologyID = _.head(mData)?.id
			setActiveTabSM(methodologyID)
			onMethodologyChange(methodologyID)
		}
	};

	const toggleSM = (tab) => {
		if (activeTabSM !== tab) {
			setActiveTabSM(tab);
			onMethodologyChange(tab)
		}
	};

	const getLabelForCode = (key) => {
		let costLabel = key;
		codeLabels['CostLabelsOptions'].forEach(codeLabel => {
			if (codeLabel.Code == key) {
				costLabel = codeLabel.Label;
			}

		});
		return costLabel;
	}

	const onMethodologyChange = (methID) => {
		let _waveBreakdownMain = wavesData?.filter(x => x.MethodologySpecId === methID)
		let _waveBreakdown = _.groupBy(_waveBreakdownMain, m => m.WaveNumber)
		setWaveBreakDown(_waveBreakdown);
		setModifiedMeths((_waveBreakdownMain))
		let data = _.head(wavesData.filter(cws => cws.MethodologySpecId == methID))
		setCurrentWaveSpec(data);
	}

	const getTotalByCostingOption = (costingOptions, ccf) => {
		let totalValue = 0;
		costingOptions?.filter(selectedCO => selectedCO.CoSelectedForSummary && selectedCO.Selected)?.map((co) => (
			totalValue = totalValue + co[ccf]
		))
		return totalValue;
	}

	return (
		<Row>
			<Col md={12} className={'countryTabs tab-vertical'}>

				<div className={"tab mt-5"}>
					<Nav tabs>
						{currentCostingProfile.CountrySpecs &&
							currentCostingProfile.CountrySpecs.map((fc) => (
								<NavItem key={fc.value}>
									<NavLink className={classnames({ active: activeTabFC === fc.id })}
										onClick={() => {
											toggleFC(fc.id);
										}}
									>
										<b>{fc.CountryName}</b>
									</NavLink>
								</NavItem>
							))}
					</Nav>
					<TabContent activeTab={activeTabFC}>
						{currentCostingProfile.CountrySpecs &&
							currentCostingProfile.CountrySpecs.map((fc, indxFC) => (
								activeTabFC === fc.id &&
								<TabPane tabId={fc.id} key={fc.value}>
									<Row>
										<Col md={12} className={"methodologyTabs"}>
											<Nav tabs>
												{fc.MethodologySpecs &&
													fc.MethodologySpecs.filter(x => !x.NotApplicable &&(x.CostingOptions.some(co => (co.CoSelectedForSummary==true||co.CoSelectedForSummary == undefined ||( co.CoSelectedForSummary==null && co.Selected))||co.Selected)
													)).map((sm, indxSM) =>
													(
														<NavItem key={sm.value}>
															<NavLink className={classnames({ active: activeTabSM === sm.id })}
																onClick={() => {
																	toggleSM(sm.id);
																}}
															>
																<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
																	<div>
																		<b>{sm.Label}</b>
																	</div>

																</div>
															</NavLink>
														</NavItem>
													))}
											</Nav>

											<TabContent activeTab={activeTabSM} style={{ padding: "0.25" }}>
												<Row>
													<Col md={12} className={"methodologyTabs"}>
														<Table responsive hover striped bordered={true} size="sm">
															<thead>
																<tr>
																	{fc.MethodologySpecs &&
																		fc.MethodologySpecs.filter(x => x.id==activeTabSM && !x.NotApplicable &&(x.CostingOptions.some(co => 
																			co.CoSelectedForSummary==true||co.CoSelectedForSummary == undefined ||( co.CoSelectedForSummary==null && co.Selected)
																		||co.Selected)
																		))?.map((sm, indxSM) =>
																		(
																			<>
																				<Table responsive hover striped bordered={true} size="sm">
																					<thead>
																						<th>Component</th>
																						{sm?.CostingOptions.filter(c=>(c.CoSelectedForSummary==true && c.Selected))?.length > 1
																							?
																							<th>Total</th>
																							: null}
																						{sm?.CostingOptions.filter(c=>(c.CoSelectedForSummary==true && c.Selected))?.map((co) => (
																							<th>
																								{co.Name}
																							</th>
																						))}
																					</thead>
																					{costFields[sm.id] && Object.keys(costFields[sm.id])?.map((cf) => {
																						return (
																							<tbody>
																								{alternativeLabels[cf] &&
																									Object.keys(alternativeLabels[cf]).filter(
																										(al) => currentCostingProfile.ProfileSetting[al]
																									).length ? (
																									<tr>
																										<td>
																											Breakdown Not Available - Using OOP % Multiplier
																										</td>
																										<td>
																											{_.round(currentCostingProfile.ProfileSetting[
																												_.head(Object.values(alternativeLabels[cf]))
																											] * 100, 2)}{" "}
																											%
																										</td>
																									</tr>
																								) : costFields[sm.id][cf].length ? (
																									costFields[sm.id][cf]?.map((ccf) => (
																										<tr>
																											<td>{getLabelForCode(ccf)}</td>
																											{sm?.CostingOptions.filter(c=>(c.CoSelectedForSummary==true && c.Selected))?.length > 1
																												?
																												<td>
																													{currentCostingProfile.IsMultiCountry ?
																														getCurrentCurrency(
																															getTotalByCostingOption(sm.CostingOptions, ccf),
																															fc.CostInputCurrency)
																														: getCurrentCurrency(
																															getTotalByCostingOption(sm.CostingOptions, ccf),
																															currentCostingProfile.CostInputCurrency)}
																												</td>
																												: null}
																											{sm.CostingOptions.filter(c=>(c.CoSelectedForSummary==true && c.Selected))?.map((co) => (
																												<td>
																													{currentCostingProfile.IsMultiCountry ? getCurrentCurrency(
																														co[ccf],
																														fc.CostInputCurrency
																													) : getCurrentCurrency(
																														co[ccf],
																														currentCostingProfile.CostInputCurrency
																													)}
																												</td>))
																											}
																										</tr>
																									))
																								) : (
																									<>
																										{Object.keys(costIntCommLabels).map((cicl) => (
																											<tr>
																												<td>{costIntCommLabels[cicl]}</td>
																											</tr>
																										))}
																									</>
																								)}

																								{alternativeLabels[cf] &&
																									Object.keys(alternativeLabels[cf]).filter(
																										(al) => currentCostingProfile.ProfileSetting[al]
																									).length ? (
																									<tr className="text-uppercase">
																										<th>{getLabelForCode(cf)}</th>
																										<th>
																											{getCurrentCurrency(
																												(currentCostingProfile.CostTotalExternalOperations *
																													currentCostingProfile.ProfileSetting[
																													_.head(
																														Object.values(alternativeLabels[cf])
																													)
																													]) + (shopperCounter[cf] ? currentCostingProfile[shopperCounter[cf]] : 0)
																											)}
																										</th>
																									</tr>
																								) : (
																									<tr className="text-uppercase">
																										<th>{getLabelForCode(cf)}</th>
																										{sm?.CostingOptions.filter(c=>(c.CoSelectedForSummary==true && c.Selected))?.length > 1
																											?
																											<td>
																												{currentCostingProfile.IsMultiCountry ? getCurrentCurrency(
																													getTotalByCostingOption(sm.CostingOptions, cf),
																													fc.CostInputCurrency
																												) : getCurrentCurrency(
																													getTotalByCostingOption(sm.CostingOptions, cf),
																													currentCostingProfile.CostInputCurrency)}																							</td>
																											: null}
																										{sm.CostingOptions.filter(c=>(c.CoSelectedForSummary==true && c.Selected))?.map((co) => (
																											<th>
																												{currentCostingProfile.IsMultiCountry ? getCurrentCurrency(
																													co[cf],
																													fc.CostInputCurrency
																												) : getCurrentCurrency(
																													co[cf],
																													currentCostingProfile.CostInputCurrency)
																												}

																											</th>
																										))}
																									</tr>
																								)}
																							</tbody>
																						);
																					})}
																				</Table>
																			</>))}
																</tr>
															</thead>

														</Table>


													</Col>
												</Row>
											</TabContent>
										</Col>
									</Row>
								</TabPane>
							))}
					</TabContent>
				</div>
			</Col>
		</Row >
	)
}

export default ViewCostBreakDown
