import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";
import axios from "../axios-interceptor";
import avatar1 from "../assets/img/avatars/nielsen-logo.png";
import "../components/ApprovalDelegate.css";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { fetchDelegateUsers, fetchUsers } from "./ApprovalUtils";
import { toastr } from "react-redux-toastr";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ApprovalDelegate = (props) => {
  const userRecord = JSON.parse(localStorage.getItem("userRecord"));
  const [updateOptions, setUpdateOptions] = useState([])
  const [isRegionSelect, setRegion] = useState([])
  const [newApprover, setNewApprover] = useState([])
  const [inputDate, setInputDate] = useState({
    StartAt: '',
    EndAt: ''
  });
  let [delegateContact, setDelegateContact] = useState([])
  const [delegatedUsers, setDelegatedUsers] = useState([])
  const [isDisabledButton, setDisabledButton] = useState(false)
  const [isDisabledField, setDisabledField] = useState(false)
  const [isStartDateFocus, setStartDateFocus] = useState(false)
  const [isEndDateFocus, setEndDateFocus] = useState(false)
  const [disableMarkets, setDisableMarkets] = useState({})
  let countries =
    useSelector(({ codeLabels }) => codeLabels.UserScopeOptions) || [];
  const Reg1 = {};
  const Reg2 = {}
  countries.map(country => {
    Reg1[country.Region] = Reg1[country.Region] || []
    Reg1[country.Region].push(country.Code);
  });
  let arr = [];
  isRegionSelect?.map(key => {
    let a = Reg1[key.value]
    if (a) {
      arr.push(a)
    }
  })
  arr = arr.flat();

  let regionLabel = countries.map((val) => val.Region)
  regionLabel = [...new Set(regionLabel)]

  let dropOpts = [
    {
      Label: "Select All",
    },
    {
      Label: "De-Select All",
    },
    {
      Label: "Toggle Selection",
    },
  ];

  var [allInputFields, setAllInputFields] = useState([]);

  useEffect(() => {
    countries.map((country) =>
      allInputFields.push({
        ApproverUserId: userRecord.id,
        ApproverUserEmail: userRecord.Email,
        DelegateUsers: "",
        StartAt: inputDate.StartAt,
        EndAt: inputDate.EndAt,
        Market: country.Code,
        Region: country.Region,
        Name: country.Code,
        Market_Label: country.Label,
        isInputChecked: false,
        isInputDisabled: false,
        dropOptions: delegateContact,
      })

    );
  }, []);
  useEffect(() => { }, [updateOptions])
  let users = []
  const handleSave = () => {
    users = dataInput?.filter(item => item.DelegateUsers?.length > 0 && !specificUserDisabled(item) && !disableMarkets[item.Market]);
    if (users?.length > 0 && inputDate.StartAt && inputDate.EndAt) {
      if( inputDate.StartAt > inputDate.EndAt){
        toastr.error("Cannot Save Dates", "End Date Shouldn't be before Start Date");
      }else{
        axios.post("/delegate/create", { users: users })
        .then((res) => (
          users?.map(user=> {
            Reg2[user.Market] = true
          }),
          setDisableMarkets({...disableMarkets,...Reg2}),
          toastr.success(res.data.message)))
        .catch(res => toastr.error(res.data.message))
      }

    }

    else if (!inputDate.StartAt || !inputDate.EndAt || !isRegionSelect || (Array.isArray(isRegionSelect) && !isRegionSelect.length)) {
      
      if (!inputDate.StartAt) {
        setStartDateFocus(true);
        toastr.error("Please Fill Required Details");
      } else if(!inputDate.EndAt){
        setEndDateFocus(true);
        toastr.error("Please Fill Required Details");
      }
      else{
        toastr.error("Please Select Atleast One Region");
      }
    } else {
      toastr.error("Please Select Atleast One Approver");
    }
  }

  let email = []
  const applyUpdateOptions = (e) => {
    let userSelection = updateOptions;
    if (userSelection === "Select All" || userSelection === "Toggle Selection") {
      let delegatedUsersData = []
      newApprover?.approverContact?.map(ele => {
        delegatedUsersData.push({
          email: ele.value,
          id: ele.id,
          label: ele.value
        })
      })
      checkApplyUpdates(delegatedUsersData)

    }
    else if (userSelection === "De-Select All" && dataInput?.DelegateUsers?.length < 0) {
      const tempArr = dataInput.DelegateUsers = ""
      setDataInput(tempArr);
    }
  };

  function checkApplyUpdates(delegatedUsersData) {
    const tempArr = dataInput?.map((item, i) => {
      if ((item?.isInputChecked || item?.DelegateUsers?.length > 0) && !specificUserDisabled(item) && !disableMarkets[item.Market]) {

        item.DelegateUsers = delegatedUsersData;
        
        if(fetchedUser.length > 0 ){
          item.StartAt = getCurrentDate(fetchedUser[0]?.StartAt);
          item.EndAt =  getCurrentDate(fetchedUser[0]?.EndAt)
        }
      }
      return item;
    });
    setDataInput(tempArr);
  }

  const updateProject = (index) => (e) => {

    const tempArr = dataInput?.map((item, i) => {
      if (index === i) {
        let delegatedUsersData = []
        e && e?.map(ee => {
          delegatedUsersData.push({
            email: ee.value,
            id: ee.id,
            label: ee.value,
            value: ee.value,
          })
        })

        let delegateUserData = []
        let del = delegatedUsersData.map(delegate =>
          delegateContact.filter(user => user.id === delegate.id ?

            delegateUserData.push({
              email: user.Email,
              id: user.id,
              label: user.Email,
              value: user.Email,
            })
            : null))
        item.DelegateUsers = delegateUserData;
        if(fetchedUser.length > 0 ){
          item.StartAt = getCurrentDate(fetchedUser[0]?.StartAt);
          item.EndAt =  getCurrentDate(fetchedUser[0]?.EndAt)
        } 
      }
      return item;
    });
    setDataInput(tempArr);
  };
  const getCurrentDate = (val) => {
    if (val) {
      let currentDate = new Date(val)
      let fullDate = currentDate.getFullYear() + '-' +
        ('0' + (currentDate.getMonth() + 1)).slice(-2) + '-'
        + ('0' + (currentDate.getUTCDate())).slice(-2);
      return fullDate
    }
  }

  const fetchdelegateUser = (res) =>{
    if(res.length > 0){
      let obj = {}
      let resu = res && res.map(e => allInputFields.filter(item => {
        if(item.Market === e.Market){
          return obj[item.Market] = item
        }
      }));
      resu = Object.values(obj);
      resu = resu?.flat()
      setDataInput(resu);
      resu = resu.map((item)=> item.Region)
      resu = resu.filter((item, index) => resu.indexOf(item) === index)
      setRegion(resu.map((item) => {
        return { value: item, label: item };
      })) 
      return resu
    }
    else{
      setDisabledField(false)
      let resu = regionLabel && regionLabel.map(e => allInputFields.filter(item => item.Region === e));
      resu = resu?.flat()
      setDataInput(resu)
      setRegion(regionLabel.map((item) => {
        return { value: item, label: item };
      }));
      return resu
    }

  }


  useEffect(() => {
    fetchUsers().then(res => setDelegateContact(res.data.users));
    fetchDelegateUsers().then(res => {
      setDelegatedUsers(res.data.delegatedUsers);
      let delUsers = res.data.delegatedUsers;
      countries.map(country => {
        Reg2[country.Market] = false;
      });
      if(delUsers.length){
        for(const user of delUsers){
          Reg2[user.Market] = true;
        }
        setDisableMarkets({...disableMarkets,...Reg2});
      }
      (delUsers && delUsers.length > 0) ? fetchdelegateUser(delUsers) : fetchdelegateUser(delUsers);
 
    })
  }, []);

  const [approvalRender, setApprovalRender] = useState(true)
  function applyUserOptions(value) {
    dataInput = dataInput?.map(item => { item.isInputChecked = value; return item }
    );
    setApprovalRender(!approvalRender);
  }

  useEffect(() => { }, [approvalRender])
  const setUpdateHandler = (e) => {
    setUpdateOptions(e.value);
    let userOption = e.value;
    if (userOption === "Select All") {
      applyUserOptions(true)
    } else if (userOption === "De-Select All") {
      applyUserOptions(false)
    } else if (userOption === "Toggle Selection") {
      dataInput?.map(item => {
        item.isInputChecked = !item.isInputChecked;
      });
    }
  };

  const handleChecked = (index) => (e) => {
    const tempArr = dataInput?.map((item, i) => {
      if (index === i) {
        let isChecked = item?.isInputChecked;
        return { ...item, [e.target.name]: !isChecked }
      } else {
        return item;
      }
    });
    setDataInput(tempArr);
  };

  const deleteOptions = () => {
    const tempArr = dataInput?.map((item, i) => {
      if (item?.isInputChecked && item?.DelegateUsers?.length > 0 && !disableMarkets[item.Market]) {

        item.DelegateUsers = '';
      }
      return item;
    });
    setDataInput(tempArr);
  };

  const handleDate = (e) => {

    const name = e.target.name;
    const value = e.target.value;
    setInputDate(values => ({ ...values, [name]: value }))

    const tempArr = dataInput?.map((item, i) => {
      return { ...item, [name]: value }
    });
    const tempArr1 = allInputFields?.map((item, i) => {
      return { ...item, [name]: value }
    });
    setDataInput(tempArr);
    setAllInputFields(tempArr1);
    if (name === 'StartAt' && value) {
      setStartDateFocus(false)
    }
    else if (name === 'EndAt' && value) {
      setEndDateFocus(false)
    }

  }

  let opt = allInputFields && allInputFields.map(item =>
    delegatedUsers?.filter(userItem => userItem.Market === item.Market))

  let fetchedUser = opt && opt?.filter(e => e.length > 0 ? e : null);
  fetchedUser = fetchedUser ? fetchedUser?.flat(): [];
  function specificUserEmail(item) {
    let temp = []
    fetchedUser.map(userItem =>{
      if(userItem.Market === item.Market){
        temp.push({ label: userItem.DelegateUserEmail, value: userItem.DelegateUserEmail });
      }
    })
      const user = item?.DelegateUsers && item?.DelegateUsers.map(e => e);
      if(user.length)temp.push(...user)
   
    return temp;
  }
  function specificUserDisabled(item) {
    let disabled = false
    for(const user of fetchedUser){
      if(user?.Market === item?.Market){
        disabled = true;
        inputDate.StartAt = user.StartAt
        inputDate.EndAt = user.EndAt
        break
      }
    }
    return disabled
  }

  let [dataInput, setDataInput] = useState([])

  const selectRegionHandler = (e) => {
    let res = e && e.map(e =>
      allInputFields && allInputFields.filter(item => item.Region === e.value));
    res = res?.flat()
    setDataInput(res)
    setRegion(e);
  }

  const getAllUniqueUsers = () => {

    let result = []
    delegateContact.map((list) => {
      let res = list?.Countries?.split(',');
      if (arr.every(v => res.includes(v))) {
        result.push({
          value: list.Email,
          label: list.Email,
          id: list.id
        });
      }
    })
    return result;
  }
  const getUniqueUsers = (Market) => {

    let result = []
    delegateContact.map((list) => {
      let res = list?.Countries?.split(',');
      if (res.includes(Market)) {
        result.push({
          value: list.Email,
          label: list.Email,
          id: list.id
        });
      }

    })
    return result;
  }
  return (
    <div>
      <Modal
        className="modal-with-header"
        isOpen={props.isApprovalModal}
        toggle={() => props.setApprovalModal(!props.isApprovalModal)}
        size="lg"
        centered={true}
      >
        <ModalHeader toggle={() => props.setApprovalModal(!props.isApprovalModal)}>
        </ModalHeader>
        <ModalBody id="scrollModalBody" className="modal-body-error-header">
          <Form>
            <div
              className="modaldiv"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>
                <span
                  className="modaltitle p-0 col-8 m-0"
                  style={{ marginTop: "25px" }}
                >
                  User Profile
                </span>
              </div>
              <div className="modalRight">
                {/* <img
                  src={avatar1}
                  className="rounded-circle mr-1 imageIcon"
                  alt="User Profile Picture"
                /> */}
                <div>
                  <h4 className="">
                    {`${userRecord.FirstName} ${userRecord.LastName}`}
                  </h4>
                  <span>{userRecord.Email}</span>
                </div>
              </div>
            </div>

            <div className="roleMain">
              <h4>Your Roles</h4>
              <ul>
                {userRecord.IsMarketAdmin && <li>Market Administrator</li>}
                {userRecord.IsInternalUser === "1" && <li>NielsenIQ Internal User</li>}
                {userRecord.IsClientService && <li>Client Service</li>}
                {userRecord.IsOpsProjectManager && <li>Operations Project Manager</li>}
                {userRecord.IsCostingApprover && <li>Pricing (Costing) Approver</li>}
                {userRecord.IsCostingSPOC && <li>Costing SPOC</li>}
                {userRecord.IsProgrammer && <li>Survey Programmer</li>}
                {userRecord.IsProgrammingTeamLeader && <li>SP Team Lead</li>}
                {userRecord.IsTCSUser && <li>TCS User</li>}
              </ul>
            </div>

            <div className="delegationMain">
              <div className="tooltipWrapper">
                <h4 className="mainHeading">Approval Delegation</h4>
                <div className="tooltip2"><FontAwesomeIcon
                  className="info"
                  icon={faInfoCircle}
                />
                  <div class="rightt">
                    <h4>Instructions</h4>
                    <p>In "Select Regions" please specify the regions you want to apply a specific delegated approver.
                      If you need to apply different approvers for different regions repeat the steps below
                      for each approver/region​</p>
                    <h4>Option 1 – Apply to all markets</h4>
                    <li>Click "Update Options" and choose an approver from "Select New Approver".
                      (The user list shows all users who has "approval right" in the regions covered in the selection.
                      If an extra approver is required for delegation, please raise support ticket to add
                      "approval right" to that extra user first.)</li>
                    <li>Click "Apply Approver to selection"</li>
                    <li>If you applied a wrong user, you can revert it by clicking "Remove approver from selection"​</li>
                    <li>Click "Save"</li>
                    <br />
                    <h4>Option 2 – Apply to individual market</h4>

                    <li>Select the delegated approver from each individual market</li>
                    <li>Click "Save"</li>

                    <i></i>
                  </div>
                </div>
              </div>
              <div className="delegationPeriod">
                <div>
                  <h5>Delegation Period</h5>
                </div>
                <div>
                  <span>{(fetchedUser?.length > 0 ? getCurrentDate(fetchedUser[0]?.StartAt) + " - " + getCurrentDate(fetchedUser[0]?.EndAt)
                    : inputDate.StartAt + " - " + inputDate.EndAt)}</span>
                </div>
              </div>

              <div className="dateTime">
                <div>
                  <span>From*</span>
                  <FormGroup>
                    <Input
                      id="StartAt"
                      name="StartAt"
                      type="date"
                      value={(fetchedUser && getCurrentDate(fetchedUser[0]?.StartAt)) || inputDate.StartAt}
                      onChange={handleDate}
                      invalid={isStartDateFocus}
                      disabled={fetchedUser?.length > 0 || Object.keys(disableMarkets).length}
                      min={getCurrentDate(new Date())}
                    />
                  </FormGroup>
                </div>
                <div>
                  <span>To*</span>
                  <FormGroup>
                    <Input
                      id="EndAt"
                      name="EndAt"
                      type="date"
                      value={(fetchedUser && getCurrentDate(fetchedUser[0]?.EndAt)) || inputDate.EndAt}
                      onChange={handleDate}
                      invalid={isEndDateFocus}
                      disabled={fetchedUser?.length > 0 || Object.keys(disableMarkets).length}
                      min={getCurrentDate(new Date())}
                    />
                  </FormGroup>
                </div>
              </div>
            </div>
            <div className="region">
              <h5>Select Region*</h5>
              <Select
                className="react-select-container selectContainer"
                classNamePrefix="react-select"
                isMulti
                isSearchable
                options={regionLabel.map((item) => {
                  return { value: item, label: item };
                })}
                value={isRegionSelect}
                onChange={(e) => selectRegionHandler(e)}
                isDisabled={ isDisabledButton}
              />
            </div>
            <div className="update-grid">
              <div className="selectToggle">
                <h5>Update Options</h5>
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={dropOpts.map((item) => {
                    return { value: item.Label, label: item.Label };
                  })}
                  value={updateOptions?.updateOption}
                  onChange={(e) => setUpdateHandler(e)}
                  isDisabled={isDisabledButton}
                />
              </div>
              <div className="selectApprover">
                <h5>Select New Approver</h5>
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={getAllUniqueUsers()}
                  isMulti
                  isSearchable
                  value={newApprover?.approverContact}
                  onChange={(e) =>
                    setNewApprover({ ...newApprover, approverContact: e })
                  }
                  isDisabled={isDisabledButton}
                />
              </div>
              <div className="approverButtons">
                <Button
                  size="sm"
                  block
                  color="primary"
                  onClick={(e) => applyUpdateOptions(e)}
                  disabled={isDisabledButton}
                >
                  Apply Approver to Selection
                </Button>
                <Button
                  size="sm"
                  block
                  color="secondary"
                  onClick={() => deleteOptions()}
                  disabled={isDisabledButton}
                >
                  Remove Approver From Selection
                </Button>
              </div>
            </div>
            <br />
            {dataInput?.map((item, index) => (
              <li
                className="approvalList"
                key={item?.Name}
              >
                <div className="countryIndustry">
                  <div className="countryIn">
                    <label htmlFor="updateall">
                      {item?.Market_Label}
                    </label>
                  </div>
                  <div className="approver">
                    <FormGroup>
                      <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={getUniqueUsers(item?.Market)}
                        name="DelegateUserEmail"
                        isMulti
                        isSearchable
                        value={fetchedUser?.length > 0 ? specificUserEmail(item) : (item?.DelegateUsers && item?.DelegateUsers.map(e => e))}
                        onChange={updateProject(index)}
                        isDisabled={specificUserDisabled(item)|| disableMarkets[item.Market]}
                      />
                    </FormGroup>
                  </div>
                  <div className="check">
                    <CustomInput
                      type="checkbox"
                      id={item?.Name}
                      label=""
                      name="isInputChecked"
                      onClick={handleChecked(index)}
                      checked={(item?.isInputChecked && !specificUserDisabled(item) && !disableMarkets[item.Market])}
                      disabled={specificUserDisabled(item)|| disableMarkets[item.Market]}
                    />
                  </div>
                </div>
              </li>
            ))}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => handleSave()} disabled={isDisabledButton}>
            Save
          </Button>
          <Button color="secondary" onClick={() => props.setApprovalModal(!props.isApprovalModal)} >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ApprovalDelegate;