import * as types from "./ActionTypes";

export const fetchVendorAndList = (Profile,id,pageload) =>
{
    var Country=[];
    var Methodology=[]
Profile?.CountrySpecs?.forEach(C=>{
Country.push(C.CountryCode)
C.MethodologySpecs?.forEach(m=>
    {
        Methodology.push(m.Code)
    })
})

    return {
        type: types.FETCH_VENDOR_AND_LIST_REQUEST,
        payload:{Methodology:Methodology,Country:Country,VendorID:id,pageload:pageload}
    };
}
