import * as types from "../constants";


export function addCostingOptionsLink(link)
{   
    return {
        type: types.SIDEBAR_ADD_COS_LINK,
        payload: link
    };
}
export function addSudOptionsLink(link)
{   
    return {
        type: types.SIDEBAR_ADD_SUD_LINK,
        payload: link
    };
}
export function resOptionsLink(link)
{   
    return {
        type: types.SIDEBAR_ADD_RES_LINK,
        payload: link
    };
}
export function addSidebarLinks(links)
{
    
    return {
        type: types.SIDEBAR_ADD_LINKS,
        payload: links
    };
}

export function toggleSidebar() {
  return {
    type: types.SIDEBAR_VISIBILITY_TOGGLE
  };
}

export function showSidebar() {
  return {
    type: types.SIDEBAR_VISIBILITY_SHOW
  };
}

export function hideSidebar() {
  return {
    type: types.SIDEBAR_VISIBILITY_HIDE
  };
}

export function toggleStickySidebar() {
  return {
    type: types.SIDEBAR_STICKY_TOGGLE
  };
}

export function enableStickySidebar() {
  return {
    type: types.SIDEBAR_STICKY_ENABLE
  };
}

export function disableStickySidebar() {
  return {
    type: types.SIDEBAR_STICKY_DISABLE
  };
}
