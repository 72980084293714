import React from "react";

function Folder() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          fill="#000"
          d="M8.828 3.5l2.562 2.56.438.44H21A1.5 1.5 0 0122.5 8v13.5h-21V5A1.5 1.5 0 013 3.5h5.828M9.45 2H3a3 3 0 00-3 3v18h24V8a3 3 0 00-3-3h-8.55l-3-3z"
        ></path>
      </g>
    </svg>
  );
}

export default Folder;