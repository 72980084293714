import React, { useState, useEffect } from "react";
import { getLabel } from "../../../utils/codeLabels";
import _, { set } from "lodash";
import { toastr } from "react-redux-toastr";
import * as mapperFunctions from "../../../utils/rfqMapper";
import {
  pageLoadEnd,
  pageLoadStart,
  recordLoadEnd,
  recordLoadStart,
  setCostingStatus,
} from "../../../redux/actions/appActions";
import * as appActions from "../../../redux/actions/appActions";
import axios from "../../../axios-interceptor";
import Layout from "../../../layouts/Project";
import { useHistory } from "react-router-dom";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import * as currentProjectActions from "../../../redux/actions/currentProjectActions";
import * as currentCostingActions from "../../../redux/actions/currentCostingActions";
import update from "immutability-helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CostingTypeMapping from "../../../components/CostingTypeMapping";
import {
  faBackward,
  faCheck,
  faChevronDown,
  faChevronUp,
  faCopy,
  faList,
  faSave,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledCollapse,
  Progress,
} from "reactstrap";
import { Prompt } from "react-router";
import BiddingDetails from "./VendorDetails";
import { useParams } from "react-router-dom";
import ProposalBiddingSelectVendorsUpdated from "./ProposalBiddingSelectVendorsUpdated";
import VbmCopyToallMarket from "./VbmCopyAllMarket";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import "./css/common.css";
import * as proposalActions from "../../../redux/actions/proposal/Actions";
import * as FetchVendorAndListActions from "../../../redux/actions/FetchVendorAndList/Actions";
import RFQTable from "./RFQTable";
import SUDTable from "./SUDTAble";
import {
  SET_TOTAL_FILES,
  SET_UPLOADED_FILES,
  SET_UPLOAD_STATUS,
  SET_VENDOR_FLAG,
  SET_BIDDING_SPLIT_DATA
} from "../../../redux/actions/vendor/ActionTypes";
import VendorsEmailManagement from "./VendorEmailPopUp";
import Project from "../../../layouts/Project";

function VendorBiddingFormUpdated(props) {
  const codeLabels = useSelector(({ codeLabels }) => codeLabels);
  const userRecord = useSelector(({ user }) => user.userRecord)
  let pacificData = _.head(codeLabels?.PacificCountriesList)?.Label;
  let arr = pacificData?.split(",");
  const [pacificMarkets] = useState(arr ? arr : []);
  const splitdata = useSelector(
    ({ vendorReducer }) => vendorReducer.SplitData
  )
  const projectid = useParams();
  const dispatch = useDispatch();
  const [updateCost, setUpdateCost] = useState();
  const [closeBidding, setCloseBidding] = useState(false);
  const [isCostSelection, setCostSelection] = useState(false);
  const [MarketCopy, setMarketCopy] = useState(false);
  const [showCostMethod, setShowCostMethod] = useState(false);
  const [vendor, setvendor] = useState();
  const [navigation, setNavigation] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [summaryRender, setSummaryRender] = useState(false);
  const [activeTabMethodology, setActiveTabMethodology] = useState(0);
  const [resetcostmodelState, setresetcostModelState] = useState(false);
  const [saveselectedrows, setsaveselectedrows] = useState([]);
  const [isSummary, setIsSummary] = useState(false);
  const [fileUploadPopUp, setFileUploadPopUp] = useState(false);
  const [fromDashboard, setFromDashboard] = useState(false);
  const [SampleUpdated, setSampleUpdated] = useState(false);
  const history = useHistory();
  const [activeTabFC, setActiveTabFC] = useState(0);
  const [activeTabSM, setActiveTabSM] = useState(0);
  const [cCProfile, setCCProfile] = useState(null);
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
  const [oncostview, setoncostview] = useState(false);
  const [CurrentDate, setCurrentDate] = useState(0);
  const [ViewOnlyMode, setViewOnlyMode] = useState(false);
  const [costingProfileIndex, setCostingProfileIndex] = useState(0);
  const [list, setlist] = useState([]);
  const [openVendorEmail, setVendorEmail] = useState(false);

  const costingStatus = useSelector(({ app }) => app.costingStatus);

  const vendorAndList = useSelector(
    ({ fetchVendorAndList }) => fetchVendorAndList.vendorAndList
  );
  let vendorreducer = useSelector(({ vendorReducer }) => vendorReducer);
  var project = useSelector(({ currentProject }) => currentProject.newProject);
  const currentCostingProfile = useSelector(
    ({ currentCosting }) => currentCosting.currentCostingProfile
  );
  const oppValue = useSelector(

    ({ currentProject }) => currentProject.opportunityValue

  );
  useEffect(() => {
    const current1 = new Date();

    setCurrentDate(SetDate(current1));
  }, []);
  useEffect(() => {
    removeTabsVendor(project)
  }, [vendorAndList])
  const updateCostingProfile = (costingProfile) => {
    dispatch({
      type: "UPDATE_NEW_COSTING",
      currentCostingProfile: costingProfile,
    });
  };
  Date.prototype.addDays = function (days) {
    let date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  function CheckViewOnlyMode(TempProject) {
    if (TempProject && TempProject?.CostingProfiles?.length > 0) {
      setViewOnlyMode((
        (TempProject.ProjectStatus == 7) ||
        (_.last(TempProject?.CostingProfiles)?.VenodrBidDeadLineStatus == "close") ||
        (["2", "3", "4", "5", "6"].indexOf(_.last(TempProject?.CostingProfiles).ProfileStatus) != -1))
        ? true : false)
    }

  }
  function closeBiddingConditions() {
    let isSelectedVendor = [];
    let mailSent = _.last(project.CostingProfiles)?.CountrySpecs?.flatMap((cs) =>
      cs?.MethodologySpecs?.filter(
        (sm) => sm.CostingType == 3 && !sm.NotApplicable
      )?.flatMap((ms) =>
        ms?.InvitedVendors?.flatMap(
          (vs) => vs?.IsMailSent
        ))).filter((f) => f);
    _.last(project.CostingProfiles)?.CountrySpecs?.flatMap((cs) =>
      cs?.MethodologySpecs?.filter(
        (sm) => sm.CostingType == 3 && !sm.NotApplicable
      )?.flatMap((ms) => {
        if (ms?.BiddingDetail?.SelectedVendor && ms?.BiddingDetail?.SelectedVendor?.length) {
          isSelectedVendor.push(true)
        }
        else {
          isSelectedVendor.push(false)
        }
      })).filter((f) => f)
    if (mailSent?.length > 0 && isSelectedVendor.length > 0 && isSelectedVendor.every(s => s == true) &&
      _.last(project.CostingProfiles)?.VenodrBidDeadLineStatus != "close" &&
      splitdata.every(s => s.RoundClosedOn)
    ) {
      setCloseBidding(true)
    }
    else {
      setCloseBidding(false)
    }
  }


  function currencyDropdown() {
    var currdata = [];
    var curr =
      project.CostingProfiles[costingProfileIndex] &&
        project.CostingProfiles[costingProfileIndex]?.CountrySpecs.length > 0
        ? project.CostingProfiles[
          costingProfileIndex
        ]?.ProfileSetting.CurrenciesData.find(
          (c) =>
            c.Code ==
            project.CostingProfiles[costingProfileIndex]?.CountrySpecs[
              activeTabFC
            ].CountryCode
        )
        : "";
    var commcountrycurr =
      project.CostingProfiles[costingProfileIndex] &&
        project.CostingProfiles[costingProfileIndex]?.CountrySpecs.length > 0
        ? project.CostingProfiles[costingProfileIndex]?.CountrySpecs[
          activeTabFC
        ].CountryCode != project.CommissioningCountry
          ? project.CostingProfiles[
            costingProfileIndex
          ]?.ProfileSetting.CurrenciesData.find(
            (c) => c.Code == project.CommissioningCountry
          )
          : ""
        : "";

    //project.CommissioningCountry;
    if (curr != "" && curr != undefined) {
      curr.AllCurrencyUnit = codeLabels.FieldingCountriesOptions.find(
        (fc) => fc.Code == curr.Code
      ).AllCurrencyUnit;
      //  curr.AllCurrencyUnit.replace(/(\r\n|\n|\r)/gm, "");
      var allUnits = curr.AllCurrencyUnit
        ? curr.AllCurrencyUnit.split(",")
        : [];
      if (allUnits.length > 0) {
        allUnits.forEach((all, i) => {
          currdata.push({
            id: i + 1,
            label: all,
            value: i + 1,
            code: curr.Code,
            ConversionRateToLocal: project.CostingProfiles[
              costingProfileIndex
            ]?.ProfileSetting.CurrenciesData.filter(
              (curr) => curr.CurrencyUnit == all
            )[0].ConversionRateToLocal,
          });
        });
      }
    }
    if (commcountrycurr != "" && commcountrycurr != undefined) {
      var currlength = currdata.length;
      commcountrycurr.AllCurrencyUnit =
        codeLabels.FieldingCountriesOptions.find(
          (fc) => fc.Code == commcountrycurr.Code
        ).AllCurrencyUnit;
      //  curr.AllCurrencyUnit.replace(/(\r\n|\n|\r)/gm, "");
      var allUnits = commcountrycurr.AllCurrencyUnit
        ? commcountrycurr.AllCurrencyUnit.split(",")
        : [];
      if (allUnits.length > 0) {
        allUnits.forEach((all, i) => {
          if (!currdata.find((a) => a.label == all)) {
            currdata.push({
              id: currlength + i + 1,
              label: all,
              value: currlength + i + 1,
              code: commcountrycurr.Code,
              ConversionRateToLocal: project.CostingProfiles[
                costingProfileIndex
              ]?.ProfileSetting.CurrenciesData.filter(
                (curr) => curr.CurrencyUnit == all
              )[0].ConversionRateToLocal,
            });
          }
        });
      }
    }
    return currdata;
  }
  function extractingVendor(res) {
    dispatch(FetchVendorAndListActions.fetchVendorAndList(res, null, false));
  }
  function updateProfiles(values) {
    dispatch({
      type: currentProjectActions.UPDATE_NEW_PROJECT,
      newProject: values,
    });
    dispatch(
      currentCostingActions.updateProfile(_.last(values.CostingProfiles), null)
    );
    {
      dispatch(
        FetchVendorAndListActions.fetchVendorAndList(
          _.last(values.CostingProfiles),
          null
        )
      );
    }
  }
  var i = 0;

  useEffect(() => {
    if (project.VendorUpload) {
      project.VendorUpload = false
      RfqChanges(project)
    }
  }, [project.VendorUpload])
  useEffect(() => {
    closeBiddingConditions()
    if (project.CostingProfiles.length > 0) {
      if (projectid.summary == 1) {
        setIsSummary(true);
        setFromDashboard(true);
      }
    }
  }, [project]);

  function removeTabsVendor(data) {
    if (data.CostingProfiles.VenodrBidDeadLineStatus != "close" && vendorAndList.length > 0) {
      _.last(data.CostingProfiles)?.CountrySpecs?.forEach(c => {
        c.MethodologySpecs.forEach(m => {
          let jsonData = setList(m.Code, m.Label)
          let tabs = jsonData
            .filter((s) => s.header && s.sud == true)
          let tab = m?.OpResourcesVariables?.filter(
            (op) => op.type == 'single' && op.value == "Yes"
          ).map(a => a.id)


          let tabToRender = tabs.filter(a => !(tab.includes(a.tabid)) && !a.show)
          let tsrender = tabToRender.map(a => a.header)
          let rows = jsonData.filter(a => !tsrender.includes(a.group || a.header))
          tabToRender?.forEach(tab => {
            m?.InvitedVendors?.filter(a => a.ServiceType == 2)?.forEach(ven => {
              ven.SelectedRows = rows?.map((u) => {
                return {
                  id: u.id,
                  name: u.name,
                  selected: ven.SelectedRows.filter(a => a.id == u.id)?.length > 0 ? ven.SelectedRows.filter(a => a.id == u.id)[0].selected : true,
                  group: u.group,
                  type: u.type,
                  key: u.key,
                  header: u.header ? u.header : null,
                  OtherCost: ven.SelectedRows.filter(a => a.id == u.id)[0]?.OtherCost ? ven.SelectedRows.filter(a => a.id == u.id)[0]?.OtherCost : ""
                };
              })

            })
            m?.InvitedVendors?.filter(a => a.ServiceType == 1)?.forEach(v => {
              if (v.ServiceLists.includes(tab.header)) {
                v.ServiceLists = v.ServiceLists.filter(a => a != tab.header)
                m?.BiddingDetail?.SelectedVendor?.forEach(a =>
                  a.selected = a.selected.filter(s => tab.header != s.Costingtype && v.VendorId != s.VendorID)
                )
                v.VendorCosts.forEach(vc => {
                  vc.CostingsData = vc.CostingsData.filter(cd => cd.group != tab.header)
                })
                v.SelectedRows = v.SelectedRows.filter(
                  (a) => a.group != tab.header && a.header != tab.header
                );
                if (v.ServiceLists.length == 0) {
                  v.IsDeleted = true
                }
              }
            })
          })
        })
      })
      dispatch({
        type: currentProjectActions.UPDATE_NEW_PROJECT,
        newProject: project,
      });
    }
  }

  useEffect(() => {
    if (project.id == "" || project.id == null) {
      dispatch(appActions.pageLoadStart());
      axios
        .get("vendors/project/bidding", {
          params: {
            ProjectId: projectid.projectId,
            CostingProfileId: projectid.CostingProfileID,
          },
        })
        .then(async (res) => {
          setCostingProfileIndex(res.data.data.CostingProfiles.length - 1);
          setCCProfile(res.data.data.CostingProfiles[res.data.data.CostingProfiles.length - 1])

          CheckViewOnlyMode(res.data.data)
          if (
            res.data.data.CostingProfiles[
              res.data.data.CostingProfiles.length - 1
            ]?.VendorBidDeadLine
          ) {
            var Cdate = new Date();
            var deadlinedate = new Date(
              res.data.data.CostingProfiles[
                res.data.data.CostingProfiles.length - 1
              ]?.VendorBidDeadLine
            );
            var current1 = deadlinedate.addDays(5);
            SetDate(current1);

            var d = current1.getDate();
            var day = d < 10 ? "0" + d : d;
            var m = current1.getMonth() + 1;
            var month = m < 10 ? "0" + m : m;
            var y = current1.getFullYear();
            var h = current1.getHours();
            var hour = h < 10 ? "0" + h : h;
            var minu = current1.getMinutes();
            var minute = minu < 10 ? "0" + minu : minu;
            var extended =
              y + "-" + month + "-" + day + "T" + hour + ":" + minute;
            var currentdate = SetDate(Cdate);
            var extendeddate = SetDate(current1);

            if (extended < currentdate) {
              if (
                res.data.data.CostingProfiles[
                  res.data.data.CostingProfiles.length - 1
                ]?.VenodrBidDeadLineStatus != "close"
              ) {
                setViewOnlyMode(true);
              }
            }
          }
          if (i == 0) {
            distributionRounds(res.data.data)
            RfqChanges(res.data.data);
            i = i + 1
          }
          setCCProfile(
            res.data.data.CostingProfiles[
            res.data.data.CostingProfiles.length - 1
            ]
          );

          //dispatch(appActions.pageLoadEnd())
        });
    } else {
      dispatch(appActions.pageLoadStart());
      setNavigation(true);
      distributionRounds(project)

      setCCProfile(project?.CostingProfiles[project.CostingProfiles.length - 1])

      if (project?.CostingProfiles.length > 0)
        CheckViewOnlyMode(project)

      {
        dispatch(
          FetchVendorAndListActions.fetchVendorAndList(
            project.CostingProfiles[project.CostingProfiles.length - 1],
            null,
            true
          )
        );
      }
    }
    dispatch({ type: SET_VENDOR_FLAG, Vendor: true });
  }, []);

  useEffect(() => {
    if (project.ProfileChange) {
      setCCProfile(project.CostingProfiles[project.CostingProfiles.length - 1])
      project.ProfileChange = false;
      dispatch({
        type: currentProjectActions.UPDATE_NEW_PROJECT,
        newProject: project,
      });
    }
  }, [project.ProfileChange])
  useEffect(() => {
    if (project.VendorLoaded == true) {
      setCCProfile(project.CostingProfiles[project.CostingProfiles.length - 1]);
      CheckViewOnlyMode(project);

      project.VendorLoaded = false;
      dispatch({
        type: currentProjectActions.UPDATE_NEW_PROJECT,
        newProject: project,
      });
    }
  }, [project.VendorLoaded]);
  // useEffect(() => {
  //   if (project.id != "" && project.id != null && !renderData) {
  //     axios
  //       .post("sampleSizeDistributionRounds/details", {
  //         MethodologySpecId: project?.CostingProfiles[
  //           costingProfileIndex
  //         ]?.CountrySpecs.map((cs) =>
  //           cs.MethodologySpecs.map((ms) => ms.id)
  //         ).flat(),
  //       })
  //       .then((res) => {
  //         let tempResult = res.data.data

  //         project?.CostingProfiles[
  //           costingProfileIndex
  //         ]?.CountrySpecs.map((cs) =>
  //           cs.MethodologySpecs.map((ms) => {
  //             tempResult.forEach(result => {
  //               if (result.RoundClosedOn == null && result.MethodologySpecId == ms.id) {
  //                 let savedCO = result.SampleDistributionMasters.map(distribution => distribution.CostingOptionId)
  //                 let allCO = ms.CostingOptions.map(co => co.id)
  //                 if (!_.isEqual(savedCO.sort(), allCO.sort)) {
  //                   ms.CostingOptions.filter(a => !savedCO.includes(a.id)).map(costingOption => {
  //                     let tempSampleMasters = {
  //                       CostingOptionId: costingOption.id,
  //                       IsSplited: false,
  //                       NumberOfSplits: 0,
  //                       TargetSamples: parseInt(costingOption.CostingOptionData.sampleSizeMain),
  //                       CoSampleSize: parseInt(costingOption.CostingOptionData.sampleSizeMain),
  //                       SampleDistributionDetails: [],
  //                       AchivableSamples: null
  //                     }
  //                     result.SampleDistributionMasters.push(tempSampleMasters)
  //                   }
  //                   )

  //                 }
  //               }
  //             })
  //           })
  //         )
  //         dispatch({ type: SET_BIDDING_SPLIT_DATA, data: tempResult })
  //         console.log(tempResult);
  //         if (tempResult.length === 0) {
  //         }
  //         setlist(tempResult);
  //         setRoundNo(tempResult);
  //         setRenderData(true);
  //       })
  //       .catch((error) => {
  //         toastr.error("Data Retrival Failed!");
  //         setRenderData(false);
  //       });

  //   }
  // }, []);

  function setList(methCode, label) {
    if (vendorAndList.length > 0) {
      let initialstateQuant = vendorAndList
        .filter((obj) => obj.Group == "fieldexecutionandsudtable")[0]
        ?.List.filter((v) => v.Name == "initailstatequant")[0]?.Values;
      let initialstateQual = vendorAndList
        .filter((obj) => obj.Group == "fieldexecutionandsudtable")[0]
        ?.List.filter((v) => v.Name == "initailstatequal")[0]?.Values;
      let initailstateOnline = vendorAndList
        .filter((obj) => obj.Group == "fieldexecutionandsudtable")[0]
        ?.List.filter((v) => v.Name == "initailstateonline")[0]?.Values;
      let typeofquant = codeLabels?.SubMethodologyOptions?.filter(
        (sm) => sm.ResearchType == "Quantitative"
      )?.map((c) => c.Code);

      return (label == "Online Self Completion Survey"
        ? initailstateOnline
        : typeofquant.find((a) => a == methCode)
          ? initialstateQuant
          : initialstateQual)
    }

  }
  function distributionRounds(data) {
    axios
      .post("sampleSizeDistributionRounds/details", {
        MethodologySpecId: data?.CostingProfiles[
          costingProfileIndex
        ]?.CountrySpecs.map((cs) =>
          cs.MethodologySpecs?.filter(a => a.CostingType == 3)?.map((ms) => ms.id)
        ).flat(),
      })
      .then((res) => {
        let tempResult = res.data.data

        // data?.CostingProfiles[
        //   costingProfileIndex
        // ]?.CountrySpecs.map((cs) =>
        //   cs.MethodologySpecs.map((ms) => {
        //     tempResult.forEach(result => {
        //       if (result.RoundClosedOn == null && result.MethodologySpecId == ms.id) {
        //         let savedCO = result.SampleDistributionMasters.map(distribution => distribution.CostingOptionId)
        //         let allCO = ms.CostingOptions.map(co => co.id)
        //         if (!_.isEqual(savedCO.sort(), allCO.sort)) {
        //           ms.CostingOptions.filter(a => !savedCO.includes(a.id)).map(costingOption => {
        //             let tempSampleMasters = {
        //               CostingOptionId: costingOption.id,
        //               IsSplited: false,
        //               NumberOfSplits: 0,
        //               TargetSamples: parseInt(costingOption.CostingOptionData.sampleSizeMain),
        //               CoSampleSize: parseInt(costingOption.CostingOptionData.sampleSizeMain),
        //               SampleDistributionDetails: [],
        //               AchivableSamples: null
        //             }
        //             result.SampleDistributionMasters.push(tempSampleMasters)
        //           }
        //           )
        //         }
        //       }
        //     })
        //   })
        // )
        dispatch({ type: SET_BIDDING_SPLIT_DATA, data: tempResult })
        setSampleUpdated(true)
        //console.log(tempResult);
        if (tempResult.length === 0) {
        }
        setlist(tempResult);
      })
      .catch((error) => {
        toastr.error("Data Retrival Failed!");
      });
  }
  function RfqChanges(data) {

    data.CostingProfiles[data.CostingProfiles.length - 1].CountrySpecs.forEach(
      (cp) => {
        cp.MethodologySpecs.forEach((ms) => {
          let tempVariables = getSchemaWithDataJSON(
            ms.RFQSchema,
            ms.Code,
            ms.RFQData,
            true
          );
          if (!ms.RFQData) {
            tempVariables.forEach((x) => {
              if (x.dependentOn) x.disabled = true;
            });
          }

          let tempOpResourceVariables = getSchemaWithDataJSON(
            ms.WaveSpecs[0].OpsResourcesSchema,
            ms.Code,
            ms.WaveSpecs[0].OpsResourcesData
          );
          if (!ms.RFQData) {
            tempOpResourceVariables.forEach((x) => {
              if (
                x.dependentOn ||
                x.label == "Coding Required" ||
                x.label == "Verbatim Coding Required"
              ) {
                x.disabled = true;
              }
            });
          }
          ms["Variables"] = _.cloneDeep(tempVariables);
          ms["OpResourcesVariables"] = tempOpResourceVariables;
        });
      }
    );

    updateProfiles(data)
  }

  function getSchemaWithDataJSON(schemaJSON, smCode, dataJSON, isRFQ = false) {
    var variables;
    variables = formatSchemaForm(schemaJSON, smCode, isRFQ);
    if (dataJSON) {
      for (var obj of variables) {
        if (dataJSON[obj.id]) obj.value = dataJSON[obj.id];
      }
    }
    return variables;
  }
  function objectKeys(obj) {
    var keys = [];
    if (!obj) return keys;
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        keys.push(key);
      }
    }
    return keys;
  }
  function move(from, to, newArr) {
    if (from > -1) {
      const item = newArr.splice(from, 1)[0];
      newArr.splice(to, 0, item);
    }
    return newArr;
  }
  function formatObjectToVariable(
    schema,
    properties,
    key,
    indx,
    variables,
    pVar = null,
    commCountryCode,
    pacificCountries
  ) {
    var variable = {
      id: key,
      label: properties[key].title.replace("Sample size (Main)", "Sample size"),
      type: null,
    };

    //  if(schema.title=="Path Tracking")
    //  {
    if (
      [
        "forBroadCategory",
        "forAllGondolaEndsFromOtherMonitoredCategories",
        "forAllGondolaEndsFromClientCategory",
        "forOtherMonitoredCategories",
        "forClientCategory",
      ].indexOf(key) > -1
    ) {
      variable.check = true;
      variable.checkOptions = [
        {
          label: "Purchase",
          selected: false,
        },
        {
          label: "Handling",
          selected: false,
        },
        {
          label: "Stop",
          selected: false,
        },
        {
          label: "Other interaction 1",
          selected: false,
        },
        {
          label: "Other interaction 2",
          selected: false,
        },
        {
          label: "Other interaction 3",
          selected: false,
        },
      ];
    }
    //}

    if (
      [
        "purchase",
        "handling",
        "stop",
        "otherInteraction1",
        "otherInteraction2",
        "otherInteraction3",
      ].indexOf(key) > -1
    ) {
      variable.category = true;
    }
    // for path tracking
    // if (["citiesCoverage", "incidenceRate", "productModelling", "totalNumberofUNIQUESKU", "incidenceRateNote",
    //     "numberofControlScenario", "numberofTestScenario", "sampleSizePerScenarioPerOPtion", "totalSamplePerCountryPerOPtion"].indexOf(key) > -1) {

    if (properties[key].isDefaultCostingOption) {
      variable.Default = true;
      if (properties[key].isRequired) {
        variable.mandatory = true;
      }
    } else if (properties[key].isDefaultCostingOption === false) {
      variable.Default = false;
      variable.mandatory = false;
    } else if (
      [
        "citiesCoverage",
        "incidenceRate",
        "typeOfChannel",
        "productModelling",
        "totalNumberofUNIQUESKU",
        "incidenceRateNote",
        "numberofControlScenario",
        "numberofTestScenario",
        "sampleSizePerScenarioPerOPtion",
        "totalSamplePerCountryPerOPtion",
        "incidenceRateMain",
      ].indexOf(key) > -1
    ) {
      // changed for Path Tracking
      variable.Default = true;
    } else if (
      [
        "totalSampleSize",
        "numberofStores",
        "sampleSizeByLocation",
        "noOfLocations",
        "sampleSource",
        "samplingType",
        "numberInEachGroup",
        "numberOfGroup",
        "lengthOfInterview",
        "sampleSizeMain",
        "sampleSize",
        "targetGroupDefinition",
        "typeOfVenue",
        "averageTimeSpent",
        "averageTimeSpentOnWatching",
        "numberOfMonitoredCategories",
        ,
        "numberOfMonitoredRegularShelves",
        "numberOfMonitoredPOSMaterialsrest",
        "numberOfPages/Screens",
        "numberOfMonitoredAdditionalExpositions",
        "numberOfMonitoredCounters/Bars",
        "averageTimeSpentOnWatchingAllMonitoredCategories",
        "numberOfMonitoredPOSMaterialsstore",
        "averagreTimeOfLookingAtAllMonitoredExpositions",
        "averageTimeSpentInAllMonitoredCategories",
        "storePermissionResponsibility",
        "additionalInformation",
        "sampleSizePerStore",
        "averageTimeSpentintheStore",
        "numberofMonitoredCategories",
      ].indexOf(key) > -1
    ) {
      //changed for Eye Tracking
      variable.Default = true;
      variable.mandatory = true;
    }

    if (properties[key].dependentOn)
      variable.dependentOn = properties[key].dependentOn;
    if (properties[key].optionsLabel)
      variable.optionsLabel = properties[key].optionsLabel;
    if (properties[key].tooltext) variable.tooltext = properties[key].tooltext;
    if (properties[key].instructions)
      variable.instructions = properties[key].instructions;
    variable.disable = properties[key].disable;
    variable.disabled = properties[key].disabled;
    variable.AddToCostingOption = properties[key].AddToCostingOption;
    variable.defTable = properties[key].defTable;

    if (properties[key].enum) {
      variable.type = "single";
      variable.options = properties[key].enum.map((x) => ({
        label: x,
        value: x,
      }));
    } else if (
      properties[key]["widgetType"] &&
      properties[key]["widgetType"] == "textarea"
    )
      variable.type = "textarea";
    else if (properties[key]["format"] && properties[key]["format"] == "date")
      variable.type = properties[key]["format"];
    else variable.type = properties[key].type.replace("string", "text");

    if (properties[key]["optionsLabel"] && !properties[key]["widgetType"])
      variable.type = "single";

    if (
      properties[key]["widgetType"] &&
      properties[key]["widgetType"] == "multiselectdropdown"
    ) {
      if (properties[key]["title"] === "City Coverage") {
        variable.type = "creatableMulti";
      } else {
        variable.type = "multi";
      }
    }

    if (schema.required) {
      var isRequired = schema.required.filter((x) => x == key).length;
      if (isRequired) variable.mandatory = true;
    }

    if (
      properties[key] &&
      properties[key]["isNewSection"] &&
      key != "citiesCoverage"
    ) {
      variable.isNewSection = properties[key]["isNewSection"];
      variable.sectionTitle = properties[key]["sectionTitle"];
    }

    if (key == "targetGroupDefinition") {
      if (properties["citiesCoverage"]) {
        variable.isNewSection = properties["citiesCoverage"]["isNewSection"];
        variable.sectionTitle = properties["citiesCoverage"]["sectionTitle"];
      }
    }

    if (!variable.sectionTitle && indx > 0)
      variable.sectionTitle = pVar
        ? pVar.sectionTitle
        : variables[indx - 1].sectionTitle;

    if (_.includes(pacificCountries, commCountryCode)) variable.Default = false;

    return variable;
  }
  function formatSchemaForm(schema, smCode, isRFQ = false) {
    var variables = [];
    if (schema) {
      var qualCodeList = ["SM000012", "SM000013", "SM000003", "SM000037"];
      var keys = objectKeys(schema.properties);
      var keysDependencies = schema.dependencies
        ? Object.keys(schema.dependencies)
        : [];

      var indx = keys.indexOf("sampleSource");
      move(indx, 0, keys);
      indx = keys.indexOf("samplingType");
      move(indx, 0, keys);
      indx = keys.indexOf("lengthOfInterview");
      move(indx, 0, keys);
      indx = keys.indexOf("sampleSizeMain");
      move(indx, 0, keys);
      indx = keys.indexOf("sampleSize");
      move(indx, 0, keys);
      // CostingOption include for Qual
      indx = keys.indexOf("numberInEachGroup");
      move(indx, 0, keys);
      indx = keys.indexOf("numberOfGroup");
      move(indx, 0, keys);
      indx = keys.indexOf("citiesCoverage");
      move(indx, 0, keys);
      indx = keys.indexOf("targetGroupDefinition");
      move(indx, 0, keys);

      var i = 0,
        smKeys = keys;
      // remove the simpleSizeMain on by code compare
      if (qualCodeList.includes(smCode)) {
        smKeys = smKeys.filter((K) => K !== "sampleSizeMain");
      }
      for (var key of smKeys) {
        if (
          schema.properties[key].title == "Sample size (Booster)" ||
          schema.properties[key].title == "Sample size 3 (Other)"
        )
          continue;

        var variable = formatObjectToVariable(
          schema,
          schema.properties,
          key,
          i,
          variables
        );
        variables.push(variable);

        if (keysDependencies.indexOf(key) > -1) {
          var dependency = schema.dependencies[key].oneOf.filter(
            (x) => Object.keys(x.properties).length > 1
          )[0];
          var nestedKeysDependencies = Object.keys(dependency.properties);
          variable.selectedIf = dependency.properties[key].enum;
          for (let keyNest of nestedKeysDependencies) {
            if (keyNest == key) continue;
            if (!dependency.properties[keyNest].dependentOn)
              dependency.properties[keyNest].dependentOn = {
                andor: "AND",
                fields: [
                  {
                    fieldName: schema.properties[key].title,
                    fieldValue:
                      dependency.properties[key] &&
                        dependency.properties[key].enum &&
                        dependency.properties[key].enum[0]
                        ? dependency.properties[key].enum[0]
                        : "Yes",
                    operator: "==",
                  },
                ],
              };
            // dependency.properties[keyNest].dependentOn = { andor: "AND", fields: [{ fieldName: schema.properties[key].title, fieldValue: "Yes", operator: "==" }] };
            //var parentVar=formatObjectToVariable.filter(p=> p.label==)
            var newVariable = formatObjectToVariable(
              schema,
              dependency.properties,
              keyNest,
              i,
              variables,
              variable
            );
            variables.push(newVariable);
          }
        }

        i++;
      }

      if (isRFQ) {
        var cntDefault = variables.filter((x) => x.Default).length;
        //variables.splice(cntDefault - 1, 0, { changed by Amit

        if (
          schema.title != "Path Tracking" &&
          schema.title != "Eye Tracking" &&
          schema.title != "Smart Store"
        ) {
          variables.splice(cntDefault, 0, {
            label: "Tracking Project",
            id: "trackingProject",
            type: "single",
            Default: true,
            options: [
              {
                label: "Yes",
                value: "Yes",
              },
              {
                label: "No",
                value: "No",
              },
            ],
          });
        }
      }
    }
    return variables;
  }
  useEffect(() => {
    let vendor = 0;
    project?.CostingProfiles[costingProfileIndex]?.CountrySpecs?.forEach(c => {
      c.MethodologySpecs.forEach(m => {
        if (m?.InvitedVendors?.filter(a => !a.IsDeleted)?.length > 0 && m?.CostingType == 3) {
          vendor = vendor + 1;
        }
      })
    })
    if (vendor > 0) {
      setSummaryRender(false)
    }
    else {
      setSummaryRender(true)
    }

  }, [project]);
  useEffect(() => {
    if (
      project.CostingProfiles &&
      project.CostingProfiles.length > 0 &&
      project.CostingProfiles[costingProfileIndex].id &&
      project.CostingProfiles[costingProfileIndex].CountrySpecs
    ) {
      let cloneProfile = formatingDataForForms();
      if (
        !Array.isArray(project.CostingProfiles[costingProfileIndex].Methodology)
      ) {
        updateCostingProfile({
          ...cloneProfile,
          StudyType: oppValue === "" ? (_.cloneDeep(
            mapperFunctions.GetValueLablesFromCSV(
              codeLabels.StudyTypeOptions,
              project.CostingProfiles[costingProfileIndex].StudyType
            )
          )) : null,
          Methodology: _.cloneDeep(
            mapperFunctions.GetValueLablesFromCSV(
              codeLabels.MethodologyOptions,
              project.CostingProfiles[costingProfileIndex].Methodology
            )
          ),
          SubMethodology: _.cloneDeep(
            mapperFunctions.GetValueLablesFromCSV(
              codeLabels.SubMethodologyOptions,
              project.CostingProfiles[costingProfileIndex].SubMethodology
            )
          ),
          IsMultiCountry:
            project.CostingProfiles[costingProfileIndex].IsMultiCountry,
          FieldingCountries: _.cloneDeep(
            mapperFunctions.GetValueLablesFromCSV(
              codeLabels.FieldingCountriesOptions,
              project.CostingProfiles[costingProfileIndex].FieldingCountries
            )
          ),
        });
        var costingprofiles = _.cloneDeep(project.CostingProfiles);

        costingprofiles[costingProfileIndex] = cloneProfile;
        updateProject({
          CostingProfiles: costingprofiles,
        });
      }
    }
  }, [currentCostingProfile.ProfileLoaded]);

  useEffect(() => {
    if (vendorAndList.length > 0) {
      setvendor(
        vendorAndList
          .filter((x) => x.Group == "Vendors")[0]
        ["List"].map((x) => ({
          label: x.label,
          value: x.value,
          VendorId: x.id,
          Methodology: x.SubMethodologies,
          VendorType: x.VendorTypes,
          Countries: x.countries
            ?.filter((c) => c.Blacklisted == 0)
            ?.map((d) => d.Country),
        }))
      );
    }
  }, [vendorAndList]);

  useEffect(() => {
    if (
      !project?.CloseRound &&
      project.CostingProfiles[costingProfileIndex]?.CountrySpecs.map(
        (cs) =>
          cs.MethodologySpecs.filter((y, index) => y.CostingType == 3).length
      ).reduce((r, e) => r + e) > 0
    ) {
      //Finding First Country for Vendor Bidding
      var indexfc = project.CostingProfiles[
        costingProfileIndex
      ].CountrySpecs.filter(
        (cs) =>
          cs.MethodologySpecs.filter(
            (sm) => sm.CostingType == 3 && !sm.NotApplicable
          ).length > 0
      )[0].id;
      setActiveTabFC(
        project.CostingProfiles[costingProfileIndex].CountrySpecs.findIndex(
          (m) => m.id == indexfc
        )
      );

      //Finding First Methodology for Vendor Bidding
      var indexsm = project.CostingProfiles[
        costingProfileIndex
      ].CountrySpecs.filter((m) => m.id == indexfc)[0].MethodologySpecs.filter(
        (y, index) => y.CostingType == 3 && !y.NotApplicable
      )[0]?.id;
      setActiveTabSM(
        project.CostingProfiles[costingProfileIndex].CountrySpecs.filter(
          (m) => m.id == indexfc
        )[0].MethodologySpecs.findIndex((m) => m.id == indexsm)
      );
      setActiveTabMethodology(indexsm);
    }
  }, [project.CostingProfiles]);

  function SetDate(current1) {
    var d = current1.getDate();
    var day = d < 10 ? "0" + d : d;
    var m = current1.getMonth() + 1;
    var month = m < 10 ? "0" + m : m;
    var y = current1.getFullYear();
    var h = current1.getHours();
    var hour = h < 10 ? "0" + h : h;
    var minu = current1.getMinutes();
    var minute = minu < 10 ? "0" + minu : minu;

    var GetDate = y + "-" + month + "-" + day + "T" + hour + ":" + minute;
    return GetDate;
  }

  function fetchBack() {
    if (fromDashboard) {
      history.push("/vendordashboard");
    } else {
      let condition = false;
      const tempcostdata = _.cloneDeep(
        project.CostingProfiles[costingProfileIndex].CountrySpecs
      );
      tempcostdata.forEach((s, i) =>
        s.MethodologySpecs.forEach((m, im) => {
          if (m.CostingType == 3 && m.Vendors?.length > 0) {
            m.Vendors?.forEach((v, iv) => {
              if (!v.save) {
                condition = true;
              }
            });
          }
        })
      );
      if (!condition) {
        setIsSummary(!isSummary);
      } else {
        toastr.error("Please save the data before Going to next Page");
      }
    }
  }

  function navigateToDashboard() {
    if (navigation) history.push("/costing");
    else history.push("/vendordashboard");
  }

  function formatingDataForForms() {
    let typeofquant = codeLabels?.SubMethodologyOptions?.filter(
      (sm) => sm.ResearchType == "Quantitative"
    )?.map((c) => c.Code);
    let typeofqual = codeLabels?.SubMethodologyOptions?.filter(
      (sm) => sm.ResearchType == "Qualitative"
    )?.map((c) => c.Code);
    let cloneProfile = _.cloneDeep(currentCostingProfile);
    cloneProfile.CountrySpecs?.forEach((cs, indexCS) => {
      let commCountryCode = project.CommissioningCountry;
      cs["label"] = cs.CountryName;
      cs.MethodologySpecs?.forEach((ms, indexMS) => {
        if (typeofquant.find((tp) => tp == ms.Code)) ms["Quant"] = true;
        else if (typeofqual.find((tp) => tp == ms.Code)) ms["Qual"] = true;

        ms["label"] = ms.Label;
        let tempVariables = mapperFunctions.getSchemaWithDataJSON(
          ms.RFQSchema,
          ms.Code,
          ms.RFQData,
          true,
          commCountryCode,
          pacificMarkets,
          project.CostingTemplateVersion,
          currentCostingProfile,
          oppValue
        );
        if (!ms.RFQData) {
          tempVariables.forEach((x) => {
            if (x.dependentOn) x.disabled = true;
          });
        }

        let tempOpResourceVariables = mapperFunctions.getSchemaWithDataJSON(
          ms.SetupSchema,
          ms.Code,
          ms.SetupData,
          false,
          commCountryCode,
          pacificMarkets,
          project.CostingTemplateVersion,
          currentCostingProfile,
          oppValue
        );
        if (!ms.RFQData) {
          tempOpResourceVariables.forEach((x) => {
            if (
              x.dependentOn ||
              x.label == "Coding Required" ||
              x.label == "Verbatim Coding Required"
            ) {
              x.disabled = true;
            }
          });
        }

        let tempCostingOption = [];
        if (ms.CostingOptions?.length == 0)
          tempCostingOption = [
            {
              label: "Default Option",
              value: "Default Option",
              isSelected: "true",
              Variables: _.cloneDeep(tempVariables.filter((x) => x.Default)),
            },
          ];
        else {
          ms.CostingOptions.forEach((co, coi) => {
            let _tempCostingOption = {};
            Object.keys(co).map((optionKey) => {
              _tempCostingOption[optionKey] = co[optionKey];
            });
            _tempCostingOption.label = co.Name;
            _tempCostingOption.value = co.Name;
            _tempCostingOption.id = co.id;
            _tempCostingOption.isSelected = co.Selected;
            _tempCostingOption.Variables = _.cloneDeep(co.CostingOptionSchema);
            tempCostingOption.push(_tempCostingOption);
          });
        }
        ms["CostingOptions"] = _.cloneDeep(tempCostingOption);
      });
    });
    cloneProfile["updateCost"] = false;
    return cloneProfile;
  }

  function closebidding(project, data) {
    let error = 0;

    project.CostingProfiles[costingProfileIndex].CountrySpecs.forEach(cs => {
      cs.MethodologySpecs.forEach(ms => {
        if (_.last(splitdata.filter(sd => sd.MethodologySpecId == ms.id))?.SampleDistributionMasters) {
          _.last(splitdata.filter(sd => sd.MethodologySpecId == ms.id)).SampleDistributionMasters.forEach(a => {
            if (a.RemainingSamples > 0) { error = error + 1 }
          })

        }
      }
      )
    })


    if (error == 0) {
      const newProposal = update(
        project.CostingProfiles[costingProfileIndex],
        data
      );
      project.CostingProfiles[costingProfileIndex] = newProposal;
      dispatch({
        type: currentProjectActions.UPDATE_NEW_PROJECT,
        newProject: project,
      });

      SaveBidding("close")
    }
    else
      toastr.error("SampleSize/Number of Groups is Remaining for Allocation!")
  }

  function saveasdraft(origin) {
    if (origin == "back") {
      fetchBack();
    }
    else {
      let vendor = false;
      const tempcostdata = _.cloneDeep(
        project.CostingProfiles[costingProfileIndex].CountrySpecs
      );
      let error = 0;
      tempcostdata.forEach((s, i) =>
        s.MethodologySpecs.forEach((m, im) => {
          if (m.CostingType == 3) {
            if (m.InvitedVendors.filter(a => !a.IsDeleted)?.length == 0) {
              vendor = true;
            }
            m.InvitedVendors?.filter(a => !a.IsDeleted && a.label != "TCS" && a.label != "Internal")?.forEach((v, iv) => {
              if (v.ServiceType != 1) {
                if ((v.VendorCosts.length > 0) && !(v.VendorCosts?.filter(x => x.RoundId).length > 0)) {
                  error = error + 1;
                }
              }
              v.VendorCosts.forEach(a => a.IsEditable = true)
            });
          }
        })
      );
      const newProposal = update(project.CostingProfiles[costingProfileIndex], {
        CountrySpecs: { $set: tempcostdata },
      });
      project.CostingProfiles[costingProfileIndex] = newProposal;
      dispatch({
        type: currentProjectActions.UPDATE_NEW_PROJECT,
        newProject: project,
      });
      if (error == 0) {

        //saving data before going to summary
        if (origin == "summary") {
          fetchBack();
          SaveBidding("Summary")
        }
        else {
          SaveBidding("open")
        }
      }
      else {
        toastr.error("Please select sample split for all the vendors")
      }
    }
  }
  function SaveBidding(status) {
    var data = {};
    data["VendorOPSMails"] = project.CostingProfiles[project.CostingProfiles.length - 1].VendorOPSMails ?
      project.CostingProfiles[project.CostingProfiles.length - 1].VendorOPSMails : userRecord.Email
    data["id"] = project.CostingProfiles[project.CostingProfiles.length - 1].id;
    data["BiddingStatus"] = status
    data["CountryName"] = project?.CommissioningCountry;
    data["IsSubmit"] = false;
    data["Message"] = (status == "close" ? "Bidding Closed" : "Bidding Saved");
    data["closeToastr"] = (status == "Summary" ? true : false);
    data["Error"] = false
    data["type"] = "OPS"
    data["Project"] = project
    data["VendorBidDeadLine"] = _.last(project.CostingProfiles).VendorBidDeadLine
    data["VenodrBidDeadLineStatus"] = _.last(project.CostingProfiles).VenodrBidDeadLineStatus
    data["CountrySpecs"] = project.CostingProfiles[project.CostingProfiles.length - 1].CountrySpecs
    dispatch(
      proposalActions.saveData(
        data,
        false,
        false,
        vendorreducer.biddingFiles
      )
    );
  }
  function Savebiddingrequest() {
    let error = 0;

    let invitedVendorsLength = 0
    if (project.CostingProfiles[costingProfileIndex].VendorBidDeadLine) {
      const tempcostdata = _.cloneDeep(
        project.CostingProfiles[costingProfileIndex].CountrySpecs
      );

      tempcostdata.forEach((s, i) =>
        s.MethodologySpecs.forEach((m, im) => {
          if (m.CostingType == 3) {
            invitedVendorsLength += m.InvitedVendors?.filter(a => !a.IsDeleted).length
            m.InvitedVendors?.filter(a => !a.IsDeleted && a.label != "TCS" && a.label != "Internal")?.forEach((v, iv) => {
              if (v.ServiceType != 1) {
                if ((v.VendorCosts.length > 0) && !(v.VendorCosts?.filter(x => x.RoundId).length > 0)) {
                  error = error + 1;
                }
              }
              v.VendorCosts.forEach(a => a.IsEditable = true)
              if (v.IsMailSent) {
                if (m.IsFileChanged || v.IsFileChanged) {
                  v["IsChanged"] = true
                  v["IsAcknowledged"] = false
                }
                else if (_.isEqual(v, cCProfile.CountrySpecs[i].MethodologySpecs[im].InvitedVendors[iv]) &&
                  _.isEqual(v.VendorCosts, cCProfile.CountrySpecs[i].MethodologySpecs[im].InvitedVendors[iv].VendorCosts) &&
                  _.isEqual(m.BiddingDetail, cCProfile.CountrySpecs[i].MethodologySpecs[im].BiddingDetail) &&
                  _.isEqual(project.CostingProfiles[costingProfileIndex].VendorBidDeadLine, cCProfile.VendorBidDeadLine)
                ) { v["IsChanged"] = false }
                else {
                  v["IsChanged"] = true
                  v["IsAcknowledged"] = false
                }
              }

            });
          }
        })
      );
      const newProposal = update(project.CostingProfiles[costingProfileIndex], {
        CountrySpecs: { $set: tempcostdata },
      });
      project.CostingProfiles[costingProfileIndex] = newProposal;
      // let tempProject=_.cloneDeep(project)
      // tempProject. VendorOPSMails=tempProject. VendorOPSMails?tempProject. VendorOPSMails:userRecord.Email
      dispatch({
        type: currentProjectActions.UPDATE_NEW_PROJECT,
        newProject: project,
      });

      if (error > 0 || invitedVendorsLength == 0) {
        invitedVendorsLength == 0 ? toastr.error("Please select atleast One Vendor") :
          toastr.error("Please select sample split for all the vendors")
      }
      else {
        setVendorEmail(true)
        console.log(true);
      }
    }
    else { toastr.error("Please Select the Deadline !") }
  }

  // function Savebiddingrequest() {
  //   var condition = false;
  //   var Count;
  //   var totallength = project.CostingProfiles[
  //     costingProfileIndex
  //   ].CountrySpecs.map((x) => x.MethodologySpecs.length).reduce(
  //     (a, b) => a + b
  //   );
  //   var totalvendorlength = project.CostingProfiles[
  //     costingProfileIndex
  //   ].CountrySpecs.flatMap((x) =>
  //     x.MethodologySpecs.flatMap((y) =>
  //       y?.Vendors ? (y.Vendors.length > 0 ? true : false) : false
  //     )
  //   ).filter((ele) => ele == true).length;
  //   var vendorselectedcolength = project.CostingProfiles[
  //     costingProfileIndex
  //   ].CountrySpecs.flatMap((x) =>
  //     x.MethodologySpecs.flatMap((y) =>
  //       y?.Vendors
  //         ? y.Vendors.length > 0
  //           ? y.Vendors.flatMap((m) => m.selectedCO.flatMap((s) => s.selected))
  //           : false
  //         : false
  //     )
  //   )
  //     .filter((ele) => ele == true)
  //     .filter((ele) => ele == true).length;
  //   project.CostingProfiles[costingProfileIndex].CountrySpecs.filter(
  //     (cs) => cs.MethodologySpecs.filter((ms) => ms.CostingType == 3).length > 0
  //   ).filter((cs) =>
  //     cs.MethodologySpecs.forEach((g) => {
  //       if (g.CostingType == 3) {
  //       }
  //     })
  //   );
  //   if (
  //     project?.CostingProfiles[costingProfileIndex]?.Deadlinestatus == "close"
  //   ) {
  //     setViewOnlyMode(true);
  //   }
  //   const tempcostdata = _.cloneDeep(
  //     project.CostingProfiles[costingProfileIndex].CountrySpecs
  //   );
  //   project.CostingProfiles[costingProfileIndex].CountrySpecs.forEach((s, i) =>
  //     s.MethodologySpecs.forEach((m, im) => {
  //       if (m.CostingType == 3 && m.Vendors?.length > 0) {
  //         if (
  //           m.Vendors.length > 0 &&
  //           m.SplitSample == 1 &&
  //           m.CostingType == 3
  //         ) {
  //           m.CostingOptions.map((Co) => {
  //             m?.SplitSampledata?.SplitsData.filter(
  //               (split) => split.coid == Co.id
  //             )?.map((cosplit) => {
  //               Count = m.Vendors.filter(
  //                 (ven) =>
  //                   ven?.CostingData?.filter(
  //                     (s) => s.soid == 14 && s.coid == Co.id
  //                   )[0]?.Selected?.value == cosplit.id
  //               );
  //               if (Count.length == 0) {
  //                 condition = true;
  //               }
  //             });
  //           });
  //         }
  //       }
  //     })
  //   );
  //   if (
  //     (project.CostingProfiles[costingProfileIndex]?.deadline == undefined &&
  //       (project.CostingProfiles[costingProfileIndex]?.Deadline == undefined ||
  //         project.CostingProfiles[costingProfileIndex]?.Deadline == "")) ||
  //     condition
  //   ) {
  //     !condition
  //       ? toastr.error("Please Select the Deadline")
  //       : toastr.error("Please Select the distribution for all Vendors");
  //   } else {
  //     tempcostdata.forEach((s, i) =>
  //       s.MethodologySpecs.forEach((m, im) => {
  //         if (m.CostingType == 3 && m.Vendors?.length > 0) {
  //           var sendmail = true;
  //           var oldval = cCProfile.CountrySpecs[i].MethodologySpecs[im];
  //           if (
  //             deadline ==
  //               project.CostingProfiles[costingProfileIndex]?.Deadline &&
  //             _.isEqual(oldval.selectedRFQ, m.selectedRFQ) &&
  //             _.isEqual(oldval.selectedSUD, m.selectedSUD) &&
  //             oldval.Comments == m.Comments &&
  //             oldval.OtherProjectSpecification == m.OtherProjectSpecification &&
  //             _.isEqual(oldval.QCValidationMode, m.QCValidationMode) &&
  //             _.isEqual(oldval.Currency, m.Currency) &&
  //             oldval.FullServiceRequired == m.FullServiceRequired &&
  //             oldval.SplitSample == m.SplitSample &&
  //             oldval.SplitSample == m.SplitSample &&
  //             //oldval.UploadOtherRequiredDocuments == m.UploadOtherRequiredDocuments
  //             _.isEqual(
  //               oldval.UploadOtherRequiredDocuments,
  //               m.UploadOtherRequiredDocuments
  //             )
  //           ) {
  //             sendmail = false;
  //           }

  //           m.Vendors?.forEach((v, iv) => {
  //             v.save = true;
  //             v.CostingData.forEach((h, ih) => {
  //               if (h.soid == 14) {
  //                 tempcostdata[i].MethodologySpecs[im].Vendors[iv].CostingData[
  //                   ih
  //                 ].enable = true;
  //               }
  //             });
  //             if (!sendmail) {
  //               if (
  //                 _.isEqual(
  //                   oldval?.Vendors?.find((a) => a.id == v.id),
  //                   v
  //                 )
  //               ) {
  //                 sendmail = false;
  //               } else {
  //                 sendmail = true;
  //               }
  //             }
  //             if (v.mail == 0) {
  //               v.mail = 1;
  //             } else if (sendmail) {
  //               v.mail = 2;
  //             } else {
  //               v.mail = 3;
  //             }
  //           });
  //         }
  //       })
  //     );
  //     const newProposal = update(project.CostingProfiles[costingProfileIndex], {
  //       CountrySpecs: { $set: tempcostdata },
  //     });
  //     project.CostingProfiles[costingProfileIndex] = newProposal;
  //     dispatch({
  //       type: currentProjectActions.UPDATE_NEW_PROJECT,
  //       newProject: project,
  //     });
  //     dispatch(
  //       proposalActions.saveProposal(
  //         project,
  //         false,
  //         false,
  //         vendorreducer.biddingFiles
  //       )
  //     );
  //     toastr.success("Sent Bidding Request");
  //     // setButtonCheck(true);
  //   }
  // }

  function updateProjectInfo(type, project, data) {
    let newProposal;
    switch (type) {
      case "deadline":
        newProposal = update(
          project.CostingProfiles[costingProfileIndex],
          data
        );
        project.CostingProfiles[costingProfileIndex] = newProposal;
        break;
      case "save-bidding":
        newProposal = update(project.CostingProfiles[costingProfileIndex], {
          CountrySpecs: data,
        });
        project.CostingProfiles[costingProfileIndex] = newProposal;
        break;
      default:
        break;
    }
    dispatch({
      type: currentProjectActions.UPDATE_NEW_PROJECT,
      newProject: project,
    });
  }

  function restricteddate(e, a) {
    if (a) e.target.value = a;
  }

  function adddeadline(e, type) {
    var selecteddate = e.target.value;
    let deadLine;
    if (type === "deadline") {
      if (CurrentDate <= selecteddate || e.target.value.length == 0) {
        deadLine = {
          VendorBidDeadLine: { $set: e.target.value },
        };
        updateProjectInfo("deadline", project, deadLine);
      }
    } else {
      setViewOnlyMode(true);
      deadLine = {
        VenodrBidDeadLineStatus: { $set: "close" },
        VendorBidDeadLine: { $set: CurrentDate },
      };
      closebidding(project, deadLine);
    }
  }

  function toggle(tab) {
    if (activeTabFC !== tab) {
      setActiveTabFC(tab);
      var indexsm = project.CostingProfiles[costingProfileIndex]?.CountrySpecs[
        tab
      ].MethodologySpecs.filter(
        (y, index) => y.CostingType == 3 && !y.NotApplicable
      )[0].id;
      setActiveTabSM(
        project.CostingProfiles[costingProfileIndex].CountrySpecs[
          tab
        ].MethodologySpecs.findIndex((m) => m.id == indexsm)
      );
    }
  }
  const updateProject = (newProject) =>
    dispatch({
      type: "UPDATE_NEW_PROJECT",
      newProject: newProject,
    });

  function toggleSM(tab) {
    if (activeTabSM !== tab) {
      setActiveTabSM(tab);
    }
    //populateSideBarLinks(profile.CountrySpecs[activeTabFC]?.MethodologySpecs[activeTabSM]?.Variables);
  }
  return (

    <Layout
      hideProfileName={true}
      projectStatusToDisplay={getLabel(
        "ProjectStatusOptions",
        project?.ProjectStatus
      )}
      avoidEdit={true}
    >
      {
        <>
          <Prompt
            when={shouldBlockNavigation}
            message="You have unsaved changes, are you sure you want to leave?"
          />

          <CostingTypeMapping
            setUpdateCost={setUpdateCost}
            setCostSelection={setCostSelection}
            setShowCostMethod={setShowCostMethod}
            pacificData={pacificData}
            showCostMethod={showCostMethod}
            isCostSelection={isCostSelection}
            updateCost={updateCost}
          ></CostingTypeMapping>

          {!isSummary && (
            <>
              {" "}
              <Container fluid className="p-0">
                {
                  <>
                    <Row>
                      <Modal
                        size="xs"
                        isOpen={vendorreducer.uploadStatus}
                        toggle={() => {
                          dispatch({ type: SET_UPLOAD_STATUS, payload: false });
                          dispatch({ type: SET_TOTAL_FILES, payload: 0 });
                          dispatch({ type: SET_UPLOADED_FILES, payload: 0 });
                        }}
                      >
                        {/* {vendorreducer.totalFiles!=vendorreducer.uploadedFiles  &&<Loader></Loader>} */}
                        <ModalBody>
                          <div className="text-center">
                            {vendorreducer.uploadedFiles} of{" "}
                            {vendorreducer.totalFiles} files are uploaded.
                          </div>
                          <hr></hr>
                          <Progress
                            max={vendorreducer.totalFiles}
                            value={vendorreducer.uploadedFiles}
                          />
                          {vendorreducer.uploadSummary.length ? (
                            vendorreducer.uploadSummary.map((vendor) => (
                              <b style={{ color: "green" }}>
                                <hr></hr>
                                {vendor.files} files are uploaded for{" "}
                                {vendor.vendorName
                                  ? vendor.vendorName
                                  : "Bidding"}
                                <br />
                              </b>
                            ))
                          ) : (
                            <b>
                              <hr></hr>Uploading Files . . .
                            </b>
                          )}
                        </ModalBody>
                      </Modal>
                      <Modal
                        size="xs"
                        isOpen={resetcostmodelState}
                        toggle={() =>
                          setresetcostModelState(!resetcostmodelState)
                        }
                      >
                        <ModalHeader>Update Costing Option</ModalHeader>
                        <ModalBody>
                          You may lost the saved date after reseting the Costing
                          type. Are You sure you want to reset Costing Type?
                        </ModalBody>
                        <ModalFooter>
                          <Button
                            onClick={() => {
                              setresetcostModelState(false);
                              dispatch(recordLoadStart());

                              var profileTemp = currentCostingProfile;
                              var profileTemp1 = {};
                              let currentCostingProfile1 = _.cloneDeep(
                                project.CostingProfiles[costingProfileIndex]
                              );
                              currentCostingProfile1.CountrySpecs.forEach(
                                (c, i) => {
                                  c.MethodologySpecs.forEach((m, mi) => {
                                    {
                                      currentCostingProfile1.CountrySpecs[
                                        i
                                      ].MethodologySpecs[mi].CostingType = 0;
                                    }
                                  });
                                }
                              );
                              dispatch(
                                currentCostingActions.saveCostingProfileDraft(
                                  currentCostingProfile1
                                )
                              );
                              const project1 = update(project, {
                                CostingProfiles: {
                                  [0]: {
                                    $set: currentCostingProfile1,
                                  },
                                },
                              });
                              axios
                                .put(
                                  "costingprofiles/" +
                                  currentCostingProfile1.id,
                                  currentCostingProfile1
                                )
                                .then((res) => {
                                  dispatch(
                                    currentCostingActions.updateProfile(
                                      currentCostingProfile1
                                    )
                                  );
                                  toastr.success(
                                    "Cost Type is Reset",
                                    res.data.message
                                  );
                                  dispatch(
                                    currentProjectActions.saveProject({
                                      ...project1,
                                      CostingType: 0,
                                      Mode: "",
                                    })
                                  );

                                  dispatch(
                                    setCostingStatus(
                                      {
                                        ...costingStatus,
                                        showSheetsCosts: false,
                                        showManualCostEntry: false,
                                        showCommercialCosts: false,
                                        showCostingInput: false,
                                      },
                                      history.push("/proposal-new")
                                    )
                                  );
                                });
                              dispatch(recordLoadEnd());
                            }}
                          >
                            Update Costing
                          </Button>
                          <Button
                            onClick={() =>
                              setresetcostModelState(!resetcostmodelState)
                            }
                          >
                            Cancel
                          </Button>
                        </ModalFooter>
                      </Modal>
                      <Col md={10}></Col>
                      <Col md={2}>
                        {
                          <Button
                            disabled={ViewOnlyMode}

                            onClick={() => {
                              // let clone = _.cloneDeep(currentCostingProfile);
                              // clone["updateCost"] = true;
                              // dispatch({
                              //   type: "UPDATE_NEW_COSTING",
                              //   currentCostingProfile: clone,
                              // });
                              setShowCostMethod(true);
                            }}
                            style={{ marginBottom: "10px" }}
                          >
                            Update Costing Type
                          </Button>
                        }
                      </Col>
                    </Row>
                    {
                      <Row>
                        <Col lg="12">
                          <Card>
                            <CardHeader>
                              <CardTitle tag="h5" className="my-2">
                                <Row>
                                  <Col md={11}>
                                    <Label>Project Details</Label>
                                  </Col>
                                  <Col md={1}>
                                    <FontAwesomeIcon
                                      id="collapseProjectDetails"
                                      icon={
                                        "#collapseProjectDetails"
                                          ? faChevronDown
                                          : faChevronUp
                                      }
                                      href="#collapseProjectDetails"
                                    />
                                  </Col>
                                </Row>
                              </CardTitle>
                            </CardHeader>
                            <UncontrolledCollapse
                              toggler="#collapseProjectDetails"
                              defaultOpen={true}
                            >
                              <CardBody>
                                {/* {JSON.stringify(project)} */}
                                <Form>
                                  <Row form>
                                    <Col md={3}>
                                      <FormGroup>
                                        <Label>Project ID</Label>
                                        <Input
                                          type="text"
                                          name="id"
                                          value={project.ProjectId}
                                          disabled
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                      <FormGroup>
                                        <Label>Single/Multiple Country</Label>
                                        <Input
                                          type="text"
                                          name="IsSingleCountry"
                                          value={
                                            project.CostingProfiles &&
                                              project.CostingProfiles.length >
                                              0 &&
                                              project?.CostingProfiles[
                                                costingProfileIndex
                                              ]?.IsMultiCountry == false
                                              ? "Single Country"
                                              : "Multi Country"
                                          }
                                          disabled
                                        />
                                      </FormGroup>
                                    </Col>

                                    <Col md={3}>
                                      <FormGroup>
                                        <Label>Commissioning Country</Label>
                                        <input
                                          type="text"
                                          className="form-control w-100"
                                          style={{ height: "2rem" }}
                                          name="CommissioningCountry"
                                          value={
                                            project.CommissioningCountry &&
                                              project.CommissioningCountry !=
                                              "" &&
                                              project.CostingProfiles &&
                                              project.CostingProfiles.length >
                                              0 &&
                                              project.CostingProfiles[
                                                costingProfileIndex
                                              ]?.ProfileSetting?.CurrenciesData &&
                                              project.CostingProfiles[
                                                costingProfileIndex
                                              ]?.ProfileSetting?.CurrenciesData
                                                .length > 0
                                              ? project.CostingProfiles[
                                                costingProfileIndex
                                              ]?.ProfileSetting?.CurrenciesData.filter(
                                                (comm) =>
                                                  comm.Code ==
                                                  project.CommissioningCountry
                                              )[0].Label
                                              : ""
                                          }
                                          disabled
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                      <FormGroup>
                                        <Label>
                                          Deadline For Submission (*){" "}
                                        </Label>
                                        <Input
                                          type="datetime-local"
                                          name="Deadline"
                                          defaultValue={
                                            project.CostingProfiles[
                                              costingProfileIndex
                                            ]?.VendorBidDeadLine
                                          }
                                          min={CurrentDate}
                                          onChange={(e) =>
                                            e.target.value >= CurrentDate
                                              ? adddeadline(e, "deadline")
                                              : restricteddate(
                                                e,
                                                project.VendorBidDeadLine
                                              )
                                          }
                                          disabled={ViewOnlyMode}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                      <Label>Background</Label>
                                      <FormGroup>
                                        <Input
                                          type="textarea"
                                          name="Background"
                                          value={
                                            project.ProjectBackground || ""
                                          }
                                          disabled
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                      <Label>Objectives</Label>
                                      <FormGroup>
                                        <Input
                                          type="textarea"
                                          name="Objectives"
                                          value={
                                            project.ResearchObjectives || ""
                                          }
                                          disabled
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md={2}>
                                      {/* {UpdateCloseBidding()} */}
                                      <Label></Label>
                                      {closeBidding && (
                                        <button
                                          className="btn bg-danger text-white "
                                          style={{ marginTop: "1.7rem" }}
                                          disabled={ViewOnlyMode}

                                          onClick={(e) => {
                                            e.preventDefault();
                                            adddeadline(e, "deadlinestatus");
                                          }}
                                        >
                                          Close Bidding
                                        </button>
                                      )}
                                    </Col>
                                  </Row>
                                </Form>
                              </CardBody>
                            </UncontrolledCollapse>
                          </Card>
                        </Col>
                      </Row>
                    }
                    <Row>
                      <Col lg="12">
                        {
                          <Card>
                            <CardHeader>
                              <CardTitle tag="h5" className="my-2">
                                <Row>
                                  <Col md={11}>
                                    <Label>Costing Details</Label>
                                  </Col>
                                  <Col md={1}>
                                    <FontAwesomeIcon
                                      id="collapseProjectDetails"
                                      icon={faChevronDown}
                                      href="#collapseProjectDetails"
                                    />
                                  </Col>
                                </Row>
                              </CardTitle>
                            </CardHeader>
                            <UncontrolledCollapse
                              toggler="#collapseProjectDetails"
                              defaultOpen={true}
                            >
                              <CardBody>
                                <Row>
                                  <Col
                                    md={12}
                                    className={"countryTabs tab-horizontal"}
                                  >
                                    <div className={"tab"}>
                                      <Nav tabs>
                                        {project.CostingProfiles.length > 0 &&
                                          _.last(project.CostingProfiles)
                                            .CountrySpecs &&
                                          _.last(
                                            project.CostingProfiles
                                          ).CountrySpecs.map(
                                            (fc, indxFC) =>
                                              fc.MethodologySpecs.filter(
                                                (sm) =>
                                                  sm.CostingType == 3 &&
                                                  !sm.NotApplicable
                                              ).length > 0 && (
                                                <NavItem key={fc.value}>
                                                  <NavLink
                                                    className={classnames({
                                                      active:
                                                        activeTabFC === indxFC,
                                                    })}
                                                    onClick={() => {
                                                      toggle(indxFC);
                                                    }}
                                                  >
                                                    {<b> {fc.CountryName}</b>}
                                                  </NavLink>
                                                </NavItem>
                                              )
                                          )}
                                      </Nav>
                                      <TabContent activeTab={activeTabFC}>
                                        <Row>
                                          <Col
                                            md={12}
                                            className={"methodologyTabs"}
                                          >
                                            <Nav tabs>
                                              {project.CostingProfiles.length >
                                                0 &&
                                                _.last(project.CostingProfiles)
                                                  .CountrySpecs &&
                                                _.last(
                                                  project.CostingProfiles
                                                ).CountrySpecs[
                                                  activeTabFC
                                                ].MethodologySpecs.map(
                                                  (sm, indxSM) =>
                                                    !sm.NotApplicable &&
                                                    sm.CostingType == 3 && (
                                                      <NavItem key={sm.value}>
                                                        <NavLink
                                                          className={classnames(
                                                            {
                                                              active:
                                                                activeTabSM ===
                                                                indxSM,
                                                            }
                                                          )}
                                                          onClick={() => {
                                                            toggleSM(indxSM);
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              display: "flex",
                                                              justifyContent:
                                                                "space-between",
                                                              alignItems:
                                                                "center",
                                                            }}
                                                          >
                                                            <div>
                                                              {sm.NotApplicable}
                                                              <b>{sm.Label}</b>
                                                            </div>
                                                          </div>
                                                        </NavLink>
                                                      </NavItem>
                                                    )
                                                )}
                                            </Nav>
                                          </Col>
                                        </Row>

                                        <TabContent
                                          activeTab={activeTabSM}
                                          style={{ padding: "0.25" }}
                                        >
                                          {project.CostingProfiles.length > 0 &&
                                            _.last(project.CostingProfiles)
                                              .CountrySpecs &&
                                            _.last(
                                              project.CostingProfiles
                                            ).CountrySpecs[
                                              activeTabFC
                                            ].MethodologySpecs.map(
                                              (sm, indxSM) =>
                                                !sm.NotApplicable &&
                                                activeTabSM === indxSM && (
                                                  <>
                                                    <TabPane
                                                      tabId={indxSM}
                                                      key={sm.value}
                                                    >

                                                      {sm.CostingOptions &&
                                                        sm.CostingType == 3 && (
                                                          <div style={{ textAlign: "right" }}>
                                                            <span className="greendot"></span>
                                                            <span>{" "}In-Scope Costing Option{" "}</span>
                                                            <span className="reddot"></span>
                                                            <span>{" "}Out-of-Scope Costing Option</span>
                                                          </div>
                                                        )
                                                      }

                                                      {sm.CostingOptions &&
                                                        sm.CostingType == 3 && (
                                                          <Card
                                                            style={{
                                                              overflow:
                                                                "scroll",
                                                            }}
                                                          >
                                                            <CardBody>
                                                              <Table
                                                                className="table table-stripped"
                                                                size="sm"
                                                                bordered
                                                                style={{
                                                                  backgroundColor:
                                                                    "white",
                                                                  maxWidth:
                                                                    "100%",
                                                                  width: "auto",
                                                                  overflow:
                                                                    "scroll",
                                                                }}
                                                              >
                                                                <tr>
                                                                  <th
                                                                    style={{
                                                                      display:
                                                                        "flex",
                                                                      justifyContent:
                                                                        "space-between",
                                                                      alignItems:
                                                                        "center",
                                                                      minWidth:
                                                                        "250px",
                                                                      maxWidth:
                                                                        "250px",
                                                                    }}
                                                                  >
                                                                    Costing
                                                                    Parameter
                                                                  </th>
                                                                  {sm.CostingOptions.map(
                                                                    // (header) => header.Selected==true &&(
                                                                    (header) =>
                                                                    (
                                                                      header.Selected ?
                                                                        <th style={{ backgroundColor: "#00F000" }}>
                                                                          {header.Name}
                                                                        </th>
                                                                        : <th style={{ backgroundColor: "#FF0000" }}>
                                                                          {header.Name}
                                                                        </th>
                                                                    )
                                                                  )}
                                                                </tr>
                                                                {sm?.CostingOptions &&
                                                                  sm
                                                                    ?.CostingOptions[0]
                                                                    ?.CostingOptionSchema &&
                                                                  sm?.CostingOptions[0]?.CostingOptionSchema?.map(
                                                                    (b) =>
                                                                      b ? (
                                                                        <tr>
                                                                          <td>
                                                                            {
                                                                              b.label
                                                                            }
                                                                          </td>
                                                                          {sm.CostingOptions?.map(
                                                                            //  ( header) => header.Selected==true &&(
                                                                            (
                                                                              header
                                                                            ) =>
                                                                            (
                                                                              <td>
                                                                                {Array.isArray(header?.CostingOptionData[b.id])
                                                                                  ? header?.CostingOptionData[b.id]?.map(a => a.label)?.toString()
                                                                                  : (typeof (header?.CostingOptionData[b.id]) == 'object') ? header?.CostingOptionData[b.id]?.label : header?.CostingOptionData[b.id]}
                                                                              </td>
                                                                            )
                                                                          )}
                                                                        </tr>
                                                                      ) : (
                                                                        ""
                                                                      )
                                                                  )}
                                                              </Table>
                                                            </CardBody>
                                                          </Card>
                                                        )}
                                                      {
                                                        <RFQTable
                                                          project={project}
                                                          sm={sm}
                                                          indexCountry={
                                                            activeTabFC
                                                          }
                                                          indexMethodology={
                                                            activeTabSM
                                                          }
                                                        />
                                                      }
                                                      {
                                                        <SUDTable
                                                          project={project}
                                                          sm={sm}
                                                          indexCountry={
                                                            activeTabFC
                                                          }
                                                          indexMethodology={
                                                            activeTabSM
                                                          }
                                                        />
                                                      }
                                                      {SampleUpdated &&
                                                        <BiddingDetails
                                                          Vendor={vendor}
                                                          indexCountry={
                                                            activeTabFC
                                                          }
                                                          setCCProfile={
                                                            setCCProfile
                                                          }
                                                          cCProfile={cCProfile}
                                                          sm={sm}
                                                          indexMethodology={
                                                            activeTabSM
                                                          }
                                                          SplitSampleInfo={list}
                                                        ></BiddingDetails>
                                                      }
                                                    </TabPane>
                                                  </>
                                                )
                                            )}
                                        </TabContent>
                                      </TabContent>
                                    </div>
                                  </Col>
                                </Row>
                              </CardBody>
                            </UncontrolledCollapse>
                          </Card>
                        }
                      </Col>
                    </Row>
                  </>
                }
              </Container>
              {MarketCopy && (<VbmCopyToallMarket
                MarketCopy={MarketCopy}
                setMarketCopy={setMarketCopy}
                Vendor={vendor}
              />)}

              {
                <Col className="justify-content-end">
                  {project?.CostingProfiles[costingProfileIndex]?.CountrySpecs?.filter(
                    (fc) => fc.MethodologySpecs.filter(
                      (sm) => sm.CostingType == 3 && !sm.NotApplicable
                    ).length > 0).length > 1 &&
                    <Button
                      className="vbm-copyAll vendor-update-btn"
                      color="primary"
                      disabled={ViewOnlyMode}
                      onClick={() => setMarketCopy(true)}
                    >
                      <FontAwesomeIcon
                        className="icon-vendorbinding"
                        icon={faCopy}
                        fixedWidth
                      />
                      <span className="copyText">
                        Copy to all Markets
                      </span>
                      {props.saving && (
                        <FontAwesomeIcon
                          className="saving-btn"
                          icon={faCopy}
                          fixedWidth
                        />
                      )}
                    </Button>}
                  <Button
                    className="Submit-vendor vendor-update-btn"
                    color="primary"
                    onClick={Savebiddingrequest}
                    disabled={ViewOnlyMode}
                  >
                    <FontAwesomeIcon
                      className="icon-vendorbinding"
                      icon={faCheck}
                      fixedWidth
                    />
                    Submit
                    {props.saving && (
                      <FontAwesomeIcon
                        className="saving-btn"
                        icon={faSpinner}
                        fixedWidth
                      />
                    )}
                  </Button>
                  <Button
                    className="copy-to-all-market vendor-update-btn"
                    // style={{  marginTop: "10px" }}
                    color="primary"
                    onClick={() => navigateToDashboard()}
                  >
                    <FontAwesomeIcon
                      className="icon-vendorbinding"
                      icon={faBackward}
                      fixedWidth
                    />
                    Back
                  </Button>
                  <Button
                    onClick={() => saveasdraft("saveasdraft")}
                    className="savedraft vendor-update-btn"
                    color="primary"
                    disabled={ViewOnlyMode}
                  >
                    <FontAwesomeIcon
                      className="icon-vendorbinding"
                      icon={faSave}
                      fixedWidth
                    />
                    Save as Draft
                  </Button>
                </Col>
              }
            </>
          )}
          {openVendorEmail && (
            //todo:manage sample split 
            <VendorsEmailManagement
              EmailManagement={openVendorEmail}
              setEmailManagement={setVendorEmail}
              // project={project}
              cCProfile={cCProfile}
              splitdata={splitdata}
              isSummary={isSummary}
            />
          )
          }

          {isSummary && (
            <ProposalBiddingSelectVendorsUpdated
              fromDashboard={fromDashboard}
              setoncostview={setoncostview}
              oncostview={oncostview}
              setsaveselectedrows={setsaveselectedrows}
              saveselectedrows={saveselectedrows}
              id={props.projectId}
              project={project}
              currencyDropdown={currencyDropdown}
            ></ProposalBiddingSelectVendorsUpdated>
          )}

          {
            <Button
              color={isSummary ? "secondary" : "primary"}
              style={{ marginTop: "10px" }}
              onClick={() => saveasdraft(isSummary ? "back" : "summary")}
              className={isSummary ? "" : "validateandsave vendor-update-btn"}
              // style={{ marginLeft: "20px" }}
              //   disabled={
              //     !(
              //       project.CostingProfiles[
              //         costingProfileIndex
              //       ]?.CountrySpecs?.flatMap((x) =>
              //         x.MethodologySpecs?.flatMap((y) =>
              //           y?.Vendors ? (y.Vendors.length > 0 ? true : false) : false
              //         )
              //       )?.filter((ele) => ele == true).length > 0 &&
              //       project.CostingProfiles[costingProfileIndex]?.Deadline
              //     )
              //   }
              disabled={summaryRender}
            >
              {isSummary ? "" : <FontAwesomeIcon
                className="icon-vendorbinding"
                icon={faList}
                fixedWidth

              />}

              {isSummary ? "Back" : "Summary View"}
            </Button>
          }

          {saveselectedrows.length > 0 && isSummary && (
            <Button
              color="primary"
              onClick={() => setoncostview(!oncostview)}
              style={{ marginLeft: "20px" }}
            >
              Selected Vendor
            </Button>
          )}
        </>
      }
    </Layout >
  );
}
export default VendorBiddingFormUpdated;