import * as types from '../actions/timecostHoursActions'

const initialState={
    commercial:{},
    operations:{},
    commercialCost:{},
    operationCost:{}
}


const timecostHoursReducer=(state=initialState,action)=>{
    switch (action.type) {
        case types.SET_COMMERCIAL_HOURS:
            return {
                ...state,
                commercial:action.payload
            }
        case types.SET_OPERATIONS_HOURS:
            return {
                ...state,
                operations:action.payload
            }
        case types.SET_COMMERCIAL_COST:
            return {
                ...state,
                commercialCost:action.payload
            }
        case types.SET_OPERATIONS_COST:
            return {
                ...state,
                operationCost:action.payload
            }
        default:
            return state;
    }
}
export default timecostHoursReducer;