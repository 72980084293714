import React, { useState, useEffect } from "react";
import { ModalGeneric } from "./ModalGeneric";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "reactstrap";
import * as currentCostingActions from "../../redux/actions/currentCostingActions";
import * as currentProjectActions from "../../redux/actions/currentProjectActions";
import axios from "../../axios-interceptor";
import _ from "lodash";
import { toastr } from "react-redux-toastr";
import { getSubMethodologyAttribute } from "../../utils/codeLabels";
import Select from "react-select";
import * as offlineReasons from "./json/offlineReasons.json";
import ModalPostCommission from "./ModalPostCommission";
import { useHistory } from "react-router-dom";


const ModalCommissioning = ({ isOpen, toggle, setIsOpen, setCommissionOpen=()=>{} }) => {
  const dispatch = useDispatch();
  const app = useSelector(({ app }) => app);
  const history = useHistory()
  const profile = useSelector(
    ({ currentCosting }) => currentCosting.currentCostingProfile
  );
  const project = useSelector(
    ({ currentProject }) => currentProject.newProject
  );

  const currencies = useSelector(({ currentCosting }) =>
  currentCosting.currentCostingProfile.ProfileSetting &&
    currentCosting.currentCostingProfile.ProfileSetting.CurrenciesData
    ? currentCosting.currentCostingProfile.ProfileSetting.CurrenciesData
    : []
);
  const codeLabels = useSelector(({ codeLabels }) => codeLabels);
  const header = <span className="commissionmodal m-0">Warning!</span>;
  let comment = React.createRef();
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [otherReason, setOtherReason] = useState("");
  const [shopperMethodologies] = useState(["SM000024", "SM000026", "SM000029"]);

  let profileSubMethodologiesDetails = [];
  let isNotOnline = false;
  if (
    profile &&
    profile.SubMethodology &&
    codeLabels &&
    codeLabels.SubMethodologyOptions
  ) {
    let stype = typeof profile.SubMethodology;
    let profileSubMethodologies = [];
    if (stype === "string") {
      profileSubMethodologies = profile.SubMethodology.split(",");
    } else {
      profileSubMethodologies = profile.SubMethodology.map((s) => s.value);
    }
    profileSubMethodologies.forEach((method) => {
      let i = codeLabels.SubMethodologyOptions.findIndex(
        (a) => a.Code === method
      );
      if (i >= 0) {
        profileSubMethodologiesDetails.push(
          codeLabels.SubMethodologyOptions[i]
        );
      }
    });
    profileSubMethodologiesDetails = profileSubMethodologiesDetails.filter(a => {
      if (a.FieldType !== "Online") {
        return profile.CountrySpecs?.some(cs => {
          let ms = cs.MethodologySpecs.find(i => i.Code === a.Code);
          return ms?.NotApplicable !== true
        })
      }
      return true
    })
    if (
      profileSubMethodologiesDetails.find((a) => a.FieldType !== "Online") &&
      profileSubMethodologiesDetails.find(
        (a) => !_.includes(shopperMethodologies, a.Code)
      )
    ) {
      isNotOnline = true;
    }
  }
  const doCommissioning = () => {

    if (isNotOnline) {
      if (selectedReasons.length > 0) {
        if (
          selectedReasons.find((s) => s.value === "Others") &&
          (otherReason === null || otherReason.trim() === "")
        )
          return false;
        dispatch(
          currentCostingActions.setReasonForOfflineProposal(
            selectedReasons,
            otherReason
          )
        );
      } else {
        return false;
      }
    }
    toggle();
    if (!profile.IsImportedProfile){
    dispatch(
      currentCostingActions.saveCostingProfile({
        ...profile,
        ProfileSetting: {
          ...profile.ProfileSetting,
          CurrenciesData: currencies,
        },
      },
      ()=>{
        if(profile.ProductTableData && profile.ProductTableData != "null")
        history.push(`/newschedule/${profile.id}`);
        else
        history.push(`/newscheduleewn/${profile.id}`);


      })
    )}
 

    

    // dispatch(
    //   currentCostingActions.commissionCosting(() => {
    //     //console.log("callback called");
    //     let waveSpecId = profile.WaveSpecs ? _.head(profile.WaveSpecs)?.id : 0;
    //     if (waveSpecId && profile?.Project?.BusinessUnit == "CI")
    //       axios.post(`/utils/mail/${waveSpecId}/rvn/all`).then((res) => {
    //         dispatch({
    //           type: currentProjectActions.UPDATE_NEW_PROJECT,
    //           newProject: { ...project, ProjectStatus: "3" },
    //         });
    //         debugger
    //         toastr.success(
    //           "Mail Sent Successfully",
    //           "Mail Sent to Revenue Controller"
    //         );
    //         setCommissionOpen(pre => !pre)
    //       }).catch((error) => {
    //         toastr.error(
    //           "Mail Sending failed",
    //           "Mail Sending to Revenue Controller failed"
    //         );
    //       });
    //       setIsOpen({ ModalPostCommission: isOpen.ModalCommissioning });
    //     })
    // );
  };

  useEffect(() => {
    if (profile) {
      setSelectedReasons(
        profile.ReasonForOfflineProposal ?
          typeof profile.ReasonForOfflineProposal == "string" ? JSON.parse(profile.ReasonForOfflineProposal) : profile.ReasonForOfflineProposal : []
      );
      setOtherReason(
        profile.ReasonForOfflineProposalOther
          ? profile.ReasonForOfflineProposalOther
          : null
      );
    }
  }, []);

  const body = (
    <>
      <p>
        <h4>
          <strong>You are about to commission this project. </strong>
        </h4>
      </p>
      <p>
        <strong>Please note the following before you continue:</strong>
      </p>
      <p>Only one costing profile can be commissioned per project.</p>
      <p>
        Once the costing profile is commissioned, the proposal will be finalized
        and all costing profiles associated to this proposal will be locked.
      </p>
      <p>
        Any changes to the costing profile will require decommissioning of the
        costing before any alterations can be made again. Original will remain
        locked and an editable duplicate will be automatically created after
        providing justification for decommissioning.
      </p>
      <p>
        <em>
          Please ensure all approvals are completed on your final price, all
          external costs are entered, all feasibility checks on latest
          specifications have been completed before continuing.
        </em>
      </p>
      {isNotOnline && (
        <p>
          Reason for offline proposal? *
          <Select
            onChange={(e) => setSelectedReasons(e ? e : [])}
            value={selectedReasons}
            className="react-select-container"
            classNamePrefix="react-select"
            options={offlineReasons.reasons?.map((val) => {
              return { label: val, value: val };
            })}
            isMulti
          />
          {selectedReasons.length === 0 && (
            <p className="error small d-block">This is a required field.</p>
          )}
          <br />
          <textarea
            className="form-control"
            placeholder={"Please enter other reason if any"}
            onChange={(e) => setOtherReason(e.target.value)}
            ref={(ref) => (comment = ref)}
            value={otherReason}
          ></textarea>
          {selectedReasons.length > 0 &&
            selectedReasons.find((s) => s.value === "Others") &&
            (otherReason === null || otherReason.trim() === "") && (
              <p className="error small d-block">This is a required field.</p>
            )}
        </p>
      )}
      <p>
        <strong>Are you sure you want to commission this project now?</strong>
      </p>
    </>
  );
  const footer = (
    <>
      <Button
        color="primary"
        className="mr-2"
        onClick={doCommissioning}
        disabled={app.recordloading}
      >
        Go to Schedule
      </Button>
      <Button color="secondary" disabled={app.recordloading} onClick={toggle}>
        Cancel
      </Button>
    </>
  );

  return (
    <>
    <ModalPostCommission
              isOpen={isOpen}
              toggle={() => {
                setIsOpen({
                  ...isOpen,
                  ModalPostCommission: isOpen.ModalCommissioning,
                });
              }}
            />
    <ModalGeneric
      isOpen={isOpen.ModalCommissioning}
      toggle={toggle}
      header={header}
      body={body}
      footer={footer}
    />
    </>
  );
};

export default ModalCommissioning;
