
export const FILTER_CRITERIA = "FILTER_CRITERIA";
export const FILTER_CRITERIA_DONE = "FILTER_CRITERIA_DONE";

const initialState = {   
    filterErr: null
}

export default function filterCriteriaData(state = initialState, action)
{
    switch (action.type)
    {
        case FILTER_CRITERIA:
            return {
                ...state,
            }

        case FILTER_CRITERIA_DONE:
            {      
                if (action.err) {
                    return {
                        ...state,
                        filterErr: true,
                    }

                }         
                return {
                    ...state,
                    filterErr: false,
                }
            }

        default: return state
    }

}