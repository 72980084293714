import React from "react";

function Calendar() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      viewBox="0 0 23 23"
    >
      <defs>
        <filter id="filter-1">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 1.000000 0"
          ></feColorMatrix>
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g filter="url(#filter-1)">
          <path
            fill="#000"
            d="M20.25 1.5H18V0h-1.5v1.5H6V0H4.5v1.5H2.25A2.25 2.25 0 000 3.75v16.5a2.25 2.25 0 002.25 2.25h18a2.25 2.25 0 002.25-2.25V3.75a2.25 2.25 0 00-2.25-2.25zm0 18.75h-18V6h18v14.25zM6 10.5H4.5V12H6v-1.5zm0 3H4.5V15H6v-1.5zm0 3H4.5V18H6v-1.5zm3-9H7.5V9H9V7.5zm0 3H7.5V12H9v-1.5zm0 3H7.5V15H9v-1.5zm0 3H7.5V18H9v-1.5zm3-9h-1.5V9H12V7.5zm0 3h-1.5V12H12v-1.5zm0 3h-1.5V15H12v-1.5zm0 3h-1.5V18H12v-1.5zm3 0h-1.5V18H15v-1.5zm0-9h-1.5V9H15V7.5zm0 3h-1.5V12H15v-1.5zm0 3h-1.5V15H15v-1.5zm3-6h-1.5V9H18V7.5zm0 3h-1.5V12H18v-1.5zm0 3h-1.5V15H18v-1.5z"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default Calendar;