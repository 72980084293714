import React, { useState, useEffect } from 'react'
import {
    Col, Row, Nav, NavItem, NavLink, TabContent, TabPane,
    Table,
} from "reactstrap";

import classnames from "classnames";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";


const BreakDownView = () => {
    const project = useSelector(
        ({ currentProject }) => currentProject.newProject
      );
    const currentCostingProfile = useSelector(
        ({ currentCosting }) => currentCosting.currentCostingProfile
    );
    const [profile] = useState(currentCostingProfile);
    const [wavesData, setWavesData] = useState(currentCostingProfile.WaveSpecs)
    const [activeTabFC, setActiveTabFC] = useState();
    const [activeTabSM, setActiveTabSM] = useState();
    const [waveBreakDown, setWaveBreakDown] = useState({})
    const [currentWaveSpec, setCurrentWaveSpec] = useState({});
    const [showManualCostEntry, setShowManualCostEntry] = useState(false);
    const [showSheetsCosts, setShowSheetsCosts] = useState(false);
    const [showCostMethod, setShowCostMethod] = useState(false);
    const dispatch = useDispatch();
    const [modifiedMeths, setModifiedMeths] = useState([])
    const [activeWave, setActiveWave] = useState("1");
    const [toggle, setToggle] = useState({
        costingOption: false
    });
    const codeLabels = useSelector(({ codeLabels }) => codeLabels);
    const app = useSelector(({ app }) => app);
    useEffect(() => {
        if (currentCostingProfile &&
            wavesData
            && wavesData.length
            && !Object.keys(waveBreakDown).length
        ) {

            let countryData = _.head(profile.CountrySpecs);

            let mData = countryData.MethodologySpecs?.filter(
				ms => !ms.NotApplicable &&
				(ms.CostingOptions.some(co => co.CoSelectedForSummary==true ||( co.CoSelectedForSummary==null && co.Selected))));
			let methodologyID = _.head(mData)?.id

            setActiveTabFC(countryData.id);
            setActiveTabSM(methodologyID);
            let _waveBreakdownMain = wavesData.filter(x => x.MethodologySpecId === methodologyID)
            let _waveBreakdown = _.groupBy(_waveBreakdownMain, m => m.WaveNumber)
            setModifiedMeths((_waveBreakdownMain))

            Object.keys(_waveBreakdown).map(w => {
                _waveBreakdown[w] = _.uniqBy(_waveBreakdown[w], t => t.MethodologyLabel)
            });
            setWavesData(wavesData);
            setWaveBreakDown(_waveBreakdown)
            let _currentwaveset = _.head(_waveBreakdown[activeWave])
            setCurrentWaveSpec(_currentwaveset);
        }
    }, [currentCostingProfile])

    const [costingOptionCostSet, setCostingOptionCostSet] = useState({})
    useEffect(() => {

        let _costingOptionCostSet = {}

        if (!Object.keys(costingOptionCostSet).length) {
            currentCostingProfile.CountrySpecs.map(cs => {
                cs.MethodologySpecs.filter(ms => !ms.NotApplicable).map(ms => {
                    if (!_costingOptionCostSet[ms.Code]) {
                        _costingOptionCostSet[ms.Code] = {}
                        let costSchema  = currentCostingProfile?.Project?.PriceChange == "1"? ms.CostsSchema.filter(section=>!(section.title == "FIELD TIME COSTS" || section.title =="SET-UP AND DELIVERY TIME COSTS")):ms.CostsSchema
                            costSchema?.map(cssch => {
                            Object.keys(cssch.properties).map(_prop => {
                                _costingOptionCostSet[ms.Code][_prop] = cssch.properties[_prop]
                            })
                        })
                    }
                })
            })
        }
        setCostingOptionCostSet(_costingOptionCostSet)
    }, [currentCostingProfile]);
    const currencies = useSelector(({ currentCosting }) =>
        currentCosting.currentCostingProfile.ProfileSetting &&
            currentCosting.currentCostingProfile.ProfileSetting.CurrenciesData
            ? currentCosting.currentCostingProfile.ProfileSetting.CurrenciesData
            : []
    );
    const [currentCurrency, setCurrentCurrency] = useState({});
    useEffect(() => {
        if (profile && profile.CostInputCurrency) {
            let values = profile.CostInputCurrency.split("-");
            let currencyUnit = _.last(values);
            let countryCode = _.head(values);
            if (currencies) {
                setCurrentCurrency(
                    _.head(
                        currencies.filter(
                            (cur) =>
                                cur.Code == countryCode && cur.CurrencyUnit == currencyUnit
                        )
                    )
                );
            }
        }
    }, [profile, currencies]);
    const getCurrentCurrency = (actualvalue, currencyvalue, onlyCurrency) => {
        if (
            currencies &&
            currencies.length &&
            currentCurrency &&
            currentCurrency.ConversionRateToLocal
        ) {
            if (actualvalue) {
                let finalVal = _.round(
                    actualvalue * currentCurrency.ConversionRateToLocal,
                    2
                );
                return `${finalVal} ${currentCurrency.CurrencyUnit}`;
            } else return `0 ${currentCurrency.CurrencyUnit}`;
        }
    };
    const toggleFC = (tab) => {
        if (activeTabFC !== tab) {
            setActiveTabFC(tab);
            let cData = profile.CountrySpecs.filter(x => x.id === tab);
            let methData = _.head(cData);
            let mData = methData.MethodologySpecs?.filter(
				ms => !ms.NotApplicable &&
				(ms.CostingOptions.some(co => co.CoSelectedForSummary==true ||( co.CoSelectedForSummary==null && co.Selected))
				 ));
			let methodologyID = _.head(mData)?.id
            setActiveTabSM(methodologyID)
            onMethodologyChange(methodologyID)
        }
    };

    const toggleSM = (tab) => {
        if (activeTabSM !== tab) {
            setActiveTabSM(tab);
            onMethodologyChange(tab)
        }
    };

    const getLabelForCode = (key) => {
        let costLabel = key;
        codeLabels['CostLabelsOptions'].forEach(codeLabel => {
            if (codeLabel.Code == key) {
                costLabel = codeLabel.Label;
            }

        });
        return costLabel;
    }

    const onMethodologyChange = (methID) => {
        let _waveBreakdownMain = wavesData?.filter(x => x.MethodologySpecId === methID)
        let _waveBreakdown = _.groupBy(_waveBreakdownMain, m => m.WaveNumber)
        setWaveBreakDown(_waveBreakdown);
        setModifiedMeths((_waveBreakdownMain))
        let data = _.head(wavesData.filter(cws => cws.MethodologySpecId == methID))
        setCurrentWaveSpec(data);
    }
    return (
        <Row>
            <Col md={12} className={'countryTabs tab-vertical'}>

                <div className={"tab mt-5"}>
                    <Nav tabs>
                        {profile.CountrySpecs &&
                            profile.CountrySpecs.map((fc) => (
                                <NavItem key={fc.value}>
                                    <NavLink className={classnames({ active: activeTabFC === fc.id })}
                                        onClick={() => {
                                            toggleFC(fc.id);
                                        }}
                                    >
                                        <b>{fc.CountryName}</b>
                                    </NavLink>
                                </NavItem>
                            ))}
                    </Nav>
                    <TabContent activeTab={activeTabFC}>
                        {profile.CountrySpecs &&
                            profile.CountrySpecs.map((fc, indxFC) => (
                                activeTabFC === fc.id &&
                                <TabPane tabId={fc.id} key={fc.value}>
                                    <Row>
                                        <Col md={12} className={"methodologyTabs"}>
                                            <Nav tabs>
                                                {fc.MethodologySpecs &&
                                                    fc.MethodologySpecs.filter(x => !x.NotApplicable &&(x.CostingOptions.some(co => (currentCostingProfile.Project.CostingTemplateVersion == "v2.0"  &&
													( co.CoSelectedForSummary==true ||( co.CoSelectedForSummary==null && co.Selected))))
													)).map((sm, indxSM) =>
                                                    (
                                                        <NavItem key={sm.value}>
                                                            <NavLink className={classnames({ active: activeTabSM === sm.id })}
                                                                onClick={() => {
                                                                    toggleSM(sm.id);
                                                                }}
                                                            >
                                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                    <div>
                                                                        <b>{sm.Label}</b>
                                                                    </div>

                                                                </div>
                                                            </NavLink>
                                                        </NavItem>
                                                    ))}
                                            </Nav>

                                            <TabContent activeTab={activeTabSM} style={{ padding: "0.25" }}>
                                                <Row>
                                                    <Col md={12} className={"methodologyTabs"}>
                                                        <Table responsive hover striped bordered={true} size="sm">
                                                            <thead>
                                                                <tr>
                                                                    {fc.MethodologySpecs &&
                                                                        fc.MethodologySpecs.filter(x => x.id == activeTabSM ).map((sm, indxSM) =>
                                                                        (
                                                                            <>
                                                                                <Table responsive hover striped bordered={true} size="sm">
                                                                                    <thead>
                                                                                        <th>Component</th>
                                                                                        {sm.CostingOptions.filter(c=>(c.CoSelectedForSummary==true && c.Selected)).map((co) => (
                                                                                            <th>
                                                                                                {co.Name}
                                                                                            </th>
                                                                                        ))}
                                                                                    </thead>


                                                                                    <tbody>
                                                                                        {Object.keys(costingOptionCostSet[sm.Code]).map(field => {
                                                                                            return costingOptionCostSet[sm.Code][field].title ? <tr>
                                                                                                <td>{costingOptionCostSet[sm.Code][field].title}</td>
                                                                                                {sm.CostingOptions.filter(c=>(c.CoSelectedForSummary==true && c.Selected)).map((co) => (
                                                                                                    co.CostsData != null && co.CostsData[field] ? <td>
                                                                                                        {profile.IsMultiCountry ? getCurrentCurrency(
                                                                                                            co.CostsData[field],
                                                                                                            fc.CostInputCurrency
                                                                                                        ) : getCurrentCurrency(
                                                                                                            co.CostsData[field],
                                                                                                            profile.CostInputCurrency
                                                                                                        )}
                                                                                                    </td> : <td>-</td>))
                                                                                                }

                                                                                            </tr> : null
                                                                                        })}

                                                                                    </tbody>

                                                                                </Table>
                                                                            </>))}
                                                                </tr>
                                                            </thead>

                                                        </Table>


                                                    </Col>
                                                </Row>
                                            </TabContent>
                                            {/* <TabContent activeTab={activeTabSM} style={{ padding: "0.25" }}>
                                            {Object.keys(costingOptionCostSet).map((co, index) =>
                                    <TabPane tabId={index}>
                                        <Table responsive hover striped bordered={true} size="sm">
                                            <thead>
                                                <th>Component</th>                                              
                                                    {  Object.keys(costingOptionCostSet[co])
                                                        .map((cs,index) => <th>{Object.keys(costingOptionCostSet[co])[index]
                                                        }</th>)}                                             
                                            </thead>
                                            <tbody>
                                                {Object.keys(costingOptionCostSet[co]).map(field => {
                                                    return costingOptionCostSet[co][field].title ? <tr>
                                                        <td>{costingOptionCostSet[co][field].title}</td>
                                                        {Object.keys(costingOptionCostSet[co])
                                                            .map(cs => <td>{ costingOptionCostSet[co][cs][field]}</td>)}

                                                    </tr> : null
                                                })}

                                            </tbody>
                                        </Table>

                                    </TabPane>
                                )}

                                                {/* <Row>
                                                    <Col md={12} className={"methodologyTabs"}>
                                                    <div class="row">
                                                        <Table responsive hover striped bordered={true} size="sm">
                                                            <tbody>
                                                                <tr>
                                                                    {fc.MethodologySpecs &&
                                                                        fc.MethodologySpecs.filter(x => x.id == activeTabSM).map((sm, indxSM) =>
                                                                        (
                                                                            sm.CostingOptions &&
                                                                            sm.CostingOptions.map((co, coi) =>
                                                                                <th style={{fontWeight:'normal'}}>
                                                                                        <div style={{ flexDirection: 'column' }}>

                                                                                            <Table responsive hover striped bordered={true} size="sm">
                                                                                                <tbody>
                                                                                                    
                                                                                                    <th>Costing Option : {co.Name}</th>
                                                                                                    {
                                                                                                        Object.keys(co.CostsData).map((key, i) => (
                                                                                                            
                                                                                                                    <tr>
                                                                                                                        <td>
                                                                                                                                { getLabelForCode(key)}
                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                                {co.CostsData[key]}
                    
                                                                                                                        </td>
                                                                                                                    </tr>
                                                                                                        
                                                                                                        ))
                                                                                                    }                                                                                                    
                                                                                                </tbody>
                                                                                            </Table>



                                                                                        </div>
                                                                                </th>)))}
                                                                </tr>
                                                            </tbody>

                                                        </Table>
                                                        </div>


                                                    </Col>
                                                </Row> */}
                                        </Col>
                                    </Row>
                                </TabPane>
                            ))}
                    </TabContent>
                </div>
            </Col>
        </Row >
    )
}

export default BreakDownView
