import React, { useState, useEffect } from "react"
import axios from "../../../axios-interceptor";
import moment from 'moment';
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { connect, useSelector, useDispatch } from 'react-redux';
import * as vendorActions from '../../../redux/actions/vendor/Actions';
import CreatableSelect from "react-select/creatable";
import Spinner from "../../../components/Spinner";
import _, { cloneDeep } from "lodash";
import * as appActions from "../../../redux/actions/appActions";
import update from 'immutability-helper';
import { faChevronDown, faChevronUp, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as masterActions from '../../../redux/actions/masters/Actions';
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-input-2';
import 'react-phone-number-input/style.css';
import "react-phone-input-2/lib/style.css";
import { getLabel } from "../../../utils/codeLabels";
import { toastr } from "react-redux-toastr";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { faDownload, faPlusCircle, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import * as FetchVendorAndListActions from "../../../redux/actions/FetchVendorAndList/Actions";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Container,
    CustomInput,
    Form,
    FormGroup,
    FormText,
    Input,
    InputGroup,
    InputGroupAddon,
    Label,
    Row,
    Table

} from "reactstrap";
import { Object, Date } from "core-js";
import { FileText, Key, Map } from "react-feather";
import {
    AvForm,
    AvField,
    AvRadioGroup,
    AvInput,
    AvRadio
} from "availity-reactstrap-validation";
import Layout from "../../../layouts/Project";
import * as headerInfoActions from '../../../redux/actions/header-info.actions';
import { searchNumbers } from "libphonenumber-js/core";
import { setDocumentLoading } from "react-doc-viewer/build/state/actions";
import { func } from "prop-types";

const mapStateToProps = (state, ownProps) => {
    return {
        id: ownProps.match.params.Id,
        vendors: state.vendorReducer.items,
        loading: state.vendorReducer.Loading,
        error: state.vendorReducer.error,
        msg: state.vendorReducer.msg,
        redux_masters: state.redux_master.masters,
        saving: state.vendorReducer.saving
    }
}

var fil = {};
function VendorForm(props) {
    const baseurl = process.env.REACT_APP_API_BASE_URL
    const [Cascading, setCascading] = useState({ Countries: { Child: "Cities", Parent: "CountryId" } });
    const [Masters, setMaster] = useState([]);
    const [Vendor, setVendorData] = useState({
        "Countries": [],
        "Cities": []
    });
    const [uploaded, setUploaded] = useState({});
    const [oldVendor, setOldVendor] = useState({});
    const [ValidateMulti, setValidateMulti] = useState(false);
    const dispatch = useDispatch();
    const [value1, setValue1] = useState()
    const [value2, setValue2] = useState()
    const [value3, setValue3] = useState();
    const [value4, setValue4] = useState();
    const [Date, setDate] = useState();
    const [rowadddata, setRowAddData] = useState([]);
    const [countryrowdata, setCountryRowData] = useState([]);
    const [vendorstyle, setVendorstyle] = useState(false);
    const [onsubmit, setonsubmit] = useState(false);
    const [enteredsapid, setenteredsapid] = useState(0);
    const [vterrormsg, setvterrormsg] = useState(false);
    const [countryerrormsg, setcountryerrormsg] = useState(false);
    const [costingpername, setcostingpername] = useState(false);
    const [metherrormsg, setmetherrormsg] = useState(false);
    const [connumkeyconperson, setconnumkeyconperson] = useState(false);
    const [connumprimaryconperson, setconnumprimaryconperson] = useState(false);
    const [costingpersonconnum, setcostingpersonconnum] = useState(false);

    var [loading, setLoading] = useState(false);

    let history = useHistory();

    useEffect(() => {
        if (props.redux_masters.length == 0) {
            dispatch(masterActions.fetchAllMasters())
        }

    }, [props.redux_masters])

    useEffect(() => {
        var VendorDataArray = ["VendorTypes", "Countries", "CostingPersonEmailID", "SubMethodologies", "ContactNumberKeyContactPerson", "ContactNumberPrimaryContactPerson", "CostingPersonContactNumber"];
        VendorDataArray.map(x => {
            VendorUpdateStyle(x, x)
        })
    }, [])
    var filtered

    function CreateMarketRow(name) {
        rowadddata?.map(rd => onVendorCountry(rd, name))
        setCountryRowData([]);
    }
    function AddMail(name) {
        onVendorCountry([], name)
        setCountryRowData([]);
    }

    function DeleteMarketRow(id) {
        var CountryData = Vendor?.Countries?.filter(x => x.id != id)
        var CityData = Vendor?.Cities?.filter(x => x.CountryId != id)
        setVendorData({
            ...Vendor, ["Countries"]: CountryData,
            ["Cities"]: CityData
        });
    }

    const vendorform1 = [
        { set: true, type: "sapid", name: "SapId", label: "SAP ID", md: 4, function: onVendorInfoChange, validate: true },
        { set: true, type: "search", name: "search", label: "Search", md: 2, validate: false },
        { set: true, type: "blank", name: "", label: "", md: 6, validate: false },
        { set: true, type: "text", name: "VendorName", label: "Vendor Name", md: 3, function: onVendorInfoChange, validate: true },
        { set: true, type: "isMultiVT", name: "VendorTypes", label: "Vendor Type", md: 3, group: "vendortypes", function: onVendorInfoSelectChange, value: Vendor.VendorTypes, validate: true },
        { set: true, type: "text", name: "PaymentTerms", label: "Payment Terms", md: 3, function: onVendorInfoChange, validate: true },
        { set: true, type: "textarea", name: "Address", label: "Address", md: 12, function: onVendorInfoChange, validate: true },
        { set: true, type: "select1", name: "OfficeId", label: "Head Office Location", md: 3, function: onVendorInfoSingle, group: "Countries", validate: true },
        { set: true, type: "text", name: "KeyContactPerson", label: "Name Of Key Contact Person", md: 3, function: onVendorInfoChange, validate: true },
        {
            set: true, type: "select", display: true, md: 3, label: "Designation Key Contact Person", name: "DesignationKeyContactId",
            function: onVendorInfoSingle, group: "Designations", option: [{ label: "CEO", value: 1, id: "1" },
            { label: "Chief Officer", value: 2, id: "2" }, { label: "Owner", value: 3, id: "3" },
            { label: "MD", value: 4, id: "4" }], map: "designation", display: true, validate: true
        },
        { set: true, type: "phone", stat: value1, setstat: setValue1, name: "ContactNumberKeyContactPerson", touched: false, label: "Contact Number", md: 3, function: onVendorInfoChange, validate: true },
        { set: true, type: "email", name: "EmailIdKeyContactPerson", label: "Email Id Key Contact Person", md: 3, function: onVendorInfoChange, validate: true },
        { set: true, type: "text", name: "PrimaryContactName", label: "Primary Contact Name", md: 3, function: onVendorInfoChange, validate: true },
        { set: true, type: "select1", md: 3, label: "Designation Primary Contact Person", name: "PrimaryDesignationId", function: onVendorInfoSingle, group: "Designations", map: "designation", validate: true },
        { set: true, type: "phone", stat: value2, setstat: setValue2, name: "ContactNumberPrimaryContactPerson", touched: false, label: "Contact Number", md: 3, function: onVendorInfoChange, validate: true },
        { set: true, type: "email", name: "PrimaryContactEmail", label: "Primary Contact Email ID", md: 3, function: onVendorInfoChange, validate: true },
        { set: true, type: "text", name: "SecondaryContactName", label: "Secondary Contact Name", md: 3, function: onVendorInfoChange, validate: false },
        { set: true, type: "select1", md: 3, label: "Designation Secondary Contact Person", name: "SecondaryDesignationId", function: onVendorInfoSingle, group: "Designations", map: "designation", validate: false },
        { set: true, type: "phone", stat: value3, setstat: setValue3, name: "ContactNumberSecondaryContactName", label: "Contact Number", md: 3, function: onVendorInfoChange, validate: false },
        { set: true, type: "email", name: "SecondaryContactEmail", label: "Secondary Contact Email ID", md: 3, function: onVendorInfoChange, validate: false },
        { set: true, type: "text", name: "CostingPersonName", label: "Costing Person Name", md: 3, function: onVendorInfoChange, validate: true },
        { set: true, type: "multiemail", name: "CostingPersonEmailID", label: "Costing Contacts Email IDs", md: 3, function: onVendorInfoChange, validate: false },
        { set: true, type: "phone", stat: value4, setstat: setValue4, name: "CostingPersonContactNumber", touched: false, label: "Costing Person Contact Number", md: 3, function: onVendorInfoChange, validate: true },
        { set: true, sectionTilte: "Market Coverage", type: "isMultiCountry", touched: false, name: "Countries", label: "Countries Covered", md: 3, function: onVendorCountry, group: "Countries", validate: true },
        // { set: true, type: "isMultiCity", name: "Cities", touched: true, label: "City Covered", md: 3, function: onVendorInfoSelectChange, validate: false, group: "Cities" },
        { set: true, sectionTilte: "Capabilites", type: "isMultiMeth", name: "SubMethodologies", touched: false, label: "Methodologies Covered", md: 3, function: onVendorInfoSelectChange, group: "Methodologies", validate: true },
        { set: true, type: "select", name: "PanelBook", label: "Panel Book", md: 3, function: onVendorInfoSingle, option: [{ label: "Yes", value: 1 }, { label: "No", value: 0 }], validate: false },
        { set: true, type: "file", multiple: false, md: 3, parent: "PanelBook", label: "Upload Panel Book", download: "Panel Book", name: "PanelBookFile", touched: false, function: onVendorFileChange, onclick: onFileClick, validate: true },
        { set: true, type: "file", multiple: false, md: 3, label: "Upload Company Profile", download: "Company Profile", name: "CompanyProfileFile", function: onVendorFileChange, onclick: onFileClick, validate: true },
        { set: true, type: "file", multiple: false, md: 3, label: "Field Execution Strength", download: "Field Execution Strength", name: "FieldExecutionStrenght", function: onVendorFileChange, onclick: onFileClick, validate: true },
        { set: true, sectionTilte: "Training", type: "file", md: 3, label: "SOP Training Completed", download: "SOP Training", name: "SOPTrainingCompleted", function: onVendorInfoChange, option: [{ label: "Yes", value: 1 }, { label: "No", value: 0 }], validate: true },
        { set: true, type: "file", multiple: false, md: 3, label: "SOP Training Evidance", download: "SOP Training Evidance", name: "SOPTrainingEvidanceFile", function: onVendorFileChange, onclick: onFileClick, validate: true },
        { set: true, sectionTilte: "Due Diligence", type: "select", display: true, name: "NDASigned", label: "NDA Signed", download: "NDA Signed", md: 3, function: onVendorInfoSingle, onChange: Vendor.NDASigned, option: [{ label: "Yes", value: 1 }, { label: "No", value: 0 }], validate: true },
        { set: true, type: "date", md: 3, name: "NDASigningDate", label: "NDA Signed Date", md: 12, function: onVendorInfoChange, parent: "NDASigned", validate: false },
        { set: true, type: "date", md: 3, name: "NDAExpiryDate", label: "NDA Expiry Date", md: 12, function: onVendorInfoChange, parent: "NDASigned", validate: false },
        { set: true, type: "file", multiple: false, md: 3, parent: "NDASigned", label: "Upload SIgned NDA", download: "SIgned NDA", name: "SignedNDAFile", function: onVendorFileChange, onclick: onFileClick, validate: false },

        { set: true, type: "select", display: true, name: "MSASigned", label: "MSA Signed", md: 3, function: onVendorInfoSingle, onChange: Vendor.MSASigned, option: [{ label: "Yes", value: 1 }, { label: "No", value: 0 }], validate: true },
        { set: true, type: "date", md: 3, name: "MSASignedDate", label: "MSA Signed Date", md: 12, function: onVendorInfoChange, validate: false, parent: "MSASigned" },
        { set: true, type: "date", md: 3, name: "MSAExpiryDate", label: "MSA Expiry Date", md: 12, function: onVendorInfoChange, validate: false, parent: "MSASigned" },

        { set: true, type: "file", multiple: false, parent: "MSASigned", md: 3, label: "Upload Signed MSA", download: "Signed MSA", name: "SignedMSAFile", function: onVendorFileChange, onclick: onFileClick, validate: false },
        { set: true, type: "select", display: true, name: "DueDeligenceCompleted", label: "Due Deligence Completed", md: 3, function: onVendorInfoSingle, option: [{ label: "Yes", value: 1 }, { label: "No", value: 0 }], validate: true },
        { set: true, type: "select", parent: "DueDeligenceCompleted", display: true, name: "DueDeligenceTypeId", label: "Type Of Due Deligence ", md: 3, function: onVendorInfoSingle, option: [{ label: "Global agreement", value: 1, id: "1" }, { label: "Regional agreement", value: 2, id: "2" }, { label: "Local Agreement", value: 3, id: "3" }], validate: false },
        { set: true, type: "file", parent: "DueDeligenceCompleted", md: 3, multiple: false, label: "Upload Due Deligence Documents", download: "Due Deligence Documents", name: "DueDeligenceDocumentsFile", function: onVendorFileChange, onclick: onFileClick, validate: false },
        { set: true, type: "textarea", name: "OtherInformation", label: "Other Information", md: 12, function: onVendorInfoChange, validate: true },
        { set: true, type: "file", titleText: "Vendor capabilites, Tax certificates etc.", md: 3, label: "Upload Any Other Documents", download: "Upload Any Other Documents", multiple: false, name: "OtherDocumentsFile", function: onVendorFileChange, onclick: onFileClick, validate: true } //rishab multiple false
    ]
    const [vf, setvf] = useState(vendorform1)//vf=vendorform json

    const fileuploadstyle = {
        color: "#495057",
        cursor: "pointer",
    };
    const headderStyleForSapId = {
        backgroundColor: "#dcdcdc",
        height: "30px",
        width: "100%",
        fontWeight: "bolder",
        alignItems: "center",
        fontSize: "18px",
        marginBottom: "10px",
        marginTop: "10px",

    }
    const headderStyle = {
        backgroundColor: "#dcdcdc",
        height: "30px",
        width: "100%",
        fontWeight: "bolder",
        alignItems: "center",
        fontSize: "18px",
        marginBottom: "10px",
        marginTop: "10px",
        marginLeft: "10px"
    }
    const spacestyle = {
        height: "5px",
        width: "100%",
        marginBottom: "10px",
        marginTop: "10px",
    }
    const requiredstyle = {
        color: "#AD4400",
        cursor: "pointer",
        fontSize: "11px"
    };

    const stylerequired = {
        color: "#AD4400",
        cursor: "pointer",
        fontSize: "11px"
    };
    const filetypestyle = {
        cursor: "pointer",
        fontSize: "11px"
    };
    const phoneinputstyle = {
        width: "210px",
        height: "30px"
    };

    function returnList(name, group) {
        switch (name) {
            case "Cities": {
                return (Masters)
            }
            case "SubMethodologies":
                {
                    return (props?.redux_masters?.filter(x => x.Group == group)[0]["List"]?.filter(val => !val.IsVendorBiddingApplicable))
                }
            default: {
                return (props?.redux_masters?.filter(x => x.Group == group)[0]["List"])
            }
        }
    }
    function returnCountryList(group) {
        return props?.redux_masters?.filter(x => x.Group == "Countries")[0]["List"]?.filter(n => !Vendor?.Countries?.map(x => x.id).some(o => o == n.id))
    }

    function returnCityList(id) {
        return props.redux_masters?.filter(x => x.Group == "Cities")[0]["List"]?.filter(sm => sm.CountryId == id)
    }

    function searchSapId() {
        if (Vendor.SapId) {
            setLoading(true)
            axios.get("vendors/FindSap/" + Vendor.SapId)
                .then(data => {
                    let filterdata = data?.data?.VendorData;
                    let SapDataBinding = {}
                    if (data?.data?.message == "vendordata") {
                        if (filterdata != undefined && filterdata["segrigate"] == undefined) {
                            filterdata["Countries"] = filterdata.countries.filter(c => c.Blacklisted == 0)?.map(d => d.Country)
                            filterdata["BlacklistCountries"] = filterdata.countries.filter(c => c.Blacklisted == 1)?.map(d => d.Country)
                            filterdata["segrigate"] = true;
                            filterdata["PanelBook"] = filterdata.PanelBook == false ? "0" : "1"
                            filterdata["MSASigned"] = filterdata.MSASigned == false ? "0" : "1"
                            filterdata["NDASigned"] = filterdata.NDASigned == false ? "0" : "1"
                            filterdata["DueDeligenceCompleted"] = filterdata.DueDeligenceCompleted == false ? "0" : "1"
                            filterdata["CostingPersonEmailID"] = filterdata.CostingPersonEmailID != null ? ((filterdata.CostingPersonEmailID != "")
                                ? JSON.parse(filterdata.CostingPersonEmailID) : []) : []
                            let CountryData = [];
                            filterdata.countries.filter(c => c.Blacklisted == 0)?.map(d => d.Country)?.map(y => CountryData.push({
                                id: y.id, label: y.Label, Code: y.Code, AdditionalContactEmail: JSON.parse(
                                    filterdata?.countries.filter(c => c.Blacklisted == 0 && c.CountryId == y.id)[0]?.AdditionalContactEmail)
                            }))
                            filterdata["Countries"] = CountryData

                            //                            filterdata["Countries"] = filterdata.countries.filter(c => c.Blacklisted == 0)?.map(d => d.Country)
                            filterdata["BlacklistCountries"] = filterdata.countries.filter(c => c.Blacklisted == 1)?.map(d => d.Country)
                            setMaster(props?.redux_masters?.filter(x => x.Group == "Cities")[0]["List"]?.filter(sm => filterdata["Countries"]?.some(s => s.id == sm.CountryId)))
                            setVendorData(filterdata)
                            setOldVendor(filterdata)
                            setonsubmit(true)
                            setenteredsapid(Vendor.SapId)
                        }
                    }
                    else {
                        if (filterdata == null) {
                            setonsubmit(false)
                            setenteredsapid(0)
                            toastr.error("No Record Found")

                            // setVendorData(SapDataBinding)
                        }
                        else {
                            SapDataBinding["OfficeId"] = _.head(props.redux_masters.filter(x => x.Group == "Countries")[0]["List"].filter(c => c.Code == filterdata.VendorAddressCountry))?.id
                            SapDataBinding["VendorName"] = filterdata?.VendorName
                            SapDataBinding["SapId"] = filterdata?.SAPID
                            SapDataBinding["PaymentTerms"] = filterdata?.PaymentTerms == null ? "" : filterdata.PaymentTerms
                            SapDataBinding["Countries"] = []
                            SapDataBinding["Cities"] = []

                            setVendorData(SapDataBinding)
                            setOldVendor(SapDataBinding)
                            setonsubmit(true)
                            setenteredsapid(filterdata?.SAPID)
                        }
                    }
                })
        }
        else
            toastr.error("Please Enter SAP ID to Search")
        setLoading(false)
    }

    useEffect(() => {
        if (props?.id) {
            dispatch(appActions.pageLoadStart())
            axios.get("vendors/FindVendor/" + props.id)
                .then(data => {
                    filtered = data?.data?.VendorData;
                    if (filtered != undefined && filtered["segrigate"] == undefined) {
                        filtered["segrigate"] = true;
                        filtered["PanelBook"] = filtered.PanelBook == false ? "0" : "1"
                        filtered["MSASigned"] = filtered.MSASigned == false ? "0" : "1"
                        filtered["NDASigned"] = filtered.NDASigned == false ? "0" : "1"
                        filtered["DueDeligenceCompleted"] = filtered.DueDeligenceCompleted == false ? "0" : "1"
                        filtered["CostingPersonEmailID"] = filtered.CostingPersonEmailID != null ? ((filtered.CostingPersonEmailID != "")
                            ? JSON.parse(filtered.CostingPersonEmailID) : []) : []

                        let CountryData = [];
                        filtered.countries.filter(c => c.Blacklisted == 0)?.map(d => d.Country)?.map(y => CountryData.push({
                            id: y.id, label: y.Label, Code: y.Code, AdditionalContactEmail: JSON.parse(
                                filtered?.countries.filter(c => c.Blacklisted == 0 && c.CountryId == y.id)[0]?.AdditionalContactEmail)
                        }))
                        filtered["Countries"] = CountryData

                        //                        filtered["Countries"] = filtered.countries.filter(c => c.Blacklisted == 0)?.map(d => d.Country)
                        filtered["BlacklistCountries"] = filtered.countries.filter(c => c.Blacklisted == 1)?.map(d => d.Country)

                        setMaster(props?.redux_masters?.filter(x => x.Group == "Cities")[0]["List"]?.filter(sm => filtered["Countries"]?.some(s => s.id == sm.CountryId)))
                        setVendorData(filtered)
                        setOldVendor(filtered)
                        setonsubmit(true)
                    }
                    dispatch(appActions.pageLoadEnd())
                })
        }
    }, []);

    useEffect(() => {
        if (props.msg) {
            toastr.success("Form saved Successfully", props.msg);
            dispatch(vendorActions.resetMsg());
            setTimeout(() => {
                history.push('/vendoronboarding')
            }, 3000);
        }

    }, [props.msg]);

    function parent(p) {
        if (p.label == "Panel Book") {
            if (Vendor.Methodologies == undefined || Vendor.Methodologies.length == 0) {
                return false
            }
            else {
                var a = Vendor.Methodologies.map((e) => { return e.Label === "ONLINE QUANTITATIVE" || e.Label === "ONLINE QUALITATIVE" || e.Label === "ONLINE CLT SELF ADMINISTRATED" })
                if (a[0] != false && a.length > 0) { return true }
                else { return false }
            }
        }
    }

    function returnChoices(name, group) {
        return (!Vendor[name] ? [] :
            (props.redux_masters?.filter(x => x.Group == group)[0]["List"]?.filter(val => Vendor[name].some(vt => vt.id == val["id"])))
        )
    }
    function returnMailData(id) {
        return Vendor?.Countries.filter(f => f.id == id)[0]?.AdditionalContactEmail
    }
    function returnCitiesChoices(name, group, CID) {
        return (!Vendor[name] ? [] :
            (props.redux_masters?.filter(x => x.Group == group)[0]["List"]?.filter(val => CID == val["CountryId"]))?.filter(val => Vendor[name].some(vt => vt?.id == val["id"]))
        )
    }
    function fileInput(e) {
        var filePath = e.target.value;
        var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf|\.xls|\.xlsx|\.doc|\.docx)$/i;
        if (!allowedExtensions.exec(filePath)) {
            toastr.error("Please upload file having extensions .jpeg/.jpg/.png/.pdf/.xls/.xlsx/ .doc/.docx only")
        }
        else if (e.target.files[0].size > 4200000) {
            toastr.error("Please upload file having size less than 4 mb")
        }
        else {
            setVendorData({
                ...Vendor, [e.target.name]: Object.keys(e.target.files).map(c => { return e.target.files[c].name }),
                [e.target.name + "Name"]: Object.keys(e.target.files).map(c => { return e.target.files[c].name })[0]

            });
            fil[e.target.name] = e.target.files[0];
            setUploaded({
                ...uploaded, [e.target.name]: true
            });
        }
    }

    function onVendorInfoChange(e) {
        setVendorData({
            ...Vendor, [e.target.name]: (e.target.type === "select-one" ? parseInt(e.target.value) : e.target.value)
        });
    }
    function onemailchange(e) {
        let error = false
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        e?.length > 0 &&
            e.forEach(a => {
                if (regex.test(a.label) === false) {
                    error = true
                }
            })
        if (error) {
            toastr.error("Please Enter Valid Email")
        }
        else {

            setVendorData({
                ...Vendor, ["CostingPersonEmailID"]: e
            });
        }
    }
    function additionalmailchange(e, id) {
        let error = false
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        e?.length > 0 &&
            e.forEach(a => {
                if (regex.test(a.label) === false) {
                    error = true
                }
            })
        if (error) {
            toastr.error("Please Enter Valid Email")
        }
        else {

            Vendor.Countries.filter(x => x.id == id)[0]["AdditionalContactEmail"] = e
            setVendorData(
                Vendor
            );
        }
        AddMail("Countries")
    }
    function onVendorInfoSelectChange(e, name) {

        if (name == "Cities") {
            if (e != null) {
                setVendorData({
                    ...Vendor,
                    [name]: e.map(a => { return { id: a.id, label: a.label, Code: a.Code, CountryId: a.CountryId } })
                });
            }
            else {
                setVendorData({
                    ...Vendor,
                    [name]: []
                })
            }
        }
        else {
            if (e != null) {
                setVendorData({
                    ...Vendor,
                    [name]: e.map(a => { return { id: a.id, label: a.label, Code: a.Code } })
                });
            }
            else {
                setVendorData({
                    ...Vendor,
                    [name]: []
                })
            }
        }
    }
    function onVendorCity(e, name, CID) {

        if (name == "Cities") {
            if (e != null) {
                if (!Vendor?.Cities.length > 0) {
                    setVendorData({
                        ...Vendor,
                        [name]: e.map(a => { return { id: a.id, label: a.label, Code: a.Code, CountryId: a.CountryId } })
                    });
                }
                else
                    if (Vendor?.Cities?.filter(x => x.CountryId == CID).length > 0) {
                        var CityData = Vendor?.Cities?.filter(x => x.CountryId != CID);
                        e?.map(a => { CityData.push({ id: a.id, label: a.label, Code: a.Code, CountryId: a.CountryId }) })
                        setVendorData({
                            ...Vendor,
                            [name]: CityData
                        });
                    }
                    else {
                        var CityData = Vendor?.Cities;
                        e?.map(a => { CityData.push({ id: a.id, label: a.label, Code: a.Code, CountryId: a.CountryId }) })
                        setVendorData({
                            ...Vendor,
                            [name]: CityData
                        });
                    }


            }
            else {
                var CityData = Vendor?.Cities?.filter(x => x.CountryId != CID);
                e?.map(a => { CityData.push({ id: a.id, label: a.label, Code: a.Code, CountryId: a.CountryId }) })
                setVendorData({
                    ...Vendor,
                    [name]: CityData
                });
            }
        }
    }

    function onVendorCountry(e, name) {
        if (e != null) {
            if (e?.id) {
                if (!Vendor[name].filter(x => x.id == e.id).length > 0) {
                    Vendor[name].push({
                        id: e.id, label: e.label, Code: e.Code, AdditionalContactEmail: []
                    })
                    setVendorData({
                        ...Vendor,
                        Vendor
                    });
                }
            }
        }
        else {
            setVendorData({
                ...Vendor,
                [name]: []
            })
        }
    }

    function onVendorInfoSingle(e, name) {
        if (e != null) {
            if (name == "OfficeId") {
                Vendor[name] = e != null ? parseInt(e.target.value) : null
                Vendor["OfficeLocation"] = e.target.children[parseInt(e.target.value)]?.label == undefined ? "" : e.target.children[parseInt(e.target.value)]?.label
                setVendorData(Vendor);
            }
            else {
                if (name == "NDASigned" || name == "MSASigned" || name == "DueDeligenceCompleted") {
                    var dependent = vendorform1.filter(a => a.parent == name).map(a => a.name)
                    dependent.forEach(a => {
                        if ((Vendor[a] != null || Vendor[a] != undefined) && e.target.value == "0") {
                            if (a != "DueDeligenceTypeId")
                                Vendor[a] = "";
                        }
                    })

                    setVendorData({
                        ...Vendor, [name]: e != null ? parseInt(e.target.value) : null
                    });

                }
                else {
                    setVendorData({
                        ...Vendor, [name]: e != null ? parseInt(e.target.value) : null
                    });
                }
            }
        }
        else
            setVendorData({
                ...Vendor, [name]: null
            });

    }

    function onVendorInfoCheckChange(e) {
        setVendorData({
            ...Vendor, [e.target.name]: e.target.checked
        });
    }

    function OnCancel() {
        history.push('/vendoronboarding')
    }

    function UpdateStyle() {
        setVendorstyle(true);
        var VendorDataArray = ["VendorTypes", "Countries", "CostingPersonEmailID", "SubMethodologies", "ContactNumberKeyContactPerson", "ContactNumberPrimaryContactPerson", "CostingPersonContactNumber"];

        if (Object.keys(Vendor).length) {
            VendorDataArray.map(x => {
                if (eval("Vendor." + x) == undefined || (eval("Vendor." + x + ".length") < 1)) {
                    if (document.getElementById(x) != null)
                        document.getElementById(x).style.color = "#AD4400";
                }
                else
                    if (eval("Vendor." + x) != undefined || (eval("Vendor." + x + ".length") > 0)) {
                        if (document.getElementById(x) != null)
                            document.getElementById(x).style.color = "#495057";
                    }
            })
        }
        else {
            VendorDataArray.map(x => {
                if (document.getElementById(x) != null)
                    document.getElementById(x).style.color = "#AD4400";
            })
        }

        VendorDataArray.map(x => {
            VendorErrorMsg("submit", x)
        })

        setVendorstyle(false);
    }

    function VendorUpdateStyle(e, name) {

        if (vendorstyle) {
            if (e.length > 0) {
                if (document.getElementById(name) != null)
                    document.getElementById(name).style.color = "#495057";
            }
            else {
                if (document.getElementById(name) != null)
                    document.getElementById(name).style.color = "#AD4400";
            }
        }
        else if (!vendorstyle && !(e?.length > 0)) {
            {
                if (document.getElementById(name) != null)
                    document.getElementById(name).style.color = "#AD4400";
            }
        }

        else {
            if (document.getElementById(name) != null)
                document.getElementById(name).style.color = "#495057";

        }

    }

    function VendorErrorMsg(e, name) {
        switch (name) {
            case "VendorTypes": {

                if (e == "submit") {
                    if (Vendor.VendorTypes == undefined || Vendor.VendorTypes.length < 1) {
                        setvterrormsg(true)
                    }
                    else if (Vendor.VendorTypes != undefined || Vendor.VendorTypes.length > 0) {
                        setvterrormsg(false)
                    }
                }
                else if (e?.length > 0) {
                    setvterrormsg(false)
                }
                else {
                    setvterrormsg(true)
                }
                break;
            }
            case "Countries": {
                if (e == "submit") {
                    if (Vendor?.Countries == undefined || Vendor?.Countries.length < 1) {
                        setcountryerrormsg(true)
                    }
                    else if (Vendor?.Countries != undefined || Vendor?.Countries.length > 0) {
                        setcountryerrormsg(false)
                    }
                }
                else if (e?.length > 0) {
                    setcountryerrormsg(false)
                }
                else {
                    setcountryerrormsg(true)
                }
                break;
            }
            case "CostingPersonEmailID": {
                if (e == "submit") {
                    if (Vendor.CostingPersonEmailID == undefined || Vendor.CostingPersonEmailID.length < 1) {
                        setcostingpername(true)
                    }
                    else if (Vendor.CostingPersonEmailID != undefined || Vendor.CostingPersonEmailID.length > 0) {
                        setcostingpername(false)
                    }
                }
                else if (e?.length > 0) {
                    setcostingpername(false)
                }
                else {
                    setcostingpername(true)
                }
                break;
            }
            case "SubMethodologies": {
                if (e == "submit") {
                    if (Vendor.SubMethodologies == undefined || Vendor.SubMethodologies.length < 1) {
                        setmetherrormsg(true)
                    }
                    else if (Vendor.SubMethodologies != undefined || Vendor.SubMethodologies.length > 0) {
                        setmetherrormsg(false)
                    }
                }
                else if (e?.length > 0) {
                    setmetherrormsg(false)
                }
                else {
                    setmetherrormsg(true)
                }
                break;
            }
            case "ContactNumberKeyContactPerson": {
                if (e == "submit") {
                    if (Vendor.ContactNumberKeyContactPerson == undefined || Vendor.ContactNumberKeyContactPerson.length < 1) {
                        setconnumkeyconperson(true)
                    }
                    else if (Vendor.ContactNumberKeyContactPerson != undefined || Vendor.ContactNumberKeyContactPerson.length > 0) {
                        setconnumkeyconperson(false)
                    }
                }
                else if (e?.length > 0) {
                    setconnumkeyconperson(false)
                }
                else {
                    setconnumkeyconperson(true)
                }
                break;
            }
            case "ContactNumberPrimaryContactPerson": {
                if (e == "submit") {
                    if (Vendor.ContactNumberPrimaryContactPerson == undefined || Vendor.ContactNumberPrimaryContactPerson.length < 1) {
                        setconnumprimaryconperson(true)
                    }
                    else if (Vendor.ContactNumberPrimaryContactPerson != undefined || Vendor.ContactNumberPrimaryContactPerson.length > 0) {
                        setconnumprimaryconperson(false)
                    }
                }
                else if (e?.length > 0) {
                    setconnumprimaryconperson(false)
                }
                else {
                    setconnumprimaryconperson(true)
                }
                break;

            }
            case "CostingPersonContactNumber": {
                if (e == "submit") {
                    if (Vendor.CostingPersonContactNumber == undefined || Vendor.CostingPersonContactNumber.length < 1) {
                        setcostingpersonconnum(true)
                    }
                    else if (Vendor.CostingPersonContactNumber != undefined || Vendor.CostingPersonContactNumber.length > 0) {
                        setcostingpersonconnum(false)
                    }
                }
                else if (e?.length > 0) {
                    setcostingpersonconnum(false)
                }
                else {
                    setcostingpersonconnum(true)
                }
                break;
            }
        }
    }

    function onVendorFileChange(e) {
        setVendorData({
            ...Vendor, [e.target.name]: e.target.files[0]
        });

    }
    async function onFileClick(e) {
        try {
            var res = await axios.get(`/files/download/vendor/${e}`, {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/pdf'
                }
            });
            if (res) {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${e}`); //or any other extension
                document.body.appendChild(link);
                link.click();
            }

        }
        catch (err) {

            toastr.error("Loading Failed", err?.data?.message);
        }

    }

    function handleparent(p) {

        if (p.name == ("PanelBook" || "PanelBookFile")) {
            if (Vendor?.SubMethodologies != '' && Vendor?.SubMethodologies?.filter(a => a.id == "64").length > 0) {
                return true
            }
            else {
                return false
            }
        }
        if (!p.parent) {
            return true
        }
        if (Vendor[p.parent] == 1) {
            p.validate = true
            return true
        }
        else {
            p.validate = false
            return false
        }
    }

    const customStyles = {
        control: (base, state) => (
            {
                ...base,
                boxShadow: "none!important",
                border: checkState(state) ? "solid 1px #AD4400" : ""
                // You can also use state.isFocused to conditionally style based on the focus state
            }
        )
    };

    const customStylesVT = {
        control: (base, state) => (
            {
                ...base,
                boxShadow: "none!important",
                border: vterrormsg ? "solid 1px #AD4400" : ""
                // You can also use state.isFocused to conditionally style based on the focus state
            }
        )
    };
    const customStylesCountry = {
        control: (base, state) => (
            {
                ...base,
                boxShadow: "none!important",
                border: countryerrormsg ? "solid 1px #AD4400" : ""
                // You can also use state.isFocused to conditionally style based on the focus state
            }
        )
    };

    const customStylesMeth = {
        control: (base, state) => (
            {
                ...base,
                boxShadow: "none!important",
                border: metherrormsg ? "solid 1px #AD4400" : ""
                // You can also use state.isFocused to conditionally style based on the focus state
            }
        )
    };
    const customStylesCPN = {
        control: (base, state) => (
            {
                ...base,
                boxShadow: "none!important",
                border: costingpername ? "solid 1px #AD4400" : ""
                // You can also use state.isFocused to conditionally style based on the focus state
            }
        )
    };


    function handleChange(event, v) {
        if (v.validate) {
            const index = vf.map((x) => { return x.name }).indexOf(v.name);
            const values = [...vf]
            values[index]["touched"] = event.target.value == "" ? true : false
            setvf(values)
        }
    }
    function checkState(state) {
        let name = state.selectProps.name;
        if (ValidateMulti && !state.isFocused) {
            if (!Vendor[name] || Vendor[name].length == 0)
                return true;
        }
        else
            return false;
    }

    function checkAllMultiSelect() {
        if ((!Vendor?.VendorTypes?.length > 0) ||
            (!Vendor?.SubMethodologies?.length > 0) ||
            (!Vendor?.CostingPersonEmailID?.length > 0))

            return true;
        else
            return false;
    }

    function validateMultiselect() {
        setValidateMulti(true);
    }

    function validateform() {
        var a = vendorform1.filter((e) => { return e.set == false })
        if (a.length > 0) { return false }
        else { return true }
    }

    function onVendorPhoneChange(name, value) {
        setVendorData({
            ...Vendor, [name]: value
        })

    }
    const ReturnControl = (v, index) => {
        switch (v.type) {
            case "date": {
                return (<>

                    {handleparent(v) &&
                        <Col md={3}>
                            <AvField
                                name={v.name}
                                placeholder=""
                                label={v.label + "(*)"}
                                value={Vendor[v.name]}
                                type="date"
                                errorMessage={`${v.label} is required`}
                                validate={{ required: { value: v.validate } }}
                                onChange={(e) => {
                                    setDate(e.target.value)
                                    v.function(e)
                                }}
                                required />
                        </Col>
                    }
                </>
                )
            }
            case "sapid": {
                return (<>

                    {
                        <Col md={3}>
                            <AvField
                                name={v.name}
                                placeholder=""
                                value={Vendor[v.name]}
                                label={v.validate ? v.label + "(*)" : v.label}
                                type="text"
                                errorMessage={`${v.label} is required`}
                                validate={{ required: { value: v.validate }, maxLength: { value: 50 } }}
                                onChange={(e) => {
                                    v.function(e)
                                }}
                                required />
                        </Col>
                    }
                </>
                )
            }
            case "search": {
                return (<>

                    <Col md={2}>
                        <div style={{ paddingTop: '28px' }}>

                        </div>
                        <Button color="primary"
                            onClick={() =>
                                searchSapId()
                            }
                        >Search
                            {loading ? (
                                <Spinner size="small" color="#495057" />
                            ) : null}
                        </Button>

                    </Col>
                </>
                )
            }
            case "blank": {
                return (
                    <>
                        <Col md={6}></Col>
                    </>
                )
            }
            case "email": {
                return (
                    <>
                        {v.sectionTilte && <div style={headderStyle}>{v.sectionTilte}<br></br></div>}
                        <Col md={3}>

                            <AvField
                                name={v.name}
                                placeholder=""
                                value={Vendor[v.name] ? Vendor[v.name] : ""}
                                label={v.validate ? v.label + "(*)" : v.label}
                                type={v.type}
                                errorMessage="A valid email is required"
                                validate={{ required: { value: v.validate } }}
                                onChange={v.function}
                                required />
                        </Col>
                    </>
                )
            }
            case "multiemail": {
                return (
                    <>
                        {v.sectionTilte && <div style={headderStyle}>{v.sectionTilte}<br></br></div>}
                        <Col md={3}>
                            <Label id={v.name}>{v.label + "(*)"}</Label>
                            <CreatableSelect
                                styles={customStylesCPN}

                                className="react-select-container"
                                classNamePrefix="react-select"
                                name={v.name}
                                isMulti
                                value={Vendor[v.name]}
                                label={v.label}
                                onChange={(e) => {
                                    onemailchange(e)
                                    VendorUpdateStyle(e, v.name)
                                    VendorErrorMsg(e, v.name)
                                }}
                            />
                            {costingpername && <span style={stylerequired}> {v.label} is required</span>}
                        </Col>
                    </>
                )
            }
            case "number": {
                return (<>
                    {v.sectionTilte && <div style={headderStyle}>{v.sectionTilte}<br></br></div>}
                    <Col md={v.md}>
                        <FormGroup>
                            <AvField
                                name={v.name}
                                placeholder=""
                                label={v.validate ? v.label + "(*)" : v.label}
                                value={Vendor[v.name]}
                                type={v.type}
                                errorMessage={`${v.label} is requiredy`}
                                validate={{ required: { value: v.validate } }}
                                onChange={v.function}
                                required />
                        </FormGroup>
                    </Col>
                </>
                )
            }
            case "textarea": {
                return (<>
                    {v.sectionTilte && <div style={headderStyle}>{v.sectionTilte}<br></br></div>}
                    <Col md={v.md}>
                        <AvField
                            name={v.name}
                            placeholder=""
                            value={Vendor[v.name] ? Vendor[v.name] : ""}
                            label={v.validate ? v.label + "(*)" : v.label}
                            type={v.type}
                            errorMessage={`${v.label} is required`}
                            validate={{ required: { value: v.validate } }}
                            onChange={v.function}
                            required />
                    </Col>
                </>
                )

            }
            case "text": {
                return (
                    <>
                        {v.sectionTilte && <div style={headderStyle}>{v.sectionTilte}<br></br></div>}

                        <Col md={v.md}>
                            <AvField
                                name={v.name}
                                placeholder=""
                                label={v.validate ? v.label + "(*)" : v.label}
                                value={Vendor[v.name] ? Vendor[v.name] : ""}
                                type={v.name}
                                errorMessage={`${v.label} is required`}
                                validate={{ required: { value: v.validate }, maxLength: { value: 50 } }}
                                onChange={v.function}
                                required />
                        </Col>
                    </>
                )
            }
            case "select": {
                return (
                    <>

                        {v.sectionTilte && <div style={headderStyle}>{v.sectionTilte}<br></br></div>}{(handleparent(v) || v.display) &&
                            handleparent(v) &&
                            <Col md={v.md}>

                                <AvField
                                    tag={CustomInput}
                                    name={v.name}
                                    label={v.validate ? v.label + "(*)" : v.label}
                                    value={(Vendor[v.name] != null || Vendor[v.name] != undefined) ? Vendor[v.name] : ""}
                                    type={v.type}
                                    errorMessage={`${v.label} is required`}
                                    validate={{ required: { value: v.validate } }}
                                    onChange={(e) => v.function(e, v.name)}
                                    required
                                >
                                    <><option value="99"> </option>
                                        {v.option.map((country) => (
                                            <option key={country.value} value={country.value}>{country.label}</option>
                                        ))}</>
                                </AvField>
                            </Col>}</>

                )
            }
            case "select1": {
                return (<>
                    {v.sectionTilte && <div style={headderStyle}>{v.sectionTilte}<br></br></div>}
                    <Col md={v.md}>
                        <AvField
                            tag={CustomInput}
                            name={v.name}
                            label={v.validate ? v.label + "(*)" : v.label}
                            type="select"
                            value={Vendor[v.name] ? Vendor[v.name] : ""}
                            errorMessage={`${v.label} is required`}
                            validate={{ required: { value: v.validate } }}
                            onChange={(e) => v.function(e, v.name)}
                            required
                        >

                            <><option value="0"></option>
                                {props.redux_masters.filter(x => x.Group == v.group)[0]["List"].map((country) => (
                                    <option key={country.value} value={country.value}>{country.label}</option>
                                ))}</>
                        </AvField>
                    </Col>
                </>
                )
            }
            case "file": {
                return (<>

                    {v.sectionTilte && <div style={headderStyle}>{v.sectionTilte}<br></br></div>}
                    {handleparent(v) && <Col md={v.md}>

                        <FormGroup>
                            <Label>{v.label}  <span style={filetypestyle}>(png, jpg, jpeg, xls, xlsx, doc ,docx and pdf)</span></Label>
                            <Col>
                                <input type="file"
                                    accept=" .png , .jpg , .pdf , .jpeg , .xls , .xlsx , .doc , .docx"
                                    name={v.name}
                                    required="required"
                                    onblur={(e) => { handleChange(e, v) }}
                                    title={v.titleText || " "}
                                    style={{ color: "transparent" }}
                                    onChange={
                                        (e) => { fileInput(e) }

                                    } />
                            </Col>

                            <Col>{uploaded[v.name] && <   span style={fileuploadstyle} onClick={v.onClick}>{Vendor[v.name]}</span>}
                                {vf[index]["touched"] && <span style={requiredstyle} >{`${v.label} is required`}</span>}
                            </Col>

                            {Vendor[v.name] && oldVendor[v.name] && !uploaded[v.name] &&
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <p style={{ color: "blue" }}>{v.download}</p>
                                    <FontAwesomeIcon
                                        onClick={() => onFileClick(Vendor[`${v.name}`])}
                                        title="Download File"
                                        className="align-middle mr-2 addButton"
                                        icon={faDownload}
                                        fixedWidth
                                        style={{ cursor: "pointer", color: "#32cd32" }}
                                    /></div>}
                        </FormGroup>
                    </Col>}</>
                )
            }
            case "isMultiVT": {
                return (<>
                    {v.sectionTilte && <div style={headderStyle}>{v.sectionTilte}<br></br></div>}

                    <Col md={3}>
                        <Label id={v.name}>{v.label + "(*)"}</Label>
                        {
                            <Select
                                styles={customStylesVT}
                                name={v.name}
                                rules={{ required: 'Please select an option' }}
                                value={returnChoices(v.name, v.group)}
                                onChange={(val) => {
                                    v.function(val, v.name)
                                    VendorUpdateStyle(val, v.name)
                                    VendorErrorMsg(val, v.name)
                                }}
                                className="basic-multi-select"
                                classNamePrefix="react-select"
                                required
                                options={returnList(v.name, v.group)}
                                isMulti
                            />
                        }
                        {vterrormsg && <span style={stylerequired}> {v.label} is required</span>}

                    </Col>
                </>
                )
            }
            case "isMultiCountry": {
                return (<>
                    {v.sectionTilte && <div style={headderStyle}>{v.sectionTilte}<br></br></div>}
                    <>
                        <Col md={8}></Col>
                        <Col md={4}>
                            <table>
                                <tr><th width="60%" ><b>Country Selection (*)</b></th>
                                    {countryrowdata.length > 0 && <th width="35%"><Button color="primary"
                                        onClick={(e) => CreateMarketRow("Countries")}>
                                        Add Market
                                    </Button></th>}</tr>
                                <tr width="100%"><td width="400px" colSpan={2}><Select
                                    name={v.name}
                                    value={countryrowdata}
                                    onChange={(val) => {
                                        setRowAddData(val)
                                        setCountryRowData(val)
                                    }}
                                    required
                                    isMulti
                                    options={returnCountryList(v.group)}
                                /></td></tr>
                            </table>


                            {/* <Col md={12}>
                                <Col md={6}>
                                    <Label>Country Selection (*)</Label>
                                </Col>
                                <Col md={6}><Button color="primary"
                                    onClick={(e) => CreateMarketRow("Countries")}>
                                    Add Market
                                </Button></Col>
                            </Col>
                            <Col md={12}><Select
                                name={v.name}
                                value={countryrowdata}
                                onChange={(val) => {
                                    setRowAddData(val)
                                    setCountryRowData(val)
                                }}
                                required
                                isMulti
                                options={returnCountryList(v.group)}
                            />
                            </Col> */}

                        </Col>
                        {/* <Col md={2}>
                            <Button color="primary"
                                onClick={(e) => CreateMarketRow("Countries")}>
                                Add Market
                            </Button>
                        </Col> */}

                    </>
                    <div style={spacestyle}></div>
                    <Col md={12}>
                        {Vendor && Vendor["Countries"]?.length > 0 &&
                            <>
                                <Table
                                    className="table table-stripped"
                                    bordered
                                    style={{
                                        backgroundColor:
                                            "white",
                                        color:
                                            "black",
                                    }}
                                    id="MarketCoverageTable"
                                >
                                    <tr style={{
                                        backgroundColor:
                                            "#dcdcdc",
                                        color:
                                            "black",
                                    }}>
                                        <th width="20%"><b>Countries</b></th>
                                        <th width="40%"><b>Cities</b></th>
                                        <th width="35%"><b>Additional Contact Email</b></th>
                                        <th width="5%"><b></b></th>
                                    </tr>
                                    {Vendor["Countries"]?.map(x =>

                                        x && <tr>
                                            <td width="20%">
                                                {Vendor?.Countries.filter(vc => vc.id == x.id)[0].label || Vendor?.Countries.filter(vc => vc.id == x.id)[0].Label}
                                            </td>
                                            <td width="40%">
                                                <Select
                                                    name={"Cities"}
                                                    value={returnCitiesChoices("Cities", "Cities", x.id)}
                                                    onChange={(val) => {
                                                        onVendorCity(val, "Cities", x.id)
                                                    }}
                                                    options={returnCityList(x.id)}
                                                    isMulti
                                                />
                                            </td>
                                            <td width="35%">
                                                <CreatableSelect
                                                    name={"AdditionalContactEmail"}
                                                    value={returnMailData(x.id)}
                                                    onChange={(e) => {
                                                        additionalmailchange(e, x.id)
                                                    }}
                                                    isMulti
                                                >
                                                </CreatableSelect>
                                            </td>
                                            <td width="5%">
                                                <div style={{
                                                    display: "flex", flexDirection: "row",
                                                    alignContent: "center",
                                                    marginTop: "12px"
                                                }}>
                                                    <FontAwesomeIcon
                                                        className="align-middle mr-2 addButton"
                                                        icon={faMinusCircle}
                                                        title="Remove Row"
                                                        style={{ cursor: "pointer", color: "#ff0000" }}
                                                        onClick={(e) => DeleteMarketRow(x.id)}

                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </Table>
                            </>}
                    </Col>
                </>
                )
            }
            case "isMultiCity": {
                return (<>
                    {v.sectionTilte && <div style={headderStyle}>{v.sectionTilte}<br></br></div>}

                    <Col md={3}>
                        <Label>{v.label}</Label>
                        {
                            <Select
                                name={v.name}
                                rules={{ required: 'Please select an option' }}
                                value={returnChoices(v.name, v.group)}
                                onChange={(val) => {
                                    v.function(val, v.name)
                                    VendorUpdateStyle(val, v.name)
                                    VendorErrorMsg(val, v.name)
                                }}
                                className="basic-multi-select"
                                classNamePrefix="react-select"
                                required={v.validate}
                                options={returnList(v.name, v.group)}
                                isMulti
                            />
                        }
                    </Col>
                </>
                )
            }
            case "isMultiMeth": {
                return (<>
                    {v.sectionTilte && <div style={headderStyle}>{v.sectionTilte}<br></br></div>}

                    <Col md={3}>
                        <Label id={v.name}>{v.label + "(*)"}</Label>
                        {
                            <Select
                                styles={customStylesMeth}
                                name={v.name}
                                rules={{ required: 'Please select an option' }}
                                value={returnChoices(v.name, v.group)}
                                onChange={(val) => {
                                    v.function(val, v.name)
                                    VendorUpdateStyle(val, v.name)
                                    VendorErrorMsg(val, v.name)
                                }}
                                className="basic-multi-select"
                                classNamePrefix="react-select"
                                required
                                options={returnList(v.name, v.group)}
                                isMulti
                            />
                        }
                        {metherrormsg && <span style={stylerequired}> {v.label} is required</span>}

                    </Col>
                </>
                )
            }

            case "phoneCKCP": {
                return (<>
                    {v.sectionTilte && <div style={headderStyle}>{v.sectionTilte}<br></br></div>}
                    <Col md={v.md}>
                        <Label id={v.name}>{v.label + "(*)"}</Label>
                        <PhoneInput
                            styles={customStyles}
                            placeholder="Enter Phone Number"
                            inputExtraProps={{ required: v.validate }}
                            name={v.name}
                            value={Vendor[v.name]}
                            type="tel"
                            onChange={(e) => {
                                onVendorPhoneChange(v.name, e)
                                VendorUpdateStyle(e, v.name)
                                VendorErrorMsg(e, v.name)
                            }}
                            maxlength="15"
                            required
                            id={v.name}
                            containerStyle={{ borderColor: "#AD4400", borderRadius: "3px" }}
                            inputStyle={phoneinputstyle}
                            isValidPhoneNumber={true}
                        />
                        {connumkeyconperson && <span style={stylerequired}> {v.label} is required</span>}
                    </Col>
                </>
                )
            }
            case "phoneCNPP": {
                return (<>
                    {v.sectionTilte && <div style={headderStyle}>{v.sectionTilte}<br></br></div>}
                    <Col md={v.md}>
                        <Label id={v.name}>{v.label + "(*)"}</Label>
                        <PhoneInput
                            placeholder="Enter Phone Number"
                            styles={customStyles}
                            inputExtraProps={{ required: v.validate }}
                            name={v.name}
                            value={Vendor[v.name]}
                            type="tel"
                            onChange={(e) => {
                                onVendorPhoneChange(v.name, e)
                                VendorUpdateStyle(e, v.name)
                                VendorErrorMsg(e, v.name)
                            }}
                            required
                            maxlength="15"
                            id={v.name}
                            inputStyle={phoneinputstyle}
                            isValidPhoneNumber={true}
                        />
                        {connumprimaryconperson && <span style={stylerequired}> {v.label} is required</span>}
                    </Col>
                </>
                )
            }
            case "phoneCPCN": {
                return (<>
                    {v.sectionTilte && <div style={headderStyle}>{v.sectionTilte}<br></br></div>}
                    <Col md={v.md}>
                        <Label id={v.name}>{v.label + "(*)"}</Label>
                        <PhoneInput
                            placeholder="Enter Phone Number"
                            styles={customStyles}
                            inputExtraProps={{ required: v.validate }}
                            name={v.name}
                            value={Vendor[v.name]}
                            type="tel"
                            onChange={(e) => {
                                onVendorPhoneChange(v.name, e)
                                VendorUpdateStyle(e, v.name)
                                VendorErrorMsg(e, v.name)
                            }}
                            required
                            maxlength="15"
                            id={v.name}
                            inputStyle={phoneinputstyle}
                            isValidPhoneNumber={true}
                        />
                        {costingpersonconnum && <span style={stylerequired}> {v.label} is required</span>}
                    </Col>
                </>
                )
            }
            case "phone": {
                return (<>
                    {v.sectionTilte && <div style={headderStyle}>{v.sectionTilte}<br></br></div>}
                    <Col md={v.md}>
                        <Label>{v.label}</Label>
                        <PhoneInput
                            placeholder="Enter phone number"
                            name={v.name}
                            value={Vendor[v.name]}
                            type="tel"
                            onChange={(e) => onVendorPhoneChange(v.name, e)}
                            maxlength="15"
                            id={v.name}
                            inputStyle={phoneinputstyle}
                            isValidPhoneNumber={true}
                        />

                    </Col>
                </>
                )
            }

        }
    }

    return (
        <Layout
            onlyHeader={true}
            avoidEdit={true}
            hideProfileName={true}
            hideActions={true}
            CardHeader={props.id ? "VENDOR DETAILS MODIFICATION" : "VENDOR ONBOARDING FORM"}
        >
            <Container fluid className="p-0">
                {
                    <Card>
                        <CardBody>
                            <div style={headderStyleForSapId}>General Information<br></br></div>
                            <AvForm encType="multipart/form-data"
                                onValidSubmit={
                                    (e) => {
                                        {
                                            e.preventDefault();

                                            let formData = new FormData();

                                            Object.keys(Vendor).forEach(key => {
                                                if (Vendor[key] != null || Vendor[key] != undefined) {
                                                    if (vendorform1.filter(val => val.name == key && val.type == "file").length > 0) {
                                                        if (fil[key] != undefined && fil[key].name != undefined)
                                                            formData.set(key, fil[key], fil[key].name)
                                                    }
                                                    else {
                                                        if (key == "VendorTypes" || key == "Countries" || key == "SubMethodologies" || key == "Cities" || key == "CostingPersonEmailID") {

                                                            formData.set(key, (Vendor[key] != null) ? JSON.stringify(Vendor[key]) : "");
                                                            if (key == "VendorTypes") {
                                                                formData.set("VendorTypeNames", Vendor[key]?.map(a => (a.label || a.Label)).toString())
                                                            }
                                                        }
                                                        else {

                                                            formData.set(key, Vendor[key]);

                                                        }
                                                    }
                                                }

                                            });
                                            if (filtered) {
                                                formData.set('id', props.id);
                                            }
                                            if ((!Vendor.SapId || Vendor.SapId == "") || checkAllMultiSelect()) {
                                                toastr.error("Please Enter Values in Missing Fields")
                                            }
                                            else
                                                if ((!Vendor?.Countries?.length > 0)) {
                                                    toastr.error("Please Select Country")
                                                }
                                                else {
                                                    dispatch(appActions.pageLoadStart());
                                                    axios.post("vendors/save", formData)
                                                        .then(data => {
                                                            dispatch(appActions.pageLoadEnd());
                                                            console.log(data)
                                                            toastr.success(props.id ? "Vendor Details Updated" : "Onboarding Request Sent")
                                                            history.push("/vendoronboarding")
                                                        })
                                                        .catch(err => { console.log("err is"); console.log(err) });
                                                }
                                        }
                                    }
                                }
                                onInvalidSubmit={(e) => {
                                    e.preventDefault();
                                    toastr.error("Please fill the missing fields")
                                    if (!filtered) {
                                        validateMultiselect();
                                    }
                                }}>

                                <Row>{
                                    vendorform1.map((e, index) => { return (ReturnControl(e, index)) })
                                }
                                </Row>
                                <Button color="primary" onClick={UpdateStyle} type="submit" disabled={!onsubmit || (enteredsapid != Vendor.SapId && !props.id)}>
                                    {/* <Button color="primary" onClick={UpdateStyle} type="submit"> */}

                                    {Vendor.Status && Vendor.Status.Code == "Rejected" ? "Re-Submit" : "Submit"}
                                    {props.saving && <FontAwesomeIcon class="saving-btn" icon={faSpinner} fixedWidth />}
                                </Button>
                                <Button style={{ marginLeft: "20px" }} color="secondary" onClick={OnCancel}>Cancel</Button>
                            </AvForm>
                        </CardBody>
                    </Card>
                }
            </Container>
        </Layout>
    )
}
export default connect(mapStateToProps, null)(VendorForm)