import { takeEvery, call, put } from "redux-saga/effects";
import * as types from "../actions/masters/ActionTypes";
import axios from '../../axios-interceptor';
import {SET_PAGELOAD} from "../actions/appActions";

const baseurl= process.env.REACT_APP_API_BASE_URL
//const baseurl="http://localhost:3030/"
function* fetchAllMasters()

{
    try
    {
        yield put({ type: SET_PAGELOAD, pageloaded: false });
        const masters = yield axios.get("masters/list");
        let tempList = [];
        masters.data.items.forEach(x =>
        {
            let tempObj = { Group: x.Group, List: [] };
            x.Data.forEach(y =>
            {
                let temp = { ...y };
                temp.label = x.Group == "Vendors" ? temp.VendorName : temp.Label;
                temp.value = temp.id;
                tempObj.List.push(temp);
            });
            tempList.push(tempObj);
        });
        yield put({ type: types.FETCH_ALL_MASTERS_REQUEST_SUCCESS, payload: tempList });
        yield put({ type: SET_PAGELOAD, pageloaded: true });  
    }
    catch (error)
    {
        const errorMsg = error.message;
        yield put({ type: types.FETCH_ALL_MASTERS_FAILURE, payload: errorMsg });
    }
}

export default function* waitForLoadAllMasters()
{
    yield takeEvery(types.FETCH_ALL_MASTERS_REQUEST, fetchAllMasters);
}
