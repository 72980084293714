import React, { useEffect, useState } from "react";
import {
  recordLoadEnd,
  recordLoadStart,
} from "../../../../../../src/redux/actions/appActions";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import * as currentCostingActions from "../../../../../redux/actions/currentCostingActions";
import logo from "../../../../../../src/assets/img/avatars/modal-logo.png";

import { toastr } from "react-redux-toastr";
import classnames from "classnames";
import Spinner from "../../../../../components/Spinner";
import _, { cloneDeep } from "lodash";
import {
  SET_BIDDING_SPLIT_DATA
} from "../../../../../redux/actions/vendor/ActionTypes";
import update from "immutability-helper";
import {
  Input,
  FormGroup,
  Modal,
  Nav,
  NavItem,
  NavLink,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  ModalFooter,
  Button,
  Label,
  Row,
  Col,
  InputGroup,
} from "reactstrap";
import Table from "reactstrap/lib/Table";
import Axios from "../../../../../../src/axios-interceptor";
import * as currentProjectActions from "../../../../../redux/actions/currentProjectActions";
// import { NavLink } from "react-router-dom";
import { faUndo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { proposalListRedirectToEdit } from "../../../../../redux/actions/proposal/Actions";
import * as proposalActions from "../../../../../redux/actions/proposal/Actions";
function SplitSampleManagement({
  SplitSample,
  setSplitSample,
  currentMethodology,
  SplitSampleInfo,
  methIndex,
  countryIndex
}) {
  const dispatch = useDispatch();

  const userRecord = useSelector(({ user }) => user.userRecord);

  const currentCostingProfile = useSelector(
    ({ currentCosting }) => currentCosting.currentCostingProfile
  );
  const projects = useSelector(({ projects }) => projects.items);
  let vendorreducer = useSelector(({ vendorReducer }) => vendorReducer);
  const app = useSelector(({ app }) => app);
  const cancelPopUp = () => {
    setSplitSample(!SplitSample);
  };
  const [roundNo, setRoundNo] = useState([]);
  const [activeRoundTab, setActiveRoundTab] = useState(0);
  const [activeTabCo, setActiveTabCo] = useState(0);
  const [list, setlist] = useState([]);
  const [currentAchieveables, setCurrentAchieveables] = useState([]);
  const [ViewOnlyMode, setViewOnlyMode] = useState(false);
  const [filteredCO, setFilteredCO] = useState([]);
  const [valid, setValid] = useState(false);
  const [validCity, setValidCity] = useState(false);
  var project = useSelector(({ currentProject }) => currentProject.newProject);

  useEffect(() => {
    setViewOnlyMode((project?.ProjectStatus == 7)
      || (["2", "3", "4", "5", "6"].indexOf(_.last(project?.CostingProfiles).ProfileStatus) != -1)
      ? true : false)
  }, []);

  useEffect(() => {

    if (SplitSampleInfo.filter(a => a.MethodologySpecId == currentMethodology.id).length == 0) {
      let tempRound = [];
      let tempInfo = {};
      tempInfo.MethodologySpecId = currentMethodology.id;
      tempInfo.RoundName = `Round 1`;
      tempInfo.SampleDistributionMasters = [];
      currentMethodology.CostingOptions.map((co) => {
        let tempCo = {};
        tempCo.CostingOptionId = co.id;
        tempCo.CoSampleSize = parseInt(co.CostingOptionData?.sampleSizeMain ? co.CostingOptionData.sampleSizeMain : co.CostingOptionData?.numberOfGroup);
        tempCo.TargetSamples = parseInt(co.CostingOptionData?.sampleSizeMain ? co.CostingOptionData.sampleSizeMain : co.CostingOptionData?.numberOfGroup);
        tempCo.SampleDistributionDetails = [];
        tempCo.Selected = co.Selected;
        if (tempCo.Selected)
          tempInfo.SampleDistributionMasters.push(tempCo);
      });
      tempRound.push(tempInfo);
      SplitSampleInfo.push(tempInfo)
      dispatch({ type: SET_BIDDING_SPLIT_DATA, data: SplitSampleInfo })
      setlist(tempRound);
      setRoundNo(tempRound);
    } else {
      //for no of splits
      let tempSampleSplit = SplitSampleInfo.filter(a => a.MethodologySpecId == currentMethodology.id)
      tempSampleSplit.forEach(info => {
        info.SampleDistributionMasters.forEach((master) => {
          master.NumberOfSplits = master.SampleDistributionDetails.length ? master.SampleDistributionDetails.length : 0;
          master.Selected = currentMethodology?.CostingOptions.filter(x => x.id == master?.CostingOptionId)[0].Selected;
        })
      })
      if (tempSampleSplit.length)
        setlist(SplitSampleInfo.filter(a => a.MethodologySpecId == currentMethodology.id)); //todo:temprary
      setRoundNo(SplitSampleInfo.filter(a => a.MethodologySpecId == currentMethodology.id));
    }
  }, []);
  useEffect(() => {
    let acieveableSamples = []
    if (list.length) {
      let filteredCostingOptions = filterationCO()
      if (currentMethodology?.InvitedVendors) {
        acieveableSamples = currentMethodology?.InvitedVendors?.filter(iv => iv.IsDeleted == false)?.map(iv => {
          let selectedIv = iv.VendorCosts.find(vc => vc.CostOptionId == filteredCostingOptions[activeTabCo].id && vc.IsSelected == true)
          if (selectedIv) {
            return {
              InvitedVendorId: iv.VendorId,
              AchivableSamples: selectedIv.AchivableSamples ? selectedIv.AchivableSamples : 0,
              SelectedDate: selectedIv.SelectedDate
            }
          }
        }).filter(a => a != undefined)
      }
      let tempRound = _.cloneDeep(list);
      if (list.length) {
        let tempList = list[activeRoundTab]?.SampleDistributionMasters.forEach(master => {
          if (master.CostingOptionId == currentMethodology.CostingOptions[activeTabCo].id) {
            master.AchivableSamples = acieveableSamples ? acieveableSamples : []
          }
        })
        // setlist(tempList)
      }
      setCurrentAchieveables(acieveableSamples ? acieveableSamples : [])
      setFilteredCO(filteredCostingOptions)
    }
  }, [currentMethodology, activeTabCo, list, activeRoundTab]);
  function toggleCO(tab) {
    if (activeTabCo !== tab) {
      setActiveTabCo(tab);
    }
  }
  function toggleRound(tab) {
    if (activeRoundTab !== tab) {
      setActiveRoundTab(tab);
      setActiveTabCo(0);
    }
  }
  var imgStyle = {
    width: "150px",
  };
  var roundclosedstyle = {
    color: "#b94e48",
    marginBottom: "5px"
  };
  var ModalFooterStyle = {
    display: "flex",
    justifyContent: "flex-Start",
  };
  const roundDisable = () => {
    let disableRound = false;
    let currentDetails = list[activeRoundTab]?.SampleDistributionMasters?.length ? (list[activeRoundTab]?.SampleDistributionMasters?.find((master) =>
      master.CostingOptionId == filteredCO[activeTabCo]?.id)?.SampleDistributionDetails.map(detail => { if (detail.id) { return detail.id } }).filter(a => a != undefined)) : []
    let currentRound = currentMethodology.InvitedVendors.filter(iv => !iv.IsDeleted).map(iv => iv.VendorCosts)?.flat().filter(vc => vc.CostOptionId == filteredCO[activeTabCo].id &&
      vc.RoundId == list[activeRoundTab]?.id)
    if (list.length > 0) {
      if (list[activeRoundTab]?.RoundClosedOn) {
        disableRound = true;
      }
      else
        if (currentDetails?.length || currentRound?.length) {
          if (currentMethodology.InvitedVendors.filter(iv => !iv.IsDeleted).map(iv => iv.VendorCosts)?.flat().filter(vc => vc.CostOptionId == filteredCO[activeTabCo].id
            && vc.RoundId == list[activeRoundTab]?.id).length) {
            disableRound = true;
          }
        }
        else
          if (currentMethodology.InvitedVendors.map(iv => iv.VendorCosts)?.flat().filter(vc => vc.CostOptionId == filteredCO[activeTabCo].id &&
            vc.IsSelected == true &&
            !vc.SampleDistributionDetailsId).length) {
            disableRound = false;
          }
          else {
            disableRound = false;
          }
    }
    return disableRound;
  };

  // const calculateRemaingSamples = () => {
  //   let remaingSamples = 0
  //   if (list.length) {
  //     let tempCostingOptions = filterationCO()
  //     if (tempCostingOptions[activeTabCo].CostingOptionData.sampleSizeMain) {
  //       remaingSamples = parseInt(tempCostingOptions[activeTabCo].CostingOptionData.sampleSizeMain) - (currentAchieveables.map(ca => ca.AchivableSamples).reduce((a, b) => parseInt(a) + parseInt(b) + 0, 0))
  //     }
  //     else {
  //       remaingSamples = parseInt(tempCostingOptions[activeTabCo].CostingOptionData.numberOfGroup) - (currentAchieveables.map(ca => ca.AchivableSamples).reduce((a, b) => parseInt(a) + parseInt(b) + 0, 0))
  //     }
  //     return list[activeRoundTab]?.RoundClosedOn ? list[activeRoundTab]?.SampleDistributionMasters?.find(
  //       (master) =>
  //         master.CostingOptionId ==
  //         tempCostingOptions[activeTabCo].id
  //     )?.RemainingSamples : remaingSamples
  //   }
  // }
  const calculateRemaingSamples = () => {
    let remaingSamples = 0
    if (list[activeRoundTab]?.SampleDistributionMasters[activeTabCo]?.CoSampleSize) {
      remaingSamples = parseInt(list[activeRoundTab]?.SampleDistributionMasters[activeTabCo]?.CoSampleSize) - (currentAchieveables.map(ca => ca.AchivableSamples).reduce((a, b) => parseInt(a) + parseInt(b) + 0, 0))
    }
    return list[activeRoundTab]?.RoundClosedOn ? list[activeRoundTab]?.SampleDistributionMasters?.find(
      (master) =>
        master.CostingOptionId ==
        currentMethodology.CostingOptions[activeTabCo].id
    )?.RemainingSamples : remaingSamples
  }
  function returnSplit() {
    return (
      <>
        <tr>
          <td
            className="width-textbox"
          // class="col-md-3"
          >
            Distribution Name<sup>(</sup>
            <sup className="requiredField">*</sup>
            <sup>)</sup>
          </td>
          {((list.length > 0 && list[activeRoundTab].SampleDistributionMasters.length > 0)
            ? list[activeRoundTab].SampleDistributionMasters?.find(
              (master) =>
                master.CostingOptionId == filteredCO[activeTabCo].id
            )?.SampleDistributionDetails
            : []
          )?.map((x, i) => (
            <td
              className="width-textbox"
            >
              <Label>
                <FormGroup>
                  <input
                    type="text"
                    value={x.DistributionName}
                    name={filteredCO[activeTabCo].name}
                    className="detailsInputBox"
                    onChange={(e) =>
                      splitNumber(e.target.value, "DistributionName", i)
                    }
                    maxLength={50}
                    disabled={ViewOnlyMode || (filteredCO.length && list.length && roundDisable())}
                  ></input>
                </FormGroup>
              </Label>
            </td>
          ))}
        </tr>

        {(_.head(
          filteredCO[
            activeTabCo
          ].CostingOptionSchema?.filter((a) => a.id?.includes("sampleSize"))
        ) ||
          _.head(filteredCO[
            activeTabCo
          ].CostingOptionSchema?.filter((a) =>
            a.id?.includes("numberOfGroup")
          )
          )) && (
            <tr>
              <td
                className={"width-textbox"}
              // class="col-md-3"
              >
                Distribution {
                  (
                    _.head(
                      filteredCO[
                        activeTabCo
                      ].CostingOptionSchema?.filter((a) =>
                        a.id?.includes("sampleSize")
                      )
                    ) ||
                    _.head(
                      filteredCO[
                        activeTabCo
                      ].CostingOptionSchema?.filter((a) =>
                        a.id?.includes("numberOfGroup")
                      )
                    )
                  )?.label
                }{" "}
                <sup>(</sup>
                <sup className="requiredField">*</sup>
                <sup>)</sup>
              </td>

              {((list.length > 0 && list[activeRoundTab].SampleDistributionMasters.length > 0)
                ? list[activeRoundTab].SampleDistributionMasters.find(
                  (master) =>
                    master.CostingOptionId ==
                    filteredCO[activeTabCo].id
                )?.SampleDistributionDetails
                : []
              )?.map((x, i) =>
                filteredCO[activeTabCo].CostingOptionData[
                  (
                    _.head(
                      filteredCO[
                        activeTabCo
                      ].CostingOptionSchema?.filter((a) =>
                        a.id?.includes("sampleSize")
                      )
                    ) ||
                    _.head(
                      filteredCO[
                        activeTabCo
                      ].CostingOptionSchema?.filter((a) =>
                        a.id?.includes("numberOfGroup")
                      )
                    )
                  )?.id
                ] != undefined ? (
                  <td
                    // style={{ "width": "0%" }}
                    className="width-textbox"
                  >
                    <FormGroup>
                      <input
                        type="number"
                        name={filteredCO[activeTabCo].name}
                        id={i}
                        min={0}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        pattern="[0-9]"
                        onChange={(e) => {
                          e.target.value < 0
                            ? toastr.error("Please Enter Valid Number")
                            : splitNumber(
                              parseInt(e.target.value),
                              "DistributionSize",
                              i
                            );
                        }}
                        value={x.DistributionSize}
                        disabled={ViewOnlyMode || (filteredCO.length && list.length && roundDisable())}
                        className="detailsInputBox"
                      />
                    </FormGroup>
                    {valid && (
                      <div className="requiredField">
                        Entered Number is invalid
                      </div>
                    )}
                  </td>
                ) : (
                  <td>No Sample Size Found</td>
                )
              )}
            </tr>
          )}

        <tr>
          <td
            // class="col-md-3"
            className={"width-textbox"}
          >
            City Coverage Split
          </td>
          {((list.length > 0 && list[activeRoundTab].SampleDistributionMasters.length > 0)
            ? list[activeRoundTab].SampleDistributionMasters.find(
              (master) =>
                master.CostingOptionId ==
                filteredCO[activeTabCo].id
            )?.SampleDistributionDetails
            : []
          )?.map((x, i) =>
            filteredCO[activeTabCo].CostingOptionData
              .citiesCoverage?.length > 0 ? (
              <td
                // style={{ "width": "0%" }}
                // class="col-md-3"
                className="width-textbox"
              >
                <FormGroup>

                  <Select
                    options={
                      filteredCO[activeTabCo]
                        .CostingOptionData.citiesCoverage
                    }
                    name={filteredCO[activeTabCo].name}
                    onChange={(e) => splitNumber(e, "CoverageDetails", i)}
                    value={x.CoverageDetails}
                    isDisabled={ViewOnlyMode || (filteredCO.length && list.length && roundDisable())}
                    isMulti
                  />
                </FormGroup>
                {validCity && (
                  <div className="requiredField">Please select the city</div>
                )}
              </td>
            ) : (
              <td>No Cities Found</td>
            )
          )}
        </tr>
      </>
    );
  }
  function splitNumber(value, name, id) {
    let tempInfo = _.cloneDeep(list);
    let splitsize = _.cloneDeep(
      list[activeRoundTab].SampleDistributionMasters[activeTabCo]
        .SampleDistributionDetails
    );
    splitsize[id][name] = value;
    tempInfo[activeRoundTab].SampleDistributionMasters[
      activeTabCo
    ].SampleDistributionDetails = splitsize;
    setlist(tempInfo);
  }
  function infoChange(activeRound, activeCo, name, value) {
    if (list.length) {
      let filteredCO = filterationCO()
      let splitCount = (list[activeRound].SampleDistributionMasters.find(
        (master) =>
          master.CostingOptionId == filteredCO[activeCo].id
      )?.SampleDistributionDetails)
        ? list[activeRound].SampleDistributionMasters.find(
          (master) =>
            master.CostingOptionId ==
            filteredCO[activeCo].id
        ).SampleDistributionDetails.length
        : 0;

      splitCount = parseInt(splitCount);

      let tempInfo = _.cloneDeep(list);
      let tempMaster = tempInfo[activeRound].SampleDistributionMasters.find(
        (master) => master.CostingOptionId == filteredCO[activeCo].id) ?
        tempInfo[activeRound].SampleDistributionMasters.find(
          (master) => master.CostingOptionId == filteredCO[activeCo].id) : {};
      if (
        name == "TargetSamples" ||
        name == "RemainingSamples" ||
        name == "NumberOfSplits"
      ) {
        tempMaster[name] = parseInt(value);
        if (name == "TargetSamples") {
          tempMaster.RemainingSamples =
            tempMaster.CoSampleSize - tempMaster.TargetSamples;
        }
      }
      // if (name == "NumberOfSplits") {
      //   if (parseInt(value) >= 0) {
      //     tempMaster.IsSplited = true;
      //   }
      //   defineSplits(tempMaster, splitCount, parseInt(value));
      // }
      setlist(tempInfo);
    }
  }
  function defineSplits(activeRound, activeCo) {
    if (list.length) {
      let filteredCO = filterationCO()
      let splitCountExisting = list[activeRound]?.SampleDistributionMasters[activeCo]?.SampleDistributionDetails?.length ? list[activeRound]?.SampleDistributionMasters[activeCo]?.SampleDistributionDetails?.length : 0
      let newValue = (list[activeRound]?.SampleDistributionMasters[activeCo]?.NumberOfSplits) ? list[activeRound]?.SampleDistributionMasters[activeCo]?.NumberOfSplits : ""
      let tempInfo = _.cloneDeep(list);
      if (tempInfo[activeRound]?.SampleDistributionMasters.length) {
        let tempMaster = tempInfo[activeRound]?.SampleDistributionMasters.find(
          (master) => master.CostingOptionId == filteredCO[activeCo].id)
        if (parseInt(newValue) >= 0) {
          tempMaster.IsSplited = true;
        }
        if (newValue < splitCountExisting) {
          for (var j = newValue; j < splitCountExisting; j++) {
            tempMaster.SampleDistributionDetails.pop();
          }
        } else if (
          tempMaster.NumberOfSplits == 0 ||
          tempMaster.NumberOfSplits == ""
        ) {
          tempMaster.SampleDistributionDetails = [];
        } else {
          for (var i = splitCountExisting; i < newValue; i++) {
            let temp = {
              DistributionName: "",
              DistributionSize: 0,
              CoverageDetails: null,
            };
            tempMaster.SampleDistributionDetails.push(temp);
          }
        }
        setlist(tempInfo);
      }
    }
  }
  // function closeRound() {
  //   dispatch(recordLoadStart());
  //   let tempRound = _.cloneDeep(list);
  //   let currentMasters = tempRound[activeRoundTab].SampleDistributionMasters.find(master => master.CostingOptionId == currentMethodology.CostingOptions[activeTabCo].id)
  //   if (currentMasters) {
  //     currentMasters.AchivableSamples = currentAchieveables
  //     currentMasters.RemainingSamples = calculateRemaingSamples()
  //   }
  //   if (validateSplit()) {
  //     tempRound[activeRoundTab].RoundClosedOn = new Date();
  //     Axios.post(
  //       "sampleSizeDistributionRounds/roundClosed",
  //       tempRound[activeRoundTab]
  //     )
  //       .then((res) => {
  //         // let tempResult=res.data.data
  //         SplitSampleInfo.forEach(info => {
  //           if (info.RoundName == list[activeRoundTab].RoundName) {
  //             info.RoundClosedOn = new Date();
  //             info.SampleDistributionMasters[0].RemainingSamples =
  //               info.SampleDistributionMasters[0].TargetSamples -
  //               info.SampleDistributionMasters[0].AchivableSamples.map(x => x.AchivableSamples).reduce((result, sm) => result + sm)
  //           }
  //         })
  //         if (res.data.data) {
  //           SplitSampleInfo.push(res.data.data)
  //           res.data?.data?.SampleDistributionMasters?.forEach(d => {
  //             if (d.SampleDistributionDetails?.length == 0) {
  //               project.CostingProfiles[project.CostingProfiles.length - 1].CountrySpecs[countryIndex].MethodologySpecs[methIndex].InvitedVendors?.filter(a => a.VendorCosts?.filter(c => !c.RoundId)?.length > 0)?.forEach(a => {
  //                 a.VendorCosts.forEach(c => {
  //                   if (c.CostOptionId == d.CostingOptionId) {
  //                     c.RoundId = res.data.data.id;
  //                   }
  //                 }
  //                 )
  //               }
  //               )
  //             }
  //           })
  //         }
  //         dispatch({
  //           type: currentProjectActions.UPDATE_NEW_PROJECT,
  //           newProject: project,
  //         });
  //         dispatch({ type: SET_BIDDING_SPLIT_DATA, data: SplitSampleInfo })
  //         setlist(SplitSampleInfo.filter(a => a.MethodologySpecId == currentMethodology.id));
  //         setRoundNo(SplitSampleInfo.filter(a => a.MethodologySpecId == currentMethodology.id))
  //         dispatch(recordLoadEnd());
  //         toastr.success("New Round Created Successfully!")
  //       })
  //       .catch((error) => {
  //         console.log(error)
  //         toastr.error("New Round Creation Failed!");
  //         dispatch(recordLoadEnd());
  //       });
  //   }
  //   else {
  //     toastr.error("Input Data is Invalid for split")
  //     dispatch(recordLoadEnd());
  //   }
  // }

  const calculateRemaingSamplesFix = (Coid, AchivableSamples) => {
    let remaingSamples = 0
    let tabCO = 0
    tabCO = currentMethodology.CostingOptions.findLastIndex(x => x.id == Coid)
    if (currentMethodology.CostingOptions[tabCO].CostingOptionData.sampleSizeMain) {
      remaingSamples = remaingSamples = parseInt(currentMethodology.CostingOptions[tabCO].CostingOptionData.sampleSizeMain) - (AchivableSamples.map(ca => ca.AchivableSamples).reduce((a, b) => parseInt(a) + parseInt(b) + 0, 0))
    }
    else {
      remaingSamples = parseInt(currentMethodology.CostingOptions[tabCO].CostingOptionData.numberOfGroup) - (AchivableSamples.map(ca => ca.AchivableSamples).reduce((a, b) => parseInt(a) + parseInt(b) + 0, 0))
    }
    return list[activeRoundTab]?.RoundClosedOn ? list[activeRoundTab]?.SampleDistributionMasters?.find(
      (master) =>
        master.CostingOptionId ==
        currentMethodology.CostingOptions[tabCO].id
    )?.RemainingSamples : remaingSamples
  }

  function getAchieveableSamples() {
    let acieveableSamples = []
    if (currentMethodology?.InvitedVendors) {
      currentMethodology.CostingOptions.map(co => {
        currentMethodology?.InvitedVendors?.filter(iv => iv.IsDeleted == false)?.map(iv => {
          let selectedIv = iv.VendorCosts.find(vc => vc.CostOptionId == co.id && vc.IsSelected == true)
          if (selectedIv) {
            acieveableSamples.push({
              InvitedVendorId: iv.VendorId,
              AchivableSamples: selectedIv.AchivableSamples ? selectedIv.AchivableSamples : 0,
              SelectedDate: selectedIv.SelectedDate,
              CostOptionId: co.id
            })
          }
        }).filter(a => a != undefined)
      })
    }
    return acieveableSamples
  }

  function closeRound() {
    dispatch(recordLoadStart());
    let tempRound = _.cloneDeep(list);
    tempRound[activeRoundTab].SampleDistributionMasters.forEach((master, i) => {
      master.AchivableSamples = getAchieveableSamples()?.filter(achieve => achieve.CostOptionId == master.CostingOptionId)
      master.RemainingSamples = calculateRemaingSamplesFix(master.CostingOptionId, master.AchivableSamples)
    })
    tempRound[activeRoundTab].SampleDistributionMasters = tempRound[activeRoundTab].SampleDistributionMasters.filter(x => x.BidRoundId == tempRound[activeRoundTab].id)
    // let currentMasters = tempRound[activeRoundTab].SampleDistributionMasters.find(master => master.CostingOptionId == currentMethodology.CostingOptions[activeTabCo].id)
    // if (currentMasters) {
    //   currentMasters.AchivableSamples = currentAchieveables
    //   currentMasters.RemainingSamples = calculateRemaingSamples()
    // }
    if (validateSplit()) {
      tempRound[activeRoundTab].RoundClosedOn = new Date();
      Axios.post(
        "sampleSizeDistributionRounds/roundClosed",
        tempRound[activeRoundTab]
      )
        .then((res) => {
          SplitSampleInfo.forEach(info => {
            if (info.RoundName == list[activeRoundTab].RoundName && info.id == list[activeRoundTab].id) {
              info.RoundClosedOn = new Date();
            }
          })
          if (res.data.data) {
            SplitSampleInfo.push(res.data.data)
            res.data?.data?.SampleDistributionMasters?.forEach(d => {
              if (d.SampleDistributionDetails?.length == 0) {
                project.CostingProfiles[project.CostingProfiles.length - 1].CountrySpecs[countryIndex].MethodologySpecs[methIndex]
                  .InvitedVendors?.filter(a => a.VendorCosts?.filter(c => !c.RoundId)?.length > 0)?.forEach(a => {
                    a.VendorCosts.forEach(c => {
                      if (c.CostOptionId == d.CostingOptionId) {
                        c.RoundId = res.data.data.id;
                      }
                    })
                  })
              }
            })
            dispatch({
              type: currentProjectActions.UPDATE_NEW_PROJECT,
              newProject: project,
            });

            toastr.success("New Round Created Successfully!")
          }
          else {
            dispatch(recordLoadEnd());
            toastr.success("Round Closed!")
          }
          //console.log(SplitSampleInfo)
          dispatch({ type: SET_BIDDING_SPLIT_DATA, data: SplitSampleInfo })
          setlist(SplitSampleInfo.filter(x => x.MethodologySpecId == tempRound[activeRoundTab].MethodologySpecId));
          setRoundNo(SplitSampleInfo.filter(x => x.MethodologySpecId == tempRound[activeRoundTab].MethodologySpecId))
          dispatch(recordLoadEnd());
        })
        .catch((error) => {
          console.log('catch', error);
          toastr.error("New Round Creation Failed!");
          dispatch(recordLoadEnd());
        });
    }
    else {
      toastr.error("Input Data is Invalid for split")
      dispatch(recordLoadEnd());
    }
  }

  function saveasdraft() {
    let vendor = false;
    const tempcostdata = _.cloneDeep(
      _.last(project.CostingProfiles).CountrySpecs
    );
    let error = 0;
    tempcostdata.forEach((s, i) =>
      s.MethodologySpecs.forEach((m, im) => {
        if (m.CostingType == 3) {
          if (m.InvitedVendors.filter(a => !a.IsDeleted)?.length == 0) {
            vendor = true;
          }
          m.InvitedVendors?.filter(a => !a.IsDeleted && a.label != "TCS" && a.label != "Internal")?.forEach((v, iv) => {
            if (v.ServiceType != 1) {
              v.VendorCosts.forEach(vc => {
                if (!vc.RoundId) {
                  error = error + 1;
                }
              }
              )
            }
            v.VendorCosts.forEach(a => a.IsEditable = true)
          });
        }
      })
    );
    const newProposal = update(_.last(project.CostingProfiles), {
      CountrySpecs: { $set: tempcostdata },
    });
    project.CostingProfiles[project?.CostingProfiles.length - 1] = newProposal;
    dispatch({
      type: currentProjectActions.UPDATE_NEW_PROJECT,
      newProject: project,
    });
    SaveBidding("open")

  }
  function SaveBidding(status) {
    var data = {};
    data["id"] = project.CostingProfiles[project.CostingProfiles.length - 1].id;
    data["BiddingStatus"] = status
    data["CountryName"] = project?.CommissioningCountry;
    data["IsSubmit"] = false;
    data["Message"] = (status == "close" ? "Bidding Closed" : "Bidding Saved");
    data["Error"] = false
    data["closeToastr"] = true
    data["type"] = "OPS"
    data["Project"] = project
    data["VendorBidDeadLine"] = _.last(project.CostingProfiles).VendorBidDeadLine
    data["VenodrBidDeadLineStatus"] = _.last(project.CostingProfiles).VenodrBidDeadLineStatus
    data["CountrySpecs"] = project.CostingProfiles[project.CostingProfiles.length - 1].CountrySpecs
    // dispatch(
    //   proposalActions.saveData(
    //     data,
    //     false,
    //     false,
    //     vendorreducer.biddingFiles
    //   )
    // );
    dispatch(recordLoadStart());
    Axios.post("vendors/project/bidding", data)
      .then((res) => {
        Axios
          .get("vendors/project/bidding", {
            params: {
              ProjectId: project.ProjectId,
              CostingProfileId: _.last(project.CostingProfiles).id,
            },
          })
          .then(async (res) => {
            res.data.data["VendorUpload"] = true;
            res.data.data["CloseRound"] = true;
            dispatch({
              type: currentProjectActions.UPDATE_NEW_PROJECT,
              newProject: res.data.data,
            });
            dispatch(
              currentCostingActions.updateProfile(_.last(res.data.data.CostingProfiles), null)
            );
          })
        closeRound();
        dispatch(recordLoadEnd());
      }).catch((error) => {
        toastr.error("Data Save Failed!");
        dispatch(recordLoadEnd());
      });
  }
  function filterationCO() {
    let zeroRemainingSamples = []
    let currentRounds = SplitSampleInfo?.filter(s => s.MethodologySpecId == currentMethodology.id)
    if (currentRounds.length > 1 && !_.last(currentRounds).RoundClosedOn) {
      // let lastActiveRound = currentRounds[currentRounds.length - 2]
      // zeroRemainingSamples = lastActiveRound.SampleDistributionMasters.filter(r => r.RemainingSamples == 0).map(rs => rs.CostingOptionId)

      zeroRemainingSamples = currentRounds?.filter(x => x.RoundClosedOn).flatMap(y => y.SampleDistributionMasters?.filter(r => r.RemainingSamples == 0))?.map(rs => rs.CostingOptionId)
    }
    if (list.length) {
      if (currentRounds.length > 1 && !list[activeRoundTab].RoundClosedOn) {
        return currentMethodology.CostingOptions.filter(co => !zeroRemainingSamples.includes(co.id))
      }
      else {
        return currentMethodology.CostingOptions.filter(co => list[activeRoundTab]?.SampleDistributionMasters.map(a => a.CostingOptionId).includes(co.id))
      }
    }
  }
  const getManagedSamples = () => {
    if (validateSplit()) {
      list[activeRoundTab].SampleDistributionMasters.forEach(master => {
        if (master.CostingOptionId == currentMethodology.CostingOptions[activeTabCo].id) {
          master.AchivableSamples = currentAchieveables
          master.RemainingSamples = calculateRemaingSamples()
          master.BidRoundId = list[activeRoundTab].id
        }
      })

      dispatch(recordLoadStart());
      Axios.post("sampleSizeDistributionRounds/", list[activeRoundTab])
        .then((res) => {
          res.data?.data?.SampleDistributionMasters?.forEach(d => {
            if (d.SampleDistributionDetails?.length == 0) {
              project.CostingProfiles[project.CostingProfiles.length - 1].CountrySpecs[countryIndex].MethodologySpecs[methIndex].InvitedVendors?.filter(a => a.VendorCosts?.filter(c => !c.RoundId)?.length > 0)?.forEach(a => {
                a.VendorCosts.forEach(c => {
                  if (c.CostOptionId == d.CostingOptionId) {
                    c.RoundId = res.data.data.id;
                  }
                }
                )
              }
              )
            }
          })
          dispatch({
            type: currentProjectActions.UPDATE_NEW_PROJECT,
            newProject: project,
          });
          dispatch(recordLoadEnd());
          SplitSampleInfo.filter(a => a.MethodologySpecId == currentMethodology.id)[activeRoundTab].id = res.data.data.id
          SplitSampleInfo.filter(a => a.MethodologySpecId == currentMethodology.id)[activeRoundTab].SampleDistributionMasters = res.data.data.SampleDistributionMasters
          dispatch({ type: SET_BIDDING_SPLIT_DATA, data: SplitSampleInfo })
          let tempSampleSplit = SplitSampleInfo.filter(a => a.MethodologySpecId == currentMethodology.id)
          tempSampleSplit.forEach(info => {
            info.SampleDistributionMasters.forEach((master) => {
              if (master.CostingOptionId == currentMethodology.CostingOptions[activeTabCo].id) {
                master.NumberOfSplits = master.SampleDistributionDetails.length ? master.SampleDistributionDetails.length : 0
              }
            })
          })
          if (tempSampleSplit.length)
            setlist(SplitSampleInfo.filter(a => a.MethodologySpecId == currentMethodology.id));
          setRoundNo(SplitSampleInfo.filter(a => a.MethodologySpecId == currentMethodology.id))
          cancelPopUp();
          toastr.success("Split Distribution Saved Successfully!");
        })
        .catch((error) => {
          toastr.error("Data Save Failed!");
          dispatch(recordLoadEnd());
        });
    } else {
      toastr.error("Please provide Unique Name and Samples must be equal to target");
    }
  };
  function validateSplit() {
    let isValid = true;
    let error = 0;
    list[activeRoundTab].SampleDistributionMasters.forEach(master => {
      if (!(_.uniq(master.SampleDistributionDetails.map(d => d.DistributionName)).length == master.SampleDistributionDetails.map(d => d.DistributionName).length)) {
        error = error + 1
      }
    }
    )
    // list[activeRoundTab].SampleDistributionMasters[
    //   activeTabCo
    // ].SampleDistributionDetails.find((nnn, index) => {
    //   return list[activeRoundTab].SampleDistributionMasters[
    //     activeTabCo
    //   ].SampleDistributionDetails.find(
    //     (x, ind) => x.DistributionName == nnn.DistributionName && index !== ind
    //   );
    // });

    let distributionSize = list[activeRoundTab].SampleDistributionMasters.map(master => {
      return (master.SampleDistributionDetails?.map(details => details.DistributionSize)
        ?.reduce((a, b) => parseInt(a) + parseInt(b) + 0, 0) == master.TargetSamples) ? true : master.SampleDistributionDetails.length == 0 ? true : false
    })

    let distributionSizeZero = list[activeRoundTab].SampleDistributionMasters.map(master => {
      return master.SampleDistributionDetails?.some(detail => detail.DistributionSize == 0)
    })?.every(ele => ele == true)

    if (distributionSize.some(s => s == false) || error > 0 || distributionSizeZero) {
      isValid = false;
    }
    if (list[activeRoundTab].SampleDistributionMasters.length) {
      if (list[activeRoundTab].SampleDistributionMasters[activeTabCo].SampleDistributionDetails.length == 0) {
        isValid = (list[activeRoundTab].SampleDistributionMasters[activeTabCo]?.TargetSamples) ? true : false
      }
    }

    return isValid;
  }
  return (
    <>
      <Modal
        isOpen={SplitSample}
        backdrop="static"
        toggle={cancelPopUp}
        centered={true}
        size="lg"
      >
        <ModalHeader toggle={cancelPopUp}>
          {/* <img src={logo} className="modallogo" /> */}
          <h1>{" "}</h1>
          <h3>Split Sample Management</h3>
        </ModalHeader>

        <ModalBody>
          {/* {JSON.stringify(list)} */}
          <Row>
            <Col md={12} className={"countryTabs tab-horizontal"}>
              <div className={"tab"}>
                <Nav tabs>
                  {roundNo?.map((round, indxRound) => (
                    <NavItem key={indxRound}>
                      <NavLink
                        className={classnames({
                          active: activeRoundTab === indxRound,
                        })}
                        onClick={() => {
                          toggleRound(indxRound);
                        }}
                      >
                        <b>{round.RoundName}</b>
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
                <TabContent>
                  {
                    list.length > 0 && (!(list[activeRoundTab]?.RoundClosedOn)) && list[activeRoundTab].id &&
                    currentMethodology?.InvitedVendors?.filter(iv => !iv.IsDeleted)?.map(iv => iv.VendorCosts)?.flat()
                      .filter(vc => vc.RoundId == list[activeRoundTab]?.id && vc.IsSelected).length > 0 &&
                    _.last(project.CostingProfiles)?.VenodrBidDeadLineStatus != "close" &&
                    //   // achivableSamplesValidation() &&
                    (
                      <Row>
                        <Col md={12}>
                          <Button
                            className={"Button-Danger"}
                            disabled={app.recordloading || calculateRemaingSamples() < 0 || ViewOnlyMode}
                            onClick={() => {
                              // closeRound();
                              saveasdraft();
                            }}
                          >
                            Close Round
                            {app.recordloading && <Spinner size="small" color="#495057" />}
                          </Button>
                        </Col>
                      </Row>
                    )}
                  <TabPane
                  // tabId={activeTabCo} key={activeTabCo}
                  >
                    <Row
                    // className={"split-sample-PopUp"}
                    >
                      {list[activeRoundTab]?.RoundClosedOn &&
                        <Col style={roundclosedstyle}>
                          <b>Note: This Round has been Closed !</b>
                        </Col>}
                      <Col md={12} className={"methodologyTabs"}>
                        <Nav tabs>
                          {list.length && filterationCO()?.map(
                            (co, indxCo) => (
                              <NavItem key={co.value}>
                                <NavLink
                                  className={classnames({
                                    active: activeTabCo === indxCo,
                                  })}
                                  onClick={() => {
                                    toggleCO(indxCo);
                                  }}
                                >
                                  <b>{co.Name}</b>
                                </NavLink>
                              </NavItem>
                            )
                          )}
                        </Nav>
                        <TabContent>
                          <Table>
                            {/* <h6 className="noteforpopup">NOTE:Sample Split Adjustments</h6> */}
                            <div className="oops-main-div">
                              <Row className="m-0 mb-2">
                                <Col>
                                  <Label>{(
                                    _.head(
                                      list.length && filterationCO()[
                                        activeTabCo
                                      ].CostingOptionSchema?.filter((a) =>
                                        a.id?.includes("sampleSize")
                                      )
                                    )
                                    ||
                                    _.head(
                                      list.length && filterationCO()[
                                        activeTabCo
                                      ].CostingOptionSchema?.filter((a) =>
                                        a.id?.includes("numberOfGroup")
                                      )
                                    )
                                  )?.label}</Label>
                                </Col>
                                <Col>
                                  <Input
                                    type="number"
                                    step="any"
                                    // value={list.length && Math.trunc(
                                    //   filterationCO()[
                                    //     activeTabCo
                                    //   ].CostingOptionData.sampleSizeMain ?
                                    //     filterationCO()[
                                    //       activeTabCo
                                    //     ].CostingOptionData.sampleSizeMain :
                                    //     filterationCO()[
                                    //       activeTabCo
                                    //     ].CostingOptionData.numberOfGroup)
                                    // }
                                    value={list[activeRoundTab]?.SampleDistributionMasters[activeTabCo]?.CoSampleSize}

                                    disabled
                                  ></Input>
                                </Col>
                              </Row>
                              <Row className="m-0 mb-2">
                                <Col>
                                  <Label>Samples to be Allocated in this Round</Label>
                                </Col>
                                <Col>
                                  <Input
                                    type="number"
                                    name="TargetSamples"
                                    value={
                                      (list.length > 0 && list[activeRoundTab].SampleDistributionMasters.length > 0)
                                        ? list[
                                          activeRoundTab
                                        ].SampleDistributionMasters[activeTabCo]
                                          ?.TargetSamples
                                        : 0
                                    }
                                    disabled
                                  ></Input>
                                </Col>
                              </Row>

                              <Row className="m-0 mb-2">
                                <Col>
                                  <Label>Remaining Samples</Label>
                                </Col>
                                <Col>
                                  <Input
                                    type="number"
                                    step="any"
                                    name="RemainingSamples"
                                    value={
                                      // (list.length > 0 && list[activeRoundTab].SampleDistributionMasters.length > 0)
                                      //   ? list[
                                      //     activeRoundTab
                                      //   ].SampleDistributionMasters[activeTabCo]
                                      //     ?.RemainingSamples
                                      //   :
                                      // (list.length > 0 && list[activeRoundTab].SampleDistributionMasters.length > 0
                                      //   &&
                                      //   list[activeRoundTab]?.SampleDistributionMasters[activeTabCo])?.RemainingSamples
                                      // ?list[activeRoundTab].SampleDistributionMasters[activeTabCo]?.RemainingSamples
                                      calculateRemaingSamples()
                                    }
                                    disabled
                                  ></Input>
                                  {calculateRemaingSamples() < 0
                                    && <h6 className="requiredField">Note: You have selected more achievable samples than required, please unselect some vendor.!</h6>}
                                </Col>
                              </Row>
                              {/* {calculateRemaingSamples()<0
                              &&
                              <Row >
                                <Col>
                                <h5 className="requiredField">Note: You have selected more vendors than required.!  </h5>
                                </Col>
                                </Row>} */}
                            </div>
                          </Table>

                          <div>
                            <Table
                              striped
                              bordered
                              hover
                              variant="dark"
                              className="table-split"
                            >
                              <tr>
                                <td className={"width-textbox"} width="50%" >
                                  Number of Splits<sup>(</sup>
                                  <sup className="requiredField">*</sup>
                                  <sup>)</sup>
                                </td>
                                <td width="50%">
                                  <InputGroup>
                                    <Input
                                      type="number"
                                      name={"NumberOfSplits"}
                                      min={0}
                                      max={10}
                                      // onKeyPress={(event) => {
                                      //   if (!/[0-9]/.test(event.key)) {
                                      //     event.preventDefault();
                                      //   }
                                      // }}
                                      // pattern="[0-9]"
                                      onChange={(e) => {
                                        (parseInt(e.target.value) < 0 || parseInt(e.target.value) > 10) ?
                                          toastr.error("Please Enter a Number between 1-10") :
                                          (parseInt(e.target.value) > calculateRemaingSamples()) ? toastr.error("Please Enter a less than Remaining Samples") :
                                            infoChange(
                                              activeRoundTab,
                                              activeTabCo,
                                              e.target.name,
                                              e.target.value
                                            )
                                      }
                                      }
                                      value={
                                        (list.length > 0 &&
                                          list[activeRoundTab]?.SampleDistributionMasters?.length > 0) ?
                                          (list[
                                            activeRoundTab
                                          ]?.SampleDistributionMasters[activeTabCo]?.NumberOfSplits ? list[
                                            activeRoundTab
                                          ].SampleDistributionMasters[activeTabCo].NumberOfSplits : "") : ""
                                      }
                                      disabled={ViewOnlyMode || (filteredCO.length && list.length && roundDisable())}
                                    ></Input>
                                    <Button color="primary" className="ml-1"
                                      onClick={() => defineSplits(activeRoundTab, activeTabCo)}
                                      disabled={ViewOnlyMode || ((filteredCO.length && list.length && roundDisable()) ||
                                        (list.length > 0 &&
                                          !list[activeRoundTab]?.SampleDistributionMasters[activeTabCo]?.NumberOfSplits))}

                                    >
                                      <FontAwesomeIcon
                                        icon={faUndo}
                                        title="Apply Splits"
                                        color="white" />
                                    </Button>
                                  </InputGroup>
                                </td>
                              </tr>
                            </Table>
                          </div>
                          <div style={{ overflowX: "scroll", overflowY: "scroll" }}>
                            <Table
                              striped
                              bordered
                              hover
                              variant="dark"
                              className="table-split"
                            >
                              {list.length && filteredCO.length && returnSplit()}
                            </Table>
                          </div>

                        </TabContent>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter style={ModalFooterStyle}>
          {_.last(project.CostingProfiles)?.VenodrBidDeadLineStatus != "close" && <Button
            color="primary"
            onClick={getManagedSamples}
            disabled={app.recordloading || calculateRemaingSamples() < 0 || ViewOnlyMode}
          >
            Submit
            {app.recordloading && <Spinner size="small" color="#495057" />}
          </Button>}
          <Button
            color="secondary"
            onClick={cancelPopUp}
            disabled={app.recordloading}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default SplitSampleManagement;