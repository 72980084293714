import React, { useEffect, useState } from "react";
// import { Form } from 'react-bootstrap';
import InfiniteScroll from "@alexcambose/react-infinite-scroll";
import Switch from "react-bootstrap/esm/Switch";
import { Col, Row, Button, Input, Modal,ModalHeader,ModalBody,ModalFooter, } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import _ from "lodash";
import ExpandableRows from "./RequestBoardExpandableItem";
import Navbar from "../../components/RequestsNavbar";
import RecordsSpinner from "../../components/RecordsSpinner";
import RecordsBadge from "../../components/RecordsBadge";
import DashboardLayout from "../../layouts/RequestsBoardLayout";
import { getMultiOptions } from "../../utils/codeLabels";
import axios from "../../axios-interceptor";
import Spinner from "../../components/Spinner";
import logo from "../../assets/img/avatars/modal-logo.png";
import {
  handleRequestProps,
  appendRequests,
  setRequests,
  setIndividualRequest,
  getRequestTypes,
  setRequestTypes
} from "../../redux/actions/requestsActions";
import Select from "react-select";
import SelectWrapper from "../../components/SelectWrapper/SelectWrapper";
import './request-board.css';

const RequestsBoard = () => {
  let { requestId } = useParams();
  const localPageload = useSelector(({ app }) => app.localPageload);
  const recordloading = useSelector(({ app }) => app.recordloading);
  const reqs = useSelector(({ requests }) => requests.requests);
  const dispatch = useDispatch();
  const history = useHistory();
  const [infiniteLoad, setInfiniteLoad] = useState(false);
  const [calledRequests, setCalledRequests] = useState(false);
  const hasMore = useSelector(({ requests }) => requests.hasMore);
  
  const codeLabels = useSelector(({ codeLabels }) => codeLabels);
  const totalItems = useSelector(({ requests }) => requests.totalItems);
  const requestsList = useSelector(({ requests }) => requests.items);
  const requestTypesList =
    useSelector(({ requests }) => requests.requestTypes) || [];
  const [searchList, setSearchList] = useState({});
  const userEmail = useSelector(({ user }) => user.userRecord.Email);
  const userInfo = useSelector(({ user }) => user.userRecord);
  const pageloaded = useSelector(({ app }) => app.pageloaded);
  const [objectData, setObjectJson] = useState({});
  const userCountries =
    useSelector(({ codeLabels }) => codeLabels.UserScopeOptions) || [];
  const [searchKey, setSearchKey] = useState("projectId");
  const [searchVal, setSearchVal] = useState("");
  const [projectObjectData, setProjectObjectJson] = useState({});
  const [filterByObjectData, setFilterByObjectJson] = useState({});
  const [typeObjectData, setTypeObjectJson] = useState({});
  const [countryObjectData, setCountryObjectJson] = useState({});
  const [searchRequestType, setSearchRequestType] = useState('');
  const [defalutValue,setDefaultValue]=useState([{ id: "assignedTo", value: "All Requests I Am Assigned To" }])
  const [statusOjectData,setSatusObjectData]=useState({});
  const [showSaveModal,setShowSaveModal]=useState(false);
  const [filterName,setFilterName]=useState("");
  const [getFilterData, setGetFilterData]=useState([]);
  const [selectedFilter,setSelectedFilter]=useState({});
  const [filteredSerchList, setFilteredSerchList]=useState({});
  const FilterAllRequests = useSelector(({ requests }) => requests.FilterAllRequests);

  useEffect(() => {

   if(FilterAllRequests){

   }

  },[FilterAllRequests]);
  
  useEffect(() => {
    if(reqs) {
      window.scrollTo(0,0);
    }    
  },[reqs]);
  
  useEffect(() => {

    if (
      requestId &&
      (!requestsList || !requestsList.length) &&
      !calledRequests
    ) {
      dispatch(setIndividualRequest(requestId));
    }
  });
  //TODO: Load from codelabels "RequestFilterOptions" once ready.
  const requestOwnerFilter = [
    { id: "allRequests", value: "All Requests " },
    { id: "createdBy", value: "All Requests I Created" },
    { id: "assignedTo", value: "All Requests I Am Assigned To" },
    { id: "unassignedTo", value: "All Unassigned Requests" },
  ];
  const requestStatusFilter=[
    { id: "inProgress", value: "Open" },
    { id: "closed", value: "Closed" },
  ];
  //TODO: load from Request Type later. dynamic based on request country >> Business Unit
  const requestTypeFilter = requestTypesList.map((data) => {
    return {
      id: data.RequestTypeName,
      value: data.RequestTypeName,
    };
  });
  //TODO: load from user access countries and then get labels
  const requestCountriesFilter = userCountries.map((country) => {
    return {
      id: country.id,
      value: country.Label,
      code: country.Code,
    };
  });
  const addSearchCountry = async (value, key) => {
    let searchlist = { ...searchList };
    let countryJson = { ...countryObjectData };
    let typeJson = { ...typeObjectData };
    searchlist[key] = value;
    setSearchList({ ...searchlist });
    if (value && value.length > 0) {
      dispatch(
        getRequestTypes({
          countryCode: value ? value.map((data) => data.value) : [],
        })
      );
      countryJson = {
        countryCode: value.map((data) => data.code),
      };
    } else {
      dispatch(setRequestTypes());
      delete countryJson.countryCode;
    }
    delete typeJson.requestType;
    setCountryObjectJson(countryJson);
    setTypeObjectJson(typeJson);
    let objectJson = {
      ...filterByObjectData,
      ...countryJson,
      ...typeJson,
      ...projectObjectData,
      ...statusOjectData,
    };
    setObjectJson(objectJson);
    addSearchRequestType([], "requestType", true);
    dispatch(setRequests(objectJson));
  };
  const addSearchQuery = (value, key) => {
    if (key === "clear") {
      setSearchList({});
      setObjectJson({});
      setCountryObjectJson({});
      setFilterByObjectJson({});
      setTypeObjectJson({});
      setProjectObjectJson({});
      setSearchVal("");
      setSearchKey("projectId");
      setDefaultValue([{ id: "allRequests", value: "All Requests " }])
      setSatusObjectData({});
      dispatch(setRequestTypes());
      setSearchRequestType("");
      setSelectedFilter({});
      return;
    }
    let searchlist = { ...searchList };
    let filterJson = {};
    searchlist[key] = value;
    setSearchList({ ...searchlist });

    if (key === "requestOwner") {
      if (value.value === "createdBy") {
        filterJson = {
          RequestorEmail: userEmail.toLowerCase(),
        };
      } else if (value.value === "assignedTo") {
        filterJson = {
          agentEmail: userEmail.toLowerCase(),
        };
      } else if (value.value === "unassignedTo") {
        filterJson = {
          agentEmail: null,
        };
      } else if (value.value === "inProgress") {
        filterJson = {
          isClosed: false,
        };
      } else if (value.value === "closed") {
        filterJson = {
          isClosed: true,
        };
      } else if (value.value === "allRequests") {
        filterJson = {};
      }
    }
    setFilterByObjectJson(filterJson);
    let objectJson = {
      ...filterJson,
      ...countryObjectData,
      ...typeObjectData,
      ...projectObjectData,
      ...statusOjectData,
    };
    setObjectJson(objectJson);
    dispatch(setRequests(objectJson));
  };
  const addStatusQuery=(value,key)=>{

    let searchlist = { ...searchList };
    let statusJson = {};
    searchlist[key] = value;
    setSearchList({ ...searchlist });
    if(key==="requestStatus"){
      if(value.value==="inProgress"){
        statusJson = {
        isClosed: false,
        };
      }
      else if(value.value === "closed"){
        statusJson = {
        isClosed: true,
        };
      }
    }
    setSatusObjectData(statusJson);
     let objectJson = {
        ...filterByObjectData,
        ...countryObjectData,
        ...typeObjectData,
        ...projectObjectData,
        ...statusJson,
      };
    setObjectJson(objectJson);
      dispatch(setRequests(objectJson));  
  };
  const addSearchRequestType = (value, key, flag = false) => {
    if (key === "clear") {
      setSearchList({});
      setObjectJson({});
      setCountryObjectJson({});
      setFilterByObjectJson({});
      setTypeObjectJson({});
      setProjectObjectJson({});
      setSearchVal("");
      setSatusObjectData({});
      setSearchKey("projectId");
      setSelectedFilter({});
      return;
    }
    let searchlist = { ...searchList };
    let typeJson = { ...typeObjectData };
    let typeValue={...searchRequestType};
    searchlist[key] = value;
    if (!flag) {
      setSearchList({ ...searchlist });
      setSearchRequestType(value);
    }
    if (value && value.length > 0)
      typeJson = {
        requestType: value.map((data) => data.value),
      };
    else {
      delete typeJson.requestType;
      setSearchRequestType("");
    }
    setTypeObjectJson(typeJson);
    let objectJson = {
      ...filterByObjectData,
      ...countryObjectData,
      ...typeJson,
      ...projectObjectData,
      ...statusOjectData,
    };
    setObjectJson(objectJson);
    if (!flag) dispatch(setRequests(objectJson));
  };
  const fetchMoreData = () => {
    setInfiniteLoad(true);
    if (requestsList?.length >= totalItems) {
      setInfiniteLoad(false);
      dispatch(handleRequestProps("hasMore", false));
      return;
    }
    axios
        .post("/requests/allrequests?limit=30&offset=" + requestsList.length, reqs)
        .then((response) => {
          let filteredItems = response.data.items;
          let totalCount = response.data.totalItems;
          dispatch(appendRequests(filteredItems, totalCount));
          setInfiniteLoad(false);
        })
        .catch((err) => {
          setInfiniteLoad(false);
            //console.log(err);
        });
  };

  const SearchBy = [
    { Code: "projectId", Label: "Project ID" },
    { Code: "projectName", Label: "Project Name" },
    { Code: "opNumber", Label: "OP Number" },
    { Code: "requestedBy", Label: "Requested By" },
    { Code: "projectOwner", Label: "Project Owner" },
    { Code: "accountName", Label: "Account Name" },
    { Code: "projectMgr", Label: "Project Manager" },
  ];
  function isEqual(a, b){
    return a?.sort()?.join() === b?.sort().join();
  }


  const saveFilterHandler=(e)=>{
    let isExisting=false;
    setShowSaveModal(true);
    // getFilterInfo();
    getFilterData.forEach((e)=>{
      let country=countryObjectData.countryCode;
      let requesttype=typeObjectData.requestType;
      let status=statusOjectData.isClosed;
      if(isEqual(e.CriteriaData.countryCode,country) && isEqual(e.CriteriaData.requestType,requesttype) && e.CriteriaData.isClosed === status){
        if(filterByObjectData.hasOwnProperty("RequestorEmail")){
          if(e.CriteriaData.RequestorEmail === filterByObjectData.RequestorEmail){
                isExisting=true;
                return;
          }
          else{
            isExisting=false;
            return;
          }
        }else if(filterByObjectData.hasOwnProperty("agentEmail")){
          if(e.CriteriaData.agentEmail === filterByObjectData.agentEmail){
                isExisting=true;
                return;
          }
          else{
            isExisting=false;
            return;
          }
        }
        isExisting=true;
        return;
      }
    })
    if(isExisting){
      toastr.error("This filter Criteria is already present");
      setShowSaveModal(false);
        return;
    }else{
      setShowSaveModal(true);
      return;
    }
    setFilterName('');
  };
  const filterNameHandler=(event)=>{
      setFilterName(event.target.value);
  };
  const getFilterInfo= ()=>{
    axios
    .get(`/filterCriteria/details?UserId=${userInfo.id}&Source=RequestBoard`)
    .then((res) => {
      setGetFilterData(res.data.data);
    })
    .catch((err) => {});
  }
  const savefilterNameHandler=()=>{

    setShowSaveModal(!showSaveModal);
    if(!filterName.trim()){
      toastr.error("Filter name is empty");
      setShowSaveModal(true);
      return;
    }
    let CriteriaData={
      ...filterByObjectData,
      ...countryObjectData,
      ...typeObjectData,
      ...statusOjectData,
    }
    if(getFilterData.findIndex((e)=>e.CriteriaName === filterName)!=-1){
      toastr.error(`${filterName} Already exists`);
      setShowSaveModal(true);
      return;
    }
    const filterInfo={
      Source: "RequestBoard",
      CriteriaName: filterName,
      UserId: userInfo.id,
      CriteriaData: CriteriaData,
    };
    console.log("filterInfo",filterInfo);
    axios
      .post("/filterCriteria", filterInfo, {})
      .then((res) => {
        
        console.log("res.data?.data?.id",res.data.data.CriteriaName);
        // // setSelectedFilter(res.data.data.CriteriaName);
        getFilterInfo();
      })
      .catch((err) => {});
    toastr.success(`${filterName} successfully saved`);
    setFilterName('');
  };

  const filterListHandler=(value,key)=>{
    let res={};
    res[key]=value;
    setSelectedFilter(res);
    getSelectedFilterData(res);
  };
  const getSelectedFilterData=(res)=>{
    console.log(selectedFilter);
    let objectJson={};
    let result=[]
    let resObject={};
    getFilterData.forEach((e)=>{
      let selectedId=res["SelectedValue"]?.value;
      if(e?.id===selectedId){
        objectJson=e.CriteriaData;
        return;
      }
    });
    dispatch(setRequests(objectJson));
    if(objectJson?.hasOwnProperty("RequestorEmail")){
      if(objectJson.RequestorEmail.length>0){
        let indexValue = requestOwnerFilter.findIndex(((e)=>e.id === "createdBy"));
        resObject["value"] = requestOwnerFilter[indexValue].id;
        resObject["label"] = requestOwnerFilter[indexValue].value;
        searchList["requestOwner"] = resObject;
        setFilterByObjectJson({"RequestorEmail" :objectJson["RequestorEmail"]});
        resObject={};
      }
    }else if (objectJson?.hasOwnProperty("agentEmail")){
      if(objectJson.agentEmail.length>0){
        let indexValue = requestOwnerFilter.findIndex(((e)=>e.id === "assignedTo"));
        resObject["value"] = requestOwnerFilter[indexValue].id;
        resObject["label"] = requestOwnerFilter[indexValue].value;
        searchList["requestOwner"] = resObject;
        setFilterByObjectJson({"agentEmail" :objectJson["agentEmail"]});
        resObject={};
      }
      else if(objectJson.agentEmail === null){
        let indexValue = requestOwnerFilter.findIndex(((e)=>e.id === "unassignedTo"));
        resObject["value"] = requestOwnerFilter[indexValue].id;
        resObject["label"] = requestOwnerFilter[indexValue].value;
        searchList["requestOwner"] = resObject;
        setFilterByObjectJson({"agentEmail" :objectJson["agentEmail"]});
        resObject={};
      }
    }
    else{
      let indexValue = requestOwnerFilter.findIndex(((e)=>e.id === "allRequests"));
      resObject["value"] = requestOwnerFilter[indexValue].id;
      resObject["label"] = requestOwnerFilter[indexValue].value;
      searchList["requestOwner"] = resObject;
      setFilterByObjectJson({});
      resObject={};
    }


    let ar=[]
    if(objectJson?.hasOwnProperty("countryCode")){
      for(let i of objectJson["countryCode"]){
        let indexValue = requestCountriesFilter.findIndex(((e)=>e.code===i));
        resObject["value"]=requestCountriesFilter[indexValue].id;
        resObject["label"]=requestCountriesFilter[indexValue].value;
        resObject["code"]=requestCountriesFilter[indexValue].code;
        result.push(resObject);
        resObject={};
        ar.push(requestCountriesFilter[indexValue]);
      }
      dispatch(
        getRequestTypes({
          countryCode: ar ? ar.map((data) => data.id) : [],
        })
      );
      searchList["requestCountry"]=result;
      setCountryObjectJson({"countryCode" : objectJson["countryCode"] });
      result=[];
    }
    else{
      delete searchList["requestCountry"];
      setCountryObjectJson({});

    }
    if(objectJson?.hasOwnProperty("requestType")){
      for(let i of objectJson["requestType"]){
        console.log(i);
        resObject["value"]=i;
        resObject["label"]=i;
        result.push(resObject);
        resObject={};
      }
      searchList["requestType"]=result;
      setTypeObjectJson({"requestType" : objectJson["requestType"]});
      setSearchRequestType(result);
      result=[];
    }
    else{
      delete searchList["requestType"];
      setTypeObjectJson({});
      setSearchRequestType('');
    }
    if(objectJson?.hasOwnProperty("isClosed")){
      if(objectJson.isClosed===true){
        resObject["value"]="Closed";
        resObject["label"]="Closed";
        searchList["requestStatus"]=resObject;
        setSatusObjectData({"isClosed" : objectJson["isClosed"]});
        resObject={};
      }
      else if(objectJson.isClosed===false){
        resObject["value"]="Open";
        resObject["label"]="Open";
        searchList["requestStatus"]=resObject;
        setSatusObjectData({"isClosed" : objectJson["isClosed"]});
        resObject={};
      }
    }
    else{
      delete searchList["requestStatus"];
      // setSatusObjectData({});
    }
  };
  const addSearch = (value, key) => {
    if (key === "clear") {
      setSearchList({});
      setObjectJson({});
      setCountryObjectJson({});
      setFilterByObjectJson({});
      setTypeObjectJson({});
      setProjectObjectJson({});
      setSearchVal("");
      setSatusObjectData({});
      setSearchKey("projectId");
      dispatch(setRequestTypes());
      setSelectedFilter({});
      return;
    }
    let projectJson = { ...projectObjectData };
    setSearchList({ ...searchList, [key]: value });
    if (key == "searchCode") {
      setSearchKey(value);
      setSearchVal("");
      projectJson = {
        [value]: "",
      };
      setProjectObjectJson(projectJson);
    } else if (key == "searchValue") {
      setSearchVal(value);
      if (searchKey === "requestedBy" && value.indexOf(" ") >= 0) {
        value = value.replaceAll(/\s/g, ".");
      }
      if (value.length >= 2 || value.length === 0) {
        projectJson = {
          [searchKey]: value,
        };
        setProjectObjectJson(projectJson);
        let objectJson = {
          ...projectJson,
          ...typeObjectData,
          ...filterByObjectData,
          ...countryObjectData,
          ...statusOjectData,
        };
        setObjectJson(objectJson);
        dispatch(setRequests(objectJson));
      }
    }
  };

  // const callDebounceFunc = useCallback(
  //     debounce((e, code) =>  addSearch(e, 'searchValue', code), 300), []
  // );

  const changeHandler = (event) => {
    addSearch(event.target.value, "searchValue");
    //callDebounceFunc(event.target.value, code);
  };
  useEffect(() => {
    addSearchQuery({label:"All Requests I Am Assigned To",value:"assignedTo"},"requestOwner")
    dispatch(handleRequestProps("hasMore", false));
    getFilterInfo();
  }, []);
  const getNameFromMail = (mail) => {
    let allMails = mail?.split(",");
    if (allMails)
      return allMails
        .map((mail) => {
          return _.head(mail.toLowerCase().split("@")).replaceAll(".", " ");
        })
        .join(", ");
    else return "";
  };
  const data = {
    tableColumns: [
      {
        dataField: "id",
        text: "ID",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "5%" };
        },
      },
      {
        dataField: "ProjectId",
        text: "PROJECT ID",
        sort: true,
        // headerStyle: (colum, colIndex) => {
        //     return { width: "12%" };
        // },
        formatter: (cell, row) => (
          <span>{row.CostingProfile?.Project.ProjectId}</span>
        ),
      },
      {
        dataField: "ProjectName",
        text: "PROJECT NAME",
        sort: true,
        formatter: (cell, row) => (
          <span>{row.CostingProfile?.Project.ProjectName}</span>
        ),
      },
      {
        dataField: "RequestType",
        text: "REQUEST TYPE",
        sort: true,
        formatter: (cell, row) => {
            //row.CostingProfile.isMultiCountry>0?{if (row.ParentId == null)?{row.RequestType + "" }:""}:row.RequestType
          return(<span>{(row.CostingProfile.isMultiCountry>0 && row.ParentId == null && row.RequestType !== "Approval")?row.RequestType+"- Parent Request":(row.CostingProfile.isMultiCountry>0 && row.ParentId !== null)?row.RequestType+"- Child Request":row.RequestType}</span>)
        }
      },
      {
        dataField: "FieldingCountries",
        text: "Fielding Countries",
        sort: true,
        formatter: (cell, row) => {
          let fcarray = [];
          let fc=null;
          if(row.CostingProfile.isMultiCountry>0 && row.ParentId == null){
            row.CostingProfile.FieldingCountries.split(',').map(x=>{
              let z =  getMultiOptions(
                codeLabels.FieldingCountriesOptions,
                x
                )[0].label;
              fcarray.push(z)
            });
            fc = fcarray.join(', ');
          }else if(row.CostingProfile.isMultiCountry>0 && row.ParentId !== null){
            let z = getMultiOptions(
              codeLabels.FieldingCountriesOptions,
              row.CountryCode
              )[0].label;
            fcarray.push(z)
          fc = fcarray.join();
          }else{
            fc = null;
          }
          return(<span>{fc}</span>)},
      },
      {
        dataField: "ProfileName",
        text: "COSTING PROFILE",
        sort: true,
        formatter: (cell, row) => (
          <span>
            {"#"}
            {row.CostingProfile?.ProfileNumber}{" "}
            {row.CostingProfile?.ProfileName}
          </span>
        ),
      },
      {
        dataField: "RequestedBy",
        text: "REQUEST BY",
        sort: true,
        // formatter: (cell, row) => <span>{row.RequestorEmail}</span>,
        formatter: (cell, row) => {
          return row.RequestorEmail ? (
            <span className="capitalize">
              {getNameFromMail(row.RequestorEmail)}
            </span>
          ) : (
            ""
          );
        },
      },
      {
        dataField: "AssignedTo",
        text: "ASSIGNED TO",
        sort: true,
        formatter: (cell, row) => {
          return row.AssignedTo ? (
            <span className="capitalize">
              {getNameFromMail(row.AssignedTo)}
            </span>
          ) : row.AgentEmail ? (
            <span className="capitalize">
              {getNameFromMail(row.AgentEmail)}
            </span>
          ) : (
            ""
          );
          // if (row.AgentEmail) {
          //     return row.AgentEmail.toLowerCase()
          //         .split("@")[0]
          //         .split(".")

          //         .join(" ");
          // } else {
          //     return "";
          // }
        },
      },
      {
        dataField: "createdAt",
        text: "CREATED DATE",
        sort: true,
        formatter: (cell, row) => (
          <span>{cell ? cell.split().map(x=>{
            let fulldate = new Date(x)
            let date = _.head(x.split("T"));
            let time = fulldate.toLocaleTimeString('en',
            { timeStyle: 'short', hour12: false });
            return(date +"  "+time)
          }) : ""}</span>
        ),
      },
      {
        dataField: "IsClosed",
        text: "STATUS",
        sort: true,
        formatter: (cell, row) =>
        
          row.IsClosed ? (
        
            <span style={{ color: "grey", fontWeight: "bold" }}>
              {"Closed"}
            </span>
          ) : row.AgentEmail ? (
            <span style={{ color: "#00b700", fontWeight: "bold" }}>
              {"Open"}
            </span>
          ) : (
            <span style={{ color: "#e6e631", fontWeight: "bold" }}>
              {"Unassigned"}
            </span>
          ),
      },
    ],
    tableData: requestsList && requestsList.length ? requestsList : [],
  };
    
  return (
    <div className="request_board">
    <DashboardLayout navbar={<Navbar headerTitle="REQUESTS BOARD" header_nav={true}/>}>
      {localPageload ||  recordloading ? (
        <div id="pageCoverSpin"></div>
      ) : null}
      <>
        {requestsList ? (
          <>
            <div className="filter-container">
            <Row>
              <Col lg="5" md="5" sm="12" xs="12" className="mb-3">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Filter by</span>
                  </div>
                  <Select
                    className="react-select-container  custom-select-box"
                    // placeholder="All Requests I Am Assig..."
                    options={requestOwnerFilter.map((c) => {
                      return {
                        value: c.id,
                        label: c.value,
                      };
                    })}
                    onChange={(select) => {
                      addSearchQuery(select, "requestOwner");
                    }}
                      //  value={{label: "ccfffd", value:"ccff"}}
                      //  value={defalutValue?.map((v)=>{return {label:v.value,value:v.value}})}
                    value={
                      searchList && searchList.requestOwner
                        ? searchList.requestOwner
                        : defalutValue?.map((v)=>{return {label:v.value,value:v.value}})
                    }
                  />
                </div>
              </Col>
              <Col lg="5" md="5" sm="12" xs="12" className="mb-3">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Country</span>
                  </div>
                  <Select
                    isMulti
                    className="react-select-container  custom-select-box custom-select-box"
                    placeholder="All Countries I Have Access To"
                    options={requestCountriesFilter.map((c) => {
                      return {
                        value: c.id,
                        label: c.value,
                        code: c.code,
                      };
                    })}
                    onChange={(select) =>
                      addSearchCountry(select, "requestCountry")
                    }
                    value={
                      searchList && searchList.requestCountry
                        ? searchList.requestCountry
                        : null
                    }
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="5" md="5" sm="12" xs="12" className="mb-3">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      Type&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </div>
                  <Select
                    isMulti
                    className="react-select-container  custom-select-box"
                    placeholder="All Request Types"
                    options={requestTypeFilter.map((c) => {
                      return {
                        value: c.id,
                        label: c.value,
                      };
                    })}
                    onChange={(select) =>
                      addSearchRequestType(select, "requestType")
                    }
                    value={searchRequestType}
                    noOptionsMessage={() => "Please select country"}
                  />
                </div>
              </Col>
              <Col lg="5" md="5" sm="12" xs="12" className="mb-3">
                <div className="input-group">
                  <SelectWrapper
                    type="select"
                    id="reqBoardSearch"
                    name="reqBoardSearch"
                    className="col-md-3 col-lg-3 p-0 width-150"
                    onChange={(e) => {
                      addSearch(e.target.value, "searchCode");
                    }}
                    value={searchKey}
                  >
                    {SearchBy.map((opt, index) => {
                      return (
                        <option key={index} value={opt.Code}>
                          {opt.Label}
                        </option>
                      );
                    })}
                  </SelectWrapper>
                  <Input
                    type="text"
                    placeholder="Search Projects..."
                    aria-label="Search"
                    className="col-md-7"
                    value={searchVal}
                    onChange={(e) => changeHandler(e)}
                  />
                </div>
              </Col>
              {(searchList.requestOwner ||
                searchList.requestCountry ||
                searchRequestType ||
                searchVal || searchList.requestStatus || selectedFilter.SelectedValue) && (
                <Col lg="1" md="1" sm="2" xs="2" className="mb-1">
                  <Button
                    color="secondary"
                    style={{ height: "40px", width: "115px", "margin-top": "-40px" }}
                    
                    onClick={() => {
                      dispatch(setRequests());
                      addSearchQuery({}, "clear");
                      // addSearchCountry([], "clear")
                      // addSearchRequestType([], "clear")
                      // ; history.replace("/requests")
                    }}
                  >
                    Clear Filters
                  </Button>
                </Col>
              )}
            </Row>
            <Row>
            <Col lg="5" md="5" sm="12" xs="12" className="mb-3">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Status</span>
                  </div>
                  <Select
                    className="react-select-container  custom-select-box"
                    placeholder="All Status"
                    options={requestStatusFilter.map((c) => {
                      return {
                        value : c.id,
                        label: c.value,
                      };
                    })}
                    onChange={(select) => {
                      addStatusQuery(select, "requestStatus");
                    }}
                      //  value={{label: "ccfffd", value:"ccff"}}
                      //  value={defalutValue?.map((v)=>{return {label:v.value,value:v.value}})}
                    value={
                      searchList && searchList.requestStatus
                        ? searchList.requestStatus
                        : null
                    }
                  />
                </div>
              </Col>
              <Col lg="5" md="5" sm="12" xs="12" className="mb-3">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">My Filters</span>
                  </div>
                  {/* <SelectWrapper
                    type="select"
                    id="reqBoardSearch"
                    name="reqBoardSearch"
                    className="col-md-3 col-lg-3 p-0 width-400"
                    onChange={(e) => {
                      addSearch(e.target.value, "searchCode");
                    }}
                    value={selectedFilter}
                  >
                  
                  </SelectWrapper> */}
                  <Select
                    className="react-select-container  custom-select-box"
                    placeholder="My Filter List"
                    options={getFilterData.map((c)=>{
                      return {
                        value: c.id,
                        label: c.CriteriaName,
                      };
                    })}
                    onChange={(select)=>
                      filterListHandler(select,"SelectedValue")
                    }
                    value={selectedFilter?.SelectedValue ? selectedFilter?.SelectedValue : null }
                  />
                </div>
              </Col>
              <Col lg="1" md="1" sm="2" xs="2" className="mb-1">
                  <Button
                    color="secondary"
                    style={{ height: "48px", width: "115px", "margin-top": "-40px" }}
                    
                    onClick={() => {saveFilterHandler()}}  
                  >
                    Save as My Filter
                  </Button>
                </Col>
            </Row>
            </div>
            {/* <Row className="align-items-start mb-3">
              <Col>
                <h4>
                  Showing {requestsList.length} of {totalItems} requests
                </h4>
              </Col>
            </Row> */}
            {pageloaded ? (
              <div className="infinite-container">
              <InfiniteScroll className="overflow-initial"
                loadMore={fetchMoreData}
                hasMore={hasMore}
                isLoading={infiniteLoad}
                loading={<div id="pageCoverSpin"></div>}
                noMore={requestsList.length > 10 ? <RecordsBadge back_top={true} recordTypeLabel="requests" /> : false}
                initialLoad={false}
                scrollableTarget="scrollableDiv"
              >
                <ExpandableRows currentRequest = {data} currentFilter = {objectData}></ExpandableRows>
              </InfiniteScroll>
              </div>
            ) : (
              <div id="pageCoverSpin"></div>
            )}
          </>
        ) : null}
         <Modal
        centered={true}
        isOpen={showSaveModal}
        size="md"
        toggle={() => {
          setShowSaveModal(!showSaveModal);
        }}
      >
        <ModalHeader
          toggle={() => {
            setShowSaveModal(!showSaveModal);
            
          }}
        >
          {/* <img src={logo} className="modallogo" /> */}
        </ModalHeader>

        <ModalBody className="m-0 p-0 col-12">
          <Row className="m-0 p-0 col-12">
            <strong className="m-0 p-0 mb-2 col-12">
              Enter Filter Name:
            </strong>
            <input
              className="m-0 p-0 col-9 mb-2"
              type="text"
              value={filterName}
              onChange={filterNameHandler}
              placeholder="Enter Filter Name"
              maxLength={30}
              minLength={1}
            />
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" 
          onClick={()=>{
            savefilterNameHandler();
          }}
          >
            Save
          </Button>{" "}
          <Button
            color="secondary"
            onClick={() => {
              setShowSaveModal(!showSaveModal);
              setFilterName('');
            
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      </>
    </DashboardLayout>
    </div>
  );
};

export default RequestsBoard;