import { combineReducers } from "redux";

import navbar from "./navbarReducer";

import sidebar from "./sidebarReducers";
import sectionLinks from "./linkReducers";
import layout from "./layoutReducer";
import theme from "./themeReducer";

import currentCosting from "./currentCostingReducer";
import costings from "./costingsReducer";
import timeCost from "./timeCostReducer"

import currentCountrySpec from "./currentCountrySpecReducer";
import countrySpecs from "./countrySpecsReducer";
import filterCriteriaData from "./filterCriteriaReducer";

import currentWaveSpec from "./currentWaveSpecReducer";
import waveSpecs from "./waveSpecsReducer";

import currentProject from "./currentProjectReducer";
import projects from "./projectsReducer";

import manageUsers from "./manageUsersReducer";
import marketdefaults from "./marketDefaultsReducer"


import user from "./userReducer";
import codeLabels from "./codeLabelReducer";
import app from "./appReducer";

import rateCards from "./rateCardsReducer";
import deliveries from "./deliveryReducer";
import requests from "./requestsReducer";

import reportStatuses from "./reportStatusReducer";

import msdSalesForceReducer from "./msdReducer"
import timecostHours from './timecostHoursReducer'
import { reducer as toastr } from "react-redux-toastr";
/// vendor Reducers

import fetchVendorAndList from "./FetchVendorAndLists"// ERA
import redux_master from "./mastersReducer";//  ERA
import proposal from "./proposalReducer";//ERA
import vendorReducer from "./vendorReducer";//ERA
import notificationReducer from "./notificationReducer";
export default combineReducers({
    fetchVendorAndList,

    navbar,redux_master,proposal,vendorReducer,
    sidebar,
    sectionLinks,
    layout,
    theme,
    toastr,
    currentCosting,
    costings,
    currentCountrySpec,
    countrySpecs,
    currentWaveSpec,
    waveSpecs,
    currentProject,
    projects,
    rateCards,
    manageUsers,
    marketdefaults,
    user,
    codeLabels,
    app,
    deliveries,
    requests,
    reportStatuses,
    timeCost,
    filterCriteriaData,
    msdSalesForceReducer,
    timecostHours,
    notificationReducer
});
