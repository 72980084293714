import React, { useState, useEffect, Suspense } from "react";
import {
  Button,
  ModalFooter,
  ModalBody,
  Modal,
  ModalHeader,
  Label,
  Input,
  Row,
} from "reactstrap";
import axios from "../../axios-interceptor";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {useSelector,useDispatch} from 'react-redux'
import {
  faThumbsDown,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";
import { toastr } from "react-redux-toastr";
import _ from "lodash";
import * as currentCostingActions from "../../redux/actions/currentCostingActions";
const ExceptionModal = ({ isOpen, isClose }) => {

  const [managerList, setManagerList] = useState([]);
  const [msdValid, setMsdValid] = useState({});
  const [isLoading,setIsloading]=useState(false)
  const [formdata,setFormData]=useState({
    reason:"",
    requestedBy:"",
    offlineApprovedBy:""
  })

  console.log(formdata,"formdata")
  const dispatch = useDispatch();
  const profile = useSelector(
    ({ currentCosting }) => currentCosting.currentCostingProfile
  );
  const project = useSelector(
    ({ currentProject }) => currentProject.newProject
  );
  const userEmail = useSelector(({ user }) => user.userRecord.Email);
  const probability =
    msdValid?.Stage && msdValid?.Stage[0] !== undefined
      ? msdValid?.Stage[0].Probability
      : "";
  const opportunityNumber =
    msdValid?.Stage && msdValid?.Stage[0] !== undefined
      ? msdValid?.Stage[0].OpportunityNumber
      : "";
  const stage =
    msdValid?.Stage && msdValid?.Stage[0] !== undefined
      ? msdValid?.Stage[0].Stage
      : "";
  const costOptions =
    msdValid?.Costopt && msdValid?.Costopt !== undefined
      ? msdValid?.Costopt
      : [];
  const wbs =
    msdValid?.WBS && msdValid?.WBS[0] !== undefined ? msdValid?.WBS[0] : [];

  console.log("msdValid",msdValid)

  useEffect(() => {
      if(profile.ExceptionCommision)
      {
        setFormData(profile.ExceptionCommisionData)
      }
      setIsloading(true)
      axios
        .get("/users/all/contacts")
        .then((res) => {
          setManagerList(res.data.items.filter((item)=>!item.isDisabled).sort((a,b)=>a.Email.localeCompare(b.Email)));
          setIsloading(false)
        })
        .catch((err) => {
          setIsloading(false)
          toastr.error("Error while fetching user list")
        });
      
  }, []);
  useEffect(()=>{
    if(isOpen)
    {
      msdValidation();
    }
    else
    {
      if(!profile.ExceptionCommision)
      {
        setFormData({
          reason:"",
          requestedBy:"",
          offlineApprovedBy:""
        })
      } 
    }
  },[isOpen])
  const msdValidation = () => {
    let ContractDetails = [];
    if (project.IsSyndicated) {
      ContractDetails = project.ContractDetails;
    } else {
      ContractDetails = project.ContractDetails.filter(
        (cd) => cd.OpportunityNumber
      );
    } 
    let tempMSDValid = msdValid;

    if (
      ContractDetails.every((cd) =>
        cd.opportunityLineItemDetails
          .filter(
            (o) =>
              o.MaterialID?.slice(0, 16).toLowerCase().replace(" ", "") !=
              "expenserecharge"
          )
          .every((ol) => ol.WBSNumber)
      )
    ) {
     

      tempMSDValid.WBS = true;
    } else {
      const keysToExtract = [
        "WBSNumber",
        "MaterialID",
        "OpportunityLineItemID",
      ];
      const cdWBS = ContractDetails.map((cd) =>
        cd.opportunityLineItemDetails
          .filter(
            (o) =>
              o.MaterialID?.slice(0, 16).toLowerCase().replace(" ", "") !=
              "expenserecharge"
          )
          .map((ol) => {
            if (ol.MaterialID) {
              const extractedObj = {};
              keysToExtract.forEach((key) => {
                extractedObj[key] = ol[key];
              });
              return extractedObj;
            }
          })
      );
      tempMSDValid.WBS = cdWBS;
    }

    if (
      ContractDetails.every(
        (cd) => cd.Stage == "Closed Won - Approved" && cd.Probability == 100
      )
    ) {
      tempMSDValid.Stage = true;
    } else {
      const keysToExtract = ["Probability", "OpportunityNumber", "Stage"];
      const cdProbability = ContractDetails.map((cd) => {
        if (cd.Stage && cd.Probability && cd.OpportunityNumber) {
          const extractedObj = {};
          keysToExtract.forEach((key) => {
            extractedObj[key] = cd[key];
          });
          return extractedObj;
        }
      });
      tempMSDValid.Stage = cdProbability;
    }

    
    let isValid;
    const lineItemId = ContractDetails.flatMap((cd) =>
      cd.opportunityLineItemDetails
        .filter(
          (o) =>
            o.MaterialID?.slice(0, 16).toLowerCase().replace(" ", "") !=
            "expenserecharge"
        )
        .map((op) => String(op.id))
    );
    const lineItemIdCostOpt = profile.CountrySpecs.flatMap((cs) =>
      cs.MethodologySpecs.flatMap((ms) =>
        ms.CostingOptions.filter((costopt) => costopt.CoSelectedForSummary).map(
          (co) => String(co.OpportunityLineItemID)
        )
      )
    );
    // console.log("lineItemId,lineItemIdCostOpt",lineItemId,lineItemIdCostOpt);
    const islineItemCostOpt = lineItemId.every((element) =>
      lineItemIdCostOpt.includes(element)
    );
    // console.log("islineItemCostOpt",islineItemCostOpt);
    if (islineItemCostOpt) {
      function validateCosts(lineitem) {
        // console.log("lineitem", lineitem);
        let isClientChargeable = profile.CountrySpecs.flatMap(
          (countrySpec) => countrySpec.MethodologySpecs
        )
          .flatMap((methodologySpec) => methodologySpec.CostingOptions)
          .filter(
            (costOption) =>
              costOption.CoSelectedForSummary &&
              costOption.OpportunityLineItemID === lineitem &&
              costOption?.CostingOptionData?.producttype
                ?.slice(2, 5)
                .toLowerCase()
                .replace(" ", "") !== "exp"
          );
        if (isClientChargeable.length === 0) {
          return true;
        }
        const matchingCostOptions = profile.CountrySpecs.flatMap(
          (countrySpec) => countrySpec.MethodologySpecs
        )
          .flatMap((methodologySpec) => methodologySpec.CostingOptions)
          .filter(
            (costOption) =>
              costOption.CoSelectedForSummary &&
              costOption.OpportunityLineItemID === lineitem
          );

        if (matchingCostOptions.length > 0) {
          // console.log("matchingCostOption", matchingCostOptions);
          const hasPositiveCosts = matchingCostOptions.some(
            (matchingCostOption) => {
              const costsData = matchingCostOption.CostsData;
              let hasPositiveCost =
                costsData?.TotalExternalCosts +
                  costsData?.MCPMargin +
                  costsData?.CostTotalInternalCommercial +
                  costsData?.CostTotalInternalOperations >
                0;
              return hasPositiveCost;
            }
          );
          // ['TotalExternalCosts', 'MCPMargin', 'CostTotalInternalCommercial', 'CostTotalInternalOperations'].every(costType => costs[costType] > 0)

          return hasPositiveCosts;
        }

        return false; // Lineitem not found in any cost option
      }

      // Perform validation for each lineitem
      const validationResults = lineItemId.map((lineitem) =>
        validateCosts(lineitem)
      );

      isValid = validationResults.every((result) => result);

      console.log("isValid", isValid);
    }
    if (islineItemCostOpt && isValid) {
      tempMSDValid.Costopt = true;
    } else {
      let total = [];
      profile.CountrySpecs.forEach((cs) =>
        cs.MethodologySpecs.filter((m) => !m.NotApplicable).forEach((ms) =>
          ms.CostingOptions.filter(
            (co) =>
              co.CoSelectedForSummary &&
              co?.CostingOptionData?.producttype
                ?.slice(2, 5)
                .toLowerCase()
                .replace(" ", "") !== "exp"
          ).forEach((co) => {
            let wbs = null;
            let materialID = null;
            let OpportunityLineItemID = null;
            project.ContractDetails.forEach((cd) =>
              cd.opportunityLineItemDetails
                .filter(
                  (o) =>
                    o.MaterialID?.slice(0, 16).toLowerCase().replace(" ", "") !=
                    "expenserecharge"
                )
                .forEach((ol) => {
                  if (ol.id == co.OpportunityLineItemID) {
                    wbs = ol.WBSNumber;
                    materialID = ol.MaterialID;
                    OpportunityLineItemID = ol.OpportunityLineItemID;
                  }
                })
            );

            if (!total.length && co.OpportunityLineItemID !== null) {
              total.push({
                CostingProfileId: profile.id,
                ProjectId: project.ProjectId,
                WBSNumber: wbs,
                MaterialID: materialID,
                OpportunityLineItemID: OpportunityLineItemID,
                Overheadswbs: co.CostsData.Overheads,
                CostTotalMCPMarginwbs: co.CostsData.CostTotalMCPMargin,
                CostTotalInternalOperationswbs: co.TotalOPSTimeCost,

                CostTotalInternalCommercialwbs: co.TotalCSTimeCost,

                CostTotalExternalOperationswbs:
                  co.CostsData.CostTotalExternalOperations,

                CostTotalExternalCommercialwbs:
                  co.CostsData.CostTotalExternalCommercial,
                PriceToClientwbs: co.CostsData.PriceToClient,
                MCPMarginwbs: co.CostsData.MCPMargin,
                // MarginPercentwbs:co.CostsData.MarginPercent,
                VATwbs: co.CostsData.VAT,
                WHTwbs: co.CostsData.WHT,

                Total:
                  // co.TotalOPSTimeCost +
                  // co.TotalCSTimeCost +
                  co.CostsData.TotalExternalCosts +
                  // co.CostsData.CostTotalExternalOperations +
                  // co.CostsData.CostTotalExternalCommercial +
                  co.CostsData.CostTotalInternalCommercial +
                  co.CostsData.CostTotalInternalOperations +
                  // co.CostsData.Overheads +
                  // co.CostsData.VAT +
                  // co.CostsData.WHT +
                  co.CostsData.MCPMargin,
              });
            } else if (total.length && co.OpportunityLineItemID !== null) {
              let existing = total.find((t) => t.WBSNumber == wbs);
              let temp;
              if (existing) {
                temp = {
                  ...existing,
                  CostTotalMCPMarginwbs:
                    existing.CostTotalMCPMarginwbs +
                    co.CostsData.CostTotalMCPMargin,
                  Overheadswbs: existing.Overheadswbs + co.CostsData.Overheads,
                  CostTotalInternalOperationswbs:
                    existing.CostTotalInternalOperationswbs +
                    co.TotalOPSTimeCost,

                  CostTotalInternalCommercialwbs:
                    existing.CostTotalInternalCommercialwbs +
                    co.TotalCSTimeCost,

                  CostTotalExternalOperationswbs:
                    existing.CostTotalExternalOperationswbs +
                    co.CostsData.CostTotalExternalOperations,

                  CostTotalExternalCommercialwbs:
                    existing.CostTotalExternalCommercialwbs +
                    co.CostsData.CostTotalExternalCommercial,
                  MCPMarginwbs: existing.MCPMarginwbs + co.CostsData.MCPMargin,
                  //  MarginPercentwbs:existing.MarginPercentwbs+co.CostsData.MarginPercent,
                  VATwbs: existing.VATwbs + co.CostsData.VAT,
                  WHTwbs: existing.WHTwbs + co.CostsData.WHT,
                  PriceToClientwbs:
                    existing.PriceToClientwbs + co.CostsData.PriceToClient,
                  Total:
                    existing.Total +
                    // co.TotalOPSTimeCost +
                    // co.TotalCSTimeCost +
                    co.CostsData.TotalExternalCosts +
                    // co.CostsData.CostTotalExternalOperations +
                    // co.CostsData.CostTotalExternalCommercial +
                    co.CostsData.CostTotalInternalCommercial +
                    co.CostsData.CostTotalInternalOperations +
                    // co.CostsData.Overheads +
                    // co.CostsData.VAT +
                    // co.CostsData.WHT +
                    co.CostsData.MCPMargin,
                };
                let index = total.findIndex((ele) => ele.WBSNumber == wbs);
                total[index] = temp;
              } else {
                total.push({
                  WBSNumber: wbs,
                  MaterialID: materialID,
                  OpportunityLineItemID: OpportunityLineItemID,
                  CostingProfileId: profile.id,
                  ProjectId: project.ProjectId,
                  Overheadswbs: co.CostsData.Overheads,
                  CostTotalMCPMarginwbs: co.CostsData.CostTotalMCPMargin,

                  CostTotalInternalOperationswbs: co.TotalOPSTimeCost,

                  CostTotalInternalCommercialwbs: co.TotalCSTimeCost,

                  CostTotalExternalOperationswbs:
                    co.CostsData.CostTotalExternalOperations,

                  CostTotalExternalCommercialwbs:
                    co.CostsData.CostTotalExternalCommercial,
                  PriceToClientwbs: co.CostsData.PriceToClient,
                  MCPMarginwbs: co.CostsData.MCPMargin,
                  // MarginPercentwbs:co.CostsData.MarginPercent,
                  VATwbs: co.CostsData.VAT,
                  WHTwbs: co.CostsData.WHT,
                  Total:
                    // co.TotalOPSTimeCost +
                    // co.TotalCSTimeCost +
                    co.CostsData.TotalExternalCosts +
                    // co.CostsData.CostTotalExternalOperations +
                    // co.CostsData.CostTotalExternalCommercial +
                    co.CostsData.CostTotalInternalCommercial +
                    co.CostsData.CostTotalInternalOperations +
                    // co.CostsData.Overheads +
                    // co.CostsData.VAT +
                    // co.CostsData.WHT +
                    co.CostsData.MCPMargin,
                });
              }
            }
          })
        )
      );

      tempMSDValid.Costopt = total;
    }
    // console.log('newVarnewVar',newVar)

    let totalPriceFromMSD = ContractDetails.reduce(
      (res, cd) => cd.AmountUSD + res,
      0
    );

    let totalPriceFromCintral = profile.ClientChargeable
      ? profile.ClientChargeable + profile.PriceToClient
      : profile.PriceToClient;

    if (
      totalPriceFromMSD >= totalPriceFromCintral * 0.99 &&
      totalPriceFromMSD <= totalPriceFromCintral * 1.01
    ) {
      tempMSDValid.Price = true;
    } else {
      tempMSDValid.Price = [totalPriceFromMSD, totalPriceFromCintral];
    }

    if(profile.OutOfPocketCostPercent >0 && profile.OutOfPocketCostPercent!=null)
    {
      tempMSDValid.OutOfPocket=true
    }
    else{
      tempMSDValid.OutOfPocket=false
    }

    if(profile.OutOfPocketCostPercent <0 || profile.OutOfPocketCostPercent==null)
    {
      tempMSDValid.RFQValidation=false
    }
    else{
      let valid=true;
      profile.CountrySpecs.forEach((cs) =>{
      cs.MethodologySpecs.forEach((ms) =>{
        ms.CostingOptions.filter(
          (co) =>
            co.CoSelectedForSummary
        ).forEach((co) => {
          if(co.CostsData === null || co?.CostsData?.OutOfPocketCostPercent<0)
          {
            valid=false;
          }
        })
      })
      })

      if(valid) tempMSDValid.RFQValidation=true
      else tempMSDValid.RFQValidation=false
    }

    tempMSDValid.EBITDA=true
    if (profile.MarginPercent*100< 1 ) {
      if(!profile.ProfileSetting.AdhocLessThanOnePercent){
        tempMSDValid.EBITDA= false
      }
    }


    setMsdValid(tempMSDValid);
  };

  const MSDValidationBody = () => {
    return (
      <>
        {msdValid.Stage === true ? (
          <FontAwesomeIcon color="green" icon={faThumbsUp}></FontAwesomeIcon>
        ) : (
          <FontAwesomeIcon color={"red"} icon={faThumbsDown}></FontAwesomeIcon>
        )}
        <span
          style={{ color: msdValid.Stage === true ? "green" : "red" }}
          color="green"
        >
          {" "}
          Change to Close Won Approved (100 % Profitability){" "}
        </span>
        <span>
          {msdValid.Stage !== true &&
            opportunityNumber &&
            stage &&
            probability && (
              <>
                {" "}
                <br />
              </>
            )}{" "}
          {msdValid.Stage !== true && opportunityNumber && stage && probability
            ? `${opportunityNumber} = ${stage} (${probability}% Probability)`
            : ""}
        </span>
        <hr></hr>
        {msdValid.Price === true ? (
          <FontAwesomeIcon color="green" icon={faThumbsUp}></FontAwesomeIcon>
        ) : (
          <FontAwesomeIcon color={"red"} icon={faThumbsDown}></FontAwesomeIcon>
        )}
        <span style={{ color: msdValid.Price === true ? "green" : "red" }}>
          {" "}
          Price to Client matched in MSD and CINTRAL{" "}
        </span>
        <span>
          {msdValid.Price !== true && (
            <>
              <br />
            </>
          )}{" "}
        </span>
        <hr></hr>
        {msdValid.WBS === true ? (
          <FontAwesomeIcon color="green" icon={faThumbsUp}></FontAwesomeIcon>
        ) : (
          <FontAwesomeIcon color={"red"} icon={faThumbsDown}></FontAwesomeIcon>
        )}
        <span style={{ color: msdValid.WBS === true ? "green" : "red" }}>
          {" "}
          WBS Number is Available in MSD and CINTRAL
          {msdValid.WBS !== true && wbs ? (
            <>
              <br />
            </>
          ) : (
            ""
          )}
        </span>
        <hr></hr>

        {msdValid.Costopt === true ? (
          <FontAwesomeIcon color="green" icon={faThumbsUp}></FontAwesomeIcon>
        ) : (
          <FontAwesomeIcon color={"red"} icon={faThumbsDown}></FontAwesomeIcon>
        )}
        <span style={{ color: msdValid.Costopt === true ? "green" : "red" }}>
          {" "}
          Each WBS level has COST associated
          {msdValid.Costopt !== true && costOptions ? (
            <>
              <br />
            </>
          ) : (
            ""
          )}
        </span>
        <hr></hr>
        {msdValid.OutOfPocket === true ? (
          <FontAwesomeIcon color="green" icon={faThumbsUp}></FontAwesomeIcon>
        ) : (
          <FontAwesomeIcon color={"red"} icon={faThumbsDown}></FontAwesomeIcon>
        )}
        <span style={{ color: msdValid.OutOfPocket === true ? "green" : "red" }}>
          {" "}
          Has Out Of Pocket Cost
          {msdValid.OutOfPocket !== true ? (
            <>
              <br />
            </>
          ) : (
            ""
          )}
        </span>
        <hr></hr>
        {msdValid.RFQValidation === true ? (
          <FontAwesomeIcon color="green" icon={faThumbsUp}></FontAwesomeIcon>
        ) : (
          <FontAwesomeIcon color={"red"} icon={faThumbsDown}></FontAwesomeIcon>
        )}
        <span style={{ color: msdValid.RFQValidation === true ? "green" : "red" }}>
          {" "}
          All mandatory fields answered on RFQ
          {msdValid.RFQValidation !== true ? (
            <>
              <br />
            </>
          ) : (
            ""
          )}
        </span>
        <hr></hr>
        {msdValid.EBITDA === true ? (
          <FontAwesomeIcon color="green" icon={faThumbsUp}></FontAwesomeIcon>
        ) : (
          <FontAwesomeIcon color={"red"} icon={faThumbsDown}></FontAwesomeIcon>
        )}
        <span style={{ color: msdValid.EBITDA === true ? "green" : "red" }}>
          {" "}
            EBITDA &gt; 1%
          {msdValid.EBITDA !== true ? (
            <>
              <br />
            </>
          ) : (
            ""
          )}
        </span>
        <hr></hr>
      </>
    );
  };

  const onchangeHandler=(e)=>{
    setFormData({
      ...formdata,
      reason:e.target.value
    })
  }
  const onchangeHandlerForRequestedby=(e)=>{
    setFormData({
      ...formdata,
      requestedBy:e
    })
  }
  const onchangeHandlerForofflineApprovedBy=(e)=>{
    setFormData({
      ...formdata,
      offlineApprovedBy:e
    })
  }

  const saveModal=()=>{
    let saveData=formdata

    if(saveData.reason===""||saveData.requestedBy===""||saveData.offlineApprovedBy==="")
    {
      toastr.error("Please answer all mandatory fields.")
      return;
    }
    saveData["userEmail"]=userEmail
    saveData["Date"]=new Date().toISOString().slice(0, 10)
    let tempProfile=_.cloneDeep(profile)
    tempProfile["ExceptionCommisionData"]=saveData
    tempProfile["ExceptionCommision"]=true
    dispatch(currentCostingActions.saveCostingProfileDraft(tempProfile,()=> toastr.success("The Continue to Commission button is now enabled. Please proceed.")));
    dispatch(currentCostingActions.updateProfile(tempProfile));
    isClose(false)
  }

  return (
      <>
    {isLoading ?(<div id="pageCoverSpin"></div>):(<Modal size="md" isOpen={isOpen} toggle={() => isClose(false)}>
      <ModalHeader toggle={() => isClose(false)}>
        <h3>Exception Commission</h3>
      </ModalHeader>
      <ModalBody>
        <p>
          Before proceeding with the Exception Commission, please ensure the
          following validations are verified.
        </p>
        <MSDValidationBody />
        <Label for="reason">Reason for Exception Commission *</Label>
        <Input className="mb-2" id="reason" name="reason" type="textarea" value={formdata.reason} onChange={onchangeHandler} disabled={profile.ExceptionCommision||profile.ProfileStatus=="6"||profile.ProfileStatus=="9"||profile.ProfileStatus === "99"} />
        <Label>Requested by *</Label>
        <Select
          placeholder="Please Select"
          className="mb-2"
          name="requestedBy"
          onChange={onchangeHandlerForRequestedby}
          value={formdata.requestedBy?formdata.requestedBy:null}
          options={managerList?.map((item) => {
            return { value: item.Email, label: item.Email };
          })}
          isDisabled={profile.ExceptionCommision||profile.ProfileStatus=="6"||profile.ProfileStatus=="9"||profile.ProfileStatus === "99"}
        />
        <Label>Offline Approved by *</Label>
        <Select
          placeholder="Please Select"
          className="mb-2"
          name="offlineApprovedBy"
          value={formdata.offlineApprovedBy?formdata.offlineApprovedBy:null}
          onChange={onchangeHandlerForofflineApprovedBy}
          options={managerList?.map((item) => {
            return { value: item.Email, label: item.Email };
          })}
          isDisabled={profile.ExceptionCommision||profile.ProfileStatus=="6"||profile.ProfileStatus=="9"||profile.ProfileStatus === "99"}
        />
        <Label>Exception Commissioned by</Label>
        <Input  type="email" className="mb-2" value={formdata?.userEmail||userEmail} disabled={true} />
        <Label>Exception Commissioned Date</Label>
        <Input  type="text" className="mb-2"  value={formdata?.Date||new Date().toISOString().slice(0, 10)} disabled={true}/>
        <p>Are you sure, you want to proceed?</p>
        <h5>This action is irreversible</h5>
      </ModalBody>
      <ModalFooter className="justify-content-end">
        <Row>
          <Button color="danger" className="m-0 mr-2" onClick={saveModal} disabled={profile.ExceptionCommision||profile.ProfileStatus=="6"||profile.ProfileStatus=="9"||profile.ProfileStatus === "99"||project.ProjectStatus=="8"||project.ProjectStatus=="7"}>
            I confirm. Proceed with Exception Commission
          </Button>
          <Button color="secondary" onClick={() => isClose(false)}>
            Cancel
          </Button>
        </Row>
      </ModalFooter>
    </Modal>)}
    </>
  );
};

export default ExceptionModal;