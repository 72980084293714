import React from "react";

function Down({color}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill={color || "#000"}>
          <path d="M18.2928932 8.29289322L19.7071068 9.70710678 12 17.4142136 4.29289322 9.70710678 5.70710678 8.29289322 12 14.585z"></path>
        </g>
      </g>
    </svg>
  );
}

export default Down;