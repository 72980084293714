import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    Button,
    Row,
    Col,
    Card,
    CardBody,
    Container,
    Collapse,
    CardHeader,
    CardTitle,
    CardText,
    Badge,
    Input,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";
import _ from "lodash";
import { selectWaveSpec, updateCurrentWaveSpec } from "../../redux/actions/currentWaveSpecActions";
import { setWaveSpecs } from "../../redux/actions/waveSpecsActions";
import Selector from "../../components/Selector/Selector_ProjectReview";
import Spinner from "../../components/Spinner";
import mockctqschema from "./CTQSchema.json";
import { saveCostingProfile, updateProfile } from "../../redux/actions/currentCostingActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import update from "immutability-helper";
import SelectWrapper from "../../components/SelectWrapper/SelectWrapper";

const ReviewPerformance = ({ profile, indxFC, methodology, indxSM ,disableforCS}) => {
    const dispatch = useDispatch();

    const project = useSelector(
        ({ currentProject }) => currentProject.newProject
    );
    const costingProfiles = useSelector(
        ({ costings }) => costings.costingProfiles
    );
    // const currentCostingProfile = useSelector(
    //     ({ currentCosting }) => currentCosting.currentCostingProfile
    // );
    // const waveSpecs = useSelector(({ waveSpecs }) => waveSpecs);
    // const currentWaveSpec = useSelector(({ currentWaveSpec }) => currentWaveSpec);
    const codeLabels = useSelector(({ codeLabels }) => codeLabels);
    const app = useSelector(({ app }) => app);
    const [Fields, setFields] = useState()
    const [ctqSchema, setCTQSchema] = useState()
    const [waveBreakDown, setWaveBreakDown] = useState({})
    const [currentWaveSpec, setcurrentWaveSpec] = useState([])
    const [modifiedMeths, setModifiedMeths] = useState([])
    const [wavesSpecsData, setwavesSpecsData] = useState(profile.WaveSpecs);
    const [activeTabFC, setActiveTabFC] = useState();
    const [activeTabSM, setActiveTabSM] = useState();
    const userRecord = useSelector(({ user }) => user.userRecord);
    const [selectedItemWave, setSelectedItemWave] = useState("1");
    const [waveEditModal, setWaveEditModal] = useState(false);
    const [editableWaveName, setEditableWavename] = useState();
    useEffect(() => {
        if (profile &&
            wavesSpecsData
            && wavesSpecsData.length
            && !Object.keys(waveBreakDown).length) {

            let data = checkCurrentWaveSpec(profile, selectedItemWave)
            setcurrentWaveSpec(data);
        }   else
        {
            let rData = (profile.CountrySpecs[indxFC]);
            let checData = (rData?.MethodologySpecs[indxSM]);        
            let _waveBreakdownMain = profile.WaveSpecs.filter(x => x.MethodologySpecId === checData.id);
            setModifiedMeths(_waveBreakdownMain);
        }
    }, [profile])
    useEffect(() => {
        if (profile
            && profile.ProfileSetting) {

            let schema = profile.ProfileSetting.CTQSchema
            // if (currentCostingProfile.ProfileSetting.CTQSchema)
            //     schema = currentCostingProfile.ProfileSetting.CTQSchema
            // else
            //     schema = mockctqschema//remove this when insert schemas in db
            setCTQSchema(schema)
            setFields(schema?.properties.CTQForm)
        }
    }, [profile])

    const checkCurrentWaveSpec = (profileVal, selectedItemWave) => {
        let rData = (profileVal.CountrySpecs[indxFC]);
        let checData = (rData?.MethodologySpecs[indxSM]);
        setActiveTabFC(rData.id);
        setActiveTabSM(checData.id);
        let _waveBreakdownMain = profileVal.WaveSpecs.filter(x => x.MethodologySpecId === checData.id);
        let _waveBreakdown = _.groupBy(_waveBreakdownMain, m => m.WaveNumber);
        setModifiedMeths(_waveBreakdownMain);

        Object.keys(_waveBreakdown).map(w => {
            _waveBreakdown[w] = _.uniqBy(_waveBreakdown[w], t => t.MethodologyLabel);
        });
        setwavesSpecsData(profileVal.WaveSpecs);
        setWaveBreakDown(_waveBreakdown);
        let _currentwaveset = _.head(_waveBreakdown[selectedItemWave]);
        return _currentwaveset;
    }
    const onWaveChangeHandler = (eve, useCurrentSpec, isApi) => {
        let currentwavespec = {};
        currentwavespec = checkCurrentWaveSpec(profile, selectedItemWave);
        setcurrentWaveSpec(currentwavespec);
       
          currentwavespec = {
            ...currentwavespec,
            [eve.target.id]: eve.target.value,
          };
            
        let wavespecs = [...profile.WaveSpecs];
        wavespecs = wavespecs.map((ws) => {
          if (ws.id == currentWaveSpec.id) {
            ws = currentwavespec;
          }
          return { ...ws };
        });
       
        setcurrentWaveSpec(currentwavespec);
        let mappedData = modifiedMeths.map(x => {
          if (x.id === currentwavespec.id) {
            x = { ...currentwavespec }
          }
          return x;
        })
        setModifiedMeths(mappedData);
        setwavesSpecsData(wavespecs)    
       
        const profileTemp = update(profile, {
          WaveSpecs: { $set: wavespecs }
        });
        dispatch(updateProfile(profileTemp));
        if (isApi) {
          setWaveEditModal(false);
        }
      };
    const onChangeHandler = (eve, mainField, id) => {
        let waveSpecsToSave = [...profile.WaveSpecs];
        let profileToSave = { ...profile };
        let currentwaveSpec = checkCurrentWaveSpec(profileToSave, selectedItemWave)
        setcurrentWaveSpec(currentwaveSpec)
        waveSpecsToSave = waveSpecsToSave.map((wss) => {
            if (wss.id == currentwaveSpec.id) {
                wss = { ...currentwaveSpec };
                if (!wss.CTQData)
                    wss.CTQData = {}
                if (mainField) {
                    if (!wss.CTQData[mainField])
                        wss.CTQData[mainField] = {}
                    if (!wss.CTQData[mainField][eve.target.id])
                        wss.CTQData[mainField][eve.target.id] = {}
                    wss.CTQData[mainField][eve.target.id][id] = eve.target.value
                    if (eve.target.id == "Rating") {
                        if (!wss.CTQData["TotalScore"])
                            wss.CTQData["TotalScore"] = 0
                        let ratings = []
                        Object.keys(wss.CTQData).map(cd => {
                            if (wss.CTQData[cd] && wss.CTQData[cd]["Rating"]) {
                                Object.values(wss.CTQData[cd]["Rating"]).map(r => {
                                    if (!isNaN(parseInt(r)))
                                        ratings.push(parseInt(r))
                                    else ratings.push(0)
                                })
                            }
                            //console.log(ratings, 'ratings')
                            wss.CTQData["TotalScore"] = _.sum(ratings)
                        })
                    }
                } else {
                    if (!wss.CTQData[eve.target.id])
                        wss.CTQData[eve.target.id] = {}
                    wss.CTQData[eve.target.id][id] = eve.target.value
                }
                dispatch(updateCurrentWaveSpec({ ...wss }));
                setcurrentWaveSpec({ ...wss })
            }
            return wss;
        });
        setwavesSpecsData(waveSpecsToSave);
        dispatch(setWaveSpecs(waveSpecsToSave));
        // dispatch(updateProfile({ ...profileToSave, WaveSpecs: waveSpecsToSave }));
        const profileTemp = update(profileToSave, {
            WaveSpecs: { $set: waveSpecsToSave }
        });
        dispatch(updateProfile(profileTemp));
    }
    const submitCurrentWaveSpec = () => {
        console.log(profile);
        // dispatch(saveCostingProfile({ ...profile, WaveSpecs: [...profile.WaveSpecs] }));
    };
    const selectorHandler = (item) => {
        setSelectedItemWave(item.WaveNumber);
        let _waveBreakdownMain = profile.WaveSpecs.filter(x => x.MethodologySpecId === activeTabSM)
        let _waveBreakdown = _.groupBy(_waveBreakdownMain, m => m.WaveNumber)
        setModifiedMeths((_waveBreakdownMain))
        Object.keys(_waveBreakdown).map(w => {
            _waveBreakdown[w] = _.uniqBy(_waveBreakdown[w], t => t.MethodologyLabel)
        })
        // do nothing if clicked item is current item
        if (item === (currentWaveSpec)?.MethodologySpecId) return;
        else {
            let data = _.head(_waveBreakdown[item.WaveNumber])
            setcurrentWaveSpec(data);
        }
    };


    const renderSelector = () => {
        if (!wavesSpecsData || (wavesSpecsData && wavesSpecsData.length === 1)) return null;
        return (
            <Col className="p-0" lg="1" md="1" sm="8" xs="8">
                <Selector
                    heading={"Waves"}
                    records={modifiedMeths}
                    clicked={selectorHandler}
                    interPolField={["WaveNumber", "WaveName"]}
                    selected={currentWaveSpec}
                    displayField={
                        <>
                            <FontAwesomeIcon
                                title="Edit Wave Name"
                                size="xs"
                                icon={faPen}
                                className={userRecord.IsOpsProjectManager ? "pointer" : "no-actions"}
                                onClick={() => {
                                    if (userRecord.IsOpsProjectManager)
                                        setWaveEditModal(!waveEditModal)
                                }}
                            />
                        </>
                    }
                />
            </Col>
        );
    };
    return (
        <>
            {ctqSchema && ctqSchema.properties ?
                Object.keys(ctqSchema.properties).map(prp => {
                    return (prp != "CTQForm" && prp != "Inputs") ? <Row className="m-0">
                        <h5 className="text-uppercase">{ctqSchema.properties[prp].title}</h5><br></br>
                        <div>
                            {ctqSchema.properties[prp].properties
                                && Array.isArray(ctqSchema.properties[prp].properties)
                                ? <ol>
                                    {ctqSchema.properties[prp].properties.map((csprp, index) => <li key={index}>{csprp}</li>)}
                                </ol>
                                : <p>{ctqSchema.properties[prp].properties}</p>}
                        </div>
                    </Row> : null
                })
                : <div><h5>Performance review not available. Please contact your local admin for assistance</h5></div>}

            <hr></hr>
            {ctqSchema && ctqSchema.properties ? <Row className="performance-main-table">
                {renderSelector()}
                <Col>
                    <Row>
                        <Col className="text-uppercase" lg="3" md="3"><strong>CTQ Parameter</strong></Col>
                        <Col className="text-uppercase" lg="2" md="2"><strong>Rating</strong></Col>
                        <Col className="text-uppercase" lg="2" md="2"><strong>Role Being Rated</strong></Col>
                        <Col className="text-uppercase" lg="2" md="2"><strong>Role Rated by</strong></Col>
                        <Col className="text-uppercase" lg="3" md="3"><strong>Explanation</strong></Col>
                    </Row>
                    {Fields?.map((mainField) => <>
                        <Row className="m-0 mt-4"><h5>{mainField.title}</h5>
                        </Row>
                        {mainField.properties.map((field) => (<>
                            <Row className="p-2">
                                <Col lg="3" md="3">
                                    <Row className="m-0 text-justify">{field.CTQ}</Row>
                                    {field.CTQGoal ? <Row className="m-0"><i>Goal of this Rating: {field.CTQGoal}</i></Row> : null}
                                </Col>
                                <Col lg="2" md="2">
                                    <Row className="m-0">
                                        <SelectWrapper
                                            className="width-150"
                                            disabled={disableforCS}
                                            onChange={(e) => {
                                                onChangeHandler(e, mainField, field.id)
                                            }}
                                            id="Rating"
                                            value={currentWaveSpec.CTQData
                                                && currentWaveSpec.CTQData[mainField]
                                                && currentWaveSpec.CTQData[mainField]["Rating"]
                                                && currentWaveSpec.CTQData[mainField]["Rating"][field.id]
                                                ? currentWaveSpec.CTQData[mainField]["Rating"][field.id]
                                                : ""}
                                        >
                                            <option value={""}>Select Rating</option>
                                            {field.Rating.map((opt, index) => <option key={index} value={opt}>{opt}</option>)}
                                        </SelectWrapper>
                                    </Row></Col>
                                <Col lg="2" md="2"><Row className="m-0">{field.RoleRated}</Row></Col>
                                <Col lg="2" md="2"><Row className="m-0">{field.RoleRatedBy}</Row></Col>
                                <Col className="p-0" lg="3" md="3"><Row className="m-0">
                                    <textarea className="form-control"
                                        id="Explanation"
                                        onChange={(e) => {
                                            onChangeHandler(e, mainField, field.id)
                                        }}
                                        value={currentWaveSpec.CTQData
                                            && currentWaveSpec.CTQData[mainField]
                                            && currentWaveSpec.CTQData[mainField]["Explanation"]
                                            && currentWaveSpec.CTQData[mainField]["Explanation"][field.id]
                                            ? currentWaveSpec.CTQData[mainField]["Explanation"][field.id]
                                            : ""}
                                        placeholder={field.Explanation}
                                        disabled={disableforCS}
                                    >
                                    </textarea>
                                </Row></Col>
                            </Row>
                            <hr className="m-0"></hr>
                        </>))}

                    </>)}

                    <Row className="m-0">
                        <Col lg="4" md="4">
                            {ctqSchema && ctqSchema.properties
                                && ctqSchema.properties.CTQForm ? <h5 lg="4" md="4" className="text-uppercase">
                                OVERALL PROJECT SCORE OUT OF {_.sum(ctqSchema.properties.CTQForm?.map(cf => cf.properties.length))}
                            </h5> : 0}
                        </Col>
                        <Col lg="1" md="1">
                            <h5>
                                {currentWaveSpec.CTQData
                                    && currentWaveSpec.CTQData.TotalScore
                                    ? currentWaveSpec.CTQData.TotalScore : 0}
                            </h5>
                        </Col>
                    </Row>
                    <hr className="m-0"></hr>

                    {ctqSchema && ctqSchema.properties
                        && ctqSchema.properties.Inputs ?
                        <Row className="m-0 mt-1">
                            {ctqSchema.properties.Inputs.map(inp => <>
                                <label className="mb-0 mt-3">{inp.label}</label>
                                <textarea placeholder={inp.placeholder}
                                    className="form-control"
                                    disabled={!userRecord.IsOpsProjectManager||disableforCS}
                                    readOnly={!userRecord.IsOpsProjectManager}
                                    value={currentWaveSpec.CTQData
                                        && currentWaveSpec.CTQData
                                        && currentWaveSpec.CTQData["Inputs"]
                                        && currentWaveSpec.CTQData["Inputs"][inp.id]
                                        ? currentWaveSpec.CTQData["Inputs"][inp.id]
                                        : ""}
                                    id="Inputs"
                                    onChange={(e) => {
                                        onChangeHandler(e, null, inp.id)
                                    }}>

                                </textarea>
                            </>)}
                        </Row>
                        : null}


                </Col>
            </Row> : null}
            <Modal
                isOpen={waveEditModal}
                toggle={() => setWaveEditModal(!waveEditModal)}
                centered
            >
                <ModalHeader toggle={() => setWaveEditModal(!waveEditModal)}>
                    Edit Current Wave Name
                </ModalHeader>
                <ModalBody>
                    <Input
                        defaultValue={
                            currentWaveSpec.WaveName ? currentWaveSpec.WaveName : ""
                        }
                        id="WaveName"
                        onChange={(e) => setEditableWavename(e.target.value)}
                        placeholder="Enter Wave Name"
                    />
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        disabled={app.recordloading}
                        onClick={(e) => {
                            onWaveChangeHandler(
                                { target: { id: "WaveName", value: editableWaveName } },
                                null,
                                true
                            );
                        }}
                    >
                        Update
                    </Button>
                    <Button
                        color="secondary"
                        disabled={app.recordloading}
                        onClick={() => setWaveEditModal(false)}
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}
export default ReviewPerformance