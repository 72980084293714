import React, { useEffect, useState } from "react";
import { Input, Row, Col, Table, Container } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import {
  setCommercialHours,
  setOperationsHours,
  setCommercialCost,
  setOperationsCost
} from "../../redux/actions/timecostHoursActions";
const TimeCostTable = ({
  reduxData,
  costingOptionID,
  activities,
  band,
  role,
  inputCountry,
  rowIndex,
  tableData,
  localSupport,
  localModalSupport,
  defaultRates,
  countryOptions,
  isClearTable,
  setIsClear,
  costData,
  setAlert,
  CurrencyUnit,
  CurrencyConversion,
  productName
}) => {
  const dispatch = useDispatch();
  const profile = useSelector(
    ({ currentCosting }) => currentCosting.currentCostingProfile
  );
  const project = useSelector(
    ({ currentProject }) => currentProject.newProject
  );
  let csCostData = useSelector(({ timecostHours }) => timecostHours.commercial);

  const [hours, setHours] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rates, setRates] = useState([]);
  console.log(costingOptionID, activities, band, role, rowIndex);
  useEffect(() => {
    if (tableData && tableData?.length > 0) {
      setHours(tableData);
    }
    setLoading(false);
    if(countryOptions && countryOptions?.length >0){
     let countryCode = countryOptions.filter((option) => option.label === inputCountry);
     let bandRates = [];
     if(countryCode && countryCode?.length >0){
     bandRates=defaultRates?.filter((rate) => rate.country_id === countryCode[0].value);
     }
      setRates(bandRates);
    }
  }, [inputCountry,costingOptionID]);

  useEffect(()=>{
    if(isClearTable===inputCountry){
      const tableHours = [];
      for (var i = 0; i < activities.length; i++) {
        tableHours.push(Array(band.length).fill(""));
      }
      setHours(tableHours);
    }
  },[isClearTable])

  
  const getRowData = (activityIndex) => {
    let totalHours = 0;
    for (let i = 0; i < band.length; i++) {
      if (hours[activityIndex][i] !== "") totalHours += hours[activityIndex][i];
    }
    return totalHours;
  };
  const getTotalBandHours = (bandIndex) => {
    console.log(hours)
    let totalHours = 0;
    for (let i = 0; i < activities.length; i++) {
      if (hours && hours?.length > 0 && hours[i][bandIndex] !== "") totalHours += hours[i][bandIndex];
    }
    return totalHours;
  };
  const getTotalHours = () => {
    let totalHours = 0;
    for (let i = 0; i < activities.length; i++) {
      for (let j = 0; j < band.length; j++) {
        if (hours[i][j] !== "") totalHours += hours[i][j];
      }
    }
    return totalHours;
  };
  const getTotalCost = (id,i) => {
    let totalHours = getTotalBandHours(i);
    if (isNaN(totalHours)) {
      return 0;
    }
    let rate = rates.filter((r)=>r.band_id ===id);
    let finalRate = 0;
    if(rate && rate?.length > 0){
    finalRate = Number(
    Math.round(totalHours * rate[0].rate * 100) / 100
    ).toFixed(2);
    }
    return finalRate; 
  };
  const totalCost = () => {
    let totalCost = 0;
    band.forEach((b,index)=>{
      let tempCsValue = getTotalCost(b.band_id,index);
      if (!isNaN(tempCsValue)) {
        totalCost += Number(tempCsValue);
      }
    })
    return (Math.round(totalCost * 100) / 100).toFixed(2);
  };

  useEffect(() => {
    if(hours && hours.length > 0){
    if (reduxData && Object.keys(reduxData)?.length) {
      let countryArray =[];
      reduxData[costingOptionID]
      .map((i,index) => {
        if(Object.keys(i)[0] === inputCountry && rowIndex === index){
          countryArray.push({[inputCountry]:hours});
      }else{
        countryArray.push(i);
      }
    });
      reduxData[costingOptionID] = countryArray;
      if (role === "CS") {
        dispatch(setCommercialHours(reduxData));
      } else {
        dispatch(setOperationsHours(reduxData));
      }
    }
    if(band && band?.length >0){
      let bandData = [];
      let bandArray = [];

      band && band.map((b, index) => {
        bandData.push({[b.band_name]: getTotalCost(b.band_id,index)});
        });
        bandData.push({["totalCost"]:totalCost()});
        if(costData && Object.keys(costData).length){
          costData[costingOptionID]
          .map((i,index) => {
            if(Object.keys(i)[0] === inputCountry && rowIndex === index) {
              bandArray.push({
                [inputCountry] : bandData
              });
              //costData[costingOptionID][index][inputCountry]=bandData;
            } else {
              bandArray.push(i);
            }
            
          });  
          costData[costingOptionID] = bandArray;
        } else {
          let newRow =[];
          newRow.push({[inputCountry]:bandData});
          costData = {[costingOptionID]:newRow}
        }
        if (role === "CS") {
          dispatch(setCommercialCost(costData));
        } else {
          dispatch(setOperationsCost(costData));
        }
      }
    }
  }, [hours]);

  const onChangeHandler = (band, activity, bandIndex, activityIndex, value) => {
    setIsClear('');
    setAlert(true);
    let tableDataCs = [...hours];
    if (value <= 9999) {
      tableDataCs[activityIndex][bandIndex] = Number(value);
    } else {
      tableDataCs[activityIndex][bandIndex] = Number(value.slice(0, 4));
    }
    console.log("tableDataCs", tableDataCs, hours);
    setHours(tableDataCs);
  };

  function handleKeypress(e) {
    setIsClear('')
    const characterCode = e.key;
    if (characterCode === "Backspace") return;
    if (characterCode === "Tab") return;
    const characterNumber = Number(characterCode);
    if (characterNumber >= 0 && characterNumber <= 9) {
      if (e?.currentTarget?.value?.length) {
        return;
      } else if (characterNumber === 0) {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  };
  
  return (
    <>
      {loading ? (
        <div id="pageCoverSpin"></div>
      ) : (
        <Container style={{ marginLeft: "100px" }}>
          <Row>
            <Col>
              <div className="">
                <Table
                  responsive
                  hover
                  striped
                  bordered
                  size="sm"
                  style={{
                    width: "90%",
                    marginLeft: "15px",
                    marginTop: "10px",
                    backgroundColor: "white",
                    tableLayout: "fixed",
                    wordWrap: "break-word",
                  }}
                >
                  <thead>
                    <tr className="">
                      <th>Activities</th>
                      {band
                        ? band.map((b) => <th key={b.id}>{b.band_name}</th>)
                        : null}
                      <th style={{ width: "125px" }}>Total Hours</th>
                    </tr>
                  </thead>
                  <tbody>
                    {activities
                      ? activities.map((activity, activityIndex) => (
                          <tr key={activity.id}>
                            <td>{activity.activity_name}</td>
                            {band
                              ? band.map((b, bandIndex) => (
                                  <td key={b.id}>
                                    <Input
                                      id={`${b.band_id}${activity.activity_id}`}
                                      type="number"
                                      placeholder="Enter Hrs"
                                      max="9999"
                                      min="1"
                                      disabled={
                                        rates[bandIndex]?.rate === null ||
                                        rates[bandIndex]?.rate == 0 ||
                                        !localModalSupport ||
                                        !localSupport ||
                                        profile.ProfileStatus === "6" ||
                                        profile.ProfileStatus === "99" ||
                                        project.PriceChange == 3 ||
                                        project.ProjectStatus == 3||
                                        productName.includes("EXPENSE RECHARGE")
                                      }
                                      value={
                                        hours[activityIndex][bandIndex]
                                          ? hours[activityIndex][bandIndex]
                                          : ""
                                      }
                                      onChange={(e) =>
                                        onChangeHandler(
                                          b,
                                          activity,
                                          bandIndex,
                                          activityIndex,
                                          e.target.value
                                        )
                                      }
                                      onKeyDown={handleKeypress}
                                    />
                                  </td>
                                ))
                              : null}
                            <td>{getRowData(activityIndex)}</td>
                          </tr>
                        ))
                      : null}

                    <tr style={{ fontWeight: "bold", fontSize: "15px" }}>
                      <td>Total Hours</td>
                      {band
                        ? band.map((hour, index) => {
                            return (
                              <td>
                                {hours.length > 1
                                  ? getTotalBandHours(index)
                                  : null}
                              </td>
                            );
                          })
                        : null}
                      <td>{hours.length > 1 ? getTotalHours() : null}</td>
                    </tr>
                    <tr style={{ fontWeight: "bold", fontSize: "15px" }}>
                      <td>Total Costs ({CurrencyUnit})</td>
                      {band
                        ? band.map((b, index) => {
                            return (
                              <td>
                                {hours.length > 1 ? (getTotalCost(b.band_id,index)*CurrencyConversion).toFixed(2) : null}
                              </td>
                            );
                          })
                        : null}
                      <td>{(totalCost()*CurrencyConversion).toFixed(2)}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
          <Table
            responsive
            hover
            striped
            bordered
            size="sm"
            style={{
              width: "50%",
              marginLeft: "15px",
              marginTop: "10px",
              backgroundColor: "white",
            }}
          >
            <tr style={{ fontWeight: "bold", fontSize: "15px" }}>
              <td style={{ width: "49%" }}>Total {role==="CS"?"Commercial":"Operations"} Hours</td>
              <td>{getTotalHours()}</td>
            </tr>
            <tr style={{ fontWeight: "bold", fontSize: "15px" }}>
              <td>Total {role==="CS"?"Commercial":"Operations"} Costs ({CurrencyUnit})</td>
              <td>{(totalCost()*CurrencyConversion)?.toFixed(2)}</td>
            </tr>
          </Table>
        </Container>
      )}
    </>
  );
};
export default TimeCostTable;