import axios from "axios";
import _ from "lodash";
import { auth, authFail, setTokenAuth } from "./redux/actions/userActions";
import store from './redux/store/index';
import AzureToken, { AzureRefreshToken, storageValue } from "./AuthEndpointSetup/AzureToken";
import AzureUser from "./AuthEndpointSetup/AzureUser";
import { encryptOrDecryptString } from "./utils/azureConstant";
import { REDIRECT_URI } from "./AuthEndpointSetup/azureKeys";
import AzureAuthUrl from './AuthEndpointSetup/Azure'
import { toastr } from "react-redux-toastr";

let baseURL;
baseURL = process.env.REACT_APP_API_BASE_URL;

let Axios;
let isRefreshing = false;
let failedQueue = [];
let que = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const init = () => {
  Axios = axios.create({
    baseURL: baseURL
  });
  Axios.interceptors.request.use(handleSuccessRequest, handleErrorRequest);
  Axios.interceptors.response.use(handleSuccess, handleError);
};

const handleSuccessRequest = (request) => {
  if (localStorage.getItem("auth-token"))
    request.headers["auth-token"] = `${localStorage.getItem("auth-token")}`;
  request.headers["api-url"] = baseURL;
  return request;
};

const handleErrorRequest = (error) => {
  return Promise.reject(error);
};

const handleSuccess = (response) => {
  if(que.length) {
    Promise.allSettled(que).then((res) => {
      store.dispatch(setTokenAuth(true));
      que = []
    })
  }
  return response;
};

const handleError = async (error) => {

  try {
    if (error.response.status === 401 && error.response.config.url == "/auth/sso-login") {
      store.dispatch(authFail(error.response.data.message, "loginError", error.response.status));
    }
    if (error.response.status === 401 && error.response.config.url != "/auth/login") {
      const originalRequest = error.response.config;
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then(token => {
            originalRequest.headers['auth-token'] = token;
            let p = Axios(originalRequest)
            que.push(p)
            return p;
          })
          .catch(err => {
            return Promise.reject(err.response);
          });
      }

      store.dispatch(setTokenAuth(false));

      originalRequest._retry = true;
      isRefreshing = true;

      let userRecord = JSON.parse(localStorage.getItem('userRecord'))
      const { Email : email } = userRecord
      await new Promise((resolve, reject) => {
        let win = window.open(AzureAuthUrl, "Auth", 'width=800, height=600');
        var timmer = setInterval(async () => {
          if(win.closed) {
            toastr.error('Authentication Canceled.');
            window.clearInterval(timmer);
            store.dispatch(authFail());
            reject("Authentication Canceled.");
          }
          if (win.document.URL.indexOf(REDIRECT_URI) != -1) {
            let url = win.document.URL
            window.clearInterval(timmer);
            let locationVal = url.substring(url.indexOf("?") + 1).split("#")
            let code_url = /((\?|\&)code\=)[^\&]+/.exec("?".concat(locationVal[0]))
            if (code_url !== null) {
              try {
                let code = decodeURIComponent(String(code_url[0]).replace(/(\?|\&)?code\=/, ''));
                let token = await AzureToken(code);
                let azureUser = await AzureUser(token)
                if (email.toLowerCase() === azureUser?.email?.toLowerCase()) {
                  storageValue("xyzT", encryptOrDecryptString(token, 1));
                } else {
                  toastr.error("Incorrect user.")
                }
              } catch(tError) {
                win.close()
                reject(tError)
              }
            }
            resolve(code_url);
            win.close();
          }
        }, 500)
      })
      let body = { email };
      await store.dispatch(auth(body));
      originalRequest['auth-token'] = localStorage.getItem('auth-token')
      processQueue(null, localStorage.getItem('auth-token'));
      isRefreshing = false;
      if(que.length === 0) {
        store.dispatch(setTokenAuth(true));
      }
      return Axios(originalRequest);
    } else {
      return Promise.reject(error.response);
    }
  } catch (error) {
    processQueue(error, null);
    isRefreshing = false;
    if(que.length === 0) {
      store.dispatch(setTokenAuth(true));
    }
    return Promise.reject(error.response);
  }
};

init();

export default Axios;
