import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import update from "immutability-helper";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import * as currentProjectActions from "../../redux/actions/currentProjectActions";
import * as costingsActions from "../../redux/actions/costingsActions";
import * as currentCostingActions from "../../redux/actions/currentCostingActions";
import * as currentWaveSpecActions from "../../redux/actions/currentWaveSpecActions";
import axios from "../../axios-interceptor";
import { toastr } from "react-redux-toastr";
import classnames from "classnames";

import Layout from "../../layouts/Project";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronRight,
    faChevronDown,
    faChevronUp,
    faQuestionCircle,
    faPen,
} from "@fortawesome/free-solid-svg-icons";

import {
    Button,
    CustomInput,
    Row,
    Col,
    Card,
    CardBody,
    Container,
    Collapse,
    CardHeader,
    CardTitle,
    CardText,
    Tooltip,
    Modal,
    Badge,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label, Table, FormGroup, Nav, NavItem, NavLink, TabContent, TabPane
} from "reactstrap";
import { faPencilAlt, faCopy, faPlus, faPlusCircle, faTimesCircle, faInfoCircle, faCommentAlt } from "@fortawesome/free-solid-svg-icons";

import {
    AvForm,
    AvField,
    AvRadioGroup,
    AvInput,
    AvRadio,
    AvSelectField
} from "availity-reactstrap-validation";
import { setCurrentWaveSpec, setWaveSpecs } from "../../redux/actions/waveSpecsActions";
import Selector from "../../components/Selector/Selector_Updated";
import { getLabel } from "../../utils/codeLabels";
import FormFeedback from "reactstrap/lib/FormFeedback";
import Spinner from "../../components/Spinner";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import { useParams, useLocation } from "react-router-dom";
import * as appActions from "../../redux/actions/appActions";
import * as mapperFunctions from "../../utils/rfqMapper";
import RecordsBadge from "../../components/RecordsBadge";
import logo from "../../assets/img/avatars/modal-logo.png";
const WaveLevelEdit = (props) => {
    //console.log(props);
    const location = useLocation();
    const [list, setlist] = useState([]);
    const [opsResourcesVariables] = useState(["surveyProgrammingResource", "surveyProgrammingResourceVendor", "dataProcessingResource", "dataProcessingResourceVendor", "dataProcessingComplexity", "translationsResource", "translationsResourceVendor", "verbatimCodingResource", "verbatimCodingResourceVendor", "chartingResource", "chartingResourceVendor", "dashboardingResource", "dashboardingResourceVendor", "qualityControl", "qualityControlComments", "dataCollectionPlatform", "platformComments"])
    const [checkVariables, setCheckVariables] = useState(false);
    const [storeData, setStoreData] = useState([]);
    const currentCostingProfile = useSelector(
        ({ currentCosting }) => currentCosting.currentCostingProfile
    );
    const [wavesSpecsData, setwavesSpecsData] = useState(currentCostingProfile.WaveSpecs)
    var ischeck = false;
    const [activeTabFC, setActiveTabFC] = useState();
    const [activeTabSM, setActiveTabSM] = useState();
    const userRecord = useSelector(
        ({ user }) => user.userRecord
    );
    const [validationHeader, setValidationHeader] = useState("Cannot Save as some Fields are Mandatory!!");
    const [isModalOpen, handleOpen] = useState(false);
    const [indexSM, setIndexSM] = useState()
    const demofn = () => {

        ischeck = true
    }
    const [errorObj, setErrorObj] = useState({});

    const project = useSelector(
        ({ currentProject }) => currentProject.newProject
    );
    const costingProfiles = useSelector(
        ({ costings }) => costings.costingProfiles
    );

    var imgStyle = {
        width: "150px",
      };
    const [editableCostingProfile, setEditableCosting] = useState(
        currentCostingProfile
    );

    const waveSpecs = useSelector(({ waveSpecs }) => waveSpecs);
    const currentWaveSpec = useSelector(({ currentWaveSpec }) => currentWaveSpec);
    const codeLabels = useSelector(({ codeLabels }) => codeLabels);
    const app = useSelector(({ app }) => app);

    const [isSaveModal, setSaveModal] = useState(false);
    const [waveEditModal, setWaveEditModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [editableWaveName, setEditableWavename] = useState();

    const [currentBusinessUnit, setCurrentBusinessUnit] = useState({})
    const [allFields] = useState(["DateWaveCommissioned", "NotesPM", "DateFinalQuestionnaire", "NotesFinalQuestionnaire", "DateFieldStart", "DateFieldEnd", "NotesFieldwork", "DateDataProcessing", "DateAnalysisPlan", "DateInterimData", "DateInterimTable", "DateFinalData", "NotesDataProcessing", "DateDashboards", "NotesDashboards", "DateCharts", "DateChartingtemplate", "DateFinalTableCharting", "NotesCharts", "DateTranslations", "NotesTranslations", "ScheduleTranslationsWordCount", "ScheduleTranslationType", "ScheduleOriginalFileFormat", "ScheduleFinalMaterialsDue", "DateVerbatimCoding", "NotesVerbatimCoding", "DateFinalReport", "DateFinalPresentation", "NotesFinalReport", "NotesDataScience", "NotesOther", "DateFinalQuestionnaireNA", "DateFieldworkNA", "DateDataProcessingNA", "DateDashboardsNA", "DateChartsNA", "DateTranslationsNA", "DateVerbatimCodingNA", "DateFinalReportNA", "DeliverableTypes"])

    const dispatch = useDispatch();
    const [profile] = useState(currentCostingProfile);
    //console.log(profile, "log issue profile")
    const [toggle, setToggle] = useState({
        overall: true,
        inst: true,
        other: true,
    });
    const [Fields, updateFields] = useState({

    });

    const collapseAll = () => {
        let Toggle = { ...toggle };
        Object.keys(Fields).map((field) => {
            Toggle[Fields[field].id] = !toggle.overall;
        });
        Object.keys(Toggle).map((t) => {
            Toggle[t] = !toggle.overall;
        });
        setToggle({ ...Toggle, overall: !toggle.overall });
    };
    const history = useHistory();
    const [methBreakdown, setmethBreakdown] = useState({})
    const [waveBreakDown, setWaveBreakDown] = useState({})
    const [currentWaveSet, setCurrentWaveSet] = useState([])
    const [modifiedMeths, setModifiedMeths] = useState([])

    const [countrySpecificData, setCountrySpecificData] = useState([]);
    useEffect(() => {
        if (currentCostingProfile &&
            wavesSpecsData
            && wavesSpecsData.length
            && !Object.keys(waveBreakDown).length) {

            let rData = _.head(profile.CountrySpecs);
            let checData = _.head(rData?.MethodologySpecs)
            setActiveTabFC(rData.id);
            setActiveTabSM(checData.id);

            let _waveBreakdownMain = wavesSpecsData.filter(x => x.MethodologySpecId === checData.id)
            let _waveBreakdown = _.groupBy(_waveBreakdownMain, m => m.WaveNumber)
            setModifiedMeths(_waveBreakdownMain)

            Object.keys(_waveBreakdown).map(w => {
                _waveBreakdown[w] = _.uniqBy(_waveBreakdown[w], t => t.MethodologyLabel)
            })
            setwavesSpecsData(wavesSpecsData);
            setWaveBreakDown(_waveBreakdown)
            let _currentwaveset = _.head(Object.values(_waveBreakdown))
            setCurrentWaveSet(_currentwaveset)
            let data = [_.head(_currentwaveset)]
            setStoreData(data[0]);
            let tempVariables = mapperFunctions.getSchemaWithDataJSONForWaveLevelEdit(data[0].OpsResourcesSchema, "", data[0]?.OpsResourcesData);
            setlist(tempVariables);
            setCheckVariables(tempVariables?.filter(x => x.check));
            dispatch(currentWaveSpecActions.selectWaveSpec({ ..._.head(_currentwaveset) }));

        }
    }, [currentCostingProfile])
    const onMethodologyChange = (methID) => {
        let _waveBreakdownMain = wavesSpecsData.filter(x => x.MethodologySpecId === methID)
        let _waveBreakdown = _.groupBy(_waveBreakdownMain, m => m.WaveNumber)
        setModifiedMeths(_waveBreakdownMain)

        setWaveBreakDown(_waveBreakdown)
        let _currentwaveset = _.head(Object.values(_waveBreakdown))
        setCurrentWaveSet(_currentwaveset)
        let data = [_.head(wavesSpecsData.filter(cws => cws.MethodologySpecId == methID))]
        setStoreData(data[0]);
        let tempVariables = mapperFunctions.getSchemaWithDataJSONForWaveLevelEdit(data[0].OpsResourcesSchema, "", data[0].OpsResourcesData);
        setlist(tempVariables);
        setCheckVariables(tempVariables?.filter(x => x.check));
    }
    const ReturnControl = (v, indexV) => {
        let defTable = v.defTable ? <FontAwesomeIcon
            title={'click to open definition'}
            className="align-middle mr-2 addButton"
            icon={faInfoCircle}
            // onClick={() => {
            //     setModalStateTableDef(true);
            //     setTableDef(v.defTable);
            // }}
            fixedWidth
            style={{ cursor: "pointer" }}
        /> : null;

        if (v.type == "integer")
            return (
                <FormGroup>
                    <Label>
                        {v.label + (v.mandatory ? '(*)' : '')}
                        {v.tooltext &&
                            <FontAwesomeIcon
                                title={v.tooltext}
                                className="align-middle mr-2 addButton"
                                icon={faInfoCircle}
                                fixedWidth
                                style={{ cursor: "pointer" }}
                            />
                        }
                        {defTable}
                    </Label>
                    <AvField
                        // disabled={_.includes(opsResourcesVariables, v.id)}
                        disabled={v.id == "numberOfWaves"}
                        name={"field_" + indexV}
                        placeholder=""
                        type="number"
                        value={v.value || ''}
                        errorMessage="field is required"
                        validate={(v.mandatory && (!_.includes(opsResourcesVariables, v.id) || (_.includes(opsResourcesVariables, v.id)
                        ))) ? { required: { value: v.mandatory }, min: { value: 0 } } : {}}
                        onChange={(e) => InfoChange(indexV, e.target.value)}
                    />
                </FormGroup>
            )
        else if (v.type == "single")
            return (
                <FormGroup>
                    <Label>

                        {v.label + (v.mandatory ? '(*)' : '')}
                        {v.tooltext &&
                            <FontAwesomeIcon
                                title={v.tooltext}
                                className="align-middle mr-2 addButton"
                                icon={faInfoCircle}
                                fixedWidth
                                style={{ cursor: "pointer" }}
                            />
                        }
                        {defTable}
                    </Label>
                    <AvField
                        //disabled={(_.includes(opsResourcesVariables, v.id)
                        //           )}
                        tag={CustomInput}
                        name={v.label}
                        value={v.value}
                        type="select"
                        // placeholder='please select'
                        errorMessage="field is required"
                        validate={(v.mandatory && (!_.includes(opsResourcesVariables, v.id) || (_.includes(opsResourcesVariables, v.id)
                        ))) ? { required: { value: v.mandatory } } : {}}
                        clearable
                        onChange={(e) => {
                            InfoChange(indexV, e.target.value)

                        }
                        }
                    >
                        <option value="0"></option>
                        {
                            v.optionsLabel ?
                                codeLabels[v.optionsLabel].map(
                                    (opt) => (
                                        <option key={opt.Code} value={opt.Code}>{opt.Label}</option>
                                    ))
                                :
                                v.options?.map((option) => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))
                        }
                    </AvField>
                </FormGroup>
            )
        else if (v.type == "multi" && (codeLabels[v.optionsLabel] && codeLabels[v.optionsLabel].find(x => x.Label.toLowerCase() == 'others'))) {
            return (
                <FormGroup>
                    <Label>
                        {v.label + (v.mandatory ? '(*)' : '')}
                        {v.tooltext &&
                            <FontAwesomeIcon
                                title={v.tooltext}
                                className="align-middle mr-2 addButton"
                                icon={faInfoCircle}
                                fixedWidth
                                style={{ cursor: "pointer" }}
                            />
                        }
                        {defTable}
                    </Label>
                    <CreatableSelect
                        // isDisabled={(_.includes(opsResourcesVariables, v.id)
                        // )}
                        name={v.label}
                        value={v.value}
                        // styles={errorFieldsObj && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]
                        //     && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"]
                        //     && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]
                        //     && _.includes(errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label], v.label) ? errorStyles : ""}
                        onChange={(e) => InfoChange(indexV, e)}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        validate={(v.mandatory && (!_.includes(opsResourcesVariables, v.id) || (_.includes(opsResourcesVariables, v.id)
                        ))) ? { required: { value: v.mandatory } } : {}}
                        options={
                            v.optionsLabel ?
                                codeLabels[v.optionsLabel].map(
                                    (opt) => {
                                        return {
                                            value: opt.Code,
                                            label: opt.Label,
                                        }
                                    })
                                :
                                v.options?.map((option) => {
                                    return {
                                        value: option.value,
                                        label: option.label,
                                    }
                                })

                        }
                        isMulti
                    />
                    {/* {errorFieldsObj && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]
                    && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"]
                    && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]
                    && _.includes(errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label], v.label) ? <p className="invalid-feedback d-flex">field is required</p> : null} */}
                </FormGroup>
            )
        }
        else if (v.type == "multi")
            return (
                <FormGroup>
                    <Label>
                        {v.label + (v.mandatory ? '(*)' : '')}
                        {v.tooltext &&
                            <FontAwesomeIcon
                                title={v.tooltext}
                                className="align-middle mr-2 addButton"
                                icon={faInfoCircle}
                                fixedWidth
                                style={{ cursor: "pointer" }}
                            />
                        }
                        {defTable}
                    </Label>

                    <Select
                        // isDisabled={(_.includes(opsResourcesVariables, v.id)
                        // )}
                        name={v.label}
                        value={v.value}
                        // styles={errorFieldsObj && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]
                        //     && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"]
                        //     && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]
                        //     && _.includes(errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label], v.label) ? errorStyles : ""}

                        onChange={(e) => InfoChange(indexV, e)}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        validate={(v.mandatory && (!_.includes(opsResourcesVariables, v.id) || (_.includes(opsResourcesVariables, v.id)
                        ))) ? { required: { value: v.mandatory } } : {}}
                        options={
                            v.optionsLabel ?
                                codeLabels[v.optionsLabel].map(
                                    (opt) => {
                                        return {
                                            value: opt.Code,
                                            label: opt.Label,
                                        }
                                    })
                                :
                                v.options?.map((option) => {
                                    return {
                                        value: option.value,
                                        label: option.label,
                                    }
                                })

                        }
                        isMulti
                    />
                    {/* {errorFieldsObj && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]
                    && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"]
                    && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]
                    && _.includes(errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label], v.label) ? <p className="invalid-feedback d-flex">field is required</p> : null} */}
                </FormGroup>
            )
        else if (v.type == "text")
            return (
                <FormGroup>
                    <Label>
                        {v.label + (v.mandatory ? '(*)' : '')}
                        {v.tooltext &&
                            <FontAwesomeIcon
                                title={v.tooltext}
                                className="align-middle mr-2 addButton"
                                icon={faInfoCircle}
                                fixedWidth
                                style={{ cursor: "pointer" }}
                            />
                        }
                        {defTable}
                    </Label>
                    <AvField
                        // disabled={(_.includes(opsResourcesVariables, v.id)
                        // )}
                        name={"field_" + indexV}
                        placeholder=""
                        type="text"
                        errorMessage="field is required"
                        validate={(v.mandatory && (!_.includes(opsResourcesVariables, v.id) || (_.includes(opsResourcesVariables, v.id)
                        ))) ? { required: { value: v.mandatory } } : {}}
                        onChange={(e) => InfoChange(indexV, e.target.value)}
                        value={v.value || ''}
                    />
                </FormGroup>
            )

        else if (v.type == "textarea")
            return (
                <FormGroup>
                    <Label>
                        {v.label + (v.mandatory ? '(*)' : '')}
                        {v.tooltext &&
                            <FontAwesomeIcon
                                title={v.tooltext}
                                className="align-middle mr-2 addButton"
                                icon={faInfoCircle}
                                fixedWidth
                                style={{ cursor: "pointer" }}
                            />
                        }
                        {defTable}
                    </Label>
                    <AvField
                        //disabled={(_.includes(opsResourcesVariables, v.id)
                        //           )}
                        name={"field_" + indexV}
                        placeholder=""
                        type="textarea"
                        errorMessage="field is required"
                        validate={(v.mandatory && (!_.includes(opsResourcesVariables, v.id) || (_.includes(opsResourcesVariables, v.id)
                        ))) ? { required: { value: v.mandatory } } : {}}
                        onChange={(e) => InfoChange(indexV, e.target.value)}
                        value={v.value || ''}
                    />
                </FormGroup>
            )
        else if (v.type == "date")
            return (
                <FormGroup>
                    <Label>
                        {v.label + (v.mandatory ? '(*)' : '')}
                        {v.tooltext &&
                            <FontAwesomeIcon
                                title={v.tooltext}
                                className="align-middle mr-2 addButton"
                                icon={faInfoCircle}
                                fixedWidth
                                style={{ cursor: "pointer" }}
                            />
                        }
                        {defTable}
                    </Label>
                    <AvField
                        //disabled={(_.includes(opsResourcesVariables, v.id)
                        //           )}
                        name={"field_" + indexV}
                        placeholder=""
                        type="date"
                        errorMessage="field is required"
                        validate={(v.mandatory && (!_.includes(opsResourcesVariables, v.id) || (_.includes(opsResourcesVariables, v.id)
                        ))) ? { required: { value: v.mandatory } } : {}}
                        onChange={(e) => InfoChange(indexV, e.target.value)}
                        value={v.value || ''}
                    />
                </FormGroup>
            )

        else if (v.type == "header")
            return (
                <>
                    <h5 id={"header_" + indexV} className={'header'}>{v.label}</h5>
                    {v.instructions && <h6 id={"header_i_" + indexV}>{v.instructions}</h6>}
                </>
            )

    };
    function getChangedValue(value) {
        let rValue = null;
        if (isNaN(value))
            rValue = "'" + value + "'";
        else
            rValue = value || 0;
        return rValue;
    }

    function InfoChange(indexV, value) {

        const variablesClone = _.cloneDeep(list);
        const currentVariable = variablesClone[indexV];
        currentVariable["value"] = value;
        var counter = 0;

        variablesClone.forEach((v, indexV) => {

            if (v.dependentOn && v.dependentOn.fields.find(f => f.fieldName == currentVariable.label)) {
                if (v.label == 'Approx Percentage Change - Each Wave') {

                }
                let op = v.dependentOn.andor == "OR" ? "||" : "&&";
                let expression = "";
                v.dependentOn.fields.forEach((f, indexF) => {

                    let field = f.fieldName != currentVariable.label ? variablesClone.filter(v => v.label == f.fieldName)[0] : currentVariable;
                    let fieldVal = field ? field.value : null;
                    if (Array.isArray(fieldVal)) {
                        let tempBool = variablesClone.filter(v => v.label == f.fieldName)[0]["value"].filter(x => x.label == f.fieldValue).length > 0;
                        expression += tempBool + f.operator + "true" + (indexF != v.dependentOn.fields.length - 1 ? op : '');
                    }
                    else {
                        expression += getChangedValue(fieldVal) + f.operator + getChangedValue(f.fieldValue) + (indexF != v.dependentOn.fields.length - 1 ? op : '');
                    }
                });

                let enabled = eval(expression);
                if (enabled) {
                    v.disabled = false;
                    //custom code starts
                    if (v.label == 'Approx Percentage Change - Each Wave') {

                        if (!v.enabled) {
                            v.disabled = true;
                        }
                    }
                    //custom code ends
                }
                else {
                    v.disabled = true;
                    v.value = null;
                    variablesClone.forEach((v2, indexV2) => {
                        if (v2.dependentOn && v2.dependentOn.fields.find(f => f.fieldName == v.label)) {
                            v2.disabled = true;
                            v2.value = null;
                        }
                    });
                }
            }
            if (v.id == 'surveyProgrammingRequired') {
                if (v.value != "Yes") {
                    variablesClone.filter(tv => tv.id == "surveyProgrammingResource")[0].disabled = true;
                }
                else {
                    variablesClone.filter(tv => tv.id == "surveyProgrammingResource")[0].disabled = false;
                }
            }

            if (v.sectionTitle == 'Operations Resources') {
                if (counter == 0 && (v.id != "surveyProgrammingResource" && !v.disabled || v.id == "surveyProgrammingResource" && variablesClone.filter(tv => tv.id == "surveyProgrammingRequired")[0].value && variablesClone.filter(tv => tv.id == "surveyProgrammingRequired")[0].value == "Yes")) {
                    v.isOps = true;
                    v.isNewSection = true;
                    v.sectionTitle = "Operations Resources";
                    counter++;

                }
                else {
                    v.isOps = true;
                    v.isNewSection = false;

                }
            }

        });
        let checkdata = mapperFunctions.getDataJSON(variablesClone);
        storeData.OpsResourcesData = checkdata;
        //console.log(storeData, "StoredData");
        let tempVariables = mapperFunctions.getSchemaWithDataJSONForWaveLevelEdit(storeData.OpsResourcesSchema, "", storeData.OpsResourcesData);

        setlist(variablesClone);
        setCheckVariables(list?.filter(x => x.check));
        var foundIndex = wavesSpecsData.findIndex(x => x.id == storeData.id);
        wavesSpecsData[foundIndex] = storeData;
        //console.log(wavesSpecsData, "wavesSpecsData")
        setwavesSpecsData(wavesSpecsData);
        // dispatch(
        //     currentWaveSpecActions.updateCurrentWaveSpec({ variablesClone }));
    }

    const checkValues = (v, label, label2, indexV) => {


        var index = list.filter(x => { return (x.label == label2) })[0].checkOptions.map(z => { return (z.label) }).indexOf(label)

        var element = checkVariables.filter(cv => { return (cv.label == label2) })
        let cData = element[0].value
        let str1 = cData.substr(0, index)
        let str2 = cData.substr(index + 1, cData.length - index)
        let str = ""
        if (v.target.checked)
            str = str1 + "t" + str2
        if (!v.target.checked)
            str = str1 + "f" + str2

        list[list.map(cv => { return (cv.label) }).indexOf(label2)].value = str
        let cVariables = [...checkVariables]
        cVariables[cVariables.map(cv => { return (cv.label) }).indexOf(label2)].value = str

        let chval = checkVariables.map(c => { return c.value })

        setCheckVariables(cVariables)



        //v.target.checked= !v.target.checked

    }
    const selectorHandler = (item) => {
        let _waveBreakdownMain = wavesSpecsData.filter(x => x.MethodologySpecId === activeTabSM)
        let _waveBreakdown = _.groupBy(_waveBreakdownMain, m => m.WaveNumber)
        setModifiedMeths(_waveBreakdownMain)
        Object.keys(_waveBreakdown).map(w => {
            _waveBreakdown[w] = _.uniqBy(_waveBreakdown[w], t => t.MethodologyLabel)
        })
        // do nothing if clicked item is current item
        if (item === _.head(currentWaveSet)?.MethodologySpecId) return;
        else {
            setCurrentWaveSet(_waveBreakdown[item])
            //console.log((_waveBreakdown[item]), "RKP check")
            let data = [_.head(_waveBreakdown[item])]
            setStoreData(data[0]);
            let tempVariables = mapperFunctions.getSchemaWithDataJSONForWaveLevelEdit(data[0].OpsResourcesSchema, "", data[0].OpsResourcesData);
            //console.log(tempVariables, "loop check");
            setlist(tempVariables);
            setCheckVariables(tempVariables?.filter(x => x.check));

        }
        if (_waveBreakdown[item] && _waveBreakdown[item].length)
            dispatch(currentWaveSpecActions.selectWaveSpec({ ..._.head(_waveBreakdown[item]) }));
    };
    const applyToAllWaves = () => {
        //console.log(modifiedMeths, "wvebreakDown")
        //console.log(storeData);
        modifiedMeths.map(x => x.OpsResourcesData = storeData.OpsResourcesData)
        setModifiedMeths(modifiedMeths)

    }
    const renderSelector = () => {
        if (!waveSpecs || (waveSpecs && waveSpecs.length === 1)) return null;
        return (
            <Col lg="2" md="2" sm="12" xs="12">
                <Selector
                    heading={"Waves"}
                    records={waveBreakDown}
                    applyAll={applyToAllWaves}
                    applyAllText={"Apply to All Waves"}
                    clicked={selectorHandler}
                    selected={_.head(currentWaveSet)?.WaveNumber}
                />
            </Col>
        );
    };
    const toggleFC = (tab) => {
        if (activeTabFC !== tab) {
            setActiveTabFC(tab);
            let cData = profile.CountrySpecs.filter(x => x.id === tab);
            let mData = _.head(cData[0].MethodologySpecs);
            setActiveTabSM(mData.id)
            onMethodologyChange(mData.id)
        }

    };

    const toggleSM = (tab) => {
        if (activeTabSM !== tab) {
            setActiveTabSM(tab);
            onMethodologyChange(tab)

        }
    };

    const onChangeHandler = (eve, useCurrentSpec, isApi) => {
        let currentwavespec = {};
        if (useCurrentSpec) {
            currentwavespec = {
                ...useCurrentSpec,
                [eve.target.id]: eve.target.value,
            };
        } else {
            currentwavespec = {
                ...currentWaveSpec,
                [eve.target.id]: eve.target.value,
            };
        }


        let wavespecs = [...waveSpecs];
        wavespecs = wavespecs.map((ws) => {
            if (ws.id == currentWaveSpec.id) {
                ws = currentwavespec;
            }
            return { ...ws };
        });
        dispatch(setWaveSpecs([...wavespecs]));
        dispatch(
            currentWaveSpecActions.updateCurrentWaveSpec({ ...currentwavespec }));

        let _waveBreakdown = _.groupBy(wavespecs, m => m.WaveNumber)
        Object.keys(_waveBreakdown).map(w => {
            _waveBreakdown[w] = _.uniqBy(_waveBreakdown[w], t => t.MethodologyLabel)
        })
        setCurrentWaveSet(_waveBreakdown[currentWaveSpec.WaveNumber])


        let editablecostingprofile = { ...editableCostingProfile };
        editablecostingprofile.WaveSpecs = editablecostingprofile.WaveSpecs.map(
            (ws) => {
                if (ws.id == currentwavespec.id) {
                    return { ...currentwavespec };
                } else {
                    return { ...ws };
                }
            }
        );
        setEditableCosting(editablecostingprofile);
        if (isApi) {
            dispatch(
                currentCostingActions.saveCostingProfile(
                    { ...editablecostingprofile },
                    () => setWaveEditModal(false)
                )
            );
            dispatch(
                currentCostingActions.updateCostingProfiles(costingProfiles, {
                    ...editablecostingprofile,
                })
            );
        }

    };


    const validateSave = () => {
        //console.log(wavesSpecsData, "wavespecsData")
        let isInvalid = false;
        //console.log(editableCostingProfile)
        let errorFieldsObj = {};
        let isValid = true;
        var allCostingOptionsUsed = [];
        let countrySpecs = [...editableCostingProfile.CountrySpecs];

        let methodologyObj = {};
        let methodologyCountryObj = {};
        let isFieldsValid = true;
        let unavialable = [];
        countrySpecs.map((cs) => {
            if (cs.MethodologySpecs) {
                cs.MethodologySpecs?.filter(ms => !ms.NotApplicable)?.map((ms) => {
                    methodologyObj[ms.id] = ms.Label;
                    methodologyCountryObj[ms.id] = cs.CountryName;
                    ms.CostingOptions?.map(co => {
                        co.CostingOptionSchema?.map(cps => {
                            if (!_.includes(allCostingOptionsUsed, cps.id) && cps.id) {
                                allCostingOptionsUsed.push(cps.id)
                            }
                        })
                    })

                    if (!isFieldsValid) {
                        if (!errorFieldsObj[cs.CountryName])
                            errorFieldsObj[cs.CountryName] = {}
                    }
                    isFieldsValid = true
                })



            }
        })

        if (editableCostingProfile?.WaveSpecs?.length) {
            let validMethIds = []
            editableCostingProfile.CountrySpecs?.map(cs => {
                cs.MethodologySpecs?.filter(ms => !ms.NotApplicable)?.map(ms => ms.id)?.map(ms => {
                    if (!_.includes(validMethIds, ms))
                        validMethIds.push(ms)
                })
            })
            let wavespecs = [...editableCostingProfile.WaveSpecs?.filter(ws => _.includes(validMethIds, ws.MethodologySpecId))];
            let ws = {}
            if (wavespecs.length) {
                wavespecs.map(ws => {
                    // ws = _.head(wavespecs)
                    let finalPropsToValidate = []
                    ws?.OpsResourcesSchema?.required?.map(prop => {
                        let currentProp = ws.OpsResourcesSchema.properties[prop]

                        if (currentProp && !currentProp.dependentOn && !currentProp.disabled) {
                            if ((!_.includes(opsResourcesVariables, prop)))
                                finalPropsToValidate.push(prop)
                        } else {
                            if (!(currentProp?.dependentOn?.fields?.length > 1) && (currentProp && !currentProp.disabled)) {
                                let depProp = _.head(currentProp?.dependentOn?.fields)
                                if (depProp) {
                                    let parentProp = {}
                                    Object.keys(ws.OpsResourcesSchema.properties).map(pp => {
                                        if (ws.OpsResourcesSchema.properties[pp].title == depProp.fieldName) {
                                            parentProp = pp
                                        }
                                    })

                                    if (parentProp && ws.OpsResourcesData
                                        && ws.OpsResourcesData[parentProp]
                                        && ws.OpsResourcesData[parentProp] != "No"
                                        && !_.includes(finalPropsToValidate, prop)) {
                                        if ((!_.includes(opsResourcesVariables, prop))
                                        )
                                            finalPropsToValidate.push(prop)
                                    }
                                }
                            }
                        }
                    })
                    let finalErrorFields = [];
                    finalPropsToValidate.map(ftv => {
                        if (!ws.OpsResourcesData || (ws.OpsResourcesData && (!ws.OpsResourcesData[ftv] || ws.OpsResourcesData[ftv] == "0"))) {
                            isValid = false;
                            isFieldsValid = false
                            finalErrorFields.push(ws.OpsResourcesSchema.properties[ftv].title);
                        }
                    })
                    if (!isFieldsValid) {
                        if (!errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]])
                            errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]] = {}

                        if (!errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD)
                            errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD = {}

                        if (!errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD[methodologyObj[ws.MethodologySpecId]])
                            errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD[methodologyObj[ws.MethodologySpecId]] = {}

                        errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD[methodologyObj[ws.MethodologySpecId]] = finalErrorFields;
                    }
                    isFieldsValid = true
                })
            }
        }
        if (!isValid) {
            document.getElementById("OpsResourcesFinalForm")?.requestSubmit();

            //console.log(errorFieldsObj);
            setErrorObj(errorFieldsObj);
            handleOpen(true);
        }
        else {


            dispatch(appActions.recordLoadStart());
            axios
                .put("/waves/savewaves", { ...editableCostingProfile })
                .then((res) => {
                    toastr.success("Success", "Saved Successfully");
                })
                .catch((err) => {
                    // //console.log("error", err);
                    toastr.error("Save Failed", err?.data.error);
                    dispatch(appActions.recordLoadEnd());
                });
        }


    }
    const validateDates = () => {
        let isInvalid = false;
        let fields = { ...Fields };
        Object.keys(fields).map((field) => {
            if (
                (fields[field].DisablingVal &&
                    !currentWaveSpec[fields[field].DisablingVal]) ||
                !fields[field].DisablingVal
            ) {

                if (currentWaveSpec.OpsResourcesData) {
                    if (fields[field].RequiredValue) {
                        let currentOpsvalue = currentWaveSpec.OpsResourcesData[fields[field].RequiredValue]
                        if (currentOpsvalue && (typeof (currentOpsvalue) == "string" && currentOpsvalue.toLowerCase() == "yes")
                            || (typeof (currentOpsvalue) == "boolean")) {
                            fields[field].DateFields = fields[field].DateFields.map((df) => {
                                if (df.isRequired && !currentWaveSpec[df.id]) {
                                    df.isInvalid = true;
                                    isInvalid = true;
                                    fields[field].isInvalid = true
                                } else df.isInvalid = false;
                                return { ...df }
                            });
                            if (fields[field].PriorFields)
                                fields[field].PriorFields = fields[field].PriorFields.map((df) => {
                                    if (df.isRequired && !currentWaveSpec[df.id]) {
                                        df.isInvalid = true;
                                        isInvalid = true;
                                        fields[field].isInvalid = true
                                    } else df.isInvalid = false;
                                    return { ...df }
                                });
                        }
                    } else {
                        fields[field].DateFields = fields[field].DateFields.map((df) => {
                            if (df.isRequired && !currentWaveSpec[df.id]) {
                                df.isInvalid = true;
                                isInvalid = true;
                                fields[field].isInvalid = true
                            } else df.isInvalid = false;

                            return { ...df }
                        });
                    }
                }
                // if (fields[field].AdditionalFields)
                //   fields[field].AdditionalFields.map((df) => {
                //     if ((currentWaveSpec[df.id] == null || currentWaveSpec[df.id] == "") && df.isRequired) {
                //       df.isInvalid = true;
                //       isInvalid = true;
                //     } else df.isInvalid = false;
                //   });
            }
            fields[field] = { ...fields[field] }
        });
        updateFields({ ...fields });
        if (!isInvalid) {
            Object.keys(fields).map((field) => {
                fields[field].isInvalid = false
            })
            updateFields({ ...fields });

            // if (!fieldingCountries.length) {
            let allFieldingCountries = currentCostingProfile.FieldingCountries?.split(",")
            let requiredCountryData = currentCostingProfile.CountrySpecs?.filter(cs => _.includes(allFieldingCountries, cs.CountryCode))
            // setFieldingCountries(requiredCountryData)




            //copy to other waves
            let _wavebreak = _.groupBy(waveSpecs, w => w.WaveNumber)
            editableCostingProfile.WaveSpecs = editableCostingProfile.WaveSpecs.map(ws => {
                let requiredSet = _wavebreak[ws.WaveNumber]
                let requiredWave = _.head(requiredSet.filter(rws => rws.MethodologyLabel == ws.MethodologyLabel && ws.id != rws.id))
                if (requiredWave?.DateWaveCommissioned) {
                    allFields.map(af => {
                        ws[af] = requiredWave[af]
                    })
                }
                return { ...ws }
            })


            setErrorMessage("");
        } else {
            setErrorMessage("Please review your form inputs and try again.");
        }
    };
    const updateWaveSpecs = () => {
        axios.put("/waves/updatecurrentwave", currentWaveSpec)
            .then(res => {

                // setSaveModal(false);
                //console.log(res, "response from api")
            })
            .catch(err => {
                //console.log(err, "Error@WavelevelEdit update waves function")

            })

    };

    return (
        <Layout
            profileStatusToDisplay={getLabel(
                "CostingStatusOptions",
                currentCostingProfile.ProfileStatus
            )}
            projectStatusToDisplay={getLabel(
                "ProjectStatusOptions",
                currentCostingProfile.Project?.ProjectStatus
            )}
        >
            <Modal isOpen={isModalOpen} toggle={() => handleOpen()} size="lg">
                <ModalHeader toggle={() => handleOpen(!isModalOpen)}>{validationHeader}</ModalHeader>
                <ModalBody id="scrollModalBody">
                    <p className="text-danger">
                        <b>Key Parameters information is missing at Wavelevel for following Country and Methodologies</b>
                    </p>
                    {Object.keys(errorObj)?.length ?
                        <>
                            {Object.keys(errorObj).map(function (key) {
                                return <>
                                    <div className="displayCountry col-md-12">
                                        <h4><b>{key} :</b></h4>
                                    </div>

                                    <div class="col-md-12">
                                        {errorObj[key]?.SD && Object.keys(errorObj[key].SD).length ? <>
                                            <p><b>Setup and Delivery Section:</b></p>
                                            <Table id="errorTable">
                                                <thead>
                                                    <tr>
                                                        <th>Methodologies</th>
                                                        <th>Fields</th>
                                                    </tr>
                                                </thead>
                                                {<tbody>
                                                    {Object.keys(errorObj[key].SD).map(function (key1) {
                                                        return (
                                                            <tr>
                                                                <td>{key1} </td>
                                                                <td>
                                                                    {errorObj[key].SD[key1].join(', ')}</td>
                                                            </tr>)
                                                    })}
                                                </tbody>}
                                            </Table>
                                        </> : null}</div>
                                </>
                            })
                            }
                        </>
                        : null}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => handleOpen(!isModalOpen)}>
                        Ok
                    </Button>
                </ModalFooter>
            </Modal >

            <Row>
                <Col md={12} className={'countryTabs tab-vertical'}>
                    <div className={"tab mt-5 mr-3"}>

                        <Nav tabs>
                            {profile.CountrySpecs &&
                                profile.CountrySpecs.map((fc, indxFC) => (
                                    <NavItem key={fc.value}>
                                        <NavLink
                                            className={classnames({ active: activeTabFC === fc.id })}
                                            onClick={() => {
                                                toggleFC(fc.id);
                                            }}
                                        >
                                            <b>{fc.CountryName}</b>

                                        </NavLink>
                                    </NavItem>

                                ))}
                        </Nav>
                        <TabContent activeTab={activeTabFC}>
                            {profile.CountrySpecs &&
                                profile.CountrySpecs.map((fc, indxFC) => (
                                    activeTabFC === fc.id &&
                                    <TabPane tabId={fc.id} key={fc.value}>
                                        <Row>
                                            <Col md={12} className={"methodologyTabs"}>

                                                <Nav tabs>
                                                    {fc.MethodologySpecs &&
                                                        fc.MethodologySpecs.filter(x => !x.NotApplicable).map((sm, indxSM) =>
                                                        // setIndexSM(indxSM)
                                                        (
                                                            <NavItem key={sm.value}>
                                                                <NavLink className={classnames({ active: activeTabSM === sm.id })}
                                                                    onClick={() => {
                                                                        toggleSM(sm.id);
                                                                    }}
                                                                >
                                                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                        <div>
                                                                            <b>{sm.Label}</b>
                                                                        </div>

                                                                    </div>
                                                                </NavLink>
                                                            </NavItem>
                                                        ))}
                                                </Nav>
                                                <TabContent activeTab={activeTabSM} style={{ padding: "0.25" }}>
                                                    <>


                                                        <Container>
                                                            <Card
                                                                className="ml-2 mr-2 mb-0 p-0"
                                                                style={{ background: "none", boxShadow: "none" }}
                                                            >

                                                                <Row>
                                                                    {renderSelector()}
                                                                    <Col>
                                                                        <Row className="m-0 mb-1 justify-content-end">
                                                                            <Col lg="6" md="6" className="p-3">
                                                                                #{currentWaveSpec.WaveNumber} {currentWaveSpec.WaveName} <FontAwesomeIcon
                                                                                    title="Edit Wave Name"
                                                                                    size="xs"
                                                                                    icon={faPen}
                                                                                    className="pointer"
                                                                                    onClick={() => setWaveEditModal(!waveEditModal)}
                                                                                />
                                                                            </Col>
                                                                            <Col lg="6" md="6">
                                                                                {/* <strong>Select Methodology:</strong>
                                                                                <select className="form-control"
                                                                                    value={currentWaveSpec.id}
                                                                                    onChange={(e) => handleMethodlogyChange(e.target.value)}>
                                                                                    {currentWaveSet?.map((cws, i) => <option value={cws.id}>{cws.MethodologyLabel}</option>)}
                                                                                </select> */}
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className="m-0 mb-3 justify-content-end">
                                                                            {/* <Button onClick={copyToAllMethodologyWaves}>
                                                                                Advance Copy
                                                                            </Button> */}
                                                                        </Row>
                                                                        {list?.length > 0 &&
                                                                            <Card className="ml-2 mr-2">
                                                                                <CardHeader>
                                                                                    <Row>
                                                                                        <Col style={{ display: "flex", justifyContent: "space-between" }}>
                                                                                            <CardTitle id="header_op_r" className="mb-0">
                                                                                                Setup & Delivery
                                                                                            </CardTitle>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </CardHeader>
                                                                                <CardBody>
                                                                                    <Row>
                                                                                        <Col md={12} className={"countryTabs tab-vertical"}>

                                                                                            <div className={"tab"}>
                                                                                                <Container fluid className="p-1">
                                                                                                    <AvForm encType="multipart/form-data" id="OpsResourcesFinalForm"
                                                                                                    // onSubmit={(e, err) => showErrorMessages(e, err, "OpsformErrors")}
                                                                                                    >
                                                                                                        <Row>
                                                                                                            {list && list.map((v, indexV) => {

                                                                                                                if (!v.Default && !v.selected && !v.disabled && v.isNewSection)
                                                                                                                    return (<>
                                                                                                                        <Col md={'12'}><h5 id={"header_" + indexV} className={'header'}>{v.sectionTitle}</h5></Col>
                                                                                                                        {v.instructions && <Col md={'12'}><h6 id={"header_" + indexV}> {v.instructions}</h6></Col>}
                                                                                                                        <Col md={v.width || '4'}
                                                                                                                            key={'c_' + indxFC + '_m_' + activeTabSM + '_co_' + indexV}
                                                                                                                        >
                                                                                                                            {ReturnControl(v, indexV)}
                                                                                                                        </Col></>)

                                                                                                                else if ((v.selected || v.Default) && v.isNewSection && list?.find(opr => !opr.selected && !opr.disabled && opr.sectionTitle == v.sectionTitle))
                                                                                                                    return (<Col md={'12'}>
                                                                                                                        <h5 id={"header_" + indexV} className={'header'}>{v.sectionTitle}</h5>
                                                                                                                        {v.instructions && <Col md={'12'}><h6 id={"header_" + indexV}> {v.instructions}</h6></Col>}
                                                                                                                    </Col>)

                                                                                                                else if (!v.selected && !v.disabled && !v.Default && !v.isNewSection)
                                                                                                                    return (<Col md={v.width || '4'}
                                                                                                                        key={'c_' + indxFC + '_m_' + activeTabSM + '_co_' + indexV}
                                                                                                                    >
                                                                                                                        {ReturnControl(v, indexV)}

                                                                                                                    </Col>)



                                                                                                                else if (v.check && !ischeck) {
                                                                                                                    demofn()

                                                                                                                    return (
                                                                                                                        <Table size="sm" bordered style={{ backgroundColor: "white", width: "100%" }}>
                                                                                                                            <thead>
                                                                                                                                <tr> <td md={"4"}><b>Type Of Interactions</b></td>
                                                                                                                                    {checkVariables[0].checkOptions.map(co => {
                                                                                                                                        return (<td><b>{co.label}</b></td>)
                                                                                                                                    })}
                                                                                                                                </tr>
                                                                                                                            </thead>
                                                                                                                            <tbody>
                                                                                                                                {checkVariables.map((v, index) => {
                                                                                                                                    return (<tr><td md={"4"}>
                                                                                                                                        {v.label}

                                                                                                                                    </td>
                                                                                                                                        {v.checkOptions.map((co, coIndex) => {
                                                                                                                                            return (<td style={{ textAlign: 'center' }}><input
                                                                                                                                                type="checkbox"
                                                                                                                                                name={v.label}
                                                                                                                                                checked={checkVariables[index].value[coIndex] == "f" ? false : true}
                                                                                                                                                onChange={(e) => checkValues(e, co.label, v.label, indexV)}
                                                                                                                                            ></input></td>)

                                                                                                                                        })}
                                                                                                                                    </tr>
                                                                                                                                    )
                                                                                                                                }



                                                                                                                                )}
                                                                                                                            </tbody>

                                                                                                                        </Table>)
                                                                                                                }

                                                                                                            }
                                                                                                            )}

                                                                                                        </Row>
                                                                                                    </AvForm>
                                                                                                </Container>
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </CardBody>
                                                                            </Card>
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                            </Card>
                                                        </Container>
                                                    </>



                                                </TabContent>
                                            </Col>
                                        </Row>
                                    </TabPane>


                                ))}

                        </TabContent>
                    </div></Col>
            </Row>

            <Col className="justify-content-end">

                <>
                    {/* <Button className="savedraft" color="primary" onClick={copyToAllMethodologyWaves}>
                        Advance Copy

                    </Button> */}

                    <Button className="validateandsave" color="primary"
                        onClick={() => {
                            validateSave();
                        }}>
                        Save
                    </Button>
                </>
            </Col>


            {/* <Container className="d-flex mt-4 mr-2 justify-content-end">
                <Button
                    disabled={app.recordloading}
                    className="float-right"
                    color="primary"
                    onClick={() => {
                        validateSave();
                        // setSaveModal(true);
                    }}
                >
                    Save
                    {app.recordloading && !waveEditModal && !isSaveModal ? (
                        <Spinner size="small" color="#495057" />
                    ) : null}
                </Button>
            </Container> */}
            <Container className="d-flex justify-content-center">
                <RecordsBadge />
            </Container>
            <Modal isOpen={isSaveModal} toggle={() => setSaveModal(false)} size="lg">
                <ModalHeader toggle={() => setSaveModal(false)}>
                {/* <img src={logo}  className="modallogo"/> */}
                </ModalHeader>
                <div>
                        <span className="modaltitle modalEWN">
                        Early Warning Notification
                        </span>
                    </div>

                <ModalBody>
                    <p>Your changed have saved successfully.</p>
                    <p>
                        <strong>Would you like to send out an updated EWN?</strong>
                    </p>
                    <p>
                        An email notification will be sent out to all relevant operations
                        teams if the dates are provided for the team.
                    </p>
                    <p>
                        <em>
                            Please Note: You are also required to include finance in the
                            notifications{" "}
                            <strong>
                                if there are any changes in fieldwork or reports due dates.
                            </strong>{" "}
                            A seperate notification will be sent to the Finance Team contacts.
                        </em>
                    </p>


                </ModalBody>
            </Modal>
            <Modal
                isOpen={waveEditModal}
                toggle={() => setWaveEditModal(!waveEditModal)}
                centered
            >
                <ModalHeader toggle={() => setWaveEditModal(!waveEditModal)}>
                    Edit Current Wave Name
                </ModalHeader>
                <ModalBody>
                    <Input
                        defaultValue={
                            currentWaveSpec.WaveName ? currentWaveSpec.WaveName : ""
                        }
                        id="WaveName"
                        onChange={(e) => setEditableWavename(e.target.value)}
                        placeholder="Enter Wave Name"
                    />
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        disabled={app.recordloading}
                        onClick={(e) => {
                            onChangeHandler(
                                { target: { id: "WaveName", value: editableWaveName } },
                                null,
                                true
                            );
                        }}
                    >
                        Update
                        {app.recordloading ? (
                            <Spinner size="small" color="#495057" />
                        ) : null}
                    </Button>
                    <Button
                        color="secondary"
                        disabled={app.recordloading}
                        onClick={() => setWaveEditModal(false)}
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>

        </Layout >
    );
};


export default WaveLevelEdit;

