import axios from "../../axios-interceptor";
import * as appActions from "./appActions";
import * as costingsActions from "./costingsActions";
import * as countrySpecsActions from "./countrySpecsActions";
import * as currentCountrySpecActions from "./currentCountrySpecActions";
import * as currentWaveSpecActions from "./currentWaveSpecActions";
import * as waveSpecsActions from "./waveSpecsActions";
import * as currentProjectActions from "./currentProjectActions";
import { toastr } from "react-redux-toastr";
import {
    sumTotalCostsRawAndGenerateMinRecPrice,
    calcProfitability,
} from "../../utils/calculations";
import _ from "lodash";
import { mailRequest } from "../../redux/actions/requestsActions";
import { formatingDataForForms } from "../../utils/rfqMapper";
import moment from "moment";
export const CREATE_PROFILE = "CREATE_PROFILE";
export const UPDATE_NEW_COSTING = "UPDATE_NEW_COSTING";
export const CLEAR_NEW_COSTING = "CLEAR_NEW_COSTING";
export const CLEAR_OPP = "CLEAR_OPP";
export const SELECT_COSTING = "SELECT_COSTING";
export const SET_CURRENCIES = "SET_CURRENCIES";
export const COPY_COSTING = "COPY_COSTING";
export const selectCosting = (profile) => {
    return (dispatch) => {
        dispatch({ type: SELECT_COSTING, profile: profile });
    };
};
export const copyCosting = (profileId, callback) => {
    return (dispatch, getState) => {
        dispatch(appActions.pageLoadStart());
        axios
            .post("/costingprofiles/duplicate/" + profileId, {
                headers: { "auth-token": localStorage.getItem("auth-token") },
            })
            .then((res) => {
                dispatch(
                    costingsActions.setCurrentCostingProfiles([
                        ...getState().costings.costingProfiles,
                        res.data.costingProfile,
                    ])
                );
                toastr.success("Costing Profile copied Successfully", res.data.message);
                dispatch(appActions.pageLoadEnd());
            })
            .catch((err) => {
                toastr.error("Save Failed", err.data.error);
                dispatch(appActions.pageLoadEnd());
            });
    };
};
export const deleteCosting = (profileId, callback) => {
    return (dispatch, getState) => {
        dispatch(appActions.pageLoadStart());
        axios
            .delete("/costingprofiles/" + profileId, {
                headers: { "auth-token": localStorage.getItem("auth-token") },
            })
            .then((res) => {
                let arrayList = [...getState().costings.costingProfiles];
                arrayList = arrayList.filter(function (item) {
                    return item.id !== profileId;
                });
                dispatch(costingsActions.setCurrentCostingProfiles(arrayList));
                let project = getState()?.currentProject?.newProject

                dispatch(currentProjectActions.getProject(project.ProjectId, () => {
                    dispatch(getCosting(project.CostingProfiles?.find(cp => !cp.ParentProfileId)?.id))
                    toastr.success(
                        "Costing Profile Deleted Successfully",
                        res.data.message
                    )
                }))

                // dispatch(appActions.pageLoadEnd());
            })
            .catch((err) => {
                toastr.error("Save Failed", err.data.error);
                dispatch(appActions.pageLoadEnd());
            });
    };
};
export const getCostingData = (profileId, callback, cangenerateSummary, _shopperSafetyBackup, vendor) => {

    return (dispatch, getState) => {
        dispatch(appActions.pageLoadStart())
        dispatch(appActions.localPageLoadStart())
        dispatch(appActions.recordLoadStart())
        axios
            .get("/costingprofiles/" + profileId)
            .then((res) => {
                let costingProfileDataObj = res.data.costingProfile;
                let WaveSpecIds = costingProfileDataObj.WaveSpecs.map((w) => w.id);
                let CostingOptionIds = [].concat(...costingProfileDataObj.CountrySpecs.map((cs) => [].concat(...cs.MethodologySpecs.map((ms) => [].concat(...ms.CostingOptions.map((co) => co.id))))));
                let fetchWaveSpecs = WaveSpecIds.length > 0 ? axios.post("/projects/FetchWavesByIds", { WaveIds: WaveSpecIds }) : [];
                let fetchCostingOptions = CostingOptionIds.length > 0 ? axios.post("/projects/FetchCostingOptionsByIds", { CostingOptionsIds: CostingOptionIds }) : [];
                dispatch(appActions.localPageLoadStart())
                dispatch(appActions.recordLoadStart())
                Promise.all([fetchWaveSpecs, fetchCostingOptions]).then(async (sss) => {
                    let waveDetailObjects = (sss && sss[0] && sss[0].data && sss[0].data.waves) || [];
                    let costingOptionDetailObjects = (sss && sss[1] && sss[1].data && sss[1].data.costingOptions) || [];
                    costingProfileDataObj.WaveSpecs.forEach((ws, wsi) => {
                        costingProfileDataObj.WaveSpecs[wsi] = { ...ws, ...waveDetailObjects[waveDetailObjects.findIndex((wdo) => wdo.id === ws.id)] };
                    })
                    costingProfileDataObj.CountrySpecs.forEach((cos, cosi) => {
                        cos.MethodologySpecs.forEach((ms, msi) => {
                            let firstWaveSpecOfMethod = waveDetailObjects.filter(x => x.MethodologySpecId == ms.id)[0];
                            if (firstWaveSpecOfMethod) {
                                let temp = costingProfileDataObj.CountrySpecs[cosi].MethodologySpecs[msi]['SetupSchema']
                                costingProfileDataObj.CountrySpecs[cosi].MethodologySpecs[msi]['SetupSchema'] = firstWaveSpecOfMethod.OpsResourcesSchema;
                                // Added this code to make dataProcessingComplexity ui_location compatable with older projects.
                                if (temp?.properties?.dataProcessingComplexity && costingProfileDataObj.CountrySpecs[cosi].MethodologySpecs[msi]['SetupSchema']?.properties?.dataProcessingComplexity) {
                                    costingProfileDataObj.CountrySpecs[cosi].MethodologySpecs[msi]['SetupSchema'].properties.dataProcessingComplexity.ui_location = temp.properties.dataProcessingComplexity.ui_location
                                }
                                costingProfileDataObj.CountrySpecs[cosi].MethodologySpecs[msi]['SetupData'] = firstWaveSpecOfMethod.OpsResourcesData;
                                costingProfileDataObj.CountrySpecs[cosi].MethodologySpecs[msi]['ResponsibilitySchema'] = firstWaveSpecOfMethod.ResponsibilitySchema;
                                costingProfileDataObj.CountrySpecs[cosi].MethodologySpecs[msi]['ResponsibilityData'] = firstWaveSpecOfMethod.ResponsibilityData;
                            }
                            ms.CostingOptions.forEach((co, coi) => {
                                costingProfileDataObj.CountrySpecs[cosi].MethodologySpecs[msi].CostingOptions[coi] = { ...co, ...costingOptionDetailObjects[costingOptionDetailObjects.findIndex((codo) => codo.id === co.id)] };
                            })
                        })
                    })
                    let resP = res.data.costingProfile
                    if (resP.ApprovalLevelNeeded !== null && resP.ApprovalLevelNeeded >= 0) {
                        resP.ApprovalSent = true
                    }
                    dispatch({ type: SELECT_COSTING, profile: { ...res.data.costingProfile, ..._shopperSafetyBackup } });
                    // //console.log('res.data.costingProfile', res.data.costingProfile)
                    let costingProfile = { ...res.data.costingProfile, ..._shopperSafetyBackup };

                    let project = getState()?.currentProject?.newProject
                    if (project && project.CostingProfiles && !project.CostingProfiles.length) {
                        let projectId = costingProfileDataObj?.Project?.ProjectId
                        axios
                            .get("/projects/" + projectId)
                            .then((res) => {
                                let project = res.data.project;
                                dispatch({ type: currentProjectActions.UPDATE_NEW_PROJECT, newProject: { ...project } });

                            })
                    } else if (project && project.CostingProfiles && project.CostingProfiles.length) {
                        let allProfiles = project.CostingProfiles.map(cp => {
                            if (cp.id == costingProfile.id) {
                                cp.FieldingCountries = costingProfile.FieldingCountries
                                cp = _.cloneDeep(costingProfile)
                            }
                            return { ...cp }
                        })
                        dispatch({ type: currentProjectActions.UPDATE_NEW_PROJECT, newProject: { ...project, CostingProfiles: allProfiles } });

                    }

                    let pacificData = _.head(getState().codeLabels.PacificCountriesList)?.Label;
                    const pacificMarkets = pacificData?.split(',');
                    let _updatedProfile = { ..._.cloneDeep(formatingDataForForms(res.data.costingProfile, project, pacificMarkets)), ..._shopperSafetyBackup }
                    dispatch(updateProfile(_updatedProfile))

                    let allProfiles = project.CostingProfiles.map(cp => {
                        if (cp.id == _updatedProfile.id) {
                            cp.FieldingCountries = _updatedProfile.FieldingCountries
                            cp = _.cloneDeep(_updatedProfile)
                        }
                        return { ...cp }
                    })
                    dispatch(currentProjectActions.setCurrentProject({ CostingProfiles: allProfiles }))

                    // allow summary refresh
                    // 1. if not Child syndicated (IsSyndicated && has ParentProjectId && IsCopy is true)
                    // 2. Has no OPS Adjusments (IsOOPAdjusted)
                    if (cangenerateSummary
                        && !(costingProfile?.Project?.ParentProjectId && costingProfile?.Project?.IsSyndicated && !costingProfile?.Project?.IsCopy)
                        && !costingProfile.IsOPSOOPAdjusted) {
                        costingProfile = await generateSummary(_updatedProfile, pacificMarkets, (profile) => dispatch(updateProfile(profile)));
                        dispatch(updateProfile(costingProfile))
                    }

                    dispatch(
                        countrySpecsActions.setCountrySpecs(_.cloneDeep(costingProfile.CountrySpecs))
                    );
                    dispatch(
                        currentCountrySpecActions.selectCountrySpec(
                            _.head(costingProfile.CountrySpecs)
                        )
                    );
                    dispatch(waveSpecsActions.setWaveSpecs(costingProfile.WaveSpecs));
                    // dispatch(
                    //   currentWaveSpecActions.selectWaveSpec(
                    //     _.head(costingProfile.WaveSpecs)
                    //   )
                    // );
                    if (callback) callback(_updatedProfile);
                    if (
                        costingProfile.ProfileSetting &&
                        !costingProfile.ProfileSetting.CurrenciesData
                    )
                        dispatch(setCurrencies());
                    if (!vendor) {
                        dispatch(appActions.pageLoadEnd())
                        dispatch(appActions.localPageLoadEnd())
                        dispatch(appActions.recordLoadEnd())
                    }
                }).catch((err) => {
                    // //console.log('err', err)
                    dispatch(appActions.pageLoadEnd())
                    dispatch(appActions.localPageLoadEnd())
                    dispatch(appActions.recordLoadEnd())
                });


            })
            .catch((err) => {
                dispatch(appActions.pageLoadEnd());
                dispatch(appActions.recordLoadEnd());
            });
    };
};
export const getSummaryCosting = (profile, callback, cangenerateSummary, _shopperSafetyBackup, vendor) => {

    return (dispatch, getState) => {
        let shopperCounter = ["shopperCostTotalExternalOperations",
            "shopperCostTotalInternalOperations",
            "shopperCostTotalInternalCommercial"]
        let _shopperSafetyBackup = {}
        shopperCounter.map(sc => {
            _shopperSafetyBackup[sc] = profile[sc]
        })
        dispatch(getCostingData(profile.id, callback, cangenerateSummary, _shopperSafetyBackup, vendor))
    }
}
export const getCosting = (profileId, callback, cangenerateSummary, _shopperSafetyBackup, vendor) => {

    return (dispatch) => {
        dispatch(getCostingData(profileId, callback, cangenerateSummary, _shopperSafetyBackup, vendor))
    }
};
export const createCostingProfile = (newProfile, callback) => {
    return (dispatch, getState) => {
        dispatch(appActions.pageLoadStart());
        axios
            .post("/costingprofiles", newProfile, {
                headers: { "auth-token": localStorage.getItem("auth-token") },
            })
            .then((res) => {
                dispatch(
                    costingsActions.setCurrentCostingProfiles([
                        ...getState().costings.costingProfiles,
                        res.data.costingProfile,
                    ])
                );
                dispatch(getCosting(res.data.costingProfile.id, callback));
            })
            .catch((err) => {
                dispatch(appActions.pageLoadEnd());
            });
    };
};
export const updateProfile = (profile, callback) => {
    return (dispatch, getState) => {
        if (callback) dispatch(callback);
        dispatch({
            type: UPDATE_NEW_COSTING,
            currentCostingProfile: { ...profile },
            currencies: getState().costings.currencies,
        });
    };
};
export const profileCalc = (profile) => {
    // //console.log("PROFILE CALC CALLED");
    return (dispatch) => {
        let newProfile = profile;

        // let fields = [
        //   "CostExtCharting",
        //   "CostIntCharting",
        //   "CostExtDataProcessing",
        //   "CostIntDataProcessing",
        //   "CostExtVerbatimCoding",
        //   "CostIntVerbatimCoding",
        //   "CostExtTextAnalytics",
        //   "CostIntSurveyProgramming",
        //   "CostExtSurveyProgramming",
        //   "CostIntOtherDataPreparation",
        //   "CostIntAdditionalOperationsSupport",
        //   "SurveyProgrammingJobCount",
        // ];

        // fields.forEach((field) => {
        //   newProfile[field] = newProfile.WaveSpecs.reduce((total, val) => {
        //     return val[field] ? total + val[field] : total;
        //   }, 0);
        // });

        // //console.log(newProfile);
        dispatch(updateProfile(newProfile));
    };
};
export const updateCostingProfiles = (costingProfilesArr, profile) => {
    return (dispatch) => {
        costingProfilesArr = costingProfilesArr.filter(
            (obj) => obj.id !== profile.id
        );
        costingProfilesArr.push(profile);
        costingProfilesArr.sort((a, b) => {
            return a.ProfileNumber - b.ProfileNumber;
        });
        dispatch({
            type: costingsActions.SET_CURRENT_PROFILES,
            costingProfiles: costingProfilesArr,
        });
    };
};
// export const saveCostingProfile = (costing) => {
//   // //console.log("before dispatch");
//   return (dispatch) => {
//     // //console.log("after dispatch");
//     dispatch(appActions.recordLoading(true));
//     axios
//       .put("/costingprofiles/" + costing.id, costing, {
//         headers: { "auth-token": localStorage.getItem("auth-token") },
//       })
//       .then((res) => {
//         // //console.log("done");
//         dispatch(appActions.recordLoading(false));
//       })
//       .catch((err) => {
//         // //console.log("error", err);
//         dispatch(appActions.recordLoading(false));
//       });
//   };
// };
const calculateFinalCosts = (costing) => {
    let finalCostFields = [];
    costing.CountrySpecs.map((cs) => {
        cs.MethodologySpecs.map((ms) => {
            Object.keys(ms.CalculationSchema).map((csch) => {
                if (!_.includes(finalCostFields, csch)) {
                    finalCostFields.push(csch);
                }
                Object.keys(ms.CalculationSchema[csch]).map((insch) => {
                    if (!_.includes(finalCostFields, insch)) {
                        finalCostFields.push(insch);
                    }
                });
            });
        });
    });
    finalCostFields.map((fcf) => {
        costing[fcf] = 0;
        costing.CountrySpecs.map((cs) => {
            costing[fcf] = costing[fcf] + (cs[fcf] ? cs[fcf] : 0);
        });
        costing.WaveSpecs.map((ws) => {
            // finalCostFields.map(fcf => {
            costing[fcf] = costing[fcf] + (ws[fcf] ? ws[fcf] : 0);
            // })
        });
    });
    return { ...costing };
};
const calcCountryToProfileCost = async (costing) => {
    //console.log("in calc profile cost");
    //console.log(costing);
    const waveFields = [
        "CostExtOpsCharting",
        "CostExtOpsDataEntry",
        "CostExtOpsDataProcessing",
        "CostExtOpsHosting",
        "CostExtOpsOnlineSample",
        "CostExtOpsSurveyProgramming",
        "CostExtOpsTextAnalytics",
        "CostExtOpsVerbatimCoding",
        "CostIntCommAssociateDirector",
        "CostIntCommDataScience",
        "CostIntCommDirector",
        "CostIntCommExecDirector",
        "CostIntCommExecutive",
        "CostIntCommManager",
        "CostIntCommSeniorExecutive",
        "CostIntCommSeniorManager",
        "CostIntOpsAdditionalOperationsSupport",
        "CostIntOpsCharting",
        "CostIntOpsDataEntry",
        "CostIntOpsDataProcessing",
        "CostIntOpsDataScience",
        "CostIntOpsOtherDataPreparation",
        "CostIntOpsPM",
        "CostIntOpsSurveyProgramming",
        "CostIntOpsVerbatimCoding",
        "SurveyProgrammingJobCount",
        "TotalIntOpsPMHours",
        "TotalOnlineSampleSize",
    ];
    const countryFields = [
        "CostExtCommConsultant",
        "CostExtCommOthers",
        "CostExtCommTE",
        "CostExtOpsConsultantVendor",
        "CostExtOpsDCQCDPSP",
        "CostExtOpsFreightShipping",
        "CostExtOpsIncentives",
        "CostExtOpsInterviewers",
        "CostExtOpsMCPSubContract",
        // "CostExtOpsOnlineSample",
        "CostExtOpsOtherTaxVAT",
        "CostExtOpsOthers",
        "CostExtOpsPrintingStationery",
        "CostExtOpsTE",
        "CostExtOpsVenueHireRecruitment",
        "CostIntCommAssociateDirector",
        "CostIntCommDataScience",
        "CostIntCommDirector",
        "CostIntCommExecDirector",
        "CostIntCommExecutive",
        "CostIntCommManager",
        "CostIntCommSeniorExecutive",
        "CostIntCommSeniorManager",
        "CostIntOpsDPCodingAnalysis",
        "CostIntOpsFieldPMQC",
        "CostIntOpsOthers",
        "CostIntOpsProgramming"
    ];
    let allFields = waveFields.concat(countryFields);
    allFields.forEach((field) => {
        costing[field] = 0;
    });
    waveFields.forEach((field) => {
        costing[field] = costing.WaveSpecs.filter(w=> costing.CountrySpecs.some(cs=>
            cs.MethodologySpecs.some(ms => ms.id == w.MethodologySpecId && !ms.NotApplicable)
            )).reduce((total, wave) => {
                if (wave[field] === undefined) {
                    //console.log("WAVE FIELD IS UNDEFINED", field);
                    return total + 0;
                } else {
                    return total + wave[field];
                }
            }, 0);
    });
    countryFields.forEach((field) => {
        costing[field] =
            costing[field] +
            costing.CountrySpecs.reduce((total, country) => {
                if (country[field] === undefined) {
                    //console.log("COUNTRY FIELD IS UNDEFINED", field);
                    return total + 0;
                } else {
                    return total + country[field];
                }
            }, 0);
    });

    // //console.log("ta da");
    // //console.log(costing);
    return costing;
};
// export const generateSummary = (cb) => {
//   // //console.log("GENERATE SUMMARY");
//   return (dispatch, getState) => {
//     let profile = getState().currentCosting.currentCostingProfile;
//     if (profile.CostingType != "SHEETS")
//       profile = calcCountryToProfileCost(profile);
//     profile = sumTotalCostsRawAndGenerateMinRecPrice({
//       ...profile,
//     });
//     dispatch(updateProfile(profile));

//     cb();
//   };
// };
export const generateSummary = async (profile, pacificMarkets, callback) => {
    // profile = calcFormula(profile, pacificMarkets)
    let sample = {}
    await axios
        .post('/utils/summary/generate/', { profile })
        .then(async (res) => {
            const profileToSave = res.data.CostingProfile;
            let finalUpdatedCostingProfile = {}
            const calculatedProfile = await calcCountryToProfileCost(profileToSave);
            finalUpdatedCostingProfile = calculatedProfile
            if (_.includes(pacificMarkets, profile.Project.CommissioningCountry) && profile.Project.CostingTemplateVersion != "v2.0")
                finalUpdatedCostingProfile = await sumTotalCostsRawAndGenerateMinRecPrice({
                    ...calculatedProfile
                }, pacificMarkets);
            //todo: simplyify this
            sample = finalUpdatedCostingProfile;
            if (callback)
                callback(finalUpdatedCostingProfile)
        }).catch((error) => {
            toastr.error("Generate summary failed", error?.data.error);
        });
    return sample;

};
const getWaves = (profile, pacificMarkets, getState) => {
    let _commCountry = profile.Project ? profile.Project?.CommissioningCountry : getState().currentProject.newProject.CommissioningCountry
    let waves =
        _.includes(pacificMarkets, _commCountry) && profile.Project.CostingTemplateVersion !="v2.0" ?
            _.max(profile.CountrySpecs.map(cs =>
                _.max(cs.MethodologySpecs.filter(ms => ms.NotApplicable != true).map(ms =>
                    ms?.RFQData?.numberOfWaves ?
                        ms.RFQData.numberOfWaves
                        : 1
                ))
            ))
            : _.max(profile.CountrySpecs.map(cs =>
                _.max(cs.MethodologySpecs.filter(ms => ms.NotApplicable != true).map(ms =>
                    (ms?.WaveSpecs && _.head(ms?.WaveSpecs)["OpsResourcesData"].numberOfWaves) ?
                        _.head(ms.WaveSpecs)["OpsResourcesData"].numberOfWaves 
                        : profile.WaveSpecs && profile.WaveSpecs.length > 0 
                            ? profile.WaveSpecs.filter(ws => ws.MethodologySpecId === ms.id).length 
                            : 1
                ))
            ))

    return waves
}

export const commissionCostingProfile = (costing, callback = null, noToastRequired = null) => {
    //Commissioning project
    return async (dispatch, getState) => {
        dispatch(appActions.recordLoadStart());
        let pacificData = _.head(getState().codeLabels.PacificCountriesList)?.Label;
        const pacificMarkets = pacificData?.split(',');
        if (!costing.Project)
        costing.Project = getState().currentProject.newProject
        costing = { ...costing, IsTracker: getTrackerStatus(costing, null, getState), TrackingFrequency: getTrackerFrequencyStatus(costing), ProfileStatus: "5" }
        if (!costing.IsImportedProfile && (!costing?.Project?.ParentProjectId && !costing.IsOPSOOPAdjusted)) {
            costing = await generateSummary({ ...costing }, pacificMarkets);
        }
        axios
            .post("/costingprofiles/commission", costing, {
                headers: { "auth-token": localStorage.getItem("auth-token") },
            })
            .then((res) => {
                if (callback) callback();
                if (!noToastRequired) {
                    toastr.success("Costing Profile Saved", res.data.message);
                }
                costing.ProfileStatus=res.data.costingProfile.ProfileStatus;
                let currentCosting = costing;
                if (costing.ProfileSetting) {
                    costing.ProfileSetting.CSRateCardUsed =
                        currentCosting.ProfileSetting?.CSRateCardUsed;
                }

        let pacificData = _.head(
          getState().codeLabels.PacificCountriesList
        )?.Label;
        const pacificMarkets = pacificData?.split(",");
        const project = res.data.project;
        let _updatedProfile = {
          ..._.cloneDeep(
            formatingDataForForms(currentCosting, project, pacificMarkets)
          ),
        };
        dispatch(updateProfile(_updatedProfile));
        dispatch(appActions.recordLoadEnd());
      })
      .catch((err) => {
        toastr.error("Save Failed", err?.data?.error);
        dispatch(appActions.recordLoadEnd());
        dispatch(appActions.localPageLoadEnd());
      });
  };
};

export const saveCostingProfile = (costing, callback = null, noToastRequired = null) => {
    //console.log("save start");
    //console.log("costing to save", costing);
    return async (dispatch, getState) => {
        // costing = calculateFinalCosts(costing);
        dispatch(appActions.recordLoadStart());
        dispatch(appActions.localPageLoadStart())
        let pacificData = _.head(getState().codeLabels.PacificCountriesList)?.Label;
        const pacificMarkets = pacificData?.split(',');
        if (!costing.Project)
        costing.Project = getState().currentProject.newProject
        costing = { ...costing, IsTracker: getTrackerStatus(costing, null, getState), TrackingFrequency: getTrackerFrequencyStatus(costing) }
        if (!costing.IsImportedProfile && (!costing?.Project?.ParentProjectId && !costing.IsOPSOOPAdjusted)) {

            // this moves the commercial time cost from WaveSpecs back to profile.WaveSpecs
            // costing = { ...costing, WaveSpecs: [...getState().waveSpecs] };
            costing = await generateSummary({ ...costing }, pacificMarkets);
        }
        // costing.CountrySpecs[0].MethodologySpecs[0].CostingType = 2;
        costing.CountrySpecs.forEach((data)=>{
            data.MethodologySpecs.forEach((meth)=>{
              meth.CostingType = 2
            })
          })
        // const cb = () =>
        axios
            .put("/costingprofiles/" + costing.id, costing, {
                headers: { "auth-token": localStorage.getItem("auth-token") },
            })
            .then((res) => {
                if (callback) callback();
                if (!noToastRequired) {
                    toastr.success("Costing Profile Saved", res.data.message);
                }
                let currentCosting = {
                    ...getState().currentCosting.currentCostingProfile, ...costing
                };
                if (costing.ProfileSetting) {
                    costing.ProfileSetting.CSRateCardUsed =
                        currentCosting.ProfileSetting?.CSRateCardUsed;
                }

                let pacificData = _.head(getState().codeLabels.PacificCountriesList)?.Label;
                const pacificMarkets = pacificData?.split(',');
                const project = getState()?.currentProject?.newProject;
                let _updatedProfile = { ..._.cloneDeep(formatingDataForForms(currentCosting, project, pacificMarkets)) }
                dispatch(updateProfile(_updatedProfile));
                dispatch(waveSpecsActions.setWaveSpecs(currentCosting.WaveSpecs));
                dispatch(appActions.recordLoadEnd());
                dispatch(appActions.localPageLoadEnd());
            })
            .catch((err) => {
                //console.log("save start");
                // //console.log("error", err);
                toastr.error("Save Failed", err?.data?.error);
                dispatch(appActions.recordLoadEnd());
                dispatch(appActions.localPageLoadEnd());
            });

        // dispatch(generateSummary(cb));
    };
};
const getTrackerStatus = (profile, pacificMarkets, getState) => {
    let _commCountry = profile.Project ? profile.Project?.CommissioningCountry : getState().currentProject.newProject.CommissioningCountry;
    let allStatus = []
    // Adding Tracking Info foe Pecific Markets 
    if (pacificMarkets && _.includes(pacificMarkets, _commCountry) && profile.Project.CostingTemplateVersion != "v2.0") {
        profile?.CountrySpecs?.map(cs => cs.MethodologySpecs?.map(ms => {
            if (ms.RFQData?.trackingProject)
                allStatus.push(ms.RFQData?.trackingProject)
        }))
    }
    else {
        profile?.CountrySpecs?.map(cs => cs.MethodologySpecs?.map(ms => ms?.CostingOptions?.filter(co => co.Selected)?.map(co => {
            if (co.CostingOptionData?.trackingProject)
                allStatus.push(co.CostingOptionData?.trackingProject)
        }
        )))
    }

    return _.includes(allStatus.map(a => a?.toLowerCase()), "yes") ? true : false
}
const getTrackerFrequencyStatus = (profile) => {
    let allFreqStatus = []
    let finalFrq = ""
    let frequencies = ["trackingFrequency",
        "dataOutputFrequency",
        "codingFrequency",
        "chartingFrequency"]
    profile?.WaveSpecs?.map(ws => {
        if (ws.OpsResourcesData) {
            frequencies.map(f => {
                if (ws.OpsResourcesData[f])
                    allFreqStatus.push(ws.OpsResourcesData[f])
            })
        }
    })
    if (allFreqStatus && allFreqStatus.length)
        finalFrq = _.head(_(allFreqStatus)
            .countBy()
            .entries()
            .maxBy(_.last))
    return finalFrq
}
export const saveCostingProfileDraft = (costing, callback, noToastRequired, noGetProfile) => {


    return async (dispatch, getState) => {
        // costing = calculateFinalCosts(costing);
        dispatch(appActions.recordLoadStart());
        let pacificData = _.head(getState().codeLabels.PacificCountriesList)?.Label;
        const pacificMarkets = pacificData?.split(',');
        if (!costing.Project)
            costing.Project = getState().currentProject.newProject
        costing = { ...costing, NumberOfWaves: getWaves(costing, pacificMarkets, getState), IsTracker: getTrackerStatus(costing, pacificMarkets, getState), TrackingFrequency: getTrackerFrequencyStatus(costing) }
        if (!costing.IsImportedProfile && (!costing?.Project?.ParentProjectId && !costing.IsOPSOOPAdjusted)) {

            // this moves the commercial time cost from WaveSpecs back to profile.WaveSpecs
            // costing = { ...costing, WaveSpecs: [...getState().waveSpecs] };
            costing = await generateSummary({ ...costing }, pacificMarkets);
        }

        // const cb = () =>
        // costing.CountrySpecs[0].MethodologySpecs[0].CostingType = 2;
        costing.CountrySpecs.forEach((data)=>{
            data.MethodologySpecs.forEach((meth)=>{
              meth.CostingType = 2
            })
          })
        axios
            .put("/costingprofiles/" + costing.id, costing, {
                headers: { "auth-token": localStorage.getItem("auth-token") },
            })
            .then((res) => {
                axios.put("/timecost/copydata/"+costing.id)
                if (callback) callback();
                if (!noToastRequired) {
                    toastr.success("Costing Profile Saved", res.data.message);
                }
                let currentCosting = {
                    ...getState().currentCosting.currentCostingProfile, ...costing
                };
                if (costing.ProfileSetting && currentCosting.ProfileSetting.CSRateCardUsed)
                    costing.ProfileSetting.CSRateCardUsed = currentCosting.ProfileSetting.CSRateCardUsed;
                dispatch(updateProfile(costing));
                dispatch(waveSpecsActions.setWaveSpecs(costing.WaveSpecs));
                if (!noGetProfile)
                    dispatch(getCosting(costing.id));
                dispatch(appActions.recordLoadEnd());
            })
            .catch((err) => {
                //console.log("save start");
                // //console.log("error", err);
                toastr.error("Save Failed", err?.data?.error);
                dispatch(appActions.recordLoadEnd());
            });

        // dispatch(generateSummary(cb));
    };
};
export const saveProfileReview = (costing, callback, noToastRequired, noGetProfile) => {


    return (dispatch, getState) => {
        // costing = calculateFinalCosts(costing);
        dispatch(appActions.recordLoadStart());

        // const cb = () =>
        axios
            .put("/costingprofiles/updateProfileReview/" + costing.id, costing, {
                headers: { "auth-token": localStorage.getItem("auth-token") },
            })
            .then((res) => {
                if (callback) callback();
                if (!noToastRequired) {
                    toastr.success("Costing Profile Saved", res.data.message);
                }

                dispatch(updateProfile(costing));
                dispatch(waveSpecsActions.setWaveSpecs(costing.WaveSpecs));
                if (!noGetProfile)
                    dispatch(getCosting(costing.id));
                dispatch(appActions.recordLoadEnd());
            })
            .catch((err) => {
                //console.log("save start");
                // //console.log("error", err);
                toastr.error("Save Failed", err?.data?.error);
                dispatch(appActions.recordLoadEnd());
            });

        // dispatch(generateSummary(cb));
    };
};

export const saveSchedule = (costing, callback) => {
    return (dispatch, getState) => {
        let currentWaveId = getState().currentWaveSpec.id;

        let methodologyWaveBreakdown = _.groupBy(costing.WaveSpecs, m => m.MethodologySpecId)
        costing.CountrySpecs = costing.CountrySpecs?.map(cs => {
            cs.MethodologySpecs = cs.MethodologySpecs?.map(ms => {
                ms.WaveSpecs = methodologyWaveBreakdown[ms.id]
                return { ...ms }
            })
            return { ...cs }
        })

        // costing = calculateFinalCosts(costing);
        // costing = { ...costing, WaveSpecs: [...getState().waveSpecs] }
        dispatch(appActions.recordLoadStart());
        dispatch(appActions.localPageLoadStart())
        axios
            .put("/waves/savewaves", { ...costing })
            .then((res) => {
                axios
                    .post(`/deliveries/${currentWaveId}/deliveryspec`)
                    .then((response) => {
                        if (callback) callback();
                        if (response.status == 201)
                            toastr.success("Save Success", res.data.message);

                        dispatch(appActions.recordLoadEnd());
                        dispatch(appActions.localPageLoadEnd());
                    })
                    .catch((error) => {
                        dispatch(appActions.recordLoadEnd());
                        dispatch(appActions.localPageLoadEnd());
                        toastr.error("Save Failed", error.data.error);
                        // //console.log(error, "error");
                    });
            })
            .catch((err) => {
                // //console.log("error", err);
                toastr.error("Save Failed", err?.data.error);
                dispatch(appActions.recordLoadEnd());
                dispatch(appActions.localPageLoadEnd());
            });
    };
};
export const setCurrencies = (callback) => {
    return (dispatch, getState) => {
        axios
            .get("/marketsettings/currencies/all")
            .then((response) => {

                if (callback) callback();
                dispatch({ type: SET_CURRENCIES, payload: response.data.currencies });
                let currenCosting = {
                    ...getState().currentCosting.currentCostingProfile,
                };

                let currentCostingProfileSetting = {
                    ...currenCosting.ProfileSetting,
                    CurrenciesData: response.data.currencies,
                };
                let costing = {
                    ...currenCosting,
                    ProfileSetting: currentCostingProfileSetting,
                };
                dispatch(updateProfile(costing));
                dispatch(saveCostingProfile(costing, null, true));
            })
            .catch((error) => {
                toastr.error(error.data.error);
            });
    };
};
export const commissionCosting = (callback) => {
    return (dispatch, getState) => {
        let profile = { ...getState().currentCosting.currentCostingProfile };
        let user = getState().user.userRecord.Email;
        if (profile?.Project?.ContractDetails?.length
            && profile.Project.ContractDetails.filter(cd => cd.isSF).length && profile?.ProfileSetting) {

            let reqContractDetails = profile.Project.ContractDetails.filter(cd => cd.isSF)
                .filter(proj => proj.Probability >= profile?.ProfileSetting?.MinimumSFProbability)
            let finalContract = _.head(reqContractDetails)
            if (finalContract) {
                delete profile.Project.DateFieldWorkStartPlanned 
                // = moment(finalContract.StartofDelivery).format("YYYY-MM-DD")
                profile.Project.DateEndOfDeliveryPlanned = moment(finalContract.EndofDelivery).format("YYYY-MM-DD")
            }
        }
        profile.ProfileNumber === "1" ? profile.Project.ProjectStatus = "3" : profile.Project.ProjectStatus = "9"
        profile.Project.CommissionedProfileId = profile.id;
        profile.Project.CommissionedBy = user;
        profile.CommissionedBy = user;
        profile.CommissionedDate = new Date();
        dispatch(commissionCostingProfile(profile, callback));
    };
};
export const generateProfitability = (priceToClient,projectType = "",callback = null) => {
    // //console.log("GENERATE PROFITABILITY");
    return (dispatch, getState) => {

        let profile = {
            ...getState().currentCosting.currentCostingProfile,
            PriceToClient: priceToClient,
        };
        console.log("profile", profile);
        let project = getState().currentProject.newProject;

        if (!profile.ApprovalDetails) {
        dispatch(appActions.pageLoadStart());

            axios
                .get("/marketsettings/" + profile.Project.VerticalId + "/approvers/all")
                .then((res) => {
                    console.log("check");
                    // //console.log(res);
                    // fetch approvalSettings
                    // sort by order (or have this done in backend)
                    res.data.ApprovalDetails.sort((a, b) => {
                        return a.Order - b.Order;
                    });
                    // save approvaldetails to profile
                    profile.ApprovalDetails = [...res.data.ApprovalDetails];
                    // to be done elsewhere:
                    // if level has multiple approvers, check IsMandatoryApprover, if true for multiple approvers, await for all mandatory approvers to approve
                    // else if one of approvers has approved, go to next level until level needed is met
                    // //console.log("after assigning approval details");
                    if(!projectType){
                            if(
                                project.PriceChange == "2"
                            ){
                                profile.ProfileStatus = "5"
                            }
                            else{

                                profile = calcProfitability(profile, project);
                                // if (profile == "Error")
                                // {
                                //     dispatch(appActions.pageLoadEnd())
                                //     return "Error"
                                // }
                            }
                    }
                    else
                    {
                        profile.ApprovalLevelNeeded =   profile.ApprovalDetails.length -1
                        profile.ApprovalLevelReached = -1
                        profile.ApprovalLevelAwaiting = 0
                        profile.ProfileStatus = "2"
                    }
                        

                    // profile = approvalValidation(profile);
                    // // //console.log("after return from approval validation function");
                    // //console.log("after calc profitability");
                    // //console.log(profile);
                    dispatch(updateProfile({ ...profile }));
                    console.log("updated",profile);
                     dispatch(appActions.pageLoadEnd());
                    //  if(profile.CostingTemplateVersion != "v2.0")
                    //  {
                    //  if(callback)
                    //  callback()
                    //  }

                })
                .catch((err) => {
                    // //console.log(err);
                });
        } else {
        

            if(!projectType){
                if(
                    project.PriceChange == "2"
                ){
                    profile.ProfileStatus = "5"
                }
                else{

                    profile = calcProfitability(profile, project);
                    // if (profile == "Error")
                    // {
                    //     dispatch(appActions.pageLoadEnd())
                    //     return "Error"
                    // }
                }
                // profile = calcProfitability(profile, project);
            }
            else
            {
                profile.ApprovalLevelNeeded = profile.ApprovalDetails.length -1
                profile.ApprovalLevelReached = -1
                profile.ApprovalLevelAwaiting = 0
                profile.ProfileStatus = "2"
            }
            // profile = approvalValidation(profile);
            // // //console.log("after return from approval validation function");
            // //console.log("after calc profitability");
            // //console.log(profile);
            dispatch(updateProfile({ ...profile }));
            // if(profile.CostingTemplateVersion != "v2.0")
            // {
            //     dispatch(appActions.pageLoadEnd());

            //    return 1
            // }
        }
    };
};
const sendMail = (template, cb) => {
    //console.log("sendmail start");
    return (dispatch, getState) => {
        const profile = getState().currentCosting.currentCostingProfile;
        const api = "/utils/mail/" + profile.id + "/approval/" + template;
        axios
            .post(api)
            .then(() => {
                //console.log("sendmail done");
                if (cb) cb();
                toastr.success("Email notification sent");

                // //console.log("SEND MAIL SUCCESS", template);
            })
            .catch((error) => {
                toastr.error("Email notification failed");
                // //console.log("SEND MAIL FAILED", template);
                // //console.log(api);
            });
    };
};
export const sendForApproval = () => {
    return (dispatch, getState) => {
        const profile = getState().currentCosting.currentCostingProfile;
        // default field to be created on db
        profile.ApprovalLevelAwaiting = 0; // default
        profile.ApprovalLevelReached = -1; // default

        profile.ApprovalSent = true;
        profile.ProfileStatus = "3";

    //MAIL
    // const cb = dispatch(
    //   saveCostingProfile(profile, dispatch(updateProfile(profile)))
    // );
    // dispatch(sendMail("new", cb));
    let currentLevelDetails = profile.ApprovalDetails.find(
      (a) => a.Order === profile.ApprovalLevelAwaiting
    );
    //close previous requests if any.

        axios.put(`/requests/${profile.id}/Approval/all`, { IsClosed: true, DateClosed: new Date() }).then((res1) => {
            let requestData = {
                CostingProfileId: profile.id,
                RequestType: 'Approval',
                RequestorEmail: profile.CreatedBy,
                AgentEmail: currentLevelDetails.ApproverContacts.map((e) => e.EmailAddress).join(','),
                RequestVersionNumber: 1,
                RequestCurrentLevel: 0,
                RequestLevelDetails: JSON.stringify(profile.ApprovalDetails)
            }
            axios
                .post("/requests", requestData)
                .then((res) => {
                    // dispatch(mailRequest(res.data.Request.id, "new"));
                    dispatch(
                        saveCostingProfile(profile, () => {
                            dispatch(
                                sendMail("new", () => {
                                    dispatch(saveCostingProfile(profile, null, true));
                                    dispatch(updateProfile(profile));
                                })
                            );
                        })
                    );
                })
                .catch((err) => {
                    toastr.error("Send failed", err.data.message);
                });

        }).catch((err) => {
            toastr.error("Send failed", err.data.message);
        });
    };
};
const updateCurrentApprovalLevel = () => {
    return (dispatch, getState) => {
        let profile = getState().currentCosting.currentCostingProfile;
        const previousLevelAwaiting = profile.ApprovalLevelAwaiting;
        // for (let i = 0; i < profile.ApprovalLevelNeeded + 1; i++) {
        //   let level = profile.ApprovalDetails[i];

        profile.ApprovalDetails.forEach((level) => {
            let mandatoryCount = 0;
            let mandatoryApproved = 0;
            let approved = false;
            level.ApproverContacts.forEach((contact) => {
                // checks if approvers are mandatory
                if (contact.IsMandatoryApprover) {
                    mandatoryCount += 1;
                    if (contact.Approved) {
                        mandatoryApproved += 1;
                    }
                }
                // if any approver at the level approves, toggle flag true
                if (contact.Approved) {
                    approved = true;
                }
            });
            // checks both mandatory approver condition and approved condition
            if (approved && mandatoryCount === mandatoryApproved) {
                profile.ApprovalLevelReached = level.Order;
                if (profile.ApprovalLevelReached < profile.ApprovalLevelNeeded) {
                    // doesn't hurt to always increment
                    profile.ApprovalLevelAwaiting = level.Order + 1;
                }
            }
            // //console.log(
            //   "checking level",
            //   mandatoryCount,
            //   mandatoryApproved,
            //   approved
            // );
        });

        // check if approvals have been fulfilled
        if (profile.ApprovalLevelReached >= profile.ApprovalLevelNeeded) {
            // profile is approved
            // no longer awaiting approvals
            // update profileStatus
            profile.ProfileStatus = "5";
            // save and update record
            //all levels reached..
            axios.put(`/requests/${profile.id}/Approval/all`, { RequestCurrentLevel: profile.ApprovalLevelReached, RequestLevelDetails: profile.ApprovalDetails, IsClosed: 1, DateClosed: new Date() }).then((res1) => {
                dispatch(
                    saveCostingProfile(profile, () =>
                        dispatch(sendMail("complete", () => dispatch(updateProfile(profile))))
                    )
                );
            })
            // alert("profile is approved");
        } else {
            // //console.log("profile is not approved");
            // test if profileLevelAwaiting is updated
            if (profile.ApprovalLevelAwaiting !== previousLevelAwaiting) {
                // awaiting new level of approval
                // send mail
                // alert("send mail to new level of approvers");
                // save and update

                //close previous level requests
                axios.put(`/requests/${profile.id}/Approval/all`, { RequestCurrentLevel: previousLevelAwaiting, RequestLevelDetails: profile.ApprovalDetails, IsClosed: 1, DateClosed: new Date() }).then((res1) => {
                    let currentLevelDetails = profile.ApprovalDetails.find((a) => a.Order === profile.ApprovalLevelAwaiting);
                    //add new level request
                    let requestData = {
                        CostingProfileId: profile.id,
                        RequestType: 'Approval',
                        RequestorEmail: profile.CreatedBy,
                        AgentEmail: currentLevelDetails.ApproverContacts.map((e) => e.EmailAddress).join(','),
                        RequestVersionNumber: 1,
                        RequestCurrentLevel: profile.ApprovalLevelAwaiting,
                        RequestLevelDetails: JSON.stringify(profile.ApprovalDetails)
                    }
                    axios
                        .post("/requests", requestData)
                        .then((res) => {
                            // dispatch(mailRequest(res.data.Request.id, "new"));
                            dispatch(
                                saveCostingProfile(profile, () =>
                                    dispatch(sendMail("new", () => dispatch(updateProfile(profile))))
                                )
                            );
                        })
                        .catch((err) => {

                        });
                })
                //MAIL

            } else {
                //update current level details
                axios.put(`/requests/${profile.id}/Approval/all`, { RequestCurrentLevel: previousLevelAwaiting, RequestLevelDetails: profile.ApprovalDetails }).then((res1) => {
                    dispatch(
                        saveCostingProfile(profile, () => dispatch(updateProfile(profile)))
                    );
                })
            }
        }
    };
};
export const approve = () => {
    return (dispatch, getState) => {
        const profile = getState().currentCosting.currentCostingProfile;
        const user = getState().user.userRecord.Email;
        // //console.log(user);
        // sets Approved = true in ApproverContacts in ApprovalDetails at ALL levels
        profile.ApprovalDetails = profile.ApprovalDetails.map((level, index) => {
            // // //console.log("LEVEL", level);
            if (index <= profile.ApprovalLevelNeeded) {
                level.ApproverContacts = level.ApproverContacts.map((contact) => {
                    // TEMP FOR TESTING
                    // if (level.Order === 0) {
                    //   contact.EmailAddress = user;
                    // }
                    ////////////////////
                    if (contact.EmailAddress.toLowerCase() === user.toLowerCase() || contact?.delegateUsers.includes(user.toLowerCase())) {
                        contact.Approved = true;
                    }
                    return contact;
                });
            }
            return level;
        });
        // dispatch(updateProfile(profile));
        dispatch(updateCurrentApprovalLevel());
        // Mail notify approval granted only if level is approved in updateCurrentApprovalLevel
    };
};
export const deny = () => {
    return (dispatch, getState) => {
        const profile = getState().currentCosting.currentCostingProfile;
        const user = getState().user.userRecord.Email;
        // //console.log(user);
        // sets Approved = true in ApproverContacts in ApprovalDetails at ALL levels
        profile.ApprovalDetails = profile.ApprovalDetails.map((level) => {
            // // //console.log("LEVEL", level);

            level.ApproverContacts = level.ApproverContacts.map((contact) => {
                // TEMP FOR TESTING
                // if (level.Order === 0) {
                //   contact.EmailAddress = user;
                // }
                ////////////////////
                if (contact.EmailAddress.toLowerCase() === user.toLowerCase()|| contact?.delegateUsers.includes(user.toLowerCase()) ) {
                    contact.Denied = true;
                    // update profile to be denied here?
                    profile.ProfileStatus = "4";
                }
                return contact;
            });
            return level;
        });
        // dispatch(updateProfile(profile));
        // dispatch(updateCurrentApprovalLevel());
        //MAIL
        axios.put(`/requests/${profile.id}/Approval/all`, { RequestLevelDetails: profile.ApprovalDetails, IsClosed: 1, DateClosed: new Date() }).then((res1) => {
            const cb = dispatch(
                saveCostingProfile(profile, () => dispatch(updateProfile(profile)))
            );
            dispatch(sendMail("deny", () => cb));
        })
    };
};
export const bypassApproval = () => {
    return (dispatch, getState) => {
        const profile = getState().currentCosting.currentCostingProfile;
        profile.ApprovalDetails = profile.ApprovalDetails.map((level) => {
            level.ApproverContacts = level.ApproverContacts.map((contact) => {
                // can add more conditions in here on whether or not user can override
                if (level.Order === profile.ApprovalLevelAwaiting) {
                    contact.Approved = true;
                    level.IsBypassed = true;
                }
                return contact;
            });
            return level;
        });
        // dispatch(updateProfile(profile));
        dispatch(updateCurrentApprovalLevel());
    };
};
export const resetApprovals = () => {
    return (dispatch, getState) => {
        const profile = getState().currentCosting.currentCostingProfile;
        profile.ApprovalDetails = null;
        profile.ApprovalLevelReached = -1;
        profile.ApprovalLevelNeeded = -1;
        profile.ApprovalLevelAwaiting = null;
        profile.ProfileStatus = "1";
        profile.ApprovalNotes = null;
        // profile.ApprovalJustification = null;
        // profile.ApprovalFormData = null;
        profile.ApprovalSent = null;
        profile.NeedsApproval = false;
        dispatch(updateProfile(profile));
    };
};
export const decommissionProfile = (cb) => {
    return (dispatch, getState) => {
        const profile = getState().currentCosting.currentCostingProfile;
        dispatch(appActions.recordLoadStart());
        if (profile.IsImportedProfile) {
            profile.ProfileStatus = "99";
            profile.Project.ProjectStatus = "30";
            const cbb = () => {
                cb();
                dispatch(appActions.recordLoadEnd());
            };
            dispatch(
                saveCostingProfile(profile, () =>
                    dispatch(
                        currentProjectActions.getProject(profile.Project.ProjectId, cbb)
                    )
                )
            );
        } else {
            axios
                .post("/costingprofiles/duplicate/" + profile.id)
                .then((res) => {
                    //console.log(res);
                    profile.ProfileStatus = "99";
                    profile.Project.ProjectStatus = "30";
                    // profile.WaveSpecs = profile.WaveSpecs?.map(ws => {
                    //   ws.EWNVersion = -1
                    //   return ws
                    // })
                    axios.post("/timecost/" + profile.id) 
                    .then((res)=>{
                       // console.log(res)
                   })
                    const cbb = () => {
                        cb();
                        dispatch(appActions.recordLoadEnd());
                    };
                    dispatch(
                        saveCostingProfile(profile, () =>
                            dispatch(
                                currentProjectActions.getProject(profile.Project.ProjectId, cbb)
                            )
                        )
                    );
                })
                .catch((err) => {
                    //console.log(err);
                    toastr.error("Decommission Failed", err?.data?.error);
                    dispatch(appActions.recordLoadEnd());
                });
        }
    };
};
const ETExtOps = ["Eye-tracking glasses rental ",
    "Shipment of  ET glasses/Tablets  ",
    "Tablets",
    "Traveling, training",
    "Venue (if applicable)",
    "Translation (Questionnaire, Briefing note, Showcard, etc.)",
    "Application programming",
    "Questionnaire programming Costs (ConfirmIT, STG, Decipher) - (TCS only)",
    "Questionnaire programming Costs (ConfirmIT, STG, Decipher) - (Non-TCS External)",
    "Store plan drawing",
    "Taking photos of POS in stores",
    "Recruitment only",
    "Fieldwork - (Payment to Interviewer and Supervisors)",
    "Outsourced Vendor Data collection/QC (Non-TCS External)",
    "Incentives",
    "QC/ Assurance cost (Payment to part-timers)",
    "Technican operator",
    "GO costs (Coding Of Verbatim) - (TCS only)",
    "GO costs (Coding Of Verbatim)  - (Non-TCS External)",
    "All other external costs",
    "All Other External costs - Printing/Stationaries costs",
    "All Other General Expenses (eg. Refreshments/Respondent travel..etc)"]
const ETIntCS = ["Project Coordination/Project management ",
    "Questionnaire Review/Inputs",
    "Insight Generation/Topline or Full Report Writing ",
    "Other (specify)…"]
const SSExtOps = [
    "Cost for the Hours to find images online for 2D ",
    "Cost for the Hours to model the 2D products in vendor software",
    "Cost to buy products to photograph for 3D, if applicable",
    "Cost of shipment products for photography",
    "Photography for 3D SKUs (6-10 images per SKU), if applicable",
    "Modelling for 3D SKUs, if applicable",
    "Cost for the Hours to procure custom wallpaper images, if applicable",
    "Other Set-Up materials costs - SPECIFY: ",
    "Other Set-Up materials costs - SPECIFY: ",
    "VR Envoirnment Build / Development Cost (Vendor)",
    "Implicit Analysis Cost (Vendor) if applicable",
    "Sampling costs",
    "Translation questionnaire (if applicable)",
    "Coding OE (please specify number of OE)",
    "Translation verbatims of OE (please specify number of OE)",
    "Other Fieldwork Cost - SPECIFY: ",
    "Other Fieldwork Cost - SPECIFY: ",
    "Webcam Eye-tracking with Sticky (if requested)",
    "Additional Incentives for respondents who agree to participate in Eye Tracking exercise",
    "Video Selfies Cost (assuming 10% of total sample) - based on Nielsen VoxPopMe rate",
    "Translation transcripts from Video Selfie Feedback",
    "Other Video Selfie and/or ET Cost - SPECIFY: ",
    "Programming of questionnaire (TCS or vendor)",
    "Tabulation (CRDC)",
    "Charting (CRDC)",
    "Decipher credits",
    "Other Programming&DP Cost - SPECIFY: ",
    "Other Programming&DP Cost - SPECIFY: "
]
const SSIntOps = [
    "Cost for the Hours to find images online for 2D ",
    "Cost for the Hours to model the 2D products in vendor software",
    "Cost for the Hours to procure custom wallpaper images, if applicable",
    "Project Management costs (Internal Ops time costs)",
    "Programming of questionnaire (internal)",
    "Tabulation (internal)",
    "Others Ops Time Cost - SPECIFY: "
]
const SSIntCS = [
    "Questionnaire Review/Inputs (Internal CS time cost)",
    "Insight Generation/Topline or Full Report Writing  (Internal CS time costs)",
    "Others CS Time Cost - SPECIFY: "
]
export const calcFormula = (profile, pacificMarkets) => {
    let finalCostFields = [];
    let finalExternalOpsFields = [];
    let countryspecs = profile.CountrySpecs;
    const shopperMethodologies = ['SM000024', 'SM000026', 'SM000029']
    const tracking = ["SM000024",
        "SM000029"]
    const stores = ["SM000026"]

    const alternativeLabels = {
        CostTotalInternalCommercial: {
            UsesOopOverrideIntCommCost: "CostIntCommMultiplier",
            // Value: "CostTotalExternalOperations"
        },
        CostTotalInternalOperations: {
            UsesOopOverrideIntOpsCost: "CostIntOpsMultiplier",
            // Value: "CostTotalExternalOperations"
        },
    };
    const shopperNeedCost = ["shopperCostTotalExternalOperations", "shopperCostTotalInternalOperations", "shopperCostTotalInternalCommercial"]
    shopperNeedCost.map(snc => {
        profile[snc] = 0
    })
    const commercialFields = {
        "Associate Director": "CostIntCommAssociateDirector",
        "Data Science": "CostIntCommDataScience",
        Director: "CostIntCommDirector",
        "Executive Director": "CostIntCommExecDirector",
        Executive: "CostIntCommExecutive",
        Manager: "CostIntCommManager",
        "Senior Executive": "CostIntCommSeniorExecutive",
        "Senior Manager": "CostIntCommSeniorManager",
    };
    countryspecs.map((cs) => {
        cs.MethodologySpecs.map((ms) => {
            if (!_.includes(shopperMethodologies, ms.Code)) {
                let costCalFormulas = ms.CalculationSchema;
                Object.keys(costCalFormulas).map((ccf) => {
                    cs[ccf] = 0;
                    Object.keys(costCalFormulas[ccf]).map((obj) => {
                        cs[obj] = 0;
                        cs.MethodologySpecs.map((mth) => {
                            if (mth.CostsData) {
                                cs[obj] =
                                    cs[obj] +
                                    _.sum(
                                        Object.keys(mth.CostsData)
                                            .filter((cdkey) =>
                                                _.includes(costCalFormulas[ccf][obj], cdkey)
                                            )
                                            .map((k) => {
                                                return mth.CostsData[k];
                                            })
                                    );
                            }
                        });
                        cs[ccf] = cs[ccf] + cs[obj];
                    });

                });

                Object.keys(ms.CalculationSchema).map((csch) => {
                    if (!_.includes(finalCostFields, csch)) {
                        finalCostFields.push(csch);
                    }

                    if (
                        !_.includes(finalExternalOpsFields, csch) &&
                        csch == "CostTotalExternalOperations"
                    ) {
                        finalExternalOpsFields.push(csch);
                    }

                    Object.keys(ms.CalculationSchema[csch]).map((insch) => {
                        if (!_.includes(finalCostFields, insch)) {
                            finalCostFields.push(insch);
                        }
                        if (
                            !_.includes(finalExternalOpsFields, insch) &&
                            csch == "CostTotalExternalOperations"
                        ) {
                            finalExternalOpsFields.push(insch);
                        }
                    });


                });
            } else if (_.includes(shopperMethodologies, ms.Code) && ms.CostsData && Object.keys(ms.CostsData).length) {
                // if (_.includes(stores, ms.Code)) {
                //     //CostExtOpsOthers is one of the CostTotalExternalOperations
                //     //just a workaround to not to ruin existing formulae
                //     cs.CostExtOpsOthers = (cs.CostExtOpsOthers ? cs.CostExtOpsOthers : 0) + _.sum(SSExtOps.map(ss => ms.CostsData[ss] ? parseFloat(ms.CostsData[ss]) : 0))
                //     //CostIntOpsOthers is one of the CostTotalInternalOperations
                //     //just a workaround to not to ruin existing formulae
                //     cs.CostIntOpsOthers = (cs.CostIntOpsOthers ? cs.CostIntOpsOthers : 0) + _.sum(SSIntOps.map(ss => ms.CostsData[ss] ? parseFloat(ms.CostsData[ss]) : 0))
                //     //CostIntCommAssociateDirector is one of the CostTotalInternalCommercial
                //     //just a workaround to not to ruin existing formulae
                //     cs.CostIntCommAssociateDirector = (cs.CostIntCommAssociateDirector ? cs.CostIntCommAssociateDirector : 0) +_.sum(SSIntCS.map(ss => ms.CostsData[ss] ? parseFloat(ms.CostsData[ss]) : 0))
                // }
                // else if (_.includes(tracking, ms.Code) && ms.CostsData && Object.keys(ms.CostsData).length) {
                //     //CostExtOpsOthers is one of the CostTotalExternalOperations
                //     //just a workaround to not to ruin existing formulae
                //     cs.CostExtOpsOthers = (cs.CostExtOpsOthers ? cs.CostExtOpsOthers : 0) +_.sum(ETExtOps.map(ss => ms.CostsData[ss] ? parseFloat(ms.CostsData[ss]) : 0))
                //     //CostIntCommAssociateDirector is one of the CostTotalInternalCommercial
                //     //just a workaround to not to ruin existing formulae
                //     cs.CostIntCommAssociateDirector = (cs.CostIntCommAssociateDirector ? cs.CostIntCommAssociateDirector : 0) +_.sum(ETIntCS.map(ss => ms.CostsData[ss] ? parseFloat(ms.CostsData[ss]) : 0))
                // }
                if (_.includes(stores, ms.Code)) {
                    //CostExtOpsOthers is one of the CostTotalExternalOperations
                    //just a workaround to not to ruin existing formulae
                    cs.shopperCostTotalExternalOperations = (cs.shopperCostTotalExternalOperations ? cs.shopperCostTotalExternalOperations : 0) + _.sum(SSExtOps.map(ss => ms.CostsData[ss] ? parseFloat(ms.CostsData[ss]) : 0))
                    //CostIntOpsOthers is one of the CostTotalInternalOperations
                    //just a workaround to not to ruin existing formulae
                    cs.shopperCostTotalInternalOperations = (cs.shopperCostTotalInternalOperations ? cs.shopperCostTotalInternalOperations : 0) + _.sum(SSIntOps.map(ss => ms.CostsData[ss] ? parseFloat(ms.CostsData[ss]) : 0))
                    //CostIntCommAssociateDirector is one of the CostTotalInternalCommercial
                    //just a workaround to not to ruin existing formulae
                    cs.shopperCostTotalInternalCommercial = (cs.shopperCostTotalInternalCommercial ? cs.shopperCostTotalInternalCommercial : 0) + _.sum(SSIntCS.map(ss => ms.CostsData[ss] ? parseFloat(ms.CostsData[ss]) : 0))
                }
                else if (_.includes(tracking, ms.Code) && ms.CostsData && Object.keys(ms.CostsData).length) {
                    //CostExtOpsOthers is one of the CostTotalExternalOperations
                    //just a workaround to not to ruin existing formulae
                    cs.shopperCostTotalExternalOperations = (cs.shopperCostTotalExternalOperations ? cs.shopperCostTotalExternalOperations : 0) + _.sum(ETExtOps.map(ss => ms.CostsData[ss] ? parseFloat(ms.CostsData[ss]) : 0))
                    //CostIntCommAssociateDirector is one of the CostTotalInternalCommercial
                    //just a workaround to not to ruin existing formulae
                    cs.shopperCostTotalInternalCommercial = (cs.shopperCostTotalInternalCommercial ? cs.shopperCostTotalInternalCommercial : 0) + _.sum(ETIntCS.map(ss => ms.CostsData[ss] ? parseFloat(ms.CostsData[ss]) : 0))
                }
            }
        });

        shopperNeedCost.map(snc => {
            profile[snc] = (profile[snc] ? profile[snc] : 0) + cs[snc]
        })
    });
    Object.keys(commercialFields).map(cf => {
        profile[commercialFields[cf]] = 0;
    })

    if (!_.includes(pacificMarkets, profile.Project.CommissioningCountry) ||
    (_.includes(pacificMarkets, profile.Project.CommissioningCountry)  && profile.Project.CostingTemplateVersion == "v2.0") 
    ) {
        countryspecs = countryspecs.map(cs => {
            Object.keys(commercialFields).map(cf => {
                cs[commercialFields[cf]] = 0;
            })
            cs.MethodologySpecs = cs.MethodologySpecs.map(ms => {
                ms.CostingOptions?.filter(co => co.Selected)?.map(co => {
                    Object.keys(commercialFields).map(cf => {
                        cs[commercialFields[cf]] = (cs[commercialFields[cf]] ? cs[commercialFields[cf]] : 0) + (co[commercialFields[cf]] ? co[commercialFields[cf]] : 0)
                    })
                })
                return { ...ms }
            })
            Object.keys(commercialFields).map(cf => {
                profile[commercialFields[cf]] = (profile[commercialFields[cf]] ? profile[commercialFields[cf]] : 0) + cs[commercialFields[cf]]
            })
            return { ...cs }
        })
    } else {
        let waveSpecs = [...profile.WaveSpecs]
        waveSpecs.map(wave => {
            Object.keys(commercialFields).map(cf => {
                profile[commercialFields[cf]] = profile[commercialFields[cf]] + (wave[commercialFields[cf]] ? wave[commercialFields[cf]] : 0)
            })
        })
    }
    let fieldingCountries = countryspecs.filter(
        (cs) => cs.CountryCode !== profile?.Project?.CommissioningCountry
    );
    profile["CostExtOpsMCPSubContract"] = 0;
    fieldingCountries.map((fc) => {
        // fc["CostExtOpsMCPSubContract"] = fc["CostTotalExternalOperations"]
        profile["CostExtOpsMCPSubContract"] =
            profile["CostExtOpsMCPSubContract"] +
            (fc["CostTotalExternalOperations"] - fc["CostExtOpsOtherTaxVAT"]);
    });
    finalCostFields.map((ff) => {
        // if (!currentCostingProfile[ff])
        profile[ff] = 0;
        countryspecs.map((cs) => {
            if (
                !(
                    _.includes(finalExternalOpsFields, ff) &&
                    cs.CountryCode !== profile.Project.CommissioningCountry
                )
            )
                profile[ff] = profile[ff] + cs[ff];
        });
    });
    // if (profile.PriceToClient)
    //     profile.OutOfPocketCostPercent = profile.CostTotalExternalOperations / profile.PriceToClient;

    let finalProfile = {
        ...profile,
        CountrySpecs: countryspecs,
    };
    return finalProfile;
};
export const syncSheetData = (profile, pacificMarkets, callback) => {
    return (dispatch, getState) => {
        dispatch(appActions.pageLoadStart());
        axios
            .put(`/utils/sheets/${profile.id}/costing`)
            .then((response) => {
                let profile = _.cloneDeep(response.data.CostingProfile)
                axios
                    .post('/utils/summary/generate/', { profile })
                    .then((res) => {
                        let profileToSave = res.data.CostingProfile;
                        let _project = getState()?.currentProject?.newProject
                        _project.CostingProfiles = _project.CostingProfiles.map(cp => {
                            if (cp.id == profileToSave.id) {
                                cp = _.cloneDeep(profileToSave)
                            }
                            return _.cloneDeep(cp)
                        })
                        dispatch(currentProjectActions.setCurrentProject(_.cloneDeep(_project)))
                        //todo: please test this; not sure why it is there
                        dispatch(updateProfile({ ..._.cloneDeep(profileToSave), CountrySpecs: [] }));
                        dispatch(countrySpecsActions.setCountrySpecs([]));
                        dispatch(
                            saveCostingProfile(
                                profileToSave,
                                () => {
                                    dispatch(appActions.pageLoadEnd());
                                    dispatch(
                                        countrySpecsActions.setCountrySpecs(profileToSave.CountrySpecs)
                                    );
                                    toastr.success(
                                        "Sheet Sync Success",
                                        "SUCCESS: Costing Sheet Sync Complete."
                                    );
                                },
                                true
                            )
                        );
                        if (callback)
                            callback()
                    }).catch((error) => {
                        if (callback)
                            callback()
                        dispatch(appActions.pageLoadEnd());
                        toastr.error("Sheet Sync Failed", error?.data.error);
                    });
                // let profileToSave = calcFormula({
                //     ...response.data.CostingProfile,
                //     isOldSheet: response.data.isOldSheet,
                // }, pacificMarkets);
            })
            .catch((error) => {
                dispatch(appActions.pageLoadEnd());
                toastr.error("Sheet Sync Failed", error?.data.error);
            });
    };
};
export const unlockPriceToClient = () => {
    return (dispatch, getState) => {
        let profile = getState().currentCosting.currentCostingProfile;
        profile.ProfileStatus = "1";
        dispatch(updateProfile(profile));
        dispatch(resetApprovals());
    };
};
const getCurrentCurrency = (actualvalue, currentCurrency) => {
    let finalVal = _.round(
        actualvalue * currentCurrency.ConversionRateToLocal,
        2
    );
    return `${finalVal} ${currentCurrency.CurrencyUnit}`;
};
export const downloadInPdf = (selectedProperties, currentCurrency) => {
    return (dispatch, getState) => {
        let profile = getState().currentCosting.currentCostingProfile;
        let orientation =
            (profile.CountrySpecs.length > 5 && profile.CountrySpecs.length <= 10) ||
                (profile.WaveSpecs.length > 5 && profile.WaveSpecs.length <= 10)
                ? "landscape"
                : "potrait";
        let format = "A4";
        if (profile.CountrySpecs.length > 6 || profile.WaveSpecs.length > 6) {
            format = "A3";
        }
        if (profile.CountrySpecs.length > 10 || profile.WaveSpecs.length > 10) {
            format = "A2";
        }
        dispatch(appActions.recordLoadStart());
        const tableStyles = `
    <style>
    table{
      font-size:12pt !important;
      border-collapse: collapse;
      margin: 0.5pt 0 0.5pt 0;
    }
    th,td{
      border-top: 1pt solid #ccc;
      border-bottom: 1pt solid #ccc;
      padding: 0.5pt 0.5pt;
      page-break-inside: avoid!important;
      page-break-before: avoid!important;
      max-width:250px;
      word-break:break-word;
    }
    tr{
      page-break-before: avoid!important;
      page-break-inside: avoid!important;
      font-size:10pt !important;
    }
    .mb-0{
      margin-bottom: 0;
    }
    </style>
    `;
        const docheader = `<div style="border-bottom: 1pt double #ccc;"><div style="display:flex;justify-content:space-between;"><h5>Project Name: ${profile.Project.ProjectName} </h5><h5>Project ID: ${profile.Project.ProjectId}<h5></div>
    <div style="display:flex;justify-content:flex-start;"><h5>Profile name:${profile.ProfileName}</h5></div></div>
    `;

        const propertyLookup = {
            profitabilitychecks: {
                id: "profitabilityChecksBreakdown",
                header: "Profitablity Checks",
            },
            profitability: {
                id: "profitabilityBreakdown",
                header: "Profitablity Overview",
            },
            costbreakdown: {
                id: "totalCostbreakdown",
                header: "Total Cost Breakup",
            },
            opsresources: {
                id: "operationsResourceBreakdown",
                header: "Operation Resources",
            },
            methodology: { id: "methodologiesBreakdown", header: "Methodologies" },
            projectDetails: { id: "projectDetails", header: "Project Details" },
            costingOptionsBreakdown: {
                id: "costingOptionsBreakdown",
                header: "Costing Options",
            },
            commercialbreakdown: {
                id: "commercialbreakdown",
                header: "Commercial Time Breakdown",
            },
            shoppersbreakdown: {
                id: "totalShoppersCostbreakdown",
                header: "Shoppers Cost Breakdown (Per Country)"
            },
        };

        let htmlbodyMarkup = "";
        selectedProperties.map((selectedProp) => {
            let currentProp = propertyLookup[selectedProp];
            if (currentProp) {
                let additionalData = "";
                if (selectedProp == "profitability") {
                    additionalData = `<tr style="font-size:12px !important;"><td style="font-size:12px !important;">Actual Price To Client</td> <td style="font-size:12px !important;padding-left:5px;">${getCurrentCurrency(
                        profile.PriceToClient,
                        currentCurrency
                    )}</td></tr>`;
                }
                htmlbodyMarkup = `${htmlbodyMarkup}<br></br><h5>${currentProp.header
                    }</h5><div style="margin-top:1pt;">${document.getElementById(currentProp.id).innerHTML + additionalData
                    }</div>`;
            }
        });

        axios
            .post(
                "/utils/converttopdf",
                {
                    htmlcontent: `${tableStyles}<div id="pageHeader-first">${docheader}</div><div style="margin-top:1pt;">${htmlbodyMarkup}</div>`,
                    orientation,
                    format,
                    docheader,
                },
                {
                    headers: { "auth-token": localStorage.getItem("auth-token") },
                }
            )
            .then((response) => {
                if (response.data && response.data.data && response.data.data.data) {
                    const pdfblob = new Blob([new Uint8Array(response.data.data.data)], {
                        type: "application/pdf",
                    });
                    const downloadLink = document.createElement("a");

                    downloadLink.href = URL.createObjectURL(pdfblob);
                    downloadLink.className = "hidden";
                    document.body.appendChild(downloadLink);

                    downloadLink.setAttribute(
                        "download",
                        `${profile.Project.ProjectId}${profile.Project.ProjectName
                            ? `_${profile.Project.ProjectName}`
                            : ""
                        }_${profile.ProfileNumber}${profile.ProfileName ? `_${profile.ProfileName}` : ""
                        }_summary.pdf`
                    );

                    downloadLink.setAttribute("target", `_blank`);
                    downloadLink.click();
                    dispatch(appActions.recordLoadEnd());
                    window.addEventListener(
                        "focus",
                        (e) => {
                            setTimeout(() => downloadLink.remove(), 300);
                            URL.revokeObjectURL(downloadLink.href);
                        },
                        { once: true }
                    );
                } else {
                    toastr.error("Download failed");
                }
            })
            .catch((error) => {
                dispatch(appActions.recordLoadEnd());
                toastr.error(
                    "Download failed",
                    error.data ? error.data.message : error
                );
            });
    };
};
export const downloadPdf = (id, filename, header) => {
    return (dispatch, getState) => {
        let profile = getState().currentCosting.currentCostingProfile;
        let project = getState().currentProject.newProject;
        let orientation =
            profile.CountrySpecs.length > 5 && profile.CountrySpecs.length <= 10
                ? "landscape"
                : "potrait";
        let format = "A4";
        if (profile.CountrySpecs.length > 6) {
            format = "A3";
        }
        if (profile.CountrySpecs.length > 10) {
            format = "A2";
        }
        // let format = "A4"
        dispatch(appActions.recordLoadStart());
        const tableStyles = `
    <style>
    table{
      font-size:12pt !important;
      border-collapse: collapse;
      margin: 0.5pt 0 0.5pt 0;
    }
    th,td{
      border-top: 1pt solid #ccc;
      border-bottom: 1pt solid #ccc;
      padding: 0.5pt 0.5pt;
      page-break-inside: avoid!important;
      page-break-before: avoid!important;
      
    }
    tr{
      page-break-before: avoid!important;
      page-break-inside: avoid!important;
      font-size:10pt !important;
    }
    .mb-0{
      margin-bottom: 0;
    }
    </style>
    `;
        const docheader = `<div style="border-bottom: 1pt double #ccc;"><div style="display:flex;justify-content:space-between;"><h5>Project Name: ${profile.Project.ProjectName} </h5><h5>Project ID: ${profile.Project.ProjectId}<h5></div>
    <div style="display:flex;justify-content:flex-start;"><h5>Profile name:${profile.ProfileName}</h5></div></div>
    `;
        const pageheader = `<h5>${header}</h5>`;
        axios
            .post(
                "/utils/converttopdf",
                {
                    htmlcontent: `${tableStyles}<div id="pageHeader-first">${docheader}</div>${pageheader}<div style="margin-top:1pt;">${document.getElementById(id).innerHTML
                        }</div>`,
                    orientation,
                    format,
                    docheader,
                },
                {
                    headers: { "auth-token": localStorage.getItem("auth-token") },
                }
            )
            .then((response) => {
                if (response.data && response.data.data && response.data.data.data) {
                    const pdfblob = new Blob([new Uint8Array(response.data.data.data)], {
                        type: "application/pdf",
                    });
                    const downloadLink = document.createElement("a");

                    downloadLink.href = URL.createObjectURL(pdfblob);
                    downloadLink.className = "hidden";
                    document.body.appendChild(downloadLink);

                    downloadLink.setAttribute(
                        "download",
                        `${profile.Project.ProjectId}_${profile.Project.ProjectName}.pdf`
                    );

                    downloadLink.setAttribute("target", `_blank`);
                    downloadLink.click();
                    dispatch(appActions.recordLoadEnd());
                    window.addEventListener(
                        "focus",
                        (e) => {
                            setTimeout(() => downloadLink.remove(), 300);
                            URL.revokeObjectURL(downloadLink.href);
                        },
                        { once: true }
                    );
                } else {
                    toastr.error("Download failed");
                }
            })
            .catch((error) => {
                dispatch(appActions.recordLoadEnd());
                toastr.error(
                    "Download failed",
                    error.data ? error.data.message : error
                );
            });
    };
};
export const saveProjectReviewSchedule = (costing, callback) => {
    return (dispatch, getState) => {
        let currentWaveId = getState().currentWaveSpec.id;

        dispatch(appActions.recordLoadStart());
        axios
            .put("/costingprofiles/" + costing.id, costing, {
                headers: { "auth-token": localStorage.getItem("auth-token") },
            })
            .then((res) => {
                dispatch(appActions.recordLoadEnd());
            })
            .catch((err) => {
                // //console.log("error", err);
                toastr.error("Save Failed", err.data.error);
                dispatch(appActions.recordLoadEnd());
            });
    };
};
export const setReasonForOfflineProposal = (reasons = [], otherReason = null, callback = null) => {
    return (dispatch, getState) => {
        const profile = getState().currentCosting.currentCostingProfile;
        profile.ReasonForOfflineProposal = reasons;
        profile.ReasonForOfflineProposalOther = otherReason;
        dispatch(updateProfile(profile, callback));
    };
};