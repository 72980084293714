import * as types from "../actions/currentProjectActions";

const initialState = {
    newProject: {
        ProjectId: "",
        ProjectName: "",
        ProjectStatus: "1",
        BusinessUnit: "",
        IndustryVertical: "",
        VerticalId: null,
        CommissioningCountry: "",
        CommissioningOffice: "",
        ExcludedFieldingCountries: "",
        Syndicated: false,
        ContractDetails: [],
        ProposalOwnerEmail: {},
        OtherProjectTeamContacts: [],
        IsSFContactSyncPaused: false,
        IsRestrictedProject: false,
        CostingProfiles: [],
        ProjectBackground: "",
        ResearchObjectives: "",
        LeadCostingSPOC: "",
        POCRevenueCountry:"",
        SalesOrgName:"",
        id: null,
        copiedProject:false,
        IscopiedProject:false,
        MSDOperatingCountry:"N/A",
        MSDOperatingCountryMismatch:false
        
    },
    // These are options
    primaryCSContacts: [],
    otherInternalContacts: [],
    costingSPOCs: [],
    //era_start
    newProjectCreated: false,
    //era_end
    opportunityValue: "",
    productValue: [],
    methodologyValue: [],
    opportunityData:{},
    indexcovalue:[],
    indexcodelvalue:[],
    lineitems:[],
    tabledata:[],
    setDelegateEmail:[],
    newCountry: 0,
    newMeth: 0,
    enableMarketDropdown:null
};

export default function currentProjectReducer(state = initialState, actions)
{
    switch (actions.type)
    {
        case types.UPDATE_PROJECT: {
            {
             
            return {
                ...state,
                ...actions.contacts,
            };
           }
        }
        case types.UPDATE_NEW_PROJECT: {
            return {
                ...state,
                newProject: {
                    ...state.newProject,
                    ...actions.newProject,
                },
            };
        }
        case types.SET_FORM_ERROR: {
            return {
                ...state,
                
                    ...actions.errors
                
                ,
            };
        }
        //era_start
        case types.PROJECT_CREATED: {
            return {
                ...state,
                newProjectCreated: true
               
            };
        }
        case types.SET_OPPORTUNITY_VALUE: {

            return {

                ...state,

                opportunityValue: actions.value

               

            };

        }
        case types.SET_MARKETDROPDOWN_VALUE: {

            return {

                ...state,

                enableMarketDropdown: actions.value

               

            };

        }

        case types.SET_PRODUCT_VALUE: {

            return {

                ...state,

                productValue: actions.value

               

            };

        }
        case types.SET_PRODUCTTABLE: {


            return {


                ...state,


                tabledata: actions.payload


            };


        }

        case types.SET_DELEGATEEMAIL: {


            return {


                ...state,


                setDelegateEmail: actions.payload


            };


        }

 

        case types.SET_OPPORTUNITY_DATA: {


            return {


                ...state,


                opportunityData: actions.value


            };


        }
        case types.SET_METHODOLOGY_VALUE: {

            return {

                ...state,

                methodologyValue: [{methodology: actions.value1, product: actions.value2}],
               

            };

        }
        case types.SET_SETINDEXCO_VALUE: {

            return {

                ...state,

                indexcovalue: actions.value

               

            };

        }
        case types.SET_OPPORTUNITYLINEITEMS: {

            return {

                ...state,

                lineitems: actions.payload

               

            };

        }

        case types.SET_SETINDEXCODEL_VALUE: {

            return {

                ...state,

                indexcodelvalue: actions.value

               

            };

        }

       //era
        case types.CLEAR_NEW_PROJECT:
            return {
                ...initialState,
            };
        case types.SET_CURRENT_LOADING_CONTACT:
            return {
                ...state,
                currentContactLoading: actions.contact
            }
        case types.SET_NEW_TAB_COUNTRY: 
            return {
                ...state,
                newCountry: actions.value
            };
        case types.SET_NEW_TAB_METH:
            return {
                ...state,
                newMeth: actions.value
            };
        default:
            return state;
    }
}
