import * as types from "../actions/proposal/ActionTypes";
import update from "immutability-helper";

const initialState = {
    loading: true,
    saving: false,
    resending: false,
    proposals: [],
    proposal: {},
    chats: [],
    error: '',
    msg: '',
    redirectTo: '',
    editMode: false,
    projectId: null,
    acknowledged: false,
    chatData: {},
    hasMore: true,
    totalItems: 0,
    items: [],
    searching: false,
    additionalCostSaved: false
}

export default function proposalReducer(state = initialState, action)
{
    switch (action.type)
    {
        case types.UPDATE_ADDITIONAL_COST_TO_DATA:
            {

                let data = JSON.parse(JSON.stringify(state["proposal"]["Data"]));
                let fields = {
                    "Field Costs": "FieldCosts", "Set-up & Delivery Costs": "SetUpDeliveryCosts"
                    , "Translation Costs": "TranslationCosts", "Other Costs": "OtherCosts", "CS OOP": "CSOOPCosts"
                    , "CS Time Costs": "CSTimeCosts", "VAT/Other Tax Adjustment":"VATOtherTax"
                };
                state["proposal"]["ListCountries"].forEach((c, indexC) =>
                {                     
                    c.Methodologies.forEach((m, indexM) =>
                    {
                        data.filter(d => d.Country == c.Code && d.MethodologyCode == m.Code).forEach((d, indexD) =>
                        {
                            if (d.CPI)
                            {
                                let cpiPerSample = ((parseInt(m["TotalCost"]) || 0) / parseInt(d["RFQSampleSize"])).toFixed(2);
                                d["TotalFinal"] = (parseInt(d["Total"]) || 0) + parseInt(parseInt(d["FinalSampleSize"]) * cpiPerSample);
                                d["CPIFinal"] = (parseInt(d["CPI"]) || 0) + parseInt(cpiPerSample);
                                if (m.SubTotals)
                                {
                                    Object.keys(m.SubTotals).forEach(key =>
                                    {
                                        d[fields[key]] = m.SubTotals[key];
                                    });
                                }
                            }
                        });
                    });
                });                 
                const newState = update(state, {
                    proposal: {
                        Data:
                        {
                            $set: data
                        }
                    }
                    , $merge: { ["additionalCostSaved"]: true }
                });
                return newState;
            }

        case types.OTHER_COST_CHANGED:
            {

                ;
                let totalCost = 0;
                let totalSubCost = 0;
                let otherCosts = state["proposal"]["ListCountries"][action.indexC]["Methodologies"][action.indexM]["OtherCosts"];
                let parent = otherCosts[action.indexOC]["parent"];
                otherCosts.forEach((x, index) =>
                {
                    if (x[x.dataField] && index != action.indexOC)
                    {
                        totalCost += parseInt(x[x.dataField]);
                        if (x["parent"] == parent)
                            totalSubCost += parseInt(x[x.dataField]);
                    }
                });
                let currentVal = action.e.target.value;

                if (currentVal)
                {
                    totalCost += parseInt(currentVal);
                    totalSubCost += parseInt(currentVal);
                }

                const newState = update(state, {
                    proposal: {
                        ListCountries: {
                            [action.indexC]: {
                                Methodologies:
                                {
                                    [action.indexM]:
                                    {
                                        OtherCosts:
                                        {
                                            [action.indexOC]:
                                            {
                                                $merge: { [action.e.target.name]: action.e.target.value }
                                            }
                                        }
                                        , $merge: { ["TotalCost"]: totalCost }
                                        , SubTotals:
                                        {
                                            $merge: { [parent]: totalSubCost }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    , $merge: { ["additionalCostSaved"]: false }
                });
                return newState;
            }

        case types.DATA_CHANGED:
            {

                ;
                const newState = update(state, {
                    proposal: {
                        Data:
                        {
                            [action.rowIndex]:
                            {
                                $merge: { [action.name]: action.value }
                            }
                        }
                    }
                    , $merge: { ["additionalCostSaved"]: false }
                });
                return newState;
            }

        case types.CLEAR_MESSAGE:
            return {
                ...state,
                msg: ''
            }

        case types.SAVING:
            return {
                ...state,
                saving: true
            }

        case types.RESENDING:
            return {
                ...state,
                resending: true
            }

        case types.SET_PROPOSALS:
            return {
                ...state,
                items: action.payload.items,
                totalItems: action.payload.totalItems,
                loading: false,
                hasMore: state.items.length < action.payload.totalItems
            }

        case types.APPEND_PROPOSALS:
            {
                const newState = update(state, { items: { $push: action.payload.items } });
                newState.loading = false;
                newState.hasMore = newState.items.length < newState.totalItems;
                return newState;
            }

        case types.ACKNOWLEDGE_VENDOR_BIDDING_SUCCESS:
            return {
                ...state,
                saving: false,
                msg: 'Bidding Acknowledged Successfully',
                acknowledged: true
            }

        case types.SEARCH_PROPOSALS_REQUEST:
            return {
                ...state,
                msg: null,
                searching: true
            }

        case types.SEARCH_PROPOSALS_SUCCESS:
            return {
                ...state,
                searching: false
            }

        case types.FETCH_PROPOSALS_REQUEST:
            return {
                ...state,
                loading: true,
                msg: null
            }

        case types.FETCH_PROPOSALS_SUCCESS:
            return {
                loading: false,
                proposals: action.payload.proposals,
                totalItems: action.payload.totalItems,
                hasMore: state.proposals.length < action.payload.proposals,
                error: ''
            }

        case types.FETCH_PROPOSALS_FAILURE:
            return {
                loading: false,
                proposals: [],
                error: action.payload
            }

        case types.FETCH_PROPOSAL_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                proposal: action.payload.proposal,
                editMode: action.payload.editMode,
                projectId: action.payload.proposal.ProjectId
            }

        case types.FETCH_PROPOSAL_VENDOR_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                proposal: action.payload.proposal,
                editMode: action.payload.editMode
            }

        case types.FETCH_VENDOR_BIDDING_SUMMARY_SUCCESS:
            return {
                ...state,
                loading: false,
                proposal: action.payload.proposal
            }

        case types.FETCH_PROPOSAL_DETAILS_FAILURE:
            return {
                loading: false,
                proposals: [],
                error: action.payload,
                vendor: {}
            }

        case types.FETCH_PROPOSAL_VENDOR_DETAILS_FAILURE:
            return {
                loading: false,
                proposals: [],
                error: action.payload,
                vendor: {}
            }

            
            case types.SAVE_PROPOSAL_REQUEST:
                return {
                    ...state,
                    saving: true,
                    msg: ''
                }
            case types.SAVE_PROFILE_REQUEST:
                return {
                    ...state,
                    saving: true,
                    msg: ''
                }
        case types.SAVE_PROPOSAL_SUCCESS:
            return {
                ...state,
                saving: false,
                msg: 'Saved Successfully'
            }
        case types.SAVE_PROFILE_SUCCESS:
            return {
                ...state,
                saving: false,
                msg: 'Saved Successfully'
            }
        case types.SAVE_PROPOSAL_FAILURE:
            return {
                ...state,
                saving: false,
                error: action.payload,
                msg: ''
            }
        case types.SAVE_PROFILE_FAILURE:
            return {
                ...state,
                saving: false,
                error: action.payload,
                msg: ''
            }

        case types.API_PROPOSAL_REDIRECT_TO_EDIT:
            return {
                ...state,
                redirectTo: action.link
            };

        case types.CLEAR_PROPOSAL_STATE:
            return { ...initialState };

        case types.FETCH_CHAT_REQUEST:
            return {
                ...state,
                loading: true,
                msg: null
            }
        case types.FETCH_CHAT_FAILURE:
            return {
                ...state,
                loading: false,
                chats: [],
                error: action.payload
            }
        case types.FETCH_CHAT_SUCCESS:
            ;
            return {
                ...state,
                loading: false,
                chats: action.payload
            }
        case types.SAVE_CHAT_REQUEST:
            return {
                ...state,
                saving: true,
                msg: ''
            }

        case types.SAVE_CHAT_SUCCESS:
            return {
                ...state,
                saving: false,
                msg: 'Saved Successfully'
            }
        case types.SAVE_CHAT_FAILURE:
            return {
                ...state,
                saving: false,
                error: action.payload,
                msg: ''
            }

        case types.SAVE_APPROVED_BIDDING_VENDORS_SUCCESS:
            if (action.saveOnly)
                return {
                    ...state,
                    saving: false,
                    resending: false,
                    msg: 'Saved Successfully'
                }
            else
                return {
                    ...state,
                    saving: false,
                    resending: false,
                    msg: 'Submitted Successfully'
                }
        case types.SAVE_APPROVED_BIDDING_VENDORS_FAILURE:
            ;
            return {
                ...state,
                saving: false,
                error: action.payload,
                msg: ''
            }

        case types.SEND_EARLY_WARNING_NOTE_REQUEST:
            return {
                ...state,
                saving: true
            }
        case types.SEND_EARLY_WARNING_NOTE_SUCCESS:
            return {
                ...state,
                loading: false,
                saving: false,
                msg: 'Early Warning Note Has Been Sent'
            }

        case types.SEND_EARLY_WARNING_NOTE_FAILURE:
            return {
                ...state,
                loading: false,
                saving: false,
                msg: action.payload
            }

        default: return state
    }

}