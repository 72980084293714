const NotesFields = [
    {
        label: "Notes for Project Manager",
        value: "NotesPM",
    },
    {
        label: "Notes for Programming",
        value: "NotesFinalQuestionnaire",
    },
    {
        label: "Notes for Translations",
        value: "NotesTranslations",
    },
    {
        label: "Notes for Field work",
        value: "NotesFieldwork",
    },
    {
        label: "Notes for Verbatim Coding",
        value: "NotesVerbatimCoding",
    },
    {
        label: "Notes for Data Processing",
        value: "NotesDataProcessing",
    },
    {
        label: "Notes for Charting",
        value: "NotesCharts",
    },
    {
        label: "Notes for Dashboarding",
        value: "NotesDashboards",
    },
    {
        label: "Notes for Final Report",
        value: "NotesFinalReport",
    },
    {
        label: "Other notes",
        value: "NotesOther",
    },
];
export { NotesFields }