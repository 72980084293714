import React from "react";
import filterFactory, {
    textFilter,
} from "react-bootstrap-table2-filter";
import _ from "lodash";

const reportColumns = [
    "ID",
    "Project ID",
    "Project Name",
    "Microsoft Dynamics Number",
    "WBS Number",
    "Opportunity Number",
    "Account Name",
    "Microsoft Dynamics Stage",
    "Project Manager Email",
    "Commissioning Country",
    "Fielding Countries",
    "Industry",
    "Practice Area",
    "Product Description",
    "Microsoft Dynamics Probability",
    "Methodology",
    "Sub-Methodology",
    "MCP",
    "Syndicated",
    "Project Status",
    "Status Notes",
    "Tracker",
    "Total Waves",
    "Profile ID",
    "Profile CreatedBy",
    "Profile Status",
    "Profile Name",
    "Currency",
    "Price To Client (USD)",
    "TotalExternalOperationsCost (USD)",
    "TotalInternalCommercialCost (USD)",
    "TotalCostsRaw (USD)",
    "Price To Client (LCY)",
    "TotalExternalOperationsCost (LCY)",
    "TotalInternalCommercialCost (LCY)",
    "TotalCostsRaw (LCY)",
    "TotalExtCommConsultant",
    "TotalExtCommTnE",
    "TotalExtCommOtherCS",
    "TotalExternalCommercialCost",
    "TotalIntOpsProgramming",
    "TotalIntOpsProjectManagement",
    "TotalIntOpsDPCA",
    "TotalIntOpsOtherOps",
    "TotalInternalOperationsCost",
    "TotalExtOpsInterviewer",
    "TotalExtOpsTnE",
    "TotalExtOpsResources",
    "TotalExtOpsIncentives",
    "TotalExtOpsConsultant",
    "TotalExtOpsVenueHire",
    "TotalExtOpsPrinting",
    "TotalExtOpsShipping",
    "TotalExtOpsOtherOps",
    "TotalExtOpsMCP",
    "CostExtOpsOtherTaxVAT",
    // "OutOfPocket",
    "ApprovalJustification",
    "TotalOnlineSampleSize",
    "OOP%",
    "CM%"
];

const finalColumns = [
    {
        dataField: "ProfileId",
        text: "ID",
        sort: true,
        formatter: (cell, row) => {
            return <span>{row.ProfileId}</span>;
        },
        csvFormatter: (cell, row) => {
            return row.ProfileId;
        },
        filter: textFilter(),
    },
    {
        dataField: "ProjectNumber",
        text: "Project ID",
        sort: true,
        formatter: (cell, row) => {
            return <span>{row.ProjectNumber || ""}</span>;
        },
        csvFormatter: (cell, row) => {
            return row.ProjectNumber || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "ProjectName",
        text: "Project Name",
        sort: true,
        formatter: (cell, row) => {
            return <span>{row.ProjectName || ""}</span>;
        },
        csvFormatter: (cell, row) => {
            return row.ProjectName || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "SfNumber",
        text: "Microsoft Dynamics Number",
        sort: true,
        formatter: (cell, row) => {
            return <span>{row.SfNumber || ""}</span>;
        },
        csvFormatter: (cell, row) => {
            return row.SfNumber || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "WBSNumber",
        text: "WBS Number",
        sort: true,
        formatter: (cell, row) => {
            return <span>{row.WBSNumber || ""}</span>;
        },
        csvFormatter: (cell, row) => {
            return row.WBSNumber || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "OpportunityNumber",
        text: "Opportunity Number",
        sort: true,
        formatter: (cell, row) => {
            return <span>{row.OpportunityNumber || ""}</span>;
        },
        csvFormatter: (cell, row) => {
            return row.OpportunityNumber || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "AccountName",
        text: "Account Name",
        sort: true,
        formatter: (cell, row) => {
            return <span>{row.AccountName || ""}</span>;
        },
        csvFormatter: (cell, row) => {
            return row.AccountName || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "SfStage",
        text: "Microsoft Dynamics Stage",
        sort: true,
        formatter: (cell, row) => {
            return <span>{row.SfStage || ""}</span>;
        },
        csvFormatter: (cell, row) => {
            return row.SfStage || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "ProjectManagerEmail",
        text: "Project Manager Email",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {row.ProjectManagerEmail}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.ProjectManagerEmail || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "CommissioningCountry",
        text: "Commissioning Country",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {row.CommissioningCountry}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.CommissioningCountry || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "FieldingCountries",
        text: "Fielding Countries",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {row.FieldingCountries && row.FieldingCountries.split(',').join(', ') || ""}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.FieldingCountries && row.FieldingCountries.split(',').join('|') || ""
        },
        filter: textFilter(),
    },
    {
        dataField: "Industry",
        text: "Industry",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {row.Industry}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.Industry || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "PracticeArea",
        text: "Practice Area",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {row.PracticeArea}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.PracticeArea || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "ProductDescription",
        text: "Product Description",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {row.ProductDescription}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.ProductDescription || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "Probability",
        text: "Microsoft Dynamics Probability",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {row.Probability}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.Probability || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "Methodology",
        text: "Methodology",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {row.Methodology && row.Methodology.split(',').join(', ') || ""}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.Methodology && row.Methodology.split(',').join('|') || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "SubMethodology",
        text: "Sub-Methodology",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {row.SubMethodology && row.SubMethodology.split(',').join(', ') || ""}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.SubMethodology && row.SubMethodology.split(',').join('|') || "";
        },
        filter: textFilter(),
    },

    {
        dataField: "IsMultiCountry",
        text: "MCP",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {row.IsMultiCountry}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.IsMultiCountry || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "IsSyndicated",
        text: "Syndicated",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {row.IsSyndicated}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.IsSyndicated || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "ProjectStatus",
        text: "Project Status",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {row.ProjectStatus}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.ProjectStatus || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "NotesProjectStatus",
        text: "Status Notes",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {row.NotesProjectStatus}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.NotesProjectStatus || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "IsTracker",
        text: "Tracker",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {row.IsTracker}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.IsTracker || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "NumberOfWaves",
        text: "Total Waves",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {row.NumberOfWaves}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.NumberOfWaves || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "profileCreatedBy",
        text: "Profile CreatedBy",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {row.profileCreatedBy}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.profileCreatedBy || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "ProfileStatus",
        text: "Profile Status",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {row.ProfileStatus}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.ProfileStatus || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "ProfileName",
        text: "Profile Name",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {row.ProfileName}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.ProfileName || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "Currency",
        text: "Currency",
        sort: true,
        formatter: (cell, row) => {
            if (row.Currency) {
                let values = row.Currency.split("-");
                let currencyUnit = _.last(values);
                return currencyUnit ? currencyUnit : "USD"
            }
            return ""
        },
        csvFormatter: (cell, row) => {
            if (row.Currency) {
                let values = row.Currency.split("-");
                let currencyUnit = _.last(values);
                return currencyUnit ? currencyUnit : "USD"
            }
            return ""
        },
        filter: textFilter(),
    },
    {
        dataField: "PriceToClient",
        text: "Price To Client (USD)",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {`${_.round(row.PriceToClient, 2)}`}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.PriceToClient && `${_.round(row.PriceToClient, 2)}` || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "PriceToClientLcy",
        text: "Price To Client (LCY)",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {`${_.round(row.PriceToClientLcy, 2)}`}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.PriceToClientLcy && `${_.round(row.PriceToClientLcy, 2)}` || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "CostTotalExternalOperations",
        text: "TotalExternalOperationsCost (USD)",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {`${_.round(row.CostTotalExternalOperations, 2)}`}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.CostTotalExternalOperations && `${_.round(row.CostTotalExternalOperations, 2)}` || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "CostTotalExternalOperationsLcy",
        text: "TotalExternalOperationsCost (LCY)",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {`${_.round(row.CostTotalExternalOperationsLcy, 2)}`}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.CostTotalExternalOperationsLcy && `${_.round(row.CostTotalExternalOperationsLcy, 2)}` || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "CostTotalInternalCommercial",
        text: "TotalInternalCommercialCost (USD)",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {`${_.round(row.CostTotalInternalCommercial, 2)}`}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.CostTotalInternalCommercial && `${_.round(row.CostTotalInternalCommercial, 2)}` || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "CostTotalInternalCommercialLcy",
        text: "TotalInternalCommercialCost (LCY)",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {`${_.round(row.CostTotalInternalCommercialLcy, 2)}`}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.CostTotalInternalCommercialLcy && `${_.round(row.CostTotalInternalCommercialLcy, 2)}` || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "CostTotalRaw",
        text: "TotalCostsRaw (USD)",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {`${_.round(row.CostTotalRaw, 2)}`}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.CostTotalRaw && `${_.round(row.CostTotalRaw, 2)}` || "";
        },
        filter: textFilter(),
    },

    {
        dataField: "CostTotalRawLcy",
        text: "TotalCostsRaw (LCY)",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {`${_.round(row.CostTotalRawLcy, 2)}`}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.CostTotalRawLcy && `${_.round(row.CostTotalRawLcy, 2)}` || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "TotalExtCommConsultant",
        text: "TotalExtCommConsultant",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {`${_.round(row.TotalExtCommConsultant, 2)}`}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.TotalExtCommConsultant && `${_.round(row.TotalExtCommConsultant, 2)}` || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "TotalExtCommTnE",
        text: "TotalExtCommTnE",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {`${_.round(row.TotalExtCommTnE, 2)}`}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.TotalExtCommTnE && `${_.round(row.TotalExtCommTnE, 2)}` || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "TotalExtCommOtherCS",
        text: "TotalExtCommOtherCS",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {`${_.round(row.TotalExtCommOtherCS, 2)}`}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.TotalExtCommOtherCS && `${_.round(row.TotalExtCommOtherCS, 2)}` || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "TotalExternalCommercialCost",
        text: "TotalExternalCommercialCost",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {`${_.round(row.TotalExternalCommercialCost, 2)}`}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.TotalExternalCommercialCost && `${_.round(row.TotalExternalCommercialCost, 2)}` || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "TotalIntOpsProgramming",
        text: "TotalIntOpsProgramming",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {`${_.round(row.TotalIntOpsProgramming, 2)}`}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.TotalIntOpsProgramming && `${_.round(row.TotalIntOpsProgramming, 2)}` || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "TotalIntOpsProjectManagement",
        text: "TotalIntOpsProjectManagement",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {`${_.round(row.TotalIntOpsProjectManagement, 2)}`}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.TotalIntOpsProjectManagement && `${_.round(row.TotalIntOpsProjectManagement, 2)}` || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "TotalIntOpsDPCA",
        text: "TotalIntOpsDPCA",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {`${_.round(row.TotalIntOpsDPCA, 2)}`}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.TotalIntOpsDPCA && `${_.round(row.TotalIntOpsDPCA, 2)}` || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "TotalIntOpsOtherOps",
        text: "TotalIntOpsOtherOps",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {`${_.round(row.TotalIntOpsOtherOps, 2)}`}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.TotalIntOpsOtherOps && `${_.round(row.TotalIntOpsOtherOps, 2)}` || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "TotalInternalOperationsCost",
        text: "TotalInternalOperationsCost",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {`${_.round(row.TotalInternalOperationsCost, 2)}`}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.TotalInternalOperationsCost && `${_.round(row.TotalInternalOperationsCost, 2)}` || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "TotalExtOpsInterviewer",
        text: "TotalExtOpsInterviewer",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {`${_.round(row.TotalExtOpsInterviewer, 2)}`}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.TotalExtOpsInterviewer && `${_.round(row.TotalExtOpsInterviewer, 2)}` || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "TotalExtOpsTnE",
        text: "TotalExtOpsTnE",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {`${_.round(row.TotalExtOpsTnE, 2)}`}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.TotalExtOpsTnE && `${_.round(row.TotalExtOpsTnE, 2)}` || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "TotalExtOpsResources",
        text: "TotalExtOpsResources",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {`${_.round(row.TotalExtOpsResources, 2)}`}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.TotalExtOpsResources && `${_.round(row.TotalExtOpsResources, 2)}` || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "TotalExtOpsIncentives",
        text: "TotalExtOpsIncentives",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {`${_.round(row.TotalExtOpsIncentives, 2)}`}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.TotalExtOpsIncentives && `${_.round(row.TotalExtOpsIncentives, 2)}` || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "TotalExtOpsConsultant",
        text: "TotalExtOpsConsultant",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {`${_.round(row.TotalExtOpsConsultant, 2)}`}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.TotalExtOpsConsultant && `${_.round(row.TotalExtOpsConsultant, 2)}` || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "TotalExtOpsVenueHire",
        text: "TotalExtOpsVenueHire",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {`${_.round(row.TotalExtOpsVenueHire, 2)}`}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.TotalExtOpsVenueHire && `${_.round(row.TotalExtOpsVenueHire, 2)}` || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "TotalExtOpsPrinting",
        text: "TotalExtOpsPrinting",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {`${_.round(row.TotalExtOpsPrinting, 2)}`}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.TotalExtOpsPrinting && `${_.round(row.TotalExtOpsPrinting, 2)}` || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "TotalExtOpsShipping",
        text: "TotalExtOpsShipping",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {`${_.round(row.TotalExtOpsShipping, 2)}`}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.TotalExtOpsShipping && `${_.round(row.TotalExtOpsShipping, 2)}` || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "TotalExtOpsOtherOps",
        text: "TotalExtOpsOtherOps",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {`${_.round(row.TotalExtOpsOtherOps, 2)}`}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.TotalExtOpsOtherOps && `${_.round(row.TotalExtOpsOtherOps, 2)}` || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "TotalExtOpsMCP",
        text: "TotalExtOpsMCP",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {`${_.round(row.TotalExtOpsMCP, 2)}`}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.TotalExtOpsMCP && `${_.round(row.TotalExtOpsMCP, 2)}` || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "ApprovalJustification",
        text: "ApprovalJustification",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {row.ApprovalJustification || ""}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.ApprovalJustification || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "TotalOnlineSampleSize",
        text: "TotalOnlineSampleSize",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {row.TotalOnlineSampleSize ? row.TotalOnlineSampleSize : ''}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.TotalOnlineSampleSize || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "OutOfPocketCostPercent",
        text: "OOP%",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {row.OutOfPocketCostPercent ? formattedPrec(row.OutOfPocketCostPercent) : ''}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.OutOfPocketCostPercent && formattedPrec(row.OutOfPocketCostPercent) || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "ContributionMarginPercent",
        text: "CM%",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {row.ContributionMarginPercent ? formattedPrec(row.ContributionMarginPercent) : ''}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.ContributionMarginPercent && formattedPrec(row.ContributionMarginPercent) || "";
        },
        filter: textFilter(),
    }
];

const formattedPrec = (data) => {
    if (data) return (data * 100).toFixed(2) + "%";
    else return "not available";
};

const csvHeaders = finalColumns.map(col => ({ label: col.text, key: col.dataField }));

export { finalColumns, csvHeaders, reportColumns }