import React from 'react';
import './NotificationItem.css'
import { Col, Row } from 'reactstrap';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { format } from "date-fns";

// ReleaseDate:"2024-12-12",
// Type:"BugFix",
// Function:"All",
// Component : "Dashboard",
// Details:"Notification Board",
// Link:"Notification",
// IsHide:null,
// IsShownOrder:0,

const NotificationItem = ({ ReleaseDate, Type, Function: func, Component, Details, Link, IsHide }) => {
  const getTypeClass = (Type) => {
    switch (Type) {
      case 'New Features':
        return 'new-features';
      case 'Enhancements':
        return 'enhancements';
      case 'Bug Fixes':
        return 'bug-fixes';
      default:
        return '';
    }
  };
 
  return (
    (Type !== "") && (!IsHide) && (<div className={`notification-item ${getTypeClass(Type)}`}>
      <Row className=".notification-row">
      <Col className="notification-col-10"  >
      {format(new Date(ReleaseDate), "dd-MMM-yyyy")}
      </Col>
      <Col className="notification-col-10" style = {{fontWeight: 'bold'}} >
      {func}
      </Col>
      <Col className="notification-col-10" style = {{fontWeight: 'bold'}} >
      {Component}
      </Col>
      <Col className="notification-col-60" style = {{textAlign: 'left'}}>
      <div dangerouslySetInnerHTML={{__html:Details}}/>
      </Col>
      <Col className= {!Link ? "notification-col-10 notification-disabled-link" :"notification-col-10  "} style = {{textAlign: 'right'}}>
        <a href={Link}  target="_blank" rel="noopener noreferrer"
         >
        <FaExternalLinkAlt
       
        />
        </a>
      </Col>
      </Row>
    </div>)
  );

};
 
export default NotificationItem;