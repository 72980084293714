import {
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faExclamationTriangle,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { faPencilAlt, faCopy, faPlus, faPlusCircle, faTimesCircle, faUserCheck, faUndo, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import axios from "../../axios-interceptor";
import * as appActions from "../../redux/actions/appActions";
import { useHistory, useLocation, useParams } from "react-router-dom";


import {
  pageLoadEnd,
  pageLoadStart,
  recordLoadEnd,
  recordLoadStart,
  setCostingStatus,
} from "../../redux/actions/appActions";
import { useSelector } from "react-redux";
import update from 'immutability-helper';
import classnames from "classnames";
import './common.css';
import * as currentCostingActions from "../../redux/actions/currentCostingActions";
import _, { size } from "lodash";
import DataSheet from "./DataSheet";
import React, { useRef, useEffect, useState } from "react";
import jspreadsheet from "jspreadsheet-ce";
import "jspreadsheet-ce/dist/jspreadsheet.css";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './spreadsheet.css';
import {
  Col, Row, Card,
  CustomInput,
  CardBody,
  CardTitle,
  CardHeader, FormFeedback, FormGroup, Nav, NavItem, NavLink, TabContent, TabPane
  , Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip, UncontrolledCollapse,
  Table,
  Container,
  Input,
  Button,
} from "reactstrap";

import './common.css';
import Select from "react-select";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvInput,
  AvRadio
} from "availity-reactstrap-validation";
import { func } from "prop-types";
import { updateNewUserData } from "../../redux/actions/manageUserActions";
import { object } from "prop-types";
import { contextType } from "google-map-react";
import { setCurrentProject } from "../../redux/actions/currentProjectActions";
import * as currentProjectActions from "../../redux/actions/currentProjectActions";
import { formatSchemaByVariables, getDataJSON } from "../../utils/rfqMapper";
import Layout from "../../layouts/Project";
import { getLabel } from "../../utils/codeLabels";


const NewSpreadsheet = ({ optionsInput, spreadSheetData, setSpreadSheetData, setTempData, indxSD }) => {


  const profile = useSelector(
    ({ currentCosting }) => currentCosting.currentCostingProfile);
  const costingStatus = useSelector(({ app }) => app.costingStatus);
  const project = useSelector(
    ({ currentProject }) => currentProject.newProject
  );
  const userRecord = useSelector(
    ({ user }) => user.userRecord
  );
  const codeLabels = useSelector(({ codeLabels }) => codeLabels);

  const recordloading = useSelector(
    ({ app }) => app.recordloading
  );
  useEffect(() => {
    if (profile?.CountrySpecs?.length)
      createData();
  }, [profile]);

  useEffect(() => {
    if (profile?.CostingDatum != null)
      setFinalProcessedData(profile.CostingDatum.CostingData);
  }, [profile.CostingDatum]);

  let { profileId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [isCostSelection, setCostSelection] = useState(false);
  let pacificData = _.head(codeLabels.PacificCountriesList)?.Label;
  const [pacificMarkets] = useState(pacificData?.split(','));
  const [activeCostSM, setActiveCostSM] = useState("");
  const [activeSM, setActiveSM] = useState("");
  const [finalData, setFinalData] = useState([]);
  const [sSheetOptionData, setSSheetOptionData] = useState([]);
  const [finalProcessedData, setFinalProcessedData] = useState([]);
  const [interval, setInterval] = useState(0);
  const [activeTabSD, setActiveTabSD] = useState(0);
  const [shopperMethodologies] = useState(['SM000024', 'SM000026', 'SM000029'])
  const [calledProfile, setCalledProfile] = useState(false);
  useEffect(() => {
    if (profileId && !calledProfile) {
      setCalledProfile(true);
      dispatch(currentCostingActions.getCosting(profileId, null, true));
    } else if (!profileId) {
      //console.log("boots to dashboard");
      history.replace("/");
    }
  }, []);
  const tracking = ["SM000024",
    "SM000029"]
  const stores = ["SM000026"]
  const ETExtOps = ["Eye-tracking glasses rental ",
    "Shipment of  ET glasses/Tablets  ",
    "Tablets",
    "Traveling, training",
    "Venue (if applicable)",
    "Translation (Questionnaire, Briefing note, Showcard, etc.)",
    "Application programming",
    "Questionnaire programming Costs (ConfirmIT, STG, Decipher) - (TCS only)",
    "Questionnaire programming Costs (ConfirmIT, STG, Decipher) - (Non-TCS External)",
    "Store plan drawing",
    "Taking photos of POS in stores",
    "Recruitment only",
    "Fieldwork - (Payment to Interviewer and Supervisors)",
    "Outsourced Vendor Data collection/QC (Non-TCS External)",
    "Incentives",
    "QC/ Assurance cost (Payment to part-timers)",
    "Technican operator",
    "GO costs (Coding Of Verbatim) - (TCS only)",
    "GO costs (Coding Of Verbatim)  - (Non-TCS External)",
    "All other external costs",
    "All Other External costs - Printing/Stationaries costs",
    "All Other General Expenses (eg. Refreshments/Respondent travel..etc)"]
  const ETIntCS = ["Project Coordination/Project management ",
    "Questionnaire Review/Inputs",
    "Insight Generation/Topline or Full Report Writing ",
    "Other (specify)…"]
  const SSExtOps = [
    "Cost for the Hours to find images online for 2D ",
    "Cost for the Hours to model the 2D products in vendor software",
    "Cost to buy products to photograph for 3D, if applicable",
    "Cost of shipment products for photography",
    "Photography for 3D SKUs (6-10 images per SKU), if applicable",
    "Modelling for 3D SKUs, if applicable",
    "Cost for the Hours to procure custom wallpaper images, if applicable",
    "Other Set-Up materials costs - SPECIFY: ",
    "Other Set-Up materials costs - SPECIFY: ",
    "VR Envoirnment Build / Development Cost (Vendor)",
    "Implicit Analysis Cost (Vendor) if applicable",
    "Sampling costs",
    "Translation questionnaire (if applicable)",
    "Coding OE (please specify number of OE)",
    "Translation verbatims of OE (please specify number of OE)",
    "Other Fieldwork Cost - SPECIFY: ",
    "Other Fieldwork Cost - SPECIFY: ",
    "Webcam Eye-tracking with Sticky (if requested)",
    "Additional Incentives for respondents who agree to participate in Eye Tracking exercise",
    "Video Selfies Cost (assuming 10% of total sample) - based on Nielsen VoxPopMe rate",
    "Translation transcripts from Video Selfie Feedback",
    "Other Video Selfie and/or ET Cost - SPECIFY: ",
    "Programming of questionnaire (TCS or vendor)",
    "Tabulation (CRDC)",
    "Charting (CRDC)",
    "Decipher credits",
    "Other Programming&DP Cost - SPECIFY: ",
    "Other Programming&DP Cost - SPECIFY: "
  ]
  const SSIntOps = [
    "Cost for the Hours to find images online for 2D ",
    "Cost for the Hours to model the 2D products in vendor software",
    "Cost for the Hours to procure custom wallpaper images, if applicable",
    "Project Management costs (Internal Ops time costs)",
    "Programming of questionnaire (internal)",
    "Tabulation (internal)",
    "Others Ops Time Cost - SPECIFY: "
  ]
  const SSIntCS = [
    "Questionnaire Review/Inputs (Internal CS time cost)",
    "Insight Generation/Topline or Full Report Writing  (Internal CS time costs)",
    "Others CS Time Cost - SPECIFY: "
  ]
  var jRef;

  function toggleCostingInput(tab) {
    if (activeCostSM !== tab) {
      setActiveCostSM(tab);
    }

  };
  var tempdata = [];
  const commercialFields = {
    "Associate Director": "CostIntCommAssociateDirector",
    "Data Science": "CostIntCommDataScience",
    Director: "CostIntCommDirector",
    "Executive Director": "CostIntCommExecDirector",
    Executive: "CostIntCommExecutive",
    Manager: "CostIntCommManager",
    "Senior Executive": "CostIntCommSeniorExecutive",
    "Senior Manager": "CostIntCommSeniorManager",
  };

  //had to make a copy of Save as Draft from country methodology tabs final, wanted to make this component independent
  function saveDraft(notAPI) {
    let _profile = { ...project.CostingProfiles.find(cp => cp.id == profile.id) };//_.last(project.CostingProfiles) //currentCosting//_.last(project.CostingProfiles)

    // _profile = { ..._profile, ...currentCosting }
    _profile.AdditionalSheetId = profile.AdditionalSheetId ? profile.AdditionalSheetId : null
    _profile.CostingType = profile.CostingType ? profile.CostingType : null
    _profile.CostingsSheetId = profile.CostingsSheetId ? profile.CostingsSheetId : null
    _profile.Project = project
    _profile.ProfileSetting = profile.ProfileSetting
    if (!_.includes(pacificMarkets, project.CommissioningCountry)||
    (_.includes(pacificMarkets, project.CommissioningCountry)  && project.CostingTemplateVersion == "v2.0")) {

      _profile.CountrySpecs = _profile.CountrySpecs?.map(cs => {
        let requiredcountry = { ...profile.CountrySpecs.find(CS => CS.id == cs.id) }
        if (requiredcountry) {
          cs.MethodologySpecs = cs.MethodologySpecs.map(ms => {
            // let requiredMeth = { ...requiredcountry.MethodologySpecs.find(MS => MS.id == ms.id) }
            // delete requiredMeth.CostingOptions
            // delete requiredMeth.NotApplicable
            // delete requiredMeth.Responsibilities
            // delete requiredMeth.ResponsibilityData
            // delete requiredMeth.ResponsibilitySchema
            // delete requiredMeth.SetupData
            // delete requiredMeth.SetupSchema
            // delete requiredMeth.RFQData
            // delete requiredMeth.RFQSchema
            return { ...ms }//, ...requiredMeth }
          })
        }
        delete requiredcountry.MethodologySpecs
        return { ...cs, ...requiredcountry }
      })
    }
    if (!_.includes(pacificMarkets, project.CommissioningCountry)||
    (_.includes(pacificMarkets, project.CommissioningCountry)  && project.CostingTemplateVersion == "v2.0")) {
      _profile.WaveSpecs = [...profile.WaveSpecs]
    }
    var WaveSpecsAll = _profile?.WaveSpecs || [];
    let temporaryWavesForValidation = []
    _profile?.CountrySpecs.forEach(cs => {
      cs.MethodologySpecs.forEach(ms => {
        ms.RFQSchema = formatSchemaByVariables(_.cloneDeep(ms.RFQSchema), _.cloneDeep(ms.Variables));
        ms.RFQData = getDataJSON(_.cloneDeep(ms.Variables));
        //let WaveSpecs=ms.WaveSpecs||[];
        var costingOptionWaves;
        if (ms.CostingOptions.length)
          costingOptionWaves = ms.CostingOptions[0]?.Variables.filter(x => x.label == 'Number of Waves');
        else {
          costingOptionWaves = ms.Variables.filter(x => x.id == "numberOfWaves")
        } var WaveSpecs = WaveSpecsAll.filter(x => x.MethodologySpecId == ms.id);
        var waveSize = (costingOptionWaves.length > 0) ? costingOptionWaves[0].value : (ms.OpResourcesVariables.filter(x => x.label == 'Number of Waves').length > 0 ? ms.OpResourcesVariables.filter(x => x.label == 'Number of Waves')[0].value : 1) || 1;
        let waves = WaveSpecs.length > 0 && WaveSpecs.length == waveSize ? WaveSpecs.length : waveSize;
        if (!waves) {
          waves = 1;
        }
        if (!_.includes(pacificMarkets, project.CommissioningCountry)||
        (_.includes(pacificMarkets, project.CommissioningCountry)  && project.CostingTemplateVersion == "v2.0")
        ) {
          var tempWaveSpecs = [];
          for (let i = 0; i < waves; i++) {
            var id = WaveSpecs.length > 0 ? (WaveSpecs[i] ? WaveSpecs[i].id : null) : null;
            tempWaveSpecs.push({
              id: id,
              WaveNumber: i + 1,
              ResponsibilitySchema: _.cloneDeep(ms.Responsibilities),
              OpsResourcesSchema: formatSchemaByVariables(_.cloneDeep(ms.SetupSchema), _.cloneDeep(ms.OpResourcesVariables)),
              OpsResourcesData: getDataJSON(_.cloneDeep(ms.OpResourcesVariables)),
              MethodologySpecId: ms.id,
              CostingProfileId: _profile?.id
            });

            ms["WaveSpecs"] = tempWaveSpecs;
            temporaryWavesForValidation = [...temporaryWavesForValidation, ...tempWaveSpecs];
          }

          ///For Wave Specs Deletion
          if (WaveSpecs.length > waves) {
            for (let i = waves; i < WaveSpecs.length; i++) {
              var id = WaveSpecs[i].id;
              tempWaveSpecs.push({
                id: id,
                toRemove: true,
                WaveNumber: (i + 1),
                ResponsibilitySchema: _.cloneDeep(ms.Responsibilities),
                OpsResourcesSchema: formatSchemaByVariables(_.cloneDeep(ms.SetupSchema), _.cloneDeep(ms.OpResourcesVariables)),
                OpsResourcesData: getDataJSON(_.cloneDeep(ms.OpResourcesVariables)),
                MethodologySpecId: ms.id,
                CostingProfileId: _profile?.id
              });
              ms["WaveSpecs"] = tempWaveSpecs;
              temporaryWavesForValidation = [...temporaryWavesForValidation, ...tempWaveSpecs]
            }
          }
        } else {
          //todo: wave specs deletion and updation
          //when _.groupBy(profile.WaveSpecs).length>tempWaveSpecs delete respective number of wavespecs 
          //for that methodology accordingly, likewise _.groupBy(profile.WaveSpecs).length<tempWaveSpecs
          //create wavespecs, if you fill wavespecs and without IDs it'll create in backend
          var pacificTempWaves = [];
          let waveMethCount = profile.WaveSpecs.filter(x => x.MethodologySpecId === ms.id);

          // to add
          if (waveMethCount.length < waves) {
            for (let i = 0; i < waves; i++) {
              var id = waveMethCount.length > 0 ? (waveMethCount[i] ? waveMethCount[i].id : null) : null;
              pacificTempWaves.push({
                id: id,
                WaveNumber: i + 1,
                ResponsibilitySchema: _.cloneDeep(ms.Responsibilities),
                CommercialHoursData: waveMethCount[i] ? (_.cloneDeep(waveMethCount[i].CommercialHoursData)) : _.cloneDeep(ms?.CommercialHoursData),
                OpsResourcesSchema: waveMethCount[i] ? (_.cloneDeep(waveMethCount[i].OpsResourcesSchema)) : formatSchemaByVariables(_.cloneDeep(ms.SetupSchema)),
                OpsResourcesData: waveMethCount[i] ? (_.cloneDeep(waveMethCount[i].OpsResourcesData)) : {},
                MethodologySpecId: ms.id,
                CostingProfileId: profile?.id
              });
              ms["WaveSpecs"] = pacificTempWaves;
              temporaryWavesForValidation = [...temporaryWavesForValidation, ...pacificTempWaves];

            }
          }
          if (pacificTempWaves.length === 0) {
            let data = profile.WaveSpecs.filter(x => x.MethodologySpecId === ms.id).map(x => {
              return { ...x, ResponsibilitySchema: _.cloneDeep(ms.Responsibilities) }
            });
            ms["WaveSpecs"] = data;
            temporaryWavesForValidation = [...temporaryWavesForValidation, ...data];
          }


          // to Remove
          if (waveMethCount.length > waves) {
            for (let i = waves; i < waveMethCount.length; i++) {
              var id = waveMethCount[i].id;
              pacificTempWaves.push({
                id: id,
                toRemove: true,
                WaveNumber: (i + 1),
                ResponsibilitySchema: _.cloneDeep(ms.Responsibilities),
                CommercialHoursData: waveMethCount[i] ? (_.cloneDeep(waveMethCount[i].CommercialHoursData)) : _.cloneDeep(ms?.CommercialHoursData),
                OpsResourcesSchema: waveMethCount[i] ? (_.cloneDeep(waveMethCount[i].OpsResourcesSchema)) : formatSchemaByVariables(_.cloneDeep(ms.SetupSchema)),
                OpsResourcesData: waveMethCount[i] ? (_.cloneDeep(waveMethCount[i].OpsResourcesData)) : {},
                MethodologySpecId: ms.id,
                CostingProfileId: _profile?.id
              });
              ms["WaveSpecs"] = pacificTempWaves;
              temporaryWavesForValidation = [...temporaryWavesForValidation, ...pacificTempWaves]
            }
          }
        }
        if (!_.includes(pacificMarkets, project.CommissioningCountry)
        ||(_.includes(pacificMarkets, project.CommissioningCountry)  && project.CostingTemplateVersion == "v2.0")
        )
          ms.CostingOptions.forEach(co => {
            co["id"] = co.id;
            co["Name"] = co.label;
            co["Selected"] = co.isSelected;
            co["CostingOptionSchema"] = _.cloneDeep(co.Variables);
            co["CostingOptionData"] = getDataJSON(_.cloneDeep(co.Variables));
            co["MethodologySpecId"] = ms.id;
          });
      })
    });
    //commercial costs recounting if there any and helps in case if any costing option got deleted
    Object.keys(commercialFields).map(cf => {
      _profile[commercialFields[cf]] = 0
    })
    _profile.CountrySpecs = _profile.CountrySpecs.map(cs => {
      Object.keys(commercialFields).map(cf => {
        cs[commercialFields[cf]] = 0
      })
      cs.MethodologySpecs = cs.MethodologySpecs?.map(ms => {
        ms.CostingOptions?.filter(co => co.Selected)?.map(co => {
          Object.keys(commercialFields).map(cf => {
            cs[commercialFields[cf]] = (cs[commercialFields[cf]] ? cs[commercialFields[cf]] : 0) + (co[commercialFields[cf]] ? co[commercialFields[cf]] : 0)
          })
        })
        return { ...ms }
      })
      Object.keys(commercialFields).map(cf => {
        _profile[commercialFields[cf]] = (_profile[commercialFields[cf]] ? _profile[commercialFields[cf]] : 0) + cs[commercialFields[cf]]
      })
      return { ...cs }
    })

    if (_profile.WaveSpecs.length > 0) {
      const noWaves = Object.values(_.groupBy(_profile.WaveSpecs, 'MethodologySpecId')).map(O => O.length);
      _profile.NumberOfWaves = _.max(noWaves);
    }
    if (project.ProjectName !== profile.Project.ProjectName) {
      profile.Project.ProjectName = project.ProjectName
    }

    if (!notAPI) {
      dispatch(currentCostingActions.saveCostingProfileDraft(_profile));
    }
    else {
      return { ..._profile, WaveSpecs: temporaryWavesForValidation }
    }

  }
  function createData() {
    // if (profile.CostingDatum) {
    //   var tempdata = profile.CostingDatum.CostingData;
    //   var tempdatanew = profile.CostingDatum.CostingData;
    //   var d;

    //   tempdatanew.map((a, b) => {

    //       tempdata[b].cols = { ...a.cols, len: Object.entries(a.rows['5'].cells).length }
    //       tempdata[b].rows = { ...a.rows, len: Object.entries(a.rows).length }
    //     }
    //   )
    //   var rows = tempdatanew.rows;



    //   setSSheetOptionData(tempdata);
    //   setFinalProcessedData(tempdata);
    //   setInterval(true)
    //   // setSpreadSheetOption({
    //   //     ...spreadSheetOption,
    //   //     data: tempdatanew.data.map(d => d.map(di => (di.label == '' ? di.value : di.label)))
    //   //     , columns: tempdatanew.columns
    //   //     , nestedHeaders: tempdatanew.nestedHeaders
    //   //     , mergeCells: tempdatanew.mergeCells
    //   // });

    // }



    var countryLength = profile?.CountrySpecs?.length;
    var commissioningCountry = profile?.Project?.CommissioningCountry;
    var methodologies = [...new Map(profile.CountrySpecs.reduce((result, c) => {
      return c.MethodologySpecs.filter(ms => _.includes(shopperMethodologies, ms.Code) && !ms.NotApplicable).reduce((result, sm) => {
        result.push({ "id": sm.id, "Label": sm.Label, "Code": sm.Code, "CostingOptions": sm.CostingOptions, "Quant": sm.Quant, "Qual": sm.Qual });
        return result;
      }, result);
    }, []).map(item => [item["Code"], item])).values()];
    var finalList = [];


    methodologies.map(mm => {
      var Getmaxlength = [];
      profile.CountrySpecs.map(c => {
        c.MethodologySpecs.map(m => {
          if (m.Code == mm.Code) {
            m.CostingOptions.map(mc => {
              mc.CostingOptionSchema.map(ms => {
                if (!Getmaxlength.find(f => f.label == ms.label))
                  Getmaxlength.push({ "label": ms.label });
              })

            })
          }
        })
      })
      mm["maxLength"] = Getmaxlength.length;
    })

    //// Creating List based on Methodologies
    methodologies.map(m => {
      var rowNum = 1;
      var mSchema = { "Code": m.Code, "Label": m.Label, "schema": [] };
      var template = profile.CostingTemplateInput[0];
      if (m.Code == 'SM000024' || m.Code == 'SM000029')
        template = profile.CostingTemplateInput[2];
      if (m.Code == 'SM000026')
        template = profile.CostingTemplateInput[1];

      template.map((ct, cti) => {
        if ((m.Qual && !ct.Qual || (
          (Array.isArray(profile.FieldingCountries)? profile.FieldingCountries[0].value:profile.FieldingCountries).split(',').some(ai => ct.show && ct.show.includes(ai))) || m.Quant && !ct.show || !m.Quant && !m.Qual) && countryLength == 1 && !ct.allMC && (!ct.hide && !ct.allSC && !ct.allMC && !ct.SC || ct.hide && ct.hide.indexOf(commissioningCountry) < 0 || ct.allSC || ct.SC && ct.SC.indexOf(commissioningCountry) >= 0)) {
          rowNum++;
          mSchema.schema.push({ ...ct, "rowNum": rowNum });
        }
        else if ((m.Qual && !ct.Qual || (profile.FieldingCountries.split(',').some(ai => ct.show && ct.show.includes(ai))) || m.Quant && !ct.show || !m.Quant && !m.Qual) && countryLength > 1 && !ct.allSC && (!profile.FieldingCountries.split(',').every(ai => ct.hide && ct.hide.includes(ai)) || ct.allMC)) {
          rowNum++;
          mSchema.schema.push({ ...ct, "rowNum": rowNum });
        }

        if (ct.Label == "Costing Option" || ct.Label == "IF EXTERNAL - PLS SPECIFY VENDOR NAME  [MANDATORY]")
          rowNum = rowNum + m.maxLength;
      }
      )
      finalList.push(mSchema);
    })

    var alphabets = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "AA", "AB", "AC", "AD", "AE", "AF", "AG", "AH", "AI", "AJ", "AK", "AL", "AM", "AN", "AO", "AP", "AQ", "AR", "AS", "AT", "AU", "AV", "AW", "AX", "AY", "AZ", "BA", "BB", "BC", "BD", "BE", "BF", "BG", "BH", "BI", "BJ", "BK", "BL", "BM", "BN", "BO", "BP", "BQ", "BR", "BS", "BT", "BU", "BV", "BW", "BX", "BY", "BZ", "CA", "CB", "CC", "CD", "CE", "CF", "CG", "CH", "CI", "CJ", "CK", "CL", "CM", "CN", "CO", "CP", "CQ", "CR", "CS", "CT", "CU", "CV", "CW", "CX", "CY", "CZ"];

    var datadetails = [];
    var dataFinal = [];
    var vCost = .1;

    var ref

    finalList.map((mAll, mAlli) => {
      var merges = [];
      var cols1 = {
        len: 10,
        0: { width: 550 },
      }
      var rows = {};
      var sOptions = {
        name: mAll.Label, Code: mAll.Code, freeze: "B10",
        styles: [
          {
            bgcolor: '#B7B7B7',
            textwrap: true,
            color: '#004d54',
            border: {
              top: [
                'thin',
                '#0366d6'
              ],
              bottom: [
                'thin',
                '#0366d6'
              ],
              right: [
                'thin',
                '#0366d6'
              ],
              left: [
                'thin',
                '#0366d6'
              ],
            },
          },
          {
            bgcolor: '#4285F4',
            color: '#004d54',
            font: { bold: true },
          },
          {
            bgcolor: '#4285F4',
            color: 'white',
            font: { bold: true },
          },
          {
            font: { bold: true },
            fontItalic: true,
            bgcolor: '#00b0f0',
            color: '#004d54',
          },
          {
            bgcolor: '#e5b8b7',
            color: '#004d54',
            font: { bold: true },
          },
          {
            font: { bold: true },
            bgcolor: '#ffff00',
            color: '#004d54',
          },
          {
            bgcolor: '#cccccc',
            color: '#004d54',
            font: { bold: true },
          },
          {
            bgcolor: '#f48174',
            color: '#004d54',
            font: { bold: true },
          },
          {
            textwrap: true,
            align: "left",
          },
          {
            font: { bold: true },
            align: "left",
            bgcolor: '#1C4587',
            color: 'white',
          },
          {
            textwrap: true,
            color: '#004d54',
            align: "center",
            border: {
              top: [
                'thin',
                '#0366d6'
              ],
              bottom: [
                'thin',
                '#0366d6'
              ],
              right: [
                'thin',
                '#0366d6'
              ],
              left: [
                'thin',
                '#0366d6'
              ],
            }
          },
          {
            bgcolor: '#4285F4',
            color: '#004d54',
            align: "center"
          },
          {
            bgcolor: '#4285F4',
            color: 'white',
            align: "center"
          },
          {
            bgcolor: '#00b0f0',
            color: '#004d54',
            align: "center"
          },
          {
            bgcolor: '#e5b8b7',
            color: '#004d54',
            align: "center"
          },
          {
            bgcolor: '#ffff00',
            color: '#004d54',
            align: "center"
          },
          {
            bgcolor: '#cccccc',
            color: '#004d54',
            align: "center"
          },
          {
            bgcolor: '#f48174',
            color: '#004d54',
            align: "center"
          },
          {
            align: "center"
          },
          {
            align: "center",
            bgcolor: '#1C4587',
            color: 'white',
          },
          {
            font: { bold: true },
            color: '#004d54',
            bgcolor: "#92D050",
            align: "left"
          },
          {
            font: { bold: true },
            color: '#004d54',
            bgcolor: "#E5B8B7",
            align: "left"
          },
          {
            font: { bold: true },
            color: '#004d54',
            bgcolor: "#FFFF00",
            align: "left"
          },
          {
            font: { bold: true },
            color: '#004d54',
            bgcolor: "#FF0000",
            align: "left"
          },
          {
            font: { bold: true },
            color: '#004d54',
            bgcolor: "#FFC000",
            align: "left"
          },
          {
            font: { bold: true },
            color: '#004d54',
            bgcolor: "#00B0F0",
            align: "left"
          },
          {
            font: { bold: true },
            color: '#004d54',
            bgcolor: "#FBD4B4",
            align: "left"
          },
          {
            font: { bold: true },
            color: 'white',
            bgcolor: "#274E13",
            align: "left"
          },
          {
            font: { bold: true },
            bgcolor: "#B45F06",
            align: "left"
          },
          {
            font: { bold: true },
            align: "left"
          },
          {
            color: '#004d54',
            bgcolor: "#92D050",
            align: "center"
          },
          {
            color: '#004d54',
            bgcolor: "#E5B8B7",
            align: "center"
          },
          {
            color: '#004d54',
            bgcolor: "#FFFF00",
            align: "center"
          },
          {
            color: '#004d54',
            bgcolor: "#FF0000",
            align: "center"
          },
          {
            color: '#004d54',
            bgcolor: "#FFC000",
            align: "center"
          },
          {
            color: '#004d54',
            bgcolor: "#00B0F0",
            align: "center"
          },
          {
            color: '#004d54',
            bgcolor: "#FBD4B4",
            align: "center"
          },
          {
            color: 'white',
            bgcolor: "#274E13",
            align: "center"
          },
          {
            bgcolor: "#B45F06",
            align: "center"
          },
          {
            font: { bold: true },
            align: "center"
          },
          {
            font: { bold: true },
            color: '#004d54',
            bgcolor: "#92D050",
            align: "left"
          },
          {
            font: { bold: true },
            color: '#004d54',
            bgcolor: "#E5B8B7",
            align: "left"
          },
          {
            font: { bold: true },
            color: '#004d54',
            bgcolor: "#ffc15d",
            align: "left"
          },
          {
            font: { bold: true },
            color: '#004d54',
            bgcolor: "#4285f4",
            align: "left"
          },
          {
            font: { bold: true },
            color: '#004d54',
            bgcolor: "#ffe6de",
            align: "left"
          },
          {
            font: { bold: true },
            color: '#004d54',
            bgcolor: "#0084ba",
            align: "left"
          },
          {
            font: { bold: true },
            color: '#004d54',
            bgcolor: "#cce2ff",
            align: "left"
          },
          {
            font: { bold: true },
            color: '#004d54',
            bgcolor: "#ff2421",
            align: "left"
          },
          {
            font: { bold: true },
            color: '#004d54',
            bgcolor: "#00b45c",
            align: "left"
          },
          {
            font: { bold: true },
            color: '#004d54',
            bgcolor: "#cccccc",
            align: "left"
          },
          {
            color: '#004d54',
            bgcolor: "#92D050",
            align: "center"
          },
          {
            color: '#004d54',
            bgcolor: "#E5B8B7",
            align: "center"
          },
          {
            color: '#004d54',
            bgcolor: "#ffc15d",
            align: "center"
          },
          {
            color: '#004d54',
            bgcolor: "#4285f4",
            align: "center"
          },
          {
            color: '#004d54',
            bgcolor: "#ffe6de",
            align: "center"
          },
          {
            color: '#004d54',
            bgcolor: "#0084ba",
            align: "center"
          },
          {
            color: '#004d54',
            bgcolor: "#cce2ff",
            align: "center"
          },
          {
            color: '#004d54',
            bgcolor: "#ff2421",
            align: "center"
          },
          {
            color: '#004d54',
            bgcolor: "#00b45c",
            align: "center"
          },
          {
            color: '#004d54',
            bgcolor: "#cccccc",
            align: "center"
          }
        ],


        cols: cols1
      };
      rows = { len: mAll.schema.length + 20 };
      rows = { ...rows, [0]: { id: 500, cells: {} } };
      rows = { ...rows, [1]: { id: 501, cells: {} } };
      //  rows[0].cells = { ...rows[0].cells, [0]: { text: mAll.Label,style:39,editable:false} };
      var bcounter = 1;

      mAll.schema.map((ct, cti) => {
        var datacosting = [];
        var datacostingschema = [];

        var datarow = [];
        var counter = 1;
        var newstyle = {};
        if (ct.Label == "COST BREAKDOWN - OUT OF POCKET ONLY") {
          ref = (ct.rowNum + 1).toString();
        }

        rows = { ...rows, [ct.rowNum]: { id: ct.id, cells: { [0]: { text: ct.Label == "Total cost interviewing & supervision" ? "Total cost interviewing & supervision (" + (ct.rowNum + 2).toString() + "-" + (profile.CountrySpecs.length == 1 ? ct.rowNum + 5 : ct.rowNum + 3).toString() + ")" : ct.Label, row: ct.rowNum, editable: ct.id > 240 && ct.id < 300 ? true : false, style: ct.style ? ct.style : 8 } } } };

        profile.CountrySpecs.map((c, ci) => {
          var Countryflag = 1;
          var cells = {};

          c.MethodologySpecs.map((m, mi) => {

            m.CostingOptions.map((co, coi) => {
              if (m.Code == mAll.Code) {
                if (cti == 0 && coi == 0 && m.Code == mAll.Code) {
                  rows[cti].cells = { ...rows[0].cells, [0]: { text: "Methodology : " + mAll.Label, style: 39, editable: false } };

                  rows[cti + 1].cells = { ...rows[1].cells, [0]: { text: "Costing Options", style: 39, editable: false } };

                  rows[cti].cells = { ...rows[cti].cells, [counter]: { text: c.CountryName, row: 0, col: counter, style: 39, cellAddress: alphabets[counter] + (ct.rowNum + 1), editable: false } };
                  if (m.CostingOptions.length > 1)
                    merges.push(alphabets[counter] + 1 + ":" + alphabets[counter + m.CostingOptions.length - 1] + 1);

                }

                if (cti == 0 && m.Code == mAll.Code) {

                  sOptions.cols = { ...sOptions.cols, [counter]: { width: 250 } }
                  datarow.push({ "title": co.label, colspan: 1 });


                  rows[cti + 1].cells = { ...rows[cti + 1].cells, [counter]: { text: co.label, isSelect: co.Selected, coid: co.id, mid: m.Code, mspec: m.id, cid: c.CountryCode, row: 1, style: 39, col: counter, cellAddress: alphabets[counter] + (ct.rowNum + 1) } };
                  bcounter++;
                }
                if (ct.subheader) {
                  merges.push("A" + (ct.rowNum + 1) + ":" + alphabets[bcounter - 1] + (ct.rowNum + 1));
                }
                if (cti == 1 && coi == 0 && m.Code == mAll.Code)
                  sOptions.cols.len = bcounter;
                if (ct.id == 1)
                  rows[ct.rowNum].cells = { ...rows[ct.rowNum].cells, [counter]: { text: profile.Project.CommissioningCountry == c.CountryCode ? "COMMISSIONING COUNTRY" : "FIELDING COUNTRY", isSelect: co.Selected, coid: co.id, mid: m.Code, cid: c.CountryCode, row: ct.rowNum, col: counter, cellAddress: alphabets[counter] + (ct.rowNum + 1) } };
                else if (ct.id == 4)
                  rows[ct.rowNum].cells = { ...rows[ct.rowNum].cells, coid: co.id, mid: m.Code, mspec: m.id, cid: c.CountryCode, [counter]: { text: userRecord.Email, isSelect: co.Selected, row: ct.rowNum, col: counter, cellAddress: alphabets[counter] + (ct.rowNum + 1) } };
                else if (ct.id == 5) {

                  rows[ct.rowNum] = { ...rows[ct.rowNum], height: 100 }
                  if (m.CostingType == 3) {
                    vCost = 0;
                    rows[ct.rowNum].cells = { ...rows[ct.rowNum].cells, [counter]: { text: "3", isSelect: co.Selected, coid: co.id, mid: m.Code, mspec: m.id, cid: c.CountryCode, row: ct.rowNum, col: counter, cellAddress: alphabets[counter] + ct.rowNum } };
                    //datarow.push({ "label": "Vendor FW (direct outsourcing)", "coid": co.id, "cid": c.CountryCode, "mid": m.id, "x": bcounter, "y": ct.rowNum - 1,"vCost":vCost })

                  }
                  else {
                    rows[ct.rowNum].cells = { ...rows[ct.rowNum].cells, [counter]: { text: "1", row: ct.rowNum, isSelect: co.Selected, col: counter, coid: co.id, mid: m.Code, mspec: m.id, cid: c.CountryCode, cellAddress: alphabets[counter] + ct.rowNum } };
                    //datarow.push({ "label": "Internal FW", "coid": co.id, "cid": c.CountryCode, "mid": m.id, "x": bcounter, "y": ct.rowNum - 1,"vCost":vCost })
                  }


                }
                else if (ct.formula) {

                  if (ct.formula.indexOf("commercialhours") >= 0) {

                    var total = (co.CommercialHours
                      ? _.sum(
                        Object.keys(
                          co.CommercialHours
                        ).map((band) => {
                          return co.CommercialHours[band]
                            .Total;
                        })
                      ) : 0)
                    ct.formula = ct.formula.replace("commercialhours", total);
                  }

                  if (ct.formula.indexOf("CostIntOpsMultiplier") >= 0) {
                    ct.formula = ct.formula.replace("CostIntOpsMultiplier", profile.ProfileSetting.CostIntOpsMultiplier);
                  }
                  if (ct.formula.indexOf("CostIntCommMultiplier") >= 0) {
                    ct.formula = ct.formula.replace("CostIntCommMultiplier", profile.ProfileSetting.CostIntCommMultiplier);
                  }

                  if (ct.formula.indexOf("VCOST") >= 0)
                    ct.formula = ct.formula.replace("VCOST", vCost);
                  if (ct.formula.indexOf("VCOST") >= 0) {
                    ct.formula = ct.formula.replace("VCOST", vCost);
                  }
                  if (ct.formula.indexOf("VCOST") >= 0) {
                    ct.formula = ct.formula.replace("VCOST", vCost);
                  }
                  if (ct.formula.includes("CONCAT") == true) {
                    ct.formula = ct.formula.replaceAll("'", '"')
                  }
                  var formula = ct.formula.split('|');
                  if (formula.length > 1 && ct.Ameformula && ct.Ameformula.indexOf(c.CountryCode) >= 0)
                    formula = formula[1];
                  else
                    formula = formula[0];
                  var dataformula = formula.split('#');
                  var dataString = dataformula[0];
                  dataformula.forEach((df, dfi) => {
                    if (dfi > 0) {
                      if (df.indexOf("YA") >= 0) {
                        if (m.Code == 'SM000024') {// || m.Code == 'SM000029') {
                          dataString += alphabets[counter] + "12" + df.substring(2, df.length);
                        }
                        else {
                          if (m.Code == 'SM000026') {
                            dataString += alphabets[counter] + "19" + df.substring(2, df.length);
                          }
                          else {
                            dataString += alphabets[counter] + "15" + df.substring(2, df.length);
                          }
                        }
                      }

                      else {
                        if ((finalList[mAlli].schema.filter(fl => fl.Key == ("#" + df.substring(0, 2))).length > 0)) {
                          dataString += alphabets[counter] + (finalList[mAlli].schema.filter(fl => fl.Key == ("#" + df.substring(0, 2)))[0].rowNum + 1) + df.substring(2, df.length)
                        }
                        else if (dataString.substring(dataString.length - 1, dataString.length) == ",") {
                          dataString = dataString.substring(0, dataString.length - 1) + df.substring(2, df.length);
                        }
                      }
                    }

                  }
                  )
                  dataString = dataString.substring(dataString.length - 1, dataString.length) == "+" ? dataString.substring(0, dataString.length - 1) : dataString;
                  if (ct.id == 130 && ct.overhead && ct.overhead.reduce(o => { if (o.country.indexOf(c.CountryCode) >= 0) { return { o } } })) {
                    dataString = dataString.replace(".16", ct.overhead.reduce(o => { if (o.country.indexOf(c.CountryCode) >= 0) { return { ...o } } }).value)
                  }
                  rows[ct.rowNum].cells = { ...rows[ct.rowNum].cells, [counter]: { text: dataString.indexOf("DELETE") >= 0 ? 0 : dataString, editable: false, isSelect: co.Selected, coid: co.id, mid: m.Code, mspec: m.id, cid: c.CountryCode, row: ct.rowNum, col: bcounter, style: ct.style ? (parseInt(ct.style) + 10) : 8, cellAddress: alphabets[counter] + (ct.rowNum + 1) } };

                  //datarow.push(dataString.indexOf("DELETE") >= 0 ? { "label": 0, "coid": co.id, "cid": c.CountryCode, "mid": m.id,"formula":ct.newfor && ct.isSummary? ct.newfor:"" } : { "label": dataString, "coid": co.id, "cid": c.CountryCode, "mid": m.id, "x": bcounter, "y": ct.rowNum - 1,"formula":ct.newfor && ct.isSummary? ct.newfor:"" })
                  //sOptions.readOnly.push({ "x": bcounter, "y": ct.rowNum - 1 });
                }
                else {





                  if (profile.CostingDatum) {
                    for (var i = 1; i <= counter; i++) {
                      var a
                      let _tempCostdatum = Object.entries(profile.CostingDatum.CostingData.filter(a => a.Code == m.Code).map(s => Object.entries(s.rows).filter(([fkey, fvalue]) => fvalue.id == ct.id)))
                      if (_.head(_tempCostdatum) && _.head(_tempCostdatum)[1] && _.head(_.head(_tempCostdatum)[1]) ? ((_.head(_.head(_tempCostdatum)[1])[1]?.cells[i]) != undefined) : false) {
                        if ((_.head(_.head(_tempCostdatum)[1])[1]?.cells[i])?.coid == co.id) {
                          a = i;
                        }
                      }
                    }
                  }
                  if (a > 1 || a == 1) {
                    if (Object.entries(profile.CostingDatum.CostingData.filter(a => a.Code == m.Code).map(s => Object.entries(s.rows).filter(([fkey, fvalue]) => fvalue.id == ct.id)))[0][1][0][1].cells[a].mid == m.Code && Object.entries(profile.CostingDatum.CostingData.filter(a => a.Code == m.Code).map(s => Object.entries(s.rows).filter(([fkey, fvalue]) => fvalue.id == ct.id)))[0][1][0][1].cells[a].coid == co.id && Object.entries(profile.CostingDatum.CostingData.filter(a => a.Code == m.Code).map(s => Object.entries(s.rows).filter(([fkey, fvalue]) => fvalue.id == ct.id)))[0][1][0][1].cells[a].mspec == m.id && Object.entries(profile.CostingDatum.CostingData.filter(a => a.Code == m.Code).map(s => Object.entries(s.rows).filter(([fkey, fvalue]) => fvalue.id == ct.id)))[0][1][0][1].cells[a].cid == c.CountryCode)



                      rows[ct.rowNum].cells = { ...rows[ct.rowNum].cells, [counter]: { text: ct.Label == "COST BREAKDOWN - OUT OF POCKET ONLY" ? profile.CountrySpecs.length == 1 ? c.CostInputCurrency.substring(3, c.CostInputCurrency.length) : "USD" : Object.entries(profile.CostingDatum.CostingData.filter(a => a.Code == m.Code).map(s => Object.entries(s.rows).filter(([fkey, fvalue]) => fvalue.id == ct.id)))[0][1][0][1].cells[a].text ? Object.entries(profile.CostingDatum.CostingData.filter(a => a.Code == m.Code).map(s => Object.entries(s.rows).filter(([fkey, fvalue]) => fvalue.id == ct.id)))[0][1][0][1].cells[a].text : ct.id > 250 && ct.id < 300 ? "0" : '', isSelect: co.Selected, row: ct.rowNum, col: counter, coid: co.id, mid: m.Code, mspec: m.id, cid: c.CountryCode, editable: ct.Label == "COST BREAKDOWN - OUT OF POCKET ONLY" || ct.readOnly || ct.hide && ct.hide.indexOf(c.CountryCode) >= 0 || ct.show && !ct.show.includes(c.CountryCode) ? false : true, style: ct.style ? (parseInt(ct.style) + 10) : 8, cellAddress: alphabets[counter] + (ct.rowNum + 1) } };

                  }


                  else
                    rows[ct.rowNum].cells = { ...rows[ct.rowNum].cells, [counter]: { text: ct.Label == "COST BREAKDOWN - OUT OF POCKET ONLY" ? profile.CountrySpecs.length == 1 ? c.CostInputCurrency.substring(3, c.CostInputCurrency.length) : "USD" : ct.id > 250 && ct.id < 300 ? "0" : '', isSelect: co.Selected, row: ct.rowNum, col: counter, coid: co.id, mid: m.Code, mspec: m.id, cid: c.CountryCode, editable: ct.Label == "COST BREAKDOWN - OUT OF POCKET ONLY" || ct.readOnly || ct.hide && ct.hide.indexOf(c.CountryCode) >= 0 || ct.show && !ct.show.includes(c.CountryCode) ? false : true, style: ct.style ? (parseInt(ct.style) + 10) : 8, cellAddress: alphabets[counter] + (ct.rowNum + 1) } };
                }
                if (ct.Label == "Costing Option" || ct.Label == "IF EXTERNAL - PLS SPECIFY VENDOR NAME  [MANDATORY]") {
                  var rownumnew = ct.rowNum + 1;
                  co.CostingOptionSchema.map((cos, cosi) => {
                    if (!datacostingschema.find(f => f.id == cos.id)) {
                      datacostingschema.push({ "id": cos.id, "index": cosi, "ctid": ct.id, "x": 0, "y": rownumnew });
                      rows = { ...rows, [rownumnew]: { cells: { [0]: { text: cos.label, row: rownumnew, col: counter, editable: false, style: 6, cellAddress: alphabets[counter] + (ct.rownumnew + 1) } } } };
                    }
                    //rows[2+rownumnew].cells= {...rows[2+rownumnew].cells,[counter]: {text:typeof co.CostingOptionData[cos.id] == 'object' ? co.CostingOptionData[cos.id].Label : co.CostingOptionData[cos.id]}};       

                    rows[rownumnew].cells = { ...rows[rownumnew].cells, [counter]: { row: rownumnew, col: counter, text: typeof co.CostingOptionData[cos.id] == 'object' ? Array.isArray(co.CostingOptionData[cos.id]) ? co.CostingOptionData[cos.id].map(m => m.label).toString() : typeof co.CostingOptionData[cos.id].label == "string" || co.CostingOptionData[cos.id].label == undefined ? co.CostingOptionData[cos.id].label : co.CostingOptionData[cos.id].label.toString() : typeof co.CostingOptionData[cos.id] == "string" || co.CostingOptionData[cos.id] == undefined ? co.CostingOptionData[cos.id] == undefined ? "" : co.CostingOptionData[cos.id] : co.CostingOptionData[cos.id].toString(), editable: false, style: ct.style ? (parseInt(ct.style) + 10) : 8, cellAddress: alphabets[counter] + (rownumnew + 1) } };


                    //rows={...rows,[rownumnew+2]: {cells:{[0]: {text:typeof co.CostingOptionData[cos.id] == 'object' ? co.CostingOptionData[cos.id].Label : co.CostingOptionData[cos.id]}}}};
                    //datacosting.push({ "label": typeof co.CostingOptionData[cos.id] == 'object' ? co.CostingOptionData[cos.id].Label : co.CostingOptionData[cos.id], "index": cosi, "coi": coi, "ci": ci, "id": ct.id, "x": 0, "y": ct.rowNum - 1 });
                    //sOptions.readOnly.push({ "x": bcounter, "y": rownumnew });
                    rownumnew++;
                  })
                } counter++;
              }
            }

            )
          }
          )
        }
        )
      })
      sOptions = { ...sOptions, rows: rows, merges: merges, reference: ref };

      datadetails.push(sOptions);
    })

    setSSheetOptionData(datadetails);
    if (profile.CostingDatum) {
      setFinalProcessedData(profile.CostingDatum.CostingData);
    }
    setInterval(true)
  }

  function Sumup(tempnew, ttxt, vi) {

    var txt = ttxt.split(/[+,\-/,\*/,\-/,\(/,\)/]/);
    var fm = ttxt;
    txt.forEach(tx => {
      if (tx != "") {
        Object.keys(tempnew[vi].rows).map(tr => {
          Object.keys(tempnew[vi].rows[tr].cells).map(tc => {
            if (tempnew[vi].rows[tr].cells[tc].cellAddress == tx.replace("=", "")) {
              // fm = fm.replace(tx, tempnew[vi].rows[tr].cells[tc].value ? ((typeof parseInt(tempnew[vi].rows[tr].cells[tc].value)=="number")?parseInt(tempnew[vi].rows[tr].cells[tc].value):0).toString() : 0);
              fm = fm.replace(tx, tempnew[vi].rows[tr].cells[tc].value ? tempnew[vi].rows[tr].cells[tc].value : 0).toString();
            }
          })
        })

      }
    })
    return fm;
  }
  function saveInputDraft() {
    var temp = _.cloneDeep(sSheetOptionData);
    var tempnew = _.cloneDeep(sSheetOptionData);
    temp.map((s, si) => {
      Object.keys(s.rows).map(k => {
        Object.keys(s.rows[k].cells).map(c => {
          tempnew[si].rows[k].cells[c] = { ...temp[si].rows[k].cells[c], value: typeof temp[si].rows[k].cells[c].text == "" ? "0" : typeof temp[si].rows[k].cells[c].text == "number" ? temp[si].rows[k].cells[c].text.toString() : temp[si].rows[k].cells[c].text };
        })
      })
    }
    )
    temp.map((s, si) => {
      Object.keys(s.rows).map(k => {
        Object.keys(s.rows[k].cells).map(c => {



          if (tempnew[si].rows[k].cells[c].text && tempnew[si].rows[k].cells[c].text.indexOf("=") >= 0) {
            var fm = tempnew[si].rows[k].cells[c].text;

            do {
              fm = Sumup(tempnew, fm.replace("=", ""), si);
            } while (fm.indexOf("=") >= 0)

            var fvalue
            if (fm.includes("CONCAT") == true) {
              fm = fm.replace("CONCAT(", "")
              fm = fm.replace(',"%")', "")
              fvalue = isNaN(eval(fm)) ? 0 : eval(fm);
              fvalue = fvalue + "%"
            }
            fvalue = isNaN(eval(fm)) ? 0 : eval(fm);
            tempnew[si].rows[k].cells[c] = { ...temp[si].rows[k].cells[c], value: fvalue };
          }



        })
      })
    }
    )
    saveInputData(tempnew);

    //dispatch(currentCostingActions.saveCostingProfileDraft(profile));
  }

  function saveInputData(tempnew) {
    //profile["CostingDatum"] = { "id": profile.CostingDatum ? profile.CostingDatum.id : null, "CostingData": JSON.stringify(tempnew), "CostingProfileId": profile.id };

    const cloneprofile = _.cloneDeep(profile);
    cloneprofile["CostingDatum"] = { "id": profile.CostingDatum ? profile.CostingDatum.id : null, "CostingData": JSON.stringify(tempnew), "CostingProfileId": profile.id };
    dispatch(recordLoadStart())
    axios
      .put("/costingprofiles/updateCostingInput/" + cloneprofile.id, cloneprofile, {
        headers: { "auth-token": localStorage.getItem("auth-token") },
      })
      .then((res) => {

        dispatch(
          currentCostingActions.getCosting(cloneprofile.id)
        );
        // dispatch(
        //   setCostingStatus(
        //     {
        //       ...costingStatus,
        //       showCostingInput: true,
        //     },
        //   )
        // )
        toastr.success("Costing Profile Saved", res.data.message);
        dispatch(appActions.pageLoadEnd())
        dispatch(appActions.recordLoadEnd());

      })
      .catch((err) => {
        toastr.error("Save Failed", err?.data?.error);
        dispatch(appActions.pageLoadEnd())
        dispatch(appActions.recordLoadEnd());
      });

  }
  function saveCostingOptions() {

    setCostSelection(true);
    if (finalProcessedData.length > 0)
      setActiveSM(finalProcessedData[0].Code);
  }
  function toggleSD(tab, mCode) {
    if (activeTabSD !== tab) {
      setActiveTabSD(tab);
    }
    setActiveSM(mCode);
  };
  function selectCostingOption(ci, mi, coi, e, coid, cid, mid) {
    const profileTemp = update(profile, {
      CountrySpecs: {
        [ci]: {
          MethodologySpecs:
          {
            [mi]:
            {
              CostingOptions:
              {
                [coi]: {
                  $merge: { "isSelected": e.target.checked ? true : false }
                }
              }
            }
          }
        }
      }
    });

    const tempProfiles = [profileTemp];
    updateStatus(cid, mid, coid, e)
    dispatch(setCurrentProject({ CostingProfiles: tempProfiles }));
  }
  function updateStatus(ci, mi, coi, e) {
    var temp = _.cloneDeep(finalProcessedData);
    temp.forEach((cm, cmi) => {
      Object.entries(cm.rows).forEach(r =>
        Object.entries(r[1].cells).forEach(c => {
          if (c[1].coid == coi && c[1].cid == ci && c[1].mid == mi)
            temp[cmi].rows[r[0]].cells[c[0]].isSelect = e.target.checked;
        }
        )
      )
    }
    )
    setSSheetOptionData(temp);
    setFinalProcessedData(temp);
  }
  function updateNewUserData(fpi, fe, fd, e) {
    var temp = _.cloneDeep(finalProcessedData);
    temp.filter(fp => fp.Code == fpi)[0].rows[fd].cells[fe].value = e.target.value;
    temp.filter(fp => fp.Code == fpi)[0].rows[fd].cells[fe].text = e.target.value;
    setSSheetOptionData(temp);
    setFinalProcessedData(temp);
  }

  return (
    <>
      {(profile?.Project && profile?.CountrySpecs?.length) ? <Layout
        costMethod={profile.CostingType}
        profileStatusToDisplay={getLabel(
          "CostingStatusOptions",
          profile.ProfileStatus
        )}
        projectStatusToDisplay={getLabel(
          "ProjectStatusOptions",
          profile.Project?.ProjectStatus
        )}
        avoidEdit={true}
      >
        <Row className="justify-content-between m-3"><h3 className="mt-3 ml-1 mb-0">{profile.ProfileName} </h3>
          <Button color="primary" className="btn-sm view-summary-btn" onClick={() => 
            {
              dispatch(
                currentCostingActions.getSummaryCosting(profile, () =>
                  history.push(`/summary/${profile.id}`),
                  profile?.IsOPSOOPAdjusted ? false : true
                )
              );
            }
           }>
            View Cost Summary
          </Button>
        </Row>
        <Modal

          isOpen={isCostSelection}
          toggle={() => setCostSelection(!isCostSelection)}
          centered={true}
          size="lg"

          backdrop={"static"}
        >

          <ModalHeader toggle={() => setCostSelection(!isCostSelection)}>
            <h4>Select Costing Options</h4>
          </ModalHeader>
          <ModalBody>
            <h5>
              Kindly select the appropriate costing input
            </h5>

            <div className={"tab"}>
              <Nav tabs>
                {finalProcessedData &&
                  finalProcessedData.map((sd, indxSD) => (
                    <NavItem key={sd.Code}>
                      <NavLink className={classnames({ active: activeTabSD === indxSD })}
                        onClick={() => {
                          toggleSD(indxSD, sd.Code);
                        }}
                      >
                        <b>{sd.name}</b>
                      </NavLink>
                    </NavItem>
                  ))}
              </Nav>

              <TabContent activeTab={activeTabSD}>
                {finalProcessedData &&
                  finalProcessedData.map((sd, indxSD) => (
                    activeTabSD === indxSD &&
                    <TabPane tabId={indxSD} key={sd.value}>


                      <Row>
                        <Table responsive hover striped bordered={true} size="sm">
                          <thead>

                            <tr style={{ fontSize: 6 }} >
                              <th rowSpan={2}>
                                Summary Parameters
                              </th>
                              {profile.CountrySpecs.map((c, ci) => (
                                c.MethodologySpecs.map((m, mi) => {
                                  if (m.Code == activeSM) {
                                    return (
                                      m.CostingOptions.map((co, coi) => {
                                        return coi == 0 &&
                                          <th colSpan={m.CostingOptions.length}>
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", verticalAlign: "middle" }}> {c.CountryName} </div>
                                          </th>
                                      }
                                      )
                                    )
                                  }
                                })))}
                            </tr>

                            <tr style={{ fontSize: 6 }}>

                              {profile.CountrySpecs.map((c, ci) => (
                                c.MethodologySpecs.map((m, mi) => {
                                  if (m.Code == activeSM) {
                                    return (
                                      m.CostingOptions.map((co, coi) => {
                                        return (
                                          <th style={{ backgroundColor: co.isSelected ? '#2D6DF6' : '', color: co.isSelected ? 'black' : '' }} >
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: ".3rem" }}>
                                              {!co.rename &&
                                                <>
                                                  <span>
                                                    {co.label}
                                                  </span>
                                                  <div>
                                                    <input type="checkbox" checked={co.isSelected} title="Select Option" onChange={(e) => selectCostingOption(ci, mi, coi, e, co.id, c.CountryCode, m.Code)} />
                                                  </div>
                                                </>
                                              }

                                            </div>
                                          </th>)
                                      }
                                      )
                                    )
                                  }
                                })))}
                            </tr>
                            {finalProcessedData.filter(fp => fp.Code == activeSM).length > 0 &&
                              finalProcessedData.filter((fp, fpi) => fp.Code == activeSM).map(s => Object.entries(s.rows).filter(([fkey, fvalue]) => fvalue.id > 122 && fvalue.id < 135).map((fd, indxSD) => {
                                return (
                                  <tr>
                                    {Object.keys(fd[1].cells).map(fe => {
                                      return (<td>
                                        {

                                          isNaN(parseInt(fd[1].cells[fe].value)) ? fd[1].cells[fe].value : (parseInt(fd[1].cells[fe].value)).toFixed(2)}

                                      </td>)
                                    })}
                                  </tr>
                                )
                              }
                              ))
                            }
                          </thead>

                        </Table>                    </Row>
                    </TabPane>
                  ))}
              </TabContent>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button disabled={recordloading} color="primary" onClick={() => {
              saveInputData(finalProcessedData);
              saveDraft();
            }}>
              Submit Selection
              {recordloading && <FontAwesomeIcon class="saving-btn" icon={faSpinner} fixedWidth />}
            </Button>
          </ModalFooter>
        </Modal>

     
        <Row>
          <Col md={2}>
            <Button disabled={recordloading} color="primary" className="savedraft shopper" onClick={() => {
              saveInputDraft();
            }}>
              Save as Draft
              {recordloading && <FontAwesomeIcon class="saving-btn" icon={faSpinner} fixedWidth />}
            </Button>
          </Col>

      
          {/* {profile.CostingDatum && <Col md={2}>
          <Button disabled={recordloading} color="primary" onClick={() => {
            saveCostingOptions();
          }}>
            Select Costing Options
            {recordloading && <FontAwesomeIcon class="saving-btn" icon={faSpinner} fixedWidth />}
          </Button>
        </Col>} */}
          <Col md={2}>
            {/* {profile.CostingDatum && < Button onClick={() => {
            // dispatch(
            //   currentCostingActions.getCosting(
            //     currentCostingProfile.id,
            //     () => {

            dispatch(
              currentCostingActions.saveCostingProfile(
                currentCostingProfile,
                () => {
                  history.push(
                    `/summary/${currentCostingProfile.id}`
                  );

                }
              )
            );
            // dispatch(currentCostingActions.generateSummary());
            //     }
            //   )
            // );
          }} style={{ marginLeft: "5px" }} color="primary">
            View Cost Summary
          </Button>} */}
          </Col>
        </Row>
        <Button style={{"margin":"10px"}}     onClick={() => {
                   
                   dispatch(
                     currentProjectActions.getProject(
                       project.ProjectId
                         ? project.ProjectId
                         : profile.Project.ProjectId,
                       () => {
                         
                         dispatch(currentCostingActions.getCosting(profile.id, () => history.push("/costing")))
                       }, true
                     )
                   );
                 }}

         >
          Back to RFQ
                  </Button>
        <html>
          <link rel="stylesheet" href="https://unpkg.com/x-data-spreadsheet@1.1.5/dist/xspreadsheet.css"></link>
          {sSheetOptionData && <div key="id1" id="x-spreadsheet-demo"></div>}

        </html>
      
             
       
        {interval &&

          <DataSheet finalProcessedData={finalProcessedData} setFinalProcessedData={setFinalProcessedData} spreadSheetOption={sSheetOptionData} setSSheetOptionData={setSSheetOptionData} finalData={finalProcessedData} />
        }
     
      
       
    
        <br />
       
      </Layout> : null}
    </>
  );
};
export default NewSpreadsheet;
