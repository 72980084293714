// Sidebar
export const FETCH_ALL_MASTERS_REQUEST = "FETCH_ALL_MASTERS_REQUEST";
export const FETCH_ALL_MASTERS_REQUEST_SUCCESS = "FETCH_ALL_MASTERS_REQUEST_SUCCESS";
export const FETCH_ALL_MASTERS_FAILURE = "FETCH_ALL_MASTERS_FAILURE"; 

export const FETCH_CC_MASTERS_REQUEST = "FETCH_CC_MASTERS_REQUEST";
export const FETCH_CC_MASTERS_REQUEST_SUCCESS = "FETCH_CC_MASTERS_REQUEST_SUCCESS";
export const FETCH_CC_MASTERS_FAILURE = "FETCH_CC_MASTERS_FAILURE"; 

export const FETCH_SINGLE_MASTER_REQUEST = "FETCH_SINGLE_MASTER_REQUEST"; 
export const FETCH_SINGLE_MASTER_SUCCESS = "FETCH_SINGLE_MASTER_SUCCESS"; 
export const FETCH_SINGLE_MASTER_FAILURE = "FETCH_SINGLE_MASTER_FAILURE"; 



