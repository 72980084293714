import React from "react";
import filterFactory, {
    textFilter,
} from "react-bootstrap-table2-filter";
import _ from "lodash";
import moment from "moment";

export const financeReportColumns = [
    "ID",
    "Project ID",
    "Project Name",
    "Wave Number",
    "Wave Name",
    "WBS Number",
    "Wave Methodology",
    "Wave Country",
    "Opportunity Number",
    "Project Manager Email",
    "Commissioning Country",
    "Business Unit",
    "Industry Vertical",
    "Fielding Countries",
    "Industry",
    "Practice Area",
    "Product Description",
    "Microsoft Dynamics Probability",
    "Research Type",
    "Methodology",
    "Multi-Country",
    "Syndicated",
    "Project Status",
    "Commissioned Date",
    "Tracker",
    "Total Waves",
    "Price To Client (LCY)",
    "Currency",
    "Price To Client (USD)",
    "Total External Commercial Cost (LCY)",
    "Total External Commercial Cost (USD)",
    "Total External Operations Cost (LCY)",
    "Total External Operations Cost (USD)",
    "OOP%",
    "Total External Operations Cost(Include Local Margin) (LCY)",
    "Total External Operations Cost(Include Local Margin) (USD)",
    "OOP%(Include Local Margin)",
    "CM%",
    "Field End Actual",
    "Field End",
    "Field Start Actual",
    "Field Start",
    "Reports Due",
    "Reports Due Actual",
    "Schedule Notes",
    "Status Notes",
]

const formattedDate = (date, flag) => {
    if (flag === false) return "not required";
    if (date) return date.substring(0, 10);
    else return "not available";
};
const formattedPrec = (data) => {
    if (data) return (data * 100).toFixed(2) + "%";
    else return "not available";
};

export const financeReportFinalColumns = [
    {
        dataField: "id",
        text: "ID",
        sort: true,
        formatter: (cell, row) => {
            return <span>{row.WaveSpecId}</span>;
        },
        csvFormatter: (cell, row) => {
            return row.WaveSpecId || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "ProjectNumber",
        text: "Project ID",
        sort: true,
        formatter: (cell, row) => {
            return <span>{row.ProjectNumber}</span>;
        },
        csvFormatter: (cell, row) => {
            return row.ProjectNumber || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "ProjectName",
        text: "Project Name",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {row.ProjectName}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.ProjectName || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "WaveNumber",
        text: "Wave Number",
        sort: true,
        formatter: (cell, row) => {
            return <span>{row.WaveNumber}</span>;
        },
        csvFormatter: (cell, row) => {
            return row.WaveNumber || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "WaveName",
        text: "Wave Name",
        sort: true,
        formatter: (cell, row) => {
            return <span>{row.WaveName}</span>;
        },
        csvFormatter: (cell, row) => {
            return row.WaveName || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "WBSNumber",
        text: "WBS Number",
        sort: true,
        filter: textFilter(),
        formatter: (cell, row) => {
            return (
                <span>
                    {row.WBSNumber && row.WBSNumber.split(',').join(', ') || ""}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.WBSNumber && row.WBSNumber.split(',').join(' | ') || "";
        },
    },
    {
        dataField: "WaveMethodology",
        text: "Wave Methodology",
        sort: true,
        formatter: (cell, row) => {
            return <span>{row.WaveMethodology}</span>;
        },
        csvFormatter: (cell, row) => {
            return row.WaveMethodology || "";
        }
    },
    {
        dataField: "WaveCountryName",
        text: "Wave Country",
        sort: true,
        formatter: (cell, row) => {
            return <span>{row.WaveCountryName}</span>;
        },
        csvFormatter: (cell, row) => {
            return row.WaveCountryName || "";
        }
    },
    {
        dataField: "OpportunityNumber",
        text: "Opportunity Number",
        sort: true,

        filter: textFilter(),
        formatter: (cell, row) => {
            return (
                <span>{row.OpportunityNumber ? row.OpportunityNumber.split(',').join(', ') : ""}</span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.OpportunityNumber && row.OpportunityNumber.split(',').join(' | ') || "";
        },
    },
    {
        dataField: "ProjectManagerEmail",
        text: "Project Manager Email",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {row.ProjectManagerEmail}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.ProjectManagerEmail || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "CommissioningCountry",
        text: "Commissioning Country",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {row.CommissioningCountry}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.CommissioningCountry || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "BusinessUnit",
        text: "Business Unit",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {row.BusinessUnit}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.BusinessUnit || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "IndustryVertical",
        text: "Industry Vertical",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {row.IndustryVertical}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.IndustryVertical || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "FieldingCountries",
        text: "Fielding Countries",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {row.FieldingCountries && row.FieldingCountries.split(',').join(', ') || ""}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.FieldingCountries && row.FieldingCountries.split(',').join('|') || ""
        },
        filter: textFilter(),
    },
    {
        dataField: "Industry",
        text: "Industry",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {row.Industry}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.Industry || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "PracticeArea",
        text: "Practice Area",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {row.PracticeArea}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.PracticeArea || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "ProductDescription",
        text: "Product Description",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {row.ProductDescription}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.ProductDescription || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "Probability",
        text: "Microsoft Dynamics Probability",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {row.Probability}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.Probability || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "ResearchType",
        text: "Research Type",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {row.ResearchType}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.ResearchType || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "Methodology",
        text: "Methodology",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {row.Methodology && row.Methodology.split(',').join(', ') || ""}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.Methodology && row.Methodology.split(',').join('|') || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "IsMultiCountry",
        text: "Multi-Country",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {row.IsMultiCountry}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.IsMultiCountry || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "IsSyndicated",
        text: "Syndicated",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {row.IsSyndicated}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.IsSyndicated || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "ProjectStatus",
        text: "Project Status",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {row.ProjectStatus}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.ProjectStatus || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "CommissionedDate",
        text: "Commissioned Date",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {formattedDate(row.CommissionedDate)}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return formattedDate(row.CommissionedDate) || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "NotesProjectStatus",
        text: "Status Notes",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {row.NotesProjectStatus}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.NotesProjectStatus || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "IsTracker",
        text: "Tracker",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {row.IsTracker}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.IsTracker || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "NumberOfWaves",
        text: "Total Waves",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {row.NumberOfWaves}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.NumberOfWaves || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "PriceToClient",
        text: "Price To Client (USD)",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {`${_.round(row.PriceToClient, 2)}`}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.PriceToClient && `${_.round(row.PriceToClient, 2)}` || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "CostInputCurrency",
        text: "Currency",
        sort: true,
        formatter: (cell, row) => {
            if (row.CostInputCurrency) {
                let values = row.CostInputCurrency.split("-");
                let currencyUnit = _.last(values);
                return currencyUnit ? currencyUnit : "USD"
            }
            return ""
        },
        csvFormatter: (cell, row) => {
            if (row.CostInputCurrency) {
                let values = row.CostInputCurrency.split("-");
                let currencyUnit = _.last(values);
                return currencyUnit ? currencyUnit : "USD"
            }
            return ""
        },
        filter: textFilter(),
    },
    {
        dataField: "PriceToClientLcy",
        text: "Price To Client (LCY)",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {`${_.round(row.PriceToClientLcy, 2)}`}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.PriceToClientLcy && `${_.round(row.PriceToClientLcy, 2)}` || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "CostTotalExternalCommercial",
        text: "Total External Commercial Cost (USD)",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {`${_.round(row.CostTotalExternalCommercial, 2)}`}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.CostTotalExternalCommercial && `${_.round(row.CostTotalExternalCommercial, 2)}` || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "CostTotalExternalCommercialLcy",
        text: "Total External Commercial Cost (LCY)",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {`${_.round(row.CostTotalExternalCommercialLcy, 2)}`}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.CostTotalExternalCommercialLcy && `${_.round(row.CostTotalExternalCommercialLcy, 2)}` || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "CostTotalExternalOperations",
        text: "Total External Operations Cost (USD)",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {`${_.round(row.CostTotalExternalOperations, 2)}`}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.CostTotalExternalOperations && `${_.round(row.CostTotalExternalOperations, 2)}` || "";
        },
        filter: textFilter(),
    },

    {
        dataField: "CostTotalExternalOperationsLcy",
        text: "Total External Operations Cost (LCY)",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {`${_.round(row.CostTotalExternalOperationsLcy, 2)}`}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.CostTotalExternalOperationsLcy && `${_.round(row.CostTotalExternalOperationsLcy, 2)}` || "";
        },
        filter: textFilter(),
    },

    {
        dataField: "OutOfPocketCostPercent",
        text: "OOP%",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {row.OutOfPocketCostPercent ? formattedPrec(row.OutOfPocketCostPercent) : ''}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.OutOfPocketCostPercent && formattedPrec(row.OutOfPocketCostPercent) || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "CostTotalExternalOperationsIncMCPMargin",
        text: "Total External Operations Cost(Include Local Margin) (USD)",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {`${_.round(row.CostTotalExternalOperationsIncMCPMargin, 2)}`}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.CostTotalExternalOperationsIncMCPMargin && `${_.round(row.CostTotalExternalOperationsIncMCPMargin, 2)}` || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "CostTotalExternalOperationsIncMCPMarginLcy",
        text: "Total External Operations Cost(Include Local Margin) (LCY)",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {`${_.round(row.CostTotalExternalOperationsIncMCPMarginLcy, 2)}`}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.CostTotalExternalOperationsIncMCPMarginLcy && `${_.round(row.CostTotalExternalOperationsIncMCPMarginLcy, 2)}` || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "OutOfPocketCostIncMCPPercent",
        text: "OOP%(Include Local Margin)",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {row.OutOfPocketCostIncMCPPercent ? formattedPrec(row.OutOfPocketCostIncMCPPercent) : ''}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.OutOfPocketCostIncMCPPercent && formattedPrec(row.OutOfPocketCostIncMCPPercent) || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "ContributionMarginPercent",
        text: "CM%",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {row.ContributionMarginPercent ? formattedPrec(row.ContributionMarginPercent) : ''}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.ContributionMarginPercent && formattedPrec(row.ContributionMarginPercent) || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "DateFieldStart",
        text: "Field Start",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {formattedDate(row.DateFieldStart, row.DateFieldworkNA)}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return formattedDate(row.DateFieldStart, row.DateFieldworkNA) || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "DateFieldStartActual",
        text: "Field Start Actual",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {formattedDate(row.DateFieldStartActual)}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return formattedDate(row.DateFieldStartActual) || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "DateFieldEnd",
        text: "Field End",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {formattedDate(row.DateFieldEnd, row.DateFieldworkNA)}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return formattedDate(row.DateFieldEnd, row.DateFieldworkNA) || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "DateFieldEndActual",
        text: "Field End Actual",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {formattedDate(row.DateFieldEndActual)}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return formattedDate(row.DateFieldEndActual) || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "DateFinalReport",
        text: "Reports Due",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {formattedDate(row.DateFinalReport, row.DateFinalReportNA)}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return formattedDate(row.DateFinalReport, row.DateFinalReportNA) || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "DateFinalReportActual",
        text: "Reports Due Actual",
        sort: true,
        formatter: (cell, row) => {
            return (
                <span>
                    {formattedDate(row.DateFinalReportActual)}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return formattedDate(row.DateFinalReportActual);
        },
        filter: textFilter(),
    },
    {
        dataField: "NotesFinance",
        text: "Schedule Notes",
        sort: true,

        filter: textFilter(),
        formatter: (cell, row) => {
            return <span>{row.NotesFinance}</span>;
        },
        csvFormatter: (cell, row) => {
            return row.NotesFinance || "";
        }
    },
];

export const financeReportCsvHeaders = financeReportFinalColumns.map(col => ({ label: col.text, key: col.dataField }));

export const financeLogsReportColumns = ["Date Of Change",
    "Project ID",
    "Project Name",
    "Profile ID",
    "Wave Number",
    "Wave Name",
    "WBS Number",
    "Wave Methodology",
    "Wave Country",
    "Opportunity Number",
    "Commissioning Country",
    "User ID",
    "Total Project Value (USD)",
    "Total Project Value (USD) Updated",
    "Total Third Party Cost (USD)",
    "Total Third Party Cost (USD) Updated",
    "OOP%",
    "OOP% Updated",
    "Field Start Planned",
    "Field Start Updated",
    "Field Start Actual Planned",
    "Field Start Actual Updated",
    "Field End Planned",
    "Field End Updated",
    "Field End Actual Planned",
    "Field End Actual Updated",
    "Final Report Planned",
    "Final Report Updated",
    "Final Report Actual Planned",
    "Final Report Actual Updated"]

export const financeLogsReportFinalColumns = [
    {
        dataField: "DateOfChange",
        text: "Date Of Change",
        sort: true,
        formatter: (cell, row) => <span>{row.DateOfChange ? moment(row.DateOfChange).format("YYYY-MM-DD") : "-"}</span>,
        csvFormatter: (cell, row) => row.DateOfChange ? moment(row.DateOfChange).format("YYYY-MM-DD") : "",
        filter: textFilter(),
    },
    {
        dataField: "ProjectNumber",
        text: "Project ID",
        sort: true,
        filter: textFilter(),
        formatter: (cell, row) => {
            return <span>{row.ProjectNumber}</span>;
        },
        csvFormatter: (cell, row) => {
            return row.ProjectNumber || "";
        }
    },
    {
        dataField: "ProjectName",
        text: "Project Name",
        sort: true,
        filter: textFilter(),
        formatter: (cell, row) => {
            return <span>{row.ProjectName}</span>;
        },
        csvFormatter: (cell, row) => {
            return row.ProjectName || "";
        }
    },
    {
        dataField: "ProfileId",
        text: "Profile ID",
        sort: true,
        filter: textFilter(),
        formatter: (cell, row) => {
            return <span>{row.ProfileId}</span>;
        },
        csvFormatter: (cell, row) => {
            return row.ProfileId || "";
        }
    },
    {
        dataField: "WaveNumber",
        text: "Wave Number",
        sort: true,
        filter: textFilter(),
        formatter: (cell, row) => {
            return <span>{row.WaveNumber}</span>;
        },
        csvFormatter: (cell, row) => {
            return row.WaveNumber || "";
        }
    },
    {
        dataField: "WaveName",
        text: "Wave Name",
        sort: true,
        filter: textFilter(),
        formatter: (cell, row) => {
            return <span>{row.WaveName}</span>;
        },
        csvFormatter: (cell, row) => {
            return row.WaveName || "";
        }
    },
    {
        dataField: "WBSNumber",
        text: "WBS Number",
        sort: true,
        filter: textFilter(),
        formatter: (cell, row) => {
            return <span>{row.WBSNumber}</span>;
        },
        csvFormatter: (cell, row) => {
            return row.WBSNumber || "";
        }
    },
    {
        dataField: "WaveMethodologySpec",
        text: "Wave Methodology",
        sort: true,
        formatter: (cell, row) => {
            return <span>{row.WaveMethodologySpec}</span>;
        },
        csvFormatter: (cell, row) => {
            return row.WaveMethodologySpec || "";
        }
    },
    {
        dataField: "WaveCountryName",
        text: "Wave Country",
        sort: true,
        formatter: (cell, row) => {
            return <span>{row.WaveCountryName}</span>;
        },
        csvFormatter: (cell, row) => {
            return row.WaveCountryName || "";
        }
    },
    {
        dataField: "OpportunityNumber",
        text: "Opportunity Number",
        sort: true,
        filter: textFilter(),
        formatter: (cell, row) => {
            return <span>{row.OpportunityNumber}</span>;
        },
        csvFormatter: (cell, row) => {
            return row.OpportunityNumber || "";
        }
    },
    {
        dataField: "CommissioningCountry",
        text: "Commissioning Country",
        sort: true,
        formatter: (cell, row) => {
            return <span>{row.CommissioningCountry}</span>;
        },
        csvFormatter: (cell, row) => {
            return row.CommissioningCountry || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "UserId",
        text: "User ID",
        sort: true,
        formatter: (cell, row) => {
            return <span>{row.UserId}</span>;
        },
        csvFormatter: (cell, row) => {
            return row.UserId || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "TotalProjectValueUSD",
        text: "Total Project Value (USD)",
        sort: true,
        formatter: (cell, row) => <span>{row.TotalProjectValueUSD ? row.TotalProjectValueUSD.toFixed(2) : "-"}</span>,
        csvFormatter: (cell, row) => { return row.TotalProjectValueUSD ? row.TotalProjectValueUSD.toFixed(2) : "" },
        filter: textFilter(),
    },
    {
        dataField: "TotalProjectValueUSDUpdated",
        text: "Total Project Value (USD) Updated",
        sort: true,
        formatter: (cell, row) => <span>{row.TotalProjectValueUSDUpdated ? row.TotalProjectValueUSDUpdated.toFixed(2) : "-"}</span>,
        csvFormatter: (cell, row) => { return row.TotalProjectValueUSDUpdated ? row.TotalProjectValueUSDUpdated.toFixed(2) : "" },
        filter: textFilter(),
    },

    {
        dataField: "TotalThirdPartyCostUSD",
        text: "Total Third Party Cost (USD)",
        sort: true,
        formatter: (cell, row) => <span>{row.TotalThirdPartyCostUSD ? row.TotalThirdPartyCostUSD.toFixed(2) : "-"}</span>,
        csvFormatter: (cell, row) => {
            return row.TotalThirdPartyCostUSD ? row.TotalThirdPartyCostUSD.toFixed(2) : ""
        },
        filter: textFilter(),
    },
    {
        dataField: "TotalThirdPartyCostUSDUpdated",
        text: "Total Third Party Cost (USD) Updated",
        sort: true,
        formatter: (cell, row) => <span>{row.TotalThirdPartyCostUSDUpdated ? row.TotalThirdPartyCostUSDUpdated.toFixed(2) : "-"}</span>,
        csvFormatter: (cell, row) => {
            return row.TotalThirdPartyCostUSDUpdated ? row.TotalThirdPartyCostUSDUpdated.toFixed(2) : ""
        },
        filter: textFilter(),
    },
    {
        dataField: "OutOfPocketCostPercent",
        text: "OOP%",
        sort: true,
        formatter: (cell, row) => <span>{row.OutOfPocketCostPercent
            ? formattedPrec(row.OutOfPocketCostPercent)
            : "-"}</span>,
        csvFormatter: (cell, row) => {
            return row.OutOfPocketCostPercent
                ? formattedPrec(row.OutOfPocketCostPercent)
                : ""
        },
        filter: textFilter(),
    },
    {
        dataField: "OutOfPocketCostPercentUpdated",
        text: "OOP% Updated",
        sort: true,
        formatter: (cell, row) => <span>{row.OutOfPocketCostPercentUpdated
            ? formattedPrec(row.OutOfPocketCostPercentUpdated)
            : "-"}</span>,
        csvFormatter: (cell, row) => {
            return row.OutOfPocketCostPercentUpdated
                ? formattedPrec(row.OutOfPocketCostPercentUpdated)
                : ""
        },
        filter: textFilter(),
    },
    {
        dataField: "DateFieldStartPlanned",
        text: "Field Start Planned",
        sort: true,
        formatter: (cell, row) => <span>{row.DateFieldStartPlanned ? moment(row.DateFieldStartPlanned).format("YYYY-MM-DD") : "-"}</span>,
        csvFormatter: (cell, row) => row.DateFieldStartPlanned ? moment(row.DateFieldStartPlanned).format("YYYY-MM-DD") : "",
        filter: textFilter(),
    },
    {
        dataField: "DateFieldStartUpdated",
        text: "Field Start Updated",
        sort: true,
        formatter: (cell, row) => <span>{row.DateFieldStartUpdated ? moment(row.DateFieldStartUpdated).format("YYYY-MM-DD") : "-"}</span>,
        csvFormatter: (cell, row) => row.DateFieldStartUpdated ? moment(row.DateFieldStartUpdated).format("YYYY-MM-DD") : "",
        filter: textFilter(),
    },
    {
        dataField: "DateFieldStartActualPlanned",
        text: "Field Start Actual Planned",
        sort: true,
        formatter: (cell, row) => <span>{row.DateFieldStartActualPlanned ? moment(row.DateFieldStartActualPlanned).format("YYYY-MM-DD") : "-"}</span>,
        csvFormatter: (cell, row) => row.DateFieldStartActualPlanned ? moment(row.DateFieldStartActualPlanned).format("YYYY-MM-DD") : "",
        filter: textFilter(),
    },
    {
        dataField: "DateFieldStartActualUpdated",
        text: "Field Start Actual Updated",
        sort: true,
        formatter: (cell, row) => <span>{row.DateFieldStartActualUpdated ? moment(row.DateFieldStartActualUpdated).format("YYYY-MM-DD") : "-"}</span>,
        csvFormatter: (cell, row) => row.DateFieldStartActualUpdated ? moment(row.DateFieldStartActualUpdated).format("YYYY-MM-DD") : "",
        filter: textFilter(),
    },
    {
        dataField: "DateFieldEndPlanned",
        text: "Field End Planned",
        sort: true,
        formatter: (cell, row) => <span>{row.DateFieldEndPlanned ? moment(row.DateFieldEndPlanned).format("YYYY-MM-DD") : "-"}</span>,
        csvFormatter: (cell, row) => row.DateFieldEndPlanned ? moment(row.DateFieldEndPlanned).format("YYYY-MM-DD") : "",
        filter: textFilter(),
    },
    {
        dataField: "DateFieldEndUpdated",
        text: "Field End Updated",
        sort: true,
        formatter: (cell, row) => <span>{row.DateFieldEndUpdated ? moment(row.DateFieldEndUpdated).format("YYYY-MM-DD") : "-"}</span>,
        csvFormatter: (cell, row) => row.DateFieldEndUpdated ? moment(row.DateFieldEndUpdated).format("YYYY-MM-DD") : "",
        filter: textFilter(),
    },
    {
        dataField: "DateFieldEndActualPlanned",
        text: "Field End Actual Planned",
        sort: true,
        formatter: (cell, row) => <span>{row.DateFieldEndActualPlanned ? moment(row.DateFieldEndActualPlanned).format("YYYY-MM-DD") : "-"}</span>,
        csvFormatter: (cell, row) => row.DateFieldEndActualPlanned ? moment(row.DateFieldEndActualPlanned).format("YYYY-MM-DD") : "",
        filter: textFilter(),
    },
    {
        dataField: "DateFieldEndActualUpdated",
        text: "Field End Actual Updated",
        sort: true,
        formatter: (cell, row) => <span>{row.DateFieldEndActualUpdated ? moment(row.DateFieldEndActualUpdated).format("YYYY-MM-DD") : "-"}</span>,
        csvFormatter: (cell, row) => row.DateFieldEndActualUpdated ? moment(row.DateFieldEndActualUpdated).format("YYYY-MM-DD") : "",
        filter: textFilter(),
    },
    {
        dataField: "DateFinalReportPlanned",
        text: "Final Report Planned",
        sort: true,
        formatter: (cell, row) => <span>{row.DateFinalReportPlanned ? moment(row.DateFinalReportPlanned).format("YYYY-MM-DD") : "-"}</span>,
        csvFormatter: (cell, row) => row.DateFinalReportPlanned ? moment(row.DateFinalReportPlanned).format("YYYY-MM-DD") : "",
        filter: textFilter(),
    },
    {
        dataField: "DateFinalReportUpdated",
        text: "Final Report Updated",
        sort: true,
        formatter: (cell, row) => <span>{row.DateFinalReportUpdated ? moment(row.DateFinalReportUpdated).format("YYYY-MM-DD") : "-"}</span>,
        csvFormatter: (cell, row) => row.DateFinalReportUpdated ? moment(row.DateFinalReportUpdated).format("YYYY-MM-DD") : "",
        filter: textFilter(),
    },
    {
        dataField: "DateFinalReportActualPlanned",
        text: "Final Report Actual Planned",
        sort: true,
        formatter: (cell, row) => <span>{row.DateFinalReportActualPlanned ? moment(row.DateFinalReportActualPlanned).format("YYYY-MM-DD") : "-"}</span>,
        csvFormatter: (cell, row) => row.DateFinalReportActualPlanned ? moment(row.DateFinalReportActualPlanned).format("YYYY-MM-DD") : "",
        filter: textFilter(),
    },
    {
        dataField: "DateFinalReportActualUpdated",
        text: "Final Report Actual Updated",
        sort: true,
        formatter: (cell, row) => <span>{row.DateFinalReportActualUpdated ? moment(row.DateFinalReportActualUpdated).format("YYYY-MM-DD") : "-"}</span>,
        csvFormatter: (cell, row) => row.DateFinalReportActualUpdated ? moment(row.DateFinalReportActualUpdated).format("YYYY-MM-DD") : "",
        filter: textFilter(),
    }
];
export const financeLogsReportCsvHeaders = financeLogsReportFinalColumns.map(col => ({ label: col.text, key: col.dataField }));
