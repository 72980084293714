import React, { useEffect, useState, useRef } from "react";
import DashboardLayout from "../../layouts/Dashboard";
import Navbar from "../../components/NavbarMarketDefaults";
import MarketRates from "./MarketRates/MarketRates";
import { Tabs, Tab, Nav } from "react-bootstrap";
import {
  Container,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  CardText,
  CardTitle,
  Col,
  Row,
  Label,
  Button,
  Table,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import Select from "react-select";
import _ from "lodash";
import { toastr } from "react-redux-toastr";

import * as actions from "../../redux/actions/marketDefaultsActions";

import { useDispatch, useSelector } from "react-redux";
import BusinessUnits from "./BusinessUnits/BusinessUnits";
import ApproverContacts from "./ApproverContacts/ApproverContacts";
import GoogleDriveFolderIds from "./GoogleDriveFolderIds/GoogleDriveFolderIds";
import NotificationRecipients from "./NotificationRecipients/NotificationRecipients";
import BootstrapTable from "react-bootstrap-table-next";
import Form from "react-bootstrap/Form";
import Spinner from "../../components/Spinner";
import CreatableSelect from "react-select/creatable";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import { recordLoadEnd } from "../../redux/actions/appActions";
import DynamicRFQ from "./DynamicRFQ/DynamicRFQ";
import { updateCodeLabels } from "../../redux/actions/codeLabelActions";
import axios from "../../axios-interceptor";
import logo from "../../assets/img/avatars/modal-logo.png";
import useAnalyticsEventTracker from "../../utils/useAnalyticsEventTracker";
import * as EventName from "../../utils/GoogleAnalyticsEvents";
import {
  AvFeedback,
  AvForm,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";

const MarketDefault = (props) => {
  const gaEventTracker = useAnalyticsEventTracker(EventName.MarketSettings);

  const dispatch = useDispatch();
  const markets = useSelector(({ marketdefaults }) => marketdefaults.items);
  const [isAddOfficeModal, openAddOfficeModal] = useState(false);
  const [addableOffice, setAddableOffice] = useState({});
  const [addableDashboard, setAddableDashboard] = useState({});
  const [isDashboardModal, openDashboardModal] = useState(false);
  const [officeError, setOfficeError] = useState();
  const [dashboardError, setDashboardError] = useState();
  const globallocalPageload = useSelector(({ app }) => app.localPageload);
  const recordloading = useSelector(({ app }) => app.recordloading);
  const [requestModal, setRequestModal] = useState(false);
  const [currentRequest, setCurrentRequest] = useState({});
  const [requestTypeDeleteModal, openRequestTypeDeleteModal] = useState(false);
  const [projectReviewFlag, setProjectReviewFlag] = useState(false);
  const [countryProjectReview, setCountryProjectReview] = useState({});
  const [addablesmartstorevr, setAddableSmartstorevr] = useState({});
  const [addablesmartEstore, setAddableSmartEstore] = useState({});
  const [addableConsumptionModal, setAddableConsumptionModal] = useState({});
  const [isSmartstorevrModal, openSmartstorevr] = useState(false);
  const [isSmartEstoreModal, openSmartEstore] = useState(false);
  const [isConsumptionModal, openConsumptionModal] = useState(false);
  const [currentOffice, setCurrentOffice] = useState({});
  const [currentDashboard, setCurrentDashboard] = useState({});
  const [officeDeleteModal, openOfficeDeleteModal] = useState(false);
  const [dashboardDeleteModal, openDashboardDeleteModal] = useState(false);
  const [currentSmartStoreVR, setCurrentSmartStoreVR] = useState({});
  const [currentSmartEStore, setCurrentSmartEStore] = useState({});
  const [currentConsumptionMoments, setCurrentConsumptionMoments] = useState(
    {}
  );
  const [marketsCalled, setMarketsCalled] = useState(false);
  const [smartStoreVRDeleteModal, SetsmartStoreVRDeleteModal] = useState(false);
  const [smartEStoreDeleteModal, SetsmartEStoreDeleteModal] = useState(false);
  const [ConsumptionDeleteModal, SetConsumptionDeleteModal] = useState(false);
  const [localPageload, setLocalPageload] = useState(false);
  const codeLabels = useSelector(({ codeLabels }) => codeLabels);
  const userRecord = useSelector(({ user }) => user.userRecord);
  const showLoaderRef = useRef(true);
  const pageloaded = useSelector(({ app }) => app.pageloaded);
  var imgStyle = {
    width: "150px",
  };
  useEffect(() => {
    if (!markets && !marketsCalled && pageloaded) {
      setMarketsCalled(true);
      dispatch(actions.getMarkets(true));
    }
  });
  const app = useSelector(({ app }) => app);
  // const loadItems = () => {
  //     dispatch({
  //         type: actions.loadItems, items: {
  //             ...items,
  //             pakistan: {
  //                 ...items.pakistan,
  //                 marketRates: 2,
  //             }
  //         }
  //     });
  // }

  const [selectedCountry, setSelectedCountry] = useState();

  const addOffice = () => {
    if (addableOffice.Code && addableOffice.Label) {
      setOfficeError(null);
      dispatch(
        actions.createOffice(selectedCountry.id, addableOffice, () => {
          setAddableOffice({});
          console.log("Office" + setAddableOffice({}));
          openAddOfficeModal(false);
        })
      );
    } else {
      if (!addableOffice.Code && !addableOffice.Label) {
        setOfficeError("Please provide Code and Label");
      } else if (!addableOffice.Code) {
        setOfficeError("Please provide Code");
      } else if (!addableOffice.Label) {
        setOfficeError("Please provide Label");
      }
    }
  };
  const addDashboard = () => {
    if (addableDashboard.contactEmails) {
      setDashboardError(null);
      dispatch(
        actions.createDashboardSME(selectedCountry.id, addableDashboard, () => {
          setAddableDashboard({});
          console.log("dashboard" + setAddableDashboard);
          openDashboardModal(false);
        })
      );
    } else {
      if (!addableDashboard.Email) {
        setDashboardError("Please provide email");
      }
    }
  };
  const addSmartStoreVR = () => {
    if (addablesmartstorevr.contactEmails) {
      setDashboardError(null);
      let code = "SMV";
      dispatch(
        actions.createShopperSME(
          selectedCountry.id,
          code,
          addablesmartstorevr,
          () => {
            setAddableSmartstorevr({});
            console.log("SmartStoreVR" + setAddableSmartstorevr);
            openSmartstorevr(false);
          }
        )
      );
    } else {
      if (!addablesmartstorevr.Email) {
        setDashboardError("Please provide email");
      }
    }
  };
  const addSmartEStore = () => {
    if (addablesmartEstore.contactEmails) {
      setDashboardError(null);
      let code = "SME";
      dispatch(
        actions.createShopperSME(
          selectedCountry.id,
          code,
          addablesmartEstore,
          () => {
            setAddableSmartEstore({});
            console.log("SmartEStore" + setAddableSmartEstore);
            openSmartEstore(false);
          }
        )
      );
    } else {
      if (!addablesmartEstore.Email) {
        setDashboardError("Please provide email");
      }
    }
  };
  const addConsumptionMoments = () => {
    if (addableConsumptionModal.contactEmails) {
      setDashboardError(null);
      let code = "CS";
      dispatch(
        actions.createShopperSME(
          selectedCountry.id,
          code,
          addableConsumptionModal,
          () => {
            setAddableConsumptionModal({});
            console.log("ConsumptionMonet" + setAddableConsumptionModal);
            openConsumptionModal(false);
          }
        )
      );
    } else {
      if (!addableConsumptionModal.Email) {
        setDashboardError("Please provide email");
      }
    }
  };
  let columns = [
    {
      dataField: "id",
      text: "#",
      sort: true,
    },
    {
      dataField: "Label",
      text: "Country",
      sort: true,
    },
    {
      dataField: "Code",
      text: "Country Code",
      sort: true,
    },
  ];
  let officeColumns = [
    {
      dataField: "id",
      text: "#",
    },
    {
      dataField: "Label",
      text: "Location/Type",
      editable: true,
    },
    {
      dataField: "Code",
      text: "Code",
      editable: true,
    },
    {
      text: "Actions",
      editable: false,
      formatter: (cell, row) => {
        return (
          <>
            <Button
              size="sm"
              color="success"
              onClick={() => {
                gaEventTracker(EventName.Save);
                setCurrentOffice(row);
                setLocalPageload(true);
                dispatch(
                  actions.saveOffice(
                    row,
                    () => {
                      setCurrentRequest(null);
                      setLocalPageload(false);
                    },
                    row.CountryId
                  )
                );
              }}
            >
              Save
            </Button>
            <Button
              size="sm"
              color="danger"
              className="ml-2"
              onClick={() => {
                gaEventTracker(EventName.Delete);
                setCurrentOffice(row);
                openOfficeDeleteModal(true);
              }}
            >
              Delete
            </Button>
          </>
        );
      },
    },
  ];
  let dashboardColumns = [
    {
      dataField: "id",
      text: "#",
    },
    {
      dataField: "contactEmails",
      text: "Contact Emails",
      editable: true,
    },

    {
      text: "Actions",
      editable: false,
      formatter: (cell, row) => {
        return (
          <>
            <Button
              size="sm"
              color="success"
              onClick={() => {
                setCurrentDashboard(row);
                setLocalPageload(true);
                dispatch(
                  actions.UpdateDashBoardSme(
                    row,
                    () => {
                      setCurrentDashboard(null);
                      setLocalPageload(false);
                    },
                    row.CountryId
                  )
                );
              }}
            >
              Save
            </Button>
            <Button
              size="sm"
              color="danger"
              className="ml-2"
              onClick={() => {
                setCurrentDashboard(row);
                openDashboardDeleteModal(true);
              }}
            >
              Delete
            </Button>
          </>
        );
      },
    },
  ];
  let SmartStoreVRColumns = [
    {
      dataField: "id",
      text: "#",
    },
    {
      dataField: "contactEmails",
      text: "Contact Emails",
      editable: true,
    },

    {
      text: "Actions",
      editable: false,

      formatter: (cell, row) => {
        return (
          <>
            <Button
              size="sm"
              color="success"
              onClick={() => {
                setCurrentSmartStoreVR(row);
                setLocalPageload(true);
                dispatch(
                  actions.UpdateShopperSme(
                    row,
                    () => {
                      setCurrentSmartStoreVR(null);
                      setLocalPageload(false);
                    },
                    row.CountryId
                  )
                );
              }}
            >
              Save
            </Button>
            <Button
              size="sm"
              color="danger"
              className="ml-2"
              onClick={() => {
                setCurrentSmartStoreVR(row);
                SetsmartStoreVRDeleteModal(true);
              }}
            >
              Delete
            </Button>
          </>
        );
      },
    },
  ];
  let SmartEStoreColumns = [
    {
      dataField: "id",
      text: "#",
    },
    {
      dataField: "contactEmails",
      text: "Contact Emails",
      editable: true,
    },

    {
      text: "Actions",
      editable: false,

      formatter: (cell, row) => {
        return (
          <>
            <Button
              size="sm"
              color="success"
              onClick={() => {
                setCurrentSmartEStore(row);
                setLocalPageload(true);
                dispatch(
                  actions.UpdateShopperSme(
                    row,
                    () => {
                      setCurrentSmartEStore(null);
                      setLocalPageload(false);
                    },
                    row.CountryId
                  )
                );
              }}
            >
              Save
            </Button>
            <Button
              size="sm"
              color="danger"
              className="ml-2"
              onClick={() => {
                setCurrentSmartEStore(row);
                SetsmartEStoreDeleteModal(true);
              }}
            >
              Delete
            </Button>
          </>
        );
      },
    },
  ];
  let ConsumptionColumns = [
    {
      dataField: "id",
      text: "#",
    },
    {
      dataField: "contactEmails",
      text: "Contact Emails",
      editable: true,
    },

    {
      text: "Actions",
      editable: false,

      formatter: (cell, row) => {
        return (
          <>
            <Button
              size="sm"
              color="success"
              onClick={() => {
                setCurrentConsumptionMoments(row);
                setLocalPageload(true);
                dispatch(
                  actions.UpdateShopperSme(
                    row,
                    () => {
                      setCurrentConsumptionMoments(null);
                      setLocalPageload(false);
                    },
                    row.CountryId
                  )
                );
              }}
            >
              Save
            </Button>
            <Button
              size="sm"
              color="danger"
              className="ml-2"
              onClick={() => {
                setCurrentConsumptionMoments(row);
                SetConsumptionDeleteModal(true);
              }}
            >
              Delete
            </Button>
          </>
        );
      },
    },
  ];
  let requestColumns = [
    {
      dataField: "id",
      text: "#",
    },
    {
      dataField: "RequestTypeName",
      text: "Request Type",
      editable: true,
    },
    {
      dataField: "PrimaryContactEmails",
      text: "Primary Contact Emails",
      editable: true,
    },
    {
      dataField: "OtherContactEmails",
      text: "Other Contact Emails",
      editable: true,
    },
    {
      dataField: "Comments",
      text: "Comments",
      editable: true,
    },
    {
      dataField: "CreatedBy",
      text: "Created By",
      editable: false,
    },
    {
      dataField: "updatedAt",
      text: "Updated At",
      editable: false,
    },
    {
      text: "Actions",
      editable: false,
      formatter: (cell, row) => {
        return (
          <div style={{ width: "250px" }}>
            <Button
              size="sm"
              color="success"
              className="ml-2"
              onClick={() => {
                setLocalPageload(true);
                setCurrentRequest(row);
                dispatch(
                  actions.saveRequestType(
                    row,
                    () => {
                      setCurrentRequest(null);
                      setLocalPageload(false);
                    },
                    row.CountryId
                  )
                );
              }}
            >
              Save
              {/* {app.recordloading && currentRequest.id == row.id ? (
								<Spinner size="small" color="#495057" />
							) : null} */}
            </Button>
            <Button
              size="sm"
              color="danger"
              className="ml-2"
              onClick={() => {
                setCurrentRequest(row);
                openRequestTypeDeleteModal(true);
              }}
            >
              Delete
            </Button>
          </div>
        );
      },
    },
  ];

  function checkProjectReview(e, countryData) {
    let tempCountries = {};
    let tempCodelabels = _.cloneDeep(codeLabels);
    if (countryData) {
      tempCountries = { ...countryData };
      if (e.target.checked == true) {
        tempCountries.IsProjectReview = true;
      } else {
        tempCountries.IsProjectReview = false;
      }

      setProjectReviewFlag(true);
      setCountryProjectReview(tempCountries);

      if (
        _.includes(
          codeLabels["FieldingCountriesOptions"].map((s) => s.Label),
          tempCountries.Label
        )
      ) {
        tempCodelabels["FieldingCountriesOptions"].find(
          (fco) => fco.Label == tempCountries.Label
        ).IsProjectReview = tempCountries.IsProjectReview;
      }

      dispatch(updateCodeLabels(tempCodelabels));
    }
    // dispatch({
    //   type: SET_CODELABELS,
    //   InitLabels: tempCodelabels,
    // });
  }
  function UpdateCountries() {
    setProjectReviewFlag(false);
    axios
      .put(`/countries/updateCountry`, { data: countryProjectReview })
      .then((res) => {})
      .catch((error) => {
        toastr.error("Updating Failed!");
      });
  }

  const countryChangeHandler = (eve) => {};
  const addRequestType = () => {
    dispatch(
      actions.addRequestType(selectedCountry.id, currentRequest, () => {
        setSelectedCountry(null);
        setCurrentRequest(null);
        setRequestModal(false);
      })
    );
  };
  const expandRow = {
    renderer: (row) => (
      <React.Fragment>
        <Row
          className="m-0 p-0 font-sm business-verticals width-100"
          style={{ fontSize: "0.7rem" }}
        >
          <Col>
            <Card className="p-0">
              <CardHeader>
                <Row className="justify-content-between">
                  <Col className="text-left">
                    <CardTitle>
                      {"#"}
                      {row.id} {row.Label}
                      {" - "} {row.Code}
                    </CardTitle>
                  </Col>
                  <Col className="text-right">
                    <CardText>
                      {row.updatedAt
                        ? `Last updated on  ${new Date(
                            row.updatedAt
                          ).toDateString()}`
                        : ""}{" "}
                      {row.UpdatedBy ? `by ${row.UpdatedBy}` : ""}
                    </CardText>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <CardTitle>Country Settings</CardTitle>
                    <Form>
                      <Form.Group>
                        <Row>
                          <Col>
                            <Form.Label>
                              Conversion Rate To Local Currency
                            </Form.Label>
                            <Form.Control
                              disabled
                              type="number"
                              value={row.ConversionRateToLocal}
                            />
                          </Col>
                          <Col>
                            <Form.Label>Local Currency Unit</Form.Label>
                            <Form.Control
                              disabled
                              type="text"
                              value={row.CurrencyUnit}
                            />
                          </Col>
                          <Col>
                            <Form.Label>Admin Contact Mails</Form.Label>
                            <CreatableSelect
                              className="react-select-container  custom-select-box"
                              isDisabled={true}
                              isMulti
                              value={row.AdminContactEmails?.split(",").map(
                                (val) => {
                                  return { label: val, value: val };
                                }
                              )}
                              onChange={(val) => {
                                countryChangeHandler({
                                  target: {
                                    id: "AdminContactEmails",
                                    value: val
                                      ? val.map((v) => v.value).join()
                                      : null,
                                  },
                                });
                              }}
                              // todo:add options
                              // options={currentVertical[prp]
                              //     ?.split(",")
                              //     .map((val) => {
                              //         return { label: val, value: val };
                              //     })}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Form.Label>
                              <input
                                type="checkbox"
                                onChange={(e) => checkProjectReview(e, row)}
                                checked={
                                  codeLabels["FieldingCountriesOptions"].find(
                                    (fco) => fco.Label == row.Label
                                  ).IsProjectReview
                                }
                                className="mr-2"
                              ></input>
                              Project Review
                            </Form.Label>
                          </Col>
                        </Row>
                      </Form.Group>
                    </Form>
                    <hr />
                    <Card>
                      <CardTitle className="p-2">Request types</CardTitle>
                      <Row className="justify-content-end mb-2 marketrowclasss">
                        <Button
                          className="mb-2"
                          onClick={() => {
                            gaEventTracker(EventName.AddRequestType);
                            setRequestModal(true);
                            setSelectedCountry(row);
                          }}
                        >
                          Add Request type
                        </Button>
                      </Row>
                      <BootstrapTable
                        data={row.RequestTypes ? row.RequestTypes : []}
                        columns={requestColumns}
                        keyField="id"
                        cellEdit={cellEditFactory({
                          mode: "click",
                          blurToSave: true,
                        })}
                      />
                    </Card>{" "}
                    <hr />
                    <Card>
                      <CardTitle className="p-2">Office(s)</CardTitle>

                      <Row className="justify-content-end mb-2 marketrowclasss">
                        <Button
                          onClick={() => {
                            openAddOfficeModal(true);
                            setSelectedCountry(row);
                          }}
                        >
                          Add Office
                        </Button>
                      </Row>
                      <BootstrapTable
                        data={row.Offices ? row.Offices : []}
                        columns={officeColumns}
                        keyField="id"
                        cellEdit={cellEditFactory({
                          mode: "click",
                          blurToSave: true,
                        })}
                      />
                    </Card>
                    <hr />
                    <Card>
                      <CardTitle>Dashboard SME</CardTitle>
                      <Row className="justify-content-end mb-2 marketrowclasss">
                        <Button
                          onClick={() => {
                            openDashboardModal(true);
                            setSelectedCountry(row);
                          }}
                        >
                          Add Dashboard SME
                        </Button>
                      </Row>
                      <BootstrapTable
                        data={row.DashBoardSmes ? row.DashBoardSmes : []}
                        columns={dashboardColumns}
                        keyField="id"
                        cellEdit={cellEditFactory({
                          mode: "click",
                          blurToSave: true,
                        })}
                      />
                    </Card>
                    <hr />
                    <Card>
                      <CardTitle>Smart Store VR SME</CardTitle>
                      <Row className="justify-content-end mb-2 marketrowclasss">
                        <Button
                          onClick={() => {
                            openSmartstorevr(true);
                            setSelectedCountry(row);
                          }}
                        >
                          Add Smart Store VR SME
                        </Button>
                      </Row>
                      <BootstrapTable
                        data={row.SmartStoreVRSmes ? row.SmartStoreVRSmes : []}
                        columns={SmartStoreVRColumns}
                        keyField="id"
                        cellEdit={cellEditFactory({
                          mode: "click",
                          blurToSave: true,
                        })}
                      />
                    </Card>
                    <hr />
                    <Card>
                      <CardTitle>Smart E-Store SME</CardTitle>
                      <Row className="justify-content-end mb-2 marketrowclasss">
                        <Button
                          onClick={() => {
                            openSmartEstore(true);
                            setSelectedCountry(row);
                          }}
                        >
                          Add Smart E-Store SME
                        </Button>
                      </Row>
                      <BootstrapTable
                        data={row.SmartEStoreSmes ? row.SmartEStoreSmes : []}
                        columns={SmartEStoreColumns}
                        keyField="id"
                        cellEdit={cellEditFactory({
                          mode: "click",
                          blurToSave: true,
                        })}
                      />
                    </Card>
                    <hr />
                    <Card>
                      <CardTitle>Consumption Moment SME</CardTitle>
                      <Row className="justify-content-end mb-2 marketrowclasss">
                        <Button
                          onClick={() => {
                            openConsumptionModal(true);
                            setSelectedCountry(row);
                          }}
                        >
                          Add Consumption Moment SME
                        </Button>
                      </Row>
                      <BootstrapTable
                        data={
                          row.ConsumptionMomentSmes
                            ? row.ConsumptionMomentSmes
                            : []
                        }
                        columns={ConsumptionColumns}
                        keyField="id"
                        cellEdit={cellEditFactory({
                          mode: "click",
                          blurToSave: true,
                        })}
                      />
                    </Card>
                    <hr />
                    <Card>
                      <CardTitle className="p-2">Business Unit(s)</CardTitle>
                      <Row>
                        <Col>
                          <BusinessUnits
                            currentCountry={row}
                            setLocalPageload={setLocalPageload}
                          />
                        </Col>
                      </Row>
                    </Card>
                    <hr />
                    {userRecord.IsMarketAdmin ? (
                      <DynamicRFQ
                        currentCountry={row}
                        codeLabels={codeLabels}
                      />
                    ) : null}
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <hr />
            {/* {
								userRecord.IsMarketAdmin
									?
									<DynamicRFQ currentCountry={row} codeLabels={codeLabels} />
									: null
							} */}
          </Col>
        </Row>
        <Modal
          isOpen={isDashboardModal}
          size="sm"
          toggle={() => openDashboardModal(!isDashboardModal)}
          centered={true}
        >
          <ModalHeader toggle={() => openDashboardModal(!isDashboardModal)}>
            {/* <img src={logo} className="modallogo" /> */}
          </ModalHeader>
          <div>
            <span className="modaltitle modaltitleoffice">
              Add Dashboard SME
            </span>
          </div>
          <ModalBody>
            <Row className="ml-1 mb-4">
              <strong>
                Commissioning Country: <i>{selectedCountry?.Label}</i>
              </strong>
            </Row>
            <AvForm
              onValidSubmit={(e) => {
                if (row.DashBoardSmes.length < 1) {
                  addDashboard();
                } else {
                  actions.UpdateDashBoardSme();
                  toastr.error(
                    "Dashboard sme is already existing go for updation"
                  );
                }
              }}
            >
              <Row className="mb-2">
                <Col>
                  Contact Email <sup>*</sup>
                </Col>
                <Col>
                  <AvGroup>
                    <AvInput
                      name="Email"
                      className="form-control"
                      type="email"
                      required
                      onChange={(e) =>
                        setAddableDashboard({
                          ...addableDashboard,
                          contactEmails: e.target.value,
                        })
                      }
                      id="Email"
                    />
                    <AvFeedback>Email is required.</AvFeedback>
                  </AvGroup>
                </Col>
              </Row>
              <Row className="ml-0 mt-2 justify-content-start">
                <Button color="primary" type="submit">
                  Add{" "}
                  {app.recordloading ? (
                    <Spinner size="small" color="#495057" />
                  ) : null}
                </Button>
                <Button
                  color="secondary"
                  className="ml-2"
                  onClick={() => {
                    setAddableDashboard({});
                    openDashboardModal(false);
                  }}
                >
                  Cancel
                </Button>
              </Row>
            </AvForm>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={isSmartstorevrModal}
          size="sm"
          toggle={() => openSmartstorevr(!isSmartstorevrModal)}
          centered={true}
        >
          <ModalHeader toggle={() => openSmartstorevr(!isSmartstorevrModal)}>
            {/* <img src={logo} className="modallogo" /> */}
          </ModalHeader>
          <div>
            <span className="modaltitle modaltitleoffice">
              Add SmartStore VR SME
            </span>
          </div>
          <ModalBody>
            <Row className="ml-1 mb-4">
              <strong>
                Commissioning Country: <i>{selectedCountry?.Label}</i>
              </strong>
            </Row>
            <AvForm
              onValidSubmit={(e) => {
                if (row.SmartStoreVRSmes.length < 1) {
                  addSmartStoreVR();
                } else {
                  actions.UpdateShopperSme();
                  toastr.error(
                    "Smart Store VR SME already exist. Please update instead."
                  );
                }
              }}
            >
              <Row className="mb-2">
                <Col>
                  Contact Email <sup>*</sup>
                </Col>
                <Col>
                  <AvGroup>
                    <AvInput
                      name="Email"
                      className="form-control"
                      type="email"
                      required
                      onChange={(e) =>
                        setAddableSmartstorevr({
                          ...addablesmartstorevr,
                          contactEmails: e.target.value,
                        })
                      }
                      id="Email"
                    />
                    <AvFeedback>Email is required.</AvFeedback>
                  </AvGroup>
                </Col>
              </Row>
              <Row className="ml-0 mt-2 justify-content-start">
                <Button color="primary" type="submit">
                  Add{" "}
                  {app.recordloading ? (
                    <Spinner size="small" color="#495057" />
                  ) : null}
                </Button>
                <Button
                  color="secondary"
                  className="ml-2"
                  onClick={() => {
                    setAddableSmartstorevr({});
                    openSmartstorevr(false);
                  }}
                >
                  Cancel
                </Button>
              </Row>
            </AvForm>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={isSmartEstoreModal}
          size="sm"
          toggle={() => openSmartEstore(!isSmartEstoreModal)}
          centered={true}
        >
          <ModalHeader toggle={() => openSmartEstore(!isSmartEstoreModal)}>
            {/* <img src={logo} className="modallogo" /> */}
          </ModalHeader>
          <div>
            <span className="modaltitle modaltitleoffice">
              Add Smart E Store SME
            </span>
          </div>
          <ModalBody>
            <Row className="ml-1 mb-4">
              <strong>
                Commissioning Country: <i>{selectedCountry?.Label}</i>
              </strong>
            </Row>
            <AvForm
              onValidSubmit={(e) => {
                if (row.SmartEStoreSmes.length < 1) {
                  addSmartEStore();
                } else {
                  actions.UpdateShopperSme();
                  toastr.error(
                    "Smart E-Store SME already exist. Please update instead."
                  );
                }
              }}
            >
              <Row className="mb-2">
                <Col>
                  Contact Email <sup>*</sup>
                </Col>
                <Col>
                  <AvGroup>
                    <AvInput
                      name="Email"
                      className="form-control"
                      type="email"
                      required
                      onChange={(e) =>
                        setAddableSmartEstore({
                          ...addablesmartEstore,
                          contactEmails: e.target.value,
                        })
                      }
                      id="Email"
                    />
                    <AvFeedback>Email is required.</AvFeedback>
                  </AvGroup>
                </Col>
              </Row>
              <Row className="ml-0 mt-2 justify-content-start">
                <Button color="primary" type="submit">
                  Add{" "}
                  {app.recordloading ? (
                    <Spinner size="small" color="#495057" />
                  ) : null}
                </Button>
                <Button
                  color="secondary"
                  className="ml-2"
                  onClick={() => {
                    setAddableSmartstorevr({});
                    openSmartstorevr(false);
                  }}
                >
                  Cancel
                </Button>
              </Row>
            </AvForm>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={isConsumptionModal}
          size="sm"
          toggle={() => openConsumptionModal(!isConsumptionModal)}
          centered={true}
        >
          <ModalHeader toggle={() => openConsumptionModal(!isConsumptionModal)}>
            {/* <img src={logo} className="modallogo" /> */}
          </ModalHeader>
          <div>
            <span className="modaltitle modaltitleoffice">
              Add Consumption Moment SME
            </span>
          </div>
          <ModalBody>
            <Row className="ml-1 mb-4">
              <strong>
                Commissioning Country: <i>{selectedCountry?.Label}</i>
              </strong>
            </Row>
            <AvForm
              onValidSubmit={(e) => {
                if (row.ConsumptionMomentSmes.length < 1) {
                  addConsumptionMoments();
                } else {
                  actions.UpdateShopperSme();
                  toastr.error(
                    "Consumption Moment SME already exist. Please update instead."
                  );
                }
              }}
            >
              <Row className="mb-2">
                <Col>
                  Contact Email <sup>*</sup>
                </Col>
                <Col>
                  <AvGroup>
                    <AvInput
                      name="Email"
                      className="form-control"
                      type="email"
                      required
                      onChange={(e) =>
                        setAddableConsumptionModal({
                          ...addableConsumptionModal,
                          contactEmails: e.target.value,
                        })
                      }
                      id="Email"
                    />
                    <AvFeedback>Email is required.</AvFeedback>
                  </AvGroup>
                </Col>
              </Row>
              <Row className="ml-0 mt-2 justify-content-start">
                <Button color="primary" type="submit">
                  Add{" "}
                  {app.recordloading ? (
                    <Spinner size="small" color="#495057" />
                  ) : null}
                </Button>
                <Button
                  color="secondary"
                  className="ml-2"
                  onClick={() => {
                    setAddableSmartstorevr({});
                    openSmartstorevr(false);
                  }}
                >
                  Cancel
                </Button>
              </Row>
            </AvForm>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={isAddOfficeModal}
          size="sm"
          toggle={() => openAddOfficeModal(!isAddOfficeModal)}
          centered={true}
        >
          <ModalHeader toggle={() => openAddOfficeModal(!isAddOfficeModal)}>
            {/* <img src={logo} className="modallogo" /> */}
          </ModalHeader>
          <div>
            <span className="modaltitle modaltitleoffice">Add Office</span>
          </div>
          <ModalBody>
            <Row className="ml-1 mb-4">
              <strong>
                Commissioning Country: <i>{selectedCountry?.Label}</i>
              </strong>
            </Row>
            <AvForm
              onValidSubmit={(e) => {
                // e.preventDefault();
                addOffice();
              }}
            >
              <Row className="mb-2">
                <Col>
                  Code <sup>*</sup>
                </Col>
                <Col>
                  <AvGroup>
                    <AvInput
                      name="Code"
                      className="form-control"
                      required
                      onChange={(e) =>
                        setAddableOffice({
                          ...addableOffice,
                          Code: e.target.value,
                        })
                      }
                      id="Code"
                    />
                    <AvFeedback>Code is required.</AvFeedback>
                  </AvGroup>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  Label <sup>*</sup>
                </Col>
                <Col>
                  <AvGroup>
                    <AvInput
                      name="Label"
                      className="form-control"
                      required
                      onChange={(e) =>
                        setAddableOffice({
                          ...addableOffice,
                          Label: e.target.value,
                        })
                      }
                      id="Label"
                    />
                    <AvFeedback>Label is required.</AvFeedback>
                  </AvGroup>
                </Col>
              </Row>
              <Row className="ml-0 mt-2 justify-content-start">
                <Button color="primary" type="submit">
                  Add{" "}
                  {app.recordloading ? (
                    <Spinner size="small" color="#495057" />
                  ) : null}
                </Button>
                <Button
                  color="secondary"
                  className="ml-2"
                  onClick={() => {
                    setAddableOffice({});
                    openAddOfficeModal(false);
                  }}
                >
                  Cancel
                </Button>
              </Row>
            </AvForm>
          </ModalBody>
          {/* <ModalFooter>
						<Row>
							{officeError ? <p className="error">{officeError}</p> : null}
						</Row>
						
					</ModalFooter> */}
        </Modal>
        <Modal
          isOpen={requestModal}
          toggle={() => setRequestModal(!requestModal)}
          centered={true}
        >
          <ModalHeader toggle={() => setRequestModal(false)}>
            {/* <img src={logo} className="modallogo" /> */}
          </ModalHeader>
          <div>
            <span className="modaltitle modaltitlerequesttype">
              Add Request type
            </span>
          </div>
          <ModalBody>
            <AvForm
              onValidSubmit={(e) => {
                // e.preventDefault();
                addRequestType();
              }}
            >
              <Row className="mb-2">
                <Col>
                  <strong>
                    Commissioning Country: <i>{selectedCountry?.Label}</i>
                  </strong>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <Label>Request Type Name</Label>
                  <sup>*</sup>
                </Col>
                <Col>
                  <AvGroup>
                    <AvInput
                      name="RequestTypeName"
                      className="form-control"
                      required
                      onChange={(e) =>
                        setCurrentRequest({
                          ...currentRequest,
                          RequestTypeName: e.target.value,
                        })
                      }
                    />
                    <AvFeedback>Request Type Name is required.</AvFeedback>
                  </AvGroup>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <Label>Primary Contact Email</Label>
                  <sup>*</sup>
                </Col>
                <Col>
                  <AvGroup>
                    <AvInput
                      name="PrimaryContactEmails"
                      className="form-control"
                      required
                      type="email"
                      onChange={(e) =>
                        setCurrentRequest({
                          ...currentRequest,
                          PrimaryContactEmails: e.target.value,
                        })
                      }
                    />
                    <AvFeedback>Primary Contact Email is required!</AvFeedback>
                  </AvGroup>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <Label>Other Contact Emails</Label>
                </Col>
                <Col>
                  <CreatableSelect
                    isMulti
                    onChange={(val) => {
                      setCurrentRequest({
                        ...currentRequest,
                        OtherContactEmails: val
                          ? val.map((v) => v.value).join()
                          : null,
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <Label>Comments</Label>
                </Col>
                <Col>
                  <AvInput
                    name="Comments"
                    className="form-control"
                    onChange={(e) =>
                      setCurrentRequest({
                        ...currentRequest,
                        Comments: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
              <Row className="justify-content-start mt-2 mr-0">
                <Button type="submit" className="ml-2" color="primary">
                  Add
                  {app.recordloading ? (
                    <Spinner size="small" color="#495057" />
                  ) : null}
                </Button>
                <Button
                  className="ml-2"
                  color="secondary"
                  onClick={() => {
                    setRequestModal(false);
                    setCurrentRequest(null);
                  }}
                >
                  Cancel
                </Button>
              </Row>
            </AvForm>
          </ModalBody>
        </Modal>

        <Modal
          size="sm"
          isOpen={requestTypeDeleteModal}
          toggle={() => openRequestTypeDeleteModal(!requestTypeDeleteModal)}
          centered={true}
        >
          <ModalHeader
            toggle={() => openRequestTypeDeleteModal(!requestTypeDeleteModal)}
          >
            {/* <img src={logo} className="modallogo" /> */}
          </ModalHeader>
          <div>
            <span className="modaltitle modaltitledeletereuesttype">
              Delete Request Type
            </span>
          </div>
          <ModalBody>
            <strong>
              This change is irreversible. Are sure want to delete{" "}
              <i>{currentRequest?.RequestTypeName}</i>
            </strong>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              size="sm"
              onClick={() => {
                dispatch(
                  actions.deleteRequestType(
                    currentRequest,
                    () => {
                      openRequestTypeDeleteModal(false);
                      setCurrentRequest(null);
                    },
                    currentRequest.CountryId
                  )
                );
              }}
            >
              Confirm
            </Button>
            <Button
              color="secondary"
              size="sm"
              onClick={() => {
                openRequestTypeDeleteModal(false);
                setCurrentRequest(null);
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={smartStoreVRDeleteModal}
          size="me"
          toggle={() => SetsmartStoreVRDeleteModal(!smartStoreVRDeleteModal)}
        >
          <ModalHeader
            toggle={() => SetsmartStoreVRDeleteModal(!smartStoreVRDeleteModal)}
          >
            {/* <img src={logo} className="modallogo" /> */}
          </ModalHeader>
          <div>
            <span className="modaltitle modaldeletedashboard">
              Delete SmartStore VR SME
            </span>
          </div>
          <ModalBody>
            <strong>
              This change is irreversible. Are you sure want to delete{" "}
              <i>{currentSmartStoreVR?.contactEmails}</i>
            </strong>
          </ModalBody>
          <ModalFooter>
            <Button
              size="sm"
              color="primary"
              onClick={() => {
                dispatch(
                  actions.DeleteShopperSme(
                    currentSmartStoreVR,
                    () => {
                      SetsmartStoreVRDeleteModal(false);
                      setCurrentSmartStoreVR(null);
                    },
                    currentSmartStoreVR.CountryId
                  )
                );
              }}
            >
              Confirm
            </Button>
            <Button
              size="sm"
              color="secondary"
              onClick={() => {
                SetsmartStoreVRDeleteModal(false);
                setCurrentSmartStoreVR(null);
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={smartEStoreDeleteModal}
          size="me"
          toggle={() => SetsmartEStoreDeleteModal(!smartEStoreDeleteModal)}
        >
          <ModalHeader
            toggle={() => SetsmartEStoreDeleteModal(!smartEStoreDeleteModal)}
          >
            {/* <img src={logo} className="modallogo" /> */}
          </ModalHeader>
          <div>
            <span className="modaltitle modaldeletedashboard">
              Delete SmartEStore SME
            </span>
          </div>
          <ModalBody>
            <strong>
              This change is irreversible. Are you sure want to delete{" "}
              <i>{currentSmartEStore?.contactEmails}</i>
            </strong>
          </ModalBody>
          <ModalFooter>
            <Button
              size="sm"
              color="primary"
              onClick={() => {
                dispatch(
                  actions.DeleteShopperSme(
                    currentSmartEStore,
                    () => {
                      SetsmartEStoreDeleteModal(false);
                      setCurrentSmartEStore(null);
                    },
                    currentSmartEStore.CountryId
                  )
                );
              }}
            >
              Confirm
            </Button>
            <Button
              size="sm"
              color="secondary"
              onClick={() => {
                SetsmartEStoreDeleteModal(false);
                setCurrentSmartEStore(null);
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={ConsumptionDeleteModal}
          size="me"
          toggle={() => SetConsumptionDeleteModal(!ConsumptionDeleteModal)}
        >
          <ModalHeader
            toggle={() => SetConsumptionDeleteModal(!ConsumptionDeleteModal)}
          >
            {/* <img src={logo} className="modallogo" /> */}
          </ModalHeader>
          <div>
            <span className="modaltitle modaldeletedashboard">
              Delete ConsumptionMoment SMEs
            </span>
          </div>
          <ModalBody>
            <strong>
              This change is irreversible. Are you sure want to delete{" "}
              <i>{currentConsumptionMoments?.contactEmails}</i>
            </strong>
          </ModalBody>
          <ModalFooter>
            <Button
              size="sm"
              color="primary"
              onClick={() => {
                dispatch(
                  actions.DeleteShopperSme(
                    currentConsumptionMoments,
                    () => {
                      SetConsumptionDeleteModal(false);
                      setCurrentConsumptionMoments(null);
                    },
                    currentConsumptionMoments.CountryId
                  )
                );
              }}
            >
              Confirm
            </Button>
            <Button
              size="sm"
              color="secondary"
              onClick={() => {
                SetConsumptionDeleteModal(false);
                setCurrentConsumptionMoments(null);
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={dashboardDeleteModal}
          size="me"
          toggle={() => openDashboardDeleteModal(!dashboardDeleteModal)}
        >
          <ModalHeader
            toggle={() => openDashboardDeleteModal(!dashboardDeleteModal)}
          >
            {/* <img src={logo} className="modallogo" /> */}
          </ModalHeader>
          <div>
            <span className="modaltitle modaldeletedashboard">
              Delete Dashboard SME
            </span>
          </div>
          <ModalBody>
            <strong>
              This change is irreversible. Are you sure want to delete{" "}
              <i>{currentDashboard?.contactEmails}</i>
            </strong>
          </ModalBody>
          <ModalFooter>
            <Button
              size="sm"
              color="primary"
              onClick={() => {
                dispatch(
                  actions.DeleteDashBoardSme(
                    currentDashboard,
                    () => {
                      openDashboardDeleteModal(false);
                      setCurrentDashboard(null);
                    },
                    currentDashboard.CountryId
                  )
                );
              }}
            >
              Confirm
            </Button>
            <Button
              size="sm"
              color="secondary"
              onClick={() => {
                openDashboardDeleteModal(false);
                setCurrentDashboard(null);
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={officeDeleteModal}
          size="sm"
          toggle={() => openOfficeDeleteModal(!officeDeleteModal)}
        >
          <ModalHeader toggle={() => openOfficeDeleteModal(!officeDeleteModal)}>
            {/* <img src={logo} className="modallogo" /> */}
          </ModalHeader>
          <div>
            <span className="modaltitle modaldeleteoffice">Delete Office</span>
          </div>
          <ModalBody>
            <strong>
              This change is irreversible. Are you sure want to delete{" "}
              <i>{currentOffice?.Label}</i>
            </strong>
          </ModalBody>
          <ModalFooter>
            <Button
              size="sm"
              color="primary"
              onClick={() => {
                dispatch(
                  actions.deleteOffice(
                    currentOffice,
                    () => {
                      openOfficeDeleteModal(false);
                      setCurrentOffice(null);
                    },
                    currentOffice.CountryId
                  )
                );
              }}
            >
              Confirm
            </Button>
            <Button
              size="sm"
              color="secondary"
              onClick={() => {
                openOfficeDeleteModal(false);
                setCurrentOffice(null);
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={projectReviewFlag}
          toggle={() => setProjectReviewFlag(false)}
          size="sm"
          centered={true}
        >
          <ModalHeader toggle={() => setProjectReviewFlag(false)}>
            {/* <img src={logo} className="modallogo" /> */}
          </ModalHeader>
          <div>
            <span className="modaltitle modalwarning">Warning!!</span>
          </div>
          <ModalBody>
            <p>
              <h5>You are going to add Project Review permission.</h5>
            </p>
            <br />
            <p>
              <h5>Please Save the Permission in DataBase.</h5>
            </p>
          </ModalBody>
          <ModalFooter>
            <div className="d-flex justify-content-between">
              <Button
                className="form-control mr-2"
                color="primary"
                onClick={() => UpdateCountries()}
              >
                Save
              </Button>
              <Button
                className="form-control"
                color="secondary"
                onClick={() => {
                  setProjectReviewFlag(false);
                  dispatch(updateCodeLabels(codeLabels));
                }}
              >
                Cancel
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    ),
    onExpand: (row, isExpand, rowIndex, e) => {
      const getJson = () => {
        return {
          verticals: "",
          businessUnits: "",
          countries: [row.Code],
        };
      };

      if (!row.BusinessUnits) {
        setTimeout(() => {
          showLoaderRef.current = false;
          dispatch(
            actions.getIndividualMarket(row.id, () => {
              dispatch(recordLoadEnd());
              showLoaderRef.current = true;
            })
          );
          dispatch(actions.loadUsers(0, getJson()));
        });
      }
    },
  };

  return (
    <DashboardLayout navbar={<Navbar show={false} />}>
      {localPageload || globallocalPageload || recordloading ? (
        <div id="pageCoverSpin"></div>
      ) : null}

      <Container fluid className="p-0">
        <Card>
          <CardBody className="p-2">
            <BootstrapTable
              hover
              bordered={false}
              bootstrap4
              striped
              data={markets ? markets : []}
              columns={columns}
              keyField="id"
              defaultSorted={[{ dataField: "createdAt", order: "desc" }]}
              expandRow={expandRow}
            />
          </CardBody>
        </Card>
      </Container>
    </DashboardLayout>
  );
};

export default MarketDefault;
